import * as Api from '@ViewModels';
import qqCatalystLogo from '../../../../assets/logo_qq_catalyst_sm.svg';
import { baseStyleSheet } from '../../../../styles/styles';
import { QQCatalystIntegrationGraphic } from '../../../svgs/graphics/QQCatalystIntegrationGraphic';
import { IntegrationCard, useIntegrationCard } from '../IntegrationCard';
import { styleSheet } from './styles';

export const DashboardQQCatalystIntegrationCard = () => {
	const { getLogoImgProps, getCloseButtonProps, getCtaButtonProps, getIntegrationTitleProps, showCard } =
		useIntegrationCard({
			closeIntegrationEventName: 'QQCatalystIntegrationClosed',
			enableUrlPath: '/integrations/qqcatalyst',
			integrationEnableEventName: 'QQCatalystIntegrationEnableClick',
			pendingAction: Api.PendingActions.ConnectQQCatalyst,
		});

	if (!showCard) {
		return null;
	}

	return (
		<IntegrationCard
			logoBgColor='#ff5400'
			closeButtonProps={getCloseButtonProps()}
			IntegrationGraphic={QQCatalystIntegrationGraphic}
			headerLabel='QQCatalyst Integration'
			logoImgProps={getLogoImgProps({ alt: 'QQCatalyst Logo', src: qqCatalystLogo })}
			ctaButtonProps={getCtaButtonProps({
				children: 'Connect with QQCatalyst',
				styleDeclaration: [baseStyleSheet.ctaButtonReverse, styleSheet.ctaButton],
			})}
			integrationTitleProps={getIntegrationTitleProps({ styleDeclaration: styleSheet.integrationTitle })}
		>
			<p>
				Levitate can sync with QQCatalyst and pull client information, including names, email, phone number, birthdays,
				anniversaries, and more. It can also push your notes from Levitate directly into QQCatalyst.
			</p>
			<p>To get started, please sign into your QQCatalyst account.</p>
		</IntegrationCard>
	);
};
