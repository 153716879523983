import { StyleSheet } from 'aphrodite';
import { brandSecondary, mention, success, white } from '../../../styles/colors';
import { CssSelectors, baseStyleSheet, ctaButtonProps, ctaButtonReverseProps } from '../../../styles/styles';

const footerHeaderHeight = 40;

export const styleSheet = StyleSheet.create({
	assistantContainer: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: 24,
	},
	assistantIcon: {
		filter: 'drop-shadow(0px 21px 36px rgba(0, 0, 0, 0.5)) drop-shadow(0px -21px 36px rgba(0, 170, 232, 0.355447))',
		height: 43,
		width: 'auto',
	},
	background: {
		height: '100%',
		margin: '-20px -30px -20px -30px',
		position: 'absolute',
		width: '100%',
	},
	body: {
		flexGrow: 1,
	},
	caution: {
		borderRadius: 8,
		boxSizing: 'border-box',
		color: '#fff',
		display: 'flex',
		flexWrap: 'wrap',
		fontSize: 12,
		justifyContent: 'center',
		lineHight: '16px',
		marginTop: 14,
		textAlign: 'center',
		...CssSelectors.allChildren(
			{
				...baseStyleSheet.fontBold,
			},
			':first-child'
		),
		...CssSelectors.allChildren(
			{
				fontStyle: 'italic',
			},
			':not(:first-child)'
		),
	},
	container: {
		borderBottomRightRadius: 6,
		boxSizing: 'border-box',
		color: '#fff',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		overflowY: 'auto',
		padding: '20px 30px',
		position: 'relative',
		...CssSelectors.allChildren({
			':not(svg)': {
				zIndex: 0,
			},
			width: '100%',
		}),
	},
	footer: {
		borderBottomRightRadius: 6,
		marginTop: 36,
		marginBottom: 12,
	},
	footerButtons: {
		height: footerHeaderHeight,
	},
	footerCloseButton: {
		...ctaButtonReverseProps('transparent', white, 'transparent'),
		boxShadow: 'none',
		':hover': {
			borderColor: success,
			color: success,
		},
	},
	generateButton: {
		width: '100%',
		...ctaButtonProps(success),
	},
	header: {
		color: '#fff',
		display: 'flex',
		height: footerHeaderHeight,
		justifyContent: 'flex-end',
	},
	headerCloseButton: {
		color: '#fff',
	},
	messageContainer: {
		marginLeft: 8,
	},
	personalitySelect: {
		marginTop: 8,
	},
	personalitySelector: {
		marginTop: 12,
	},
	pill: {
		alignItems: 'center',
		background: brandSecondary,
		borderRadius: 60,
		boxSizing: 'border-box',
		display: 'flex',
		fontSize: 12,
		height: 'auto',
		margin: 0,
		padding: '12px 24px',
		position: 'relative',
	},
	postResponseButton: {
		...ctaButtonProps(success),
	},
	refreshIcon: {
		height: 16,
		width: 16,
	},
	instructionsContainer: {},
	responseContainer: {
		borderTop: `1px solid ${mention}`,
		paddingTop: 24,
	},
	textArea: {
		marginTop: 8,
	},
});
