import * as React from 'react';
import { SvgIcon } from '../SvgIcon';

interface IProps extends React.SVGProps<SVGSVGElement> {
	fillColor?: string;
}

export const CompanyIcon = ({ fillColor = '#757575', width = 15, height = 14, ...props }: IProps) => {
	return (
		<SvgIcon height={height} viewBox='0 0 15 14' width={width} {...props}>
			<path
				fill={fillColor}
				fillRule='evenodd'
				d='M.7 14H14v-1.4H.7V14ZM3.119 4.2l4.23-2.672L11.582 4.2H3.119Zm11.255.108-6.65-4.2a.703.703 0 0 0-.748 0l-6.65 4.2A.7.7 0 0 0 .7 5.6H14a.7.7 0 0 0 .374-1.292ZM2.8 7.7h.7v2.8h-.7V7.7Zm1.4 4.2a.7.7 0 0 0 .7-.7V7a.7.7 0 0 0-.7-.7H2.1a.7.7 0 0 0-.7.7v4.2a.7.7 0 0 0 .7.7h2.1ZM7 7.7h.7v2.8H7V7.7Zm1.4 4.2a.7.7 0 0 0 .7-.7V7a.7.7 0 0 0-.7-.7H6.3a.7.7 0 0 0-.7.7v4.2a.7.7 0 0 0 .7.7h2.1ZM11.2 7.7h.7v2.8h-.7V7.7Zm1.4 4.2a.7.7 0 0 0 .7-.7V7a.7.7 0 0 0-.7-.7h-2.1a.7.7 0 0 0-.7.7v4.2a.7.7 0 0 0 .7.7h2.1Z'
				clipRule='evenodd'
			/>
		</SvgIcon>
	);
};
