import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { getVisibleConnections } from '.';
import {
	getContactTitleAndCompany,
	getDisplayName,
	getNormalizedNoteVisibility,
	sortContactTags,
} from '../../../../../models/UiUtils';
import { useFullscreenModal } from '../../../../../models/hooks/appStateHooks';
import { Checkbox } from '../../../../components/Checkbox';
import { TinyPopover } from '../../../../components/TinyPopover';
import { VisibilityIndicatorIcon } from '../../../../components/VisibilityIndicatorIcon';
import { CompanyIcon } from '../../../../components/svgs/icons/CompanyIcon';
import { Tag } from '../../../../components/tags/Tag';
import { brandPrimary } from '../../../../styles/colors';
import { baseStyleSheet, bs } from '../../../../styles/styles';
import { styleSheet } from './styles';

interface IProps {
	contact: Api.IContact;
	tagsToHighlight?: string[];
	onToggleSelection: () => void;
	isSelected: boolean;
}

const _PeopleDuplicateRowInternal = ({ contact, isSelected, onToggleSelection, tagsToHighlight }: IProps) => {
	const { history } = useFullscreenModal();
	const title = getContactTitleAndCompany(contact);
	const name = getDisplayName(contact);
	const sortedTags = sortContactTags({ tags: contact.tags ?? [], priorityTags: tagsToHighlight });
	const visibleTags = sortedTags.slice(0, 3);
	const extraTags = sortedTags.slice(3);
	const phone = contact.phoneNumbers?.[0]?.value;
	const email = contact.primaryEmail?.value;
	const visibleConnections: Api.IContactConnection[] = contact.connections ? getVisibleConnections(contact) : [];
	const contactConnections = contact.connections || [];
	const overflowConnectionCount = contactConnections.length - visibleConnections.length;
	const contactOwner = visibleConnections.find(x => x?.user?.id === contact.ownerId);
	const otherContacts = visibleConnections.filter(x => x?.user?.id !== contact.ownerId);
	const onClickContainer = (event: React.MouseEvent<HTMLDivElement>) => {
		if (event.isDefaultPrevented()) {
			return;
		}
		if (event.ctrlKey || event.metaKey) {
			window.open(window.location.origin + `/#/people/${contact.id}`);
		} else {
			history.push(`/people/${contact.id}`);
		}
	};

	const contactOwnerConnectionTypes = contactOwner?.connectionTypes
		? contactOwner?.connectionTypes.sort((a, b) => a.localeCompare(b))
		: null;

	return (
		<div
			className={css(styleSheet.outerContainer, isSelected && styleSheet.containerSelected)}
			onClick={onClickContainer}
		>
			<div className={`${css(styleSheet.container)}`}>
				<div
					className={`${css(styleSheet.selection)}`}
					onClick={ev => {
						ev.stopPropagation();
						ev.preventDefault();
						onToggleSelection();
					}}
				>
					<Checkbox
						checked={isSelected}
						id={`people-duplicate-row-checkbox-${contact.id}`}
						childrenPosition='left'
						backgroundFillColor='white'
						readOnly={true}
					/>
				</div>
				<div className={css(bs.flex, bs.itemsCenter, bs.justifyCenter, bs.w4)}>
					<VisibilityIndicatorIcon
						title={getNormalizedNoteVisibility(contact.visibility ?? 'none')}
						visibility={contact.visibility}
					/>
				</div>
				<div className={`${css(styleSheet.name, bs.ml9)}`}>
					<div className={css(baseStyleSheet.truncateText)}>{name}</div>
					{title && (
						<div className={css(bs.textNavigation, bs.truncateText, bs.textXs, bs.flex, bs.itemsCenter, bs.gap1)}>
							<CompanyIcon fillColor={brandPrimary} /> {title}
						</div>
					)}
				</div>
				<div className={`${css(styleSheet.tags)}`}>
					{visibleTags.map(x => {
						return <Tag className={css(styleSheet.tag)} key={`contact-${contact.id}-tag-${x}`} tagValue={x} />;
					})}
					{extraTags.length > 0 ? (
						<TinyPopover
							anchor={
								<span className={css(baseStyleSheet.brandLink, styleSheet.tagMore)}>
									&nbsp;{`+${extraTags.length} more`}
								</span>
							}
							autoCloseOtherPopoversOnHover={true}
							dismissOnOutsideAction={true}
							toggleOnHover={true}
						>
							<div className={css(styleSheet.extraTags)}>
								{extraTags.map(x => {
									return (
										<div key={x} className={css(styleSheet.extraTagsItem)}>
											<Tag className={css(styleSheet.tag)} key={`contact-${contact.id}-tag-${x}`} tagValue={x} />
										</div>
									);
								})}
							</div>
						</TinyPopover>
					) : null}
				</div>
				<div className={`${css(styleSheet.info)}`}>
					{phone && <div className={css(styleSheet.title)}> {phone} </div>}
					{email && <div className={css(styleSheet.title)}> {email} </div>}
				</div>
				<div className={css(styleSheet.connections)}>
					<div className={`${css(styleSheet.title, styleSheet.connectionsItemOwner)}`} key={contactOwner?.user?.id}>
						{contactOwner?.user ? <span>{getDisplayName(contactOwner.user)}</span> : null}
						<span className={css(styleSheet.title)}>{`${
							contactOwnerConnectionTypes ? ' (Owner, ' + contactOwnerConnectionTypes.join(', ') + ')' : ' (Owner)'
						}`}</span>
						<span>{`${otherContacts.length ? ',' : ''}`}</span>
					</div>
					<div>
						{otherContacts.map((x, i) => {
							const displayName = x?.user ? getDisplayName(x.user) : '';
							const addComma = i !== otherContacts.length - 1 && otherContacts.length > 1;
							const connectionTypesListDisplay = x?.connectionTypes
								? x.connectionTypes.sort((a, b) => a.localeCompare(b))
								: null;

							return (
								<span className={css(styleSheet.title)} key={x?.user?.id} title={displayName}>
									{`${displayName}${
										connectionTypesListDisplay ? ' (' + connectionTypesListDisplay?.join(', ') + ')' : ''
									}${addComma ? ',' : ''}`}
									{!!addComma && <span>&nbsp;</span>}
								</span>
							);
						})}
						{overflowConnectionCount > 0 && <span>&nbsp;{`+${overflowConnectionCount} more`}</span>}
					</div>
				</div>
			</div>
		</div>
	);
};

export const PeopleDuplicateRow = observer(_PeopleDuplicateRowInternal);
