import { Switch, useRouteMatch } from 'react-router';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { PrivateRoute } from '../../components/PrivateRoute';
import { TextCampaignFilterPage } from './TextCampaignFilterPage';
import { TextCampaignScheduleSendPage } from './TextCampaignScheduleSendPage';
import { TextCampaignSearchPage } from './TextCampaignSearchPage';
import { TextingCampaignContactsPage } from './TextingCampaignContactsPage';
import { TextCampaignTemplateContactsPage } from './template/TextCampaignTemplateContactsPage';
import { TextCampaignTemplateFilterPage } from './template/TextCampaignTemplateFilterPage';
import { TextCampaignTemplateScheduleSendPage } from './template/TextCampaignTemplateScheduleSendPage';
import { TextCampaignTemplateSearchPage } from './template/TextCampaignTemplateSearchPage';

export function TextingCampaignRoutes() {
	const userSession = useUserSession();
	const { url } = useRouteMatch();
	return (
		<Switch>
			<PrivateRoute path={`${url}/filter`} userSession={userSession}>
				<TextCampaignFilterPage />
			</PrivateRoute>
			<PrivateRoute path={`${url}/search`} userSession={userSession}>
				<TextCampaignSearchPage />
			</PrivateRoute>
			<PrivateRoute path={`${url}/contacts`} userSession={userSession}>
				<TextingCampaignContactsPage />
			</PrivateRoute>
			<PrivateRoute path={`${url}/template-search/:automationTemplateId`} userSession={userSession}>
				<TextCampaignTemplateSearchPage />
			</PrivateRoute>
			<PrivateRoute path={`${url}/template-filter/:automationTemplateId`} userSession={userSession}>
				<TextCampaignTemplateFilterPage />
			</PrivateRoute>
			<PrivateRoute path={`${url}/template-contacts/:automationTemplateId`} userSession={userSession}>
				<TextCampaignTemplateContactsPage />
			</PrivateRoute>
			<PrivateRoute path={`${url}/schedule-send/:automationTemplateId`} userSession={userSession}>
				<TextCampaignTemplateScheduleSendPage />
			</PrivateRoute>
			<PrivateRoute path={`${url}/schedule-send`} userSession={userSession}>
				<TextCampaignScheduleSendPage />
			</PrivateRoute>
		</Switch>
	);
}
