import { SvgIcon } from '../../icons/SvgIcon';

export const TrialExpiringSoonGraphic = () => {
	return (
		<SvgIcon viewBox='0 0 80 84' height={84} width={84}>
			<g fill='none' fillRule='evenodd' transform='translate(0 1)'>
				<g stroke='#ddd'>
					<path d='m.5 81.5h79v1h-79z' />
					<circle cx='28' cy='2' fill='#f9f9f9' r='2' />
					<circle cx='6.5' cy='29.5' r='1' />
					<circle cx='2' cy='16' fill='#f9f9f9' r='1' />
				</g>
				<circle cx='47' cy='11' fill='#ddd' r='1' />
				<circle cx='73.5' cy='31.5' fill='#f9f9f9' r='1.5' stroke='#ddd' />
				<g fill='#ddd' transform='translate(12 8)'>
					<rect height='1' rx='.5' width='3' y='1' />
					<rect height='1' rx='.5' transform='matrix(0 1 -1 0 3 0)' width='3' y='1' />
				</g>
				<g fill='#ddd' transform='translate(70 6)'>
					<rect height='1' rx='.5' width='3' y='1' />
					<rect height='1' rx='.5' transform='matrix(0 1 -1 0 3 0)' width='3' y='1' />
				</g>
				<path
					d='m47 50 11.9047482 10.2040699c1.3298754 1.1398932 2.0952518 2.8039903 2.0952518 4.5555396v16.2403905h-42v-16.2403905c0-1.7515493.7653764-3.4156464 2.0952518-4.5555396l11.9047482-10.2040699-11.9047482-10.2040699c-1.3298754-1.1398932-2.0952518-2.8039903-2.0952518-4.5555396v-16.2403905h42v16.2403905c0 1.7515493-.7653764 3.4156464-2.0952518 4.5555396z'
					fill='#f2f2f2'
				/>
				<path
					d='m60 20h-40v15.2403905c0 1.4596244.6378136 2.846372 1.7460431 3.796283l12.7905476 10.9633265-12.7905476 10.9633265c-1.1082295.949911-1.7460431 2.3366586-1.7460431 3.796283v15.2403905h40v-15.2403905c0-1.4596244-.6378136-2.846372-1.7460431-3.796283l-12.7905476-10.9633265 12.7905476-10.9633265c1.1082295-.949911 1.7460431-2.3366586 1.7460431-3.796283z'
					stroke='#ddd'
					strokeWidth='2'
				/>
				<path
					d='m23 26.8332787h34v6.1897573c0 1.7806751-.7909345 3.4693574-2.1588849 4.6093204l-14.8411151 12.3676436-14.8411151-12.3676436c-1.3679504-1.139963-2.1588849-2.8286453-2.1588849-4.6093204z'
					fill='#fff'
				/>
				<path
					d='m23 50h34v6.4155353c0 1.8139788-.8206627 3.5305785-2.2324302 4.6696271l-14.7675698 11.9148376-14.7675698-11.9148376c-1.4117675-1.1390486-2.2324302-2.8556483-2.2324302-4.6696271z'
					fill='#fff'
					transform='matrix(1 0 0 -1 0 123)'
				/>
				<path d='m39 45h2v22h-2z' fill='#ccc' />
				<path
					d='m29 40 11 9 11-9c-2.6720069 1.3333333-6.3386735 2-11 2s-8.3279931-.6666667-11-2zm-5 27.2736858v4.7263142h32l.0000071-4.7263102c0-1.3542388-.9072822-2.5405723-2.2142439-2.8952752-5.8424424-1.5856097-10.4376968-2.3784146-13.7857632-2.3784146-3.3480684 0-7.9433264.7928058-13.785774 2.3784175l.0000033.0000119c-1.3069531.3547007-2.2142293 1.5410264-2.2142293 2.8952564z'
					fill='#ccc'
				/>
				<rect fill='#f2f2f2' height='10' rx='5' width='54' x='13' y='17' />
				<rect height='8' rx='4' stroke='#ddd' strokeWidth='2' width='52' x='14' y='18' />
				<rect fill='#f2f2f2' height='10' rx='5' width='54' x='13' y='73' />
				<rect height='8' rx='4' stroke='#ddd' strokeWidth='2' width='52' x='14' y='74' />
				<g fill='#ddd' transform='translate(33 30)'>
					<rect height='1' rx='.5' width='3' y='1' />
					<rect height='1' rx='.5' transform='matrix(0 1 -1 0 3 0)' width='3' y='1' />
				</g>
				<circle cx='44.5' cy='36.5' fill='#ddd' r='1.5' />
			</g>
		</SvgIcon>
	);
};
