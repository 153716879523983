import { SvgIcon } from '../SvgIcon';

const defaultHeight = 40;
const defaultWidth = 24;
export const RightChevron = ({
	width = defaultWidth,
	height = defaultHeight,
	colorFill,
	className,
}: { width: number; height: number; colorFill: string; className?: string }) => {
	return (
		<SvgIcon className={className} width={width} height={height} viewBox={`0 0 ${defaultWidth} ${defaultHeight}`}>
			<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
				<g transform='translate(-1051.638935, -540.000000)' fill={colorFill}>
					<g transform='translate(169.000000, 460.000000)'>
						<g transform='translate(892.500000, 104.000000) scale(-1, 1) translate(-892.500000, -104.000000) translate(863.000000, 50.000000)'>
							<polygon
								transform='translate(27.680532, 50.000000) scale(-1, 1) translate(-27.680532, -50.000000) '
								points='19.3610649 70 16 66.6389351 32.6389351 50 16 33.3610649 19.3610649 30 39.3610649 50'
							/>
						</g>
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
