import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { IntegrationOAuthStep } from '../../../../viewmodels/AppViewModels';
import wealthboxLogo from '../../../assets/logo_wealthbox.png';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { InProgressIcon } from '../../../components/svgs/icons/InProgressIcon';
import { baseStyleSheet } from '../../../styles/styles';
import { useManageIntegrationConnection } from '../hooks/useManageIntegrationConnection';
import { styleSheet as integrationsStyleSheet } from '../styles';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const Wealthbox: React.FC<IProps> = props => {
	const { className, styles } = props;
	const { oauthStep, onConnect, onDisable, isLoadingCallbackAuthUrl, hasIntegrationStatusLoaded } =
		useManageIntegrationConnection({ provider: Api.ConfigurableIntegrationType.Wealthbox });

	const renderContent = () => {
		switch (oauthStep) {
			case IntegrationOAuthStep.InProgress:
				return (
					<>
						<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
							To get started, please sign into your Wealthbox account.
						</p>
						<div className={css(styleSheet.inProgressContainer)}>
							<div className={css(styleSheet.inProgressIconContainer)}>
								<InProgressIcon className={css(baseStyleSheet.absoluteCenter)} />
							</div>
							<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
								Sync in progress... this could take a while.
							</p>
						</div>
					</>
				);
			case IntegrationOAuthStep.Connected:
				return (
					<>
						<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
							We are now syncing your Wealthbox contacts. This may take a while. Feel free to navigate away from this
							screen.
						</p>
						<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
							If you wish to disable this integration for your user,{' '}
							<Link
								to='#'
								onClick={ev => {
									ev.preventDefault();
									onDisable();
								}}
							>
								click here to disable.
							</Link>
						</p>
					</>
				);
			default:
				return (
					<>
						<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
							To get started, please sign into your Wealthbox account.
						</p>
						<div className={css(integrationsStyleSheet.signInContainer, styleSheet.signInContainer)}>
							<div className={css(styleSheet.center)}>
								<img src={wealthboxLogo} alt='Wealthbox Logo' />
							</div>
							<div className={css(styleSheet.center)}>
								<button
									className={css(baseStyleSheet.ctaButton, styleSheet.button)}
									onClick={onConnect}
									disabled={isLoadingCallbackAuthUrl}
								>
									Connect
								</button>
							</div>
						</div>
					</>
				);
		}
	};

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} component-class ${className || ''}`}>
			<div className={css(integrationsStyleSheet.logo, integrationsStyleSheet.headerLogo)}>
				<img src={wealthboxLogo} alt='Wealthbox Logo' />
			</div>
			<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
				Levitate can sync data from Wealthbox, including client names, email addresses, physical addresses, matters, and
				more.
			</p>
			{!hasIntegrationStatusLoaded ? (
				<LoadingSpinner type='large' className={css(baseStyleSheet.absoluteCenter)} />
			) : (
				renderContent()
			)}
		</div>
	);
};
