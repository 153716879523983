import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	button: {
		marginTop: 24,
		padding: '10px 60px',
		width: '80%',
	},
	center: {
		display: 'flex',
		justifyContent: 'center',
	},
	container: {},
	customButton: {
		':hover': {
			background: 'none',
			textDecoration: 'underline',
		},
		background: 'none',
		border: 'none',
		boxShadow: 'none',
		display: 'inline',
		outlone: 'none',
		padding: '0 0 0 5px',
	},
	inProgressContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		marginTop: 50,
		...CssSelectors.allChildren(
			{
				margin: 0,
				paddingTop: 5,
			},
			'p'
		),
	},
	inProgressIconContainer: {
		background: '#f9f9f9',
		borderRadius: '50%',
		height: 150,
		marginBottom: 20,
		position: 'relative',
		width: 150,
	},
	signInContainer: {
		minHeight: 0,
		...CssSelectors.allDescendants(
			{
				width: '90%',
			},
			'img'
		),
	},
});
