import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet } from './styles';
import { css } from 'aphrodite';

export function AutomationStepTitleAndDescription({ title, description }: { title: string; description: string }) {
	return (
		<>
			<h3 className={css(baseStyleSheet.truncateText, styleSheet.automationCardTitle)}>{title}</h3>
			<p className={css(baseStyleSheet.tableColumnHeader, styleSheet.automationCardLabel)}>{description}</p>
		</>
	);
}
