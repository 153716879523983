import { css } from 'aphrodite';
import * as Api from '@ViewModels';
import * as React from 'react';
import { styleSheet } from './styles';
import { useInsurancePolicyFilter } from '../../../../queries';
import { Badge } from '../../dataBoards/Badge';
import { numberToCurrencyStringValue } from '../../../../models/UiUtils';
import moment from 'moment';

export const CompactPoliciesInfo = ({
	contact,
}: {
	contact: Api.IContact;
}) => {
	const policyFilterQuery = useInsurancePolicyFilter({
		enabled: !!contact.id,
		filterRequest: {
			criteria: [
				{
					property: Api.InsurancePolicyFilterProperty.Contact,
					value: contact.id,
				},
			],
		},
	});

	const policies = React.useMemo(() => {
		return policyFilterQuery.data?.pages.map(page => page.values).flat() ?? [];
	}, [policyFilterQuery.data]);

	return (
		<div className={css(styleSheet.container)}>
			{policies.map(policy => {
				return (
					<div key={`policy-details-${policy.id}`} className={css(styleSheet.row)}>
						<div className={css(styleSheet.box)}>
							<label className={css(styleSheet.label)}>Policy</label>
							<Badge text={policy.lineOfBusiness.name} foregroundColor={policy.lineOfBusiness.foregroundColor} />
						</div>
						<div className={css(styleSheet.box)}>
							<label className={css(styleSheet.label)}>Renewal Date</label>
							<span>{moment(policy.renewalDate).format('MM/DD/YYYY')}</span>
						</div>
						<div className={css(styleSheet.box)}>
							<label className={css(styleSheet.label)}>Effective Date</label>
							<span>{moment(policy.effectiveDate).format('MM/DD/YYYY')}</span>
						</div>
						<div className={css(styleSheet.box)}>
							<label className={css(styleSheet.label)}>Policy #</label>
							<span>{policy.policyNumber}</span>
						</div>
						<div className={css(styleSheet.box)}>
							<label className={css(styleSheet.label)}>Carrier</label>
							<span>{policy.carrier.name}</span>
						</div>
						<div className={css(styleSheet.box)}>
							<label className={css(styleSheet.label)}>Premium</label>
							<span>${numberToCurrencyStringValue(policy.premium)}</span>
						</div>
					</div>
				);
			})}
		</div>
	);
};
