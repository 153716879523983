import { styleSheet } from './styles';
import { StyleDeclarationValue, css } from 'aphrodite';
import { clsx } from 'clsx';
import * as React from 'react';

interface IProps {
	className?: string;
	children: React.ReactNode;
	style?: React.CSSProperties;
	styleDeclaration?: StyleDeclarationValue[] | StyleDeclarationValue;
}

export const DashboardCard = ({ className, children, style, styleDeclaration }: IProps) => {
	const styleDeclarations = Array.isArray(styleDeclaration) ? styleDeclaration : [styleDeclaration];
	return (
		<article className={clsx(css(styleSheet.card, ...styleDeclarations), className)} style={style}>
			{children}
		</article>
	);
};
