import * as React from 'react';
import { css } from 'aphrodite';
import * as Api from '@ViewModels';
import { styleSheet } from './styles';
import { white } from '../../../../../styles/colors';
import { useActionItemComposer, useNoteComposer, useUserSession } from '../../../../../../models/hooks/appStateHooks';
import { NotesIcon } from '../../../../../components/svgs/icons/NotesIcon';
import { ActionItemsIcon } from '../../../../../components/svgs/icons/ActionItemsIcon';
import { AutomationsIcon } from '../../../../../components/svgs/icons/AutomationsIcon';
import { AutomationSelectorModal } from '../../../../../components/automation/AutomationSelector';

export const QuickActions = ({
	contact,
}: {
	contact: Api.IContact;
}) => {
	const userSession = useUserSession();
	const actionItemComposer = useActionItemComposer();
	const noteComposer = useNoteComposer();

	const [automationPopoverOpen, setAutomationPopoverOpen] = React.useState(false);

	const addNote = () => {
		const note: Api.INote = Api.createRichContentWithReferencedEntities([contact] || []);
		const noteVm = new Api.NoteViewModel(userSession, note);
		noteComposer.show(noteVm);
	};
	const addActionItem = () => {
		const actionItemModel: Api.IActionItem = Api.createRichContentWithReferencedEntities([contact] || []);
		const actionItem = new Api.ActionItemViewModel(userSession, actionItemModel);
		actionItemComposer.show(actionItem);
	};
	const startAutomation = () => {
		setAutomationPopoverOpen(true);
	};

	const contactVm = React.useMemo(() => {
		return contact ? new Api.ContactViewModel(userSession, contact) : null;
	}, [contact, userSession]);

	return (
		<div className={css(styleSheet.quickActions)}>
			<a className={css(styleSheet.quickActionButton)} onClick={addNote}>
				<NotesIcon fillColor={white} />
			</a>
			<a className={css(styleSheet.quickActionButton)} onClick={addActionItem}>
				<ActionItemsIcon fill={white} width={16} height={14} />
			</a>
			<a className={css(styleSheet.quickActionButton)} onClick={startAutomation}>
				<AutomationsIcon fillColor={white} />
			</a>

			{!!contactVm && automationPopoverOpen ? (
				<AutomationSelectorModal
					contact={contactVm}
					createAutomationForContact={true}
					closeModal={() => setAutomationPopoverOpen(false)}
				/>
			) : null}
		</div>
	);
};
