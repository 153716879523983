import { StyleSheet } from 'aphrodite';
import { brandPrimary, header, mention, nameCircles, tagBackgroundColor, titles } from '../../../styles/colors';
import { CssSelectors, baseStyleSheet } from '../../../styles/styles';

export const commCardGreenColor = '#89C947';

export const styleSheet = StyleSheet.create({
	campaignLibraryBtn: {
		alignItems: 'center',
		display: 'flex',
		marginTop: '0.5rem',
	},
	campaignLibraryBtnArrow: {
		marginLeft: '0.5rem',
	},
	campaignSendDate: {
		color: header,
		fontSize: '0.875rem',
		fontStyle: 'italic',
	},
	campaignTag: {
		background: tagBackgroundColor,
		borderRadius: 13,
		color: titles,
		fontSize: '0.75rem',
		padding: '0.5rem',
	},
	campaignTagExtra: {
		color: '#046CB6',
		fontSize: '0.75rem',
	},
	campaignTags: {
		...CssSelectors.allChildren(
			{
				marginLeft: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	campaignTitle: {
		color: titles,
		...baseStyleSheet.fontBold,
		fontSize: '0.875rem',
		fontWeight: 700,
		margin: '0 0 0.5rem 0',
	},
	cardBodyColumn: {
		display: 'flex',
		flex: 1,
		paddingTop: '1.5rem',
	},
	cardBodyColumnInner: {
		flex: 1,
	},
	cardColumn: {
		display: 'flex',
		flex: '1 1 198px',
		flexDirection: 'column',
	},
	cardColumnInset: {
		padding: '1rem',
	},
	carouselBtnColumn: {
		alignItems: 'center',
	},
	circle: {
		borderRadius: '50%',
		...CssSelectors.allChildren(
			{
				borderRadius: '50%',
			},
			'.shimmer'
		),
	},
	emailIconButton: {
		alignItems: 'center',
		background: commCardGreenColor,
		borderRadius: 12,
		display: 'flex',
		justifyContent: 'center',
		marginRight: '0.5rem',
		padding: '0.5rem',
	},
	emailOpenRateProgressContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		padding: '0 0.5rem',
		textAlign: 'center',
	},
	firstCampaignCard: {
		border: `1px solid ${nameCircles}`,
		borderRadius: '0.8125rem',
		boxShadow: '0px 1px 2px rgba(167,171,173, 0.22)',
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
		padding: '1rem',
		...CssSelectors.allChildren(
			{
				marginTop: '1rem',
			},
			':not(:first-child)'
		),
	},
	firstCampaignContent: {
		margin: 0,
	},
	firstCampaignTitle: {
		...baseStyleSheet.fontBold,
		fontSize: '1rem',
		fontWeight: 600,
		margin: 0,
	},
	linkButton: {
		color: brandPrimary,
		display: 'flex',
		fontSize: 14,
		fontWeight: 400,
		/* identical to box height */
	},
	messageSentHeader: {
		alignItems: 'flex-start',
		display: 'flex',
		paddingBottom: '1rem',
		...CssSelectors.allChildren(
			{
				marginLeft: '1rem',
			},
			':not(:first-child)'
		),
	},
	messageSentHeaderFull: {
		flex: 1,
	},
	messageSentHeaderLabel: {
		color: header,
		lineHeight: '1rem',
		margin: 0,
	},
	messageSentHeaderNumber: {
		color: commCardGreenColor,
		fontSize: '1.625rem',
	},
	messageSentHeaderNumberSmaller: {
		fontSize: '1.125rem',
	},
	messageSentHeaderSeparator: {
		borderBottom: `1px solid ${mention}`,
	},
	messageSentHeaderTotal: {
		color: header,
		flex: 1,
		fontSize: '0.875rem',
		textAlign: 'right',
		...CssSelectors.allChildren(
			{
				margin: 0,
			},
			'p'
		),
	},
	messageSentHGroup: {
		display: 'flex',
		flexDirection: 'column',
		height: 50,
		justifyContent: 'center',
		...CssSelectors.allChildren(
			{
				marginTop: '0.25rem',
			},
			':not(:first-child)'
		),
	},
	messageSentTexts: {
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: '2.5rem',
			},
			':not(:first-child)'
		),
	},
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		width: '100%',
	},
	statsContainer: {
		alignItems: 'flex-start',
		display: 'flex',
		flexDirection: 'column',
		...CssSelectors.allChildren(
			{
				marginTop: '1rem',
			},
			':not(:first-child)'
		),
	},
	tag: {
		background: '#DBF2C3',
		color: commCardGreenColor,
	},
	textEngagementCount: {
		color: titles,
		...baseStyleSheet.fontBold,
		fontWeight: 700,
	},
	textProgressContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
	},
	textProgressRow: {
		alignItems: 'center',
		display: 'flex',
		flex: 1,
		...CssSelectors.allChildren(
			{
				marginLeft: '0.25rem',
			},
			':not(:first-child)'
		),
	},
	textProgressRowLabel: {
		color: header,
		fontSize: '0.75rem',
	},
});
