import * as React from 'react';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { styleSheet } from '../../SearchComponent/styles';
import { Badge } from '../../Badge';
import { TextInput } from '../../../TextInput';
import { useAddDonationCampaignMutation, useCampaignAutocomplete } from '../../../../../queries';
import { SearchIcon } from '../../../svgs/icons/SearchIcon';

export const DonationCampaignSearch = ({
	onSelectDonationCampaign,
	canAdd = true,
	onCreateCampaign,
	initialSearchFragment,
	className,
	dropdownClassName,
	onClear,
}: {
	onSelectDonationCampaign: React.Dispatch<React.SetStateAction<Api.IDonationCampaign>>;
	canAdd?: boolean;
	onCreateCampaign?: (campaign: Api.IDonationCampaign) => void;
	initialSearchFragment?: string;
	className?: string;
	dropdownClassName?: string;
	onClear?: () => void;
}) => {
	const [isCampaignSearchDropdownOpen, setIsCampaignSearchDropdownOpen] = React.useState(false);
	const [searchFragment, setSearchFragment] = React.useState<string>(initialSearchFragment ?? '');
	const addCampaignMutation = useAddDonationCampaignMutation({
		onSuccess: (result: Api.IDonationCampaign) => {
			setSearchFragment(result.name);
			onCreateCampaign?.(result);
			setIsCampaignSearchDropdownOpen(false);
		},
	});

	const selectDonationCampaign = (donationCampaign: Api.IDonationCampaign) => {
		setSearchFragment(donationCampaign.name);
		setIsCampaignSearchDropdownOpen(false);
		onSelectDonationCampaign(donationCampaign);
	};

	const updateSearchFragment = (newValue: string) => {
		setSearchFragment(newValue);

		if (newValue) {
			setIsCampaignSearchDropdownOpen(true);
		} else {
			setIsCampaignSearchDropdownOpen(false);
			if (onClear) {
				onClear();
			}
		}
	};

	const createNewCampaign = () => {
		const donationCampaign: Api.IDonationCampaign = {
			name: searchFragment,
		};
		addCampaignMutation.mutate({
			donationCampaign,
		});
	};

	const donationCampaignsQuery = useCampaignAutocomplete({
		enabled: !!searchFragment,
		pageSize: 25,
		fragment: searchFragment,
	});

	const donationCampaigns = donationCampaignsQuery?.data?.values ?? [];

	return (
		<div className={`${css(styleSheet.searchInputWrap)} ${className || ''}`}>
			<TextInput
				className={css(isCampaignSearchDropdownOpen ? styleSheet.popoverOpen : null)}
				inputId='donation-modal-edit-campaign'
				onChange={e => updateSearchFragment(e.target.value)}
				type='text'
				value={searchFragment}
				placeholder='Search'
				leftAccessory={<SearchIcon className={css(styleSheet.searchIcon)} />}
			/>

			{isCampaignSearchDropdownOpen ? (
				<div className={`${css(styleSheet.dropdown)} ${dropdownClassName || ''}`}>
					{donationCampaigns.length === 0 && !donationCampaignsQuery.isLoading ? <i>No matches found</i> : null}

					{donationCampaigns.map(donationCampaign => {
						return (
							<button onClick={() => selectDonationCampaign(donationCampaign)} key={`campaign-${donationCampaign.id}`}>
								<Badge text={donationCampaign.name} foregroundColor={donationCampaign.foregroundColor} />
							</button>
						);
					})}

					{canAdd && !!searchFragment ? (
						<button className={css(styleSheet.addNewButton)} onClick={createNewCampaign}>
							<span>+ Add &quot;{searchFragment}&quot;</span>
						</button>
					) : null}
				</div>
			) : null}
		</div>
	);
};
