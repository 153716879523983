import { StyleSheet } from 'aphrodite';
import { titles } from '../../../styles/colors';
import { BreakPoints, CssSelectors } from '../../../styles/styles';

const footerHeight = 60;

export const styleSheet = StyleSheet.create({
	archiveCheckbox: {
		display: 'inline-flex',
	},
	board: {
		position: 'relative',
	},
	boardHeader: {
		textAlign: 'right',
	},
	boardHeaderSubheader: {
		letterSpacing: 0,
		marginTop: 10,
		whiteSpace: 'pre',
	},
	boardHeaderTitle: {
		color: titles,
		fontSize: '18px',
	},
	boardHeaderTitles: {
		color: '#767676',
		fontSize: '14px',
		lineHeight: '22px',
		textAlign: 'left',
	},
	body: {
		boxSizing: 'border-box',
		padding: `20px 0 ${footerHeight}px 0`,
	},
	checkboxSection: {
		marginBottom: 20,
	},
	columns: {
		height: 380,
		overflowY: 'auto',
		[BreakPoints.forMinWidth(768)]: {
			height: 'auto',
			maxHeight: 500,
			minHeight: 380,
		},
		[BreakPoints.forMinWidth(1024)]: {
			height: 'auto',
			maxHeight: 660,
			minHeight: 380,
		},
		...CssSelectors.allDescendants(
			{
				marginTop: 20,
			},
			'.edit-kanban-board-column-definition:not(:first-child)'
		),
	},
	columnsFooter: {
		margin: '20px 0',
	},
	footer: {
		alignItems: 'center',
		background: '#fff',
		bottom: 0,
		display: 'flex',
		height: footerHeight,
		left: 0,
		position: 'absolute',
		right: 0,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	nameInput: {
		width: '50%',
		marginTop: 8,
	},
	nameLabel: {},
});
