import { brandSecondary } from '../../../../styles/colors';

export function SettingCardIcon({ width = 60, height = 60 }: { width?: number; height?: number }) {
	return (
		<svg width={width} height={height} viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<circle cx='30' cy='30' r='30' fill='#E8F3F9' />
			<circle cx='30' cy='30' r='18' fill='white' stroke='#00AAE8' strokeWidth='2' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M37.1 29.4857H33.3V27.5829H37.1V29.4857ZM36.15 37.0971H33.3V31.3886H36.15V37.0971ZM32.6284 22.154C32.8079 21.9742 33.0464 21.8753 33.3 21.8753C33.5536 21.8753 33.7921 21.9742 33.9707 22.1531C34.3412 22.5241 34.3421 23.1283 33.9707 23.4984C33.4102 24.0607 32.4042 24.388 31.5653 24.563C31.7401 23.7229 32.0678 22.7154 32.6284 22.154ZM31.4 29.4857H27.6V27.5829H31.4V29.4857ZM31.4 37.0971H27.6V31.3886H31.4V37.0971ZM25.0283 23.4984C24.8488 23.3186 24.75 23.0798 24.75 22.8257C24.75 22.5727 24.8488 22.3329 25.0293 22.1531C25.2079 21.9742 25.4454 21.8753 25.7 21.8753C25.9536 21.8753 26.1921 21.9742 26.3716 22.154C26.9321 22.7144 27.2589 23.722 27.4337 24.5611C26.5977 24.3851 25.586 24.0569 25.0283 23.4984ZM25.7 29.4857H21.9V27.5829H25.7V29.4857ZM25.7 37.0971H22.85V31.3886H25.7V37.0971ZM37.1 25.68H34.1407C34.5692 25.4574 34.9758 25.1843 35.3149 24.8437C36.4265 23.7315 36.4265 21.9209 35.3149 20.8078C34.2376 19.7307 32.3624 19.7307 31.2851 20.8078C29.7309 22.3643 29.519 25.2966 29.5 25.6258C29.481 25.2966 29.2691 22.3643 27.715 20.8078C26.6377 19.7307 24.7604 19.7307 23.6851 20.8078C23.1464 21.3472 22.85 22.0636 22.85 22.8257C22.85 23.5878 23.1464 24.3043 23.6841 24.8437C24.0242 25.1843 24.4299 25.4564 24.8593 25.68H21.9C20.8521 25.68 20 26.5335 20 27.5829V30.4372C20 30.9623 20.4256 31.3886 20.95 31.3886V37.0971C20.95 38.1466 21.8021 39 22.85 39H36.15C37.1978 39 38.05 38.1466 38.05 37.0971V31.3886C38.5744 31.3886 39 30.9623 39 30.4372V27.5829C39 26.5335 38.1479 25.68 37.1 25.68Z'
				fill={brandSecondary}
			/>
		</svg>
	);
}
