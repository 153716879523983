import { StyleSheet } from 'aphrodite';

const donotPerfectColor = '#00B8F9';

export const styleSheet = StyleSheet.create({
	ctaButton: {
		background: donotPerfectColor + '10',
		border: 'none',
		boxShadow: 'none',
		color: donotPerfectColor,
		fontWeight: 400,
		marginTop: 20,
	},
	integrationTitle: {
		color: donotPerfectColor,
	},
	logo: {
		borderRadius: 12,
		height: 40,
		marginRight: -10,
		width: 40,
	},
});
