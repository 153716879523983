import {
	background,
	backgroundAlt,
	borderOutline,
	brandSecondary,
	inputBorderColor,
	titles,
	urgentDate,
} from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	childRoute: {
		boxSizing: 'border-box',
		height: '100%',
		overflow: 'auto',
		padding: 20,
	},
	closeButton: {
		height: 30,
		marginBottom: 4,
	},
	confirmationSubTitle: {
		color: titles,
		fontSize: 14,
	},
	confirmationTitle: {
		color: brandSecondary,
		fontSize: 18,
	},
	container: {
		height: '100%',
		width: '100%',
	},
	keyDateSendOptions: {
		margin: '30px auto 0 auto',
		width: 300,
	},
	loaderContainer: {
		margin: '30px auto',
	},
	omittedContainer: {
		background,
		border: `1px solid ${inputBorderColor}`,
		borderRadius: 3,
		marginBottom: 20,
		marginTop: 20,
		paddingLeft: 5,
		paddingRight: 3,
		width: '70%',
	},
	omittedDialog: {
		...CssSelectors.allDescendants(
			{
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column',
				maxHeight: 500,
				minHeight: 300,
				minWidth: 500,
			},
			'.body'
		),
	},
	omittedGroup: {
		'::-webkit-scrollbar': {
			backgroundColor: backgroundAlt,
			width: 6,
		},
		'::-webkit-scrollbar-thumb': {
			backgroundColor: borderOutline,
			borderRadius: 10,
			padding: '0 2px',
			width: 4,
		},
		background,
		display: 'flex',
		flexDirection: 'column',
		maxHeight: 235,
		overflowY: 'auto',
	},
	omittedItem: {
		display: 'flex',
		paddingLeft: 12,
	},
	omittedList: {
		':last-child': {
			borderBottom: 'none',
		},
		alignItems: 'center',
		borderBottom: `1px solid ${inputBorderColor}`,
		display: 'flex',
		fontSize: 13,
		height: 30,
		marginRight: 5,
		paddingLeft: 10,
	},
	popover: {
		boxSizing: 'border-box',
		color: urgentDate,
		fontSize: 12,
		maxWidth: 200,
		padding: 6,
	},
});
