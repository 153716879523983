import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { getDisplayName } from '../../../models/UiUtils';
import { useUserQuery } from '../../../queries';
import { CampaignSelectEmployeeModal } from '../../containers/TextingCampaign/CampaignSelectEmployeeModal';
import { bs } from '../../styles/styles';
import { Dropdown } from '../Dropdown';
import { DisclosureIcon } from '../svgs/icons/DisclosureIcon';
import { styleSheet } from './styles';

export function ComposerSendFromDropdown(props: {
	disabled?: boolean;
	impersonationContext?: Api.IImpersonationContext;
	sendFrom: Api.ISendEmailFrom;
	sendFromUserId: string | null;
	onChange: (params: { sendFrom: Api.ISendEmailFrom; sendFromUserId: string | null }) => void;
}) {
	const [isOpen, setIsOpen] = React.useState(false);
	const [isEmployeeModalOpen, setIsEmployeeModalOpen] = React.useState(false);
	const userSession = useUserSession();
	const isUsingImpersonation = Boolean(props.impersonationContext?.account?.id);
	const userQuery = useUserQuery({
		impersonationContext: props.impersonationContext,
		enabled:
			props.sendFromUserId != null &&
			Boolean(isUsingImpersonation || userSession.user!.role?.toLowerCase().includes('admin')),
		id: props.sendFromUserId!,
	});

	const user = props.impersonationContext?.user ?? userSession.user;
	let sendFromDropdownText;
	switch (props.sendFrom) {
		case Api.SendEmailFrom.ContactOwner:
			sendFromDropdownText = 'Contact owner of the recipient';
			break;
		case Api.SendEmailFrom.CurrentUser:
			sendFromDropdownText = user != null ? getDisplayName(user) : '';
			break;
		case Api.SendEmailFrom.SelectedUser:
			sendFromDropdownText = userQuery.data ? getDisplayName(userQuery.data) : 'User ...';
			break;
		default:
			sendFromDropdownText = '';
	}
	return (
		<div className={css(bs.flex, bs.itemsCenter, bs.textSm, bs.textDarkGrayFontColor, bs.gap2)}>
			<span>Send From:</span>
			<Dropdown
				anchor={
					<div className={css(bs.truncateText, bs.itemsCenter, bs.flex, bs.cursorPointer, bs.justifyBetween)}>
						<span className={css(bs.truncateText)}>{sendFromDropdownText}</span>
						<DisclosureIcon className={css(bs.transform, bs.rotate90, bs.h4, bs.ml3, bs.w2)} />
					</div>
				}
				disabled={props.disabled}
				styles={[styleSheet.dropdownInline, bs.w60]}
				contentClassName={css(styleSheet.dropdownMenu)}
				contentPositionY='bottom'
				isOpen={isOpen}
				onOpenChanged={setIsOpen}
				openOnClick={true}
			>
				<div>
					<div
						className={css(bs.cursorPointer, bs.p2, styleSheet.dropdownItem, bs.truncateText)}
						onClick={() => {
							setIsOpen(false);
							props.onChange({ sendFrom: Api.SendEmailFrom.CurrentUser, sendFromUserId: null });
						}}
					>
						{`${userSession.user!.firstName} ${userSession.user!.lastName}`}
					</div>
					<div
						className={css(bs.cursorPointer, bs.p2, styleSheet.dropdownItem, bs.truncateText)}
						onClick={() => {
							setIsOpen(false);
							props.onChange({ sendFrom: Api.SendEmailFrom.ContactOwner, sendFromUserId: null });
						}}
					>
						Contact owner of the recipient
					</div>
					{userSession.user!.role?.toLowerCase().includes('admin') ? (
						<div
							className={css(bs.cursorPointer, bs.p2, styleSheet.dropdownItem, bs.truncateText)}
							onClick={() => {
								setIsOpen(false);
								setIsEmployeeModalOpen(true);
							}}
						>
							Select an employee
						</div>
					) : null}
				</div>
			</Dropdown>
			{isEmployeeModalOpen ? (
				<CampaignSelectEmployeeModal
					onClose={() => setIsEmployeeModalOpen(false)}
					onSubmit={userId => {
						props.onChange({ sendFrom: Api.SendEmailFrom.SelectedUser, sendFromUserId: userId });
						setIsEmployeeModalOpen(false);
					}}
				/>
			) : null}
		</div>
	);
}
