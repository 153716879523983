import { css } from 'aphrodite';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { animated, useSpring } from 'react-spring-legacy';
import { ClassifyContactViewModel, UnclassifiedContactsViewModel } from '../../../../extViewmodels';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useEventLogging } from '../../../../models/Logging';
import { Button } from '../../../components/Button';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { TaggingGameEmptyGraphic } from '../../../components/svgs/graphics/TaggingGameEmptyGraphic';
import { HatIcon } from '../../../components/svgs/icons/HatIcon';
import { bs } from '../../../styles/styles';
import { ContactCardContainer } from './ContactCardContainer';
import { styleSheet } from './styles';

const pageSize = 25;
export const ContactTaggingGamePowerUser = () => {
	const userSession = useUserSession();
	const { logApiError } = useEventLogging('TaggingGamePowerUser');
	const unclassifiedVM = React.useMemo(() => new UnclassifiedContactsViewModel(userSession), [userSession]);
	const [unclassifiedContacts, setUnclassifiedContacts] = React.useState<ClassifyContactViewModel[]>([]);
	const [loading, setLoading] = React.useState(true);
	const [allUnclassifiedFetched, setAllUnclassifiedFetched] = React.useState(false);
	const [fetchingMoreLoading, setFetchingMoreLoaded] = React.useState(false);

	React.useEffect(() => {
		unclassifiedVM
			.fetchUnclassifiedContacts([], pageSize)
			.then(() => {
				setUnclassifiedContacts(unclassifiedVM.unclassifiedContacts.toArray());
				setLoading(false);
			})
			.catch(e => {
				setLoading(false);
				logApiError('load-initial-unclassified', e);
			});
	}, [unclassifiedVM, logApiError]);

	React.useEffect(() => {
		setAllUnclassifiedFetched(unclassifiedContacts.length < pageSize);
	}, [unclassifiedContacts]);

	const fetchMore = () => {
		setFetchingMoreLoaded(true);
		unclassifiedVM
			.fetchUnclassifiedContacts(
				unclassifiedContacts.map(({ contact }) => contact.id),
				pageSize
			)
			.then(() => {
				setFetchingMoreLoaded(false);
				setUnclassifiedContacts([...unclassifiedContacts, ...unclassifiedVM.unclassifiedContacts.toArray()]);
			})
			.catch(e => {
				setFetchingMoreLoaded(false);
				logApiError('fetch-next-page', e);
			});
	};
	const springs = useSpring({
		from: {
			opacity: 0,
			height: '40%',
		},
		to: { opacity: 1, height: '90%' },
	});

	const isListEmpty = !unclassifiedContacts.length && allUnclassifiedFetched;
	const returnToTaggingGameText = 'Return to tagging game';
	if (loading) {
		return (
			<div className={css(styleSheet.container)}>
				<LoadingSpinner className={css(bs.absoluteCenter)} />
			</div>
		);
	}
	return (
		<div className={css(styleSheet.container)}>
			<GhostContainer width='73%' height='93%' />
			<GhostContainer width='70%' height='95%' />
			<animated.div style={springs} className={css(styleSheet.unclassifiedContactsContainer)}>
				<div className={css(styleSheet.backButtonContainer)}>
					<NavLink className={css(bs.brandSecondaryLink)} to='/people/tagging-game'>
						<div className={css(styleSheet.backToTaggingGameButton)}>
							<span>{returnToTaggingGameText}</span>
							<figure>
								<HatIcon />
							</figure>
						</div>
					</NavLink>
				</div>
				<div className={css(styleSheet.headerSection)}>
					<h3 className={css(styleSheet.title)}>Contacts to Tag</h3>
					{isListEmpty ? null : (
						<p className={css(bs.m0)}>
							<span>View Potential contacts based on</span>
							&nbsp;
							<span className={css(bs.emailSubjectBadge)}>Email Subject Lines.</span>
						</p>
					)}
				</div>
				{isListEmpty ? (
					<div className={css(bs.flex, bs.itemsCenter, bs.flexCol, bs.hFull, bs.justifyCenter, bs.textCenter)}>
						<div className={css(styleSheet.emptyStateTitle, bs.mb2)}>Congratulations!</div>
						<p>
							You have tagged all contacts. <br /> Check back again soon and we&apos;ll have more for you
						</p>
						<TaggingGameEmptyGraphic />
					</div>
				) : (
					<ContactCardContainer
						fetchMore={fetchMore}
						fetchingMoreLoading={fetchingMoreLoading}
						allUnclassifiedFetched={allUnclassifiedFetched}
						setUnclassifiedContacts={setUnclassifiedContacts}
						unclassifiedContacts={unclassifiedContacts}
					/>
				)}
				<div className={css(bs.flex, bs.justifyCenter, bs.mb8, styleSheet.ctaButton)}>
					<NavLink className={css(bs.wFull, bs.noUnderline)} to='/people/tagging-game'>
						<Button className={css(bs.wFull)} label={isListEmpty ? returnToTaggingGameText : 'Done'} kind='primary' />
					</NavLink>
				</div>
			</animated.div>
		</div>
	);
};

const GhostContainer = ({ height, width }: { height: string; width: string }) => {
	const springs = useSpring({
		from: { opacity: 0, height: '40%' },
		to: {
			opacity: 1,
			height,
		},
	});

	return <animated.div style={{ ...springs, width }} className={css(styleSheet.ghostContainer)} />;
};
