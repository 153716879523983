import { ICampaignApprovalCommand } from '../../../../../models';
import { useEventLogging } from '../../../../../models/Logging';
import * as Api from '@ViewModels';
import * as React from 'react';

export function useLaunchCampaignApproval({
	queryCommand,
	eventLoggingCategory,
	launchCampaignApproval,
}: {
	launchCampaignApproval: (campaignId: string) => void;
	queryCommand: Api.IQueryStringCommand;
	eventLoggingCategory: string;
}) {
	const { logEvent } = useEventLogging(eventLoggingCategory);
	React.useEffect(() => {
		const isCommandCampaignApproval = queryCommand?.command === 'campaign-approval';
		if (isCommandCampaignApproval) {
			const campaignApprovalCommand = queryCommand as ICampaignApprovalCommand;
			if (campaignApprovalCommand?.id) {
				launchCampaignApproval(campaignApprovalCommand.id);
			}
			logEvent('AppLink-CampaignApproval');
		}
	}, [queryCommand, logEvent, launchCampaignApproval]);
}
