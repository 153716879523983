import { StyleSheet } from 'aphrodite';
import { header, nameCircles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

const avatarSize = '40px';

export const styleSheet = StyleSheet.create({
	avatar: {
		height: avatarSize,
		width: avatarSize,
	},
	avatarContainer: {
		maxHeight: avatarSize,
		maxWidth: avatarSize,
		minHeight: avatarSize,
		minWidth: avatarSize,
	},
	container: {
		':hover': {
			background: `${nameCircles}66`,
			cursor: 'pointer',
		},
		alignItems: 'center',
		display: 'flex',
		padding: 5,
	},
	contentContainer: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		fontSize: '14px',
		height: 40,
		justifyContent: 'center',
		paddingLeft: 10,
		...CssSelectors.allChildren(
			{
				margin: 0,
			},
			'p'
		),
	},
	phoneNumber: {
		color: header,
		fontSize: 12,
	},
});
