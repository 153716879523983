import { CssSelectors } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	bodyText: {
		color: '#4a4a4a',
		fontSize: 14,
		lineHeight: '22px',
	},
	container: {},
	description: {
		marginBottom: 30,
	},
	headerLogo: {
		margin: '30px 0',
	},
	logo: {
		...CssSelectors.allChildren({
			display: 'block',
			width: 160,
		}),
	},
	signInContainer: {
		...CssSelectors.allChildren(
			{
				margin: '0 auto',
			},
			':first-child'
		),
		boxShadow: 'rgba(139, 139, 139, 0.4) 0px 0px 6px 0px',
		boxSizing: 'border-box',
		minHeight: 300,
		padding: '30px 50px',
		position: 'relative',
		width: 420,
	},
});
