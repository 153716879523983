import { StyleSheet } from 'aphrodite';
import {
	bulletMarginRight,
	bulletSize,
	editButtonMarginRight,
	editButtonSize,
} from '../../../components/entities/EntityInfoBulletList/styles';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	dataOriginContainer: {
		width: `calc(100% - ${bulletSize}px - ${bulletMarginRight}px - ${editButtonSize}px - ${editButtonMarginRight}px)`,
	},
	pluginEnv: {
		maxWidth: 300,
	},
	factText: {
		display: 'flex',
		flexWrap: 'wrap',
		overflowWrap: 'break-word',
		width: '100%',
		wordBreak: 'break-word',
		wordWrap: 'break-word',
		...CssSelectors.allChildren(
			{
				marginRight: 4,
			},
			':first-child'
		),
	},
});
