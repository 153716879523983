import { useEventLogging } from '../../../../../models/Logging';
import {
	invalidateCompanyTimeline,
	invalidateContactTimeline,
	useInfiniteCompanyTimeline,
	useInfiniteContactTimeline,
} from '../../../../../queries';
import * as Api from '@ViewModels';
import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query';
import * as React from 'react';

function isSameDay(d1: Date, d2: Date) {
	return d1.getDate() === d2.getDate() && d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth();
}

export type TimelinePaginationData = Pick<
	UseInfiniteQueryResult<Api.IPagedCollection<Api.TimelineEventViewModel<Api.ITimelineEvent> | Date>, unknown>,
	'data' | 'fetchNextPage' | 'hasNextPage' | 'isFetchingNextPage' | 'isLoading' | 'status'
> & {
	invalidateTimeline: () => void;
};

const useTimelineData = ({
	invalidateTimeline,
	timelinePageData,
}: {
	invalidateTimeline: () => void;
	timelinePageData: UseInfiniteQueryResult<
		Api.IPagedCollection<Api.TimelineEventViewModel<Api.ITimelineEvent>>,
		unknown
	>;
}) => {
	const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, status } = timelinePageData;

	// @ts-ignore
	const dataWithDate: InfiniteData<Api.IPagedCollection<Api.TimelineEventViewModel<Api.ITimelineEvent> | Date>> =
		React.useMemo(() => {
			if (data) {
				// @ts-ignore
				let prevTime: Date = null;
				const newPages: Api.IPagedCollection<Api.TimelineEventViewModel<Api.ITimelineEvent> | Date>[] = [];
				for (const page of data.pages) {
					const values: (Date | Api.TimelineEventViewModel)[] = [];
					for (const value of page.values) {
						// @ts-ignore
						const sameDay = isSameDay(new Date(value.timestamp), new Date(prevTime));
						if (!sameDay || prevTime == null) {
							// @ts-ignore
							values.push(new Date(value.timestamp));
						}
						values.push(value);
						// @ts-ignore
						prevTime = new Date(value.timestamp);
					}
					newPages.push({
						...page,
						values,
					});
				}
				return {
					...data,
					pages: newPages,
				};
			}
			return data;
		}, [data]);
	return {
		data: dataWithDate,
		fetchNextPage,
		hasNextPage,
		invalidateTimeline,
		isFetchingNextPage,
		isLoading,
		status,
	};
};

export const useContactTimelineData = ({
	contactId,
	eventTypes,
}: {
	contactId: string;
	eventTypes: Api.TimelineEventTypes[];
}): TimelinePaginationData => {
	const { logApiError } = useEventLogging('ContactTimeline');
	const timelinePageData = useInfiniteContactTimeline({
		contactId,
		eventTypes,
		onError: error => logApiError('ContentTimeline-Error', error),
	});
	return useTimelineData({
		invalidateTimeline: () => invalidateContactTimeline({ contactId, eventTypes }),
		// @ts-ignore
		timelinePageData,
	});
};

export const useCompanyTimelineData = ({
	companyId,
	eventTypes,
}: {
	companyId: string;
	eventTypes: Api.TimelineEventTypes[];
}): TimelinePaginationData => {
	const { logApiError } = useEventLogging('CompanyTimeline');
	const timelinePageData = useInfiniteCompanyTimeline({
		companyId,
		eventTypes,
		onError: error => logApiError('ContentTimeline-Error', error),
	});
	return useTimelineData({
		invalidateTimeline: () => invalidateCompanyTimeline({ companyId, eventTypes }),
		// @ts-ignore
		timelinePageData,
	});
};
