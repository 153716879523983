import { header, titles } from '../../../../styles/colors';
import { CssSelectors } from '../../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	body: {
		alignItems: 'center',
		display: 'flex',
		marginBottom: 60,
		...CssSelectors.allChildren(
			{
				marginLeft: 20,
			},
			':not(:first-child)'
		),
	},
	checkbox: {
		color: titles,
		fontSize: 14,
	},
	checkboxes: {},
	container: {},
	emailCheckbox: {
		marginBottom: 10,
	},
	footer: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	placeholderIcon: {
		height: 115,
		width: 115,
	},
	pushCheckboxContainer: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	pushCheckboxDeniedHelp: {
		color: titles,
		fontSize: 12,
	},
	title: {
		color: header,
		fontSize: 14,
		lineHeight: '24px',
		marginBottom: 20,
	},
});
