import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { Modal } from '../../components/Modal';
import { SimpleAutoCompleteSearchField } from '../../components/autocomplete/SimpleAutoCompleteSearchField';
import { bs } from '../../styles/styles';
import { styleSheet } from './styles';

export function CampaignSelectEmployeeModal({
	onClose,
	onSubmit,
}: {
	onClose: () => void;
	onSubmit: (userId: string) => void;
}) {
	const [userId, setUserId] = React.useState<string | null>(null);
	return (
		<Modal isOpen={true} onRequestClose={onClose} useDefaultHeader={true}>
			<div className={css(bs.maxWSm)}>
				<div className={css(bs.textBrandSecondary, bs.textXl, bs.fontBold, bs.truncateText)}>Select an Employee</div>
				<div className={css(bs.textLabel, bs.textSm, bs.mt2)}>
					Since you are the admin of this Levitate account, you can select another employee so this email will come from
					their email instead. They must be a Levitate user.
				</div>
				<SimpleAutoCompleteSearchField
					onClear={() => setUserId(null)}
					onItemSelected={ev => {
						const user = ev.selection as Api.IUser;
						if (ev.target) {
							ev.target.setSearchQuery(Api.VmUtils.getDisplayName(user));
						}
						setUserId(user.id!);
					}}
					onKeyDown={ev => {
						if (ev.sourceEvent && ev.sourceEvent.keyCode !== 13) {
							setUserId(null);
						}
					}}
					placeholder='Search employee'
					style={styleSheet.searchEmployeeBox}
					type={Api.ResourceAutoCompleteViewModelType.User}
					onCreateAutoCompleteViewModel={(_, vm) => {
						vm.addParam({ includeDeactivated: false });
						return vm;
					}}
				/>
				<button
					className={css(bs.ctaButton, bs.mt24)}
					style={{ marginBottom: 5 }}
					onClick={() => {
						onSubmit(userId!);
					}}
					disabled={!userId}
				>
					<span>Save</span>
				</button>
			</div>
		</Modal>
	);
}
