import { borderColor, brandPrimaryHover, errorLight, header, tagBackgroundColor } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	anchorContainer: {
		display: 'flex',
		justifyContent: 'flex-start',
		maxWidth: '100%',
	},
	autocompleteContainer: {
		background: 'white',
		border: `1px solid ${borderColor}`,
		borderRadius: 3,
		marginLeft: 1,
		marginTop: 1,
		maxWidth: 640,
		padding: '20px 0',
		width: 273,
	},
	autoCompleteResultItem: {
		boxSizing: 'border-box',
		maxWidth: '100%',
		paddingRight: 5,
		whiteSpace: 'nowrap',
		...CssSelectors.allChildren({
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		}),
		...CssSelectors.allChildren(
			{
				maxWidth: 125,
			},
			'.autocomplete-result-item'
		),
	},
	cancelButton: {
		marginLeft: 10,
	},
	container: {
		display: 'flex',
	},
	footer: {
		color: header,
		fontSize: '14px',
		fontStyle: 'italic',
		textAlign: 'center',
	},
	inputContainer: {
		background: 'white',
		border: '1px solid #CCC',
		borderRadius: 3,
		display: 'flex',
		maxWidth: '100%',
		minHeight: 40,
		padding: '0 14px',
		position: 'relative',
		...CssSelectors.allChildren(
			{
				'::placeholder': {
					color: header,
					fontSize: 16,
				},
			},
			'input'
		),
	},
	inputWithRecipients: {
		flexWrap: 'wrap',
		padding: '0 5px',
		...CssSelectors.allChildren(
			{
				margin: 3,
			},
			'input'
		),
	},
	leftText: {
		display: 'flex',
		padding: '8px 10px 0 0',
	},
	needsResolved: {
		background: errorLight,
	},
	recentSearch: {
		width: '100%',
	},
	recipientPill: {
		background: tagBackgroundColor,
		borderRadius: 30,
		fontSize: 12,
		margin: '5px 3px',
		padding: '5px 10px',
		...CssSelectors.allChildren(
			{
				marginLeft: 5,
				...CssSelectors.allChildren(
					{
						height: 8,
						width: 8,
					},
					'svg'
				),
			},
			'button'
		),
	},
	selectedRecipients: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
	},
	sendTextToUnknownNumberCta: {
		margin: '0 auto',
		textAlign: 'center',
	},
	title: {
		color: brandPrimaryHover,
		fontSize: 12,
		letterSpacing: 1.3,
		margin: '8px 30px',
		textTransform: 'uppercase',
	},
});
