import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import equal from 'fast-deep-equal';
import { inject, observer } from 'mobx-react';
import { parse as getQueryStringParams } from 'query-string';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ILocationState } from '../../../../models';
import {
	FullScreenModalViewModelKey,
	IFullscreenModalComponentProps,
	IQuickAddEntityComponentProps,
	IUserSessionComponentProps,
	QuickAddEntityViewModelKey,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { useEventLogging } from '../../../../models/Logging';
import { PersistentStorageManager } from '../../../../models/Storage';
import { queryStringParamsToSearchRequest, searchRequestToQueryStringParams } from '../../../../models/UiUtils';
import { useErrorMessages, useUserSession } from '../../../../models/hooks/appStateHooks';
import { SavedSearchesViewModel } from '../../../../viewmodels/AppViewModels';
import { CompoundButton, CompoundButtonType } from '../../../components/CompoundButton';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { TaggingGameButton } from '../../../components/TaggingGameButton';
import { baseStyleSheet, bs } from '../../../styles/styles';
import { OwnershipFilters } from './OwnershipFilters';
import { PeopleActionsBar } from './PeopleActionsBar';
import {
	ContactsPagePeopleSearchTab,
	DefaultPeopleFilter,
	DefaultPeopleSort,
	getSearchFromCriteria,
	getTabFromCriteria,
} from './PeopleContext';
import { PeopleGlobalSearch } from './PeopleGlobalSearch';
import { PeopleSearchBarTypeTabs } from './PeopleSearchBarTypeTabs';
import { PeopleSearchDescription } from './PeopleSearchDescription';
import { PeopleTable } from './PeopleTable';
import { PopularTags } from './PopularTags';
import { SaveSearch } from './SaveSearch';
import { SavedSearches } from './SavedSearches';
import { StatusFilterCheckboxes } from './StatusFilters';
import { useIsDuplicateGroupingToggled } from './hooks/useIsDuplicateGroupingToggled';
import { styleSheet } from './styles';

interface IProps extends IQuickAddEntityComponentProps, IFullscreenModalComponentProps, IUserSessionComponentProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	onContactsLoad?(filter: Api.IBulkContactsRequest, contacts: Api.ContactViewModel[]): void;
}

const PeopleSearchStorageKey = 'PeopleSearchStorageKey';

export const PeopleContainer: React.FC<IProps> = inject(
	QuickAddEntityViewModelKey,
	FullScreenModalViewModelKey,
	UserSessionViewModelKey
)(
	observer(props => {
		const { className, styles, quickAddEntity, fullscreenModal, onContactsLoad } = props;
		const history = useHistory();
		const location = useLocation();
		const errorMessages = useErrorMessages();
		const { logApiError } = useEventLogging();
		const userSession = useUserSession();
		const [searches] = React.useState(new SavedSearchesViewModel(userSession));
		// get search from
		const params = getQueryStringParams(location?.search);
		const storedContactsQueryStringValue = PersistentStorageManager.local.get(PeopleSearchStorageKey);
		const storedContactsQuery: {
			searchRequest: Api.IContactFilterCriteria;
			sortDescriptor: Api.ISortDecriptor;
		} = storedContactsQueryStringValue ? JSON.parse(storedContactsQueryStringValue) : null;
		const contactsQuery = queryStringParamsToSearchRequest<Api.IContactsFilterRequest>(params);
		const queryFilter =
			contactsQuery?.searchRequest || storedContactsQuery?.searchRequest || DefaultPeopleFilter.filter;
		const querySort = contactsQuery?.sortDescriptor || storedContactsQuery?.sortDescriptor || DefaultPeopleSort;
		// ContactsViewModel
		const [contacts] = React.useState(new Api.ContactsViewModel(userSession));
		// Filter Context
		const initialBulkContactsFilter: Api.IBulkContactsRequest = {
			filter: queryFilter,
			includeContactIds: [], // Used for narrowing down results to duplicate contacts of a specific contact
		};
		const [filter, setFilter] = React.useState<Api.IBulkContactsRequest>(initialBulkContactsFilter);
		const isDuplicateGroupingToggled = useIsDuplicateGroupingToggled({ request: filter });
		const [sort, setSort] = React.useState<Api.ISortDecriptor>(querySort);
		const load = ({
			newFilter = filter,
			newSort = sort,
		}: {
			newFilter?: Api.IBulkContactsRequest;
			newSort?: Api.ISortDecriptor;
		} = {}) => {
			setFilter({
				...filter,
				includeContactIds: [],
			});
			const notEmptyCriteria = newFilter.filter.criteria.filter(x => x.criteria?.length || x.property >= 0);
			const searchFilter: Api.IContactFilterCriteria = {
				...newFilter.filter,
				criteria: notEmptyCriteria,
			};
			const query = searchRequestToQueryStringParams<Api.IContactsFilterRequest>(searchFilter, newSort);
			if (
				(!equal(query?.search, params?.search) || !equal(query?.sort, params?.sort)) &&
				query &&
				query.search &&
				query.sort
			) {
				history.push(`/people/?search=${query.search}&sort=${query.sort}`);
				return;
			}
		};
		// Search Input Context
		const [search, setSearch] = React.useState(() => getSearchFromCriteria(queryFilter.criteria));
		const [tab, setTab] = React.useState<ContactsPagePeopleSearchTab>(getTabFromCriteria(queryFilter.criteria));
		// Push first filter into query on mount
		React.useEffect(() => {
			load({ newFilter: filter });
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);
		React.useEffect(() => {
			setFilter(initialBulkContactsFilter);
			setSort(querySort);
			setSearch(getSearchFromCriteria(queryFilter.criteria));
			setTab(getTabFromCriteria(queryFilter.criteria));
			if (!location?.pathname.includes('companies')) {
				PersistentStorageManager.local.setObject(PeopleSearchStorageKey, contactsQuery);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [location]);
		React.useEffect(() => {
			contacts.selectedContacts.clear();
			contacts
				.getContacts({ ...filter, groupByDuplicate: isDuplicateGroupingToggled }, sort, 25, {
					expand: isDuplicateGroupingToggled ? 'DuplicateContacts' : undefined,
				})
				?.then(() => {
					onContactsLoad(filter, contacts.fetchResults.toArray());
				})
				?.catch((err: Api.IOperationResultNoValue) => {
					errorMessages.pushApiError(err);
					logApiError('LoadContacts-Error', err);
				});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [filter, sort]);
		const onShowQuickAddContactModal = () => {
			quickAddEntity.show({
				entity: {},
				onComplete: (error: Api.IOperationResultNoValue, contactModel?: Api.IContact) => {
					if (!error && !!contactModel) {
						quickAddEntity.reset();
						const locationState: ILocationState<Api.ContactViewModel, Api.IContact> = {
							viewModel: new Api.ContactViewModel(userSession, contactModel),
						};
						const contactLink = {
							pathname: `/people/${contactModel.id}`,
							state: locationState,
						};
						if (fullscreenModal) {
							fullscreenModal.history.push(contactLink);
						} else {
							history.push(contactLink);
						}
					}
				},
				type: 'contact',
			});
		};
		const onShowContactsSelfImport = () => fullscreenModal.history.push(`/people/contacts-self-import/upload`);
		const handleFilterChange = (newFilter: Api.IBulkContactsRequest) => {
			load({ newFilter });
		};

		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
				<div
					className={css(
						bs.flex,
						bs.bgWhite,
						bs.h24,
						bs.overflowVisible,
						bs.pr4,
						bs.boxBorder,
						styleSheet.searchBarContainer
					)}
				>
					<div className={css(bs.flex, bs.justifyCenter, styleSheet.taggingGameContainer)}>
						<TaggingGameButton />
					</div>
					<div className={css(bs.flex, bs.pt5, bs.flex1, bs.gap2)}>
						<div className={css(bs.flex1)}>
							<PeopleGlobalSearch
								search={search}
								onSearchChange={setSearch}
								searchTab={tab}
								filter={filter}
								onFilterChange={handleFilterChange}
							/>
							<PeopleSearchBarTypeTabs
								search={search}
								searchTab={tab}
								filter={filter}
								onFilterChange={handleFilterChange}
								onClear={() => {
									setSearch('');
									load({ newFilter: DefaultPeopleFilter, newSort: DefaultPeopleSort });
								}}
							/>
						</div>
						<CompoundButton
							buttonTitle={<span>New Contact</span>}
							styleDeclaration={styleSheet.addContactButton}
							onClick={onShowQuickAddContactModal}
							openDirection='down'
							kind={CompoundButtonType.CtaPrimary}
						>
							<button
								className={css(baseStyleSheet.ctaButtonReverse, styleSheet.importButton)}
								onClick={onShowContactsSelfImport}
							>
								<span>Import from File</span>
							</button>
						</CompoundButton>
					</div>
				</div>
				<div className={css(styleSheet.main)}>
					<div className={css(styleSheet.left)}>
						<div className={css(styleSheet.sectionTitle)}>Popular Tags</div>
						<PopularTags filter={filter} onChangeFilter={handleFilterChange} />
						<div className={css(styleSheet.sectionTitle)}>Saved Searches</div>
						<SavedSearches savedSearchesVm={searches} filter={filter} onChangeFilter={handleFilterChange} />
						<div className={css(styleSheet.sectionTitle)}>Ownership Filters</div>
						<OwnershipFilters filter={filter} onChangeFilter={handleFilterChange} />
						<div className={css(styleSheet.sectionTitle)}>Status Filters</div>
						<StatusFilterCheckboxes
							filter={filter}
							onChangeFilter={handleFilterChange}
							styles={[styleSheet.statusFilters]}
						/>
					</div>

					<div className={css(styleSheet.right)}>
						<PeopleActionsBar
							contactsVM={contacts}
							sort={sort}
							filter={filter}
							onReload={load}
							onChangeFilter={handleFilterChange}
						/>
						<div className={css(styleSheet.rightCard)}>
							<div
								className={css(bs.flex, bs.itemsCenter, bs.justifyBetween, bs.py0, bs.px7)}
								style={{ minHeight: 52 }}
							>
								<PeopleSearchDescription filter={filter} onChangeFilter={handleFilterChange} contactsVM={contacts} />
								<SaveSearch savedSearchesVm={searches} filter={filter} />
							</div>
							{contacts.isFetchingResults && !contacts.fetchResults.length ? (
								<div className={css(styleSheet.loader)}>
									<LoadingSpinner type='large' />
								</div>
							) : (
								<PeopleTable load={load} filter={filter} sort={sort} contactsVM={contacts} onSearchChange={setSearch} />
							)}
						</div>
					</div>
				</div>
			</div>
		);
	})
);
