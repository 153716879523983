import { SvgIcon } from '../../icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const EmailCancelledGraphic: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon className={`email-cancelled-graphic ${className || ''}`} height={259} width={360}>
			<path
				d='M148.5 256c61.58 0 111.5-49.92 111.5-111.5S210.08 33 148.5 33 37 82.92 37 144.5 86.92 256 148.5 256z'
				fill='#F2F2F2'
			/>
			<path
				d='M297.261 62.33L285.75 76.261c-.621 6-1.502 12.705-2.75 19.739l18.911-19.972.089.35c-1.076-5.368-2.704-10.237-4.845-14.378l.106.33zM286 75l11-13.173c-2.401-4.508-5.441-8.11-9.075-10.473a20.934 20.934 0 00-.569-.354c.002 1.601-.044 11-1.356 24zm15.266 42.991l.025.401A101.345 101.345 0 00303 111l-28.329 17.964-.019-.183A144.801 144.801 0 01273 133l28.266-15.009zM303.85 96.6l.055.382A86.186 86.186 0 00302.513 77l-19.275 20.269c-1.2 6.56-2.613 12.804-4.238 18.731l24.85-19.4zM254.364 63A70.995 70.995 0 00247 75.16L278.787 112A222.872 222.872 0 00282 97.15l-27.804-33.785.168-.365z'
				fill='#89C947'
			/>
			<path
				d='M245 79.688l.163-.368L277.986 116c.353-1.256.691-2.505 1.014-3.748l-32.081-36.885.165-.367A86.785 86.785 0 00245 79.688zm57.41 30.477l.039.393A102.038 102.038 0 00304 97l-25.378 19.644A174.339 174.339 0 01275 128l27.41-17.835zm-36.662 38.012c-4.353 7.451-9.285 13.751-14.748 18.823 6.042-2.397 30.384-12.957 43-32l-28.252 13.177zM240.581 98a99.196 99.196 0 00-.553 13.057l.198-.39L265.739 147A122.07 122.07 0 00272 133.915L240.581 98zm44.847-23c1.409-14.282 1.334-24.235 1.333-24.377l.239-.1c-5.658-3.38-11.91-3.262-18-.259L285.428 75z'
				fill='#89C947'
			/>
			<path
				d='M272.215 134.033c-.174.423-.346.847-.522 1.264A119.743 119.743 0 01266 147l27.53-13.065.014.391a47.428 47.428 0 005.122-10.815A98.31 98.31 0 00300 119l-27.785 15.033z'
				fill='#89C947'
			/>
			<path
				d='M282.313 96A272.22 272.22 0 00285 76.654l-16.954-25.308.178-.346c-4.617 2.332-9.133 6.293-13.224 11.62L282.313 96z'
				fill='#6C63FF'
			/>
			<path
				d='M266 147.537L240 112c.627 25.76 9.654 53.252 10.236 55 6.244-5.448 11.44-12.146 15.764-19.463zM272.2 133l.027-.014a161.465 161.465 0 005.773-16.54L244.768 80a95.634 95.634 0 00-2.502 6.796A57.71 57.71 0 00240 97.159L272.2 133zm51.675 36.324l-17.489 3.24A266.693 266.693 0 01292 186l26.921-3.075-.149.327c2.513-4.842 4.294-9.661 5.228-14.252l-.125.324zM307 172l17.296-3.127c1.027-5.129.957-9.966-.388-14.219-.07-.221-.145-.438-.222-.654-1.039 1.262-7.19 8.631-16.686 18zm-15.813 42.663l-.24.337a103.432 103.432 0 006.053-4.79l-33.133-4.052.103-.158a142.888 142.888 0 01-3.97 2.294l31.187 6.369zm16.432-14.987l-.198.324A83.36 83.36 0 00319 184l-27.835 3.059c-5.09 4.198-10.145 8.021-15.165 11.469l31.619 1.148zM291 142a66.817 66.817 0 00-13 4.549L279.307 195c4.155-3.1 8.024-6.243 11.53-9.26l-.187-43.569.35-.171zm-19 8.253l.387-.178L274.6 199c1.15-.734 2.284-1.473 3.4-2.216l-1.456-48.609.387-.175a95.834 95.834 0 00-4.931 2.253zm25.433 58.439l-.219.308a98.313 98.313 0 009.786-8.887L274.987 199a173.33 173.33 0 01-9.987 6.039l32.433 3.653zM244.184 216c-8.052 2.887-15.808 4.574-23.184 5.035 6.108 1.866 31.291 8.717 53 2.235L244.184 216z'
				fill='#89C947'
			/>
			<path
				d='M256.372 160a97.064 97.064 0 00-8.602 9.693l.397-.172L245 214a118.148 118.148 0 0013-6.021L256.372 160zM306 172c10.313-10.127 16.653-17.882 16.744-17.993l.256.081c-2.353-6.353-7.43-10.391-14.233-12.088L306 172zm-46.959 37c-.402.217-.805.437-1.207.648A118.727 118.727 0 01246 215.09l29.509 7.597-.239.313a47.085 47.085 0 0010.831-5.119 97.798 97.798 0 003.899-2.653L259.041 209z'
				fill='#89C947'
			/>
			<path
				d='M291.194 185a267.768 267.768 0 0014.604-12.954l2.837-30.079.365-.147c-5.172-1.193-11.314-1.086-18 .332l.194 42.848zm-46.419 29.776L248 171c-16.31 20.345-27.307 47.282-28 49 8.339-.318 16.69-2.258 24.775-5.224zm13.917-6.782l.03.006A167.097 167.097 0 00274 198.92L271.9 150a99.188 99.188 0 00-6.379 3.649 59.356 59.356 0 00-8.521 6.539l1.692 47.806zm-27.176-97.719L225 126.788A272.233 272.233 0 01228.255 146l11.561-24.417.184.297c-2.565-4.661-5.492-8.688-8.678-11.88l.194.275zM224.305 126L231 109.521c-3.814-3.652-7.972-6.208-12.323-7.357-.226-.06-.452-.112-.677-.164.513 1.571 3.462 10.814 6.305 24zm27.547 36.167l.148.375a101.648 101.648 0 00-.692-7.542l-20.993 25.659-.075-.169A147.268 147.268 0 01230 185l21.852-22.833zm-3.025-21.142l.173.342A83.13 83.13 0 00241.436 123L229 148.06c.873 6.545 1.44 12.858 1.703 18.94l18.124-25.975zM190.345 124A67.903 67.903 0 00187 137.541L229 162a210.558 210.558 0 00-1.485-14.768l-37.218-22.842.048-.39zM187 143.217l.042-.41L230 168c-.05-1.332-.113-2.653-.188-3.963l-42.308-25.628.045-.409a89.697 89.697 0 00-.549 5.217z'
				fill='#89C947'
			/>
			<path
				d='M250.841 153.842l.159.357A98.55 98.55 0 00248.29 141L230 167.231c.151 4.042.164 7.965.039 11.769l20.802-25.158zm-22.951 48.519c-1.749 8.505-4.387 16.08-7.89 22.639 4.857-4.163 24.15-21.805 30-44l-22.11 21.361zM188 163a95.18 95.18 0 003.465 12.362l.07-.426L227.021 201A118.32 118.32 0 00229 186.854L188 163zm36-37c-3.067-13.909-6.252-23.267-6.298-23.402l.206-.17c-6.659-1.399-12.815.685-17.908 5.438L224 126zm5.921 60.418c-.035.466-.068.934-.107 1.395A124.239 124.239 0 01228 201l22.322-21.422.135.375A49.218 49.218 0 00252 167.81a101.14 101.14 0 00-.126-4.81l-21.953 23.418z'
				fill='#89C947'
			/>
			<path
				d='M228 146a261.631 261.631 0 00-3.385-19.071l-24.392-18.547.067-.382c-3.779 3.641-6.954 8.787-9.29 15.087L228 146zm-1 55.376L192 175c8.524 24.693 25.35 48.489 26.428 50 4.092-7.173 6.831-15.233 8.572-23.624zM229.559 185l.021-.022c.42-5.818.525-11.654.315-17.484L187.253 143a95.934 95.934 0 00-.253 7.224 57.95 57.95 0 001.077 10.542L229.559 185z'
				fill='#89C947'
			/>
			<path
				d='M180 259c99.411 0 180-4.701 180-10.5S279.411 238 180 238 0 242.701 0 248.5 80.589 259 180 259z'
				fill='#CAE0EC'
			/>
			<path
				opacity='.1'
				d='M243 234.931c0 20.478-28.192 14.144-62.709 14.144-34.518 0-62.291 6.334-62.291-14.144C118 214.454 146.188 210 180.706 210c34.517 0 62.294 4.454 62.294 24.931z'
				fill='#000'
			/>
			<path
				d='M243 229.931c0 20.478-28.192 14.144-62.709 14.144-34.518 0-62.291 6.334-62.291-14.144C118 209.454 146.188 205 180.706 205c34.517 0 62.294 4.454 62.294 24.931z'
				fill='#CAE0EC'
			/>
			<path
				d='M249 145.467s0 34.101 1.848 41.65A22679.1 22679.1 0 01259.294 222l8.975-1.822s-1.584-36.184-3.167-41.391c-1.584-5.206 1.847-26.552 6.335-31.238 4.487-4.686-2.112-7.549-2.112-7.549L249 145.467z'
				fill='#2F2E41'
			/>
			<path
				opacity='.1'
				d='M249 145.467s0 34.101 1.848 41.65A22679.1 22679.1 0 01259.294 222l8.975-1.822s-1.584-36.184-3.167-41.391c-1.584-5.206 1.847-26.552 6.335-31.238 4.487-4.686-2.112-7.549-2.112-7.549L249 145.467z'
				fill='#000'
			/>
			<path
				d='M271.016 216s-11.881 4.666-12.673 4.666c-.793 0-1.321 13.481-1.321 13.481s.792 8.037-12.145 10.63c-12.937 2.592 10.297 8.296 14.522 5.444 4.224-2.852 6.072-4.926 6.072-4.926l1.584-2.592-.528 4.407 1.849.259 4.224-8.296s1.056-6.222-.264-7.777c-1.32-1.556-1.32-15.296-1.32-15.296z'
				fill='#2F2E41'
			/>
			<path d='M212 167l1.75 10 8.25-.769-1.5-7.18L212 167z' fill='#A0616A' />
			<path
				d='M254.03 124.133l-1.556 2.598s-44.867-6.236-44.867 10.392-2.074 26.501-2.593 27.28c-.519.779 13.486 9.093 16.598 8.574 0 0 2.075-2.858 1.815-6.496-.259-3.637 2.075-17.147 0-17.926 0 0 2.594-1.04 2.335-2.598 0 0 36.567 5.196 41.235 4.156 4.668-1.039 7.781-4.416 8.559-6.495.778-2.078 2.444-10.186 2.444-10.186l-.37-13.716-4.149-4.937-6.224-.779-9.596 5.976-3.631 4.157z'
				fill='#2F2E41'
			/>
			<path d='M282.338 149s-3.303 8 .471 8c3.775 0 5.191-7 5.191-7l-5.662-1z' fill='#A0616A' />
			<path
				d='M254.256 82l-1.276-1.564s-14.286-32.835-8.674-41.695c5.613-8.86 30.103-10.163 30.868-9.642.765.521 3.061 2.345 2.806 2.866-.255.522-5.357 1.825-3.571 4.952 0 0-2.551.782-3.061-.26-.511-1.043-2.041-1.304-2.551-.261-.511 1.042-8.674 3.387-8.674 4.17 0 .78-1.276 4.69-2.551 5.211-1.276.521.255 8.34 2.806 10.424 2.551 2.085 4.337 7.818 4.337 7.818l-1.276 11.727L254.256 82z'
				fill='#D0CDE1'
			/>
			<path
				d='M261.429 37.362c-1.545.793-3.081 1.806-4.012 3.403-1.906 3.268-.689 7.738-1.87 11.413-.503 1.568-1.42 2.915-2.177 4.347-.757 1.433-1.375 3.07-1.177 4.722.195 1.62 1.139 2.994 1.585 4.55.768 2.677-.001 5.58-.868 8.219-.475 1.445-.98 2.876-1.515 4.295-.31.823-.683 1.705-1.395 2.101l6.11.506c1.776.147 3.739.234 5.135-1.01a5.984 5.984 0 001.337-1.928c1.379-2.907 1.602-6.334 1.443-9.624-.158-3.29-.668-6.558-.64-9.852-.03-1.082.115-2.161.428-3.196a18.03 18.03 0 011.148-2.344c1.392-2.71 1.968-5.838 2.523-8.905.381-2.103.988-5.15-.101-7.125-1.086-1.97-4.485-.327-5.954.428z'
				fill='#2F2E41'
			/>
			<path
				d='M267.5 60c5.799 0 10.5-4.701 10.5-10.5S273.299 39 267.5 39 257 43.701 257 49.5 261.701 60 267.5 60z'
				fill='#A0616A'
			/>
			<path
				d='M263 58.92s1.711 6.772.658 10.224C262.605 72.595 278 64.497 278 64.497s-4.737-6.373-4.737-8.497L263 58.92z'
				fill='#A0616A'
			/>
			<path
				d='M282.899 69.077s-3.367-2.606-3.367-3.908c0-1.303-2.331-4.169-2.331-4.169s-11.137 5.732-11.655 5.732c-.518 0-.777-3.387-.777-3.387l-11.915 16.414s-3.885 9.38-2.59 16.675 3.367 14.85 2.331 21.624c-1.036 6.775.259 9.119 1.813 8.338 1.554-.782 0-3.387 3.367-3.127 3.367.261 14.764-3.387 15.541-4.95.777-1.563 3.885 3.387 4.144 6.253.259 2.866-.518 10.682 0 10.422.518-.261 1.295-4.69 1.554-6.774.259-2.085 1.036-8.859 0-9.901-1.036-1.042-.259-2.605.518-3.126.777-.522 13.468-32.829 13.468-32.829s-1.295-6.774-5.698-9.119c-4.403-2.344-4.403-4.168-4.403-4.168z'
				fill='#D0CDE1'
			/>
			<path
				d='M288.64 78l4.169 4.432v23.464s5.472 28.158 2.085 29.722c-3.387 1.564-4.951 1.043-4.951 1.043S290.725 151 288.38 151c-2.345 0-5.993-1.043-8.077-.521 0 0 .521-5.215 2.084-7.561 1.563-2.347 1.563-6.518 1.563-6.518s-2.344 0-2.344-3.389c0-3.39-.261-5.475-.522-6.257-.26-.783-2.084-14.6-2.084-14.6L288.64 78z'
				fill='#D0CDE1'
			/>
			<path
				d='M264.847 37.04c1.807-1.147 4.204-.279 6.303-.701 2.282-.46 4.184-2.447 6.51-2.334 1.934.094 3.461 1.617 4.714 3.092a21.837 21.837 0 013.004 4.295c.857 1.72 1.324 3.602 1.785 5.467l1.544 6.256c.523 2.12 1.053 4.26 2.004 6.226.951 1.968 2.367 3.776 4.289 4.818a12.637 12.637 0 01-7.215.023c.905 2.36 2.4 4.448 4.344 6.066-1.742.53-3.619-.005-5.436.11a7.716 7.716 0 00-6.251 4.092c-.949 1.848-1.095 3.99-1.662 5.989-1.692 5.965-7.581 10.475-13.789 10.561 1.741-2.505 3.568-5.413 2.894-8.387-.407-1.798-1.691-3.307-2.072-5.11-.407-1.928.266-3.894.723-5.81.457-1.917.647-4.117-.543-5.688-1.261-1.666-3.792-2.217-4.605-4.14a5.888 5.888 0 01-.321-2.223l-.052-3.429a7.276 7.276 0 01.181-2.141c.268-.92.884-1.689 1.472-2.445 1.116-1.436 2.202-2.963 2.635-4.73.525-2.144-.29-3.474-1.336-5.21-.905-1.501-.69-3.65.88-4.647z'
				fill='#2F2E41'
			/>
			<path d='M276.491 30s12.151 10.531 8.981 14.217-8.189-6.845-8.189-6.845L272 33.422 276.491 30z' fill='#A0616A' />
			<path
				d='M226.375 170s-13.823 3.356-15.388 6.196c0 0-1.826 8.778-1.826 12.392 0 3.614-.782 10.326-8.085 11.617-7.303 1.291-12.52 3.615-2.608 5.422 9.911 1.807 12.78 2.323 15.649-1.549 2.869-3.873 4.434-4.905 4.434-4.905l2.086 5.163h1.304l4.956-10.585s.522-5.163-.783-8.261c-1.304-3.098.261-15.49.261-15.49z'
				fill='#2F2E41'
			/>
			<path d='M278 31.25s-.382 4-5.816 4.75L271 32.25l3.75-3.25 3.25 2.25z' fill='#D0CDE1' />
			<path
				d='M139 191c15.464 0 28-12.536 28-28s-12.536-28-28-28-28 12.536-28 28 12.536 28 28 28zm-13.993-70.139c-10.741 3.583-22.462-1.539-27.071-11.83L67.545 41.167a29.11 29.11 0 01-.058-23.678A29.37 29.37 0 0185.07 1.519a29.581 29.581 0 0123.818 2.255 29.28 29.28 0 0114.264 19.1l16.314 72.494c2.459 10.926-3.783 21.932-14.459 25.493z'
				fill='#00528C'
			/>
			<path
				d='M138.5 182c12.426 0 22.5-10.074 22.5-22.5S150.926 137 138.5 137 116 147.074 116 159.5s10.074 22.5 22.5 22.5zm-15.293-69.046c-9.883 3.29-20.666-1.412-24.906-10.86l-27.96-62.302a26.672 26.672 0 01-.053-21.737c3.07-6.929 8.943-12.252 16.176-14.66a27.267 27.267 0 0121.913 2.07c6.679 3.737 11.448 10.109 13.122 17.533l15.01 66.553c2.262 10.03-3.481 20.134-13.302 23.403z'
				fill='#046CB6'
			/>
		</SvgIcon>
	);
};
