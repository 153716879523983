import { IActionItem, IOperationResultNoValue, IPageCollectionControllerFetchResult, IUser } from '@ViewModels';
import { css } from 'aphrodite';
import { action, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import FlipMove from 'react-flip-move';
import Waypoint from 'react-waypoint';
import { NotesSortOptionValue } from '../../../../models';
import * as AppState from '../../../../models/AppState';
import { Topics } from '../../../../models/LocalNotificationTopics';
import { ILocalNotification } from '../../../../models/LocalNotifications';
import { EventLogger, IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { formatDateCriteria } from '../../../../models/UiUtils';
import * as AppViewModels from '../../../../viewmodels/AppViewModels';
import ActionItemsPlaceholderIconUrl from '../../../assets/actionItemsListPlaceholderIcon.svg';
import { LocalNotificationObserver } from '../../LocalNotificationObserver';
import { IMoreMenuItem } from '../../MoreMenu';
import { Placeholder } from '../../Placeholder';
import { Portal } from '../../Portal';
import { ActionItemFeedCard } from '../../cards/ActionItemFeedCard';
import {
	KitCard,
	KitCardChangeFrequencyMoreMenuOption,
	KitCardMarkAsCompleteMoreMenuOption,
} from '../../cards/KitCard';
import { ActionItemsFilterValue } from '../ActionItemsFilter';
import { ActionItemsListHeader } from '../ActionItemsListHeader';
import { styleSheet } from './styles';

export interface IActionItemsListAnimationOptions {
	/** Runs when the component mounts */
	animateOnAppear?: boolean;
	/** Runs when new items are added */
	animateOnEnter?: boolean;
	/** Runs when new items are removed */
	animateOnLeave?: boolean;
	disableAllAnimations?: boolean;
}

const ActionItemsLisKitCardMarkAsInCompleteMoreMenuOption: IMoreMenuItem<string> = {
	name: 'Mark as incomplete',
	representedObject: 'markIncomplete',
};

const DefaultAnimationOptions: IActionItemsListAnimationOptions = {
	disableAllAnimations: true,
};

interface IProps
	extends AppState.IErrorMessageComponentProps,
		AppState.IActionItemComposerComponentProps,
		AppState.IErrorMessageComponentProps,
		AppState.ISingleEmailComposerComponentProps,
		IEventLoggingComponentProps {
	actionItems?: AppViewModels.ActionItemViewModel[];
	actionItemsVm?: AppViewModels.ActionItemsViewModel;
	animationOptions?: IActionItemsListAnimationOptions;
	bodyClassName?: string;
	className?: string;
	defaultPlaceholderClassName?: string;
	defaultPlaceholderContent?: React.ReactNode;
	defaultPlaceholderLoaderType?: 'large' | 'small';
	headerPortalDestination?: HTMLElement | string;
	initialSelectedFilterValue?: ActionItemsFilterValue;
	maxItems?: number;
	onGetListItemRightAccessory?(actionItem: AppViewModels.ActionItemViewModel, index: number): React.ReactNode;
	onRemoveActionItems?(actionItems: AppViewModels.ActionItemViewModel[]): void;
	onRenderPlaceholder?(isEmpty: boolean, isLoading: boolean): React.ReactNode;
	pageSize?: number;
	scrollToBottomPortalId?: string;
	showCompletedItems?: boolean;
	showSearchBar?: boolean;
	userSession?: AppViewModels.UserSessionContext;
}

interface IState {
	actionItems?: AppViewModels.ObservableCollection<AppViewModels.ActionItemViewModel>;
	animationOptions?: IActionItemsListAnimationOptions;
	assignedTo?: string;
	contactProfileRedirect?: AppViewModels.ContactViewModel;
	dateRange?: AppViewModels.IRange<Date>;
	sortSelectedOption?: NotesSortOptionValue;
	selectedFilterValue?: ActionItemsFilterValue;
	showCompletedItems?: boolean;
}

class _ActionItemsList extends React.Component<IProps, IState> {
	public static defaultProps: Partial<IProps> = {
		animationOptions: DefaultAnimationOptions,
	};

	public state: IState = {};

	public UNSAFE_componentWillMount() {
		const showCompletedItems = this.props.showCompletedItems;
		this.setState(
			{
				actionItems: this.props.actionItems ? new AppViewModels.ObservableCollection(this.props.actionItems) : null,
				animationOptions: this.props.animationOptions || DefaultAnimationOptions,
				assignedTo:
					this.props.initialSelectedFilterValue !== ActionItemsFilterValue.All
						? this.props.userSession?.user?.id
						: null,
				selectedFilterValue: this.props.initialSelectedFilterValue ?? ActionItemsFilterValue.CurrentUser,
				showCompletedItems,
			},
			() => this.getItems(showCompletedItems)
		);
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		const nextState: IState = {};

		if (this.state.showCompletedItems !== nextProps.showCompletedItems) {
			// switching list filter
			const showCompletedItems = nextProps.showCompletedItems;
			nextProps.actionItemsVm.reset();
			nextState.showCompletedItems = nextProps.showCompletedItems;
			// This must be true or false for the FilterRequest to work
			this.getItems(Boolean(showCompletedItems));
		}

		if (this.props.actionItems !== nextProps.actionItems) {
			nextState.actionItems = nextProps.actionItems
				? new AppViewModels.ObservableCollection(nextProps.actionItems)
				: null;
		}

		if (this.state.animationOptions !== nextProps.animationOptions) {
			nextState.animationOptions = nextProps.animationOptions;
		}

		this.setState(nextState);
	}
	onRangeChange = (range: AppViewModels.IRange<Date>) => {
		const nextDateRange = {
			start: range.start,
			end: range.end,
		};
		this.setState({ dateRange: nextDateRange }, () => {
			this.getItems(this.state.showCompletedItems);
		});
	};

	onSortChange = (option: NotesSortOptionValue) => {
		this.setState({ sortSelectedOption: option }, () => {
			this.getItems(this.state.showCompletedItems);
		});
	};

	public render() {
		const {
			actionItemsVm,
			bodyClassName,
			className,
			defaultPlaceholderClassName,
			defaultPlaceholderLoaderType,
			headerPortalDestination,
			onRenderPlaceholder,
			showCompletedItems,
			showSearchBar,
		} = this.props;
		const { animationOptions, selectedFilterValue } = this.state;
		const items = this.displayedItems;
		const loading = !!actionItemsVm && !!actionItemsVm.isBusy;
		const isEmpty = !!items && items.length === 0;

		return (
			<div className={`action-items-list ${className || ''}`}>
				{showSearchBar ? (
					<ActionItemsListHeader
						actionItemsVm={actionItemsVm}
						onClearInput={this.onClearInput}
						onFilterSelectedOptionChanged={this.onFilterSelectedOptionChanged}
						onSearchInputBlur={this.onSearchInputBlur}
						onSearchInputChanged={this.onSearchInputChanged}
						onSearchInputKeyDown={this.onSearchInputKeyDown}
						onRangeChanged={this.onRangeChange}
						onSortSelectedOptionChanged={this.onSortChange}
						portalDestination={headerPortalDestination}
						selectedFilterValue={selectedFilterValue}
					/>
				) : null}
				<div className={`${css(styleSheet.body)} action-items-list-body ${bodyClassName || ''}`}>
					{onRenderPlaceholder ? (
						onRenderPlaceholder(isEmpty, loading)
					) : (
						<Placeholder
							className={`action-items-list-placeholder ${defaultPlaceholderClassName || ''}`}
							isEmpty={isEmpty}
							isLoading={loading}
							loaderType={defaultPlaceholderLoaderType || 'large'}
							message={this.getPlaceholderText()}
							placeholderUrl={ActionItemsPlaceholderIconUrl}
						>
							{this.props.defaultPlaceholderContent}
						</Placeholder>
					)}
					<FlipMove
						appearAnimation={animationOptions ? animationOptions.animateOnAppear : undefined}
						disableAllAnimations={animationOptions ? animationOptions.disableAllAnimations : true}
						enterAnimation={animationOptions ? animationOptions.animateOnEnter : undefined}
						leaveAnimation={animationOptions ? animationOptions.animateOnLeave : undefined}
						staggerDelayBy={50}
						staggerDurationBy={10}
					>
						{!!items &&
							items.map(actionItem => {
								if (actionItem.isKeepInTouchActionItem) {
									return (
										<KitCard
											actionItem={actionItem}
											className={`${css(styleSheet.item)} action-items-list-body-item`}
											key={actionItem.id}
											moreMenuItems={[
												showCompletedItems
													? ActionItemsLisKitCardMarkAsInCompleteMoreMenuOption
													: KitCardMarkAsCompleteMoreMenuOption,
												KitCardChangeFrequencyMoreMenuOption,
											]}
											onMoreMenuItemClicked={this.onKitCardMoreMenuItemClicked(actionItem)}
											onRequestRemove={this.onRequestRemoveItem(actionItem)}
										/>
									);
								}
								return (
									<ActionItemFeedCard
										actionItem={actionItem}
										canToggleCompleted={true}
										className={`${css(styleSheet.item)} action-items-list-body-item`}
										hideRemindMeLaterOption={true}
										key={actionItem.id}
										onCheckChanged={this.onActionItemCheckedChanged(actionItem)}
										onRequestRemove={this.onRequestRemoveItem(actionItem)}
									/>
								);
							})}
					</FlipMove>
					{!!this.allowFetchingMore(items) && (
						<Portal destination={this.props.scrollToBottomPortalId}>
							<Waypoint key='notes-list' bottomOffset='-200px' onEnter={this.scrolledToBottom} />
						</Portal>
					)}
				</div>
				<LocalNotificationObserver
					topic={Topics.CREATE_ACTION_ITEM}
					onNotificationReceived={this.onCreateOrUpdateNotificationReceived}
				/>
				<LocalNotificationObserver
					topic={Topics.EDIT_ACTION_ITEM}
					onNotificationReceived={this.onCreateOrUpdateNotificationReceived}
				/>
				<LocalNotificationObserver
					topic={Topics.DELETE_ACTION_ITEM}
					onNotificationReceived={this.onDeleteNotificationReceived}
				/>
				<LocalNotificationObserver topic={Topics.SEND_EMAIL} onNotificationReceived={this.onEmailsSent} />
			</div>
		);
	}

	@computed
	private get displayedItems() {
		const { actionItems, showCompletedItems } = this.state;
		const { actionItemsVm } = this.props;
		if (actionItems) {
			return actionItems;
		}
		if (actionItemsVm) {
			if (actionItemsVm.isSearching) {
				return actionItemsVm.searchResults;
			} else {
				return showCompletedItems ? actionItemsVm.completedActionItems : actionItemsVm.inProgressActionItems;
			}
		}

		return null;
	}

	private onKitCardMoreMenuItemClicked =
		(actionItem: AppViewModels.ActionItemViewModel) =>
		(item: IMoreMenuItem<string>, e: React.MouseEvent<HTMLElement>) => {
			if (item.representedObject === 'markIncomplete' && !actionItem.isBusy) {
				const { logInput, errorMessages, logApiError } = this.props;
				const promise = actionItem.toggleComplete(false);
				if (promise) {
					logInput('MarkIncomplete', 'Click', { id: actionItem.id });
					e.preventDefault();
					promise
						.then(() => {
							this.removeActionItemFromList(actionItem);
						})
						.catch((error: IOperationResultNoValue) => {
							errorMessages.pushApiError(error);
							logApiError?.('MarkIncomplete-Error', error);
						});
				}
			}
		};

	private onActionItemCheckedChanged = (actionItem: AppViewModels.ActionItemViewModel) => (checked: boolean) => {
		const { showCompletedItems } = this.props;
		if (checked !== showCompletedItems) {
			this.removeActionItemFromList(actionItem);
		}
	};

	private onFilterSelectedOptionChanged = (selectedFilterValue: ActionItemsFilterValue, user?: IUser) => {
		const { userSession } = this.props;

		const assignedTo =
			selectedFilterValue === ActionItemsFilterValue.OwnedBy
				? user?.id
				: selectedFilterValue === ActionItemsFilterValue.CurrentUser
					? userSession?.user?.id
					: null;

		/** Reset search when switching to "Owned By" filter */
		if (selectedFilterValue === ActionItemsFilterValue.OwnedBy) {
			this.props.actionItemsVm.resetSearch();
		}

		this.setState(
			{
				assignedTo,
				selectedFilterValue,
			},
			() => {
				if (!!this.props.actionItemsVm && !this.props.actionItemsVm.isBusy) {
					this.getItems(this.state.showCompletedItems);
				}
			}
		);
	};

	private onEmailsSent = (notification: ILocalNotification<AppViewModels.EmailMessageViewModel>) => {
		const { actionItemsVm, onRemoveActionItems } = this.props;
		if (!!actionItemsVm && !!actionItemsVm.isBusy) {
			return;
		}

		if (!!actionItemsVm && !!actionItemsVm.isSearching) {
			this.reloadSearchResults();
			return;
		}

		const { showCompletedItems } = this.state;
		if (notification.info) {
			let search: (actionItem: AppViewModels.ActionItemViewModel) => boolean = null;
			if (notification.info instanceof AppViewModels.EmailMessageViewModel) {
				const emailMessage = notification.info;
				if (emailMessage.contactsToAdd.length > 0) {
					const recipientIds = emailMessage.contactsToAdd
						.filter(x => !emailMessage.contactsToOmit.has(x))
						.map(x => x.id);
					// can only check explicitly added contacts here
					search = actionItem => {
						return (
							!!actionItem.isKeepInTouchActionItem &&
							!!actionItem.keepInTouchReference.contact &&
							recipientIds.indexOf(actionItem.keepInTouchReference.contact.id) >= 0
						);
					};
				} else {
					search = actionItem => {
						return (
							!!actionItem.isKeepInTouchActionItem &&
							!!actionItem.keepInTouchReference.contact.emailAddresses &&
							!!actionItem.keepInTouchReference.contact.emailAddresses.find(emailAddress => {
								return !!emailMessage.contactsToAdd.find(recipient => {
									const recipientEmailAddress = emailMessage.getPreferredEmailAddressForContact(recipient) || {
										value: null,
									};
									return !!recipientEmailAddress.value && recipientEmailAddress.value === emailAddress.value;
								});
							})
						);
					};
				}
			}

			if (search) {
				if (actionItemsVm) {
					if (!showCompletedItems) {
						const actionItemsToRemove = actionItemsVm.inProgressActionItems.filter(search);
						if (!!actionItemsToRemove && actionItemsToRemove.length > 0) {
							actionItemsVm.removeActionItems(actionItemsToRemove, false);

							if (onRemoveActionItems) {
								onRemoveActionItems(actionItemsToRemove);
							}
						}
					}
				} else if (this.state.actionItems) {
					const actionItemsToRemove = this.state.actionItems.filter(search);
					if (!!actionItemsToRemove && actionItemsToRemove.length > 0) {
						this.state.actionItems.removeItems(actionItemsToRemove);

						if (onRemoveActionItems) {
							onRemoveActionItems(actionItemsToRemove);
						}
					}
				}
			}
		}
	};

	private onRequestRemoveItem = (actionItem: AppViewModels.ActionItemViewModel) => () => {
		this.removeActionItemFromList(actionItem);
	};

	private removeActionItemFromList = (actionItem: AppViewModels.ActionItemViewModel) => {
		if (this.props.actionItemsVm) {
			this.props.actionItemsVm.removeActionItems([actionItem], this.state.showCompletedItems);
		} else if (this.state.actionItems) {
			if (this.props.onRemoveActionItems) {
				// controlled case
				this.props.onRemoveActionItems([actionItem]);
				return;
			}
			this.state.actionItems.removeItems([actionItem]);
		}
	};

	private handleApiPromiseError = (promise: Promise<any>) => {
		if (promise) {
			promise.catch((error: IOperationResultNoValue) => {
				if (!!error && !!error.systemMessage) {
					this.showErrorMessage(error.systemMessage);
				}
			});
		}
	};

	private showErrorMessage = (errorMessage: string) => {
		if (this.props.errorMessages) {
			this.props.errorMessages.push({
				messages: [errorMessage],
			});
		}
	};

	private onCreateOrUpdateNotificationReceived = (notification: ILocalNotification<IActionItem>) => {
		const { actionItemsVm, userSession } = this.props;
		if (!actionItemsVm) {
			return;
		}

		const actionItem = notification.info;
		if (!!this.state.showCompletedItems !== !!actionItem?.isCompleted || actionItemsVm.isBusy) {
			return;
		}

		if (actionItemsVm.isSearching) {
			this.reloadSearchResults();
			return;
		}

		// convert to vm
		const actionItemVm = new AppViewModels.ActionItemViewModel(userSession, actionItem);

		// add to the right collection
		const collection = this.state.showCompletedItems
			? actionItemsVm.completedActionItems
			: actionItemsVm.inProgressActionItems;
		if (collection.has(actionItemVm)) {
			// alread exists.. could be an edit... replace
			collection.splice(collection.indexOf(actionItemVm), 1, actionItemVm);
		} else {
			// add to the top
			collection.splice(0, 0, actionItemVm);
		}
	};

	private onDeleteNotificationReceived = (notification: ILocalNotification<IActionItem>) => {
		const { actionItemsVm } = this.props;
		if (!actionItemsVm) {
			return;
		}

		const actionItem = notification.info;
		if (!!this.state.showCompletedItems !== !!actionItem?.isCompleted || actionItemsVm.isBusy) {
			return;
		}

		if (actionItemsVm.isSearching) {
			this.reloadSearchResults();
			return;
		}

		const actionItemVM = this.state.showCompletedItems
			? actionItemsVm.completedActionItems.find(x => x.id === actionItem.id)
			: actionItemsVm.inProgressActionItems.find(x => x.id === actionItem.id);

		actionItemsVm.removeActionItems([actionItemVM], false);
	};

	@action
	private onSearchInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.keyCode === 13 || e.key === 'Enter') {
			this.reloadSearchResults();
		}
	};

	private getFilterRequest = (showCompleted = this.state.showCompletedItems) => {
		const { dateRange, assignedTo, sortSelectedOption } = this.state;
		const newFilterRequest: AppViewModels.IRichContentRequest = {
			criteria: [],
		};
		if (dateRange) {
			let dateFilterProperty = AppViewModels.RichContentProperty.CreationDate;
			if (sortSelectedOption === 'DueDate-asc' || sortSelectedOption === 'DueDate-dsc') {
				dateFilterProperty = AppViewModels.RichContentProperty.DueDate;
			} else if (sortSelectedOption === 'LastModifiedDate-asc' || sortSelectedOption === 'LastModifiedDate-dsc') {
				dateFilterProperty = AppViewModels.RichContentProperty.LastModifiedDate;
			}

			newFilterRequest.criteria?.push(
				formatDateCriteria<AppViewModels.RichContentProperty>(dateFilterProperty, {
					start: dateRange.start,
					end: dateRange.end,
				})
			);
		}
		newFilterRequest.criteria?.push({
			property: AppViewModels.RichContentProperty.IsCompleted,
			value: showCompleted ? 'true' : 'false',
		});
		newFilterRequest.criteria?.push({
			property: AppViewModels.RichContentProperty.Type,
			value: 'ActionItem',
		});
		if (assignedTo) {
			newFilterRequest.criteria?.push({
				property: AppViewModels.RichContentProperty.AssignedTo,
				value: assignedTo,
			});
		} else {
			newFilterRequest.criteria?.push({
				property: AppViewModels.RichContentProperty.AssignedTo,
				value: undefined,
			});
		}

		return newFilterRequest;
	};

	private getItems = (showCompletedItems?: boolean) => {
		const { actionItemsVm, pageSize } = this.props;
		const { sortSelectedOption } = this.state;
		if (actionItemsVm) {
			const promise = actionItemsVm.filterItems(
				this.getFilterRequest(showCompletedItems),
				pageSize,
				sortSelectedOption
			) as any;
			this.handleApiPromiseError(promise);
		}
	};

	private reloadSearchResults() {
		const { actionItemsVm, pageSize } = this.props;
		const { showCompletedItems, sortSelectedOption } = this.state;

		const searchQuery = (this.props.actionItemsVm?.searchQuery || '').trim();

		this.props.actionItemsVm?.reset();
		this.props.actionItemsVm.searchQuery = searchQuery;
		const promise: Promise<IPageCollectionControllerFetchResult<IActionItem[]>> = this.props.actionItemsVm?.filterItems(
			this.getFilterRequest(showCompletedItems),
			pageSize,
			sortSelectedOption
		) as any;
		this.handleApiPromiseError(promise);
		// reset the filter
		this.setState({
			selectedFilterValue: ActionItemsFilterValue.All,
		});

		EventLogger.logEvent(
			{
				action: 'Search',
				category: 'ActionItems',
				label: showCompletedItems ? 'Completed' : 'InProgress',
			},
			{ length: (actionItemsVm?.searchQuery || '').length }
		);
	}

	private onSearchInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		e.preventDefault();
		this.reloadSearchResults();
	};

	private onSearchInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.props.actionItemsVm.searchQuery = e.target.value;
	};

	private onClearInput = () => {
		this.props.actionItemsVm.searchQuery = '';

		this.reloadSearchResults();
	};

	private getPlaceholderText() {
		return this.state.showCompletedItems
			? 'You have no completed action items.'
			: 'You have no action items in progress.';
	}

	private allowFetchingMore = (items: AppViewModels.ObservableCollection<AppViewModels.ActionItemViewModel>) => {
		if (this.props.maxItems) {
			return !!items && items.length < this.props.maxItems;
		}

		return !!items;
	};

	private scrolledToBottom = () => {
		if (!!this.props.actionItemsVm && !this.props.actionItemsVm.isBusy) {
			this.getItems(this.state.showCompletedItems);
		}
	};
}

const ActionItemsListAsObserver = observer(_ActionItemsList);
const ActionItemsListWithContext = inject(
	AppState.ErrorMessagesViewModelKey,
	AppState.ActionItemComposerViewModelKey,
	AppState.SingleEmailComposerKey,
	AppState.ErrorMessagesViewModelKey
)(ActionItemsListAsObserver);
export const ActionItemsList = withEventLogging(ActionItemsListWithContext, 'ActionItemsList');
