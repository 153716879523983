import { css } from 'aphrodite';
import * as React from 'react';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	title?: React.ReactNode;
	titleClassName?: string;
}

export const SettingsSubGroup: React.FC<IProps> = props => {
	const { className, title, children, titleClassName } = props;
	return (
		<div className={`${css(styleSheet.container)} settings-sub-group ${className || ''}`}>
			{!!title && <div className={`${css(styleSheet.title)} ${titleClassName || ''}`}>{title}</div>}
			<div className={css(titleClassName === 'Social' ? styleSheet.contentSocial : styleSheet.content)}>{children}</div>
		</div>
	);
};
