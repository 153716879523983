import { StyleSheet } from 'aphrodite';
import { borderColor, brandPrimary, brandPrimaryHover, brandSecondary } from '../../../styles/colors';
import { cardStyle } from '../../../styles/styles';

const avatarSize = 60;
const interactionBannerHeight = 46;
const doneButtonHeight = 37;
export const styleSheet = StyleSheet.create({
	avatar: {
		height: avatarSize,
		width: avatarSize,
	},
	container: {
		...cardStyle,
		borderRadius: 10,
		cursor: 'pointer',
	},
	doneButton: {
		alignItems: 'center',
		background: '#E8F3F9',
		boxShadow: '0 0 6px 0 rgba(139,139,139,0.10)',
		color: brandPrimaryHover,
		display: 'flex',
		fontSize: 14,
		height: doneButtonHeight,
		justifyContent: 'center',
		width: '100%',
	},
	email: {
		display: 'flex',
		justifyContent: 'center',
		gap: 12,
	},
	header: {
		boxSizing: 'border-box',
		padding: 20,
		textAlign: 'center',
	},
	interactionBanner: {
		background: `linear-gradient(to right, ${brandSecondary}, #7CC9FF)`,
		boxSizing: 'border-box',
		color: '#fff',
		display: 'flex',
		flexDirection: 'column',
		fontSize: 12,
		height: interactionBannerHeight,
		justifyContent: 'center',
		padding: '0 20px',
	},
	interactionBannerRow: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
	},
	interactionBannerTitle: {
		fontWeight: 600,
		letterSpacing: '1.1px',
		textTransform: 'uppercase',
	},
	interactionBannerType: {
		fontSize: 11,
	},
	interactionBannerValue: {
		fontWeight: 600,
	},
	name: {
		color: brandPrimary,
		fontSize: 24,
		textDecoration: 'underline',
	},
	separator: {
		borderLeft: `2px solid ${borderColor}`,
		marginTop: 40,
	},
	tagsContainer: {
		boxSizing: 'border-box',
		overflowX: 'auto',
		padding: 16,
	},
	tagsContainerSplit: {
		flex: '0 0 40%',
	},
	recentEmailsContainer: {
		padding: 16,
		width: '100%',
		maxHeight: 450,
		overflow: 'scroll',
	},
	recentEmailsHeader: {
		margin: 0,
		paddingBottom: 16,
	},
	recentEmailsContainerList: {
		display: 'flex',
		flexDirection: 'column',
		gap: 20,
	},
	body: {
		display: 'flex',
	},
	titleAndCompany: {
		color: `#767676`,
		fontSize: 14,
		padding: 12,
	},
});
