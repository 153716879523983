import { StyleSheet } from 'aphrodite';

export const wealthboxColor = '#106DD4';

export const styleSheet = StyleSheet.create({
	ctaButton: {
		backgroundColor: wealthboxColor,
		boxShadow: 'none',
		marginTop: 20,
	},
	integrationTitle: {
		color: wealthboxColor,
	},
});
