import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { alternateTitleColor, mention } from '../../../../styles/colors';
import { SvgIcon } from '../../icons/SvgIcon';

interface Props extends React.SVGProps<SVGSVGElement> {
	styles?: StyleDeclarationValue[];
	bgCircleFill?: string;
	strokeColor?: string;
}

export const EmailInCircleGraphic = ({
	className,
	styles,
	width = 180,
	height = 180,
	bgCircleFill = mention,
	strokeColor = alternateTitleColor,
}: Props) => {
	return (
		<SvgIcon
			className={`email-in-circle-graphic ${className || ''} ${css(...(styles || []))}`}
			height={height}
			width={width}
			viewBox='0 0 180 180'
		>
			<circle cx='90' cy='90' r='90' fill={bgCircleFill} />
			<path
				d='M44.08 80.569l44-36.667a3 3 0 013.84 0l44.001 36.667A3 3 0 01137 82.874V135a3 3 0 01-3 3H46a3 3 0 01-3-3V82.874a3 3 0 011.08-2.305z'
				fill='#CAE0EC'
				stroke={strokeColor}
				strokeWidth='2'
			/>
			<path fill='#fff' stroke={strokeColor} strokeWidth='2' d='M54 53h70v63H54z' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M43 85v49a4 4 0 004 4h66L43 85z'
				fill='#E8F3F9'
				stroke={strokeColor}
				strokeWidth='2'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M137.01 85v49a4 4 0 01-4 4h-66l70-53z'
				fill='#E8F3F9'
				stroke={strokeColor}
				strokeWidth='2'
			/>
			<rect x='73.5' y='77.5' width='32' height='1' rx='.5' fill='#D8D8D8' stroke={strokeColor} />
			<rect x='73.5' y='85.5' width='32' height='1' rx='.5' fill='#D8D8D8' stroke={strokeColor} />
			<rect x='73.5' y='93.5' width='25' height='1' rx='.5' fill='#D8D8D8' stroke={strokeColor} />
			<rect x='73.5' y='67.5' width='11' height='1' rx='.5' fill='#D8D8D8' stroke={strokeColor} />
			<path fillRule='evenodd' clipRule='evenodd' d='M73 109l-23 28 30-23-7-5zm34 0l23 28-30-23 7-5z' fill='#CAE0EC' />
			<path
				d='M91.987 104.09L130.358 138H49.642l38.371-33.91a3 3 0 013.974 0z'
				fill='#fff'
				stroke={strokeColor}
				strokeWidth='2'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M87.626 110.727a4 4 0 014.748 0L128 137H52l35.626-26.273z'
				fill='#E8F3F9'
			/>
		</SvgIcon>
	);
};
