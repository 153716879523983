import { css } from 'aphrodite';
import * as React from 'react';
import { DangerIcon } from '../../svgs/icons/DangerIcon';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	text: string;
}

export const ContactError: React.FC<IProps> = (props: IProps) => {
	const { text, className } = props;
	return (
		<div className={`${css(styleSheet.container)} ${className ?? ''}`}>
			<div>
				<DangerIcon />
			</div>
			<div>
				<div className={css(styleSheet.headerContainer)}>{text}</div>
			</div>
		</div>
	);
};
