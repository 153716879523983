import { ISelectedRecipientPill } from './index';
import { useEffect, useState } from 'react';

export const useIsGroupTextSupported = (
	selectedRecipients: ISelectedRecipientPill[],
	supportsGroupTexting: boolean
) => {
	const [forceDisabled, setForceDisabled] = useState(false);

	useEffect(() => {
		setForceDisabled(selectedRecipients?.length > 0 && !supportsGroupTexting);
	}, [supportsGroupTexting, selectedRecipients]);

	return forceDisabled;
};
