import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { useHistory } from 'react-router';
import { useEventLogging } from '../../../../models/Logging';
import { copyToClipboard } from '../../../../models/UiUtils';
import { useErrorMessages, useToaster, useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	invalidateGetPublicAuthKeys,
	useDeletePublicAuthKeyMutation,
	useGetPublicAuthKey,
	useGetPublicAuthKeys,
	usePostPublicAuthKeyMutation,
} from '../../../../queries';
import {
	ConfirmationDialog,
	DefaultDeleteConfirmationOptions,
	IConfirmationDialogOption,
} from '../../../components/ConfirmationDialog';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { MoreMenu, MoreMenuItem } from '../../../components/MoreMenu';
import { TextInput } from '../../../components/TextInput';
import { WarningIcon } from '../../../components/svgs/icons/WarningIcon';
import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet } from './styles';

export const ManageAPIKeys = () => {
	const [showingAddRow, setShowingAddRow] = React.useState(false);
	const [description, setDescription] = React.useState('');
	const [selectedAuthKey, setSelectedAuthKey] = React.useState(null);
	const { logApiError } = useEventLogging('ManageAPIKeys');
	const errorMessages = useErrorMessages();
	const [keyToDelete, setKeyToDelete] = React.useState<Api.IAPIKeyReference | null>(null);

	const userSession = useUserSession();
	const toaster = useToaster();
	const history = useHistory();

	if (!userSession.account.features.publicApi?.enabled) {
		history.push('/dashboard');
	}

	const apiKeysQuery = useGetPublicAuthKeys({
		enabled: Boolean(userSession.account.features.publicApi?.enabled),
		refetchOnWindowFocus: false,
	});

	const createPublicAuthKey = usePostPublicAuthKeyMutation({
		onSuccess: (result: string) => {
			setDescription('');
			setShowingAddRow(false);
			invalidateGetPublicAuthKeys({});

			copyToClipboard(result);
			toaster?.push({
				message: `New key has been copied to your clipboard`,
				type: 'successMessage',
			});
		},
		onError: (error: Api.IOperationResultNoValue) => {
			errorMessages.pushApiError(error);
			logApiError('CreatePublicApiKey-Error', error);
		},
	});

	const deletePublicAuthKey = useDeletePublicAuthKeyMutation({
		onSuccess: () => {
			invalidateGetPublicAuthKeys({});
			toaster.push({
				message: 'API Key has been deleted',
				type: 'successMessage',
			});
		},
		onError: (error: Api.IOperationResultNoValue) => {
			errorMessages.pushApiError(error);
			logApiError('DeletePublicApiKey-Error', error);
		},
	});

	useGetPublicAuthKey({
		enabled: Boolean(selectedAuthKey),
		refetchOnWindowFocus: false,
		keyId: selectedAuthKey,
		onSuccess: value => {
			copyToClipboard(value);
			toaster?.push({
				message: `Key has been copied to your clipboard`,
				type: 'successMessage',
			});
			setSelectedAuthKey(null);
		},
		onError: (error: Api.IOperationResultNoValue) => {
			errorMessages.pushApiError(error);
			logApiError('GetPublicApiKey-Error', error);
			setSelectedAuthKey(null);
		},
	});

	const onDeleteConfirmationRequestClose = (result?: IConfirmationDialogOption<boolean>, cancel?: boolean) => {
		setKeyToDelete(null);
		if (result?.isDestructive && !cancel) {
			deletePublicAuthKey.mutate(keyToDelete.blobId);
		}
	};

	if (apiKeysQuery.isLoading) {
		return <LoadingSpinner type='large' className={css(baseStyleSheet.absoluteCenter)} />;
	}

	const showAddRow = () => {
		setDescription('');
		setShowingAddRow(true);
	};

	const saveNewKey = () => {
		createPublicAuthKey.mutate({ description });
	};

	const copyKey = (keyId: string) => {
		setSelectedAuthKey(keyId);
	};

	const deleteKey = (key: Api.IAPIKeyReference) => {
		setKeyToDelete(key);
	};

	return (
		<div className={css(styleSheet.container)}>
			<header className={css(styleSheet.header)}>
				<section>
					<h1 className={css(baseStyleSheet.breadcrumbTitle)}>API Keys</h1>
					<small>Generate and manage API keys to integrate with third-party apps</small>
				</section>

				<section className={css(styleSheet.headerActions)}>
					<button className={css(baseStyleSheet.ctaButton)} onClick={showAddRow}>
						Generate New Key
					</button>
				</section>
			</header>
			<table className={css(styleSheet.tableContainer)}>
				<thead>
					<tr>
						<th className={css(styleSheet.tableHeaderCell)}>Description</th>
						<th className={css(styleSheet.tableHeaderCell)}>Key</th>
						<th className={css(styleSheet.tableHeaderCell)}>Added</th>
						<th className={css(styleSheet.tableHeaderCell)}>Last Used</th>
						<th className={css(styleSheet.tableHeaderCell)}>Actions</th>
					</tr>
				</thead>
				<tbody>
					{apiKeysQuery.data.map((apiKey: Api.IAPIKeyReference) => {
						const lastUsedDate = apiKey.lastUsedDate ? moment(apiKey.lastUsedDate).format('MM/DD/YYYY') : 'Never';

						return (
							<tr key={`auth-keys-${apiKey.blobId}`} className={css(styleSheet.tableRow)}>
								<td className={css(styleSheet.tableCell)}>{apiKey.description}</td>
								<td className={css(styleSheet.tableCell)}>
									<span>**** **** **** ****</span>
									<button onClick={() => copyKey(apiKey.blobId)} className={css(styleSheet.copyKeyButton)}>
										Copy to Clipboard
									</button>
								</td>
								<td className={css(styleSheet.tableCell)}>{moment(apiKey.creationDate).format('MM/DD/YYYY')}</td>
								<td className={css(styleSheet.tableCell)}>{lastUsedDate}</td>
								<td className={css(styleSheet.tableCell)}>
									<MoreMenu>
										<MoreMenuItem onClick={() => deleteKey(apiKey)}>Delete</MoreMenuItem>
									</MoreMenu>
								</td>
							</tr>
						);
					})}
					{showingAddRow ? (
						<tr className={css(styleSheet.tableRow)}>
							<td className={css(styleSheet.addRowTableCell)}>
								<TextInput
									inputId='api-key-description'
									placeholder='Description'
									onChange={e => setDescription(e.target.value)}
									type='text'
									value={description}
								/>
							</td>
							<td />
							<td />
							<td className={css(styleSheet.addRowTableCell)}>
								<button className={css(baseStyleSheet.ctaButton)} onClick={saveNewKey}>
									Generate
								</button>
							</td>
						</tr>
					) : null}
					{apiKeysQuery.data?.length < 1 ? (
						<tr>
							<td>No data found</td>
						</tr>
					) : null}
				</tbody>
			</table>

			<ConfirmationDialog
				icon={<WarningIcon />}
				modalProps={{
					isOpen: !!keyToDelete,
					onRequestClose: onDeleteConfirmationRequestClose,
				}}
				options={DefaultDeleteConfirmationOptions}
				title={
					<span>
						{`Are you sure you want to delete the "${keyToDelete?.description || ''}" key?`}
						<br />
						<b>The key will no longer work.</b>
					</span>
				}
			/>
		</div>
	);
};
