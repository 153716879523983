export enum PersonalSettingsSection {
	Profile,
	EmailAndCalendar,
	Password,
	SocialMedia,
	EmailSignature,
	ImportContacts,
	Texting,
	Notifications,
}
