import { titles } from '../../../styles/colors';
import { BreakPoints } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	browseAllFeatured: {
		cursor: 'pointer',
		marginTop: 0,
		textAlign: 'right',
		width: 'calc(96% + 40px)',
		[BreakPoints.forMaxWidth(1150)]: {
			width: 'calc(93% + 40px)',
		},
	},
	button: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: '20px',
	},
	cardGroupContainer: {},
	cardSectionRow: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		margin: '20px 0',
	},
	categoryTitle: {
		color: titles,
		textTransform: 'capitalize',
	},
	templateCard: {
		':last-child': {
			marginRight: 0,
		},
		marginRight: '20px',
		width: '32%',
		[BreakPoints.forMaxWidth(1150)]: {
			width: '31%',
		},
	},
});
