import { brandSecondary } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import { CSSProperties } from 'react';

export function RightArrow({ color = brandSecondary, styles = {} }: { color?: string; styles?: CSSProperties }) {
	return (
		<figure style={{ ...styles }}>
			<SvgIcon width='8' height='8' viewBox='0 0 8 8' fill='none'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M2.293 6L3.707 7.414L7.414 3.707L3.707 0L2.293 1.414L3.586 2.707H0V4.707H3.586L2.293 6Z'
					fill={color}
				/>
			</SvgIcon>
		</figure>
	);
}
