import { brandPrimary, brandSecondary, mention, navigation, midGrey } from '../../styles/colors';
import { CssSelectors } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	addCard: {
		alignItems: 'center',
		backgroundColor: mention,
		borderColor: brandSecondary,
		borderRadius: 5,
		borderStyle: 'dashed',
		borderWith: 1,
		color: brandPrimary,
		cursor: 'pointer',
		display: 'flex',
		fontSize: 18,
		justifyContent: 'center',
		minHeight: 113,
		padding: '25px 21px',
		position: 'relative',
	},
	cardsContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		marginTop: 30,
		minHeight: 100,
		position: 'relative',
		...CssSelectors.allChildren({
			flexBasis: '25%',
			flexGrow: 1,
			margin: '20px 1%',
			maxWidth: '28%',
		}),
	},
	clipboardIcon: {
		height: 12,
		marginRight: 6,
		width: 12,
	},
	container: {
		padding: 20,
	},
	copyLinkButton: {
		marginTop: 8,
	},
	headerContainer: {
		marginLeft: 20,
		marginTop: 20,
	},
	noMeetings: {
		color: navigation,
		textAlign: 'center',
	},
	text: {
		color: midGrey,
		fontSize: 14,
		maxWidth: 400,
	},
});
