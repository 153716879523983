import { header } from '../../../styles/colors';
import { baseStyleSheet } from '../../../../web/styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	card: {
		background: 'white',
		borderRadius: 12,
		boxShadow: '0px 1px 2px rgba(167, 171, 173, 0.21)',
		boxSizing: 'border-box',
		display: 'flex',
		padding: '1rem 1.25rem',
	},
	headerLabel: {
		color: header,
		margin: 0,
	},
	stat: {
		fontFamily: 'Roboto',
		fontWeight: 900,
		margin: 0,
	},
	tag: {
		borderRadius: 6,
		display: 'inline-block',
		...baseStyleSheet.fontBold,
		fontSize: '0.625rem',
		padding: '0.25rem 0.5rem',
		textTransform: 'uppercase',
	},
});
