import { darkGrayFontColor } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	bioTextArea: {
		minHeight: 80,
	},
	bulletListItemTextField: {
		flexGrow: 1,
		minWidth: 0,
	},
	container: {
		position: 'relative',
	},
	fieldGroup: {
		...CssSelectors.allChildren(
			{
				marginTop: 20,
			},
			':not(:first-child)'
		),
	},
	footer: {
		alignItems: 'center',
		background: '#fff',
		boxSizing: 'border-box',
		display: 'flex',
		paddingBottom: 30,
		paddingTop: 30,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	header: {
		...CssSelectors.allChildren(
			{
				marginTop: 10,
			},
			':not(:first-child)'
		),
	},
	title: {
		color: darkGrayFontColor,
		fontSize: 14,
		marginBottom: 30,
		textTransform: 'uppercase',
	},
});
