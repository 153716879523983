import { useEventLogging } from '../../../../../models/Logging';
import * as Api from '@ViewModels';
import * as React from 'react';

export function useLaunchOwnerReport({
	queryCommand,
	eventLoggingCategory,
}: {
	queryCommand: Api.IQueryStringCommand;
	eventLoggingCategory: string;
}) {
	const isCommandOTR = queryCommand?.command === 'otr';
	const { logEvent } = useEventLogging(eventLoggingCategory);
	React.useEffect(() => {
		if (isCommandOTR) {
			logEvent('AppLink-OwnerTagReport');
		}
	}, [isCommandOTR, logEvent]);
	const ownerReportId = isCommandOTR ? (queryCommand as Api.IOwnerTagReportCommand)?.reportId : null;

	return {
		ownerReportId,
	};
}
