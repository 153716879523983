import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import React from 'react';
import { Link } from 'react-router-dom';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useAccountIntegrationQuery } from '../../../../queries';
import { IntegrationOAuthStep } from '../../../../viewmodels/AppViewModels';
import mycaseLogo from '../../../assets/logo_mycase.svg';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../../components/ConfirmationDialog';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { WarningIcon } from '../../../components/svgs/icons/WarningIcon';
import { baseStyleSheet } from '../../../styles/styles';
import { useManageIntegrationConnection } from '../hooks/useManageIntegrationConnection';
import { styleSheet as integrationsStyleSheet } from '../styles';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const MyCase = (props: IProps) => {
	const { className, styles } = props;
	const { oauthStep, onConnect, onDisable, isLoadingCallbackAuthUrl, hasIntegrationStatusLoaded } =
		useManageIntegrationConnection({ provider: Api.ConfigurableIntegrationType.MyCase });

	const userSession = useUserSession();
	const accountIntegrationQuery = useAccountIntegrationQuery<Api.IMyCaseIntegration>({
		type: Api.ConfigurableIntegrationType.MyCase,
	});

	const renderContent = () => {
		switch (oauthStep) {
			case IntegrationOAuthStep.InProgress:
			case IntegrationOAuthStep.Connected:
				return (
					<>
						<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
							Your account is successfully connected.
						</p>
						<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
							If you wish to disable this integration for your user, click{' '}
							<Link
								to='#'
								className={css(styleSheet.link)}
								onClick={ev => {
									ev.preventDefault();
									if (accountIntegrationQuery.data.syncUserId === userSession.user.id) {
										setIsDisableModalOpen(true);
									} else {
										onDisable();
									}
								}}
							>
								here
							</Link>{' '}
							to disable.
						</p>
					</>
				);
			default:
				return (
					<>
						<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
							To get started, please sign into your MyCase account.
						</p>
						<div className={css(integrationsStyleSheet.signInContainer, styleSheet.signInContainer)}>
							<div className={css(styleSheet.center)}>
								<img width={160} src={mycaseLogo} alt='MyCase Logo' />
							</div>
							<div className={css(styleSheet.center)}>
								<button
									className={css(baseStyleSheet.ctaButton, styleSheet.button)}
									onClick={onConnect}
									disabled={isLoadingCallbackAuthUrl}
								>
									Connect
								</button>
							</div>
						</div>
					</>
				);
		}
	};

	const [isDisableModalOpen, setIsDisableModalOpen] = React.useState(false);
	const disableSyncUserOptions: IConfirmationDialogOption<boolean>[] = [
		{
			isDestructive: true,
			representedObject: true,
			title: 'Disable',
		},
		{
			isCancel: true,
			representedObject: false,
			title: 'Cancel',
		},
	];

	const onDisableConfirmationDialogRequestClose = (result?: IConfirmationDialogOption<boolean>, canceled?: boolean) => {
		if (!!result?.isDestructive && !canceled) {
			onDisable();
		}

		setIsDisableModalOpen(false);
	};

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} component-class ${className || ''}`}>
			<div className={css(integrationsStyleSheet.logo, integrationsStyleSheet.headerLogo)}>
				<img src={mycaseLogo} alt='MyCase Logo' />
			</div>
			<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
				Levitate can sync with MyCase, and pull client information, including names, email, phone number, birthdays,
				anniversaries, and more. We can also push our notes into MyCase.
			</p>
			{!hasIntegrationStatusLoaded || accountIntegrationQuery.isLoading ? (
				<LoadingSpinner type='large' className={css(baseStyleSheet.absoluteCenter)} />
			) : (
				renderContent()
			)}

			<ConfirmationDialog
				icon={<WarningIcon />}
				modalProps={{
					isOpen: isDisableModalOpen,
					onRequestClose: onDisableConfirmationDialogRequestClose,
				}}
				options={disableSyncUserOptions}
				title='You are the MyCase Sync User for this account. If you disable your user, syncs will not be able to complete until a new Sync User is selected.'
			/>
		</div>
	);
};
