import { SvgIcon } from '../../icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const EmailSignatureGraphic: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon className={`people-in-window-graphic ${className || ''}`} height={120} width={120}>
			<defs>
				<linearGradient id='emailsignaturegraphicgradiant' x1='0' y1='0' x2='0' y2='120' gradientUnits='userSpaceOnUse'>
					<stop stopColor='#FFE27A' />
					<stop offset='1' stopColor='#F7B500' />
				</linearGradient>
			</defs>
			<circle cx='60' cy='60' r='60' fill='url(#emailsignaturegraphicgradiant)' />
			<path
				d='m27.071 53.363 31.5-26.439a3 3 0 0 1 3.858 0l31.5 26.439A3 3 0 0 1 95 55.66V93a3 3 0 0 1-3 3H29a3 3 0 0 1-3-3V55.66a3 3 0 0 1 1.071-2.297Z'
				fill='#89C947'
				stroke='#00AAE8'
				strokeWidth='2'
			/>
			<path fill='#fff' stroke='#00AAE8' strokeWidth='2' d='M34 33h52v46H34z' />
			<path fill='#00AAE8' d='M44 43h33v2H44z' />
			<path
				d='M45.866 61.448c4.221-2.554 15.908-10.979 12.665-7.663l-4.135 8.488c-.825.843-2.836.344-4.07-.099-2.333-.837-3.694-3.116-6.19-3.585-1.562-.293 1.644 2.256 2.96 2.978 1.486.817 3.304 1.495 5.12 1.459 2.557-.051 4.8-1.723 7.357-1.708 4.528.027-5.688 4.253 2.434 2.089 2.066-.55 3.807-1.67 5.71-2.504'
				stroke='#00528C'
				strokeWidth='2'
			/>
			<path fill='#00AAE8' d='M44 48h33v2H44z' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M26 56v36a4 4 0 0 0 4 4h48L26 56Z'
				fill='#E8F3F9'
				stroke='#00AAE8'
				strokeWidth='2'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M95 56v36a4 4 0 0 1-4 4H43l52-40Z'
				fill='#E8F3F9'
				stroke='#00AAE8'
				strokeWidth='2'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M47.867 74 31 95l22-17.25L47.867 74Zm25.266 0L90 95 68 77.75 73.133 74Z'
				fill='#CAE0EC'
			/>
			<path
				d='M62.975 72.057 90.34 96H31.66l27.364-23.943a3 3 0 0 1 3.95 0Z'
				fill='#fff'
				stroke='#00AAE8'
				strokeWidth='2'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M58.62 76.043a4 4 0 0 1 4.76 0L89 95H33l25.62-18.957Z'
				fill='#E8F3F9'
			/>
		</SvgIcon>
	);
};
