import { SvgIcon } from '../../icons/SvgIcon';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const AutomationsNotPendingGraphic: React.FunctionComponent<IProps> = props => {
	const { className, styles } = props;
	return (
		<SvgIcon
			className={`automations-not-pending-graphic ${className || ''} ${css(...(styles || []))}`}
			height={180}
			width={180}
		>
			<circle cx='90' cy='90' r='90' fill='#E8F3F9' />
			<path d='M37 50h106a3 3 0 013 3v75H34V53a3 3 0 013-3z' fill='#fff' stroke='#00AAE8' strokeWidth='2' />
			<path d='M37 50h106a3 3 0 013 3v8H34v-8a3 3 0 013-3z' fill='#E8F3F9' stroke='#00AAE8' strokeWidth='2' />
			<circle cx='39.5' cy='55.5' r='1.5' fill='#00AAE8' />
			<circle cx='44.5' cy='55.5' r='1.5' fill='#00AAE8' />
			<circle cx='49.5' cy='55.5' r='1.5' fill='#00AAE8' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M86 75.5a2.5 2.5 0 012.5-2.5h22a2.5 2.5 0 110 5h-22a2.5 2.5 0 01-2.5-2.5z'
				fill='#00AAE8'
			/>
			<path fill='#E8F3F9' d='M45 91h16v18H45z' />
			<rect x='49.25' y='97.25' width='7.5' height='.5' rx='.25' fill='#D8D8D8' stroke='#00AAE8' strokeWidth='.5' />
			<rect x='49.25' y='99.25' width='7.5' height='.5' rx='.25' fill='#D8D8D8' stroke='#00AAE8' strokeWidth='.5' />
			<rect x='49.25' y='101.25' width='5.5' height='.5' rx='.25' fill='#D8D8D8' stroke='#00AAE8' strokeWidth='.5' />
			<rect x='49.25' y='95.25' width='2.5' height='.5' rx='.25' fill='#D8D8D8' stroke='#00AAE8' strokeWidth='.5' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M76 104a4 4 0 014-4h14a4 4 0 010 8H80a4 4 0 01-4-4zm37 0a4 4 0 014-4h14a4 4 0 010 8h-14a4 4 0 01-4-4z'
				fill='#D3DBDE'
			/>
			<path
				fill='#FFF'
				d='M104.815 76s1.065 3.38-3.996 4.68c-6.289 1.615-14.385-1.3-24.242 0C65.609 82.127 55 89 55 89m-2 22s3.508 5 13.492 5C76.476 116 87 111 87 111m7-15s7.864-8.727 18.712-7.934C123.559 88.86 126 96 126 96'
				stroke='#00AAE8'
				strokeDasharray='4'
			/>
		</SvgIcon>
	);
};
