import { StyleSheet } from 'aphrodite';
import {
	background,
	brandPrimaryText,
	destructive,
	header,
	inputBorderColor,
	mention,
	mentionDark,
	nameCircles,
	titles,
} from '../../styles/colors';
import { CssSelectors, Layouts } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	addMediaContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: 14,
	},
	dateRangePicker: {
		width: 400,
	},
	calendarIcon: {
		marginRight: 10,
	},
	clearDeadline: {
		color: brandPrimaryText,
		left: 'auto',
		position: 'absolute',
		right: -50,
		textDecoration: 'none',
		top: '50%',
		transform: 'translateY(-50%)',
	},
	contentContainers: {
		color: '#a7abad',
		fontSize: 14,
	},
	deadlineDateInput: {
		maxWidth: 126,
		paddingTop: 8,
	},
	errorMessage: {
		color: destructive,
		fontSize: 12,
		left: 0,
		position: 'absolute',
		top: '100%',
		whiteSpace: 'nowrap',
	},
	eventDateButton: {
		minHeight: 20,
	},
	eventDateInput: {
		backgroundColor: background,
		border: `1px solid ${inputBorderColor}`,
		borderRadius: 3,
		display: 'inline-flex',
		padding: 10,
		position: 'relative',
		width: '100%',
		...CssSelectors.allChildren(
			{
				alignItems: 'center',
				display: 'inline-flex',
				width: `100%`,
			},
			'button'
		),
	},
	eventEndTimeInput: {
		maxWidth: 75,
	},
	eventEndTimeInputInner: {
		width: '100%',
	},
	eventlabel: {
		color: header,
		display: 'block',
		fontSize: 14,
		marginBottom: 6,
	},
	eventStartTimeInput: {
		maxWidth: 75,
	},
	eventStartTimeInputInner: {
		width: '100%',
	},
	fieldset: {
		border: 0,
		margin: 0,
		padding: 0,
	},
	footer: {
		display: 'flex',
		justifyContent: 'flex-end',
		margin: '0 auto',
		padding: '22px 0',
		width: 922,
		...CssSelectors.allChildren(
			{
				marginRight: 20,
			},
			'button:not(:last-child)'
		),
		...CssSelectors.allChildren(
			{
				alignItems: 'center',
				borderRadius: 3,
				display: 'inline-flex',
				justifyContent: 'center',
			},
			'button'
		),
	},
	formControlWrap: {
		marginBottom: 16,
		position: 'relative',
	},
	formControlWrapLastChild: {
		marginBottom: 69,
	},
	formStyles: {
		display: 'flex',
		flexDirection: 'column-reverse',
	},
	freeImage: {
		paddingLeft: 6,
		paddingRight: 6,
	},
	iconButtons: {
		background: '#f9f9f9',
		border: `1px solid ${mentionDark}`,
		color: '#0D0F11',
		display: 'flex',
		fontSize: 16,
		marginBottom: 4,
		minWidth: 142,
		padding: 8,
	},
	infoText: {
		color: header,
		fontSize: 14,
		marginBottom: 25,
	},
	linkIcon: {
		marginRight: 10,
	},
	logoImage: {
		height: 50,
		left: 300,
	},
	logoName: {
		maxWidth: 200,
	},
	logoRemove: {
		left: 'auto',
		marginRight: 12,
		position: 'absolute',
		right: 0,
		top: '50%',
		transform: 'translateY(-50%)',
	},
	logoWrap: {
		...Layouts.horizontalStack(12),
		border: `1px solid ${nameCircles}`,
		borderRadius: 3,
		boxShadow: '0px 0px 6px rgba(139,139,139, 0.100034)',
		margin: '20px 0',
		position: 'relative',
	},
	mainContainer: {
		...Layouts.horizontalStack(18),
		alignItems: 'stretch',
		display: 'flex',
		justifyContent: 'center',
	},
	maximumCapasityInput: {
		maxWidth: 70,
		paddingTop: 8,
		...CssSelectors.allDescendants(
			{
				width: '100%',
			},
			'input'
		),
	},
	mediaModalStyle: {
		minWidth: 500,
		width: 500,
	},
	noMargin: {
		margin: 0,
	},
	notEditableNotice: {
		color: destructive,
		fontSize: 16,
		textAlign: 'center',
	},
	ownImage: {
		color: '#046CB6',
		fontSize: 14,
		marginBottom: 13,
		marginTop: 12,
	},
	previewEvent: {
		color: 'transparent',
		fontSize: 0,
	},
	saveButton: {
		alignItems: 'center',
		boxSize: 'border-box',
		height: 40,
		width: '100%',
	},
	section: {
		border: `1px solid ${mentionDark}`,
		borderRadius: 3,
		boxShadow: `0px 0px 12px ${mention}`,
		boxSizing: 'border-box',
		padding: '45px 50px 65px',
		width: 452,
	},
	sectionTitle: {
		color: brandPrimaryText,
		fontSize: 18,
		margin: '0 0 21px 0',
	},
	selectDate: {
		boxSizing: 'border-box',
		height: 40,
		width: 152,
	},
	selectDateLabel: {
		display: 'block',
		fontSize: 12,
		marginBottom: 8,
	},
	selectedDate: {},
	selectTimeWrap: {
		...Layouts.horizontalStack(16),
		justifyContent: 'center',
		marginBottom: 24,
	},
	sourceMention: {
		color: ' #A7ABAD',
		fontSize: 12,
		fontStyle: 'italic',
		minWidth: 142,
		textAlign: 'center',
	},
	submitButtom: {
		borderRadius: 3,
	},
	toggleText: {
		color: titles,
		cursor: 'pointer',
	},
});
