import { css } from 'aphrodite';
import { baseStyleSheet, bs } from '../../../styles/styles';
import { MoreMenuDropdown } from '../../MoreMenuDropdown';
import { ISelectOption } from '../../Select';
import { styleSheet } from './styles';

type IProps<T = any> = {
	title: string;
	description: string;
	onTemplateCardClick: () => void;
	onOptionClick?: (option: ISelectOption<T>) => void;
	options?: ISelectOption<T>[];
};

export const BlogPostTemplateCard = <T = any>({
	title,
	description,
	onTemplateCardClick,
	options = [],
	onOptionClick,
}: IProps<T>) => {
	return (
		<article
			className={css(
				bs.flex,
				bs.flexCol,
				bs.py8,
				bs.px6,
				bs.shadow,
				bs.itemsStart,
				bs.roundedLg,
				bs.relative,
				styleSheet.card
			)}
		>
			{options.length > 0 ? (
				<div className={css(styleSheet.moreMenu)}>
					<MoreMenuDropdown options={options} onOptionClick={onOptionClick} />
				</div>
			) : null}
			<h3>{title}</h3>
			<p>{description.length > 100 ? `${description.substring(0, 100)}...` : description}</p>
			<button className={css(baseStyleSheet.ctaButtonReverseSmall, styleSheet.button)} onClick={onTemplateCardClick}>
				View Blog
			</button>
		</article>
	);
};
