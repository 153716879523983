import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	badge: {
		display: 'flex',
		alignItems: 'center',
		fontWeight: 600,
		height: 24,
		// For some reason the border radius is somewhat non-deterministic so some badges end up with this value but others
		// end up with the value this is overwriting (8px for ex.).
		borderRadius: '18px !important',
	},
	campaignTag: {
		borderRadius: 18,
	},
});
