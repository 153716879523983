import { css } from 'aphrodite';
import textingProvidersMigration from '../../../assets/textingProvidersMigration.png';
import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet } from './styles';

export const TextingProvidersMigration = ({ temporaryNumber }: { temporaryNumber?: string }) => {
	return (
		<div className={css(styleSheet.container)}>
			<h2 className={css(styleSheet.header)}>We are migrating texting providers</h2>
			<figure className={css(styleSheet.headerImage)}>
				<img src={textingProvidersMigration} alt='Texting Campaign Registration' />
			</figure>
			<p className={css(styleSheet.text)}>
				We wanted to inform you that we are currently in the process of migrating texting providers for your account.
				Unfortunately, the current provider has taken over 30 days to recognize the brand registration we have submitted
				on your behalf.
			</p>
			<p className={css(styleSheet.text)}>
				As a result, we have provisioned a temporary number for you on our new texting provider.{' '}
				<strong className={css(baseStyleSheet.fontBold)}>Your temporary number is {temporaryNumber}</strong>. Please
				note that recipients of your texts will receive an additional message stating that this is your temporary
				number.
			</p>
			<p className={css(styleSheet.text)}>
				We apologize for any inconvenience this may cause and assure you that we are working diligently to resolve this
				issue as quickly as possible.{' '}
				<strong className={css(baseStyleSheet.fontBold)}>
					We expect your number to be ported in the next few days.
				</strong>{' '}
				If you have any questions or concerns, please don&lsquo;t hesitate to reach out to our support team.
			</p>
		</div>
	);
};
