import { baseStyleSheet } from '../../styles/styles';
import { styleSheet } from './styles';
import { StyleDeclarationValue, css } from 'aphrodite';
import { CSSProperties, ReactNode } from 'react';

export const GhostCard = ({
	children,
	style,
	stylesDeclarations,
}: {
	style?: CSSProperties;
	stylesDeclarations?: StyleDeclarationValue[];
	children: ReactNode | ReactNode[];
}) => {
	return (
		<div
			style={{ ...style }}
			className={css(
				baseStyleSheet.ghostBackgroundAnimation,
				styleSheet.background,
				styleSheet.card,
				...(stylesDeclarations || [])
			)}
		>
			Loading...
			<div className={css(baseStyleSheet.shimmer)} />
			{children}
		</div>
	);
};
