import { ConfirmationDialog, IConfirmationDialogOption } from '../../ConfirmationDialog';
import { IModalProps } from '../../Modal';
import { WarningIcon } from '../../svgs/icons/WarningIcon';
import { styleSheet } from './styles';
import { ITemplate, TemplateScope } from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	onRequestClose(result?: IConfirmationDialogOption<boolean>, canceled?: boolean): void;
	show?: boolean;
	template?: ITemplate;
}

export const TemplateSharingConfirmation: React.FC<IProps> = props => {
	const { className, show, onRequestClose, template } = props;
	const isTemplateShared = template?.scope === TemplateScope.Account;
	const shareConfirmationOptions = React.useMemo<IConfirmationDialogOption<boolean>[]>(() => {
		return [
			{
				isDestructive: isTemplateShared,
				representedObject: true,
				title: isTemplateShared ? 'Unshare' : 'Yes, share',
			},
			{
				isCancel: true,
				representedObject: false,
				title: 'Cancel',
			},
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isTemplateShared, template]);
	// @ts-ignore
	const modalProps = React.useMemo<IModalProps<IConfirmationDialogOption<boolean>>>(() => {
		return {
			className,
			isOpen: show,
			onRequestClose,
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [show, onRequestClose]);
	return (
		<>
			{show ? (
				<ConfirmationDialog
					className={`${css(styleSheet.modal)}`}
					icon={<WarningIcon />}
					modalProps={modalProps}
					options={shareConfirmationOptions}
					title={`Are you sure you want to ${!isTemplateShared ? 'share' : 'unshare'} this template?
								${
									!isTemplateShared
										? 'This will allow all users on the account to use it.'
										: 'This will remove it for all users on the account.'
								}`}
				/>
			) : null}
		</>
	);
};
