import { baseStyleSheet } from '../../styles/styles';
import { TinyPopover } from '../TinyPopover';
import { styleSheet } from './styles';
import { StyleDeclaration, css } from 'aphrodite';
import { useCombobox } from 'downshift';
import * as React from 'react';

interface IProps {
	autoFocus?: boolean;
	disabled?: boolean;
	inputStyleDeclaration?: StyleDeclaration;
	inputValue?: string;
	items: any[];
	onClickEnter?: (inputValue: string) => void;
	onInputBlur?: (inputValue: string) => void;
	onInputFocus?: () => void;
	onInputValueChange?: (inputValue: string) => void;
	onSelectItem?: (selectedItem: string) => void;
	saveButtonStyleDeclaration?: StyleDeclaration;
}

export const KeywordsSelector: React.FC<IProps> = ({
	autoFocus,
	disabled,
	inputStyleDeclaration,
	inputValue,
	items,
	onClickEnter,
	onInputBlur,
	onInputFocus,
	onInputValueChange,
	onSelectItem,
	saveButtonStyleDeclaration,
}) => {
	const [toggleAdd, setAdd] = React.useState(false);

	const { isOpen, getMenuProps, getInputProps, highlightedIndex, getItemProps, selectedItem } = useCombobox({
		inputValue,
		items,
		onInputValueChange: changes => onInputValueChange?.(changes.inputValue as string),
		onSelectedItemChange: changes => onSelectItem?.(changes.selectedItem as string),
		onStateChange: changes => {
			if (changes.type === '__input_keydown_enter__') {
				onClickEnter?.(getInputProps()?.value);
			}
		},
	});

	const handleClickSave = () => {
		onClickEnter?.(getInputProps()?.value);
		setAdd(!toggleAdd);
	};

	return (
		<div className={css(styleSheet.container)}>
			<div>
				<input
					autoFocus={autoFocus}
					className={css(baseStyleSheet.textField, styleSheet.inputField, inputStyleDeclaration)}
					disabled={disabled}
					type='text'
					{...getInputProps({
						onBlur: event => onInputBlur?.(event.target.value),
						onFocus: onInputFocus,
					})}
				/>
				<TinyPopover
					align='start'
					dismissOnOutsideAction={true}
					isOpen={isOpen}
					placement={['bottom', 'top']}
					{...getMenuProps()}
				>
					<ul className={css(styleSheet.list, !(isOpen && items.length) && styleSheet.listHidden)}>
						{isOpen &&
							items.map((item: any, index: number) => (
								<li
									key={`${item}-${index}`}
									className={css(
										styleSheet.listItem,
										highlightedIndex === index && styleSheet.listItemHighlighted,
										selectedItem === item && styleSheet.listItemSelected
									)}
									{...getItemProps({ index, item })}
								>
									<span>{item}</span>
								</li>
							))}
					</ul>
				</TinyPopover>
			</div>
			<button
				className={css(baseStyleSheet.ctaButton, styleSheet.saveButton, saveButtonStyleDeclaration)}
				disabled={disabled}
				onClick={handleClickSave}
			>
				Save
			</button>
		</div>
	);
};
