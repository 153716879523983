import { BoardStageDefinitionViewModel } from '../../../../viewmodels/AppViewModels';
import { Checkbox } from '../../Checkbox';
import { InputFieldError } from '../../InputFieldError';
import { TextInput } from '../../TextInput';
import { EditBoardStageIndicatorInfo } from '../EditBoardStageIndicatorInfo';
import KanbanBoardColumnDefinitionGripIconUrl from './kanbanBoardColumnDefinitionGrip.svg';
import KanbanBoardColumnDefinitionRemoveIconUrl from './removeKanbanBoardColumnDefinition.svg';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import {
	Draggable,
	DraggableProvided,
	DraggableProvidedDragHandleProps,
	DraggableStateSnapshot,
} from 'react-beautiful-dnd';

interface IProps {
	className?: string;
	index: number;
	isDraggingOver?: boolean;
	onRequestRemove?(column: BoardStageDefinitionViewModel): void;
	stageDefinition: BoardStageDefinitionViewModel;
}

interface IState {
	isDraggingOver?: boolean;
	showingStageIndicatorHelp?: boolean;
}

interface IEditBoardColumnDefinitionContentProps extends IProps {
	draggableProvided: DraggableProvided;
	draggableSnapshot: DraggableStateSnapshot;
}

// Needed to pull this out as an observer in order for the vm-level reorder logic to work properly
const EditBoardColumnDefinitionContent: React.FC<IEditBoardColumnDefinitionContentProps> = observer(
	(props: IEditBoardColumnDefinitionContentProps) => {
		const { draggableProvided, className = '', stageDefinition, index } = props;

		const onShowStateIndicatorCheckboxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
			props.stageDefinition.enableStageIndicator = e.target.checked;
		};

		const onRenderLeftAccessory = (dragHandleProps?: DraggableProvidedDragHandleProps) => {
			return (
				<div className={css(styleSheet.left)}>
					<img {...dragHandleProps} src={KanbanBoardColumnDefinitionGripIconUrl} />
					<span className={css(styleSheet.leftSpan)}>{`Column ${props.index + 1}`}</span>
				</div>
			);
		};

		const onRequestRemove = () => {
			if (props.onRequestRemove) {
				props.onRequestRemove(props.stageDefinition);
			}
		};

		const onRenderRightAccessory = () => {
			return (
				<button className={css(styleSheet.right)} onClick={onRequestRemove}>
					<img src={KanbanBoardColumnDefinitionRemoveIconUrl} />
				</button>
			);
		};

		const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			runInAction(() => {
				stageDefinition.name = e.target.value;
				// @ts-ignore
				stageDefinition.errorMessage = null;
			});
		};

		const infoPopover = index === 0 ? <EditBoardStageIndicatorInfo /> : <div />;

		return (
			<div
				ref={draggableProvided.innerRef}
				{...draggableProvided.draggableProps}
				className={`edit-kanban-board-column-definition ${css(styleSheet.definition)} ${className}`}
			>
				<InputFieldError className={css(styleSheet.definitionField)} errorMessage={stageDefinition.errorMessage}>
					<TextInput
						className={css(styleSheet.definitionInput)}
						inputId={stageDefinition.uuid}
						// @ts-ignore
						leftAccessory={onRenderLeftAccessory(draggableProvided.dragHandleProps)}
						onChange={onTextChange}
						rightAccessory={onRenderRightAccessory()}
						type='text'
						value={stageDefinition.name || ''}
					/>
				</InputFieldError>
				<Checkbox
					className={css(styleSheet.checkbox)}
					checked={stageDefinition.enableStageIndicator || false}
					id={`Checkbox-${stageDefinition.uuid}`}
					onChange={onShowStateIndicatorCheckboxChanged}
				>
					<span className={css(styleSheet.checkboxLabel)}>Show stage indicator</span>
				</Checkbox>
				{infoPopover}
				{draggableProvided.placeholder}
			</div>
		);
	}
);

class _EditKanbanBoardColumnDefinition extends React.Component<IProps, IState> {
	public state: IState = {
		isDraggingOver: this.props.isDraggingOver,
	};

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		if (this.state.isDraggingOver !== nextProps.isDraggingOver) {
			this.setState({
				isDraggingOver: nextProps.isDraggingOver,
			});
		}
	}

	public render() {
		const { className, stageDefinition, index, onRequestRemove } = this.props;
		// const { isDraggingOver } = this.state;
		return (
			<Draggable draggableId={stageDefinition.uuid} index={index}>
				{(draggableProvided: DraggableProvided, draggableSnapshot: DraggableStateSnapshot) => {
					return (
						<EditBoardColumnDefinitionContent
							className={className}
							stageDefinition={stageDefinition}
							draggableProvided={draggableProvided}
							draggableSnapshot={draggableSnapshot}
							index={index}
							onRequestRemove={onRequestRemove}
						/>
					);
				}}
			</Draggable>
		);
	}
}

export const EditBoardColumnDefinition = observer(_EditKanbanBoardColumnDefinition);
