export const SmallGraph = () => {
	return (
		<svg width='62' height='38' viewBox='0 0 62 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M8.23667 36H0.76333C0.341973 35.9996 0.000502864 35.7109 0 35.3547V26.6453C0.000502864 26.2891 0.341973 26.0004 0.76333 26H8.23667C8.65803 26.0004 8.9995 26.2891 9 26.6453V35.3547C8.9995 35.7109 8.65803 35.9996 8.23667 36Z'
				fill='white'
			/>
			<path
				d='M21.2367 37H13.7633C13.342 36.9995 13.0005 36.6676 13 36.2579V16.7421C13.0005 16.3324 13.342 16.0005 13.7633 16H21.2367C21.658 16.0005 21.9995 16.3324 22 16.7421V36.2579C21.9995 36.6676 21.658 36.9995 21.2367 37Z'
				fill='white'
			/>
			<path
				d='M33.2367 36H25.7633C25.342 35.9996 25.0005 35.7109 25 35.3547V26.6453C25.0005 26.2891 25.342 26.0004 25.7633 26H33.2367C33.658 26.0004 33.9995 26.2891 34 26.6453V35.3547C33.9995 35.7109 33.658 35.9996 33.2367 36Z'
				fill='white'
			/>
			<path
				d='M46.2367 35.9998H38.7633C38.3516 36.0082 38.0103 35.701 38 35.3129V12.6871C38.0103 12.299 38.3516 11.9918 38.7633 12.0002H46.2367C46.6484 11.9918 46.9897 12.299 47 12.6871V35.3129C46.9897 35.701 46.6484 36.0082 46.2367 35.9998Z'
				fill='white'
			/>
			<path
				d='M59.2367 36H51.7633C51.342 35.9996 51.0005 35.6834 51 35.2933V6.70673C51.0005 6.31658 51.342 6.00043 51.7633 6H59.2367C59.658 6.00043 59.9995 6.31658 60 6.70673V35.2933C59.9995 35.6834 59.658 35.9996 59.2367 36Z'
				fill='white'
			/>
			<circle cx='5' cy='20' r='1' fill='#3F3D56' />
			<circle cx='17' cy='12' r='1' fill='#3F3D56' />
			<circle cx='30' cy='20' r='1' fill='#3F3D56' />
			<circle cx='43' cy='7' r='1' fill='#3F3D56' />
			<circle cx='55' cy='1' r='1' fill='#3F3D56' />
			<path
				d='M29.5574 22L16.8352 11.7627L4.28718 21.8601L4 21.4483L16.8352 11.1197L29.4967 21.3084L42.0788 6.51585L42.1293 6.49386L54.8209 1L55 1.47735L42.3587 6.94921L29.5574 22Z'
				fill='#3F3D56'
			/>
			<path
				d='M61.2367 38H53.7633C53.342 37.9996 53.0005 37.6834 53 37.2933V8.70673C53.0005 8.31658 53.342 8.00043 53.7633 8H61.2367C61.658 8.00043 61.9995 8.31658 62 8.70673V37.2933C61.9995 37.6834 61.658 37.9996 61.2367 38Z'
				fill='#89C947'
			/>
		</svg>
	);
};
