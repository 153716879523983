import * as Tabs from '@radix-ui/react-tabs';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { canViewTexting } from '../../../extViewmodels/Utils';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { usePhoneNumberUserQuery } from '../../../queries';
import { KnownCategories } from '../../../viewmodels/AppViewModels';
import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { PortalDestination } from '../../components/Portal';
import { baseStyleSheet } from '../../styles/styles';
import { useCampaignParams } from '../CampaignBrowser';
import { INavigationItemProps } from '../MainContainer';
import { CampaignBrowserTabContent, CampaignBrowserTabTrigger, TabColors, TabValues, tabContent } from './presentation';
import { styleSheet } from './styles';

interface IProps extends INavigationItemProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const CampaignTabListRightPortalID = 'CampaignTabListRightPortalID';

export const Campaigns: React.FC<IProps> = observer(({ className, styles }) => {
	const userSession = useUserSession();
	const params = useParams<{ tab?: string }>();
	const history = useHistory();
	const { setCampaignBrowserParam } = useCampaignParams();
	const phoneNumberUserQuery = usePhoneNumberUserQuery();
	const socialEnabled = userSession?.account?.features?.socialMedia?.enabled;
	const cardsEnabled = userSession?.account?.features?.handwrittenCards?.enabled;
	const blogsEnabled = userSession?.account?.features?.blogFeature?.enabled;
	const textingEnabled = canViewTexting(userSession) && phoneNumberUserQuery.data?.length;
	const TAB_VALUES = Object.values(TabValues);
	const selectedTab: keyof typeof TabValues = React.useMemo(() => {
		const paramTab = params.tab?.toLowerCase();
		if (paramTab?.startsWith(TabValues.Email.toLowerCase())) {
			return TabValues.Email;
		}
		if (paramTab?.startsWith(TabValues.Social.toLowerCase())) {
			return TabValues.Social;
		}
		if (paramTab?.startsWith(TabValues.Cards.toLowerCase())) {
			return TabValues.Cards;
		}
		if (paramTab?.startsWith(TabValues.Texting.toLowerCase())) {
			return TabValues.Texting;
		}
		if (paramTab?.startsWith(TabValues.Blogs.toLowerCase())) {
			return TabValues.Blogs;
		}
	}, [params.tab]);

	React.useEffect(() => {
		if (!params.tab) {
			history.replace({ pathname: `/campaigns/email` });
		}
	}, [params.tab, history]);

	const onSelectedTabChanged = (tabValue: (typeof TabValues)[keyof typeof TabValues]) => {
		const navParamsObj = { tabUrlParam: tabValue.toLocaleLowerCase() };
		switch (tabValue) {
			case TabValues.Social:
			case TabValues.Email:
			case TabValues.Texting:
				setCampaignBrowserParam({ ...navParamsObj });
				break;
			case TabValues.Blogs:
			case TabValues.Cards:
				setCampaignBrowserParam({ category: KnownCategories.All, ...navParamsObj });
				break;
			default:
				throw new Error('Invalid tab selected');
		}
	};

	const isTabEnabled = (tabValue: keyof typeof TabValues) => {
		switch (tabValue) {
			case TabValues.Email:
				return true;
			case TabValues.Social:
				return socialEnabled;
			case TabValues.Cards:
				return cardsEnabled;
			case TabValues.Texting:
				return textingEnabled;
			case TabValues.Blogs:
				return blogsEnabled;
			default:
				return false;
		}
	};

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
			<MultiContainerHeader
				appBarContentStyles={[styleSheet.appBarContent]}
				appBarHeader={<h1 className={css(baseStyleSheet.breadcrumbTitle)}>Campaigns</h1>}
				appBarStyles={[styleSheet.appBar]}
			/>
			<Tabs.Root
				className={`tab-view ${css(styleSheet.tabView)}`}
				value={selectedTab}
				onValueChange={tab => onSelectedTabChanged(tab as (typeof TabValues)[keyof typeof TabValues])}
			>
				<Tabs.List className={`tab-view-tabbar-tabs ${css(styleSheet.tabViewTablist)}`}>
					<menu className={css(styleSheet.tabViewTabs)}>
						{TAB_VALUES.map(tab => {
							if (!isTabEnabled(tab)) {
								return null;
							}
							return (
								<CampaignBrowserTabTrigger
									key={tab}
									type={tab}
									selectedTab={selectedTab}
									isNew={tab === TabValues.Blogs}
									color={TabColors[tab]}
								/>
							);
						})}
					</menu>
					<PortalDestination id={CampaignTabListRightPortalID} />
				</Tabs.List>

				{TAB_VALUES.map(tabValue => {
					if (!isTabEnabled(tabValue)) {
						return null;
					}
					return (
						<CampaignBrowserTabContent
							key={`content-${tabValue}`}
							type={tabValue}
							selectedTab={selectedTab}
							content={tabContent[tabValue]}
						/>
					);
				})}
			</Tabs.Root>
		</div>
	);
});
