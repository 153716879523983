import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useEventLogging } from '../../../../../models/Logging';
import { getFormattedPhoneNumber } from '../../../../../models/UiUtils';
import { useErrorMessages, useTextMessaging, useToaster } from '../../../../../models/hooks/appStateHooks';
import { baseStyleSheet } from '../../../../styles/styles';
import { Button } from '../../../Button';
import { InputFieldError } from '../../../InputFieldError';
import { RadioButton } from '../../../RadioButton';
import { TextInput } from '../../../TextInput';
import { SettingsGroup } from '../../SettingsGroup';
import { SettingsGroupIcon } from '../../SettingsGroupIcon';
import { SettingsSubGroup } from '../../SettingsSubGroup';
import { styleSheet } from './styles';

interface IProps {
	expanded?: boolean;
}

// @ts-ignore
export const TextingSettings: React.FC<IProps> = observer(({ expanded }) => {
	const logger = useEventLogging();
	const toaster = useToaster();
	const errorMessages = useErrorMessages();
	const textMessagingVm = useTextMessaging();
	const [forwardingEnabled, setForwardingEnabled] = useState(
		!!textMessagingVm?.phoneNumberOrder?.callForwardingNumber?.phoneNumber?.standard
	);
	const [forwardingNumberErrorMessage, setForwardingNumberErrorMessage] = useState<string>('');
	const [forwardingPhoneNumber, setForwardingPhoneNumber] = useState('');

	const onCallForwardingComplete = React.useCallback(async () => {
		if (
			(!!textMessagingVm?.phoneNumberOrder?.id &&
				forwardingPhoneNumber !== undefined &&
				forwardingPhoneNumber !== '' &&
				forwardingNumberErrorMessage === '') ||
			!forwardingEnabled
		) {
			try {
				// @ts-ignore
				await textMessagingVm
					// @ts-ignore
					.onSetForwardingPhoneNumber(forwardingPhoneNumber, textMessagingVm?.phoneNumberOrder?.id)
					.then(() => {
						// @ts-ignore
						toaster.push({
							message: 'Changes were saved successfully!',
							type: 'successMessage',
						});
					});
			} catch (err) {
				// @ts-ignore
				logger.logApiError('Call Forwarding-Error', err);
				// @ts-ignore
				// @ts-ignore
				errorMessages.pushApiError(err);
			}
			textMessagingVm.getUserPhone();
		} else if (
			forwardingEnabled &&
			forwardingPhoneNumber === '' &&
			!!textMessagingVm?.phoneNumberOrder?.callForwardingNumber?.phoneNumber?.standard
		) {
			// @ts-ignore
			toaster.push({
				message: 'Changes were saved successfully',
				type: 'successMessage',
			});
		} else {
			setForwardingNumberErrorMessage('Please enter a valid phone number');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [forwardingPhoneNumber, forwardingEnabled]);

	useEffect(() => {
		if (textMessagingVm?.phoneNumberOrder?.callForwardingNumber?.phoneNumber?.standard) {
			setForwardingEnabled(true);
		}
	}, [textMessagingVm]);

	// #region call forwarding callbacks
	const enableForwarding = () => {
		if (!forwardingEnabled) {
			setForwardingEnabled(true);
		}
	};

	const disableForwarding = () => {
		if (forwardingEnabled) {
			setForwardingEnabled(false);
			setForwardingPhoneNumber('');
			setForwardingNumberErrorMessage('');
		}
	};

	const onForwardingInputChange = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			let value = (e.target.value || '').trim();
			try {
				value = getFormattedPhoneNumber(value);
				setForwardingPhoneNumber(value);
			} catch (error) {
				setForwardingNumberErrorMessage('Please enter a valid phone number');
				setForwardingPhoneNumber('');
			}
			unstable_batchedUpdates(() => {
				setForwardingPhoneNumber(value);
				setForwardingNumberErrorMessage('');
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[disableForwarding, enableForwarding]
	);

	const onForwardingNumberInputBlur = React.useCallback((e: React.FocusEvent<HTMLInputElement>) => {
		let value = (e.target.value || '').trim();
		try {
			value = getFormattedPhoneNumber(value);
			setForwardingPhoneNumber(value);
		} catch (error) {
			setForwardingNumberErrorMessage('Please enter a valid phone number');
			setForwardingPhoneNumber('');
		}
		unstable_batchedUpdates(() => {
			setForwardingPhoneNumber(value);
			setForwardingNumberErrorMessage('');
		});
	}, []);
	// #endregion

	const onCancelClick = () => {
		setForwardingPhoneNumber('');
		setForwardingNumberErrorMessage('');
		setForwardingEnabled(!!textMessagingVm?.phoneNumberOrder?.callForwardingNumber?.phoneNumber?.standard);
	};

	const renderForwardingNumberInput = React.useCallback(() => {
		return (
			<div className={css(styleSheet.enableForwardingContainer)}>
				<span>Enable Call Forwarding:</span>
				<InputFieldError className={css(styleSheet.fieldErrorContainer)} errorMessage={forwardingNumberErrorMessage}>
					<TextInput
						className={css(styleSheet.callForwardingInput)}
						onChange={onForwardingInputChange}
						disabled={!forwardingEnabled}
						inputId='call-forwarding-phone-number'
						type='text'
						onBlur={onForwardingNumberInputBlur}
						placeholder={
							forwardingEnabled ? textMessagingVm?.phoneNumberOrder?.callForwardingNumber?.phoneNumber?.standard : ''
						}
						value={forwardingPhoneNumber}
					/>
				</InputFieldError>
			</div>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onForwardingInputChange, onCallForwardingComplete]);

	const onToggleExpansion = (expand: boolean) => {
		if (expand) {
			setForwardingEnabled(!!textMessagingVm?.phoneNumberOrder?.callForwardingNumber?.phoneNumber?.standard);
		}
	};

	const footer = (
		<div className={css(baseStyleSheet.horizontalStack)}>
			<Button label='Save' onClick={onCallForwardingComplete} />
			<Button label='Cancel' onClick={onCancelClick} kind='reverse' />
		</div>
	);

	return (
		!!textMessagingVm?.phoneNumberOrder?.number && (
			<SettingsGroup
				className='texting-settings-forwarding'
				description='Manage number and call forwarding.'
				// Update later for out of office texting;
				expanded={expanded}
				footer={footer}
				icon={<SettingsGroupIcon type='Texting' />}
				name='Texting'
				onToggleExpansion={onToggleExpansion}
			>
				<div className={css(styleSheet.sections)}>
					<SettingsSubGroup title='General'>
						<div>
							<span>
								Your Levitate texting number is{' '}
								{getFormattedPhoneNumber(textMessagingVm?.phoneNumberOrder?.number) || ' '}
							</span>
							<a className={css(styleSheet.contactSupport)} href='mailto:support@levitateapp.com'>
								Contact support to request a new number
							</a>
						</div>
					</SettingsSubGroup>
					<SettingsSubGroup title='Call Forwarding - Designate a number to dial if someone were to call the number above'>
						<div>
							<RadioButton
								className={css(styleSheet.marginBottom)}
								id='userTextingEnablementEnable'
								name='userTextingEnablement'
								onChange={enableForwarding}
								checked={forwardingEnabled}
							>
								{renderForwardingNumberInput()}
							</RadioButton>
							<RadioButton
								id='userTExtingEnablementDisable'
								name='userTextingEnablement'
								onChange={disableForwarding}
								checked={!forwardingEnabled}
							>
								Disable Call Forwarding
							</RadioButton>
						</div>
					</SettingsSubGroup>
				</div>
			</SettingsGroup>
		)
	);
});
