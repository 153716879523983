import { StyleSheet } from 'aphrodite';
import { background, nameCircles, titles } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	addContactButton: {
		height: 40,
	},
	container: {},
	importButton: {
		width: 153,
	},
	left: {
		'::-webkit-scrollbar': {
			background: '#f9f9f9',
			width: 10,
		},
		'::-webkit-scrollbar-thumb': {
			backgroundColor: '#D8D8D8',
			borderRadius: 10,
			padding: '0 2px',
			width: 6,
		},
		background: 'white',
		borderRadius: 3,
		boxShadow: '0px 1px 4px 0px rgba(0,0,0,0.1)',
		boxSizing: 'border-box',
		height: 'calc(100vh - 238px)',
		marginLeft: 20,
		marginTop: 20,
		overflowY: 'auto',
		paddingBottom: 25,
		paddingLeft: 32,
		width: 225,
	},
	loader: {
		marginTop: 60,
	},
	main: {
		background,
		boxShadow: 'inset -4px -3px 10px 0px  rgba(0, 0, 0, 0.1)',
		display: 'flex',
		position: 'fixed',
		width: '100%',
	},
	right: {
		marginLeft: 20,
		width: 'calc(100vw - 360px)',
	},
	rightCard: {
		background: 'white',
		borderRadius: 3,
		boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.1)',
		fontSize: 14,
		height: 'calc(100vh - 197px)',
	},
	searchBarContainer: {
		borderBottom: `1px solid ${nameCircles}`,
		width: 'calc(100vw - 90px)',
	},
	sectionTitle: {
		color: titles,

		fontSize: 14,
		margin: '20px 0 5px 0',
	},
	statusFilters: {
		margin: '10px 0 5px 0',
	},
	taggingGameContainer: {
		width: 265,
	},
	tinyLoader: {
		marginLeft: 8,
	},
});
