import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		width: 300,
		...CssSelectors.allDescendants(
			{
				background: '#FFFFFF',
				border: '1px solid #BABABA',
				boxShadow: '0 2px 6px 0 rgba(0,0,0,0.20)',
				boxSizing: 'border-box',
				color: '#858585',
				fontSize: '12px',
				lineHeight: '18px',
				padding: 20,
			},
			'.popover-content'
		),
	},
	header: {
		textAlign: 'right',
	},
	indicator: {
		margin: '14px 0 12px 0',
	},
	trigger: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		margin: '0 10px 0 20px',
		width: 20,
	},
});
