import { IModalContext, ModalChildComponentContextKey } from '@AppModels/.';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '@AppModels/AppState';
import { EventLogger } from '@AppModels/Logging';
import { ICompany, IOperationResultNoValue } from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { CompaniesViewModel, CompanyViewModel } from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import { LoadingSpinner } from '../../LoadingSpinner';
import { RadioButton } from '../../RadioButton';
import './styles.less';

interface IProps extends IUserSessionComponentProps, IModalContext<ICompany>, IErrorMessageComponentProps {
	companies?: CompaniesViewModel;
	selectedCompanies?: CompanyViewModel[];
}

interface IState {
	selectedCompanies?: CompanyViewModel[];
	selectedCompany?: CompanyViewModel;
}

class _MergeCompaniesWizard extends React.Component<IProps, IState> {
	public state: IState = {
		selectedCompanies: this.props.selectedCompanies,
	};

	public render() {
		const { selectedCompany, selectedCompanies } = this.state;
		return (
			<div className='merge-companies-wizard'>
				{!!selectedCompany && selectedCompany.isMerging ? (
					<LoadingSpinner className='absolute-center' />
				) : (
					<div className='merge-companies-wizard-content'>
						<div className='merge-companies-wizard-content-header'>Merge companies</div>
						<div className='merge-companies-wizard-content-subheader'>
							Please select the <strong>main</strong> email domain for this company
						</div>
						<div className='merge-companies-wizard-content-list'>
							{!!selectedCompanies &&
								selectedCompanies.map(x => {
									return (
										<div className='merge-companies-wizard-company' key={x.id} onClick={this.onCompanySelected(x)}>
											<RadioButton
												checked={x === selectedCompany}
												className='merge-companies-wizard-company-radio-button'
												id={`merge-company-radio-${x.id}`}
												name='merge-company-radio-group'
												readOnly={true}
											/>
											<span className='company-name-with-email-domain truncate-text'>
												{this.onRenderCompanyNameWithDomain(x)}
											</span>
										</div>
									);
								})}
						</div>
						<div className='merge-companies-wizard-content-footer'>
							<button className={css(baseStyleSheet.ctaButton)} onClick={this.mergeClick}>
								Merge
							</button>
							{/* @ts-ignore */}
							<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={this.onRequestClose(null, true)}>
								Cancel
							</button>
						</div>
					</div>
				)}
			</div>
		);
	}

	private mergeClick = () => {
		const { selectedCompany, selectedCompanies } = this.state;
		const { companies, errorMessages } = this.props;
		if (selectedCompany) {
			// @ts-ignore
			const companiesToMerge = selectedCompanies.filter(x => !!x && x.id !== selectedCompany.id);
			// call merge on the companies vm (if provided) or the selected target company vm
			const promise = companies
				? companies.merge(selectedCompany, companiesToMerge)
				: selectedCompany.merge(companiesToMerge);
			if (promise) {
				EventLogger.logEvent({
					action: 'Merge',
					category: 'MergeCompaniesWizard',
					label: `${companiesToMerge.length}`,
				});

				promise
					.then(company => {
						this.onRequestClose(company)();
					})
					.catch((error: IOperationResultNoValue) => {
						// @ts-ignore
						errorMessages.pushApiError(error);
						EventLogger.logEvent(
							{
								action: 'Merge-Error',
								category: 'MergeCompaniesWizard',
							},
							{ ...error }
						);
					});
			}
		}
	};

	private onCompanySelected = (selectedCompany: CompanyViewModel) => () => {
		this.setState({
			selectedCompany,
		});
	};

	private onRenderCompanyNameWithDomain(company: CompanyViewModel) {
		const emailDomain =
			!!company.emailDomains && company.emailDomains.length > 0 ? ` (${company.emailDomains[0]})` : '';
		return `${company.companyName}${emailDomain}`;
	}

	private onRequestClose =
		(company?: ICompany, canceled = false) =>
		() => {
			const { selectedCompanies } = this.state;
			if (canceled) {
				EventLogger.logInput(
					'MergeCompaniesWizard',
					'Cancel',
					'Click',
					selectedCompanies ? { selectedCompanies: selectedCompanies.length } : undefined
				);
			}

			if (this.props.parentModal) {
				this.props.parentModal.onRequestClose(company, canceled);
			}
		};
}

const MergeCompaniesWizardAsObserver = observer(_MergeCompaniesWizard);
export const MergeCompaniesWizard = inject(
	UserSessionViewModelKey,
	ModalChildComponentContextKey,
	ErrorMessagesViewModelKey
)(MergeCompaniesWizardAsObserver);
