import { brandSecondary, titles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { ContactsSelfImportContentPadding } from '../ContactsSelfImport/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		color: titles,
		padding: ContactsSelfImportContentPadding,
		textAlign: 'center',
	},
	footer: {
		alignItems: 'center',
		display: 'flex',
		marginTop: 20,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	importOptions: {
		marginBottom: 45,
	},
	importOptionsRadio: {
		':not(:first-child)': {
			marginTop: 10,
		},
	},
	options: {
		display: 'inline-block',
		marginBottom: 40,
		marginLeft: 'auto',
		marginRight: 'auto',
		textAlign: 'left',
	},
	optionsSubtitle: {
		fontSize: 14,
		marginBottom: 4,
	},
	optionsTitle: {
		fontSize: 18,
		marginBottom: 20,
	},
	searchField: {
		marginBottom: 20,
		width: '100%',
	},
	tagOptions: {},
	uploadField: {
		background: '#F9F9F9',
		border: `1px dashed ${brandSecondary}`,
		borderRadius: 4,
		height: 300,
		position: 'relative',
	},
	uploadSpreadsheetIcon: {
		boxShadow: '0 1px 2px 0 rgba(0,0,0,0.20)',
	},
	uploadTarget: {
		alignItems: 'center',
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		width: '100%',
	},
	uploadTargetOverlay: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		...CssSelectors.allChildren(
			{
				marginTop: 10,
			},
			':not(:first-child)'
		),
	},
	uploadTargetOverlayArrow: {
		display: 'block',
	},
	uploadTargetOverlayButton: {
		fontSize: 16,
	},
	uploadTargetOverlayChosenFile: {
		color: titles,
		fontSize: 18,
	},
	uploadTargetOverlayMessage: {
		color: '#858585',
		fontSize: 16,
	},
});
