import { IDashboardEmails } from '../../../../extViewmodels';
import { CircularProgress } from '../CircularProgress';
import { DashboardCard, DashboardCardStat, DashboardCardTag } from '../DashboardCard';
import { CommFeaturedTemplates, CommGhostCard, CommStatHeader, EmptyCommStat, OpenReplyStats } from './presentation';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

export function DashboardCommStatsCard({
	data,
	status,
}: {
	data: IDashboardEmails;
	status: 'success' | 'loading' | 'error';
}) {
	const history = useHistory();
	if (!data && status !== 'success') {
		return <CommGhostCard />;
	}

	/* data.communicationStats.mostRecentCampaign = null;
	data.communicationStats.featuredTemplates = [
		{
			name: 'Levitate Announcement - Coverage Review',
			summary:
				"Let clients know you've commited to keeping in touch and are available for a complimentary review of their coverage anytime.",
		},
		{
			name: 'Levitate Announcement - Coverage Review',
			summary:
				"Let clients know you've commited to keeping in touch and are available for a complimentary review of their coverage anytime.",
		},
		{
			name: 'Levitate Announcement - Coverage Review',
			summary:
				"Let clients know you've commited to keeping in touch and are available for a complimentary review of their coverage anytime.",
		},
	]; */

	const mostRecentCampaign = data.communicationStats?.mostRecentCampaign;
	const totalEmailsSent = data.communicationStats?.totalEmailsSent ?? 0;
	const totalTextsSent = data.communicationStats?.totalTextsSent ?? 0;
	const totalMessagesSent = totalEmailsSent + totalTextsSent;
	const featuredTemplates = data.communicationStats?.featuredTemplates ?? [];

	if (mostRecentCampaign == null && (data.communicationStats?.averageOpenRate ?? 0) === 0) {
		return (
			<EmptyCommStat
				featuredTemplates={featuredTemplates}
				totalEmailsSent={totalEmailsSent}
				totalTextsSent={totalTextsSent}
			/>
		);
	}

	return (
		<DashboardCard styleDeclaration={styleSheet.root}>
			<div
				className={css(
					styleSheet.messageSentHeader,
					styleSheet.messageSentHeaderFull,
					styleSheet.messageSentHeaderSeparator
				)}
			>
				<CommStatHeader totalEmailsSent={totalEmailsSent} totalTextsSent={totalTextsSent} />
				{totalMessagesSent > 0 ? (
					<div className={css(styleSheet.messageSentHeaderTotal)}>
						<p>Wow, you&apos;ve kept in touch</p>
						<p>
							<DashboardCardStat
								styleDeclaration={[styleSheet.messageSentHeaderNumber, styleSheet.messageSentHeaderNumberSmaller]}
							>
								{totalMessagesSent.toLocaleString()}
							</DashboardCardStat>{' '}
							times!
						</p>
					</div>
				) : null}
			</div>
			<div className={css(styleSheet.root)}>
				<div className={css(styleSheet.cardColumn)}>
					{mostRecentCampaign != null ? (
						<div className={css(styleSheet.cardBodyColumn)}>
							<div className={css(styleSheet.cardBodyColumnInner)}>
								<div className={css(styleSheet.statsContainer)}>
									<DashboardCardTag styleDeclaration={styleSheet.tag}>Most Recent Campaign</DashboardCardTag>
									<section>
										<h3 className={css(styleSheet.campaignTitle)}>
											{/* @ts-ignore */}
											{/* @ts-ignore */}
											{data.communicationStats.mostRecentCampaign.subject}
										</h3>
										<span className={css(styleSheet.campaignSendDate)}>
											Sent {moment(mostRecentCampaign.completedDate).fromNow()}
										</span>
									</section>
									<button
										type='button'
										className={css(styleSheet.linkButton)}
										onClick={() => history.push(`/reporting/group-email/${mostRecentCampaign.id}`)}
									>
										View Campaign
									</button>
								</div>
							</div>
							<div className={css(styleSheet.cardBodyColumnInner)}>
								<div className={css(styleSheet.emailOpenRateProgressContainer)}>
									<CircularProgress
										width={150}
										// @ts-ignore
										// @ts-ignore
										progress={Math.round((100 * mostRecentCampaign.openCount) / mostRecentCampaign.totalEmailsSent)}
										label='Open rate'
									/>
								</div>
							</div>
						</div>
					) : (
						<div className={css(styleSheet.cardBodyColumn)}>
							<OpenReplyStats
								// @ts-ignore
								openRate={data.communicationStats?.averageOpenRate}
								replyRate={data.communicationStats?.averageReplyRate}
							/>
						</div>
					)}
				</div>
				{mostRecentCampaign != null ? (
					<div className={css(styleSheet.cardColumn)}>
						<div className={css(styleSheet.cardBodyColumn)}>
							<OpenReplyStats
								// @ts-ignore
								openRate={data.communicationStats?.averageOpenRate}
								replyRate={data.communicationStats?.averageReplyRate}
							/>
						</div>
					</div>
				) : (
					<CommFeaturedTemplates data={featuredTemplates} />
				)}
			</div>
		</DashboardCard>
	);
}
