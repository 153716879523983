import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { RecipientListItem } from '../RecipientListItem';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	onRecipientItemClick: (recipient: Api.TextRecipientViewModel | Api.IPhoneNumber) => void;
	recipients: (Api.TextRecipientViewModel | Api.IPhoneNumber)[];
	toNumbers?: Api.ITextRecipient[];
}

export const RecipientList: React.FC<IProps> = ({ className = '', onRecipientItemClick, recipients, toNumbers }) => {
	return (
		<div className={`${css(styleSheet.container)} ${className}`}>
			<div className={css(styleSheet.header)}>Select a Contact</div>
			<div className={css(styleSheet.text)}>Select a contact to see their profile.</div>
			<div>
				{recipients.map(t => (
					<RecipientListItem
						key={t instanceof Api.TextRecipientViewModel ? t.id : t.metadata?.e164 ?? t.value}
						onClick={onRecipientItemClick}
						recipient={t}
						toNumbers={toNumbers}
					/>
				))}
			</div>
		</div>
	);
};
