import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';

const useIsCompactNav = ({ compactNavPaths }: { compactNavPaths: { exact?: boolean; path: string }[] }) => {
	const isTablet = useMediaQuery({ maxWidth: 1200 });
	const { location } = useHistory();
	const currentPath = (location.pathname ?? '').toLowerCase();
	const isCompactPath = !!compactNavPaths.find(x =>
		x.exact ? x.path.toLowerCase() === currentPath : new RegExp(`^${x.path}`, 'i').test(currentPath)
	);
	return isTablet || isCompactPath;
};

const MainCompactNavPaths: { exact?: boolean; path: string }[] = [
	{
		exact: false,
		path: '/dataBoards',
	},
	{
		exact: false,
		path: '/people',
	},
	{
		path: '/automations',
	},
	{
		exact: false,
		path: '/companies',
	},
	{
		path: '/campaigns',
	},
	{
		path: '/content-calendar',
	},
	{
		path: '/notes-action-items',
	},
	{
		path: '/registration',
	},
	{
		path: '/reporting',
	},
	{
		path: '/texting',
	},
];

export const useIsMainNavCompact = () => {
	return useIsCompactNav({ compactNavPaths: MainCompactNavPaths });
};

const CoffeeCompactNavPaths: { exact?: boolean; path: string }[] = [
	{
		exact: false,
		path: '/people',
	},
	{
		path: '/automations',
	},
	{
		exact: false,
		path: '/companies',
	},
	{
		exact: false,
		path: '/templates',
	},
	{
		exact: false,
		path: '/reporting',
	},
];

export const useIsCoffeeNavCompact = () => {
	return useIsCompactNav({ compactNavPaths: CoffeeCompactNavPaths });
};
