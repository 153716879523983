import { StyleSheet } from 'aphrodite';
import { brandSecondary } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	actionItemsFilter: {
		width: 245,
		borderRadius: 6,
	},
	searchEmployeeBox: {
		fontSize: 14,
		marginTop: 16,
	},
	selectAnEmployeeCTA: {
		marginBotton: 5,
		marginTop: 100,
	},
	selectAnEmployeeModal: {
		maxWidth: 360,
		...CssSelectors.allChildren(
			{
				marginTop: 80,
			},
			'.cta-button'
		),
	},
	title: {
		color: brandSecondary,
		fontSize: 22,
		fontWeight: 600,
		maxWidth: '80%',
	},
});
