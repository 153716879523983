import { NoConversationTextingGraphic } from '../../svgs/graphics/NoConversationTextingGraphic';
import { ContactError } from '../ContactError';
import { NoConversationMultiPhoneSelect } from '../MultiPhoneSelect';
import { styleSheet } from './styles';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';

interface IProps {
	onPhoneNumberSelected: (phoneNumber: Api.IPhoneNumber) => void;
	recipient?: Api.TextRecipientViewModel;
}

export const ConversationResolve: React.FC<IProps> = observer(props => {
	const { onPhoneNumberSelected, recipient } = props;
	const textableRecipientPhoneNumbers = Api.VmUtils.getTextMessageCapablePhoneNumbers(recipient?.phoneNumbers);

	const renderContent = React.useCallback(() => {
		if (!recipient) {
			return (
				<div className={`${css(styleSheet.container)} ${css(styleSheet.noTextingConversationGraphic)}`}>
					<NoConversationTextingGraphic />
				</div>
			);
		}

		if (textableRecipientPhoneNumbers?.length > 1 && !recipient?.selectedPhoneNumber) {
			// a contact has been selected but they have multiple numbers
			// conversation has not been created yet because user has to
			// select which number to send texts to
			return <NoConversationMultiPhoneSelect recipient={recipient} onPhoneNumberSelected={onPhoneNumberSelected} />;
		}

		// contact does not have a phone number
		if (!textableRecipientPhoneNumbers?.length) {
			return (
				<div className={css(styleSheet.error)}>
					<ContactError
						text={`${recipient?.firstName || 'Contact'} does not have a phone number. Please add one to start texting.`}
					/>
				</div>
			);
		}

		// a contact has been selected but their number is invalid
		if (textableRecipientPhoneNumbers?.length === 1 && !textableRecipientPhoneNumbers[0].metadata) {
			return (
				<div className={css(styleSheet.error)}>
					<div>
						<ContactError
							text={`${
								recipient?.firstName || 'Contact'
							} does not have a valid phone number. Please edit or add a new number to start texting.`}
						/>
					</div>
				</div>
			);
		}
		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [recipient, onPhoneNumberSelected]);

	return <div className={css(styleSheet.container)}>{renderContent()}</div>;
});
