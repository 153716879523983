import AdTagPlusIconUrl from './addPlusIcon.svg';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	title?: string;
}

export const AddButton: React.FC<IProps> = props => {
	const { className = '', title, ...restProps } = props;
	return (
		<button className={`${css(styleSheet.button)} ${className}`} {...restProps}>
			<img src={AdTagPlusIconUrl} />
			{title && <span>{title}</span>}
			{props.children}
		</button>
	);
};
