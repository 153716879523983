import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { PendingActions, UserViewModel } from '../../../extViewmodels';
import { useEventLogging } from '../../../models/Logging';
import { useErrorMessages, useTextMessaging, useToaster, useUserSession } from '../../../models/hooks/appStateHooks';
import { useResolvePhoneNumber, useTelephonyTextSettingsQuery } from '../../../queries';
import { AppBarNavigationItemPortalDestinationId } from '../../components/AppBar';
import { Button } from '../../components/Button';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { Modal } from '../../components/Modal';
import { Portal } from '../../components/Portal';
import { TextArea } from '../../components/TextArea';
import { Toggle } from '../../components/Toggle';
import { DangerMessage } from '../../components/errorMessages/DangerMessage';
import { WarningIcon } from '../../components/svgs/icons/WarningIcon';
import { Conversations } from '../../components/texting/Conversations';
import { Setup } from '../../components/texting/Setup';
import { TextingProvidersMigration } from '../../components/texting/TextingProvidersMigration';
import { grayIconFill } from '../../styles/colors';
import { baseStyleSheet } from '../../styles/styles';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const TextingBase: React.FC<IProps> = ({ className, styles }) => {
	const errorMessages = useErrorMessages();
	const logger = useEventLogging();
	const textMessagingVM = useTextMessaging();
	const userSession = useUserSession();
	const toaster = useToaster();

	const history = useHistory();

	// @ts-ignore
	const userViewModel = useRef(new UserViewModel(userSession, userSession.user)).current;

	const [showManageTextAutoReply, setShowManageTextAutoReply] = React.useState(false);
	const [textAutoReplyEnabled, setTextAutoReplyEnabled] = React.useState(false);
	const [textAutoReplyMessage, setTextAutoReplyMessage] = React.useState('');
	const [saveButtonEnabled, setSaveButtonEnabled] = React.useState(true);
	const [showRegistrationRequiredModal, setShowRegistrationRequiredModal] = React.useState(false);

	const [toggleProvidersMigrationModal, setProvidersMigrationModal] = React.useState(false);

	const shouldRenderMigrationWarning = textMessagingVM?.phoneNumberOrder?.isUsingTemporarySendingNumber;
	const phoneNumberId = textMessagingVM?.phoneNumberOrder?.sendingPhoneNumberId;

	const resolvePhoneNumberQuery = useResolvePhoneNumber({
		enabled: Boolean(shouldRenderMigrationWarning && phoneNumberId),
		phoneNumberId,
	});
	// turn off caching as we don't want to read a stale value and block the user from texting after reg. is successful
	const telephonyTextSettingsQuery = useTelephonyTextSettingsQuery({ cacheTime: 0 });
	React.useEffect(() => {
		if (
			textMessagingVM.isLoaded &&
			textMessagingVM.number == null &&
			telephonyTextSettingsQuery.data &&
			!telephonyTextSettingsQuery.data.canRequestTextingPhoneNumber
		) {
			history.push('/texting/registration');
		}
	}, [telephonyTextSettingsQuery.data, history, textMessagingVM.isLoaded, textMessagingVM.number]);

	const requiresCampaignRegistration = telephonyTextSettingsQuery.data?.requiresCampaignRegistration;
	React.useEffect(() => {
		setShowRegistrationRequiredModal(requiresCampaignRegistration);
	}, [requiresCampaignRegistration]);

	const shouldHaveBrandRegistered = userSession.pendingActions.find(
		a => a === PendingActions.TextingRegistrationBrandRequired
	);

	React.useEffect(() => {
		if (shouldHaveBrandRegistered) {
			history.replace(`/texting/registration/form`);
		}
	}, [shouldHaveBrandRegistered, history]);

	useEffect(() => {
		// @ts-ignore
		if (textMessagingVM.isLoaded === undefined) {
			// @ts-ignore
			textMessagingVM.getUserPhone();
		}
	}, [textMessagingVM]);

	useEffect(() => {
		setTextAutoReplyEnabled(userViewModel.toJs().textAutoReply?.enabled ?? false);
		setTextAutoReplyMessage(
			userViewModel.toJs().textAutoReply?.message ??
				'[Auto Reply] Thanks for your text. I am out of the office right now and will respond as soon as I can.'
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userViewModel.isLoaded]);

	useEffect(() => {
		setSaveButtonEnabled(!!textAutoReplyMessage && textAutoReplyMessage.length > 0);
	}, [textAutoReplyMessage]);

	const setupComplete = async () => {
		try {
			// @ts-ignore
			return await textMessagingVM.getUserPhone();
		} catch (err) {
			// @ts-ignore
			logger.logApiError('GetUserPhone-Error', err);
			// @ts-ignore
			// @ts-ignore
			errorMessages.push(err);
		}
	};

	const onSaveClicked = async () => {
		try {
			await userViewModel.updateTextAutoReplySettings({
				enabled: true,
				message: textAutoReplyMessage,
			});

			// @ts-ignore
			toaster.push({
				message: 'Successfully updated text out of office response.',
				type: 'successMessage',
			});

			toggleShowManageTextAutoReplyModal();
		} catch (err) {
			// @ts-ignore
			// @ts-ignore
			errorMessages.push(err);
		}
	};

	const onCancelClicked = () => {
		toggleShowManageTextAutoReplyModal();
	};

	const toggleShowManageTextAutoReplyModal = () => {
		setShowManageTextAutoReply(!showManageTextAutoReply);
	};

	const onCloseRegistrationWarningClicked = () => {
		setShowRegistrationRequiredModal(false);
	};

	const onTextAreaUpdated = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setTextAutoReplyMessage(e.target.value);
	};

	const onToggleCheckChanged = async (checked: boolean) => {
		if (checked) {
			setShowManageTextAutoReply(true);
		}

		if (!checked) {
			try {
				await userViewModel.updateTextAutoReplySettings({
					enabled: false,
					message: textAutoReplyMessage,
				});
			} catch (err) {
				// @ts-ignore
				// @ts-ignore
				errorMessages.push(err);
			}
		}

		setTextAutoReplyEnabled(checked);
	};

	const renderTextingViews = !shouldHaveBrandRegistered ? (
		// @ts-ignore
		textMessagingVM.id ? (
			<Conversations />
		) : (
			<Setup onSetupComplete={setupComplete} />
		)
	) : null;

	const renderContactVerbiage = () => {
		return telephonyTextSettingsQuery.data?.customerSuccessManagerEmailAddress ? (
			<span>
				Please contact your success manager at{' '}
				<b>
					<a
						className={css(styleSheet.modalBrandLink)}
						href={`mailto:${telephonyTextSettingsQuery.data.customerSuccessManagerEmailAddress}}`}
					>
						{telephonyTextSettingsQuery.data.customerSuccessManagerEmailAddress}
					</a>
				</b>{' '}
				for assistance with registering.
			</span>
		) : (
			<span>
				Please contact Levitate Support at{' '}
				<b>
					<a className={css(styleSheet.modalBrandLink)} href='mailto:support@levitateapp.com'>
						support@levitateapp.com
					</a>
				</b>{' '}
				for assistance with registering.
			</span>
		);
	};

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
			<Portal destination={AppBarNavigationItemPortalDestinationId}>
				<h1 className={css(baseStyleSheet.breadcrumbTitle)}>Texting</h1>
			</Portal>
			<div className={css(styleSheet.autoReply)}>
				{shouldRenderMigrationWarning ? (
					<DangerMessage type='warning'>
						Your texts are being sent with a temporary number while we are migrating providers.{' '}
						<button className={css(baseStyleSheet.brandLink)} onClick={() => setProvidersMigrationModal(true)}>
							<strong className={css(baseStyleSheet.fontBold)}>Read More</strong>
						</button>
					</DangerMessage>
				) : (
					<div />
				)}
				<div>
					<Toggle
						id='outOfOfficeToggle'
						text='Automatic "Out of Office" response'
						uncheckedColor={grayIconFill}
						isOn={textAutoReplyEnabled}
						onToggleCheckChanged={onToggleCheckChanged}
					/>
					{textAutoReplyEnabled && (
						<button
							className={css(baseStyleSheet.link, baseStyleSheet.brandLink, styleSheet.manageButton)}
							onClick={toggleShowManageTextAutoReplyModal}
						>
							<span>Manage</span>
						</button>
					)}
				</div>
			</div>
			<div className={css(styleSheet.wrapper)}>
				{textMessagingVM.isLoading || !textMessagingVM.isLoaded || telephonyTextSettingsQuery.isLoading ? (
					<LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='large' />
				) : (
					renderTextingViews
				)}
			</div>
			<Modal
				isOpen={toggleProvidersMigrationModal}
				onRequestClose={() => setProvidersMigrationModal(false)}
				useDefaultHeader={true}
			>
				<TextingProvidersMigration temporaryNumber={resolvePhoneNumberQuery?.data?.number} />
			</Modal>
			<Modal isOpen={showManageTextAutoReply} useDefaultHeader={true} onRequestClose={onCancelClicked}>
				<div className={css(styleSheet.modal)}>
					<div className={css(styleSheet.modalTitle)}>Automatic &quot;Out of Office&quot; Response</div>
					<div>
						<TextArea
							autoComplete='off'
							className={css(styleSheet.autoReplyMessageTextField)}
							inputId='create-email-templates-summary-input'
							onChange={onTextAreaUpdated}
							value={textAutoReplyMessage}
						/>
						<div className={css(styleSheet.footer)}>
							<Button label='Save' size='normal' disabled={!saveButtonEnabled} onClick={onSaveClicked} />
							<Button label='Cancel' size='normal' kind='reverse' onClick={onCancelClicked} />
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				isOpen={showRegistrationRequiredModal}
				useDefaultHeader={true}
				onRequestClose={onCloseRegistrationWarningClicked}
			>
				<div className={css(styleSheet.modal, styleSheet.centerText)}>
					<div className={css(styleSheet.modalIcon)}>
						<WarningIcon />
					</div>
					<div className={css(styleSheet.modalTitle)}>
						<span>Effective December 1, 2024</span>
					</div>
					<div className={css(styleSheet.modalDescription)}>
						<p>
							Due to new regulations being enforced by cell carriers, your Levitate text message traffic will be blocked
							on December 1st by Syniverse <b>if you do not register.</b>
						</p>
						<p>
							<b>
								If you want to continue to use texting through Levitate, we are more than happy to assist you through
								this process.
							</b>{' '}
							{renderContactVerbiage()} For quick answers related to text registration, refer to the Levitate{' '}
							<a
								className={css(styleSheet.modalBrandLink)}
								href='https://help.levitate.ai/category/349-text-registration'
								target='_blank'
								rel='noreferrer'
							>
								help
							</a>{' '}
							articles.
						</p>
						<p>If you do not wish to continue using this feature, please let us know and we will disable it.</p>
					</div>
					<div className={css(styleSheet.footer, styleSheet.justifyCenter)}>
						<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={onCloseRegistrationWarningClicked}>
							<span>Close</span>
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export const Texting = observer(TextingBase);
