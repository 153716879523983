import { StyleSheet } from 'aphrodite';
import { inputBorderColor } from '../../../../styles/colors';

export const styleSheet = StyleSheet.create({
	dropdownRow: {
		display: 'flex',
		gap: 8,
		fontSize: 12,
		padding: 4,
	},
	dropdownContentInnerOverrides: {
		borderColor: inputBorderColor,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
		borderTopRightRadius: 0,
		maxHeight: 500,
	},
});
