import * as Api from '@ViewModels';
import * as React from 'react';
import { ContactSortKey } from '../../../../models';
import { HasBeenNotifiedExcludingContactWillSnoozeResourceSelectorStorageKey } from '../../../../models/Storage';
import { getDisplayName } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useContactResourceSelectorCardCountQuery, useContactResourceSelectorFilterQuery } from '../../../../queries';
import { IConfirmationDialogOption } from '../../../components/ConfirmationDialog';
import { ISelectOption } from '../../../components/Select';
import { ISelectBoxOption } from '../../../components/SelectBox';
import {
	BulkDeliveryMethodOptions,
	bulkDeliveryMethodFromFilterCriteria,
} from '../../../components/email/TagEmailRecipientsList';

const resourceSortOptions = [
	{
		dataContext: ContactSortKey.Handle,
		id: 'sort-option-first-name',
		text: 'Sort by first name A - Z',
		type: 'default',
	},
	{
		dataContext: ContactSortKey.HandleReverse,
		id: 'sort-option-last-name',
		text: 'Sort by last name A - Z',
		type: 'default',
	},
	{
		dataContext: ContactSortKey.KeyDate,
		id: 'sort-option-type',
		text: `Sort by birthday`,
		type: 'default',
	},
] as ISelectOption<ContactSortKey>[];

export const useHwcBirthdayRecipients = ({
	onSendFromCurrentUserSelected,
	onSendFromContactOwnerSelected,
	onSendFromSelectedEmployee,
	onShowingFiltersChanged,
	onTagListSearchChange,
}: {
	onSendFromCurrentUserSelected?: () => void;
	onSendFromContactOwnerSelected?: () => void;
	onSendFromSelectedEmployee?: () => void;
	onShowingFiltersChanged?: () => void;
	onTagListSearchChange?: () => void;
} = {}) => {
	const userSession = useUserSession();
	const [selectedSortByOption, setSelectedSortByOption] = React.useState<ISelectOption<ContactSortKey>>(
		resourceSortOptions[0]
	);
	const [filterCriterias, setFilterCriterias] = React.useState<Api.IContactFilterCriteria[]>([
		{
			property: Api.ContactFilterCriteriaProperty.All,
		},
		{
			criteria: [
				{
					property: Api.ContactFilterCriteriaProperty.CapableOf,
					value: 'ReceiveEmail',
				},
				{
					property: Api.ContactFilterCriteriaProperty.CapableOf,
					value: 'ReceiveCard',
				},
				{
					property: Api.ContactFilterCriteriaProperty.CapableOf,
					value: 'ReceiveText',
				},
			],
			op: Api.FilterOperator.Or,
		},
	]);
	const [tagSearchCriterias, setTagSearchCriterias] = React.useState<Api.IContactFilterCriteria[]>([]);
	const allCriterias = React.useMemo(() => {
		return [...filterCriterias, ...tagSearchCriterias];
	}, [tagSearchCriterias, filterCriterias]);
	const [contactIdsToOmit, setContactIdsToOmit] = React.useState<string[]>([]);
	const contactResourceSelectorCountQuery = useContactResourceSelectorCardCountQuery({
		filterRequest: {
			excludeIds: contactIdsToOmit,
			filter: {
				criteria: allCriterias,
			},
		},
		resourceSelectorId: Api.ResourceSelectorId.HappyBirthday,
	});
	const contactResourceSelectorFilterQuery = useContactResourceSelectorFilterQuery({
		filterRequest: {
			filter: {
				criteria: allCriterias,
			},
		},
		resourceSelectorId: Api.ResourceSelectorId.HappyBirthday,
		sortBy: selectedSortByOption.dataContext,
	});
	const [isSendFromDropdownOpen, setIsSendFromDropdownOpen] = React.useState(false);
	const [sendEmailFrom, setSendEmailFrom] = React.useState<Api.ISendEmailFrom>(
		userSession.canSendOnBehalf ? Api.SendEmailFrom.ContactOwner : Api.SendEmailFrom.CurrentUser
	);

	const [sendEmailFromUser, setSendEmailFromUser] = React.useState<Api.IUser>(null);
	const [selectAnEmployeeModalIsOpen, setSelectAnEmployeeModalIsOpen] = React.useState(false);
	const [showingAdvancedFiltersFlyout, setShowingAdvancedFiltersFlyout] = React.useState(false);
	const [isSuppressionIntroModalOpen, setIsSuppressionIntroModalOpen] = React.useState(false);
	const [contactToRemoveAfterSuppressionIntro, setContactToRemoveAfterSuppressionIntro] =
		React.useState<Api.IContact>();
	const sendFromDropdownAnchorText = React.useMemo(() => {
		switch (sendEmailFrom) {
			case Api.SendEmailFrom.ContactOwner:
				return 'Contact owner of the recipient';
			case Api.SendEmailFrom.CurrentUser:
				return getDisplayName(userSession.user);
			case Api.SendEmailFrom.SelectedUser:
				return sendEmailFromUser ? getDisplayName(sendEmailFromUser) : 'Select User';
			default:
				return '';
		}
	}, [sendEmailFrom, sendEmailFromUser, userSession]);
	const showingFilterOptions = React.useMemo(() => {
		const contactFilterOptions: ISelectBoxOption<Api.IContactFilterCriteria>[] = [
			{
				title: 'All contacts',
				value: {
					property: Api.ContactFilterCriteriaProperty.All,
				},
			},
			{
				title: 'Contacts I own',
				value: {
					property: Api.ContactFilterCriteriaProperty.OwnedBy,
					value: userSession?.user?.id,
				},
			},
			{
				title: 'All connections',
				value: {
					property: Api.ContactFilterCriteriaProperty.Connections,
				},
			},
		];
		if (sendEmailFrom === Api.SendEmailFrom.SelectedUser && sendEmailFromUser) {
			contactFilterOptions.push({
				title: 'Contacts they own',
				value: {
					property: Api.ContactFilterCriteriaProperty.OwnedBy,
					value: sendEmailFromUser.id,
				},
			});
		}
		return contactFilterOptions;
	}, [userSession, sendEmailFrom, sendEmailFromUser]);
	const selectedShowingFilterOption = React.useMemo(() => {
		const ownedByCriteria = filterCriterias.find(x => x.property === Api.ContactFilterCriteriaProperty.OwnedBy);
		if (ownedByCriteria) {
			return showingFilterOptions.find(
				x => x.value?.property === Api.ContactFilterCriteriaProperty.OwnedBy && x.value?.value === ownedByCriteria.value
			);
		}
		const allconnectionsCriteria = filterCriterias.find(
			x => x.property === Api.ContactFilterCriteriaProperty.Connections
		);
		if (allconnectionsCriteria) {
			return showingFilterOptions.find(x => x.value?.property === Api.ContactFilterCriteriaProperty.Connections);
		}
		return showingFilterOptions.find(x => x.value?.property === Api.ContactFilterCriteriaProperty.All);
	}, [filterCriterias, showingFilterOptions]);
	const selectedDeliveryMethodOption = bulkDeliveryMethodFromFilterCriteria(filterCriterias);
	const onSendBehalfOfCurrentUser = async () => {
		setSendEmailFrom(Api.SendEmailFrom.CurrentUser);

		setSendEmailFromUser(null);
		setIsSendFromDropdownOpen(false);
		// If OwnedBy filter already exists and is set to another user's id change it to the current users'.
		const ownedByFilterIndex = filterCriterias.findIndex(
			criteria => criteria.property === Api.ContactFilterCriteriaProperty.OwnedBy
		);

		if (ownedByFilterIndex > -1 && filterCriterias[ownedByFilterIndex].value !== userSession.user.id) {
			const newCriterias = [...filterCriterias];
			newCriterias[ownedByFilterIndex] = {
				property: Api.ContactFilterCriteriaProperty.OwnedBy,

				value: userSession.user.id,
			};
			setFilterCriterias(newCriterias);
		}
		onSendFromCurrentUserSelected?.();
	};
	const onSendBehalfOfContactOwner = async () => {
		setSendEmailFrom(Api.SendEmailFrom.ContactOwner);

		setSendEmailFromUser(null);
		setIsSendFromDropdownOpen(false);
		// Clear out any other contact filter criteria and add all
		setFilterCriterias(
			Api.VmUtils.concatContactFilterCriteria(
				filterCriterias.filter(
					criteria =>
						![
							Api.ContactFilterCriteriaProperty.All,
							Api.ContactFilterCriteriaProperty.OwnedBy,
							Api.ContactFilterCriteriaProperty.Connections,
						].includes(criteria.property)
				),
				[{ property: Api.ContactFilterCriteriaProperty.All }]
			)
		);
		onSendFromContactOwnerSelected?.();
	};
	const onSendOnBehalfSelectEmployee = async () => {
		setSelectAnEmployeeModalIsOpen(true);
		setIsSendFromDropdownOpen(false);
		onSendFromSelectedEmployee?.();
	};
	const onSaveSelectAnEmployee = (user: Api.IUser) => {
		setSendEmailFrom(Api.SendEmailFrom.SelectedUser);

		setSendEmailFromUser(user);
		setSelectAnEmployeeModalIsOpen(false);
		// If OwnedBy filter already exists and is set to another user's id change it to the selected users.
		const ownedByFilterIndex = filterCriterias.findIndex(
			criteria => criteria.property === Api.ContactFilterCriteriaProperty.OwnedBy
		);

		if (ownedByFilterIndex > -1 && filterCriterias[ownedByFilterIndex].value !== user.id) {
			const newCriterias = [...filterCriterias];
			newCriterias[ownedByFilterIndex] = {
				property: Api.ContactFilterCriteriaProperty.OwnedBy,

				value: user.id,
			};
			setFilterCriterias(newCriterias);
		}
	};
	const onTagListSearchesChanged = (searches: Api.IContactFilterCriteria[]) => {
		// Tag list is confined to filters
		setTagSearchCriterias(searches);
		onTagListSearchChange?.();
	};
	const onAdvancedFiltersFlyoutSave = (filterCriteria: Api.IContactFilterCriteria[]) => {
		if (!!filterCriteria && filterCriteria.length > 0) {
			setTagSearchCriterias(filterCriteria);
			onTagListSearchChange?.();
		}
		setShowingAdvancedFiltersFlyout(false);
	};
	const onAdvancedFiltersFlyoutCancel = () => {
		setShowingAdvancedFiltersFlyout(false);
	};
	const onShowingFilterOptionChanged = (selectedOption: ISelectBoxOption<Api.IContactFilterCriteria>) => {
		setFilterCriterias(
			Api.VmUtils.concatContactFilterCriteria(
				filterCriterias.filter(
					x =>
						![
							Api.ContactFilterCriteriaProperty.All,
							Api.ContactFilterCriteriaProperty.OwnedBy,
							Api.ContactFilterCriteriaProperty.Connections,
						].includes(x.property)
				),

				[selectedOption.value]
			)
		);
		onShowingFiltersChanged?.();
	};
	const onDeliveryMethodOptionChanged = (selectedOption: ISelectBoxOption<BulkDeliveryMethodOptions>) => {
		const filterCriteriaToBeAdded: Record<string, Api.IContactFilterCriteria> = {
			all: {
				criteria: [
					{
						property: Api.ContactFilterCriteriaProperty.CapableOf,
						value: 'ReceiveEmail',
					},
					{
						property: Api.ContactFilterCriteriaProperty.CapableOf,
						value: 'ReceiveCard',
					},
					{
						property: Api.ContactFilterCriteriaProperty.CapableOf,
						value: 'ReceiveText',
					},
				],
				op: Api.FilterOperator.Or,
			},
			withEmails: {
				property: Api.ContactFilterCriteriaProperty.CapableOf,
				value: 'ReceiveEmail',
			},
			withTexts: {
				property: Api.ContactFilterCriteriaProperty.CapableOf,
				value: 'ReceiveText',
			},
			withAddress: {
				property: Api.ContactFilterCriteriaProperty.CapableOf,
				value: 'ReceiveCard',
			},
		};
		setFilterCriterias(
			Api.VmUtils.concatContactFilterCriteria(
				filterCriterias.filter(
					x =>
						!Api.VmUtils.areAllCriteriaOfProperty({
							criteria: x,
							property: Api.ContactFilterCriteriaProperty.CapableOf,
						})
				),

				[filterCriteriaToBeAdded[selectedOption.value]].filter(Boolean)
			)
		);
	};
	const onSelectedSortByOptionChanged = (_selectedResourceSortOption: ISelectOption<ContactSortKey>) => {
		setSelectedSortByOption(_selectedResourceSortOption);
	};
	const onRemoveRecipient = (contact: Api.IContact) => {
		const hasBeenNotifiedAboutSnooze = Boolean(
			localStorage.getItem(HasBeenNotifiedExcludingContactWillSnoozeResourceSelectorStorageKey)
		);
		if (!hasBeenNotifiedAboutSnooze) {
			setContactToRemoveAfterSuppressionIntro(contact);
			setIsSuppressionIntroModalOpen(true);
			return;
		}

		setContactIdsToOmit([...contactIdsToOmit, contact.id]);
	};
	const onSuppressionIntroModalClose = (result?: IConfirmationDialogOption<boolean>, canceled?: boolean) => {
		if (!!result && !canceled && !!result.representedObject) {
			localStorage.setItem(HasBeenNotifiedExcludingContactWillSnoozeResourceSelectorStorageKey, 'true');

			setContactIdsToOmit([...contactIdsToOmit, contactToRemoveAfterSuppressionIntro.id]);
		}

		setContactToRemoveAfterSuppressionIntro(null);
		setIsSuppressionIntroModalOpen(false);
	};
	return {
		allFilterCriterias: allCriterias,
		contactIdsToOmit,
		contactResourceSelectorCountQuery,
		contactResourceSelectorFilterQuery,
		isSendFromDropdownOpen,
		isSuppressionIntroModalOpen,
		onAdvancedFiltersFlyoutSave,
		onAdvancedFiltersFlyoutCancel,
		onDeliveryMethodOptionChanged,
		onRemoveRecipient,
		onSaveSelectAnEmployee,
		onSelectedSortByOptionChanged,
		onSendBehalfOfContactOwner,
		onSendBehalfOfCurrentUser,
		onSendOnBehalfSelectEmployee,
		onShowingFilterOptionChanged,
		onSuppressionIntroModalClose,
		onTagListSearchesChanged,
		selectAnEmployeeModalIsOpen,
		selectedDeliveryMethodOption,
		selectedShowingFilterOption,
		selectedSortByOption,
		sendEmailFrom,
		sendEmailFromUser,
		sendFromDropdownAnchorText,
		sendFromDropdownDisabled: !userSession.canSendOnBehalf,
		setIsSendFromDropdownOpen,
		setSelectAnEmployeeModalIsOpen,
		setShowingAdvancedFiltersFlyout,
		showingAdvancedFiltersFlyout,
		showingFilterOptions,
		tagSearchCriterias,
	};
};
