import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import * as yup from 'yup';
import { useEventLogging } from '../../../../models/Logging';
import { useTelephonyConfigurationQuery } from '../../../../queries';
import { TextInput } from '../../../components/TextInput';
import { baseStyleSheet as bs } from '../../../styles/styles';
import { TextingRegistrationFormLayout } from '../presentation';
import { defaultValueDynamicKey } from './TextingRegistrationFormBrandReg';
import { FormErrorList } from './presentation';
import { styleSheet } from './styles';

export interface ITextingRegistrationConsentInfoForm {
	isCollectingPhoneNumbers?: boolean;
	isDirectLender: boolean;
	includesEmbeddedPhone: boolean;
	embeddedPhoneSample?: string;
	consentType?: Api.CampaignConsentType;
	privacyPolicyUrl?: string;
	optInScript?: string;
	optInFlowImage?: File;
	consentFormUrl?: string;
}

const validationSchema = yup
	.object({
		isCollectingPhoneNumbers: yup.string().oneOf(['yes', 'no']).required('This field is required'),
		isDirectLender: yup.string().oneOf(['yes', 'no']).required('This field is required'),
		includesEmbeddedPhone: yup.string().oneOf(['yes', 'no']).required('This field is required'),
		embeddedPhoneSample: yup.string().when('includesEmbeddedPhone', ([includesEmbeddedPhone], schema) => {
			return includesEmbeddedPhone === 'yes' ? schema.required('This field is required') : schema.notRequired();
		}),
		consentType: yup
			.string()
			.oneOf([Api.CampaignConsentType.OfflineForm, Api.CampaignConsentType.OfflineVerbal], 'Consent type is required')
			.when('isCollectingPhoneNumbers', ([isCollectingPhoneNumbers], schema) => {
				return isCollectingPhoneNumbers === 'no' ? schema.required('Consent type is required') : schema.notRequired();
			}),
		optInScript: yup.string().when('consentType', ([consentType], schema) => {
			return consentType === Api.CampaignConsentType.OfflineVerbal
				? schema.required('Sample script is required')
				: schema.notRequired();
		}),
		optInFlowImage: yup
			.mixed<File>()
			.when(['isCollectingPhoneNumbers', 'consentType'], ([isCollectingPhoneNumbers, consentType], schema) => {
				if (isCollectingPhoneNumbers === 'yes' || consentType === Api.CampaignConsentType.OfflineForm) {
					return schema
						.test('required', 'This field is required', file => {
							return file != null && file.size > 0;
						})
						.test('fileSize', 'The file is too large', file => {
							return file?.size != null && file.size <= 10000000;
						})
						.required('This field is required');
				}
				return schema.notRequired();
			}),
		consentFormUrl: yup.string().when('isCollectingPhoneNumbers', ([isCollectingPhoneNumbers], schema) => {
			return isCollectingPhoneNumbers === 'yes' ? schema.required('Consent URL is required') : schema.notRequired();
		}),
		privacyPolicyUrl: yup.string().required('Privacy Policy URL is required'),
	})
	.required();

export function TextingRegistrationConsentInfo({
	isSubmitting = false,
	defaultValues,
	onSave,
}: {
	isSubmitting: boolean;
	defaultValues?: ITextingRegistrationConsentInfoForm;
	onSave: (data: ITextingRegistrationConsentInfoForm) => void;
}) {
	const { logEvent } = useEventLogging('TextingRegistrationConsentInfo');
	const [formErrors, setFormErrors] = React.useState<
		Partial<Record<keyof ITextingRegistrationConsentInfoForm, string>>
	>({});
	const formRef = React.useRef<HTMLFormElement>(null);
	const configurationQuery = useTelephonyConfigurationQuery();
	const [initialIncludesEmbeddedPhone, setInitialIncludesEmbeddedPhone] = React.useState(
		defaultValues?.includesEmbeddedPhone != null
			? defaultValues.includesEmbeddedPhone
			: configurationQuery?.data?.clientProfile?.campaignOptions?.includesEmbeddedPhone
	);
	const [includesEmbeddedPhone, setIncludesEmbeddedPhone] = React.useState<boolean | undefined>(
		initialIncludesEmbeddedPhone
	);
	if (
		defaultValues?.includesEmbeddedPhone == null &&
		configurationQuery?.data?.clientProfile?.campaignOptions?.includesEmbeddedPhone !== initialIncludesEmbeddedPhone
	) {
		const newIncludesEmbeddedPhone = configurationQuery?.data?.clientProfile?.campaignOptions?.includesEmbeddedPhone;
		setIncludesEmbeddedPhone(newIncludesEmbeddedPhone);
		setInitialIncludesEmbeddedPhone(newIncludesEmbeddedPhone);
	}
	const [initialConsentType, setInitialConsentType] = React.useState(
		defaultValues?.consentType ?? configurationQuery.data?.clientProfile?.campaignOptions?.consent?.type ?? ''
	);
	const [isCollectingPhoneNumbers, setIsCollectingPhoneNumbers] = React.useState(
		initialConsentType !== '' ? initialConsentType === Api.CampaignConsentType.Online : undefined
	);
	const [consentType, setConsentType] = React.useState(initialConsentType);
	if (
		defaultValues?.consentType == null &&
		configurationQuery.data?.clientProfile?.campaignOptions?.consent?.type &&
		configurationQuery.data.clientProfile.campaignOptions.consent.type !== initialConsentType
	) {
		const newConsentType = configurationQuery.data?.clientProfile?.campaignOptions?.consent?.type;
		setConsentType(newConsentType);
		setInitialConsentType(newConsentType);
		if (newConsentType) {
			setIsCollectingPhoneNumbers(newConsentType === Api.CampaignConsentType.Online);
		}
	}
	const [optInFlowImage, setOptInFlowImage] = React.useState<File>();
	const optInFlowImageFileName =
		optInFlowImage?.name || configurationQuery.data?.clientProfile?.campaignOptions?.optInFlowImage?.fileName;
	const handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
		ev.preventDefault();
		try {
			const formData = new FormData(ev.currentTarget);
			const data = Object.fromEntries(formData.entries());
			let schema = validationSchema;
			if (configurationQuery.data?.clientProfile?.campaignOptions?.optInFlowImage?.fileName) {
				schema = schema.shape({
					optInFlowImage: yup.mixed<File>(),
				});
			}
			const result = await schema.validate(data, { abortEarly: false });
			onSave({
				...result,
				isCollectingPhoneNumbers:
					result.isCollectingPhoneNumbers != null ? result.isCollectingPhoneNumbers === 'yes' : undefined,
				isDirectLender: result.isDirectLender === 'yes',
				includesEmbeddedPhone: result.includesEmbeddedPhone === 'yes',
				optInFlowImage: result.optInFlowImage?.size ? result.optInFlowImage : undefined,
				consentType: result.isCollectingPhoneNumbers === 'yes' ? Api.CampaignConsentType.Online : result.consentType,
			});
		} catch (error) {
			logEvent('TextingRegistration-FormSubmissionError', error);
			if (error instanceof yup.ValidationError) {
				const validationErrors: Record<string, string> = {};
				error.inner.forEach(err => {
					if (err.path) {
						validationErrors[err.path] = err.message;
					}
				});
				setFormErrors(validationErrors);
				return;
			}
			throw error;
		}
	};
	const renderOptInFlowImage = () => {
		return (
			<>
				<label
					htmlFor='optInFlowImage-input'
					className={css(
						bs.itemsCenter,
						bs.bgGhostWhite,
						bs.cursorPointer,
						bs.flex,
						bs.flexRow,
						bs.justifyCenter,
						bs.mt2,
						bs.wFull
					)}
				>
					<p className={css(bs.textLabel, bs.block, bs.textSm, bs.mb1, bs.mt4, bs.wFull, styleSheet.labelRequired)}>
						Please upload a screenshot of the page where you&apos;re collecting phone numbers
					</p>
					<div className={css(bs.itemsEnd, bs.flex, bs.flexCol, bs.justifyCenter, bs.relative, bs.w80)}>
						<p className={css(bs.textBrandPrimaryHover, bs.textSm, bs.m0)}>
							{optInFlowImageFileName ? '↻ Update file' : '+ Upload a file'}
						</p>
						{optInFlowImageFileName ? (
							<p className={css(styleSheet.imagePlaceholder, bs.truncateText)}>{optInFlowImageFileName}</p>
						) : null}
					</div>
					<input
						name='optInFlowImage'
						accept='image/*, .pdf'
						className={css(bs.hidden)}
						id='optInFlowImage-input'
						type='file'
						onChange={ev => {
							if (ev.target.files?.[0]) {
								setOptInFlowImage(ev.target.files[0]);
							}
						}}
						aria-invalid={Boolean(formErrors?.optInFlowImage) || undefined}
						aria-describedby={formErrors?.optInFlowImage ? 'optInFlowImageError' : undefined}
					/>
				</label>
				<FormErrorList id='optInFlowImageError' errors={formErrors?.optInFlowImage} />
			</>
		);
	};
	return (
		<TextingRegistrationFormLayout showBackButton={true}>
			<h2 className={css(bs.textLg, bs.textCenter)}>Texting Consent Information</h2>
			<p className={css(bs.mb8, bs.textLabel, bs.textSm)}>
				Before submitting your registration we&apos;ll need to gather some information about how you are obtaining
				texting consent from your customers.
			</p>
			<form onSubmit={handleSubmit} ref={formRef} className={css(bs.wFull)}>
				<div className={css(bs.textLabel, bs.textSm, bs.mt4, styleSheet.labelRequired)}>Are you a direct lender?</div>
				<span className={css(bs.block, bs.textXs, bs.textNavigation, bs.mb2)}>
					(Check Yes if you are in the Mortgage industry or offer any type of financing on products you sell)
				</span>
				<div className={css(bs.flex, bs.gap4)}>
					<label className={css(bs.inlineFlex, bs.gap2, bs.textSm)}>
						<input
							key={defaultValueDynamicKey({ loading: configurationQuery.isLoading, name: 'isDirectLender' })}
							type='radio'
							name='isDirectLender'
							defaultChecked={
								defaultValues?.isDirectLender != null
									? defaultValues.isDirectLender
									: configurationQuery?.data?.clientProfile?.campaignOptions?.isDirectLender
							}
							value='yes'
						/>
						<span>Yes</span>
					</label>
					<label className={css(bs.inlineFlex, bs.gap2, bs.textSm)}>
						<input
							key={defaultValueDynamicKey({ loading: configurationQuery.isLoading, name: 'isDirectLender' })}
							type='radio'
							name='isDirectLender'
							defaultChecked={
								defaultValues?.isDirectLender != null
									? defaultValues.isDirectLender
									: configurationQuery?.data?.clientProfile?.campaignOptions?.isDirectLender === false
							}
							value='no'
						/>
						No
					</label>
				</div>
				<FormErrorList id='isDirectLenderError' errors={formErrors?.isDirectLender} />
				<div className={css(bs.textLabel, bs.textSm, bs.mt4, bs.mb2, styleSheet.labelRequired)}>
					Are you going to include phone number(s) in the text?
				</div>
				<div className={css(bs.flex, bs.gap4)}>
					<label className={css(bs.inlineFlex, bs.gap2, bs.textSm)}>
						<input
							key={defaultValueDynamicKey({ loading: configurationQuery.isLoading, name: 'includesEmbeddedPhone' })}
							type='radio'
							name='includesEmbeddedPhone'
							defaultChecked={
								defaultValues?.includesEmbeddedPhone != null
									? defaultValues.includesEmbeddedPhone
									: configurationQuery?.data?.clientProfile?.campaignOptions?.includesEmbeddedPhone
							}
							onChange={ev => {
								setIncludesEmbeddedPhone(ev.currentTarget.value === 'yes');
							}}
							value='yes'
						/>
						Yes
					</label>
					<label className={css(bs.inlineFlex, bs.gap2, bs.textSm)}>
						<input
							key={defaultValueDynamicKey({ loading: configurationQuery.isLoading, name: 'includesEmbeddedPhone' })}
							type='radio'
							name='includesEmbeddedPhone'
							defaultChecked={
								defaultValues?.includesEmbeddedPhone != null
									? defaultValues.includesEmbeddedPhone
									: configurationQuery?.data?.clientProfile?.campaignOptions?.includesEmbeddedPhone === false
							}
							onChange={ev => {
								setIncludesEmbeddedPhone(ev.currentTarget.value !== 'no');
							}}
							value='no'
						/>
						No
					</label>
				</div>
				<FormErrorList id='includesEmbeddedPhoneError' errors={formErrors?.includesEmbeddedPhone} />
				{includesEmbeddedPhone ? (
					<>
						<label
							className={css(bs.textLabel, bs.block, bs.textSm, bs.mb1, bs.mt4, styleSheet.labelRequired)}
							htmlFor='embeddedPhoneSample-input'
						>
							Provide a sample text that would include a phone number
						</label>
						<textarea
							className={css(
								bs.bgWhite,
								bs.wFull,
								bs.h36,
								bs.border,
								bs.borderSolid,
								bs.borderGray300,
								bs.resizeNone,
								bs.p3
							)}
							id='embeddedPhoneSample-input'
							name='embeddedPhoneSample'
							aria-invalid={Boolean(formErrors?.embeddedPhoneSample) || undefined}
							aria-describedby={formErrors?.embeddedPhoneSample ? 'embeddedPhoneSampleError' : undefined}
							defaultValue={
								defaultValues?.embeddedPhoneSample ??
								configurationQuery?.data?.clientProfile?.campaignOptions?.embeddedPhoneSample
							}
							placeholder='Add message...'
						/>
						<FormErrorList id='embeddedPhoneSampleError' errors={formErrors?.embeddedPhoneSample} />
					</>
				) : null}
				<label
					className={css(bs.textLabel, bs.block, bs.textSm, bs.mb1, bs.mt4, styleSheet.labelRequired)}
					htmlFor='privacyPolicyUrl-input'
				>
					Provide the URL of your privacy policy
				</label>
				<TextInput
					className={css(bs.bgWhite)}
					inputId='privacyPolicyUrl-input'
					name='privacyPolicyUrl'
					type='text'
					defaultValue={
						defaultValues?.privacyPolicyUrl ?? configurationQuery.data?.clientProfile?.campaignOptions?.privacyPolicyUrl
					}
					aria-invalid={Boolean(formErrors?.privacyPolicyUrl) || undefined}
					aria-describedby={formErrors?.privacyPolicyUrl ? 'privacyPolicyUrlError' : undefined}
				/>
				<FormErrorList id='privacyPolicyUrlError' errors={formErrors?.privacyPolicyUrl} />
				<div className={css(bs.textLabel, bs.textSm, bs.mt4, bs.mb2, styleSheet.labelRequired)}>
					Are you collecting phone numbers on your website
				</div>
				<div className={css(bs.flex, bs.gap4)}>
					<label className={css(bs.inlineFlex, bs.gap2, bs.textSm)}>
						<input
							type='radio'
							name='isCollectingPhoneNumbers'
							checked={isCollectingPhoneNumbers === true}
							onChange={ev => {
								setIsCollectingPhoneNumbers(ev.currentTarget.value === 'yes');
							}}
							value='yes'
						/>
						Yes
					</label>
					<label className={css(bs.inlineFlex, bs.gap2, bs.textSm)}>
						<input
							type='radio'
							name='isCollectingPhoneNumbers'
							checked={isCollectingPhoneNumbers === false}
							onChange={ev => {
								setIsCollectingPhoneNumbers(ev.currentTarget.value !== 'no');
							}}
							value='no'
						/>
						No
					</label>
				</div>
				<FormErrorList id='isCollectingPhoneNumbersError' errors={formErrors?.isCollectingPhoneNumbers} />
				{isCollectingPhoneNumbers === true ? (
					<>
						<label
							className={css(bs.textLabel, bs.block, bs.textSm, bs.mb1, bs.mt4, styleSheet.labelRequired)}
							htmlFor='consentFormUrl-input'
						>
							Provide the URL of where you are collecting customer information
						</label>
						<TextInput
							className={css(bs.bgWhite)}
							inputId='consentFormUrl-input'
							name='consentFormUrl'
							type='text'
							defaultValue={
								defaultValues?.consentFormUrl ??
								configurationQuery.data?.clientProfile?.campaignOptions?.consent?.consentFormUrl
							}
							aria-invalid={Boolean(formErrors?.consentFormUrl) || undefined}
							aria-describedby={formErrors?.consentFormUrl ? 'consentFormUrlError' : undefined}
						/>
						<FormErrorList id='consentFormUrlError' errors={formErrors?.consentFormUrl} />
						{renderOptInFlowImage()}
					</>
				) : null}
				{isCollectingPhoneNumbers === false ? (
					<>
						<label
							className={css(bs.textLabel, bs.block, bs.textSm, bs.mb1, bs.mt4, styleSheet.labelRequired)}
							htmlFor='consentType-input'
						>
							How are you collecting phone numbers?
						</label>
						<select
							className={css(
								bs.border,
								bs.borderSolid,
								bs.borderGray300,
								bs.h10,
								bs.rounded,
								bs.textSm,
								bs.textTitles,
								bs.wFull
							)}
							id='consentType-input'
							name='consentType'
							value={consentType}
							onChange={ev => setConsentType(ev.target.value)}
							aria-invalid={Boolean(formErrors?.consentType) || undefined}
							aria-describedby={formErrors?.consentType ? 'consentTypeError' : undefined}
						>
							<option value='' />
							<option value={Api.CampaignConsentType.OfflineForm}>Offline Form</option>
							<option value={Api.CampaignConsentType.OfflineVerbal}>Offline Verbal</option>
						</select>
						<FormErrorList id='consentTypeError' errors={formErrors?.consentType} />
						{consentType === Api.CampaignConsentType.OfflineVerbal ? (
							<>
								<label
									className={css(bs.textLabel, bs.block, bs.textSm, bs.mb1, bs.mt4, styleSheet.labelRequired)}
									htmlFor='optInScript-input'
								>
									Provide a sample script
								</label>
								<textarea
									className={css(
										bs.bgWhite,
										bs.wFull,
										bs.h36,
										bs.border,
										bs.borderSolid,
										bs.borderGray300,
										bs.resizeNone,
										bs.p3
									)}
									id='optInScript-input'
									name='optInScript'
									aria-invalid={Boolean(formErrors?.optInScript) || undefined}
									aria-describedby={formErrors?.optInScript ? 'optInScriptError' : undefined}
									defaultValue={
										defaultValues?.optInScript ??
										configurationQuery?.data?.clientProfile?.campaignOptions?.consent?.optInScript ??
										''
									}
									placeholder='Add Script... (3,500 characters max)'
									maxLength={3500}
								/>
								<FormErrorList id='optInScriptError' errors={formErrors?.optInScript} />
								<p className={css(bs.textTitles, bs.textSm)}>Scripts must include</p>
								<div className={css(bs.textNavigation, bs.textXs)}>
									<ol className={css(bs.p0, bs.m0, bs.listDecimal, bs.pl4)}>
										<li className={css(bs.listDecimal)}>
											A request for permission to text the number provided by the contact.
										</li>
										<li className={css(bs.listDecimal)}>A notice informing them about message and data rates.</li>
										<li className={css(bs.listDecimal)}>Information on how to opt out of future messages.</li>
									</ol>
									<br />
									Ex. Do we have permission to text you at the number provided? Keep in mind that standard message and
									data rates may apply and you can always reply STOP to opt out of future messages.
								</div>
							</>
						) : null}
						{consentType === Api.CampaignConsentType.Online || consentType === Api.CampaignConsentType.OfflineForm
							? renderOptInFlowImage()
							: null}
					</>
				) : null}
				<div className={css(bs.flex, bs.mt8)}>
					<button className={css(bs.ctaButton, bs.wFull)} type='submit' disabled={isSubmitting}>
						Submit Form
					</button>
				</div>
			</form>
		</TextingRegistrationFormLayout>
	);
}
