import { styleSheet } from './styles';
import * as React from 'react';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { LoadingSpinner } from '../../../../../LoadingSpinner';
import { ContactsImportProcessingIcon } from '../../../../../svgs/icons/ContactsImportProcessingIcon';
import { SuccessCheckIcon } from '../../../../../svgs/icons/SuccessCheckIcon';
import { useGetSystemJob } from '../../../../../../../queries';

export const ProcessStep = ({
	systemJob,
	setSystemJob,
}: {
	systemJob: Api.ISystemJob;
	setSystemJob: React.Dispatch<React.SetStateAction<Api.ISystemJob>>;
}) => {
	const isJobComplete = systemJob.percentComplete >= 100;
	const isCompleteAndFailed = systemJob.percentComplete === -1;
	const isInProgress = !isJobComplete && !isCompleteAndFailed;
	const bySupportUser = false;

	const getSystemJobQuery = useGetSystemJob({
		enabled: !!systemJob,
		jobId: systemJob.id,
		refetchInterval: data => {
			if (!systemJob.id) {
				return false;
			}
			return !(data?.percentComplete >= 100) ? 3000 : false;
		},
		refetchOnWindowFocus: false,
	});

	React.useEffect(() => {
		if (!getSystemJobQuery.data) {
			return;
		}
		setSystemJob(getSystemJobQuery.data);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getSystemJobQuery.data]);

	const processed = getSystemJobQuery.data?.recordsProcessed || 0;

	return (
		<div className={css(styleSheet.container)}>
			<div className={css(styleSheet.icon)}>
				<ContactsImportProcessingIcon />
				{!!isJobComplete && <SuccessCheckIcon className={css(styleSheet.iconSpinner, styleSheet.iconSuccess)} />}
				{isInProgress && <LoadingSpinner className={css(styleSheet.iconSpinner)} type='small' />}
			</div>
			<div className={css(styleSheet.message)}>
				{isJobComplete
					? 'All done!'
					: !isCompleteAndFailed
						? `${systemJob ? `Processed ${processed} donation${processed > 1 || processed === 0 ? 's' : ''}` : ''}`
						: 'Import failed.'}
			</div>
			<div className={css(styleSheet.detail)}>
				{isJobComplete
					? `We finished importing ${bySupportUser ? 'the' : 'your'} donations.`
					: !isCompleteAndFailed
						? 'We will email you when we are done. You can close this window now.'
						: 'Failed to import all donations: ' + systemJob.status}
			</div>
		</div>
	);
};
