import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const OwnerTagReportImageIcon: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon className={`owner-tag-report-icon ${className || ''}`} height={167} width={280}>
			<defs>
				<circle id='otr-a' cx='31.592' cy='31.592' r='26' />
				<linearGradient id='otr-c' x1='50%' x2='50%' y1='100%' y2='31.858%'>
					<stop offset='0%' stopColor='#FFF' stopOpacity='0' />
					<stop offset='100%' stopColor='#DBF2C3' />
				</linearGradient>
			</defs>
			<g fill='none' fillRule='evenodd' transform='translate(0 1)'>
				<path
					fill='#FFF'
					stroke='#CF949C'
					strokeWidth='2'
					d='M83,165 L195,165 L195,90 C195,88.3431458 193.656854,87 192,87 L86,87 C84.3431458,87 83,88.3431458 83,90 L83,165 Z'
				/>
				<path
					fill='#FFF6F6'
					stroke='#CF949C'
					strokeWidth='2'
					d='M83,98 L195,98 L195,90 C195,88.3431458 193.656854,87 192,87 L86,87 C84.3431458,87 83,88.3431458 83,90 L83,98 Z'
				/>
				<circle cx='88.5' cy='92.5' r='1.5' fill='#CF949C' />
				<circle cx='93.5' cy='92.5' r='1.5' fill='#CF949C' />
				<circle cx='98.5' cy='92.5' r='1.5' fill='#CF949C' />
				<circle cx='126' cy='132' r='6' fill='#EFEFEF' />
				<circle cx='126' cy='150' r='6' fill='#EFEFEF' />
				<rect width='28' height='3' x='148' y='113' fill='#EFEEEF' rx='1.5' />
				<rect width='26' height='3' x='139' y='128' fill='#EFEEEF' rx='1.5' />
				<path
					fill='#EFEEEF'
					d='M140.5,146 L169.5,146 C170.328427,146 171,146.671573 171,147.5 L171,147.5 C171,148.328427 170.328427,149 169.5,149 L140.5,149 C139.671573,149 139,148.328427 139,147.5 L139,147.5 C139,146.671573 139.671573,146 140.5,146 Z'
				/>
				<rect width='35' height='3' x='139' y='134' fill='#EFEEEF' rx='1.5' />
				<rect width='20' height='3' x='139' y='152' fill='#EFEEEF' rx='1.5' />
				<rect width='278' height='1' x='1' y='165' stroke='#858585' strokeWidth='2' />
				<g transform='translate(50)'>
					<circle cx='31.871' cy='31.871' r='31' fill='#FFF6F6' stroke='#CF949C' strokeWidth='2' />
					<mask id='otr-b' fill='#fff'>
						<use xlinkHref='#otr-a' />
					</mask>
					<use fill='#FFF' xlinkHref='#otr-a' />
					<g mask='url(#otr-b)'>
						<g transform='translate(12 15)'>
							<polygon fill='#C0A093' points='18.199 15.816 20.497 15.816 20.497 13.519 18.199 13.519' />
							<polygon fill='#C0A093' points='24.982 18.114 27.28 18.114 27.28 15.816 24.982 15.816' />
							<polygon fill='#987E73' points='11.407 18.114 13.704 18.114 13.704 15.816 11.407 15.816' />
							<polygon
								fill='#C0A093'
								points='31.835 45.682 31.802 45.682 29.538 45.682 29.538 47.979 31.802 47.979 31.835 47.979 34.1 47.979 34.1 45.682'
							/>
							<polygon fill='#C0A093' points='31.802 50.277 34.1 50.277 34.1 47.979 31.802 47.979' />
							<polygon fill='#F2C1BF' points='18.199 11.252 20.497 11.252 20.497 8.955 18.199 8.955' />
							<polygon fill='#D2B4AA' points='18.199 25.006 20.497 25.006 20.497 22.708 18.199 22.708' />
							<polygon fill='#D2B4AA' points='20.479 25.006 22.776 25.006 22.776 22.708 20.479 22.708' />
							<polygon fill='#D2B4AA' points='15.985 25.006 18.282 25.006 18.282 22.708 15.985 22.708' />
							<polygon
								fill='#C0A093'
								points='16.008 52.574 15.985 52.574 13.71 52.574 13.71 54.86 13.71 54.872 13.71 57.157 15.985 57.157 16.008 57.157 18.282 57.157 18.282 54.872 18.282 54.86 18.282 52.574'
							/>
							<polygon
								fill='#C0A093'
								points='22.776 52.574 22.75 52.574 20.479 52.574 20.479 54.86 20.479 54.872 20.479 57.157 22.75 57.157 22.776 57.157 25.047 57.157 25.047 54.872 25.047 54.86 25.047 52.574'
							/>
							<polygon fill='#ECECEC' points='18.199 36.493 20.497 36.493 20.497 34.195 18.199 34.195' />
							<polygon fill='#ECECEC' points='9.117 36.493 11.414 36.493 11.414 34.195 9.117 34.195' />
							<polygon
								fill='#ECECEC'
								points='9.117 38.79 9.117 38.79 9.117 41.087 9.117 41.087 9.117 43.385 11.414 43.385 11.414 41.087 11.414 41.087 11.414 38.79 11.414 38.79 11.414 36.493 9.117 36.493'
							/>
							<polygon fill='#ECECEC' points='9.117 45.682 11.414 45.682 11.414 43.385 9.117 43.385' />
							<polygon fill='#ECECEC' points='27.27 43.385 29.568 43.385 29.568 41.087 27.27 41.087' />
							<polygon
								fill='#ECECEC'
								points='27.27 34.195 27.27 36.492 27.27 36.493 27.27 38.79 27.27 38.79 27.27 41.087 29.568 41.087 29.568 38.79 29.568 38.79 29.568 36.493 29.568 36.492 29.568 34.195'
							/>
							<polygon fill='#ECECEC' points='27.27 45.682 29.568 45.682 29.568 43.385 27.27 43.385' />
							<polygon
								fill='#C0A093'
								points='6.866 45.682 6.835 45.682 4.568 45.682 4.568 47.979 6.835 47.979 6.866 47.979 9.132 47.979 9.132 45.682'
							/>
							<polygon fill='#C0A093' points='4.568 50.277 6.866 50.277 6.866 47.979 4.568 47.979' />
							<polygon fill='#ECECEC' points='18.199 41.087 20.497 41.087 20.497 38.79 18.199 38.79' />
							<polygon fill='#ECECEC' points='18.199 45.682 20.497 45.682 20.497 43.385 18.199 43.385' />
							<polygon fill='#2F4149' points='11.407 59.466 13.704 59.466 13.704 57.169 11.407 57.169' />
							<polygon
								fill='#2F4149'
								points='16.008 57.169 15.985 57.169 13.71 57.169 13.71 59.466 15.985 59.466 16.008 59.466 18.282 59.466 18.282 57.169'
							/>
							<polygon
								fill='#2F4149'
								points='25.047 57.169 24.982 57.169 22.776 57.169 22.75 57.169 20.479 57.169 20.479 59.466 22.75 59.466 22.776 59.466 24.982 59.466 25.047 59.466 27.28 59.466 27.28 57.169'
							/>
							<polygon
								fill='#A4A18D'
								points='11.407 50.277 11.407 52.574 13.704 52.574 13.704 50.277 13.704 47.98 11.407 47.98'
							/>
							<polygon
								fill='#A4A18D'
								points='25.047 47.98 24.982 47.98 22.776 47.98 22.75 47.98 20.497 47.98 20.479 47.98 18.282 47.98 18.199 47.98 16.008 47.98 15.985 47.98 13.71 47.98 13.71 50.277 13.71 52.574 15.985 52.574 16.008 52.574 18.282 52.574 18.282 50.277 20.479 50.277 20.479 52.574 22.75 52.574 22.776 52.574 24.982 52.574 25.047 52.574 27.28 52.574 27.28 50.277 27.28 47.98'
							/>
							<polygon
								fill='#C3CBCC'
								points='11.407 29.601 9.132 29.601 9.117 29.601 6.835 29.601 6.835 31.898 9.117 31.898 9.132 31.898 11.407 31.898 11.414 31.898 13.704 31.898 13.704 29.601 11.414 29.601'
							/>
							<polygon
								fill='#C3CBCC'
								points='11.407 31.898 9.132 31.898 9.117 31.898 6.866 31.898 6.835 31.898 4.568 31.898 4.568 34.195 4.568 34.195 4.568 36.493 6.835 36.493 6.866 36.493 9.132 36.493 9.132 34.195 11.407 34.195 11.407 36.493 13.704 36.493 13.704 34.195 13.704 34.195 13.704 31.898 11.414 31.898'
							/>
							<polygon
								fill='#C3CBCC'
								points='11.407 38.79 11.407 38.79 11.407 41.087 11.407 41.087 11.407 43.385 13.704 43.385 13.704 41.087 13.704 41.087 13.704 38.79 13.704 38.79 13.704 36.493 11.407 36.493'
							/>
							<polygon
								fill='#C3CBCC'
								points='11.407 45.682 9.132 45.682 9.132 43.385 6.866 43.385 6.835 43.385 4.568 43.385 4.568 45.682 6.835 45.682 6.866 45.682 9.117 45.682 9.117 47.979 11.407 47.979 11.414 47.979 13.704 47.979 13.704 45.682 13.704 45.682 13.704 43.385 11.407 43.385'
							/>
							<polygon
								fill='#C3CBCC'
								points='27.28 31.898 29.538 31.898 29.568 31.898 31.835 31.898 31.835 29.601 29.568 29.601 29.538 29.601 27.28 29.601 24.982 29.601 24.982 31.898'
							/>
							<polygon
								fill='#C3CBCC'
								points='31.802 43.385 29.538 43.385 29.538 45.682 27.28 45.682 27.28 43.385 25.047 43.385 24.982 43.385 22.776 43.385 22.75 43.385 20.479 43.385 20.479 45.682 18.282 45.682 18.282 43.385 16.008 43.385 15.985 43.385 13.71 43.385 13.71 45.682 13.71 45.682 13.71 47.979 15.985 47.979 16.008 47.979 18.199 47.979 18.282 47.979 20.479 47.979 20.497 47.979 22.75 47.979 22.776 47.979 24.982 47.979 25.047 47.979 27.271 47.979 27.28 47.979 29.568 47.979 29.568 45.682 31.802 45.682 31.835 45.682 34.1 45.682 34.1 43.385 31.835 43.385'
							/>
							<polygon
								fill='#C3CBCC'
								points='31.835 31.898 31.802 31.898 29.568 31.898 29.538 31.898 27.28 31.898 25.047 31.898 24.982 31.898 22.776 31.898 22.75 31.898 20.497 31.898 20.479 31.898 18.282 31.898 18.199 31.898 16.008 31.898 15.985 31.898 13.71 31.898 13.71 34.195 13.71 34.195 13.71 36.493 15.985 36.493 16.008 36.493 18.282 36.493 18.282 34.195 20.479 34.195 20.479 36.493 22.75 36.493 22.776 36.493 24.982 36.493 25.047 36.493 27.28 36.493 27.28 34.195 29.538 34.195 29.538 36.493 31.802 36.493 31.835 36.493 34.1 36.493 34.1 34.195 34.1 34.195 34.1 31.898'
							/>
							<polygon
								fill='#C3CBCC'
								points='31.802 36.493 29.538 36.493 29.538 38.79 29.538 38.79 29.538 41.087 29.538 41.087 29.538 43.385 31.802 43.385 31.835 43.385 34.1 43.385 34.1 41.087 34.1 41.087 34.1 38.79 34.1 38.79 34.1 36.493 31.835 36.493'
							/>
							<polygon
								fill='#C3CBCC'
								points='6.866 43.385 9.132 43.385 9.132 41.087 9.132 41.087 9.132 38.79 9.132 38.79 9.132 36.493 6.866 36.493 6.835 36.493 4.568 36.493 4.568 38.79 4.568 38.79 4.568 41.087 4.568 41.087 4.568 43.385 6.835 43.385'
							/>
							<path
								fill='#C3CBCC'
								d='M18.2824568,41.0872063 L20.4788131,41.0872063 L20.4788131,38.7901217 L18.2824568,38.7901217 L18.2824568,41.0872063 Z M16.0075714,43.3845079 L18.1994444,43.3845079 L18.2823845,43.3845079 L20.4788131,43.3845079 L20.496746,43.3845079 L22.749649,43.3845079 L22.7761146,43.3845079 L24.9821605,43.3845079 L25.0470229,43.3845079 L27.2795344,43.3845079 L27.2795344,41.0872787 L27.2795344,41.0872063 L27.2795344,38.7900494 L27.2795344,38.7899771 L27.2795344,36.4926755 L25.0470229,36.4926755 L24.9821605,36.4926755 L22.7761146,36.4926755 L22.749649,36.4926755 L20.496746,36.4926755 L20.4788131,36.4926755 L18.2823845,36.4926755 L18.1994444,36.4926755 L16.0075714,36.4926755 L15.9850829,36.4926755 L13.7102698,36.4926755 L13.7102698,38.7899771 L13.7102698,38.7900494 L13.7102698,41.0872063 L13.7102698,41.0872787 L13.7102698,43.3845079 L15.9850829,43.3845079 L16.0075714,43.3845079 Z'
							/>
							<polygon fill='#D2B4AA' points='15.985 20.385 18.282 20.385 18.282 18.088 15.985 18.088' />
							<polygon fill='#D2B4AA' points='13.71 20.385 16.008 20.385 16.008 18.088 13.71 18.088' />
							<polygon fill='#D2B4AA' points='13.71 22.689 16.008 22.689 16.008 20.391 13.71 20.391' />
							<polygon fill='#D2B4AA' points='22.75 20.385 25.047 20.385 25.047 18.088 22.75 18.088' />
							<polygon fill='#D2B4AA' points='22.75 22.689 25.047 22.689 25.047 20.391 22.75 20.391' />
							<polygon fill='#D2B4AA' points='20.479 20.385 22.776 20.385 22.776 18.088 20.479 18.088' />
							<polygon fill='#C0A093' points='11.407 20.385 13.704 20.385 13.704 18.088 11.407 18.088' />
							<polygon fill='#6B6766' points='13.71 31.898 16.008 31.898 16.008 29.601 13.71 29.601' />
							<polygon fill='#6B6766' points='15.985 31.898 18.282 31.898 18.282 29.601 15.985 29.601' />
							<polygon fill='#6B6766' points='18.199 31.898 20.497 31.898 20.497 29.601 18.199 29.601' />
							<polygon fill='#6B6766' points='20.479 31.898 22.776 31.898 22.776 29.601 20.479 29.601' />
							<polygon fill='#C0A093' points='22.75 31.898 25.047 31.898 25.047 29.601 22.75 29.601' />
							<polygon fill='#C0A093' points='24.982 20.385 27.28 20.385 27.28 18.088 24.982 18.088' />
							<polygon fill='#C0A093' points='24.982 22.689 27.28 22.689 27.28 20.391 24.982 20.391' />
							<polygon fill='#C0A093' points='11.407 22.689 13.704 22.689 13.704 20.391 11.407 20.391' />
							<polygon fill='#C0A093' points='15.985 27.303 18.282 27.303 18.282 25.006 15.985 25.006' />
							<polygon fill='#C0A093' points='18.199 27.303 20.497 27.303 20.497 25.006 18.199 25.006' />
							<polygon fill='#C0A093' points='20.479 27.303 22.776 27.303 22.776 25.006 20.479 25.006' />
							<polygon fill='#987E73' points='15.985 29.6 18.282 29.6 18.282 27.303 15.985 27.303' />
							<polygon fill='#6B6766' points='13.71 29.6 16.008 29.6 16.008 27.303 13.71 27.303' />
							<polygon fill='#987E73' points='11.407 27.303 13.704 27.303 13.704 25.006 11.407 25.006' />
							<polygon fill='#987E73' points='11.407 29.6 13.704 29.6 13.704 27.303 11.407 27.303' />
							<polygon fill='#987E73' points='13.71 27.303 16.008 27.303 16.008 25.006 13.71 25.006' />
							<polygon fill='#C0A093' points='13.71 25.006 16.008 25.006 16.008 22.708 13.71 22.708' />
							<polygon fill='#C0A093' points='22.75 25.006 25.047 25.006 25.047 22.708 22.75 22.708' />
							<polygon fill='#987E73' points='11.407 25.006 13.704 25.006 13.704 22.708 11.407 22.708' />
							<polygon fill='#808286' points='15.985 9.022 18.282 9.022 18.282 6.725 15.985 6.725' />
							<polygon fill='#808286' points='24.982 9.022 27.28 9.022 27.28 6.725 24.982 6.725' />
							<polygon fill='#808286' points='15.985 4.595 18.282 4.595 18.282 2.297 15.985 2.297' />
							<polygon fill='#808286' points='24.982 4.595 27.28 4.595 27.28 2.297 24.982 2.297' />
							<polygon fill='#987E73' points='22.75 27.303 25.047 27.303 25.047 25.006 22.75 25.006' />
							<polygon fill='#987E73' points='24.982 27.303 27.28 27.303 27.28 25.006 24.982 25.006' />
							<polygon fill='#C0A093' points='24.982 25.006 27.28 25.006 27.28 22.708 24.982 22.708' />
							<polygon fill='#987E73' points='18.199 29.6 20.497 29.6 20.497 27.303 18.199 27.303' />
							<polygon fill='#987E73' points='20.479 29.6 22.776 29.6 22.776 27.303 20.479 27.303' />
							<polygon fill='#6B6766' points='22.75 29.6 25.047 29.6 25.047 27.303 22.75 27.303' />
							<polygon fill='#987E73' points='24.982 29.6 27.28 29.6 27.28 27.303 24.982 27.303' />
							<polygon
								fill='#242933'
								points='15.985 13.46 13.71 13.46 13.71 15.816 13.71 15.902 13.71 18.114 16.008 18.114 16.008 15.902 18.282 15.902 18.282 13.46 16.008 13.46'
							/>
							<polygon fill='#242933' points='9.117 25.092 11.414 25.092 11.414 22.794 9.117 22.794' />
							<polygon
								fill='#242933'
								points='29.538 20.477 27.271 20.477 27.271 22.774 29.538 22.774 29.568 22.774 31.835 22.774 31.835 20.477 29.568 20.477'
							/>
							<polygon
								fill='#242933'
								points='9.117 20.477 6.835 20.477 6.835 22.774 9.117 22.774 9.132 22.774 11.414 22.774 11.414 20.477 9.132 20.477'
							/>
							<polygon
								fill='#242933'
								points='11.407 2.297 11.407 4.592 11.407 4.595 11.407 4.678 9.132 4.678 9.117 4.678 6.835 4.678 6.835 6.811 4.568 6.811 4.568 9.041 2.297 9.041 2.297 11.307 0 11.307 0 13.605 0 15.902 2.297 15.902 2.297 18.2 4.568 18.2 4.568 20.471 6.835 20.471 6.866 20.471 9.117 20.471 9.132 20.471 11.414 20.471 11.414 18.2 11.414 18.174 11.414 15.902 13.704 15.902 13.704 13.605 13.704 13.519 13.704 11.252 13.704 11.222 13.704 9.022 13.704 8.955 13.704 6.889 13.704 6.725 13.704 4.595 13.704 4.592 13.704 2.297'
							/>
							<path
								fill='#242933'
								d='M22.7496635,0 L22.7496635,2.2973739 L20.4787552,2.2973739 L20.4787552,4.59192769 L18.2823989,4.59192769 L18.1994589,4.59192769 L16.0075859,4.59192769 L16.0075859,2.2973739 L16.0075859,0 L13.7102843,0 L13.7102843,2.2973739 L13.7102843,4.59192769 L13.7102843,4.5947478 L13.7102843,6.72465079 L13.7102843,6.88930159 L13.7102843,8.95506526 L13.7102843,9.02202469 L13.7102843,11.2524392 L15.985025,11.2524392 L16.0075859,11.2524392 L18.1994589,11.2524392 L18.2823989,11.2524392 L20.4787552,11.2524392 L20.4967605,11.2524392 L20.4787552,13.604769 L20.4787552,15.9021429 L22.7496635,15.9021429 L22.7496635,18.113612 L25.0470374,18.113612 L25.0470374,15.9021429 L27.2704377,15.9021429 L27.2704377,18.1736296 L27.2704377,18.1995168 L27.2704377,20.4710035 L29.5378751,20.4710035 L29.5678116,20.4710035 L31.8024924,20.4710035 L31.8351767,20.4710035 L34.0516353,20.4710035 L34.099794,20.4710035 L36.3489369,20.4710035 L36.3489369,18.1995168 L38.6463108,18.1995168 L38.6463108,15.9021429 L38.6463108,15.8163104 L40.9436847,15.8163104 L40.9436847,11.2215626 L38.6463108,11.2215626 L38.6463108,9.04089771 L36.3489369,9.04089771 L36.3489369,6.81055556 L34.099794,6.81055556 L34.099794,4.67776014 L31.8351767,4.67776014 L31.8351767,2.38320635 L29.5678116,2.38320635 L29.5678116,0.0858324515 L27.2704377,0.0858324515 L27.2704377,2.38320635 L27.2704377,4.59192769 L25.0470374,4.59192769 L25.0470374,2.2973739 L25.0470374,0 L22.7496635,0 Z M25.0470374,6.88930159 L27.2704377,6.88930159 L27.2704377,6.97513404 L27.2704377,8.95506526 L25.0470374,8.95506526 L25.0470374,6.88930159 Z M16.0075859,8.95506526 L18.1994589,8.95506526 L18.1994589,6.88930159 L16.0075859,6.88930159 L16.0075859,8.95506526 Z'
							/>
							<polygon fill='#242933' points='27.27 25.092 29.568 25.092 29.568 22.794 27.27 22.794' />
							<polygon
								fill='#C0A093'
								points='22.776 11.222 22.75 11.222 20.497 11.222 20.479 11.222 18.282 11.222 18.199 11.222 16.008 11.222 15.985 11.222 13.71 11.222 13.71 13.519 15.985 13.519 16.008 13.519 18.199 13.519 18.282 13.519 20.479 13.519 20.497 13.519 22.75 13.519 22.776 13.519 25.047 13.519 25.047 11.222'
							/>
							<polygon
								fill='#C0A093'
								points='20.479 20.391 18.282 20.391 18.199 20.391 15.985 20.391 15.985 22.689 18.199 22.689 18.282 22.689 20.479 22.689 20.497 22.689 22.776 22.689 22.776 20.391 20.497 20.391'
							/>
							<polygon
								fill='#C0A093'
								points='20.479 15.816 18.282 15.816 18.199 15.816 15.985 15.816 15.985 18.114 18.199 18.114 18.199 20.385 20.497 20.385 20.497 18.114 22.776 18.114 22.776 15.816 20.497 15.816'
							/>
						</g>
					</g>
				</g>
				<circle cx='233' cy='7' r='1' stroke='#CF949C' />
				<path
					fill='#CF949C'
					d='M54.2261833 2.45283019C54.9033856 2.45283019 55.4523665 1.90374545 55.4523665 1.22641509 55.4523665.549084741 54.9033856-7.99360578e-15 54.2261833-7.99360578e-15 53.5489809-7.99360578e-15 53 .549084741 53 1.22641509 53 1.90374545 53.5489809 2.45283019 54.2261833 2.45283019zM44 20.0003782C44.5522847 20.0003782 45 19.5525782 45 19.0001891 45 18.4477999 44.5522847 18 44 18 43.4477153 18 43 18.4477999 43 19.0001891 43 19.5525782 43.4477153 20.0003782 44 20.0003782z'
				/>
				<g fill='#CF949C' transform='translate(244 20)'>
					<rect width='4' height='1.333' y='1.333' rx='.667' />
					<rect width='4' height='1.333' y='1.333' rx='.667' transform='rotate(90 2 2)' />
				</g>
				<g fill='#CF949C' transform='translate(236 32)'>
					<rect width='4' height='1.333' y='1.333' rx='.667' />
					<rect width='4' height='1.333' y='1.333' rx='.667' transform='rotate(90 2 2)' />
				</g>
				<g fill='#CF949C' transform='translate(41 5)'>
					<rect width='4' height='1.333' y='1.333' rx='.667' />
					<rect width='4' height='1.333' y='1.333' rx='.667' transform='rotate(90 2 2)' />
				</g>
				<path stroke='#979797' strokeDasharray='4' d='M92,66 C92.6666667,81.3333333 100,94.6666667 114,106' />
				<path
					stroke='#979797'
					strokeDasharray='4'
					d='M159,66 C159.666667,81.3333333 167,94.6666667 181,106'
					transform='matrix(-1 0 0 1 340 0)'
				/>
				<rect width='66' height='44' x='160' y='16' fill='#FFF6F6' stroke='#CF949C' strokeWidth='2' rx='4' />
				<path
					fill='#FFF'
					d='M166,20 L220,20 C221.104569,20 222,20.8954305 222,22 L222,54 C222,55.1045695 221.104569,56 220,56 L166,56 C164.895431,56 164,55.1045695 164,54 L164,22 C164,20.8954305 164.895431,20 166,20 Z'
				/>
				<path stroke='#979797' strokeDasharray='4' d='M112,10.5 C128.666667,0.5 144.333333,0.5 159,10.5' />
				<polygon fill='url(#otr-c)' points='169 44 184 32 200 36 217 26 217 52 169 52' />
				<circle cx='169' cy='44' r='2' fill='#89C947' />
				<circle cx='184' cy='32' r='2' fill='#89C947' />
				<circle cx='200' cy='36' r='2' fill='#E2C52F' />
				<circle cx='216' cy='27' r='2' fill='#89C947' />
			</g>
		</SvgIcon>
	);
};
