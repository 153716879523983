import { Switch, useRouteMatch } from 'react-router';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { MediaChooserContext, useMediaChooserContextController } from '../../components/MediaChooser/context';
import { PrivateRoute } from '../../components/PrivateRoute';
import { BlogPostContextProvider } from './blogPostContext';
import { CreateBlogPost } from './Post/CreateBlogPost';
import { CreateBlogPostFromTemplate } from './Post/CreateBlogPostFromTemplate';
import { EditBlogPost } from './Post/EditBlogPost';
import { ScheduleBlogPost } from './ScheduleBlogPost';
import { ScheduleSuccess } from './ScheduleSuccess';

export function BlogCampaignRoutes() {
	const userSession = useUserSession();
	const mediaContext = useMediaChooserContextController();
	const { url } = useRouteMatch();

	return (
		<BlogPostContextProvider>
			<Switch>
				<PrivateRoute path={`${url}/post/schedule`} userSession={userSession}>
					<ScheduleBlogPost />
				</PrivateRoute>
				<PrivateRoute
					path={`${url}/post/success`}
					render={props => <ScheduleSuccess {...props} />}
					userSession={userSession}
				/>
				<PrivateRoute path={`${url}/post/create`} userSession={userSession}>
					<MediaChooserContext.Provider value={mediaContext}>
						<CreateBlogPost />
					</MediaChooserContext.Provider>
				</PrivateRoute>
				<PrivateRoute path={`${url}/post/edit/:id`} userSession={userSession}>
					<EditBlogPost />
				</PrivateRoute>
				<PrivateRoute path={`${url}/post/create-from-template/:id`} userSession={userSession}>
					<CreateBlogPostFromTemplate />
				</PrivateRoute>
			</Switch>
		</BlogPostContextProvider>
	);
}
