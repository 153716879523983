import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { ISelectOption, Select } from '../../../components/Select';
import { TextInput } from '../../../components/TextInput';
import { handwritingStyleFontMap } from '../../../components/settings/PersonalSettings/HandwrittenCardSettings';
import { bs } from '../../../styles/styles';

const handwritingStyleOptions = Object.values(Api.HandwritingStyle).map(value => ({
	dataContext: value,
	id: `handwriting-style-option-${value}`,
	text: value,
}));

interface Props {
	handwritingStyle: Api.HandwritingStyle;
	onChangeHandWritingStyle: (handwritingStyle: Api.HandwritingStyle) => void;
	disabled?: boolean;
}

export function HwcSettingsWritingStyleInputWithPreview({
	handwritingStyle,
	onChangeHandWritingStyle,
	disabled,
}: Props) {
	const onChangeHandwritingStyleOption = (option: ISelectOption<Api.HandwritingStyle>) => {
		onChangeHandWritingStyle(option.dataContext);
	};
	return (
		<>
			<div className={css(bs.flex, bs.itemsStart)}>
				<label className={css(bs.textHeader, bs.inlineBlock, bs.textSm, bs.w48)}>Writing Style</label>
				<div>
					<Select
						styles={[bs.w80]}
						options={handwritingStyleOptions}
						onOptionClick={onChangeHandwritingStyleOption}
						selectedOption={handwritingStyleOptions.find(option => option.dataContext === handwritingStyle)}
						disabled={disabled}
					/>
				</div>
			</div>
			<div className={css(bs.flex, bs.itemsStart)}>
				<TextInput
					style={{
						fontFamily: handwritingStyleFontMap[handwritingStyle].fontFamily,
						fontSize: `${handwritingStyleFontMap[handwritingStyle].fontSize}px`,
					}}
					inputClassName={css(bs.textBase)}
					className={css(bs.w96, bs.ml48)}
					disabled={true}
					type='text'
					inputId='hwc-style-example'
					value='The quick brown fox jumped over the lazy dog'
				/>
			</div>
		</>
	);
}
