import { CssSelectors } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		boxSizing: 'border-box',
		maxWidth: '100%',
		...CssSelectors.allDescendants(
			{
				minHeight: 300,
			},
			'.scroll-view-content'
		),
	},
});
