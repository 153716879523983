import { useToaster } from '../../../models/hooks/appStateHooks';
import { Toaster } from '../Toaster';
import { styleSheet } from './styles';
import { css } from 'aphrodite';

export function GlobalToaster() {
	const toaster = useToaster();
	if (!toaster) {
		return null;
	}
	return <Toaster className={`${css(styleSheet.toaster)}`} toasterViewModel={toaster} />;
}
