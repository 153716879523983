import { IToasterComponentProps, ToasterViewModelKey } from '@AppModels/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { Topics } from '../../../models/LocalNotificationTopics';
import { AppAutoUpdaterEvent, AppAutoUpdaterEventName, IAppAutoUpdaterEvent, IBuildInfo } from '../../../models/index';
import AppBuildInfo from '../../assets/build-info.json';
import {
	INotificationServiceComponentProps,
	withNotificationService,
} from '../LocalNotificationObserver/WithNotificationService';

interface IProps
	extends IEventLoggingComponentProps,
		IToasterComponentProps,
		INotificationServiceComponentProps<IAppAutoUpdaterEvent> {
	onWillBroadcastRefreshNotification?(e: IAppAutoUpdaterEvent): void;
}

class _AppAutoUpdater extends React.Component<IProps> {
	// @ts-ignore
	private mDomMutationObserver: MutationObserver;
	// @ts-ignore
	@observable.ref private mLastDomMutationMoment: moment.Moment;
	// @ts-ignore
	private mMounted: boolean;
	// @ts-ignore
	private mPendingRefreshEvent: IAppAutoUpdaterEvent;
	private mUpdateTimeoutHandle: any;
	private static TimeOutDuration = 600000; // 10 min

	public componentDidMount() {
		this.mMounted = true;
		this.mLastDomMutationMoment = moment();
		this.toggleUserInputObserver(true);
		this.startPollingForBuildInfoChanges();
	}

	public componentWillUnmount() {
		this.mMounted = false;
		this.clearTimeout();
		this.toggleUserInputObserver(false);
		this.onPendingRefreshCanceled();
	}

	public render() {
		// @ts-ignore
		return null as JSX.Element;
	}

	@computed
	private get appIsIdle() {
		return moment().diff(this.mLastDomMutationMoment, 'minutes') > 5;
	}

	private toggleUserInputObserver = (on: boolean) => {
		if (window?.MutationObserver) {
			this.mDomMutationObserver?.disconnect();
			// @ts-ignore
			this.mDomMutationObserver = null;
			if (on) {
				const reactRootElement = document.getElementById('react-root');
				if (reactRootElement) {
					this.mDomMutationObserver = new MutationObserver(this.onDomMutation);
					this.mDomMutationObserver.observe(reactRootElement, {
						attributes: true,
						childList: true,
						subtree: true,
					});
				}
			}
		}
	};

	@action
	private onDomMutation = () => {
		this.mLastDomMutationMoment = moment();
	};

	private startPollingForBuildInfoChanges = () => {
		this.clearTimeout();
		this.mUpdateTimeoutHandle = setTimeout(() => {
			this.getBuildInfo();
		}, _AppAutoUpdater.TimeOutDuration);
	};

	private getBuildInfo = () => {
		const { logEvent, onWillBroadcastRefreshNotification, postNotification } = this.props;
		if (this.mMounted) {
			this.clearTimeout();
			fetch('./assets/build-info.json')
				?.then(async res => {
					try {
						const buildInfo: IBuildInfo = await res.json();
						if (!!buildInfo && !!this.mMounted) {
							const shouldBroadcastUpdateNotification =
								(AppBuildInfo as IBuildInfo).hash !== buildInfo.hash && !!this.appIsIdle;
							if (shouldBroadcastUpdateNotification) {
								const event = new AppAutoUpdaterEvent(AppAutoUpdaterEventName.AppAutoUpdaterWillRefresh, {
									onDefaultPrevented: this.onPendingRefreshCanceled,
									target: this,
								});
								if (onWillBroadcastRefreshNotification) {
									onWillBroadcastRefreshNotification(event);
								}

								if (!event.defaultPrevented) {
									this.mPendingRefreshEvent = event;
									// @ts-ignore
									postNotification({
										info: event,
										topic: Topics.APP_REFRESH,
									});
									setTimeout(() => {
										if (this.mMounted) {
											if (!event.defaultPrevented && !!this.appIsIdle) {
												// @ts-ignore
												logEvent('Refresh');
												if (window?.location?.reload) {
													window.location.reload();
												}
												return;
											}

											if (event === this.mPendingRefreshEvent) {
												// @ts-ignore
												this.mPendingRefreshEvent = null;
											}

											if (!this.mUpdateTimeoutHandle && !this.mPendingRefreshEvent) {
												this.startPollingForBuildInfoChanges();
											}
										}
									}, 5000);
									return;
								}
							}
							this.startPollingForBuildInfoChanges();
						}
					} catch (e) {
						this.startPollingForBuildInfoChanges();
					}
				})
				.catch(() => {
					this.startPollingForBuildInfoChanges();
				});
		}
	};

	@action
	private clearTimeout() {
		if (this.mUpdateTimeoutHandle) {
			clearTimeout(this.mUpdateTimeoutHandle);
			this.mUpdateTimeoutHandle = null;
		}
	}

	private onPendingRefreshCanceled = () => {
		// @ts-ignore
		this.mPendingRefreshEvent = null;
	};
}

const AppAutoUpdaterAsObserver = observer(_AppAutoUpdater);
const AppAutoUpdaterWithContext = inject(ToasterViewModelKey)(AppAutoUpdaterAsObserver);
const AppAutoUpdatedWithNotificationService = withNotificationService(AppAutoUpdaterWithContext);
export const AppAutoUpdater = withEventLogging(AppAutoUpdatedWithNotificationService, 'AppAutoUpdater');
