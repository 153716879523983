import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { DeprecatedCloseButton } from '../../components/DeprecatedCloseButton';
import { oldSilver, white } from '../../styles/colors';
import { bs } from '../../styles/styles';

export function StoredAttachmentPreview({
	attachment,
	onClose,
}: {
	attachment: Api.IFileAttachment;
	onClose?: () => void;
}) {
	if (attachment.mimeType.includes('image/')) {
		return (
			<div className={css(bs.relative)}>
				<img
					key={attachment.url}
					src={attachment.url}
					alt={attachment.fileName}
					style={{ maxWidth: 240, maxHeight: 240 }}
					className={css(bs.roundedLg, bs.maxWFull, bs.objectCover)}
				/>
				{onClose ? (
					<DeprecatedCloseButton
						className={css(bs.absolute, bs.right1)}
						fillColor={oldSilver}
						onClick={onClose}
						opacity={0.9}
						outlineColor={white}
						outlineWidth={1.5}
						widthAndHeight={16}
						xMark={true}
					/>
				) : null}
			</div>
		);
	}
	return (
		<div className={css(bs.flex, bs.py1, bs.itemsCenter, bs.px2, bs.gap2, bs.bgGray200, bs.boxBorder)}>
			<span className={css(bs.boxBorder, bs.textBrandPrimaryHover, bs.textSm)}>{attachment.fileName}</span>
			{onClose ? (
				<DeprecatedCloseButton
					fillColor={oldSilver}
					onClick={onClose}
					opacity={0.9}
					outlineColor={white}
					outlineWidth={1.5}
					widthAndHeight={16}
					xMark={false}
				/>
			) : null}
		</div>
	);
}
