import { ILocationState } from '../../../../../models';
import { getVisibleTags } from '../../../../../models/UiUtils';
import { useFullscreenModal } from '../../../../../models/hooks/appStateHooks';
import { Checkbox } from '../../../../components/Checkbox';
import { TinyPopover } from '../../../../components/TinyPopover';
import { Tag } from '../../../../components/tags/Tag';
import { baseStyleSheet } from '../../../../styles/styles';
import { styleSheet } from './styles';
import {
	CompaniesViewModel,
	CompanyViewModel,
	ESelectionState,
	EntityViewModel,
	IEntity,
	ObservableCollection,
	TagViewModel,
} from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { LocationDescriptorObject } from 'history';
import { observer } from 'mobx-react';
import * as React from 'react';

interface IBaseProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

interface IProps extends IBaseProps {
	company: CompanyViewModel;
	companies: CompaniesViewModel;
	tagsToHighlight?: ObservableCollection<TagViewModel>;
}

export const CompanyRow: React.FC<IProps> = observer(props => {
	const { className, styles, company, companies, tagsToHighlight } = props;
	// @ts-ignore
	const { history } = useFullscreenModal();
	const redirection = React.useRef(getRedirection(company)).current;

	const [visibleTags, extraTags] = getVisibleTags(company, tagsToHighlight, 2);

	const onClick = React.useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			if (event.isDefaultPrevented()) {
				return;
			}
			if (event.ctrlKey || event.metaKey) {
				window.open(window.location.origin + '/#' + redirection.pathname);
			} else {
				history.push(redirection);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[history]
	);

	const isSelected = (companyToSelect: CompanyViewModel, companiesToSelect: CompaniesViewModel) => {
		if (
			companiesToSelect.selectionState === ESelectionState.All ||
			companiesToSelect.selectionState === ESelectionState.Some
		) {
			if (companiesToSelect.selectedCompanies.length === 0) {
				// select all checked... only check excluded
				return !companiesToSelect.excludedCompanies.has(company);
			}
		}
		return companiesToSelect.selectedCompanies.has(companyToSelect);
	};

	const selected = isSelected(company, companies);

	const onSelect = React.useCallback(
		(event: React.MouseEvent<HTMLInputElement>) => {
			event.preventDefault();
			if (!selected) {
				// contact should be included in effective selection
				if (companies.selectionState === ESelectionState.None) {
					companies.selectedCompanies.add(company);
				} else if (companies.selectionState === ESelectionState.Some) {
					if (companies.selectedCompanies.length > 0) {
						companies.selectedCompanies.add(company);
					} else {
						// some selected but explicit list of selected contacts === 0
						// make sure to remove the contact from the exclusion list
						companies.excludedCompanies.removeItems([company]);
					}
				}
			} else {
				// contact should be excluded in effective selection
				if (companies.selectionState === ESelectionState.Some) {
					if (companies.selectedCompanies.has(company)) {
						companies.selectedCompanies.removeItems([company]);
					} else {
						companies.excludedCompanies.add(company);
					}
				} else if (companies.selectionState === ESelectionState.All) {
					companies.excludedCompanies.add(company);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selected]
	);

	return (
		<div className={css(styleSheet.outerContainer, selected && styleSheet.containerSelected)} onClick={onClick}>
			<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
				<div className={`${css(styleSheet.selection, ...(styles || []))} ${className || ''}`} onClick={onSelect}>
					<Checkbox
						checked={selected}
						id={`company-select-${company.id}`}
						childrenPosition='left'
						backgroundFillColor='white'
						onClick={onSelect}
						readOnly={true}
					/>
				</div>
				<div className={`${css(styleSheet.name, ...(styles || []))} ${className || ''}`}>
					<div className={css(baseStyleSheet.truncateText)}>{company.companyName}</div>
				</div>
				<div className={`${css(styleSheet.tags, ...(styles || []))} ${className || ''}`}>
					{!!visibleTags?.length &&
						visibleTags.map(x => {
							return <Tag className={css(styleSheet.tag)} key={`company-${company.id}-tag-${x}`} tagValue={x} />;
						})}
					{extraTags.length > 0 && (
						<TinyPopover
							anchor={
								<span className={css(baseStyleSheet.brandLink, styleSheet.tagMore)}>
									&nbsp;{`+${extraTags.length} more`}
								</span>
							}
							autoCloseOtherPopoversOnHover={true}
							dismissOnOutsideAction={true}
							toggleOnHover={true}
						>
							<div className={css(styleSheet.extraTags)}>
								{extraTags.map(x => {
									return (
										<div key={x} className={css(styleSheet.extraTagsItem)}>
											<Tag className={css(styleSheet.tag)} key={`company-${company.id}-tag-${x}`} tagValue={x} />
										</div>
									);
								})}
							</div>
						</TinyPopover>
					)}
				</div>
				<div className={`${css(styleSheet.domain, ...(styles || []))} ${className || ''}`}>
					<div className={css(baseStyleSheet.truncateText)}>
						{!!company.emailDomains && company.emailDomains.length > 0 ? company.emailDomains[0] : ''}
					</div>
				</div>
			</div>
		</div>
	);
});

const getRedirection = (company: CompanyViewModel) => {
	const locationState: ILocationState<EntityViewModel, IEntity> = {
		viewModel: company,
	};
	const redirection: LocationDescriptorObject = {
		pathname: `/companies/${company.id}`,
		state: locationState,
	};
	return redirection;
};
