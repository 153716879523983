import { StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../../../styles/styles';

export const styleSheet = StyleSheet.create({
	buttonContainer: {
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	calendarSettings: {
		position: 'relative',
	},
});
