import PlaceholderFabPointerUrl from './placeholderFabPointer.svg';
import './styles.less';
import * as React from 'react';

interface IProps {
	className?: string;
	icon?: React.ReactNode;
	iconUrl?: string;
	showFabPointer?: boolean;
	subtitle?: string;
	title?: string;
}

export const FabCalloutPlaceholder: React.FC<IProps> = props => {
	const { icon, iconUrl, title, subtitle, showFabPointer } = props;
	return (
		<div className={`fab-callout-placeholder ${props.className || ''}`}>
			<div className='fab-callout-placeholder-content'>
				{icon ? icon : !!iconUrl && <img className='fab-callout-placeholder-content-icon' src={iconUrl} />}
				{!!title && <span className='fab-callout-placeholder-content-title'>{title}</span>}
				{!!subtitle && <span className='fab-callout-placeholder-content-subtitle'>{subtitle}</span>}
			</div>
			{showFabPointer && <img className='fab-callout-placeholder-fab-pointer' src={PlaceholderFabPointerUrl} />}
		</div>
	);
};
