import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	ctaButton: {
		background: '#6974FB1b',
		border: 'none',
		boxShadow: 'none',
		color: '#6974FB',
		fontWeight: 400,
		marginTop: 20,
	},
	integrationTitle: {
		color: '#6974FB',
	},
	logo: {
		borderRadius: 12,
		height: 40,
		marginRight: -10,
		width: 40,
	},
});
