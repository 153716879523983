import { background } from '../../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		background,
		display: 'flex',
		position: 'fixed',
		width: '100%',
	},
});
