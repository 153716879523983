import { brandPrimary, borderColor } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
	copyKeyButton: {
		color: brandPrimary,
		fontSize: 16,
		marginLeft: 24,
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	headerActions: {
		padding: 16,
	},
	tableContainer: {
		alignItems: 'stretch',
		border: `1px solid ${borderColor}`,
		borderRadius: 10,
		boxShadow: '0px 0px 6px rgba(139, 139, 139, 0.1)',
		boxSizing: 'border-box',
		marginBottom: '1.5rem',
		padding: '20px 30px',
		marginTop: 24,
	},
	tableHeader: {
		background: '#fff',
	},
	tableRow: {
		height: 40,
	},
	tableHeaderCell: {
		borderBottom: `1px solid ${borderColor}`,
		color: '#AAAAAA',
		fontSize: 11,
		letterSpacing: '1.1px',
		paddingBottom: 20,
		textTransform: 'uppercase',
		whiteSpace: 'nowrap',
		minWidth: 150,
	},
	tableCell: {
		borderBottom: `1px solid ${borderColor}`,
	},
	addRowTableCell: {
		paddingTop: 16,
	},
});
