import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { baseStyleSheet } from '../../../../styles/styles';
import { Dropdown } from '../../../Dropdown';
import { RadioButton } from '../../../RadioButton';
import { DateRangeOption, DonationTypeOption } from '../../../dataBoards/donations/models';
import { DisclosureIcon } from '../../../svgs/icons/DisclosureIcon';
import { styleSheet } from './styles';
import { styleSheet as dropdownStyles } from '../../DropdownComponent/styles';

interface IDayOption {
	key: keyof typeof DateRangeOption;
	label: string;
	value: string;
}

const dayOptions: IDayOption[] = (Object.keys(DateRangeOption) as (keyof typeof DateRangeOption)[]).map(key => {
	const value = DateRangeOption[key];
	const parts = value.split(' ');
	return {
		key,
		label: value,
		value: moment()
			.subtract(parseInt(parts[0], 10), parts[1].toLowerCase() as moment.unitOfTime.DurationConstructor)
			.format('YYYY-MM-DD'),
	};
});

export const DateRangeFilterDropdown = ({
	minimumDonationDate,
	setMinimumDonationDate,
	selectedDonationType,
	setSelectedDonationType,
}: {
	minimumDonationDate: string;
	setMinimumDonationDate: React.Dispatch<React.SetStateAction<string>>;
	selectedDonationType: DonationTypeOption;
	setSelectedDonationType: React.Dispatch<React.SetStateAction<DonationTypeOption>>;
}) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const [selectedDayOption, setSelectedDayOption] = React.useState<IDayOption>(null);

	const getDateRangeMessage = () => {
		if (!minimumDonationDate || !selectedDayOption) {
			return 'Date Range';
		}
		const key = selectedDayOption.key;
		return `${selectedDonationType}/${DateRangeOption[key]}`;
	};
	const dateRangeMessage = getDateRangeMessage();

	const selectDateRangeOption = (newDayOption: IDayOption) => {
		setMinimumDonationDate(newDayOption.value);
		setIsOpen(false);
	};

	React.useEffect(() => {
		const match = dayOptions.find((option: IDayOption) => {
			return option.value === minimumDonationDate;
		});
		setSelectedDayOption(match);
	}, [minimumDonationDate]);

	const clearDateSearch = () => {
		setSelectedDayOption(null);
		setMinimumDonationDate('');
		setIsOpen(false);
	};

	const selectDonationType = (option: DonationTypeOption) => {
		setSelectedDonationType(option);
		setMinimumDonationDate('');
		setSelectedDayOption(null);
	};

	return (
		<Dropdown
			anchor={
				<div className={css(dropdownStyles.dropdownAnchor)}>
					<span>{dateRangeMessage}</span>
					<DisclosureIcon className={css(dropdownStyles.dropdownIcon)} />
				</div>
			}
			disabled={false}
			className={css(dropdownStyles.dropdownInline, isOpen ? dropdownStyles.dropdownInlineOpen : null)}
			contentClassName={css(dropdownStyles.dropdownMenu)}
			contentPositionY='bottom'
			contentInnerClassName={css(styleSheet.dropdownContentInnerOverrides)}
			isOpen={isOpen}
			onOpenChanged={() => setIsOpen(!isOpen)}
			openOnClick={true}
		>
			<div className={css(dropdownStyles.dropdown)}>
				<RadioButton
					checked={selectedDonationType === DonationTypeOption.HasDonated}
					id={DonationTypeOption.HasDonated}
					onChange={() => selectDonationType(DonationTypeOption.HasDonated)}
				>
					<span>{DonationTypeOption.HasDonated}</span>
				</RadioButton>

				<RadioButton
					checked={selectedDonationType === DonationTypeOption.NotDonated}
					id={DonationTypeOption.NotDonated}
					onChange={() => selectDonationType(DonationTypeOption.NotDonated)}
				>
					<span>{DonationTypeOption.NotDonated}</span>
				</RadioButton>

				<div className={css(dropdownStyles.dropdownSeparator)} />

				<div>
					{dayOptions.map(dayOption => {
						return (
							<div
								key={`donation-date-range-option-${dayOption.key}`}
								onClick={() => selectDateRangeOption(dayOption)}
								className={css(
									dropdownStyles.option,
									selectedDayOption?.key === dayOption.key ? dropdownStyles.selected : null
								)}
							>
								{dayOption.label}
							</div>
						);
					})}
				</div>

				<div className={css(dropdownStyles.dropdownSeparator)} />

				{minimumDonationDate ? (
					<button className={css(baseStyleSheet.ctaButton)} onClick={clearDateSearch}>
						Reset Filter
					</button>
				) : null}
			</div>
		</Dropdown>
	);
};
