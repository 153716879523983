import { brandPrimary, brandPrimaryActive, brandSecondary } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	cardListItem: {
		alignItems: 'center',
		background: '#fff',
		border: '1px solid #f2f2f2',
		borderRadius: 6,
		boxShadow: '0px 0px 6px rgba(139, 139, 139, 0.100034)',
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		height: 270,
		justifyContent: 'space-between',
		margin: 10,
		padding: 20,
		position: 'relative',
		width: 250,
		...CssSelectors.allDescendants(
			{
				border: `1px dashed ${brandSecondary}`,
				borderRadius: 3,
				boxSizing: 'border-box',
				fontSize: 14,
				padding: '1px 5px',
			},
			'span[data-placeholder="true"]'
		),
		...CssSelectors.allChildren(
			{
				maxWidth: 145,
			},
			'button'
		),
	},
	cardListItemCta: {
		...CssSelectors.allChildren(
			{
				background: 'none',
				color: brandPrimaryActive,
				fontWeight: 'normal',
			},
			'.cta-button-reverse-small, .cta-button-reverse-small:active'
		),
	},
	cardListItemTitle: {
		color: brandPrimary,
		fontSize: 14,
		margin: '0 0 1rem 0',
		padding: 0,
		textAlign: 'center',
	},
	cardThumbnail: {
		display: 'block',
		width: '12rem',
	},
});
