import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { HatIcon } from '../svgs/icons/HatIcon';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const TaggingGameButton: React.FC<IProps> = () => {
	return (
		<div className={css(styleSheet.taggingGameButtonContainer)}>
			<NavLink className={css(styleSheet.taggingGameLink)} target='_blank' to='/people/tagging-game'>
				<span>Tagging Game</span>
				<figure>
					<HatIcon />
				</figure>
			</NavLink>
		</div>
	);
};
