import {
	EntityViewModel,
	IOperationResultNoValue,
	ResourceAutoCompleteViewModel,
	ResourceAutoCompleteViewModelType,
} from '@ViewModels';
import { css } from 'aphrodite';
import { computed } from 'mobx';
import { Observer, inject, observer } from 'mobx-react';
import * as React from 'react';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { stringsEqual } from '../../../../models/UiUtils';
import { Dropdown } from '../../Dropdown';
import { InlineValueEditor } from '../../entities/InlineValueEditor';
import { LoadingSpinner } from '../../LoadingSpinner';
import { AddTagButton } from '../AddTagButton';
import { Tag } from '../Tag';
import { styleSheet } from './styles';

interface IProps extends IErrorMessageComponentProps, IEventLoggingComponentProps, IUserSessionComponentProps {
	className?: string;
	entity: EntityViewModel;
	onAddTagButtonClicked?(e: React.MouseEvent<HTMLElement>): void;
	onTagAdded?(): void;
	popularTags?: string[];
	suggestedTags?: string[];
	singleColumn?: boolean;
}

interface IPendingTagChange {
	tagValue: string;
	op: 'adding' | 'removing';
}

interface IState {
	editingPendingTag?: boolean;
	highlightedAutoCompleteSuggestionIndex?: number;
	inputTagValue?: string;
	isAutocompleteOpen?: boolean;
	pendingTagChange?: IPendingTagChange;
	popularTags?: string[];
}

class _AddSuggestedTags extends React.Component<IProps, IState> {
	private mAutoCompleteVm: ResourceAutoCompleteViewModel<string>;
	public readonly state: IState = {
		isAutocompleteOpen: false,
	};

	public UNSAFE_componentWillMount() {
		const { userSession } = this.props;
		this.mAutoCompleteVm = new ResourceAutoCompleteViewModel<string>(userSession, {
			type: ResourceAutoCompleteViewModelType.Tag,
		});
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		if (nextProps.popularTags !== this.state.popularTags) {
			this.setState({
				popularTags: nextProps.popularTags || [],
			});
		}
	}

	public render() {
		const { className, suggestedTags, entity, singleColumn } = this.props;
		const { pendingTagChange, editingPendingTag, inputTagValue } = this.state;
		const suggested = suggestedTags || [];
		const tags = [
			...(entity.tags || []),
			...(!!pendingTagChange && pendingTagChange.op === 'adding' ? [pendingTagChange.tagValue] : []),
		].filter(
			x =>
				suggested.indexOf(x) < 0 &&
				(pendingTagChange ? !(pendingTagChange.tagValue === x && pendingTagChange.op === 'removing') : true)
		);
		return (
			<div className={`${css(styleSheet.container)} add-suggested-tags ${className || ''}`}>
				<div className={css(styleSheet.content)}>
					<h5 className={css(styleSheet.header)}>Possible Tags</h5>
					<div className={css(styleSheet.tags, singleColumn ? styleSheet.singleColumn : null)}>
						{suggested.map((x, i) => {
							return (
								<Tag
									className={css(styleSheet.tag)}
									isSelected={
										(!!pendingTagChange && pendingTagChange.tagValue === x && pendingTagChange.op === 'adding') ||
										(entity.tags || []).indexOf(x) >= 0
									}
									key={`${x}-${i}`}
									onClick={this.onTagSuggestionClicked(x)}
									showSelectionIndicator={true}
									tagValue={x}
								/>
							);
						})}
						{tags.map((x, i) => {
							return (
								<Tag
									className={css(styleSheet.tag)}
									isSelected={
										(!!pendingTagChange && pendingTagChange.tagValue === x && pendingTagChange.op === 'adding') ||
										(entity.tags || []).indexOf(x) >= 0
									}
									key={`${x}-${i}`}
									onClick={this.removeTag(x)}
									showSelectionIndicator={true}
									tagValue={x}
								/>
							);
						})}
						{editingPendingTag ? (
							<div className={css(styleSheet.editTag)}>
								<InlineValueEditor
									autoComplete='off'
									autoFocus={true}
									id='add-suggested-tag-input'
									onBlur={this.onInputBlur}
									onCancelButtonClicked={this.onClickCancelAddTag}
									onChange={this.onPendingTagChange}
									onFocus={this.onInputFocus}
									onKeyDown={this.onInputKeyDown}
									onRenderInput={this.onRenderAddTagInput}
									onSaveButtonClicked={this.addTagFromInput()}
									value={inputTagValue || ''}
								/>
							</div>
						) : (
							<AddTagButton
								className={css(styleSheet.addTagButton, styleSheet.tag)}
								onClick={this.onAddTagButtonClicked}
							/>
						)}
					</div>
				</div>
			</div>
		);
	}

	@computed
	private get suggestions() {
		const { inputTagValue, popularTags } = this.state;
		const suggestions = inputTagValue
			? this.mAutoCompleteVm.searchResults || []
			: !!popularTags && popularTags.length > 0
				? popularTags
				: [];
		return suggestions.slice(0, 5);
	}

	private onRenderAddTagInput = (input: JSX.Element) => {
		return (
			<Observer>
				{() => {
					const { isAutocompleteOpen, highlightedAutoCompleteSuggestionIndex } = this.state;
					const isOpen = !!this.mAutoCompleteVm.isBusy || (isAutocompleteOpen && this.suggestions.length > 0);
					return (
						<Dropdown anchor={input} contentClassName={css(styleSheet.autoCompleteMenu)} isOpen={isOpen}>
							<Observer>
								{() => {
									return (
										<>
											{this.mAutoCompleteVm.isBusy ? (
												<LoadingSpinner type='small' />
											) : (
												this.suggestions.map((x, i) => {
													const className = `${css(
														styleSheet.autoCompleteMenuItem,
														highlightedAutoCompleteSuggestionIndex === i
															? styleSheet.autoCompleteMenuItemHighlighted
															: null
													)} truncate-text`;
													return (
														<div
															className={className}
															key={`${x}-${i}`}
															onMouseDown={this.addTagFromInput(x)}
															title={x}
														>
															{x}
														</div>
													);
												})
											)}
										</>
									);
								}}
							</Observer>
						</Dropdown>
					);
				}}
			</Observer>
		);
	};

	private onInputFocus = () => {
		this.setState(
			{
				editingPendingTag: true,
				highlightedAutoCompleteSuggestionIndex: -1,
				isAutocompleteOpen: true,
			},
			() => {
				const { inputTagValue } = this.state;
				if (inputTagValue) {
					this.mAutoCompleteVm.setSearchQuery(inputTagValue);
				}
			}
		);
	};

	private onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const { highlightedAutoCompleteSuggestionIndex } = this.state;
		switch (e.keyCode) {
			case 13: {
				// enter
				if (highlightedAutoCompleteSuggestionIndex > -1) {
					this.addTagFromInput(this.suggestions[highlightedAutoCompleteSuggestionIndex])();
				} else {
					this.addTagFromInput()();
				}
				break;
			}
			case 38: {
				// up
				const index = highlightedAutoCompleteSuggestionIndex - 1;
				if (index >= -1) {
					this.setState({
						highlightedAutoCompleteSuggestionIndex: index,
					});
				}
				break;
			}
			case 40: {
				// down
				const index = highlightedAutoCompleteSuggestionIndex + 1;
				if (index < this.suggestions.length) {
					this.setState({
						highlightedAutoCompleteSuggestionIndex: index,
					});
				}
				break;
			}
			default: {
				break;
			}
		}
		e.stopPropagation();
	};

	private onInputBlur = () => {
		this.setState(
			{
				highlightedAutoCompleteSuggestionIndex: -1,
				isAutocompleteOpen: false,
			},
			() => {
				this.mAutoCompleteVm.reset();
			}
		);
	};

	private onPendingTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputTagValue = e.target.value;
		this.setState(
			{
				highlightedAutoCompleteSuggestionIndex: -1,
				inputTagValue,
				isAutocompleteOpen: true,
			},
			() => {
				this.mAutoCompleteVm.setSearchQuery(inputTagValue);
			}
		);
	};

	private getNextStateForResetInput = () => {
		const nextState: IState = {
			editingPendingTag: false,
			highlightedAutoCompleteSuggestionIndex: -1,
			inputTagValue: '',
			isAutocompleteOpen: false,
		};
		return nextState;
	};

	private onTagSuggestionClicked = (tagValue: string) => () => {
		const { entity } = this.props;
		const tags = entity.tags || [];
		const indexOfMatchingTag = tags.findIndex(x => stringsEqual(x, tagValue));
		const nextState = this.getNextStateForResetInput();
		nextState.pendingTagChange = {
			op: indexOfMatchingTag >= 0 ? 'removing' : 'adding',
			tagValue,
		};
		const tagsToSave = [...tags];
		if (nextState.pendingTagChange.op === 'adding') {
			tagsToSave.push(tagValue);
		} else {
			tagsToSave.splice(indexOfMatchingTag, 1);
		}

		this.setState(nextState, () => {
			const eventAction = `${nextState.pendingTagChange.op === 'removing' ? 'RemoveTag' : 'AddTag'}`;
			this.updateTags(tagsToSave, eventAction, true);
		});
	};

	private onAddTagButtonClicked = (e: React.MouseEvent<HTMLElement>) => {
		const { onAddTagButtonClicked } = this.props;
		if (onAddTagButtonClicked) {
			onAddTagButtonClicked(e);
		}
		if (e.defaultPrevented) {
			return;
		}

		const { editingPendingTag } = this.state;
		if (!editingPendingTag) {
			this.setState({
				editingPendingTag: true,
				highlightedAutoCompleteSuggestionIndex: -1,
				isAutocompleteOpen: true,
			});
		}
	};

	private addTagFromInput =
		(tagValue = this.state.inputTagValue) =>
		() => {
			const { entity } = this.props;
			const tags = entity.tags || [];
			const nextState = this.getNextStateForResetInput();
			if (tagValue) {
				const indexOfMatchingTag = tags.findIndex(x => stringsEqual(x, tagValue));
				nextState.pendingTagChange = null;
				if (indexOfMatchingTag < 0) {
					nextState.pendingTagChange = {
						op: 'adding',
						tagValue,
					};
				}
			}

			this.setState(nextState, () => {
				if (nextState.pendingTagChange) {
					const tagsToSave = [...tags, tagValue];
					this.updateTags(tagsToSave, 'AddTag');
				}
			});
		};

	private removeTag = (tagValue: string) => () => {
		const { entity } = this.props;
		const tags = entity.tags || [];
		const nextState = this.getNextStateForResetInput();
		if (tagValue) {
			nextState.pendingTagChange = {
				op: 'removing',
				tagValue,
			};
			tags.splice(tags.indexOf(tagValue), 1);
		}

		this.setState(nextState, () => {
			if (nextState.pendingTagChange) {
				this.updateTags(tags, 'RemoveTag');
			}
		});
	};

	private updateTags = (tagsToSave: string[], eventAction?: string, isSuggested = false) => {
		const { entity, logEvent, logApiError, onTagAdded } = this.props;
		const { pendingTagChange } = this.state;
		const promise = entity.updateTags(tagsToSave);
		if (promise) {
			if (eventAction) {
				logEvent(eventAction, {
					isSuggested,
					tagValue: pendingTagChange ? pendingTagChange.tagValue : null,
				});
			}
			promise
				.then(() => {
					this.setState({
						pendingTagChange: null,
					});
					if (!!onTagAdded && eventAction === 'AddTag') {
						onTagAdded();
					}
				})
				.catch((error: IOperationResultNoValue) => {
					if (eventAction) {
						logApiError(`${eventAction}-Error`, error);
					}
					this.setState({
						pendingTagChange: null,
					});
				});
		}
	};

	private onClickCancelAddTag = () => {
		if (this.state.editingPendingTag) {
			this.setState({
				editingPendingTag: false,
				inputTagValue: '',
				isAutocompleteOpen: false,
			});
		}
	};
}

const AddSuggestedTagsAsObserver = observer(_AddSuggestedTags);
const AddSuggestedTagsWithContext = inject(
	ErrorMessagesViewModelKey,
	UserSessionViewModelKey
)(AddSuggestedTagsAsObserver);
export const AddSuggestedTags = withEventLogging(AddSuggestedTagsWithContext, 'AddSuggestedTags');
