import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import equal from 'fast-deep-equal';
import * as React from 'react';
import { useEventLogging } from '../../../../../models/Logging';
import { useErrorMessages, useToaster } from '../../../../../models/hooks/appStateHooks';
import { useInput } from '../../../../../models/hooks/inputHooks';
import { SavedSearchesViewModel } from '../../../../../viewmodels/AppViewModels';
import { Button } from '../../../../components/Button';
import { Modal } from '../../../../components/Modal';
import { TextInput } from '../../../../components/TextInput';
import { baseStyleSheet } from '../../../../styles/styles';
import { DefaultPeopleFilter } from '../PeopleContext';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	savedSearchesVm: SavedSearchesViewModel;
	filter: Api.IBulkContactsRequest;
}

export const SaveSearch: React.FC<IProps> = ({ className, styles, savedSearchesVm, filter }) => {
	const { pushApiError } = useErrorMessages();
	const toaster = useToaster();
	const [searchInput, setSearch, setSearchInput] = useInput('');
	const { logApiError } = useEventLogging();

	const [isSavedSearchModalOpen, setIsSavedSearchModalOpen] = React.useState(false);
	const handleRequestClose = (result: string | undefined, isCancelled: boolean) => {
		setSearch('');
		if (isCancelled) {
			setIsSavedSearchModalOpen(false);
			return;
		}
		savedSearchesVm
			.save({ filter: filter.filter, name: result! })
			?.then(() => {
				toaster.push({
					message: `Search "${result}" was saved.`,
					type: 'successMessage',
				});
			})
			?.catch((err: Api.IOperationResultNoValue) => {
				logApiError('SaveSearch-Error', err);
				pushApiError(err);
			});
		setIsSavedSearchModalOpen(false);
	};

	const isNotDefaultFilter = React.useCallback(() => !equal(DefaultPeopleFilter, filter), [filter]);

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
			{isNotDefaultFilter() && (
				<a
					href='#'
					className={css(baseStyleSheet.brandLink, styleSheet.link)}
					onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
						e.preventDefault();
						setIsSavedSearchModalOpen(true);
					}}
				>
					Save Search
				</a>
			)}
			<Modal isOpen={isSavedSearchModalOpen} onRequestClose={handleRequestClose} useDefaultHeader={true}>
				<div className={css(styleSheet.saveSearchTitle)}>Save Search</div>
				<div className={css(styleSheet.saveSearchDescription)}>Name this search</div>
				<TextInput
					value={searchInput}
					onChange={setSearchInput}
					inputId='save-search-input'
					type='text'
					placeholder='Name this search'
				/>
				<div className={css(styleSheet.saveSearchButtonContainer)}>
					<Button
						label='Save'
						kind='primary'
						disabled={!searchInput}
						onClick={() => handleRequestClose(searchInput, false)}
					/>
					<Button label='Cancel' kind='reverse' onClick={() => handleRequestClose(undefined, true)} />
				</div>
			</Modal>
		</div>
	);
};
