import { SvgIcon } from '../../icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	blue?: boolean;
}

export const IntegrationGraphic: React.FC<IProps> = props => {
	const { blue, className } = props;
	const width = 225;
	const height = 174;

	if (blue) {
		return (
			<SvgIcon
				className={`integration-icon ${className}`}
				fill='none'
				height={height}
				viewBox={`0 0 ${width} ${height}`}
				width={width}
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M47.7842 84.4669C53.1696 111.323 76.9305 130.666 104.536 130.666C119.89 130.708 134.624 124.654 145.451 113.855C164.971 94.4927 167.984 64.1492 152.647 41.3815C137.311 18.6137 107.896 9.76279 82.3924 20.2416C56.8886 30.7204 42.3988 57.6105 47.7842 84.4669ZM47.049 73.2692C47.049 41.8266 72.8373 16.2462 104.536 16.2462C136.234 16.2462 162.022 41.8266 162.022 73.2692C162.022 104.712 136.234 130.292 104.536 130.292C72.8373 130.292 47.049 104.712 47.049 73.2692Z'
					fill='#3F3D56'
				/>
				<path
					d='M108.91 123.989C107.95 128.113 104.662 130.667 104.662 130.667C104.662 130.667 102.93 126.535 103.89 122.411C104.85 118.287 108.138 115.733 108.138 115.733C108.138 115.733 109.87 119.865 108.91 123.989Z'
					fill='#0078BF'
				/>
				<path
					d='M99.2233 123.989C100.183 128.113 103.472 130.667 103.472 130.667C103.472 130.667 105.203 126.535 104.243 122.411C103.283 118.287 99.995 115.733 99.995 115.733C99.995 115.733 98.2632 119.865 99.2233 123.989Z'
					fill='#0078BF'
				/>
				<path
					d='M101.352 123.473C104.187 126.309 104.533 130.667 104.533 130.667C104.533 130.667 100.283 130.428 97.448 127.593C94.6129 124.758 94.2667 120.4 94.2667 120.4C94.2667 120.4 98.5169 120.638 101.352 123.473Z'
					fill='#3F3D56'
				/>
				<path
					d='M107.715 123.473C104.88 126.309 104.533 130.667 104.533 130.667C104.533 130.667 108.784 130.428 111.619 127.593C114.454 124.758 114.8 120.4 114.8 120.4C114.8 120.4 110.55 120.638 107.715 123.473Z'
					fill='#3F3D56'
				/>
				<path
					d='M99.2233 21.611C100.183 17.4873 103.472 14.9333 103.472 14.9333C103.472 14.9333 105.203 19.0653 104.243 23.189C103.283 27.3127 99.995 29.8667 99.995 29.8667C99.995 29.8667 98.2632 25.7347 99.2233 21.611Z'
					fill='#046CB6'
				/>
				<path
					d='M108.91 21.611C107.95 17.4873 104.662 14.9333 104.662 14.9333C104.662 14.9333 102.93 19.0653 103.89 23.189C104.85 27.3127 108.138 29.8667 108.138 29.8667C108.138 29.8667 109.87 25.7347 108.91 21.611Z'
					fill='#046CB6'
				/>
				<path
					d='M107.715 23.9932C104.88 21.1581 104.533 16.8 104.533 16.8C104.533 16.8 108.784 17.0384 111.619 19.8735C114.454 22.7085 114.8 27.0667 114.8 27.0667C114.8 27.0667 110.55 26.8283 107.715 23.9932Z'
					fill='#3F3D56'
				/>
				<path
					d='M101.352 23.9932C104.187 21.1581 104.533 16.8 104.533 16.8C104.533 16.8 100.283 17.0384 97.448 19.8735C94.6129 22.7085 94.2667 27.0667 94.2667 27.0667C94.2667 27.0667 98.5169 26.8283 101.352 23.9932Z'
					fill='#3F3D56'
				/>
				<rect x='2.79999' y='2.8' width='73.7333' height='75.6' fill='#0078BF' />
				<path
					d='M74.6667 4.66667H7.53814H3.73334V8.644V74.6667H7.53814V8.644H74.6667V4.66667V4.66667Z'
					fill='#3F3D56'
				/>
				<path
					opacity='0.3'
					d='M74.6667 4.66667H7.53814H3.73334V8.644V74.6667H7.53814V8.644H74.6667V4.66667V4.66667Z'
					fill='black'
				/>
				<path
					opacity='0.1'
					d='M63.0019 8.4H60.0507H7.46667V18.1157H60.0507V75.6H73.7333V8.4H63.0019V8.4Z'
					fill='black'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M0 80.2667V0H78.4V80.2667H0ZM4.28123 75.9279H74.1188V4.33875H4.28123V75.9279Z'
					fill='#3F3D56'
				/>
				<path
					d='M54.3619 82.1333C54.3619 82.1333 58.0907 89.1639 60.1881 90.7514C62.2856 92.3389 56.8089 94.2667 56.8089 94.2667L50.4 84.5146L54.3619 82.1333Z'
					fill='#A0616A'
				/>
				<path
					d='M33.9605 65.3333C33.9605 65.3333 50.5281 69.6796 51.9735 72.5771C53.419 75.4746 57.8667 82.4955 57.8667 82.4955L51.3064 87.7333L44.8573 78.0378L27.0667 70.0139L33.9605 65.3333Z'
					fill='#2F2E41'
				/>
				<path
					d='M57.0795 91.4328C57.0795 91.4328 55.4821 90.1874 55.0257 91.0176C54.5692 91.8479 53.2 96.1028 53.2 96.1028C53.2 96.1028 55.1398 97.7632 59.1333 96.7254C59.1333 96.7254 60.1603 95.999 61.7577 96.7254C63.3551 97.4519 69.2885 95.999 69.0603 93.6121C68.8321 91.2252 62.7846 91.4328 62.7846 91.4328C62.7846 91.4328 60.2375 88.8373 59.9136 89.8237C59.5898 90.8101 60.0462 90.9139 60.0462 90.9139C60.0462 90.9139 57.9923 92.8856 57.0795 91.4328Z'
					fill='#2F2E41'
				/>
				<path
					d='M37.5429 89.6L36.4 98.9333H42C42 98.9333 41.5428 91.7111 41.6571 90.8222C41.7714 89.9333 37.5429 89.6 37.5429 89.6Z'
					fill='#A0616A'
				/>
				<path
					d='M41.1726 68.1333C41.1726 68.1333 44.1314 73.3257 42.378 73.7151C40.6246 74.1045 39.2 68.1333 39.2 68.1333H41.1726Z'
					fill='#A0616A'
				/>
				<path
					d='M18.9144 68.0905C18.9144 68.0905 16.7214 74.6581 25.3838 76.4391C34.0461 78.2202 36.6777 79.6673 36.6777 79.6673L37.7742 91.0214L45.34 91.4667C45.34 91.4667 48.9584 74.2128 44.2435 71.9865C39.5286 69.7602 29.5505 67.2 29.5505 67.2L18.9144 68.0905Z'
					fill='#2F2E41'
				/>
				<path
					d='M37.4969 97.2085C37.4969 97.2085 39.091 96.3944 39.3188 96.3944C39.3188 96.3944 40.3196 94.0365 42.1534 94.285L46.1507 100.232C46.1507 100.232 52.1856 104.652 47.9726 105.815C43.7595 106.978 40.4574 106.048 40.4574 106.048L38.7494 104.768C38.7494 104.768 38.9772 103.489 37.4969 103.722C37.4969 103.722 34.4225 102.442 34.5364 101.512C34.6503 100.581 37.0689 96.5314 37.0689 96.5314L37.4969 97.2085Z'
					fill='#2F2E41'
				/>
				<circle cx='37.8' cy='42.4667' r='4.2' fill='#A0616A' />
				<path
					d='M34.7178 40.1333C34.7178 40.1333 30.7326 42.9697 29.9577 43.0788C29.1828 43.1879 33.6108 48.5333 33.6108 48.5333C33.6108 48.5333 35.7141 45.1515 37.0425 44.7152C38.3709 44.2788 34.7178 40.1333 34.7178 40.1333Z'
					fill='#A0616A'
				/>
				<path
					d='M31.6582 44.2256C31.6582 44.2256 29.9891 42 28.9876 42C27.9861 42 18.9727 43.0015 18.1938 50.4574C17.4148 57.9133 17.8599 70.9333 17.8599 70.9333C17.8599 70.9333 17.1923 67.7062 24.6478 69.0415C24.6478 69.0415 33.5499 67.1497 35.1078 67.1497C36.6657 67.1497 37.3333 51.2364 37.3333 51.2364L34.7997 47.308C34.7997 47.308 33.6612 49.4559 31.6582 44.2256Z'
					fill='#D0CDE1'
				/>
				<path
					d='M35.7237 50.4L37.3469 51.2866L39.2947 63.4776L42 69.7947C42 69.7947 39.0783 68.7646 38.8618 70L34.5333 63.5884L35.7237 50.4Z'
					fill='#D0CDE1'
				/>
				<path
					d='M37.1095 38.5605L39.5431 38.8611C39.4966 39.3508 39.5095 39.9575 39.9483 40.1891C40.1546 40.298 40.4163 40.2925 40.5992 40.4366C41.0389 40.7833 40.6088 41.5289 40.857 42.0279L41.5688 42.2141C41.7415 42.5337 42.0359 42.7719 42.3869 42.876C42.7378 42.9801 43.1162 42.9414 43.4383 42.7686C43.9165 42.4846 44.1602 41.942 44.3754 41.4331C44.6325 40.825 44.8949 40.1717 44.7662 39.525C44.6279 38.8302 44.0539 38.2529 44.0279 37.5452C44.0162 37.2287 44.0838 36.834 43.814 36.6633C43.6898 36.6056 43.5561 36.5705 43.4194 36.5598C42.7977 36.4314 42.4515 35.7951 41.9878 35.3658C41.0162 34.4663 39.5241 34.4899 38.1955 34.5684C37.8151 34.5697 37.4378 34.6348 37.0794 34.7607C36.6743 34.9616 36.3162 35.2441 36.0279 35.5899C35.3154 36.3414 34.6217 37.1285 34.1517 38.0478C33.6816 38.9672 33.45 40.0413 33.7055 41.04C33.6804 40.9439 34.526 40.3151 34.6313 40.2143C34.9618 39.8978 34.9919 39.7467 35.1707 39.3825C35.5877 38.5336 36.1674 38.4441 37.1095 38.5605Z'
					fill='#2F2E41'
				/>
				<path
					opacity='0.1'
					d='M30.8 52.2667L31.1539 60.2887L36.4 66.2667L31.6257 59.6011L30.8 52.2667V52.2667Z'
					fill='black'
				/>
				<path
					opacity='0.1'
					d='M21.4667 55.0667L25.1417 64.0362L29.8667 68.1333L24.8266 64.4791L21.4667 55.0667V55.0667Z'
					fill='black'
				/>
				<path
					d='M42.5849 61.6686L44.5891 63.2511C44.7948 63.4371 44.8587 63.7633 44.7422 64.0326L44.4742 64.6675L44.5362 64.7165L44.2317 65.4379L44.1696 65.3889L41.962 70.6181C41.9156 70.7573 41.8165 70.8648 41.6928 70.9101C41.5691 70.9554 41.4341 70.9337 41.326 70.8511L39.4425 69.364C39.206 69.1501 39.1325 68.7751 39.2665 68.4655L42.0537 61.8633C42.0925 61.747 42.1752 61.6573 42.2786 61.6194C42.3819 61.5815 42.4946 61.5997 42.5849 61.6686Z'
					fill='#3F3D56'
				/>
				<path d='M39.2 68.2904L41.63 70L44.8 63.3096L42.3698 61.6L39.2 68.2904V68.2904Z' fill='white' />
				<path
					d='M37.5294 68.1333C37.5294 68.1333 42.4068 71.5681 40.7071 72.6423C39.0073 73.7166 35.4667 68.9489 35.4667 68.9489L37.5294 68.1333Z'
					fill='#A0616A'
				/>
				<path
					d='M24.7323 44.8C24.7323 44.8 19.2347 46.48 20.8206 51.632C21.2031 52.8748 21.6656 54.2088 22.1473 55.5178C23.6623 59.635 25.3666 63.504 25.3666 63.504L30.1452 68.0812L36.5732 70L37.3133 69.328C37.3133 69.328 37.5248 67.2 36.5732 66.976C35.6217 66.752 34.1416 65.856 34.1416 65.856L30.3356 59.92C30.3356 59.92 30.2299 45.136 24.7323 44.8Z'
					fill='#D0CDE1'
				/>
				<rect
					x='207.2'
					y='132.533'
					width='72.8'
					height='73.7333'
					transform='rotate(-180 207.2 132.533)'
					fill='#046CB6'
				/>
				<path d='M137.2 60.6667H202.562H206.267V64.591V129.733H202.562V64.591H137.2V60.6667V60.6667Z' fill='#3F3D56' />
				<path
					opacity='0.3'
					d='M137.2 60.6667H202.562H206.267V64.591V129.733H202.562V64.591H137.2V60.6667V60.6667Z'
					fill='black'
				/>
				<path
					opacity='0.1'
					d='M148.563 64.4H151.43H202.533V73.8457H151.43V129.733H138.133V64.4H148.563V64.4Z'
					fill='black'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M132.533 134.4V56.9333H210V134.4H132.533ZM205.77 61.1207H136.764V130.213H205.77V61.1207Z'
					fill='#3F3D56'
				/>
				<path
					d='M178.227 92.5968C176.539 89.7437 173.199 89.6108 173.199 89.6108C173.199 89.6108 169.944 89.1945 167.856 93.5398C165.91 97.5899 163.225 101.5 167.424 102.449L168.182 100.087L168.652 102.624C169.25 102.667 169.85 102.678 170.449 102.655C174.946 102.51 179.229 102.698 179.091 101.083C178.908 98.9377 179.851 95.342 178.227 92.5968Z'
					fill='#2F2E41'
				/>
				<path
					d='M166.472 115.211L166.133 121.259L176.061 122.267L176.4 115.995L173.015 113.867L166.472 115.211V115.211Z'
					fill='#A0616A'
				/>
				<path
					d='M194.094 129.439C193.785 126.483 181.516 125.141 178.568 124.861L177.465 124.224L177.114 120.516C177.114 120.516 166.223 119.589 165.404 119.473C164.584 119.357 163.881 120.864 163.881 121.211C163.881 121.463 163.728 123.257 163.113 124.32C161.784 125.377 157.733 128.701 157.733 129.96C157.733 131.467 160.954 150.414 160.954 150.414L161.305 154.354L164.584 154.933L164.35 142.65C164.35 142.65 165.521 135.581 163.881 133.611V132.8C163.881 132.8 168.69 131.56 170.385 130.21C170.876 130.106 172.256 129.848 173.018 130.072C173.122 130.133 173.243 130.196 173.377 130.259C173.394 130.274 173.41 130.291 173.425 130.308L173.438 130.288C175.631 131.304 181.255 132.584 183.555 132.22C185.328 131.952 187.142 132.279 188.707 133.147C187.419 134.77 188.356 139.405 188.356 139.405L188.473 149.371L190.112 151.341H192.337V145.894C192.337 145.894 194.445 132.8 194.094 129.439Z'
					fill='#2F2E41'
				/>
				<circle cx='172.667' cy='97.0667' r='3.73333' fill='#A0616A' />
				<path
					d='M169.979 98.9333C169.979 98.9333 170.539 101.555 169.755 101.793C169.134 102.012 168.535 102.291 167.963 102.627L167.067 105.725V108.823L177.931 110.133L178.267 109.061V107.512L177.035 105.129L176.475 104.533C176.475 104.533 174.011 101.674 174.571 98.9333L169.979 98.9333Z'
					fill='#A0616A'
				/>
				<path
					d='M179.937 108.267C179.937 108.267 180.379 112.215 179.937 114.021C179.495 115.826 179.642 118.533 179.642 118.533L176.4 118.082V111.99L177.137 108.718L179.937 108.267Z'
					fill='#A0616A'
				/>
				<circle cx='172.667' cy='90.5333' r='2.8' fill='#2F2E41' />
				<path
					d='M170.4 89.6C170.4 88.1678 171.378 86.9666 172.667 86.8157C172.578 86.8054 172.489 86.8001 172.4 86.8C171.001 86.8 169.867 88.0536 169.867 89.6C169.867 91.1464 171.001 92.4 172.4 92.4C172.489 92.3999 172.578 92.3946 172.667 92.3843C171.378 92.2335 170.4 91.0322 170.4 89.6V89.6Z'
					fill='#2F2E41'
				/>
				<path
					d='M177.186 91.5871L174.091 89.6L169.817 90.4129L168.933 95.2L171.134 95.0962L171.749 93.3376V95.0672L172.765 95.0193L173.354 92.2193L173.723 95.2L177.333 95.1096L177.186 91.5871V91.5871Z'
					fill='#2F2E41'
				/>
				<path
					d='M164.101 150.267C164.101 150.267 165.624 151.037 166.072 151.325C166.52 151.614 168.311 151.037 167.953 152.769C167.595 154.502 166.072 156.138 166.072 156.138C166.072 156.138 165.355 157.004 165.355 157.582C165.355 158.159 163.564 162.586 161.414 162.394C159.264 162.201 159.622 159.891 159.622 159.891L160.07 157.389C160.07 157.389 160.966 153.154 161.145 153.25C161.324 153.347 161.772 154.02 161.772 154.02C161.772 154.02 164.012 153.058 164.101 150.267Z'
					fill='#D0CDE1'
				/>
				<path
					d='M187.765 146.533C187.765 146.533 186.243 147.303 185.795 147.592C185.347 147.881 183.555 147.303 183.914 149.036C184.272 150.768 185.795 152.404 185.795 152.404C185.795 152.404 186.511 153.271 186.511 153.848C186.511 154.426 188.303 158.853 190.453 158.661C192.603 158.468 192.244 156.158 192.244 156.158L191.796 153.656C191.796 153.656 190.901 149.421 190.721 149.517C190.542 149.613 190.094 150.287 190.094 150.287C190.094 150.287 187.855 149.325 187.765 146.533Z'
					fill='#D0CDE1'
				/>
				<path
					d='M175.467 119.674V118.741H175.233V118.326C175.233 118.133 175.147 117.949 174.994 117.813C174.841 117.676 174.633 117.6 174.417 117.6H171.617C171.166 117.6 170.8 117.925 170.8 118.326V125.274C170.8 125.675 171.166 126 171.617 126H174.417C174.868 126 175.233 125.675 175.233 125.274V120.919H175.467V119.985H175.233V119.674H175.467Z'
					fill='#D0CDE1'
				/>
				<circle cx='173.133' cy='119.933' r='0.466667' fill='#3F3D56' />
				<path
					d='M162.744 105.467L160.706 114.025C160.706 114.025 159.913 115.876 161.725 117.032C163.537 118.189 167.613 119.693 167.613 119.693C167.613 119.693 171.236 124.782 171.689 122.7C172.142 120.618 168.972 117.958 168.972 117.958L163.423 114.372L165.575 108.358L162.744 105.467Z'
					fill='#A0616A'
				/>
				<path
					d='M178.475 116.675C178.475 116.675 180.246 116.448 180.128 118.374C180.01 120.299 177.649 120.525 177.649 120.525C177.649 120.525 176.586 124.829 175.052 124.036C173.517 123.243 175.878 118.94 175.878 118.94L177.885 117.468L178.475 116.675Z'
					fill='#A0616A'
				/>
				<path
					d='M167.335 105.551L169.162 101.733L165.507 103.006C165.507 103.006 163.451 104.047 163.337 106.476C163.223 108.906 165.964 110.178 165.964 110.178C165.964 110.178 166.421 113.649 166.192 114.112C165.964 114.575 166.192 116.425 166.192 116.425C166.192 116.425 170.59 115.211 172.761 116.715C174.931 118.219 176.701 117.351 176.701 117.351C176.701 117.351 176.587 114.459 177.501 113.996C178.415 113.533 179.214 111.335 178.415 109.947C178.415 109.947 180.242 110.178 180.128 108.443C180.014 106.708 179.1 105.204 177.615 104.51C176.13 103.816 175.966 103.705 175.966 103.705L178.072 107.633V108.675C178.072 108.675 174.344 106.264 170.655 106.064C169.452 106.008 168.27 106.402 167.335 107.171L167.335 107.171L167.335 105.551Z'
					fill='#2F2E41'
				/>
			</SvgIcon>
		);
	}

	return (
		<SvgIcon
			className={`integration-icon ${className}`}
			fill='none'
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			width={width}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M51.1974 90.5002C56.9675 119.275 82.4255 139.999 112.002 140C128.454 140.044 144.239 133.558 155.841 121.988C176.755 101.242 179.983 68.7313 163.551 44.3373C147.119 19.9432 115.603 10.4601 88.2776 21.6874C60.9521 32.9147 45.4272 61.7255 51.1974 90.5002ZM50.4097 78.5028C50.4097 44.8142 78.04 17.4066 112.002 17.4066C145.965 17.4066 173.595 44.8142 173.595 78.5028C173.595 112.191 145.965 139.599 112.002 139.599C78.04 139.599 50.4097 112.191 50.4097 78.5028Z'
				fill='#3F3D56'
			/>
			<path
				d='M116.689 132.845C115.661 137.264 112.138 140 112.138 140C112.138 140 110.282 135.573 111.311 131.155C112.339 126.736 115.862 124 115.862 124C115.862 124 117.718 128.427 116.689 132.845Z'
				fill='#C30B25'
			/>
			<path
				d='M106.311 132.845C107.339 137.264 110.862 140 110.862 140C110.862 140 112.718 135.573 111.689 131.155C110.661 126.736 107.138 124 107.138 124C107.138 124 105.282 128.427 106.311 132.845Z'
				fill='#C30B25'
			/>
			<path
				d='M108.591 132.293C111.629 135.331 112 140 112 140C112 140 107.446 139.745 104.409 136.707C101.371 133.669 101 129 101 129C101 129 105.554 129.255 108.591 132.293Z'
				fill='#3F3D56'
			/>
			<path
				d='M115.409 132.293C112.371 135.331 112 140 112 140C112 140 116.554 139.745 119.591 136.707C122.629 133.669 123 129 123 129C123 129 118.446 129.255 115.409 132.293Z'
				fill='#3F3D56'
			/>
			<path
				d='M106.311 23.1546C107.339 18.7364 110.862 16 110.862 16C110.862 16 112.718 20.4271 111.689 24.8454C110.661 29.2637 107.138 32 107.138 32C107.138 32 105.282 27.5729 106.311 23.1546Z'
				fill='#046CB6'
			/>
			<path
				d='M116.689 23.1546C115.661 18.7364 112.138 16 112.138 16C112.138 16 110.282 20.4271 111.311 24.8454C112.339 29.2637 115.862 32 115.862 32C115.862 32 117.718 27.5729 116.689 23.1546Z'
				fill='#046CB6'
			/>
			<path
				d='M115.409 25.707C112.371 22.6694 112 18 112 18C112 18 116.554 18.2554 119.591 21.293C122.629 24.3306 123 29 123 29C123 29 118.446 28.7446 115.409 25.707Z'
				fill='#3F3D56'
			/>
			<path
				d='M108.591 25.707C111.629 22.6694 112 18 112 18C112 18 107.446 18.2554 104.409 21.293C101.371 24.3306 101 29 101 29C101 29 105.554 28.7446 108.591 25.707Z'
				fill='#3F3D56'
			/>
			<rect x='3' y='3' width='79' height='81' fill='#C30B25' />
			<path d='M80 5H8.07657H4V9.26142V80H8.07657V9.26142H80V5V5Z' fill='#3F3D56' />
			<path opacity='0.3' d='M80 5H8.07657H4V9.26142V80H8.07657V9.26142H80V5V5Z' fill='black' />
			<path opacity='0.1' d='M67.502 9H64.3401H8V19.4096H64.3401V81H79V9H67.502V9Z' fill='black' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0 86V0H84V86H0ZM4.58703 81.3514H79.413V4.64866H4.58703V81.3514Z'
				fill='#3F3D56'
			/>
			<path
				d='M58.2448 88C58.2448 88 62.24 95.5327 64.4873 97.2336C66.7345 98.9346 60.8667 101 60.8667 101L54 90.5514L58.2448 88Z'
				fill='#A0616A'
			/>
			<path
				d='M36.3863 70C36.3863 70 54.1372 74.6567 55.6859 77.7612C57.2347 80.8657 62 88.3881 62 88.3881L54.9711 94L48.0614 83.6119L29 75.0149L36.3863 70Z'
				fill='#2F2E41'
			/>
			<path
				d='M61.1566 97.9637C61.1566 97.9637 59.4451 96.6294 58.956 97.5189C58.467 98.4084 57 102.967 57 102.967C57 102.967 59.0783 104.746 63.3571 103.634C63.3571 103.634 64.4574 102.856 66.169 103.634C67.8805 104.413 74.2376 102.856 73.9931 100.299C73.7486 97.7413 67.2692 97.9637 67.2692 97.9637C67.2692 97.9637 64.5402 95.1828 64.1932 96.2397C63.8462 97.2965 64.3352 97.4077 64.3352 97.4077C64.3352 97.4077 62.1346 99.5203 61.1566 97.9637Z'
				fill='#2F2E41'
			/>
			<path
				d='M40.2245 96L39 106H45C45 106 44.5102 98.2619 44.6327 97.3095C44.7551 96.3571 40.2245 96 40.2245 96Z'
				fill='#A0616A'
			/>
			<path
				d='M44.1134 73C44.1134 73 47.2836 78.5632 45.405 78.9804C43.5264 79.3977 42 73 42 73H44.1134Z'
				fill='#A0616A'
			/>
			<path
				d='M20.2655 72.9541C20.2655 72.9541 17.9158 79.9908 27.1969 81.8991C36.478 83.8073 39.2975 85.3578 39.2975 85.3578L40.4723 97.5229L48.5786 98C48.5786 98 52.4555 79.5138 47.4038 77.1284C42.3521 74.7431 31.6612 72 31.6612 72L20.2655 72.9541Z'
				fill='#2F2E41'
			/>
			<path
				d='M40.1753 104.152C40.1753 104.152 41.8833 103.28 42.1273 103.28C42.1273 103.28 43.1996 100.753 45.1644 101.02L49.4472 107.392C49.4472 107.392 55.9131 112.127 51.3992 113.373C46.8852 114.619 43.3472 113.622 43.3472 113.622L41.5173 112.252C41.5173 112.252 41.7613 110.881 40.1753 111.13C40.1753 111.13 36.8813 109.76 37.0033 108.763C37.1253 107.766 39.7167 103.426 39.7167 103.426L40.1753 104.152Z'
				fill='#2F2E41'
			/>
			<circle cx='40.5' cy='45.5' r='4.5' fill='#A0616A' />
			<path
				d='M37.1976 43C37.1976 43 32.9278 46.039 32.0975 46.1558C31.2673 46.2727 36.0116 52 36.0116 52C36.0116 52 38.2651 48.3766 39.6884 47.9091C41.1117 47.4416 37.1976 43 37.1976 43Z'
				fill='#A0616A'
			/>
			<path
				d='M33.9195 47.3846C33.9195 47.3846 32.1312 45 31.0581 45C29.9851 45 20.3279 46.0731 19.4933 54.0615C18.6588 62.05 19.1357 76 19.1357 76C19.1357 76 18.4203 72.5423 26.4084 73.9731C26.4084 73.9731 35.9464 71.9462 37.6155 71.9462C39.2847 71.9462 40 54.8962 40 54.8962L37.2854 50.6871C37.2854 50.6871 36.0656 52.9885 33.9195 47.3846Z'
				fill='#D0CDE1'
			/>
			<path
				d='M38.2754 54L40.0145 54.95L42.1014 68.0117L45 74.78C45 74.78 41.8696 73.6763 41.6377 75L37 68.1304L38.2754 54Z'
				fill='#D0CDE1'
			/>
			<path
				d='M39.7602 41.3148L42.3676 41.6369C42.3178 42.1616 42.3316 42.8116 42.8018 43.0598C43.0228 43.1765 43.3032 43.1705 43.4991 43.325C43.9702 43.6964 43.5094 44.4953 43.7754 45.0299L44.538 45.2294C44.723 45.5718 45.0385 45.827 45.4145 45.9386C45.7905 46.0501 46.196 46.0087 46.541 45.8235C47.0534 45.5192 47.3145 44.9379 47.5451 44.3926C47.8206 43.7411 48.1017 43.0411 47.9638 42.3482C47.8157 41.6038 47.2007 40.9852 47.1727 40.227C47.1603 39.8879 47.2327 39.465 46.9435 39.2821C46.8105 39.2203 46.6673 39.1827 46.5207 39.1712C45.8546 39.0336 45.4837 38.3519 44.987 37.892C43.9459 36.9282 42.3472 36.9535 40.9237 37.0375C40.5162 37.039 40.1119 37.1087 39.7279 37.2436C39.2939 37.4589 38.9102 37.7615 38.6014 38.132C37.8379 38.9372 37.0947 39.7805 36.591 40.7655C36.0874 41.7506 35.8393 42.9014 36.113 43.9715C36.0861 43.8685 36.9922 43.1948 37.1049 43.0868C37.4591 42.7477 37.4913 42.5857 37.6829 42.1955C38.1296 41.286 38.7508 41.1901 39.7602 41.3148Z'
				fill='#2F2E41'
			/>
			<path opacity='0.1' d='M33 56L33.3792 64.595L39 71L33.8847 63.8583L33 56V56Z' fill='black' />
			<path opacity='0.1' d='M23 59L26.9375 68.6102L32 73L26.5999 69.0848L23 59V59Z' fill='black' />
			<path
				d='M45.6267 66.0735L47.774 67.769C47.9944 67.9684 48.0629 68.3178 47.9381 68.6063L47.6509 69.2866L47.7174 69.3391L47.391 70.1121L47.3245 70.0596L44.9593 75.6622C44.9095 75.8114 44.8034 75.9265 44.6708 75.9751C44.5383 76.0237 44.3937 76.0004 44.2778 75.9119L42.2599 74.3186C42.0064 74.0894 41.9277 73.6876 42.0712 73.3559L45.0576 66.2821C45.0991 66.1575 45.1877 66.0614 45.2984 66.0208C45.4092 65.9802 45.5299 65.9996 45.6267 66.0735Z'
				fill='#3F3D56'
			/>
			<path d='M42 73.1683L44.6036 75L48 67.8317L45.3962 66L42 73.1683V73.1683Z' fill='white' />
			<path
				d='M40.21 73C40.21 73 45.4358 76.6801 43.6147 77.8311C41.7935 78.9821 38 73.8738 38 73.8738L40.21 73Z'
				fill='#A0616A'
			/>
			<path
				d='M26.4989 48C26.4989 48 20.6086 49.8 22.3078 55.32C22.7176 56.6516 23.2132 58.0809 23.7292 59.4833C25.3525 63.8947 27.1786 68.04 27.1786 68.04L32.2984 72.9441L39.1856 75L39.9786 74.28C39.9786 74.28 40.2051 72 39.1856 71.76C38.1662 71.52 36.5803 70.56 36.5803 70.56L32.5025 64.2C32.5025 64.2 32.3892 48.36 26.4989 48Z'
				fill='#D0CDE1'
			/>
			<rect x='222' y='142' width='78' height='79' transform='rotate(-180 222 142)' fill='#046CB6' />
			<path d='M147 65H217.031H221V69.2046V139H217.031V69.2046H147V65V65Z' fill='#3F3D56' />
			<path opacity='0.3' d='M147 65H217.031H221V69.2046V139H217.031V69.2046H147V65V65Z' fill='black' />
			<path opacity='0.1' d='M159.174 69H162.247H217V79.1204H162.247V139H148V69H159.174V69Z' fill='black' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M142 144V61H225V144H142ZM220.468 65.4865H146.532V139.514H220.468V65.4865Z'
				fill='#3F3D56'
			/>
			<path
				d='M190.958 99.2109C189.149 96.154 185.57 96.0115 185.57 96.0115C185.57 96.0115 182.083 95.5655 179.846 100.221C177.761 104.561 174.883 108.75 179.383 109.766L180.196 107.236L180.699 109.955C181.339 110.001 181.982 110.012 182.624 109.988C187.442 109.832 192.031 110.033 191.884 108.304C191.687 106.005 192.698 102.152 190.958 99.2109Z'
				fill='#2F2E41'
			/>
			<path d='M178.363 123.44L178 129.92L188.637 131L189 124.28L185.373 122L178.363 123.44V123.44Z' fill='#A0616A' />
			<path
				d='M207.958 138.685C207.627 135.517 194.482 134.079 191.323 133.779L190.141 133.097L189.765 129.124C189.765 129.124 178.096 128.131 177.218 128.007C176.34 127.883 175.587 129.497 175.587 129.869C175.587 130.138 175.423 132.061 174.764 133.2C173.34 134.332 169 137.894 169 139.243C169 140.857 172.45 161.158 172.45 161.158L172.827 165.379L176.34 166L176.089 152.839C176.089 152.839 177.344 145.265 175.587 143.154V142.285C175.587 142.285 180.739 140.957 182.555 139.511C183.081 139.399 184.56 139.123 185.376 139.363C185.488 139.428 185.617 139.495 185.761 139.563C185.779 139.58 185.796 139.597 185.813 139.616L185.827 139.594C188.176 140.682 194.201 142.055 196.666 141.664C198.566 141.377 200.509 141.727 202.186 142.658C200.806 144.396 201.81 149.362 201.81 149.362L201.935 160.04L203.692 162.151H206.076V156.315C206.076 156.315 208.334 142.285 207.958 138.685Z'
				fill='#2F2E41'
			/>
			<circle cx='185' cy='104' r='4' fill='#A0616A' />
			<path
				d='M182.12 106C182.12 106 182.72 108.809 181.88 109.064C181.215 109.298 180.573 109.597 179.96 109.957L179 113.277V116.596L190.64 118L191 116.851V115.191L189.68 112.638L189.08 112C189.08 112 186.44 108.936 187.04 106L182.12 106Z'
				fill='#A0616A'
			/>
			<path
				d='M192.789 116C192.789 116 193.263 120.231 192.789 122.165C192.316 124.099 192.474 127 192.474 127L189 126.516V119.989L189.789 116.484L192.789 116Z'
				fill='#A0616A'
			/>
			<circle cx='185' cy='97' r='3' fill='#2F2E41' />
			<path
				d='M182.571 96C182.572 94.4655 183.619 93.1784 185 93.0169C184.905 93.0058 184.81 93.0001 184.714 93C183.215 93 182 94.3431 182 96C182 97.6569 183.215 99 184.714 99C184.81 98.9999 184.905 98.9942 185 98.9831C183.619 98.8216 182.572 97.5345 182.571 96V96Z'
				fill='#2F2E41'
			/>
			<path
				d='M189.842 98.129L186.526 96L181.947 96.871L181 102L183.358 101.889L184.017 100.005V101.858L185.105 101.806L185.737 98.8064L186.131 102L190 101.903L189.842 98.129V98.129Z'
				fill='#2F2E41'
			/>
			<path
				d='M175.823 161C175.823 161 177.454 161.825 177.934 162.134C178.414 162.444 180.333 161.825 179.95 163.681C179.566 165.537 177.934 167.29 177.934 167.29C177.934 167.29 177.166 168.219 177.166 168.837C177.166 169.456 175.247 174.2 172.943 173.993C170.64 173.787 171.024 171.312 171.024 171.312L171.504 168.631C171.504 168.631 172.464 164.094 172.656 164.197C172.848 164.3 173.327 165.022 173.327 165.022C173.327 165.022 175.727 163.991 175.823 161Z'
				fill='#D0CDE1'
			/>
			<path
				d='M201.177 157C201.177 157 199.546 157.825 199.066 158.134C198.586 158.444 196.667 157.825 197.05 159.681C197.434 161.537 199.066 163.29 199.066 163.29C199.066 163.29 199.834 164.219 199.834 164.837C199.834 165.456 201.753 170.2 204.057 169.993C206.36 169.787 205.976 167.312 205.976 167.312L205.496 164.631C205.496 164.631 204.536 160.094 204.344 160.197C204.152 160.3 203.673 161.022 203.673 161.022C203.673 161.022 201.273 159.991 201.177 157Z'
				fill='#D0CDE1'
			/>
			<path
				d='M188 128.222V127.222H187.75V126.778C187.75 126.571 187.658 126.374 187.494 126.228C187.33 126.082 187.107 126 186.875 126H183.875C183.392 126 183 126.348 183 126.778V134.222C183 134.652 183.392 135 183.875 135H186.875C187.358 135 187.75 134.652 187.75 134.222V129.556H188V128.556H187.75V128.222H188Z'
				fill='#D0CDE1'
			/>
			<circle cx='185.5' cy='128.5' r='0.5' fill='#3F3D56' />
			<path
				d='M174.369 113L172.185 122.17C172.185 122.17 171.336 124.153 173.277 125.392C175.218 126.631 179.585 128.242 179.585 128.242C179.585 128.242 183.467 133.695 183.953 131.464C184.438 129.233 181.041 126.383 181.041 126.383L175.097 122.542L177.402 116.098L174.369 113Z'
				fill='#A0616A'
			/>
			<path
				d='M191.223 125.009C191.223 125.009 193.12 124.766 192.994 126.829C192.867 128.892 190.338 129.134 190.338 129.134C190.338 129.134 189.2 133.745 187.555 132.896C185.911 132.046 188.441 127.436 188.441 127.436L190.591 125.858L191.223 125.009Z'
				fill='#A0616A'
			/>
			<path
				d='M179.287 113.09L181.245 109L177.329 110.363C177.329 110.363 175.126 111.479 175.004 114.082C174.881 116.685 177.819 118.048 177.819 118.048C177.819 118.048 178.308 121.767 178.063 122.263C177.819 122.758 178.063 124.742 178.063 124.742C178.063 124.742 182.775 123.44 185.101 125.051C187.426 126.663 189.323 125.733 189.323 125.733C189.323 125.733 189.201 122.634 190.18 122.139C191.159 121.643 192.015 119.288 191.159 117.8C191.159 117.8 193.117 118.048 192.994 116.189C192.872 114.33 191.893 112.718 190.302 111.975C188.711 111.231 188.535 111.112 188.535 111.112L190.792 115.321V116.437C190.792 116.437 186.797 113.855 182.845 113.639C181.556 113.58 180.29 114.002 179.287 114.826L179.287 114.826L179.287 113.09Z'
				fill='#2F2E41'
			/>
		</SvgIcon>
	);
};
