import * as Api from '@ViewModels';
import { StyleSheet } from 'aphrodite';
import { brandPrimary } from '../../../../styles/colors';
import { ctaButtonProps } from '../../../../styles/styles';

export const styleSheet = StyleSheet.create({
	ctaButton: {
		boxShadow: 'none',
		marginTop: 20,
	},
});

export const titleStyleSheet = StyleSheet.create({
	[Api.MergeAccountingProvider.QuickBooksOnline]: {
		color: '#2CA01C',
	},
	[Api.MergeAccountingProvider.Xero]: {
		color: '#00B7E2',
	},
	[Api.MergeCrmProvider.Salesforce]: {
		color: '#159bd7',
	},
});

export const ctaButtonStyles = {
	[Api.MergeAccountingProvider.QuickBooksOnline]: {
		...ctaButtonProps('#2CA01C'),
	},
	[Api.MergeAccountingProvider.Xero]: {
		...ctaButtonProps('#00B7E2'),
	},
	[Api.MergeCrmProvider.Salesforce]: {
		...ctaButtonProps('#159bd7'),
	},
	default: {
		background: brandPrimary,
	},
};

export const ctaStyleSheet = StyleSheet.create(ctaButtonStyles);

export const getLogoBgColor = (integrations?: Api.IAccountIntegrations) => {
	if (integrations?.mergeAccounting) {
		switch (integrations.mergeAccounting.mergeAccountingProvider) {
			case Api.MergeAccountingProvider.QuickBooksOnline:
			case Api.MergeAccountingProvider.Xero:
				return '#E8F3F9';
			default:
				return null;
		}
	}

	if (integrations?.mergeCrm) {
		switch (integrations.mergeCrm.mergeCrmProvider) {
			case Api.MergeCrmProvider.Salesforce:
				return '#E8F3F9';
			default:
				return null;
		}
	}
	return 'transparent';
};
