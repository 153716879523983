import { IUser, ResourceAutoCompleteViewModelType, VmUtils } from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IModalContext, ModalChildComponentContextKey } from '../../../../../models';
import { asModalComponent } from '../../../Modal';
import {
	ISimpleAutoCompleteSearchFieldEvent,
	ISimpleAutoCompleteSearchFieldItemSelectionEvent,
	SimpleAutoCompleteSearchField,
} from '../../../autocomplete/SimpleAutoCompleteSearchField';
import { styleSheet } from './styles';

interface IProps extends IModalContext {
	onSelectionMade?(selection: IUser): void;
}

const _searchEmployeeModal = ({ onSelectionMade }: IProps) => {
	const [selectedEmployee, setSelectedEmployee] = React.useState<IUser | null>(null);

	const onSearchFieldBlur = (e: ISimpleAutoCompleteSearchFieldEvent<React.FocusEvent<HTMLInputElement>>) => {
		if (!selectedEmployee && e.target) {
			e.target.clearInput();
		}
	};

	const onSearchFieldKeyDown = (e: ISimpleAutoCompleteSearchFieldEvent<React.KeyboardEvent<HTMLInputElement>>) => {
		if (e.sourceEvent && e.sourceEvent.key !== 'Enter') {
			clearSelectedUser();
		}
	};

	const onUserSelected = (e: ISimpleAutoCompleteSearchFieldItemSelectionEvent<IUser>) => {
		const selection = e.selection;
		if (e.target) {
			e.target.setSearchQuery(VmUtils.getDisplayName(selection));
		}
		// @ts-ignore
		onSelectionMade(selection);
		setSelectedEmployee(selection);
	};
	const clearSelectedUser = () => setSelectedEmployee(null);
	return (
		<article>
			<h3 className={css(styleSheet.title)}>Employee Search</h3>
			<SimpleAutoCompleteSearchField
				onBlur={onSearchFieldBlur}
				onClear={clearSelectedUser}
				// @ts-ignore
				onItemSelected={onUserSelected}
				onKeyDown={onSearchFieldKeyDown}
				pageSize={5}
				placeholder='Search employees'
				resultsLimit={5}
				type={ResourceAutoCompleteViewModelType.User}
			/>
		</article>
	);
};

const SearchEmployeeModalObserver = observer(_searchEmployeeModal);
const SearchEmployeeModalWithContext = inject(ModalChildComponentContextKey)(SearchEmployeeModalObserver);

export const SearchEmployeeModal = asModalComponent(SearchEmployeeModalWithContext, {
	className: 'search-employee-modal',
	useDefaultHeader: true,
});
