import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	card: {
		background: '#F8F0FF',
		flexDirection: 'column',
		minWidth: 278,
		padding: '23px 38px 0 19px',
	},
	newClientIcon: {
		height: 22,
		width: 20,
	},
});
