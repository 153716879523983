import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		boxSizing: 'border-box',
		height: '100%',
		padding: 30,
		position: 'relative',
	},
	editCampaign: {
		height: '100%',
	},
});
