import { StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	column: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		...CssSelectors.allChildren(
			{
				marginTop: '1rem',
			},
			':not(:first-child)'
		),
	},
	integrationOwnershipModal: {
		maxWidth: 400,
	},
	leftColumn: {
		'@media (min-width: 992px)': {
			maxWidth: '64%',
		},
		'@media (min-width: 1024px)': {
			flex: 2,
			maxWidth: '100%',
		},
		flex: 1,
		maxWidth: '54%',
		minHeight: 200,
		paddingBottom: '1.25rem',
		width: 'calc(100% - 0.5rem)',
	},
	rightColumn: {
		flex: '0 1 0%',
		marginLeft: '1rem',
		minHeight: 200,
	},
	root: {
		'@media (min-width: 1200px)': {
			paddingLeft: '2rem',
			paddingRight: '2rem',
		},
		boxSizing: 'border-box',
		display: 'flex',
		height: 'fit-content',
		justifyContent: 'center',
		maxWidth: '100%',
		paddingBottom: '2rem',
		paddingLeft: '1rem',
		paddingRight: '1rem',
	},
});
