import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { useLocation, useParams } from 'react-router';
import { useEventLogging } from '../../../models/Logging';
import {
	createContentStateWithHtmlStringValue,
	getDisplayName,
	getDisplayNameForBulkEmail,
	getPrincipalInitials,
	hasRequiredHwcRecipientAddressFields,
	isAddressComplete,
} from '../../../models/UiUtils';
import { useErrorMessages, useFullscreenModal, useUserSession } from '../../../models/hooks/appStateHooks';
import { useContact, useContactsQuery, useHwcBulkCreateMutation, useTemplate } from '../../../queries';
import { Avatar2 } from '../../components/Avatar2';
import { DayPicker } from '../../components/DayPicker';
import { DeprecatedCloseButton } from '../../components/DeprecatedCloseButton';
import { FabContext } from '../../components/FabContext';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { Modal } from '../../components/Modal';
import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { Popover, PopoverType } from '../../components/Popover';
import { TextArea } from '../../components/TextArea';
import { TextInput } from '../../components/TextInput';
import { TinyPopover } from '../../components/TinyPopover';
import { AutoCompleteSearchField } from '../../components/autocomplete/AutoCompleteSearchField';
import { ContactContextTabView } from '../../components/email/ContactContextTabView';
import { PurchaseCreditsModal } from '../../components/settings/PurchaseCreditsModal';
import { DropDownIcon } from '../../components/svgs/icons/DropDownIcon';
import { SearchIcon } from '../../components/svgs/icons/SearchIcon';
import { baseStyleSheet } from '../../styles/styles';
import { addressTextFromHwcRecipient, addressTextFromHwcReturnContact } from '../PostcardComposer';
import { AddressFormField } from '../PostcardComposer/AddressFormField';
import { HwcOrderSuccessPage } from '../PostcardComposer/HwcOrderSuccessPage';
import { HwcPreviewToggle, HwcTogglePreview, HwcTogglePreviewValues } from '../PostcardComposer/HwcPreviewToggle';
import { HwcTextPreview } from '../PostcardComposer/HwcTextPreview';
import { PostcardTextEditor } from '../PostcardComposer/PostcardTextEditor';
import { RecipientAddressModal, addresseeNameFromHwcRecipient } from '../PostcardComposer/RecipientAddressModal';
import { ReturnContactModal } from '../PostcardComposer/ReturnAddressModal';
import { useCurrentUserGiftingBalance } from '../PostcardComposer/hooks/useCurrentUserGiftingBalance';
import {
	HANDWRITTEN_CARD_TEXT_MAX_ALLOWED_CHARACTERS,
	usePostcardTextEditor,
} from '../PostcardComposer/hooks/usePostcardTextEditor';
import { hwcRecipientFromContact } from '../PostcardComposer/hooks/useRecipient';
import { AddressMissingPill } from './AddressMissingPill';
import { CustomRecipientAddressField } from './CustomRecipientAddressField';
import { useHwcSaveTemplate } from './hooks/useHwcSaveTemplate';
import { BulkHwcCustomLayout, CampaignComposerBaseLayout, SaveTemplateModal } from './presentation';
import { styleSheet } from './styles';

interface IUserCustomCard {
	handwritingStyle: Api.HandwritingStyle;
	postcardContent: string;
	signature: string;
	recipient: Api.IHandwrittenCardRecipient;
}

export function BulkHwcFromContactSelectionPage() {
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const location = useLocation<{ contactIds: string[] }>();
	const [contactIds, setContactIds] = React.useState(location.state.contactIds);
	const { logApiError } = useEventLogging('BulkHwcBirthdayComposerPage');
	const params = useParams<{ templateId: string }>();
	const fullscreenModal = useFullscreenModal();
	const [step, setStep] = React.useState<'recipients' | 'reviewOrder' | 'success'>('recipients');
	const [selectedHwcTemplateId, setSelectedHwcTemplateId] = React.useState<string>(params.templateId);
	const selectedTemplateQuery = useTemplate<Api.IHandwrittenCardTemplate>({ templateId: selectedHwcTemplateId });
	const [postcardContent, setPostcardContent] = React.useState('');
	const { handwritingStyle, postcardText, getPostcardTextEditorProps, touched } = usePostcardTextEditor({
		content: postcardContent,
		// @ts-ignore
		defaultHandwritingStyle: userSession.user.userPreferences?.handwrittenCardPreferences?.handwritingStyle,
		onContentChanged: setPostcardContent,
	});
	const [toggledHwcPreview, setToggledHwcPreview] = React.useState<HwcTogglePreviewValues>('image');
	const [signature, setSignature] = React.useState(
		// @ts-ignore
		userSession.user.userPreferences?.handwrittenCardPreferences?.signature ?? ''
	);
	const [isEditingPostcard, setIsEditingPostcard] = React.useState(false);
	const [datePickerOpen, setDatePickerOpen] = React.useState(false);
	// @ts-ignore
	const [mailDate, setMailDate] = React.useState<Date>(null);
	const mailDateText = mailDate ? moment(mailDate).format('YYYY-MM-DD') : '';
	const [showPurchaseCreditsModal, setShowPurchaseCreditsModal] = React.useState(false);
	const {
		createTemplateMutation,
		handleSaveAsButtonClicked,
		handleSaveAsModalClose,
		handleSaveAsModalSave,
		handleSaveButtonClicked,
		isSaveAllowed,
		isSaveAsPopoverShowing,
		isShowingSaveAsModal,
		toggleSaveAsPopover,
		updatingTemplateStatus,
	} = useHwcSaveTemplate({
		onSavedNewTemplate: template => {
			// @ts-ignore
			setSelectedHwcTemplateId(template.id);
		},
		postcardContent,
		// @ts-ignore
		template: selectedTemplateQuery.data,
	});
	const [cardCustomizationActiveContactId, setCardCustomizationActiveContactId] = React.useState<string | null>(null);
	const [customCardUserMapping] = React.useState(new Map<string, IUserCustomCard>());
	const [customUserCardContent, setCustomUserCardContent] = React.useState(postcardContent);
	const [customUserHwStyle, setCustomUserHwStyle] = React.useState(handwritingStyle);
	const [customUserSignature, setCustomUserSignature] = React.useState(signature);
	const [showRecipientAddressModal, setShowRecipientAddressModal] = React.useState(false);
	// @ts-ignore
	const [customUserRecipient, setCustomUserRecipient] = React.useState<Api.IHandwrittenCardRecipient>(null);
	const [returnContact, setReturnContact] = React.useState<Api.IHandwrittenCardReturnContact>(
		// @ts-ignore
		userSession.user?.userPreferences?.handwrittenCardPreferences?.returnContact ??
			userSession.account?.preferences?.handwrittenCardPreferences?.returnContact
	);
	const returnAddressText =
		// @ts-ignore
		returnContact && isAddressComplete(returnContact.address) ? addressTextFromHwcReturnContact(returnContact) : '';
	const [isShowingReturnContactModal, setIsShowingReturnContactModal] = React.useState(false);
	const customContactQuery = useContact({
		// @ts-ignore
		contactId: cardCustomizationActiveContactId,
		enabled: cardCustomizationActiveContactId != null,
	});
	const customContactVm = React.useMemo(() => {
		return customContactQuery.data ? new Api.ContactViewModel(userSession, customContactQuery.data) : null;
	}, [customContactQuery.data, userSession]);
	// Set recipient data from query when customization is active, there's no recipient customization so far and queryData is available
	React.useEffect(() => {
		if (cardCustomizationActiveContactId != null && customUserRecipient == null && customContactQuery.data != null) {
			setCustomUserRecipient(hwcRecipientFromContact(customContactQuery.data));
		}
	}, [customUserRecipient, customContactQuery.data, cardCustomizationActiveContactId]);
	const customUserRecipientAddressText =
		customUserRecipient && hasRequiredHwcRecipientAddressFields(customUserRecipient.address)
			? addressTextFromHwcRecipient(customUserRecipient)
			: '';
	const customPostcardEditor = usePostcardTextEditor({
		content: customUserCardContent,
		handwritingStyle: customUserHwStyle,
		onContentChanged: content => setCustomUserCardContent(content),
		onHandwritingStyleChanged: hwStyle => setCustomUserHwStyle(hwStyle),
	});
	React.useEffect(() => {
		// If the template has content, but the postcard editor is untouched, set the postcard text to the template content
		// Only when editor is untouched do we want to set the postcard text to the template content
		// Otherwise we don't want to overwrite it with the template content
		if (selectedTemplateQuery.data?.content && !touched) {
			setPostcardContent(Api.getContentHtmlStringValueFromRawRichTextContentState(selectedTemplateQuery.data.content));
		}
	}, [selectedTemplateQuery.data?.content, touched]);
	const onEditClicked = () => {
		setIsEditingPostcard(true);
	};
	const onPreviewClicked = () => {
		setIsEditingPostcard(false);
	};
	const handleRecipientClick = (contactId: string) => {
		const customCard = customCardUserMapping.get(contactId);
		if (customCard) {
			setCustomUserCardContent(customCard.postcardContent);
			setCustomUserHwStyle(customCard.handwritingStyle);
			setCustomUserSignature(customCard.signature);
			setCustomUserRecipient(customCard.recipient);
		} else {
			setCustomUserCardContent(postcardContent);
			setCustomUserHwStyle(handwritingStyle);
			setCustomUserSignature(signature);
		}
		setCardCustomizationActiveContactId(contactId);
	};
	const clearCustomUserCard = () => {
		setCustomUserHwStyle(handwritingStyle);
		setCustomUserCardContent(postcardContent);
		setCustomUserSignature(signature);
		// @ts-ignore
		setCustomUserRecipient(null);
		setCardCustomizationActiveContactId(null);
	};
	const handleSaveCustomUserCardClick = () => {
		if (!customUserCardContent) {
			return;
		}
		// @ts-ignore
		customCardUserMapping.set(cardCustomizationActiveContactId, {
			handwritingStyle: customUserHwStyle,
			postcardContent: customUserCardContent,
			recipient: customUserRecipient,
			signature: customUserSignature,
		});
		clearCustomUserCard();
	};
	const handleCancelCustomUserCardClick = (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
		ev.preventDefault();
		clearCustomUserCard();
	};
	const contactsQuery = useContactsQuery({
		contactIds,
	});
	const onAddContact = (contact: Api.IContact) => {
		// @ts-ignore
		if (contactIds.indexOf(contact.id) === -1) {
			// @ts-ignore
			setContactIds([contact.id, ...contactIds]);
		}
	};
	const onRemoveContact = (contactId: string) => {
		customCardUserMapping.delete(contactId);
		setContactIds(contactIds.filter(id => id !== contactId));
	};
	const hasSendableAddress = (contact: Api.IContact) => {
		// @ts-ignore
		const contactCustomCard = customCardUserMapping.get(contact.id);
		return (
			(contactCustomCard && hasRequiredHwcRecipientAddressFields(contactCustomCard.recipient.address)) ||
			(contact.address != null && hasRequiredHwcRecipientAddressFields(contact.address))
		);
	};
	const numberOfRecipients = contactsQuery.filter(contactQuery => {
		if (contactQuery.data == null) {
			return false;
		}
		return hasSendableAddress(contactQuery.data);
	}).length;
	const { balanceAfterSend, currentBalance, giftingQuery, totalCost } = useCurrentUserGiftingBalance({
		totalRecipients: numberOfRecipients,
	});
	// @ts-ignore
	const canPlaceOrder = postcardText && mailDate && returnContact && !!numberOfRecipients && balanceAfterSend >= 0;
	const bulkSendMutation = useHwcBulkCreateMutation({
		onError: error => {
			// @ts-ignore
			errorMessages.pushApiError(error);
			logApiError('useHwcBulkCreateMutation', error);
		},
		onSuccess: () => {
			setStep('success');
		},
	});
	const handlePlaceOrderClick = () => {
		const recipients = contactIds
			.map(contactId => {
				if (customCardUserMapping.has(contactId)) {
					const customCard = customCardUserMapping.get(contactId);
					return {
						// @ts-ignore
						...customCard.recipient,
						contactId,
						// @ts-ignore
						content: createContentStateWithHtmlStringValue(customCard.postcardContent).getRawRichTextContent(),
						// @ts-ignore
						signature: customCard.signature,
						// @ts-ignore
						style: customCard.handwritingStyle,
					} as Api.IHandwrittenCardRecipient;
				}
				return {
					contactId,
				} as Api.IHandwrittenCardRecipient;
			})
			// Remove recipients without addresses
			.filter(contact => {
				if (contact.address && hasRequiredHwcRecipientAddressFields(contact.address)) {
					return true;
				}
				const contactQuery = contactsQuery.find(query => query.data?.id === contact.contactId);
				return contactQuery?.data?.address && hasRequiredHwcRecipientAddressFields(contactQuery.data.address);
			});
		bulkSendMutation.mutate({
			card: {
				content: createContentStateWithHtmlStringValue(postcardContent).getRawRichTextContent(),
				recipients,
				returnContact,
				scheduledSendDate: mailDate.toJSON(),
				sendFrom: Api.SendEmailFrom.CurrentUser,
				signature,
				templateId: selectedHwcTemplateId,
			},
			filter: {},
		});
	};
	if (step === 'success') {
		return (
			<>
				<MultiContainerHeader
					fullscreenHeader='Order Confirmation'
					onFullscreenRequestBack={ev => {
						ev.preventDefault();
						// @ts-ignore
						fullscreenModal.dismissModal();
					}}
					// @ts-ignore
					onFullscreenRequestClose={() => fullscreenModal.dismissModal()}
				/>
				<HwcOrderSuccessPage />
			</>
		);
	}
	if (cardCustomizationActiveContactId != null) {
		return (
			<BulkHwcCustomLayout
				mainHeader={
					<>
						{selectedTemplateQuery.data ? (
							<div className={css(baseStyleSheet.truncateText, styleSheet.customCardHeaderTitle)}>
								{selectedTemplateQuery.data.name}
							</div>
						) : (
							<LoadingSpinner type='small' />
						)}
						{customContactQuery?.data ? (
							<div className={css(styleSheet.customCardHeaderUser)}>
								<Avatar2
									styleDeclaration={styleSheet.customCardUserAvatar}
									imgSrc={customContactQuery.data.profilePic}
									fallbackText={getPrincipalInitials(customContactQuery.data)}
								/>
								<div>
									<div className={css(styleSheet.customCardHeaderUserName)}>
										{getDisplayName(customContactQuery.data)}
									</div>
									<div className={css(styleSheet.customCardHeaderUserInfo)}>
										You are currently customizing the card for just this user
									</div>
								</div>
							</div>
						) : (
							<LoadingSpinner type='small' />
						)}
					</>
				}
				sideBar={
					<>
						{customContactVm ? (
							<ContactContextTabView
								contact={customContactVm}
								initialEdit={false}
								styles={[styleSheet.customCardContactContextTabView]}
							/>
						) : (
							<LoadingSpinner type='large' />
						)}
					</>
				}
				footer={
					<button
						className={css(baseStyleSheet.ctaButton)}
						onClick={handleSaveCustomUserCardClick}
						disabled={!customUserCardContent}
					>
						Save and go back to all recipients
					</button>
				}
			>
				<>
					<FabContext appearance={{ hidden: true }} />
					<MultiContainerHeader
						fullscreenHeader="Customize Recipient's Card"
						onFullscreenRequestBack={handleCancelCustomUserCardClick}
						onFullscreenRequestClose={handleCancelCustomUserCardClick}
					/>
					<div className={css(styleSheet.mainPreviewContainer)}>
						<div className={css(styleSheet.previewWrapper)}>
							<div className={css(styleSheet.configDrawerSection)}>
								<h3 className={css(styleSheet.configDrawerMessage)}>Message</h3>
								<span
									className={css(
										styleSheet.configDrawerSubtitle,
										styleSheet.configDrawerCharCount,
										// @ts-ignore
										customPostcardEditor.postcardText.length > HANDWRITTEN_CARD_TEXT_MAX_ALLOWED_CHARACTERS &&
											styleSheet.configDrawerCharCountOver
									)}
								>
									{/* @ts-ignore */}
									{customPostcardEditor.postcardText.length}/{HANDWRITTEN_CARD_TEXT_MAX_ALLOWED_CHARACTERS}
								</span>
								<PostcardTextEditor {...customPostcardEditor.getPostcardTextEditorProps()} />
							</div>
							<div className={css(styleSheet.configDrawerSection)}>
								<h4 className={css(styleSheet.configDrawerSelectSig)}>Signature</h4>
								<TextArea
									inputId='postcard-signature'
									value={customUserSignature}
									onChange={ev => setCustomUserSignature(ev.target.value)}
								/>
							</div>
							<CustomRecipientAddressField
								address={customUserRecipientAddressText}
								id='recipient-address'
								onBtnClick={() => setShowRecipientAddressModal(true)}
								onEditBtnClick={() => setShowRecipientAddressModal(true)}
								required={true}
								styleDeclaration={styleSheet.configDrawerSection}
							/>
							{showRecipientAddressModal ? (
								<RecipientAddressModal
									isOpen={true}
									initialData={{
										addresseeName: addresseeNameFromHwcRecipient(customUserRecipient),
										address1: customUserRecipient.address?.address1 ?? '',
										address2: customUserRecipient.address?.address2 ?? '',
										city: customUserRecipient.address?.city ?? '',
										salutationName: customUserRecipient.firstName ?? '',
										postalCode: customUserRecipient.address?.postalCode ?? '',
										stateProvince: customUserRecipient.address?.stateProvince ?? '',
									}}
									onSave={data => {
										setCustomUserRecipient({
											...data,
											address: {
												name: data.addresseeName,
												address1: data.address1,
												address2: data.address2,
												city: data.city,
												postalCode: data.postalCode,
												stateProvince: data.stateProvince,
											},
											contactId: cardCustomizationActiveContactId,
											firstName: data.salutationName,
										});
										setShowRecipientAddressModal(false);
									}}
									onCancel={() => setShowRecipientAddressModal(false)}
								/>
							) : null}
						</div>
					</div>
				</>
			</BulkHwcCustomLayout>
		);
	}
	return (
		<CampaignComposerBaseLayout
			mainHeader={
				<>
					{selectedTemplateQuery.isLoading ? (
						<LoadingSpinner type='small' />
					) : selectedTemplateQuery.data != null ? (
						<div className={css(baseStyleSheet.truncateText, styleSheet.customCardHeaderTitle)}>
							{selectedTemplateQuery.data.name}
						</div>
					) : null}
				</>
			}
			sideBar={
				step === 'recipients' ? (
					<>
						<MultiContainerHeader
							fullscreenHeader='View Card'
							onFullscreenRequestBack={ev => {
								ev.preventDefault();
								// @ts-ignore
								fullscreenModal.dismissModal();
							}}
							// @ts-ignore
							onFullscreenRequestClose={() => fullscreenModal.dismissModal()}
						/>
						<div className={css(styleSheet.recipientsHeader)}>
							<div className={css(styleSheet.recipientsHeaderTitle)}>
								<div>Recipients</div>
							</div>
							<div className={css(styleSheet.recipientsCountHeader)}>
								<div className={css(styleSheet.recipientsCountContainer)}>
									{numberOfRecipients != null
										? `${numberOfRecipients} out of ${contactIds.length} contact${
												contactIds.length !== 1 ? 's' : ''
											} with addresses`
										: null}
								</div>
							</div>
						</div>
						<AutoCompleteSearchField
							anchorClassName='email-recipients-list-autocomplete-search-field-anchor'
							className='email-recipients-list-autocomplete-search-field'
							clearSearchFieldAfterSelectingItem={true}
							dropdownContentClassName={css(styleSheet.autocompleteDropdownContent)}
							inputId='email-recipients-list-search-input'
							inputProps={{
								placeholder: 'Add additional contacts',
							}}
							leftAccessory={<SearchIcon className='email-recipients-list-autocomplete-search-field-icon' />}
							onItemSelected={onAddContact}
							resultsLimit={6}
							type={Api.ResourceAutoCompleteViewModelType.Contact}
						/>
						<div className={css(styleSheet.recipientsList)}>
							<div>
								{contactsQuery.map((contactQuery, idx) => {
									if (contactQuery.data == null) {
										return <LoadingSpinner key={idx} type='small' />;
									}
									const contact = contactQuery.data;
									// @ts-ignore
									const contactCustomCard = customCardUserMapping.get(contact.id);
									let companyLine: string;
									if (contact.companyName != null) {
										companyLine =
											contact.jobTitle != null ? `${contact.jobTitle} at ${contact.companyName}` : contact.companyName;
									}
									const hasValidAddress = hasSendableAddress(contact);
									return (
										<div
											className={css(styleSheet.recipientsListItem)}
											key={contact.id}
											// @ts-ignore
											onClick={() => handleRecipientClick(contact.id)}
										>
											<DeprecatedCloseButton
												onClick={ev => {
													ev.stopPropagation();
													// @ts-ignore
													onRemoveContact(contact.id);
												}}
											/>
											<Avatar2
												styleDeclaration={styleSheet.recipientsListItemAvatar}
												imgSrc={contact.profilePic}
												fallbackText={getPrincipalInitials(contact)}
											/>
											<div className={css(styleSheet.recipientsListItemDetails)}>
												<div className={css(styleSheet.recipientsListItemDetailsName, baseStyleSheet.truncateText)}>
													{getDisplayNameForBulkEmail(contact)}
												</div>
												<div
													className={css(styleSheet.recipientsListItemDetailsCompanyName, baseStyleSheet.truncateText)}
												>
													{/* @ts-ignore */}
													{companyLine}
												</div>
												<div
													className={css(
														baseStyleSheet.truncateText,
														styleSheet.recipientsListItemDetailsInfo,
														!!contactCustomCard && styleSheet.recipientsListItemDetailsInfoCustomized
													)}
												>
													{contactCustomCard ? 'See customized card' : 'Customize card'}
												</div>
											</div>
											{!hasValidAddress ? (
												<AddressMissingPill styleDeclaration={styleSheet.addressMissingPillPlacement} />
											) : null}
										</div>
									);
								})}
							</div>
						</div>
						<div className={css(styleSheet.recipientsFooter)}>
							<button
								className={css(baseStyleSheet.ctaButton, styleSheet.recipientsFooterCta)}
								onClick={() => setStep('reviewOrder')}
								disabled={!numberOfRecipients}
							>
								<span>Next: Review Order</span>
							</button>
						</div>
					</>
				) : (
					<>
						<MultiContainerHeader
							fullscreenHeader='View Card'
							onFullscreenRequestBack={ev => {
								ev.preventDefault();
								setStep('recipients');
							}}
							// @ts-ignore
							onFullscreenRequestClose={() => fullscreenModal.dismissModal()}
						/>
						<h2 className={css(styleSheet.configDrawerHeader)}>Review Order</h2>
						<div className={css(styleSheet.divider, styleSheet.dividerMargin)} />
						<div className={css(styleSheet.reviewOrderForm)}>
							<div className={css(baseStyleSheet.mb4)}>
								<strong className={css(styleSheet.configDrawerThirdHeader)}>Mail Date</strong>
								<span className={css(styleSheet.configDrawerSubtitle, baseStyleSheet.mb4)}>
									Please allow up to 10 days for delivery
								</span>
								<Popover
									anchor={
										<TextInput
											inputId='postcard-mail-date'
											type='date'
											onChange={ev => {
												const parsed = moment(ev.target.value);
												if (parsed.isValid()) {
													setMailDate(parsed.toDate());
												}
											}}
											onClick={() => setDatePickerOpen(true)}
											value={mailDateText}
										/>
									}
									dismissOnClickOutside={true}
									isOpen={datePickerOpen}
									onRequestClose={() => setDatePickerOpen(false)}
									type={PopoverType.white}
									preferredPlacement='below'
								>
									<DayPicker month={mailDate} onDayClick={setMailDate} selectedDays={mailDate} />
								</Popover>
							</div>
							<AddressFormField
								address={returnAddressText}
								btnLabel='Add Return Address'
								id='return-address'
								label='Return Address'
								onBtnClick={() => setIsShowingReturnContactModal(true)}
								onEditBtnClick={() => setIsShowingReturnContactModal(true)}
								required={true}
							/>
							<div className={css(styleSheet.reviewOrderRecipientsCount)}>
								{numberOfRecipients != null
									? `${numberOfRecipients} recipient${numberOfRecipients !== 1 ? 's' : ''}`
									: null}
								<button className={css(baseStyleSheet.brandLink)} onClick={() => setStep('recipients')}>
									Edit
								</button>
							</div>
							<div className={css(styleSheet.divider, styleSheet.dividerMargin)} />
							{giftingQuery.status === 'loading' ? <LoadingSpinner /> : null}
							{giftingQuery.status === 'error' ? (
								<p role='error' className={css(styleSheet.errorContactLoad)}>
									Failed to load balance data
								</p>
							) : null}
							{giftingQuery.status === 'success' ? (
								<>
									<p className={css(styleSheet.balanceContainer)}>
										<strong className={css(styleSheet.balanceText)}>Current Balance:</strong> {/* @ts-ignore */}
										{new Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' }).format(currentBalance)}
									</p>
									{/* @ts-ignore */}
									{/* @ts-ignore */}
									{balanceAfterSend >= 0 || !Api.isAdmin(userSession.user) ? (
										<p className={css(styleSheet.balanceContainer)}>
											<strong className={css(styleSheet.balanceText)}>Balance After Send: </strong> {/* @ts-ignore */}
											{new Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' }).format(balanceAfterSend)}
										</p>
									) : (
										<button
											className={css(baseStyleSheet.ctaButtonSecondary)}
											onClick={() => setShowPurchaseCreditsModal(true)}
										>
											+ Add credits to continue
										</button>
									)}
								</>
							) : null}
							<div className={css(styleSheet.divider, styleSheet.dividerMargin)} />
							{totalCost != null ? (
								<p className={css(styleSheet.balanceContainer)}>
									<strong className={css(styleSheet.total)}>Total:</strong>
									{new Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' }).format(totalCost)}
								</p>
							) : null}
						</div>
						<div className={css(styleSheet.recipientsFooter)}>
							<button
								className={css(baseStyleSheet.ctaButton, styleSheet.recipientsFooterCta)}
								disabled={bulkSendMutation.isLoading || !canPlaceOrder}
								onClick={handlePlaceOrderClick}
							>
								<span>Place Order</span>
							</button>
						</div>
						{isShowingReturnContactModal ? (
							<ReturnContactModal
								isOpen={isShowingReturnContactModal}
								initialRecipient={returnContact}
								onSave={data => {
									setReturnContact({
										...data,
										address: {
											address1: data.address1,
											address2: data.address2,
											city: data.city,
											country: data.country,
											postalCode: data.postalCode,
											stateProvince: data.stateProvince,
										},
										firstName: data.firstName,
										lastName: data.lastName,
									});
									setIsShowingReturnContactModal(false);
								}}
								onCancel={() => setIsShowingReturnContactModal(false)}
							/>
						) : null}
					</>
				)
			}
		>
			<>
				<FabContext appearance={{ hidden: true }} />
				<div className={css(styleSheet.hwcActionsToolbar)}>
					{!isEditingPostcard ? (
						<button
							className={css(styleSheet.outlineButton, baseStyleSheet.outlineButtonContainer)}
							disabled={!postcardText && selectedTemplateQuery.isLoading}
							onClick={onEditClicked}
						>
							Edit
						</button>
					) : (
						<button
							className={css(styleSheet.outlineButton, baseStyleSheet.outlineButtonContainer)}
							disabled={!postcardText && selectedTemplateQuery.isLoading}
							onClick={onPreviewClicked}
						>
							Preview
						</button>
					)}
					{isSaveAllowed ? (
						<TinyPopover
							anchor={
								<div className={css(styleSheet.outlineButton, baseStyleSheet.outlineButtonContainer)}>
									<button
										className={css(baseStyleSheet.outlineButton)}
										onClick={handleSaveButtonClicked}
										disabled={updatingTemplateStatus === 'loading' || !postcardContent}
									>
										<span>Save</span>
									</button>
									<button className={css(styleSheet.saveAsDropdownCaret)} onClick={toggleSaveAsPopover}>
										<DropDownIcon />
									</button>
								</div>
							}
							isOpen={isSaveAsPopoverShowing}
							dismissOnOutsideAction={true}
							align='center'
							placement={['bottom']}
							onRequestClose={toggleSaveAsPopover}
						>
							<button
								className={css(styleSheet.outlineButton, baseStyleSheet.outlineButtonContainer)}
								disabled={false}
								onClick={handleSaveAsButtonClicked}
							>
								Save as
							</button>
						</TinyPopover>
					) : (
						<button
							className={css(styleSheet.outlineButton, baseStyleSheet.outlineButtonContainer)}
							disabled={false}
							onClick={handleSaveAsButtonClicked}
						>
							Save as
						</button>
					)}
				</div>
				<div className={css(styleSheet.mainPreviewContainer)}>
					<div className={css(styleSheet.previewWrapper)}>
						{!isEditingPostcard ? (
							<>
								<p className={css(styleSheet.cardPreviewText)}>Card Preview</p>
								{toggledHwcPreview === HwcTogglePreview.image ? (
									<div className={css(styleSheet.mainPreview, styleSheet.mainPreviewModeImage)}>
										{selectedTemplateQuery.data ? (
											<img
												src={selectedTemplateQuery.data.image.publicUrl}
												alt='Template image preview'
												className={css(styleSheet.mainPreviewImage)}
											/>
										) : (
											<LoadingSpinner />
										)}
									</div>
								) : (
									<HwcTextPreview
										handwritingStyle={handwritingStyle}
										// @ts-ignore
										postcardText={postcardText}
										signature={signature}
									/>
								)}
								<HwcPreviewToggle value={toggledHwcPreview} onValueChange={value => setToggledHwcPreview(value)} />
							</>
						) : null}
						{isEditingPostcard ? (
							<>
								<div className={css(styleSheet.configDrawerSection)}>
									<h3 className={css(styleSheet.configDrawerMessage)}>Message</h3>
									<span
										className={css(
											styleSheet.configDrawerSubtitle,
											styleSheet.configDrawerCharCount,
											// @ts-ignore
											postcardText.length > HANDWRITTEN_CARD_TEXT_MAX_ALLOWED_CHARACTERS &&
												styleSheet.configDrawerCharCountOver
										)}
									>
										{/* @ts-ignore */}
										{postcardText.length}/{HANDWRITTEN_CARD_TEXT_MAX_ALLOWED_CHARACTERS}
									</span>
									<PostcardTextEditor {...getPostcardTextEditorProps()} />
								</div>
								<div className={css(styleSheet.configDrawerSection)}>
									<h4 className={css(styleSheet.configDrawerSelectSig)}>Signature</h4>
									<TextArea
										inputId='postcard-signature'
										value={signature}
										onChange={ev => setSignature(ev.target.value)}
									/>
								</div>
							</>
						) : null}
					</div>
				</div>
				{/* <div className={css(styleSheet.emailFallbackInfo)}>
					<Checkbox
						checked={emailFallbackActive}
						id={`bulk-hwc-email-fallback`}
						childrenPosition='left'
						checkmarkFillColor='white'
						checkedBackgroundFillColor={brandPrimary}
						onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
							setEmailFallbackActive(ev.target.checked);
						}}
					/>
					<span>If the recipient lacks an address, send a backup email (of the same message on the card) instead.</span>
				</div> */}
				{isShowingSaveAsModal ? (
					<Modal
						useDefaultHeader
						className={css(styleSheet.templateSaveModal)}
						isOpen={true}
						shouldCloseOnOverlayClick={false}
						onRequestClose={handleSaveAsModalClose}
					>
						<SaveTemplateModal
							initialTemplateScope={
								selectedTemplateQuery.data?.scope === Api.TemplateScope.Account
									? Api.TemplateScope.Account
									: Api.TemplateScope.User
							}
							actionButtonsDisabled={createTemplateMutation.isLoading}
							onSaveButtonClicked={({ templateName, scope }) => {
								handleSaveAsModalSave({ name: templateName, scope });
							}}
							onCancelButtonClicked={handleSaveAsModalClose}
						/>
					</Modal>
				) : null}
				<PurchaseCreditsModal
					isOpen={showPurchaseCreditsModal}
					onRequestClose={() => setShowPurchaseCreditsModal(false)}
				/>
			</>
		</CampaignComposerBaseLayout>
	);
}
