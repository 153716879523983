import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { useHistory } from 'react-router';
import { useEventLogging } from '../../../../models/Logging';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useUpdateActionStatusMutation } from '../../../../queries';
import mobileMarketingImage from '../../../assets/undraw_mobile_marketing.svg';
import { baseStyleSheet, bs } from '../../../styles/styles';
import { styleSheet } from './styles';

export function TextingCampaignRegistrationPrompt({
	pendingActionId,
	onComplete,
}: {
	pendingActionId?: string;
	onComplete: () => void;
}) {
	const history = useHistory();
	const userSession = useUserSession();
	const { logApiError } = useEventLogging('TextingCampaignRegistrationPrompt');
	const updateActionStatus = useUpdateActionStatusMutation({
		onError: error => {
			logApiError('UpdatePendingAccountActions-Error', error);
		},
		onSuccess: (_, params) => {
			userSession.removePendingAccountAction(params.accountActionId);
		},
	});
	const suppressPendingActionStatus = () => {
		if (pendingActionId) {
			updateActionStatus.mutate({
				accountActionId: pendingActionId,
				status: Api.AccountActionStatus.Completed,
				impersonationContext: { account: userSession.account, user: userSession.user ?? undefined },
			});
		}
	};
	const handleCtaClicked = () => {
		history.push('texting/registration');
		suppressPendingActionStatus();
		onComplete();
	};
	const handleSkip = () => {
		suppressPendingActionStatus();
		onComplete();
	};

	return (
		<div className={css(bs.flex, bs.flexCol, bs.itemsCenter)}>
			<h2 className={css(styleSheet.header)}>Texting Campaigns</h2>
			<figure className={css(styleSheet.imageWrapper)}>
				<img src={mobileMarketingImage} alt='Texting Campaigns' className={css(styleSheet.image)} />
			</figure>
			<p className={css(styleSheet.description, bs.mb6)}>
				Do you need to send to more than 100 contacts in a single send? To comply with carrier regulations, campaign
				registration is required.{' '}
			</p>
			<button className={css(baseStyleSheet.ctaButton, bs.mb2)} onClick={handleCtaClicked}>
				Get started with texting campaigns
			</button>
			<button className={css(bs.textBrandPrimaryHover, bs.textSm)} onClick={handleSkip}>
				Skip Registration
			</button>
		</div>
	);
}
