import { StyleSheet } from 'aphrodite';
import { brandSecondary, titles } from '../../../../../../styles/colors';

export const styleSheet = StyleSheet.create({
	container: {
		display: 'flex',
		justifyContent: 'center',
		width: 870,
	},
	uploadField: {
		background: '#F9F9F9',
		border: `1px dashed ${brandSecondary}`,
		borderRadius: 4,
		height: 300,
		position: 'relative',
		width: '100%',
	},
	uploadSpreadsheetIcon: {
		boxShadow: '0 1px 2px 0 rgba(0,0,0,0.20)',
	},
	uploadTarget: {
		alignItems: 'center',
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		width: '100%',
	},
	uploadTargetOverlay: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		gap: 10,
	},
	uploadTargetOverlayArrow: {
		display: 'block',
	},
	uploadTargetOverlayButton: {
		fontSize: 16,
	},
	uploadTargetOverlayChosenFile: {
		color: titles,
		fontSize: 18,
	},
	uploadTargetOverlayMessage: {
		color: '#858585',
		fontSize: 16,
	},
});
