import KitIndicatorIconUrl from '../../../assets/icon_keepintouch.svg';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

export const KeepInTouchAvatarWrapper: React.FC = ({ children }) => {
	return (
		<div className={css(styleSheet.container)}>
			{children}
			<img className={`avatar-kit-imge ${css(styleSheet.avatarKit)}`} src={KitIndicatorIconUrl} />
		</div>
	);
};
