import { brandPrimaryHover } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	actionContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	automationContainer: {
		width: '35%',
	},
	automationName: {
		color: brandPrimaryHover,
		fontSize: 18,
		marginBottom: 0,
		marginTop: 45,
	},
	checkbox: {
		fontSize: 14,
		marginLeft: 11,
	},
	contactList: {
		border: '1px solid #C5DCEC',
		margin: '20px 0',
		maxHeight: 330,
		overflowY: 'auto',
		position: 'relative',
	},
	contactListContainer: {
		width: '55%',
	},
	contactListItem: {
		background: '#FFF',
	},
	container: {
		':last-child': {
			borderBottom: 'none',
		},
		borderBottom: '1px solid #D3DBDE',
		display: 'flex',
		justifyContent: 'space-between',
		padding: 20,
	},
	disableScroll: {
		overflowY: 'hidden',
	},
	dropdownMenuButton: {
		':focus': {
			background: 'rgba(204, 204, 204, 1)',
		},
		':hover': {
			background: 'rgba(204, 204, 204, 1)',
		},
		alignItems: 'center',
		background: 'rgba(204, 204, 204, 0.3)',
		border: '1px solid #ccc',
		borderRadius: 3,
		color: '#0D0F11',
		display: 'flex',
		fontSize: 12,
		justifyContent: 'space-between',
		minWidth: 160,
		padding: '7px 10px',
	},
	employeeSearch: {
		boxSizing: 'border-box',
		overFlow: 'hidden',
	},
	menu: {
		minWidth: 200,
	},
	menuInnerContainer: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		minWidth: 200,
		overFlow: 'hidden',
	},
	menuInnerContainerExpanded: {
		// minHeight: 200,
	},
	nameWrap: {
		display: 'block',
		width: 126,
	},
	opacity: {
		cursor: 'initial',
		opacity: 0.4,
	},
	resetButton: {
		':focus': {
			background: 'rgba(204, 204, 204, 0.3)',
		},
		':hover': {
			background: 'rgba(204, 204, 204, 0.3)',
		},
		marginBottom: 5,
		padding: 10,
	},
	sendFromFieldWrap: {
		alignItems: 'center',
		display: 'flex',
		marginTop: 10,
		width: '100%',
	},
	sendFromLabel: {
		color: '#A7ABAD',
		fontSize: 12,
		marginRight: 10,
		minWidth: 62,
	},
	stepsLabel: {
		marginBottom: 7,
		marginTop: 10,
	},
	stepsOverflowLabel: {
		color: '#AAAAAA',
		fontSize: 11,
	},
	successCover: {
		alignItems: 'center',
		background: `rgba(255,255,255, 0.6)`,
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		left: 0,
		position: 'absolute',
		top: 0,
		width: '100%',
	},
	triggerCaret: {
		transform: 'rotate(90deg)',
	},
});
