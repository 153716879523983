import { StyleSheet } from 'aphrodite';
import { brandPrimary, brandPrimaryActive, brandPrimaryHover } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	button: {
		marginTop: 24,
		padding: '10px 60px',
		width: '80%',
	},
	center: {
		display: 'flex',
		justifyContent: 'center',
	},
	container: {},
	signInContainer: {
		minHeight: 0,
	},
	link: {
		':active': {
			color: brandPrimaryActive,
		},
		':hover': {
			color: brandPrimary,
			cursor: 'pointer',
		},
		color: brandPrimaryHover,
		textDecoration: 'none',
	},
});
