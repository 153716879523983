import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import produce from 'immer';
import * as React from 'react';
import { AdvancedFiltersFlyout } from '../../../../components/AdvancedFiltersFlyout/';
import { baseStyleSheet } from '../../../../styles/styles';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	filter: Api.IBulkContactsRequest;
	onChangeFilter: (filter: Api.IBulkContactsRequest) => void;
}

export const AdvancedSearch = ({ className, styles, filter, onChangeFilter }: IProps) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const onRequestClose = (newFilterCriteria: Api.IContactFilterCriteria[]) => {
		setIsOpen(false);
		const nextFilter = produce(filter, draftFilter => {
			draftFilter.filter.criteria = newFilterCriteria;
		});
		onChangeFilter(nextFilter);
	};
	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
			<button className={css(baseStyleSheet.brandLink)} onClick={() => setIsOpen(true)}>
				Advanced Search
			</button>

			<AdvancedFiltersFlyout
				initialFilterCriteria={filter.filter.criteria}
				onSave={onRequestClose}
				isOpen={isOpen}
				onDismissFlyout={() => setIsOpen(false)}
				showOwnershipFilters
				showOwnedByOption
				showStatusFilters
				showKeyFactFilters
			/>
		</div>
	);
};
