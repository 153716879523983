import { css } from 'aphrodite';
import { animated } from 'react-spring-legacy';
import { ContactViewModel } from '../../../../extViewmodels';
import { CloseButton } from '../../../components/CloseButton';
import { bs } from '../../../styles/styles';
import { ContactCardName } from './ContactCardName';
import { RecentEmailSubjects } from './RecentEmailSubjects';
import { styleSheet } from './styles';
import { SuggestedTags } from './SuggestedTags';

type Props = {
	contact: ContactViewModel;
	possibleTags: string[];
	recentEmailSubjects: string[] | undefined;
	onXClick: () => void;
	animation: any;
};

export const ContactCard = ({ contact, recentEmailSubjects, possibleTags, onXClick, animation }: Props) => {
	return (
		<animated.div
			style={animation}
			data-testid='contact-card-outer-container'
			className={css(styleSheet.contactCardOuter, bs.flex, bs.itemsCenter, bs.justifyStart, bs.flexShrink0)}
		>
			<CloseButton onClick={onXClick} />
			<ContactCardName contact={contact} />
			<div
				data-testid='subject-and-tag-container'
				className={css(bs.bgWhite, bs.flex1, bs.hFull, bs.minW0, bs.flex, bs.flexCol, styleSheet.tagsAndSubjects)}
			>
				<RecentEmailSubjects recentEmailSubjects={recentEmailSubjects} />
				<SuggestedTags contactVm={contact} suggestedTags={possibleTags} />
			</div>
		</animated.div>
	);
};
