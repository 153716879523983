import { titles } from '../../../styles/colors';
import { CssSelectors, truncateTextStyle } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	avatars: {},
	body: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: 12,
		...CssSelectors.allChildren(
			{
				marginLeft: 30,
			},
			':not(:first-child)'
		),
	},
	bodyText: {
		color: '#8E8E8E',
		fontSize: 14,
		...truncateTextStyle,
	},
	container: {},
	header: {
		alignItems: 'center',
		color: titles,
		display: 'flex',
		fontSize: 18,
	},
	recordNoteButton: {
		flexShrink: 0,
	},
	summaryWithAttendees: {
		marginLeft: 12,
	},
	title: {
		flexGrow: 1,
	},
});
