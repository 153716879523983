import { StyleSheet } from 'aphrodite';
import { AppBarHeight } from '../AppBar/styles';

const coffeeNavBarWidth = 250;
const coffeeNavBarWidthCompact = 72;
const coffeeContentPadding = 40;
const coffeeContentPaddingCompact = 20;

export const styleSheet = StyleSheet.create({
	coffeeAppBar: {
		padding: `0 ${coffeeContentPadding}px`,
	},
	coffeeAppBarExpanded: {
		padding: `0 ${coffeeContentPaddingCompact}px`,
	},
	coffeeContainer: {
		background: '#fff',
		display: 'flex',
		height: '100%',
		minWidth: 768,
	},
	coffeeContentContainer: {
		height: `calc(100% - ${AppBarHeight}px)`,
		overflowY: 'auto',
	},
	coffeeLevLogo: {
		height: 'auto',
		marginTop: 8,
		width: 'auto',
	},
	coffeeLogo: {
		height: 66,
		marginBottom: 10,
		width: 'auto',
	},
	coffeeLogoContainer: {
		alignItems: 'center',
		// boxSizing: 'border-box',
		color: '#aaa',
		display: 'flex',
		flexDirection: 'column',
		fontSize: 11,
		letterSpacing: '1.1px',
		marginBottom: 20,
		paddingTop: 20,
		textTransform: 'uppercase',
	},
	coffeeNavbar: {
		height: '100%',
		width: coffeeNavBarWidth,
	},
	coffeeNavBarCompact: {
		width: coffeeNavBarWidthCompact,
	},
	coffeeRightContainer: {
		boxSizing: 'border-box',
		height: '100%',
		width: `calc(100% - ${coffeeNavBarWidth}px)`,
	},
	coffeeRightContainerExpanded: {
		width: `calc(100% - ${coffeeNavBarWidthCompact}px)`,
	},
	mainLogo: {
		maxHeight: 39.62,
		maxWidth: 200,
		width: '100%',
	},
	pageShell: {
		'@media (max-width: 1200px)': {
			padding: '0 1.25rem',
		},
		padding: '0 2.5rem',
	},
	pageShellCompactOnly: {
		padding: '0 1.25rem',
	},
	rightContainer: {
		flex: 1,
		position: 'relative',
	},
	rightContainerCompact: {
		minWidth: 'calc(100% - 90px)',
		width: 'calc(100% - 90px)',
	},
	rightContainerTopGradient: {
		background:
			'linear-gradient(transparent 275px, #F9F9F9 275px), linear-gradient(176.85deg, #FFFFFF 2.61%, #9DD7ED 72.26%, #51BAE5 162.54%, #3FB2E3 238.56%)',
	},
});
