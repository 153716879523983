import * as React from 'react';

export function useFocusFirstErrorField({
	formRef,
	formErrors,
}: {
	formRef: React.RefObject<HTMLFormElement>;
	formErrors: any;
}) {
	React.useEffect(() => {
		const formEl = formRef.current;
		if (!formEl) {
			return;
		}
		if (!formErrors) {
			return;
		}

		if (formEl.matches('[aria-invalid="true"]')) {
			formEl.focus();
		} else {
			const firstInvalid = formEl.querySelector('[aria-invalid="true"]');
			if (firstInvalid instanceof HTMLElement) {
				firstInvalid.focus();
			}
		}
	}, [formErrors, formRef]);
}
