import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	avatarKit: {
		backgroundColor: 'white',
		borderRadius: '50%',
		bottom: 0,
		height: 17,
		position: 'absolute',
		right: 0,
		width: 18,
	},
	container: {
		display: 'inline-flex',
		fontSize: 16,
		fontWeight: 600,
		position: 'relative',
	},
});
