import { StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	root: {
		padding: '1.25rem 0 7.5rem 0',
		...CssSelectors.allChildren(
			{
				marginTop: '1.25rem',
			},
			':not(:first-child)'
		),
	},
});
