import * as React from 'react';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { DetailsFlyout } from '../../../../components/DetailsFlyout';
import { Badge } from '../../../../components/dataBoards/Badge';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { styleSheet } from './styles';
import { styleSheet as flyoutStyleSheet } from '../../../../components/DetailsFlyout/styles';
import { styleSheet as dbStyleSheet } from '../../styles';

import { numberToCurrencyStringValue } from '../../../../../models/UiUtils';
import { DonationIcon } from '../../../../components/svgs/icons/DonationIcon';
import { useDonationFilterQuery, useDonorFilterQuery } from '../../../../../queries';
import moment from 'moment';
import { dataGridStyles } from '../../../../components/DataGrid/data-grid-styles';

export const DonorDetailsFlyout = ({
	contactId,
	isOpen,
	setIsOpen,
}: {
	contactId: string;
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const donorFilterQuery = useDonorFilterQuery({
		enabled: isOpen && !!contactId,
		filterRequest: {
			criteria: [
				{
					property: Api.DonorFilterProperty.ContactId,
					value: contactId,
				},
			],
		},
	});

	const donationFilterQuery = useDonationFilterQuery({
		enabled: isOpen && !!contactId,
		filterRequest: {
			criteria: [
				{
					property: Api.DonationFilterProperty.ContactId,
					value: contactId,
				},
			],
		},
	});

	const historyData = React.useMemo(
		() => donationFilterQuery.data?.pages.map(page => page.values).flat() ?? [],
		[donationFilterQuery.data?.pages]
	);

	const donor = React.useMemo(() => {
		return donorFilterQuery.data?.pages.map(page => page.values).flat()[0] ?? null;
	}, [donorFilterQuery.data?.pages]);

	return (
		<DetailsFlyout isOpen={isOpen} setIsOpen={setIsOpen} title='Donor Details'>
			{donorFilterQuery.isLoading ? <LoadingSpinner type='large' /> : null}
			{!donorFilterQuery.isLoading ? (
				<div className={css(styleSheet.container)}>
					<section className={css(styleSheet.section)}>
						<h4 className={css(flyoutStyleSheet.subTitle)}>{donor.donorName}</h4>
						<small>{donor.contact?.primaryEmail?.value}</small>
					</section>

					<div className={css(styleSheet.section)}>
						<label className={css(styleSheet.label)}>Most Recent Donation</label>

						<table className={css(dataGridStyles.table, styleSheet.tableOverrides)}>
							<tbody>
								<tr>
									<td className={css(dataGridStyles.tableDataCellBorderless)}>
										<span className={css(dbStyleSheet.amountText)}>
											${numberToCurrencyStringValue(donor.mostRecentDonation.amount)}
										</span>
									</td>
									<td className={css(dataGridStyles.tableDataCellBorderless)}>
										{donor.mostRecentDonation.campaign ? (
											<Badge
												text={donor.mostRecentDonation.campaign.name}
												foregroundColor={donor.mostRecentDonation.campaign.foregroundColor}
											/>
										) : null}
									</td>
									<td className={css(dataGridStyles.tableDataCellBorderless)}>
										<span>{moment(donor.mostRecentDonation.date).format('MM/DD/YYYY')}</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div className={css(flyoutStyleSheet.separator)} />

					<section className={css(styleSheet.lifetimeSection)}>
						<DonationIcon />
						<div className={css(styleSheet.stackedText)}>
							<h6 className={css(styleSheet.sectionHeader)}>Life Time Donations</h6>
							<span className={css(dbStyleSheet.amountText)}>
								${numberToCurrencyStringValue(donor.lifetimeDonations)}
							</span>
						</div>
					</section>

					{donor.contact ? (
						<section className={css(styleSheet.section)}>
							<h6 className={css(styleSheet.sectionHeader)}>Donation History ({historyData.length})</h6>

							{donationFilterQuery.isLoading ? <LoadingSpinner type='large' /> : null}

							{!donationFilterQuery.isLoading ? (
								<table className={css(dataGridStyles.table, styleSheet.tableOverrides)}>
									<tbody>
										{historyData.map(datum => {
											const borderlessRow = datum === historyData[0];
											const rowStyles = borderlessRow
												? dataGridStyles.tableDataCellBorderless
												: dataGridStyles.tableDataCell;
											return (
												<tr key={`donation-${datum.id}`}>
													<td className={css(rowStyles)}>
														<span className={css(dbStyleSheet.amountText)}>
															${numberToCurrencyStringValue(datum.amount)}
														</span>
													</td>
													<td className={css(rowStyles)}>
														{datum.campaign ? (
															<Badge text={datum.campaign.name} foregroundColor={datum.campaign.foregroundColor} />
														) : null}
													</td>
													<td className={css(rowStyles)}>
														<span>{moment(datum.date).format('MM/DD/YYYY')}</span>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							) : null}
						</section>
					) : null}
				</div>
			) : null}
		</DetailsFlyout>
	);
};
