import * as Api from '@ViewModels';
import * as React from 'react';
import { ContactSortKey } from '../../../../models';
import { getDisplayName } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useContactFilterQuery, useContactsQuery, useHwcEstimateCostQuery } from '../../../../queries';
import { ISelectBoxOption } from '../../../components/SelectBox';

export const useContactFilterRecipients = ({
	initialContactIdsToOmit = [],
	initialTagFilterCriterias = [],
	onRemoveRecipient: onRemoveRecipientCallback,
	onSendFromCurrentUserSelected,
	onSendFromContactOwnerSelected,
	onSendFromSelectedEmployee,
	onShowingFiltersChanged,
	onTagListSearchChange,
}: {
	initialContactIdsToOmit?: string[];
	initialTagFilterCriterias?: Api.IContactFilterCriteria[];
	onSendFromCurrentUserSelected?: () => void;
	onSendFromContactOwnerSelected?: () => void;
	onRemoveRecipient?: (contactId: string) => void;
	onSendFromSelectedEmployee?: () => void;
	onShowingFiltersChanged?: () => void;
	onTagListSearchChange?: () => void;
} = {}) => {
	const userSession = useUserSession();
	const [filterCriterias, setFilterCriterias] = React.useState<Api.IContactFilterCriteria[]>([
		{
			property: Api.ContactFilterCriteriaProperty.All,
		},
	]);
	const [tagSearchCriterias, setTagSearchCriterias] =
		React.useState<Api.IContactFilterCriteria[]>(initialTagFilterCriterias);
	const allCriterias = React.useMemo(() => {
		return [...filterCriterias, ...tagSearchCriterias];
	}, [tagSearchCriterias, filterCriterias]);
	const [contactIdsToOmit, setContactIdsToOmit] = React.useState<Set<string>>(new Set(initialContactIdsToOmit));
	const contactIdsToOmitArray = React.useMemo(() => Array.from(contactIdsToOmit), [contactIdsToOmit]);
	const [contactIdsToAdd, setContactIdsToAdd] = React.useState<string[]>([]);
	const contactsToAdd = useContactsQuery({
		contactIds: contactIdsToAdd,
	});
	const [householdGroupingOn, setHouseholdGroupingOn] = React.useState(
		userSession?.account?.features?.households?.sendToHouseholdByDefault ?? false
	);
	const hwcEstimateCostQuery = useHwcEstimateCostQuery({
		excludeContactIds: contactIdsToOmitArray,
		filter: {
			criteria: allCriterias,
		},
		groupByHousehold: householdGroupingOn,
		includeContactIds: contactIdsToAdd,
	});
	const contactFilterQuery = useContactFilterQuery({
		expand: householdGroupingOn ? 'HouseholdMembers' : undefined,
		filterRequest: {
			filter: {
				criteria: allCriterias,
			},
			groupByHousehold: householdGroupingOn,
		},
		sortBy: ContactSortKey.Handle,
	});
	const [isSendFromDropdownOpen, setIsSendFromDropdownOpen] = React.useState(false);
	const [sendEmailFrom, setSendEmailFrom] = React.useState<Api.ISendEmailFrom>(
		userSession.canSendOnBehalf ? Api.SendEmailFrom.ContactOwner : Api.SendEmailFrom.CurrentUser
	);

	const [sendEmailFromUser, setSendEmailFromUser] = React.useState<Api.IUser>(null);
	const [selectAnEmployeeModalIsOpen, setSelectAnEmployeeModalIsOpen] = React.useState(false);
	const [showingAdvancedFiltersFlyout, setShowingAdvancedFiltersFlyout] = React.useState(false);
	const sendFromDropdownAnchorText = React.useMemo(() => {
		switch (sendEmailFrom) {
			case Api.SendEmailFrom.ContactOwner:
				return 'Contact owner of the recipient';
			case Api.SendEmailFrom.CurrentUser:
				return getDisplayName(userSession.user);
			case Api.SendEmailFrom.SelectedUser:
				return sendEmailFromUser ? getDisplayName(sendEmailFromUser) : 'Select User';
			default:
				return '';
		}
	}, [sendEmailFrom, sendEmailFromUser, userSession]);
	const showingFilterOptions = React.useMemo(() => {
		const contactFilterOptions: ISelectBoxOption<Api.IContactFilterCriteria>[] = [
			{
				title: 'All contacts',
				value: {
					property: Api.ContactFilterCriteriaProperty.All,
				},
			},
			{
				title: 'Contacts I own',
				value: {
					property: Api.ContactFilterCriteriaProperty.OwnedBy,
					value: userSession?.user?.id,
				},
			},
			{
				title: 'All connections',
				value: {
					property: Api.ContactFilterCriteriaProperty.Connections,
				},
			},
		];
		if (sendEmailFrom === Api.SendEmailFrom.SelectedUser && sendEmailFromUser) {
			contactFilterOptions.push({
				title: 'Contacts they own',
				value: {
					property: Api.ContactFilterCriteriaProperty.OwnedBy,
					value: sendEmailFromUser.id,
				},
			});
		}
		return contactFilterOptions;
	}, [userSession, sendEmailFrom, sendEmailFromUser]);
	const selectedShowingFilterOption = React.useMemo(() => {
		const ownedByCriteria = filterCriterias.find(x => x.property === Api.ContactFilterCriteriaProperty.OwnedBy);
		if (ownedByCriteria) {
			return showingFilterOptions.find(
				x => x.value?.property === Api.ContactFilterCriteriaProperty.OwnedBy && x.value?.value === ownedByCriteria.value
			);
		}
		const allconnectionsCriteria = filterCriterias.find(
			x => x.property === Api.ContactFilterCriteriaProperty.Connections
		);
		if (allconnectionsCriteria) {
			return showingFilterOptions.find(x => x.value?.property === Api.ContactFilterCriteriaProperty.Connections);
		}
		return showingFilterOptions.find(x => x.value?.property === Api.ContactFilterCriteriaProperty.All);
	}, [filterCriterias, showingFilterOptions]);
	const onSendBehalfOfCurrentUser = async () => {
		setSendEmailFrom(Api.SendEmailFrom.CurrentUser);

		setSendEmailFromUser(null);
		setIsSendFromDropdownOpen(false);
		// If OwnedBy filter already exists and is set to another user's id change it to the current users'.
		const ownedByFilterIndex = filterCriterias.findIndex(
			criteria => criteria.property === Api.ContactFilterCriteriaProperty.OwnedBy
		);

		if (ownedByFilterIndex > -1 && filterCriterias[ownedByFilterIndex].value !== userSession.user.id) {
			const newCriterias = [...filterCriterias];
			newCriterias[ownedByFilterIndex] = {
				property: Api.ContactFilterCriteriaProperty.OwnedBy,

				value: userSession.user.id,
			};
			setFilterCriterias(newCriterias);
		}
		onSendFromCurrentUserSelected?.();
	};
	const onSendBehalfOfContactOwner = async () => {
		setSendEmailFrom(Api.SendEmailFrom.ContactOwner);

		setSendEmailFromUser(null);
		setIsSendFromDropdownOpen(false);
		// Clear out any other contact filter criteria and add all
		setFilterCriterias(
			Api.VmUtils.concatContactFilterCriteria(
				filterCriterias.filter(
					criteria =>
						![
							Api.ContactFilterCriteriaProperty.All,
							Api.ContactFilterCriteriaProperty.OwnedBy,
							Api.ContactFilterCriteriaProperty.Connections,
						].includes(criteria.property)
				),
				[{ property: Api.ContactFilterCriteriaProperty.All }]
			)
		);
		onSendFromContactOwnerSelected?.();
	};
	const onSendOnBehalfSelectEmployee = async () => {
		setSelectAnEmployeeModalIsOpen(true);
		setIsSendFromDropdownOpen(false);
		onSendFromSelectedEmployee?.();
	};
	const onSaveSelectAnEmployee = (user: Api.IUser) => {
		setSendEmailFrom(Api.SendEmailFrom.SelectedUser);
		setSendEmailFromUser(user);
		setSelectAnEmployeeModalIsOpen(false);
		// If OwnedBy filter already exists and is set to another user's id change it to the selected users.
		const ownedByFilterIndex = filterCriterias.findIndex(
			criteria => criteria.property === Api.ContactFilterCriteriaProperty.OwnedBy
		);

		if (ownedByFilterIndex > -1 && filterCriterias[ownedByFilterIndex].value !== user.id) {
			const newCriterias = [...filterCriterias];
			newCriterias[ownedByFilterIndex] = {
				property: Api.ContactFilterCriteriaProperty.OwnedBy,

				value: user.id,
			};
			setFilterCriterias(newCriterias);
		}
	};
	const onTagListSearchesChanged = (searches: Api.IContactFilterCriteria[]) => {
		// Tag list is confined to filters
		setTagSearchCriterias(searches);
		onTagListSearchChange?.();
	};
	const onAdvancedFiltersFlyoutSave = (filterCriteria: Api.IContactFilterCriteria[], householdGrouping?: boolean) => {
		if (!!filterCriteria && filterCriteria.length > 0) {
			setFilterCriterias(filterCriteria);
			onTagListSearchChange?.();
		}
		if (householdGrouping) {
			setHouseholdGroupingOn(householdGrouping);
		}
		setShowingAdvancedFiltersFlyout(false);
	};
	const onAdvancedFiltersFlyoutCancel = () => {
		setShowingAdvancedFiltersFlyout(false);
	};
	const onShowingFilterOptionChanged = (selectedOption: ISelectBoxOption<Api.IContactFilterCriteria>) => {
		setFilterCriterias(
			Api.VmUtils.concatContactFilterCriteria(
				filterCriterias.filter(
					x =>
						![
							Api.ContactFilterCriteriaProperty.All,
							Api.ContactFilterCriteriaProperty.OwnedBy,
							Api.ContactFilterCriteriaProperty.Connections,
						].includes(x.property)
				),

				[selectedOption.value]
			)
		);
		onShowingFiltersChanged?.();
	};
	const onRemoveRecipient = (contact: Api.IContact) => {
		setContactIdsToAdd(contactIdsToAdd.filter(x => x !== contact.id));
		const _contactIdsToOmit = new Set(contactIdsToOmit);

		_contactIdsToOmit.add(contact.id);
		if (householdGroupingOn) {
			const spouse = contact.householdMembers?.find(
				c => c.id !== contact.id && c.householdRelationship?.title === Api.HouseholdTitle.Spouse
			);
			if (spouse) {
				_contactIdsToOmit.add(spouse.id);
				setContactIdsToAdd(contactIdsToAdd.filter(x => x !== spouse.id));
			}
		}
		setContactIdsToOmit(_contactIdsToOmit);

		onRemoveRecipientCallback(contact.id);
	};
	const onAddContact = (contact: Api.IContact) => {
		const _contactIdsToOmit = new Set(contactIdsToOmit);

		_contactIdsToOmit.delete(contact.id);
		setContactIdsToOmit(_contactIdsToOmit);

		setContactIdsToAdd([...contactIdsToAdd, contact.id]);
	};
	return {
		allFilterCriterias: allCriterias,
		contactFilterQuery,
		contactIdsToAdd,
		contactIdsToOmit: contactIdsToOmitArray,
		contactsToAdd,
		householdGroupingOn,
		hwcEstimateCostQuery,
		isSendFromDropdownOpen,
		onAddContact,
		onAdvancedFiltersFlyoutSave,
		onAdvancedFiltersFlyoutCancel,
		onRemoveRecipient,
		onSaveSelectAnEmployee,
		onSendBehalfOfContactOwner,
		onSendBehalfOfCurrentUser,
		onSendOnBehalfSelectEmployee,
		onShowingFilterOptionChanged,
		onTagListSearchesChanged,
		selectAnEmployeeModalIsOpen,
		selectedShowingFilterOption,
		sendEmailFrom,
		sendEmailFromUser,
		sendFromDropdownAnchorText,
		sendFromDropdownDisabled: !userSession.canSendOnBehalf,
		setIsSendFromDropdownOpen,
		setSelectAnEmployeeModalIsOpen,
		setShowingAdvancedFiltersFlyout,
		showingAdvancedFiltersFlyout,
		showingFilterOptions,
		tagSearchCriterias,
	};
};
