import { useEventLogging } from '../../../../../models/Logging';
import { useUserSession } from '../../../../../models/hooks/appStateHooks';
import { useUpdateUserMilestone } from '../../../../../queries';
import * as React from 'react';

export const useHasUsedWebApp = ({ eventLoggingCategory }: { eventLoggingCategory: string }) => {
	const userSession = useUserSession();
	const { logApiError } = useEventLogging(eventLoggingCategory);
	const updateUserMilestoneMutation = useUpdateUserMilestone({
		onError: error => {
			// @ts-ignore
			logApiError('SetUserMilestone-Error', error);
		},
		userSession,
	});
	React.useEffect(() => {
		// @ts-ignore
		// @ts-ignore
		if (!userSession.user.userMilestones || !userSession.user.userMilestones.hasUsedWebApp) {
			// @ts-ignore
			// @ts-ignore
			updateUserMilestoneMutation.mutate({ name: 'hasUsedWebApp', userId: userSession.user.id, value: true });
		}
	}, [
		updateUserMilestoneMutation,
		// @ts-ignore
		userSession.user.id,
		// @ts-ignore
		userSession.user.userMilestones,
		// @ts-ignore
		// @ts-ignore
		userSession.user.userMilestones.hasUsedWebApp,
	]);
};
