import { StyleSheet } from 'aphrodite';
import { brandPrimaryText } from '../../styles/colors';
import { CssSelectors } from '../../styles/styles';
import TaggingGameButtonBackgroundImageUrl from './taggingGameButtonBackground.png';

export const styleSheet = StyleSheet.create({
	taggingGameButtonContainer: {
		alignItems: 'center',
		backgroundImage: `url(${TaggingGameButtonBackgroundImageUrl})`,
		backgroundSize: '154px 69px',
		display: 'flex',
		height: 69,
		justifyContent: 'center',
		width: 154,
	},
	taggingGameLink: {
		alignItems: 'center',
		color: brandPrimaryText,
		display: 'flex',
		fontSize: 14,
		textDecoration: 'none',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
});
