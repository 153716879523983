import { brandSecondary, grayIconFill } from '../../../styles/colors';
import { CssSelectors, DefaultBorder } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

const entityNavigatorHeaderHeight = 20;
const entityNavigatorHeaderBottomPadding = 17;

export const styleSheet = StyleSheet.create({
	container: {
		boxSizing: 'border-box',
		height: '100%',
		padding: '30px 10px 30px 20px',
	},
	entityContextTabView: {
		height: '100%',
		width: '100%',
	},
	entityNavigatorBody: {
		height: `calc(100% - ${entityNavigatorHeaderHeight}px - ${entityNavigatorHeaderBottomPadding}px)`,
	},
	entityNavigatorHeader: {
		borderBottom: DefaultBorder,
		display: 'flex',
		height: entityNavigatorHeaderHeight,
		paddingBottom: entityNavigatorHeaderBottomPadding,
	},
	mentionList: {
		height: '100%',
	},
	navBackButton: {
		alignItems: 'center',
		color: brandSecondary,
		display: 'flex',
		fontSize: 14,
		justifyContent: 'center',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	placeholder: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '25%',
	},
	placeholderAddButton: {
		alignSelf: 'center',
	},
	placeholderContent: {
		color: grayIconFill,
		display: 'inline-flex',
		flexDirection: 'column',
		fontSize: 12,
		lineHeight: '18px',
		textAlign: 'center',
	},
	placeholderDescription: {
		marginBottom: 16,
		marginTop: 10,
	},
	placeholderIcon: {
		margin: '0 auto',
	},
	placeholderTitle: {
		color: brandSecondary,
		fontSize: 16,
		lineHeight: '22px',
		marginTop: 16,
	},
});
