import { StyleSheet } from 'aphrodite';
import { brandPrimaryActive, brandPrimaryHover, brandPrimary } from '../../../../../styles/colors';

export const styleSheet = StyleSheet.create({
	quickActions: {
		display: 'flex',
		gap: 12,
		padding: 16,
		justifyContent: 'center',
	},
	quickActionButton: {
		':active': {
			background: brandPrimaryActive,
		},
		':hover': {
			background: brandPrimaryHover,
		},
		alignItems: 'center',
		background: brandPrimary,
		borderRadius: '50%',
		cursor: 'pointer',
		display: 'flex',
		height: 40,
		justifyContent: 'center',
		width: 40,
	},
});
