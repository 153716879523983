import { StyleSheet } from 'aphrodite';
import { brandPrimary } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	dateContainer: {
		alignItems: 'center',
		background: 'rgb(249,249,249)',
		border: '1px solid #ccc',
		borderRadius: 8,
		boxSizing: 'border-box',
		display: 'flex',
		height: '100%',
		justifyContent: 'space-between',
		minHeight: 40,
		padding: '0 10px',
		width: '100%',
		...CssSelectors.allDescendants(
			{
				fontSize: 14,
			},
			'span'
		),
	},
	dateFieldButton: {
		display: 'flex',
		alignItems: 'center',
		gap: 8,
	},
	clickable: {
		cursor: 'pointer',
	},
	container: {
		width: 457,
		maxHeight: '100vh',
		overflow: 'auto',
		paddingLeft: 36,
		paddingRight: 36,
	},
	wideContainer: {
		width: 650,
	},
	contactSearchField: {
		width: '100%',
	},
	footer: {
		marginBottom: 24,
		display: 'flex',
		gap: 12,
	},
	formControl: {
		margin: '0 0 16px',
	},
	header: {
		fontSize: 24,
		marginTop: 24,
		marginBottom: 24,
	},
	label: {
		display: 'block',
		fontSize: 14,
		margin: '0 0 8px',
	},
	formControlInputWrap: {
		display: 'flex',
		width: '100%',
		...CssSelectors.allChildren(
			{
				borderRadius: 8,
				width: '100%',
			},
			'.text-input'
		),
	},
	twoColumnLayout: {
		display: 'flex',
		justifyContent: 'space-between',
		gap: 36,
	},
	column: {
		flex: '1',
	},
	modalContainer: {
		...CssSelectors.allDescendants(
			{
				padding: 0,
			},
			'.modal-overlay-content'
		),
	},
	modalHeader: {
		backgroundColor: brandPrimary,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingLeft: 24,
	},
});
