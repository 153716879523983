import { IEventLoggingComponentProps, useEventLogging } from '@AppModels/Logging';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import FlipMove from 'react-flip-move';
import { IErrorMessageComponentProps, IUserSessionComponentProps } from '../../../models/AppState';
import { useErrorMessages, useUserSession } from '../../../models/hooks/appStateHooks';
import {
	getFeedItemViewModel,
	invalidateDashboardFeed,
	useDashboardFeed,
	useSuppressDashboardItem,
} from '../../../queries';
import { RecentMeetingViewModel } from '../../../viewmodels/AppViewModels';
import { AppBarNavigationItemPortalDestinationId } from '../../components/AppBar';
import { EmptyState } from '../../components/EmptyState';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { DarkMainContainerBackground } from '../../components/MainContainerBackground';
import { NavigationBreadcrumbsBar } from '../../components/NavigationBreadcrumbsBar';
import { Portal } from '../../components/Portal';
import { RecentMeetingCard } from '../../components/cards/RecentMeetingCard';
import { CalendarInCircleGraphic } from '../../components/svgs/graphics/CalendarInCircleGraphic';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps, IUserSessionComponentProps, IErrorMessageComponentProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

const PAGE_SIZE = 100;
const FEED_TYPE = 'Meeting';

export function RecentMeetings({ className, styles }: IProps) {
	const errorMessages = useErrorMessages();
	const userSession = useUserSession();
	const { logApiError } = useEventLogging('RecentMeetings');
	const dashboardFeedQuery = useDashboardFeed({
		handleError: err => {
			// @ts-ignore
			errorMessages.pushApiError(err);
			logApiError('LoadRecentMeetings-Error', err);
		},
		pageSize: PAGE_SIZE,
		typeOf: FEED_TYPE,
	});
	const suppressDashboardItemMutation = useSuppressDashboardItem({
		onError: error => {
			// @ts-ignore
			// @ts-ignore
			errorMessages.pushApiError(error);
			// @ts-ignore
			logApiError('SuppressRecentMeeting-Error', error);
		},
		onSuccess: () => {
			invalidateDashboardFeed({ pageSize: PAGE_SIZE, typeOf: FEED_TYPE });
		},
	});
	const onSuppressClicked = (x: RecentMeetingViewModel) => () => {
		// @ts-ignore
		suppressDashboardItemMutation.mutate({ context: Api.DashboardSuppressContext.RecentMeeting, id: x.id });
	};

	const feedItems = dashboardFeedQuery.data?.values ?? [];

	return (
		<div
			className={`${css(styleSheet.container, ...(styles || []))} recently-viewed-emails main-container-child ${
				className || ''
			}`}
		>
			<Portal destination={AppBarNavigationItemPortalDestinationId}>
				<NavigationBreadcrumbsBar currentLocationName='Recent Meetings' />
			</Portal>
			<DarkMainContainerBackground />
			{dashboardFeedQuery.isLoading ? <LoadingSpinner type='large' /> : null}
			{dashboardFeedQuery.isSuccess && feedItems.length === 0 ? (
				<EmptyState
					image={<CalendarInCircleGraphic />}
					text={"You don't have any recent meetings"}
					subtext={"More meetings will appear on your dashboard once they've occurred."}
				/>
			) : null}
			{dashboardFeedQuery.isSuccess && feedItems.length > 0 ? (
				<FlipMove
					enterAnimation={true}
					leaveAnimation={true}
					staggerDelayBy={50}
					staggerDurationBy={10}
					appearAnimation={false}
				>
					{feedItems.map(x => {
						const itemVM = getFeedItemViewModel({ feedItem: x, userSession });
						if (itemVM instanceof RecentMeetingViewModel) {
							return (
								<RecentMeetingCard
									className={css(styleSheet.item)}
									recentMeeting={itemVM}
									key={itemVM.id}
									onSuppressClicked={onSuppressClicked(itemVM)}
								/>
							);
						}
					})}
				</FlipMove>
			) : null}
		</div>
	);
}
