import { CSSProperties, StyleSheet } from 'aphrodite';
import { brandPrimaryHover, destructive, mention, navigation } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

const input: CSSProperties = {
	color: `${brandPrimaryHover} !important`,
	fontSize: '36px !important',
	opacity: 1,
	textAlign: 'center',
};

export const styleSheet = StyleSheet.create({
	betweenLabel: {
		color: 'gray',
	},
	button: {
		alignItems: 'center',
		background: mention,
		color: 'gray',
	},
	buttonNoNumber: {
		alignItems: 'center',
		background: mention,
		borderRadius: 12,
		color: `${brandPrimaryHover}`,
		display: 'flex',
		height: '165px',
		justifyContent: 'center',
		paddingLeft: 50,
		paddingRight: 50,
		textAlign: 'center',
		width: '340px',
	},
	checkboxContainer: {
		alignItems: 'center',
		background: '#DBF2C3',
		display: 'flex',
		height: 40,
		justifyContent: 'center',
		marginTop: 20,
		width: 450,
	},
	checkboxLabel: {
		color: 'black',
	},
	container: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		padding: '64px 20px 20px',
		...CssSelectors.allChildren(
			{
				fontWeight: 400,
				margin: '20px 0 0',
			},
			'h2'
		),
		...CssSelectors.allChildren(
			{
				color: navigation,
				marginTop: 15,
			},
			'p'
		),
		...CssSelectors.allChildren(
			{
				marginTop: 20,
			},
			'button'
		),
	},
	error: {
		color: `${destructive} !important`,
		fontSize: 14,
		margin: `0 !important`,
	},
	graphicContainer: {
		alignItems: 'center',
		background: '#f9f9f9',
		borderRadius: '50%',
		display: 'flex',
		height: 120,
		justifyContent: 'center',
		width: 120,
	},
	inlineLink: {
		fontSize: '16px',
		paddingLeft: 5,
	},
	label: {
		color: 'gray',
		marginTop: 10,
	},
	noNumberLabel: {
		fontSize: 16,
		fontWeight: 600,
	},
	phoneNumber: {
		background: mention,
		border: 'none',
		margin: '10px 0',
		padding: '20px 0',
	},
	phoneNumberInput: {
		...input,
		maxWidth: 350,
	},
	phoneNumberInputEditable: {
		...input,
		maxWidth: 125,
	},
	requestANumber: {
		margin: '10px 0 0',
	},
	sharedNumber: {
		color: brandPrimaryHover,
		fontSize: 36,
		fontWeight: 400,
	},
	sharedNumberContainer: {
		alignItems: 'center',
		background: mention,
		borderRadius: 12,
		display: 'flex',
		flexDirection: 'column',
		height: '165px',
		justifyContent: 'center',
		paddingLeft: 40,
		paddingRight: 40,
		textAlign: 'center',
		width: '340px',
	},
});
