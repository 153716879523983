import { StyleSheet } from 'aphrodite';
import { brandPrimaryHoverTint, borderColor, hotPink } from '../../styles/colors';
import { CssSelectors } from '../../styles/styles';

const borderStyle = `1px solid ${borderColor}`;

export const dataGridStyles = StyleSheet.create({
	badge: {
		background: '#FCE0F3',
		color: hotPink,
	},
	tableDataCellBorderless: {
		padding: '16px 10px 16px 10px',
	},
	tableDataCell: {
		borderTop: borderStyle,
		padding: '16px 10px 16px 10px',
	},
	table: {
		alignItems: 'stretch',
		border: borderStyle,
		borderRadius: 10,
		boxShadow: '0px 0px 6px rgba(139, 139, 139, 0.1)',
		boxSizing: 'border-box',
		paddingTop: 20,
		paddingBottom: 20,
		width: '100%',
		borderSpacing: 0,
	},
	tableHeader: {
		background: '#fff',
		whiteSpace: 'nowrap',
	},
	tableHeaderCell: {
		color: '#AAAAAA',
		fontSize: 11,
		letterSpacing: '1.1px',
		textTransform: 'uppercase',
		padding: 10,
	},
	tableDataCellCentered: {
		textAlign: 'center',
		verticalAlign: 'middle',
	},
	tableHeaderCellCentered: {
		textAlign: 'center',
		verticalAlign: 'middle',
	},
	stackedCell: {
		display: 'flex',
		flexDirection: 'column',
	},
	fullHeightTable: {
		height: '100%',
	},
	tableHeaderRow: {
		backgroundColor: brandPrimaryHoverTint,
		...CssSelectors.allChildren(
			{
				borderTopLeftRadius: 8,
			},
			':first-child'
		),
		...CssSelectors.allChildren(
			{
				borderTopRightRadius: 8,
			},
			':last-child'
		),
	},
	tableRow: {
		...CssSelectors.allChildren(
			{
				paddingLeft: 20,
			},
			':first-child'
		),
		...CssSelectors.allChildren(
			{
				paddingRight: 20,
			},
			':last-child'
		),
	},
});
