import { StyleSheet } from 'aphrodite';
import bg from '../../../assets/focused-view-background.png';

export const styleSheet = StyleSheet.create({
	container: {},
	defaultChildStyle: {
		boxSizing: 'border-box',
		height: '100%',
		padding: '0 30px',
		position: 'relative',
		width: '100%',
	},
	focusedHeader: {
		background: `url(${bg}) no-repeat`,
		backgroundSize: '100% auto',
		height: 100,
	},
});
