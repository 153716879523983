import { StyleSheet } from 'aphrodite';
import { darkGrayFontColor } from '../../../../styles/colors';
import { CssSelectors } from '../../../../styles/styles';

export const styleSheet = StyleSheet.create({
	autocompleteBox: {
		marginLeft: 5,
		...CssSelectors.allChildren(
			{
				...CssSelectors.allChildren(
					{
						...CssSelectors.allChildren(
							{
								padding: '5px 10px',
							},
							'div'
						),
					},
					'div'
				),
				width: 205,
			},
			'div'
		),
	},
	container: {},
	disabled: {
		opacity: 0.5,
		pointerEvents: 'none',
	},
	radio: {
		marginBottom: 3,
		marginLeft: 0,
	},
	radioText: {
		color: darkGrayFontColor,
	},
	searchBox: {
		':nth-child(1n)': {
			boxSizing: 'border-box',
			marginLeft: 32,
			marginTop: 5,
			padding: '7px 12px',
			width: 165,
			...CssSelectors.allChildren(
				{
					'::placeholder': {
						fontStyle: 'italic',
					},
					fontSize: 12,
				},
				'input'
			),
		},
	},
	searchContainer: {
		position: 'relative',
	},
	searchIcon: {
		marginRight: 8,
		marginTop: 5,
	},
});
