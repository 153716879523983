import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const InProgressIcon: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon
			className={` in-progress-icon ${className || ''}`}
			width='78'
			height='106'
			viewBox='0 0 78 106'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g>
				<path
					d='M4 1H74C75.6569 1 77 2.34315 77 4V26C77 27.6569 75.6569 29 74 29H4C2.34315 29 1 27.6569 1 26V4C1 2.34315 2.34315 1 4 1Z'
					fill='#E8F3F9'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<rect x='30.875' y='14.3103' width='35.75' height='3.24138' rx='1.62069' fill='#CAE0EC' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M14.55 20.3089C13.8338 21.2383 12.4751 21.3544 11.6116 20.56L8.62139 17.8089C8.02314 17.2585 8.00424 16.3204 8.57983 15.7464V15.7464C9.10972 15.2179 9.95767 15.188 10.5235 15.6778L12.8577 17.6988L20.1981 8.3457C20.6645 7.75146 21.5155 7.62864 22.1308 8.06675V8.06675C22.7842 8.53193 22.9204 9.44676 22.4308 10.082L14.55 20.3089Z'
					fill='#00AAE8'
				/>
				<path
					d='M4 39H74C75.6569 39 77 40.3431 77 42V64C77 65.6569 75.6569 67 74 67H4C2.34315 67 1 65.6569 1 64V42C1 40.3431 2.34315 39 4 39Z'
					fill='#E8F3F9'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<rect x='30.875' y='52.3103' width='35.75' height='3.24138' rx='1.62069' fill='#CAE0EC' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M14.55 58.3089C13.8338 59.2383 12.4751 59.3544 11.6116 58.56L8.62139 55.8089C8.02314 55.2585 8.00424 54.3204 8.57983 53.7464V53.7464C9.10972 53.2179 9.95767 53.188 10.5235 53.6778L12.8577 55.6988L20.1981 46.3457C20.6645 45.7515 21.5155 45.6286 22.1308 46.0668V46.0668C22.7842 46.5319 22.9204 47.4468 22.4308 48.082L14.55 58.3089Z'
					fill='#00AAE8'
				/>
				<path
					d='M4 77H74C75.6569 77 77 78.3431 77 80V102C77 103.657 75.6569 105 74 105H4C2.34315 105 1 103.657 1 102V80C1 78.3431 2.34315 77 4 77Z'
					fill='white'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<rect x='30.875' y='90.2069' width='35.75' height='3.24138' rx='1.62069' fill='#CAE0EC' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M10.3149 86.3147C11.4371 85.1879 12.964 84.5235 14.6189 84.5235C17.9804 84.5235 20.7141 87.258 20.7141 90.6187H22.2379C22.2379 86.4176 18.8208 82.9997 14.6189 82.9997C12.5503 82.9997 10.6417 83.8294 9.23829 85.2382L7.76172 83.7616V88.333H12.3331L10.3149 86.3147Z'
					fill='#89C947'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M18.9233 94.9234C17.801 96.0502 16.2741 96.7146 14.6193 96.7146C11.2578 96.7146 8.52405 93.9801 8.52405 90.6194H7.00024C7.00024 94.8205 10.4174 98.2384 14.6193 98.2384C16.6879 98.2384 18.5964 97.4087 19.9999 95.9992L21.4764 97.4765V92.9051H16.905L18.9233 94.9234Z'
					fill='#89C947'
				/>
			</g>
		</SvgIcon>
	);
};
