import { StyleSheet } from 'aphrodite';
import { dashboardNavBackground } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import BackgroundImageBottomUrl from './backgroundBottom.svg';
import BackgroundImageLeftUrl from './backgroundLeft.svg';
import BackgroundImageRightUrl from './backgroundRight.svg';

const gutterSize = 209;
const bottomHeight = gutterSize / 2.0;
export const styleSheet = StyleSheet.create({
	arrowBottom: {
		height: 42,
		width: 21,
	},
	arrowBottomButton: {
		marginRight: -10,
		marginTop: -80,
	},
	arrowLeft: {
		height: 20,
		transform: 'rotate(180deg)',
		width: 41,
	},
	arrowLeftButton: {
		marginTop: -5,
		position: 'absolute',
		right: -30,
	},
	arrowRight: {
		height: 20,
		width: 41,
	},
	arrowRightButton: {
		left: -30,
		marginTop: -5,
		position: 'absolute',
	},
	bottom: {
		alignItems: 'center',
		background: `url(${BackgroundImageBottomUrl})`,
		backgroundPosition: 'top',
		backgroundRepeat: 'no-repeat',
		bottom: 0,
		display: 'flex',
		flexDirection: 'column',
		height: bottomHeight,
		justifyContent: 'center',
		left: 0,
		position: 'absolute',
		right: 0,
		width: '100%',
		zIndex: 0,
	},
	cardContainer: {
		position: 'absolute',
	},
	container: {
		background: dashboardNavBackground,
		display: 'flex',
		height: '100%',
		justifyContent: 'space-between',
		overflow: 'hidden',
		position: 'relative',
		width: '100%',
	},
	image: {
		pointerEvents: 'none',
	},
	left: {
		alignItems: 'center',
		background: `url(${BackgroundImageLeftUrl})`,
		backgroundColor: dashboardNavBackground,
		backgroundPosition: 'right',
		backgroundRepeat: 'no-repeat',
		display: 'flex',
		height: '100%',
		position: 'relative',
		width: gutterSize,
		zIndex: 0,
	},
	middle: {
		alignItems: 'center',
		display: 'flex',
		height: `calc(100% - ${bottomHeight}px)`,
		justifyContent: 'center',
		pointerEvents: 'none',
		position: 'relative',
		width: `calc(100% - ${2 * gutterSize}px)`,
		zIndex: 1,
		...CssSelectors.allChildren({
			pointerEvents: 'all',
		}),
	},
	right: {
		alignItems: 'center',
		background: `url(${BackgroundImageRightUrl})`,
		backgroundColor: dashboardNavBackground,
		backgroundPosition: 'left',
		backgroundRepeat: 'no-repeat',
		display: 'flex',
		height: '100%',
		position: 'relative',
		width: gutterSize,
		zIndex: 0,
	},
	stack: {
		position: 'relative',
	},
});
