import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const PushNotificationsPlaceholderIcon: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon className={`push-notifications-placeholder-icon ${className || ''}`} height={260} width={260}>
			<g fill='none' fillRule='evenodd'>
				<circle cx='130' cy='130' r='130' fill='#E8F3F9' />
				<path
					fill='#CAE0EC'
					stroke='#00AAE8'
					strokeWidth='2'
					d='M10,214 L250,214 L250,48 C250,46.3431458 248.656854,45 247,45 L13,45 C11.3431458,45 10,46.3431458 10,48 L10,214 Z'
				/>
				<path
					fill='#E8F3F9'
					stroke='#00AAE8'
					strokeWidth='2'
					d='M10,71 L250,71 L250,48 C250,46.3431458 248.656854,45 247,45 L13,45 C11.3431458,45 10,46.3431458 10,48 L10,71 Z'
				/>
				<circle cx='22' cy='58' r='4' fill='#00AAE8' />
				<circle cx='33' cy='58' r='4' fill='#00AAE8' />
				<circle cx='43' cy='58' r='4' fill='#00AAE8' />
				<path
					fill='#FFF'
					transform='translate(92 79)'
					id='b-a'
					d='M4.07920023,0 L153,0 C155.209139,-4.05812251e-16 157,1.790861 157,4 L157,47 C157,49.209139 155.209139,51 153,51 L4.92233749,51 C2.74376212,51 0.965815157,49.256573 0.923106202,47.0784163 L0.0799689471,4.0784163 C0.0366608206,1.86970185 1.79206948,0.0440768399 4.00078393,0.000768713373 C4.02691956,0.000256250102 4.05305958,-4.3928725e-16 4.07920023,-4.4408921e-16 Z'
				/>
				<g transform='translate(99 84)'>
					<rect width='40' height='40' fill='#0066A5' />
					<polygon
						fill='#FEFEFE'
						points='29.583 33.333 12.917 33.333 12.917 5.417 17.255 5.417 17.255 29.065 28.121 29.065 28.121 28.186 18.33 28.186 18.33 5.417 19.98 5.417 19.98 26.535 29.583 26.535 29.583 30.716 15.604 30.716 15.604 7.068 14.568 7.068 14.568 31.682 29.583 31.682'
					/>
				</g>
				<g fill='#D8D8D8' transform='translate(147 91)'>
					<rect width='92' height='10' />
					<rect width='62' height='10' y='15' />
				</g>
			</g>
		</SvgIcon>
	);
};
