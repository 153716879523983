import { nameCircles } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	root: {
		alignItems: 'center',
		backgroundColor: nameCircles,
		borderRadius: '100%',
		display: 'inline-flex',
		height: 60,
		justifyContent: 'center',
		overflow: 'hidden',
		userSelect: 'none',
		verticalAlign: 'middle',
		width: 60,
	},
});
