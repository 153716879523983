import { StyleSheet } from 'aphrodite';
import { brandPrimary, brandPrimaryHover, darkGrayFontColor } from '../../styles/colors';
import { CssSelectors } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	autoReply: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		paddingBottom: 10,
		width: '100%',
	},
	autoReplyMessageTextField: {
		...CssSelectors.allChildren(
			{
				height: 120,
				resize: 'none',
			},
			'textarea'
		),
	},
	container: {
		background: '#f9f9f9',
		padding: 20,
	},
	footer: {
		display: 'flex',
		paddingTop: 15,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	manageButton: {
		fontSize: '12px',
		paddingLeft: 10,
	},
	modal: {
		width: '400px',
	},
	modalTitle: {
		color: brandPrimary,
		height: 20,
		paddingBottom: 10,
	},
	wrapper: {
		alignItems: 'center',
		background: '#fff',
		borderRadius: 3,
		boxShadow: '1px 2px 5px 0px rgba(0,0,0,0.1)',
		boxSizing: 'border-box',
		height: `calc(100% - 30px)`,
		overflow: 'auto',
		width: '100%',
	},
	modalDescription: {
		color: darkGrayFontColor,
		fontSize: 12,
		lineHeight: 1.5,
	},
	modalIcon: {
		marginBottom: 10,
	},
	centerText: {
		textAlign: 'center',
	},
	justifyCenter: {
		justifyContent: 'center',
	},
	modalBrandLink: {
		':hover': {
			color: brandPrimaryHover,
			textDecoration: 'underline',
		},
		alignItems: 'center',
		color: brandPrimary,
		cursor: 'pointer',
		display: 'inline-flex',
		fontSize: 12,
		textDecoration: 'none',
	},
});
