import { StyleSheet } from 'aphrodite';

const redtailColor = '#c72127';

export const styleSheet = StyleSheet.create({
	ctaButton: {
		backgroundColor: redtailColor,
		boxShadow: 'none',
		marginTop: 20,
	},
	integrationTitle: {
		color: redtailColor,
	},
});
