import { CssSelectors } from '../../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	availabilityContainer: {
		display: 'flex',
	},
	availabilityContainerParent: {
		':not(:last-child)': {
			marginBottom: 12,
		},
	},
	availabilityDisabled: {
		opacity: 0.5,
	},
	availabilitySubblock: {
		marginLeft: 75,
		marginTop: 6,
	},
	availabilityTimeBlockContainer: {
		display: 'flex',
		...CssSelectors.allChildren(
			{
				display: 'block',
				fontSize: 12,
				minWidth: 40,
				paddingTop: 12,
				textAlign: 'center',
			},
			'span'
		),
	},
	button: {
		marginLeft: 8,
	},
	checkboxContainer: {
		alignItems: 'flex-start',
		display: 'flex',
		minWidth: 75,
		paddingTop: 8,
		...CssSelectors.allChildren(
			{
				margin: `4px 8px 0 0`,
			},
			'.checkbox'
		),
	},
	checkboxText: {
		color: '#000',
		fontSize: 14,
		fontWeight: 600,
	},
	container: {
		marginBottom: 12,
	},
	row: {
		display: 'flex',
	},
	timeDropdown: {
		minWidth: 115,
	},
});
