import { UserSessionViewModelKey } from '../../../../models/AppState';
import EmailIconUrl from '../../../assets/email_graphic.svg';
import { campaignTintColor } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { FeedCard, IFeedCardCallbackProps } from '../FeedCard';
import { styleSheet } from './styles';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { CampaignViewModel } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

interface IProps extends IFeedCardCallbackProps, IEventLoggingComponentProps {
	campaign: CampaignViewModel;
	className?: string;
	styles?: StyleDeclarationValue[];
}

class _CampaignCard extends React.Component<IProps> {
	public render() {
		const { className, styles, onSuppressClicked, campaign } = this.props;
		return (
			<FeedCard
				className={`${css(styleSheet.container, ...(styles || []))} campaign-card ${className || ''}`}
				indicatorColor={campaignTintColor}
				onSuppressClicked={onSuppressClicked}
				showSuppressOption={true}
			>
				<div className={css(styleSheet.header)}>
					<img src={EmailIconUrl} className={css(styleSheet.icon)} />
					<div>See how your Group Email is doing!</div>
				</div>
				<div className={css(baseStyleSheet.truncateText, styleSheet.body)}>
					<div>You sent the email</div>
					&nbsp;
					<div className={css(baseStyleSheet.truncateText, styleSheet.subject)}>{`"${campaign.subject || ''}"`}</div>
					&nbsp;
					<div>Watch the live update on who’s reading your email!</div>
				</div>
				<div className={css(styleSheet.footer)}>
					<NavLink
						className={css(baseStyleSheet.brandLink, styleSheet.link)}
						to={`/reporting/group-email/${campaign.id}`}
					>
						Go to Group Email Report
					</NavLink>
				</div>
			</FeedCard>
		);
	}
}

const CampaignCardAsObserver = observer(_CampaignCard);
const CampaignCardWithContext = inject(UserSessionViewModelKey)(CampaignCardAsObserver);
export const CampaignCard = withEventLogging(CampaignCardWithContext, 'CampaignCard');
