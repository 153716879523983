import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { v4 as uuid } from 'uuid';
import { useUserSession } from '../../../../../models/hooks/appStateHooks';
import { Checkbox } from '../../../../components/Checkbox';
import { EStatusOption, StatusFilterCheckboxesMap, useStatusFilters } from '../hooks/useStatusFilter';
import { styleSheet } from './styles';
interface ICheckboxOption {
	text: string;
	value: number;
}

// Note: There is a very similar component in the AdvancedFiltersFlyout that does not save immediately
export const StatusFilterCheckboxes = ({
	className,
	styles,
	filter,
	onChangeFilter,
}: {
	className?: string;
	styles?: StyleDeclarationValue[];
	filter: Api.IBulkContactsRequest;
	onChangeFilter: (filter: Api.IBulkContactsRequest) => void;
}) => {
	const userSession = useUserSession();
	const { selectedStatuses: savedSelectedStatuses, toggleStatusFilter } = useStatusFilters({ filter, onChangeFilter });

	const currentSelectedValues = savedSelectedStatuses;

	const options = Object.keys(StatusFilterCheckboxesMap)
		.map(checkbox => {
			const filterOptionKey = parseInt(checkbox, 10);
			const option = StatusFilterCheckboxesMap[filterOptionKey as EStatusOption];
			return {
				text: option.text,
				value: filterOptionKey,
			};
		})
		.filter(option => {
			if (option.value === EStatusOption.Archived && userSession.userRole !== 'superAdmin') {
				return false;
			}
			return true;
		});

	const toggleOption = (option: ICheckboxOption) => {
		toggleStatusFilter(option.value);
	};

	const disabledOptions = options
		.filter(option => {
			const opposingValues = {
				[EStatusOption.NoEmail]: EStatusOption.WithEmail,
				[EStatusOption.WithEmail]: EStatusOption.NoEmail,
				[EStatusOption.NoAddress]: EStatusOption.WithAddress,
				[EStatusOption.WithAddress]: EStatusOption.NoAddress,
				[EStatusOption.WithPhoneNumber]: EStatusOption.WithoutPhoneNumber,
				[EStatusOption.WithoutPhoneNumber]: EStatusOption.WithPhoneNumber,
			};
			const opposite: EStatusOption = opposingValues[option.value as keyof typeof opposingValues];

			if (opposite === undefined) {
				return false;
			}

			if (currentSelectedValues.indexOf(opposite) > -1) {
				return true;
			}
			return false;
		})
		.map(option => option.value);

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
			{options.map(option => {
				return (
					<div className={css(styleSheet.checkboxWrapper)} key={option.value}>
						<Checkbox
							id={uuid()}
							onChange={() => toggleOption(option)}
							checked={currentSelectedValues.indexOf(option.value) > -1}
							disabled={disabledOptions.indexOf(option.value) > -1}
						>
							<div className={css(styleSheet.checkboxText)}>{option.text}</div>
						</Checkbox>
					</div>
				);
			})}
		</div>
	);
};
