import { StyleDeclaration, css } from 'aphrodite';
import { PostageIcon } from '../../components/svgs/icons/PostageIcon';
import { styleSheet } from './styles';

export function AddressMissingPill({ styleDeclaration }: { styleDeclaration?: StyleDeclaration }) {
	return (
		<div className={css(styleSheet.addressMissingPill, styleDeclaration)}>
			<span>Missing</span>
			<span className={css(styleSheet.addressMissingPillPostageWrapper)}>
				<PostageIcon width={12} height={12} />
			</span>
		</div>
	);
}
