import { brandSecondary, destructive, destructiveActive, destructiveHover, titles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	body: {
		marginTop: 20,
	},
	container: {
		position: 'relative',
		width: 420,
	},
	deleteButton: {
		':active': {
			color: destructiveActive,
		},

		':hover': {
			color: destructiveHover,
		},
		alignItems: 'center',
		color: destructive,
		display: 'flex',
		fontSize: 12,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	fieldLabel: {
		color: titles,
		fontSize: 14,
	},
	footer: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: 28,
	},
	footerLeft: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	header: {
		color: brandSecondary,
		fontSize: 18,
	},
	inputField: {
		marginTop: 4,
	},
	ownerCheckbox: {
		marginTop: 25,
	},
	ownerCheckboxText: {
		color: titles,
		fontSize: 14,
	},
	searchField: {
		width: '100%',
	},
});
