import { styleSheet } from './styles';
import { IAddress } from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	address?: IAddress;
	className?: string;
}

export const MultiLineAddress: React.FC<IProps> = props => {
	const { className, address } = props;
	if (!address) {
		return null;
	}
	const { city, address1, address2, stateProvince, postalCode, country } = address;
	const address3 =
		!!city || !!stateProvince || !!postalCode
			? `${city || ''}${(!!stateProvince || !!postalCode) && !!city ? ', ' : ''}${stateProvince || ''}${
					!!stateProvince && !!postalCode ? ' ' : ''
				}${postalCode || ''}`
			: null;
	return (
		<div className={`${css(styleSheet.multiLineAddress)} ${className || ''}`}>
			{!!address1 && (
				<div className='truncate-text' title={address1}>
					{address1}
				</div>
			)}
			{!!address2 && (
				<div className='truncate-text' title={address2}>
					{address2}
				</div>
			)}
			{!!address3 && (
				<div className='truncate-text' title={address3}>
					{address3}
				</div>
			)}
			{!!country && (
				<div className='truncate-text' title={country}>
					{country}
				</div>
			)}
		</div>
	);
};
