import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { getCampaignCalendarDateRangeStringValue } from '../../../models/UiUtils';
import { CompleteColor } from '../../components/reporting/emails/CampaignListItem/styles';
import { CheckmarkIcon } from '../../components/svgs/icons/CheckmarkIcon';
import { ReplyIcon } from '../../components/svgs/icons/ReplyIcon';
import { TextingIcon } from '../../components/svgs/icons/TextingIcon';
import { bs } from '../../styles/styles';
import { styleSheet } from './styles';

export function TextCampaignCompletedListItem({
	report,
	index,
}: {
	report: Api.TextCampaignReportView;
	index?: number;
}) {
	return (
		<div
			className={css(
				bs.bgHoneyDew,
				bs.borderSolid,
				bs.border,
				bs.borderYellowGreen,
				bs.textSuccess,
				bs.rounded,
				bs.textXs,
				bs.p2,
				bs.textLeft,
				index ? styleSheet.plannedCampaignListItem : undefined
			)}
			style={{ '--stagger-anim-order': index } as React.CSSProperties}
		>
			<div className={css(bs.flex, bs.itemsCenter)}>
				<div
					className={css(
						bs.itemsCenter,
						bs.roundedFull,
						bs.flex,
						bs.bgBrilliantAzure,
						bs.flexShrink0,
						bs.w10,
						bs.h10,
						bs.justifyCenter,
						bs.mr3,
						bs.bgGreen
					)}
				>
					<TextingIcon fill='white' />
				</div>
				<div className={css(bs.flex1)}>
					<div>{getCampaignCalendarDateRangeStringValue(report.dueDate, report.dueDate)}</div>
					<div className={css(bs.fontBold)}>{report.campaignName}</div>
				</div>
			</div>
			<div className={css(bs.mt1, bs.flex, bs.itemsCenter, bs.justifyBetween)}>
				{report.stats?.textsSent != null ? (
					<div className={css(bs.flex, bs.itemsCenter, bs.gap2)}>
						<CheckmarkIcon fillColor={CompleteColor} type='bold' />
						<span>Sent to {report.stats.textsSent.toLocaleString()}</span>
					</div>
				) : null}
				{report.stats?.textReplies != null ? (
					<div className={css(bs.flex, bs.itemsCenter, bs.gap2)}>
						<ReplyIcon fillColor={CompleteColor} />
						<span>{`${report.stats.textReplies.toLocaleString()} replies`}</span>
					</div>
				) : null}
			</div>
		</div>
	);
}
