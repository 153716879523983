import { brandSecondary, titles } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		marginTop: 50,
		position: 'relative',
		width: '100%',
	},
	image: {
		height: 180,
		width: 180,
	},
	subtitle: {
		color: titles,
		fontSize: 14,
		marginTop: 10,
		textAlign: 'center',
	},
	title: {
		color: brandSecondary,
		fontSize: 18,
		marginTop: 30,
	},
});
