import { AssigneeIcon } from '../svgs/icons/AssigneeIcon';
import { styleSheet } from './styles';
import * as Avatar from '@radix-ui/react-avatar';
import { StyleDeclaration, css } from 'aphrodite';

export function UserIconAvatar({ styleDeclaration }: { styleDeclaration?: StyleDeclaration }) {
	return (
		<Avatar.Root className={css(styleSheet.root, styleDeclaration)}>
			<Avatar.Fallback asChild>
				<AssigneeIcon fillColor='#fff' />
			</Avatar.Fallback>
		</Avatar.Root>
	);
}
