import * as Api from '@ViewModels';
import * as Tabs from '@radix-ui/react-tabs';
import { css } from 'aphrodite';
import * as React from 'react';
import { CardSize } from '../../../models';
import { HiddenDefaultMessageTemplateKey } from '../../../models/MessageTemplates';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { ITemplateCard, KnownCategories } from '../../../viewmodels/AppViewModels';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { ISelectOption, Select } from '../../components/Select';
import { TextInput } from '../../components/TextInput';
import { OopsNotFoundGraphic } from '../../components/svgs/graphics/OopsNotFoundGraphic';
import { SearchIcon } from '../../components/svgs/icons/SearchIcon';
import { SocialMediaIndicatorIcon } from '../../components/svgs/icons/SocialMediaIndicatorIcon';
import { CampaignTemplateCard } from '../../components/templateCards/CampaignTemplateCard';
import {
	alternateTitleColor,
	brandPrimaryHover,
	emailActivityTintColor,
	success,
	warningDark,
} from '../../styles/colors';
import { BlogsCampaignCards } from './BlogsCampaignCards';
import { EmailCampaignCards } from './EmailCampaignCards';
import { HandwrittenCardsCampaignCards } from './HandwrittenCardsCampaignCards';
import { SocialMediaCampaignCards } from './SocialMedia/SocialMediaCampaignCards';
import { styleSheet } from './styles';
import { TextingTabPage } from './texting/TextingTabPage';

export const TabValues = {
	Email: 'Email',
	Social: 'Social',
	Cards: 'Cards',
	Texting: 'Texting',
	Blogs: 'Blogs',
} as const;

export const TabColors = {
	[TabValues.Email]: brandPrimaryHover,
	[TabValues.Social]: alternateTitleColor,
	[TabValues.Cards]: success,
	[TabValues.Texting]: warningDark,
	[TabValues.Blogs]: emailActivityTintColor,
} as const;

export const tabContent = {
	[TabValues.Email]: <EmailCampaignCards />,
	[TabValues.Social]: <SocialMediaCampaignCards />,
	[TabValues.Cards]: <HandwrittenCardsCampaignCards />,
	[TabValues.Texting]: <TextingTabPage />,
	[TabValues.Blogs]: <BlogsCampaignCards />,
} as const;

const LazyHtmlNewsletterTemplates = React.lazy(() => import(/* webpackPrefetch: true */ '../HtmlNewsletterTemplates'));

export const SearchKeywords: React.FC<{
	onChange: (value: string) => void;
	value: string;
}> = ({ onChange, value }) => {
	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChange(event.target.value);
	};
	return (
		<div className={css(styleSheet.searchKeywords)}>
			<TextInput
				className={css(styleSheet.searchBox)}
				inputClassName={css(styleSheet.searchBoxInput)}
				inputId='campaign-browser-search'
				leftAccessory={<SearchIcon />}
				onChange={handleSearchChange}
				placeholder='Search by keywords'
				type='text'
				value={value}
			/>
		</div>
	);
};

export const emailCampaignSortByOptions: ISelectOption<string>[] = [
	{
		dataContext: 'creationDate',
		id: 'sort-by-option-creation-date',
		text: 'Creation Date',
	},
	{
		dataContext: 'name',
		id: 'sort-by-option-template-name',
		text: 'Template Name',
	},
];

export const SortBySelector: React.FC<{
	onChange: (option: ISelectOption<string>) => void;
	selectedOption: ISelectOption<string>;
}> = ({ onChange, selectedOption }) => {
	return (
		<div className={css(styleSheet.sortBySelector)}>
			<div>Sort by:</div>
			<Select
				onOptionClick={onChange}
				options={emailCampaignSortByOptions}
				selectedOption={selectedOption}
				triggerStyles={[styleSheet.sortBySelect]}
				styles={[styleSheet.sortBySelectContainer]}
			/>
		</div>
	);
};

export const renderLoading = () => {
	return (
		<div className={css(styleSheet.loadingContainer)}>
			<LoadingSpinner type='large' />
		</div>
	);
};

export const renderNotFound = (key?: string) => {
	return (
		<div key={key} className={css(styleSheet.noCampaigns)}>
			<figure>
				<OopsNotFoundGraphic />
			</figure>
			<p>We did not find any campaigns based on your search.</p>
			<p>Please try a different search.</p>
		</div>
	);
};

export const HTMLNewsletter = ({
	search,
	category,
	industry,
}: {
	search: string;
	category: KnownCategories;
	industry: Api.Industry;
}) => {
	const userSession = useUserSession();
	if (!search && category === KnownCategories.HtmlNewsletters) {
		if (!userSession?.account?.features?.htmlNewsletter?.enabled) {
			return (
				<div className={css(styleSheet.htmlEmpty)}>
					<p className={css(styleSheet.htmlEmptyText)}>HTML Newsletters are disabled for this account.</p>
					<p className={css(styleSheet.htmlEmptyText)}>Contact your account Admin to enable.</p>
				</div>
			);
		}

		return (
			<React.Suspense fallback={<LoadingSpinner type='large' />}>
				<LazyHtmlNewsletterTemplates
					industry={industry}
					styles={[styleSheet.htmlNewsletterTemplates, styleSheet.cards]}
				/>
			</React.Suspense>
		);
	}
	return null;
};

export const rowizeCards = (cards: ITemplateCard[]): ITemplateCard[][] => {
	if (!cards?.length) {
		return [];
	}
	const hideDefaultMessages = localStorage.getItem(HiddenDefaultMessageTemplateKey);
	const copy = JSON.parse(JSON.stringify(cards.filter(x => !hideDefaultMessages?.includes(x.id))));
	const rows: ITemplateCard[][] = new Array(Math.ceil(copy.length / 3)).fill(undefined)?.map(() => copy.splice(0, 3));
	return rows;
};

export const SectionCards = ({
	category,
	title,
	templates,
	industry,
	button,
	onViewCampaign,
	onDeleteClick,
	onShareClick,
	areAccountTemplates,
}: {
	category?: KnownCategories;
	title: string;
	templates?: ITemplateCard[];
	industry: Api.Industry;
	button: React.ReactNode;
	onViewCampaign: (templateId: string, socialIndicator?: boolean) => void;
	onDeleteClick: (templateCard: ITemplateCard) => void;
	onShareClick: (templateCard: ITemplateCard) => (isShared: boolean) => void;
	areAccountTemplates: boolean;
}) => {
	return (
		<div className={css(styleSheet.cardSection)}>
			{title ? <h2 className={css(styleSheet.categoryTitle)}>{title}</h2> : null}
			{rowizeCards(templates ?? [])?.map((r, rowIdx) => {
				const key = r
					.map(x => x.id)
					.filter(x => !!x)
					.join('-');
				return (
					<div className={css(styleSheet.cardSectionRow)} key={key}>
						{r.map((c, templateIndex) => {
							return (
								<CampaignTemplateCard
									category={category}
									card={c}
									cardSize={CardSize.Large}
									industry={industry}
									isAccountTemplate={areAccountTemplates}
									isFeatured={title.includes('Featured')}
									isFinraReviewed={title.includes('FINRA Reviewed')}
									key={c.id}
									onCtaClicked={() => onViewCampaign(c.id)}
									onDelete={() => onDeleteClick(c)}
									onShare={onShareClick(c)}
									onSocialClicked={() => onViewCampaign(c.id, true)}
									rowIndex={rowIdx}
									styles={[styleSheet.templateCard]}
									templateIndex={templateIndex}
								/>
							);
						})}
					</div>
				);
			})}
			{button}
		</div>
	);
};

export const CampaignBrowserTabTrigger = ({
	type,
	selectedTab,
	isNew,
	color,
}: {
	type: keyof typeof TabValues;
	selectedTab: keyof typeof TabValues;
	isNew: boolean;
	color: string;
}) => {
	return (
		<Tabs.Trigger
			value={type}
			className={`tab-view-tabbar-item ${selectedTab === type ? `tabbar-item-selected` : ''} ${css(
				styleSheet.tabViewItem,
				selectedTab === type && styleSheet.tabViewSelectedItem
			)}`}
		>
			{isNew ? <span className={css(styleSheet.newTabIndicator)}>New!</span> : null}
			{type}

			<div className={css(styleSheet.tabFlag, selectedTab === type ? styleSheet.tabFlagSelected : null)}>
				<SocialMediaIndicatorIcon width={12} height={12} className='tab-view-tabbar-social-icon' fillColor={color} />
			</div>
		</Tabs.Trigger>
	);
};

export const CampaignBrowserTabContent = ({
	type,
	selectedTab,
	content,
}: {
	type: keyof typeof TabValues;
	selectedTab: keyof typeof TabValues;
	content: React.ReactNode;
}) => {
	return (
		<Tabs.Content
			key={`tab-content-${type}`}
			value={type}
			className={`tab-view-content ${css(selectedTab === type && styleSheet.tabViewContent)}`}
		>
			{content}
		</Tabs.Content>
	);
};
