import { titles } from '../../../styles/colors';
import { CssSelectors, Layouts } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		...Layouts.verticalStack(20),
		margin: '20px auto 0 auto',
		paddingBottom: 10,
		width: 440,
	},
	listItem: {
		...Layouts.horizontalStack(20),
		alignItems: 'flex-start',
		marginBottom: 0,
		wordBreak: 'break-word',
		...CssSelectors.allChildren(
			{
				marginBottom: 0,
				marginTop: 6,
				minWidth: 11,
			},
			':first-child'
		),
	},
	previewContactToken: {
		maxWidth: 100,
	},
	title: {
		color: titles,
		fontSize: 24,
		marginTop: 30,
	},
});
