import { borderColor, inputBorderColor, white } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		alignItems: 'center',
		display: 'flex',
	},
	inputField: {
		fontSize: 14,
		padding: 4,
	},
	list: {
		background: white,
		border: `1px solid ${inputBorderColor}`,
		boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 4px 0px',
		marginLeft: 5,
		marginTop: 1,
		maxHeight: 200,
		overflowY: 'auto',
		width: 165,
	},
	listHidden: {
		display: 'none',
	},
	listItem: {
		cursor: 'pointer',
		fontSize: 14,
		padding: 10,
	},
	listItemHighlighted: {
		backgroundColor: borderColor,
	},
	listItemSelected: {
		backgroundColor: borderColor,
	},
	saveButton: {
		fontSize: 14,
		marginLeft: 5,
		minWidth: 50,
		padding: 5,
	},
});
