import { CompanySearchRequestProperty, FilterOperator, IFilterCriteria } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import equal from 'fast-deep-equal';
import produce from 'immer';
import * as React from 'react';
import { AssigneeIcon } from '../../../../components/svgs/icons/AssigneeIcon';
import { SearchIcon } from '../../../../components/svgs/icons/SearchIcon';
import { TagIcon } from '../../../../components/svgs/icons/TagIcon';
import { brandPrimary, header } from '../../../../styles/colors';
import { baseStyleSheet } from '../../../../styles/styles';
import {
	CompaniesTab,
	DefaultCompaniesFilter,
	DefaultCompaniesSort,
	getCompanyCriteriaFromTab,
	getCompanySearchFromCriteria,
	getCompanyTabFromCriteria,
	useCompaniesInput,
	useCompaniesSearch,
} from '../CompaniesContext';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const CompaniesSearchBarTypeTabs: React.FC<IProps> = props => {
	const { className, styles } = props;
	const { load, filter, sort } = useCompaniesSearch();
	const { search } = useCompaniesInput();

	const onTabClick = React.useCallback(
		(newTab: CompaniesTab) => () => {
			const nextFilter = produce(filter, draftFilter => {
				// @ts-ignore
				const newCriteria = draftFilter.criteria.filter(x => {
					return (
						x.property !== CompanySearchRequestProperty.All &&
						x.property !== CompanySearchRequestProperty.Name &&
						x.property !== CompanySearchRequestProperty.Tag
					);
				});

				const criterion: IFilterCriteria<CompanySearchRequestProperty, FilterOperator> = {
					property: getCompanyCriteriaFromTab(newTab),
				};
				if (search) {
					criterion.value = search;
				}

				newCriteria.push(criterion);
				draftFilter.criteria = newCriteria;
			});

			load(nextFilter, sort);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filter, sort]
	);

	const onClear = () => {
		load(DefaultCompaniesFilter, DefaultCompaniesSort);
	};

	// @ts-ignore
	const searched = getCompanySearchFromCriteria(filter.criteria);
	const hasSearched = !equal(DefaultCompaniesFilter, filter);
	// @ts-ignore
	const tab = getCompanyTabFromCriteria(filter.criteria);

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
			<div className={css(styleSheet.tabContainer, !searched && baseStyleSheet.hidden)}>
				<FakeTab onClick={onTabClick} tab='All' selectedTab={tab}>
					<SearchIcon className={css(styleSheet.tabIcon)} fillColor={tab === 'All' ? brandPrimary : header} />
				</FakeTab>
				<FakeTab onClick={onTabClick} tab='Name' selectedTab={tab}>
					<AssigneeIcon
						className={css(styleSheet.tabIcon)}
						fillColor={tab === 'Name' ? brandPrimary : header}
						strokeWidth='1.5'
					/>
				</FakeTab>
				<FakeTab onClick={onTabClick} tab='Tag' selectedTab={tab}>
					<TagIcon className={css(styleSheet.tabIcon)} fillColor={tab === 'Tag' ? brandPrimary : header} />
				</FakeTab>
			</div>
			{hasSearched && (
				<div className={css(baseStyleSheet.brandLink, styleSheet.clear)} onClick={onClear}>
					Clear search
				</div>
			)}
		</div>
	);
};

interface ITabProps {
	onClick(newTab: CompaniesTab): () => void;
	tab: CompaniesTab;
	selectedTab: CompaniesTab;
}

const FakeTab: React.FC<ITabProps> = ({ onClick, children, tab, selectedTab }) => {
	return (
		<div className={css(styleSheet.tab, tab === selectedTab && styleSheet.tabSelected)} onClick={onClick(tab)}>
			{children}
			<span className={css(styleSheet.tabText, tab === selectedTab && styleSheet.tabTextSelected)}>{tab}</span>
		</div>
	);
};
