import { css } from 'aphrodite';
import * as React from 'react';
import { useTransition } from 'react-spring-legacy';
import Waypoint from 'react-waypoint';
import { ClassifyContactViewModel } from '../../../../extViewmodels';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { bs } from '../../../styles/styles';
import { ContactCard } from './ContactCard';

type Props = {
	unclassifiedContacts: ClassifyContactViewModel[];
	setUnclassifiedContacts: React.Dispatch<React.SetStateAction<ClassifyContactViewModel[]>>;
	allUnclassifiedFetched: boolean;
	fetchingMoreLoading: boolean;
	fetchMore: () => void;
};

export const ContactCardContainer = ({
	unclassifiedContacts,
	setUnclassifiedContacts,
	allUnclassifiedFetched,
	fetchingMoreLoading,
	fetchMore,
}: Props) => {
	const removeContactFromList = React.useCallback(
		(unclassifiedContactId: string) => {
			setUnclassifiedContacts(contacts => contacts.filter(contact => contact.id !== unclassifiedContactId));
		},
		[setUnclassifiedContacts]
	);
	const transitions = useTransition(unclassifiedContacts, contact => contact.id, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	});

	return (
		<div
			data-testid='contacts-scrolling-container'
			className={css(bs.flex, bs.itemsCenter, bs.justifyStart, bs.flexCol, bs.gap3, bs.hFull, bs.overflowYAuto, bs.mb2)}
		>
			{transitions.map(({ key, item: unclassified, props: style }) => {
				const onXClick = () => removeContactFromList(unclassified.id);
				return (
					<ContactCard
						key={key}
						animation={style}
						onXClick={onXClick}
						contact={unclassified.contact}
						recentEmailSubjects={unclassified.recentEmailSubjects}
						possibleTags={unclassified.suggestedTags}
					/>
				);
			})}
			{!allUnclassifiedFetched && <Waypoint bottomOffset='-200px' onEnter={fetchMore} />}
			{fetchingMoreLoading && <LoadingSpinner />}
		</div>
	);
};
