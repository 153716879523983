import * as React from 'react';
import { useContactsQuery } from '../../../../queries';

type Actions =
	| { type: 'removeRecipient'; value: string }
	| { type: 'addRecipient'; value: string }
	| { type: 'toggleConsentChecked' };

interface ITextingCampaignContactsPageState {
	contactIds: string[];
	consentChecked: boolean;
}

const reducer = (state: ITextingCampaignContactsPageState, action: Actions): ITextingCampaignContactsPageState => {
	switch (action.type) {
		case 'removeRecipient': {
			const contactId = action.value;
			return {
				...state,
				contactIds: state.contactIds.filter(x => x !== contactId),
			};
		}
		case 'addRecipient': {
			const contactId = action.value;
			return {
				...state,
				contactIds: state.contactIds.indexOf(contactId) === -1 ? state.contactIds.concat(contactId) : state.contactIds,
			};
		}
		case 'toggleConsentChecked': {
			return {
				...state,
				consentChecked: !state.consentChecked,
			};
		}
		default:
			throw new Error('Invalid action type');
	}
};

export const useTextingCampaignContacts = ({
	initialContactIds = [],
}: {
	initialContactIds?: string[];
} = {}) => {
	const [state, dispatch] = React.useReducer(reducer, {
		contactIds: initialContactIds,
		consentChecked: false,
	});
	const contactQueries = useContactsQuery({
		contactIds: state.contactIds,
	});
	const numberOfRecipients = contactQueries.filter(contactQuery => {
		if (contactQuery.data == null) {
			return false;
		}
		return contactQuery.data.canReceiveText;
	}).length;

	return {
		state,
		dispatch,
		contactQueries,
		numberOfRecipients,
	};
};
