import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import mycaseLogo from '../../../../assets/logo_mycase_sm.png';
import { white } from '../../../../styles/colors';
import { baseStyleSheet } from '../../../../styles/styles';
import { MyCaseIntegrationGraphic } from '../../../svgs/graphics/MyCaseIntegrationGraphic';
import { IntegrationCard, useIntegrationCard } from '../IntegrationCard';
import { styleSheet } from './styles';

export const DashboardMyCaseIntegrationCard = () => {
	const { getLogoImgProps, getCloseButtonProps, getCtaButtonProps, getIntegrationTitleProps, showCard } =
		useIntegrationCard({
			closeIntegrationEventName: 'MyCaseIntegrationClosed',
			enableUrlPath: '/integrations/mycase',
			integrationEnableEventName: 'MyCaseIntegrationEnableClick',
			pendingAction: Api.PendingActions.ConnectMyCase,
		});

	if (!showCard) {
		return null;
	}

	return (
		<IntegrationCard
			closeButtonProps={getCloseButtonProps()}
			IntegrationGraphic={MyCaseIntegrationGraphic}
			headerLabel='MyCase Integration'
			logoBgColor={white}
			logoImgProps={getLogoImgProps({
				alt: 'MyCase Logo',
				className: css(styleSheet.logo),
				src: mycaseLogo,
			})}
			ctaButtonProps={getCtaButtonProps({
				children: 'Connect with MyCase',
				styleDeclaration: [baseStyleSheet.ctaButtonReverse, styleSheet.ctaButton],
			})}
			integrationTitleProps={getIntegrationTitleProps({
				styleDeclaration: styleSheet.integrationTitle,
			})}
		>
			<p>
				We are excited to announce that you can integrate your MyCase account with Levitate. With this integration, we
				will have the ability to pull client information, including names, email, phone number, birthdays,
				anniversaries, and more &mdash; making staying in touch even easier. We can also push our notes into MyCase.
			</p>
		</IntegrationCard>
	);
};
