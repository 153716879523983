import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { StyleSheet, css } from 'aphrodite';
import * as React from 'react';

const styleSheet = StyleSheet.create({
	loader: {
		marginTop: 60,
	},
});

export const ContactsTableLoader: React.FC = () => {
	return (
		<div className={css(styleSheet.loader)}>
			<LoadingSpinner type='large' />
		</div>
	);
};
