import { StyleDeclarationValue, css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import ReactModal from 'react-modal';
import { IFabMenuItem } from '../../../models';
import { FabViewModelKey, IFabComponentProps } from '../../../models/AppState';
import { SvgIcon } from '../svgs/icons/SvgIcon';
import { styleSheet } from './styles';

interface IProps extends IFabComponentProps {
	className?: string;
	menuItemClassName?: string;
	menuItems?: IFabMenuItem[];
	onClick?(): void;
	onClose?(): void;
	onMenuItemClick?(menuItem: IFabMenuItem, e: React.MouseEvent<HTMLElement>): void;
	onOpen?(): void;
	tooltip?: string;
	tooltipClassName?: string;
}

interface IState {
	hidden?: boolean;
	isOpen?: boolean;
	isHoveringOver?: boolean;
	isHoveringOverTrigger?: boolean;
	/** Index corresponding to the menu item with :hover. Defaults to -1. */
	menuItemHoverIndex?: number;
}

/** Add note floating action button */
class _Fab extends React.PureComponent<IProps, IState> {
	// @ts-ignore
	private mMounted: boolean;
	private static DefaultClassNames: ReactModal.Classes = {
		afterOpen: '',
		base: css(styleSheet.modal),
		beforeClose: '',
	};

	public readonly state: IState = {
		hidden: true,
		isHoveringOverTrigger: false,
		menuItemHoverIndex: -1,
	};

	public componentDidMount() {
		this.mMounted = true;
		// delay for one animation transition so we don't see the menu closing on load
		setTimeout(() => {
			if (this.mMounted) {
				this.setState({
					hidden: false,
				});
			}
		}, 300);
	}

	public componentWillUnmount() {
		this.mMounted = false;
	}

	public render() {
		const { className, menuItems } = this.props;
		const { isOpen, isHoveringOverTrigger } = this.state;
		const effectiveClassNames: ReactModal.Classes = className
			? {
					afterOpen: `${_Fab.DefaultClassNames.base} ${className}`,
					base: `${_Fab.DefaultClassNames.base} ${className}`,
					beforeClose: `${_Fab.DefaultClassNames.base} ${className}`,
				}
			: _Fab.DefaultClassNames;
		const fabStyles = [
			styleSheet.addNoteloatingActionButton,
			!!isHoveringOverTrigger || !!isOpen ? styleSheet.addNoteloatingActionButtonOpen : null,
		];
		return (
			<ReactModal
				// @ts-ignore
				bodyOpenClassName={null}
				className={effectiveClassNames}
				isOpen={true}
				overlayClassName={_Fab.DefaultClassNames}
				portalClassName={`fab-modal ${css(styleSheet.modal, styleSheet.modalPortal)}`}
				shouldCloseOnOverlayClick={false}
				shouldCloseOnEsc={false}
			>
				<div
					className={`add-note-fab ${css(fabStyles, ...this.contextStyles)}`}
					onMouseEnter={this.onHoverChanged(true)}
					onMouseLeave={this.onHoverChanged(false)}
					style={this.isHidden ? { opacity: 0, pointerEvents: 'none' } : undefined}
				>
					<ul className={css(styleSheet.menu, isOpen ? styleSheet.menuOpen : null)}>
						{!!menuItems && this.renderMenuItemElements()}
					</ul>
					<div className={css(styleSheet.triggerWithTooltip)}>{this.renderTrigger()}</div>
				</div>
			</ReactModal>
		);
	}

	/** Uses the tooltip position props value to set the correct placement of the trigger button and the tooltip text */
	private renderTrigger() {
		const { isOpen } = this.state;

		const button = (
			<button
				className={css(styleSheet.trigger, this.state.isOpen ? styleSheet.rotate : null)}
				key='fab-trigger-button'
				onMouseEnter={this.onTriggerButtonHoverChanged(true)}
				onMouseLeave={this.onTriggerButtonHoverChanged(false)}
				onClick={this.props.onClick}
				style={this.isHidden ? { opacity: 0, pointerEvents: 'none' } : undefined}
			>
				<span className={css(styleSheet.triggerImage, this.state.isOpen ? styleSheet.rotate : null)}>
					<SvgIcon height={22} width={22}>
						<g fill='none' fillRule='evenodd'>
							{isOpen ? (
								<g stroke='#FFF' strokeWidth='2' transform='translate(3 1)'>
									<polygon points='9.032 0 12.316 3.537 3.284 13.263 0 13.263 0 9.726' />
									<path d='M-1.15463195e-14,17.0526316 L15.1578947,17.0526316' />
								</g>
							) : (
								<path
									d='M8.7158302,0 C8.1626925,0 7.7142857,0.4480965 7.7142857,1.0085081 L7.7142857,7.7142857 L1.0085081,7.7142857 C0.4515245,7.7142857 -3.55271368e-15,8.1621944 -3.55271368e-15,8.7158302 L-3.55271368e-15,9.2841698 C-3.55271368e-15,9.8373075 0.4480965,10.2857143 1.0085081,10.2857143 L7.7142857,10.2857143 L7.7142857,16.9914919 C7.7142857,17.5484755 8.1621944,18 8.7158302,18 L9.2841698,18 C9.8373075,18 10.2857143,17.5519035 10.2857143,16.9914919 L10.2857143,10.2857143 L16.9914919,10.2857143 C17.5484755,10.2857143 18,9.8378056 18,9.2841698 L18,8.7158302 C18,8.1626925 17.5519035,7.7142857 16.9914919,7.7142857 L10.2857143,7.7142857 L10.2857143,1.0085081 C10.2857143,0.4515245 9.8378056,0 9.2841698,0 L8.7158302,0 Z'
									fill='#FFF'
									transform='translate(1 2)'
								/>
							)}
							<rect width='22' height='22' />
						</g>
					</SvgIcon>
				</span>
			</button>
		);

		if (!this.props.tooltip) {
			return button;
		} else {
			const tooltip = (
				<div
					className={css(styleSheet.tooltip, this.state.isHoveringOverTrigger ? styleSheet.showTooltip : null)}
					key='fab-trigger-tooltip'
				>
					{this.props.tooltip}
				</div>
			);

			return [tooltip, button];
		}
	}

	@computed
	private get isHidden() {
		const { fab } = this.props;
		const { hidden } = this.state;
		// @ts-ignore
		return !!hidden || (fab.contextCollection || []).some(x => !!x.appearance && !!x.appearance.hidden);
	}

	@computed
	private get contextStyles() {
		const { fab } = this.props;
		// @ts-ignore
		return (fab.contextCollection || []).reduce<StyleDeclarationValue[]>((result, x) => {
			if (!!x && !!x.appearance && !!x.appearance.styles) {
				result = [...result, ...x.appearance.styles];
			}
			return result;
		}, []);
	}

	@computed
	private get menuItems() {
		const { fab, menuItems } = this.props;
		// @ts-ignore
		const contextMenuItems = fab.contextCollection
			.reduce<IFabMenuItem[]>((result, x) => {
				if (!!x.menuItems && x.menuItems.length > 0) {
					return [...result, ...x.menuItems];
				}
				return result;
			}, [])
			// @ts-ignore
			// @ts-ignore
			// @ts-ignore
			// @ts-ignore
			.sort((x, y) => (x.order > y.order ? 1 : x.order < y.order ? -1 : 0));
		return [...contextMenuItems, ...(menuItems || [])];
	}

	/** Changes a class on the trigger tooltip to show/hide it based on hover of the trigger button */
	private onTriggerButtonHoverChanged = (hoverOn: boolean) => () => {
		const nextState: IState = {
			isHoveringOverTrigger: hoverOn,
		};

		if (hoverOn && !this.state.isOpen) {
			nextState.isOpen = true;
		}

		this.setState(nextState);
	};

	private onHoverChanged = (hoverOn: boolean) => () => {
		const nextState: IState = {
			isHoveringOver: hoverOn,
		};

		if (!hoverOn) {
			nextState.isOpen = false;
		}

		this.setState(nextState);
	};

	private renderMenuItemElements() {
		const elements = this.menuItems.map((menuItem, i) => {
			return (
				<li
					onMouseEnter={this.onMenuItemHoverChanged(true, i)}
					onMouseLeave={this.onMenuItemHoverChanged(false, i)}
					key={i}
					className={`fab-menu-item ${this.props.menuItemClassName || ''} ${css(
						styleSheet.menuItem,
						this.state.isOpen ? styleSheet.showMenuItem : null
					)}`}
					onClick={this.onMenuItemClick(menuItem)}
				>
					{menuItem.icon}
					{menuItem.tooltip && (
						<div
							className={css(styleSheet.tooltip, this.state.menuItemHoverIndex === i ? styleSheet.showTooltip : null)}
							key='fab-menu-item-tooltip'
						>
							{menuItem.tooltip}
						</div>
					)}
				</li>
			);
		});
		return elements;
	}

	private onMenuItemHoverChanged = (hoverOn: boolean, index: number) => () => {
		this.setState({
			menuItemHoverIndex: hoverOn ? index : -1,
		});
	};

	private onMenuItemClick = (menuItem: IFabMenuItem) => (e: React.MouseEvent<HTMLElement>) => {
		const { onMenuItemClick, fab } = this.props;
		if (onMenuItemClick) {
			onMenuItemClick(menuItem, e);
		}

		if (!e.defaultPrevented) {
			// @ts-ignore
			(fab.contextCollection || []).forEach(x => {
				if (!e.defaultPrevented && !!x.menuItems && x.menuItems.indexOf(menuItem) >= 0 && !!x.onMenuItemSelected) {
					x.onMenuItemSelected(menuItem, e);
				}
			});
		}

		this.setState({
			isOpen: false,
		});
	};
}

const FabAsObserver = observer(_Fab);
export const Fab = inject(FabViewModelKey)(FabAsObserver);
