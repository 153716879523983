import { StyleSheet } from 'aphrodite';
import { borderColor } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	content: {
		minHeight: 200,
	},
	dropdown: {
		width: 300,
	},
	oppsLoadingSpinner: {
		marginTop: '10%',
	},
	richContentFilterOptionsContainer: {
		borderBottom: `1px solid ${borderColor}`,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		padding: '15px 0',
	},

	tabView: {
		...CssSelectors.allDescendants(
			{
				overflow: 'unset',
			},
			'.scroll-view-content'
		),
	},
	timelineFilterOptionsContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		paddingTop: 15,
		...CssSelectors.allChildren({
			borderRadius: 30,
		}),
	},
});
