import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { useHistory, useLocation } from 'react-router';
import { useErrorMessages, useFullscreenModal } from '../../../models/hooks/appStateHooks';
import { useAutomationTemplateTextingCampaignMutation } from '../../../queries';
import { FabContext } from '../../components/FabContext';
import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { bs } from '../../styles/styles';
import { TextingCampaignScheduler } from './TextingCampaignScheduler';

export function TextCampaignScheduleSendPage() {
	const fullscreenModal = useFullscreenModal();
	const location = useLocation<{
		request: Omit<Api.CreateTextCampaignAutomationRequest, 'scheduledStartDate'>;
		files?: File[];
	}>();
	const history = useHistory();
	const errorMessages = useErrorMessages();
	const automationTemplateTextingCampaignMutation = useAutomationTemplateTextingCampaignMutation({
		onSuccess: () => {
			history.push('/reporting/texting');
		},
		onError: error => {
			errorMessages.pushApiError(error);
		},
	});
	const handleSchedule = (scheduleDate: Date) => {
		automationTemplateTextingCampaignMutation.mutate({
			request: {
				...location.state.request,
				scheduledStartDate: scheduleDate.toISOString(),
			},
			files: location.state.files,
		});
	};
	const handleSendNow = () => {
		automationTemplateTextingCampaignMutation.mutate({
			request: location.state.request,
			files: location.state.files,
		});
	};
	return (
		<>
			<FabContext appearance={{ hidden: true }} />
			<MultiContainerHeader
				fullscreenHeader='Send Options'
				onFullscreenRequestClose={ev => {
					ev.preventDefault();
					fullscreenModal.dismissModal();
				}}
			/>
			<div className={css(bs.mxAuto, bs.maxW3Xl, bs.pt16, bs.pb7)}>
				<div
					className={css(
						bs.itemsCenter,
						bs.boxBorder,
						bs.flexCol,
						bs.flex,
						bs.px2,
						bs.borderGray300,
						bs.border0,
						bs.borderB,
						bs.borderSolid
					)}
				>
					<h3 className={css(bs.textTitles, bs.textLg, bs.m0, bs.mb4)}>When do you want to send this text campaign?</h3>
					<p className={css(bs.textLabel, bs.textSm, bs.m0)}>Send it now or choose a future date</p>
				</div>
				<TextingCampaignScheduler
					sendFrom={location.state.request.sendFromOptions.mode}
					onSendNowClick={handleSendNow}
					onScheduleClick={handleSchedule}
					isSubmitting={automationTemplateTextingCampaignMutation.isLoading}
				/>
			</div>
		</>
	);
}
