import { hotPink } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';

export const TargetIcon = ({
	className,
	fillColor = hotPink,
}: {
	className?: string;
	fillColor?: string;
}) => {
	return (
		<SvgIcon className={className || ''} height={22} width={22}>
			<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
				<g transform='translate(-889, -48)' fill={fillColor}>
					<g transform='translate(889, 48)'>
						<path d='M12,16.9097 L12,12.9997 L10,12.9997 L10,16.9097 C7.493,16.4867 5.514,14.5077 5.09,11.9997 L9,11.9997 L9,9.9997 L5.09,9.9997 C5.514,7.4927 7.493,5.5137 10,5.0907 L10,8.9997 L12,8.9997 L12,5.0907 C14.507,5.5137 16.486,7.4927 16.91,9.9997 L13,9.9997 L13,11.9997 L16.91,11.9997 C16.486,14.5077 14.507,16.4867 12,16.9097 L12,16.9097 Z M22,9.9997 L18.931,9.9997 C18.477,6.3887 15.611,3.5227 12,3.0697 L12,-0.000300000001 L10,-0.000300000001 L10,3.0697 C6.389,3.5227 3.523,6.3887 3.069,9.9997 L0,9.9997 L0,11.9997 L3.069,11.9997 C3.523,15.6117 6.389,18.4777 10,18.9307 L10,21.9997 L12,21.9997 L12,18.9307 C15.611,18.4777 18.477,15.6117 18.931,11.9997 L22,11.9997 L22,9.9997 Z' />
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
