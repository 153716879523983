import { DoubleArrowIcon } from '../../svgs/icons/DoubleArrowIcon';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	icon: React.ReactNode;
	iconBgColor?: string;
	children: React.ReactNode;
	subtext: React.ReactNode;
	onClick: () => void;
}

export function DashboardDailyUpdateItem({ icon, iconBgColor, children, subtext, onClick }: IProps) {
	return (
		<div className={css(styleSheet.dailyUpdateItemRoot)} onClick={onClick}>
			<div className={css(styleSheet.dailyUpdateItemContent)}>
				<figure className={css(styleSheet.dailyUpdateItemIconContainer)} style={{ background: iconBgColor }}>
					{icon}
				</figure>
				<div className={css(styleSheet.dailyUpdateItemHGroup)}>
					<h4 className={css(styleSheet.dailyUpdateItemTitle)}>{children}</h4>
					<p className={css(styleSheet.dailyUpdateItemSubtitle)}>{subtext}</p>
				</div>
			</div>
			<DoubleArrowIcon fillColor='#D3DBDE' />
		</div>
	);
}
