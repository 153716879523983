import { borderColor, titles } from '../../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		// height hack for IE11 center
		height: 50,
		minHeight: 52,
		whiteSpace: 'nowrap',
	},
	extraTags: {
		background: 'white',
		border: `1px solid ${borderColor}`,
		borderRadius: 3,
	},
	extraTagsItem: {
		':not(:last-of-type)': {
			borderBottom: `1px solid ${borderColor}`,
		},
		alignItems: 'center',
		display: 'flex',
		fontSize: 12,
		padding: '3px 10px',
	},
	filterPill: {
		':nth-child(1n)': {
			margin: 4,
		},
	},
	moreTags: {
		':hover': {
			textDecoration: 'none',
		},
	},
	tag: {
		alignItems: 'center',
		display: 'flex',
	},
	tagTokenOpPrefix: {
		color: titles,
	},
});
