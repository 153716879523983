import { css } from 'aphrodite';
import { produce } from 'immer';
import { inject } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { IRange, ISatisfactionSurvey } from '../../../extViewmodels';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../models';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { Checkbox } from '../../components/Checkbox';
import { DateRangePickerModal } from '../../components/DateRangePicker';
import { RadioButton } from '../../components/RadioButton';
import { TextArea } from '../../components/TextArea';
import { TextInputFormField } from '../../components/TextInputFormField';
import { Toggle } from '../../components/Toggle';
import { StarRating } from '../../components/surveys/StarRating';
import { grayIconFill, success } from '../../styles/colors';
import { baseStyleSheet } from '../../styles/styles';
import { useEditSurveyContext } from './context';
import { styleSheet } from './styles';

enum BasicInfoInputIds {
	CompanyName = 'edit-survey-company-name-input',
	IndefDateRange = 'edit-survey-indef-date-range-input',
	Intro = 'edit-survey-intro-input',
	Name = 'edit-survey-name-input',
	SpecificDateRange = 'edit-survey-specific-date-range-input',
}

export const BasicSurveyInfo: React.FC = () => {
	const { survey, setSurvey } = useEditSurveyContext<ISatisfactionSurvey>();
	const [surveyName, setSurveyName] = React.useState<string>(survey?.name || '');
	const [companyName, setCompanyName] = React.useState<string>(survey?.companyDisplayName || '');
	const [intro, setIntro] = React.useState<string>(survey?.intro || '');
	const isArchived = !!survey?.archivedDate;

	// #region Date range modal
	const [dateRangeModalIsOpen, setDateRangeModalIsOpen] = React.useState<boolean>(false);
	const onDateRangeModalRequestClose = React.useCallback(
		(result?: IRange<Date>, cancel?: boolean) => {
			if (!cancel && result) {
				const nextSurvey = produce(survey, draftSurvey => {
					// @ts-ignore
					draftSurvey.startDate = result.start.toISOString();
					// @ts-ignore
					draftSurvey.expirationDate = result.end.toISOString();
				});
				setSurvey(nextSurvey);
			}
			setDateRangeModalIsOpen(false);
		},
		[survey, setSurvey]
	);
	const onEditDateRangeClicked = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
		setDateRangeModalIsOpen(true);
		e.stopPropagation();
		e.preventDefault();
	}, []);
	// #endregion

	// #region Input callbacks
	const onInputBlur = React.useCallback(() => {
		const nextSurvey = produce(survey, draftSurvey => {
			draftSurvey.companyDisplayName = companyName?.trim() ?? null;
			draftSurvey.name = surveyName?.trim() ?? '';
			draftSurvey.intro = intro?.trim() ?? '';
		});
		unstable_batchedUpdates(() => {
			// @ts-ignore
			setSurveyName(nextSurvey.name);
			// @ts-ignore
			setCompanyName(nextSurvey.companyDisplayName);
			// @ts-ignore
			setIntro(nextSurvey.intro);
			setSurvey(nextSurvey);
		});
	}, [surveyName, companyName, survey, setSurvey, intro]);

	const onInputChanged = React.useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		switch (e.target.id) {
			case BasicInfoInputIds.Name: {
				setSurveyName(e.target.value);
				break;
			}
			case BasicInfoInputIds.CompanyName: {
				setCompanyName(e.target.value);
				break;
			}
			case BasicInfoInputIds.Intro: {
				setIntro(e.target.value);
				break;
			}
			default: {
				break;
			}
		}
	}, []);

	const onDateRangeRadioButtonChanged = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.target.id === BasicInfoInputIds.IndefDateRange && e.target.checked) {
				const nextSurvey = produce(survey, draftSurvey => {
					draftSurvey.startDate = moment().toISOString();
					// @ts-ignore
					draftSurvey.expirationDate = null;
				});
				setSurvey(nextSurvey);
			}

			if (e.target.id === BasicInfoInputIds.SpecificDateRange && e.target.checked) {
				setDateRangeModalIsOpen(true);
			}
		},
		[setSurvey, survey]
	);
	// #endregion

	const hasDateRange = !!survey?.startDate && !!survey?.expirationDate;
	return (
		<div className={css(styleSheet.section)}>
			<div className={css(styleSheet.sectionTitle)}>Basic Survey Info</div>
			<div className={css(styleSheet.fieldGroup)}>
				<TextInputFormField
					disabled={isArchived}
					inputId={BasicInfoInputIds.Name}
					label={
						<span>
							Survey Name
							<span className={css(baseStyleSheet.required)}>*</span>
						</span>
					}
					labelStyles={[styleSheet.label]}
					onBlur={onInputBlur}
					onChange={onInputChanged}
					type='text'
					value={surveyName || ''}
				/>
				<TextInputFormField
					disabled={isArchived}
					inputId={BasicInfoInputIds.CompanyName}
					label='Company Name'
					labelStyles={[styleSheet.label]}
					onBlur={onInputBlur}
					onChange={onInputChanged}
					type='text'
					value={companyName || ''}
				/>
			</div>
			<div className={css(styleSheet.fieldGroup)}>
				<div className={css(styleSheet.label)}>
					<span>
						Survey Intro
						<span className={css(baseStyleSheet.required)}>*</span>
					</span>
				</div>
				<TextArea
					disabled={isArchived}
					inputClassName={css(styleSheet.introTextArea)}
					inputId={BasicInfoInputIds.Intro}
					onBlur={onInputBlur}
					onChange={onInputChanged}
					value={intro || ''}
				/>
			</div>
			<div className={css(styleSheet.fieldGroup)}>
				<div className={css(styleSheet.label)}>Survey Date Range</div>
				<div className={css(styleSheet.radioButtons)}>
					<RadioButton
						// @ts-ignore
						checked={survey?.startDate && !survey?.expirationDate}
						disabled={isArchived}
						id={BasicInfoInputIds.IndefDateRange}
						onChange={onDateRangeRadioButtonChanged}
					>
						<span>Survey is available indefinitely into the future</span>
					</RadioButton>
					<RadioButton
						checked={hasDateRange}
						disabled={isArchived}
						id={BasicInfoInputIds.SpecificDateRange}
						onChange={onDateRangeRadioButtonChanged}
					>
						<span>
							{`Survey is only available for a specific date range${hasDateRange ? ':' : ''}`}
							&nbsp;
						</span>
						{hasDateRange && (
							<button className={css(baseStyleSheet.brandLink)} disabled={isArchived} onClick={onEditDateRangeClicked}>
								<span>
									{`${moment(survey.startDate).format('MM/DD/yyyy')} - ${moment(survey.expirationDate).format(
										'MM/DD/yyyy'
									)}`}
								</span>
							</button>
						)}
					</RadioButton>
				</div>
				<DateRangePickerModal
					modalProps={{
						isOpen: dateRangeModalIsOpen,
						onRequestClose: onDateRangeModalRequestClose,
					}}
					range={
						hasDateRange
							? {
									// @ts-ignore
									end: new Date(survey.expirationDate),
									// @ts-ignore
									start: new Date(survey.startDate),
								}
							: undefined
					}
				/>
			</div>
		</div>
	);
};

enum ReviewRequestInputIds {
	Link = 'review-request-link-input',
	LinkType = 'review-request-link-type-input',
	Message = 'review-request-message-input',
}

const KnownReviewSites = {
	'Better Business Bureau': [/\.(bbb)\./gi],
	G2: [/\.?(g2)\./gi],
	Google: [/\.?(google)\./gi],
	Trustpilot: [/\.?(trustpilot)\./gi],
	Yelp: [/\.?(yelp)\./gi],
};

const LIMIT = 5;

export const ReviewRequestOptionsBase: React.FC<IImpersonationContextComponentProps> = props => {
	const { survey, setSurvey } = useEditSurveyContext();
	const userSession = useUserSession();

	const satisfactionSurvey = survey as ISatisfactionSurvey;
	const defaultReviewLink = (
		props.impersonationContext?.account?.features?.surveys || userSession.account.features?.surveys
	)?.defaultReviewLink;
	const [linkStringValue, setLinkStringValue] = React.useState<string>(
		satisfactionSurvey?.reviewRequestSettings?.reviewUrl || (!survey?.id ? defaultReviewLink : null) || ''
	);
	const [linkTypeStringValue, setLinkTypeStringValue] = React.useState<string>(
		satisfactionSurvey?.reviewRequestSettings?.reviewType || ''
	);
	const [requestMessage, setRequestMessage] = React.useState<string>(
		satisfactionSurvey?.reviewRequestSettings?.reviewRequest || ''
	);
	const isArchived = !!survey?.archivedDate;

	const onToggleCheckChanged = React.useCallback(
		(checked: boolean) => {
			const nextSurvey = produce(satisfactionSurvey, draftSurvey => {
				// @ts-ignore
				draftSurvey.reviewRequestSettings.isEnabled = checked;
			});
			setSurvey(nextSurvey);
		},
		[satisfactionSurvey, setSurvey]
	);

	const onInputBlur = React.useCallback(() => {
		const nextSurvey = produce(satisfactionSurvey, draftSurvey => {
			// @ts-ignore
			draftSurvey.reviewRequestSettings.reviewRequest = requestMessage?.trim() ?? null;
			// @ts-ignore
			draftSurvey.reviewRequestSettings.reviewUrl = linkStringValue?.trim() ?? null;
			// @ts-ignore
			draftSurvey.reviewRequestSettings.reviewType = linkTypeStringValue?.trim() ?? null;
			// @ts-ignore
			if (draftSurvey.reviewRequestSettings.reviewUrl) {
				// @ts-ignore
				if (!/^http[s]?:\/\//gim.test(draftSurvey.reviewRequestSettings.reviewUrl)) {
					// @ts-ignore
					// @ts-ignore
					draftSurvey.reviewRequestSettings.reviewUrl = `https://${draftSurvey.reviewRequestSettings.reviewUrl}`;
				}
				// @ts-ignore
				if (!draftSurvey.reviewRequestSettings.reviewType) {
					const match = Object.entries(KnownReviewSites).find(entry =>
						// @ts-ignore
						// @ts-ignore
						entry[1].some(x => x.test(draftSurvey.reviewRequestSettings.reviewUrl))
					);
					if (match) {
						// @ts-ignore
						draftSurvey.reviewRequestSettings.reviewType = match[0];
					}
				}
			}
		});
		unstable_batchedUpdates(() => {
			setSurvey(nextSurvey);
			// @ts-ignore
			// @ts-ignore
			setRequestMessage(nextSurvey.reviewRequestSettings.reviewRequest);
			// @ts-ignore
			// @ts-ignore
			setLinkStringValue(nextSurvey.reviewRequestSettings.reviewUrl);
			// @ts-ignore
			// @ts-ignore
			setLinkTypeStringValue(nextSurvey.reviewRequestSettings.reviewType);
		});
	}, [linkStringValue, requestMessage, satisfactionSurvey, setSurvey, linkTypeStringValue]);

	const onInputChanged = React.useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (e.target.id === ReviewRequestInputIds.Link) {
			setLinkStringValue(e.target.value);
		}

		if (e.target.id === ReviewRequestInputIds.LinkType) {
			setLinkTypeStringValue(e.target.value);
		}

		if (e.target.id === ReviewRequestInputIds.Message) {
			setRequestMessage(e.target.value);
		}
	}, []);

	const onStarRatingOptionCheckChanged = React.useCallback(
		(value: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
			const indexOfMatch = satisfactionSurvey?.reviewRequestSettings?.ratings?.findIndex(x => x === value);
			// @ts-ignore
			if (indexOfMatch > -1) {
				if (!e.target.checked) {
					// @ts-ignore
					const nextRatings = (satisfactionSurvey.reviewRequestSettings.ratings || []).slice();
					// @ts-ignore
					nextRatings.splice(indexOfMatch, 1);
					const nextSurvey = produce(satisfactionSurvey, draftSurvey => {
						// @ts-ignore
						draftSurvey.reviewRequestSettings.ratings = nextRatings;
					});
					setSurvey(nextSurvey);
					return;
				}
			} else {
				if (e.target.checked) {
					// @ts-ignore
					const nextRatings = (satisfactionSurvey.reviewRequestSettings.ratings || []).slice();
					nextRatings.push(value);
					const nextSurvey = produce(satisfactionSurvey, draftSurvey => {
						// @ts-ignore
						draftSurvey.reviewRequestSettings.ratings = nextRatings;
					});
					setSurvey(nextSurvey);
					return;
				}
			}
		},
		[satisfactionSurvey, setSurvey]
	);

	if (survey?._type !== 'SatisfactionSurvey') {
		return null;
	}

	return (
		<div className={css(styleSheet.section)}>
			<div className={css(styleSheet.sectionTitle)}>
				<div className={css(styleSheet.layoutFlexRowSpaceBetween)}>
					<span>Ask for a Review</span>
					<Toggle
						checkedColor={success}
						disabled={isArchived}
						id='edit-survey-review-request-toggle'
						isOn={satisfactionSurvey?.reviewRequestSettings?.isEnabled}
						onToggleCheckChanged={onToggleCheckChanged}
						text={satisfactionSurvey?.reviewRequestSettings?.isEnabled ? 'Enabled' : 'Disabled'}
						textStyles={[styleSheet.toggleText]}
						uncheckedColor={grayIconFill}
					/>
				</div>
			</div>
			<div
				className={css(
					baseStyleSheet.verticalStack,
					!satisfactionSurvey?.reviewRequestSettings?.isEnabled ? styleSheet.disabled : null
				)}
			>
				<div className={css(styleSheet.label)}>Ask to leave a review when someone gives you the following ratings:</div>
				<div className={css(styleSheet.fieldGroup)}>
					{Array(LIMIT)
						.fill(null)
						.map((_, i) => {
							const count = LIMIT - i;
							return (
								<Checkbox
									// @ts-ignore
									checked={satisfactionSurvey?.reviewRequestSettings?.ratings?.indexOf(count) > -1}
									disabled={isArchived}
									id={`edit-survey-review-request-${count}stars`}
									key={`edit-survey-review-request-${count}stars`}
									onChange={onStarRatingOptionCheckChanged(count)}
								>
									<div className={css(styleSheet.starRating)}>
										<span>{`${count}/${LIMIT}`}</span>
										<StarRating fillColor='#F3CE0D' readonly={true} total={LIMIT} value={count} />
									</div>
								</Checkbox>
							);
						})}
				</div>
				<div className={css(styleSheet.divider)} />
				<div className={css(styleSheet.fieldGroup)}>
					<div className={css(styleSheet.label)}>Request shown after a positive rating is left:</div>
					<TextArea
						disabled={isArchived}
						inputClassName={css(styleSheet.reviewRequestMessage)}
						inputId={ReviewRequestInputIds.Message}
						onBlur={onInputBlur}
						onChange={onInputChanged}
						value={requestMessage || ''}
					/>
				</div>
				<TextInputFormField
					disabled={isArchived}
					inputId={ReviewRequestInputIds.Link}
					label='Link to the review page (Google, Yelp, etc.)'
					labelStyles={[styleSheet.label]}
					onBlur={onInputBlur}
					onChange={onInputChanged}
					type='text'
					value={linkStringValue || ''}
				/>
				<TextInputFormField
					disabled={isArchived}
					inputId={ReviewRequestInputIds.LinkType}
					label='What type of review is it above?'
					labelStyles={[styleSheet.label]}
					onBlur={onInputBlur}
					onChange={onInputChanged}
					styles={[styleSheet.reviewRequestLinkTypeInput]}
					type='text'
					value={linkTypeStringValue || ''}
				/>
			</div>
		</div>
	);
};

export const ReviewRequestOptions = inject(ImpersonationContextKey)(ReviewRequestOptionsBase);
