import * as React from 'react';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { styleSheet } from '../../SearchComponent/styles';
import { TextInput } from '../../../TextInput';
import {
	useCreateInsurancePolicyLineOfBusinessMutation,
	useInsurancePolicyLineOfBusinessAutocompleteQuery,
} from '../../../../../queries';
import { SearchIcon } from '../../../svgs/icons/SearchIcon';

export const PolicyLineOfBusinessSearch = ({
	onSelectLineOfBusiness,
	canAdd = true,
	onCreateLineOfBusiness,
	initialSearchFragment,
	className,
	dropdownClassName,
	onClear,
}: {
	onSelectLineOfBusiness: (policyLineOfBusiness: Api.IInsurancePolicyLineOfBusiness) => void;
	canAdd?: boolean;
	onCreateLineOfBusiness?: (policyLineOfBusiness: Api.IInsurancePolicyLineOfBusiness) => void;
	initialSearchFragment?: string;
	className?: string;
	dropdownClassName?: string;
	onClear?: () => void;
}) => {
	const [isLineOfBusinessSearchDropdownOpen, setIsLineOfBusinessSearchDropdownOpen] = React.useState(false);
	const [searchFragment, setSearchFragment] = React.useState<string>(initialSearchFragment ?? '');
	const addLineOfBusinessMutation = useCreateInsurancePolicyLineOfBusinessMutation({
		onSuccess: (result: Api.IInsurancePolicyLineOfBusiness) => {
			setSearchFragment(result.name);
			onCreateLineOfBusiness?.(result);
			setIsLineOfBusinessSearchDropdownOpen(false);
		},
	});

	const selectLineOfBusiness = (policyLineOfBusiness: Api.IInsurancePolicyLineOfBusiness) => {
		setSearchFragment(policyLineOfBusiness.name);
		setIsLineOfBusinessSearchDropdownOpen(false);
		onSelectLineOfBusiness(policyLineOfBusiness);
	};

	const updateSearchFragment = (newValue: string) => {
		setSearchFragment(newValue);

		if (newValue) {
			setIsLineOfBusinessSearchDropdownOpen(true);
		} else {
			setIsLineOfBusinessSearchDropdownOpen(false);
			if (onClear) {
				onClear();
			}
		}
	};

	const createNewLineOfBusiness = () => {
		const policyLineOfBusiness: Api.IInsurancePolicyLineOfBusiness = {
			name: searchFragment,
		};
		addLineOfBusinessMutation.mutate({ policyLineOfBusiness });
	};

	const policyLineOfBusinessAutocompleteQuery = useInsurancePolicyLineOfBusinessAutocompleteQuery({
		enabled: !!searchFragment,
		pageSize: 25,
		fragment: searchFragment,
	});

	const policyLineOfBusinesses =
		policyLineOfBusinessAutocompleteQuery.data?.pages.map(page => page.values).flat() ?? [];

	return (
		<div className={`${css(styleSheet.searchInputWrap)} ${className || ''}`}>
			<TextInput
				className={css(isLineOfBusinessSearchDropdownOpen ? styleSheet.popoverOpen : null)}
				inputId='policy-modal-edit-policyLineOfBusiness'
				onChange={e => updateSearchFragment(e.target.value)}
				type='text'
				value={searchFragment}
				placeholder='Search'
				leftAccessory={<SearchIcon className={css(styleSheet.searchIcon)} />}
			/>

			{isLineOfBusinessSearchDropdownOpen ? (
				<div className={`${css(styleSheet.dropdown)} ${dropdownClassName || ''}`}>
					{policyLineOfBusinesses.length === 0 && !policyLineOfBusinessAutocompleteQuery.isLoading ? (
						<i>No matches found</i>
					) : null}

					{policyLineOfBusinesses.map(policyLineOfBusiness => {
						return (
							<button
								onClick={() => selectLineOfBusiness(policyLineOfBusiness)}
								key={`policyLineOfBusiness-${policyLineOfBusiness.id}`}
							>
								{policyLineOfBusiness.name}
							</button>
						);
					})}

					{canAdd && !!searchFragment ? (
						<button className={css(styleSheet.addNewButton)} onClick={createNewLineOfBusiness}>
							<span>+ Add &quot;{searchFragment}&quot;</span>
						</button>
					) : null}
				</div>
			) : null}
		</div>
	);
};
