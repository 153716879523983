import { StyleSheet } from 'aphrodite';
import { brandPrimaryText, titles } from '../../../styles/colors';
import { CssSelectors, truncateTextStyle } from '../../../styles/styles';

const avatarSize = 35;

export const styleSheet = StyleSheet.create({
	avatar: {
		fontSize: 14,
		height: avatarSize,
		width: avatarSize,
	},
	body: {
		alignItems: 'center',
		color: '#8E8E8E',
		display: 'flex',
		fontSize: 12,
		justifyContent: 'space-between',
		marginTop: 12,
		...CssSelectors.allChildren(
			{
				marginLeft: 30,
			},
			':not(:first-child)'
		),
	},
	bodyLeft: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren(
			{
				':not(:first-child)': {
					marginLeft: 15,
				},
			},
			'*'
		),
	},
	bodyRight: {},
	companyName: {
		color: '#8E8E8E',
		fontSize: 12,
	},
	contactName: {
		':active': {
			textDecoration: 'none',
		},
		':focus': {
			textDecoration: 'none',
		},
		':hover': {
			textDecoration: 'underline',
		},
		color: brandPrimaryText,
		textDecoration: 'none',
	},
	container: {},
	header: {
		alignItems: 'center',
		color: titles,
		display: 'flex',
		fontSize: 16,
	},
	headerDescription: {
		marginLeft: 12,
		width: `calc(100% - ${avatarSize + 12}px)`,
	},
	headerDescriptionTitle: {
		alignItems: 'center',
		display: 'flex',
	},
	lastInteraction: {
		alignItems: 'center',
		display: 'flex',
	},
	lastInteractionTime: {
		color: titles,
	},
	tag: {
		maxWidth: 200,
		...truncateTextStyle,
	},
	tagAlerts: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
});
