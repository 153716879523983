import { inputBorderColor } from '../../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	textInputContainer: {
		width: 120,
	},
	textInput: {
		width: '100%',
	},
	textInputWrapper: {
		marginTop: 8,
	},
	amountDropdown: {
		display: 'flex',
		flexDirection: 'column',
		gap: 16,
		padding: 16,
	},
	amountInputs: {
		display: 'flex',
		gap: 8,
	},
	radios: {
		display: 'flex',
		gap: 12,
	},
	separator: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
	},
	dropdownContentInnerOverrides: {
		borderColor: inputBorderColor,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
		borderTopRightRadius: 0,
		maxHeight: 500,
	},
});
