import { brandPrimary, darkGrayFontColor, titles, urgentDate } from '../../../../styles/colors';
import { CssSelectors } from '../../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	amountTitle: {
		marginTop: 32,
	},
	body: {
		boxSizing: 'border-box',
		padding: '20px 0 60px 0',
		position: 'relative',
	},
	bodyContent: {
		':last-child': {
			marginBottom: 20,
		},
		minHeight: 380,
	},
	closingDate: {
		alignItems: 'center',
		background: 'rgb(249,249,249)',
		border: '1px solid #ccc',
		borderRadius: 3,
		boxSizing: 'border-box',
		display: 'flex',
		height: '100%',
		justifyContent: 'space-between',
		minHeight: 40,
		padding: '0 10px',
		width: '100%',
		...CssSelectors.allDescendants(
			{
				fontSize: 14,
			},
			'span'
		),
	},
	closingDateTitle: {
		color: titles,
		fontSize: 14,
		paddingTop: 20,
	},
	company: {
		alignItems: 'center',
		display: 'flex',
		height: '42px',
		marginTop: 1,
		width: '100%',
	},
	container: {
		position: 'relative',
	},
	contentContainer: {
		display: 'flex',
	},
	contentContainerLeft: {
		borderRight: '1px solid #D8D8D8',
		boxSizing: 'border-box',
		padding: 20,
		width: '55%',
	},
	contentContainerRight: {
		boxSizing: 'border-box',
		padding: 20,
		width: '45%',
	},
	details: {
		...CssSelectors.allDescendants({
			color: titles,
		}),
	},
	dollar: {
		color: darkGrayFontColor,
		fontSize: 18,
		marginRight: 6,
	},
	field: {
		alignItems: 'center',
		display: 'flex',
		height: '40px',
		width: '100%',
	},
	fieldLabel: {
		':first-of-type': {
			marginTop: 10,
		},
		color: titles,
		fontSize: 14,
		letterSpacing: 0,
		marginBottom: 4,
		marginTop: 30,
	},
	focus: {
		':focus-within': {
			border: '1px solid #00AAE8',
		},
	},
	header: {
		alignItems: 'center',
		background: brandPrimary,
		display: 'flex',
		height: 35,
		justifyContent: 'space-between',
		padding: '0 10px 0 20px',
	},
	inputFieldError: {
		height: 40,
	},
	optional: {
		color: '#858585',
		fontSize: 14,
		letterSpacing: 1.2,
		lineHeight: '13px',
		paddingTop: 10,
		textTransform: 'uppercase',
	},
	primary: {
		width: '100%',
	},
	required: {
		color: urgentDate,
	},
	selectStyles: {
		maxWidth: 'none',
	},
	subtitle: {
		color: darkGrayFontColor,
		fontSize: 14,
		fontStyle: 'italic',
	},
	title: {
		color: titles,
		fontSize: 18,
	},
});
