import * as Api from '@ViewModels';
import * as React from 'react';

export interface IEditSurveyContext<T extends Api.ISurvey = Api.ISurvey> {
	askForReview: boolean;
	setAskForReview(askForReview: boolean): void;
	setSurvey(survey: T): void;
	survey: T;
}

// @ts-ignore
export const EditSurveyContext = React.createContext<IEditSurveyContext>(null);

export function useEditSurveyContext<T extends Api.ISurvey = Api.ISurvey>() {
	const context = React.useContext(EditSurveyContext) as IEditSurveyContext<T>;

	if (!context) {
		throw new Error('This component requires the use of "EditSurveyContext"');
	}

	return context;
}
