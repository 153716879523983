import { animated, config, useSpring } from 'react-spring-legacy';

export function AnimatedNumber({ from = 0, to, className = '' }: { from?: number; to: number; className?: string }) {
	const spring = useSpring({
		config: config.molasses,
		from: { value: from },
		value: to,
	});
	return (
		<animated.span className={className}>
			{/* @ts-ignore */}
			{spring.value.interpolate((val: number) => Math.round(val).toLocaleString())}
		</animated.span>
	);
}
