import { StyleSheet } from 'aphrodite';
import { borderColor, brandPrimary } from '../../../../styles/colors';
import { BrowserMediaQueries, CssSelectors } from '../../../../styles/styles';

export const styleSheet = StyleSheet.create({
	autoCompleteMenuItem: {
		color: brandPrimary,
	},
	advancedTagSearch: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		cursor: 'pointer',
		margin: '10px auto',
	},
	autocompleteBox: {
		width: 233,
		maxHeight: 304,
		overflowY: 'auto',
		overflowX: 'hidden',
		...CssSelectors.allChildren(
			{
				...CssSelectors.allChildren(
					{
						...CssSelectors.allChildren(
							{
								padding: '5px 10px',
							},
							'div'
						),
					},
					'div'
				),
				width: 205,
			},
			'div'
		),
	},
	chevron: {
		marginLeft: 2,
		marginRight: 6,
	},
	chevronFlip: {
		transform: 'rotate3d(0,0,1,180deg)',
	},
	chevronIcon: {
		transform: 'rotate3d(0,0,1,0deg)',
	},
	container: {
		marginBottom: 10,
	},
	expander: {
		height: '100%',
		overflow: 'hidden',
		position: 'relative',
		width: 215,
	},
	moreButton: {
		paddingLeft: 0,
	},
	searchBox: {
		':nth-child(1n)': {
			boxSizing: 'border-box',
			margin: '5px 14px 0 0',
			padding: '3px 6px',
			...CssSelectors.allChildren(
				{
					fontSize: 12,
				},
				'input'
			),
			[BrowserMediaQueries.IE11]: {
				width: '72%',
			},
		},
	},
	searchIcon: {
		marginRight: 8,
		marginTop: 5,
	},
	tag: {
		':nth-child(1n)': {
			padding: '2px 6px 2px 0',
		},
	},
	sectionWrap: {
		borderBox: 'box-sizing',
		alignItems: 'center',
		display: 'flex',
		padding: 10,
		borderTop: `1px solid ${borderColor}`,
		borderBottom: `1px solid ${borderColor}`,
	},
	sectionTitle: {
		minWidth: 200,
		color: brandPrimary,
		fontSize: 14,
		margin: 0,
	},
	sectionIcon: {
		marginRight: 8,
		height: 16,
		width: 16,
	},
	createTag: {
		display: 'block',
		padding: 10,
		fontSize: 14,
	},
	noMargin: {
		margin: 0,
	},
});
