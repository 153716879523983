import { StyleSheet } from 'aphrodite';
import { header } from '../../../../styles/colors';
import { CssSelectors } from '../../../../styles/styles';

export const styleSheet = StyleSheet.create({
	formEditReturnAddressBtn: {
		marginLeft: '1rem',
	},
	formInput: {
		width: 320,
	},
	formLabel: {
		color: header,
		display: 'inline-block',
		fontSize: 14,
		width: 192,
	},
	formReturnAddressInput: {
		height: 120,
	},
	formRow: {
		alignItems: 'flex-start',
		display: 'flex',
	},
	loadingSpinner: {
		left: '50%',
		position: 'absolute',
		top: '50%',
		transform: 'translate(-50%, -50%)',
	},
	settingContentRoot: {
		position: 'relative',
		...CssSelectors.allChildren(
			{
				marginTop: '1rem',
			},
			':not(:first-child)'
		),
	},
});
