import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useLocation } from 'react-router';
import { useFullscreenModal, useUserSession } from '../../../models/hooks/appStateHooks';
import { ComposerSendFromDropdown } from '../../components/ComposerSendFromDropdown';
import { FabContext } from '../../components/FabContext';
import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { TextInput } from '../../components/TextInput';
import {
	CampaignRecipientsSearch,
	CampaignRecipientsSearchResult,
} from '../../components/campaigns/CampaignRecipientsSearch';
import { bs } from '../../styles/styles';
import { CampaignComposerBaseLayout } from '../BulkHwcPages/presentation';
import { TextCampaignPageBody } from './TextCampaignPageBody';
import { useTextingCampaignEditor } from './hooks/useTextingCampaignEditor';
import { styleSheet } from './styles';

export function TextCampaignSearchPage() {
	const userSession = useUserSession();
	const fullscreenModal = useFullscreenModal();
	const location = useLocation<{
		initialSendFrom?: Api.ISendEmailFrom;
		initialSendFromUserId?: string | null;
		initialCampaignTitle?: string;
		initialContentEditorState?: Api.IRichContentEditorState;
		initialAttachmentFiles?: File[];
		initialStoredAttachments?: Api.IFileAttachment[];
	}>();
	const [sendFrom, setSendFrom] = React.useState<Api.ISendEmailFrom>(
		location.state?.initialSendFrom ?? Api.SendEmailFrom.CurrentUser
	);
	const [sendFromUserId, setSendFromUserId] = React.useState<string | null>(
		location.state?.initialSendFromUserId ?? null
	);
	const { state, dispatch } = useTextingCampaignEditor({
		initialCampaignTitle: location.state?.initialCampaignTitle,
		initialContentEditorState: location.state?.initialContentEditorState,
		initialAttachmentFiles: location.state?.initialAttachmentFiles,
		initialStoredAttachments: location.state?.initialStoredAttachments,
	});
	const handleNextClick = (value: CampaignRecipientsSearchResult) => {
		const initialContactIdsToInclude = value.selectedContacts.map(c => c.id);
		if (
			initialContactIdsToInclude.length > 0 &&
			!value.selectedContactSearch?.filter?.criteria &&
			value.selectedTags.length === 0
		) {
			fullscreenModal.history.push({
				pathname: `/texting-campaign/contacts`,
				state: {
					contactIds: initialContactIdsToInclude,
					initialSendFrom: sendFrom,
					initialSendFromUserId: sendFromUserId,
					initialCampaignTitle: state.campaignTitle,
					initialContentEditorState: state.contentEditorState,
					initialAttachmentFiles: state.attachmentFiles,
					initialStoredAttachments: state.storedAttachments,
				},
			});
			return;
		}
		let initialContactFilterCriteriaList: Api.IContactFilterCriteria[];
		if (value.selectedContactSearch && value.selectedContactSearch?.filter?.criteria) {
			// assumes the top level op of all contact searches is 'And'
			initialContactFilterCriteriaList = value.selectedContactSearch.filter.criteria;
		} else if (value.selectedTags.length > 0) {
			initialContactFilterCriteriaList = [
				{
					op: 'Or',
					criteria: value.selectedTags.map(tag => ({
						property: Api.ContactFilterCriteriaProperty.Tag,
						value: tag.tag,
					})),
				},
			];
		}
		fullscreenModal.history.push({
			pathname: `/texting-campaign/filter`,
			state: {
				initialContactIdsToInclude,
				initialFilterCriteriaList: initialContactFilterCriteriaList,
				initialSendFrom: sendFrom,
				initialSendFromUserId: sendFromUserId,
				initialCampaignTitle: state.campaignTitle,
				initialContentEditorState: state.contentEditorState,
				initialAttachmentFiles: state.attachmentFiles,
				initialStoredAttachments: state.storedAttachments,
			},
		});
	};

	return (
		<>
			<MultiContainerHeader
				fullscreenHeader='New Texting Campaign'
				onFullscreenRequestBack={ev => {
					ev.preventDefault();
					fullscreenModal.dismissModal();
				}}
				onFullscreenRequestClose={() => fullscreenModal.dismissModal()}
			/>
			<CampaignComposerBaseLayout
				mainHeader={
					<div className={css(bs.px3, bs.py5, bs.flex, bs.gap2, bs.flexCol)}>
						<div>
							<div className={css(bs.fontLexend, bs.mb1, bs.flex, bs.itemsCenter, bs.justifyBetween)}>
								<label
									htmlFor='textingCampaignName-input'
									className={css(bs.textSm, bs.textGray600, styleSheet.labelRequired)}
								>
									Campaign Title
								</label>
								<span className={css(bs.fontLight, bs.textDestructive, bs.textXs)}>Required Field</span>
							</div>
							<TextInput
								inputId='textingCampaignName-input'
								type='text'
								placeholder='Add title...'
								className={css(bs.roundedLg)}
								value={state.campaignTitle}
								onChange={ev => dispatch({ type: 'setCampaignTitle', value: ev.target.value })}
							/>
						</div>
						<ComposerSendFromDropdown
							disabled={!userSession.canSendOnBehalf}
							sendFrom={sendFrom}
							sendFromUserId={sendFromUserId}
							onChange={params => {
								setSendFrom(params.sendFrom);
								setSendFromUserId(params.sendFromUserId);
							}}
						/>
					</div>
				}
				sideBar={<CampaignRecipientsSearch onSubmit={handleNextClick} />}
			>
				<>
					<FabContext appearance={{ hidden: true }} />
					<div
						className={css(
							bs.itemsCenter,
							bs.boxBorder,
							bs.flex,
							bs.flex1,
							bs.flexCol,
							bs.overflowAuto,
							bs.py8,
							bs.px6,
							bs.bgGray50
						)}
					>
						<div className={css(bs.flex, bs.flexCol, bs.maxWXl, bs.wFull, bs.mxAuto)}>
							<TextCampaignPageBody
								contentEditorState={state.contentEditorState}
								attachmentFiles={state.attachmentFiles}
								storedAttachments={state.storedAttachments}
								onAttachFiles={files => dispatch({ type: 'addAttachmentFiles', value: files })}
								onRemoveAttachmentFile={file => dispatch({ type: 'removeAttachmentFile', value: file })}
								onRemoveStoredAttachment={attachmentId =>
									dispatch({ type: 'removeStoredAttachment', value: attachmentId })
								}
								onContentStateChanged={content => dispatch({ type: 'setTextingContentEditorState', value: content })}
							/>
						</div>
					</div>
				</>
			</CampaignComposerBaseLayout>
		</>
	);
}
