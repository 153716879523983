import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import Waypoint from 'react-waypoint';
import { useEventLogging } from '../../../../../models/Logging';
import { useErrorMessages, useTextMessaging, useToaster } from '../../../../../models/hooks/appStateHooks';
import { LoadingSpinner } from '../../../LoadingSpinner';
import { ConversationItem } from '../../ConversationItem';
import { useTextMessageConversationsContext } from '../context';
import { styleSheet } from '../styles';

export const ConversationList: React.FC = observer(() => {
	const textMessagingVM = useTextMessaging();
	const {
		selectedConversation,
		selectedRecipient,
		setRecipients,
		setSelectedConversation,
		setSelectedRecipient,
		setSelectedRecipientIndex,
		setShowNewConversationSearch,
	} = useTextMessageConversationsContext();
	const errorMessages = useErrorMessages();
	const logger = useEventLogging();
	const toaster = useToaster();
	const history = useHistory();

	const onConversationClick = (conversation: Api.ConversationViewModel) => {
		setShowNewConversationSearch(false);
		if (selectedConversation?.id !== conversation.id) {
			setRecipients([]);
			// @ts-ignore
			history.push(`/texting/${textMessagingVM.id}/${conversation.id}`);
		}
	};

	const onTrashConversationClick = async (conversation: Api.ConversationViewModel) => {
		try {
			// @ts-ignore
			await textMessagingVM.archiveConversation(conversation.id);
			if (selectedConversation?.id === conversation.id) {
				// @ts-ignore
				setSelectedConversation(null);
				// @ts-ignore
				setSelectedRecipientIndex(null);
				history.push('/texting');
			}
			// @ts-ignore
			toaster.push({
				message: 'Conversation deleted.',
				type: 'successMessage',
			});
		} catch (err) {
			// @ts-ignore
			logger.logApiError('ArchiveTextConversation-Error', err);
			// @ts-ignore
			errorMessages.pushApiError(Api.asApiError(err));
		}
	};

	const onMarkConversationAsSpamClick = async (conversation: Api.ConversationViewModel) => {
		try {
			// @ts-ignore
			await textMessagingVM.markAsSpam(conversation.id);
			if (selectedConversation?.id === conversation.id) {
				// @ts-ignore
				setSelectedConversation(null);
				// @ts-ignore
				setSelectedRecipient(null);
				history.push('/texting');
			}
			// @ts-ignore
			toaster.push({
				message: 'Conversation marked as spam.',
				type: 'successMessage',
			});
		} catch (err) {
			// @ts-ignore
			logger.logApiError('MarkAsSpamConversation-Error', err);
			// @ts-ignore
			errorMessages.pushApiError(Api.asApiError(err));
		}
	};

	const onUnsubscribeConversationClick = async (conversation: Api.ConversationViewModel) => {
		// create an unsubscribed variable (and pass to conversation item) based on the existance of automatedSMSOptOutDate
		// if it exists on the conversation then the conversation is unsubscribed.
		try {
			// @ts-ignore
			await textMessagingVM.toggleUnsubscribeConversation(conversation);
			await selectedRecipient?.load();
			// @ts-ignore
			toaster.push({
				message: `Conversation ${conversation.automatedSMSOptOutDate ? 'un' : ''}subscribed.`,
				type: 'successMessage',
			});
		} catch (err) {
			// @ts-ignore
			logger.logApiError('UnsubscribedConvo-Error', err);
			// @ts-ignore
			errorMessages.pushApiError(Api.asApiError(err));
		}
	};

	const loadMoreConversations = () => {
		// @ts-ignore
		textMessagingVM.getConversations().catch(err => errorMessages.pushApiError(Api.asApiError(err)));
	};

	// @ts-ignore
	const isLoading = textMessagingVM.loadingConversations && !textMessagingVM.conversations?.length;

	// @ts-ignore
	const allLoaded = !textMessagingVM.allConversationsLoaded;

	const noConversations =
		// @ts-ignore
		textMessagingVM.conversationsLoaded &&
		// @ts-ignore
		!textMessagingVM.loadingConversations &&
		// @ts-ignore
		textMessagingVM.conversations.length === 0;

	const filterByLast = (conversation: Api.ConversationViewModel) => conversation.lastMessage;

	return (
		<div className={css(styleSheet.conversationsContainer)}>
			{noConversations && <div className={css(styleSheet.noConversations)}>No conversations</div>}
			{/* @ts-ignore */}
			{textMessagingVM.conversations.filter(filterByLast).map(conversation => (
				<ConversationItem
					className={css(styleSheet.conversationItem)}
					conversation={conversation}
					key={conversation.id}
					onClick={onConversationClick}
					onMarkAsSpamClick={onMarkConversationAsSpamClick}
					onTrashClick={onTrashConversationClick}
					onUnsubscribeClick={onUnsubscribeConversationClick}
					selected={selectedConversation?.id === conversation.id}
					selectedRecipient={selectedRecipient}
				/>
			))}
			{allLoaded && <Waypoint key='conversations-waypoint' bottomOffset='-200px' onEnter={loadMoreConversations} />}
			{isLoading && <LoadingSpinner className={css(styleSheet.conversationsLoading)} />}
		</div>
	);
});
