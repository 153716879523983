import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { useState } from 'react';
import { useEventLogging } from '../../../../../models/Logging';
import { getDisplayName, getMultilineFormattedAddress } from '../../../../../models/UiUtils';
import { useErrorMessages, useToaster, useUserSession } from '../../../../../models/hooks/appStateHooks';
import { useUserPreferencesMutation } from '../../../../../queries';
import { HwcSettingsSignatureInputField } from '../../../../containers/Settings/components/HwcSettingsSignatureInput';
import { HwcSettingsWritingStyleInputWithPreview } from '../../../../containers/Settings/components/HwcSettingsWritingStyleInputWithPreview';
import { baseStyleSheet, bs } from '../../../../styles/styles';
import { LoadingSpinner } from '../../../LoadingSpinner';
import { TextArea } from '../../../TextArea';
import { INameExtendedAddress, UpdateAddressModal } from '../../../entities/UpdateAddressModal';
import { SettingCardIcon } from '../../../svgs/icons/SettingCardIcon';
import { SettingsGroup } from '../../SettingsGroup';
import { styleSheet } from './styles';

interface IProps {
	forUser?: Api.IUser;
	expanded?: boolean;
	handleCloseSettings?: (result?: Api.IUserPreferences, canceled?: boolean) => void;
}

export const handwritingStyleFontMap: Record<Api.HandwritingStyle, { fontFamily: string; fontSize: number }> = {
	[Api.HandwritingStyle.Aladdin]: { fontFamily: Api.HandwritingStyle.Aladdin, fontSize: 21 },
	[Api.HandwritingStyle.Cinderella]: { fontFamily: Api.HandwritingStyle.Cinderella, fontSize: 32 },
	[Api.HandwritingStyle.Donald]: { fontFamily: Api.HandwritingStyle.Donald, fontSize: 21 },
	[Api.HandwritingStyle.Nemo]: { fontFamily: Api.HandwritingStyle.Nemo, fontSize: 30 },
	[Api.HandwritingStyle.Scar]: { fontFamily: Api.HandwritingStyle.Scar, fontSize: 30 },
	[Api.HandwritingStyle.Tarzan]: { fontFamily: Api.HandwritingStyle.Tarzan, fontSize: 30 },
	[Api.HandwritingStyle.Woody]: { fontFamily: Api.HandwritingStyle.Woody, fontSize: 30 },
};

function getReturnAddress(returnContact: Api.IHandwrittenCardReturnContact) {
	const displayName = getDisplayName({ firstName: returnContact.firstName, lastName: returnContact.lastName });
	return `${displayName ? `${displayName}\n` : ''}${getMultilineFormattedAddress(returnContact.address ?? {})}`.trim();
}

// @ts-ignore
export function HandwrittenCardSettings({ expanded = false, forUser = null, handleCloseSettings }: IProps) {
	const userSession = useUserSession();
	const selectedUser = forUser ?? userSession.user;
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging('HandwrittenCardSettings');
	const toaster = useToaster();
	const mutation = useUserPreferencesMutation({
		onError: error => {
			// @ts-ignore
			errorMessages.pushApiError(error);
			logApiError('MutateHandwrittenCardPrefs-Error', error);
		},
		onSuccess: data => {
			if (!forUser) {
				userSession.updateUser({
					...selectedUser,
					userPreferences: data,
				});
			}
			// @ts-ignore
			toaster.push({
				message: 'Changes applied successfully',
				type: 'successMessage',
			});
		},
	});
	const [handwritingStyle, setHandwritingStyle] = useState<Api.HandwritingStyle>(
		selectedUser.userPreferences?.handwrittenCardPreferences?.handwritingStyle ?? Api.HandwritingStyle.Aladdin
	);
	const [signature, setSignature] = useState<string>(
		selectedUser.userPreferences?.handwrittenCardPreferences?.signature ?? ''
	);
	const [returnContact, setReturnContact] = useState<Api.IHandwrittenCardReturnContact>(
		selectedUser.userPreferences?.handwrittenCardPreferences?.returnContact ?? {}
	);
	const [isUpdateAddressModalOpen, setIsUpdateAddressModalOpen] = useState(false);
	const returnAddressStr = getReturnAddress(returnContact);
	const handleSave = () => {
		const preferences: Api.IUserPreferences = {
			...selectedUser.userPreferences,
			handwrittenCardPreferences: {
				...selectedUser.userPreferences?.handwrittenCardPreferences,
				handwritingStyle,
				returnContact: Object.keys(returnContact).length > 0 ? returnContact : undefined,
				signature,
			},
		};
		if (forUser) {
			// @ts-ignore
			handleCloseSettings(preferences, false);
			return;
		}
		mutation.mutate({
			preferences,
		});
	};
	const onCancelChange = () => {
		setHandwritingStyle(
			selectedUser.userPreferences?.handwrittenCardPreferences?.handwritingStyle ?? Api.HandwritingStyle.Aladdin
		);
		setReturnContact(selectedUser.userPreferences?.handwrittenCardPreferences?.returnContact ?? {});
		setSignature(selectedUser.userPreferences?.handwrittenCardPreferences?.signature ?? '');
		if (forUser) {
			// @ts-ignore
			// @ts-ignore
			handleCloseSettings(null, true);
		}
	};
	const handleSaveAddress = (_address: INameExtendedAddress) => {
		const { firstName, lastName, ...address } = _address;
		setReturnContact({ address, firstName, lastName });
		handleCloseUpdateAddressModal();
	};
	const handleCloseUpdateAddressModal = () => {
		setIsUpdateAddressModalOpen(false);
	};
	return (
		<SettingsGroup
			description='Setup your card with personalization writing style, signature and logo.'
			expanded={expanded}
			staticSetting={Boolean(forUser)}
			icon={<SettingCardIcon />}
			name='Cards Setting'
			footer={
				<div className={css(bs.flex, bs.gap2)}>
					<button className={css(baseStyleSheet.ctaButton)} onClick={handleSave} disabled={mutation.isLoading}>
						Save
					</button>
					<button
						className={css(baseStyleSheet.ctaButtonReverse)}
						onClick={onCancelChange}
						disabled={mutation.isLoading}
					>
						Cancel
					</button>
				</div>
			}
		>
			<div className={css(styleSheet.settingContentRoot)}>
				<HwcSettingsWritingStyleInputWithPreview
					handwritingStyle={handwritingStyle}
					onChangeHandWritingStyle={setHandwritingStyle}
				/>
				<HwcSettingsSignatureInputField
					inputProps={{
						value: signature,
						onChange: ev => setSignature(ev.target.value),
						disabled: mutation.isLoading,
						style: {
							fontFamily: handwritingStyleFontMap[handwritingStyle].fontFamily,
							fontSize: `${handwritingStyleFontMap[handwritingStyle].fontSize}px`,
						},
					}}
				/>
				<div className={css(styleSheet.formRow)}>
					<label className={css(styleSheet.formLabel)}>Return address</label>
					<TextArea
						inputClassName={css(styleSheet.formInput, styleSheet.formReturnAddressInput)}
						value={returnAddressStr}
						inputId='hwc-return-address'
						disabled={true}
					/>
					<button
						className={css(baseStyleSheet.brandLink, styleSheet.formEditReturnAddressBtn)}
						onClick={() => setIsUpdateAddressModalOpen(true)}
					>
						Edit
					</button>
				</div>
				{mutation.isLoading ? <LoadingSpinner className={css(styleSheet.loadingSpinner)} type='large' /> : null}
				<UpdateAddressModal
					withNames
					initAddress={{
						firstName: returnContact.firstName,
						lastName: returnContact.lastName,
						...returnContact.address,
					}}
					onClickToSave={handleSaveAddress}
					modalProps={{
						isOpen: isUpdateAddressModalOpen,
						onRequestClose: handleCloseUpdateAddressModal,
					}}
				/>
			</div>
		</SettingsGroup>
	);
}
