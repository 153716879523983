import {
	ActionItemViewModel,
	EntityViewModel,
	IActionItem,
	IEntity,
	IOperationResultNoValue,
	IRichContentEditorState,
	IRichContentEntityReference,
	IUser,
	RichContentComposerResult,
	RichContentReferenceMethod,
	VmUtils,
	getTypeForEntity,
} from '@ViewModels';
import { css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { DefaultEditableActionItemContentCSS, IModalContext, ModalChildComponentContextKey } from '../../../../models';
import * as AppState from '../../../../models/AppState';
import { Topics } from '../../../../models/LocalNotificationTopics';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import {
	convertRawRichTextContentStateToRichContentEditorState,
	getDefaultDateStringValue,
	getDisplayName,
} from '../../../../models/UiUtils';
import { baseStyleSheet } from '../../../styles/styles';
import { DeleteConfirmation } from '../../DeleteConfirmation';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { InputFieldError } from '../../InputFieldError';
import { LoadingSpinner } from '../../LoadingSpinner';
import {
	INotificationServiceComponentProps,
	withNotificationService,
} from '../../LocalNotificationObserver/WithNotificationService';
import { Modal } from '../../Modal';
import { ResourceVisibility, VisibilityDropdown } from '../../VisibilityDropdown';
import { MentionEntitiesContext } from '../../mentions/MentionEntitiesContext';
import {
	IRichContentDocumentEditorConfig,
	RichContentDocumentEditor,
} from '../../richContent/RichContentDocumentEditor';
import { getActionItemsIcon } from '../../svgs/icons/NavIcon';
import { ActionItemAssociatedNoteHeader } from '../ActionItemAssociatedNoteHeader';
import { ActionItemCheckbox } from '../ActionItemCheckbox';
import { AssignDueDateButton } from '../AssignDueDateButton';
import { AssigneeButton } from '../AssigneeButton';
import { styleSheet } from './styles';

interface IProps
	extends IEventLoggingComponentProps,
		IModalContext,
		AppState.IErrorMessageComponentProps,
		AppState.IUserSessionComponentProps,
		RouteComponentProps<any>,
		INotificationServiceComponentProps<IActionItem> {
	actionItem?: ActionItemViewModel;
	autoFocus?: boolean;
	className?: string;
	onComplete?(actionItem?: ActionItemViewModel, result?: RichContentComposerResult): void;
	readOnly?: boolean;
}

interface IState {
	actionItem?: ActionItemViewModel;
	assignee?: IUser;
	checked?: boolean;
	dueDate?: Date;
	editorState?: IRichContentEditorState;
	inputErrorMessage?: string;
	mentionedEntities?: EntityViewModel<IEntity>[];
	readOnly?: boolean;
	showDeleteConfirmation?: boolean;
	visibility?: ResourceVisibility;
}

const DefaultEditorConfig: IRichContentDocumentEditorConfig = {
	autoresizeToFitContent: true,
	contentRawCss: DefaultEditableActionItemContentCSS,
	minHeight: 100,
	plugins: [], // none
	toolbar: false,
};

class _ActionItemComposer extends React.Component<IProps, IState> {
	public readonly state: IState = {
		visibility: 'admin',
	};

	public UNSAFE_componentWillMount() {
		const nextState = this.getNextStateWithProps(this.props) || {};
		this.setState(nextState);
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		const nextState = this.getNextStateWithProps(nextProps);
		if (nextState) {
			this.setState(nextState);
		}
	}

	public render() {
		const { className, autoFocus } = this.props;
		const {
			visibility,
			mentionedEntities,
			actionItem,
			checked,
			dueDate,
			assignee,
			inputErrorMessage,
			showDeleteConfirmation,
			readOnly,
			editorState,
		} = this.state;
		return (
			<div className={`${css(styleSheet.container)} action-item-composer ${className || ''}`}>
				<div className={css(styleSheet.header)}>
					{getActionItemsIcon('#fff', css(styleSheet.headerIcon))}
					<DeprecatedCloseButton
						disabled={!!actionItem && !!actionItem.isSaving}
						fillColor='#fff'
						onClick={this.onRequestClose}
					/>
				</div>
				<div className={css(styleSheet.body)}>
					<div className={css(styleSheet.composer)}>
						<div className={css(styleSheet.composerHeader)}>
							<div className={css(styleSheet.composerHeaderTitle)}>
								{`${!!actionItem && !!actionItem.id ? 'Edit' : 'Add an'} action item`}
							</div>
							<VisibilityDropdown
								className={css(styleSheet.visibilityDropdown)}
								disabled={!!actionItem && !!actionItem.isSaving}
								onVisibilityChanged={this.onVisibilityChanged}
								visibility={visibility}
							/>
						</div>
						<div className={css(styleSheet.composerBody)}>
							{this.renderNoteMetadataHeader()}
							<InputFieldError errorMessage={inputErrorMessage}>
								<div className={css(styleSheet.composerBodyContent)}>
									<ActionItemCheckbox
										checked={!!checked || false}
										disabled={!!actionItem && !!actionItem.isSaving}
										id='edit-action-item-checkbox'
										onChange={this.onCheckChanged}
									/>
									<RichContentDocumentEditor
										autoFocus={autoFocus}
										className={css(styleSheet.composerEditor)}
										config={DefaultEditorConfig}
										contentPlaceholderText='Type action item...'
										contentState={readOnly ? this.readOnlyEditorState : editorState}
										onContentStateChanged={this.onEidtorStateChanged}
										onFocus={this.clearInputErrorMessage}
										readOnly={readOnly}
									/>
								</div>
							</InputFieldError>
							{!!actionItem && !!actionItem.isBusy && <LoadingSpinner className='absolute-center' type='large' />}
						</div>
						<div className={css(styleSheet.options)}>
							<div className={css(styleSheet.option)}>
								<AssignDueDateButton
									className={css(styleSheet.optionButton)}
									disabled={!!actionItem && !!actionItem.isBusy}
									dueDate={dueDate}
									onDaySelected={this.onDueDateChanged}
									readOnly={!!actionItem && !!actionItem.isSaving}
								>
									{dueDate ? (
										<span>
											<span>Due date:</span>
											&nbsp;
											<span className={css(styleSheet.optionButtonSelection)}>
												{getDefaultDateStringValue(dueDate)}
											</span>
										</span>
									) : (
										<span>Assign a due date</span>
									)}
								</AssignDueDateButton>
								{!!dueDate && (
									<button
										className={css(styleSheet.optionButton, styleSheet.optionRemoveButton)}
										disabled={!!actionItem && !!actionItem.isBusy}
										onClick={this.onRemoveDueDate}
									>
										<span>Remove due date</span>
									</button>
								)}
							</div>
							<div className={css(styleSheet.option)}>
								<AssigneeButton
									assignee={assignee}
									className={css(styleSheet.optionButton)}
									disabled={!!actionItem && !!actionItem.isBusy}
									onAssigneeSelected={this.onAssigneeChanged}
									readOnly={!!actionItem && !!actionItem.isSaving}
									userSession={this.props.userSession}
								>
									{assignee ? (
										<span className='edit-action-item-options-assign-label'>
											<span>Assignee:</span>
											&nbsp;
											<span className={css(styleSheet.optionButtonSelection)}>{getDisplayName(assignee)}</span>
										</span>
									) : (
										<span>Assign to an employee</span>
									)}
								</AssigneeButton>
								{!!assignee && (
									<button
										className={css(styleSheet.optionButton, styleSheet.optionRemoveButton)}
										disabled={!!actionItem && !!actionItem.isBusy}
										onClick={this.onRemoveAssignee}
									>
										<span>Remove assignee</span>
									</button>
								)}
							</div>
						</div>
						<div className={css(styleSheet.composerFooter)}>
							<button
								className={css(baseStyleSheet.ctaButton)}
								disabled={!!actionItem && !!actionItem.isBusy}
								onClick={this.onSave}
							>
								<span>Save</span>
							</button>
							{!!actionItem && !!actionItem.id && (
								<button
									className={css(baseStyleSheet.ctaButtonDestructive)}
									disabled={!!actionItem && !!actionItem.isBusy}
									onClick={this.showDeleteConfirmation}
								>
									<span>Delete</span>
								</button>
							)}
						</div>
					</div>
					<div className={css(styleSheet.context)}>
						<MentionEntitiesContext
							contextItemName='action item'
							mentionedEntities={mentionedEntities}
							onMentionedEntitiesChanged={this.onMentionedEntitiesChanged}
						/>
					</div>
				</div>
				<DeleteConfirmation
					bodyText='Are you sure you want to delete this action item? Once deleted it will be gone forever.'
					isOpen={!!showDeleteConfirmation}
					onFinish={this.onDeleteConfirmationFinished}
				/>
			</div>
		);
	}

	@computed
	private get readOnlyEditorState() {
		const { actionItem } = this.state;
		return actionItem
			? convertRawRichTextContentStateToRichContentEditorState(actionItem.rawContentState, actionItem.preview)
			: null;
	}

	private renderNoteMetadataHeader() {
		if (this.props.actionItem) {
			const actionItemModel = this.props.actionItem.toActionItem();
			if (actionItemModel.associatedNote) {
				return (
					<ActionItemAssociatedNoteHeader
						actionItem={actionItemModel}
						className={css(styleSheet.noteHeader)}
						onSeeNoteClicked={this.onSeeAssociatedNoteClicked}
					/>
				);
			}
		}

		return null;
	}

	private showDeleteConfirmation = () => {
		this.setState({
			showDeleteConfirmation: true,
		});
	};

	private onDeleteConfirmationFinished = (shouldDelete: boolean) => {
		if (shouldDelete) {
			this.onDeleteActionItem();
		}
		this.setState({
			showDeleteConfirmation: false,
		});
	};

	private onAssigneeChanged = (assignee: IUser) => {
		this.setState({
			assignee,
		});
	};

	private onRemoveAssignee = () => {
		this.setState({
			assignee: null,
		});
	};

	private onEidtorStateChanged = (editorState: IRichContentEditorState) => {
		this.setState({
			editorState,
		});
		this.clearInputErrorMessage();
	};

	private getNextStateWithProps = (nextProps: IProps) => {
		let nextState: IState = null;
		if (this.state.actionItem !== nextProps.actionItem || this.props === nextProps) {
			nextState = {
				actionItem: nextProps.actionItem,
				assignee: null,
				checked: null,
				dueDate: null,
				mentionedEntities: [],
			};
			if (nextProps.actionItem) {
				nextState.assignee = nextProps.actionItem.assignee;
				nextState.checked = nextProps.actionItem.isCompleted;
				nextState.dueDate = nextProps.actionItem.dueDate;
				nextState.mentionedEntities = (nextProps.actionItem.mentionedEntities || [])
					.filter(x => x.method === RichContentReferenceMethod.Explicit)
					.map(x => x.entity);

				nextState.editorState = convertRawRichTextContentStateToRichContentEditorState(
					nextState.actionItem.rawContentState
				);
			}
		}

		if (nextState) {
			nextState.visibility =
				!!nextState.actionItem && !!nextState.actionItem.id ? nextState.actionItem.visibility : null;
			if (!nextState.visibility) {
				nextState.visibility = VmUtils.getDefaultVisibility(nextProps.userSession.user);
			}
		}

		if (this.state.readOnly !== nextProps.readOnly) {
			nextState.readOnly = nextProps.readOnly;
		}

		return nextState;
	};

	private onDueDateChanged = (dueDate: Date) => {
		this.setState({
			dueDate,
		});
	};

	private onRemoveDueDate = () => {
		this.setState({
			dueDate: null,
		});
	};

	private onSeeAssociatedNoteClicked = (e: React.MouseEvent<HTMLElement>) => {
		const { onComplete, actionItem } = this.props;
		if (onComplete) {
			onComplete(actionItem, RichContentComposerResult.None);
		}
		e.stopPropagation();
	};

	private onVisibilityChanged = (visibility: ResourceVisibility) => {
		this.setState({
			visibility,
		});
	};

	private onRequestClose = () => {
		const { parentModal, actionItem } = this.props;
		if (parentModal) {
			parentModal.onRequestClose(actionItem, true);
		}
	};

	private onDeleteActionItem = () => {
		const { logEvent, logApiError, postNotification, onComplete, errorMessages } = this.props;
		const { actionItem } = this.state;

		const promise = actionItem.delete();
		if (promise) {
			logEvent('DeleteActionItem', { id: actionItem.id });
			promise
				.then(() => {
					if (onComplete) {
						onComplete(actionItem, RichContentComposerResult.Delete);
					}

					const actionItemModel = actionItem.toActionItem();
					postNotification({
						info: actionItemModel,
						topic: Topics.DELETE_ACTION_ITEM,
					});
				})
				.catch((error: IOperationResultNoValue) => {
					logApiError('DeleteActionItem-Error', error);
					errorMessages.pushApiError(error);
				});
		}
	};

	private onSave = () => {
		const { onComplete, postNotification, logApiError, logEvent } = this.props;
		const { editorState, actionItem, dueDate, assignee, checked = false, mentionedEntities, visibility } = this.state;
		if (!!editorState && editorState.hasContent()) {
			const notificationTopic = actionItem.id ? Topics.EDIT_ACTION_ITEM : Topics.CREATE_ACTION_ITEM;

			// create the action item to save
			const actionItemToSave = { ...actionItem.toActionItem(true) };
			actionItemToSave.content = editorState.getRawRichTextContent();
			actionItemToSave.dueDate = dueDate ? moment(dueDate).toISOString() : null;
			actionItemToSave.assignee = !!assignee && !!assignee.id ? { id: assignee.id } : null;
			actionItemToSave.isCompleted = checked;
			actionItemToSave.visibility = visibility;

			// set refrenced entities
			actionItemToSave.referencedEntities = {
				companies: [],
				contacts: [],
				users: [],
			};
			(mentionedEntities || []).forEach(x => {
				const entityModel = x.toMention();
				const referencedEntityCollection: IRichContentEntityReference<IEntity>[] =
					getTypeForEntity(entityModel) === 'company'
						? actionItemToSave.referencedEntities.companies
						: actionItemToSave.referencedEntities.contacts;
				if (referencedEntityCollection.findIndex(j => j.entity.id === entityModel.id) < 0) {
					referencedEntityCollection.push({
						entity: entityModel,
						method: RichContentReferenceMethod.Explicit,
					});
				}
			});

			const promise = actionItem.save(actionItemToSave);
			if (promise) {
				const action = `${actionItemToSave.id ? 'Edit' : 'Save'}ActionItem`;
				logEvent(`${action}`, { id: actionItemToSave.id });
				promise
					.then(savedActionItemModel => {
						if (onComplete) {
							onComplete(actionItem);
						}

						if (postNotification) {
							postNotification({
								info: savedActionItemModel,
								topic: notificationTopic,
							});
						}
					})
					.catch((error: IOperationResultNoValue) => {
						logApiError(`${action}-Error`, error);
						this.setState({
							inputErrorMessage: error.systemMessage,
						});
					});
			}
		} else {
			this.setState({
				inputErrorMessage: 'A description is required',
			});
		}
	};

	private onMentionedEntitiesChanged = (mentionedEntities: EntityViewModel<IEntity>[]) => {
		this.setState({
			mentionedEntities,
		});
	};

	private onCheckChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			checked: e.target.checked,
		});
	};

	private clearInputErrorMessage = () => {
		if (this.state.inputErrorMessage) {
			this.setState({
				inputErrorMessage: null,
			});
		}
	};
}

const ActionItemComposerAsObserver = observer(_ActionItemComposer);
const ActionItemComposerWithContext = inject(
	AppState.UserSessionViewModelKey,
	AppState.ErrorMessagesViewModelKey,
	ModalChildComponentContextKey
)(ActionItemComposerAsObserver);
const ActionItemComposerWithRouter = withRouter(ActionItemComposerWithContext);
const ActionItemComposerWithNotificationService = withNotificationService(ActionItemComposerWithRouter);
export const ActionItemComposer = withEventLogging(ActionItemComposerWithNotificationService, 'ActionItemComposer');

class _ActionItemComposerModal extends React.Component<AppState.IActionItemComposerComponentProps> {
	public render() {
		const { actionItemComposer } = this.props;
		return (
			<Modal
				className={`action-item-composer-modal ${css(styleSheet.modal)}`}
				isOpen={!!actionItemComposer.richContent}
				onRequestClose={this.onRequestClose}
				shouldCloseOnOverlayClick={false}
			>
				<ActionItemComposer actionItem={actionItemComposer.richContent} onComplete={actionItemComposer.onComplete} />
			</Modal>
		);
	}

	private onRequestClose = (result?: ActionItemViewModel) => {
		const { actionItemComposer } = this.props;
		actionItemComposer.onComplete(result, RichContentComposerResult.None);
	};
}

const ActionItemComposerModalAsObserver = observer(_ActionItemComposerModal);
export const ActionItemComposerModal = inject(AppState.ActionItemComposerViewModelKey)(
	ActionItemComposerModalAsObserver
);
