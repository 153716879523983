import * as React from 'react';
import { PersonalSettingsSection } from '../../../components/settings/PersonalSettings/models';
import { AdminSettingsSection } from '../../../components/settings/models';
import { ISettingsContext, SettingsContext } from '../../../containers/Settings/context';

export const SettingsContextProvider: React.FC<{
	value?: Partial<ISettingsContext>;
}> = ({ children, value: externalValue }) => {
	const value = React.useRef<ISettingsContext>({
		supportedAdminSettingsSections: [AdminSettingsSection.Company, AdminSettingsSection.UsersAndBilling],
		supportedPersonalSettingsSections: [
			PersonalSettingsSection.Profile,
			PersonalSettingsSection.Password,
			PersonalSettingsSection.EmailSignature,
			PersonalSettingsSection.Notifications,
			PersonalSettingsSection.SocialMedia,
		],
		...(externalValue || {}),
	}).current;
	return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};
