import { css } from 'aphrodite';
import { baseStyleSheet as bs } from '../../../styles/styles';

export function FormErrorList({ id, errors: errorsOrError }: { id?: string; errors?: string | string[] | null }) {
	const errors = typeof errorsOrError === 'string' ? [errorsOrError] : errorsOrError;
	return (
		<div className={css(bs.px4, bs.pb3, bs.pt1)}>
			{errors?.length ? (
				<ul id={id} className={css(bs.flex, bs.flexCol, bs.gap1)}>
					{errors.map((error, i) => (
						<li key={i} className={css(bs.textXs, bs.textDestructive)}>
							{error}
						</li>
					))}
				</ul>
			) : null}
		</div>
	);
}
