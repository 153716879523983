import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useParams } from 'react-router';
import Waypoint from 'react-waypoint';
import { ContactSortKey } from '../../../models';
import { useEventLogging } from '../../../models/Logging';
import {
	createContentStateWithHtmlStringValue,
	getDisplayName,
	getDisplayNameForBulkEmail,
	getPrincipalInitials,
	hasRequiredHwcRecipientAddressFields,
	isAddressComplete,
} from '../../../models/UiUtils';
import { useErrorMessages, useFullscreenModal, useUserSession } from '../../../models/hooks/appStateHooks';
import {
	useByResourceSelectorQuery,
	useContact,
	useHwcResourceSelectorMutation,
	useTemplate,
	useUserHwcPreferences,
} from '../../../queries';
import { AdvancedFiltersFlyout } from '../../components/AdvancedFiltersFlyout';
import { Avatar2 } from '../../components/Avatar2';
import { ConfirmationDialog } from '../../components/ConfirmationDialog';
import { DeprecatedCloseButton } from '../../components/DeprecatedCloseButton';
import { Dropdown } from '../../components/Dropdown';
import { FabContext } from '../../components/FabContext';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { Modal } from '../../components/Modal';
import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { OverflowAccordion } from '../../components/OverflowAccordion';
import { ISelectOption, Select } from '../../components/Select';
import { SelectAnEmployeeCardBody, SelectAnEmployeeModal } from '../../components/SelectAnEmployee';
import { DefaultSelectBox } from '../../components/SelectBox';
import { TextArea } from '../../components/TextArea';
import { TinyPopover } from '../../components/TinyPopover';
import { ContactsTableActiveSearchesList } from '../../components/contacts/ContactsTableActiveSearchesList';
import { ContactContextTabView } from '../../components/email/ContactContextTabView';
import { BulkDeliveryMethodOptions } from '../../components/email/TagEmailRecipientsList';
import { PurchaseCreditsModal } from '../../components/settings/PurchaseCreditsModal';
import { DisclosureIcon } from '../../components/svgs/icons/DisclosureIcon';
import { DropDownIcon } from '../../components/svgs/icons/DropDownIcon';
import { PostageIcon } from '../../components/svgs/icons/PostageIcon';
import { WarningIcon } from '../../components/svgs/icons/WarningIcon';
import { baseStyleSheet } from '../../styles/styles';
import { addressTextFromHwcRecipient, addressTextFromHwcReturnContact } from '../PostcardComposer';
import { AddressFormField } from '../PostcardComposer/AddressFormField';
import { HwcOrderSuccessPage } from '../PostcardComposer/HwcOrderSuccessPage';
import { HwcPreviewToggle, HwcTogglePreview, HwcTogglePreviewValues } from '../PostcardComposer/HwcPreviewToggle';
import { HwcTextPreview } from '../PostcardComposer/HwcTextPreview';
import { PostcardTextEditor } from '../PostcardComposer/PostcardTextEditor';
import { RecipientAddressModal, addresseeNameFromHwcRecipient } from '../PostcardComposer/RecipientAddressModal';
import { ReturnContactModal } from '../PostcardComposer/ReturnAddressModal';
import { useCurrentUserGiftingBalance } from '../PostcardComposer/hooks/useCurrentUserGiftingBalance';
import {
	HANDWRITTEN_CARD_TEXT_MAX_ALLOWED_CHARACTERS,
	usePostcardTextEditor,
} from '../PostcardComposer/hooks/usePostcardTextEditor';
import { hwcRecipientFromContact } from '../PostcardComposer/hooks/useRecipient';
import { AddressMissingPill } from './AddressMissingPill';
import { CustomRecipientAddressField } from './CustomRecipientAddressField';
import { useHwcBirthdayRecipients } from './hooks/useHwcBirthdayRecipients';
import { useHwcSaveTemplate } from './hooks/useHwcSaveTemplate';
import { BulkHwcCustomLayout, CampaignComposerBaseLayout, SaveTemplateModal } from './presentation';
import { styleSheet } from './styles';

const resourceSortOptions = [
	{
		dataContext: ContactSortKey.Handle,
		id: 'sort-option-first-name',
		text: 'Sort by first name A - Z',
		type: 'default',
	},
	{
		dataContext: ContactSortKey.HandleReverse,
		id: 'sort-option-last-name',
		text: 'Sort by last name A - Z',
		type: 'default',
	},
	{
		dataContext: ContactSortKey.KeyDate,
		id: 'sort-option-type',
		text: `Sort by birthday`,
		type: 'default',
	},
] as ISelectOption<ContactSortKey>[];

interface IUserCustomCard {
	handwritingStyle?: Api.HandwritingStyle;
	postcardContent?: string;
	signature?: string;
	recipient?: Api.IHandwrittenCardRecipient;
}

export function BulkHwcBirthdayComposerPage() {
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging('BulkHwcBirthdayComposerPage');
	const params = useParams<{ templateId: string }>();
	const fullscreenModal = useFullscreenModal();
	const [step, setStep] = React.useState<'recipients' | 'reviewOrder' | 'success'>('recipients');
	const [isHwcTemplatesDropdownOpen, setIsHwcTemplatesDropdownOpen] = React.useState(false);
	const [selectedHwcTemplateId, setSelectedHwcTemplateId] = React.useState<string>(params.templateId);
	const selectedTemplateQuery = useTemplate<Api.IHandwrittenCardTemplate>({ templateId: selectedHwcTemplateId });
	const [postcardContent, setPostcardContent] = React.useState('');
	const { handwritingStyle, postcardText, getPostcardTextEditorProps, touched } = usePostcardTextEditor({
		content: postcardContent,
		// @ts-ignore
		defaultHandwritingStyle: userSession.user.userPreferences?.handwrittenCardPreferences?.handwritingStyle,
		onContentChanged: setPostcardContent,
	});
	const [toggledHwcPreview, setToggledHwcPreview] = React.useState<HwcTogglePreviewValues>('image');
	const birthdayHwcTemplatesQuery = useByResourceSelectorQuery({
		resourceSelector: Api.ResourceSelectorId.HappyBirthday,
		templateType: Api.TemplateType.HandwrittenCard,
	});
	const [isEditingPostcard, setIsEditingPostcard] = React.useState(false);
	const {
		createTemplateMutation,
		handleSaveAsButtonClicked,
		handleSaveAsModalClose,
		handleSaveAsModalSave,
		handleSaveButtonClicked,
		isSaveAllowed,
		isSaveAsPopoverShowing,
		isShowingSaveAsModal,
		toggleSaveAsPopover,
		updatingTemplateStatus,
	} = useHwcSaveTemplate({
		onSavedNewTemplate: template => {
			// @ts-ignore
			setSelectedHwcTemplateId(template.id);
		},
		postcardContent,
		// @ts-ignore
		template: selectedTemplateQuery.data,
	});
	const [cardCustomizationActiveContactId, setCardCustomizationActiveContactId] = React.useState<string | null>(null);
	const [customCardUserMapping] = React.useState(new Map<string, IUserCustomCard>());
	const [customUserCardContent, setCustomUserCardContent] = React.useState(postcardContent);
	const [customUserHwStyle, setCustomUserHwStyle] = React.useState(handwritingStyle);
	const [showRecipientAddressModal, setShowRecipientAddressModal] = React.useState(false);
	// @ts-ignore
	const [customUserRecipient, setCustomUserRecipient] = React.useState<Api.IHandwrittenCardRecipient>(null);
	const [returnContact, setReturnContact] = React.useState<Api.IHandwrittenCardReturnContact>(
		// @ts-ignore
		userSession.user?.userPreferences?.handwrittenCardPreferences?.returnContact ??
			userSession.account?.preferences?.handwrittenCardPreferences?.returnContact
	);
	const returnAddressText =
		// @ts-ignore
		returnContact && isAddressComplete(returnContact.address) ? addressTextFromHwcReturnContact(returnContact) : '';
	const [isShowingReturnContactModal, setIsShowingReturnContactModal] = React.useState(false);
	const customContactQuery = useContact({
		// @ts-ignore
		contactId: cardCustomizationActiveContactId,
		enabled: cardCustomizationActiveContactId != null,
	});
	const customContactHwcPreferencesQuery = useUserHwcPreferences({
		enabled: customContactQuery.data?.ownerId != null,
		// @ts-ignore
		forUserId: customContactQuery.data?.ownerId,
	});
	const customContactVm = React.useMemo(() => {
		return customContactQuery.data ? new Api.ContactViewModel(userSession, customContactQuery.data) : null;
	}, [customContactQuery.data, userSession]);
	// Set recipient data from query when customization is active, there's no recipient customization so far and queryData is available
	React.useEffect(() => {
		if (cardCustomizationActiveContactId != null && customUserRecipient == null && customContactQuery.data != null) {
			setCustomUserRecipient(hwcRecipientFromContact(customContactQuery.data));
		}
	}, [customUserRecipient, customContactQuery.data, cardCustomizationActiveContactId]);
	const customUserRecipientAddressText =
		customUserRecipient && hasRequiredHwcRecipientAddressFields(customUserRecipient.address)
			? addressTextFromHwcRecipient(customUserRecipient)
			: '';
	const customPostcardEditor = usePostcardTextEditor({
		content: customUserCardContent,
		handwritingStyle: customUserHwStyle,
		onContentChanged: content => setCustomUserCardContent(content),
		onHandwritingStyleChanged: hwStyle => setCustomUserHwStyle(hwStyle),
	});
	React.useEffect(() => {
		// If the template has content, but the postcard editor is untouched, set the postcard text to the template content
		// Only when editor is untouched do we want to set the postcard text to the template content
		// Otherwise we don't want to overwrite it with the template content
		if (selectedTemplateQuery.data?.content && !touched) {
			setPostcardContent(Api.getContentHtmlStringValueFromRawRichTextContentState(selectedTemplateQuery.data.content));
		}
	}, [selectedTemplateQuery.data?.content, touched]);
	const onEditClicked = () => {
		setIsEditingPostcard(true);
	};
	const onPreviewClicked = () => {
		setIsEditingPostcard(false);
	};
	const handleSelectedHwcTemplateChanged = (templateId: string) => () => {
		setSelectedHwcTemplateId(templateId);
		setIsHwcTemplatesDropdownOpen(false);
	};
	const handleRecipientClick = (contactId: string) => {
		const customCard = customCardUserMapping.get(contactId);
		if (customCard) {
			setCustomUserCardContent(customCard.postcardContent || postcardContent);
			setCustomUserHwStyle(customCard.handwritingStyle || handwritingStyle);
			setCustomUserSignature(customCard.signature || signature);
			// @ts-ignore
			setCustomUserRecipient(customCard.recipient);
		} else {
			setCustomUserCardContent(postcardContent);
			setCustomUserHwStyle(handwritingStyle);
			setCustomUserSignature(signature);
		}
		setCardCustomizationActiveContactId(contactId);
	};
	const clearCustomUserCard = () => {
		setCustomUserHwStyle(handwritingStyle);
		setCustomUserCardContent(postcardContent);
		setCustomUserSignature(signature);
		// @ts-ignore
		setCustomUserRecipient(null);
		setCardCustomizationActiveContactId(null);
	};
	const handleSaveCustomUserCardClick = () => {
		const customCard: IUserCustomCard = {};
		if (customUserRecipient) {
			customCard.recipient = customUserRecipient;
		}
		if (
			customUserHwStyle !== handwritingStyle ||
			customUserCardContent !== postcardContent ||
			(customUserSignature !== signature && sendEmailFrom !== Api.SendEmailFrom.ContactOwner)
		) {
			customCard.handwritingStyle = customUserHwStyle;
			customCard.signature = customUserSignature;
			customCard.postcardContent = customUserCardContent;
		}
		if (Object.keys(customCard).length > 0) {
			// @ts-ignore
			customCardUserMapping.set(cardCustomizationActiveContactId, customCard);
		}
		clearCustomUserCard();
	};
	const handleCancelCustomUserCardClick = (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
		ev.preventDefault();
		clearCustomUserCard();
	};
	const [showPurchaseCreditsModal, setShowPurchaseCreditsModal] = React.useState(false);
	const {
		allFilterCriterias,
		contactResourceSelectorCountQuery,
		contactResourceSelectorFilterQuery,
		contactIdsToOmit,
		isSendFromDropdownOpen,
		isSuppressionIntroModalOpen,
		onAdvancedFiltersFlyoutSave,
		onAdvancedFiltersFlyoutCancel,
		onDeliveryMethodOptionChanged,
		onRemoveRecipient,
		onSaveSelectAnEmployee,
		onSelectedSortByOptionChanged,
		onSendBehalfOfContactOwner,
		onSendBehalfOfCurrentUser,
		onSendOnBehalfSelectEmployee,
		onShowingFilterOptionChanged,
		onSuppressionIntroModalClose,
		onTagListSearchesChanged,
		selectAnEmployeeModalIsOpen,
		selectedDeliveryMethodOption,
		selectedShowingFilterOption,
		selectedSortByOption,
		sendEmailFrom,
		sendEmailFromUser,
		sendFromDropdownAnchorText,
		sendFromDropdownDisabled,
		setIsSendFromDropdownOpen,
		setSelectAnEmployeeModalIsOpen,
		setShowingAdvancedFiltersFlyout,
		showingAdvancedFiltersFlyout,
		showingFilterOptions,
		tagSearchCriterias,
	} = useHwcBirthdayRecipients({
		onSendFromContactOwnerSelected: () => {
			// Clear signature when contact owner is selected
			setSignature('');
			customCardUserMapping.clear();
		},
		onSendFromCurrentUserSelected: () => {
			// Set signature to user's signature when current user is selected
			// @ts-ignore
			setSignature(userSession.user.userPreferences?.handwrittenCardPreferences?.signature ?? '');
			setReturnContact(
				// @ts-ignore
				// @ts-ignore
				userSession.user.userPreferences?.handwrittenCardPreferences?.returnContact ??
					userSession.account?.preferences?.handwrittenCardPreferences?.returnContact
			);
			customCardUserMapping.clear();
		},
		onSendFromSelectedEmployee: () => {
			customCardUserMapping.clear();
		},
		onShowingFiltersChanged: () => {
			customCardUserMapping.clear();
		},
		onTagListSearchChange: () => {
			customCardUserMapping.clear();
		},
	});
	const [signature, setSignature] = React.useState(
		sendEmailFrom === Api.SendEmailFrom.CurrentUser
			? userSession.user!.userPreferences?.handwrittenCardPreferences?.signature ?? ''
			: ''
	);
	const [customUserSignature, setCustomUserSignature] = React.useState(signature);
	const sendFromUserPreferencesQuery = useUserHwcPreferences({
		enabled: sendEmailFromUser != null,
		// @ts-ignore
		forUserId: sendEmailFromUser?.id,
	});
	React.useEffect(() => {
		if (sendFromUserPreferencesQuery?.data?.returnContact) {
			setReturnContact(sendFromUserPreferencesQuery?.data?.returnContact);
		}
	}, [sendFromUserPreferencesQuery?.data?.returnContact]);
	const numberOfRecipients =
		contactResourceSelectorCountQuery.data != null
			? contactResourceSelectorCountQuery.data +
				Array.from(customCardUserMapping).filter(([contactId, customCard]) => {
					// If contact is in contactsToOmit, don't count it
					if (contactIdsToOmit.indexOf(contactId) > -1) {
						return false;
					}
					if (contactResourceSelectorFilterQuery.data) {
						const contact = contactResourceSelectorFilterQuery.data.pages
							// @ts-ignore
							.flatMap(page => page.values)
							.find(c => c.id === contactId);
						// Don't recount if the recipient already has a sendable address
						if (contact?.address && hasRequiredHwcRecipientAddressFields(contact.address)) {
							return false;
						}
					}
					if (customCard.recipient?.address) {
						return hasRequiredHwcRecipientAddressFields(customCard.recipient.address);
					}
					return false;
				}).length
			: null;
	const hasSendableAddress = (contact: Api.IContact) => {
		// @ts-ignore
		const contactCustomCard = customCardUserMapping.get(contact.id);
		if (contactCustomCard?.recipient?.address) {
			return hasRequiredHwcRecipientAddressFields(contactCustomCard.recipient.address);
		}
		return contact.address != null && hasRequiredHwcRecipientAddressFields(contact.address);
	};
	// When custom mode is active get the hwc preferences for the selected contact and set the signature but only if send from is contact owner and no signature custumizations have been made
	React.useEffect(() => {
		if (
			customContactHwcPreferencesQuery?.data?.signature &&
			sendEmailFrom === Api.SendEmailFrom.ContactOwner &&
			!customUserSignature
		) {
			setCustomUserSignature(customContactHwcPreferencesQuery?.data?.signature);
		}
	}, [customContactHwcPreferencesQuery?.data?.signature, sendEmailFrom, customUserSignature]);
	const sendEmailFromUserHwcPreferencesQuery = useUserHwcPreferences({
		enabled: sendEmailFromUser?.id != null,
		// @ts-ignore
		forUserId: sendEmailFromUser?.id,
	});
	// When send from user changes, update the signature to the selected user's signature preference
	React.useEffect(() => {
		if (sendEmailFromUserHwcPreferencesQuery?.data?.signature) {
			setSignature(sendEmailFromUserHwcPreferencesQuery.data.signature);
		}
	}, [sendEmailFromUserHwcPreferencesQuery?.data?.signature]);
	const { balanceAfterSend, currentBalance, giftingQuery, totalCost } = useCurrentUserGiftingBalance({
		// @ts-ignore
		totalRecipients: numberOfRecipients,
	});
	const canPlaceOrder =
		postcardText &&
		(returnContact || sendEmailFrom === Api.SendEmailFrom.ContactOwner) && // returnContact is not required if sendFrom is contactOwner
		!!numberOfRecipients &&
		// @ts-ignore
		balanceAfterSend >= 0;
	const bulkSendMutation = useHwcResourceSelectorMutation({
		onError: error => {
			// @ts-ignore
			errorMessages.pushApiError(error);
			logApiError('useHwcResourceSelectorMutation', error);
		},
		onSuccess: () => {
			setStep('success');
		},
	});
	const handlePlaceOrderClick = () => {
		bulkSendMutation.mutate({
			card: {
				content: createContentStateWithHtmlStringValue(postcardContent).getRawRichTextContent(),
				recipients: Array.from(customCardUserMapping).map(
					([contactId, customCard]) =>
						({
							...customCard.recipient,
							contactId, // probably not necessary
							content: customCard.postcardContent
								? createContentStateWithHtmlStringValue(customCard.postcardContent).getRawRichTextContent()
								: undefined,
							signature: customCard.signature ? customCard.signature : undefined,
							style: customCard.handwritingStyle ? customCard.handwritingStyle : undefined,
						}) as Api.IHandwrittenCardRecipient
				),
				returnContact,
				schedule: {
					criteria: Api.ScheduleCriteria.OnDayOf,
				},
				sendFrom: sendEmailFrom,
				...(sendEmailFrom === Api.SendEmailFrom.SelectedUser &&
					!!sendEmailFromUser && {
						senderUserId: sendEmailFromUser.id,
					}),
				signature,
				templateId: selectedHwcTemplateId,
			},
			resourceSelectorId: Api.ResourceSelectorId.HappyBirthday,
			selectorRequest: {
				excludeIds: contactIdsToOmit,
				filter: {
					criteria: allFilterCriterias,
				},
			},
		});
	};

	if (step === 'success') {
		return (
			<>
				<MultiContainerHeader
					fullscreenHeader='Order Confirmation'
					onFullscreenRequestBack={ev => {
						ev.preventDefault();
						// @ts-ignore
						fullscreenModal.dismissModal();
					}}
					// @ts-ignore
					onFullscreenRequestClose={() => fullscreenModal.dismissModal()}
				/>
				<HwcOrderSuccessPage />
			</>
		);
	}
	if (cardCustomizationActiveContactId != null) {
		return (
			<BulkHwcCustomLayout
				mainHeader={
					<>
						{selectedTemplateQuery.data ? (
							<div className={css(baseStyleSheet.truncateText, styleSheet.customCardHeaderTitle)}>
								{selectedTemplateQuery.data.name}
							</div>
						) : (
							<LoadingSpinner type='small' />
						)}
						{customContactQuery?.data ? (
							<div className={css(styleSheet.customCardHeaderUser)}>
								<Avatar2
									styleDeclaration={styleSheet.customCardUserAvatar}
									imgSrc={customContactQuery.data.profilePic}
									fallbackText={getPrincipalInitials(customContactQuery.data)}
								/>
								<div>
									<div className={css(styleSheet.customCardHeaderUserName)}>
										{getDisplayName(customContactQuery.data)}
									</div>
									<div className={css(styleSheet.customCardHeaderUserInfo)}>
										You are currently customizing the card for just this user
									</div>
								</div>
							</div>
						) : (
							<LoadingSpinner type='small' />
						)}
					</>
				}
				sideBar={
					<>
						{customContactVm ? (
							<ContactContextTabView
								contact={customContactVm}
								initialEdit={false}
								styles={[styleSheet.customCardContactContextTabView]}
							/>
						) : (
							<LoadingSpinner type='large' />
						)}
					</>
				}
				footer={
					<button
						className={css(baseStyleSheet.ctaButton)}
						onClick={handleSaveCustomUserCardClick}
						disabled={!customUserCardContent}
					>
						Save and go back to all recipients
					</button>
				}
			>
				<>
					<FabContext appearance={{ hidden: true }} />
					<MultiContainerHeader
						fullscreenHeader="Customize Recipient's Card"
						onFullscreenRequestBack={handleCancelCustomUserCardClick}
						onFullscreenRequestClose={handleCancelCustomUserCardClick}
					/>
					<div className={css(styleSheet.mainPreviewContainer)}>
						<div className={css(styleSheet.previewWrapper)}>
							<div className={css(styleSheet.configDrawerSection)}>
								<h3 className={css(styleSheet.configDrawerMessage)}>Message</h3>
								<span
									className={css(
										styleSheet.configDrawerSubtitle,
										styleSheet.configDrawerCharCount,
										// @ts-ignore
										customPostcardEditor.postcardText.length > HANDWRITTEN_CARD_TEXT_MAX_ALLOWED_CHARACTERS &&
											styleSheet.configDrawerCharCountOver
									)}
								>
									{/* @ts-ignore */}
									{customPostcardEditor.postcardText.length}/{HANDWRITTEN_CARD_TEXT_MAX_ALLOWED_CHARACTERS}
								</span>
								<PostcardTextEditor {...customPostcardEditor.getPostcardTextEditorProps()} />
							</div>
							<div className={css(styleSheet.configDrawerSection)}>
								<h4 className={css(styleSheet.configDrawerSelectSig)}>Signature</h4>
								<TextArea
									inputId='postcard-signature'
									value={customUserSignature}
									onChange={ev => setCustomUserSignature(ev.target.value)}
								/>
							</div>
							<CustomRecipientAddressField
								address={customUserRecipientAddressText}
								id='recipient-address'
								onBtnClick={() => setShowRecipientAddressModal(true)}
								onEditBtnClick={() => setShowRecipientAddressModal(true)}
								required={true}
								styleDeclaration={styleSheet.configDrawerSection}
							/>
							{showRecipientAddressModal ? (
								<RecipientAddressModal
									isOpen={true}
									initialData={{
										addresseeName: addresseeNameFromHwcRecipient(customUserRecipient),
										address1: customUserRecipient.address?.address1 ?? '',
										address2: customUserRecipient.address?.address2 ?? '',
										city: customUserRecipient.address?.city ?? '',
										salutationName: customUserRecipient.firstName ?? '',
										postalCode: customUserRecipient.address?.postalCode ?? '',
										stateProvince: customUserRecipient.address?.stateProvince ?? '',
									}}
									onSave={data => {
										setCustomUserRecipient({
											...data,
											address: {
												name: data.addresseeName,
												address1: data.address1,
												address2: data.address2,
												city: data.city,
												postalCode: data.postalCode,
												stateProvince: data.stateProvince,
											},
											contactId: cardCustomizationActiveContactId,
											firstName: data.salutationName,
										});
										setShowRecipientAddressModal(false);
									}}
									onCancel={() => setShowRecipientAddressModal(false)}
								/>
							) : null}
						</div>
					</div>
				</>
			</BulkHwcCustomLayout>
		);
	}
	return (
		<CampaignComposerBaseLayout
			mainHeader={
				<>
					{birthdayHwcTemplatesQuery.isLoading ? (
						<LoadingSpinner type='small' />
					) : birthdayHwcTemplatesQuery.data != null ? (
						<Dropdown
							className={css(styleSheet.hwcTemplatesDropdown)}
							anchor={
								<div className={css(baseStyleSheet.truncateText, styleSheet.hwcTemplatesDropdownAnchor)}>
									<div className={css(baseStyleSheet.truncateText, baseStyleSheet.horizontalStack)}>
										<div className={css(styleSheet.hwcTemplatesDropdownPostageIcon)}>
											<PostageIcon fillColor='#fff' width={15} height={15} />
										</div>
										{selectedTemplateQuery.data ? (
											<div className={css(baseStyleSheet.truncateText)}>{selectedTemplateQuery.data.name}</div>
										) : (
											<LoadingSpinner type='small' />
										)}
									</div>
									<DisclosureIcon className={css(styleSheet.hwcTemplatesDropdownIcon)} />
								</div>
							}
							contentClassName={css(styleSheet.hwcTemplatesDropdownMenu)}
							contentPositionY='bottom'
							isOpen={isHwcTemplatesDropdownOpen}
							onOpenChanged={open => setIsHwcTemplatesDropdownOpen(open)}
							openOnClick={true}
						>
							<>
								{/* @ts-ignore */}
								{birthdayHwcTemplatesQuery.data.map((template: Api.IHandwrittenCardTemplate) => {
									return (
										<div
											className={css(
												baseStyleSheet.truncateText,
												baseStyleSheet.horizontalStack,
												styleSheet.hwcTemplatesDropdownItem
											)}
											key={template.id}
											// @ts-ignore
											onClick={handleSelectedHwcTemplateChanged(template.id)}
										>
											<div className={css(styleSheet.hwcTemplatesDropdownPostageIcon)}>
												<PostageIcon fillColor='#fff' width={15} height={15} />
											</div>
											<div className={css(baseStyleSheet.truncateText)}>{template.name}</div>
										</div>
									);
								})}
							</>
						</Dropdown>
					) : null}
					<div className={css(styleSheet.sendFromDropdownContainer)}>
						<span>Send from:</span>
						<Dropdown
							anchor={
								<div className={css(baseStyleSheet.truncateText, styleSheet.dropdownAnchor)}>
									<span className={css(baseStyleSheet.truncateText, styleSheet.dropdownAnchorText)}>
										{sendFromDropdownAnchorText}
									</span>
									<DisclosureIcon className={css(styleSheet.dropdownIcon)} />
								</div>
							}
							disabled={sendFromDropdownDisabled}
							className={css(styleSheet.dropdownInline, styleSheet.sendFromDropdown)}
							contentClassName={css(styleSheet.dropdownMenu)}
							contentPositionY='bottom'
							isOpen={isSendFromDropdownOpen}
							onOpenChanged={open => setIsSendFromDropdownOpen(open)}
							openOnClick={true}
						>
							<div>
								<div
									className={css(styleSheet.dropdownItem, baseStyleSheet.truncateText)}
									onClick={onSendBehalfOfCurrentUser}
								>
									{/* @ts-ignore */}
									{`${userSession.user.firstName} ${userSession.user.lastName}`}
								</div>
								<div
									className={css(styleSheet.dropdownItem, baseStyleSheet.truncateText)}
									onClick={onSendBehalfOfContactOwner}
								>
									Contact owner of the recipient
								</div>
								{/* @ts-ignore */}
								{userSession.user.role.toLowerCase().includes('admin') ? (
									<div
										className={css(styleSheet.dropdownItem, baseStyleSheet.truncateText)}
										onClick={onSendOnBehalfSelectEmployee}
									>
										Select an employee
									</div>
								) : null}
							</div>
						</Dropdown>
					</div>
				</>
			}
			sideBar={
				step === 'recipients' ? (
					<>
						<MultiContainerHeader
							fullscreenHeader='View Card'
							onFullscreenRequestBack={ev => {
								ev.preventDefault();
								// @ts-ignore
								fullscreenModal.dismissModal();
							}}
							// @ts-ignore
							onFullscreenRequestClose={() => fullscreenModal.dismissModal()}
						/>
						<div className={css(styleSheet.recipientsHeader)}>
							<div className={css(styleSheet.recipientsHeaderTitle)}>
								<div>Recipients</div>
								<button className={css(baseStyleSheet.brandLink)} onClick={() => setShowingAdvancedFiltersFlyout(true)}>
									<div>Advanced Filters</div>
								</button>

								<AdvancedFiltersFlyout
									initialFilterCriteria={allFilterCriterias || []}
									onSave={onAdvancedFiltersFlyoutSave}
									isOpen={showingAdvancedFiltersFlyout}
									onDismissFlyout={onAdvancedFiltersFlyoutCancel}
									showOwnershipFilters
									disableOwnershipFilters={sendEmailFrom === Api.SendEmailFrom.ContactOwner}
								/>
							</div>

							<div className={css(baseStyleSheet.horizontalStack)}>
								{tagSearchCriterias.length > 0 ? (
									<OverflowAccordion
										threshold={65}
										onRenderTrigger={isExpanded => {
											return (
												<>
													<span className={css(styleSheet.tagTriggerText)}>{`${
														isExpanded ? 'Hide' : 'Show all'
													} tags`}</span>
													<DisclosureIcon
														className={css(isExpanded ? styleSheet.tagPointUp : styleSheet.tagPointDown)}
													/>
												</>
											);
										}}
									>
										<ContactsTableActiveSearchesList
											hideAdvancedSearch={true}
											hideLabel={true}
											hideRemoveButtons={false}
											onSearchesChanged={onTagListSearchesChanged}
											searches={tagSearchCriterias}
											tokenContainerStyles={[styleSheet.tagTokenContainer]}
											styles={[styleSheet.tagSearches]}
										/>
									</OverflowAccordion>
								) : null}
							</div>
							<div className={css(styleSheet.showingAndDelivery)}>
								<div className={css(baseStyleSheet.verticalStack, styleSheet.showingHeaderOptions)}>
									<div>Showing:</div>
									<DefaultSelectBox
										disabled={sendEmailFrom === Api.SendEmailFrom.ContactOwner}
										menuStyles={[styleSheet.showingFilterDropdownMenu]}
										onSelectionChanged={onShowingFilterOptionChanged}
										options={showingFilterOptions}
										selectedOption={selectedShowingFilterOption}
										triggerStyles={[styleSheet.showingFilterDropdownTrigger]}
									/>
								</div>
								<div className={css(baseStyleSheet.verticalStack, styleSheet.showingHeaderOptions)}>
									<div>Filter by available info</div>
									<DefaultSelectBox
										menuStyles={[styleSheet.showingFilterDropdownMenu]}
										onSelectionChanged={onDeliveryMethodOptionChanged}
										options={BulkDeliveryMethodOptions}
										// @ts-ignore
										selectedOption={selectedDeliveryMethodOption}
										triggerStyles={[styleSheet.showingFilterDropdownTrigger]}
									/>
								</div>
							</div>
							<div className={css(styleSheet.recipientsCountHeader)}>
								<div className={css(styleSheet.recipientsCountContainer)}>
									{contactResourceSelectorCountQuery.isLoading ? <LoadingSpinner type='tiny' /> : null}
									{numberOfRecipients != null
										? `${numberOfRecipients} recipient${numberOfRecipients !== 1 ? 's' : ''}`
										: null}
								</div>
								<div>
									<Select
										onOptionClick={onSelectedSortByOptionChanged}
										options={resourceSortOptions}
										selectedOption={selectedSortByOption}
										selectedOptionTitle={
											<span className={css(styleSheet.recipientsCountSortText)}>{selectedSortByOption.text}</span>
										}
										styles={[styleSheet.recipientsCountSort]}
										triggerStyles={[styleSheet.recipientsCountSortTrigger]}
									/>
								</div>
							</div>
						</div>
						<div className={css(styleSheet.recipientsList)}>
							<div>
								{contactResourceSelectorFilterQuery.status === 'success' ? (
									<>
										{contactResourceSelectorFilterQuery.data.pages.map(group => {
											return (
												// @ts-ignore
												group.values
													// @ts-ignore
													.filter(contact => contactIdsToOmit.indexOf(contact.id) === -1)
													// @ts-ignore
													.map(contact => {
														const contactCustomCard = customCardUserMapping.get(contact.id);
														const isCustomized = !!contactCustomCard?.postcardContent;
														const keyFactsResult = contact.keyFactsCollection?.find(
															// @ts-ignore
															keyFact => keyFact?.keyDate?.kind === Api.KeyDateKind.Birthday
														);
														let companyLine: string;
														if (contact.companyName != null) {
															companyLine =
																contact.jobTitle != null
																	? `${contact.jobTitle} at ${contact.companyName}`
																	: contact.companyName;
														}
														const hasValidAddress = hasSendableAddress(contact);
														const spouses = contact.householdMembers?.filter(
															i => i.householdRelationship?.title === Api.HouseholdTitle.Spouse
														);
														const multipleHouseholdMembers = spouses && spouses.length > 1;
														const nameLabel = multipleHouseholdMembers
															? contact.address?.name ?? contact.household?.name ?? getDisplayNameForBulkEmail(contact)
															: getDisplayNameForBulkEmail(contact);
														return (
															<div
																className={css(styleSheet.recipientsListItem)}
																key={contact.id}
																onClick={() => handleRecipientClick(contact.id)}
															>
																<DeprecatedCloseButton
																	onClick={ev => {
																		ev.stopPropagation();
																		onRemoveRecipient(contact);
																	}}
																/>
																{multipleHouseholdMembers ? (
																	<div className={css(styleSheet.recipientsListItemAvatarList)}>
																		{/* @ts-ignore */}
																		{/* @ts-ignore */}
																		{spouses.slice(0, 2).map((householdMember, i) => {
																			return (
																				<Avatar2
																					styleDeclaration={
																						i === 0
																							? styleSheet.recipientsListItemAvatarsFirst
																							: styleSheet.recipientsListItemAvatarsSecond
																					}
																					key={householdMember.id}
																					imgSrc={householdMember.profilePic}
																					fallbackText={getPrincipalInitials(householdMember)}
																				/>
																			);
																		})}
																	</div>
																) : (
																	<Avatar2
																		styleDeclaration={styleSheet.recipientsListItemAvatar}
																		imgSrc={contact.profilePic}
																		fallbackText={getPrincipalInitials(contact)}
																	/>
																)}
																<div className={css(styleSheet.recipientsListItemDetails)}>
																	<div className={css(styleSheet.recipientsListItemDetailsName)}>{nameLabel}</div>
																	<div
																		className={css(
																			styleSheet.recipientsListItemDetailsCompanyName,
																			baseStyleSheet.truncateText
																		)}
																	>
																		{/* @ts-ignore */}
																		{keyFactsResult?.value ?? companyLine}
																	</div>
																	<div
																		className={css(
																			baseStyleSheet.truncateText,
																			styleSheet.recipientsListItemDetailsInfo,
																			isCustomized && styleSheet.recipientsListItemDetailsInfoCustomized
																		)}
																	>
																		{isCustomized ? 'See customized card' : 'Customize card'}
																	</div>
																</div>
																{!hasValidAddress ? (
																	<AddressMissingPill styleDeclaration={styleSheet.addressMissingPillPlacement} />
																) : null}
															</div>
														);
													})
											);
										})}
										{contactResourceSelectorFilterQuery.hasNextPage &&
										!contactResourceSelectorFilterQuery.isFetchingNextPage ? (
											<Waypoint
												onEnter={() => contactResourceSelectorFilterQuery.fetchNextPage()}
												bottomOffset='-200px'
											/>
										) : null}
									</>
								) : null}
							</div>
						</div>
						<div className={css(styleSheet.recipientsFooter)}>
							<button
								className={css(baseStyleSheet.ctaButton, styleSheet.recipientsFooterCta)}
								onClick={() => setStep('reviewOrder')}
								disabled={!numberOfRecipients}
							>
								<span>Next: Review Order</span>
							</button>
						</div>
					</>
				) : (
					<>
						<MultiContainerHeader
							fullscreenHeader='View Card'
							onFullscreenRequestBack={ev => {
								ev.preventDefault();
								setStep('recipients');
							}}
							// @ts-ignore
							onFullscreenRequestClose={() => fullscreenModal.dismissModal()}
						/>
						<h2 className={css(styleSheet.configDrawerHeader)}>Review Order</h2>
						<div className={css(styleSheet.divider, styleSheet.dividerMargin)} />
						<div className={css(styleSheet.reviewOrderForm)}>
							<strong className={css(styleSheet.configDrawerThirdHeader)}>Mail Date</strong>
							<span className={css(styleSheet.configDrawerSubtitle, baseStyleSheet.mb4)}>
								We will mail the card 7-10 days before the birth-date for each recipient.
							</span>
							{sendEmailFrom !== Api.SendEmailFrom.ContactOwner ? (
								<AddressFormField
									address={returnAddressText}
									btnLabel='Add Return Address'
									id='return-address'
									label='Return Address'
									onBtnClick={() => setIsShowingReturnContactModal(true)}
									onEditBtnClick={() => setIsShowingReturnContactModal(true)}
									required={true}
								/>
							) : null}
							<div className={css(styleSheet.reviewOrderRecipientsCount)}>
								{contactResourceSelectorCountQuery.isLoading ? <LoadingSpinner type='small' /> : null}
								{numberOfRecipients != null
									? `${numberOfRecipients} recipient${numberOfRecipients !== 1 ? 's' : ''}`
									: null}
								<button className={css(baseStyleSheet.brandLink)} onClick={() => setStep('recipients')}>
									Edit
								</button>
							</div>
							<div className={css(styleSheet.divider, styleSheet.dividerMargin)} />
							{giftingQuery.status === 'loading' ? <LoadingSpinner /> : null}
							{giftingQuery.status === 'error' ? (
								<p role='error' className={css(styleSheet.errorContactLoad)}>
									Failed to load balance data
								</p>
							) : null}
							{giftingQuery.status === 'success' ? (
								<>
									<p className={css(styleSheet.balanceContainer)}>
										<strong className={css(styleSheet.balanceText)}>Current Balance:</strong> {/* @ts-ignore */}
										{new Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' }).format(currentBalance)}
									</p>
									{/* @ts-ignore */}
									{/* @ts-ignore */}
									{balanceAfterSend >= 0 || !Api.isAdmin(userSession.user) ? (
										<p className={css(styleSheet.balanceContainer)}>
											<strong className={css(styleSheet.balanceText)}>Balance After Send: </strong> {/* @ts-ignore */}
											{new Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' }).format(
												/* @ts-ignore */
												balanceAfterSend
											)}
										</p>
									) : (
										<button
											className={css(baseStyleSheet.ctaButtonSecondary)}
											onClick={() => setShowPurchaseCreditsModal(true)}
										>
											+ Add credits to continue
										</button>
									)}
								</>
							) : null}
							<div className={css(styleSheet.divider, styleSheet.dividerMargin)} />
							{totalCost != null ? (
								<p className={css(styleSheet.balanceContainer)}>
									<strong className={css(styleSheet.total)}>Total:</strong>
									{new Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' }).format(totalCost)}
								</p>
							) : null}
						</div>
						<div className={css(styleSheet.recipientsFooter)}>
							<button
								className={css(baseStyleSheet.ctaButton, styleSheet.recipientsFooterCta)}
								disabled={bulkSendMutation.isLoading || !canPlaceOrder}
								onClick={handlePlaceOrderClick}
							>
								<span>Place Order</span>
							</button>
						</div>
						{isShowingReturnContactModal ? (
							<ReturnContactModal
								isOpen={isShowingReturnContactModal}
								initialRecipient={returnContact}
								onSave={data => {
									setReturnContact({
										...data,
										address: {
											address1: data.address1,
											address2: data.address2,
											city: data.city,
											country: data.country,
											postalCode: data.postalCode,
											stateProvince: data.stateProvince,
										},
										firstName: data.firstName,
										lastName: data.lastName,
									});
									setIsShowingReturnContactModal(false);
								}}
								onCancel={() => setIsShowingReturnContactModal(false)}
							/>
						) : null}
					</>
				)
			}
		>
			<>
				<FabContext appearance={{ hidden: true }} />
				<div className={css(styleSheet.hwcActionsToolbar)}>
					{!isEditingPostcard ? (
						<button
							className={css(baseStyleSheet.outlineButtonContainer, styleSheet.outlineButton)}
							disabled={!postcardText && selectedTemplateQuery.isLoading}
							onClick={onEditClicked}
						>
							Edit
						</button>
					) : (
						<button
							className={css(baseStyleSheet.outlineButtonContainer, styleSheet.outlineButton)}
							disabled={!postcardText && selectedTemplateQuery.isLoading}
							onClick={onPreviewClicked}
						>
							Preview
						</button>
					)}
					{isSaveAllowed ? (
						<TinyPopover
							anchor={
								<div className={css(baseStyleSheet.outlineButtonContainer, styleSheet.outlineButton)}>
									<button
										className={css(baseStyleSheet.outlineButton)}
										onClick={handleSaveButtonClicked}
										disabled={updatingTemplateStatus === 'loading' || !postcardContent}
									>
										<span>Save</span>
									</button>
									<button className={css(styleSheet.saveAsDropdownCaret)} onClick={toggleSaveAsPopover}>
										<DropDownIcon />
									</button>
								</div>
							}
							isOpen={isSaveAsPopoverShowing}
							dismissOnOutsideAction={true}
							align='center'
							placement={['bottom']}
							onRequestClose={toggleSaveAsPopover}
						>
							<button
								className={css(baseStyleSheet.outlineButtonContainer, styleSheet.outlineButton)}
								disabled={false}
								onClick={handleSaveAsButtonClicked}
							>
								Save as
							</button>
						</TinyPopover>
					) : (
						<button
							className={css(baseStyleSheet.outlineButtonContainer, styleSheet.outlineButton)}
							disabled={false}
							onClick={handleSaveAsButtonClicked}
						>
							Save as
						</button>
					)}
				</div>
				<div className={css(styleSheet.mainPreviewContainer)}>
					<div className={css(styleSheet.previewWrapper)}>
						{!isEditingPostcard ? (
							<>
								<p className={css(styleSheet.cardPreviewText)}>Card Preview</p>
								{toggledHwcPreview === HwcTogglePreview.image ? (
									<div className={css(styleSheet.mainPreview, styleSheet.mainPreviewModeImage)}>
										{selectedTemplateQuery.data ? (
											<img
												src={selectedTemplateQuery.data.image.publicUrl}
												alt='Template image preview'
												className={css(styleSheet.mainPreviewImage)}
											/>
										) : (
											<LoadingSpinner />
										)}
									</div>
								) : (
									<HwcTextPreview
										handwritingStyle={handwritingStyle}
										// @ts-ignore
										postcardText={postcardText}
										signature={signature}
									/>
								)}
								<HwcPreviewToggle value={toggledHwcPreview} onValueChange={value => setToggledHwcPreview(value)} />
							</>
						) : null}
						{isEditingPostcard ? (
							<>
								<div className={css(styleSheet.configDrawerSection)}>
									<h3 className={css(styleSheet.configDrawerMessage)}>Message</h3>
									<span
										className={css(
											styleSheet.configDrawerSubtitle,
											styleSheet.configDrawerCharCount,
											// @ts-ignore
											postcardText.length > HANDWRITTEN_CARD_TEXT_MAX_ALLOWED_CHARACTERS &&
												styleSheet.configDrawerCharCountOver
										)}
									>
										{/* @ts-ignore */}
										{postcardText.length}/{HANDWRITTEN_CARD_TEXT_MAX_ALLOWED_CHARACTERS}
									</span>
									<PostcardTextEditor {...getPostcardTextEditorProps()} />
								</div>
								{
									// Hide signature if sendFrom is ContactOwner
									sendEmailFrom !== Api.SendEmailFrom.ContactOwner ? (
										<div className={css(styleSheet.configDrawerSection)}>
											<h4 className={css(styleSheet.configDrawerSelectSig)}>Signature</h4>
											<TextArea
												inputId='postcard-signature'
												value={signature}
												onChange={ev => setSignature(ev.target.value)}
											/>
										</div>
									) : null
								}
							</>
						) : null}
					</div>
				</div>
				{/* <div className={css(styleSheet.emailFallbackInfo)}>
					<Checkbox
						checked={emailFallbackActive}
						id={`bulk-hwc-email-fallback`}
						childrenPosition='left'
						checkmarkFillColor='white'
						checkedBackgroundFillColor={brandPrimary}
						onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
							setEmailFallbackActive(ev.target.checked);
						}}
					/>
					<span>If the recipient lacks an address, send a backup email (of the same message on the card) instead.</span>
				</div> */}
				{isShowingSaveAsModal ? (
					<Modal
						useDefaultHeader
						className={css(styleSheet.templateSaveModal)}
						isOpen={true}
						shouldCloseOnOverlayClick={false}
						onRequestClose={handleSaveAsModalClose}
					>
						<SaveTemplateModal
							initialTemplateScope={
								selectedTemplateQuery.data?.scope === Api.TemplateScope.Account
									? Api.TemplateScope.Account
									: Api.TemplateScope.User
							}
							actionButtonsDisabled={createTemplateMutation.isLoading}
							onSaveButtonClicked={({ templateName, scope }) => {
								handleSaveAsModalSave({ name: templateName, scope });
							}}
							onCancelButtonClicked={handleSaveAsModalClose}
						/>
					</Modal>
				) : null}
				{selectAnEmployeeModalIsOpen ? (
					<SelectAnEmployeeModal
						onClose={() => setSelectAnEmployeeModalIsOpen(false)}
						onUserSelected={onSaveSelectAnEmployee}
						bodyText={SelectAnEmployeeCardBody}
					/>
				) : null}
				<ConfirmationDialog
					className={css(styleSheet.suppressionModal)}
					icon={<WarningIcon />}
					modalProps={{
						isOpen: isSuppressionIntroModalOpen,
						onRequestClose: onSuppressionIntroModalClose,
					}}
					options={[
						{
							isDestructive: true,
							representedObject: true,
							title: 'Suppress',
						},
						{
							isCancel: true,
							representedObject: false,
							title: 'Cancel',
						},
					]}
					title='Excluding a contact from this send will suppress the contact from appearing on the dashboard. Are you sure?'
				/>
				<PurchaseCreditsModal
					isOpen={showPurchaseCreditsModal}
					onRequestClose={() => setShowPurchaseCreditsModal(false)}
				/>
			</>
		</CampaignComposerBaseLayout>
	);
}
