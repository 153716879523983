import { useEventLogging } from '../../../../../models/Logging';
import { useErrorMessages, useUserSession } from '../../../../../models/hooks/appStateHooks';
import { useModal } from '../../../../../models/hooks/useModal';
import { Checkbox } from '../../../../components/Checkbox';
import { TinyPopover } from '../../../../components/TinyPopover';
import { EntityTablePlaceholder } from '../../../../components/entities/EntityTablePlaceholder';
import { DisclosureIcon } from '../../../../components/svgs/icons/DisclosureIcon';
import {
	DefaultCompaniesFilter,
	DefaultCompaniesSort,
	useCompaniesInput,
	useCompaniesSearch,
} from '../CompaniesContext';
import { CompanyRow } from '../CompanyRow';
import { styleSheet } from './styles';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import Waypoint from 'react-waypoint';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const CompaniesTable: React.FC<IProps> = observer(props => {
	const { className, styles } = props;
	const { filter, sort } = useCompaniesSearch();
	const { companies, load } = useCompaniesSearch();
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging();
	const { setSearch } = useCompaniesInput();

	const sortPopover = useModal(false, Api.VmUtils.Noop, []);

	const loadMore = () => {
		// @ts-ignore
		companies.getCompanies({ searches: [...filter.criteria] }, sort)?.catch((err: Api.IOperationResultNoValue) => {
			// @ts-ignore
			errorMessages.pushApiError(err);
			logApiError('LoadMoreCompanies-Error', err);
		});
	};

	const onClearButtonClicked = React.useCallback(() => {
		load(DefaultCompaniesFilter, DefaultCompaniesSort);
		setSearch('');
	}, [load, setSearch]);

	const onSort = (newSort: Api.ISortDecriptor) => () => {
		sortPopover.setIsOpen(false)();
		load(filter, newSort);
	};

	const onSelectAllCheckedChanged = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.target.checked || companies.selectionState === 'some') {
				companies.selectAll();
			} else {
				companies.deseclectAll();
			}
		},
		[companies]
	);
	const tagsToHighlight = React.useMemo(() => {
		const tags: string[] = [];

		// @ts-ignore
		const tagFilters = filter.criteria.filter(x => x.property === Api.CompanySearchRequestProperty.Tag);
		tagFilters.forEach(tagFilter => {
			if (tagFilter.value && typeof tagFilter.value === 'string') {
				tags.push(tagFilter.value);
			}
		});

		if (!!tags && tags.length > 0) {
			return new Api.ObservableCollection(
				tags.map(
					x =>
						new Api.TagViewModel(userSession, {
							favoriteFor: Api.FavoriteFor.None,
							id: x,
							tag: x,
						})
				)
			);
		}

		return new Api.ObservableCollection<Api.TagViewModel>();
	}, [filter, userSession]);

	const sortDirectionDesc = sort.sort === 'desc';

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
			<div className={css(styleSheet.tableHeader)}>
				<Checkbox
					id='select-all-companies'
					childrenPosition='left'
					backgroundFillColor='white'
					checked={companies.selectionState === 'all' || companies.selectionState === 'some'}
					partial={companies.selectionState === 'some'}
					className={css(styleSheet.selectAll)}
					onChange={onSelectAllCheckedChanged}
				>
					<span className={css(styleSheet.selectAllText)}>All</span>
				</Checkbox>

				<TinyPopover
					anchor={
						<div onClick={sortPopover.setIsOpen(true)}>
							Name
							<span className={css(styleSheet.sortIcon, sortDirectionDesc && styleSheet.sortIconFlipped)}>
								<DisclosureIcon type='chevron' />
							</span>
						</div>
					}
					isOpen={sortPopover.isOpen}
					onRequestClose={sortPopover.onRequestClose}
					align='start'
					dismissOnOutsideAction={true}
					anchorStyles={[styleSheet.tableHeaderElement, styleSheet.name]}
				>
					<div className={css(styleSheet.sortMenu)}>
						<div className={css(styleSheet.sortMenuItem)} onClick={onSort({ sort: 'asc', sortBy: 'companyName' })}>
							Company Name A-Z
						</div>
						<div className={css(styleSheet.sortMenuItem)} onClick={onSort({ sort: 'desc', sortBy: 'companyName' })}>
							Company Name Z-A
						</div>
					</div>
				</TinyPopover>
				<div className={css(styleSheet.tableHeaderElement, styleSheet.tags)}>Tag</div>
				<div className={css(styleSheet.tableHeaderElement, styleSheet.domain)}>Domain</div>
				<div style={{ width: 30 }} />
			</div>
			<div className={css(styleSheet.tableBody)}>
				{companies.companies.length > 0 || !!companies.isFetchingResults ? (
					companies.companies?.map(company => {
						return (
							<CompanyRow company={company} companies={companies} key={company.id} tagsToHighlight={tagsToHighlight} />
						);
					})
				) : (
					<EntityTablePlaceholder
						className={css(styleSheet.placeholder)}
						onClearButtonClicked={onClearButtonClicked}
						type='company'
					/>
				)}
				{companies.companies.length > 0 && !companies.isFetchingResults && (
					<Waypoint key='load-more-trigger' bottomOffset='-400px' onEnter={loadMore} />
				)}
			</div>
		</div>
	);
});
