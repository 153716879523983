import { styleSheet } from './styles';
import { StyleDeclaration, css } from 'aphrodite';
import * as React from 'react';

export function MainContainerPageShell({
	styleDeclarations = [],
	compactOnly = false,
	children,
}: {
	styleDeclarations?: StyleDeclaration[];
	children: React.ReactNode;
	compactOnly?: boolean;
}) {
	return (
		<div className={css(styleSheet.pageShell, compactOnly && styleSheet.pageShellCompactOnly, ...styleDeclarations)}>
			{children}
		</div>
	);
}
