import { StyleSheet } from 'aphrodite';
import {
	brandPrimary,
	brandPrimaryHoverTint,
	brandPrimaryText,
	header,
	mention,
	hotPink,
	success,
	successTint,
} from '../../../styles/colors';
import { BreakPoints, CssSelectors, baseStyleSheet } from '../../../styles/styles';

const MobileBreakpoint = 720;

export const styleSheet = StyleSheet.create({
	author: {
		display: 'block',
		...baseStyleSheet.fontBold,
		marginTop: 16,
	},
	blueBadge: {
		background: 'rgb(4, 102, 255, 0.15)',
		color: brandPrimary,
	},
	bodyColumns: {
		display: 'grid',
		gridTemplateColumns: '25% 75%',
		width: '100%',
		[BreakPoints.forMaxWidth(MobileBreakpoint)]: {
			display: 'flex',
			flexDirection: 'column',
		},
	},
	bodyFull: {
		backgroundColor: brandPrimaryHoverTint,
		borderRadius: 6,
		color: brandPrimaryText,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		textAlign: 'center',
		width: '100%',
		[BreakPoints.forMinWidth(MobileBreakpoint)]: {
			height: 140,
			flexDirection: 'row',
		},
	},
	bodyLeft: {
		textAlign: 'center',
	},
	bodyRight: {
		display: 'grid',
		gridTemplateColumns: '5% 90% 5%',
		width: '100%',
		[BreakPoints.forMaxWidth(MobileBreakpoint)]: {
			marginTop: 24,
		},
	},
	buttonContainer: {
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	cardHeader: {
		alignItems: 'center',
		borderBottom: `1px solid ${mention}`,
		color: header,
		display: 'flex',
		marginBottom: '1rem',
		paddingBottom: '0.5rem',
		textAlign: 'center',
		width: '100%',
	},
	cardHGroup: {
		...CssSelectors.allChildren(
			{
				marginTop: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	dailyUpdateItemContent: {
		display: 'flex',
	},
	dailyUpdateItemHGroup: {
		display: 'flex',
		flexDirection: 'column',
	},
	dailyUpdateItemIcon: {
		boxSizing: 'border-box',
	},
	dailyUpdateItemIconContainer: {
		alignItems: 'center',
		borderRadius: 12,
		boxSizing: 'border-box',
		display: 'flex',
		height: 40,
		justifyContent: 'center',
		marginRight: '1rem',
		padding: '0.5rem',
		width: 40,
	},
	dailyUpdateItemNumber: {
		fontFamily: 'OpenSans-Bold',
	},
	dailyUpdateItemRoot: {
		...CssSelectors.allChildren(
			{
				marginBottom: '0.5rem',
			},
			':not(:last-child)'
		),
		alignItems: 'center',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
		paddingRight: '1rem',
	},
	daysAgo: {
		alignSelf: 'flex-end',
		color: header,
		fontSize: 12,
		marginBottom: 2,
		marginLeft: 8,
	},
	getMoreReviews: {
		background: 'transparent',
		textDecoration: 'none',
	},
	goBackButton: {
		marginRight: 8,
		marginTop: 42,
		textAlign: 'right',
	},
	goNextButton: {
		marginLeft: 8,
		marginTop: 42,
		textAlign: 'left',
	},
	headerColumns: {
		display: 'grid',
		gridTemplateColumns: '29% 71%', // Desktop, as close as possible to aligned with text
		width: '100%',
		[BreakPoints.forMaxWidth(MobileBreakpoint)]: {
			gridTemplateColumns: '50% 50%',
		},
	},
	logo: {
		height: 24,
		marginRight: 8,
	},
	logoWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	noReviewsImage: {
		overflow: 'hidden',
	},
	percentIncreaseContainer: {
		padding: 16,
		display: 'table',
		margin: '0 auto',
	},
	percentIncreaseStat: {
		background: successTint,
		color: success,
		display: 'flex',
		flexDirection: 'column',
		maxWidth: 140,
		padding: '8px 24px 12px 24px',
		borderRadius: 6,
	},
	percentIncreaseSubtitle: {
		fontSize: 10,
	},
	percentIncreaseTitle: {
		fontSize: 30,
		...baseStyleSheet.fontBold,
	},
	rating: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: 8,
	},
	ratingStar: {
		height: 12,
		width: 12,
	},
	ratingsTitle: {
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
		fontSize: 14,
		height: 24,
		marginTop: 6,
	},
	redBadge: {
		background: '#FCE0F3',
		color: hotPink,
	},
	refreshButton: {
		alignItems: 'center',
		background: brandPrimary,
		borderRadius: 12,
		display: 'flex',
		flexShrink: 0,
		justifyContent: 'center',
		marginRight: '1rem',
		padding: '0.5rem',
	},
	respondedText: {
		alignItems: 'center',
		color: success,
		display: 'flex',
		fontSize: 12,
		...CssSelectors.allChildren({
			marginRight: 4,
		}),
		padding: '15px 0 5px',
		width: '100%',
	},
	reviewText: {
		maxHeight: 140,
		overflow: 'hidden',
	},
	root: {
		flexDirection: 'column',
		width: '100%',
	},
	wideBadge: {
		paddingLeft: 20,
		paddingRight: 20,
	},
});
