import { brandPrimaryHover } from '../../../styles/colors';
import { Layouts, cardStyle } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

const quickActionWidth = 320;
const columnSpace = 20;
export const styleSheet = StyleSheet.create({
	cardGroupCoffee: {
		width: `calc(100% - ${quickActionWidth + columnSpace}px)`,
	},
	cardGroupContainer: {
		boxSizing: 'border-box',
		display: 'flex',
		paddingBottom: 20,
		paddingTop: 20,
	},
	cardGroupHeader: {
		height: 35,
	},
	circleGraphics: {
		paddingTop: '30px',
	},
	header: {
		display: 'flex',
	},
	quickAction: {
		...Layouts.verticalStack(12),
		alignItems: 'center',
		boxSizing: 'border-box',
		color: brandPrimaryHover,
		display: 'flex',
		flexDirection: 'column',
		fontSize: 16,
		height: 180,
		justifyContent: 'center',
		lineHeight: '18px',
		padding: 20,
		textAlign: 'center',
		width: '100%',
	},
	quickActions: {
		...cardStyle,
		...Layouts.verticalStack(20),
		boxSizing: 'border-box',
		paddingBottom: 20,
		paddingTop: 20,
	},
	quickActionsColumn: {
		marginLeft: columnSpace,
		width: quickActionWidth,
	},
});
