import { StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../../../styles/styles';

export const styleSheet = StyleSheet.create({
	buttonContainer: {
		display: 'flex',
		marginTop: 16,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	container: {},
});
