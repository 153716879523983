import { SvgIcon } from '../../icons/SvgIcon';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { animated, config, useSpring } from 'react-spring-legacy';

export enum StepBubbleGraphicType {
	AnimatedSuccess,
	Success,
	Custom,
}

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	type?: StepBubbleGraphicType;
}

export const StepBubbleGraphic: React.FC<IProps> = props => {
	const { className, styles, type = StepBubbleGraphicType.Custom, children } = props;

	const [animatedOnMount, setAnimatedOnMount] = React.useState<boolean>(false);
	const successSpring = useSpring({
		config: { ...config.default, delay: 1000, duration: 1000 },
		from: { strokeDashoffset: animatedOnMount ? 100 : 0 },
		reset: animatedOnMount,
		to: { strokeDashoffset: 0 },
	});

	const renderKnownContent = () => {
		switch (type) {
			case StepBubbleGraphicType.AnimatedSuccess:
			case StepBubbleGraphicType.Success: {
				return (
					<animated.polyline
						stroke='#00AAE8'
						strokeWidth='8'
						strokeDasharray={100}
						points='68 73.318 78.459 83.667 95.523 59.461'
						style={successSpring}
					/>
				);
			}
			default: {
				return null;
			}
		}
	};

	React.useEffect(() => {
		if (type === StepBubbleGraphicType.AnimatedSuccess) {
			setAnimatedOnMount(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<SvgIcon className={`step-bubble-graphic ${className || ''} ${css(...(styles || []))}`} height={159} width={150}>
			<g fill='none' fillRule='evenodd' transform='translate(1)'>
				<g transform='translate(23.514 15.24)'>
					<ellipse cx='58.785' cy='58.88' fill='#E8F3F9' stroke='#00AAE8' strokeWidth='2' rx='58.785' ry='58.88' />
					<ellipse cx='58.785' cy='58.88' fill='#FFF' rx='51.869' ry='51.953' />
				</g>
				<ellipse cx='2.766' cy='77.583' fill='#E8F3F9' stroke='#00AAE8' rx='2.766' ry='2.771' />
				<ellipse cx='145.925' cy='20.089' fill='#E8F3F9' stroke='#00AAE8' rx='2.075' ry='2.078' />
				<ellipse cx='121.72' cy='13.854' fill='#E8F3F9' stroke='#00AAE8' rx='1.383' ry='1.385' />
				<ellipse cx='106.505' cy='1.385' fill='#00AAE8' rx='1.383' ry='1.385' />
				<ellipse cx='15.907' cy='90.745' fill='#00AAE8' rx='2.075' ry='2.078' />
				<path
					fill='#00AAE8'
					d='M11.0654206,60.9583333 C11.8293284,60.9583333 12.4485981,60.3380612 12.4485981,59.5729167 C12.4485981,58.8077722 11.8293284,58.1875 11.0654206,58.1875 C10.3015127,58.1875 9.68224299,58.8077722 9.68224299,59.5729167 C9.68224299,60.3380612 10.3015127,60.9583333 11.0654206,60.9583333 Z'
				/>
				<g fill='#00AAE8' transform='translate(135.551 1.385)'>
					<rect width='4.15' height='1.385' y='1.385' rx='.693' />
					<rect width='4.15' height='1.385' y='1.385' rx='.693' transform='rotate(90 2.075 2.078)' />
				</g>
				<g fill='#00AAE8' transform='translate(143.85 41.563)'>
					<rect width='4.15' height='1.385' y='1.385' rx='.693' />
					<rect width='4.15' height='1.385' y='1.385' rx='.693' transform='rotate(90 2.075 2.078)' />
				</g>
				<ellipse cx='82.5' cy='153' fill='#E8F3F9' rx='36.5' ry='6' />
				{type === StepBubbleGraphicType.Custom ? children : renderKnownContent()}
			</g>
		</SvgIcon>
	);
};
