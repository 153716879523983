/* eslint-disable react-hooks/exhaustive-deps */
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { getDisplayName } from '../../../../../models/UiUtils';
import { useTextMessaging, useToaster, useUserSession } from '../../../../../models/hooks/appStateHooks';
import { TextingArrowGraphic } from '../../../svgs/graphics/TextingArrowGraphic';
import { ContactError } from '../../ContactError';
import { ISelectedRecipientPill, RecipientSearch } from '../../RecipientSearch';
import { useTextMessageConversationsContext } from '../context';
import { styleSheet } from '../styles';
import { verifyIsNotAlreadyAdded } from './utils';

interface IProps {
	missingNumbers: Api.ITextRecipient[];
}

const MAX_RECIPIENTS = 9;

export const NewConversation: React.FC<IProps> = observer(({ missingNumbers }) => {
	const textMessagingVM = useTextMessaging();
	const {
		recipients,
		selectedConversation,
		selectedRecipient,
		setRecipients,
		setShowNewConversationSearch,
		showNewConversationSearch,
	} = useTextMessageConversationsContext();
	const userSession = useUserSession();
	const toaster = useToaster();

	const alreadyAddedToasterError = () => {
		// @ts-ignore
		toaster.push({
			message: 'Recipient has already been added to the conversation.',
			type: 'errorMessage',
		});
	};

	const onCancelNewConversationClick = () => {
		setShowNewConversationSearch(false);
		setRecipients([]);
	};

	const onRecipientSelected = async (contact: Api.IContact) => {
		const recipient = new Api.TextRecipientViewModel(userSession, contact);
		const alreadyAdded = verifyIsNotAlreadyAdded(
			recipient,
			recipients as Api.TextRecipientViewModel[],
			alreadyAddedToasterError
		);
		if (!alreadyAdded) {
			const newRecipients = [...recipients, recipient];
			setRecipients(newRecipients);
		}
	};

	const onRemoveRecipient = (recipient: ISelectedRecipientPill) => {
		const newRecipients = recipients.filter(r => {
			if (r instanceof Api.TextRecipientViewModel) {
				return r.id !== recipient.id;
			}
			const p = r as Api.IPhoneNumber;
			const phone = p.metadata?.standard ?? p.value;
			return phone !== recipient.id;
		});
		setRecipients(newRecipients);
	};

	const onUnknownNumberSelected = (num: string) => {
		const alreadyAdded = verifyIsNotAlreadyAdded(
			{ value: num },
			recipients as Api.TextRecipientViewModel[],
			alreadyAddedToasterError
		);
		if (!alreadyAdded) {
			const phoneNumber: Api.IPhoneNumber = { value: num };
			const newRecipients = [...recipients, phoneNumber];
			setRecipients(newRecipients);
		}
	};

	const getInvalidContactString = () => {
		let result = '';
		if (missingNumbers?.length > 0) {
			for (let i = 0; i < missingNumbers.length; i++) {
				const num = missingNumbers[i];
				const displayName = getDisplayName(num.contact) || `unknown contact number ${num.number.standard}`;
				result += i === 0 ? displayName : `, ${displayName}`;
			}
		}
		return result;
	};

	const getRecipientsNames = () => {
		return recipients.map(r => {
			if (r instanceof Api.TextRecipientViewModel) {
				let value = 'unknown';
				if (r.firstName) {
					value = r.firstName;
				} else if (r.lastName) {
					value = r.lastName;
				} else if (r.selectedPhoneNumber) {
					value = r.selectedPhoneNumber.metadata?.standard ?? r.selectedPhoneNumber.value;
				} else if (r.primaryEmail?.value) {
					value = r.primaryEmail.value;
				}
				// @ts-ignore
				const recipient: ISelectedRecipientPill = { id: r.id, value };
				if (r.needsResolving) {
					recipient.needsResolved = true;
				}
				return recipient;
			}
			const p = r as Api.IPhoneNumber;
			const phone = p.metadata?.standard ?? p.value;
			return { id: phone, value: phone };
		});
	};

	const showStarNewConversationMessage = !showNewConversationSearch && !selectedConversation && recipients.length === 0;

	const showSelectedConversation = !showNewConversationSearch && !!selectedConversation;

	const disableSearch = recipients.length >= MAX_RECIPIENTS || !!selectedRecipient?.needsResolving;

	return (
		<div
			className={css(
				styleSheet.conversationHeader,
				showStarNewConversationMessage ? styleSheet.conversationHeaderLeft : null
			)}
		>
			{showNewConversationSearch && (
				<RecipientSearch
					disableInput={disableSearch}
					inputStyles={[styleSheet.inputContainer]}
					leftText='To:'
					onCancel={onCancelNewConversationClick}
					onRecipientSelected={onRecipientSelected}
					onRemoveRecipient={onRemoveRecipient}
					onUnknownNumberSelected={onUnknownNumberSelected}
					placeholder={recipients.length ? 'Add contacts' : 'Search contacts'}
					popoverAnchorStyles={[styleSheet.anchor]}
					recentSearchesStorageKey='TextRecipientsRecentSearches'
					selectedRecipients={getRecipientsNames()}
					styles={[styleSheet.recipientSearch]}
					// @ts-ignore
					supportsGroupTexting={textMessagingVM.supportsGroupTexting}
				/>
			)}
			{showSelectedConversation && (
				<div className={css(styleSheet.conversationHeaderContentContainer)}>
					<p className={css(styleSheet.conversationHeaderNames)}>
						{Api.VmUtils.renderConversationNames(selectedConversation)}
					</p>
					{recipients.filter(recipient => recipient instanceof Api.TextRecipientViewModel).length === 1 &&
						recipients.length === 1 && (
							<p className={css(styleSheet.conversationHeaderNumbers)}>
								{selectedConversation?.toNumbers.map(num => num.number.standard).join(', ')}
							</p>
						)}
				</div>
			)}
			{missingNumbers.length > 0 && (
				<ContactError
					className={css(styleSheet.alert)}
					text={
						selectedConversation?.toNumbers?.length > 1
							? `The following recipients in this conversation are no longer up to date: ${getInvalidContactString()}`
							: 'This recipient is no longer up to date.'
					}
				/>
			)}
			{showStarNewConversationMessage && (
				<div className={css(styleSheet.textContainer)}>
					<TextingArrowGraphic />
					<div>Start a new message</div>
				</div>
			)}
		</div>
	);
});
