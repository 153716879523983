import { computed } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import {
	convertRawRichTextContentStateToRichContentEditorState,
	getDefaultDateStringValue,
	getDisplayName,
	numberToCurrencyStringValue,
} from '../../../../../models/UiUtils';
import { OpportunityViewModel } from '../../../../../viewmodels/AppViewModels';
import { StageIndicator } from '../../../boards/StageIndicator';
import { EntityChip } from '../../../chips/EntityChip';
import { MultiLineAddress } from '../../../entities/MultiLineAddress';
import { SocialProfiles } from '../../../entities/SocialProfiles';
import { RichContentDocumentEditor } from '../../../richContent/RichContentDocumentEditor';
import './styles.less';

interface IProps {
	className?: string;
	opportunity: OpportunityViewModel;
}

/** Helper SFC for rendering sections */
const OpportunityProfileSection: React.FC<{
	title?: string;
	smallTitle?: boolean;
}> = observer(({ children, title, smallTitle }) => {
	return (
		<div className='opportunity-profile-section'>
			<div
				className={`opportunity-profile-section-title ${smallTitle ? 'opportunity-profile-section-small-title' : ''}`}
			>
				{title}
			</div>
			{children}
		</div>
	);
});

class _OpportunityProfile extends React.Component<IProps> {
	public render() {
		const { className, opportunity } = this.props;
		return (
			<div className={`opportunity-profile ${className || ''}`}>
				<div className='opportunity-profile-name truncate-text'>{opportunity.name}</div>
				{this.renderStageIndicator()}
				{!!opportunity.company && (
					<OpportunityProfileSection title='Company:'>
						<EntityChip entity={opportunity.company} readOnly={true} />
					</OpportunityProfileSection>
				)}
				{!!opportunity.primaryContact && (
					<OpportunityProfileSection title='Primary Contact:'>
						<EntityChip entity={opportunity.primaryContact} readOnly={true} />
					</OpportunityProfileSection>
				)}
				<OpportunityProfileSection title='Amount:'>
					<div className='opportunity-profile-amount'>
						{`$${opportunity.dealSize ? numberToCurrencyStringValue(opportunity.dealSize) : 0}`}
					</div>
				</OpportunityProfileSection>
				<OpportunityProfileSection title='Close date:'>
					<div className='opportunity-profile-due-date'>{this.getCloseDate(opportunity)}</div>
				</OpportunityProfileSection>
				<OpportunityProfileSection title='Owner:'>
					<div className='opportunity-profile-owner'>
						{opportunity.owner ? getDisplayName(opportunity.owner.toJs(), true) : ''}
					</div>
				</OpportunityProfileSection>
				{!!opportunity.details && !!opportunity.details.document && (
					<OpportunityProfileSection title='Details:'>
						<RichContentDocumentEditor
							className='opportunity-profile-details'
							contentState={this.readOnlyEditorState}
							readOnly={true}
						/>
					</OpportunityProfileSection>
				)}
				{!!opportunity.company && this.renderCompanySection(opportunity)}
			</div>
		);
	}

	private renderCompanySection(opportunity: OpportunityViewModel) {
		const socialProfiles = opportunity.company.socialProfiles;
		return (
			<>
				<div className='opportunity-profile-divider' />
				{!!opportunity.company.address && (
					<OpportunityProfileSection smallTitle={true} title='Address:'>
						<div className='opportunity-profile-details'>
							<MultiLineAddress address={opportunity.company.address} />
						</div>
					</OpportunityProfileSection>
				)}
				{!!opportunity.company.emailDomain && (
					<OpportunityProfileSection smallTitle={true} title='Domain:'>
						<div className='opportunity-profile-details'>{opportunity.company.emailDomain}</div>
					</OpportunityProfileSection>
				)}
				{socialProfiles?.length > 0 && <SocialProfiles socialProfiles={socialProfiles} />}
			</>
		);
	}

	private renderStageIndicator() {
		const { opportunity } = this.props;
		if (!!opportunity.stage && !!opportunity.stage.config && !!opportunity.stage.config.enableStageIndicator) {
			return (
				<div className='opportunity-profile-stage-indicator'>
					<div className='opportunity-profile-stage-indicator-title'>
						<span className='opportunity-profile-section-title'>{'Stage: '}</span>
						<span>{opportunity.stage.name}</span>
					</div>
					<StageIndicator
						currentIndex={opportunity.stage.indexInBoard}
						hideFutureStages={true}
						total={opportunity.stage.boardStageCount}
					/>
				</div>
			);
		}
	}

	@computed
	private get readOnlyEditorState() {
		const { opportunity } = this.props;
		return opportunity ? convertRawRichTextContentStateToRichContentEditorState(opportunity.details) : null;
	}

	private getCloseDate(opportunity: OpportunityViewModel) {
		return opportunity.closeDate ? getDefaultDateStringValue(opportunity.closeDate) : '-';
	}
}

export const OpportunityProfile = observer(_OpportunityProfile);
