import redtailLogo from '../../../../assets/logo_redtail_sm.svg';
import { baseStyleSheet } from '../../../../styles/styles';
import { IntegrationGraphic } from '../../../svgs/graphics/IntegrationGraphic';
import { IntegrationCard, useIntegrationCard } from '../IntegrationCard';
import { styleSheet } from './styles';
import * as Api from '@ViewModels';

export const DashboardRedtailIntegrationCard = () => {
	const { getLogoImgProps, getCloseButtonProps, getCtaButtonProps, getIntegrationTitleProps, showCard } =
		useIntegrationCard({
			closeIntegrationEventName: 'RedtailIntegrationClosed',
			enableUrlPath: '/integrations/redtail',
			integrationEnableEventName: 'RedtailIntegrationEnableClick',
			pendingAction: Api.PendingActions.ConnectRedtail,
		});

	if (!showCard) {
		return null;
	}

	return (
		<IntegrationCard
			logoBgColor='#c72127'
			closeButtonProps={getCloseButtonProps()}
			IntegrationGraphic={IntegrationGraphic}
			headerLabel='Redtail Integration'
			logoImgProps={getLogoImgProps({ alt: 'Redtail Logo', src: redtailLogo })}
			ctaButtonProps={getCtaButtonProps({
				children: 'Sign In to Redtail to Integrate',
				styleDeclaration: [baseStyleSheet.ctaButton, styleSheet.ctaButton],
			})}
			integrationTitleProps={getIntegrationTitleProps({ styleDeclaration: styleSheet.integrationTitle })}
		>
			<p>
				We are excited to announce that you can integrate your Redtail account with Levitate. With this integration, we
				will have the ability to pull client information, including names, email, phone number, birthdays,
				anniversaries, and more &mdash; making staying in touch even easier. We can also push our notes into Redtail.
			</p>
		</IntegrationCard>
	);
};
