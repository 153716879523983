import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	actions: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	body: {
		marginBottom: 30,
		...CssSelectors.allChildren(
			{
				marginTop: 10,
			},
			':not(:first-child)'
		),
	},
	bodyWithMessage: {
		marginLeft: 20,
	},
	container: {},
	image: {
		marginBottom: 30,
	},
	message: {
		color: '#4A4A4A',
		fontSize: 14,
		marginBottom: 26,
	},
});
