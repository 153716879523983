import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	headerClassName?: string;
	title?: React.ReactNode;
	titleRightAccessory?: React.ReactNode;
}

/** Layout (column) for groups of dashboard cards. */
export const CardGroup: React.FC<IProps> = ({
	children,
	className = '',
	headerClassName = '',
	title,
	titleRightAccessory,
}) => {
	return (
		<div className={className}>
			<div className={`${css(styleSheet.header)} ${headerClassName}`}>
				{!!title && <div className={css(styleSheet.title)}>{title}</div>}
				{!!titleRightAccessory && <div>{titleRightAccessory}</div>}
			</div>
			{!!children && <div className={css(styleSheet.content)}>{children}</div>}
		</div>
	);
};
