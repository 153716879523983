import ArrowBottomIconImageUrl from './arrowBottom.svg';
import ArrowRightIconImageUrl from './arrowRight.svg';
import { styleSheet } from './styles';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { animated, interpolate, useSprings } from 'react-spring-legacy';
import { useDrag } from 'react-use-gesture';

export interface IInteractiveCategorizationItemInfo<T = any> {
	index: number;
	item: T;
}

export type IInteractiveCategorizationCallbacks = IStackActions;

export interface ISortedInteractiveCategorizationItemInfo<T = any> extends IInteractiveCategorizationItemInfo<T> {
	axis: 'x' | 'y';
	direction: -1 | 1;
}

interface IProps<T = any> extends IEventLoggingComponentProps {
	className?: string;
	disableArrowClicks?: boolean;
	disableKeyboardInput?: boolean;
	items: T[];
	itemsContainerStyles?: StyleDeclarationValue[];
	loading?: boolean;
	onInnerRef?(ref?: IInteractiveCategorizationCallbacks): void;
	onKeyForItem?(info: IInteractiveCategorizationItemInfo<T>): number | string;
	onRenderBottomOption?(callbacks?: IInteractiveCategorizationCallbacks): React.ReactNode;
	onRenderEmptyPlaceholder?(): React.ReactNode;
	onRenderItem(info: IInteractiveCategorizationItemInfo<T>): React.ReactNode;
	onRenderItemsLeftAccessory?(callbacks?: IInteractiveCategorizationCallbacks): React.ReactNode;
	onRenderItemsRightAccessory?(callbacks?: IInteractiveCategorizationCallbacks): React.ReactNode;
	onRenderLeftOption(callbacks?: IInteractiveCategorizationCallbacks): React.ReactNode;
	onRenderLoading?(): React.ReactNode;
	onRenderRightOption(callbacks?: IInteractiveCategorizationCallbacks): React.ReactNode;
	onRequestMore?(): void;
	onSwipeEnd?(sortedItem: ISortedInteractiveCategorizationItemInfo<T>): void;
	styles?: StyleDeclarationValue[];
}

const CardRotateTransform = (r: number, s = 1) =>
	`perspective(1500px) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`;
const CardTranslateTransform = (x: number, y: number, z = 0) =>
	`perspective(1500px) translate3d(${x}px, ${y}px, ${z}px)`;

interface IStackItemAnimationProps {
	delay?: number;
	opacity?: number;
	rotation?: number;
	scale?: number;
	x?: number;
	y?: number;
	z?: number;
}

const BaseAnimationProps: IStackItemAnimationProps = {
	delay: 0,
	opacity: 1,
	rotation: 0,
	scale: 1,
	x: 0,
	y: 0,
	z: 0,
};

const OnMountToAnimationProps = (i: number): IStackItemAnimationProps => ({
	...BaseAnimationProps,
	delay: i * 100,
	opacity: 1 - i * 0.2,
	scale: 1,
	y: i * -20,
	z: i * -60,
});

const OnMountFromAnimationProps = (index: number): IStackItemAnimationProps => ({
	...BaseAnimationProps,
	opacity: 0,
	scale: 0.8,
	y: (index + 1) * -40,
});

interface IStackActions {
	swipeBottom(index?: number): Promise<void> | null;
	swipeLeft(index?: number): Promise<void> | null;
	swipeRight(index?: number): Promise<void> | null;
	undoLastSwipe(): Promise<void> | null;
}

interface IDragEvent<T = any> extends IInteractiveCategorizationItemInfo<T> {
	axis?: 'x' | 'y';
	direction?: -1 | 1 | 0;
	target?: EventTarget;
}

const BaseSpringConfig = { duration: 550, friction: 50, tension: 200 };

interface IItemStackProps<T = any> {
	cardStyles?: StyleDeclarationValue[];
	items: T[];
	itemStyles?: StyleDeclarationValue[];
	maxVisibleCount?: number;
	onActions?(ref?: IStackActions): void;
	onDrag?(e: IDragEvent): void;
	onKeyForItem?(info: IInteractiveCategorizationItemInfo<T>): number | string;
	onRenderItem(info: IInteractiveCategorizationItemInfo<T>): React.ReactNode;
	onRenderItemLeftAccessory?(info: IInteractiveCategorizationItemInfo<T>): React.ReactNode;
	onRenderItemRightAccessory?(info: IInteractiveCategorizationItemInfo<T>): React.ReactNode;
	onRequestMore?(): void;
	onSwipeEnd?(sortedItem: ISortedInteractiveCategorizationItemInfo): void;
	onSwipeStart?(sortedItem: ISortedInteractiveCategorizationItemInfo): void;
	styles?: StyleDeclarationValue[];
	swipeTriggerDistanceX?: number;
	swipeTriggerDistanceY?: number;
}

const ItemStack: React.FC<IItemStackProps> = props => {
	const [items, setItems] = React.useState<any[]>(props.items || []);
	const [sortedItems, setSortedItems] = React.useState<ISortedInteractiveCategorizationItemInfo[]>([]);
	const [springs, setSprings] = useSprings(items.length, i => ({
		...OnMountToAnimationProps(i),
		from: {
			delay: 0,
			rotation: 0,
			scale: 1,
			x: 0,
			y: 0,
			z: 0,
			...OnMountFromAnimationProps(i),
		},
	}));

	const onSwipeFinished = (sortedItem: ISortedInteractiveCategorizationItemInfo) => {
		// update the sorted items
		// report swipe complete to listeners
		// request more items if sorted collection size matches the props.items collection
		setSortedItems(sorted => {
			const result = [...sorted, sortedItem];
			setTimeout(() => {
				if (props.onSwipeEnd) {
					props.onSwipeEnd(sortedItem);
				}
				if (items.length > 0 && result.length > 0 && result.length === items.length && !!props.onRequestMore) {
					props.onRequestMore();
				}
			});
			return result;
		});
	};

	let canRespondToActions = true;

	// create indexed drag functions for animated elements
	const binds = useDrag(({ args: [index], down, movement: [dx, dy], cancel, event, dragging }) => {
		if (index !== sortedItems.length) {
			// tried dragging item not on top
			cancel();
			return;
		}
		canRespondToActions = !dragging && canRespondToActions;
		const dirX = dx < 0 ? -1 : 1;
		const dirY = dy < 0 ? -1 : 1;
		const didTriggerSwipeYThreshold = Math.abs(dy) > Math.abs(props.swipeTriggerDistanceY || 200);
		const didTriggerSwipeXThreshold = Math.abs(dx) > Math.abs(props.swipeTriggerDistanceX || 200);
		const didSwipeX = !didTriggerSwipeYThreshold && Math.abs(dy) < 1000 && !!didTriggerSwipeXThreshold;
		const didSwipeY = !didTriggerSwipeXThreshold && dirY > 0 && Math.abs(dirX) < 1000 && !!didTriggerSwipeYThreshold;

		if (props.onDrag) {
			const e: IDragEvent = {
				axis: didSwipeX ? 'x' : didSwipeY ? 'y' : null,
				direction: didSwipeX ? dirX : didSwipeY ? dirY : null,
				index,
				item: items[index],
				target: event.target,
			};
			props.onDrag(e);
		}

		setSprings(((i: number) => {
			if (index !== i) {
				return;
			}
			let sortedItem: ISortedInteractiveCategorizationItemInfo = null;
			if (didSwipeX) {
				sortedItem = {
					axis: 'x',
					direction: dirX,
					index: i,
					item: props.items[i],
				};
			}
			if (didSwipeY) {
				sortedItem = {
					axis: 'y',
					direction: dirY,
					index: i,
					item: props.items[i],
				};
			}
			if (!!sortedItem && !!props.onSwipeStart) {
				props.onSwipeStart(sortedItem);
			}
			return {
				...BaseAnimationProps,
				config: { friction: 50, tension: 800 },
				onRest: () => {
					if (!down) {
						if (sortedItem) {
							onSwipeFinished(sortedItem);
						}
						canRespondToActions = true;
					}
				},
				reset: false,
				rotation: down ? dx / 100 : 0,
				scale: 1,
				x: down ? dx || 0 : didSwipeX ? (200 + window.innerWidth) * dirX : 0,
				y: down ? dy || 0 : didSwipeY ? (200 + window.innerHeight) * dirY : 0,
			};
		}) as any);
	});

	React.useEffect(() => {
		// move the stack forward in z, staggered
		const indexesToOmit = new Set(sortedItems.map(x => x.index));
		const indexes = new Set(items.map((_, i) => (!indexesToOmit.has(i) ? i : -1)).filter(x => x !== -1));
		setSprings(((i: number) => {
			if (!indexes.has(i)) {
				return;
			}
			const index = i - indexesToOmit.size;
			return {
				...BaseAnimationProps,
				delay: index * 100,
				onRest: null as any,
				opacity: 1 - index * 0.2,
				reset: false,
				scale: 1,
				y: index * -20,
				z: index * -60,
			};
		}) as any);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortedItems]);

	React.useEffect(() => {
		if (props.items !== items) {
			setSortedItems([]);
			setItems(props.items);
			setSprings(((i: number) => {
				return {
					...OnMountToAnimationProps(i),
					from: {
						...BaseAnimationProps,
						scale: 1,
						...OnMountFromAnimationProps(i),
					},
					// eslint-disable-next-line react-hooks/exhaustive-deps
					onRest: () => (canRespondToActions = true),
					reset: true,
				};
			}) as any);
		}
	}, [props.items]);

	const actions: IStackActions = {
		swipeBottom: (index?: number) => {
			if (!canRespondToActions) {
				return null;
			}
			canRespondToActions = false;
			const promise = new Promise<void>(resolve => {
				setSprings(((i: number) => {
					if ((index >= 0 ? index : sortedItems.length) !== i) {
						return;
					}
					const y = 200 + window.innerHeight;
					const item = props.items[i];
					const sortedItem: ISortedInteractiveCategorizationItemInfo = {
						axis: 'y',
						direction: 1,
						index: i,
						item,
					};
					if (props.onSwipeStart) {
						props.onSwipeStart(sortedItem);
					}
					return {
						...BaseAnimationProps,
						config: BaseSpringConfig,
						onRest: () => {
							onSwipeFinished(sortedItem);
							setTimeout(() => {
								resolve();
							});
						},
						reset: false,
						rotation: Math.random() * 100 * (Math.floor(Math.random() * 10) % 2 === 0 ? -1 : 1),
						scale: 1,
						y,
					};
				}) as any);
			});
			promise.then(() => {
				canRespondToActions = true;
			});
			return promise;
		},
		swipeLeft: (index?: number) => {
			if (!canRespondToActions) {
				return null;
			}
			canRespondToActions = false;
			const promise = new Promise<void>(resolve => {
				setSprings(((i: number) => {
					if ((index >= 0 ? index : sortedItems.length) !== i) {
						return;
					}
					const x = -(200 + window.innerWidth);
					const item = props.items[i];
					const sortedItem: ISortedInteractiveCategorizationItemInfo = {
						axis: 'x',
						direction: -1,
						index: i,
						item,
					};
					if (props.onSwipeStart) {
						props.onSwipeStart(sortedItem);
					}
					return {
						...BaseAnimationProps,
						config: BaseSpringConfig,
						onRest: () => {
							onSwipeFinished(sortedItem);
							setTimeout(() => {
								resolve();
							});
						},
						reset: false,
						rotation: x / 100,
						scale: 1,
						x,
					};
				}) as any);
			});
			promise.then(() => {
				canRespondToActions = true;
			});
			return promise;
		},
		swipeRight: (index?: number) => {
			if (!canRespondToActions) {
				return null;
			}
			canRespondToActions = false;
			const promise = new Promise<void>(resolve => {
				setSprings(((i: number) => {
					if ((index >= 0 ? index : sortedItems.length) !== i) {
						return;
					}
					const x = 200 + window.innerWidth;
					const item = props.items[i];
					const sortedItem: ISortedInteractiveCategorizationItemInfo = {
						axis: 'x',
						direction: 1,
						index: i,
						item,
					};
					if (props.onSwipeStart) {
						props.onSwipeStart(sortedItem);
					}
					return {
						...BaseAnimationProps,
						config: BaseSpringConfig,
						onRest: () => {
							onSwipeFinished(sortedItem);
							setTimeout(() => {
								resolve();
							});
						},
						reset: false,
						rotation: x / 100,
						scale: 1,
						x,
					};
				}) as any);
			});
			promise.then(() => {
				canRespondToActions = true;
			});
			return promise;
		},
		undoLastSwipe: () => {
			const lastSort = sortedItems[sortedItems.length - 1];
			if (!canRespondToActions || !lastSort || props.items?.indexOf(lastSort.item) < 0) {
				return null;
			}
			canRespondToActions = false;
			const promise = new Promise<void>(resolve => {
				setSprings(((i: number) => {
					if (lastSort.index !== i) {
						return;
					}
					return {
						...BaseAnimationProps,
						config: BaseSpringConfig,
						onRest: () => {
							const sorted = [...sortedItems];
							const index = sorted.indexOf(lastSort);
							if (index >= 0) {
								sorted.splice(index, 1);
								setSortedItems(sorted);
							}
							setTimeout(() => {
								resolve();
							});
						},
						reset: false,
						x: 0,
						y: 0,
					};
				}) as any);
			});
			promise.then(() => {
				canRespondToActions = true;
			});
			return promise;
		},
	};

	if (props.onActions) {
		props.onActions(actions);
	}

	// last page could have less than first
	const springsToRender = springs.slice(0, items?.length || 0);
	return (
		<div className={css(styleSheet.stack, ...(props.styles || []))}>
			{springsToRender.map(({ x, y, z, rotation, scale, opacity }, i) => {
				const info: IInteractiveCategorizationItemInfo = {
					index: i,
					item: items[i],
				};
				const key = props.onKeyForItem ? props.onKeyForItem(info) || i : i;
				return (
					<animated.div
						className={css(...(props.itemStyles || []))}
						key={key}
						style={{
							transform: interpolate([x, y, z], CardTranslateTransform),
							zIndex: springs.length - i,
						}}
					>
						{props.onRenderItemLeftAccessory ? props.onRenderItemLeftAccessory(info) : null}
						<animated.div
							{...binds(i)}
							className={css(...(props.cardStyles || []))}
							style={{
								opacity,
								transform: interpolate([rotation, scale], CardRotateTransform),
							}}
						>
							{props.onRenderItem(info)}
						</animated.div>
						{props.onRenderItemRightAccessory ? props.onRenderItemRightAccessory(info) : null}
					</animated.div>
				);
			})}
		</div>
	);
};

class _InteractiveCategorization<T = any>
	extends React.Component<IProps<T>>
	implements IInteractiveCategorizationCallbacks
{
	private mStackActions: IStackActions;
	private mRootElementRef: React.RefObject<HTMLDivElement>;

	constructor(props: IProps) {
		super(props);
		this.state = { sortedItems: [] };
		this.mRootElementRef = React.createRef<HTMLDivElement>();
	}

	public componentDidMount() {
		const { disableKeyboardInput, onInnerRef } = this.props;
		if (!disableKeyboardInput) {
			this.mRootElementRef.current?.focus();
		}
		if (onInnerRef) {
			onInnerRef(this);
		}
	}

	public componentWillUnmount() {
		const { onInnerRef } = this.props;
		if (onInnerRef) {
			onInnerRef(null);
		}
	}

	public render() {
		const {
			className,
			disableKeyboardInput,
			items,
			loading,
			onRenderBottomOption,
			onRenderEmptyPlaceholder,
			onRenderItemsLeftAccessory,
			onRenderItemsRightAccessory,
			onRenderLeftOption,
			onRenderLoading,
			onRenderRightOption,
			styles,
		} = this.props;
		return (
			<div
				className={`${css(styleSheet.container, ...(styles || []))} interactive-categorization ${className || ''}`}
				onKeyDown={!disableKeyboardInput && this.onKeyDown}
				ref={this.mRootElementRef}
				tabIndex={0}
			>
				<div className={css(styleSheet.left)}>
					{this.renderArrow('left')}
					{onRenderLeftOption ? onRenderLeftOption(this.mStackActions) : null}
				</div>
				<div className={css(styleSheet.middle)}>
					{onRenderItemsLeftAccessory ? onRenderItemsLeftAccessory(this.mStackActions) : null}
					{this.renderItems()}
					{onRenderItemsRightAccessory ? onRenderItemsRightAccessory(this.mStackActions) : null}
					{!!loading && !!onRenderLoading ? onRenderLoading() : null}
					{!loading && items?.length === 0 && !!onRenderEmptyPlaceholder ? onRenderEmptyPlaceholder() : null}
				</div>
				<div className={css(styleSheet.right)}>
					{this.renderArrow('right')}
					{onRenderRightOption ? onRenderRightOption(this.mStackActions) : null}
				</div>
				{!!onRenderBottomOption && (
					<div className={css(styleSheet.bottom)}>
						{this.renderArrow('bottom')}
						{onRenderBottomOption(this.mStackActions)}
					</div>
				)}
			</div>
		);
	}

	private renderItems() {
		const { items, onRequestMore, itemsContainerStyles } = this.props;
		return (
			<ItemStack
				items={items || []}
				itemStyles={[styleSheet.cardContainer]}
				onActions={this.onStackActions}
				onKeyForItem={this.onKeyForItem}
				onRenderItem={this.onRenderItem}
				onRequestMore={onRequestMore}
				onSwipeEnd={this.onSwipeEnd}
				styles={itemsContainerStyles}
			/>
		);
	}

	private renderArrow(type: 'left' | 'right' | 'bottom') {
		const { disableArrowClicks } = this.props;
		let img: React.ReactNode = null;
		let style: StyleDeclarationValue = null;
		let onClick: () => void = null;
		switch (type) {
			case 'left': {
				img = (
					<img
						className={css(styleSheet.arrowLeft, disableArrowClicks ? styleSheet.arrowLeftButton : null)}
						src={ArrowRightIconImageUrl}
					/>
				);
				if (!disableArrowClicks) {
					style = styleSheet.arrowLeftButton;
					onClick = this.swipeLeft;
				}
				break;
			}
			case 'right': {
				img = (
					<img
						className={css(styleSheet.arrowRight, disableArrowClicks ? styleSheet.arrowRightButton : null)}
						src={ArrowRightIconImageUrl}
					/>
				);
				if (!disableArrowClicks) {
					style = styleSheet.arrowRightButton;
					onClick = this.swipeRight;
				}
				break;
			}
			case 'bottom': {
				img = (
					<img
						className={css(styleSheet.arrowBottom, disableArrowClicks ? styleSheet.arrowBottomButton : null)}
						src={ArrowBottomIconImageUrl}
					/>
				);
				if (!disableArrowClicks) {
					style = styleSheet.arrowBottomButton;
					onClick = this.swipeBottom;
				}
				break;
			}
			default: {
				break;
			}
		}

		if (disableArrowClicks) {
			return img;
		}
		return (
			<button className={css(style)} onClick={onClick}>
				{img}
			</button>
		);
	}

	private onSwipeEnd = (info: ISortedInteractiveCategorizationItemInfo) => {
		const { onSwipeEnd } = this.props;
		this.mRootElementRef.current?.focus();
		if (onSwipeEnd) {
			onSwipeEnd(info);
		}
	};

	private onKeyForItem = (info: IInteractiveCategorizationItemInfo) => {
		const { onKeyForItem } = this.props;
		if (onKeyForItem) {
			return onKeyForItem(info);
		}
		return null;
	};

	private onRenderItem = (info: IInteractiveCategorizationItemInfo) => {
		const { onRenderItem } = this.props;
		return onRenderItem(info);
	};

	private onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
		switch (e.key) {
			case 'Down':
			case 'ArrowDown': {
				this.mStackActions?.swipeBottom();
				break;
			}
			case 'Right':
			case 'ArrowRight': {
				this.mStackActions?.swipeRight();
				break;
			}
			case 'Left':
			case 'ArrowLeft': {
				this.mStackActions?.swipeLeft();
				break;
			}
			default: {
				break;
			}
		}
		this.mRootElementRef.current?.focus();
	};

	private onStackActions = (ref?: IStackActions) => {
		this.mStackActions = ref;
	};

	public swipeRight = () => {
		return this.mStackActions?.swipeRight();
	};

	public swipeLeft = () => {
		return this.mStackActions?.swipeLeft();
	};

	public swipeBottom = () => {
		return this.mStackActions?.swipeBottom();
	};

	public undoLastSwipe = () => {
		return this.mStackActions?.undoLastSwipe();
	};
}

export const InteractiveCategorization = withEventLogging(_InteractiveCategorization, 'InteractiveCategorization');
