import { baseStyleSheet } from '../../styles/styles';
import { styleSheet } from './styles';
import { StyleDeclarationValue, css } from 'aphrodite';
import { CSSProperties } from 'react';

export const GhostImage = ({
	style,
	stylesDeclarations,
}: {
	style?: CSSProperties;
	stylesDeclarations?: StyleDeclarationValue[];
}) => {
	return (
		<div
			style={{ ...style }}
			className={css(baseStyleSheet.ghostReverseBackgroundAnimation, styleSheet.image, ...(stylesDeclarations || []))}
		>
			<div className={css(baseStyleSheet.shimmer)} />
		</div>
	);
};
