import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { useToaster } from '../../../../../models/hooks/appStateHooks';
import { getDefaultDateStringValue, getDisplayName } from '../../../../../models/UiUtils';
import { useCreateInsurancePolicyMutation, useUpdateInsurancePolicyMutation } from '../../../../../queries';
import CalendarIconUrl from '../../../../assets/icon_calendar.svg';
import { SimpleAutoCompleteSearchField } from '../../../../components/autocomplete/SimpleAutoCompleteSearchField';
import { CloseButton } from '../../../../components/CloseButton';
import { PolicyCarrierSearch } from '../../../../components/dataBoards/policies/PolicyCarrierSearch';
import { PolicyLineOfBusinessSearch } from '../../../../components/dataBoards/policies/PolicyLineOfBusinessSearch';
import { DayPicker } from '../../../../components/DayPicker';
import { Modal } from '../../../../components/Modal';
import { Popover, PopoverType } from '../../../../components/Popover';
import { ClearFieldIcon } from '../../../../components/svgs/icons/ClearFieldIcon';
import { PolicyIcon } from '../../../../components/svgs/icons/PolicyIcon';
import { TextInput } from '../../../../components/TextInput';
import { white } from '../../../../styles/colors';
import { baseStyleSheet } from '../../../../styles/styles';
import { styleSheet } from '../../EditModal/styles';

export const EditPolicyModal = ({
	initialPolicy,
	isOpen,
	setIsOpen,
	onSave,
	onCancel,
}: {
	initialPolicy: Api.IInsurancePolicy;
	isOpen: boolean;
	setIsOpen: (newValue: boolean) => void;
	onSave: (policy: Api.IInsurancePolicy) => void;
	onCancel: () => void;
}) => {
	const toaster = useToaster();
	const [contactName, setContactName] = React.useState(initialPolicy.contact?.handle ?? '');
	const [contact, setContact] = React.useState<Api.IContact>(initialPolicy.contact ?? null);
	const [ownerName, setOwnerName] = React.useState(initialPolicy.owner ? getDisplayName(initialPolicy.owner) : '');
	const [owner, setOwner] = React.useState<Api.IContact>(initialPolicy.owner ?? null);
	const [premium, setPremium] = React.useState<string>(initialPolicy.premium?.toString() ?? '');
	const [carrier, setCarrier] = React.useState<Api.IInsurancePolicyCarrier>(initialPolicy.carrier ?? null);
	const [policyNumber, setPolicyNumber] = React.useState<string>(initialPolicy.policyNumber ?? '');
	const [lineOfBusiness, setLineOfBusiness] = React.useState<Api.IInsurancePolicyLineOfBusiness>(
		initialPolicy.lineOfBusiness ?? null
	);
	const [renewalDate, setRenewalDate] = React.useState<Date>(
		initialPolicy.renewalDate ? moment(initialPolicy.renewalDate).toDate() : null
	);
	const [effectiveDate, setEffectiveDate] = React.useState<Date>(
		initialPolicy.effectiveDate ? moment(initialPolicy.effectiveDate).toDate() : null
	);
	const [originationDate, setOriginationDate] = React.useState<Date>(
		initialPolicy.originationDate ? moment(initialPolicy.originationDate).toDate() : null
	);
	const [renewalDatePickerOpen, setRenewalDatePickerOpen] = React.useState(false);
	const [effectiveDatePickerOpen, setEffectiveDatePickerOpen] = React.useState(false);
	const [originationDatePickerOpen, setOriginationDatePickerOpen] = React.useState(false);

	const isEditing = !!initialPolicy?.id;

	const createPolicyMutation = useCreateInsurancePolicyMutation({
		onSuccess: (policy: Api.IInsurancePolicy) => {
			toaster.push({
				message: 'Policy successfully created',
				type: 'successMessage',
			});
			onSave(policy);
		},
	});

	const updatePolicyMutation = useUpdateInsurancePolicyMutation({
		onSuccess: (policy: Api.IInsurancePolicy) => {
			toaster.push({
				message: 'Policy successfully updated',
				type: 'successMessage',
			});
			onSave(policy);
		},
	});

	const save = () => {
		const carrierId = carrier?.id;
		const lineOfBusinessId = lineOfBusiness?.id;
		const ownerId = owner.id;
		const contactId = contact.id;

		const updatedPolicy = {
			...initialPolicy,
			premium: parseFloat(premium),
			carrierId,
			renewalDate: moment(renewalDate).format('YYYY-MM-DD'),
			effectiveDate: moment(effectiveDate).format('YYYY-MM-DD'),
			originationDate: moment(originationDate).format('YYYY-MM-DD'),
			policyNumber,
			contactId,
			lineOfBusinessId,
			ownerId,
		};

		const payload = { policy: updatedPolicy };
		if (isEditing) {
			updatePolicyMutation.mutateAsync(payload);
			return;
		}
		createPolicyMutation.mutateAsync(payload);
	};

	const cancel = () => {
		onCancel();
		setIsOpen(false);
	};

	const onContactCleared = () => {
		if (contactName === getDisplayName(contact)) {
			setContactName('');
		}

		setContact(null);
	};
	const onContactSelected = ({ selection }: { selection: Api.IEntity }) => {
		setContact(selection);

		if (!contactName) {
			setContactName(getDisplayName(selection));
		}
		if (!owner) {
			// TODO: Fetch the owner and set it on the policy if not already set
		}
	};

	const onOwnerCleared = () => {
		if (ownerName === getDisplayName(owner)) {
			setOwnerName('');
		}

		setOwner(null);
	};
	const onOwnerSelected = ({ selection }: { selection: Api.IEntity }) => {
		setOwner(selection);

		if (!ownerName) {
			setOwnerName(getDisplayName(selection));
		}
	};

	const toggleRenewalDatePickerOpen = () => {
		setRenewalDatePickerOpen(!renewalDatePickerOpen);
	};
	const toggleEffectiveDatePickerOpen = () => {
		setEffectiveDatePickerOpen(!effectiveDatePickerOpen);
	};
	const toggleOriginationDatePickerOpen = () => {
		setOriginationDatePickerOpen(!originationDatePickerOpen);
	};

	const clearDate = (e: React.MouseEvent<HTMLElement>, method: React.Dispatch<React.SetStateAction<Date>>) => {
		// The clear button is nested in a clickable control, do not open the calendar if clear is clicked
		e.stopPropagation();
		e.preventDefault();

		method(null);
	};

	const clearRenewalDate = (e: React.MouseEvent<HTMLElement>) => {
		clearDate(e, setRenewalDate);
	};
	const clearEffectiveDate = (e: React.MouseEvent<HTMLElement>) => {
		clearDate(e, setEffectiveDate);
	};
	const clearOriginationDate = (e: React.MouseEvent<HTMLElement>) => {
		clearDate(e, setOriginationDate);
	};

	const selectPolicyLineOfBusiness = (selectedLineOfBusiness: Api.IInsurancePolicyLineOfBusiness) => {
		setLineOfBusiness(selectedLineOfBusiness);
	};

	const selectCarrier = (selectedCarrier: Api.IInsurancePolicyCarrier) => {
		setCarrier(selectedCarrier);
	};

	const queryIsLoading = updatePolicyMutation.isLoading || createPolicyMutation.isLoading;
	const canSave =
		!!contactName &&
		!!contact &&
		!!owner &&
		!!premium &&
		parseFloat(premium) >= 0 &&
		!!renewalDate &&
		!!effectiveDate &&
		!!originationDate &&
		!queryIsLoading;

	const onClose = () => {
		onCancel();
		setIsOpen(false);
	};

	return (
		<Modal
			className={css(styleSheet.modalContainer)}
			isOpen={isOpen}
			headerAccessory={
				<div className={css(styleSheet.modalHeader)}>
					<PolicyIcon height={18} width={20} />
					<CloseButton onClick={onClose} fillColor={white} />
				</div>
			}
			onRequestClose={cancel}
		>
			<article className={css(styleSheet.container, styleSheet.wideContainer)}>
				<h3 className={css(styleSheet.header)}>{`${isEditing ? 'Edit' : 'Add New'} Policy`}</h3>

				<div className={css(styleSheet.twoColumnLayout)}>
					<div className={css(styleSheet.column)}>
						<div className={css(styleSheet.formControl)}>
							<label className={css(styleSheet.label)}>Policy Holder Name / Company</label>

							<div className={css(styleSheet.formControlInputWrap)}>
								<SimpleAutoCompleteSearchField
									onClear={onContactCleared}
									// @ts-ignore
									onItemSelected={onContactSelected}
									resultsLimit={5}
									pageSize={5}
									placeholder='Search'
									style={styleSheet.contactSearchField}
									type={Api.ResourceAutoCompleteViewModelType.Contact}
									initialSearchQuery={initialPolicy.contact ? getDisplayName(initialPolicy.contact) : ''}
								/>
							</div>
						</div>

						<div className={css(styleSheet.formControl)}>
							<label className={css(styleSheet.label)}>Policy Owner</label>
							<SimpleAutoCompleteSearchField
								onClear={onOwnerCleared}
								// @ts-ignore
								onItemSelected={onOwnerSelected}
								resultsLimit={5}
								pageSize={5}
								placeholder='Search'
								type={Api.ResourceAutoCompleteViewModelType.User}
								initialSearchQuery={initialPolicy.owner ? getDisplayName(initialPolicy.owner) : ''}
							/>
						</div>

						<div className={css(styleSheet.formControl)}>
							<label className={css(styleSheet.label)}>Line of Business</label>

							<PolicyLineOfBusinessSearch
								onSelectLineOfBusiness={selectPolicyLineOfBusiness}
								initialSearchFragment={lineOfBusiness?.name ?? ''}
								onCreateLineOfBusiness={selectPolicyLineOfBusiness}
								className={css(baseStyleSheet.fullWidth)}
							/>
						</div>

						<div className={css(styleSheet.formControl)}>
							<label className={css(styleSheet.label)}>Carrier</label>

							<PolicyCarrierSearch
								onSelectCarrier={selectCarrier}
								initialSearchFragment={initialPolicy?.carrier?.name ?? ''}
								onCreateCarrier={selectCarrier}
								className={css(baseStyleSheet.fullWidth)}
							/>
						</div>

						<div className={css(styleSheet.formControl)}>
							<label className={css(styleSheet.label)}>Premium Amount</label>

							<div className={css(styleSheet.formControlInputWrap)}>
								<TextInput
									inputId='policy-modal-edit-premium'
									onChange={e => setPremium(e.target.value)}
									type='number'
									value={premium}
									placeholder='$0'
									min={0}
								/>
							</div>
						</div>
					</div>

					<div className={css(styleSheet.column)}>
						<div className={css(styleSheet.formControl)}>
							<label className={css(styleSheet.label)}>Policy Number</label>

							<div className={css(styleSheet.formControlInputWrap)}>
								<TextInput
									inputId='policy-modal-edit-policy-number'
									onChange={e => setPolicyNumber(e.target.value)}
									type='text'
									value={policyNumber}
								/>
							</div>
						</div>

						<div className={css(styleSheet.formControl)}>
							<label className={css(styleSheet.label)}>Origination Date</label>

							<div
								className={css(styleSheet.formControlInputWrap, styleSheet.clickable)}
								onClick={toggleOriginationDatePickerOpen}
							>
								<div className={css(styleSheet.dateContainer)}>
									<div className={css(styleSheet.dateFieldButton)}>
										<Popover
											anchor={<img src={CalendarIconUrl} />}
											dismissOnClickOutside={true}
											isOpen={originationDatePickerOpen}
											onRequestClose={toggleOriginationDatePickerOpen}
											preferredPlacement='above'
											type={PopoverType.white}
										>
											<DayPicker
												allowPastDates={true}
												onDayClick={(value: Date) => setOriginationDate(value)}
												selectedDays={originationDate}
											/>
										</Popover>
										{!!originationDate && <span>{getDefaultDateStringValue(originationDate)}</span>}
									</div>
									<button onClick={(e: React.MouseEvent<HTMLElement>) => clearOriginationDate(e)}>
										<ClearFieldIcon />
									</button>
								</div>
							</div>
						</div>

						<div className={css(styleSheet.formControl)}>
							<label className={css(styleSheet.label)}>Effective Date</label>

							<div
								className={css(styleSheet.formControlInputWrap, styleSheet.clickable)}
								onClick={toggleEffectiveDatePickerOpen}
							>
								<div className={css(styleSheet.dateContainer)}>
									<div className={css(styleSheet.dateFieldButton)}>
										<Popover
											anchor={<img src={CalendarIconUrl} />}
											dismissOnClickOutside={true}
											isOpen={effectiveDatePickerOpen}
											onRequestClose={toggleEffectiveDatePickerOpen}
											preferredPlacement='above'
											type={PopoverType.white}
										>
											<DayPicker
												allowPastDates={true}
												onDayClick={(value: Date) => setEffectiveDate(value)}
												selectedDays={effectiveDate}
											/>
										</Popover>
										{!!effectiveDate && <span>{getDefaultDateStringValue(effectiveDate)}</span>}
									</div>
									<button onClick={(e: React.MouseEvent<HTMLElement>) => clearEffectiveDate(e)}>
										<ClearFieldIcon />
									</button>
								</div>
							</div>
						</div>

						<div className={css(styleSheet.formControl)}>
							<label className={css(styleSheet.label)}>Renewal Date</label>

							<div
								className={css(styleSheet.formControlInputWrap, styleSheet.clickable)}
								onClick={toggleRenewalDatePickerOpen}
							>
								<div className={css(styleSheet.dateContainer)}>
									<div className={css(styleSheet.dateFieldButton)}>
										<Popover
											anchor={<img src={CalendarIconUrl} />}
											dismissOnClickOutside={true}
											isOpen={renewalDatePickerOpen}
											onRequestClose={toggleRenewalDatePickerOpen}
											preferredPlacement='above'
											type={PopoverType.white}
										>
											<DayPicker
												allowPastDates={true}
												onDayClick={(value: Date) => setRenewalDate(value)}
												selectedDays={renewalDate}
											/>
										</Popover>
										{!!renewalDate && <span>{getDefaultDateStringValue(renewalDate)}</span>}
									</div>
									<button onClick={(e: React.MouseEvent<HTMLElement>) => clearRenewalDate(e)}>
										<ClearFieldIcon />
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<footer className={css(styleSheet.footer)}>
					<button className={css(baseStyleSheet.ctaButton)} onClick={save} disabled={!canSave}>
						Save
					</button>
					<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={cancel}>
						Cancel
					</button>
				</footer>
			</article>
		</Modal>
	);
};
