import { titles } from '../../../../styles/colors';
import { CssSelectors, CtaButtons, absoluteCenterStyle, requiredStyle } from '../../../../styles/styles';
import SingleEmailGuideComposerKeyFactsPopoverImageUrl from './singleEmailGuideComposerKeyFactsPopover.svg';
import SingleEmailGuideComposerSubjectPopoverImageUrl from './singleEmailGuideComposerSubjectPopover.svg';
import { CSSProperties, StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	body: {},
	bodyText: {
		color: titles,
		fontSize: 14,
	},
	conclusionInput: {
		height: 110,
		marginTop: 20,
		resize: 'none',
	},
	container: {},
	descriptionExamples: {
		fontSize: 14,
		marginTop: 10,
		...CssSelectors.allChildren(
			{
				marginTop: 10,
			},
			':not(:first-child)'
		),
	},
	firstLine: {
		marginTop: 20,
	},
	floatingPopover: {
		maxWidth: 300,
		padding: 20,
	},
	footer: {
		alignItem: 'center',
		display: 'flex',
		marginTop: 30,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	keyFactsDescription: {
		borderImage: `url(${SingleEmailGuideComposerKeyFactsPopoverImageUrl}) 20 20 32 20 fill`,
		borderStyle: 'solid',
		borderWidth: '20px 20px 32px 20px',
		marginBottom: 4,
		marginTop: 20,
	},
	keyFactsExamples: {
		marginTop: 20,
	},
	keyFactsInput: {
		height: 150,
		resize: 'none',
	},
	keyFactsPreview: {
		marginTop: 20,
		whiteSpace: 'pre-wrap',
	},
	nextButton: {
		...(CtaButtons.ctaButton as CSSProperties),
	},
	personalUpdateInput: {
		height: 110,
		padding: 0,
	},
	personalUpdateInputContainer: {
		marginTop: 20,
		position: 'relative',
	},
	personalUpdatePopoverExamples: {
		marginTop: 30,
	},
	personalUpdatePreview: {
		marginTop: 20,
		whiteSpace: 'pre-wrap',
	},
	popoverCloseButton: {},
	popoverContent: {
		color: '#fff',
		fontSize: 16,
		fontStyle: 'italic',
	},
	popoverHeader: {
		marginBottom: 8,
		textAlign: 'right',
	},
	popoverMessageHeader: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren(
			{
				flexShrink: 0,
			},
			'svg'
		),
		...CssSelectors.allChildren(
			{
				marginLeft: 20,
				...CssSelectors.allChildren(
					{
						fontSize: 14,
					},
					':not(:first-child)'
				),
			},
			':not(:first-child)'
		),
	},
	popoverMessageHeaderTitle: {
		fontWeight: 600,
	},
	skipButton: {
		...(CtaButtons.ctaButtonReverse as CSSProperties),
	},
	stepTitle: {
		color: titles,
		fontSize: 18,
	},
	subjectDescription: {
		borderImage: `url(${SingleEmailGuideComposerSubjectPopoverImageUrl}) 32 20 20 20 fill`,
		borderStyle: 'solid',
		borderWidth: '32px 20px 20px 20px',
		marginTop: 4,
		position: 'relative',
	},
	subjectField: {
		marginTop: 26,
	},
	subjectFieldLeftAccessory: {
		color: '#858585',
		fontSize: 12,
		paddingRight: 8,
		...CssSelectors.allChildren(
			{
				...requiredStyle,
			},
			':not(:first-child)'
		),
	},
	templateLoading: {
		...absoluteCenterStyle,
	},
});
