import { ResourceTablePlaceholder } from '../../ResourceTablePlaceholder';
import { SvgIcon } from '../../svgs/icons/SvgIcon';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	hideClearButton?: boolean;
	message?: React.ReactNode;
	onClearButtonClicked?(): void;
	type: 'contact' | 'company';
}

export const EntityTablePlaceholder: React.FC<IProps> = props => {
	const { className, onClearButtonClicked, type, hideClearButton, message } = props;
	const computedClass = `${css(styleSheet.container)} entity-table-placeholder ${className || ''}`;

	return (
		<ResourceTablePlaceholder
			className={computedClass}
			emptyMessage={message}
			emptyText='We did not find anyone matching your search criteria.'
			icon={
				<SvgIcon height={180} width={180}>
					<defs>
						<circle id='b-a' cx='95' cy='90' r='90' />
						<linearGradient id='b-c' x1='-14.748%' x2='62.526%' y1='106.162%' y2='21.257%'>
							<stop offset='0%' stopColor='#00D5F5' stopOpacity='0' />
							<stop offset='97.029%' stopColor='#00AAE8' />
						</linearGradient>
					</defs>
					<g fill='none' fillRule='evenodd' transform='translate(-5)'>
						<mask id='b-b' fill='#fff'>
							<use xlinkHref='#b-a' />
						</mask>
						<use fill='#F2FBFF' fillRule='nonzero' xlinkHref='#b-a' />
						<g mask='url(#b-b)'>
							<g transform='translate(0 38)'>
								<polygon fill='url(#b-c)' points='31 1 0 67 0 143 147 144 164 105' opacity='.225' />
								<path
									fill='#FFF'
									stroke='#00AAE8'
									strokeWidth='2'
									d='M31,85 L31,104 C53.5052799,104 53.8669292,104 79,104 C90.0185185,104 90.6306584,104 96,104 L96,100 C96,91.7157288 89.2842712,85 81,85 L31,85 Z'
								/>
								<path
									fill='#FFF'
									stroke='#00AAE8'
									strokeWidth='2'
									d='M31,85 L31,99 C53.5052799,99 53.8669292,99 79,99 C86.6219003,99 92.2923463,100.269799 96,102.866713 L96,100 C96,91.7157288 89.2842712,85 81,85 L31,85 Z'
								/>
								<path
									fill='#FFF'
									stroke='#00AAE8'
									strokeWidth='2'
									d='M31,85 L31,94 C53.5052799,94 53.8669292,94 79,94 C86.5902075,94 92.2901419,96.4947024 96,101.46186 L96,100 C96,91.7157288 89.2842712,85 81,85 L31,85 Z'
								/>
								<path
									fill='#FFF'
									stroke='#00AAE8'
									strokeWidth='2'
									d='M31,1 L31,89 C53.5052799,89 53.8669292,89 79,89 C86.622496,89 92.3141567,92.6953625 96.0000009,99.9614135 L96,16 C96,7.71572875 89.2842712,1 81,1 L31,1 Z'
								/>
								<path
									fill='#FFF'
									stroke='#00AAE8'
									strokeWidth='2'
									d='M98,85 L98,104 C120.50528,104 120.866929,104 146,104 C157.018519,104 157.630658,104 163,104 L163,100 C163,91.7157288 156.284271,85 148,85 L98,85 Z'
									transform='matrix(-1 0 0 1 261 0)'
								/>
								<path
									fill='#FFF'
									stroke='#00AAE8'
									strokeWidth='2'
									d='M98,85 L98,99 C120.50528,99 120.866929,99 146,99 C153.6219,99 159.292346,100.269799 163,102.866713 L163,100 C163,91.7157288 156.284271,85 148,85 L98,85 Z'
									transform='matrix(-1 0 0 1 261 0)'
								/>
								<path
									fill='#FFF'
									stroke='#00AAE8'
									strokeWidth='2'
									d='M98,85 L98,94 C120.50528,94 120.866929,94 146,94 C153.590208,94 159.290142,96.4947024 163,101.46186 L163,100 C163,91.7157288 156.284271,85 148,85 L98,85 Z'
									transform='matrix(-1 0 0 1 261 0)'
								/>
								<path
									fill='#FFF'
									stroke='#00AAE8'
									strokeWidth='2'
									d='M98,1 L98,89 C120.50528,89 120.866929,89 146,89 C153.622496,89 159.314157,92.6953625 163.000001,99.9614135 L163,16 C163,7.71572875 156.284271,1 148,1 L98,1 Z'
									transform='matrix(-1 0 0 1 261 0)'
								/>
								<polygon fill='#00AAE8' points='67 94 67 110 72 108 77 110 77 94' />
								{type === 'contact' ? (
									<g stroke='#00AAE8' strokeWidth='2' transform='translate(50 13)'>
										<circle cx='12' cy='12' r='12' fill='#FFF' />
										<g strokeLinecap='round' strokeLinejoin='round' transform='translate(7 6)'>
											<path d='M9.6969697,10.5686275 L9.6969697,9.41830065 C9.6969697,8.14768476 8.61159939,7.11764706 7.27272727,7.11764706 L2.42424242,7.11764706 C1.0853703,7.11764706 1.34572488e-16,8.14768476 0,9.41830065 L0,10.5686275' />
											<ellipse cx='5.091' cy='2.301' rx='2.424' ry='2.301' />
										</g>
									</g>
								) : (
									<>
										<circle cx='62' cy='24' r='12' fill='#FFF' stroke='#00AAE8' strokeWidth='2' />
										<g strokeLinecap='round' strokeLinejoin='round' transform='translate(55 17)'>
											<rect width='14' height='9.437' y='2.696' stroke='#00AAE8' strokeWidth='2' rx='1.778' />
											<path
												stroke='#00AAE8'
												strokeWidth='2'
												d='M9.8,12.1333333 L9.8,1.34814815 C9.8,0.603586485 9.17319865,0 8.4,0 L5.6,0 C4.82680135,0 4.2,0.603586485 4.2,1.34814815 L4.2,12.1333333'
											/>
										</g>
									</>
								)}
								<rect width='21' height='3' x='51' y='44' fill='#00AAE8' rx='1.5' />
								<rect width='15' height='3' x='51' y='50' fill='#00AAE8' rx='1.5' />
								<rect width='6' height='3' x='49' y='60' fill='#DBF2C3' rx='1.5' />
								<rect width='10' height='3' x='57' y='60' fill='#DBF2C3' rx='1.5' />
								<rect width='10' height='3' x='49' y='65' fill='#DBF2C3' rx='1.5' />
								<rect width='8' height='3' x='69' y='60' fill='#DBF2C3' rx='1.5' />
								<rect width='8' height='3' x='61' y='65' fill='#DBF2C3' rx='1.5' />
								<path
									fill='#D3DBDE'
									d='M114.5 63L147.5 63C148.328427 63 149 63.6715729 149 64.5L149 64.5C149 65.3284271 148.328427 66 147.5 66L114.5 66C113.671573 66 113 65.3284271 113 64.5L113 64.5C113 63.6715729 113.671573 63 114.5 63zM114.5 69L142.5 69C143.328427 69 144 69.6715729 144 70.5L144 70.5C144 71.3284271 143.328427 72 142.5 72L114.5 72C113.671573 72 113 71.3284271 113 70.5L113 70.5C113 69.6715729 113.671573 69 114.5 69z'
								/>
								<rect width='6' height='37' x='113' y='21' fill='#DBF2C3' />
								<rect width='6' height='29' x='123' y='29' fill='#DBF2C3' />
								<rect width='6' height='26' x='133' y='32' fill='#DBF2C3' />
								<rect width='6' height='34' x='143' y='24' fill='#89C947' />
							</g>
						</g>
					</g>
				</SvgIcon>
			}
			hideClearButton={hideClearButton}
			onClearButtonClicked={onClearButtonClicked}
		/>
	);
};
