import { StyleSheet } from 'aphrodite';
import { background, brandPrimary, white } from '../../../styles/colors';

export const searchHeaderHeight = 80;

export const styleSheet = StyleSheet.create({
	clear: {
		color: brandPrimary,
		marginLeft: 10,
	},
	dateSelect: {
		width: 300,
		borderRadius: 6,
		marginLeft: 10,
		marginRight: 10,
	},
	header: {
		alignItems: 'center',
		background: white,
		display: 'flex',
		height: searchHeaderHeight,
		justifyContent: 'space-between',
	},
	headerSearchWrap: {
		display: 'flex',
	},
	icon: {
		marginRight: 10,
	},
	searchContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
	searchField: {
		alignItems: 'center',
		background,
		border: '1px solid #ccc',
		borderRadius: 6,
		display: 'flex',
		fontSize: 14,
		width: 260,
	},
	sortSelect: {
		width: 500,
		borderRadius: 6,
	},
});
