import * as Api from '@ViewModels';
import { AutomationStepIcon } from '../../svgs/icons/AutomationStepIcon';

export function AutomationStepIcons({ visibleSteps }: { visibleSteps: Api.IBaseApiModel[] }) {
	return (
		<>
			{visibleSteps.map(step => {
				return <AutomationStepIcon key={step.id} type={step._type as Api.AutomationStepModelType} />;
			})}
		</>
	);
}
