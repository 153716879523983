import { StyleSheet } from 'aphrodite';
import { destructiveHover, error, label } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	containerFooter: {
		marginTop: 30,
	},
	containerHeader: {
		marginBottom: 30,
		textAlign: 'center',
		...CssSelectors.allDescendants(
			{
				fontSize: 18,
			},
			'h2'
		),
		...CssSelectors.allDescendants(
			{
				fontSize: 16,
			},
			'p'
		),
		...CssSelectors.allDescendants(
			{
				display: 'inline-flex',
				marginTop: 30,
			},
			'button'
		),
	},
	formErrorLine: {
		color: error,
		fontSize: '0.75rem',
		marginTop: 0,
	},
	headerImage: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: 40,
		marginTop: 30,
		width: '100%',
		...CssSelectors.allDescendants(
			{
				width: 260,
			},
			'img'
		),
	},
	label: {
		color: label,
		display: 'block',
		fontSize: 14,
		letterSpacing: 0,
		marginBottom: 5,
		marginTop: 15,
	},
	labelRequired: {
		'::after': {
			color: destructiveHover,
			content: '"*"',
			display: 'inline',
			marginLeft: '0.25rem',
		},
	},
	loading: {
		marginTop: 100,
	},
});
