import { titles } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	button: {
		display: 'block',
		margin: '0 auto',
		marginTop: 60,
	},
	container: {},
	content: {
		height: '100%',
	},
	graphic: {
		display: 'block',
		margin: '0 auto',
	},
	subtitle: {
		color: '#4A4A4A',
		fontSize: 14,
		marginTop: 15,
		textAlign: 'center',
	},
	title: {
		color: titles,
		fontSize: 24,
		marginTop: 28,
		textAlign: 'center',
	},
});
