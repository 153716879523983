import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		display: 'inline-block',
	},
	content: {
		position: 'relative',
	},
	contentBackground: {
		left: 0,
		position: 'absolute',
		top: 0,
	},
	value: {},
});
