import { titles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	body: {
		color: '#8E8E8E',
		display: 'flex',
		flexWrap: 'wrap',
		fontSize: 14,
	},
	container: {},
	footer: {
		boxSizing: 'border-box',
		padding: '10px 0',
	},
	header: {
		alignItems: 'center',
		color: titles,
		display: 'flex',
		fontSize: 18,
		marginBottom: 16,
		...CssSelectors.allChildren(
			{
				marginLeft: 14,
			},
			':not(:first-child)'
		),
	},
	icon: {
		height: 35,
		width: 35,
	},
	link: {},
	subject: {
		flexShrink: 1,
		maxWidth: '25%',
		minWidth: 0,
	},
});
