import { StyleSheet } from 'aphrodite';
import { header } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	container: {
		maxWidth: 625,
		padding: '0 40px 20px',
	},
	header: {
		fontSize: 22,
		textAlign: 'center',
	},
	headerImage: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: 30,
		marginTop: 40,
		width: '100%',
		...CssSelectors.allDescendants(
			{
				width: 270,
			},
			'img'
		),
	},
	text: {
		color: header,
		fontSize: 14,
		padding: '10px 0',
		textAlign: 'justify',
	},
});
