import { StyleSheet } from 'aphrodite';
import { hotPink, titles } from '../../../styles/colors';
import { CssSelectors, cardStyle, truncateTextStyle } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	anchorContainerExpirySocial: {
		paddingRight: 10,
	},
	body: {
		background: 'transparent !important',
		color: '#3F3F3F !important',
		fontFamily: 'Sora !important',
		fontSize: '14px !important',
		marginTop: 0,
		overflow: 'hidden',
	},
	button: {
		marginTop: 20,
	},
	card: {
		...cardStyle,
		background: 'linear-gradient(160deg, #F9F9F9 0%, #F9F9F9 39%, #F9F9F9 100%)',
		border: 'none',
		borderBottom: '1px solid #d3dbde',
		borderRadius: 0,
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6,
		boxSizing: 'border-box',
		color: '#0d0f11',
		display: 'flex',
		fontWeight: 600,
		overflow: 'hidden',
		padding: '15px 20px',
		textOverflow: 'ellipsis',
		width: '100%',
	},
	cardSocial: {
		background: 'linear-gradient(160deg, #F5FAFD 0%, #E8F3F9 39%, #E8F3F9 100%)',
	},
	cardTitle: {
		color: titles,

		fontSize: 16,
		marginBottom: 6,
	},
	clickNav: {
		':hover': {
			cursor: 'pointer',
		},
	},
	container: {
		alignItems: 'stretch',
		background: '#FFFFFF',
		border: '1px solid #F2F2F2',
		borderRadius: 6,
		boxShadow: '0px 0px 6px rgba(139, 139, 139, 0.420034)',
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		padding: 20,
		position: 'relative',
		width: 330,
		...CssSelectors.allChildren(
			{
				maxWidth: 145,
			},
			'button'
		),
	},
	containerPost: {
		border: '1px solid #F2F2F2',
		borderRadius: 6,
		boxShadow: '0px 0px 6px rgba(139, 139, 139, 0.420034)',
		boxSizing: 'border-box',
		marginBottom: 30,
		padding: 0,
		...CssSelectors.allChildren(
			{
				marginBottom: 12,
				marginLeft: 20,
				marginTop: 12,
			},
			'button'
		),
	},
	containerPostIndicator: {
		borderTopRightRadius: 0,
	},
	flagHighlight: {
		height: 27,
		width: 27,
	},
	imageContainer: {
		alignItems: 'center',
		background: '#f9f9f9',
		display: 'flex',
		height: 210,
		justifyContent: 'center',
		margin: 0,
		overflow: 'hidden',
		padding: 0,
		paddingBottom: 24,
		position: 'relative',
		width: '100%',
		...CssSelectors.allChildren(
			{
				left: 0,
				width: '100%',
			},
			'img'
		),
	},
	imageFit: {
		objectFit: 'cover',
	},
	name: {
		borderTopLeftRadius: 9,
		borderTopRightRadius: 9,
		boxSizing: 'border-box',
		color: titles,
		display: 'flex',

		fontSize: 16,
		paddingTop: 10,
		width: '100%',
		...truncateTextStyle,
	},
	newItem: {
		color: hotPink,
	},
	popover: {
		borderBottomRightRadius: 0,
		boxSizing: 'border-box',
		color: '#4A4A4A',
		fontSize: 12,
		lineHeight: '18px',
		marginLeft: 8,
		maxWidth: 300,
		padding: '6px 6px 6px 6px',
	},
	popoverContainer: {
		background: 'linear-gradient(160deg, #F9F9F9 0%, #F9F9F9 39%, #F9F9F9 100%)',
		display: 'flex',
		maxWidth: '100%',
		...CssSelectors.allChildren(
			{
				display: 'flex',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
			},
			'div'
		),
	},
	postName: {
		paddingLeft: 0,
		paddingTop: 0,
		width: '100%',
	},
	socialPopoverContainer: {
		marginLeft: '2px',
		width: '120px',
	},
	summaryPadding: {
		padding: 20,
		paddingBottom: 10,
	},
	textContainer: {
		height: 74,
	},
});
