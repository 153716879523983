import { CSSProperties, StyleSheet } from 'aphrodite';
import { brandPrimary, heartRed } from '../../../styles/colors';
import { CssSelectors, Fonts } from '../../../styles/styles';

export const AppBarHeight = 96;

export const AppBarCssProperties: CSSProperties = {
	boxSizing: 'border-box',
	height: AppBarHeight,
	minHeight: AppBarHeight,
	zIndex: 5,
};

export const styleSheet = StyleSheet.create({
	appBar: {
		...AppBarCssProperties,
		'@media (max-width: 1200px)': {
			padding: '0 1rem',
		},
		padding: '0 2rem',
	},
	appBarContent: {
		alignItems: 'center',
		boxSizing: 'border-box',
		display: 'flex',
		height: '100%',
		position: 'relative',
	},
	heartIcon: {
		fill: heartRed,
	},
	helpLink: {
		alignItems: 'center',
		display: 'flex',
		fontFamily: Fonts.lexend,
	},
	helpText: {
		fill: '#fff',
		fontSize: 14,
	},
	leftContainer: {
		'@media (min-width: 1200px)': {
			flex: 2,
		},
		alignItems: 'center',
		display: 'flex',
		flex: 1,
	},
	rightAccessoryContainer: {
		alignItems: 'center',
		display: 'flex',
		flex: '0 1 280px',
		justifyContent: 'center',
		marginLeft: '2rem',
		...CssSelectors.allChildren(
			{
				marginLeft: 20,
			},
			':not(:first-child)'
		),
	},
	userProfileNav: {},
	welcomeMessage: {
		color: brandPrimary,
		flex: 1,
		marginLeft: '1rem',
	},
});
