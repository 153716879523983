import { ILocationState } from '@AppModels/.';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '@AppModels/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import {
	IOperationResultNoValue,
	IOpportunity,
	OpportunitiesBoardViewModel,
	OpportunitiesViewModel,
} from '@ViewModels';
import { LoadingSpinner } from '@WebComponents/LoadingSpinner';
import { NavigationBreadcrumbsBar } from '@WebComponents/NavigationBreadcrumbsBar';
import { NotFound } from '@WebComponents/NotFound';
import { SplitView } from '@WebComponents/SplitView';
import { ITabViewChild, TabView } from '@WebComponents/TabView';
import { BoardItemActivityList } from '@WebComponents/boards/BoardItemActivityList';
import { CompanyContactsList } from '@WebComponents/companies/CompanyContactsList';
import { EditOpportunityModal } from '@WebComponents/dataBoards/opportunities/EditOpportunity';
import { OpportunityProfile } from '@WebComponents/dataBoards/opportunities/OpportunityProfile';
import { EntityRichContent } from '@WebComponents/entities/EntityRichContent';
import { StyleDeclarationValue, css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { OpportunitiesBoardStageViewModel, OpportunityViewModel } from '../../../../viewmodels/AppViewModels';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { INavigationItemProps } from '../../MainContainer';
import './styles.less';

interface IProps
	extends INavigationItemProps,
		RouteComponentProps<{ id?: string; boardId?: string }>,
		IUserSessionComponentProps,
		IEventLoggingComponentProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

interface IState {
	board?: OpportunitiesBoardViewModel;
	loadError?: IOperationResultNoValue;
	opportunity?: OpportunityViewModel;
	showingEditModal?: boolean;
	showNotFound?: boolean;
}

class _Opportunity extends React.Component<IProps, IState> {
	private mMounted = false;

	public static getDerivedStateFromProps(props: Readonly<IProps>, state: IState) {
		const nextState: IState = {};
		const { id: opportunityId } = props.match.params;
		const locationState: ILocationState<OpportunityViewModel, IOpportunity> = props.history.location.state || {};
		if (!!locationState.viewModel && locationState.viewModel !== state.opportunity) {
			nextState.opportunity = locationState.viewModel;
		} else {
			const currentOppId = state.opportunity?.id ?? null;
			if (!!locationState && !!locationState.viewModel) {
				if (state.opportunity !== locationState.viewModel) {
					nextState.opportunity = locationState.viewModel;
				}
			} else if (!!opportunityId && currentOppId !== opportunityId) {
				nextState.opportunity = new OpportunityViewModel(props.userSession, null, { id: opportunityId });
			}
		}

		if (nextState.opportunity) {
			// clear the error
			nextState.showNotFound = false;

			nextState.loadError = null;
		}
		return Object.keys(nextState).length > 0 ? nextState : null;
	}

	constructor(props: IProps) {
		super(props);
		this.state = {
			...(_Opportunity.getDerivedStateFromProps(props, {}) || {}),
		};
	}

	public componentDidMount() {
		this.mMounted = true;
		this.loadOpportunity();
		this.loadBoard();
	}

	public componentWillUnmount() {
		this.mMounted = false;
	}

	public componentDidUpdate() {
		const { opportunity, showNotFound, loadError } = this.state;
		if (!this.isLoading && !opportunity.isLoaded && !showNotFound && !loadError) {
			this.loadOpportunity();
		}
	}

	public render() {
		const { className, routeContainerClassName, styles } = this.props;
		const { opportunity, showingEditModal, showNotFound, board } = this.state;
		return (
			<div
				className={`${css(...(styles || [])) || ''} ${routeContainerClassName || ''} opportunity-container ${
					className || ''
				}`}
			>
				{showNotFound ? (
					<NotFound />
				) : (
					<>
						<MultiContainerHeader
							appBarHeader={
								<NavigationBreadcrumbsBar
									currentLocationName={opportunity.name ? opportunity.name : ' '}
									pathComponentNameProvider={this.onValidateNavigationBreadcrumbBarPathComponent}
								/>
							}
							fullscreenHeader='Opportunity'
						/>
						<SplitView
							className='opportunity-container-splitview'
							detail={this.renderSplitViewDetail()}
							master={this.renderSplitViewMaster()}
						/>
						<EditOpportunityModal
							board={board}
							modalProps={{
								isOpen: !!opportunity && !!showingEditModal,
								onRequestClose: this.onEditOpportunityModalRequestClose,
								shouldCloseOnOverlayClick: false,
							}}
							opportunity={opportunity}
							stage={opportunity.stage as unknown as OpportunitiesBoardStageViewModel}
						/>
					</>
				)}
			</div>
		);
	}

	private renderSplitViewMaster() {
		const { opportunity } = this.state;
		return (
			<div className='opportunity-container-profile'>
				{!!opportunity.isLoading || (!!opportunity.stage && opportunity.stage.isLoading) ? (
					<LoadingSpinner className='opportunity-container-profile-loading' type='large' />
				) : (
					<>
						{!opportunity?.isArchived ? (
							<div className='opportunity-container-profile-header'>
								<button className='opportunity-container-edit-button' onClick={this.onEditClicked}>
									Edit
								</button>
							</div>
						) : null}
						<OpportunityProfile opportunity={opportunity} />
					</>
				)}
			</div>
		);
	}

	private renderSplitViewDetail() {
		const { opportunity } = this.state;
		if (!opportunity) {
			return null;
		}

		return (
			<div className='opportunity-container-tabs'>
				<TabView>{this.onRenderTabViewContent}</TabView>
			</div>
		);
	}

	private onRenderTabViewContent = (): ITabViewChild[] => {
		const { opportunity } = this.state;

		const entity = opportunity.company || opportunity.primaryContact;
		const options = [
			{
				content: <BoardItemActivityList boardItem={opportunity} />,
				tabbarItem: {
					content: 'Activity',
				},
			},
			{
				content: <EntityRichContent entity={entity} />,
				tabbarItem: {
					content: 'Notes',
				},
			},
		];

		if (opportunity.company) {
			options.push({
				content: <CompanyContactsList company={opportunity.company} />,
				tabbarItem: {
					content: 'Contacts',
				},
			});
		}

		return options;
	};

	@computed
	private get isLoading() {
		const { opportunity } = this.state;
		return !opportunity || (!!opportunity && !!opportunity.isLoading) || !this.mMounted;
	}

	private loadBoard = () => {
		const { logEvent, logApiError, userSession, match } = this.props;
		const { boardId } = match.params || {};
		const { board: currentBoard } = this.state;
		if (!currentBoard && boardId) {
			const opportunities = new OpportunitiesViewModel(userSession);
			const promise = opportunities.getBoards();
			if (promise) {
				promise
					.then(() => {
						logEvent('BoardsLoad');
						const board = opportunities.boards?.find(x => x.id === boardId);
						this.setState({
							board,
						});
					})
					.catch((error: IOperationResultNoValue) => {
						logApiError('BoardsLoad-Error', error);
					});
			}
		}
	};

	private loadOpportunity = (opportunity = this.state.opportunity) => {
		if (opportunity) {
			const { history, logApiError } = this.props;
			const promise = opportunity.load();
			if (promise) {
				promise
					.then(() => {
						// save back to location state
						if (!!this.mMounted && !!history) {
							const locationStateToSet: ILocationState<OpportunityViewModel, IOpportunity> = { viewModel: opportunity };
							history.location.state = locationStateToSet;
						}
					})
					.catch((error: IOperationResultNoValue) => {
						logApiError('Load-Error', error);
						if (this.mMounted) {
							const stateWithError: IState = { loadError: error };
							if (error.systemCode === 404) {
								stateWithError.showNotFound = true;
							}
							this.setState(stateWithError);
						}
					});
			}
		}
	};

	private onValidateNavigationBreadcrumbBarPathComponent = (pathComponent: string) => {
		// remove boards from the path
		if (pathComponent === 'boards') {
			return null;
		}
		return pathComponent ? `${pathComponent.charAt(0).toUpperCase()}${pathComponent.substring(1)}` : pathComponent;
	};

	private onEditClicked = () => {
		const { logInput } = this.props;

		logInput('Edit', 'Click');
		this.setState({
			showingEditModal: true,
		});
	};

	private onEditOpportunityModalRequestClose = () => {
		this.setState({
			showingEditModal: false,
		});
	};
}

const OpportunityAsObserver = observer(_Opportunity);
const OpportunityWithInjection = inject(UserSessionViewModelKey)(OpportunityAsObserver);
const OpportunityWithRouter = withRouter(OpportunityWithInjection);
export const Opportunity = withEventLogging(OpportunityWithRouter, 'Opportunity');
