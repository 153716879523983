import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	content: {
		marginTop: 10,
	},
	header: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
	},
	title: {
		color: '#000000',
		flexGrow: 1,
		fontSize: '16px',
		fontWeight: 600,
		letterSpacing: '0',
	},
});
