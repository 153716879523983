import * as Api from '@ViewModels';
import { isTagSearchContactFilterCriteria } from '../models/UiUtils';

export function useTagsFromBulkContactRequest(req: Api.IBulkContactsRequest) {
	const tags: string[] = [];

	if (req.filter?.criteria) {
		const tagFilters = req.filter.criteria.filter(x => isTagSearchContactFilterCriteria(x));
		tagFilters.forEach(tagFilter => {
			if (tagFilter.value && typeof tagFilter.value === 'string') {
				tags.push(tagFilter.value);
			} else if (tagFilter.criteria?.length) {
				tagFilter.criteria.forEach(x => {
					if (x.value) {
						tags.push(x.value);
					}
				});
			}
		});
	}

	return tags;
}
