import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { CampaignTabListRightPortalID } from '.';
import { useFullscreenModal, useUserSession } from '../../../models/hooks/appStateHooks';
import {
	useHandwrittenCardAllCategoryTemplates,
	useHandwrittenCardCategories,
	useHandwrittenCardCategoryTemplates,
	useHandwrittenCardSearchTemplates,
	useMyTemplates,
} from '../../../queries';
import { IHandwrittenCardTemplate, KnownCategories, TemplateType, isAdmin } from '../../../viewmodels/AppViewModels';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { Portal } from '../../components/Portal';
import { PurchaseCreditsModal } from '../../components/settings/PurchaseCreditsModal';
import { OopsNotFoundGraphic } from '../../components/svgs/graphics/OopsNotFoundGraphic';
import { EmailIcon } from '../../components/svgs/icons/EmailIcon';
import { HandwrittenCardsTemplateCard } from '../../components/templateCards/HandwrittenCardsTemplateCard';
import { brandSecondary } from '../../styles/colors';
import { baseStyleSheet, bs } from '../../styles/styles';
import { CampaignBrowser, useCampaignParams, useEnforceIndustryAndCategory } from '../CampaignBrowser';
import { BrowseAllInCategoryButton } from '../CampaignBrowser/presentation';
import { PostcardComposerStep } from '../PostcardComposer';
import { useCurrentUserGiftingBalance } from '../PostcardComposer/hooks/useCurrentUserGiftingBalance';
import { TabValues } from './presentation';
import { styleSheet } from './styles';

export const HandwrittenCardsCampaignCards = observer(() => {
	const userSession = useUserSession();
	const { category, industry, search, setCampaignBrowserParam } = useCampaignParams();
	const fullscreenModal = useFullscreenModal();
	useEnforceIndustryAndCategory({ setCampaignBrowserParam, tabUrlParam: TabValues.Cards });
	const searchTemplatesQuery = useHandwrittenCardSearchTemplates({
		enabled: !!search,
		industry,
		search,
	});
	const categoryQuery = useHandwrittenCardCategories({
		enabled: !!industry,
		industry,
		withAll: true,
		withMyTemplates: true,
	});
	const categoryTemplatesQuery = useHandwrittenCardCategoryTemplates({
		category,
		enabled: category !== KnownCategories.All && category !== KnownCategories.MyTemplates,
		industry,
	});
	const myTemplatesQuery = useMyTemplates<IHandwrittenCardTemplate>({
		enabled: category === KnownCategories.MyTemplates,
		industry,
		templateType: TemplateType.HandwrittenCard,
	});
	const allCategoryTemplatesQuery = useHandwrittenCardAllCategoryTemplates({
		enabled: category === KnownCategories.All,
		industry,
	});
	const isSearching = !!search;
	const { cardsAvailable, currentBalanceFormatted, isLoading: isLoadingBalance } = useCurrentUserGiftingBalance();
	const [showPurchaseCreditsModal, setShowPurchaseCreditsModal] = React.useState(false);
	// We have data when we are searching and searchTemplatesQuery has data or
	// we are not searching and either allCategoryTemplatesQuery or categoryTemplatesQuery has data depending on whether the category is set to All
	const hasData = React.useMemo(() => {
		return (
			(isSearching && searchTemplatesQuery.data && searchTemplatesQuery.data.length > 0) ||
			(!isSearching &&
				((category === KnownCategories.All &&
					allCategoryTemplatesQuery.data &&
					Object.values(allCategoryTemplatesQuery.data).flat().length > 0) ||
					(category !== KnownCategories.All &&
						category !== KnownCategories.MyTemplates &&
						categoryTemplatesQuery.data &&
						categoryTemplatesQuery.data.length > 0) ||
					(category === KnownCategories.MyTemplates && myTemplatesQuery.data && myTemplatesQuery.data.length > 0)))
		);
	}, [
		isSearching,
		searchTemplatesQuery.data,
		allCategoryTemplatesQuery.data,
		categoryTemplatesQuery.data,
		myTemplatesQuery.data,
		category,
	]);
	// isLoading is true when we are searching and searchTemplatesQuery is loading or
	// we are not searching and either allCategoryTemplatesQuery or categoryTemplatesQuery is loading depending on whether the category is set to All
	const isLoading =
		(isSearching && searchTemplatesQuery.isLoading) ||
		(!isSearching &&
			((category === KnownCategories.All && allCategoryTemplatesQuery.isLoading) ||
				(category !== KnownCategories.All &&
					category !== KnownCategories.MyTemplates &&
					categoryTemplatesQuery.isLoading) ||
				(category === KnownCategories.MyTemplates && myTemplatesQuery.isLoading)));

	const handleChangeIndustry = (value: string) => {
		setCampaignBrowserParam({ industry: value, category: KnownCategories.All });
	};
	const handleChangeCategory = (value: string) => {
		setCampaignBrowserParam({ category: value });
	};
	const handleSearchChange = React.useCallback(
		(value: string) => {
			setCampaignBrowserParam({ search: value });
		},
		[setCampaignBrowserParam]
	);
	const handleTemplateClick = (id: string) => {
		fullscreenModal.history.push(`/postcard/${id}/${PostcardComposerStep.Preview}`);
	};
	return (
		<>
			<Portal destination={CampaignTabListRightPortalID}>
				{isLoadingBalance ? (
					<LoadingSpinner type='small' />
				) : (
					<div className={css(bs.flex, bs.itemsCenter, bs.gap2, bs.hFull)}>
						<EmailIcon fillColor={brandSecondary} />
						{cardsAvailable != null ? (
							<span className={css(styleSheet.cardsAvailable)}>{cardsAvailable} Cards Available</span>
						) : null}
						{currentBalanceFormatted != null ? (
							<span className={css(styleSheet.balanceAvailable)}> = {currentBalanceFormatted}</span>
						) : null}
					</div>
				)}
			</Portal>
			<CampaignBrowser
				categories={categoryQuery.data ?? []}
				category={category}
				industry={industry}
				onCategoryChange={handleChangeCategory}
				onIndustryChange={handleChangeIndustry}
				onSearchChange={handleSearchChange}
				renderRightActions={
					<>
						{/* @ts-ignore */}
						{isAdmin(userSession.user) ? (
							<button
								className={css(baseStyleSheet.ctaButtonSecondary, styleSheet.addCreditBtn)}
								onClick={() => setShowPurchaseCreditsModal(true)}
							>
								+ Add Credits
							</button>
						) : null}
					</>
				} // To be replaced with renderNewCardButton when custom card is supported
				search={search}
				searchPlaceholder='Search card type or keywords'
			>
				{isSearching && searchTemplatesQuery.data && searchTemplatesQuery.data.length > 0 ? (
					<div className={css(styleSheet.cards)}>
						<section className={css(styleSheet.cardSection)}>
							<div className={css(styleSheet.cardSectionRow)}>
								{searchTemplatesQuery.data.map(template => (
									<HandwrittenCardsTemplateCard
										key={template.id}
										// @ts-ignore
										name={template.name}
										// @ts-ignore
										onTemplateClicked={() => handleTemplateClick(template.id)}
										publicUrl={template.thumbnail.publicUrl}
									/>
								))}
							</div>
						</section>
					</div>
				) : null}
				{!isSearching &&
				category !== KnownCategories.All &&
				category !== KnownCategories.MyTemplates &&
				categoryTemplatesQuery.data ? (
					<div className={css(styleSheet.cards)}>
						<section className={css(styleSheet.cardSection)}>
							<h2 className={css(styleSheet.categoryTitle)}>{category}</h2>
							<div className={css(styleSheet.cardSectionRow)}>
								{categoryTemplatesQuery.data.map(template => (
									<HandwrittenCardsTemplateCard
										key={template.id}
										// @ts-ignore
										name={template.name}
										// @ts-ignore
										onTemplateClicked={() => handleTemplateClick(template.id)}
										publicUrl={template.thumbnail.publicUrl}
									/>
								))}
							</div>
						</section>
					</div>
				) : null}
				{!isSearching && category === KnownCategories.MyTemplates && myTemplatesQuery.data ? (
					<div className={css(styleSheet.cards)}>
						<section className={css(styleSheet.cardSection)}>
							<h2 className={css(styleSheet.categoryTitle)}>{category}</h2>
							<div className={css(styleSheet.cardSectionRow)}>
								{myTemplatesQuery.data.map(template => (
									<HandwrittenCardsTemplateCard
										key={template.id}
										// @ts-ignore
										name={template.name}
										// @ts-ignore
										onTemplateClicked={() => handleTemplateClick(template.id)}
										publicUrl={template.thumbnail.publicUrl}
									/>
								))}
							</div>
						</section>
					</div>
				) : null}
				{!isSearching &&
				category === KnownCategories.All &&
				allCategoryTemplatesQuery.data &&
				Object.keys(allCategoryTemplatesQuery.data).length > 0 ? (
					<div className={css(styleSheet.cards)}>
						{Object.keys(allCategoryTemplatesQuery.data).map((categoryName: string) => {
							// @ts-ignore
							const templates = allCategoryTemplatesQuery.data[categoryName];
							return (
								<section key={categoryName} className={css(styleSheet.cardSection)}>
									<h2 className={css(styleSheet.categoryTitle)}>
										{categoryName === KnownCategories.Me ? KnownCategories.MyTemplates : categoryName}
									</h2>
									<div className={css(styleSheet.cardSectionRow)}>
										{templates.map(template => (
											<HandwrittenCardsTemplateCard
												key={template.id}
												// @ts-ignore
												name={template.name}
												// @ts-ignore
												onTemplateClicked={() => handleTemplateClick(template.id)}
												publicUrl={template.thumbnail.publicUrl}
											/>
										))}
									</div>
									<BrowseAllInCategoryButton
										styles={[styleSheet.browseAllCampaigns, baseStyleSheet.brandLink]}
										onClick={() => handleChangeCategory(categoryName)}
									>
										Browse all {categoryName === KnownCategories.Me ? KnownCategories.MyTemplates : categoryName}
									</BrowseAllInCategoryButton>
								</section>
							);
						})}
					</div>
				) : null}
				{isLoading && <LoadingSpinner className={css(styleSheet.pageLoading)} />}
				{!isLoading && !hasData ? (
					<div className={css(styleSheet.cardListEmpty)}>
						<OopsNotFoundGraphic />
						<p>We did not find any handwritten cards</p>
					</div>
				) : null}
				<PurchaseCreditsModal
					isOpen={showPurchaseCreditsModal}
					onRequestClose={() => setShowPurchaseCreditsModal(false)}
				/>
			</CampaignBrowser>
		</>
	);
});
