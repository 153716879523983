import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		boxSizing: 'border-box',
		width: '100%',
		height: '100%',
		paddingTop: 24,
	},
	loadingRow: {
		height: 300,
	},
});
