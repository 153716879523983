import { navigation } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const NewTextMessageIcon: React.FC<IProps> = ({ className = '', fillColor = navigation }) => (
	<SvgIcon className={`new-text-message-icon ${className}`} height={21} width={22}>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M11 21H0.999995C0.601995 21 0.241995 20.764 0.082995 20.4C-0.076005 20.035 -0.00300506 19.611 0.266995 19.32L2.57299 16.836C1.541 15.392 0.999995 13.735 0.999995 12C0.999995 7.038 5.486 3 11 3V5C6.589 5 2.99999 8.14 2.99999 12C2.99999 13.537 3.568 15 4.64199 16.232C4.97799 16.618 4.969 17.195 4.621 17.57L3.29299 19H11C15.411 19 19 15.86 19 12H21C21 16.962 16.514 21 11 21Z'
			fill={fillColor}
		/>
		<path fillRule='evenodd' clipRule='evenodd' d='M22 3H19V0H17V3H14V5H17V8H19V5H22V3Z' fill={fillColor} />
	</SvgIcon>
);
