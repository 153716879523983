import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { MeetingConfigCard } from '../../components/settings/PersonalSettings/MeetingConfigCard';
import { baseStyleSheet } from '../../styles/styles';
import { styleSheet } from './styles';

interface IProps {
	isLoadingMeetingLinks: boolean;
	meetingConfigs: Api.MeetingConfigViewModel[];
	onAddMeeting: () => void;
	onDeleteMeeting: (meeting: Api.MeetingConfigViewModel) => void;
	onEditMeeting: (meeting: Api.MeetingConfigViewModel) => void;
}

const ScheduleMeetingBase: React.FC<IProps> = ({
	isLoadingMeetingLinks,
	meetingConfigs,
	onAddMeeting,
	onDeleteMeeting,
	onEditMeeting,
}) => {
	return (
		<div className={css(styleSheet.cardsContainer)}>
			{isLoadingMeetingLinks ? <LoadingSpinner type='small' className={css(baseStyleSheet.absoluteCenter)} /> : null}
			{!isLoadingMeetingLinks ? (
				<>
					{meetingConfigs.map((meeting, i) => (
						<MeetingConfigCard
							key={i}
							meeting={meeting}
							onDelete={onDeleteMeeting}
							onEdit={() => onEditMeeting(meeting)}
						/>
					))}
					<div key='add-new' className={css(styleSheet.addCard)} onClick={onAddMeeting}>
						+ Add new meeting
					</div>
				</>
			) : null}
		</div>
	);
};

export const ScheduleMeeting = observer(ScheduleMeetingBase);
