import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEventLogging } from '../../../../models/Logging';
import { getDefaultDateStringValue } from '../../../../models/UiUtils';
import { useErrorMessages } from '../../../../models/hooks/appStateHooks';
import { EntityInfoBulletList } from '../../../components/entities/EntityInfoBulletList';
import { KeyFactWithKeyDate } from '../../../components/entities/KeyFactWithKeyDate';
import { bs } from '../../../styles/styles';
import { styleSheet } from './styles';

interface Props {
	compactLayout: boolean;
	companyVm: Api.CompanyViewModel;
}

function _CompanyInfoKeyFacts({ compactLayout, companyVm }: Props) {
	const [keyFacts, setKeyFacts] = React.useState<Api.IKeyFact[] | null>(null);
	const errorMessages = useErrorMessages();
	const { logEvent } = useEventLogging('ContactInfoKeyFactsAndTags');
	const [editableKeyFact, setEditableKeyFact] = React.useState<Api.IKeyFact | null>(null);
	const [editItemIndex, setEditItemIndex] = React.useState<number>(-1);
	const [showEditNewItemInput, setShowEditNewItemInput] = React.useState(false);
	const onAddKeyFact = (keyFact: Api.IKeyFact) => {
		const newKeyFacts = [...companyVm.keyFactsCollection];
		newKeyFacts.push(keyFact);
		const promise = companyVm.updateKeyFactsCollection(newKeyFacts);
		if (promise) {
			logEvent('AddKeyFact');
			setKeyFacts(newKeyFacts);
			promise
				.then(() => {
					setKeyFacts(null);
				})
				.catch((error: Api.IOperationResultNoValue) => {
					setKeyFacts(null);
					logEvent('AddKeyFact-Error', { ...error });
					errorMessages.pushApiError(error);
				});
		}
	};
	const onUpdateKeyFact = (keyFact: Api.IKeyFact) => {
		const newKeyFacts = [...companyVm.keyFactsCollection];
		// if the key fact doesn't have a value, this actually just removes it from the collection
		const index = companyVm.keyFactsCollection.findIndex(x => x.id === keyFact.id);
		if (keyFact.value) {
			newKeyFacts.splice(index, 1, keyFact);
		} else {
			newKeyFacts.splice(index, 1);
		}
		const promise = companyVm.updateKeyFactsCollection(newKeyFacts);
		if (promise) {
			logEvent('UpdateKeyFact', { id: keyFact.id });
			setKeyFacts(newKeyFacts);
			promise
				.then(() => {
					setKeyFacts(null);
				})
				.catch((error: Api.IOperationResultNoValue) => {
					setKeyFacts(null);
					logEvent('UpdateKeyFact-Error', { ...error });
					errorMessages.pushApiError(error);
				});
		}
	};
	const onAddNewButtonClicked = () => {
		// need to init with null properties
		setEditableKeyFact({
			id: null,
			value: null,
		});
		setEditItemIndex(-1);
		setShowEditNewItemInput(true);
	};
	const onEditCanceled = () => {
		setEditableKeyFact(null);
		setEditItemIndex(-1);
		setShowEditNewItemInput(false);
	};
	const onEditTextChanged = (value: string) => {
		if (editableKeyFact) {
			setEditableKeyFact({
				...editableKeyFact,
				value,
			});
		}
	};
	const onItemEditButtonClicked = (item: Api.IKeyFact, index: number) => {
		setEditableKeyFact({
			id: null,
			value: '',
			...(item || {}),
		});
		setEditItemIndex(index);
		setShowEditNewItemInput(false);
	};
	const onRemoveItem = (item: Api.IKeyFact) => {
		if (item.id) {
			onUpdateKeyFact({
				...item,
				value: null,
			});
		}
		setEditableKeyFact(null);
		setEditItemIndex(-1);
		setShowEditNewItemInput(false);
	};
	const onRenderItem = (item: Api.IKeyFact, _: number, bullet?: React.ReactNode, editButton?: React.ReactNode) => {
		const dateString = item.lastModifiedDate || item.creationDate;
		const displayDate = dateString ? getDefaultDateStringValue(dateString) : null;
		const keyFact = item.keyDate ? <KeyFactWithKeyDate key={item.id} keyFact={item} /> : <span>{item.value}</span>;
		let content = (
			<>
				<div>{keyFact}</div>
				{!!displayDate && <div className={css(bs.textNavigation, bs.flexShrink0, bs.textXs)}>{displayDate}</div>}
			</>
		);
		content = (
			<div
				className={`${css(
					styleSheet.factText,
					bs.itemsBaseline,
					bs.pt1,
					bs.pb1,
					bs.pl2
				)} key-facts-editor-item-fact-text`}
			>
				{content}
			</div>
		);
		let edit: React.ReactNode = null;
		if (!item.source || item.source?.source === Api.IntegrationSources.CsvImport) {
			// If there is no source (you created it yourself) then you should still be able to edit it
			edit = editButton;
		}
		return (
			<>
				{bullet}
				{content}
				{edit}
			</>
		);
	};
	const onSaveEdit = () => {
		if (editableKeyFact) {
			setEditableKeyFact({
				...editableKeyFact,
				value: editableKeyFact.value?.trim() || '',
			});
			if (editableKeyFact.id) {
				onUpdateKeyFact(editableKeyFact);
			} else {
				onAddKeyFact(editableKeyFact);
			}
		}
		setEditableKeyFact(null);
		setEditItemIndex(-1);
		setShowEditNewItemInput(false);
	};
	return (
		<EntityInfoBulletList
			addButtonContent='Add a key fact'
			compactLayout={compactLayout}
			deleteConfirmationContent='Do you want to delete this key fact?'
			disabled={!!companyVm.isBusy}
			editItemIndex={editItemIndex}
			editTextValue={editableKeyFact ? editableKeyFact.value : null}
			entity={companyVm}
			items={keyFacts || companyVm.keyFactsCollection || []}
			onAddNewButtonClicked={onAddNewButtonClicked}
			onEditCanceled={onEditCanceled}
			onEditTextChanged={onEditTextChanged}
			onItemEditButtonClicked={onItemEditButtonClicked}
			onRemoveItem={onRemoveItem}
			onRenderItem={onRenderItem}
			onSaveEdit={onSaveEdit}
			showEditNewItemInput={showEditNewItemInput}
		/>
	);
}

export const CompanyInfoKeyFacts = observer(_CompanyInfoKeyFacts);
