import { DayOfWeek, IDailyIntervals } from '../../../../../extViewmodels';
import { Button } from '../../../Button';
import { RoundAddIcon } from '../../../svgs/icons/RoundAddIcon';
import { MeetingConfigAvailabilityDay } from '../MeetingConfigAvailabilityDay';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';

interface IProps {
	increment: number;
	meetingAvailability: IDailyIntervals[];
	onAddBlock(day: DayOfWeek): void;
	onAddDay(day: DayOfWeek): void;
	onChangeTime(day: DayOfWeek, block: number, property: AvailabilityTimeProperty, value: number): void;
	onRemoveBlock(day: DayOfWeek): void;
	onToggleAvailable(day: DayOfWeek): void;
}

export type AvailabilityTimeProperty = 'startMinutes' | 'endMinutes';

export const MeetingConfigAvailability: React.FC<IProps> = observer(props => {
	const { increment, meetingAvailability, onAddBlock, onAddDay, onChangeTime, onRemoveBlock, onToggleAvailable } =
		props;

	const addDay = (day: DayOfWeek) => () => onAddDay(day);
	const addBlock = (day: DayOfWeek) => () => onAddBlock(day);
	const removeBlock = (day: DayOfWeek) => () => onRemoveBlock(day);
	const changeTime = (day: DayOfWeek) => (blockIndex: number, property: AvailabilityTimeProperty, value: number) =>
		onChangeTime(day, blockIndex, property, value);
	const toggleAvailable = (day: DayOfWeek) => () => onToggleAvailable(day);

	return (
		<div>
			{meetingAvailability?.map((intervals, index) => {
				return (
					<MeetingConfigAvailabilityDay
						key={`Day:${index}`}
						onAddBlock={addBlock(index)}
						onRemoveBlock={removeBlock(index)}
						onToggleAvailable={toggleAvailable(index)}
						onChange={changeTime(index)}
						intervals={intervals?.intervals}
						dayIndex={index}
						increment={increment}
					/>
				);
			})}
			{!meetingAvailability?.[0] && (
				<Button
					accessory={<RoundAddIcon />}
					className={css(styleSheet.addContainer, meetingAvailability[0] && styleSheet.addContainerFirst)}
					kind='icon'
					label='Add hours for Sunday'
					onClick={addDay(DayOfWeek.Sunday)}
				/>
			)}
			{!meetingAvailability?.[6] && (
				<Button
					accessory={<RoundAddIcon />}
					className={css(styleSheet.addContainer, meetingAvailability[6] && styleSheet.addContainerFirst)}
					kind='icon'
					label='Add hours for Saturday'
					onClick={addDay(DayOfWeek.Saturday)}
				/>
			)}
		</div>
	);
});
