import { StyleSheet } from 'aphrodite';
import { header } from '../../../../styles/colors';
import { CssSelectors } from '../../../../styles/styles';

export const styleSheet = StyleSheet.create({
	container: {
		'@media (min-width: 1024px)': {
			flexDirection: 'row',
		},
		display: 'flex',
		flexDirection: 'column',
	},
	close: {
		':hover': {
			cursor: 'pointer',
		},
		position: 'absolute',
		right: '1.25rem',
		top: '1rem',
	},
	integrationMessage: {
		color: header,
		lineHeight: '25px',
	},
	integrationTitle: {
		fontSize: '1.5rem',
	},
	leftCol: {
		...CssSelectors.allChildren(
			{
				marginTop: '1rem',
			},
			':not(:first-child)'
		),
	},
	logo: {
		display: 'block',
	},
	logoHeader: {
		alignItems: 'center',
		display: 'flex',
	},
	logoWrapper: {
		borderRadius: 12,
		marginRight: '1rem',
		padding: '0.5rem',
	},
	rightCol: {
		marginLeft: '1.5rem',
	},
	root: {
		backgroundColor: 'white',
		position: 'relative',
		width: '100%',
	},
});
