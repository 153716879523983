import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const EmptySearchIcon: React.FC<IProps> = ({ className = '' }) => {
	return (
		<SvgIcon className={className || ''} width={180} height={180}>
			<g fill='none' fillRule='evenodd'>
				<circle cx='90' cy='90' r='90' fill='#E5FAFF' />
				<g fillRule='nonzero' transform='translate(22 26)'>
					<path
						fill='#0CF'
						d='M85.2410548,82.6801992 L27.152895,97.7557579 C23.0502139,98.8155426 18.8705504,96.3283598 17.8097335,92.1959441 L0.242344105,23.4416959 C-0.809204219,19.3068742 1.65864604,15.0944663 5.75894087,14.0253401 L59.8004858,0 L73.6325779,6.24068239 L90.7576562,73.2638434 C91.8092042,77.3986665 89.3413515,81.6110751 85.2410548,82.6801992 Z'
					/>
					<path
						fill='#FFF'
						d='M6.12083816,15.7683704 C3.18685078,16.5262522 1.42096607,19.5123819 2.17341282,22.4435083 L19.8254784,90.885569 C20.5845415,93.8149948 23.575322,95.5781337 26.5110134,94.8268567 L84.8791623,79.8197511 C87.8131489,79.0618682 89.5790331,76.0757394 88.8265877,73.1446132 L71.8855887,7.45956213 L59.6710935,2 L6.12083816,15.7683704 Z'
					/>
					<path
						fill='#0CF'
						d='M73.8795785,6.53743657 L64.8486632,8.91640592 C63.4757772,9.27805718 62.0762379,8.43273329 61.7227033,7.02832135 L60.0050738,0.205043605 C59.98973,0.144087557 60.0098188,0.0795244474 60.056783,0.0388561338 C60.1037472,-0.00181217978 60.1693117,-0.0114199171 60.225548,0.0141255161 L73.9046785,6.22851528 C73.9670758,6.25686142 74.0051007,6.32249934 73.9994455,6.39210053 C73.9937904,6.46170173 73.9456943,6.52001722 73.8795785,6.53743657 L73.8795785,6.53743657 Z'
					/>
					<path
						fill='#C6CCD7'
						d='M70.008 65.685 42.651 72.957C42.194 73.078 41.709 72.939 41.378 72.591 41.047 72.243 40.922 71.739 41.048 71.27 41.175 70.8 41.535 70.436 41.992 70.315L69.349 63.043C69.806 62.922 70.291 63.061 70.622 63.409 70.953 63.757 71.078 64.261 70.952 64.73 70.825 65.2 70.465 65.564 70.008 65.685ZM75.045 67.59 43.592 75.958C42.91 76.139 42.216 75.716 42.041 75.012 41.865 74.308 42.275 73.591 42.956 73.409L74.409 65.042C75.09 64.861 75.784 65.285 75.959 65.988 76.135 66.691 75.725 67.408 75.045 67.59L75.045 67.59Z'
					/>
					<circle cx='31.5' cy='75.5' r='4.5' fill='#E9EBEF' />
					<path
						fill='#FFF'
						stroke='#E9EBEF'
						d='M66.9969597,53.7413305 L31.4473748,62.8739806 C29.3082018,63.420975 27.1290474,62.1366318 26.5761443,60.0029901 L19.1262708,31.120103 C18.5781816,28.9852305 19.865107,26.8104599 22.0030364,26.2586671 L57.5526354,17.1260171 C59.6918047,16.5790287 61.870952,17.8633709 62.4238541,19.9970076 L69.87373,48.8798947 C70.4218175,51.0147684 69.1348902,53.1895388 66.9969597,53.7413305 Z'
					/>
					<path
						fill='#E9EBEF'
						d='M66.9969597,53.7413305 L31.4473748,62.8739806 C29.3082018,63.420975 27.1290474,62.1366318 26.5761443,60.0029901 L19.1262708,31.120103 C18.5781816,28.9852305 19.865107,26.8104599 22.0030364,26.2586671 L57.5526354,17.1260171 C59.6918047,16.5790287 61.870952,17.8633709 62.4238541,19.9970076 L69.87373,48.8798947 C70.4218175,51.0147684 69.1348902,53.1895388 66.9969597,53.7413305 Z M22.1203671,26.7135138 C20.233965,27.2004002 19.0984472,29.1193093 19.5820446,31.0030196 L27.0319181,59.8859067 C27.5197843,61.7685192 29.4425532,62.9017562 31.3300534,62.4191316 L66.8796525,53.2864839 C68.7660546,52.7995975 69.9015724,50.8806884 69.4179751,48.9969781 L61.9680851,20.114091 C61.4802189,18.2314773 59.5574484,17.09824 57.6699474,17.5808661 L22.1203671,26.7135138 Z'
					/>
					<path
						fill='#F2F2F2'
						d='M54.541 26.076 36.798 29.98C36.476 30.057 36.14 29.907 36.028 29.638 35.978 29.501 35.995 29.352 36.077 29.227 36.158 29.101 36.297 29.01 36.46 28.974L54.52 25C55.25 25.316 55.058 25.962 54.54 26.076L54.541 26.076ZM55.541 30.076 37.798 33.98C37.476 34.057 37.14 33.907 37.028 33.638 36.978 33.501 36.995 33.352 37.077 33.227 37.158 33.101 37.297 33.01 37.46 32.974L55.52 29C56.25 29.316 56.058 29.962 55.54 30.076L55.541 30.076Z'
					/>
					<path
						fill='#E9EBEF'
						d='M35.5098369,37.6092486 L30.3753905,38.9786291 C30.0262353,39.0713622 29.670531,38.8537161 29.5803251,38.4921496 L28.0206267,32.2144531 C27.9311231,31.8527 28.1411881,31.484161 28.4901616,31.3906991 L33.624608,30.021321 C33.9737446,29.9287226 34.3293625,30.1463158 34.4196734,30.5078004 L35.9793739,36.7855039 C36.068876,37.1472569 35.8588101,37.5157947 35.5098369,37.6092555 L35.5098369,37.6092486 Z'
					/>
					<path
						fill='#F2F2F2'
						d='M57.53 36.22 29.817 42.977C29.488 43.064 29.143 42.895 29.029 42.59 28.977 42.434 28.995 42.265 29.079 42.123 29.162 41.981 29.304 41.877 29.47 41.836L57.509 35C58.256 35.358 58.059 36.091 57.53 36.22L57.53 36.22ZM58.53 39.22 30.817 45.977C30.488 46.064 30.143 45.895 30.029 45.59 29.977 45.434 29.995 45.265 30.079 45.123 30.162 44.981 30.304 44.877 30.47 44.836L58.509 38C59.256 38.358 59.059 39.091 58.53 39.22L58.53 39.22ZM59.53 42.22 31.817 48.977C31.488 49.064 31.143 48.895 31.029 48.59 30.977 48.434 30.995 48.265 31.079 48.123 31.162 47.981 31.304 47.877 31.47 47.836L59.509 41C60.256 41.358 60.059 42.091 59.53 42.22L59.53 42.22ZM59.53 45.22 31.817 51.977C31.488 52.064 31.143 51.895 31.029 51.59 30.977 51.434 30.995 51.265 31.079 51.123 31.162 50.981 31.304 50.877 31.47 50.836L59.509 44C60.256 44.358 60.059 45.091 59.53 45.22L59.53 45.22ZM61.53 48.22 33.817 54.977C33.488 55.064 33.143 54.895 33.029 54.59 32.977 54.434 32.995 54.265 33.079 54.123 33.162 53.981 33.304 53.877 33.47 53.836L61.509 47C62.256 47.358 62.059 48.091 61.53 48.22L61.53 48.22Z'
					/>
					<path
						fill='#FFF'
						d='M31.138543,76.9843505 C31.0229927,77.0109646 30.9008327,77.0034855 30.7904863,76.9630409 L30.7836431,76.9605319 L29.3381508,76.4162207 C29.1556349,76.3475974 29.0282745,76.1976199 29.0041346,76.0228889 C28.9799948,75.8481579 29.0627512,75.6752783 29.221172,75.5694941 C29.3795927,75.4637098 29.5895563,75.4411278 29.7718236,75.5102705 L30.708479,75.8641398 L31.9358795,73.2994962 C31.9933638,73.1794088 32.1022018,73.0845234 32.2384499,73.0357142 C32.374698,72.986905 32.5271951,72.9881703 32.6623928,73.0392317 L32.6625786,73.0392997 L32.6552026,73.0560717 L32.6628846,73.0392293 C32.9442061,73.1458306 33.0752614,73.4346041 32.9558207,73.6846991 L31.5118037,76.7001558 C31.4439516,76.841079 31.3060762,76.9458928 31.1388462,76.9836808 L31.138543,76.9843505 Z'
					/>
					<path
						fill='#0CF'
						d='M107.361111,108 L47.6388889,108 C43.4220217,107.995204 40.0047634,104.554608 40,100.308943 L40,29.6910569 C40.0047634,25.4453916 43.4220217,22.0047959 47.6388889,22 L103.20066,22 L115,31.4690685 L115,100.308943 C114.995237,104.554608 111.577978,107.995204 107.361111,108 L107.361111,108 Z'
					/>
					<path
						fill='#FFF'
						d='M47.4704918,25 C44.450634,25.0033849 42.0034047,27.4363345 42,30.4385714 L42,100.561429 C42.0034035,103.563666 44.4506335,105.996616 47.4704918,106 L107.529508,106 C110.549367,105.996616 112.996597,103.563666 113,100.561429 L113,33.2632891 L102.572046,25 L47.4704918,25 Z'
					/>
					<path
						fill='#E9EBEF'
						d='M96.717 45 69.283 45C68.575 45 68 44.328 68 43.5 68 42.672 68.575 42 69.283 42L96.717 42C97.425 42 98 42.672 98 43.5 98 44.328 97.425 45 96.717 45L96.717 45ZM101.689 49 68.307 49C67.585 48.999 67 48.328 67 47.5 67 46.672 67.585 46.001 68.307 46L101.689 46C102.157 45.999 102.59 46.285 102.824 46.749 103.059 47.214 103.059 47.786 102.824 48.251 102.59 48.715 102.157 49.001 101.689 49L101.689 49ZM96.717 62 69.283 62C68.575 62 68 62.672 68 63.5 68 64.328 68.575 65 69.283 65L96.717 65C97.425 65 98 64.328 98 63.5 98 62.672 97.425 62 96.717 62ZM101.691 66 68.309 66C67.586 66 67 66.672 67 67.5 67 68.328 67.586 69 68.309 69L101.691 69C102.414 69 103 68.328 103 67.5 103 66.672 102.414 66 101.691 66ZM96.717 83 69.283 83C68.575 83 68 82.328 68 81.5 68 80.672 68.575 80 69.283 80L96.717 80C97.425 80 98 80.672 98 81.5 98 82.328 97.425 83 96.717 83L96.717 83ZM101.689 89 68.307 89C67.585 88.999 67 88.328 67 87.5 67 86.672 67.585 86.001 68.307 86L101.689 86C102.157 85.999 102.59 86.285 102.824 86.749 103.059 87.214 103.059 87.786 102.824 88.251 102.59 88.715 102.157 89.001 101.689 89L101.689 89Z'
					/>
					<circle cx='57.5' cy='45.5' r='4.5' fill='#E9EBEF' />
					<path
						fill='#E9EBEF'
						d='M63,65.4996293 C63.0004569,66.6928863 62.526624,67.8374359 61.68277,68.6814149 C60.838916,69.525394 59.6941879,69.9996451 58.5004884,69.9998072 C58.4618145,70.0005792 58.4231292,69.9990324 58.3846405,69.995175 C55.9150119,69.9318639 53.9582145,67.890168 54.0006782,65.4210081 C54.0431419,62.9518482 56.068987,60.9785935 58.5393332,61.0001754 C61.0096795,61.0217572 63.0007141,63.0301047 63,65.4996293 L63,65.4996293 Z'
					/>
					<circle cx='57.5' cy='85.5' r='4.5' fill='#E9EBEF' />
					<path
						fill='#0CF'
						d='M114.840263,32 L105.555658,32 C104.144207,32 103,30.8047377 103,29.3303091 L103,22.1668572 C103,22.1028609 103.035039,22.0445028 103.090192,22.0166426 C103.145345,21.9887824 103.210892,21.9963293 103.258913,22.0360685 L114.939441,31.7023515 C114.992726,31.7464424 115.01346,31.820932 114.991114,31.8879949 C114.968768,31.9550578 114.908239,32 114.840263,32 Z'
					/>
					<path
						fill='#0466FF'
						d='M63 65.467C63 66.669 62.555 67.822 61.761 68.672 60.967 69.522 59.89 70 58.767 70 58.731 70.001 58.694 69.999 58.658 69.995 57.548 67.032 57.855 63.677 59.48 61 61.513 61.373 63 63.259 63 65.467ZM68 63.501C68.001 62.673 68.578 62.001 69.29 62L76.153 62C76.554 62.949 76.839 63.959 77 65L69.29 65C68.578 65 68.001 64.329 68 63.501L68 63.501ZM76 66C76 67.013 75.887 68.022 75.664 69L68.266 69C67.567 69 67 68.328 67 67.5 67 66.672 67.567 66 68.266 66L76 66Z'
					/>
					<path
						fill='#0CF'
						d='M93.7964588,82.3164073 C93.3966502,82.9887125 92.5412176,83.2012894 91.8857666,82.7912185 L75.6662498,72.6432752 C75.2422491,72.3779936 74.9884308,71.900839 75.0004057,71.3915509 C75.0123806,70.8822627 75.2883295,70.4182141 75.7243051,70.1742076 C76.1602806,69.930201 76.6900478,69.943307 77.1140485,70.2085886 L93.333563,80.3565319 C93.9889955,80.7666328 94.1962375,81.6440841 93.7964588,82.3164073 Z'
					/>
					<path
						fill='#0CF'
						d='M76.3937923,72.7277867 C74.0767289,76.5259795 69.6995338,78.5546005 65.3032863,77.867707 C60.9070388,77.1808135 57.3575047,73.9136773 56.3098263,69.589759 C55.2621478,65.2658407 56.9226475,60.7366622 60.5170368,58.1141723 C64.111426,55.4916825 68.9318514,55.292336 72.7305559,57.6090877 C77.9116013,60.776942 79.5503495,67.5402894 76.3937923,72.7277867 Z M59.9578131,62.7038241 C57.5848864,66.593588 58.8149511,71.6702426 62.7052395,74.0428495 C66.5955279,76.4154564 71.6728671,75.1855579 74.0457942,71.2957941 C76.4187213,67.4060303 75.188657,62.3293757 71.2983688,59.9567684 C67.4072223,57.5896599 62.33402,58.8185563 59.9578131,62.7038241 L59.9578131,62.7038241 Z'
					/>
					<circle cx='113' cy='62' r='6' fill='#A0616A' />
					<path
						fill='#A0616A'
						d='M113.12329,94.2810089 C112.898875,93.6094206 112.983421,92.8777758 113.355697,92.2697942 C113.727972,91.6618126 114.352616,91.2352458 115.072777,91.0972069 C115.185588,91.0760529 115.299807,91.0625439 115.414591,91.0567798 L121.47482,81.7147511 L115.939121,76.6601265 C115.363537,76.1346118 115.125877,75.3528299 115.31662,74.6124095 C115.507363,73.871989 116.097161,73.2868506 116.861475,73.0797596 C117.625789,72.8726687 118.447022,73.075488 119.012526,73.6110033 L126.655725,80.8495376 L126.671107,80.8675541 C127.156594,81.5770704 127.099991,82.5084526 126.531855,83.1588996 L118.040557,92.8045552 C118.065909,92.881875 118.08702,92.9604192 118.103803,93.0398639 C118.247425,93.7316044 118.076977,94.4493401 117.635258,95.0128472 C117.193539,95.5763544 116.522506,95.9321071 115.790656,95.9907757 C115.717511,95.9969252 115.64473,96 115.572311,96 C114.454985,95.9955112 113.467098,95.3021043 113.12329,94.2810089 Z'
					/>
					<polygon fill='#A0616A' points='103 142 100.289 142 99 130 103 130' />
					<path
						fill='#2F2E41'
						d='M103.999827,145 L95,144.999632 L95,144.873749 C95,143.846353 95.3690529,142.861033 96.0259977,142.13456 C96.6829424,141.408087 97.5739519,141 98.5030048,141 L98.5032325,141 L104,141.000252 L103.999827,145 Z'
					/>
					<polygon fill='#A0616A' points='132 138.529 129.614 140 123 131.171 126.521 129' />
					<path
						fill='#2F2E41'
						d='M134 139.224 126.533 144 126.476 143.899C126.017 143.07 125.883 142.08 126.104 141.146 126.324 140.212 126.881 139.41 127.652 138.917L127.652 138.917 132.213 136 134 139.224ZM99.42 137.04C97.554 114.521 96.443 93.6 103.266 85.066L103.319 85 114.802 89.706 114.821 89.748C114.86 89.835 118.679 98.442 117.788 104.231L120.62 117.581 129.855 133.425C130.006 133.683 130.041 133.995 129.952 134.281 129.862 134.568 129.658 134.801 129.389 134.922L125.376 136.731C124.908 136.94 124.361 136.765 124.092 136.32L114.046 119.535 108.372 106.661C108.308 106.517 108.159 106.435 108.007 106.46 107.855 106.485 107.738 106.611 107.722 106.768L104.577 137.063C104.522 137.596 104.083 138.001 103.56 138L100.44 138C99.909 137.997 99.468 137.581 99.42 137.04L99.42 137.04Z'
					/>
					<path
						fill='#FF9D52'
						d='M103.213774,85.1756916 L103.158282,85.149078 L103.149614,85.0879542 C102.714507,82.0369625 103.229533,78.6469898 104.680283,75.012291 C106.158217,71.3355763 110.100624,69.2975597 113.947879,70.2214326 L113.947879,70.2214326 C116.115737,70.7566195 117.960394,72.177776 119.033414,74.139421 C120.09266,76.0809814 120.291067,78.3784919 119.580407,80.4734737 C117.751449,85.8318095 115.378875,90.8452817 115.355124,90.8953471 L115.305427,91 L103.213774,85.1756916 Z'
					/>
					<path
						fill='#A0616A'
						d='M89.9624954,77.8513711 C90.5292074,77.421143 91.2598923,77.2438109 91.97107,77.3639043 C92.6822478,77.4839976 93.3063637,77.8901087 93.6866981,78.4802603 C93.7458252,78.5728668 93.7981075,78.669356 93.8431581,78.769014 L104.88788,80.9153828 L107.828656,74.2709753 C108.134383,73.5801292 108.802197,73.1001929 109.57786,73.0138806 C110.353523,72.9275683 111.117692,73.2481598 111.579443,73.8536045 C112.041194,74.4590492 112.129483,75.2561938 111.810698,75.9415615 L107.501438,85.2050334 L107.489461,85.2248668 C106.976274,85.8993548 106.063686,86.1702784 105.244351,85.891381 L93.0713929,81.6968715 C93.0059809,81.7459741 92.937935,81.7917436 92.8675356,81.8339913 C92.2536813,82.1996179 91.5067578,82.2960833 90.8143178,82.099165 C90.1218777,81.9022467 89.5496939,81.4306494 89.2414416,80.8027912 C89.2103578,80.7401404 89.182348,80.67675 89.1574122,80.6126199 C88.7769905,79.6216322 89.1017456,78.5077974 89.9624954,77.8513711 L89.9624954,77.8513711 Z'
					/>
					<path
						fill='#2F2E41'
						d='M133.930998,68.4606961 C133.373423,67.4651035 132.561055,66.5532025 131.487104,66.1851797 C130.10013,65.7099207 128.598303,66.2159706 127.172313,66.553839 C126.07362,66.8141389 124.925788,66.9734706 123.818349,66.753804 C122.711022,66.5341256 121.642827,65.8846954 121.138995,64.8669976 C120.397753,63.3698276 121.022187,61.5844525 120.977076,59.9119187 C120.930116,58.0672055 120.045796,56.3462076 118.577957,55.2429307 C117.110117,54.1396538 115.221082,53.7761067 113.453093,54.256644 C112.087638,53.8595428 110.851307,53.8228094 110.006715,54.8806179 C107.793873,54.880631 106,56.6883551 106,58.9182998 L109.831214,58.9182998 C109.649577,59.9027582 109.85716,60.9197656 110.409618,61.7520614 C111.085959,62.7533638 112.261989,63.4776954 112.488356,64.6672678 C112.707818,65.8200283 111.930204,66.9138055 111.114501,67.7517456 C110.29891,68.5896834 109.352701,69.4199757 109.098023,70.5653592 C108.941992,71.4348614 109.119177,72.3316361 109.593799,73.0746004 C110.067601,73.808541 110.655381,74.4609705 111.334653,75.006928 C114.395139,77.6491577 118.03247,79.5243257 121.949525,80.4792554 C124.708054,81.1485154 127.725289,81.3219956 130.286106,80.0908066 C132.356806,79.0952367 133.924674,77.2783589 134.615628,75.0736663 C135.306582,72.8689737 135.058655,70.4741923 133.931,68.4606984 L133.930998,68.4606961 Z'
					/>
					<path
						fill='#E5FAFF'
						d='M134.765625,145 L90.234375,145 C90.1049333,145 90,144.776142 90,144.5 C90,144.223858 90.1049333,144 90.234375,144 L134.765625,144 C134.895067,144 135,144.223858 135,144.5 C135,144.776142 134.895067,145 134.765625,145 Z'
					/>
				</g>
			</g>
		</SvgIcon>
	);
};
