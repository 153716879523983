import { StyleSheet } from 'aphrodite';
import { titles } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	container: {
		alignItems: 'center',
		background: '#fff',
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
		paddingTop: 20,
	},
	iconContainer: {
		display: 'inline-block',
		position: 'relative',
	},
	link: {
		':hover': {
			textDecoration: 'underline',
		},
		textDecoration: 'none',
	},
	successIcon: {
		height: 42,
		left: 6,
		position: 'absolute',
		top: '26%',
		width: 42,
		zIndex: 1,
	},
	overlayCloseButton: {
		marginTop: 20,
	},
	titleMessage: {
		color: titles,
		fontSize: 18,
		marginTop: 26,
	},
});
