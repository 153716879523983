import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	activity: {},
	container: {
		boxSizing: 'border-box',
		display: 'flex',
		width: '100%',
		height: '100%',
	},
	splitDetail: {
		borderLeft: '1px solid #ccc',
		flex: '1 0 0',
		overflowY: 'auto',
		width: '100%',
	},
	splitMaster: {
		flex: '0 0 420px',
		overflowY: 'auto',
	},
});
