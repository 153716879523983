import { css } from 'aphrodite';
import * as React from 'react';
import * as yup from 'yup';
import { fixPhoneNumberAreaCode, getPartialFormattedPhoneNumber } from '../../../../models/UiUtils';
import {
	useTelephonyConfigBusinessIndustry,
	useTelephonyConfigBusinessJobPositions,
	useTelephonyConfigBusinessType,
	useTelephonyConfigExchanges,
	useTelephonyConfigurationQuery,
} from '../../../../queries';
import { TextInput } from '../../../components/TextInput';
import { USStates } from '../../../components/ValueSelectors/StateSelector';
import { baseStyleSheet as bs } from '../../../styles/styles';
import { TextingRegistrationFormLayout } from '../presentation';
import { useFocusFirstErrorField } from './hooks/useFocusFirstErrorField';
import { FormErrorList } from './presentation';
import { styleSheet } from './styles';

export const defaultValueDynamicKey = ({ loading, name }: { loading: boolean; name: string }) => {
	return loading ? `${name}-loading` : name;
};

const validationSchema = yup
	.object({
		// businessTitle: yup.string().required('Job Title is required'),
		city: yup.string().required('City is required'),
		domain: yup.string().required('Website URL is required'),
		ein: yup.string().when('type', ([businessType], schema) => {
			return businessType !== 'SoleProprietorship'
				? schema.matches(/^[0-9]{2}-?[0-9]{7}$/, 'EIN must be ######### or ##-#######').required('EIN is required')
				: schema.notRequired();
		}),
		email: yup
			.string()
			.matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/, 'Must be a valid email address')
			.required('Email is required'),
		stockExchange: yup.string().when('type', ([businessType], schema) => {
			return businessType === 'PublicCompany' ? schema.required('Exchange Type is required') : schema.notRequired();
		}),
		stockSymbol: yup.string().when('type', ([businessType], schema) => {
			return businessType === 'PublicCompany' ? schema.required('Stock symbol is required') : schema.notRequired();
		}),
		firstName: yup.string().required('First Name is required'),
		industry: yup.string().required('Business Industry is required'),
		jobPosition: yup.string().required('Position is required'),
		lastName: yup.string().required('Last Name is required'),
		legalBusinessName: yup.string().required('Legal Business Name is required'),
		phoneNumber: yup
			.string()
			.matches(/^(\([0-9]{3}\)\s)[0-9]{3}-[0-9]{4}$/, 'Phone number is not valid')
			.required('Phone Number is required'),
		mobilePhoneNumber: yup.string().matches(/^(\([0-9]{3}\)\s)[0-9]{3}-[0-9]{4}$/, {
			message: 'Mobile phone number is not valid',
			excludeEmptyString: true,
		}),
		postalCode: yup
			.string()
			.matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Must be ##### or #####-####')
			.required('Postal Code is required'),
		stateProvince: yup.string().required('State/Province is required'),
		street: yup.string().required('Registered Business Address is required'),
		type: yup.string().required('Business Type is required'),
	})
	.required();

export type IBrandRegistrationFormData = yup.InferType<typeof validationSchema> & {
	businessTitle: string;
};

export const TextingRegistrationFormBrandReg = ({
	defaultValues,
	onSave,
}: {
	defaultValues?: IBrandRegistrationFormData;
	onSave: (data: IBrandRegistrationFormData) => void;
}) => {
	const [formErrors, setFormErrors] = React.useState<Partial<Record<keyof IBrandRegistrationFormData, string>>>({});
	const businessTypesQuery = useTelephonyConfigBusinessType();
	const businessIndustryQuery = useTelephonyConfigBusinessIndustry();
	const jobPositionsQuery = useTelephonyConfigBusinessJobPositions();
	const stockExchangesQuery = useTelephonyConfigExchanges();
	const configurationQuery = useTelephonyConfigurationQuery();
	const [initialBusinessType, setInitialBusinessType] = React.useState(
		defaultValues?.type ?? configurationQuery?.data?.clientProfile?.businessInformation?.type ?? ''
	);
	const [businessType, setBusinessType] = React.useState(initialBusinessType);
	const [taxEinResponse, setTaxEinResponse] = React.useState(
		configurationQuery?.data?.clientProfile?.businessInformation?.type === 'SoleProprietorship' ? 'No' : 'Yes'
	);

	const changeTaxEinResponse = (value: string) => {
		setTaxEinResponse(value);
		if (value === 'Yes') {
			setBusinessType('');
		} else {
			setBusinessType('SoleProprietorship');
		}
	};

	if (
		defaultValues?.type == null &&
		configurationQuery?.data?.clientProfile?.businessInformation?.type &&
		configurationQuery.data.clientProfile.businessInformation.type !== initialBusinessType
	) {
		const bizType = configurationQuery.data.clientProfile.businessInformation.type;
		setBusinessType(bizType);
		setInitialBusinessType(bizType);
	}
	const phoneNumberInputRef = React.useRef<HTMLInputElement>(null);
	const mobileNumberInputRef = React.useRef<HTMLInputElement>(null);
	const formRef = React.useRef<HTMLFormElement>(null);
	useFocusFirstErrorField({ formRef, formErrors });
	const handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
		ev.preventDefault();
		const formData = new FormData(ev.currentTarget);
		const data = Object.fromEntries(formData.entries());
		try {
			const result = await validationSchema.validate(data, { abortEarly: false });
			onSave({ ...result, businessTitle: result.jobPosition });
		} catch (error) {
			if (error instanceof yup.ValidationError) {
				const validationErrors: Record<string, string> = {};
				error.inner.forEach(err => {
					if (err.path) {
						validationErrors[err.path] = err.message;
					}
				});
				setFormErrors(validationErrors);
			}
		}
	};

	const availabileBusinessTypes = (businessTypesQuery?.data ?? []).filter(
		x =>
			(taxEinResponse === 'Yes' && x.value !== 'SoleProprietorship') ||
			(taxEinResponse === 'No' && x.value === 'SoleProprietorship')
	);

	return (
		<TextingRegistrationFormLayout>
			<header className={css(styleSheet.containerHeader)}>
				<h2 className={css(styleSheet.headline)}>Texting Campaign Registration</h2>
				<p className={css(styleSheet.subHeadline)}>
					Mobile carriers now require all business SMS users to register their brand in compliance with new regulations.
					Please fill out the form below, so we can help to ensure the deliverability of your text messages.
				</p>
			</header>
			<form onSubmit={handleSubmit} ref={formRef} className={css(bs.wFull)}>
				<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='type-input'>
					Do you have a Tax ID (EIN) for your business?
				</label>
				<select
					className={css(
						bs.border,
						bs.borderSolid,
						bs.borderGray300,
						bs.h10,
						bs.rounded,
						bs.textSm,
						bs.textTitles,
						bs.wFull
					)}
					id='type-input'
					name='type'
					value={taxEinResponse}
					aria-invalid={Boolean(formErrors?.type) || undefined}
					aria-describedby={formErrors?.type ? 'typeError' : undefined}
					onChange={ev => changeTaxEinResponse(ev.target.value)}
				>
					<option value='Yes'>Yes</option>
					<option value='No'>No</option>
				</select>
				<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='type-input'>
					Business Type
				</label>
				<select
					className={css(
						bs.border,
						bs.borderSolid,
						bs.borderGray300,
						bs.h10,
						bs.rounded,
						bs.textSm,
						bs.textTitles,
						bs.wFull
					)}
					id='type-input'
					name='type'
					value={businessType}
					aria-invalid={Boolean(formErrors?.type) || undefined}
					aria-describedby={formErrors?.type ? 'typeError' : undefined}
					onChange={ev => setBusinessType(ev.target.value)}
				>
					<option value='' />
					{availabileBusinessTypes.map(type => {
						return (
							<option value={type.value} key={type.value}>
								{type.description}
							</option>
						);
					})}
				</select>
				<FormErrorList id='typeError' errors={formErrors?.type} />
				{businessType === 'PublicCompany' ? (
					<div className={css(bs.flex, bs.gap2)}>
						<div className={css(bs.flex1)}>
							<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='stockExchange-input'>
								Exchange name
							</label>
							<select
								key={defaultValueDynamicKey({
									loading: configurationQuery.isLoading || stockExchangesQuery.isLoading,
									name: 'stockExchange',
								})}
								className={css(
									bs.border,
									bs.borderSolid,
									bs.borderGray300,
									bs.h10,
									bs.rounded,
									bs.textSm,
									bs.textTitles,
									bs.wFull
								)}
								id='stockExchange-input'
								name='stockExchange'
								defaultValue={
									defaultValues?.stockExchange ??
									configurationQuery.data?.clientProfile?.businessInformation?.stockExchange ??
									''
								}
								aria-invalid={Boolean(formErrors?.stockExchange) || undefined}
								aria-describedby={formErrors?.stockExchange ? 'stockExchangeError' : undefined}
							>
								<option value='' />
								{(stockExchangesQuery.data ?? []).map(exchange => {
									return (
										<option value={exchange.value} key={exchange.value}>
											{exchange.description}
										</option>
									);
								})}
							</select>
							<FormErrorList id='stockExchangeError' errors={formErrors?.stockExchange} />
						</div>
						<div className={css(bs.flex1)}>
							<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='stockSymbol-input'>
								Stock Symbol
							</label>
							<TextInput
								className={css(styleSheet.formField)}
								inputId='stockSymbol-input'
								type='text'
								name='stockSymbol'
								defaultValue={
									defaultValues?.stockSymbol ??
									configurationQuery.data?.clientProfile?.businessInformation?.stockSymbol ??
									''
								}
								aria-invalid={Boolean(formErrors?.stockSymbol) || undefined}
								aria-describedby={formErrors?.stockSymbol ? 'stockSymbolError' : undefined}
							/>
							<FormErrorList id='stockSymbolError' errors={formErrors?.stockSymbol} />
						</div>
					</div>
				) : null}
				{businessType === 'SoleProprietorship' ? (
					<>
						<label className={css(styleSheet.label)} htmlFor='mobilePhoneNumber-input'>
							Mobile phone number
						</label>
						<TextInput
							inputRef={mobileNumberInputRef}
							className={css(styleSheet.formField)}
							inputId='mobilePhoneNumber-input'
							type='text'
							name='mobilePhoneNumber'
							defaultValue={
								defaultValues?.mobilePhoneNumber ??
								fixPhoneNumberAreaCode(
									configurationQuery.data?.clientProfile?.primaryRepresentative?.mobilePhoneNumber ?? ''
								) ??
								''
							}
							aria-invalid={Boolean(formErrors?.mobilePhoneNumber) || undefined}
							aria-describedby={formErrors?.mobilePhoneNumber ? 'mobilePhoneNumberError' : undefined}
							onBlur={ev => {
								if (mobileNumberInputRef.current) {
									mobileNumberInputRef.current.value = getPartialFormattedPhoneNumber(ev.target.value);
								}
							}}
						/>
						<p className={css(bs.textNavigation, bs.textXs, bs.mb0, bs.mt1)}>Required for account verification</p>
						<FormErrorList id='mobilePhoneNumberError' errors={formErrors?.mobilePhoneNumber} />
					</>
				) : null}
				{businessType !== 'SoleProprietorship' ? (
					<>
						<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='ein-input'>
							Tax EIN
						</label>
						<TextInput
							className={css(styleSheet.formField)}
							type='text'
							inputId='ein-input'
							name='ein'
							defaultValue={
								defaultValues?.ein ?? configurationQuery.data?.clientProfile?.businessInformation?.ein ?? ''
							}
							aria-invalid={Boolean(formErrors?.ein) || undefined}
							aria-describedby={formErrors?.ein ? 'einError' : undefined}
						/>
						<FormErrorList id='einError' errors={formErrors?.ein} />
					</>
				) : null}
				<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='legalBusinessName-input'>
					Legal Business Name {taxEinResponse !== 'No' ? <>(must match to associated EIN)</> : null}
				</label>
				<TextInput
					className={css(styleSheet.formField)}
					inputId='legalBusinessName-input'
					name='legalBusinessName'
					type='text'
					defaultValue={
						defaultValues?.legalBusinessName ??
						configurationQuery.data?.clientProfile?.businessInformation?.legalBusinessName ??
						''
					}
					aria-invalid={Boolean(formErrors?.legalBusinessName) || undefined}
					aria-describedby={formErrors?.legalBusinessName ? 'legalBusinessNameError' : undefined}
				/>
				<FormErrorList id='legalBusinessNameError' errors={formErrors?.legalBusinessName} />
				<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='street-input'>
					Registered Business Address
				</label>
				<TextInput
					className={css(styleSheet.formField)}
					inputId='street-input'
					type='text'
					name='street'
					defaultValue={defaultValues?.street ?? configurationQuery.data?.clientProfile?.address?.street ?? ''}
					aria-invalid={Boolean(formErrors?.street) || undefined}
					aria-describedby={formErrors?.street ? 'streetError' : undefined}
				/>
				<FormErrorList id='streetError' errors={formErrors?.street} />
				<div className={css(bs.flex, bs.gap2)}>
					<div className={css(bs.flex1)}>
						<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='city-input'>
							City
						</label>
						<TextInput
							className={css(styleSheet.formField)}
							inputId='city-input'
							type='text'
							name='city'
							defaultValue={defaultValues?.city ?? configurationQuery.data?.clientProfile?.address?.city ?? ''}
							aria-invalid={Boolean(formErrors?.city) || undefined}
							aria-describedby={formErrors?.city ? 'cityError' : undefined}
						/>
						<FormErrorList id='cityError' errors={formErrors?.city} />
					</div>
					<div className={css(bs.flex1)}>
						<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='stateProvince-input'>
							State/Province
						</label>
						<select
							key={defaultValueDynamicKey({ loading: configurationQuery.isLoading, name: 'stateProvince' })}
							className={css(
								bs.border,
								bs.borderSolid,
								bs.borderGray300,
								bs.h10,
								bs.rounded,
								bs.textSm,
								bs.textTitles,
								bs.wFull,
								bs.p3
							)}
							id='stateProvince-input'
							name='stateProvince'
							defaultValue={
								defaultValues?.stateProvince ??
								configurationQuery.data?.clientProfile?.address?.stateProvince ??
								undefined
							}
							aria-invalid={Boolean(formErrors?.stateProvince) || undefined}
							aria-describedby={formErrors?.stateProvince ? 'stateProvinceError' : undefined}
						>
							<option value='' />
							{USStates.map(state => {
								return (
									<option value={state.value} key={state.value}>
										{state.name}
									</option>
								);
							})}
						</select>
						<FormErrorList id='stateProvinceError' errors={formErrors?.stateProvince} />
					</div>
					<div className={css(bs.flex1)}>
						<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='postalCode-input'>
							Zip/Postal Code
						</label>
						<TextInput
							className={css(styleSheet.formField)}
							inputId='postalCode-input'
							type='text'
							name='postalCode'
							defaultValue={
								defaultValues?.postalCode ?? configurationQuery.data?.clientProfile?.address?.postalCode ?? ''
							}
							aria-invalid={Boolean(formErrors?.postalCode) || undefined}
							aria-describedby={formErrors?.postalCode ? 'postalCodeError' : undefined}
						/>
						<FormErrorList id='postalCodeError' errors={formErrors?.postalCode} />
					</div>
				</div>
				<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='industry-input'>
					Business Industry
				</label>
				<select
					key={defaultValueDynamicKey({
						loading: configurationQuery.isLoading || businessIndustryQuery.isLoading,
						name: 'industry',
					})}
					className={css(
						bs.border,
						bs.borderSolid,
						bs.borderGray300,
						bs.h10,
						bs.rounded,
						bs.textSm,
						bs.textTitles,
						bs.wFull
					)}
					id='industry-input'
					name='industry'
					defaultValue={
						defaultValues?.industry ??
						configurationQuery.data?.clientProfile?.businessInformation?.industry ??
						undefined
					}
					aria-invalid={Boolean(formErrors?.industry) || undefined}
					aria-describedby={formErrors?.industry ? 'industryError' : undefined}
				>
					<option value='' />
					{(businessIndustryQuery?.data ?? []).map(industry => {
						return (
							<option value={industry.value} key={industry.value}>
								{industry.description}
							</option>
						);
					})}
				</select>
				<FormErrorList id='industryError' errors={formErrors?.industry} />
				<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='domain-input'>
					Website URL
				</label>
				<TextInput
					className={css(styleSheet.formField)}
					inputId='domain-input'
					type='text'
					name='domain'
					defaultValue={
						defaultValues?.domain ?? configurationQuery.data?.clientProfile?.businessInformation?.domain ?? ''
					}
					aria-invalid={Boolean(formErrors?.domain) || undefined}
					aria-describedby={formErrors?.domain ? 'domainError' : undefined}
				/>
				<FormErrorList id='domainError' errors={formErrors?.domain} />
				<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='firstName-input'>
					First Name
				</label>
				<TextInput
					className={css(styleSheet.formField)}
					inputId='firstName-input'
					type='text'
					name='firstName'
					defaultValue={
						defaultValues?.firstName ?? configurationQuery.data?.clientProfile?.primaryRepresentative?.firstName ?? ''
					}
					aria-invalid={Boolean(formErrors?.firstName) || undefined}
					aria-describedby={formErrors?.firstName ? 'firstNameError' : undefined}
				/>
				<FormErrorList id='firstNameError' errors={formErrors?.firstName} />
				<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='lastName-input'>
					Last Name
				</label>
				<TextInput
					className={css(styleSheet.formField)}
					inputId='lastName-input'
					type='text'
					name='lastName'
					defaultValue={
						defaultValues?.lastName ?? configurationQuery.data?.clientProfile?.primaryRepresentative?.lastName ?? ''
					}
					aria-invalid={Boolean(formErrors?.lastName) || undefined}
					aria-describedby={formErrors?.lastName ? 'lastNameError' : undefined}
				/>
				<FormErrorList id='lastNameError' errors={formErrors?.lastName} />
				<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='email-input'>
					{businessType !== 'SoleProprietorship' ? <>Email</> : <>Personal Email (Outlook, Google, Yahoo, AOL, etc.)</>}
				</label>
				<TextInput
					className={css(styleSheet.formField)}
					inputId='email-input'
					type='text'
					name='email'
					defaultValue={
						defaultValues?.email ?? configurationQuery.data?.clientProfile?.primaryRepresentative?.email ?? ''
					}
					aria-invalid={Boolean(formErrors?.email) || undefined}
					aria-describedby={formErrors?.email ? 'emailError' : undefined}
				/>
				{businessType === 'SoleProprietorship' ? (
					<p className={css(bs.textNavigation, bs.textXs, bs.mb0, bs.mt1)}>
						A personal email is required for Sole Proprietorship registrations. Business emails are not accepted.
					</p>
				) : null}
				<FormErrorList id='emailError' errors={formErrors?.email} />
				<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='phoneNumber-input'>
					Phone Number
				</label>
				<TextInput
					inputRef={phoneNumberInputRef}
					className={css(styleSheet.formField)}
					inputId='phoneNumber-input'
					type='text'
					name='phoneNumber'
					defaultValue={
						defaultValues?.phoneNumber ??
						fixPhoneNumberAreaCode(configurationQuery.data?.clientProfile?.primaryRepresentative?.phoneNumber ?? '') ??
						''
					}
					aria-invalid={Boolean(formErrors?.phoneNumber) || undefined}
					aria-describedby={formErrors?.phoneNumber ? 'phoneNumberError' : undefined}
					onBlur={ev => {
						if (phoneNumberInputRef.current) {
							phoneNumberInputRef.current.value = getPartialFormattedPhoneNumber(ev.target.value);
						}
					}}
				/>
				<FormErrorList id='phoneNumberError' errors={formErrors?.phoneNumber} />
				<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='jobPosition-input'>
					Position
				</label>
				<select
					key={defaultValueDynamicKey({
						loading: configurationQuery.isLoading || jobPositionsQuery.isLoading,
						name: 'jobPosition',
					})}
					className={css(
						bs.border,
						bs.borderSolid,
						bs.borderGray300,
						bs.h10,
						bs.rounded,
						bs.textSm,
						bs.textTitles,
						bs.wFull
					)}
					id='jobPosition-input'
					name='jobPosition'
					defaultValue={
						defaultValues?.jobPosition ?? configurationQuery.data?.clientProfile?.primaryRepresentative?.jobPosition
					}
					aria-invalid={Boolean(formErrors?.jobPosition) || undefined}
					aria-describedby={formErrors?.jobPosition ? 'jobPositionError' : undefined}
				>
					<option value='' />
					{(jobPositionsQuery?.data ?? []).map(pos => {
						return (
							<option value={pos.value} key={pos.value}>
								{pos.description}
							</option>
						);
					})}
				</select>
				<FormErrorList id='jobPositionError' errors={formErrors?.jobPosition} />
				<div className={css(styleSheet.formFooter)}>
					<button className={css(bs.ctaButton)} type='submit'>
						Save & Continue
					</button>
				</div>
			</form>
		</TextingRegistrationFormLayout>
	);
};
