import { header, surveysTint } from '../../../styles/colors';
import { CssSelectors, baseStyleSheet } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	clientFeedbackButtonColumn: {
		boxSizing: 'border-box',
		padding: '0.5rem 0.25rem 0',
		width: 24,
	},
	clientFeedbackCarouselWrapper: {
		display: 'grid',
		gridTemplateColumns: 'auto 1fr auto',
	},
	clientFeedbackHeadline: {
		marginLeft: 24,
	},
	clientFeedbackSliderButton: {},
	closeButton: {
		cursor: 'pointer',
		position: 'absolute',
		right: '1.25rem',
		top: '1rem',
	},
	column1: {
		'@media (min-width: 1200px)': {
			marginBottom: 0,
			marginLeft: '1rem',
		},
		display: 'flex',
		flexDirection: 'column',
		marginBottom: '0.5rem',
		...CssSelectors.allChildren(
			{
				marginTop: '0.5rem',
			},
			':not(:first-child)'
		),
		width: 150,
	},
	column2: {
		display: 'flex',
		flex: '1',
		flexDirection: 'column',
		padding: '0 1rem',
		...CssSelectors.allChildren(
			{
				marginTop: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	emblaContainer: {
		display: 'grid',
		gridAutoColumns: '100%',
		gridAutoFlow: 'column',
		gridGap: '1rem',
	},
	emblaSlide: {
		flex: '0 0 100%',
		minWidth: 0,
		position: 'relative',
	},
	emblaViewport: {
		overflow: 'hidden',
	},
	feedback: {
		fontSize: '1rem',
		lineHeight: 1.5,
		margin: 0,
		maxHeight: 53,
		overflow: 'hidden',
	},
	hide: {
		display: 'none',
	},
	ratingBar: {
		backgroundColor: surveysTint,
		borderRadius: 12,
		boxSizing: 'border-box',
		color: '#0D0F11',
		display: 'block',
		fontSize: '0.75rem',
		padding: '0.25rem 0.5rem',
	},
	ratingBarContainer: {
		alignItems: 'center',
		display: 'flex',
	},
	ratingBarLabel: {
		marginRight: '0.25rem',
	},
	ratingBarLabelContainer: {
		alignItems: 'center',
		color: header,
		display: 'flex',
		fontWeight: 600,
	},
	ratingBarWrapper: {
		flex: 1,
		marginRight: '0.5rem',
	},
	rightContainerColumn: {
		display: 'flex',
		flex: 1,
		flexWrap: 'wrap',
	},
	root: {
		alignItems: 'flex-start',
		justifyContent: 'center',
		position: 'relative',
		width: '100%',
	},
	satisfactionRating: {
		color: surveysTint,
		fontSize: '2.5rem',
		lineHeight: '47px',
		marginRight: '0.5rem',
	},
	satisfactionRatingContainer: {
		alignItems: 'center',
		display: 'flex',
	},
	starButton: {
		alignItems: 'center',
		background: surveysTint,
		borderRadius: 12,
		display: 'flex',
		flexShrink: 0,
		justifyContent: 'center',
		marginRight: '1rem',
		padding: '0.5rem',
	},
	surveyLink: {
		alignItems: 'center',
		color: '#046CB6',
		display: 'flex',
		textDecoration: 'none',
	},
	surveySuggestion: {
		color: header,
	},
	userRaterName: {
		display: 'inline-block',
		...baseStyleSheet.fontBold,
		fontSize: '0.875rem',
		fontWeight: 700,
	},
	userRaterNumber: {
		':before': {
			content: "'-'",
			display: 'inline-block',
			margin: '0 0.25rem',
		},
		color: header,
		...baseStyleSheet.fontBold,
		fontSize: '0.875rem',
		fontWeight: 700,
	},
	userRatingLeft: {
		alignItems: 'center',
		display: 'flex',
		flex: 1,
		...CssSelectors.allChildren(
			{
				marginLeft: '0.25rem',
			},
			':not(:first-child)'
		),
	},
	userRatingWrapper: {
		alignItems: 'center',
		display: 'flex',
		width: '100%',
	},
	viewContactLink: {
		alignItems: 'center',
		color: '#046CB6',
		display: 'flex',
		textDecoration: 'none',
	},
});
