import { IOperationResultNoValue, IUserPreferences } from '@ViewModels';
import { css } from 'aphrodite';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IToasterComponentProps,
	IUserSessionComponentProps,
	ToasterViewModelKey,
	UserSessionViewModelKey,
} from '../../../../../models/AppState';
import { EventLogger, IEventLoggingComponentProps, withEventLogging } from '../../../../../models/Logging';
import {
	KeepInTouchCommitmentInterval,
	KeepInTouchCommitmentIntervalError,
	KeepInTouchCommitmentPreferencesViewModel,
	UserViewModel,
} from '../../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../../styles/styles';
import { LoadingSpinner } from '../../../LoadingSpinner';
import { RadioButton } from '../../../RadioButton';
import { SettingsGroup } from '../../SettingsGroup';
import { SettingsGroupIcon } from '../../SettingsGroupIcon';
import { styleSheet } from './styles';

interface IProps
	extends IToasterComponentProps,
		IErrorMessageComponentProps,
		IUserSessionComponentProps,
		IEventLoggingComponentProps {
	className?: string;
	userViewModel: UserViewModel;
}

interface IState {
	selectedDigestOption?: [string, DigestNotificationInterval];
	selectedRecentMeetingsOption?: [string, boolean];
	showLoading?: boolean;
}

type DigestNotificationInterval = 'Never' | 'Daily' | 'Weekly';

const DigestRadioButtonOptions: [string, DigestNotificationInterval][] = [
	['Send me daily updates', 'Daily'],
	['Send me weekly updates', 'Weekly'],
	["I don't want to receive updates", 'Never'],
];

const RecentMeetingsRadioButtonOptions: [string, boolean][] = [
	['Show meetings with me as the only attendee', true],
	['Do not show meetings with me as the only attendee', false],
];

class _EmailAndCalendarSettings extends React.Component<IProps, IState> {
	@observable.ref
	// @ts-ignore
	private mKeepInTouchCommitmentPreferences: KeepInTouchCommitmentPreferencesViewModel;
	public readonly state: IState = {};

	public UNSAFE_componentWillMount() {
		const { userSession } = this.props;
		this.mKeepInTouchCommitmentPreferences = new KeepInTouchCommitmentPreferencesViewModel(
			// @ts-ignore
			userSession,
			// @ts-ignore
			userSession.user
		);
		this.onResetChanges();
	}

	public render() {
		const { className, userViewModel, userSession } = this.props;
		const { selectedDigestOption, selectedRecentMeetingsOption, showLoading } = this.state;

		// @ts-ignore
		// @ts-ignore
		// @ts-ignore
		// @ts-ignore
		const hasCalendaring = !!userSession.user.emailProviderFeatures.calendar.canViewHistoryAll;

		const footer = (
			<div className={css(styleSheet.buttonContainer)}>
				<button className={css(baseStyleSheet.ctaButton)} disabled={userViewModel.isBusy} onClick={this.onSaveClicked}>
					Save
				</button>
				<button
					className={css(baseStyleSheet.ctaButtonReverse)}
					disabled={userViewModel.isBusy}
					onClick={this.onResetChanges}
				>
					Cancel
				</button>
			</div>
		);
		return (
			<SettingsGroup
				className={`${css(styleSheet.calendarSettings)} ${className || ''}`}
				description={`Adjust email frequency${
					hasCalendaring ? ', recent meetings, and calendar' : ' and recent meetings'
				} settings.`}
				footer={footer}
				icon={<SettingsGroupIcon type='KitAndEmail' />}
				name={`Keep in Touch, Email${hasCalendaring ? ' & Calendar' : ''} Settings`}
			>
				<div className='settings-group-radio-button-group'>
					<div className='settings-group-radio-button-group-header'>Email Settings</div>
					{DigestRadioButtonOptions.map((item, index) => {
						return (
							<RadioButton
								checked={selectedDigestOption === item}
								disabled={userViewModel.isBusy}
								id={`digest-email-settings-${index}`}
								key={index}
								name='digest-email-settings'
								onChange={this.onEmailDigestOptionChange(item)}
								value={item[1]}
							>
								{item[0]}
							</RadioButton>
						);
					})}
				</div>
				{!!hasCalendaring && (
					<div className='settings-group-radio-button-group'>
						<div className='settings-group-radio-button-group-header'>Recent Meetings</div>
						{RecentMeetingsRadioButtonOptions.map((item, index) => {
							return (
								<RadioButton
									checked={selectedRecentMeetingsOption === item}
									disabled={userViewModel.isBusy}
									id={`recent-meetings-settings-${index}`}
									key={index}
									name='recent-meetings-settings'
									onChange={this.onRecentMeetingOptionChanged(item)}
									value={`${item[1]}`}
								>
									{item[0]}
								</RadioButton>
							);
						})}
					</div>
				)}
				{!!showLoading && !!userViewModel.isBusy && <LoadingSpinner className='absolute-center' type='large' />}
			</SettingsGroup>
		);
	}

	private onSaveClicked = () => {
		const { userViewModel, toaster, errorMessages, userSession } = this.props;
		const { selectedDigestOption, selectedRecentMeetingsOption } = this.state;

		// validate
		// validate
		// @ts-ignore
		let errorMessage: string = null;
		switch (this.mKeepInTouchCommitmentPreferences.error) {
			case KeepInTouchCommitmentIntervalError.SelectAtLeasTwoDays: {
				errorMessage = 'You must select at least two days or choose "Weekly" instead.';
				break;
			}
			case KeepInTouchCommitmentIntervalError.SelectAtLeastOneDay: {
				errorMessage = 'You must select at least one day.';
				break;
			}
			case KeepInTouchCommitmentIntervalError.NeverNotSatisfied: {
				this.mKeepInTouchCommitmentPreferences.setInterval(KeepInTouchCommitmentInterval.Never);
				break;
			}
			default: {
				break;
			}
		}

		if (errorMessage) {
			// @ts-ignore
			errorMessages.push({
				messages: [errorMessage],
			});
			return;
		}

		const preferences: IUserPreferences = {
			// @ts-ignore
			digestNotificationInterval: selectedDigestOption[1],
			// @ts-ignore
			displayMeetingsWithoutOtherAttendees: selectedRecentMeetingsOption[1],
			keepInTouchCommitmentPreferences: this.mKeepInTouchCommitmentPreferences.toJs(),
		};
		const promise = userViewModel.updateUserPreferences(preferences);
		if (promise) {
			this.setState({
				showLoading: true,
			});

			EventLogger.logEvent(
				{
					action: 'Save',
					category: 'EmailAndCalendarSettings',
				},
				{ ...preferences }
			);

			promise
				.then(prefs => {
					// @ts-ignore
					// @ts-ignore
					userSession.user.userPreferences = prefs;
					this.setState({
						showLoading: false,
					});
					// @ts-ignore
					toaster.push({
						message: 'Changes were saved successfully!',
						type: 'successMessage',
					});
				})
				.catch((error: IOperationResultNoValue) => {
					EventLogger.logEvent(
						{
							action: 'Save-Error',
							category: 'EmailAndCalendarSettings',
						},
						{ ...error }
					);
					// @ts-ignore
					errorMessages.push({
						// @ts-ignore
						messages: [error.systemMessage],
					});
				});
		}
	};

	@action
	private onResetChanges = () => {
		const { userViewModel } = this.props;
		// Set the selected value for the dropdown
		let selectedValue: DigestNotificationInterval = 'Daily'; // Default to 1, which is daily notifications
		if (!!userViewModel.preferences && !!userViewModel.preferences.digestNotificationInterval) {
			selectedValue = userViewModel.preferences.digestNotificationInterval;
		}

		this.mKeepInTouchCommitmentPreferences.reset();
		this.setState({
			selectedDigestOption: DigestRadioButtonOptions.find(x => x[1] === selectedValue),
			selectedRecentMeetingsOption: RecentMeetingsRadioButtonOptions.find(
				// @ts-ignore
				x => x[1] === (userViewModel.preferences.displayMeetingsWithoutOtherAttendees || false)
			),
		});
	};

	private onRecentMeetingOptionChanged =
		(selectedRecentMeetingsOption: [string, boolean]) => (e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.target.checked) {
				this.setState({
					selectedRecentMeetingsOption,
				});
			}
		};

	private onEmailDigestOptionChange =
		(selectedDigestOption: [string, DigestNotificationInterval]) => (e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.target.checked) {
				this.setState({
					selectedDigestOption,
				});
			}
		};
}

const EmailAndCalendarSettingsAsObserver = observer(_EmailAndCalendarSettings);
const EmailAndCalendarSettingsWithContext = inject(
	ToasterViewModelKey,
	ErrorMessagesViewModelKey,
	UserSessionViewModelKey
)(EmailAndCalendarSettingsAsObserver);
export const EmailAndCalendarSettings = withEventLogging(
	EmailAndCalendarSettingsWithContext,
	'EmailAndCalendarSettings'
);
