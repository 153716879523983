import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {},
	header: {
		alignItems: 'center',
		boxSizing: 'border-box',
		display: 'flex',
		justifyContent: 'flex-end',
		padding: 10,
	},
});
