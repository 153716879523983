import { IAccountTag, IAddress, ICompany, IKeyFact, IOperationResultNoValue, IPhoneNumber } from '@ViewModels';
import { css } from 'aphrodite';
import { computed, observable } from 'mobx';
import { Observer, inject, observer } from 'mobx-react';
import * as React from 'react';
import { ErrorMessagesViewModelKey, IErrorMessageComponentProps } from '../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { CompanyViewModel, VmUtils } from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import { LoadingSpinner } from '../../LoadingSpinner';
import { Portal } from '../../Portal';
import { TextArea } from '../../TextArea';
import { TextInput } from '../../TextInput';
import { EditEntityInfoPhoneNumber } from '../../entities/EditEntityInfoPhoneNumber';
import { EditableEntityAddress } from '../../entities/EditableEntityAddress';
import { EditableEntityInfoBulletList } from '../../entities/EditableEntityInfoBulletList';
import { EntityInfoFieldLabel } from '../../entities/EntityInfoFieldLabel';
import { EntityInfoSection } from '../../entities/EntityInfoSection';
import { TagsListWithTagsEditor } from '../../entities/tags/TagsEditor';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps, IErrorMessageComponentProps {
	className?: string;
	company: CompanyViewModel;
	footerPortalDestinationId?: string;
	onFinish?(cancel: boolean): void;
}

export class _EditCompanyInfo extends React.Component<IProps> {
	// @ts-ignore
	@observable private mEditableCompanyModel: ICompany;

	public UNSAFE_componentWillMount() {
		const { company } = this.props;
		this.mEditableCompanyModel = {
			// @ts-ignore
			address: null,
			// @ts-ignore
			bio: null,
			// @ts-ignore
			companyName: null,
			emailDomains: [],
			// @ts-ignore
			id: null,
			keyFactsCollection: [],
			// @ts-ignore
			logoUrl: null,
			phoneNumbers: [],
			socialProfiles: [],
			tags: [],
			// @ts-ignore
			webSite: null,
			...(company.toJs() || {}),
			// @ts-ignore
			emailDomain: null /* If we don't clear this out, the back end tries to add it */,
		};
	}

	public render() {
		const { className, footerPortalDestinationId, company } = this.props;
		return (
			<div className={`${css(styleSheet.container)} edit-company-info ${className || ''}`}>
				<div className={css(styleSheet.title)}>Add/Edit Company</div>
				<div className={css(styleSheet.header)}>
					<div>
						<EntityInfoFieldLabel title='Name' />
						{this.renderInputField('companyName', 'Company Name (must be unique)')}
					</div>
					<div>
						<EntityInfoFieldLabel title='Description' />
						<TextArea
							inputClassName={css(styleSheet.bioTextArea)}
							inputId='edit-company-bio-input'
							onChange={this.onBioChanged}
							value={this.mEditableCompanyModel.bio || ''}
						/>
					</div>
				</div>
				<EntityInfoSection title='Key Facts / Tags'>
					<div className={css(styleSheet.fieldGroup)}>
						<div>
							<EntityInfoFieldLabel title='Key facts' />
							<div className={css(styleSheet.fieldGroup)}>
								<EditableEntityInfoBulletList
									addButtonContent={<span>Add key fact</span>}
									items={this.mEditableCompanyModel.keyFactsCollection || []}
									itemValueKeyPath='value'
									onAddButtonClicked={this.onAddKeyFactButtonClicked}
									onItemValueChanged={this.onKeyFactValueChanged}
									onRemoveButtonClicked={this.onRemoveKeyFact}
								/>
							</div>
						</div>
						<div>
							<EntityInfoFieldLabel title='Tags' />
							<TagsListWithTagsEditor
								entity={company}
								onRequestAddTag={this.onRequestAddTag}
								onRequestRemoveTag={this.onRequestRemoveTag}
								tags={this.accountTags}
							/>
						</div>
					</div>
				</EntityInfoSection>
				<EntityInfoSection title='Company Information'>
					<div className={css(styleSheet.fieldGroup)}>
						<div>
							<EntityInfoFieldLabel title='Phone Number' />
							<div className={css(styleSheet.fieldGroup)}>
								<EditableEntityInfoBulletList
									addButtonContent={<span>Add phone number</span>}
									items={this.mEditableCompanyModel.phoneNumbers || []}
									itemValueKeyPath='value'
									onAddButtonClicked={this.onAddPhoneNumber}
									onRemoveButtonClicked={this.onRemovePhoneNumber}
									onRenderItem={this.onRenderEditablePhoneNumber}
								/>
							</div>
						</div>
						<div>
							<EntityInfoFieldLabel title='Email Domain' />
							{this.renderInputField('emailDomains', 'company.com (no @ symbol)')}
						</div>
						<div>
							<EntityInfoFieldLabel title='Website' />
							{this.renderInputField('webSite', 'https://www.company.com')}
						</div>
						<EditableEntityAddress
							address={this.mEditableCompanyModel.address}
							onAddressChanged={this.onAddressChanged}
						/>
					</div>
				</EntityInfoSection>
				{footerPortalDestinationId ? (
					<Portal destination={footerPortalDestinationId}>{this.renderFooter()}</Portal>
				) : (
					this.renderFooter()
				)}
				{!!company.isBusy && <LoadingSpinner className='absolute-center' type='large' />}
			</div>
		);
	}

	@computed
	private get accountTags() {
		// @ts-ignore
		return this.mEditableCompanyModel.tags.map(tag => ({ tag })) || [];
	}

	private renderFooter() {
		const { company } = this.props;
		return (
			<div className={css(styleSheet.footer)}>
				<button className={css(baseStyleSheet.ctaButtonSmall)} disabled={!!company.isBusy} onClick={this.onSaveClicked}>
					<span>Save</span>
				</button>
				<button
					className={css(baseStyleSheet.ctaButtonReverseSmall)}
					disabled={!!company.isBusy}
					onClick={this.onCancelClicked}
				>
					<span>Cancel</span>
				</button>
			</div>
		);
	}

	private renderInputField(propertyName: keyof ICompany, placeholder?: string) {
		const value =
			propertyName === 'emailDomains'
				? !!this.mEditableCompanyModel.emailDomains && this.mEditableCompanyModel.emailDomains.length > 0
					? this.mEditableCompanyModel.emailDomains[0]
					: null
				: (this.mEditableCompanyModel[propertyName] as string);
		return (
			<TextInput
				autoComplete='off'
				inputId={`edit-company-${propertyName}-input`}
				onChange={this.onPropertyInputChanged(propertyName)}
				placeholder={placeholder}
				type='text'
				value={value || ''}
			/>
		);
	}

	private onRenderEditablePhoneNumber = (phoneNumber: IPhoneNumber) => {
		return (
			<Observer>
				{() => {
					return (
						<EditEntityInfoPhoneNumber
							rootElementType='none'
							label={phoneNumber.label}
							onLabelChanged={this.onPhoneNumberLabelChanged(phoneNumber)}
							onValueChanged={this.onPhoneNumberValueChanged(phoneNumber)}
							value={phoneNumber.value}
						/>
					);
				}}
			</Observer>
		);
	};

	private onRemovePhoneNumber = (_: IPhoneNumber, index: number) => {
		const phoneNumbers = [...(this.mEditableCompanyModel.phoneNumbers || [])];
		phoneNumbers.splice(index, 1);
		this.mEditableCompanyModel.phoneNumbers = phoneNumbers;
	};

	private onAddPhoneNumber = () => {
		const phoneNumbers = [...(this.mEditableCompanyModel.phoneNumbers || []), { label: null, value: null }];
		// @ts-ignore
		this.mEditableCompanyModel.phoneNumbers = phoneNumbers;
	};

	private onPhoneNumberValueChanged = (phoneNumber: IPhoneNumber) => (value: string) => {
		// we can change this directly because "this.mEditableContactModel" is @observable
		phoneNumber.value = value;
	};

	private onPhoneNumberLabelChanged = (phoneNumber: IPhoneNumber) => (label: string) => {
		// we can change this directly because "this.mEditableContactModel" is @observable
		phoneNumber.label = label;
	};

	private onAddressChanged = (address: IAddress) => {
		this.mEditableCompanyModel.address = address;
	};

	private onBioChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		this.mEditableCompanyModel.bio = e.target.value;
	};

	private onPropertyInputChanged = (propertyName: keyof ICompany) => (e: React.ChangeEvent<HTMLInputElement>) => {
		if (propertyName === 'emailDomains') {
			this.mEditableCompanyModel.emailDomains = [(e.target.value || '').trim()];
			return;
		}

		(this.mEditableCompanyModel as any)[propertyName] = e.target.value;
	};

	private onRemoveKeyFact = (_: IKeyFact, index: number) => {
		const keyFactsCollection = [...(this.mEditableCompanyModel.keyFactsCollection || [])];
		keyFactsCollection.splice(index, 1);
		this.mEditableCompanyModel.keyFactsCollection = keyFactsCollection;
	};

	private onAddKeyFactButtonClicked = () => {
		const keyFact: IKeyFact = {
			// @ts-ignore
			value: null,
		};
		this.mEditableCompanyModel.keyFactsCollection = [...(this.mEditableCompanyModel.keyFactsCollection || []), keyFact];
	};

	private onKeyFactValueChanged = (keyFact: IKeyFact, _: number, value: string) => {
		// we can change this directly because "this.mEditableCompanyModel" is @observable
		keyFact.value = value;
	};

	private onRequestRemoveTag = (tag: IAccountTag) => {
		// @ts-ignore
		const index = (this.mEditableCompanyModel.tags || []).indexOf(tag?.tag);
		if (index >= 0) {
			const tags = [...(this.mEditableCompanyModel.tags || [])];
			tags.splice(index, 1);
			this.mEditableCompanyModel.tags = tags;
		}
	};

	private onRequestAddTag = (tag: IAccountTag) => {
		if (
			this.mEditableCompanyModel.tags?.length &&
			tag?.tag &&
			// @ts-ignore
			this.mEditableCompanyModel.tags.find(x => x.toLocaleLowerCase() === tag.tag.toLocaleLowerCase())
		) {
			return;
		}
		const tags = [...(this.mEditableCompanyModel.tags || []), tag.tag];
		// @ts-ignore
		this.mEditableCompanyModel.tags = tags;
	};

	private onCancelClicked = () => {
		const { onFinish } = this.props;
		if (onFinish) {
			onFinish(true);
		}
	};

	private onSaveClicked = () => {
		const { onFinish, logApiError, logInput, errorMessages, company } = this.props;

		// remove some empty properties
		const companyModel = {
			...this.mEditableCompanyModel,
		};

		VmUtils.removeEmptyKvpFromObject(companyModel);

		// validate
		// @ts-ignore
		let errorMessage: string = null;
		const hasName = !!companyModel.companyName;
		if (!hasName) {
			errorMessage = 'Company name is required.';
		}

		if (errorMessage) {
			// @ts-ignore
			errorMessages.push({
				messages: [errorMessage],
			});
			return;
		}

		if (companyModel.keyFactsCollection) {
			companyModel.keyFactsCollection = companyModel.keyFactsCollection.filter(x => !!x && !!x.value);
		}

		if (companyModel.phoneNumbers) {
			companyModel.phoneNumbers = companyModel.phoneNumbers.filter(x => !!x && !!x.value);
		}

		if (companyModel.tags) {
			companyModel.tags = companyModel.tags.filter(x => !!x);
		}

		// this will also create a contact if the companyModel.id === null/undefined
		const promise = company.update(companyModel);
		if (promise) {
			// @ts-ignore
			logInput('Save', 'Click');
			promise
				.then(() => {
					if (onFinish) {
						onFinish(false);
					}
				})
				.catch((error: IOperationResultNoValue) => {
					// @ts-ignore
					logApiError('UpdateCompany-Error', error);
					// @ts-ignore
					errorMessages.pushApiError(error);
				});
		}
	};
}

const EditCompanyInfoAsObserver = observer(_EditCompanyInfo);
const EditCompanyInfoWithContext = inject(ErrorMessagesViewModelKey)(EditCompanyInfoAsObserver);
export const EditCompanyInfo = withEventLogging(EditCompanyInfoWithContext, 'EditCompanyInfo');
