import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import { ScheduleMeeting } from '.';
import { copyToClipboard } from '../../../models/UiUtils';
import { useToaster, useUserSession } from '../../../models/hooks/appStateHooks';
import { AppBarNavigationItemPortalDestinationId } from '../../components/AppBar';
import { NavigationBreadcrumbsBar } from '../../components/NavigationBreadcrumbsBar';
import { Portal } from '../../components/Portal';
import { ClipboardIcon } from '../../components/svgs/icons/ClipboardIcon';
import { baseStyleSheet } from '../../styles/styles';
import { styleSheet } from './styles';
import { useScheduleMeetingSettings } from './useScheduleMeetingSettings';

function _ScheduleMeetingSettings() {
	const userSession = useUserSession();
	const { isLoadingMeetingLinks, meetingConfigs, onAddMeeting, onDeleteMeeting, onEditMeeting } =
		// @ts-ignore
		useScheduleMeetingSettings({ user: userSession.user });
	const toaster = useToaster();
	const firstPublishedMeeting = meetingConfigs?.filter(config => config.isPublished)?.[0];
	const onCopyLandingLinkClick = () => {
		if (firstPublishedMeeting == null) {
			return;
		}
		// @ts-ignore
		const url = firstPublishedMeeting.link.replace(
			`${firstPublishedMeeting.shortCode}/${firstPublishedMeeting.vanityPath}`,
			`${firstPublishedMeeting.shortCode}?landing=true`
		);
		const done = copyToClipboard(url);
		// @ts-ignore
		toaster.push({
			message: done ? `Copied meetings homepage link to clipboard!` : 'Unable to copy to clipboard.',
			type: done ? 'successMessage' : 'errorMessage',
		});
	};
	return (
		<div className={css(styleSheet.container)}>
			<Portal destination={AppBarNavigationItemPortalDestinationId}>
				<NavigationBreadcrumbsBar currentLocationName='Schedule a Meeting' />
			</Portal>
			{firstPublishedMeeting ? (
				<div className={css(styleSheet.headerContainer)}>
					<div className={css(styleSheet.text)}>
						Want to give your clients the option to choose from all your meeting types? Simply copy and send this link.
					</div>
					<button
						className={css(baseStyleSheet.ctaButtonSmall, styleSheet.copyLinkButton)}
						onClick={onCopyLandingLinkClick}
					>
						<span>
							<ClipboardIcon className={css(styleSheet.clipboardIcon)} fillColor='#fff' />
							Copy Booking Calendar
						</span>
					</button>
				</div>
			) : null}
			<ScheduleMeeting
				isLoadingMeetingLinks={isLoadingMeetingLinks}
				meetingConfigs={meetingConfigs}
				onAddMeeting={onAddMeeting}
				onDeleteMeeting={onDeleteMeeting}
				onEditMeeting={onEditMeeting}
			/>
		</div>
	);
}

export const ScheduleMeetingSettingsPage = observer(_ScheduleMeetingSettings);
