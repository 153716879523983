import { StyleSheet } from 'aphrodite';
import {
	brandPrimaryText,
	brandSecondary,
	destructiveHover,
	header,
	label,
	mention,
	titles,
} from '../../styles/colors';
import { CssSelectors, Layouts } from '../../styles/styles';

const fadeInKeyframes = {
	'0%': {
		opacity: 0,
	},
	'100%': {
		opacity: 1,
	},
};

export const styleSheet = StyleSheet.create({
	plannedCampaignListItem: {
		animationDelay: 'calc(var(--stagger-anim-order) * 100ms)',
		animationDuration: '300ms',
		animationFillMode: 'forwards',
		animationIterationCount: 1,
		animationName: [fadeInKeyframes],
		animationTimingFunction: 'ease-in-out',
		opacity: 0,
	},
	scheduleTypeDropDown: {
		...CssSelectors.allChildren(
			{
				minWidth: 120,
			},
			'.select-box-menu'
		),
	},
	timeDropdown: {
		...CssSelectors.allChildren(
			{
				maxWidth: 150,
				minWidth: 120,
			},
			'.select-box-menu'
		),
	},
	emailFallbackInfo: {
		alignItems: 'center',
		background: mention,
		borderRadius: 3,
		color: brandPrimaryText,
		display: 'flex',
		fontSize: '12px',
		fontStyle: 'italic',
		justifyContent: 'center',
		padding: '1rem 0.5rem',
		...CssSelectors.allChildren(
			{
				marginLeft: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	modalBodyText: {
		color: label,
		fontSize: 14,
		lineHeight: '18px',
		marginTop: '0.5rem',
	},
	recipientsHeader: {
		...Layouts.verticalStack(20),
		boxSizing: 'border-box',
		padding: '30px 0 10px 0',
	},
	recipientsHeaderTitle: {
		alignItems: 'center',
		color: titles,
		display: 'flex',
		fontSize: 18,
		justifyContent: 'space-between',
	},
	recipientsListItemAvatar: {
		flexShrink: 0,
		marginLeft: '0.5rem',
	},
	recipientsListItemDetailsCompanyName: {
		color: header,
		fontSize: '14px',
		marginBottom: '0.5rem',
		marginTop: '0.25rem',
	},
	recipientsListItemDetailsName: {
		color: titles,
		fontSize: '18px',
		lineHeight: '22px',
	},
	labelRequired: {
		'::after': {
			color: destructiveHover,
			content: '"*"',
			display: 'inline',
			marginLeft: '0.25rem',
		},
	},
	searchEmployeeBox: {
		fontSize: 14,
		marginTop: '1rem',
	},
	showingFilterDropdownTrigger: {
		background: '#fff',
		color: '#858585',
		fontSize: 14,
	},
	showingHeaderOptions: {
		color: '#858585',
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		fontSize: 14,
		marginBottom: '0.5rem',
	},
	tagPointDown: {
		transform: 'rotateZ(90deg)',
	},
	tagPointUp: {
		transform: 'rotateZ(270deg)',
	},
	tagTokenContainer: {
		maxHeight: 'unset',
		...CssSelectors.allChildren({
			marginLeft: '0.5rem',
		}),
	},
	title: {
		color: brandSecondary,
		fontSize: 22,
		fontWeight: 600,
		maxWidth: '80%',
	},
});
