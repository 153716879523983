import { IBuildInfo } from '../../../models';
import AppBuildInfo from '../../assets/build-info.json';
import { AppBarNavigationItemPortalDestinationId } from '../../components/AppBar';
import { NavigationBreadcrumbsBar } from '../../components/NavigationBreadcrumbsBar';
import { Portal } from '../../components/Portal';
import { INavigationItemProps } from '../MainContainer';
import './styles.less';
import moment from 'moment';
import * as React from 'react';

export class BuildInfo extends React.Component<INavigationItemProps> {
	public componentDidMount() {
		fetch('./assets/build-info.json')?.then(async res => {
			console.log(await res.json());
		});
	}

	public render() {
		const buildInfo: IBuildInfo = AppBuildInfo as any;
		return (
			<div className={`build-info ${this.props.routeContainerClassName || ''}`}>
				<Portal destination={AppBarNavigationItemPortalDestinationId}>
					<NavigationBreadcrumbsBar currentLocationName='Build Info' />
				</Portal>
				<div className='build-info-date'>{`Date:\t${moment(buildInfo.date).format('hh:mm:ss A\tMM/DD/YYYY')}`}</div>
				<div className='build-info-hash'>{`Hash:\t${buildInfo.hash}`}</div>
			</div>
		);
	}
}
