import { StyleDeclarationValue, css } from 'aphrodite';
import clsx from 'clsx';
import * as React from 'react';
import { styleSheet } from './styles';

interface IProps {
	children: React.ReactNode;
	className?: string;
	styleDeclaration?: StyleDeclarationValue | StyleDeclarationValue[];
}

export function DashboardCardTag({ children, className, styleDeclaration }: IProps) {
	const styleDeclarations = Array.isArray(styleDeclaration) ? styleDeclaration : [styleDeclaration];
	return <span className={clsx(css(styleSheet.tag, ...styleDeclarations), className)}>{children}</span>;
}
