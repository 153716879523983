import * as Api from '@ViewModels';
import * as React from 'react';
import { ContactSortKey } from '../../../../models';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useContactApproximateQuery, useContactFilterQuery, useContactsQuery } from '../../../../queries';
import { ComposerRecipientOwnershipDropdownOption } from '../../../components/ComposerRecipientOwnershipDropdown';

type Actions =
	| { type: 'selectSendOnBehalfOfCurrentUser'; value: { currentUserId: string } }
	| { type: 'selectSendOnBehalfOfContactOwner' }
	| { type: 'selectEmployeeToSendOnBehalfOf'; value: string }
	| { type: 'setFilterList'; value: Api.IContactFilterCriteria[] }
	| { type: 'setShowingAdvancedTagFiltersModal'; value: boolean }
	| { type: 'ownershipCriteriaChange'; value: Api.IContactFilterCriteria }
	| { type: 'removeRecipient'; value: Api.IContact }
	| { type: 'addRecipient'; value: Api.IContact }
	| { type: 'addRecipients'; value: Api.IContact[] }
	| { type: 'toggleConsentChecked' };

export function hasFilters(filterList: Api.IContactFilterCriteria[]): boolean {
	for (const filter of filterList) {
		if (filter.criteria?.length) {
			if (hasFilters(filter.criteria)) {
				return true;
			}
		} else if (filter.property !== Api.ContactFilterCriteriaProperty.All) {
			return true;
		}
	}
	return false;
}

function getOwnershipFilterCriteria(filterList: Api.IContactFilterCriteria[]): Api.IContactFilterCriteria | undefined {
	return filterList.find(x => x.property === Api.ContactFilterCriteriaProperty.OwnedBy);
}

interface ITextingCampaignPageState {
	filterCriteriaList: Api.IContactFilterCriteria[];
	contactIdsToOmit: Set<string>;
	contactIdsToAdd: string[];
	showingAdvancedTagFiltersModal: boolean;
	consentChecked: boolean;
}

const reducer = (state: ITextingCampaignPageState, action: Actions): ITextingCampaignPageState => {
	switch (action.type) {
		/* fired when sendFrom is changed to CurrentUser
		 Aside from setting sendFrom to currentUser we have to manipulate the filter which controls the showing dropdown.
		 The presence of ownedBy filter denotes "Contacts I own" or "Contacts they own" is selected in the Showing dropdown
		 So when currentUser is selected we have to look into the filters and see if an ownedBy criteria exists and update the criteria's value to current user id,
		 thereby updating the contacts list to show only contacts owned by the current user and changing the showing dropdown to "Contacts I own"
		 */
		case 'selectSendOnBehalfOfCurrentUser': {
			let newFilterCriterias = state.filterCriteriaList;
			const ownedByFilterIndex = state.filterCriteriaList.findIndex(
				criteria => criteria.property === Api.ContactFilterCriteriaProperty.OwnedBy
			);
			if (
				ownedByFilterIndex > -1 &&
				state.filterCriteriaList[ownedByFilterIndex].value !== action.value.currentUserId
			) {
				const newCriterias = [...state.filterCriteriaList];
				newCriterias[ownedByFilterIndex] = {
					property: Api.ContactFilterCriteriaProperty.OwnedBy,
					value: action.value.currentUserId,
				};
				newFilterCriterias = newCriterias;
			}
			return {
				...state,
				filterCriteriaList: newFilterCriterias,
			};
		}
		// The only allowed value in the showing dropdown when sendFrom is ContactOwner is "All"
		// So when ContactOwner is selected, we have to update the filterCriterias and remove any ownedBy("Contacts I/they own") or connections("My connections") filters
		case 'selectSendOnBehalfOfContactOwner': {
			return {
				...state,
				filterCriteriaList: Api.VmUtils.concatContactFilterCriteria(
					state.filterCriteriaList.filter(
						criteria =>
							!(
								criteria.property &&
								[
									Api.ContactFilterCriteriaProperty.All,
									Api.ContactFilterCriteriaProperty.OwnedBy,
									Api.ContactFilterCriteriaProperty.Connections,
								].includes(criteria.property)
							)
					),
					[{ property: Api.ContactFilterCriteriaProperty.All }]
				)!,
			};
		}
		/* fired when sendFrom is changed to SelectedEmployee with a Selected User
		 Aside from setting sendFrom to the user we have to manipulate the filter which controls the showing dropdown.
		 The presence of ownedBy filter denotes "Contacts I own" or "Contacts they own" is selected in the Showing dropdown
		 So when another user is selected we have to look into the filters and see if an ownedBy criteria exists and update the criteria's value to the selected user's id,
		 thereby updating the contacts list to show only contacts owned by them and changing the showing dropdown to "Contacts they own"
		 */
		case 'selectEmployeeToSendOnBehalfOf': {
			const selectedEmployeeId = action.value;
			let filterCriterias = state.filterCriteriaList;
			const ownedByFilterIndex = state.filterCriteriaList.findIndex(
				criteria => criteria.property === Api.ContactFilterCriteriaProperty.OwnedBy
			);
			if (ownedByFilterIndex > -1 && filterCriterias[ownedByFilterIndex].value !== selectedEmployeeId) {
				const newCriterias = [...filterCriterias];
				newCriterias[ownedByFilterIndex] = {
					property: Api.ContactFilterCriteriaProperty.OwnedBy,
					value: selectedEmployeeId,
				};
				filterCriterias = newCriterias;
			}
			return {
				...state,
				filterCriteriaList: filterCriterias,
			};
		}
		case 'setFilterList': {
			return {
				...state,
				filterCriteriaList: action.value,
			};
		}
		case 'setShowingAdvancedTagFiltersModal': {
			return {
				...state,
				showingAdvancedTagFiltersModal: action.value,
			};
		}
		case 'ownershipCriteriaChange': {
			const criteriaListWithoutOwnership = state.filterCriteriaList.filter(
				x =>
					![
						Api.ContactFilterCriteriaProperty.All,
						Api.ContactFilterCriteriaProperty.OwnedBy,
						Api.ContactFilterCriteriaProperty.Connections,
					].includes(x.property!)
			);
			return {
				...state,
				filterCriteriaList: Api.VmUtils.concatContactFilterCriteria(criteriaListWithoutOwnership, [action.value]),
			};
		}
		// Controlling logic for removing contact from the recipient list
		case 'removeRecipient': {
			const contact = action.value;
			if (!contact.id) {
				return state; // or throw?
			}
			const contactIdsToAdd = state.contactIdsToAdd.filter(x => x !== contact.id);
			const contactIdsToOmit = new Set(state.contactIdsToOmit);
			contactIdsToOmit.add(contact.id);
			return {
				...state,
				contactIdsToAdd,
				contactIdsToOmit,
			};
		}
		// Controlling logic for adding contact to the recipient list
		case 'addRecipient': {
			const contact = action.value;
			if (!contact.id || state.contactIdsToAdd.includes(contact.id)) {
				return state; // or throw?
			}
			const contactIdsToOmit = new Set(state.contactIdsToOmit);
			contactIdsToOmit.delete(contact.id);
			return {
				...state,
				contactIdsToOmit,
				contactIdsToAdd: [...state.contactIdsToAdd, contact.id],
			};
		}
		case 'addRecipients': {
			const contacts = action.value;
			const contactIdsToOmit = new Set(state.contactIdsToOmit);
			const contactIdsToAdd: string[] = [];
			for (const contact of contacts) {
				if (state.contactIdsToAdd.includes(contact.id!)) {
					continue;
				}
				contactIdsToAdd.push(contact.id!);
				contactIdsToOmit.delete(contact.id!);
			}
			return {
				...state,
				contactIdsToOmit,
				contactIdsToAdd: [...state.contactIdsToAdd, ...contactIdsToAdd],
			};
		}
		case 'toggleConsentChecked': {
			return {
				...state,
				consentChecked: !state.consentChecked,
			};
		}
		default:
			throw new Error('Invalid action type');
	}
};

export const getInitialSendFrom = ({
	canSendOnBehalf,
	initialFilterCriteriaList,
	currentUserId,
}: {
	currentUserId: string;
	canSendOnBehalf: boolean;
	initialFilterCriteriaList: Api.IContactFilterCriteria[];
}): { sendFrom: Api.ISendEmailFrom; sendFromUserId: string | null } => {
	const initialOwnershipCriteria = getOwnershipFilterCriteria(initialFilterCriteriaList);
	if (initialOwnershipCriteria) {
		if (initialOwnershipCriteria.value === currentUserId) {
			return { sendFrom: Api.SendEmailFrom.CurrentUser, sendFromUserId: null };
		}
		if (canSendOnBehalf) {
			return { sendFrom: Api.SendEmailFrom.SelectedUser, sendFromUserId: initialOwnershipCriteria.value };
		}
	}
	const initialConnectionsCriteria = initialFilterCriteriaList.find(
		criteria => criteria.property === Api.ContactFilterCriteriaProperty.Connections
	);
	if (initialConnectionsCriteria) {
		return { sendFrom: Api.SendEmailFrom.CurrentUser, sendFromUserId: currentUserId };
	}
	return {
		sendFrom: canSendOnBehalf ? Api.SendEmailFrom.ContactOwner : Api.SendEmailFrom.CurrentUser,
		sendFromUserId: null,
	};
};

export const useTextingCampaignRecipientFilter = ({
	initialContactIdsToOmit = [],
	initialFilterCriterias = [],
	initialContactIdsToInclude = [],
	onAllFiltersRemoved,
}: {
	initialContactIdsToOmit?: string[];
	initialContactIdsToInclude?: string[];
	initialFilterCriterias?: Api.IContactFilterCriteria[];
	onAllFiltersRemoved?: () => void;
}) => {
	const userSession = useUserSession();
	const [state, dispatch] = React.useReducer(reducer, {
		filterCriteriaList: Api.VmUtils.concatContactFilterCriteria(
			initialFilterCriterias.filter(
				criteria => !(criteria.property && [Api.ContactFilterCriteriaProperty.All].includes(criteria.property))
			),
			[{ property: Api.ContactFilterCriteriaProperty.All }]
		)!,
		contactIdsToOmit: new Set(initialContactIdsToOmit),
		contactIdsToAdd: initialContactIdsToInclude,
		showingAdvancedTagFiltersModal: false,
		consentChecked: false,
	});
	// Only apply filter in the approximation if filters are applied or no contacts were manually added
	const contactIdsToOmitArray = React.useMemo(() => Array.from(state.contactIdsToOmit), [state.contactIdsToOmit]);
	const contactsToAdd = useContactsQuery({
		contactIds: state.contactIdsToAdd,
	});
	const contactApproximateQuery = useContactApproximateQuery({
		filterRequest: {
			groupByHousehold: false,
			excludeContactIds: contactIdsToOmitArray,
			includeContactIds: state.contactIdsToAdd,
			filter: {
				criteria: state.filterCriteriaList,
			},
		},
		capabilities: [Api.ContactCapability.ReceiveText],
	});
	const totalRecipientsWithTextingCapability = contactApproximateQuery.data?.hasRequestedCapabilities;
	const totalRecipients = contactApproximateQuery.data?.total;
	const contactFilterQuery = useContactFilterQuery({
		filterRequest: {
			filter: {
				criteria: state.filterCriteriaList,
			},
		},
		sortBy: ContactSortKey.Handle,
	});
	// The selected showing filter option is derived from the filter criteria list
	let selectedOwnershipDropdownOption = ComposerRecipientOwnershipDropdownOption.All;
	for (const criteria of state.filterCriteriaList) {
		if (criteria.property === Api.ContactFilterCriteriaProperty.OwnedBy) {
			// on impersonation support change userSession.user.id check to imp.user.id || userSession.user.id
			selectedOwnershipDropdownOption =
				criteria.value === userSession.user.id
					? ComposerRecipientOwnershipDropdownOption.MyContacts
					: ComposerRecipientOwnershipDropdownOption.TheirContacts;
			break;
		}
		if (criteria.property === Api.ContactFilterCriteriaProperty.Connections) {
			selectedOwnershipDropdownOption = ComposerRecipientOwnershipDropdownOption.MyConnections;
			break;
		}
	}
	const onChangeFilterList = (filterList: Api.IContactFilterCriteria[]) => {
		if (!hasFilters(filterList)) {
			onAllFiltersRemoved?.();
		}
		dispatch({ type: 'setFilterList', value: filterList });
	};
	return {
		state,
		dispatch,
		contactFilterQuery,
		contactIdsToOmit: contactIdsToOmitArray,
		contactsToAdd,
		contactApproximateQuery,
		onChangeFilterList,
		selectedOwnershipDropdownOption,
		totalRecipients,
		totalRecipientsWithTextingCapability,
	};
};
