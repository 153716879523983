import { brandPrimaryHover } from '../../styles/colors';
import { CssSelectors } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {},
	body: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren({
			flex: 1,
		}),
	},
	sideButton: {
		padding: 12,
		display: 'block',
		width: '100%',
		':not(:last-child)': {
			borderBottom: '1px solid #ccc',
		},
	},
	dateRangePicker: {
		width: 370,
	},
	footer: {
		marginTop: 20,
		widht: '100%',
	},
	submitButton: {
		marginLeft: 'auto',
		width: 370,
	},
	selected: {
		backgroundColor: brandPrimaryHover,
	},
});
