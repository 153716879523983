import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import { useTagsFromBulkContactRequest } from '../../../../../hooks/useTagsFromBulkContactRequest';
import {
	getContactTitleAndCompany,
	getDisplayName,
	getNormalizedNoteVisibility,
	getPrincipalInitials,
} from '../../../../../models/UiUtils';
import { Avatar2 } from '../../../../components/Avatar2';
import { Checkbox } from '../../../../components/Checkbox';
import { VisibilityIndicatorIcon } from '../../../../components/VisibilityIndicatorIcon';
import { bs } from '../../../../styles/styles';
import { PeopleDuplicateRow } from '../PeopleRow/PeopleDuplicateRow';
import { styleSheet } from './styles';

function _PeopleDuplicateContainerRow({
	contact,
	contacts,
	onToggleContactSelection,
	selectContactRow,
	deselectContactRow,
	filter,
}: {
	contact: Api.IContact;
	contacts: Api.ContactsViewModel;
	onToggleContactSelection: (c: Api.IContact) => void;
	selectContactRow: (c: Api.IContact) => void;
	deselectContactRow: (c: Api.IContact) => void;
	filter: Api.IBulkContactsRequest;
}) {
	const topLevelContactTitleAndCompany = getContactTitleAndCompany(contact);
	const allRowContacts = [contact, ...(contact.duplicateContacts ?? [])];
	const tagsToHighlight = useTagsFromBulkContactRequest(filter);
	const allRowsSelected = allRowContacts.every(c => contacts.isContactSelected(c.id!));
	const someRowsSelected = allRowContacts.some(c => contacts.isContactSelected(c.id!));
	const toggleTopRowSelection = () => {
		if (allRowsSelected) {
			allRowContacts.forEach(deselectContactRow);
		} else {
			allRowContacts.forEach(selectContactRow);
		}
	};
	return (
		<>
			<div
				className={css(bs.bgWhite, bs.cursorPointer, bs.transitionColors, bs.duration100)}
				style={{ backgroundColor: allRowsSelected ? '#f3f6f8' : undefined }}
			>
				<div
					className={css(
						bs.flex,
						bs.itemsCenter,
						bs.textXs,
						bs.mx7,
						bs.border0,
						bs.borderSolid,
						bs.borderB,
						bs.borderGray200,
						styleSheet.rowContainer
					)}
				>
					<div
						className={css(bs.flex, bs.itemsCenter, bs.h12, bs.justifyCenter, bs.pl3, bs.w12)}
						onClick={ev => {
							ev.stopPropagation();
							ev.preventDefault();
							toggleTopRowSelection();
						}}
					>
						<Checkbox
							checked={allRowsSelected}
							partial={!allRowsSelected && someRowsSelected}
							id={`people-duplicate-container-row-checkbox-${contact.id}`}
							childrenPosition='left'
							backgroundFillColor='white'
							readOnly={true}
						/>
					</div>
					<div className={css(bs.flex, bs.itemsCenter, bs.justifyCenter, bs.w4)}>
						<VisibilityIndicatorIcon
							title={getNormalizedNoteVisibility(contact.visibility ?? 'none')}
							visibility={contact.visibility}
						/>
					</div>
					<div className={css(bs.flex, bs.textBase, bs.fontBold, bs.relative, bs.ml3)}>
						<Avatar2
							styleDeclaration={[
								bs.cursorPointer,
								bs.flexShrink0,
								bs.h12,
								bs.opacity100,
								bs.transitionOpacity,
								bs.w12,
								styleSheet.rowActive,
							]}
							fallbackText={getPrincipalInitials(contact)}
							imgSrc={contact.profilePic}
						/>
						<span
							className={css(
								bs.bgBrandPrimary,
								bs.roundedFull,
								bs.bottom0,
								bs.h5,
								bs.w5,
								bs.absolute,
								bs.right0,
								bs.textWhite,
								bs.fontBold,
								bs.textXs,
								bs.flex,
								bs.itemsCenter,
								bs.justifyCenter
							)}
						>
							+{allRowContacts.length}
						</span>
					</div>
					<div
						className={css(
							bs.textLg,
							bs.transitionOpacity,
							bs.w12,
							bs.flexGrow,
							bs.my0,
							bs.mr2,
							bs.ml5,
							bs.w40,
							styleSheet.rowActive
						)}
					>
						<div className={css(bs.truncateText)}>{getDisplayName(contact)}</div>
						{topLevelContactTitleAndCompany && (
							<div className={css(bs.textGray400, bs.textXs, bs.truncateText)}>{topLevelContactTitleAndCompany}</div>
						)}
					</div>
				</div>
			</div>
			{allRowContacts.map(c => {
				const isSelected = contacts.isContactSelected(c.id!);
				return (
					<PeopleDuplicateRow
						isSelected={isSelected}
						onToggleSelection={() => onToggleContactSelection(c)}
						contact={c}
						key={`people-duplicate-row-${c.id}`}
						tagsToHighlight={tagsToHighlight}
					/>
				);
			})}
		</>
	);
}

export const PeopleDuplicateContainerRow = observer(_PeopleDuplicateContainerRow);
