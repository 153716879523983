import { FocusedViewContext } from './context';
import * as React from 'react';

export const FocusedViewProvider: React.FC = props => {
	const { children } = props;

	const [contactIds, setContactIds] = React.useState([]);
	const [selectedContactId, setSelectedContactId] = React.useState(null);

	const ctx = {
		contactIds,
		setContactIds,
		selectedContactId,
		setSelectedContactId,
	};

	return <FocusedViewContext.Provider value={ctx}>{children}</FocusedViewContext.Provider>;
};
