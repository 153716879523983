import { darkGrayFontColor, nameCircles, titles } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	complete: {
		marginTop: 100,
	},
	container: {
		margin: '0 auto',
		maxWidth: 800,
	},
	flex: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'flex-start',
	},
	flexBetween: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
	},
	headerContainer: {
		borderBottom: `1px solid ${nameCircles}`,
		margin: '0 20px',
		padding: '20px 0',
	},
	message: {
		fontSize: 14,
		marginLeft: '47.5%',
		marginTop: 20,
		textAlign: 'left',
		width: '50%',
	},
	nonePending: {},
	nonePendingGraphic: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 60,
	},
	nonePendingText: {
		color: darkGrayFontColor,
		marginTop: 30,
		textAlign: 'center',
	},
	select: {
		marginRight: 10,
		width: 220,
	},
	selectOption: {
		width: 218,
	},
	title: {
		color: titles,
		fontSize: 18,
		marginLeft: 15,
	},
	titleContainer: {
		alignItems: 'center',
		display: 'flex',
	},
});
