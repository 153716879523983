import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import FlipMove from 'react-flip-move';
import { useErrorMessages } from '../../../aida/hooks';
import { useEventLogging } from '../../../models/Logging';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import {
	getFeedItemViewModel,
	invalidateDashboardFeed,
	useDashboardFeed,
	useSuppressDashboardItem,
} from '../../../queries';
import { AppBarNavigationItemPortalDestinationId } from '../../components/AppBar';
import { EmptyState } from '../../components/EmptyState';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { DarkMainContainerBackground } from '../../components/MainContainerBackground';
import { NavigationBreadcrumbsBar } from '../../components/NavigationBreadcrumbsBar';
import { Portal } from '../../components/Portal';
import { CampaignCard } from '../../components/cards/CampaignCard';
import { EmailActivityCard } from '../../components/cards/EmailActivityCard';
import { EmailInCircleGraphic } from '../../components/svgs/graphics/EmailInCircleGraphic';
import { styleSheet } from './styles';

const PAGE_SIZE = 100;
const FEED_TYPE = 'EmailActivity';

function _RecentlyViewedEmails() {
	const { logApiError } = useEventLogging('RecentlyViewedEmails');
	const errorMessages = useErrorMessages();
	const userSession = useUserSession();
	const dashboardFeed = useDashboardFeed({ typeOf: FEED_TYPE, pageSize: PAGE_SIZE });
	const suppressMutation = useSuppressDashboardItem({
		onSuccess: () => {
			invalidateDashboardFeed({ typeOf: FEED_TYPE, pageSize: PAGE_SIZE });
		},
		onError: error => {
			// @ts-ignore
			// @ts-ignore
			errorMessages.push(error);
			// @ts-ignore
			logApiError('SuppressRecentMeeting-Error', error);
		},
	});
	const onSuppressClicked = (item: Api.DashboardFeedItemViewModel) => {
		let suppressionContext: Api.DashboardSuppressContext;
		if (item instanceof Api.CampaignViewModel) {
			suppressionContext = Api.DashboardSuppressContext.RecentBulkEmails;
		} else if (item instanceof Api.EmailActivityViewModel) {
			suppressionContext = Api.DashboardSuppressContext.EmailActivity;
		}
		// @ts-ignore
		if (!suppressionContext) {
			return;
		}
		suppressMutation.mutate({
			// @ts-ignore
			id: item.id,
			context: suppressionContext,
		});
	};
	const feedItems = dashboardFeed.data?.values ?? [];
	return (
		<div className={`${css(styleSheet.container)} main-container-child`}>
			<Portal destination={AppBarNavigationItemPortalDestinationId}>
				<NavigationBreadcrumbsBar currentLocationName='Recently Viewed Emails' />
			</Portal>
			<DarkMainContainerBackground />
			{dashboardFeed.isLoading ? <LoadingSpinner type='large' /> : null}
			{dashboardFeed.isSuccess && feedItems.length === 0 ? (
				<EmptyState
					image={<EmailInCircleGraphic />}
					text={"You don't have any recently viewed emails"}
					subtext='More emails will appear on your dashboard as they are viewed.'
				/>
			) : null}
			{dashboardFeed.isSuccess && feedItems.length > 0 ? (
				<FlipMove
					enterAnimation={true}
					leaveAnimation={true}
					staggerDelayBy={50}
					staggerDurationBy={10}
					appearAnimation={false}
				>
					{feedItems.map(item => {
						const x = getFeedItemViewModel({ feedItem: item, userSession });
						if (x instanceof Api.EmailActivityViewModel) {
							return (
								<EmailActivityCard
									className={css(styleSheet.item)}
									emailActivity={x}
									key={x.id}
									onSuppressClicked={() => onSuppressClicked(x)}
								/>
							);
						} else if (x instanceof Api.CampaignViewModel) {
							return (
								<CampaignCard
									className={css(styleSheet.item)}
									campaign={x}
									key={x.id}
									onSuppressClicked={() => onSuppressClicked(x)}
								/>
							);
						}
					})}
				</FlipMove>
			) : null}
		</div>
	);
}

export const RecentlyViewedEmails = observer(_RecentlyViewedEmails);
