import { ContactViewModel } from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { IEnvironmentComponentProps } from '../../../../../models/AppState';
import { withEventLogging } from '../../../../../models/Logging';
import { getContactTitleAndCompany } from '../../../../../models/UiUtils';
import { Avatar } from '../../../Avatar';
import { styleSheet } from './styles';

interface IProps extends IEnvironmentComponentProps {
	className?: string;
	onStartOverButtonClicked?(): void;
	recipient: ContactViewModel;
}

class _SingleEmailSendComplete extends React.Component<IProps> {
	public render() {
		const { className, recipient, onStartOverButtonClicked } = this.props;
		const titleAndCompany = getContactTitleAndCompany(recipient);
		const name = recipient.firstName || recipient.name;
		return (
			<div className={`${css(styleSheet.container)} single-email-send-complete ${className || ''}`}>
				<div className={css(styleSheet.section)}>
					<div className={css(styleSheet.header)}>
						<div className={css(styleSheet.headerTitle)}>
							<div>
								{`That's it! You've sent a Keep in Touch Email to`}
								&nbsp;
							</div>
							<div className={css(styleSheet.name)} title={name}>
								{name}!
							</div>
						</div>
						<div className={css(styleSheet.headerSubtitle)}>
							(BTW, we&apos;ve also saved that email for you as a template.)
						</div>
					</div>
					<div className={css(styleSheet.body)}>
						{/* @ts-ignore */}
						<Avatar className={css(styleSheet.avatar)} entityVm={recipient} />
						{!!titleAndCompany && <div className={css(styleSheet.titleAndCompany)}>{titleAndCompany}</div>}
					</div>
				</div>
				<div className={`${css(styleSheet.section)} single-email-send-complete-footer`}>
					<div className={css(styleSheet.footerContent)}>
						<div>See? Keeping in touch is easy. Want to send another email?</div>
						<div>
							<button className={css(styleSheet.startOverButton)} onClick={onStartOverButtonClicked}>
								<span>Sure, who&apos;s up next?</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const SingleEmailSendCompleteAsObserver = observer(_SingleEmailSendComplete);
export const SingleEmailSendComplete = withEventLogging(SingleEmailSendCompleteAsObserver, 'SingleEmailSendComplete');
