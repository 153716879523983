import { darkGrayFontColor, header } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	loading: {
		marginTop: 30,
	},
	rightAccessory: {
		color: header,
		fontSize: '12px',
		letterSpacing: 0,
	},
	text: {
		color: darkGrayFontColor,
		fontSize: '14px',
		letterSpacing: 0,
		lineHeight: '20px',
	},
});
