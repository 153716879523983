import {
	ContactFilterCriteriaProperty,
	ContactViewModel,
	ContactsViewModel,
	IBulkContactsRequest,
	IContact,
	IEntity,
	IOperationResultNoValue,
	IUser,
	QuickAddEntityViewModel,
	ResourceAutoCompleteViewModelType,
	SuggestedContactsViewModel,
	VmUtils,
} from '@ViewModels';
import { css } from 'aphrodite';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import Waypoint from 'react-waypoint';
import { DefaultContactsFilterRequest } from '../../../../../models';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../../models/Logging';
import { baseStyleSheet } from '../../../../styles/styles';
import { LoadingSpinner } from '../../../LoadingSpinner';
import { Modal } from '../../../Modal';
import { QuickAddEntity } from '../../../QuickAddEntity';
import {
	AutoCompleteSearchField,
	AutoCompleteSearchFieldAccessoryViewMode,
	IAutoCompleteSearchFieldComponent,
} from '../../../autocomplete/AutoCompleteSearchField';
import { ContactCard } from '../../../contacts/ContactCard';
import { ContactListIcon } from '../../../svgs/icons/ContactListIcon';
import { InputFieldEnterIcon } from '../../../svgs/icons/InputFieldEnterIcon';
import { SearchIcon } from '../../../svgs/icons/SearchIcon';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps, IUserSessionComponentProps, IErrorMessageComponentProps {
	className?: string;
	onContactSelected?(selectedContact: ContactViewModel): void;
}

interface IState {
	fetchingMoreContacts?: boolean;
	searchValue?: string;
	selectedContact?: ContactViewModel;
}

class _SingleEmailContactSelect extends React.Component<IProps, IState> {
	@observable.ref private mFilterRequest: IBulkContactsRequest;

	private mContacts: ContactsViewModel;

	private mContactSuggestions: SuggestedContactsViewModel;

	private mMounted: boolean;

	private mQuickAddEntity: QuickAddEntityViewModel;

	private mSearchFieldRef: IAutoCompleteSearchFieldComponent;

	private mSearchInputRef: HTMLInputElement;
	public readonly state: IState = {};

	public UNSAFE_componentWillMount() {
		const { userSession } = this.props;
		this.mQuickAddEntity = new QuickAddEntityViewModel(userSession);

		this.mContacts = new ContactsViewModel(userSession);

		this.mContactSuggestions = new SuggestedContactsViewModel(userSession);
		this.getNextBatchOfSuggestions();
	}

	public componentDidMount() {
		this.mMounted = true;
	}

	public componentWillUnmount() {
		this.mMounted = false;
		this.mQuickAddEntity.reset();
	}

	public render() {
		const { className, loggingCategory } = this.props;
		const { fetchingMoreContacts, selectedContact } = this.state;
		return (
			<div className={`${css(styleSheet.container)} single-email-contact-select ${className || ''}`}>
				<div className={css(styleSheet.header)}>
					<div className={css(styleSheet.title)}>Pick someone you want to send a message to:</div>
					<div className={css(styleSheet.subtitle)}>(Don&apos;t worry, we&apos;ll help you write it.)</div>
					<div className={css(styleSheet.searchHeader)}>
						<AutoCompleteSearchField
							anchorClassName={css(styleSheet.searchFieldAnchor)}
							className={css(styleSheet.searchField)}
							dropdownContentClassName={css(styleSheet.searchFieldDropdown)}
							hideResultsFooter={true}
							inputId={`${loggingCategory}-search-input`}
							inputProps={{
								className: css(styleSheet.searchFieldInput),
								onChange: this.onSearchValueChanged,
								onKeyDown: this.onSearchFieldKeyDown,
								placeholder: 'Search names',
							}}
							leftAccessory={<SearchIcon className={css(styleSheet.searchFieldIcon)} />}
							onInnerRef={this.onSearchFieldRef}
							onInputRef={this.onSearchInputRef}
							onItemSelected={this.onSearchFieldAutoCompleteItemSelected}
							resultsLimit={5}
							rightAccessory={
								<button
									className={css(styleSheet.searchFieldEnterButton)}
									onMouseDown={this.onSearchFieldEnterButtonClicked}
								>
									<InputFieldEnterIcon className={css(styleSheet.searchFieldEnterIcon)} />
								</button>
							}
							rightAccessoryViewMode={AutoCompleteSearchFieldAccessoryViewMode.WhileEditing}
							type={ResourceAutoCompleteViewModelType.Contact}
						/>
					</div>
				</div>
				<div className={css(styleSheet.body)}>
					<div className={css(styleSheet.contacts)}>
						{!this.isLoading && this.fetchResults.length > 0 ? (
							<>
								<div className={css(styleSheet.contactsContent)}>
									{this.fetchResults.map(x => {
										const hasEmailAddress = !!x.emailAddresses && x.emailAddresses.length > 0;
										return (
											<ContactCard
												className={css(styleSheet.contactCard)}
												contact={x}
												disabled={!hasEmailAddress}
												isSelected={selectedContact === x}
												key={x.id}
												onClick={this.onContactCardClicked(x)}
												tooltipText={!hasEmailAddress ? 'No email address' : undefined}
											/>
										);
									})}
									{!!fetchingMoreContacts && (
										<div className={css(styleSheet.loadingCard, styleSheet.contactCard)}>
											<LoadingSpinner className={css(styleSheet.loadingCardSpinner)} type='large' />
										</div>
									)}
								</div>
								<Waypoint bottomOffset='-200px' onEnter={this.onWaypointEnter} />
							</>
						) : (
							!this.isLoading && (
								<div className={css(styleSheet.emptyPlaceholder)}>
									<div className={css(styleSheet.emptyPlaceholderTitle)}>No contacts found</div>
									<ContactListIcon className={css(styleSheet.emptyPlaceholderIcon)} />
									<div className={css(styleSheet.emptyPlaceholderDescription)}>Please add a contact to continue.</div>
									<button
										className={css(baseStyleSheet.ctaButton, styleSheet.emptyPlaceholderNewContactButton)}
										onClick={this.onCreateContactClicked}
									>
										<span>New Contact</span>
									</button>
								</div>
							)
						)}
					</div>
					{!!this.isLoading && <LoadingSpinner className={css(styleSheet.loading)} type='large' />}
				</div>
				<Modal
					className='single-email-contact-select-quick-add-entity-modal'
					isOpen={this.mQuickAddEntity.isOpen}
					onRequestClose={this.mQuickAddEntity.close}
					shouldCloseOnOverlayClick={true}
					useDefaultHeader={true}
				>
					<QuickAddEntity quickAddEntityVm={this.mQuickAddEntity} />
				</Modal>
			</div>
		);
	}

	@computed
	private get isLoading() {
		if (!this.mFilterRequest) {
			return this.mContactSuggestions.isFetching && this.mContactSuggestions.fetchResults.length === 0;
		}

		return this.mContacts.isFetchingResults && this.mContacts.fetchResults.length === 0;
	}

	@computed
	private get fetchResults() {
		if (!this.mFilterRequest) {
			return this.mContactSuggestions.fetchResults;
		}

		return this.mContacts.fetchResults;
	}

	private onCreateContactClicked = () => {
		const { logApiError, errorMessages, userSession } = this.props;
		this.mQuickAddEntity.show({
			contactCreateOptions: {
				requireEmail: true,
			},
			onComplete: (error?: IOperationResultNoValue, entity?: IContact) => {
				if (error) {
					logApiError('CrateContact-Error', error);

					errorMessages.pushApiError(error);
				} else {
					const contact = new ContactViewModel(userSession, entity);
					this.onContactCardClicked(contact, 0)();
				}
			},
			type: 'contact',
		});
	};

	private onContactCardClicked =
		(selectedContact: ContactViewModel, delay = 300) =>
		() => {
			if (!this.state.selectedContact) {
				this.setState(
					{
						selectedContact,
					},
					() => {
						// pause to show selection and then return to consumer
						setTimeout(() => {
							const { onContactSelected } = this.props;
							if (!!this.mMounted && !!onContactSelected) {
								onContactSelected(selectedContact);
							}
						}, delay);
					}
				);
			}
		};

	private onSearchFieldAutoCompleteItemSelected = (item: IEntity | string | IUser) => {
		const contactModel = item as IContact;
		const searchValue = VmUtils.getDisplayName(contactModel).trim();
		if (this.mSearchFieldRef) {
			this.mSearchFieldRef.setSearchQuery(searchValue);
		}
		this.setState(
			{
				searchValue,

				selectedContact: null,
			},
			() => {
				this.executeSearch();
			}
		);
	};

	private onSearchInputRef = (ref?: HTMLInputElement) => {
		this.mSearchInputRef = ref;
	};

	private onSearchFieldRef = (ref?: IAutoCompleteSearchFieldComponent) => {
		this.mSearchFieldRef = ref;
	};

	private onSearchFieldEnterButtonClicked = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		this.executeSearch();
	};

	private onSearchValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			searchValue: (e.target as HTMLInputElement).value || '',
		});
	};

	private onSearchFieldKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.keyCode === 13) {
			this.executeSearch();
		}
	};

	private onWaypointEnter = () => {
		if (this.mFilterRequest) {
			this.executeSearch();
			return;
		}
		this.getNextBatchOfSuggestions(this.mContactSuggestions, true);
	};

	private getNextBatchOfSuggestions = (
		contactSuggestions = this.mContactSuggestions,
		initiatedFromWaypoint = false
	) => {
		if (!contactSuggestions.isBusy) {
			const { logEvent, logApiError } = this.props;

			const promise = contactSuggestions.getSuggestions(null, 10);
			if (promise) {
				logEvent(`${initiatedFromWaypoint ? 'LoadMore' : 'Load'}Contacts`);
				if (initiatedFromWaypoint) {
					this.setState({
						fetchingMoreContacts: true,
					});
				}
				promise
					.then(() => {
						if (initiatedFromWaypoint) {
							this.setState({
								fetchingMoreContacts: false,
							});
						}
					})
					.catch((error: IOperationResultNoValue) => {
						if (initiatedFromWaypoint) {
							this.setState({
								fetchingMoreContacts: false,
							});
						}

						logApiError('LoadContacts-Error', error);
					});
			}
		}
	};

	@action
	private executeSearch = (searchValue = this.state.searchValue) => {
		if (this.mSearchInputRef) {
			this.mSearchInputRef.blur();
		}

		if (!this.mContacts.isBusy) {
			const { errorMessages, logApiError, logEvent } = this.props;
			if (!searchValue) {
				this.mFilterRequest = null;
				this.setState({
					selectedContact: null,
				});
				this.mContacts.reset();
				return;
			}

			const nextFilterRequest: IBulkContactsRequest =
				!!this.mFilterRequest &&
				this.mFilterRequest.filter.criteria &&
				!!this.mFilterRequest.filter.criteria[0] &&
				this.mFilterRequest.filter.criteria[0].value === searchValue
					? this.mFilterRequest
					: {
							filter: {
								...DefaultContactsFilterRequest,
								criteria: [
									{
										property: ContactFilterCriteriaProperty.Name,
										value: searchValue,
									},
								],
							},
						};
			const promise = this.mContacts.getContacts(nextFilterRequest);
			if (promise) {
				logEvent('SearchContacts');
				this.mFilterRequest = nextFilterRequest;
				this.setState({
					selectedContact: null,
				});
				promise.then().catch((error: IOperationResultNoValue) => {
					logApiError('SearchContacts-Error', error);

					errorMessages.pushApiError(error);

					this.mFilterRequest = null;
					this.setState({
						selectedContact: null,
					});
				});
			}
		}
	};
}

const SingleEmailContactSelectAsObserver = observer(_SingleEmailContactSelect);
const SingleEmailContactSelectWithContext = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey
)(SingleEmailContactSelectAsObserver);
export const SingleEmailContactSelect = withEventLogging(
	SingleEmailContactSelectWithContext,
	'SingleEmailContactSelect'
);
