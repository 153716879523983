import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { bs } from '../../styles/styles';
import { DefaultSelectBox, ISelectBoxOption } from '../SelectBox';

export enum ComposerRecipientOwnershipDropdownOption {
	All = 'All',
	MyContacts = 'MyContacts',
	MyConnections = 'MyConnections',
	TheirContacts = 'TheirContacts',
}

export function ComposerRecipientContactFilterOwnershipDropdown(props: {
	filterCriteriaList: Api.IContactFilterCriteria[];
	currentUserId: string | null;
	contactOwnerUserId: string;
	theirContactsOptionEnabled?: boolean;
	disabled?: boolean;
	onChange: (ownershipCriteria: Api.IContactFilterCriteria) => void;
}) {
	let selectedOwnershipDropdownOption = ComposerRecipientOwnershipDropdownOption.All;
	for (const criteria of props.filterCriteriaList) {
		if (criteria.property === Api.ContactFilterCriteriaProperty.OwnedBy) {
			// on impersonation support change userSession.user.id check to imp.user.id || userSession.user.id
			selectedOwnershipDropdownOption =
				criteria.value === props.currentUserId
					? ComposerRecipientOwnershipDropdownOption.MyContacts
					: ComposerRecipientOwnershipDropdownOption.TheirContacts;
			break;
		}
		if (criteria.property === Api.ContactFilterCriteriaProperty.Connections) {
			selectedOwnershipDropdownOption = ComposerRecipientOwnershipDropdownOption.MyConnections;
			break;
		}
	}
	const handleChange = (option: ComposerRecipientOwnershipDropdownOption) => {
		let ownershipCriteria: Api.IContactFilterCriteria;
		switch (option) {
			case ComposerRecipientOwnershipDropdownOption.MyContacts:
				ownershipCriteria = {
					property: Api.ContactFilterCriteriaProperty.OwnedBy,
					value: props.currentUserId,
				};
				break;
			case ComposerRecipientOwnershipDropdownOption.TheirContacts:
				ownershipCriteria = {
					property: Api.ContactFilterCriteriaProperty.OwnedBy,
					value: props.contactOwnerUserId,
				};
				break;
			case ComposerRecipientOwnershipDropdownOption.MyConnections:
				ownershipCriteria = {
					property: Api.ContactFilterCriteriaProperty.Connections,
				};
				break;
			case ComposerRecipientOwnershipDropdownOption.All:
			default:
				ownershipCriteria = {
					property: Api.ContactFilterCriteriaProperty.All,
				};
		}
		props.onChange(ownershipCriteria);
	};
	return (
		<ComposerRecipientOwnershipDropdown
			theirContactsOptionEnabled={props.theirContactsOptionEnabled}
			disabled={props.disabled}
			selectedOption={selectedOwnershipDropdownOption}
			onChange={handleChange}
		/>
	);
}

export function ComposerRecipientOwnershipDropdown(props: {
	theirContactsOptionEnabled?: boolean;
	disabled?: boolean;
	selectedOption: ComposerRecipientOwnershipDropdownOption;
	onChange: (option: ComposerRecipientOwnershipDropdownOption) => void;
}) {
	const options: ISelectBoxOption<ComposerRecipientOwnershipDropdownOption>[] = [
		{
			title: 'All contacts',
			value: ComposerRecipientOwnershipDropdownOption.All,
		},
		{
			title: 'Contacts I own',
			value: ComposerRecipientOwnershipDropdownOption.MyContacts,
		},
		{
			title: 'My connections',
			value: ComposerRecipientOwnershipDropdownOption.MyConnections,
		},
	];
	if (props.theirContactsOptionEnabled !== false) {
		options.push({
			title: 'Contacts they own',
			value: ComposerRecipientOwnershipDropdownOption.TheirContacts,
		});
	}
	return (
		<div className={css(bs.flex, bs.flexCol, bs.gap2, bs.textHeader)}>
			<div>Showing:</div>
			<DefaultSelectBox
				disabled={props.disabled}
				menuStyles={[bs.textSm]}
				onSelectionChanged={(selectedOption: ISelectBoxOption<ComposerRecipientOwnershipDropdownOption>) => {
					if (selectedOption.value) {
						props.onChange(selectedOption.value);
					}
				}}
				options={options}
				selectedOption={options.find(option => option.value === props.selectedOption)}
				triggerStyles={[bs.bgWhite, bs.textHeader, bs.textSm]}
			/>
		</div>
	);
}
