import * as Api from '@ViewModels';
import { observer } from 'mobx-react';
import { useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useEventLogging } from '../../../../../models/Logging';
import { useErrorMessages, useTextMessaging } from '../../../../../models/hooks/appStateHooks';
import { Conversation } from '../../Conversation';
import { ConversationResolve } from '../../ConversationResolve';
import { MessageBubble } from '../../MessageBubble';
import { TextMessageInput } from '../../TextMessageInput';
import { useTextMessageConversationsContext } from '../context';

export const SelectedConversation: React.FC = observer(() => {
	const textMessagingVM = useTextMessaging();
	const {
		recipients,
		selectedConversation,
		selectedRecipient,
		setRecipients,
		setSelectedConversation,
		setShowNewConversationSearch,
	} = useTextMessageConversationsContext();

	const errorMessages = useErrorMessages();
	const logger = useEventLogging();
	const history = useHistory();
	const [placeholderMessage, setPlaceholderMessage] = useState<JSX.Element[]>(null);

	const createConversation = async (phoneNumbers: string[]) => {
		try {
			setShowNewConversationSearch(false);
			const newConversation = await textMessagingVM.createConversation(phoneNumbers);
			setSelectedConversation(newConversation);

			const recipientsAsContacts = recipients.filter(r => r instanceof Api.TextRecipientViewModel);
			setRecipients(recipientsAsContacts);

			if (recipientsAsContacts.length) {
				for (const recipient of recipientsAsContacts) {
					if (recipient instanceof Api.TextRecipientViewModel) {
						await newConversation.updateRecipients(recipient.toJs());
					}
				}
			}

			return newConversation;
		} catch (err) {
			logger.logApiError('CreateTextConversation-Error', err);
			errorMessages.pushApiError(Api.asApiError(err));
		}
	};

	const onMultiplePhoneNumberSelected = (phoneNumber: Api.IPhoneNumber) => {
		if (selectedRecipient?.needsResolving) {
			selectedRecipient.setSelectedPhoneNumber(phoneNumber);
			const newRecipients = recipients.map(r => {
				if (r instanceof Api.TextRecipientViewModel) {
					return r.id === selectedRecipient.id ? selectedRecipient : r;
				}
				return r;
			});
			setRecipients(newRecipients);
		}
	};

	const onTryNewPhoneNumber = async (phoneNumber: Api.IPhoneNumber) => {
		createConversation([phoneNumber.metadata?.standard]);
	};

	const onSendText = async (msg: string, attachments: Api.AttachmentsToBeUploadedViewModel<File>) => {
		const placeholderMessages: JSX.Element[] = [];

		if (msg) {
			const textMessage: Partial<Api.ITextMessage> = {
				direction: Api.Direction.Outbound,
				status: Api.TextStatus.Sending,
				text: msg,
			};

			placeholderMessages.push(
				<MessageBubble className='message' id={uuid()} showStatus={!attachments} textMessage={textMessage} />
			);
		}

		if (attachments) {
			attachments.attachments.forEach((attachment, i, all) => {
				const textMessage: Partial<Api.ITextMessage> = {
					direction: Api.Direction.Outbound,
					status: Api.TextStatus.Sending,
				};

				placeholderMessages.push(
					<MessageBubble
						className='message'
						id={uuid()}
						placeholder={<div>{`Loading ${attachment.name}`}</div>}
						showStatus={i === all.length - 1}
						textMessage={textMessage}
					/>
				);
			});
		}

		if (placeholderMessages.length) {
			setPlaceholderMessage(placeholderMessages);
		}

		let conversation = selectedConversation;
		let redirect = false;
		if (!conversation && !selectedRecipient?.needsResolving) {
			redirect = true;
			conversation = await createConversation(
				recipients.map(r => {
					return r instanceof Api.TextRecipientViewModel
						? r.selectedPhoneNumber?.metadata?.standard || r.phoneNumbers[0].metadata?.standard
						: r.metadata?.standard || r.value;
				})
			);
		}

		if (conversation) {
			try {
				await conversation?.sendMessage(msg, attachments);
				setPlaceholderMessage(null);
				if (redirect) {
					history.push(`/texting/${textMessagingVM.id}/${conversation.id}`);
				}
			} catch (err) {
				logger.logApiError('SendTextMessage-Error', err);
				errorMessages.pushApiError(Api.asApiError(err));
			}
		}
	};

	const showTextInput = !!selectedConversation || (recipients.length > 0 && !selectedRecipient?.needsResolving);

	const isProcessing =
		(!!selectedConversation && !selectedConversation?.isLoaded && !selectedConversation?.textMessages?.length) ||
		textMessagingVM.loadingConversations;

	return (
		<>
			{selectedConversation ? (
				<Conversation
					className='conversation'
					conversation={selectedConversation}
					onTryNewPhoneNumber={onTryNewPhoneNumber}
					phoneNumber={textMessagingVM.phoneNumberOrder}
					placeholderMessages={placeholderMessage}
					processing={isProcessing}
					recipient={selectedRecipient}
				/>
			) : (
				<ConversationResolve recipient={selectedRecipient} onPhoneNumberSelected={onMultiplePhoneNumberSelected} />
			)}
			{showTextInput && (
				<TextMessageInput className='text-message-input' conversation={selectedConversation} onSend={onSendText} />
			)}
		</>
	);
});
