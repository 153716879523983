import { titles } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		color: titles,
		textAlign: 'center',
	},
	message: {
		fontSize: 14,
		margin: '20px 30px',
	},
	title: {
		fontSize: 24,
		margin: '20px 30px',
	},
});
