import { titles } from '../../styles/colors';
import { CssSelectors } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	body: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: 30,
				width: 250,
			},
			':not(:first-child)'
		),
	},
	container: {
		padding: '0 20px 20px 20px',
	},
	footer: {
		alignItems: 'center',
		display: 'flex',
		marginTop: 60,
		...CssSelectors.allChildren(
			{
				marginLeft: 20,
			},
			':not(:first-child)'
		),
	},
	message: {
		color: titles,
		fontSize: 14,
		lineHeight: '24px',
	},
	okButton: {},
	rtnCheckbox: {
		color: titles,
		fontSize: 14,
		textAlign: 'left',
	},
	title: {
		color: titles,
		fontSize: 18,
		marginBottom: 20,
	},
});
