import { LoadingSpinner } from '../../components/LoadingSpinner';
import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { NavigationBreadcrumbsBar } from '../../components/NavigationBreadcrumbsBar';
import { baseStyleSheet } from '../../styles/styles';
import { INavigationItemProps } from '../MainContainer';
import { StyleSheet, css } from 'aphrodite';
import * as React from 'react';

const LazySurveysReportingRoute = React.lazy(() => import(/* webpackChunkName: "surveys-route" */ './index'));

const styleSheet = StyleSheet.create({
	loadingSpinnerContainer: {
		height: '100%',
		position: 'relative',
		width: '100%',
	},
});

type IProps = INavigationItemProps;

export const LazySurveysRoute: React.FC<IProps> = props => {
	const spinner = React.useMemo(() => {
		return (
			<div className={css(styleSheet.loadingSpinnerContainer)}>
				<MultiContainerHeader appBarHeader={<NavigationBreadcrumbsBar />} />
				<LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='large' />
			</div>
		);
	}, []);
	return (
		<>
			<React.Suspense fallback={spinner}>
				<LazySurveysReportingRoute {...props} />
			</React.Suspense>
		</>
	);
};
