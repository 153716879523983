import { baseStyleSheet } from '../../../../styles/styles';
import { RightArrow } from '../../../svgs/icons/RightArrow';
import { conditionalTextFormatted } from '../utils';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import moment from 'moment';
import { useState } from 'react';

export const EmailsToSendButton = ({
	color,
	counter,
	heading,
	icon,
	monthTotals,
	onClick,
	shouldUpdateHeading,
	subHeading,
}: {
	color: string;
	counter: number;
	heading: string;
	icon: JSX.Element;
	monthTotals?: {
		total: number;
		month: number;
	}[];
	onClick?: (index: number) => void;
	subHeading?: string;
	shouldUpdateHeading?: boolean;
}) => {
	const [onHover, setOnHover] = useState(false);
	const hasMonthTotals = monthTotals != null && monthTotals.length > 0;
	const [currentMonthIndex, setCurrentMonthIndex] = useState(hasMonthTotals ? 0 : null);
	// @ts-ignore
	const currentMonthObj = currentMonthIndex != null ? monthTotals[currentMonthIndex] : null;
	const monthTotal = currentMonthObj?.total ?? 0;
	const mainHeading =
		shouldUpdateHeading && currentMonthObj ? formatDateString(currentMonthObj.month - 1, monthTotal, heading) : heading;
	const counterTotal = hasMonthTotals ? monthTotal : counter;

	const isMay = (month: number) => {
		return month === 5;
	};

	const handleMonthClick = (idx: number) => {
		setCurrentMonthIndex(idx);
	};

	const handleButtonClick = () => {
		// @ts-ignore
		// @ts-ignore
		onClick(currentMonthIndex);
	};

	function formatDateString(momentMonthIndex: number, total: number, text: string) {
		return `${moment().month(momentMonthIndex).format('MMMM')} ${`${conditionalTextFormatted(text, total > 1)}`}`;
	}

	return (
		<div
			className={css(
				styleSheet.mainWrapper,
				// @ts-ignore
				// @ts-ignore
				monthTotals?.length > 0 && monthTotals?.length < 4 ? styleSheet.mainWrapperWChildren : undefined,
				// @ts-ignore
				monthTotals?.length > 3 ? styleSheet.mainWrapperWLotsOfChildren : undefined
			)}
		>
			<div className={css(styleSheet.listWrap)}>
				<div className={css(styleSheet.list)} tabIndex={-1}>
					{/* @ts-ignore */}
					<div className={monthTotals?.length > 3 ? css(styleSheet.listItem) : undefined}>
						<button
							type='button'
							className={css(styleSheet.button, !monthTotals ? styleSheet.centeredButton : undefined)}
							onClick={handleButtonClick}
							onMouseEnter={() => setOnHover(true)}
							onMouseLeave={() => setOnHover(false)}
						>
							<figure className={css(styleSheet.figure)} style={{ backgroundColor: color }} tabIndex={-1}>
								{icon}
								<figcaption className={css(styleSheet.counter)}>{counterTotal}</figcaption>
							</figure>
							<div>
								<div className={css(styleSheet.positionRelative, styleSheet.headingWrap)}>
									<h6 tabIndex={-1} className={css(styleSheet.heading, baseStyleSheet.truncateText)}>
										{mainHeading}{' '}
									</h6>
									<div
										className={css(
											styleSheet.positionAbsolute,
											styleSheet.arrowRight,
											onHover ? styleSheet.arrowRightHover : undefined
										)}
									>
										<RightArrow styles={{ display: 'inline-block' }} />
									</div>
								</div>
								<div className={css(styleSheet.subHeading)}>{subHeading}</div>
							</div>
						</button>
						{hasMonthTotals ? (
							<div
								className={css(
									styleSheet.positionAbsolute,
									styleSheet.childrenWrap,
									monthTotals?.length > 3 ? styleSheet.childrenWrapAdjusted : undefined
								)}
							>
								{monthTotals.map((x, i) => {
									const isMonthSelected = currentMonthIndex === i;
									const formatMonth = conditionalTextFormatted(`MMM`, isMay(x.month), '', '.');
									if (i > 5) {
										return null;
									}
									return (
										<button
											key={i}
											onClick={() => handleMonthClick(i)}
											className={css(styleSheet.smallButton, isMonthSelected ? styleSheet.smallButtonSelected : null)}
											style={isMonthSelected ? { backgroundColor: color } : undefined}
										>
											<div tabIndex={-1}>
												{`${moment()
													.month(x.month - 1)
													.format(formatMonth)}`}
											</div>
										</button>
									);
								})}
							</div>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
};
