import { header, titles } from '../../../../styles/colors';
import { Layouts } from '../../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {},
	link: {
		whiteSpace: 'nowrap',
	},
	saveSearchButtonContainer: {
		marginTop: 20,
		...Layouts.horizontalStack(20),
	},
	saveSearchDescription: {
		color: header,
		fontSize: 14,
		marginBottom: 10,
	},
	saveSearchTitle: {
		color: titles,
		fontSize: 18,
		marginBottom: 20,
	},
});
