import * as React from 'react';
import { css } from 'aphrodite';
import { FlyoutModal } from '../../components/FlyoutModal';
import { baseStyleSheet } from '../../styles/styles';
import { styleSheet } from './styles';

export const DetailsFlyout = ({
	title,
	children,
	isOpen = false,
	setIsOpen,
}: {
	title: string;
	isOpen: boolean;
	children: React.ReactNode;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const cancel = () => {
		setIsOpen(false);
	};

	return (
		<FlyoutModal isOpen={isOpen} useDefaultHeader={false} onRequestClose={cancel}>
			<header className={css(styleSheet.header)}>
				<h3 className={css(styleSheet.headerText)}>{title}</h3>
			</header>

			<div className={css(styleSheet.container)}>
				<div className={css(styleSheet.body)}>{children}</div>
			</div>

			<footer className={css(styleSheet.footer)}>
				<div className={css(styleSheet.separator)} />

				<div className={css(styleSheet.actions)}>
					<button className={css(baseStyleSheet.ctaButton)} onClick={cancel}>
						Close
					</button>
				</div>
			</footer>
		</FlyoutModal>
	);
};
