import { BrowserMediaQueries } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		alignItems: 'center',
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		marginBottom: 5,
		marginTop: 5,
	},
	error: {
		marginTop: 16,
	},
	noTextingConversationGraphic: {
		[BrowserMediaQueries.IE11]: {
			transform: 'rotate(180deg)',
		},
	},
});
