import { StyleSheet } from 'aphrodite';
import { borderColor, brandPrimary, white } from '../../styles/colors';

export const styleSheet = StyleSheet.create({
	header: {
		background: brandPrimary,
		color: white,
		display: 'flex',
		justifyContent: 'center',
	},
	headerText: {
		margin: 0,
		padding: 12,
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		height: 'calc(100vh - 124px - 49px)',
	},
	body: {
		flex: '1',
		overflow: 'auto',
		height: '100%',
	},
	footer: {
		padding: 32,
		paddingTop: 0,
		background: white,
		bottom: 0,
		height: 108,
		left: 0,
		position: 'absolute',
		right: 0,
	},
	separator: {
		borderBottom: `2px solid ${borderColor}`,
	},
	subTitle: {
		color: brandPrimary,
		marginBottom: 4,
		fontWeight: 600,
		margin: 0,
	},
	actions: {
		display: 'flex',
		justifyContent: 'space-around',
		marginTop: 32,
	},
});
