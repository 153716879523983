import { StyleSheet } from 'aphrodite';
import {
	brandPrimaryHover,
	brandSecondary,
	header,
	heartRed,
	mention,
	nameCircles,
	titles,
	warningDark,
} from '../../../styles/colors';
import { CssSelectors, baseStyleSheet } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	cardHeader: {
		alignItems: 'flex-start',
		borderBottom: `1px solid ${mention}`,
		display: 'flex',
		paddingBottom: '0.5rem',
		...CssSelectors.allChildren(
			{
				marginLeft: '1rem',
			},
			':not(:first-child)'
		),
	},
	cardIconButton: {
		alignItems: 'center',
		background: brandSecondary,
		borderRadius: 12,
		display: 'flex',
		justifyContent: 'center',
		padding: '0.5rem',
	},
	headerTotalPosts: {
		display: 'flex',
		flexDirection: 'column',
		...CssSelectors.allChildren(
			{
				marginTop: '0.25rem',
			},
			':not(:first-child)'
		),
	},
	headerTotalPostsStat: {
		color: brandSecondary,
		fontSize: '1.625rem',
	},
	imageContainer: {
		alignItems: 'center',
		background: '#FFFFFF',
		display: 'flex',
		height: 130,
		justifyContent: 'center',
		margin: 0,
		minHeight: 130,
		overflow: 'hidden',
		padding: 0,
		position: 'relative',
		width: '100%',
		...CssSelectors.allChildren(
			{
				left: 0,
				width: '100%',
			},
			'img'
		),
	},
	linkedInConnect: {
		alignItems: 'center',
		borderColor: brandPrimaryHover,
		borderRadius: 3,
		borderStyle: 'solid',
		borderWidth: 1,
		color: brandPrimaryHover,
		fontSize: 14,
		marginLeft: 12,
		padding: '2px 9px',
		position: 'relative',
	},
	loginButton: {
		marginLeft: 12,
		position: 'relative',
	},
	postInfo: {
		display: 'inline-flex',
		marginLeft: '0.6rem',
		...CssSelectors.allChildren(
			{
				marginRight: '0.6rem',
			},
			':first-child'
		),
	},
	postSubmitter: {
		color: header,
		...baseStyleSheet.fontBold,
		fontSize: '0.75rem',
		marginRight: '5px',
	},
	recentPostsTag: {
		backgroundColor: mention,
		color: brandSecondary,
	},
	root: {
		display: 'grid',
		gridTemplateColumns: '50% 50%',
		width: '100%',
	},
	serviceConnectedText: {
		color: header,
		...baseStyleSheet.fontBold,
		fontSize: '0.75rem',
		marginLeft: 12,
	},
	serviceConnection: {
		...CssSelectors.allChildren(
			{
				marginTop: 20,
			},
			':not(:first-child)'
		),
	},
	serviceConnections: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		maxWidth: 1100,
		...CssSelectors.allChildren({
			flexGrow: 1,
			maxWidth: '50%',
			minWidth: 320,
			padding: 8,
		}),
	},
	socialButtonContainer: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allDescendants(
			{
				display: 'flex',
			},
			'div'
		),
	},
	socialMediaColumn: {
		alignItems: 'flex-start',
		display: 'flex',
		flexDirection: 'column',
		maxHeight: 195,
		overflowY: 'auto',
		paddingTop: '1.5rem',
		...CssSelectors.allChildren(
			{
				marginTop: '1rem',
			},
			':not(:first-child)'
		),
	},
	socialMediaLikes: {
		alignItems: 'center',
		color: header,
		display: 'flex',
		fontSize: '0.75rem',
	},
	socialMediaPost: {
		display: 'flex',
		flexDirection: 'column',
		margin: '0 0 0 10px',
	},
	socialMediaPostHeartIcon: {
		fill: heartRed,
		marginRight: '0.25rem',
	},
	socialMediaPostLogo: {
		display: 'inline-block',
		height: 18,
		marginRight: '0.2rem',
		width: 18,
	},
	socialMediaPostTarget: {
		display: 'flex',
		marginBottom: 3,
	},
	socialMediaPostTargets: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		margin: 0,
		...CssSelectors.allChildren(
			{
				marginTop: '0.25rem',
			},
			':not(:first-child)'
		),
	},
	socialMediaPostTitle: {
		color: titles,
		...baseStyleSheet.fontBold,
		fontSize: '14px',
		fontWeight: 700,
		height: 19,
		margin: '0 0 7px 0',
		overflow: 'hidden',
	},
	stackMultipleAccounts: {
		display: 'flex',
		flexDirection: 'column',
	},
	suggestedTemplate: {
		alignItems: 'center',
		background: '#F9F9F9',
		border: `1px solid ${nameCircles}`,
		borderRadius: '0.8125rem',
		boxShadow: '0px 1px 2px rgba(167,171,173, 0.22)',
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'column',
		height: 214,
		overflow: 'hidden',
	},
	suggestedTemplateButtonColumn: {
		alignItems: 'center',
	},
	suggestedTemplateImg: {
		height: 130,
		minHeight: 130,
		objectFit: 'cover',
		width: '100%',
	},
	suggestedTemplatesHeader: {
		padding: '0.5rem 0 1.5rem',
	},
	suggestedTemplatesList: {
		...CssSelectors.allChildren(
			{
				marginLeft: '1rem',
			},
			':not(:first-child)'
		),
	},
	suggestedTemplatesTag: {
		backgroundColor: 'rgba(248, 145, 67, 0.17)',
		color: warningDark,
	},
	suggestedTemplateTitle: {
		color: header,
		fontSize: '11px',
		margin: '0px 0px 5px 0px',
		overflow: 'hidden',
		padding: '17px 5px 0 5px',
		textAlign: 'center',
	},
	suggestedTemplateVideoImg: {
		objectFit: 'contain',
	},
});
