import { StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../../../web/styles/styles';

const interItemSpacing = 15;

export const styleSheet = StyleSheet.create({
	container: {
		boxSizing: 'border-box',
		overflow: 'hidden',
	},
	item: {
		height: 200,
		marginLeft: interItemSpacing,
		marginTop: interItemSpacing,
		width: 300,
	},
	itemIcon: {
		height: '100%',
		objectFit: 'contain',
		width: '100%',
	},
	items: {
		display: 'flex',
		flexWrap: 'wrap',
		marginLeft: -interItemSpacing,
		marginTop: -interItemSpacing,
	},
	googleBusinessItem: {
		...CssSelectors.allChildren({
			marginBottom: 6,
		}),
	},
	statusMessage: {
		fontSize: 12,
	},
});
