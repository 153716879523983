import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { INavBarLink } from '../../NavBarLink';

const LiteAdminLinks: INavBarLink[] = [
	{
		linkName: 'Dashboard',
		path: '/dashboard',
	},
	{
		linkName: 'Templates',
		path: '/templates',
	},

	{
		linkName: 'Contacts',
		path: '/people',
	},
	{
		linkName: 'Automations',
		path: '/automations',
	},
	{
		linkName: 'Reporting',
		path: '/reporting',
	},
];

const LiteUserLinks: INavBarLink[] = [
	{
		linkName: 'Dashboard',
		path: '/dashboard',
	},
	{
		linkName: 'Settings',
		path: '/settings',
	},
];

export function useCoffeeNavLinks() {
	const userSession = useUserSession();
	return userSession.account.isAdmin ? LiteAdminLinks : LiteUserLinks;
}
