import { IFileAttachment, IOperationResultNoValue, TemplatesViewModel, UserViewModel } from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IComposerContent, SingleEmailGuidedComposerStep } from '../../../../../models';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../../models/Logging';
import {
	ComposeEmailToWithGuideViewModel,
	ContactViewModel,
	SingleEmailGuideStep,
} from '../../../../../viewmodels/AppViewModels';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../../ConfirmationDialog';
import { DeprecatedCloseButton } from '../../../DeprecatedCloseButton';
import { Modal } from '../../../Modal';
import { BackArrowIcon } from '../../../svgs/icons/BackArrowIcon';
import { WarningIcon } from '../../../svgs/icons/WarningIcon';
import { ContactContextTabView } from '../../ContactContextTabView';
import { SendMessageComposer } from '../../SendMessageComposer';
import { SingleEmailContactSelect } from '../SingleEmailContactSelect';
import { SingleEmailGuidedComposer } from '../SingleEmailGuidedComposer';
import { SingleEmailSendComplete } from '../SingleEmailSendComplete';
import { styleSheet } from './styles';

const ReturnToGuideWarningOptions: IConfirmationDialogOption<string>[] = [
	{
		isCta: true,
		title: 'Continue',
	},
	{
		isCancel: true,
		title: 'Cancel',
	},
];

interface IProps extends IUserSessionComponentProps, IEventLoggingComponentProps {
	className?: string;
	guideViewModel: ComposeEmailToWithGuideViewModel;
	canShowTemplateSelect?: boolean;
}

interface IState {
	showingReturnToGuideWarning?: boolean;
	templates?: TemplatesViewModel;
}

class _SingleEmailGuideModal extends React.Component<IProps, IState> {
	public readonly state: IState = {};

	public render() {
		const { className, guideViewModel } = this.props;
		const modalClassName = `${css(
			guideViewModel.step === SingleEmailGuideStep.Compose ? styleSheet.sendMessageComposerModal : null
		)} single-email-guide-modal ${className || ''}`;
		return (
			<Modal className={modalClassName} isOpen={!!guideViewModel.isShowingModal} shouldCloseOnOverlayClick={false}>
				<div
					className={`${css(
						guideViewModel.step !== SingleEmailGuideStep.Compose ? styleSheet.container : null
					)} single-email-guide-modal-content`}
				>
					<div className={css(styleSheet.header)}>
						<DeprecatedCloseButton onClick={this.onCloseButtonClicked} />
					</div>
					{guideViewModel.step === SingleEmailGuideStep.GuidedComposer && (
						<button className={css(styleSheet.backButton)} onClick={this.onBackButtonClicked}>
							<BackArrowIcon />
							<span>Back</span>
						</button>
					)}
					<div className={css(styleSheet.body)}>{this.renderBody()}</div>
				</div>
			</Modal>
		);
	}

	private renderBody() {
		const { guideViewModel } = this.props;
		switch (guideViewModel.step) {
			case SingleEmailGuideStep.SelectRecipient: {
				return (
					<SingleEmailContactSelect className={css(styleSheet.guide)} onContactSelected={this.onRecipientSelected} />
				);
			}
			case SingleEmailGuideStep.GuidedComposer: {
				return (
					<div className={css(styleSheet.guidedComposerContainer, styleSheet.guide)}>
						<div className={css(styleSheet.guidedComposerContainerColumn)}>
							<SingleEmailGuidedComposer
								contact={guideViewModel.recipient}
								onComplete={this.onGuidedComposerComplete}
								onStepChanged={this.onGuidedComposerStepChanged}
								step={guideViewModel.guidedComposerStep}
								onSkipButtonClicked={this.onSkipClicked}
							/>
						</div>
						<div className={css(styleSheet.guidedComposerContainerColumn)}>
							<ContactContextTabView
								className={css(styleSheet.guidedComposerContactContext)}
								contact={guideViewModel.recipient}
							/>
						</div>
					</div>
				);
			}
			case SingleEmailGuideStep.Complete: {
				return (
					<SingleEmailSendComplete
						className={css(styleSheet.guide, styleSheet.sendComplete)}
						onStartOverButtonClicked={this.onStartOverButtonClicked}
						recipient={guideViewModel.recipient}
					/>
				);
			}
			default: {
				return (
					<SendMessageComposer
						canScheduleSend={!guideViewModel.emailMessage?.options?.scheduledSend ?? true}
						canShowTemplateSelect={true}
						// @ts-ignore
						sendCtaTitle={guideViewModel.emailMessage?.options?.scheduledSend ? 'Schedule Email' : null}
						className={css(styleSheet.sendMessageComposer)}
						emailMessage={guideViewModel.emailMessage}
						guideViewModel={guideViewModel}
						header={this.renderSendMessageComposerHeader()}
						onRequestRemoveSavedEmailAttachment={this.onRequestRemoveSavedEmailAttachment}
						onSend={this.onSend}
						toastOnSendSuccess={true}
					/>
				);
			}
		}
	}

	private onRequestRemoveSavedEmailAttachment = (savedAttachment: IFileAttachment) => {
		return new Promise(resolve => {
			const { guideViewModel } = this.props;
			const currentAttachments = guideViewModel?.emailMessage?.savedAttachments;
			const filteredAttachments = currentAttachments?.filter(x => x.id !== savedAttachment.id);
			guideViewModel?.emailMessage?.setSavedAttachments(filteredAttachments);
			resolve(undefined);
		});
	};

	private renderSendMessageComposerHeader() {
		const { showingReturnToGuideWarning } = this.state;
		const { recipient } = this.props.guideViewModel;
		const name =
			recipient.firstName || recipient.name || (recipient.primaryEmail && recipient.primaryEmail.value) || '';
		return (
			<div className={css(styleSheet.sendMessageComposerHeader)}>
				<div className={css(styleSheet.sendMessageComposerHeaderTitle)}>
					<div>Message for &nbsp;</div>
					<div className={css(styleSheet.sendMessageComposerHeaderName)} title={name}>
						{name}
					</div>
				</div>
				<button
					className={css(styleSheet.sendMessageComposerHeaderNotSureButton)}
					onClick={this.onNotSureWhatToWriteClicked}
				>
					<span>Not sure what to write?</span>
				</button>
				<ConfirmationDialog
					icon={<WarningIcon />}
					modalProps={{
						isOpen: !!showingReturnToGuideWarning,
						onRequestClose: this.onReturnToGuideWarningOptionSelected,
					}}
					options={ReturnToGuideWarningOptions}
					title='Use the Levitate Keep in Touch Guide?'
				>
					<div className={css(styleSheet.warningModalMessage)}>
						{[
							'If you use our Keep in Touch guide to help you craft an',
							"email, you may lose any content you've already written.",
							'Would you like to continue?',
						].map((x, i) => {
							return <div key={`${x}-${i}`}>{x}</div>;
						})}
					</div>
				</ConfirmationDialog>
			</div>
		);
	}

	private onStartOverButtonClicked = () => {
		const { guideViewModel, logInput } = this.props;
		// @ts-ignore
		logInput('Restart', 'Click');
		guideViewModel.restart();
	};

	private onSend = (sendPromise: Promise<IOperationResultNoValue>) => {
		if (sendPromise) {
			sendPromise.then(() => {
				const { guideViewModel } = this.props;
				if (guideViewModel.canShowGuidedComposer) {
					guideViewModel.setStep(SingleEmailGuideStep.Complete);
				} else {
					guideViewModel.finish(true);
				}
			});
		}
	};

	private onReturnToGuideWarningOptionSelected = (option?: IConfirmationDialogOption<string>, cancel?: boolean) => {
		this.setState(
			{
				showingReturnToGuideWarning: false,
			},
			() => {
				if (!cancel && !!option && !!option.isCta) {
					// go to guide
					const { guideViewModel } = this.props;
					guideViewModel.setGuidedComposerStep(SingleEmailGuidedComposerStep.Subject);
					guideViewModel.setStep(SingleEmailGuideStep.GuidedComposer);
				}
			}
		);
	};

	private onNotSureWhatToWriteClicked = () => {
		this.setState({
			showingReturnToGuideWarning: true,
		});
	};

	private onSkipClicked = (content: IComposerContent) => {
		const { userSession, logEvent, logApiError } = this.props;
		this.advanceToFullEmailComposer('skipped', content);

		// save to user prefs.
		// @ts-ignore
		// @ts-ignore
		const promise = new UserViewModel(userSession, userSession.user).updateUserPreferences({
			skipSendEmailGuide: true,
		});
		if (promise) {
			// @ts-ignore
			logEvent('UpdateUserPreferences');
			promise
				.then(() => {
					// @ts-ignore
					// @ts-ignore
					// @ts-ignore
					userSession.user.userPreferences.skipSendEmailGuide = true;
				})
				.catch((error: IOperationResultNoValue) => {
					// @ts-ignore
					logApiError('UpdateUserPreferences-Error', error);
				});
		}
	};

	private onBackButtonClicked = () => {
		const { guideViewModel, logInput } = this.props;
		if (guideViewModel.step === SingleEmailGuideStep.GuidedComposer) {
			if (guideViewModel.guidedComposerStep === SingleEmailGuidedComposerStep.Subject) {
				// @ts-ignore
				logInput('GoBackToRecipientSelect', 'Click');
				guideViewModel.setStep(SingleEmailGuideStep.SelectRecipient);
			} else {
				// @ts-ignore
				logInput(`GoBackToGuidedComposerStep${guideViewModel.guidedComposerStep}`, 'Click');
				guideViewModel.setGuidedComposerStep(guideViewModel.guidedComposerStep - 1);
			}
		}
	};

	private onRecipientSelected = (recipient: ContactViewModel) => {
		const { logEvent, guideViewModel } = this.props;
		// @ts-ignore
		logEvent('RecipientSelected', { id: recipient.id });
		guideViewModel.setRecipient(recipient);

		if (guideViewModel.canShowGuidedComposer) {
			guideViewModel.setStep(SingleEmailGuideStep.GuidedComposer);
		} else {
			this.advanceToFullEmailComposer('skipGuide');
		}
	};

	private onGuidedComposerStepChanged = (step: SingleEmailGuidedComposerStep) => {
		const { guideViewModel } = this.props;
		guideViewModel.setGuidedComposerStep(step);
	};

	private onGuidedComposerComplete = (content: IComposerContent) => {
		this.advanceToFullEmailComposer('completed', content);
	};

	private onCloseButtonClicked = () => {
		const { guideViewModel, logInput } = this.props;
		// @ts-ignore
		logInput('Close', 'Click');
		guideViewModel.finish(guideViewModel.step === SingleEmailGuideStep.Complete ? true : false);
	};

	private advanceToFullEmailComposer = (action: string, content?: IComposerContent) => {
		const { guideViewModel, logEvent } = this.props;
		guideViewModel.onGuidedComposerComplete(guideViewModel.recipient, content);
		guideViewModel.setStep(SingleEmailGuideStep.Compose);

		const eventContext = (content || {}) as any;
		// @ts-ignore
		logEvent(
			`GuidedComposer${action.charAt(0).toLocaleUpperCase()}${action.substring(1)}`,
			Object.keys(eventContext).reduce((result, key) => {
				result[key] = (eventContext[key] || '').length;
				return result;
			}, {} as any)
		);
	};
}

const SingleEmailGuideModalAsObserver = observer(_SingleEmailGuideModal);
const SingleEmailGuideModalWithContext = inject(UserSessionViewModelKey)(SingleEmailGuideModalAsObserver);
export const SingleEmailGuideModal = withEventLogging(SingleEmailGuideModalWithContext, 'SingleEmailGuideModal');
