import * as Api from '@ViewModels';
import produce from 'immer';
import { useCallback } from 'react';

export enum EStatusOption {
	WithEmail,
	WithPhoneNumber,
	WithAddress,
	HaveAlertsDue,
	PrivateContacts,
	KeepInTouch,
	WithoutTags,
	NoEmail,
	WithoutPhoneNumber,
	NoAddress,
	Archived,
}

const withEmailCriteria: Api.IContactFilterCriteria = { property: Api.ContactFilterCriteriaProperty.WithEmailAddress };
const withPhoneNumberCriteria: Api.IContactFilterCriteria = {
	property: Api.ContactFilterCriteriaProperty.WithPhoneNumber,
};
const withAddressCriteria: Api.IContactFilterCriteria = {
	property: Api.ContactFilterCriteriaProperty.WithMailingAddress,
};
const haveAlertsDueCriteria: Api.IContactFilterCriteria = { property: Api.ContactFilterCriteriaProperty.TagAlert };
const privateContactsCriteria: Api.IContactFilterCriteria = {
	property: Api.ContactFilterCriteriaProperty.PrivateContacts,
};
const keepInTouchCriteria: Api.IContactFilterCriteria = { property: Api.ContactFilterCriteriaProperty.KeepInTouch };
const withoutTagsCriteria: Api.IContactFilterCriteria = { property: Api.ContactFilterCriteriaProperty.WithoutTags };
const noEmailCriteria: Api.IContactFilterCriteria = {
	property: Api.ContactFilterCriteriaProperty.WithoutEmailAddresses,
};
const withoutPhoneNumberCriteria: Api.IContactFilterCriteria = {
	property: Api.ContactFilterCriteriaProperty.WithPhoneNumber,
	op: Api.FilterOperator.Not,
};
const noAddressCriteria: Api.IContactFilterCriteria = {
	property: Api.ContactFilterCriteriaProperty.WithMailingAddress,
	op: Api.FilterOperator.Not,
};
const archivedCriteria: Api.IContactFilterCriteria = { property: Api.ContactFilterCriteriaProperty.Archived };

export const StatusFilterCheckboxesMap = {
	[EStatusOption.WithEmail]: { text: 'With Email', criteria: withEmailCriteria },
	[EStatusOption.WithPhoneNumber]: { text: 'With Phone Number', criteria: withPhoneNumberCriteria },
	[EStatusOption.WithAddress]: { text: 'With Address', criteria: withAddressCriteria },
	[EStatusOption.HaveAlertsDue]: { text: 'Have Alerts Due', criteria: haveAlertsDueCriteria },
	[EStatusOption.PrivateContacts]: { text: 'Private contacts', criteria: privateContactsCriteria },
	[EStatusOption.KeepInTouch]: { text: 'Keep in touch', criteria: keepInTouchCriteria },
	[EStatusOption.WithoutTags]: { text: 'Without Tags', criteria: withoutTagsCriteria },
	[EStatusOption.NoEmail]: { text: 'No Email', criteria: noEmailCriteria },
	[EStatusOption.WithoutPhoneNumber]: { text: 'No Phone Number', criteria: withoutPhoneNumberCriteria },
	[EStatusOption.NoAddress]: { text: 'No Address', criteria: noAddressCriteria },
	[EStatusOption.Archived]: { text: 'Archived', criteria: archivedCriteria },
};

export const useStatusFilters = ({
	filter,
	onChangeFilter,
}: {
	filter: Api.IBulkContactsRequest;
	onChangeFilter: (filter: Api.IBulkContactsRequest) => void;
}) => {
	const findMatchingStatus = (criterion: Api.IContactFilterCriteria) => {
		return Object.entries(StatusFilterCheckboxesMap).find(entry => {
			const value = entry[1];
			return criterion.property === value.criteria.property && criterion.op === value.criteria.op;
		});
	};

	const selectedStatuses: EStatusOption[] = filter.filter.criteria
		.filter(criterion => {
			const match = findMatchingStatus(criterion);
			return !!match;
		})
		.map(criterion => {
			const key = findMatchingStatus(criterion)[0];
			return parseInt(key, 10) as EStatusOption;
		});

	const isArchived = useCallback(() => {
		return !!filter.filter.criteria.find(x => x.property === Api.ContactFilterCriteriaProperty.Archived);
	}, [filter]);

	const getStatusCriteria = (_selectedStatuses: EStatusOption[]) => {
		return _selectedStatuses.map(statusOption => StatusFilterCheckboxesMap[statusOption].criteria);
	};

	const toggleStatusFilter = (statusOption: EStatusOption) => {
		const nextFilter = produce(filter, draftFilter => {
			if (selectedStatuses.indexOf(statusOption) > -1) {
				const criteriaToRemove = StatusFilterCheckboxesMap[statusOption].criteria;
				const match = draftFilter.filter.criteria.find(criterion => {
					return criterion.property === criteriaToRemove.property;
				});
				const index = draftFilter.filter.criteria.indexOf(match);
				draftFilter.filter.criteria.splice(index, 1);
			} else {
				const match = StatusFilterCheckboxesMap[statusOption];
				draftFilter.filter.criteria.push(match.criteria);
			}
		});

		onChangeFilter(nextFilter);
	};

	return {
		getStatusCriteria,
		isArchived,
		selectedStatuses,
		toggleStatusFilter,
	};
};
