import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import * as React from 'react';
import { isAdminUser } from '../../../api/Utils';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../models/AppState';
import { EventLogger } from '../../../models/Logging';
import { baseStyleSheet } from '../../styles/styles';
import { Modal } from '../Modal';
import { TrialExpiredGraphic } from '../../components/svgs/graphics/TrialExpiredGraphic';
import './styles.less';

class _TrialExpired extends React.PureComponent<IUserSessionComponentProps> {
	public componentDidMount() {
		EventLogger.logEvent({
			action: 'TrialExpired-Message',
			category: 'TrialExpired',
		});
	}

	public render() {
		const { userSession } = this.props;
		return (
			<Modal isOpen={true} shouldCloseOnOverlayClick={false}>
				<div className='trialExpired-innerContainer'>
					<figure>
						<TrialExpiredGraphic />
					</figure>
					<div className='trialExpired-headline'>Your trial has ended.</div>
					<div className={isAdminUser(userSession.user) ? 'trialExpired-subHeadline' : 'hide'}>
						Thanks for trying Levitate! All of your content has been preserved, but to continue to use Levitate you need
						to convert to a paid account. Click the button below to contact support and change your plan.
					</div>
					<div className={!isAdminUser(userSession.user) ? 'trialExpired-subHeadline' : 'hide'}>
						Thanks for trying Levitate! All of your content has been preserved, but to continue to use Levitate you need
						to convert to a paid account. Please reach out to the person at your company who signed up for Levitate to
						ask them to enter your company&apos;s billing information to unlock the account.
					</div>
					<div className={isAdminUser(userSession.user) ? 'trialExpired-buttonContainer' : 'hide'}>
						<a
							className={`${css(baseStyleSheet.ctaButton)} trial-contact-support-link`}
							href={`mailto:support@levitateapp.com?subject=${encodeURIComponent('Purchase Levitate')}`}
						>
							<span>Purchase Levitate</span>
						</a>
					</div>
				</div>
			</Modal>
		);
	}
}

export const TrialExpired = inject(UserSessionViewModelKey)(_TrialExpired);
