import { brandPrimary } from '../../styles/colors';
import { CssSelectors, baseStyles } from '../../styles/styles';
import { CSSProperties, StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	userSetupLoading: {
		background: brandPrimary,
		height: '100%',
		position: 'relative',
		...CssSelectors.allChildren(
			{
				...(baseStyles.absoluteCenter as CSSProperties),
			},
			'.loading-spinner'
		),
	},
});
