import { CoffeeCupGraphic } from '../../../../../aida/components/svgs/CoffeeCupGraphic';
import { baseStyleSheet } from '../../../../styles/styles';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	onClick?(): void;
}

export const CoffeeCard: React.FC<IProps> = props => {
	const { onClick } = props;
	return (
		<div className={css(styleSheet.container)}>
			<div className={css(styleSheet.wrapper)}>
				<CoffeeCupGraphic className={css(styleSheet.graphic)} />
				<button className={css(baseStyleSheet.ctaButtonSecondary, styleSheet.button)} onClick={onClick}>
					<span>Launch Coffee</span>
				</button>
			</div>
		</div>
	);
};
