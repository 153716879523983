import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import Waypoint from 'react-waypoint';
import { IAutomationInfo, SizeConstraint } from '../../../../models';
import { Topics } from '../../../../models/LocalNotificationTopics';
import { ILocalNotification } from '../../../../models/LocalNotifications';
import {
	convertRawRichTextContentStateToRichContentEditorState,
	createContentStateWithHtmlStringValue,
} from '../../../../models/UiUtils';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../ConfirmationDialog';
import { LoadingSpinner } from '../../LoadingSpinner';
import { LocalNotificationObserver } from '../../LocalNotificationObserver';
import { IMoreMenuItem } from '../../MoreMenu';
import { Placeholder } from '../../Placeholder';
import { Portal } from '../../Portal';
import { TimelineScheduledMeeting } from '../../TimelineScheduledMeeting';
import { ActionItemFeedCard } from '../../cards/ActionItemFeedCard';
import { DefaultCompactEditorConfig } from '../../notes/NoteEditor';
import { RichContentDocumentEditor } from '../../richContent/RichContentDocumentEditor';
import { NotesPlaceholderIcon } from '../../svgs/icons/NotesPlaceholderIcon';
import { WarningIcon } from '../../svgs/icons/WarningIcon';
import { EntityConversationContent } from '../EntityConversationContent';
import { EntityModalContent } from '../EntityModalContent';
import { TimelinePaginationData } from './hooks/useTimelineData';
import {
	EntityTimelineEventAction,
	EntityTimelineEventDateHeader,
	EntityTimelineEventIcon,
	EntityTimelineEventTitle,
	EntityTimelineNoteEventContent,
	EntityTimelineSatisfactionSurveyResponseEventContent,
} from './presentation';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	contact?: Api.IContact;
	onEntityClicked?(entity: Api.EntityViewModel<Api.IEntity>, e: React.MouseEvent<HTMLElement>): void;
	onMoreMenuOptionClicked?(
		richContent: Api.RichContentViewModel,
		option: 'edit' | 'delete',
		e: React.MouseEvent<HTMLElement>
	): void;
	readonly?: boolean;
	renderAction?(evt: Api.TimelineEventViewModel): JSX.Element;
	renderActionFor?: Api.TimelineEventTypes[];
	renderContent?(evt: Api.TimelineEventViewModel): JSX.Element;
	renderContentFor?: Api.TimelineEventTypes[];
	placeholderText?: string;
	scrollToBottomWaypointPortalId?: string;
	sendMessageDisabled?: boolean;
	sizeConstraint?: SizeConstraint;
	onAutomationEdited: (notification?: ILocalNotification<IAutomationInfo>) => void;
	onCreateOrUpdateNotificationReceived: (notification: ILocalNotification<Api.IActionItem>) => void;
	onDeleteContentConfirmationDialogRequestClose: (
		result?: IConfirmationDialogOption<boolean>,
		canceled?: boolean
	) => Promise<void>;
	onEmailsSent?: (notification: ILocalNotification<Api.EmailMessageViewModel>) => void;
	onMoreMenuItemClicked: (
		richContent: Api.RichContentViewModel,
		menuItem: IMoreMenuItem,
		e: React.MouseEvent<HTMLElement>
	) => void;
	onNoteCreateOrUpdateNotificationReceived: (notification: ILocalNotification<Api.INote>) => void;
	onTimelineRefreshNotificationReceived: () => void;
	showDeletingRichContentModal: Api.RichContentViewModel<Api.IRichContent>;
	timelineData: TimelinePaginationData;
}

function _Timeline({
	contact,
	className,
	sizeConstraint,
	placeholderText,
	renderAction,
	renderActionFor,
	readonly,
	renderContentFor,
	renderContent: renderContentProp,
	scrollToBottomWaypointPortalId,
	timelineData,
	onAutomationEdited,
	onCreateOrUpdateNotificationReceived,
	onDeleteContentConfirmationDialogRequestClose,
	onEmailsSent,
	onMoreMenuItemClicked,
	onNoteCreateOrUpdateNotificationReceived,
	onTimelineRefreshNotificationReceived,
	showDeletingRichContentModal,
}: IProps) {
	const renderBottomWaypoint = () => {
		const waypoint = <Waypoint bottomOffset='-600px' onEnter={() => timelineData.fetchNextPage()} />;
		if (scrollToBottomWaypointPortalId) {
			return <Portal destination={scrollToBottomWaypointPortalId}>{waypoint}</Portal>;
		}
		return waypoint;
	};

	const renderContent = (event: Api.TimelineEventViewModel) => {
		switch (event.type) {
			case 'ReceivedEmailEvent':
			case 'ViewedEmailEvent':
			case 'RepliedEmailEvent':
			case 'HtmlNewsletterEvent':
			case 'SentEmailEvent': {
				const evt = event as Api.SentEmailEventViewModel;
				if (evt.viewmodel?.rawContentState) {
					return <EntityModalContent event={evt} />;
				}
				return null;
			}
			case 'DealCreatedEvent':
			case 'DealUpdatedEvent': {
				const noteEvt = event as Api.NoteEventViewModel;
				if (noteEvt.viewmodel.rawContentState) {
					return (
						<>
							<RichContentDocumentEditor
								autoFocus={false}
								className='note-editor-body-editor'
								config={DefaultCompactEditorConfig}
								contentState={convertRawRichTextContentStateToRichContentEditorState(noteEvt.viewmodel.rawContentState)}
								readOnly={true}
							/>
							{/* @ts-ignore */}
							<TimelineScheduledMeeting scheduledMeeting={event?.scheduledMeeting} />
						</>
					);
				}

				// @ts-ignore
				return <TimelineScheduledMeeting scheduledMeeting={event?.scheduledMeeting} />;
			}
			case 'NoteEvent': {
				const note = event as Api.NoteEventViewModel;
				if (note.viewmodel.rawContentState) {
					return <EntityTimelineNoteEventContent note={note} />;
				}
				return null;
			}
			case 'PhoneCallCompletedEvent':
			case 'PhoneCallEvent':
			case 'UntrackedPhoneCallEvent':
			case 'SkipLeadEvent': {
				const note = event as Api.NoteEventViewModel;
				if (note.viewmodel.rawContentState) {
					return (
						<RichContentDocumentEditor
							autoFocus={false}
							className='note-editor-body-editor'
							config={DefaultCompactEditorConfig}
							contentState={convertRawRichTextContentStateToRichContentEditorState(note.viewmodel.rawContentState)}
							readOnly={true}
						/>
					);
				}
				return null;
			}
			case 'ActionItemEvent': {
				const vm = (event as Api.ActionItemEventViewModel).viewmodel;
				return <ActionItemFeedCard actionItem={vm} moreMenuDisabled={true} />;
			}
			case 'ConversationThreadEvent': {
				const conversation = event as Api.ConversationThreadEventViewModel;
				return <EntityConversationContent event={conversation} entity={contact} />;
			}
			case 'SatisfactionSurveyResponseEvent': {
				const satisfactionSurveyResponseEvent = event as Api.SatisfactionSurveyResponseEventViewModel;
				return <EntityTimelineSatisfactionSurveyResponseEventContent event={satisfactionSurveyResponseEvent} />;
			}
			case 'HandwrittenCardOrderEvent': {
				const cardOrder = event as Api.HandwrittenCardOrderEventViewModel;
				const signature = cardOrder.cardSignature ? `<p>${cardOrder.cardSignature}</p>` : '';
				const content = `<p>${cardOrder.cardContent}</p>${signature}`;
				return (
					<RichContentDocumentEditor
						autoFocus={false}
						className='note-editor-body-editor'
						config={DefaultCompactEditorConfig}
						contentState={createContentStateWithHtmlStringValue(content)}
						readOnly
					/>
				);
				return null;
			}
			case 'MeetingEvent':
			case 'MeetingScheduledEvent':
			case 'ExternalSentEmailEvent':
			default: {
				return null;
			}
		}
	};

	const renderEvent = (event: Api.TimelineEventViewModel | Date) => {
		if (!(event instanceof Api.TimelineEventViewModel)) {
			return <EntityTimelineEventDateHeader date={event} key={event.toUTCString()} />;
		}
		return (
			<div className={css(styleSheet.eventContainer)} key={event.id} data-id={event.id}>
				<div className={css(styleSheet.eventTime)}>{moment(event.timestamp).format('h:mm A')}</div>
				<div className={css(styleSheet.eventIconContainer)}>
					<div className={css(styleSheet.eventIcon)}>
						<EntityTimelineEventIcon event={event} />
					</div>
					<div className={css(styleSheet.eventFillLine)} />
				</div>
				<div className={css(styleSheet.eventBodyContainer)}>
					<div className={css(styleSheet.eventTitleContainer)}>
						<EntityTimelineEventTitle event={event} />
						<div className={css(styleSheet.eventAccessory)}>
							{!!renderAction && renderActionFor?.includes(event.type) ? (
								renderAction(event)
							) : (
								<EntityTimelineEventAction
									event={event}
									onMoreMenuItemClicked={onMoreMenuItemClicked}
									readonly={readonly}
								/>
							)}
						</div>
					</div>
					<div className={css(styleSheet.eventContent)}>
						{!!renderContentProp && renderContentFor?.includes(event.type)
							? renderContentProp(event)
							: renderContent(event)}
					</div>
				</div>
			</div>
		);
	};

	const renderData = () => {
		if (timelineData.status === 'loading') {
			return <LoadingSpinner type='large' />;
		}
		if (timelineData.status === 'success') {
			return (
				<>
					{/* @ts-ignore */}
					{timelineData.data.pages[0].values.length === 0 ? (
						<Placeholder
							isEmpty={true}
							loaderType={sizeConstraint === 'compact' ? 'small' : 'large'}
							message={placeholderText || "You haven't added any notes or reminders yet"}
							placeholderIcon={<NotesPlaceholderIcon />}
						/>
					) : null}
					{/* @ts-ignore */}
					{timelineData.data.pages.map(group =>
						group.values.map(item => {
							return renderEvent(item);
						})
					)}
					{timelineData.hasNextPage && !timelineData.isFetchingNextPage ? renderBottomWaypoint() : null}
					<LocalNotificationObserver
						topic={Topics.CREATE_ACTION_ITEM}
						onNotificationReceived={onCreateOrUpdateNotificationReceived}
					/>
					<LocalNotificationObserver
						topic={Topics.EDIT_ACTION_ITEM}
						onNotificationReceived={onCreateOrUpdateNotificationReceived}
					/>
					<LocalNotificationObserver
						topic={Topics.TIMELINE_REFRESH}
						onNotificationReceived={onTimelineRefreshNotificationReceived}
					/>
					<LocalNotificationObserver
						topic={Topics.CREATE_NOTE}
						onNotificationReceived={onNoteCreateOrUpdateNotificationReceived}
					/>
					<LocalNotificationObserver
						topic={Topics.EDIT_NOTE}
						onNotificationReceived={onNoteCreateOrUpdateNotificationReceived}
					/>
					{onEmailsSent ? (
						<LocalNotificationObserver topic={Topics.SEND_EMAIL} onNotificationReceived={onEmailsSent} />
					) : null}
					<LocalNotificationObserver topic={Topics.CREATE_AUTOMATION} onNotificationReceived={onAutomationEdited} />
					<LocalNotificationObserver topic={Topics.DELETE_AUTOMATION} onNotificationReceived={onAutomationEdited} />
					<ConfirmationDialog
						icon={<WarningIcon />}
						modalProps={{
							isOpen: !!showDeletingRichContentModal,
							onRequestClose: onDeleteContentConfirmationDialogRequestClose,
						}}
						options={[
							{
								isDestructive: true,
								representedObject: true,
								title: 'Delete',
							},
							{
								isCancel: true,
								representedObject: false,
								title: 'Cancel',
							},
						]}
						title={`Are you sure you want to delete this ${
							showDeletingRichContentModal?.toJs()?._type === 'ActionItem' ? 'action item' : 'note'
						}?`}
					/>
				</>
			);
		}
	};
	return <ul className={`${css(styleSheet.container)} entity-rich-content ${className || ''}`}>{renderData()}</ul>;
}

export const Timeline = observer(_Timeline);
