import * as React from 'react';
import Measure, { ContentRect } from 'react-measure';
import { animated, useSpring } from 'react-spring-legacy';
import DisclosureDownIconUrl from '../../../assets/icon_downArrow.svg';
import './styles.less';

interface IProps {
	className?: string;
	description?: React.ReactNode;
	expanded?: boolean;
	footer?: React.ReactNode;
	icon: React.ReactNode;
	name: React.ReactNode;
	onToggleExpansion?(expanded: boolean): void;
	showHeader?: boolean;
	staticSetting?: boolean;
}

export const SettingsGroup: React.FC<IProps> = ({
	children,
	className,
	description,
	expanded,
	footer,
	icon,
	name,
	onToggleExpansion,
	showHeader = true,
	staticSetting,
}) => {
	const [isExpanded, setIsExpanded] = React.useState(expanded);

	const [contentHeight, setContentHeight] = React.useState<number>(0);
	const styles = useSpring({ height: isExpanded ? contentHeight : 120 });

	const onToggleExpansionCallback = (expand: boolean) => () => {
		setIsExpanded(expand);
		onToggleExpansion?.(expand);
	};

	const onContentResize = React.useCallback((contentRect: ContentRect) => {
		// @ts-ignore
		setContentHeight(contentRect.client.height);
	}, []);
	return (
		<animated.div
			style={staticSetting ? { ...styles, background: 'transparent', border: 0, boxShadow: 'none' } : { ...styles }}
			className={`settings-group ${isExpanded ? 'settings-group-expanded' : ''} ${className || ''}`}
		>
			<Measure client={true} onResize={onContentResize}>
				{({ measureRef }) => {
					return (
						<div ref={measureRef}>
							{showHeader ? (
								<div
									className='settings-group-header'
									style={{ cursor: staticSetting ? 'default' : 'pointer' }}
									onClick={staticSetting ? undefined : onToggleExpansionCallback(!isExpanded)}
								>
									<div className='settings-group-header-icon'>{icon}</div>
									<div className='settings-group-header-content'>
										<div className='settings-group-name'>{name}</div>
										{!!description && <div className='settings-group-description'>{description}</div>}
									</div>
									{!staticSetting ? (
										<span className='settings-group-header-disclosure'>
											<img src={DisclosureDownIconUrl} />
										</span>
									) : null}
								</div>
							) : null}

							<div className='settings-group-body'>
								<div className='settings-group-body-content'>
									{children}
									{footer && <footer className='settings-group-footer'>{footer}</footer>}
								</div>
							</div>
						</div>
					);
				}}
			</Measure>
		</animated.div>
	);
};
