import { brandPrimary, brandPrimaryHover } from '../../../styles/colors';
import { CtaButtons } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

const iconSize = 46;

export const styleSheet = StyleSheet.create({
	body: {
		alignItems: 'center',
		color: '#fff',
		display: 'flex',
		flexWrap: 'wrap',
		fontSize: 14,
		marginTop: 14,
	},
	bodyCampaignName: {
		maxWidth: '40%',
	},
	container: {},
	footer: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: 14,
	},
	header: {
		alignItems: 'center',
		display: 'flex',
		fontSize: 18,
	},
	headerIcon: {
		height: iconSize,
		width: iconSize,
	},
	headerTitle: {
		alignItems: 'center',
		color: '#fff',
		display: 'flex',
		width: `calc(100% - ${66 + 20}px)`,
	},
	headerTitleMessage: {
		flexShrink: 0,
	},
	headerTitleName: {
		marginLeft: 20,
		maxWidth: '40%',
	},
	item: {
		':not(:first-child)': {
			marginTop: 15,
		},
		background: `linear-gradient(to bottom, ${brandPrimaryHover}, ${brandPrimary})`,
	},
	reviewAndApproveButton: {
		...CtaButtons.ctaButtonReverseSmall,
		boxShadow: 'none',
		color: brandPrimaryHover,
	},
});
