import { StyleSheet } from 'aphrodite';
import { brandPrimaryText, darkGrayFontColor, navigation, titles } from '../../../styles/colors';
import { CssSelectors, DefaultBorder } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	body: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: 20,
		...CssSelectors.allChildren(
			{
				borderTop: DefaultBorder,
				marginTop: 18,
				paddingTop: 18,
			},
			':not(:first-child)'
		),
	},
	contactInfo: {
		color: titles,
		fontSize: 14,
		...CssSelectors.allChildren(
			{
				marginBottom: 20,
			},
			':not(:last-child)'
		),
	},
	contactInfoHeader: {
		color: navigation,
		...CssSelectors.allChildren(
			{
				marginTop: 15,
			},
			':not(:first-of-type)'
		),
	},
	container: {
		minHeight: 500,
		position: 'relative',
	},
	editButton: {
		color: brandPrimaryText,
		fontSize: 14,
		position: 'absolute',
		right: 30,
		top: 10,
	},
	header: {
		marginTop: 0,
		...CssSelectors.allChildren(
			{
				marginTop: 10,
			},
			':not(:first-child)'
		),
	},
	headerBio: {
		color: darkGrayFontColor,
		fontSize: 14,
	},
	headerTitle: {
		color: titles,
		fontSize: 24,
		maxWidth: '60%',
	},
	loading: {
		marginTop: 30,
	},
	socialPrifles: {
		display: 'flex',
		minHeight: 20,
	},
	websiteLink: {
		':hover': {
			textDecoration: 'underline',
		},
		textDecoration: 'none',
	},
});
