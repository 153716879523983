import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	button: {
		':focus': {
			backgroundColor: '#046CB6',
			color: 'rgb(243,248,251)',
		},
		backgroundColor: 'rgb(243,248,251)',
		borderRadius: 3,
		color: '#046CB6',
		display: 'block',
		margin: '0 auto',
		padding: '11px 16px',
		textAlign: 'center',
		width: '100%',
	},
	card: {
		background: '#fff',
		flexDirection: 'column',
		minWidth: 278,
		padding: '23px 38px 22px 19px',
	},
	content: {
		margin: '-40px 0 0 0',
	},
	description: {
		color: 'rgba(142,142,142,1)',
		fontSize: 14,
		fontStyle: 'normal',
		fontWeight: 400,
		letterSpacing: 0,
		margin: '0 0 14px 0',
		paddingLeft: 13,
		textAlign: 'center',
	},
	heading: {
		color: 'rgba(13,15,17,1)',
		fontSize: 16,
		fontStyle: 'normal',
		fontWeight: 400,
		letterSpacing: 0,
		lineHeight: '22px',
		margin: '0 0 4px 0',
		opacity: 1,
		textAlign: 'center',
	},
	iconWrap: {
		alignItems: 'center',
		backgroundColor: '#CAE0EC',
		borderRadius: 12,
		display: 'flex',
		height: 40,
		justifyContent: 'center',
		marginRight: 13,
		width: 40,
	},
});
