import { StyleSheet } from 'aphrodite';
import { actionItemTintColor, brandPrimaryText, titles } from '../../../styles/colors';
import { CssSelectors, DefaultBorder } from '../../../styles/styles';

const horizontalPadding = 20;
const dialogHeaderHeight = 35;

export const styleSheet = StyleSheet.create({
	body: {
		display: 'flex',
		height: `calc(100% - ${dialogHeaderHeight}px)`,
	},
	composer: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: '52%',
	},
	composerBody: {
		boxSizing: 'border-box',
		flexGrow: 1,
		overflowY: 'auto',
		padding: `${horizontalPadding}px ${horizontalPadding}px 0 ${horizontalPadding}px`,
		position: 'relative',
		width: '100%',
	},
	composerBodyContent: {
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: 20,
			},
			':not(:first-child)'
		),
	},
	composerEditor: {
		width: 'calc(100% - 38px)',
	},
	composerFooter: {
		boxSizing: 'border-box',
		display: 'flex',
		padding: horizontalPadding,
		width: '100%',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	composerHeader: {
		borderBottom: '1px solid #D8D8D8',
		boxSizing: 'border-box',
		marginTop: 10,
		padding: horizontalPadding,
		width: '100%',
	},
	composerHeaderTitle: {
		color: titles,
		fontSize: 18,
	},
	container: {
		'@media only screen and (min-width: 1024px)': {
			width: 900,
		},
		width: 700,
	},
	context: {
		borderLeft: DefaultBorder,
		boxSizing: 'border-box',
		height: '100%',
		width: '48%',
	},
	header: {
		alignItems: 'center',
		background: actionItemTintColor,
		boxSizing: 'border-box',
		display: 'flex',
		height: dialogHeaderHeight,
		justifyContent: 'space-between',
		padding: `0 ${horizontalPadding}px`,
	},
	headerIcon: {
		height: 18,
		width: 18,
	},
	modal: {
		...CssSelectors.allChildren(
			{
				...CssSelectors.allChildren(
					{
						borderRadius: 4,
						boxSizing: 'border-box',
						overflow: 'hidden',
						padding: 0,
						...CssSelectors.allChildren(
							{
								'@media only screen and (min-height: 1024px)': {
									height: 740,
								},
								'@media only screen and (min-height: 768px)': {
									height: 540,
								},
								height: 500,
							},
							'.action-item-composer'
						),
					},
					'.modal-overlay-content'
				),
			},
			'.modal-overlay'
		),
	},
	noteHeader: {
		marginBottom: 20,
	},
	option: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
	},
	optionButton: {
		alignItems: 'center',
		color: '#858585',
		display: 'flex',
		fontSize: 12,
		justifyContent: 'center',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	optionButtonSelection: {
		color: brandPrimaryText,
	},
	optionRemoveButton: {
		color: brandPrimaryText,
	},
	options: {
		borderTop: '1px solid #D8D8D8',
		boxSizing: 'border-box',
		padding: horizontalPadding,
		...CssSelectors.allChildren(
			{
				marginTop: 24,
			},
			':not(:first-child)'
		),
	},
	visibilityDropdown: {
		marginTop: 10,
	},
});
