import * as Api from '@ViewModels';
import { ContactFilterCriteriaProperty, IContactFilterCriteria } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import equal from 'fast-deep-equal';
import produce from 'immer';
import * as React from 'react';
import { AssigneeIcon } from '../../../../components/svgs/icons/AssigneeIcon';
import { CompaniesIcon } from '../../../../components/svgs/icons/CompaniesIcon';
import { SearchIcon } from '../../../../components/svgs/icons/SearchIcon';
import { TagIcon } from '../../../../components/svgs/icons/TagIcon';
import { brandPrimary, header } from '../../../../styles/colors';
import { baseStyleSheet } from '../../../../styles/styles';
import {
	ContactsPagePeopleSearchTab,
	DefaultPeopleFilter,
	getCriteriaFromTab,
	getSearchFromCriteria,
} from '../PeopleContext';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	search: string;
	searchTab: ContactsPagePeopleSearchTab;
	filter: Api.IBulkContactsRequest;
	onFilterChange: (filter: Api.IBulkContactsRequest) => void;
	onClear: () => void;
}

export const PeopleSearchBarTypeTabs: React.FC<IProps> = ({
	search,
	className,
	styles,
	searchTab,
	filter,
	onFilterChange,
	onClear,
}) => {
	const onTabClick = (newTab: ContactsPagePeopleSearchTab) => () => {
		const nextFilter = produce(filter, draftFilter => {
			const newCriteria = draftFilter.filter.criteria.filter(x => {
				return (
					x.property !== ContactFilterCriteriaProperty.All &&
					x.property !== ContactFilterCriteriaProperty.Company &&
					x.property !== ContactFilterCriteriaProperty.Name &&
					x.property !== ContactFilterCriteriaProperty.Tag
				);
			});

			const criterion: IContactFilterCriteria = {
				property: getCriteriaFromTab(newTab),
			};
			if (search) {
				criterion.value = search;
			}

			newCriteria.push(criterion);
			draftFilter.filter.criteria = newCriteria;
		});

		onFilterChange(nextFilter);
	};
	const searched = getSearchFromCriteria(filter.filter.criteria);
	const hasSearched = !equal(DefaultPeopleFilter, filter);
	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
			<div className={css(styleSheet.tabContainer, !searched && baseStyleSheet.hidden)}>
				<FakeTab onClick={onTabClick} tab='All' selectedTab={searchTab}>
					<SearchIcon className={css(styleSheet.tabIcon)} fillColor={searchTab === 'All' ? brandPrimary : header} />
				</FakeTab>
				<FakeTab onClick={onTabClick} tab='Name' selectedTab={searchTab}>
					<AssigneeIcon
						className={css(styleSheet.tabIcon)}
						fillColor={searchTab === 'Name' ? brandPrimary : header}
						strokeWidth='1.5'
					/>
				</FakeTab>
				<FakeTab onClick={onTabClick} tab='Tag' selectedTab={searchTab}>
					<TagIcon className={css(styleSheet.tabIcon)} fillColor={searchTab === 'Tag' ? brandPrimary : header} />
				</FakeTab>
				<FakeTab onClick={onTabClick} tab='Company' selectedTab={searchTab}>
					<CompaniesIcon
						className={css(styleSheet.tabIcon)}
						stroke={searchTab === 'Company' ? brandPrimary : header}
						strokeWidth='1.5'
					/>
				</FakeTab>
			</div>
			{hasSearched && (
				<div className={css(baseStyleSheet.brandLink, styleSheet.clear)} onClick={onClear}>
					Clear search
				</div>
			)}
		</div>
	);
};

interface ITabProps {
	onClick(newTab: ContactsPagePeopleSearchTab): () => void;
	tab: ContactsPagePeopleSearchTab;
	selectedTab: ContactsPagePeopleSearchTab;
}

const FakeTab: React.FC<ITabProps> = ({ onClick, children, tab, selectedTab }) => {
	return (
		<div className={css(styleSheet.tab, tab === selectedTab && styleSheet.tabSelected)} onClick={onClick(tab)}>
			{children}
			<span className={css(styleSheet.tabText, tab === selectedTab && styleSheet.tabTextSelected)}>{tab}</span>
		</div>
	);
};
