import { brandSecondary } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const ReferencedContactsIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`referenced-contacts-icon ${className || ''}`} height={48} width={54}>
			<g fill='none' fillRule='evenodd' transform='translate(2)'>
				<g
					stroke={fillColor || brandSecondary}
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth='3'
					transform='translate(0 13)'
				>
					<path d='M29.0909091,32.6666667 L29.0909091,29.1111111 C29.0909091,25.1837529 25.8347982,22 21.8181818,22 L7.27272727,22 C3.25611091,22 4.03717464e-16,25.1837529 0,29.1111111 L0,32.6666667' />
					<ellipse cx='15.273' cy='7.111' rx='7.273' ry='7.111' />
					<path d='M40,32 L40,28.4444444 C39.9975278,25.2037649 37.7545432,22.3746137 34.5454545,21.5644444 M27.2727273,0.231111111 C30.4907542,1.03674629 32.7415332,3.87198426 32.7415332,7.12 C32.7415332,10.3680157 30.4907542,13.2032537 27.2727273,14.0088889' />
				</g>
				<polygon
					fill={fillColor || brandSecondary}
					points='46.4 0 43.6 0 43.6 5.6 38 5.6 38 8.4 43.6 8.4 43.6 14 46.4 14 46.4 8.4 52 8.4 52 5.6 46.4 5.6'
				/>
			</g>
		</SvgIcon>
	);
};
