import { ErrorMessagesViewModelKey } from '../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../models/Logging';
import { LevitateAppShell } from '../AppShell';
import { Button } from '../Button';
import { WarningIcon } from '../svgs/icons/WarningIcon';
import { styleSheet } from './styles';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

interface IProps extends IEventLoggingComponentProps, RouteComponentProps<any> {
	className?: string;
	styles?: StyleDeclarationValue[];
}

interface IState {
	hasError: boolean;
}

class _ErrorBoundary extends React.Component<IProps, IState> {
	public readonly state: IState = { hasError: false };

	public static getDerivedStateFromError() {
		return { hasError: true };
	}

	public componentDidCatch(error: Error) {
		const { logError } = this.props;
		// @ts-ignore
		logError('ErrorBoundary-Error', error);
	}

	private onGoToDash = () => {
		const { history } = this.props;
		history.push('/dashboard');
		this.setState({ hasError: false });
	};

	public render() {
		const { className, styles } = this.props;
		const { hasError } = this.state;

		if (hasError) {
			return (
				<LevitateAppShell>
					<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
						<div>
							<WarningIcon />
							<div className={css(styleSheet.title)}>An error occurred</div>
							<div className={css(styleSheet.description)}>Please return to the dashboard or refresh the page.</div>
							<Button className={css(styleSheet.button)} label='Go to dashboard' onClick={this.onGoToDash} />
						</div>
					</div>
				</LevitateAppShell>
			);
		}

		return this.props.children;
	}
}

const ErrorBoundaryAsObserver = observer(_ErrorBoundary);
const ErrorBoundaryWithLogging = withEventLogging(ErrorBoundaryAsObserver, 'Crash');
const ErrorBoundaryWithRouter = withRouter(ErrorBoundaryWithLogging);
export const ErrorBoundary = inject(ErrorMessagesViewModelKey)(ErrorBoundaryWithRouter);
