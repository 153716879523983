import { StyleSheet } from 'aphrodite';
import { alternateTitleColor, label } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	header: {
		color: alternateTitleColor,
		textAlign: 'center',
		fontSize: 18,
		marginBottom: '1.5rem',
	},
	image: {
		display: 'block',
		marginBottom: '1.5rem',
		width: 136,
	},
	imageWrapper: {
		display: 'flex',
		justifyContent: 'center',
		marginLeft: '-1rem',
	},
	description: {
		color: label,
		fontSize: 14,
		textAlign: 'center',
		width: 400,
	},
});
