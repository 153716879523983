import { StyleSheet } from 'aphrodite';
import { BreakPoints, CssSelectors } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	container: {},
	item: {
		margin: '15px 0',
	},
	focus: {
		'--lev-people-action-bar-button-hover-shadow': 'rgba(234,129,96, 0.4)',
	},
	focusButtonContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	button: {
		opacity: 1,
		transform: 'translate3d(0,0,0)',
		transition: 'opacity 0.2s, transform 0.2s',
		...CssSelectors.allChildren(
			{
				alignItems: 'center',
				background: 'none',
				borderRadius: 20,
				boxShadow: 'none',
				display: 'flex',
				marginRight: 12,
				padding: '8px 10px',
				transform: 'translate3d(0,0,0)',
				transition: 'background 0.2s, box-shadow 0.2s, transform 0.1s',
				[BreakPoints.forMaxWidth(1080)]: {
					alignItems: 'center',
					flexDirection: 'column',
					justifyContent: 'center',
					marginRight: 2,
				},
				':hover': {
					boxShadow: `0 1px 2px 1px  var(--lev-people-action-bar-button-hover-shadow)`,
				},
			},
			'span'
		),
	},
	buttonText: {
		marginLeft: 6,
		[BreakPoints.forMaxWidth(1080)]: {
			display: 'none',
		},
	},
});
