import * as Api from '@ViewModels';
import { useUserSession } from '../../../../../models/hooks/appStateHooks';
import { useTelephonyConfigurationQuery } from '../../../../../queries';

export function useTextingRegistrationStatus(): {
	isLoading: boolean;
	campaignStatus: Api.CampaignRegistrationStatus | null;
	brandStatus: Api.TextingBrandStatus | null;
	forceAllowClientProfileEdit: boolean;
} {
	const userSession = useUserSession();
	const configurationQuery = useTelephonyConfigurationQuery();
	const accountTelephonyProvider = userSession.account.features?.texting?.provider;
	if (!accountTelephonyProvider) {
		return {
			isLoading: false,
			campaignStatus: null,
			brandStatus: null,
			forceAllowClientProfileEdit: false,
		} as const;
	}
	if (!configurationQuery.isSuccess) {
		return {
			isLoading: true,
			campaignStatus: null,
			brandStatus: null,
			forceAllowClientProfileEdit: false,
		} as const;
	}
	return {
		isLoading: false,
		brandStatus: configurationQuery.data?.campaignRegistration?.brand?.status ?? null,
		campaignStatus:
			configurationQuery.data?.campaignRegistration?.telephonyProviderCampaignStatuses?.[accountTelephonyProvider] ??
			null,
		forceAllowClientProfileEdit: configurationQuery.data?.forceAllowClientProfileEdit ?? false,
	};
}
