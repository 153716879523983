import { background, borderColor, darkGrayFontColor, header, mention, titles } from '../../../../styles/colors';
import { BreakPoints } from '../../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const contactTable = {
	connection: {
		flexGrow: 1,
		margin: '0 10px',
		width: 140,
		[BreakPoints.forMaxWidth(1080)]: {
			display: 'none',
		},
	},
	domain: {
		flexGrow: 1,
		margin: '0 10px',
		width: 140,
		[BreakPoints.forMaxWidth(1080)]: {
			display: 'none',
		},
	},
	name: {
		flexGrow: 1,
		margin: '0 10px 0 20px',
		width: 160,
	},
	tags: {
		flexGrow: 1,
		margin: '0 10px',
		width: 140,
	},
};

export const styleSheet = StyleSheet.create({
	avatar: {
		width: 50,
	},
	connections: {
		...contactTable.connection,
	},
	container: {
		background,
	},
	domain: {
		...contactTable.domain,
	},
	name: {
		...contactTable.name,
	},
	placeholder: {
		marginTop: 60,
	},
	selectAll: {
		paddingLeft: 28,
		width: 60,
	},
	selectAllText: {
		fontSize: 12,
	},
	sortIcon: {
		marginLeft: 6,
	},
	sortIconFlipped: {
		transform: 'rotate3d(0,0,1, 180deg)',
	},
	sortMenu: {
		backgroundColor: 'white',
		border: `1px solid ${borderColor}`,
		borderRadius: 3,
		boxShadow: `0px 2px 4px 0px ${borderColor}`,
		color: titles,
		fontSize: 14,
		margin: '7px 0px',
		position: 'absolute',
		width: '160px ',
	},
	sortMenuItem: {
		':hover': {
			backgroundColor: borderColor,
			cursor: 'pointer',
		},

		':not(:last-child)': {
			borderBottom: '1px solid #f0eded',
		},
		color: header,
		cursor: 'pointer',
		padding: '8px 12px',
	},
	tableBody: {
		'::-webkit-scrollbar': {
			background,
			width: 10,
		},
		'::-webkit-scrollbar-thumb': {
			backgroundColor: '#D8D8D8',
			borderRadius: 10,
			padding: '0 2px',
			width: 6,
		},
		boxSizing: 'border-box',
		height: 'calc(100vh - 343px)',
		overflowX: 'hidden',
		overflowY: 'auto',
	},
	tableHeader: {
		alignItems: 'center',
		background: mention,
		borderBottom: `1px solid ${borderColor}`,
		color: darkGrayFontColor,
		display: 'flex',
		fontSize: 12,
		height: 30,
		textTransform: 'uppercase',
	},
	tableHeaderElement: {
		fontSize: 12,
	},
	tags: {
		...contactTable.tags,
	},
	visibility: {
		alignItems: 'center',
		display: 'flex',
		margin: '0 15px',
		width: 15,
	},
});
