import * as React from 'react';
import * as Api from '@ViewModels';
import { DeleteConfirmation } from '../../../components/DeleteConfirmation';
import { useDeleteDonationMutation, useArchiveInsurancePolicyMutation } from '../../../../queries';
import { useToaster } from '../../../../models/hooks/appStateHooks';

export enum DeleteType {
	Policy = 'policy',
	Donation = 'donation',
}

export const DeleteModal = ({
	item,
	isOpen,
	setIsOpen,
	onConfirm,
	type,
}: {
	item: Api.IDonation | Api.IInsurancePolicy;
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onConfirm: () => void;
	type: DeleteType;
}) => {
	const [isDeleting, setIsDeleting] = React.useState(false);
	const toaster = useToaster();

	const deleteDonationMutation = useDeleteDonationMutation({
		onSuccess: () => {
			toaster.push({
				message: 'Donation successfully deleted',
				type: 'successMessage',
			});
			onConfirm();
		},
	});
	const archivePolicyMutation = useArchiveInsurancePolicyMutation({
		onSuccess: () => {
			toaster.push({
				message: 'Policy successfully archived',
				type: 'successMessage',
			});
			onConfirm();
		},
	});

	const confirm = async () => {
		if (type === DeleteType.Donation) {
			await deleteDonationMutation.mutateAsync({
				donationId: item.id,
			});
		} else if (DeleteType.Policy) {
			await archivePolicyMutation.mutateAsync({
				policyId: item.id,
			});
		}
		setIsDeleting(false);
	};

	const cancel = () => {
		setIsOpen(false);
		setIsDeleting(false);
	};

	const onFinish = (confirmDelete: boolean) => {
		setIsDeleting(true);
		if (confirmDelete) {
			confirm();
		} else {
			cancel();
		}
	};

	const message = `Are you sure you want to delete this ${type}?`;

	return <DeleteConfirmation bodyText={message} isOpen={isOpen} onFinish={onFinish} showSpinner={isDeleting} />;
};
