import { getDisplayName } from '../../../../models/UiUtils';
import { ISelectOption, Select } from '../../Select';
import { UserSelectModal } from '../../UserSelectModal';
import { styleSheet } from './styles';
import { IUser, UserViewModel } from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';

export enum ActionItemsFilterValue {
	All = 'All',
	CurrentUser = 'CurrentUser',
	OwnedBy = 'OwnedBy',
}

interface IProps {
	onFilterValueChanged?(value: ActionItemsFilterValue, user?: IUser): void;
	selectedFilterValue?: ActionItemsFilterValue;
}

interface IState {
	selectedEmployee?: IUser;
	selectedFilterValue?: ActionItemsFilterValue;
	showSelectEmployeeModal?: boolean;
}

export class ActionItemsFilter extends React.Component<IProps, IState> {
	public readonly state: IState = {
		selectedFilterValue: this.props.selectedFilterValue || ActionItemsFilterValue.CurrentUser,
	};

	private options: ISelectOption<ActionItemsFilterValue>[] = [
		{
			dataContext: ActionItemsFilterValue.All,
			id: 'action-item-filter-option-show-all',
			text: 'Show all',
		},
		{
			dataContext: ActionItemsFilterValue.CurrentUser,
			id: 'action-item-filter-option-current-user',
			text: 'Show my action items',
		},
		{
			dataContext: ActionItemsFilterValue.OwnedBy,
			id: 'action-item-filter-option-owned-by',
			text: 'Show action items assigned to...',
		},
	];

	public static getDerivedStateFromProps(props: IProps) {
		const { selectedFilterValue } = props;
		return selectedFilterValue ? { selectedFilterValue } : {};
	}

	public render() {
		const { selectedFilterValue, showSelectEmployeeModal } = this.state;

		return (
			<>
				<Select
					styles={[styleSheet.actionItemsFilter]}
					dropdownStyles={[styleSheet.actionItemsFilter]}
					onOptionClick={this.onSelectedFilterValueChanged}
					options={this.options}
					selectedOption={
						this.options.find(option => option.dataContext === selectedFilterValue) ||
						this.options.find(option => option.dataContext === ActionItemsFilterValue.CurrentUser)
					}
					selectedOptionTitle={this.renderSelectTrigger}
				/>
				<UserSelectModal
					bodyText='Filter action items by those owned by an employee.'
					className={`${css(styleSheet.selectAnEmployeeModal)}`}
					modalProps={{
						// @ts-ignore
						isOpen: showSelectEmployeeModal,
						onRequestClose: this.onSelectAnEmployeeClose,
						shouldCloseOnOverlayClick: true,
					}}
					title='Select an Employee'
				/>
			</>
		);
	}

	private onSelectAnEmployeeClose = (user?: UserViewModel, cancelled?: boolean) => {
		const { onFilterValueChanged } = this.props;
		const nextState: IState = { showSelectEmployeeModal: false };

		if (cancelled) {
			// @ts-ignore
			nextState.selectedEmployee = null;
			nextState.selectedFilterValue = ActionItemsFilterValue.CurrentUser;
			// @ts-ignore
			onFilterValueChanged?.(ActionItemsFilterValue.CurrentUser, null);
		} else {
			nextState.selectedEmployee = user;
			onFilterValueChanged?.(ActionItemsFilterValue.OwnedBy, nextState.selectedEmployee);
		}

		this.setState(nextState);
	};

	private onSelectedFilterValueChanged = ({ dataContext }: ISelectOption<ActionItemsFilterValue>) => {
		const showSelectEmployeeModal = dataContext === ActionItemsFilterValue.OwnedBy;

		this.setState({
			// @ts-ignore
			selectedEmployee: dataContext === ActionItemsFilterValue.OwnedBy ? this.state.selectedEmployee : null,
			selectedFilterValue: dataContext,
			showSelectEmployeeModal,
		});

		if (!showSelectEmployeeModal) {
			this.props.onFilterValueChanged?.(dataContext);
		}
	};

	private renderSelectTrigger = ({ dataContext, text }: ISelectOption<ActionItemsFilterValue>) => {
		return dataContext === ActionItemsFilterValue.OwnedBy ? (
			// @ts-ignore
			<div>Assigned to {getDisplayName(this.state.selectedEmployee) || '...'}</div>
		) : (
			<div>{text}</div>
		);
	};
}
