export function BillingIcon({ width, height }: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width={width} height={height} viewBox='0 0 180 180'>
			<g fill='none' transform='translate(25 51)'>
				<rect width='98' height='58' x='30' y='18' fill='#E8F3F9' stroke='#06F' rx='6' />
				<rect width='26' height='10' x='34' y='56' fill='#FFF' rx='3' />
				<path fill='#06F' d='M31 32h96v11H31z' />
				<rect width='98' height='58' x='1' y='1' fill='#E8F3F9' stroke='#06F' rx='6' />
				<rect width='92' height='52' x='4' y='4' fill='#FFF' rx='3' />
				<rect width='26' height='10' x='9' y='10' fill='#CAE0EC' rx='3' />
				<rect width='36' height='6' x='9' y='42' fill='#E8F3F9' rx='3' />
				<text fill='#CAE0EC'>
					<tspan x='9' y='38'>
						xxxx xxxx xxxx xxxx
					</tspan>
				</text>
			</g>
		</svg>
	);
}
