import { StyleSheet } from 'aphrodite';
import {
	background,
	brandSecondary,
	error,
	grayIconFill,
	header,
	mentionDark,
	nameCircles,
	navigation,
} from '../../../../styles/colors';
import { CssSelectors, cardStyle } from '../../../../styles/styles';

export const styleSheet = StyleSheet.create({
	advancedSettingsButton: {
		position: 'absolute',
		right: 0,
		top: '50%',
	},
	advancedSettingsIntervals: {
		display: 'flex',
		...CssSelectors.allChildren(
			{
				margin: `0 20px 0 0`,
			},
			'.checkbox'
		),
	},
	advancedSettingsLabel: {
		fontSize: '14px',
		margin: '0 0 16px',
		fontWeight: 600,
	},
	advancedSettingsRequiredLabel: {
		color: error,
		fontSize: '13px',
	},
	advancedSettingsTitle: {
		color: brandSecondary,
		fontSize: 18,
		margin: '0px 0 20px',
		position: 'relative',
	},
	advancedSettingsRow: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	advancedSettingsWrapper: {
		height: 'auto',
		opacity: 1,
		padding: '40px 50px',
		position: 'relative',
		transition: 'ease-in-out height 800ms',
		border: '1px solid #6E9FF7',
		borderRadius: '10px',
	},
	advancedSettingsWrapperHidden: {
		height: 0,
		margin: '0 -50px',
		opacity: 0,
		overflow: 'hidden',
		position: 'absolute',
		width: '100%',
	},
	allowInviteeCheckboxes: {
		paddingLeft: 22,
	},
	animateIn: {
		opacity: 1,
		transform: 'translateY(0)',
		transition: 'ease-in-out all 300ms',
	},
	animateOut: {
		opacity: 0,
		transform: 'translateY(30px)',
		transition: 'ease-in-out all 300ms',
	},
	availabilitiesContainer: {
		background,
		marginRight: '-50px',
		padding: '54px 0 23px 90px',
	},
	card: {
		...cardStyle,
		height: '100%',
		overflow: 'visible',
		padding: '0px 50px',
	},
	cardBottomContainer: {
		paddingTop: 32,
	},
	checkbox: {
		marginBottom: 5,
	},
	checkboxContainer: {
		alignItems: 'flex-start',
		display: 'flex',
		minWidth: 75,
		paddingTop: 8,
		...CssSelectors.allChildren(
			{
				margin: `4px 8px 0 0`,
			},
			'.checkbox'
		),
	},
	checkboxText: {
		color: '#000',
		display: 'inline-flex',
		fontSize: 14,
		fontWeight: 600,
		marginRight: 16,
	},
	checkboxTextDisabled: {
		opacity: 0.5,
	},
	col: {
		flexBasis: '50%',
	},
	commentsInput: {
		marginLeft: 25,
		maxWidth: '350px',
	},
	commentsInputLabel: {
		color: header,
		fontSize: 14,
		margin: '0 0 8px 25px',
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		padding: '36px 48px',
		width: '100%',
		...CssSelectors.allChildren(
			{
				color: brandSecondary,
				fontSize: '18px',
				margin: '-20px 0 20px',
				maxWidth: '50%',
			},
			'h2'
		),
	},
	customFieldslabel: {
		color: header,
		fontSize: 14,
		margin: '0',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			'sup'
		),
	},
	customFieldToggle: {
		paddingTop: 8,
	},
	dateRangeContainer: {
		alignItems: 'center',
		display: 'flex',
	},
	dateRangeText: {
		marginRight: 6,
		width: 150,
	},
	dropdownAnchor: {
		':focus': {
			backgroundColor: '#e0e0e0 !important',
		},
		...CssSelectors.allChildren(
			{
				display: 'block',
				maxWidth: '90%',
			},
			'span'
		),
		alignItems: 'center',
		backgroundColor: '#f9f9f9 !important',
		border: '1px solid #ccc',
		borderRadius: 3,
		color: '#0D0F11',
		display: 'inline-flex',
		fontSize: '14px',
		justifyContent: 'space-between',
		padding: '15px',
		width: '100%',
	},
	dropdownIcon: {
		height: 16,
		marginLeft: 12,
		transform: 'rotate(90deg)',
		width: 10,
	},
	dropdownInline: {
		maxWidth: '45%',
	},
	dropdownItem: {
		':focus': {
			backgroundColor: '#e0e0e0',
		},
		':hover': {
			backgroundColor: '#f9f9f9',
		},
		padding: '15px',
		width: '100%',
	},
	dropdownItemActive: {
		backgroundColor: '#def5ff',
	},
	dropdownMenu: {
		width: '100%',
	},
	dropdownMenuInner: {
		background: '#fff',
		boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
	},
	duration: {
		width: 200,
	},
	error: {
		color: error,
		fontSize: '12px',
		fontStyle: 'italic',
	},
	footer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		marginTop: 20,
		paddingBottom: 20,
		...CssSelectors.allChildren(
			{
				marginRight: 10,
			},
			':first-child'
		),
	},
	futureDays: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
	},
	futureDaysText: {
		marginRight: 6,
	},
	hoursOrDaysSelect: {
		width: 92,
	},
	label: {
		color: header,
		fontSize: '14px',
		margin: '0 0 16px',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			'sup'
		),
	},
	locationInfoContainer: {
		paddingBottom: 8,
		paddingLeft: 22,
	},
	locationInfoInput: {
		maxWidth: 450,
	},
	note: {
		height: 110,
	},
	noteEditor: {
		height: 110,
	},
	numberInput: {
		background: '#fff',
		borderColor: '#CCCCCC',
		marginBottom: 0,
		marginRight: 6,
	},
	numberInputContainer: {
		alignItems: 'center',
		display: 'flex',
		marginBottom: 8,
	},
	phoneInfo: {
		background: '#fff',
		border: `1px solid ${mentionDark}`,
		borderRadius: 5,
		marginLeft: 10,
		maxWidth: 250,
		padding: 10,
	},
	phoneLabel: {
		display: 'flex',
	},
	phoneLabelIcon: {
		padding: '0 5px',
		...CssSelectors.allChildren(
			{
				marginTop: 2,
			},
			'svg'
		),
	},
	radio: {
		paddingBottom: 6,
	},
	radioBig: {
		minHeight: 45,
	},
	richContentEditor: {
		background: '#F9F9F9',
		border: '1px solid #ccc',
		height: 60,
		resize: 'none',
		...CssSelectors.allChildren(
			{
				padding: 20,
			},
			'.rich-content-document-editor-readonly'
		),
	},
	savingChanges: {
		color: navigation,
		display: 'flex',
		paddingTop: 10,
		...CssSelectors.allChildren(
			{
				margin: '0 10px 0 20px',
			},
			'div'
		),
	},
	sectionTitle: {
		color: brandSecondary,
		fontSize: 18,
		margin: '44px 0 20px',
		position: 'relative',
	},
	sectionTitleAdjustedMargin: {
		marginTop: 0,
	},
	sectionWrapper: {
		marginBottom: 20,
	},
	smallInput: {
		marginBottom: 12,
		width: 75,
	},
	spacer: {
		backgroundColor: nameCircles,
		height: 1,
		marginBottom: 20,
		marginTop: 10,
		width: '100%',
	},
	subLabel: {
		fontSize: '14px',
		marginBottom: 8,
		marginTop: 11,
	},
	text: {
		fontSize: 14,
	},
	textDisabled: {
		color: grayIconFill,
	},
	timeDropdown: {
		width: 78,
	},
	timeDropdownDisabled: {
		opacity: 0.5,
	},
	timeInAdvanceSelect: {
		width: 68,
	},
	toggle: {
		marginRight: 12,
	},
	toggleAdjustedMargin: {
		marginRight: 5,
	},
	willCallRow: {
		alignItems: 'flex-start',
		display: 'flex',
		justifyContent: 'flex-start',
		...CssSelectors.allChildren(
			{
				margin: '2px 10px 0 22px',
			},
			'.checkbox'
		),
	},
	wrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		...CssSelectors.allChildren(
			{
				marginRight: 20,
			},
			':first-child'
		),
		...CssSelectors.allChildren(
			{
				marginLeft: 20,
			},
			':last-child'
		),
	},
});
