import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { getPlainTextPreviewForRawRichTextContent } from '../../../../models/UiUtils';
import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet } from './styles';

export function SocialCampaignTemplateCardSummary({
	bigScreenCutoff,
	content,
	ctaClicked,
}: {
	content: Api.IRawRichTextContentState;
	bigScreenCutoff: number;
	ctaClicked?: () => void;
}) {
	const { text, truncated } = getPlainTextPreviewForRawRichTextContent(content, {
		maxCharCount: bigScreenCutoff,
		maxLineCount: 4,
	});

	return (
		<div className={css(styleSheet.textContainer)}>
			<p className={css(styleSheet.body)}>
				<span>{text}</span>
				{truncated ? (
					<a onClick={ctaClicked} className={css(baseStyleSheet.fontBold, styleSheet.clickNav)}>
						... Read more
					</a>
				) : null}
			</p>
		</div>
	);
}
