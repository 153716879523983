import { StyleSheet } from 'aphrodite';
import { nameCircles } from '../../../../styles/colors';

export const styleSheet = StyleSheet.create({
	container: {
		background: 'white',
		borderBottom: `1px solid ${nameCircles}`,
		height: 100,
		overflow: 'visible',
		width: 'calc(100vw - 72px)',
	},
	searchBarContainer: {
		borderBottom: `1px solid ${nameCircles}`,
		width: 'calc(100vw - 90px)',
	},
	containerHappiness: {
		width: 'calc(100vw - 90px)',
	},
	controlsContainer: {
		display: 'flex',
		padding: '20px 0 0 265px',
	},
	searchSection: {
		flexGrow: 1,
	},
	taggingGameLink: {
		width: 265,
	},
});
