import * as React from 'react';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { styleSheet } from '../../SearchComponent/styles';
import { TextInput } from '../../../TextInput';
import {
	useCreateInsurancePolicyCarrierMutation,
	useInsurancePolicyCarrierAutocompleteQuery,
} from '../../../../../queries';
import { SearchIcon } from '../../../svgs/icons/SearchIcon';

export const PolicyCarrierSearch = ({
	onSelectCarrier,
	canAdd = true,
	onCreateCarrier,
	initialSearchFragment,
	className,
	dropdownClassName,
	onClear,
}: {
	onSelectCarrier: (carrier: Api.IInsurancePolicyCarrier) => void;
	canAdd?: boolean;
	onCreateCarrier?: (carrier: Api.IInsurancePolicyCarrier) => void;
	initialSearchFragment?: string;
	className?: string;
	dropdownClassName?: string;
	onClear?: () => void;
}) => {
	const [isCarrierSearchDropdownOpen, setIsCarrierSearchDropdownOpen] = React.useState(false);
	const [searchFragment, setSearchFragment] = React.useState<string>(initialSearchFragment ?? '');
	const addCarrierMutation = useCreateInsurancePolicyCarrierMutation({
		onSuccess: (result: Api.IInsurancePolicyCarrier) => {
			setSearchFragment(result.name);
			onCreateCarrier?.(result);
			setIsCarrierSearchDropdownOpen(false);
		},
	});

	const selectCarrier = (carrier: Api.IInsurancePolicyCarrier) => {
		setSearchFragment(carrier.name);
		setIsCarrierSearchDropdownOpen(false);
		onSelectCarrier(carrier);
	};

	const updateSearchFragment = (newValue: string) => {
		setSearchFragment(newValue);

		if (newValue) {
			setIsCarrierSearchDropdownOpen(true);
		} else {
			setIsCarrierSearchDropdownOpen(false);

			onClear?.();
		}
	};

	const createNewCarrier = () => {
		const carrier: Api.IInsurancePolicyCarrier = {
			name: searchFragment,
		};
		addCarrierMutation.mutate({
			carrier,
		});
	};

	const carrierAutocompleteQuery = useInsurancePolicyCarrierAutocompleteQuery({
		enabled: !!searchFragment,
		pageSize: 25,
		fragment: searchFragment,
	});

	const carriers = carrierAutocompleteQuery.data?.pages.map(page => page.values).flat() ?? [];

	return (
		<div className={`${css(styleSheet.searchInputWrap)} ${className || ''}`}>
			<TextInput
				className={css(isCarrierSearchDropdownOpen ? styleSheet.popoverOpen : null)}
				inputId='policy-modal-edit-carrier'
				onChange={e => updateSearchFragment(e.target.value)}
				type='text'
				value={searchFragment}
				placeholder='Search'
				leftAccessory={<SearchIcon className={css(styleSheet.searchIcon)} />}
			/>

			{isCarrierSearchDropdownOpen ? (
				<div className={`${css(styleSheet.dropdown)} ${dropdownClassName || ''}`}>
					{carriers.length === 0 && !carrierAutocompleteQuery.isLoading ? <i>No matches found</i> : null}

					{carriers.map(carrier => {
						return (
							<button onClick={() => selectCarrier(carrier)} key={`carrier-${carrier.id}`}>
								{carrier.name}
							</button>
						);
					})}

					{canAdd && !!searchFragment ? (
						<button className={css(styleSheet.addNewButton)} onClick={createNewCarrier}>
							<span>+ Add &quot;{searchFragment}&quot;</span>
						</button>
					) : null}
				</div>
			) : null}
		</div>
	);
};
