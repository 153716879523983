import { styleSheet } from './styles';
import { css } from 'aphrodite';

interface IProps {
	width?: number;
	color?: 'blue' | 'green' | 'purple';
	progress?: number;
	stroke?: number;
	label?: string;
}

export function CircularProgress({ width = 25, stroke = 10, color = 'blue', progress = 0, label = '' }: IProps) {
	const radius = width / 2;
	const normalizedRadius = radius - stroke * 2;
	const circumference = normalizedRadius * 2 * Math.PI;
	const strokeDashoffset = circumference - (progress / 100) * circumference;
	const isSmall = width <= 100;
	return (
		<div className={css(styleSheet.root)} style={{ width }}>
			<svg height={width} width={width}>
				<linearGradient id='blueGradient'>
					<stop offset='0%' stopColor='#3CC0F1' />
					<stop offset='100%' stopColor='#0B90EF' />
				</linearGradient>
				<linearGradient id='greenGradient'>
					<stop offset='0%' stopColor='#6DD400' />
					<stop offset='100%' stopColor='#00F73F' />
				</linearGradient>
				<linearGradient id='purpleGradient'>
					<stop offset='0%' stopColor='#C30DAF' />
					<stop offset='100%' stopColor='#FF7892' />
				</linearGradient>
				<circle stroke='#E8F3F9' fill='transparent' strokeWidth={stroke} r={normalizedRadius} cx={radius} cy={radius} />
				<circle
					stroke={`url(#${color}Gradient)`}
					fill='transparent'
					strokeWidth={stroke}
					strokeDasharray={`${circumference} ${circumference}`}
					strokeLinecap='round'
					style={{ strokeDashoffset }}
					r={normalizedRadius}
					cx={radius}
					cy={radius}
					className={css(styleSheet.progressIndicator)}
				/>
			</svg>
			<div className={css(styleSheet.centerLabel)}>
				<span className={css(styleSheet.centerLabelPercent, isSmall && styleSheet.smCenterLabelPercent)}>
					{progress}%
				</span>
				<span className={css(styleSheet.centerLabelText, isSmall && styleSheet.smCenterLabelText)}>{label}</span>
			</div>
		</div>
	);
}
