import { ISelectOption } from '../../components/Select';

/**
 * TIME_LIMIT = 24 hours * 60 minutes = 1440 minutes - 15 minutes = (1425 minutes) which is the max time limit
 * before midnight. This is to prevent the user from selecting a time that is after midnight
 */
const TIME_LIMIT = 1425;
export const eventTimeOptions = (): ISelectOption<string>[] => {
	const options: ISelectOption<string>[] = [];
	let hours;
	let minutes;
	let ampm;
	let texthours;
	for (let i = 0; i <= TIME_LIMIT; i += 15) {
		hours = Math.floor(i / 60);
		minutes = i % 60;
		if (minutes < 10) {
			minutes = '0' + minutes; // adding leading zero
		}
		ampm = hours % 24 < 12 ? 'am' : 'pm';
		texthours = hours % 12;
		if (texthours === 0) {
			texthours = 12;
		}
		if (texthours < 10) {
			texthours = '0' + texthours; // adding leading zero
		}
		options.push({
			dataContext: hours + ':' + minutes + ':00',
			id: `time-${hours + ':' + minutes + ' ' + ampm}`,
			text: texthours + ':' + minutes + '' + ampm,
		});
	}
	return options;
};
