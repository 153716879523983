import { titles } from '../../../../styles/colors';
import { CssSelectors, absoluteCenterStyle } from '../../../../styles/styles';
import { ContactCardContainerStyle } from '../../../contacts/ContactCard/styles';
import { StyleSheet } from 'aphrodite';

const contactCardSpacing = 15;
const headerHeight = 110;
const searchEnterIconSize = 19;
const searchIconSize = 16;

export const styleSheet = StyleSheet.create({
	body: {
		height: `calc(100% - ${22 + headerHeight}px)`,
		marginTop: 22,
		overflowY: 'auto',
		position: 'relative',
	},
	contactCard: {
		cursor: 'pointer',
		marginLeft: contactCardSpacing,
		marginTop: contactCardSpacing,
		width: `calc(33% - ${contactCardSpacing}px)`,
	},
	contacts: {
		overflow: 'hidden',
	},
	contactsContent: {
		display: 'flex',
		flexWrap: 'wrap',
		marginLeft: -contactCardSpacing,
		marginTop: -contactCardSpacing,
	},
	container: {
		height: '100%',
	},
	emptyPlaceholder: {
		textAlign: 'center',
		...CssSelectors.allChildren(
			{
				marginLeft: 'auto',
				marginRight: 'auto',
				marginTop: 20,
			},
			':not(:first-child)'
		),
	},
	emptyPlaceholderDescription: {
		color: '#4A4A4A',
		fontSize: 14,
	},
	emptyPlaceholderIcon: {
		margin: 'auto',
	},
	emptyPlaceholderNewContactButton: {},
	emptyPlaceholderTitle: {
		color: titles,
		fontSize: 24,
	},
	header: {
		color: titles,
		display: 'flex',
		flexDirection: 'column',
		fontSize: 18,
		height: headerHeight,
		justifyContent: 'space-between',
	},
	loading: {
		...absoluteCenterStyle,
	},
	loadingCard: {
		position: 'relative',
		...ContactCardContainerStyle,
	},
	loadingCardSpinner: {
		...absoluteCenterStyle,
	},
	searchField: {
		display: 'inline-block',
		fontSize: 14,
		width: '62%',
		...CssSelectors.allDescendants(
			{
				display: 'block',
			},
			'.dropdown'
		),
		...CssSelectors.allDescendants(
			{
				width: '100%',
			},
			'.dropdown-anchor'
		),
	},
	searchFieldAnchor: {
		borderRadius: '0px 3px 3px 0px',
		width: '100%',
	},
	searchFieldAnchorLeftAccessory: {
		marginRight: 10,
	},
	searchFieldDropdown: {
		maxWidth: '150%',
	},
	searchFieldEnterButton: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		padding: 0,
	},
	searchFieldEnterIcon: {
		flexShrink: 0,
		height: searchEnterIconSize,
		marginLeft: 8,
		width: searchEnterIconSize,
	},
	searchFieldIcon: {
		flexShrink: 0,
		height: searchIconSize,
		marginRight: 8,
		width: searchIconSize,
	},
	searchFieldInput: {
		width: `calc(100% - ${searchEnterIconSize}px - ${searchIconSize}px)`,
	},
	searchHeader: {
		display: 'flex',
		justifyContent: 'center',
	},
	subtitle: {
		color: '#4A4A4A',
		fontSize: 14,
		textAlign: 'center',
	},
	title: {
		textAlign: 'center',
	},
});
