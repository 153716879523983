import { SvgIcon } from '../../icons/SvgIcon';

export const BlogEmptyGraphic = () => {
	return (
		<SvgIcon width='199' height='130'>
			<g fill='none'>
				<path
					fill='#FFD9B6'
					d='M174.708 87.25a2.213 2.213 0 0 1-.4-1.676c.097-.597.427-1.116.902-1.417L174 77l3.146 1.466.666 6.572c.412.938.126 2.07-.666 2.635-.792.564-1.84.383-2.438-.423Z'
				/>
				<path fill='#E9EBEF' d='M12 4h153v73H12z' />
				<path fill='#FFF' d='M16 10h145v61H16z' />
				<path fill='#06F' d='M14 0h149a2 2 0 0 1 2 2v4H12V2a2 2 0 0 1 2-2Z' />
				<circle cx='17.5' cy='2.5' r='1.5' fill='#FFF' />
				<circle cx='21.5' cy='2.5' r='1.5' fill='#FFF' />
				<circle cx='26.5' cy='2.5' r='1.5' fill='#FFF' />
				<path
					fill='#E9EBEF'
					d='M25.63 50c-.348.001-.63.225-.63.5s.282.499.63.5h34.74c.348-.001.63-.225.63-.5s-.282-.499-.63-.5H25.63Zm0 3c-.348.001-.63.225-.63.5s.282.499.63.5h17.74c.348-.001.63-.225.63-.5s-.282-.499-.63-.5H25.63Z'
				/>
				<path
					fill='#06F'
					d='M60.332 60H46.668c-.369 0-.668-.34-.668-.758v-2.484c0-.419.299-.758.668-.758h13.664c.369 0 .668.34.668.758v2.484c0 .419-.3.758-.668.758Zm0-21H46.668c-.369 0-.668-.34-.668-.758v-2.484c0-.419.299-.758.668-.758h13.664c.369 0 .668.34.668.758v2.484c0 .419-.3.758-.668.758Z'
				/>
				<path
					fill='#E9EBEF'
					d='M83.828 21c-.458 0-.828.224-.828.5s.37.5.828.5h69.344c.458 0 .828-.224.828-.5s-.37-.5-.828-.5H83.828Zm.004 5c-.46 0-.832.224-.832.5s.372.5.832.5h47.336c.46 0 .832-.224.832-.5s-.372-.5-.832-.5H83.832Zm-.004 5c-.458 0-.828.224-.828.5s.37.5.828.5h69.344c.458 0 .828-.224.828-.5s-.37-.5-.828-.5H83.828Zm.004 6c-.46 0-.832.224-.832.5s.372.5.832.5h47.336c.46 0 .832-.224.832-.5s-.372-.5-.832-.5H83.832Z'
				/>
				<path
					fill='#06F'
					d='M152.332 47h-13.664c-.369 0-.668-.34-.668-.758v-2.484c0-.419.299-.758.668-.758h13.664c.369 0 .668.34.668.758v2.484c0 .419-.299.758-.668.758Z'
				/>
				<path
					fill='#E9EBEF'
					d='M40.426 22c-.236 0-.426.225-.426.501 0 .275.191.498.426.499h20.148c.236 0 .426-.225.426-.501 0-.275-.191-.498-.426-.499H40.426Zm0 4c-.236 0-.426.225-.426.501 0 .275.191.498.426.499h20.148c.236 0 .426-.225.426-.501 0-.275-.191-.498-.426-.499H40.426Zm-.023 4c-.223 0-.403.225-.403.501 0 .275.18.498.403.499h8.194c.223 0 .403-.225.403-.501 0-.275-.18-.498-.403-.499h-8.194Z'
				/>
				<circle cx='30.5' cy='26.5' r='5.5' fill='#E9EBEF' />
				<path
					fill='#FFD9B6'
					d='M143.015 44.83c-.067.608.098 1.22.45 1.67.35.451.853.695 1.371.666l2.32 6.834L149 50.854l-2.486-6.007c-.102-1.05-.85-1.843-1.743-1.847-.893-.004-1.648.782-1.756 1.83ZM172.999 127h-2.71L169 118h4z'
				/>
				<path fill='#00528C' d='M174 129h-8v-.126c0-2.14 1.394-3.874 3.114-3.874H174v4Z' />
				<path fill='#FFD9B6' d='m165.701 126-2.701-.732 2.013-9.268 3.987 1.081z' />
				<path
					fill='#00528C'
					d='M165.984 129 158 126.424l.032-.103c.543-1.75 2.375-2.719 4.091-2.165l4.877 1.573-1.016 3.271Z'
				/>
				<path
					fill='#FF9D52'
					d='M161.78 80.12s-3.849 5.792-2.49 10.96c1.36 5.169 2.841 15.103 2.841 15.103L169.504 123l5.496-.215-5.458-19.827.216-13.257s3.793-6.336 3.055-8.308c-.74-1.972-11.034-1.272-11.034-1.272Z'
				/>
				<path
					fill='#FF9D52'
					d='M173.927 81.309s.479 5.79-1.018 8.242c-1.498 2.452-3.093 11.784-3.093 11.784L167.271 120 162 118.361l4.495-20.911L170.028 81l3.9.309Z'
				/>
				<path
					fill='#0CF'
					d='m176.06 53.87-4.082-2.697-6.21-.173-2.162 2.038-3.3 1.62L160 81.044s9.883 4.553 15.2 2.356l2.557-20.556c1.061-4.08-1.698-8.976-1.698-8.976Z'
				/>
				<path
					fill='#0CF'
					d='M162.251 62H151.84L145 49.822 149.448 48l3.766 6.93 5.452-.51 3.404-.17a3.687 3.687 0 0 1 2.928 1.234 3.963 3.963 0 0 1 .974 3.111c-.233 1.943-1.828 3.403-3.72 3.405Z'
				/>
				<circle cx='170' cy='44' r='5' fill='#FFD9B6' />
				<path
					fill='#7A5541'
					d='M175.963 42.671c-.162-1.188-.95-2.317-2.07-2.677-.445-1.321-1.623-2.293-2.926-2.717-2.48-.809-5.18.198-6.57 2.45-.099.16-.405.553-.397.744.011.243.374.493.557.646.452.358.94.665 1.455.918 1.905 1.01 1.27 2.596 1.245 4.394-.013.874.273 1.62 1.001 2.118 1.052.72 2.557.434 3.701.145 1.299-.328 2.378-1.494 3.119-2.618.656-.996 1.048-2.214.885-3.403Z'
				/>
				<path fill='#0CF' d='M176.132 61 178 83l-6-.346z' />
				<path
					fill='#E9EBEF'
					d='M198.756 130h-79.513c-.134 0-.243-.224-.243-.5s.109-.5.243-.5h79.513c.135 0 .244.224.244.5s-.11.5-.244.5Z'
				/>
				<path fill='#9E616A' d='M17 127h2.033l.967-7h-3z' />
				<path fill='#2F2E41' d='M17 126h3.665c1.29 0 2.335 1.3 2.335 2.905V129h-6v-3Z' />
				<path fill='#9E616A' d='M35 127h2.033l.967-7h-3z' />
				<path fill='#2F2E41' d='M35 126h3.665c1.29 0 2.335 1.3 2.335 2.905V129h-6v-3Z' />
				<path fill='#9E616A' d='M32.737 67 34 74.846 22 75l1.579-7.538z' />
				<path
					fill='#8B3DC6'
					d='m33.752 73 3.78 10.303L38 126h-3.2l-7.651-34.305-7.026 33.41H17l2.186-37.566s-.645-7.54 2.654-11.989l.803-2.375L33.753 73Z'
				/>
				<path
					fill='#9E616A'
					d='M17.82 80.915a2.23 2.23 0 0 0 1.428-1.247 2.349 2.349 0 0 0-.011-1.93L24 61.717 20.496 61 16.14 76.705c-.983.566-1.401 1.8-.972 2.875.429 1.074 1.568 1.648 2.653 1.335Z'
				/>
				<path
					fill='#E072BE'
					d='M23.172 72a1.15 1.15 0 0 1-.217-.02 1.106 1.106 0 0 1-.903-.993c-.124-1.648-.738-4.518-1.825-8.529-.753-2.794.409-5.745 2.89-7.338a7.115 7.115 0 0 1 8.082.29 6.62 6.62 0 0 1 2.647 4.556c.54 4.623-.478 9.264-.941 11.045a1.123 1.123 0 0 1-1.07.827l-8.64.162h-.024Z'
				/>
				<path
					fill='#9E616A'
					d='M46.562 57.34c-.05.11-.09.223-.122.339l-10.39 3.47-2.007-1.724L31 62.063l3.252 3.39c.54.563 1.393.708 2.093.357l11.118-5.582c.823.497 1.873.44 2.636-.14a2.26 2.26 0 0 0 .799-2.474 2.315 2.315 0 0 0-2.071-1.61 2.328 2.328 0 0 0-2.265 1.335Z'
				/>
				<path
					fill='#E072BE'
					d='m36.66 60.542-3.787 3.219a1.014 1.014 0 0 1-1.492-.206l-2.708-4.105a2.64 2.64 0 0 1 .279-3.791c1.15-.973 2.894-.855 3.896.263l3.802 3.153c.22.183.348.45.35.732a.958.958 0 0 1-.34.735Zm-13.324 4.392-4.702-1.757a.975.975 0 0 1-.578-.57.91.91 0 0 1 .077-.79l2.509-4.154c.567-1.353 2.18-2.013 3.603-1.476 1.423.536 2.122 2.067 1.562 3.423l-1.13 4.653a.96.96 0 0 1-.509.627 1.047 1.047 0 0 1-.832.044Z'
				/>
				<circle cx='27' cy='48' r='5' fill='#9E616A' />
				<path
					fill='#E9EBEF'
					d='M79.757 130H.243c-.134 0-.243-.224-.243-.5s.109-.5.243-.5h79.514c.134 0 .243.224.243.5s-.109.5-.243.5Z'
				/>
				<path
					fill='#2F2E41'
					d='m29.72 45.437 3.269-.018c.077-.72-.253-1.437-.735-1.99a8.314 8.314 0 0 0-1.72-1.381c-.625-.416-1.273-.842-2.015-.987-.623-.122-1.267-.037-1.896.048l-2.503.337c-.915.124-1.873.263-2.617.796-1.006.72-1.39 1.984-1.714 3.155L18 51.884l10.567 1.075c.382.064.774.053 1.152-.032a.972.972 0 0 0 .736-.782c.03-.418-.311-.76-.589-1.081a4.931 4.931 0 0 1-.45-5.714l.304.087Z'
				/>
			</g>
		</SvgIcon>
	);
};
