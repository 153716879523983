import { CssSelectors } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	composer: {
		':nth-child(1n).note-composer': {
			height: '100%',
			width: '100%',
		},
		...CssSelectors.allDescendants(
			{
				display: 'none',
				opacity: 0,
				visibility: 'collapse',
			},
			'.note-composer-header-close-button'
		),
	},
	container: {
		background: '#fff',
		height: '100%',
		minWidth: 840,
		position: 'relative',
		width: '100%',
	},
	toaster: {
		left: '50%',
		position: 'absolute',
		top: 10,
		transform: 'translate(-50%, 0)',
	},
});
