import { brandPrimaryHover, tagBackgroundColor } from '../../../styles/colors';
import { CssSelectors, baseStyles } from '../../../styles/styles';
import { TagFontSize, TagHeight } from '../Tag/styles';
import { CSSProperties, StyleSheet } from 'aphrodite';

const interItemSpacing = 10;

export const styleSheet = StyleSheet.create({
	addTagButton: {
		alignItems: 'center',
		background: tagBackgroundColor,
		borderRadius: TagHeight / 2,
		boxSizing: 'border-box',
		color: brandPrimaryHover,
		fontSize: TagFontSize,
		height: TagHeight,
		letterSpacing: '0',
		padding: '0px 15px 0 10px',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	autoCompleteMenu: {
		...(baseStyles.dropdownMenu as CSSProperties),
		marginTop: 2,
		width: 'calc(100% + 120px)',
	},
	autoCompleteMenuItem: {
		...baseStyles.dropdownMenuItem,
	},
	autoCompleteMenuItemHighlighted: {
		...baseStyles.dropdownMenuItemHighlighted,
	},
	container: {
		height: '100%',
	},
	content: {
		height: '100%',
	},
	editTag: {
		marginLeft: interItemSpacing,
		marginTop: interItemSpacing,
	},
	header: {
		margin: 0,
		paddingBottom: 16,
	},
	tag: {
		cursor: 'pointer',
		marginLeft: interItemSpacing,
		marginTop: interItemSpacing,
	},
	tags: {
		display: 'flex',
		flexWrap: 'wrap',
		marginLeft: -interItemSpacing,
		marginTop: -interItemSpacing,
	},
	singleColumn: {
		flexDirection: 'column',
	},
});
