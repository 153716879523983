import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	image: {
		borderRadius: 8,
		height: 45,
		position: 'relative',
		width: 45,
	},
});
