import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import { EventLogger } from '../../../../models/Logging';
import { getDefaultDateStringValue } from '../../../../models/UiUtils';
import { ActivityListItem } from '../../ActivityListItem';
import { LoadingSpinner } from '../../LoadingSpinner';
import BoardItemActivityIconUrl from './boardItemActivityIcon.svg';
import { styleSheet } from './styles';
import { BoardItemViewModel, IOperationResultNoValue, VmUtils } from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import Waypoint from 'react-waypoint';

interface IProps extends IUserSessionComponentProps {
	boardItem?: BoardItemViewModel;
	className?: string;
	hideListItemIcons?: boolean;
}

interface IState {
	boardItem?: BoardItemViewModel;
}

class _BoardItemActivityList extends React.Component<IProps, IState> {
	public readonly state: IState = {
		boardItem: this.props.boardItem,
	};

	public UNSAFE_componentWillMount() {
		this.getNextBatchOfActivity();
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		if (nextProps.boardItem !== this.state.boardItem) {
			this.setState({
				boardItem: nextProps.boardItem,
			});
		}
	}

	public render() {
		const { className = '', hideListItemIcons } = this.props;
		const { boardItem } = this.state;
		return (
			<div className={className}>
				{!!boardItem && !!boardItem.activityItems && (
					<ul>
						{boardItem.activityItems.map((x, i) => {
							const rightAccessory = (
								<span className={css(styleSheet.rightAccessory)}>
									{/* @ts-ignore */}
									{`by ${VmUtils.getDisplayName(x.creator, true)}`}
								</span>
							);
							return (
								<ActivityListItem
									date={getDefaultDateStringValue(x.lastModifiedDate)}
									iconSrc={hideListItemIcons ? undefined : BoardItemActivityIconUrl}
									key={x.id || i}
									rightAccessory={rightAccessory}
								>
									<span className={css(styleSheet.text)}>{x.text}</span>
								</ActivityListItem>
							);
						})}
						<Waypoint bottomOffset='-200px' onEnter={this.scrolledToBottom} />
					</ul>
				)}
				{!!boardItem && !boardItem.activityItems && !!boardItem.isBusy && (
					<LoadingSpinner className={css(styleSheet.loading)} type='large' />
				)}
			</div>
		);
	}

	private scrolledToBottom = () => {
		this.getNextBatchOfActivity();
	};

	private getNextBatchOfActivity = (boardItem = this.state.boardItem) => {
		if (!!boardItem && !boardItem.fetchingActivityItems) {
			const promise = boardItem.getActivity();
			if (promise) {
				promise.catch((error: IOperationResultNoValue) => {
					EventLogger.logEvent(
						{
							action: 'GetActivity-Error',
							category: 'BoardItemActivityList',
						},
						{ ...error }
					);
				});
			}
		}
	};
}

const BoardItemActivityListAsObserver = observer(_BoardItemActivityList);
export const BoardItemActivityList = inject(UserSessionViewModelKey)(BoardItemActivityListAsObserver);
