import { StyleSheet } from 'aphrodite';
import { brandPrimary, header, mention } from '../../../styles/colors';
import { CssSelectors, baseStyleSheet } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	cardHeader: {
		alignItems: 'center',
		borderBottom: `1px solid ${mention}`,
		display: 'flex',
		marginBottom: '1rem',
		paddingBottom: '0.5rem',
		width: '100%',
	},
	cardHGroup: {
		...CssSelectors.allChildren(
			{
				marginTop: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	dailyUpdateItemContent: {
		display: 'flex',
	},
	dailyUpdateItemHGroup: {
		display: 'flex',
		flexDirection: 'column',
	},
	dailyUpdateItemIcon: {
		boxSizing: 'border-box',
	},
	dailyUpdateItemIconContainer: {
		alignItems: 'center',
		borderRadius: 12,
		boxSizing: 'border-box',
		display: 'flex',
		height: 40,
		justifyContent: 'center',
		marginRight: '1rem',
		padding: '0.5rem',
		width: 40,
	},
	dailyUpdateItemNumber: {
		...baseStyleSheet.fontBold,
	},
	dailyUpdateItemRoot: {
		...CssSelectors.allChildren(
			{
				marginBottom: '0.5rem',
			},
			':not(:last-child)'
		),
		alignItems: 'center',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
		paddingRight: '1rem',
	},
	dailyUpdateItemSendEmailLink: {
		marginLeft: '0.25rem',
	},
	dailyUpdateItemSubtitle: {
		color: header,
		fontSize: '0.75rem',
		margin: 0,
	},
	dailyUpdateItemTitle: {
		margin: '0 0 0.25rem 0',
	},
	dailyUpdatesNumber: {
		color: brandPrimary,
		fontSize: '1.625rem',
	},
	refreshButton: {
		alignItems: 'center',
		background: brandPrimary,
		borderRadius: 12,
		display: 'flex',
		flexShrink: 0,
		justifyContent: 'center',
		marginRight: '1rem',
		padding: '0.5rem',
	},
	root: {
		flexDirection: 'column',
		width: '100%',
	},
});
