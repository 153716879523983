import { brandSecondary, titles } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	button: { margin: '20px auto' },
	container: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center',
	},
	description: {
		color: titles,
		fontSize: 14,
		margin: '10px auto',
		width: 220,
	},
	title: {
		color: brandSecondary,
		fontSize: 18,
		margin: '10px 0',
	},
});
