import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { CampaignViewModel, DashboardFeedViewModel, EmailSendStatus, ICampaign } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { ILocationState } from '../../../../models';
import {
	FullScreenModalViewModelKey,
	IFullscreenModalComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { Topics } from '../../../../models/LocalNotificationTopics';
import { ILocalNotification } from '../../../../models/LocalNotifications';
import { invalidateDashboardResourceSelector } from '../../../../queries';
import { CampaignsApprovalViewModel } from '../../../../viewmodels/AppViewModels';
import EmailIconImageUrl from '../../../assets/email_graphic.svg';
import { brandSecondary } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { LocalNotificationObserver } from '../../LocalNotificationObserver';
import { FeedCard } from '../../cards/FeedCard';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps, IFullscreenModalComponentProps, IUserSessionComponentProps {
	className?: string;
	dashboardFeed?: DashboardFeedViewModel;
	itemStyles?: StyleDeclarationValue[];
	styles?: StyleDeclarationValue[];
}

class _CampaignsWithApprovalRequests extends React.Component<IProps> {
	public render() {
		const { className, styles, dashboardFeed, itemStyles } = this.props;
		// @ts-ignore
		// @ts-ignore
		if (dashboardFeed.campaignsWithApprovalRequests.length === 0 || !!dashboardFeed.isBusy) {
			return null;
		}
		return (
			<div
				className={`${css(styleSheet.container, ...(styles || []))} campaigns-with-approval-requests ${
					className || ''
				}`}
			>
				{/* @ts-ignore */}
				{dashboardFeed.campaignsWithApprovalRequests.map((campaign, i) => {
					return (
						<FeedCard
							indicatorColor={brandSecondary}
							key={`${campaign.id}-${i}`}
							styles={[styleSheet.item, ...(itemStyles || [])]}
						>
							<div className={css(styleSheet.header)}>
								<img className={css(styleSheet.headerIcon)} src={EmailIconImageUrl} />
								<div className={css(styleSheet.headerTitle)}>
									<div
										className={css(baseStyleSheet.truncateText, styleSheet.headerTitleName)}
										title={campaign?.actor?.name}
									>
										{campaign?.actor?.name}
									</div>
									&nbsp;
									<span className={css(styleSheet.headerTitleMessage)}>has sent you a campaign to approve.</span>
								</div>
							</div>
							<div className={css(styleSheet.body)}>
								<div className={css(baseStyleSheet.fontBold, baseStyleSheet.truncateText, styleSheet.bodyCampaignName)}>
									{campaign.templateReference?.name || campaign.subject}
								</div>
								&nbsp;
								{/* @ts-ignore */}
								<div>{`scheduled for ${moment(campaign.schedule.startDate).format('M/DD')}`}</div>
							</div>
							<div className={css(styleSheet.footer)}>
								<button
									className={css(styleSheet.reviewAndApproveButton)}
									// @ts-ignore
									onClick={this.launchReviewAndApprove(campaign, i)}
								>
									<span>Review & Approve</span>
								</button>
							</div>
						</FeedCard>
					);
				})}
				<LocalNotificationObserver
					onNotificationReceived={this.onNotificationReceived}
					topic={Topics.EDIT_CAMPAIGNS_ITEM}
				/>
			</div>
		);
	}

	private launchReviewAndApprove = (selectedCampaign: CampaignViewModel, index: number) => () => {
		const { fullscreenModal, userSession, dashboardFeed } = this.props;
		// @ts-ignore
		const campaigns = [...dashboardFeed.campaignsWithApprovalRequests.toArray()];
		campaigns.splice(index, 1);
		const locationState: ILocationState<CampaignsApprovalViewModel, any> = {
			// @ts-ignore
			viewModel: new CampaignsApprovalViewModel(userSession, [selectedCampaign, ...campaigns]),
		};
		fullscreenModal?.history?.push({
			pathname: '/email/campaigns/edit',
			state: locationState,
		});
	};

	@action
	private onNotificationReceived = (notification?: ILocalNotification<ICampaign[]>) => {
		const { dashboardFeed } = this.props;
		const campaignsToRemove: CampaignViewModel[] = [];
		notification?.info?.forEach(x => {
			// @ts-ignore
			// @ts-ignore
			const match = dashboardFeed.campaignsWithApprovalRequests.getById(x.id);
			if (!!match && x.status !== EmailSendStatus.WaitingForApproval) {
				campaignsToRemove.push(match);
			}
		});
		if (campaignsToRemove.length > 0) {
			// @ts-ignore
			dashboardFeed.campaignsWithApprovalRequests.removeItems(campaignsToRemove);
			invalidateDashboardResourceSelector();
		}
	};
}

const CampaignsWithApprovalRequestsAsObserver = observer(_CampaignsWithApprovalRequests);
const CampaignsWithApprovalRequestsWithContext = inject(
	UserSessionViewModelKey,
	FullScreenModalViewModelKey
)(CampaignsWithApprovalRequestsAsObserver);
export const CampaignsWithApprovalRequests = withEventLogging(
	CampaignsWithApprovalRequestsWithContext,
	'CampaignsWithApprovalRequests'
);
