import { brandSecondary } from '../../../../styles/colors';
import { SvgIcon } from '../../icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const TextingArrowGraphic: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon className={`texting-arrow-graphic ${className || ''}`} height={16} width={110}>
			<path d='M7.98489 1L1.63867 8.35787L7.98489 15.1402' stroke={brandSecondary} fill='none' />
			<path d='M3.5 8.5H116.5' stroke={brandSecondary} strokeDasharray='4' />
		</SvgIcon>
	);
};
