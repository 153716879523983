import { brandSecondary, brandSecondaryActive, brandSecondaryHover } from '../../styles/colors';
import { CssSelectors } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	button: {
		':active': {
			color: brandSecondaryActive,
		},
		':focus': {
			outline: 'none',
		},
		':hover': {
			color: brandSecondaryHover,
		},
		alignItems: 'center',
		background: '#f6f6f6',
		borderRadius: '3px',
		color: brandSecondary,
		display: 'flex',
		fontSize: '12px',
		justifyContent: 'center',
		padding: '7px 10px',
		...CssSelectors.allChildren(
			{
				marginLeft: 6,
			},
			':not(:first-child)'
		),
	},
});
