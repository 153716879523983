import { StyleSheet } from 'aphrodite';
import { brandPrimaryText, destructive } from '../../../../styles/colors';

export const styleSheet = StyleSheet.create({
	ctaButton: {
		boxShadow: 'none',
		marginTop: 20,
	},
	disconnectedTitle: {
		color: destructive,
	},
	integrationTitle: {
		color: brandPrimaryText,
	},
	pointLeft: {
		transform: 'rotateZ(180deg)',
	},
});
