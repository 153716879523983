import * as Api from '@ViewModels';
import { IRange } from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import Waypoint from 'react-waypoint';
import { NotesDateFilterOptionValue, NotesFilterOptionValue, NotesSortOptionValue } from '../../../../models';
import { Topics } from '../../../../models/LocalNotificationTopics';
import { useEventLogging } from '../../../../models/Logging';
import { formatDateCriteria, formatDateRangeString, getDisplayName } from '../../../../models/UiUtils';
import { useErrorMessages, useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	NotesSearchSortByType,
	invalidateNotesByAccount,
	invalidateNotesByMe,
	invalidateNotesSearch,
	useDeleteNoteMutation,
	useInfiniteNotesSearch,
} from '../../../../queries';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../ConfirmationDialog';
import { DateRangePickerSideModal } from '../../DateRangePickerSide';
import { FabCalloutPlaceholder } from '../../FabCalloutPlaceholder';
import { LoadingSpinner } from '../../LoadingSpinner';
import { LocalNotificationObserver } from '../../LocalNotificationObserver';
import { IMoreMenuItem } from '../../MoreMenu';
import { Portal } from '../../Portal';
import { ISelectOption, Select } from '../../Select';
import { TextInput } from '../../TextInput';
import { UserSelectModal } from '../../UserSelectModal';
import { NoteCard } from '../../cards/NoteCard';
import { NotesPlaceholderIcon } from '../../svgs/icons/NotesPlaceholderIcon';
import { SearchIcon } from '../../svgs/icons/SearchIcon';
import { WarningIcon } from '../../svgs/icons/WarningIcon';
import { styleSheet } from './styles';

export const NoteOptions: ISelectOption<NotesFilterOptionValue>[] = [
	{
		dataContext: 'byAccount',
		id: 'note-option-show-all',
		text: 'Show all notes',
	},
	{
		dataContext: 'byMe',
		id: 'note-option-mine-only',
		text: 'Show my notes only',
	},
	{
		dataContext: 'ownedBy',
		id: 'notes-filter-option-owned-by',
		text: 'Show notes owned by...',
	},
];

const DefaultDateRangeText = 'Filter by Date';
export const NoteDateOptions: ISelectOption<NotesDateFilterOptionValue>[] = [
	{
		dataContext: 'date',
		id: 'note-option-mine-asc',
		text: DefaultDateRangeText,
	},
];
export const NoteSortOptions: ISelectOption<NotesSortOptionValue>[] = [
	{
		dataContext: 'LastModifiedDate-dsc',
		id: 'note-sort-option-last-modified-date-dsc',
		text: 'Last Modified date (newest first)',
	},
	{
		dataContext: 'LastModifiedDate-asc',
		id: 'note-sort-option-last-modified-date-asc',
		text: 'Last Modified date (oldest first)',
	},
	{
		dataContext: 'CreationDate-dsc',
		id: 'note-sort-option-creation-date-dsc',
		text: 'Creation date (newest first)',
	},
	{
		dataContext: 'CreationDate-asc',
		id: 'note-sort-option-creation-date-asc',
		text: 'Creation date (oldest first)',
	},
];

const DeleteMoreMenuItem: IMoreMenuItem<string> = {
	key: 'delete',
	name: 'Delete',
	representedObject: 'delete',
};
const EditMoreMenuItem: IMoreMenuItem<string> = {
	key: 'edit',
	name: 'Edit',
	representedObject: 'edit',
};

interface IProps {
	scrollToBottomPortalId?: string;
	onEditNote: (noteId: string) => void;
}

function _NotesList({ onEditNote, scrollToBottomPortalId }: IProps) {
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const { logInput, logApiError, logEvent } = useEventLogging();
	const [searchQuery, setSearchQuery] = React.useState('');
	const [debouncedSearchQuery, setDebouncedSearchQuery] = React.useState(searchQuery);
	const [noteToDelete, setNoteToDelete] = React.useState<Api.NoteViewModel | null>(null);
	const [selectedFilterOption, setSelectedFilterOption] = React.useState(NoteOptions[1]);
	const activeQueryType: 'search' | 'byMe' | 'byAccount' | 'ownedBy' = React.useMemo(() => {
		return debouncedSearchQuery ? 'search' : selectedFilterOption.dataContext;
	}, [selectedFilterOption, debouncedSearchQuery]);
	const [dateRange, setDateRange] = React.useState<IRange<Date | null>>({ start: null, end: null });
	const [showDateSelector, setShowDateSelector] = React.useState(false);
	const [selectedDateFilterOption, setSelectedDateFilterOption] = React.useState(NoteDateOptions[0]);
	const [selectedDateRangeText, setSelectedDateRangeText] = React.useState(DefaultDateRangeText);
	const [selectedSortFilterOption, setSelectedSortFilterOption] = React.useState(NoteSortOptions[0]);
	const [selectedSortText, setSelectedSortText] = React.useState(NoteSortOptions[0].text);
	const [showSelectEmployeeModal, setShowSelectEmployeeModal] = React.useState(false);
	const [assignedToUser, setAssignedToUser] = React.useState<Api.UserViewModel | undefined>();

	const filterRequest = React.useMemo(() => {
		const newFilterRequest: Api.IRichContentRequest = {
			criteria: [],
		};

		if (debouncedSearchQuery.length > 0) {
			newFilterRequest.criteria?.push({
				property: Api.RichContentProperty.Content,
				value: debouncedSearchQuery,
			});
		} else {
			newFilterRequest.criteria?.push({
				property: Api.RichContentProperty.All,
				value: 'All',
			});
		}
		if (dateRange?.start) {
			newFilterRequest.criteria?.push(
				// @ts-ignore
				formatDateCriteria<Api.RichContentProperty>(Api.RichContentProperty.CreationDate, dateRange)
			);
		}

		if (selectedFilterOption.dataContext === 'ownedBy') {
			newFilterRequest.criteria?.push({
				property: Api.RichContentProperty.OwnedBy,
				value: assignedToUser?.id,
			});
		}

		return newFilterRequest;
	}, [debouncedSearchQuery, dateRange, selectedFilterOption.dataContext, assignedToUser]);
	const sort = React.useMemo<Api.ISortDecriptor<NotesSearchSortByType>>(() => {
		let newSort: 'asc' | 'desc' = 'asc';
		let sortBy: NotesSearchSortByType = 'lastModifiedDate';
		switch (selectedSortFilterOption.dataContext) {
			case 'LastModifiedDate-asc':
				newSort = 'asc';
				sortBy = 'lastModifiedDate';
				break;
			case 'LastModifiedDate-dsc':
				newSort = 'desc';
				sortBy = 'lastModifiedDate';
				break;
			case 'CreationDate-asc':
				newSort = 'asc';
				sortBy = 'creationDate';
				break;
			case 'CreationDate-dsc':
				newSort = 'desc';
				sortBy = 'creationDate';
				break;
			default:
				break;
		}
		return { sort: newSort, sortBy };
	}, [selectedSortFilterOption]);

	const onRangeChangedCallback = (range: IRange<Date | null>) => {
		const nextDateRange = {
			start: range.start,
			end: range.end,
		};
		// @ts-ignore
		setDateRange(nextDateRange);
	};

	const onSelectAnEmployeeClose = (user?: Api.UserViewModel, cancelled?: boolean) => {
		if (cancelled) {
			setSelectedFilterOption(NoteOptions[1]);
		} else {
			setSelectedFilterOption(NoteOptions[2]);
			setAssignedToUser(user);
		}
		setShowSelectEmployeeModal(false);
	};

	const notesSearchQuery = useInfiniteNotesSearch({
		currentUserOnly: selectedFilterOption.dataContext === 'byMe',
		enabled: activeQueryType === 'search',
		query: debouncedSearchQuery,
		filterRequest,
		sort: sort.sort,
		sortBy: sort.sortBy,
	});

	const notesByMeQuery = useInfiniteNotesSearch({
		currentUserOnly: selectedFilterOption.dataContext === 'byMe',
		enabled: activeQueryType === 'byMe',
		query: debouncedSearchQuery,
		filterRequest,
		sort: sort.sort,
		sortBy: sort.sortBy,
	});

	const notesByAccountQuery = useInfiniteNotesSearch({
		currentUserOnly: selectedFilterOption.dataContext === 'byMe',
		enabled: activeQueryType === 'byAccount',
		query: debouncedSearchQuery,
		filterRequest,
		sort: sort.sort,
		sortBy: sort.sortBy,
	});

	const notesOwnedByQuery = useInfiniteNotesSearch({
		currentUserOnly: selectedFilterOption.dataContext === 'byMe',
		enabled: activeQueryType === 'ownedBy' && Boolean(assignedToUser),
		query: debouncedSearchQuery,
		filterRequest,
		sort: sort.sort,
		sortBy: sort.sortBy,
	});

	const handleInvalidateAllNotes = () => {
		invalidateNotesByAccount();
		invalidateNotesByMe();
		invalidateNotesSearch();
	};
	const deleteNoteMutation = useDeleteNoteMutation({
		onError: error => {
			logApiError('DeleteNote-Error', error);
			// @ts-ignore
			errorMessages.pushApiError(error);
		},
		onSuccess: handleInvalidateAllNotes,
	});
	React.useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedSearchQuery(searchQuery);
		}, 300);
		return () => {
			clearTimeout(handler);
		};
	}, [searchQuery]);
	const activeQuery = {
		byAccount: notesByAccountQuery,
		byMe: notesByMeQuery,
		search: notesSearchQuery,
		ownedBy: notesOwnedByQuery,
	}[activeQueryType];
	if (
		activeQueryType === 'byAccount' &&
		notesByAccountQuery.status === 'success' &&
		// @ts-ignore
		notesByAccountQuery.data.pages[0].values.length === 0
	) {
		return (
			<FabCalloutPlaceholder
				icon={<NotesPlaceholderIcon />}
				subtitle='Add your first note with the blue round button on the bottom right.'
				title='You don’t have any notes yet.'
			/>
		);
	}
	const onMoreMenuItemClicked = (note: Api.NoteViewModel, menuItem: IMoreMenuItem<string>) => {
		switch (menuItem.representedObject) {
			case 'edit': {
				logInput('Edit', 'Click');
				// @ts-ignore
				onEditNote(note.id);
				break;
			}
			case 'delete': {
				logInput('Delete', 'Click');
				setNoteToDelete(note);
				break;
			}
			default: {
				break;
			}
		}
	};
	const onDeleteNoteConfirmationDialogRequestClose = (
		result?: IConfirmationDialogOption<boolean>,
		canceled?: boolean
	) => {
		if (result && !canceled && noteToDelete) {
			logEvent('DeleteNote', { id: noteToDelete.id });
			// @ts-ignore
			deleteNoteMutation.mutate({ id: noteToDelete.id });
		}
		setNoteToDelete(null);
	};

	const renderSelectTrigger = ({ dataContext, text }: ISelectOption<NotesFilterOptionValue>) => {
		return dataContext === 'ownedBy' ? (
			// @ts-ignore
			<div>Owned by {getDisplayName(assignedToUser) || '...'}</div>
		) : (
			<div>{text}</div>
		);
	};

	return (
		<>
			<div className={css(styleSheet.searchHeader)}>
				<div>
					<div className={css(styleSheet.searchHeaderWrap)}>
						<TextInput
							className={css(styleSheet.searchField)}
							inputId='notes-search'
							leftAccessory={<SearchIcon className={css(styleSheet.searchIcon)} />}
							onChange={ev => setSearchQuery(ev.target.value)}
							placeholder='Search notes'
							type='text'
							value={searchQuery}
						/>
						{debouncedSearchQuery.length > 0 ? (
							<button onClick={() => setSearchQuery('')} className={css(styleSheet.clear)}>
								Clear
							</button>
						) : null}
					</div>
				</div>
				<div className={css(styleSheet.searchContainer)}>
					<Select
						options={NoteSortOptions}
						onOptionClick={option => {
							setSelectedSortFilterOption(option as ISelectOption<NotesSortOptionValue>);
							setSelectedSortText(option.text);
						}}
						selectedOption={{ ...selectedDateFilterOption, text: selectedSortText }}
						styles={[styleSheet.sortSelect]}
					/>
					<Select
						options={NoteDateOptions}
						onOptionClick={option => {
							setSelectedDateFilterOption(option);
							if (!dateRange?.start) {
								setDateRange({
									start: new Date(),
									end: new Date(),
								});
							}
							setShowDateSelector(true);
						}}
						selectedOption={{ ...selectedDateFilterOption, text: selectedDateRangeText }}
						styles={[styleSheet.dateSelect]}
					/>
					<DateRangePickerSideModal
						isOpen={showDateSelector}
						onRequestClose={(clear = false) => {
							setShowDateSelector(false);
							if (clear) {
								setSelectedDateRangeText(DefaultDateRangeText);
								// @ts-ignore
								onRangeChangedCallback({ start: null, end: null });
							} else {
								setSelectedDateRangeText(formatDateRangeString(dateRange));
							}
						}}
						onRangeChange={onRangeChangedCallback}
						range={dateRange}
					/>
					<Select
						styles={[styleSheet.filterSelect]}
						onOptionClick={option => {
							setSelectedFilterOption(option);
							if (option.dataContext === 'ownedBy') {
								setShowSelectEmployeeModal(true);
							}
						}}
						options={NoteOptions}
						optionStyles={[styleSheet.filterSelectOption]}
						selectedOption={selectedFilterOption}
						selectedOptionTitle={renderSelectTrigger}
					/>
					<UserSelectModal
						bodyText='Filter notes by those owned by an employee.'
						// className={`${css(styleSheet.selectAnEmployeeModal)}`}
						modalProps={{
							isOpen: showSelectEmployeeModal,
							onRequestClose: onSelectAnEmployeeClose,
							shouldCloseOnOverlayClick: true,
						}}
						title='Select an Employee'
					/>
				</div>
			</div>

			<div className={css(styleSheet.notesList)}>
				{activeQuery.status === 'loading' ? <LoadingSpinner type='large' /> : null}
				{activeQuery.status === 'success'
					? activeQuery.data.pages.map(group =>
							// @ts-ignore
							// @ts-ignore
							group.values.map(note => {
								const noteVm = new Api.NoteViewModel(userSession, note);
								const moreMenuItems = [
									noteVm.canEdit ? EditMoreMenuItem : null,
									noteVm.canDelete ? DeleteMoreMenuItem : null,
								].filter(y => !!y);
								return (
									<NoteCard
										className={css(styleSheet.note)}
										key={note.id}
										// @ts-ignore
										moreMenuItems={moreMenuItems}
										note={noteVm}
										onMoreMenuItemClicked={menuItem => onMoreMenuItemClicked(noteVm, menuItem)}
									/>
								);
							})
						)
					: null}
				{activeQuery.hasNextPage && !activeQuery.isFetchingNextPage ? (
					// @ts-ignore
					<Portal destination={scrollToBottomPortalId}>
						<Waypoint key='notes-list' bottomOffset='-200px' onEnter={() => activeQuery.fetchNextPage()} />
					</Portal>
				) : null}
				{activeQuery.isFetchingNextPage ? <LoadingSpinner type='small' /> : null}
			</div>
			<LocalNotificationObserver topic={Topics.CREATE_NOTE} onNotificationReceived={handleInvalidateAllNotes} />
			<ConfirmationDialog
				icon={<WarningIcon />}
				modalProps={{
					isOpen: !!noteToDelete,
					onRequestClose: onDeleteNoteConfirmationDialogRequestClose,
				}}
				options={[
					{
						isDestructive: true,
						representedObject: true,
						title: 'Delete',
					},
					{
						isCancel: true,
						representedObject: false,
						title: 'Cancel',
					},
				]}
				title='Are you sure you want to delete this note?'
			/>
		</>
	);
}

export const NotesList = observer(_NotesList);
