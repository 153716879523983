import {
	borderColor,
	brandPrimary,
	brandPrimaryText,
	brandPrimaryHoverTint,
	header,
	navigation,
	success,
	white,
} from '../../styles/colors';
import { StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	addButtonContainer: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
	},
	addButton: {
		height: 38,
		whiteSpace: 'nowrap',
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
	},
	comboBox: {
		borderBottomRightRadius: 8,
		borderTopRightRadius: 8,
		borderBottomLeftRadius: 0,
		borderTopLeftRadius: 0,
		marginLeft: -1,
	},
	comboBoxDropdown: {
		marginTop: '41px !important',
	},
	comboBoxRight: {
		marginLeft: -1,
		...CssSelectors.allChildren(
			{
				borderTopLeftRadius: '0 !important',
				borderBottomLeftRadius: '0 !important',
			},
			'.text-input'
		),
	},
	comboBoxRightAutocomplete: {
		marginLeft: -1,
		width: 300,
		borderTopLeftRadius: '0 !important',
		borderBottomLeftRadius: '0 !important',
		borderTopRightRadius: '8px !important',
		borderBottomRightRadius: '8px !important',
	},
	comboBoxRightFullHeight: {
		marginLeft: -1,
		height: '100%',
		width: 300,
		...CssSelectors.allChildren(
			{
				borderTopLeftRadius: '0 !important',
				borderBottomLeftRadius: '0 !important',
			},
			'.autocomplete-search-field-input'
		),
	},
	amountText: {
		color: success,
		fontSize: 18,
	},
	contact: {
		display: 'inline-flex',
		margin: '4px 0 0 4px',
		maxWidth: '80%',
	},
	dropdownCaret: {
		alignItems: 'center',
		borderLeft: `1px solid rgb(0, 85, 218)`,
		display: 'flex',
		padding: '10px 10px',
		color: white,
		backgroundColor: brandPrimary,
		borderTopRightRadius: 8,
		borderBottomRightRadius: 8,
		height: 38,
		boxShadow: '0 1px 2px 0 rgba(140,140,140,0.50)',
	},
	importButton: {},
	tableContainer: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '80%',
	},
	noResults: {
		flex: '1 1',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		gap: 12,
		padding: 24,
		borderLeft: `1px solid ${borderColor}`,
		borderRight: `1px solid ${borderColor}`,
		boxShadow: '0px 0px 6px rgba(139, 139, 139, 0.1)',
		boxSizing: 'border-box',
	},
	email: {
		color: header,
		fontSize: 12,
		margin: 0,
		marginTop: 4,
	},
	header: {
		display: 'flex',
		gap: 16,
	},
	indented: {
		paddingLeft: 16,
		justifyContent: 'space-between',
	},
	iconButton: {
		alignItems: 'center',
		display: 'flex',
		gap: 8,
		fontSize: 14,
	},
	name: {
		margin: 0,
		color: brandPrimaryText,
	},
	searchPropertySelector: {
		borderBottomRightRadius: 0,
		borderTopRightRadius: 0,
		borderBottomLeftRadius: 8,
		borderTopLeftRadius: 8,
		minWidth: 180,
	},
	searchFieldAnchor: {
		borderLeft: 'none',
		borderBottomLeftRadius: '0 !important',
		borderTopLeftRadius: '0 !important',
		height: 42,
	},
	searchIcon: {
		marginRight: 6,
		flexShrink: 0,
	},
	selectContainer: {
		minWidth: 135,
	},
	propertySearch: {
		display: 'flex',
	},
	leftHeader: {
		display: 'flex',
		gap: 12,
	},
	rightHeader: {
		display: 'flex',
		gap: 12,
		alignItems: 'center',
	},
	tableOverrides: {
		paddingBottom: 0,
		paddingTop: 0,
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
	},
	totalRow: {
		position: 'sticky',
		bottom: 0,
		backgroundColor: brandPrimaryHoverTint,
		display: 'flex',
		gap: 36,
		justifyContent: 'space-between',
		padding: 16,
		border: `1px solid ${borderColor}`,
		boxShadow: '0px 0px 6px rgba(139, 139, 139, 0.1)',
		boxSizing: 'border-box',
		borderBottomLeftRadius: 6,
		borderBottomRightRadius: 6,
	},
	stat: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	statHeader: {
		display: 'flex',
		alignItems: 'center',
		fontWeight: 600,
	},
	statTitle: {
		color: navigation,
		fontSize: 12,
		fontWeight: 600,
		margin: 0,
		textTransform: 'uppercase',
	},
	statValue: {
		color: brandPrimaryText,
		fontWeight: 600,
		fontSize: 18,
	},
	totalValue: {
		color: success,
		fontWeight: 600,
		fontSize: 18,
	},
	clearSearch: {
		width: '100%',
	},
	noMargin: {
		margin: 0,
	},
	mediumInput: {
		width: 200,
	},
	wideInput: {
		width: 300,
		flexShrink: 0,
	},
});
