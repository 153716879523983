import { FullscreenModalHeaderContext } from '@AppModels/.';
import { Portal } from '@WebComponents/Portal';
import { css } from 'aphrodite';
import * as React from 'react';
import { DisclosureIcon } from '../../svgs/icons/DisclosureIcon';
import { styleSheet } from './styles';
import { brandPrimary } from '../../../styles/colors';
import { CloseButton } from '../../CloseButton';

interface IFullscreenModalNavHeaderProps {
	next: () => void;
	back: () => void;
}

export const FullscreenModalNavHeader: React.FC<IFullscreenModalNavHeaderProps> = ({ next, back, children }) => {
	return (
		<FullscreenModalHeaderContext.Consumer>
			{({
				onClose,
				headerTitlePortalDestinationId,
				headerRightAccessoryPortalDestinationId,
				headerLeftAccessoryPortalDestinationId,
			}) => {
				const onNext = () => {
					next();
				};
				const onBack = () => {
					back();
				};
				return (
					<>
						{!!headerLeftAccessoryPortalDestinationId && (
							<Portal destination={headerLeftAccessoryPortalDestinationId}>
								<button onClick={onBack}>
									<div className={css(styleSheet.navIcon, styleSheet.backIcon)}>
										<DisclosureIcon type='chevron' fillColor={brandPrimary} />
									</div>
								</button>
							</Portal>
						)}
						{!!headerTitlePortalDestinationId && (
							<Portal destination={headerTitlePortalDestinationId}>
								<div className={css(styleSheet.container)}>{children}</div>
							</Portal>
						)}
						{!!headerRightAccessoryPortalDestinationId && (
							<Portal destination={headerRightAccessoryPortalDestinationId}>
								<div className={css(styleSheet.rightNav)}>
									<button onClick={onNext}>
										<div className={css(styleSheet.navIcon, styleSheet.nextIcon)}>
											<DisclosureIcon type='chevron' fillColor={brandPrimary} />
										</div>
									</button>

									<CloseButton onClick={onClose} />
								</div>
							</Portal>
						)}
					</>
				);
			}}
		</FullscreenModalHeaderContext.Consumer>
	);
};
