import { IEntity, INote, IUser, RichContentContextSource, RichContentReferenceMethod } from '@ViewModels';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { v4 as uuidgen } from 'uuid';
import {
	INoteComposerComponentProps,
	IToasterComponentProps,
	IUserSessionComponentProps,
	NoteComposerViewModelKey,
	ToasterViewModelKey,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { Topics } from '../../../../models/LocalNotificationTopics';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import {
	EntityViewModel,
	NoteComposerViewModel,
	NoteViewModel,
	RichContentComposerResult,
	VmUtils,
} from '../../../../viewmodels/AppViewModels';
import { LoadingSpinner } from '../../LoadingSpinner';
import {
	INotificationServiceComponentProps,
	withNotificationService,
} from '../../LocalNotificationObserver/WithNotificationService';
import { MentionEntitiesContext } from '../../mentions/MentionEntitiesContext';
import { Modal } from '../../Modal';
import { PortalDestination } from '../../Portal';
import { CallIcon } from '../../svgs/icons/CallIcon';
import { VisibilityDropdown } from '../../VisibilityDropdown';
import { CCField } from '../CCField';
import { NoteEditor } from '../NoteEditor';
import NoteComposerCloseIconUrl from './noteComposerClose.svg';
import NoteComposerIconUrl from './noteComposerIcon.svg';
import './styles.less';

interface IProps
	extends IUserSessionComponentProps,
		IToasterComponentProps,
		INotificationServiceComponentProps<INote>,
		IEventLoggingComponentProps {
	className?: string;
	noteComposer: NoteComposerViewModel;
	onComplete?(note: NoteViewModel, result: RichContentComposerResult): void;
	onToggleMinimizeClicked?(): boolean;
	toastOnSaveSucces?: boolean;
}

interface IState {
	ccUsers?: IUser[];
	editingExistingNote?: boolean;
	isPublic?: boolean;
	mentionedEntities?: EntityViewModel<IEntity>[];
	showingCcField?: boolean;
}

class _NoteComposer extends React.Component<IProps, IState> {
	private mNoteEditorHeaderPortalId = uuidgen();
	public static defaultProps: Partial<IProps> = {
		toastOnSaveSucces: true,
	};
	public state: IState = {};

	public UNSAFE_componentWillMount() {
		const { noteComposer, userSession } = this.props;
		const isPublic =
			!!noteComposer.richContent && !!noteComposer.richContent.id
				? noteComposer.richContent.isPublic
				: VmUtils.getDefaultVisibility(userSession.user) === 'all';

		// collect/show all implicit and explicit (but not background) mentionedEntities
		const mentionedEntities = [
			...(noteComposer.richContent ? noteComposer.richContent.mentionedEntities || [] : []).reduce((result, x) => {
				if (x.method !== RichContentReferenceMethod.Background) {
					result.push(x.entity);
				}
				return result;
			}, [] as EntityViewModel<IEntity>[]),
		];

		const ccUsers = (noteComposer.richContent.userReferences || [])
			.filter(x => x.method === RichContentReferenceMethod.Explicit)
			.map(x => x.entity);
		this.setState({
			ccUsers: isPublic ? ccUsers : [],
			editingExistingNote: !!noteComposer.richContent && !!noteComposer.richContent.id,
			isPublic,
			mentionedEntities,
		});
	}

	public render() {
		const { className, noteComposer } = this.props;

		if (!noteComposer.richContent) {
			return null;
		}

		return (
			<div className={`note-composer ${className || ''}`}>
				<div className='note-composer-header'>
					{this.isFromPhoneCall ? <CallIcon /> : <img src={NoteComposerIconUrl} />}
					<div>
						<button className='note-composer-header-close-button' onClick={this.onCancel}>
							<img src={NoteComposerCloseIconUrl} />
						</button>
					</div>
				</div>
				<div className='note-composer-body'>
					<div className='note-composer-body-left'>{this.onRenderLeftContent()}</div>
					<div className='note-composer-body-right'>{this.renderRightContent()}</div>
				</div>
			</div>
		);
	}

	@computed
	private get noteContext() {
		const { noteComposer } = this.props;
		return !!noteComposer && !!noteComposer.richContent ? noteComposer.richContent.context : null;
	}

	@computed
	private get isFromPhoneCall() {
		return !!this.noteContext && this.noteContext.source === RichContentContextSource.PhoneCall;
	}

	@computed
	private get isFromMeeting() {
		return !!this.noteContext && this.noteContext.source === RichContentContextSource.Meeting;
	}

	private onCancel = () => {
		const { onComplete } = this.props;
		if (onComplete) {
			onComplete(null, RichContentComposerResult.None);
		}
	};

	private onRenderLeftContent() {
		const { noteComposer, userSession } = this.props;
		const { showingCcField, isPublic, ccUsers, mentionedEntities } = this.state;
		return (
			<div className='note-composer-editor'>
				<div className='note-composer-editor-header'>
					<div className='note-composer-editor-header-title'>{this.renderTitle()}</div>
					<div className='note-composer-editor-header-options'>
						<VisibilityDropdown
							onVisibilityChanged={this.onVisibilityChanged}
							visibility={isPublic ? 'all' : 'admin'}
						/>
						{!showingCcField && !!isPublic && (
							<button className='note-composer-editor-add-cc-button brand-link' onClick={this.onAddCcButtonClicked}>
								Add Cc
							</button>
						)}
					</div>
					{!!showingCcField && !!isPublic && (
						<CCField
							ccUsers={ccUsers}
							className='note-composer-editor-cc-field'
							onCCUsersChanged={this.onCcUsersChanged}
							userSession={userSession}
						/>
					)}
					<PortalDestination id={this.mNoteEditorHeaderPortalId} />
				</div>
				<div className='note-composer-editor-body'>
					<NoteEditor
						alwaysShowsSaveOptions={true}
						autoFocus={true}
						ccUsers={ccUsers}
						className='note-composer-editor-note-editor'
						headersPortalId={this.mNoteEditorHeaderPortalId}
						hideMeetingAttendeesHeader={true}
						hideMentionField={true}
						hidesCCField={true}
						hidesPrivacyToggle={true}
						isPublicNote={isPublic}
						mentionedEntities={mentionedEntities}
						note={noteComposer.richContent}
						onNoteSaved={this.onSaveSuccess}
						popoverClassName='note-composer-editor-popover'
						sendMessageDisabled={true}
						userSession={userSession}
					/>
					{!!noteComposer.richContent.isSaving && <LoadingSpinner className='absolute-center' type='large' />}
				</div>
			</div>
		);
	}

	private renderTitle() {
		const { noteComposer } = this.props;
		const meetingTitle = this.isFromMeeting ? noteComposer.richContent.context.name : null;
		if (noteComposer.richContent.id) {
			if (this.isFromPhoneCall) {
				return 'Note about your phone call';
			}
			return `${
				this.isFromMeeting
					? `${meetingTitle ? `Note about your meeting ${`"${meetingTitle}"`}` : 'Note about a meeting'}`
					: 'Note'
			}`;
		} else {
			if (this.isFromPhoneCall) {
				return 'Add Phone Call';
			}
			return `Add a note${this.isFromMeeting ? ` about your meeting${meetingTitle ? ` "${meetingTitle}"` : ''}` : ''}`;
		}
	}

	private onVisibilityChanged = (visibility: string) => {
		const nextState: IState = {
			isPublic: visibility === 'all',
		};
		if (nextState.isPublic) {
			nextState.showingCcField = false;
			nextState.ccUsers = [];
		}
		this.setState(nextState);
	};

	private onCcUsersChanged = (ccUsers: IUser[]) => {
		this.setState({
			ccUsers,
		});
	};

	private onSaveSuccess = (noteModel: INote) => {
		const { noteComposer, toaster, postNotification, onComplete, toastOnSaveSucces } = this.props;
		const { editingExistingNote } = this.state;

		if (!!toaster && !!toastOnSaveSucces) {
			toaster.push({
				linkTitle: 'See Notes',
				message: 'Your note was saved.',
				onLinkClicked: routerProps => {
					routerProps.history.push('/notes-action-items?activeTab=notes');
				},
				type: 'successMessage',
			});
		}

		if (postNotification) {
			postNotification({
				info: noteModel,
				topic: editingExistingNote ? Topics.EDIT_NOTE : Topics.CREATE_NOTE,
			});
		}

		if (onComplete) {
			onComplete(
				noteComposer.richContent,
				editingExistingNote ? RichContentComposerResult.Edit : RichContentComposerResult.Create
			);
		}
	};

	private onAddCcButtonClicked = () => {
		this.setState({
			showingCcField: true,
		});
	};

	private renderRightContent() {
		const { mentionedEntities } = this.state;
		return (
			<MentionEntitiesContext
				className='note-composer-referenced-contacts'
				contextItemName={this.isFromPhoneCall ? 'phone call' : 'note'}
				mentionedEntities={mentionedEntities}
				onMentionedEntitiesChanged={this.onMentionedEntitiesChanged}
			/>
		);
	}

	private onMentionedEntitiesChanged = (mentionedEntities: EntityViewModel<IEntity>[]) => {
		this.setState({
			mentionedEntities,
		});
	};
}

const NoteComposerAsObserver = observer(_NoteComposer);
const NoteComposerWithNotificationService = withNotificationService(NoteComposerAsObserver);
const NoteComposerWithContext = inject(UserSessionViewModelKey)(NoteComposerWithNotificationService);
export const NoteComposer = withEventLogging(NoteComposerWithContext, 'NoteComposer');

class _NoteComposerModal extends React.Component<INoteComposerComponentProps & IToasterComponentProps> {
	public render() {
		const { noteComposer, toaster } = this.props;
		return (
			<Modal className='note-composer-modal' isOpen={!!noteComposer.richContent} shouldCloseOnOverlayClick={false}>
				<NoteComposer noteComposer={noteComposer} toaster={toaster} onComplete={noteComposer.onComplete} />
			</Modal>
		);
	}
}

const NoteComposerModalAsObserver = observer(_NoteComposerModal);
export const NoteComposerModal = inject(NoteComposerViewModelKey, ToasterViewModelKey)(NoteComposerModalAsObserver);
