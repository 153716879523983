import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	item: {
		cursor: 'pointer',
	},
	list: {
		textAlign: 'center',
	},
});
