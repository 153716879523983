import { grayIconFill, titles } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	centerLabel: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		left: '50%',
		position: 'absolute',
		textAlign: 'center',
		top: '50%',
		transform: 'translate(-50%, -50%)',
	},
	centerLabelPercent: {
		color: titles,
		fontFamily: 'Sora',
		fontSize: '1.25rem',
	},
	centerLabelText: {
		color: grayIconFill,
		fontFamily: 'Sora',
		fontSize: '0.75rem',
		marginTop: '0.25rem',
		textTransform: 'uppercase',
	},
	progressIndicator: {
		transform: 'rotate(-90deg)',
		transformOrigin: '50% 50%',
	},
	root: {
		position: 'relative',
	},
	smCenterLabelPercent: {
		fontSize: '1rem',
	},
	smCenterLabelText: {
		fontSize: '0.5rem',
	},
});
