import { styleSheet } from './styles';
import { StyleDeclarationValue, css } from 'aphrodite';
import { clsx } from 'clsx';
import * as React from 'react';

interface IProps {
	className?: string;
	children: React.ReactNode;
	styleDeclaration?: StyleDeclarationValue[] | StyleDeclarationValue;
}

export const DashboardCardHeaderLabel = ({ className, children, styleDeclaration }: IProps) => {
	const styleDeclarations = Array.isArray(styleDeclaration) ? styleDeclaration : [styleDeclaration];
	return <h5 className={clsx(css(styleSheet.headerLabel, ...styleDeclarations), className)}>{children}</h5>;
};
