import { ISocialMediaPost } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { isTemplateNew } from '../../../../models/UiUtils';
import { useLambda } from '../../../../models/hooks/useLambda';
import { ITemplateCard } from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet, bs } from '../../../styles/styles';
import { PopoverType, TinyPopover } from '../../TinyPopover';
import { CampaignExpiryHint } from '../../campaigns/CampaignExpiryHint';
import '../../richContent/RichContentDocumentEditor/placeholders.tinymce.css';
import {
	BasicImagePreview,
	BasicVideoPreview,
	InstagramImagePreview,
	PreviewSize,
} from '../../socialMedia/PreviewSocialMediaPost/presentation';
import { ImageMissingIcon } from '../../svgs/icons/ImageMissingIcon';
import { SocialMediaIndicatorIcon } from '../../svgs/icons/SocialMediaIndicatorIcon';
import { SocialCampaignTemplateCardSummary } from './presentation';
import { styleSheet } from './styles';

interface Props {
	card: ITemplateCard | ISocialMediaPost;
	className?: string;
	ctaText?: string;
	isFeatured?: boolean;
	onCtaClicked?(): void;
	onAssociatedTemplateClicked?(e: React.MouseEvent<Element>): void;
	styles?: StyleDeclarationValue[];
	contextMenu?: React.ReactNode;
}

export function SocialCampaignTemplateCard(props: Props) {
	const { card, className, ctaText, onCtaClicked, onAssociatedTemplateClicked, styles } = props;
	const [showAssociatedPopover, , setShowAssociatedPopover] = useLambda(false);

	const onAssociatedIndicatorClicked = (e: React.MouseEvent<SVGSVGElement>) => {
		setShowAssociatedPopover(false)();
		onAssociatedTemplateClicked?.(e);
	};
	const tCard = card as ITemplateCard;
	const expirationDate = tCard?.schedule?.expirationDate ? moment(tCard?.schedule?.expirationDate) : undefined;
	const hasAssociatedTemplateOrCampaign = tCard.associatedTemplates?.length > 0;

	return (
		<div
			className={`${css(styleSheet.container, styleSheet.containerPost, ...(styles || []))}
			social-campaign-template-card
			${className || ''}`}
		>
			<div className={css(bs.flex)}>
				<div
					className={`campaign-template-card-name ${css(
						!hasAssociatedTemplateOrCampaign && styleSheet.name,
						styleSheet.postName,
						baseStyleSheet.truncateText
					)}`}
				>
					<div
						className={css(
							bs.bgBackground,
							bs.flex,
							bs.gap2,
							bs.itemsCenter,
							bs.justifyBetween,
							bs.boxBorder,
							bs.border0,
							bs.borderB,
							bs.borderSolid,
							bs.borderNameCircles,
							bs.py4,
							bs.pl4,
							bs.pr2,
							bs.wFull
						)}
					>
						<div className={css(bs.truncateText, bs.flex, bs.itemsCenter, bs.gap2)}>
							<span className={css(bs.truncateText, bs.fontBold, bs.textTitles)}>{card.name || 'Custom Post'}</span>
							{expirationDate ? <CampaignExpiryHint expirationDate={expirationDate} /> : null}
							{isTemplateNew(tCard) ? <span className={css(styleSheet.newItem)}>(New!)</span> : null}
						</div>
						{props.contextMenu ?? null}
					</div>
				</div>
				{hasAssociatedTemplateOrCampaign && (
					<TinyPopover
						align='end'
						anchor={
							<SocialMediaIndicatorIcon
								className={css(
									bs.absolute,
									bs.top0,
									bs.right0,
									bs.cursorPointer,
									showAssociatedPopover && styleSheet.flagHighlight
								)}
								fillColor='#89C947'
								onClick={onAssociatedIndicatorClicked}
								onMouseEnter={setShowAssociatedPopover(true)}
								onMouseLeave={setShowAssociatedPopover(false)}
							/>
						}
						dismissOnOutsideAction={true}
						isOpen={showAssociatedPopover}
						placement={['top', 'left']}
						type={PopoverType.white}
					>
						<div className={css(styleSheet.popover)}>Also available as an email campaign.</div>
					</TinyPopover>
				)}
			</div>
			<div className={css(styleSheet.summaryPadding)}>
				<SocialCampaignTemplateCardSummary ctaClicked={onCtaClicked} content={card?.content} bigScreenCutoff={120} />
			</div>
			{card?.attachments?.length > 0 ? (
				card?.attachments?.[0].mimeType?.startsWith('video/') ? (
					<BasicVideoPreview
						previewSize={PreviewSize.Sm}
						postImages={card?.attachments}
						onCtaClick={onCtaClicked}
						controls={false}
					/>
				) : card?.attachments?.length > 1 ? (
					<InstagramImagePreview
						previewSize={PreviewSize.Sm}
						postImages={card?.attachments}
						onCtaClick={onCtaClicked}
					/>
				) : (
					<BasicImagePreview previewSize={PreviewSize.Sm} postImages={card?.attachments} onCtaClick={onCtaClicked} />
				)
			) : (
				<p className={css(styleSheet.imageContainer)}>
					<ImageMissingIcon />
				</p>
			)}
			{onCtaClicked && (
				<button className={css(baseStyleSheet.ctaButtonReverseSmall, styleSheet.button)} onClick={onCtaClicked}>
					<span>{ctaText || 'View Campaign'}</span>
				</button>
			)}
		</div>
	);
}
