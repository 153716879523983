import { useToggle } from '../../../../models/hooks/useToggle';
import { Checkbox } from '../../Checkbox';
import { Tag } from '../../tags/Tag';
import { styleSheet } from './styles';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	tag: string;
	isActive?: boolean;
	leftAccessory?: JSX.Element;
	updateSelectedTagList?(): void;
	showRemoveButton?: boolean;
	onRemoveButtonClicked?(tagValue: string): void;
	hideCheckbox?: boolean;
}

export const ContactsGutterTag: React.FC<IProps> = props => {
	const {
		tag,
		isActive,
		updateSelectedTagList,
		showRemoveButton,
		onRemoveButtonClicked,
		className,
		hideCheckbox,
		leftAccessory,
	} = props;
	const [isClicked, , setClicked] = useToggle(false);

	if (!tag) {
		return null;
	}

	return (
		<li
			key={tag}
			title={tag}
			className={`${css(isActive ? styleSheet.tagItemSelected : styleSheet.tagItem)} ${className}`}
			onClick={updateSelectedTagList}
			onMouseDown={setClicked(true)}
			onMouseUp={setClicked(false)}
			style={isClicked ? { transform: 'translate3d(0,1px,0)', transition: 'transform 0.1s' } : {}}
		>
			{!hideCheckbox && (
				<Checkbox id={tag} checked={isActive} readOnly={true} className={css(styleSheet.disableTouch)} />
			)}
			<Tag
				leftAccessory={leftAccessory}
				tagValue={tag}
				styles={isActive ? [styleSheet.tagSelected] : []}
				showRemoveButton={showRemoveButton}
				onRemoveButtonClicked={onRemoveButtonClicked}
				className={css(styleSheet.tag)}
			/>
		</li>
	);
};
