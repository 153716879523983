import { brandPrimaryHover, header, nameCircles } from '../../styles/colors';
import { BreakPoints, BrowserMediaQueries } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const AppBarHeight = 96;

export const styleSheet = StyleSheet.create({
	appBar: {
		background: '#fff',
		borderBottom: 'none',
		boxSizing: 'border-box',
		height: AppBarHeight,
		minHeight: AppBarHeight,
		padding: 0,
		zIndex: 1,
	},
	appBarContent: {
		alignItems: 'center',
		borderBottom: `1px solid ${nameCircles}`,
		boxSizing: 'border-box',
		display: 'flex',
		height: '100%',
		justifyContent: 'space-between',
		position: 'relative',
	},
	container: {},
	tabsContainer: {
		width: 'auto',
	},
	tabViewContainer: {
		marginLeft: 265,
		marginTop: -44,
		position: 'absolute',
		zIndex: 4,
		[BreakPoints.forMaxWidth(930)]: {
			marginTop: -24,
		},
	},
	tabViewContent: {
		marginLeft: -265,
		marginTop: 44,
		position: 'absolute',
		[BreakPoints.forMaxWidth(930)]: {
			marginTop: 24,
		},
		[BrowserMediaQueries.IE11]: {
			marginTop: 4,
		},
	},
	tabViewItem: {
		background: '#F9F9F9',
		borderBottom: `1px solid #D3DBDE `,
		borderTopLeftRadius: 3,
		borderTopRightRadius: 3,
		color: header,
		display: 'inline-flex',
		flexBasis: 'auto',
		flexGrow: 0,
		fontSize: 14,
		height: 44,
		padding: '0 36px',
		textAlign: 'center',
		textTransform: 'uppercase',
		whiteSpace: 'nowrap',
		[BreakPoints.forMaxWidth(930)]: {
			height: 24,
		},
	},
	tabViewSelectedItem: {
		background: 'white !important',
		border: `1px solid #D3DBDE `,
		borderBottom: 'none !important',
		color: brandPrimaryHover,
		overflow: 'visible',
		position: 'relative',
	},
	tabViewSelectedTabIndicator: {
		display: 'none',
	},
	tabViewTabs: {
		borderBottom: 'none',
		display: 'flex',
	},
});
