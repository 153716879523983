import { StyleSheet } from 'aphrodite';
import { brandPrimary } from '../../../../styles/colors';

export const styleSheet = StyleSheet.create({
	ctaButton: {
		boxShadow: 'none',
		color: brandPrimary,
		marginTop: 20,
	},
	integrationTitle: {
		color: '#1370E0',
	},
});
