import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IModalContext, ModalChildComponentContextKey } from '../../../../models';
import { baseStyleSheet } from '../../../styles/styles';
import { asModalComponent } from '../../Modal';
import { AutomationStartedGraphic } from '../../svgs/graphics/AutomationStartedGraphic';
import { styleSheet } from './styles';

interface IProps extends IModalContext<boolean> {
	className?: string;
	styles?: StyleDeclarationValue[];
}

class _AutomationStartedNotification extends React.Component<IProps> {
	public render() {
		const { className, styles } = this.props;
		return (
			<div
				className={`${css(styleSheet.container, ...(styles || []))} automation-started-notification ${className || ''}`}
			>
				<div className={css(baseStyleSheet.flexCenter)}>
					<AutomationStartedGraphic />
				</div>
				<div className={css(styleSheet.title)}>Automation Started</div>
				<div className={css(styleSheet.message)}>
					If the first step of an automation is an email, we will start sending in 30 minutes.
				</div>
				<div className={css(baseStyleSheet.flexCenter)}>
					<button className={css(baseStyleSheet.ctaButton)} onClick={this.onDoneClick}>
						<span>Done</span>
					</button>
				</div>
			</div>
		);
	}

	private onDoneClick = () => {
		this.props.parentModal?.onRequestClose(false, false);
	};
}

const AutomationStartedNotificationAsObserver = observer(_AutomationStartedNotification);
export const AutomationStartedNotification = inject(ModalChildComponentContextKey)(
	AutomationStartedNotificationAsObserver
);
export const AutomationStartedNotificationModal = asModalComponent(AutomationStartedNotification, {
	overlayPresentationStyle: {
		className: 'modal-overlay modal-overlay-no-background',
		transitionInDuration: 450,
		transitionOutDuration: 450,
	},
	shouldCloseOnOverlayClick: false,
	useDefaultHeader: true,
});
