import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { emailScannerImageUrl } from '../../../../models/UiUtils';
import { EmailScannerToggle } from '../EmailScannerToggle';
import { styleSheet } from './styles';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import {
	AllEmailScanners,
	EmailScannerId,
	IAccount,
	IOperationResultNoValue,
	IUserPreferences,
	UserViewModel,
	VmUtils,
} from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';

interface IProps extends IEventLoggingComponentProps, IUserSessionComponentProps, IErrorMessageComponentProps {
	className?: string;
	emailScannerId: EmailScannerId;
	styles?: StyleDeclarationValue[];
}

interface IState {
	enabledForAccount?: boolean;
	enabledForUser?: boolean;
}

class _EmailScannerSettings extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = this.getNextState();
	}

	public render() {
		const { className, styles, emailScannerId, userSession } = this.props;
		const { enabledForUser, enabledForAccount } = this.state;
		// @ts-ignore
		// @ts-ignore
		const isAdmin = userSession.userRole === 'superAdmin' || userSession.userRole === 'admin';
		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} email-scanner-settings ${className || ''}`}>
				{!!isAdmin && (
					<EmailScannerToggle
						enabled={enabledForAccount}
						image={this.renderImage()}
						message={this.renderMessage(true)}
						onChange={this.onEmailScannerToggleChanged(emailScannerId, true)}
						type={emailScannerId}
					/>
				)}
				{!!enabledForAccount && (
					<EmailScannerToggle
						enabled={enabledForUser}
						image={!isAdmin ? this.renderImage() : undefined}
						message={this.renderMessage()}
						onChange={this.onEmailScannerToggleChanged(emailScannerId)}
						type={emailScannerId}
					/>
				)}
			</div>
		);
	}

	private renderImage() {
		const { emailScannerId } = this.props;
		// @ts-ignore
		return <img className={css(styleSheet.emailToggleImage)} src={emailScannerImageUrl(emailScannerId)} />;
	}

	private renderMessage(forAccount = false) {
		const { emailScannerId } = this.props;
		const emailScanner = AllEmailScanners.find(x => x.id === emailScannerId);
		return (
			<span>
				<span>
					{`Levitate can automatically tag any leads from ${name} (that were sent to ${
						forAccount ? 'users on the account' : 'your email'
					}) with the tag `}
					<span className={css(styleSheet.integrationTagName)}>{`${emailScanner?.name} Lead`}</span>.
				</span>
			</span>
		);
	}

	private getNextState = (): IState => {
		const { userSession, emailScannerId } = this.props;
		return {
			// @ts-ignore
			enabledForAccount: VmUtils.isIntegrationEnabledForAccount(emailScannerId, userSession.account.toJs()),
			// @ts-ignore
			// @ts-ignore
			// @ts-ignore
			enabledForUser: VmUtils.isIntegrationEnabledForUser(emailScannerId, userSession.user, userSession.account.toJs()),
		};
	};

	private onEmailScannerToggleChanged =
		(emailScannerId: EmailScannerId, forAccount = false) =>
		(enabled: boolean) => {
			const { userSession, logApiError, errorMessages } = this.props;
			if (forAccount) {
				// @ts-ignore
				const account = userSession.account.toJs();
				const isEnabledForAccount = VmUtils.isIntegrationEnabledForAccount(emailScannerId, account);
				if (isEnabledForAccount === enabled) {
					return Promise.resolve();
				}
				const nextAccount: IAccount = JSON.parse(JSON.stringify(account));
				if (nextAccount?.features?.emailScan?.enabledScanners) {
					nextAccount.features.emailScan.enabledScanners = enabled
						? nextAccount.features.emailScan.enabledScanners.concat([emailScannerId])
						: nextAccount.features.emailScan.enabledScanners.filter(x => x !== emailScannerId);
				}
				// @ts-ignore
				const promise = userSession.account.save(nextAccount);
				promise
					?.then(() => {
						this.setState(this.getNextState());
					})
					.catch((error: IOperationResultNoValue) => {
						// @ts-ignore
						logApiError('SaveAccount-Error', error);
						// @ts-ignore
						errorMessages.pushApiError(error);
					});
				return promise;
			} else {
				// @ts-ignore
				// @ts-ignore
				const user = new UserViewModel(userSession, userSession.user);
				// @ts-ignore
				const optedOutEmailScanners = user.preferences.optedOutEmailScanners || [];
				const index = optedOutEmailScanners.indexOf(emailScannerId);
				if ((index >= 0 && !enabled) || (index < 0 && !!enabled)) {
					return Promise.resolve();
				}
				const nextUserPreferences: IUserPreferences = JSON.parse(JSON.stringify(user.preferences));
				nextUserPreferences.optedOutEmailScanners = enabled
					? optedOutEmailScanners.filter(x => x !== emailScannerId)
					: optedOutEmailScanners.concat([emailScannerId]);
				const promise = user.updateUserPreferences(nextUserPreferences);
				promise
					?.then(() => {
						// @ts-ignore
						// @ts-ignore
						userSession.user.userPreferences = nextUserPreferences;
						this.setState(this.getNextState());
					})
					.catch((error: IOperationResultNoValue) => {
						// @ts-ignore
						logApiError('SaveUserPreferences-Error', error);
					});
				return promise;
			}
		};
}

const EmailScannerSettingsAsObserver = observer(_EmailScannerSettings);
const EmailScannerSettingsWithContext = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey
)(EmailScannerSettingsAsObserver);
export const EmailScannerSettings = withEventLogging(EmailScannerSettingsWithContext, 'EmailScannerSettings');
