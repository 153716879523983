import wealthboxLogo from '../../../../assets/logo_wealthbox_sm.svg';
import { baseStyleSheet } from '../../../../styles/styles';
import { ClioIntegrationGraphic } from '../../../svgs/graphics/ClioIntegrationGraphic';
import { IntegrationCard, useIntegrationCard } from '../IntegrationCard';
import { styleSheet, wealthboxColor } from './styles';
import * as Api from '@ViewModels';

export const DashboardWealthboxIntegrationCard = () => {
	const { getLogoImgProps, getCloseButtonProps, getCtaButtonProps, getIntegrationTitleProps, showCard } =
		useIntegrationCard({
			closeIntegrationEventName: 'WealthboxIntegrationClosed',
			enableUrlPath: '/integrations/wealthbox',
			integrationEnableEventName: 'WealthboxIntegrationEnableClick',
			pendingAction: Api.PendingActions.ConnectWealthbox,
		});

	if (!showCard) {
		return null;
	}

	return (
		<IntegrationCard
			logoBgColor={wealthboxColor}
			closeButtonProps={getCloseButtonProps()}
			IntegrationGraphic={ClioIntegrationGraphic}
			headerLabel='Wealthbox Integration'
			logoImgProps={getLogoImgProps({ alt: 'Wealthbox Logo', src: wealthboxLogo })}
			ctaButtonProps={getCtaButtonProps({
				children: 'Connect with Wealthbox',
				styleDeclaration: [baseStyleSheet.ctaButton, styleSheet.ctaButton],
			})}
			integrationTitleProps={getIntegrationTitleProps({ styleDeclaration: styleSheet.integrationTitle })}
		>
			<p>Integration Available!</p>
			<p>
				We are excited to announce that you can integrate your Wealthbox account with Levitate. With this integration,
				we will have the ability to pull client information, including names, email, phone number, birthdays,
				anniversaries, and more &mdash; making staying in touch even easier.
			</p>
		</IntegrationCard>
	);
};
