import * as Api from '@ViewModels';
import * as React from 'react';
import { Noop } from '../../../../../extViewmodels/Utils';
import { ISocialMediaConnectResult } from '../../../../../models/SocialMedia';
import { useUserSession } from '../../../../../models/hooks/appStateHooks';
import { IModalProps } from '../../../Modal';

export const useSocialMediaAuthHandler = (
	vm: Api.SocialMediaViewModel,
	result?: ISocialMediaConnectResult,
	onFinish?: (vm: Api.SocialMediaViewModel, connected: boolean, error?: Api.IOperationResultNoValue) => void
) => {
	const userSession = useUserSession();
	const [socialMediaPostTargetModalProps, setSocialMediaPostTargetModalProps] = React.useState<
		IModalProps<Api.ISocialMediaPage>
	>({
		isOpen: false,
		onRequestClose: Noop,
	});

	const onFinishCallbackRef =
		React.useRef<(vm: Api.SocialMediaViewModel, connected: boolean, error?: Api.IOperationResultNoValue) => void>(
			onFinish
		);
	onFinishCallbackRef.current = onFinish;

	const handleSuccess = React.useCallback(async () => {
		try {
			const userId = result.userId;
			const filteredConnections = userSession?.user?.socialMediaConnectedAccounts.filter(
				x =>
					x?.type === vm.getConnectionType() &&
					x.encryptedBlobId &&
					x?.state !== Api.SocialMediaConnectionState.Connected &&
					(userId ? x?.userId === userId : true)
			);
			const selectedConnection = filteredConnections.length
				? filteredConnections[filteredConnections.length - 1]
				: null;
			await vm.loadPages(selectedConnection);
			if (vm.pages.length === 0) {
				onFinishCallbackRef.current?.(
					vm,
					false,
					Api.asApiError(
						'There are no post targets to choose from for the given account. Please add a page or choose a different account to connect to.'
					)
				);
				vm.disconnect(selectedConnection);
				return;
			}

			if (vm.pages.length === 1) {
				await vm.setPostTargetPage(vm.pages[0], selectedConnection);
				onFinishCallbackRef.current?.(vm, true);
				return;
			}

			// open the modal and let the user select the post target
			setSocialMediaPostTargetModalProps(value => {
				return {
					...value,
					isOpen: true,
					onRequestClose: async (page, cancel) => {
						if (vm.isBusy) {
							return;
						}
						setSocialMediaPostTargetModalProps(currentValue => {
							return {
								...currentValue,
								isOpen: false,
								onRequestClose: Noop,
							};
						});

						if (page && !cancel) {
							try {
								await vm.setPostTargetPage(page, selectedConnection);
								onFinishCallbackRef.current?.(vm, true);
							} catch (err) {
								onFinishCallbackRef.current?.(vm, false, err);
							}
						} else if (cancel) {
							vm.resetConnectionsToUsers(
								userSession,
								vm.connections.find(x => x.postTargetId === page?.id)
							);
							onFinishCallbackRef.current?.(vm, false);
						}
					},
				};
			});
		} catch (error) {
			setSocialMediaPostTargetModalProps(value => {
				return {
					...value,
					isOpen: false,
					onRequestClose: Noop,
				};
			});
			onFinishCallbackRef.current?.(vm, false, error);
		}
	}, [vm, result, userSession]);

	React.useEffect(() => {
		if (!socialMediaPostTargetModalProps?.isOpen && result?.type === vm.getConnectionType()) {
			if (result.success) {
				handleSuccess();
			} else {
				const error = result.errorMessage ?? 'Unknown error.';
				onFinishCallbackRef.current?.(vm, false, Api.asApiError(error));
			}
		}
	}, [vm, result, handleSuccess, socialMediaPostTargetModalProps?.isOpen]);
	return {
		setSocialMediaPostTargetModalProps,
		socialMediaPostTargetModalProps,
	} as const;
};
