import { StyleSheet } from 'aphrodite';
import { destructiveHover, error, inputBorderColor, label, navigation, titles, white } from '../../../styles/colors';
import { CssSelectors, baseStyles } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	consentCheckbox: {
		color: titles,
	},
	imagePlaceholder: {
		bottom: -20,
		color: navigation,
		fontSize: 14,
		fontStyle: 'italic',
		height: 20,
		maxWidth: 190,
		position: 'absolute',
		right: 0,
		top: 5,
	},
	infoList: {
		listStyleType: '"-"',
		...CssSelectors.allChildren(
			{
				listStyleType: '"-"',
			},
			'li'
		),
		...CssSelectors.allChildren(
			{
				...baseStyles.mt2,
			},
			'li:not(:first-child)'
		),
	},
	consentBox: {
		'::-webkit-scrollbar': {
			background: 'transparent',
			width: 4,
		},
		'::-webkit-scrollbar-thumb': {
			backgroundColor: inputBorderColor,
			borderRadius: 10,
			padding: 4,
		},
	},
	consentList: {
		listStyleType: '"-"',
		...CssSelectors.allChildren(
			{
				listStyleType: '"-"',
			},
			'li'
		),
		...CssSelectors.allChildren(
			{
				...baseStyles.mt1,
			},
			'li:not(:first-child)'
		),
	},
	headline: {
		fontSize: 18,
	},
	subHeadline: {
		color: label,
		fontSize: 14,
		textAlign: 'center',
	},
	addressLineCol: {
		flex: 1,
	},
	addressLineGroup: {
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	containerHeader: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		marginBottom: 30,
	},
	formContainer: {
		background: '#f9f9f9',
		margin: 'auto',
		padding: 50,
		width: 600,
	},
	formErrorLine: {
		color: error,
		fontSize: '0.75rem',
		marginTop: 0,
	},
	formField: {
		background: white,
		...CssSelectors.allDescendants(
			{
				background: white,
			},
			'.select-box-trigger, .select-box-trigger:hover'
		),
	},
	formFooter: {
		display: 'flex',
		marginTop: 30,
		...CssSelectors.allDescendants(
			{
				width: '100%',
			},
			'button'
		),
	},
	label: {
		color: label,
		display: 'block',
		fontSize: 14,
		letterSpacing: 0,
		marginBottom: 5,
		marginTop: 15,
	},
	labelRequired: {
		'::after': {
			color: destructiveHover,
			content: '"*"',
			display: 'inline',
			marginLeft: '0.25rem',
		},
	},
});
