import { getDisplayName } from '../../../../models/UiUtils';
import { emailActivityTintColor } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet } from './styles';
import {
	ErrorMessagesViewModelKey,
	FullScreenModalViewModelKey,
	IFullscreenModalComponentProps,
	ISingleEmailComposerComponentProps,
	IUserSessionComponentProps,
	SingleEmailComposerKey,
	UserSessionViewModelKey,
} from '@AppModels/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { ContactViewModel, IContactWithKeyFact } from '@ViewModels';
import { Avatar } from '@WebComponents/Avatar';
import { FeedCard, IFeedCardCallbackProps } from '@WebComponents/cards/FeedCard';
import { FullscreenModalNavLink } from '@WebComponents/fullscreen/FullscreenModalNavLink';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

interface IProps
	extends IFeedCardCallbackProps,
		ISingleEmailComposerComponentProps,
		IEventLoggingComponentProps,
		RouteComponentProps<any>,
		IFullscreenModalComponentProps,
		IUserSessionComponentProps {
	contact: IContactWithKeyFact;
	className?: string;
	onSendEmailClicked?(): void;
}

class _GenericKeyDatesCard extends React.Component<IProps> {
	public render() {
		const { className, contact, userSession, onSendEmailClicked, onRequestRemove } = this.props;
		return (
			<FeedCard
				className={`${css(styleSheet.container)} kit-card ${className || ''}`}
				indicatorColor={emailActivityTintColor}
				onSuppressClicked={onRequestRemove}
				showSuppressOption={true}
			>
				<div className={css(styleSheet.header)}>
					{/* @ts-ignore */}
					{/* @ts-ignore */}
					<Avatar className={css(styleSheet.avatar)} entityVm={new ContactViewModel(userSession, contact.contact)} />
					<div className={css(styleSheet.headerDescription)}>
						<div className={css(styleSheet.headerDescriptionTitle)}>
							<div className={css(baseStyleSheet.truncateText)}>
								<FullscreenModalNavLink
									className={css(styleSheet.contactName)}
									title={getDisplayName(contact.contact)}
									to={{ pathname: `/people/${contact.contact.id}`, state: {} }}
								>
									{getDisplayName(contact.contact)}
								</FullscreenModalNavLink>
							</div>
						</div>
						{!!contact.contact.companyName && (
							<div className={css(baseStyleSheet.truncateText, styleSheet.companyName)}>
								{contact.contact.companyName}
							</div>
						)}
					</div>
				</div>
				<div className={css(styleSheet.body)}>
					<div className={css(styleSheet.bodyLeft)}>{contact.keyFact.value}</div>
					<div className={css(styleSheet.bodyRight)}>
						<button className={css(baseStyleSheet.ctaButtonSmall)} onClick={onSendEmailClicked}>
							<span>Send Email</span>
						</button>
					</div>
				</div>
			</FeedCard>
		);
	}
}

const GenericKeyDatesCardAsObserver = observer(_GenericKeyDatesCard);
const GenericKeyDatesCardWithContext = inject(
	SingleEmailComposerKey,
	ErrorMessagesViewModelKey,
	FullScreenModalViewModelKey,
	UserSessionViewModelKey
)(GenericKeyDatesCardAsObserver);
const GenericKeyDatesCardWithRouter = withRouter(GenericKeyDatesCardWithContext);
export const GenericKeyDatesCard = withEventLogging(GenericKeyDatesCardWithRouter, 'GenericKeyDatesCard');
