import { ImpersonationContextKey, ModalChildComponentContextKey } from '@AppModels/.';
import * as SharedAppState from '@AppModels/AppState';
import { EventLogger } from '@AppModels/Logging';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { IReactionDisposer, action, autorun, observable } from 'mobx';
import { Provider, observer } from 'mobx-react';
import * as React from 'react';
import { CredentialStore } from '../../../api/CredentialStore';
import { isIE11 } from '../../../models/Browser';
import {
	FullScreenModalViewModel,
	IUserSessionContextConfig,
	UserSessionContext,
} from '../../../viewmodels/AppViewModels';
import { BrowserPushNotificationsViewModel } from '../../../viewmodels/PushNotifications';
import { ReactQueryProvider } from '../../ReactQueryProvider';
import { styleSheet } from './styles';
import { FocusedViewProvider } from '../Contact/Focused/FocusedViewProvider';

interface IProps {
	userSessionConfig?: IUserSessionContextConfig;
}

interface IState {
	isLoading?: boolean;
}

class _App extends React.Component<IProps, IState> {
	private userSession: UserSessionContext;
	// @ts-ignore
	@observable private mMounted: boolean;
	// @ts-ignore
	private mLoggedInDisposer: IReactionDisposer;
	public readonly state: IState = {
		isLoading: true,
	};

	constructor(props: IProps) {
		super(props);
		const userSessionConfig: IUserSessionContextConfig = {
			...props.userSessionConfig,
			apiConfig: {
				...(
					props.userSessionConfig || {
						apiConfig: {
							baseUrl: process.env.API_URL,
						},
					}
				).apiConfig,
				credentialStore: new CredentialStore(),
			},
		};

		this.userSession = new UserSessionContext(userSessionConfig);
		SharedAppState.AppState[SharedAppState.UserSessionViewModelKey] = this.userSession;
		// @ts-ignore
		SharedAppState.AppState[SharedAppState.ActionItemComposerViewModelKey].setUserSession(this.userSession);
		// @ts-ignore
		SharedAppState.AppState[SharedAppState.NoteComposerViewModelKey].setUserSession(this.userSession);
		// @ts-ignore
		SharedAppState.AppState[SharedAppState.QuickAddEntityViewModelKey].setUserSession(this.userSession);
		// @ts-ignore
		SharedAppState.AppState[SharedAppState.SingleEmailComposerKey].setUserSession(this.userSession);
		SharedAppState.AppState[SharedAppState.TextMessagingViewModelKey] = new Api.TextMessagingViewModel(
			this.userSession
		);
		SharedAppState.AppState[SharedAppState.FullScreenModalViewModelKey] = new FullScreenModalViewModel();
		SharedAppState.AppState[SharedAppState.PushNotificationsViewModelKey] = new BrowserPushNotificationsViewModel(
			this.userSession,
			{
				addLogger: Api.VmUtils.Noop,
				logEvent: (args, context) => console.log(args, context),
				removeLogger: Api.VmUtils.Noop,
			}
		);
	}

	@action
	public componentDidMount() {
		this.mMounted = true;

		if (!isIE11()) {
			const bugsnagEnv = process.env.BUGSNAG_ENV as BUGSNAG_ENV;
			import(/* webpackChunkName: "highlight" */ '../../../models/HighlightLogger')
				?.then(mod => {
					// @ts-ignore
					const HighlightEventLogger: new (env: string, appId: string) => Api.ILogger<Api.ILogEvent> = mod.default;
					// @ts-ignore
					const highlightEventLogger = new HighlightEventLogger(bugsnagEnv, process.env.HIGHLIGHT_APPID);
					Api.EventLogger.addLogger(highlightEventLogger);
				})
				.catch(() => {
					console.error('Failed to load Highlight');
				});
			new FontFace('Aladdin', 'url(/fonts/Aladdin.ttf)', {
				style: 'normal',
				weight: 'normal',
			}).load();
			new FontFace('Cinderella', 'url(/fonts/Cinderella.ttf)', {
				style: 'normal',
				weight: 'normal',
			}).load();
			new FontFace('Donald', 'url(/fonts/Donald.ttf)', {
				style: 'normal',
				weight: 'normal',
			}).load();
			new FontFace('Nemo', 'url(/fonts/Nemo.ttf)', {
				style: 'normal',
				weight: 'normal',
			}).load();
			new FontFace('Scar', 'url(/fonts/Scar.ttf)', {
				style: 'normal',
				weight: 'normal',
			}).load();
			new FontFace('Tarzan', 'url(/fonts/Tarzan.ttf)', {
				style: 'normal',
				weight: 'normal',
			}).load();
			new FontFace('Woody', 'url(/fonts/Woody.ttf)', {
				style: 'normal',
				weight: 'normal',
			}).load();
		}

		this.mLoggedInDisposer = autorun(() => {
			if (!!this.mMounted && !!this.userSession?.isAuthenticated) {
				// @ts-ignore
				SharedAppState.AppState[SharedAppState.TextMessagingViewModelKey].init(EventLogger);
			}
		});
		const promise = this.userSession.load();
		const onFinish = () => {
			if (this.mMounted) {
				this.setState({
					isLoading: false,
				});
			}
		};
		if (promise) {
			promise.then(onFinish).catch(onFinish);
		} else {
			onFinish();
		}
	}

	public componentWillUnmount() {
		this.mMounted = false;
		this.mLoggedInDisposer?.();
		// @ts-ignore
		this.mLoggedInDisposer = null;
	}

	public render() {
		return (
			<ReactQueryProvider>
				<Provider
					{...SharedAppState.AppState}
					{...{ [ModalChildComponentContextKey]: null }}
					{...{ [ImpersonationContextKey]: null }}
				>
					<FocusedViewProvider>
						<div className={`${css(styleSheet.container)} root`}>
							{this.state.isLoading ? <span style={{ display: 'none' }} /> : this.props.children}
						</div>
					</FocusedViewProvider>
				</Provider>
			</ReactQueryProvider>
		);
	}
}

export const App = observer(_App);
