import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useLocation, useParams } from 'react-router';
import Waypoint from 'react-waypoint';
import { getDisplayNameForBulkEmail, getPrincipalInitials } from '../../../../models/UiUtils';
import { useFullscreenModal, useUserSession } from '../../../../models/hooks/appStateHooks';
import { useAutomationTemplateIdQuery } from '../../../../queries';
import { AdvancedFiltersFlyout } from '../../../components/AdvancedFiltersFlyout';
import { Avatar2 } from '../../../components/Avatar2';
import { Checkbox } from '../../../components/Checkbox';
import { ComposerRecipientContactFilterOwnershipDropdown } from '../../../components/ComposerRecipientOwnershipDropdown';
import { ComposerSendFromDropdown } from '../../../components/ComposerSendFromDropdown';
import { DeprecatedCloseButton } from '../../../components/DeprecatedCloseButton';
import { FabContext } from '../../../components/FabContext';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { OverflowAccordion } from '../../../components/OverflowAccordion';
import { AutoCompleteSearchField } from '../../../components/autocomplete/AutoCompleteSearchField';
import { ContactsTableActiveSearchesList } from '../../../components/contacts/ContactsTableActiveSearchesList';
import { DisclosureIcon } from '../../../components/svgs/icons/DisclosureIcon';
import { SearchIcon } from '../../../components/svgs/icons/SearchIcon';
import { baseStyleSheet as bs } from '../../../styles/styles';
import { CampaignComposerBaseLayout } from '../../BulkHwcPages/presentation';
import { PhoneMissingPill } from '../PhoneMissingPill';
import { getInitialSendFrom, useTextingCampaignRecipientFilter } from '../hooks/useTextingCampaignRecipientFilter';
import { styleSheet } from '../styles';
import { TextCampaignTemplateBody } from './TextCampaignTemplateBody';

export function TextCampaignTemplateFilterPage() {
	const userSession = useUserSession();
	const location = useLocation<{
		initialContactIdsToOmit?: string[];
		initialContactIdsToInclude?: string[];
		initialFilterCriteriaList?: Api.IContactFilterCriteria[];
		initialSendFrom?: Api.ISendEmailFrom;
		initialSendFromUserId?: string;
		initialCampaignTitle?: string;
		initialContentEditorState?: Api.IRichContentEditorState;
		initialAttachmentFiles?: File[];
	}>();
	const fullscreenModal = useFullscreenModal();
	const { automationTemplateId } = useParams<{ automationTemplateId: string }>();
	const automationTemplateIdQuery = useAutomationTemplateIdQuery({
		id: automationTemplateId,
	});
	const initialSendFromConfig = getInitialSendFrom({
		currentUserId: userSession.user.id!,
		canSendOnBehalf: userSession.canSendOnBehalf,
		initialFilterCriteriaList: location.state?.initialFilterCriteriaList,
	});
	const [sendFrom, setSendFrom] = React.useState<Api.ISendEmailFrom>(
		location.state?.initialSendFrom ?? initialSendFromConfig.sendFrom
	);
	const [sendFromUserId, setSendFromUserId] = React.useState<string | null>(
		location.state?.initialSendFromUserId ?? initialSendFromConfig.sendFromUserId
	);
	const {
		state,
		dispatch,
		contactFilterQuery,
		contactIdsToOmit,
		contactsToAdd,
		contactApproximateQuery,
		onChangeFilterList,
		totalRecipients,
		totalRecipientsWithTextingCapability,
	} = useTextingCampaignRecipientFilter({
		initialContactIdsToOmit: location.state?.initialContactIdsToOmit,
		initialFilterCriterias: location.state?.initialFilterCriteriaList,
		initialContactIdsToInclude: location.state?.initialContactIdsToInclude,
		onAllFiltersRemoved: () => {
			fullscreenModal.history.push({
				pathname: `/texting-campaign/template-search/${automationTemplateId}`,
				state: {
					initialSendFrom: sendFrom,
					initialSendFromUserId: sendFromUserId,
				},
			});
		},
	});
	const tagSearchCriteria = Api.VmUtils.sortContactFilterCriteria(state.filterCriteriaList).searches.filter(x =>
		Api.VmUtils.isTagSearchContactFilterCriteria(x)
	);
	const onNextButtonClick = () => {
		fullscreenModal.history.push(`/texting-campaign/schedule-send/${automationTemplateId}`, {
			request: {
				filter: {
					criteria: state.filterCriteriaList,
				},
				excludeContactIds: Array.from(state.contactIdsToOmit),
				includeContactIds: Array.from(state.contactIdsToAdd),
				sendFromOptions: {
					mode: sendFrom,
					selectedUser: sendFromUserId ?? undefined,
				},
				autoStart: false,
				putOnHold: false,
				skipIfInProgress: true,
				skipIfInPrevious: false,
				groupByHousehold: false,
			},
		});
	};
	const renderRecipientItem = (contact: Api.IContact) => {
		let companyLine = '';
		if (contact.companyName != null) {
			companyLine = contact.jobTitle != null ? `${contact.jobTitle} at ${contact.companyName}` : contact.companyName;
		}
		const nameLabel = getDisplayNameForBulkEmail(contact);
		return (
			<div
				className={css(
					bs.flex,
					bs.itemsCenter,
					bs.border0,
					bs.borderB,
					bs.borderSolid,
					bs.borderGray200,
					bs.cursorPointer,
					bs.py5,
					bs.px0,
					bs.relative
				)}
				key={contact.id}
			>
				<DeprecatedCloseButton
					onClick={ev => {
						ev.stopPropagation();
						if (contact.id) {
							dispatch({ type: 'removeRecipient', value: contact });
						}
					}}
				/>

				<Avatar2
					styleDeclaration={styleSheet.recipientsListItemAvatar}
					imgSrc={contact.profilePic}
					fallbackText={getPrincipalInitials(contact)}
				/>

				<div className={css(bs.flex1, bs.textSm, bs.ml4, bs.overflowHidden)}>
					<div className={css(styleSheet.recipientsListItemDetailsName)}>{nameLabel}</div>
					<div className={css(styleSheet.recipientsListItemDetailsCompanyName, bs.truncateText)}>{companyLine}</div>
				</div>
				{!contact.canReceiveText ? <PhoneMissingPill styleDeclarations={[bs.absolute, bs.bottom2, bs.right2]} /> : null}
			</div>
		);
	};
	return (
		<>
			<MultiContainerHeader
				fullscreenHeader='New Texting Campaign'
				onFullscreenRequestBack={ev => {
					ev.preventDefault();
					fullscreenModal.dismissModal();
				}}
				onFullscreenRequestClose={() => fullscreenModal.dismissModal()}
			/>
			<CampaignComposerBaseLayout
				mainHeader={
					<div className={css(bs.px3, bs.py5, bs.flex, bs.gap2, bs.flexCol)}>
						<div className={css(bs.mb2)}>
							{automationTemplateIdQuery.status === 'success' ? (
								<h2 className={css(bs.m0)}>{automationTemplateIdQuery.data?.name}</h2>
							) : (
								<LoadingSpinner type='small' />
							)}
						</div>
						<ComposerSendFromDropdown
							disabled={!userSession.canSendOnBehalf}
							sendFrom={sendFrom}
							sendFromUserId={sendFromUserId}
							onChange={params => {
								if (params.sendFrom === sendFrom) {
									return;
								}
								setSendFrom(params.sendFrom);
								setSendFromUserId(params.sendFromUserId);
								if (params.sendFrom === Api.SendEmailFrom.CurrentUser) {
									dispatch({ type: 'selectSendOnBehalfOfCurrentUser', value: { currentUserId: userSession.user.id } });
								} else if (params.sendFrom === Api.SendEmailFrom.ContactOwner) {
									dispatch({ type: 'selectSendOnBehalfOfContactOwner' });
								} else if (params.sendFrom === Api.SendEmailFrom.SelectedUser) {
									dispatch({ type: 'selectEmployeeToSendOnBehalfOf', value: params.sendFromUserId });
								}
							}}
						/>
					</div>
				}
				sideBar={
					<>
						<div className={css(styleSheet.recipientsHeader)}>
							<div className={css(styleSheet.recipientsHeaderTitle)}>
								<div>Recipients</div>
								<button
									className={css(bs.brandLink)}
									onClick={() => dispatch({ type: 'setShowingAdvancedTagFiltersModal', value: true })}
								>
									<div>Advanced Tag Filter</div>
								</button>
								<AdvancedFiltersFlyout
									initialFilterCriteria={state.filterCriteriaList}
									onSave={filterCriteria => {
										onChangeFilterList(filterCriteria);
										dispatch({ type: 'setShowingAdvancedTagFiltersModal', value: false });
									}}
									isOpen={state.showingAdvancedTagFiltersModal}
									onDismissFlyout={() => dispatch({ type: 'setShowingAdvancedTagFiltersModal', value: false })}
									showOwnershipFilters
									disableOwnershipFilters={sendFrom === Api.SendEmailFrom.ContactOwner}
									showStatusFilters
								/>
							</div>
							<div className={css(bs.horizontalStack)}>
								{tagSearchCriteria.length > 0 ? (
									<OverflowAccordion
										threshold={65}
										onRenderTrigger={isExpanded => {
											return (
												<>
													<span className={css(bs.mr2)}>{`${isExpanded ? 'Hide' : 'Show all'} tags`}</span>
													<DisclosureIcon
														className={css(isExpanded ? styleSheet.tagPointUp : styleSheet.tagPointDown)}
													/>
												</>
											);
										}}
									>
										<ContactsTableActiveSearchesList
											hideAdvancedSearch={true}
											hideLabel={true}
											hideRemoveButtons={false}
											onSearchesChanged={(searches: Api.IContactFilterCriteria[]) => {
												const sorted = Api.VmUtils.sortContactFilterCriteria(state.filterCriteriaList);
												onChangeFilterList(sorted.filters.concat(searches));
											}}
											searches={tagSearchCriteria}
											tokenContainerStyles={[styleSheet.tagTokenContainer]}
											styles={[bs.hAuto, bs['-ml2']]}
										/>
									</OverflowAccordion>
								) : null}
							</div>
							<ComposerRecipientContactFilterOwnershipDropdown
								theirContactsOptionEnabled={Boolean(sendFrom && sendFromUserId)}
								disabled={sendFrom === Api.SendEmailFrom.ContactOwner}
								filterCriteriaList={state.filterCriteriaList}
								currentUserId={userSession.user.id}
								contactOwnerUserId={sendFromUserId}
								onChange={ownershipCriteria => {
									dispatch({ type: 'ownershipCriteriaChange', value: ownershipCriteria });
								}}
							/>
							<div className={css(bs.mt2, bs.flex, bs.itemsCenter, bs.flexWrap, bs.textSm, bs.justifyStart)}>
								<div className={css(bs.flex, bs.mr2)}>
									{contactApproximateQuery.isLoading ? <LoadingSpinner type='tiny' /> : null}
									{totalRecipientsWithTextingCapability != null && totalRecipients != null
										? `${totalRecipientsWithTextingCapability} out of ${totalRecipients} contact${
												totalRecipients !== 1 ? 's' : ''
											} with phone numbers`
										: null}
								</div>
							</div>
							<AutoCompleteSearchField
								anchorClassName='email-recipients-list-autocomplete-search-field-anchor'
								className='email-recipients-list-autocomplete-search-field'
								clearSearchFieldAfterSelectingItem={true}
								dropdownContentClassName={css(bs.wFull)}
								inputId='email-recipients-list-search-input'
								inputProps={{
									placeholder: 'Add additional contacts',
								}}
								leftAccessory={<SearchIcon className='email-recipients-list-autocomplete-search-field-icon' />}
								onItemSelected={(contact: Api.IContact) => {
									if (contact.id) {
										dispatch({ type: 'addRecipient', value: contact });
									}
								}}
								resultsLimit={6}
								type={Api.ResourceAutoCompleteViewModelType.Contact}
							/>
						</div>
						<div className={css(bs.flex, bs.flex1, bs.flexCol, bs.overflowXHidden, bs.overflowYAuto)}>
							<div>
								{contactsToAdd.map((contactQuery, i) => {
									if (!contactQuery.data) {
										return <LoadingSpinner key={i} type='small' />;
									}
									return renderRecipientItem(contactQuery.data);
								})}
								{contactFilterQuery.status === 'success' ? (
									<>
										{contactFilterQuery.data.pages.map(group => {
											return group.values
												.filter(contact => {
													if (!contact.id) {
														return false;
													}
													return (
														contactIdsToOmit.indexOf(contact.id) === -1 &&
														state.contactIdsToAdd.indexOf(contact.id) === -1
													);
												})
												.map(contact => {
													return renderRecipientItem(contact);
												});
										})}
										{contactFilterQuery.hasNextPage && !contactFilterQuery.isFetchingNextPage ? (
											<Waypoint onEnter={() => contactFilterQuery.fetchNextPage()} bottomOffset='-200px' />
										) : null}
									</>
								) : null}
							</div>
						</div>
						<div className={css(bs.py2, bs.px4, bs.border0, bs.borderT, bs.borderSolid, bs.borderGray200)}>
							<Checkbox
								id='textingCampaignAgreement-checkbox'
								className={css(bs.mb6)}
								checked={state.consentChecked}
								onChange={() => dispatch({ type: 'toggleConsentChecked' })}
							>
								<span className={css(bs.ml2)}>
									I agree that the recipients have opted in to receive texts and this is not a marketing campaign.
								</span>
							</Checkbox>
							<button
								className={css(bs.ctaButton, bs.wFull)}
								onClick={onNextButtonClick}
								disabled={!totalRecipientsWithTextingCapability || !state.consentChecked}
							>
								<span>Next: Send Options</span>
							</button>
						</div>
					</>
				}
			>
				<>
					<FabContext appearance={{ hidden: true }} />
					<TextCampaignTemplateBody
						automationTemplateId={automationTemplateId}
						onDuplicateClick={({ title, contentEditorState, attachments }) => {
							fullscreenModal.history.push({
								pathname: `/texting-campaign/filter`,
								state: {
									initialSendFrom: sendFrom,
									initialSendFromUserId: sendFromUserId,
									initialContactIdsToOmit: state.contactIdsToOmit,
									initialContactIdsToInclude: state.contactIdsToAdd,
									initialFilterCriteriaList: state.filterCriteriaList,
									initialCampaignTitle: title,
									initialContentEditorState: contentEditorState,
									initialStoredAttachments: attachments,
								},
							});
						}}
					/>
				</>
			</CampaignComposerBaseLayout>
		</>
	);
}
