import { StyleSheet } from 'aphrodite';

const qqCatalystColor = '#ff5400';

export const styleSheet = StyleSheet.create({
	ctaButton: {
		borderColor: qqCatalystColor,
		boxShadow: 'none',
		color: qqCatalystColor,
		marginTop: 20,
	},
	integrationTitle: {
		color: qqCatalystColor,
	},
});
