import { css } from 'aphrodite';
import * as React from 'react';
import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet } from './styles';

interface IProps {
	isOpen?: boolean;
	onFinish?(frequency: number): void;
}

interface IState {
	contentContainerClass?: string;
}

export class KeepInTouchDropdown extends React.PureComponent<IProps, IState> {
	public state: IState = {};

	public render() {
		return (
			<div className={css(this.props.isOpen ? styleSheet.frequencyContainer : baseStyleSheet.hidden)}>
				<div className={css(styleSheet.optionContainer)} id='keepInTouchFrequency-1' onClick={this.onFinish(1)}>
					<span>Every month</span>
				</div>
				<div className={css(styleSheet.optionContainer)} id='keepInTouchFrequency-2' onClick={this.onFinish(2)}>
					<span>Every 2 months</span>
				</div>
				<div className={css(styleSheet.optionContainer)} id='keepInTouchFrequency-3' onClick={this.onFinish(3)}>
					<span>Every 3 months</span>
				</div>
				<div className={css(styleSheet.optionContainer)} id='keepInTouchFrequency-6' onClick={this.onFinish(6)}>
					<span>Every 6 months</span>
				</div>
				<div className={css(styleSheet.optionContainer)} id='keepInTouchFrequency-12' onClick={this.onFinish(12)}>
					<span>Every year</span>
				</div>
			</div>
		);
	}

	private onFinish = (frequency: number) => () => {
		const { onFinish } = this.props;
		if (onFinish) {
			onFinish(frequency);
		}
	};
}
