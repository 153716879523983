import { css } from 'aphrodite';
import { ContactViewModel } from '../../../../extViewmodels';
import { getPrincipalInitials } from '../../../../models/UiUtils';
import { Avatar2 } from '../../../components/Avatar2';
import { bs } from '../../../styles/styles';
import { styleSheet } from './styles';

type Props = {
	contact: ContactViewModel;
};

export const ContactCardName = ({ contact }: Props) => {
	const emailAddress =
		contact.primaryEmail?.value || (contact.emailAddresses ? contact.emailAddresses[0]?.value : null);
	return (
		<div
			data-testid='contact-card-name'
			className={css(bs.flex, bs.itemsCenter, bs.justifyStart, bs.gap2, bs.hFull, bs.pl3, styleSheet.cardNameContainer)}
		>
			<Avatar2 fallbackText={getPrincipalInitials(contact)} styleDeclaration={styleSheet.avatarOverride} />
			<div className={css(bs.flex, bs.flexCol, bs.mr1, bs.flex1, bs.minW0)}>
				<strong className={css(bs.fontBold, bs.truncateText)}>{contact.name}</strong>
				<div className={css(styleSheet.emailAddress, bs.textXs, bs.truncateText)}>{emailAddress}</div>
			</div>
		</div>
	);
};
