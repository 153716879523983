import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import moment from 'moment';
import { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { getPrincipalInitials } from '../../../../models/UiUtils';
import { header } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { Avatar2 } from '../../Avatar2';
import { Popover, PopoverType } from '../../Popover';
import { UserIconAvatar } from '../../UserIconAvatar';
import { DangerIcon } from '../../svgs/icons/DangerIcon';
import { MoreIcon } from '../../svgs/icons/MoreIcon';
import { TrashIcon } from '../../svgs/icons/TrashIcon';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	conversation: Api.ConversationViewModel;
	onClick(conversation: Api.ConversationViewModel): void;
	onMarkAsSpamClick(conversation: Api.ConversationViewModel): void;
	onTrashClick(conversation: Api.ConversationViewModel): void;
	onUnsubscribeClick(conversation: Api.ConversationViewModel): void;
	selected?: boolean;
	selectedRecipient?: Api.TextRecipientViewModel;
}

export const ConversationItemBase: FC<IProps> = ({
	className = '',
	conversation,
	onClick,
	onMarkAsSpamClick,
	onUnsubscribeClick,
	onTrashClick,
	selected,
	selectedRecipient,
}) => {
	const intervalRef = useRef<NodeJS.Timeout>(null);
	const [sinceLastMessage, setSinceLastMessage] = useState('');
	const [moreOptionsOpen, setMoreOptionsOpen] = useState(false);
	const [isHovering, setIsHovering] = useState(false);
	useEffect(() => {
		if (conversation?.lastMessage?.creationDate) {
			const creationDate = moment(conversation.lastMessage.creationDate);
			if (creationDate.isValid()) {
				setSinceLastMessage(Api.VmUtils.timeSince(creationDate));
			} else {
				setSinceLastMessage('---');
			}
		}

		return () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
		}

		if (conversation?.lastMessage?.creationDate) {
			// @ts-ignore
			intervalRef.current = setInterval(() => {
				const creationDate = moment(conversation.lastMessage.creationDate);
				if (creationDate.isValid()) {
					setSinceLastMessage(Api.VmUtils.timeSince(creationDate));
				} else {
					setSinceLastMessage('---');
				}
			}, 1000);
		}
	}, [conversation?.lastMessage]);

	const onConversationClick = () => {
		onClick(conversation);
	};
	const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Enter') {
			onClick(conversation);
		}
	};

	const onTrashConversationClick = (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		onTrashClick(conversation);
	};

	const onMarkConversationAsSpamClick = (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		onMarkAsSpamClick(conversation);
	};

	const onUnsubscribeConversationClick = (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		onUnsubscribeClick(conversation);
	};

	const onMoreClick = (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		setMoreOptionsOpen(true);
	};

	const onMouseEnter = () => {
		setIsHovering(true);
	};

	const onMouseLeave = () => {
		setIsHovering(false);
		setMoreOptionsOpen(false);
	};

	const unsubscribedByConversation = !!conversation.automatedSMSOptOutDate;
	const unsubscribedByContact =
		!!selectedRecipient?.automatedSmsOptOutMetadata &&
		conversation.toNumbers?.length === 1 &&
		selectedRecipient?.id === conversation.toNumbers?.[0]?.contact?.id;
	const unsubscribed = unsubscribedByConversation || unsubscribedByContact;

	const avatarContact = conversation.toNumbers.length > 1 ? null : conversation.toNumbers[0].contact;

	return (
		<div
			className={`${css(styleSheet.container, selected && styleSheet.selected)} ${className}`}
			tabIndex={0}
			role='button'
			onKeyDown={onKeyDown}
			onClick={onConversationClick}
			onMouseEnter={onMouseEnter}
			onFocus={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			{conversation.unreadMessageCount > 0 && (
				<div className={css(baseStyleSheet.absoluteCenter, styleSheet.unreadDot)} />
			)}
			<div className={css(styleSheet.errorContainer)}>
				{!!conversation?.lastMessage?.errorMessage && <DangerIcon />}
			</div>
			<div className={css(styleSheet.avatarContainer)}>
				{avatarContact ? (
					<Avatar2
						fallbackText={getPrincipalInitials(avatarContact)}
						imgSrc={avatarContact?.profilePic}
						styleDeclaration={styleSheet.avatar}
					/>
				) : (
					<UserIconAvatar styleDeclaration={styleSheet.avatar} />
				)}
			</div>
			<div className={css(styleSheet.contentWrapper)}>
				<p className={css(styleSheet.conversationNames, conversation.unreadMessageCount > 0 && styleSheet.unread)}>
					{Api.VmUtils.renderConversationNames(conversation)}
				</p>
				{unsubscribed ? (
					<div className={css(styleSheet.unsubscribed)}>Unsubscribed</div>
				) : (
					!!conversation.lastMessage && (
						<p className={css(styleSheet.gray, styleSheet.lastMessage)}>
							{Api.VmUtils.getLastMessageText(conversation.lastMessage)}
						</p>
					)
				)}
			</div>
			<div className={css(styleSheet.rightContainer, !conversation.lastMessage && styleSheet.rightContainerCenter)}>
				{!isHovering ? (
					<p className={`time-since-last-message ${css(styleSheet.gray, baseStyleSheet.italicText)}`}>
						{sinceLastMessage}
					</p>
				) : (
					<>
						<button className={css(styleSheet.trashConversation)} onClick={onTrashConversationClick}>
							<TrashIcon fillColor={header} />
						</button>
						<Popover
							anchor={
								<button className={css(styleSheet.moreConversation)} onClick={onMoreClick}>
									<MoreIcon fillColor={header} />
								</button>
							}
							className={css(styleSheet.menu)}
							isOpen={moreOptionsOpen}
							place='below'
							type={PopoverType.custom}
							tipSize={0.1}
						>
							<div>
								<button onClick={onMarkConversationAsSpamClick}>
									<div className={css(styleSheet.menuItem)}>Mark as spam</div>
								</button>
							</div>
							{conversation.toNumbers?.length === 1 ? (
								<div>
									<button onClick={onUnsubscribeConversationClick}>
										<div className={css(styleSheet.menuItem)}>
											{unsubscribedByConversation ? 'Subscribe' : 'Unsubscribe'}{' '}
										</div>
									</button>
								</div>
							) : null}
						</Popover>
					</>
				)}
			</div>
		</div>
	);
};

export const ConversationItem = observer(ConversationItemBase);
