import { useEventLogging } from '../../../../models/Logging';
import { useErrorMessages } from '../../../../models/hooks/appStateHooks';
import { useCreateMergeLinkToken, useExchangeMergePublicTokenForAccountTokenMutation } from '../../../../queries';
import * as Api from '@ViewModels';
import { useMergeLink } from '@mergeapi/react-merge-link';
import * as React from 'react';

export function useMergeAuth({
	errorLoggingCategory,
	integrationName,
	integrationSource,
	onError,
	onSuccess,
	userSession,
}: {
	errorLoggingCategory?: string;
	integrationName: string;
	integrationSource: Api.IntegrationSources;
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
	userSession: Api.UserSessionContext;
}) {
	const { data: linkToken } = useCreateMergeLinkToken({
		integrationName,
		integrationSource,
		refetchOnWindowFocus: false,
		// @ts-ignore
		userSession,
	});
	// @ts-ignore
	const { mutateAsync } = useExchangeMergePublicTokenForAccountTokenMutation({ userSession });
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging(errorLoggingCategory || 'useMergeAuth');

	const onMergeLinkSuccess = React.useCallback(
		async token => {
			try {
				await mutateAsync({
					integration: integrationName,
					integrationProvider: integrationSource,
					publicToken: token,
				});
				onSuccess?.();
			} catch (err) {
				// @ts-ignore
				// @ts-ignore
				errorMessages.pushApiError(err);
				// @ts-ignore
				logApiError('ExchangeMergePublicTokenForAccountToken-Error', err);
				// @ts-ignore
				onError?.(err);
			}
		},
		[mutateAsync, integrationName, integrationSource, onSuccess, errorMessages, logApiError, onError]
	);

	const { open, isReady: isMergeLinkReady } = useMergeLink({
		linkToken,
		onSuccess: onMergeLinkSuccess,
	});

	return { isReady: isMergeLinkReady && linkToken, openMergeAuthFlow: open } as const;
}
