import { brandPrimary, grayIconFill } from '../../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	clipboardIcon: {
		height: 12,
		marginRight: 6,
		width: 12,
	},
	clockContainer: {
		alignItems: 'center',
		display: 'flex',
		paddingTop: 6,
	},
	clockIcon: {
		marginRight: 6,
	},
	clockText: {
		color: grayIconFill,
	},
	container: {
		boxShadow: `0 0 6px 0 rgba(139, 139, 139, 0.20)`,
		padding: '25px 21px',
		position: 'relative',
	},
	header: {
		color: brandPrimary,
		margin: 0,
	},
	link: {
		paddingBottom: 14,
		paddingTop: 6,
	},
	linkDisabled: {
		':hover': {
			color: grayIconFill,
			textDecoration: 'none',
		},
		color: grayIconFill,
		cursor: 'not-allowed',
	},
	moreMenu: {
		position: 'absolute',
		right: 10,
		top: 10,
	},
});
