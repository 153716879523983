import { SvgIcon } from '../SvgIcon';
import clsx from 'clsx';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
	height?: number;
	width?: number;
}

export const HeartAndShareIcon: React.FC<IProps> = props => {
	const { className, fillColor = '#fff', width = 24, height = 24 } = props;
	return (
		<SvgIcon className={clsx(className)} height={height} width={width} viewBox='0 0 24 24'>
			<path
				fill={fillColor}
				fillRule='evenodd'
				d='M5 2C3.346 2 2 3.346 2 5c0 3.363 4.643 7.207 7 8.806 2.357-1.599 7-5.443 7-8.806 0-1.654-1.346-3-3-3-1.689 0-3 1.613-3 3a1 1 0 0 1-2 0c0-1.387-1.311-3-3-3Zm4 14c-.184 0-.368-.051-.53-.152C8.124 15.632 0 10.491 0 5c0-2.757 2.243-5 5-5 1.594 0 3.071.837 4 2.08C9.929.837 11.406 0 13 0c2.757 0 5 2.243 5 5 0 5.491-8.124 10.632-8.47 10.848A1.002 1.002 0 0 1 9 16ZM21.5 19c-.812 0-1.529.396-1.986.998l-2.562-1.024c.03-.155.048-.312.048-.474 0-.162-.018-.319-.048-.473l2.562-1.025A2.492 2.492 0 0 0 21.5 18c1.378 0 2.5-1.122 2.5-2.5S22.878 13 21.5 13a2.503 2.503 0 0 0-2.5 2.5c0 .03.008.059.009.089l-2.781 1.112A2.488 2.488 0 0 0 14.5 16a2.503 2.503 0 0 0-2.5 2.5c0 1.378 1.122 2.5 2.5 2.5.671 0 1.278-.27 1.728-.701l2.781 1.112c-.001.03-.009.059-.009.089 0 1.378 1.122 2.5 2.5 2.5s2.5-1.122 2.5-2.5-1.122-2.5-2.5-2.5Z'
				clipRule='evenodd'
			/>
		</SvgIcon>
	);
};
