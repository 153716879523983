import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import * as Api from '@ViewModels';
import * as H from 'history';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { parse as getQueryStringParams } from 'query-string';
import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { ICampaignApprovalCommand } from '../../../models';
import {
	FullScreenModalViewModelKey,
	IFullscreenModalComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../models/AppState';
import { ISocialMediaConnectResult } from '../../../models/SocialMedia';
import {
	IQueryStringCommand,
	ITagManagementCommand,
	ITemplateCommand,
	TagManagementCommandOperation,
	TemplateCommandOperation,
	TemplateType,
} from '../../../viewmodels/AppViewModels';
import { NotFoundContainer } from '../NotFoundContainer';

type IProps = IEventLoggingComponentProps &
	RouteComponentProps &
	IUserSessionComponentProps &
	IFullscreenModalComponentProps;

interface IState {
	redirection?: H.LocationDescriptor;
}

class _AppLinks extends React.Component<IProps, IState> {
	// @ts-ignore
	@observable private mMounted: boolean;

	constructor(props: IProps) {
		super(props);
		this.state = {};
	}

	public async componentDidMount() {
		const { location, logEvent, userSession, match, logApiError } = this.props;
		const query = getQueryStringParams(location.search);
		const command: string = query.cmd;
		const id: string = query.id;

		// default redirect
		let redirection: H.LocationDescriptor = '/dashboard';
		if (command) {
			const eventContext = {};
			switch (location.pathname) {
				case `${match.path}/tagginggame`:
				case `${match.path}/taggingGame`: {
					if (command === 'people') {
						redirection = '/people/tagging-game';
					}
					break;
				}
				case `${match.path}/email/campaign`: {
					if (id) {
						if (command === 'campaign-approval') {
							const cmd: ICampaignApprovalCommand = {
								command,
								id,
							};
							redirection = {
								pathname: '/dashboard',
								state: cmd,
							};
						} else {
							// command === campaign-view, for example
							redirection = `/reporting/group-email/${id}`;
						}
					}
					break;
				}
				case `${match.path}/social/campaign`: {
					if (id) {
						if (command === 'social-approval') {
							const cmd: ICampaignApprovalCommand = {
								command,
								id,
							};
							redirection = {
								pathname: '/content-calendar',
								state: cmd,
							};
						} else {
							redirection = `/reporting/social-media-posts/${id}`;
						}
					}
					break;
				}
				case `${match.path}/blog/campaign`: {
					if (id) {
						if (command === 'blog-approval') {
							const cmd: ICampaignApprovalCommand = {
								command,
								id,
							};
							redirection = {
								pathname: '/content-calendar',
								state: cmd,
							};
						} else {
							// command === campaign-view, for example
							redirection = `/dashboard`;
						}
					}
					break;
				}
				case `${match.path}/reporting`: {
					if (command === 'group-email' && !!id) {
						redirection = `/reporting/group-email/${id}`;
					} else if (command === 'tag-management') {
						const tag: string = query.tag;
						const op: TagManagementCommandOperation = query.op;
						if (!!op && !!tag) {
							const tagManagementCommand: ITagManagementCommand = {
								command,
								op,
								tag,
							};
							redirection = {
								pathname: `/reporting/tag-management`,
								state: { model: tagManagementCommand },
							};
						} else {
							redirection = `/reporting/tag-management`;
						}
					} else if (command === 'handwritten-cards') {
						redirection = `/reporting/handwritten-cards`;
					}
					break;
				}
				case `${match.path}/reporting/group-email`: {
					redirection = `/reporting/group-email?cmd=${command}`;
					break;
				}
				case `${match.path}/templates`: {
					const op: TemplateCommandOperation = command as any;
					if (id) {
						try {
							// It is safe to show any system template here even from other industries. The API will
							// restrict access if it is an account template, or restricted for another reason.
							// @ts-ignore
							const templatesVM = new Api.TemplatesViewModel(userSession);
							const template = await templatesVM.getById(id);
							if (template) {
								const templateCommand: ITemplateCommand = {
									command: 'templates',
									date: query.date,
									id,
									op,
									sendOption: query.sendOption ?? 'now',
									tags: template.templateType === TemplateType.SocialMediaPost ? undefined : query.tag ?? [],
									template,
								};
								redirection = {
									pathname: '/dashboard',
									state: templateCommand,
								};
							}
						} catch (err) {
							// @ts-ignore
							logApiError('SystemTemplatesLoad-Error', Api.asApiError(err));
						}
					}
					break;
				}
				case `${match.path}/contact`: {
					if (id) {
						redirection = {
							pathname: `/people/${id}`,
							search: `cmd=${command}`,
						};
						if (command === 'manage-automation') {
							redirection.search = `cmd=${command}&automationId=${query.automationId}`;
						}
					}
					break;
				}
				case `${match.path}/settings`: {
					if (command === 'email-signature') {
						redirection = `/settings/email-signature`;
					}
					if (command === 'unsubscribe-signature') {
						redirection = {
							pathname: '/dashboard',
							state: { command },
						};
					}
					break;
				}
				case `${match.path}/automations`: {
					if (command === 'pending') {
						redirection = {
							pathname: '/automations',
							state: command,
						};
					}
					break;
				}
				case `${match.path}/integration`: {
					if (command === 'ownership') {
						const cmd: IQueryStringCommand = {
							command,
						};
						redirection = {
							pathname: '/dashboard',
							state: cmd,
						};
					} else if (command === 'configCallback') {
						const cmd: IQueryStringCommand = { command };
						redirection = {
							pathname: `/integrations/${id?.toLocaleLowerCase()}`,
							state: cmd,
						};
					}
					break;
				}
				default: {
					break;
				}
			}
			// @ts-ignore
			logEvent(command, eventContext);
		} else {
			// simple redirects, no command
			let searchForExactMatches = true;

			// fuzzy matches
			// const regExp = new RegExp(`${ match.path }\/user\/connect-socialmedia\/([^\/\s]+)`, 'ig');
			const regExp = new RegExp(`${match.path}/user/connect-socialmedia/([^/]+)`); // Check with Ryan on what he had going on with this regEx -- was pulling 'in' for instagram
			const socialmediaMatch = regExp.exec(location.pathname);
			if (socialmediaMatch?.length) {
				searchForExactMatches = false;
				const platform = socialmediaMatch[1]?.toLocaleLowerCase();
				const result: Partial<ISocialMediaConnectResult> = {
					errorMessage: String(query.errorMessage) || undefined,
					success: String(query.success)?.toLocaleLowerCase() === 'true',
					userId: String(query.userId) || undefined,
				};
				switch (platform) {
					case 'facebook': {
						result.type = Api.SocialMediaType.Facebook;
						break;
					}
					case 'instagram': {
						result.type = Api.SocialMediaType.Instagram;
						break;
					}
					case 'linkedin': {
						result.type = Api.SocialMediaType.LinkedIn;
						break;
					}
					default: {
						searchForExactMatches = true;
						break;
					}
				}

				if (result.type) {
					redirection = {
						pathname: '/settings/social-media?op=connect-result',
						state: result,
					};
				}
			}

			if (searchForExactMatches) {
				// exact matches
				switch (location.pathname) {
					case `${match.path}/integrations`: {
						redirection = '/integrations';
						break;
					}
					case `${match.path}/texting`: {
						const { phoneNumberId, conversationId } = query;
						redirection =
							!phoneNumberId || !conversationId ? '/texting' : `/texting/${phoneNumberId}/${conversationId}`;

						break;
					}
					default: {
						// @ts-ignore
						logEvent('NullCommand-Error');
						break;
					}
				}
			}
		}

		const mounted = () => (this.mMounted = true);
		if (redirection) {
			this.setState(
				{
					redirection,
				},
				mounted
			);
		} else {
			mounted();
		}
	}

	public componentWillUnmount() {
		this.mMounted = false;
	}

	public render() {
		const { redirection } = this.state;
		if (!this.mMounted) {
			return null;
		}

		if (redirection) {
			return <Redirect to={redirection} push={false} />;
		}

		return <NotFoundContainer {...this.props} />;
	}
}

const AppLinksAsObserver = observer(_AppLinks);
const AppLinksWithContext = inject(UserSessionViewModelKey, FullScreenModalViewModelKey)(AppLinksAsObserver);
export const AppLinks = withEventLogging(AppLinksWithContext, 'AppLinks');
