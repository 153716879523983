import { styleSheet } from './styles';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	image: React.ReactNode;
	text: string;
	subtext: string;
}

export const EmptyState: React.FC<IProps> = props => {
	const { className, styles, image, text, subtext } = props;
	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} empty-state ${className || ''}`}>
			<div className={css(styleSheet.image)}>{image}</div>
			<div className={css(styleSheet.title)}>{text}</div>
			<div className={css(styleSheet.subtitle)}>{subtext}</div>
		</div>
	);
};
