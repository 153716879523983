import { StyleDeclaration, css } from 'aphrodite';
import * as React from 'react';
import { brandPrimary } from '../../../styles/colors';
import { baseStyleSheet as bs } from '../../../styles/styles';
import { TextMessageTail } from '../../svgs/icons/TextMessageTail';
import { styleSheet } from './styles';

export function OutboundTextMessageBubble({
	styleDeclarations = [],
	children,
}: {
	styleDeclarations?: StyleDeclaration[];
	children: React.ReactNode;
}) {
	return (
		<div
			className={css(
				bs.rounded2xl,
				bs.inlineBlock,
				bs.textXs,
				bs.py4,
				bs.px6,
				bs.relative,
				bs.whiteSpacePreWrap,
				bs.breakAll,
				bs.textWhite,
				bs.bgBrandPrimary,
				...styleDeclarations
			)}
		>
			{children}
			<TextMessageTail className={css(bs.absolute, bs.bottom0, styleSheet.tailSent)} fill={brandPrimary} />
		</div>
	);
}
