/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox } from '../../../Checkbox';
import { styleSheet } from '../styles';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';

interface IProps {
	onChange: () => void;
	onError: (error: any) => void;
	textMessagingVM: Api.TextMessagingViewModel;
}

export const ShowUnreadCheckbox: React.FC<IProps> = observer(({ onChange, onError, textMessagingVM }) => {
	const handleUnreadOnly = (event: React.ChangeEvent<HTMLInputElement>) => {
		textMessagingVM.setUnreadOnly(event.target.checked);
		onChange();
		textMessagingVM.getConversations().catch(onError);
	};

	return (
		<Checkbox
			checked={textMessagingVM.unreadOnly}
			className={css(styleSheet.unreadOnlyCheckbox)}
			id='unread-only-checkbox'
			onChange={handleUnreadOnly}
		>
			<span>Show unread texts only</span>
		</Checkbox>
	);
});
