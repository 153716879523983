import { StyleSheet } from 'aphrodite';
import {
	background,
	brandPrimaryHover,
	brandSecondary,
	darkGrayFontColor,
	label,
	navigation,
	white,
} from '../../styles/colors';
import { CssSelectors } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	back: {
		color: brandSecondary,
		fontSize: 14,
	},
	backIcon: {
		transform: 'rotate3d(0,0,1,90deg)',
		marginRight: 12,
	},
	container: {
		padding: 20,
	},
	headerImage: {
		marginTop: -70,
		...CssSelectors.allDescendants(
			{
				display: 'block',
				width: 140,
			},
			'img'
		),
	},
	imagePlaceholder: {
		bottom: -20,
		color: navigation,
		fontSize: 14,
		fontStyle: 'italic',
		height: 20,
		maxWidth: 190,
		position: 'absolute',
		right: 0,
		top: 5,
	},
	outerContainer: {
		paddingTop: '6rem',
		margin: '0 auto',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		maxWidth: 720,
	},
	innerContainer: {
		alignItems: 'center',
		background,
		display: 'flex',
		flexDirection: 'column',
		paddingLeft: '3rem',
		paddingRight: '3rem',
		paddingBottom: '3rem',
		width: '100%',
		boxSizing: 'border-box',
	},
	input: {
		display: 'none',
	},
	label: {
		color: label,
		display: 'block',
		fontSize: 14,
		letterSpacing: 0,
		marginBottom: 5,
		marginTop: 15,
		width: '100%',
	},
	labelFileInput: {
		alignItems: 'center',
		background,
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		marginTop: 10,
		width: '100%',
	},
	labelInnerDiv: {
		alignItems: 'flex-end',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		position: 'relative',
		width: 350,
	},
	optInContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		position: 'relative',
		width: '100%',
	},
	textAreaInput: {
		background: white,
		width: '100%',
		...CssSelectors.allDescendants(
			{
				background: white,
				height: 140,
			},
			'textarea'
		),
	},
	textAreaLength: {
		bottom: -18,
		color: darkGrayFontColor,
		fontSize: 12,
		fontStyle: 'italic',
		position: 'absolute',
		right: 0,
	},
	uploadImageLink: {
		color: brandPrimaryHover,
		fontSize: 14,
		margin: 0,
	},
});
