import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {},
	emailToggleContainer: {
		boxSizing: 'border-box',
		paddingTop: 40,
		...CssSelectors.allChildren(
			{
				marginTop: 30,
			},
			':not(:first-child)'
		),
	},
	emailToggleImage: {
		maxHeight: 46,
		width: 'auto',
	},
	integrationCardsContainer: {
		boxSizing: 'border-box',
		paddingTop: 30,
	},
	integrationCardsContainerMessage: {
		color: '#4A4A4A',
		fontSize: 14,
		marginBottom: 23,
	},
	integrationTagName: {
		fontWeight: 600,
	},
});
