import { css } from 'aphrodite';
import * as React from 'react';
import { TaggingGameButton } from '../../../../components/TaggingGameButton';
import { bs } from '../../../../styles/styles';
import { CompaniesGlobalSearch } from '../CompaniesGlobalSearch';
import { CompaniesSearchBarTypeTabs } from '../CompaniesSearchBarTypeTabs';
import { styleSheet } from './styles';

export const CompaniesSearchBarContainer: React.FC = props => {
	const { children } = props;

	return (
		<div
			className={css(
				bs.flex,
				bs.bgWhite,
				bs.h24,
				bs.overflowVisible,
				bs.pr4,
				bs.boxBorder,
				styleSheet.searchBarContainer
			)}
		>
			<div className={css(bs.flex, bs.justifyCenter, styleSheet.taggingGameLink)}>
				<TaggingGameButton />
			</div>
			<div className={css(bs.flex, bs.pt5, bs.flex1, bs.gap2)}>
				<div className={css(bs.flex1)}>
					<CompaniesGlobalSearch />
					<CompaniesSearchBarTypeTabs />
				</div>
				{children}
			</div>
		</div>
	);
};
