import * as Api from '@ViewModels';
import * as React from 'react';
import { Switch, useHistory, useRouteMatch } from 'react-router';
import * as TelephonyApiModels from '../../../../extViewmodels/sdk/models/Telephony';
import { useEventLogging } from '../../../../models/Logging';
import { useErrorMessages, useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	invalidateTelephonyGetConfiguration,
	useTelephonyConfigurationQuery,
	useTelephonySaveConfiguration,
	useTelephonyUploadAttachmentMutation,
} from '../../../../queries';
import { useTelephonyUpdateConfiguration } from '../../../../queries/Telephony/useTelephonyUpdateConfiguration';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { PrivateRoute } from '../../../components/PrivateRoute';
import { ITextingRegistrationConsentInfoForm, TextingRegistrationConsentInfo } from './TextingRegistrationConsentInfo';
import { IBrandRegistrationFormData, TextingRegistrationFormBrandReg } from './TextingRegistrationFormBrandReg';
import { TextingRegistrationFormConsent } from './TextingRegistrationFormConsent';
import { useTextingRegistrationStatus } from './hooks/useTextingRegistrationStatus';

const formDataToClientProfile = (
	formPayload: IBrandRegistrationFormData & ITextingRegistrationConsentInfoForm
): TelephonyApiModels.ClientProfile => ({
	address: {
		city: formPayload.city,
		country: 'US',
		postalCode: formPayload.postalCode,
		stateProvince: formPayload.stateProvince,
		street: formPayload.street,
	},
	businessInformation: {
		domain: formPayload.domain,
		ein: formPayload.ein,
		industry: formPayload.industry,
		legalBusinessName: formPayload.legalBusinessName,
		stockExchange: formPayload.stockExchange,
		stockSymbol: formPayload.stockSymbol,
		type: formPayload.type,
	},
	campaignOptions: {
		consent: {
			type: formPayload.consentType,
			optInScript: formPayload.optInScript,
			consentFormUrl: formPayload.consentFormUrl,
		},
		embeddedPhoneSample: formPayload.embeddedPhoneSample,
		includesEmbeddedPhone: formPayload.includesEmbeddedPhone,
		isDirectLender: formPayload.isDirectLender,
		privacyPolicyUrl: formPayload.privacyPolicyUrl,
	},
	primaryRepresentative: {
		businessTitle: formPayload.businessTitle,
		email: formPayload.email,
		firstName: formPayload.firstName,
		jobPosition: formPayload.jobPosition,
		lastName: formPayload.lastName,
		phoneNumber: formPayload.phoneNumber,
		mobilePhoneNumber: formPayload.mobilePhoneNumber,
	},
	registrationRequirementsCertified: true,
});

const FORM_BLOCKING_CAMPAIGN_STATUSES: TelephonyApiModels.CampaignRegistrationStatus[] = [
	Api.CampaignRegistrationStatus.Pending,
	Api.CampaignRegistrationStatus.Verified,
	Api.CampaignRegistrationStatus.Accepted,
];

export const TextingRegistrationFormPage = () => {
	const history = useHistory();
	const regStatus = useTextingRegistrationStatus();
	React.useEffect(() => {
		// Block user from form flow if registration exists and not declined or failed.
		if (
			!regStatus.isLoading &&
			!regStatus.forceAllowClientProfileEdit &&
			((regStatus.brandStatus != null && regStatus.brandStatus === 'Pending') ||
				(regStatus.campaignStatus != null && FORM_BLOCKING_CAMPAIGN_STATUSES.includes(regStatus.campaignStatus)))
		) {
			history.replace('/texting/registration');
			return;
		}
	}, [
		history,
		regStatus.isLoading,
		regStatus.brandStatus,
		regStatus.campaignStatus,
		regStatus.forceAllowClientProfileEdit,
	]);

	/* Previously was more exhaustive
	if (
		regStatus.isLoading ||
		(regStatus.brandStatus != null && regStatus.brandStatus === 'Pending') ||
		(regStatus.campaignStatus != null && FORM_BLOCKING_CAMPAIGN_STATUSES.includes(regStatus.campaignStatus))
	) {
	*/
	if (regStatus.isLoading) {
		return <LoadingSpinner type='large' />;
	}

	return <TextingRegistrationFormRoutes />;
};

function TextingRegistrationFormRoutes() {
	const errorMessages = useErrorMessages();
	const configurationQuery = useTelephonyConfigurationQuery();
	const saveConfigurationMutation = useTelephonySaveConfiguration({
		onError: error => {
			errorMessages.pushApiError(error);
			logApiError('TextingRegistrationOnSave-Error', error);
		},
		onSuccess: () => {
			invalidateTelephonyGetConfiguration();
		},
	});
	const updateConfigurationMutation = useTelephonyUpdateConfiguration({
		onError: error => {
			errorMessages.pushApiError(error);
			logApiError('TextingRegistrationOnUpdate-Error', error);
		},
		onSuccess: () => {
			invalidateTelephonyGetConfiguration();
		},
	});
	const [consent, setConsent] = React.useState(false);
	const [brandRegFormData, setBrandRegFormData] = React.useState<IBrandRegistrationFormData>();
	const [consentFormData, setConsentFormData] = React.useState<ITextingRegistrationConsentInfoForm>();
	const { path } = useRouteMatch();
	const userSession = useUserSession();
	const history = useHistory();
	const { logApiError } = useEventLogging('TextingRegistrationFormRoutes');
	const uploadAttachmentMutation = useTelephonyUploadAttachmentMutation({
		onError: error => {
			errorMessages.push({
				messages: ['Failed to upload the attachment'],
			});
			logApiError('ImageAttachmentMutation-Error', error);
		},
	});
	const isSubmitting =
		uploadAttachmentMutation.isLoading || updateConfigurationMutation.isLoading || saveConfigurationMutation.isLoading;
	const onSave = async (data: ITextingRegistrationConsentInfoForm) => {
		const defaultOptInFlowImage = configurationQuery?.data?.clientProfile?.campaignOptions
			?.optInFlowImage as Api.IFileAttachment;
		let newOptInFlowImage = null;
		try {
			if (data?.optInFlowImage && data?.optInFlowImage instanceof File) {
				newOptInFlowImage = await uploadAttachmentMutation.mutateAsync(data.optInFlowImage);
			}
			const clientProfilePayload = formDataToClientProfile({ ...brandRegFormData!, ...data });
			clientProfilePayload.campaignOptions = {
				...clientProfilePayload.campaignOptions!,
				optInFlowImage: newOptInFlowImage || defaultOptInFlowImage,
			};
			if (configurationQuery.data?.id) {
				updateConfigurationMutation.mutate({
					body: clientProfilePayload,
					registrationId: configurationQuery.data.id,
				});
				return;
			}
			saveConfigurationMutation.mutate({
				clientProfile: clientProfilePayload,
			});
		} catch (error) {
			logApiError('BrandRegistrationOnSave-Error', error as any);
		}
	};
	return (
		<Switch>
			<PrivateRoute exact path={path} userSession={userSession}>
				<TextingRegistrationFormConsent
					defaultValues={{ consent }}
					onContinue={() => {
						setConsent(true);
					}}
				/>
			</PrivateRoute>
			<PrivateRoute path={`${path}/brand-registration`} userSession={userSession}>
				<TextingRegistrationFormBrandReg
					defaultValues={brandRegFormData}
					onSave={formData => {
						setBrandRegFormData(formData);
						history.push(`/texting/registration/form/consent-info`);
					}}
				/>
			</PrivateRoute>
			<PrivateRoute path={`${path}/consent-info`} userSession={userSession}>
				<TextingRegistrationConsentInfo
					isSubmitting={isSubmitting}
					defaultValues={consentFormData}
					onSave={data => {
						setConsentFormData(data);
						onSave(data);
					}}
				/>
			</PrivateRoute>
		</Switch>
	);
}
