import * as Api from '@ViewModels';
import { yupResolver } from '@hookform/resolvers/yup';
import { css } from 'aphrodite';
import * as React from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { useErrorMessages } from '../../../../aida/hooks';
import { useEventLogging } from '../../../../models/Logging';
import { useTelephonyConfigurationQuery, useTelephonyUploadAttachmentMutation } from '../../../../queries';
import { useTelephonyUpdateConfiguration } from '../../../../queries/Telephony/useTelephonyUpdateConfiguration';
import registrationAttention from '../../../assets/registrationAttention.png';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { baseStyleSheet } from '../../../styles/styles';
import { useTextingRegistrationStatus } from '../TextingRegistrationForm/hooks/useTextingRegistrationStatus';
import { TextingOptInFlowContent, TextingOptInFlowFile, TextingRegistrationLayout } from '../presentation';
import { styleSheet as mainStyleSheet } from '../styles';
import { styleSheet } from './styles';

const validationSchema = yup
	.object({
		optInFlow: yup
			.string()
			// @ts-ignore
			.test('len', 'The content should have at least 40 characters and not more than 2048', (value: string) => {
				if (value === undefined) {
					return true;
				}
				return value.length >= 40 && value.length <= 2048;
			})
			.required('This is required'),
		optInFlowImage: yup
			.mixed()
			// @ts-ignore
			.test('fileSize', 'The file is too large', (file: Api.IFileAttachment & File) => {
				if (!file) {
					return true;
				}
				return (file?.size || file?.fileSize) <= 10000000;
			})
			.notRequired(),
	})
	.required();

interface IOptInFlowFormData {
	optInFlow?: string;
	optInFlowImage?: Api.IFileAttachment & File;
}

export const TextingRegistrationOptIn = () => {
	const history = useHistory();
	const errorMessages = useErrorMessages();
	const logger = useEventLogging('BrandCampaignRegistration');
	const configurationQuery = useTelephonyConfigurationQuery();
	const initialRecipient = configurationQuery?.data?.clientProfile;
	const onErrorSaving = (error: Api.IOperationResultNoValue) => {
		error.systemMessage =
			'Sorry, we got an error, please double-check the data you filled in and try to send it again.';
		// @ts-ignore
		errorMessages.pushApiError(error, 'Campaign Registration Error');
	};

	const updateConfigurationMutation = useTelephonyUpdateConfiguration({ onError: onErrorSaving });
	const uploadAttachmentMutation = useTelephonyUploadAttachmentMutation({
		onError: error => {
			const msg = error.systemMessage ?? 'Failed to upload the attachment';

			// @ts-ignore
			errorMessages.push({
				messages: [msg],
			});
			logger.logApiError('ImageAttachmentMutation-Error', error);
		},
	});

	const isSaving = updateConfigurationMutation.isLoading || uploadAttachmentMutation.isLoading;
	const onSave = async (formData: IOptInFlowFormData) => {
		// @ts-ignore
		const defaultOptInFlowImage: Api.IFileAttachment = initialRecipient?.campaignOptions?.optInFlowImage;
		let newOptInFlowImage = null;
		try {
			if (formData?.optInFlowImage && formData?.optInFlowImage instanceof File) {
				try {
					newOptInFlowImage = await uploadAttachmentMutation.mutateAsync(formData?.optInFlowImage);
				} catch (error) {
					return;
				}
			}

			// @ts-ignore
			initialRecipient.campaignOptions = {
				// @ts-ignore
				...initialRecipient.campaignOptions,
				optInFlow: formData?.optInFlow,
				optInFlowImage: newOptInFlowImage || defaultOptInFlowImage,
			};

			await updateConfigurationMutation.mutateAsync({
				// @ts-ignore
				body: initialRecipient,
				// @ts-ignore
				// @ts-ignore
				registrationId: configurationQuery.data.id,
			});
			history.replace(`/texting/registration`);
		} catch (error) {
			// @ts-ignore
			logger.logApiError('BrandRegistrationOptIn-Error', error);
		}
	};

	const regStatus = useTextingRegistrationStatus();
	if (regStatus.campaignStatus === 'Accepted') {
		history.replace(`/texting/registration`);
	}

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(validationSchema),
		values: {
			optInFlow: initialRecipient?.campaignOptions?.optInFlow ?? '',
			optInFlowImage: (initialRecipient?.campaignOptions?.optInFlowImage ?? null) as Api.IFileAttachment & File,
		},
	});

	const renderError = (fieldError: FieldError) =>
		fieldError != null ? (
			<p role='alert' className={css(styleSheet.formErrorLine)}>
				{fieldError.message}
			</p>
		) : null;

	if (configurationQuery.isLoading) {
		return (
			<TextingRegistrationLayout>
				<LoadingSpinner className={css(styleSheet.loading)} type='large' />
			</TextingRegistrationLayout>
		);
	}

	return (
		<TextingRegistrationLayout>
			<div className={css(mainStyleSheet.innerContainer)}>
				<figure className={css(styleSheet.headerImage)}>
					<img src={registrationAttention} alt='Texting Campaign Registration' />
				</figure>
				<header className={css(styleSheet.containerHeader)}>
					<h2>Opt-in Consent</h2>
					<p>
						In order to send text campaigns, please describe how you are capturing consent from your clients to send
						text messages.
					</p>
				</header>
				<form onSubmit={handleSubmit(onSave)}>
					<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='optInFlow'>
						How are you capturing texting consent from clients:
					</label>
					<Controller
						name='optInFlow'
						control={control}
						render={({ field: { ref, ...fieldProps } }) => (
							<TextingOptInFlowContent {...fieldProps} disabled={isSaving} inputId='opt-in-flow-input' />
						)}
					/>
					{/* @ts-ignore */}
					{renderError(errors?.optInFlow)}

					<Controller
						name='optInFlowImage'
						control={control}
						render={({ field: { ref, ...fieldProps } }) => (
							<TextingOptInFlowFile
								{...fieldProps}
								disabled={isSaving}
								fileName={fieldProps.value?.fileName || fieldProps.value?.name}
								inputId='opt-in-flow-image-input'
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									// @ts-ignore
									const file = event.target.files[0];
									if (file) {
										fieldProps.onChange(file);
									}
								}}
							/>
						)}
					/>
					{/* @ts-ignore */}
					{renderError(errors?.optInFlowImage)}

					<footer className={css(styleSheet.containerFooter)}>
						<button
							disabled={isSaving}
							className={css(baseStyleSheet.ctaButton, baseStyleSheet.ctaButtonFullWidth)}
							type='submit'
						>
							{isSaving ? 'Saving Information...' : 'Submit'}
						</button>
					</footer>
				</form>
			</div>
		</TextingRegistrationLayout>
	);
};
