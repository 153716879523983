import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { SvgIcon } from '../../icons/SvgIcon';

export const TextingCallForwardingGraphic = ({
	styles = [],
	width = 235,
	height = 172,
	className,
	...props
}: React.SVGProps<SVGSVGElement> & { styles?: StyleDeclarationValue[] }) => {
	return (
		<SvgIcon
			className={`${className || ''} ${css(...styles)}`}
			{...props}
			height={height}
			width={width}
			viewBox='0 0 235 172'
		>
			<g fill='none' fillRule='evenodd'>
				<path
					fill='#3F3D56'
					fillRule='nonzero'
					d='M192 172C184.823498 170.867921 177.923384 169.905 171.29966 169.111238L173.850318 163.970845C172.964968 163.673469 169.12845 166.64723 169.12845 166.64723L172.374735 151.778426C167.947983 152.373178 165.882166 167.539359 165.882166 167.539359L160.86518 162.483965 163.334821 168.207601C143.065823 166.061536 125.57558 165.391804 110.873149 165.54541L113.056263 161.145773C112.170913 160.848397 108.334395 163.822157 108.334395 163.822157L111.580679 148.953353C107.153928 149.548105 105.08811 164.714286 105.08811 164.714286L100.071125 159.658892 102.686863 165.72116C92.6158022 166.016019 82.5663942 166.831492 72.5782887 168.16436 74.756702 161.325965 82.3641189 154.752181 82.3641189 154.752181 76.5147392 156.585915 73.471619 159.573361 71.8965335 162.342586 71.5779268 148.293412 73.7228149 134.297845 78.2324841 121 78.2324841 121 66.4278132 147.763848 68.1985138 165.606414L68.4231832 168.775872C58.1533025 170.390259 53 172 53 172L192 172zM219.5 135C217.567003 135 216 133.432997 216 131.5 216 129.567003 217.567003 128 219.5 128 221.432997 128 223 129.567003 223 131.5 222.99779 133.43208 221.43208 134.99779 219.5 135zM219.5 128.583333C217.889169 128.583333 216.583333 129.889169 216.583333 131.5 216.583333 133.110831 217.889169 134.416667 219.5 134.416667 221.110831 134.416667 222.416667 133.110831 222.416667 131.5 222.414876 129.889912 221.110088 128.585124 219.5 128.583333zM49.8607779 171L46 166.860785 50.139225 163 54 167.139215 49.8607779 171zM46.6153773 166.882189L49.8821877 170.384603 53.3846026 167.117796 50.1178009 163.615382 46.6153773 166.882189z'
				/>
				<rect width='215' height='1' x='8' y='171' fill='#3F3D56' fillRule='nonzero' />
				<path
					fill='#3F3D56'
					fillRule='nonzero'
					d='M165,39.4647158 L165,58.8430243 C164.999006,59.2157593 164.697604,59.5176733 164.325502,59.5186687 L163.845433,59.5186687 L163.845433,160.467028 C163.845107,166.284101 159.137512,170.999686 153.330306,171 L90.3604478,171 C87.5720189,170.999734 84.8979159,169.889834 82.9264735,167.914492 C80.9550311,165.93915 79.8477588,163.260195 79.8482697,160.467028 L79.8482697,63.0591635 L79.5007116,63.0591635 C79.238172,63.0595566 79.0247887,62.8471303 79.0235555,62.5841471 L79.0235555,51.5555057 C79.0235329,51.4287341 79.0737973,51.3071482 79.1632863,51.2175071 C79.2527753,51.127866 79.374155,51.0775162 79.5007116,51.0775389 L79.8482697,51.0775389 L79.8482697,46.9233583 L79.4977574,46.9233583 C79.2221371,46.9222962 78.9994256,46.6978782 79.0000011,46.4217882 L79.0000011,35.5436178 C79.0007004,35.2685353 79.2231415,35.0457083 79.4977574,35.0449981 L79.8482609,35.0449981 L79.8482609,29.3743055 L79.4918665,29.3743055 C79.374643,29.3744624 79.2621756,29.3278859 79.1792859,29.2448554 C79.0963963,29.1618249 79.0498988,29.0491664 79.0500554,28.9317437 L79.0500554,22.995514 C79.0495024,22.7503862 79.2471563,22.5510766 79.4918665,22.5500017 L79.8482609,22.5500017 L79.8482609,10.5300217 C79.8484821,4.71453619 84.5548178,0.000211815424 90.3604389,0 L153.330294,0 C159.136491,-2.62905347e-05 163.843809,4.71395995 163.845421,10.5300217 L163.845421,38.7890714 L164.325502,38.7890714 C164.697608,38.7900587 164.999014,39.0919774 165,39.4647158 Z'
				/>
				<rect width='12' height='2' x='114' y='5' fill='#E6E8EC' fillRule='nonzero' rx='1' />
				<circle cx='128.5' cy='6.5' r='1.5' fill='#E6E8EC' fillRule='nonzero' />
				<path
					fill='#CAE0EC'
					fillRule='nonzero'
					d='M158.999997,11.7202119 L158.999997,161.282748 C159.001918,163.250976 158.140096,165.120476 156.643366,166.394856 C155.430211,167.431099 153.888284,168.000182 152.294256,167.999992 L91.7057409,167.999992 C90.6277181,168.001643 89.5652772,167.742076 88.6089616,167.243412 C88.3842945,167.128113 88.1667878,166.999309 87.9576314,166.857705 C86.1085311,165.60744 85.0001289,163.518168 85,161.282748 L85,11.7202119 C84.9999215,9.93787685 85.7063919,8.22851903 86.9639793,6.96821784 C88.2215667,5.70791664 89.9272437,4.99992166 91.7057409,5.00000036 L100.774034,5.00000036 L100.774034,6.16602381 C100.774059,9.22203668 103.246106,11.6994184 106.295538,11.699443 L137.10938,11.699443 C140.158816,11.6994283 142.630869,9.22204075 142.630884,6.16602381 L142.630884,5.00000036 L152.294256,5.00000036 C154.072906,4.99942086 155.778874,5.70725502 157.036569,6.9676648 C158.294265,8.22807457 159.000575,9.93772339 158.999997,11.7202119 Z'
				/>
				<circle cx='103.5' cy='130.5' r='9.5' fill='#89C947' />
				<path
					fill='#A7ABAD'
					d='M8.5978125,6.68826562 L7.1476875,5.22914063 C6.9283125,5.00807813 6.571125,5.00807813 6.3511875,5.22801562 L5.0625,6.51670312 L2.25,3.70420313 L3.540375,2.41382812 C3.75975,2.19445312 3.7603125,1.83839062 3.5409375,1.61845312 L2.0896875,0.164953125 C1.86975,-0.054984375 1.5136875,-0.054984375 1.29375,0.164953125 L0.00225,1.45589062 L0,1.45420313 C0,5.49295312 3.27375,8.76670313 7.3125,8.76670313 L8.5966875,7.48251563 C8.8160625,7.26314063 8.816625,6.90820313 8.5978125,6.68826562'
					transform='translate(99 126)'
				/>
				<g transform='translate(131 121)'>
					<circle cx='9.5' cy='9.5' r='9.5' fill='#D0021B' />
					<path
						fill='#A7ABAD'
						d='M8.5978125,6.68826562 L7.1476875,5.22914063 C6.9283125,5.00807813 6.571125,5.00807813 6.3511875,5.22801562 L5.0625,6.51670312 L2.25,3.70420313 L3.540375,2.41382812 C3.75975,2.19445312 3.7603125,1.83839062 3.5409375,1.61845312 L2.0896875,0.164953125 C1.86975,-0.054984375 1.5136875,-0.054984375 1.29375,0.164953125 L0.00225,1.45589062 L0,1.45420313 C0,5.49295312 3.27375,8.76670313 7.3125,8.76670313 L8.5966875,7.48251563 C8.8160625,7.26314063 8.816625,6.90820313 8.5978125,6.68826562'
						transform='rotate(134 5.939 8.061)'
					/>
				</g>
				<g transform='translate(0 144)'>
					<rect width='27' height='27' fill='#00528C' rx='13.5' />
					<polygon
						fill='#FFF'
						fillRule='nonzero'
						points='18.737 20.061 18.737 20.567 11.322 20.567 11.322 4.966 10.745 4.966 10.828 20.986 18.737 20.986 18.737 22.5 9.263 22.5 9.263 4.5 9.76 4.5 9.76 22.006 18.239 22.006 18.239 21.492 10.251 21.492 10.251 4.5 11.816 4.5 11.816 20.061'
					/>
				</g>
				<g fillRule='nonzero' transform='matrix(-1 0 0 1 235 146)'>
					<path
						fill='#3F3D56'
						d='M12.2577264,15.1328899 C15.8730924,22.0364354 23.5462001,25 23.5462001,25 C23.5462001,25 25.3576385,16.7706676 21.7422725,9.86710712 C18.1269066,2.96354662 10.4537988,0 10.4537988,0 C10.4537988,0 8.64236331,8.22933237 12.2577264,15.1328899 Z'
					/>
					<path
						fill='#89C947'
						d='M14.8201547,12.7991253 C21.4475702,16.7977747 24,24.740196 24,24.740196 C24,24.740196 15.8072607,26.1995262 9.17984525,22.2008738 C2.55242984,18.2022215 0,10.2598031 0,10.2598031 C0,10.2598031 8.19273638,8.80047584 14.8201547,12.7991253 Z'
					/>
				</g>
				<rect width='55' height='7' x='96' y='49' fill='#FFF' rx='3.5' />
				<g stroke='#00AAE8' transform='translate(9 47)'>
					<path
						strokeDasharray='4'
						d='M1,89 C-0.333333333,69 5.33333333,50.6666667 18,34 C30.6666667,17.3333333 44.6666667,7.66666667 60,5'
					/>
					<polyline points='58 0 63 5 58 10' />
				</g>
			</g>
		</SvgIcon>
	);
};
