import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { NotFound } from '../../components/NotFound';
import { baseStyleSheet } from '../../styles/styles';
import { INavigationItemProps } from '../MainContainer';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

interface IProps extends INavigationItemProps, RouteComponentProps<any> {
	className?: string;
}

const NotFoundContainerBase: React.FC<IProps> = ({ className = '', routeContainerClassName = '' }) => {
	return (
		<div className={`${routeContainerClassName} ${className}`}>
			<MultiContainerHeader
				appBarHeader={<div className={css(baseStyleSheet.cardWelcome)}>404</div>}
				fullscreenHeader=''
			/>
			<NotFound className={`${css(styleSheet.notFound)}`} />
		</div>
	);
};

export const NotFoundContainer = withRouter(NotFoundContainerBase);
