import { ClassifyContactViewModel, ContactViewModel, InteractionType } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { ILocationState } from '../../../../models';
import { getContactTitleAndCompany } from '../../../../models/UiUtils';
import { brandPrimary } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { FullscreenModalNavLink } from '../../fullscreen/FullscreenModalNavLink';
import { EmailIcon } from '../../svgs/icons/EmailIcon';
import { AddSuggestedTags } from '../../tags/AddSuggestedTags';
import { styleSheet } from './styles';

const _ContactTaggingGameCard = ({
	className,
	onAddTagButtonClicked,
	onDoneButtonClicked,
	onTagAdded,
	popularTags,
	styles,
	unclassifiedContact,
}: {
	className?: string;
	onAddTagButtonClicked?(e: React.MouseEvent<HTMLElement>): void;
	onDoneButtonClicked?(e: React.MouseEvent<HTMLElement>): void;
	onTagAdded?(): void;
	popularTags?: string[];
	styles?: StyleDeclarationValue[];
	unclassifiedContact: ClassifyContactViewModel;
}) => {
	const typeStringValue = React.useMemo(() => {
		if (!unclassifiedContact?.mostRecentInteraction?.interactionDate) {
			return null;
		}
		switch (unclassifiedContact.mostRecentInteraction.interactionType) {
			case InteractionType.NoteMention: {
				return 'Note written';
			}
			case InteractionType.PhoneCall: {
				return 'Phone call';
			}
			case InteractionType.Meeting: {
				return 'Meeting';
			}
			case InteractionType.SentEmail: {
				return 'Sent an email';
			}
			case InteractionType.TextMessage: {
				return 'Sent a text';
			}
			default: {
				break;
			}
		}
	}, [unclassifiedContact]);

	const contact = unclassifiedContact.contact;
	const emailAddress =
		contact.primaryEmail?.value || (contact.emailAddresses ? contact.emailAddresses[0]?.value : null);

	const daysSinceLastInteraction = unclassifiedContact.mostRecentInteraction?.interactionDate
		? moment().diff(moment(unclassifiedContact.mostRecentInteraction?.interactionDate), 'days')
		: -1;

	const hasInteraction = daysSinceLastInteraction >= 0;
	const hasTag = contact.tags?.length > 0;
	const locationState: ILocationState<ContactViewModel, boolean> = {
		model: true,
		viewModel: contact,
	};

	const hasRecentEmails = unclassifiedContact.recentEmailSubjects?.length > 0;

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} contact-tagging-game-card ${className || ''}`}>
			<div className={css(styleSheet.header)}>
				<div className={css(baseStyleSheet.truncateText)}>
					<FullscreenModalNavLink
						className={css(styleSheet.name)}
						title={contact.name}
						to={{ pathname: `/people/${contact.id}`, state: locationState }}
					>
						{contact.name}
					</FullscreenModalNavLink>
				</div>
				<div className={css(baseStyleSheet.truncateText, styleSheet.titleAndCompany)}>
					{getContactTitleAndCompany(contact)}
				</div>
				{!!emailAddress && (
					<a
						className={css(baseStyleSheet.brandLink, styleSheet.email)}
						target='_blank'
						href={`mailto:${emailAddress}`}
						rel='noreferrer'
					>
						<EmailIcon fillColor={brandPrimary} width={16} height={14} /> {emailAddress}
					</a>
				)}
			</div>
			{!!hasInteraction && (
				<div className={css(styleSheet.interactionBanner)}>
					<div className={css(styleSheet.interactionBannerRow)}>
						<span className={css(styleSheet.interactionBannerTitle)}>Last Interaction</span>
						<span className={css(styleSheet.interactionBannerValue)}>
							{`${daysSinceLastInteraction} day${daysSinceLastInteraction === 1 ? '' : 's'}`}
						</span>
					</div>

					<div className={css(styleSheet.interactionBannerRow)}>
						<span className={css(styleSheet.interactionBannerValue, styleSheet.interactionBannerType)}>
							{`${typeStringValue} on ${moment(unclassifiedContact.mostRecentInteraction.interactionDate).format('l')}`}
						</span>
					</div>
				</div>
			)}
			<div className={css(styleSheet.body)}>
				<div className={css(styleSheet.tagsContainer, hasRecentEmails ? styleSheet.tagsContainerSplit : null)}>
					<AddSuggestedTags
						entity={unclassifiedContact.contact}
						onAddTagButtonClicked={onAddTagButtonClicked}
						onTagAdded={onTagAdded}
						popularTags={popularTags}
						suggestedTags={unclassifiedContact.suggestedTags}
						singleColumn={hasRecentEmails}
					/>
				</div>

				{hasRecentEmails ? <div className={css(styleSheet.separator)} /> : null}

				{hasRecentEmails ? (
					<div className={css(styleSheet.recentEmailsContainer)}>
						<h5 className={css(styleSheet.recentEmailsHeader)}>Recently sent email subjects</h5>
						<div className={css(styleSheet.recentEmailsContainerList)}>
							{unclassifiedContact.recentEmailSubjects.map(emailSubject => {
								return (
									<div key={`email-subject-${emailSubject}`}>
										<span className={css(baseStyleSheet.emailSubjectBadge)}>{emailSubject}</span>
									</div>
								);
							})}
						</div>
					</div>
				) : null}
			</div>
			{!!hasTag && (
				<button className={css(styleSheet.doneButton)} onMouseDown={onDoneButtonClicked}>
					<span>Done</span>
				</button>
			)}
		</div>
	);
};

export const ContactTaggingGameCard = observer(_ContactTaggingGameCard);
