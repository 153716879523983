import { header } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {},
	header: {
		fontSize: '18px',
	},
	text: {
		color: header,
		fontSize: '14px',
		padding: '20px 0',
	},
});
