import { ILocationState } from '../../../../models';
import { FullScreenModalViewModelKey, IFullscreenModalComponentProps } from '../../../../models/AppState';
import { CompanyViewModel, ContactViewModel, IContact } from '../../../../viewmodels/AppViewModels';
import EmptyContactsPlaceholderUrl from '../../../assets/emptyContactsPlaceholder.svg';
import { baseStyleSheet } from '../../../styles/styles';
import { Placeholder } from '../../Placeholder';
import { Portal } from '../../Portal';
import { PrincipalListItem } from '../../PrincipalListItem';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Redirect } from 'react-router';
import Waypoint from 'react-waypoint';

interface IProps extends IFullscreenModalComponentProps {
	className?: string;
	company: CompanyViewModel;
	scrollToBottomWaypointPortalId?: string;
}

interface IState {
	selectedContact?: ContactViewModel;
}

class _CompanyContactsList extends React.Component<IProps, IState> {
	public state: IState = {};

	public componentDidMount() {
		this.props.company.getContacts();
	}

	public render() {
		const { company, className } = this.props;
		const loading = !!company.fetchingContacts;
		const isEmpty = !!company.contacts && company.contacts.length === 0;

		return (
			<div className={`${css(styleSheet.list)} ${className || ''}`}>
				{this.getContactRows()}
				<Placeholder
					isEmpty={isEmpty}
					isLoading={loading}
					message='Does not have any known contacts.'
					placeholderUrl={EmptyContactsPlaceholderUrl}
				/>
				{this.renderBottomWaypoint()}
				{this.renderRedirect()}
			</div>
		);
	}

	private renderRedirect() {
		const { selectedContact } = this.state;
		if (selectedContact) {
			const locationState: ILocationState<ContactViewModel, IContact> = {
				viewModel: selectedContact,
			};
			return (
				<Redirect
					push={true}
					to={{
						pathname: `/people/${selectedContact.id}`,
						state: locationState,
					}}
				/>
			);
		}
	}

	private scrolledToBottom = () => {
		if (!this.props.company.fetchingContacts) {
			// only grab the next batch if we're not fetching
			this.props.company.getContacts();
		}
	};

	private getContactRows() {
		return (
			!!this.props.company.contacts &&
			this.props.company.contacts.length > 0 && (
				<ul>
					{this.props.company.contacts.map((contact, i) => {
						return (
							<PrincipalListItem
								className={css(styleSheet.item, baseStyleSheet.truncateText)}
								key={contact.id || i}
								onClick={this.onContactClicked(contact)}
								principal={contact.toJs()}
							>
								{!!contact.jobTitle && <div className='truncate-text'>{contact.jobTitle}</div>}
							</PrincipalListItem>
						);
					})}
				</ul>
			)
		);
	}

	private onContactClicked = (contact: ContactViewModel) => () => {
		if (contact.isValid) {
			const { fullscreenModal } = this.props;
			if (fullscreenModal) {
				const locationState: ILocationState<ContactViewModel, IContact> = {
					viewModel: contact,
				};
				fullscreenModal.history.push({
					pathname: `/people/${contact.id}`,
					state: locationState,
				});
			} else {
				this.setState({
					selectedContact: contact,
				});
			}
		}
	};

	private renderBottomWaypoint() {
		const { scrollToBottomWaypointPortalId, company } = this.props;
		if (!company.contacts) {
			return null;
		}

		const waypoint = <Waypoint bottomOffset='-200px' onEnter={this.scrolledToBottom} />;
		if (scrollToBottomWaypointPortalId) {
			return <Portal destination={scrollToBottomWaypointPortalId}>{waypoint}</Portal>;
		}

		return waypoint;
	}
}

const CompanyContactsListAsObserver = observer(_CompanyContactsList);
export const CompanyContactsList = inject(FullScreenModalViewModelKey)(CompanyContactsListAsObserver);
