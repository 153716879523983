import { brandSecondary, mention } from '../../../styles/colors';
import { SvgIcon } from '@WebComponents/svgs/icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	type:
		| 'UserProfile'
		| 'KitAndEmail'
		| 'Password'
		| 'ImportContacts'
		| 'CrmIntegration'
		| 'Notifications'
		| 'EmailSignature'
		| 'ScheduleMeeting'
		| 'Texting'
		| 'SocialMedia';
}

export const SettingsGroupIcon: React.FC<IProps> = props => {
	const { className, type } = props;
	const renderContent = () => {
		switch (type) {
			case 'SocialMedia': {
				return (
					<>
						<circle cx='30' cy='31' r='18' fill='#fff' stroke={brandSecondary} strokeWidth='2' />
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M35 39a2 2 0 1 1 .001-4.001A2 2 0 0 1 35 39m-12-6a2 2 0 1 1 .001-4.001A2 2 0 0 1 23 33m12-10a2 2 0 1 1-.001 4.001A2 2 0 0 1 35 23m0 10c-1.2 0-2.266.542-3 1.382l-5.091-2.546c.058-.27.091-.549.091-.836 0-.287-.033-.566-.091-.836L32 27.618c.734.84 1.8 1.382 3 1.382 2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4c0 .287.033.566.091.836L26 28.382A3.975 3.975 0 0 0 23 27c-2.206 0-4 1.794-4 4s1.794 4 4 4c1.2 0 2.266-.542 3-1.382l5.091 2.546c-.058.27-.091.549-.091.836 0 2.206 1.794 4 4 4s4-1.794 4-4-1.794-4-4-4'
							fill={brandSecondary}
						/>
					</>
				);
			}
			case 'KitAndEmail': {
				return (
					<>
						<rect width='34' height='34' x='13' y='13' fill='#FFF' stroke={brandSecondary} strokeWidth='2' rx='17' />
						<g fill={brandSecondary} transform='translate(20 20)'>
							<path d='M14,14 C14,14.552 13.551,15 13,15 L7,15 C6.449,15 6,14.552 6,14 L6,6.863 L9.998,3.336 L14,6.894 L14,14 Z M19.665,9.253 L16,5.995 L16,3 L19,3 L19,0 L14,0 L14,4.218 L10.002,0.665 L0.838,8.75 L2.162,10.25 L4,8.628 L4,14 C4,15.655 5.346,17 7,17 L9,17 L9,20 L11,20 L11,17 L13,17 C14.654,17 16,15.655 16,14 L16,8.671 L18.335,10.747 L19.665,9.253 Z' />
							<polygon points='7 11 13 11 13 9 7 9' />
						</g>
					</>
				);
			}
			case 'ImportContacts': {
				return (
					<>
						<rect width='34' height='28' x='13' y='16' fill='#FFF' stroke={brandSecondary} strokeWidth='2' rx='5' />
						<g fill={brandSecondary} transform='translate(19 20)'>
							<path d='M8 6C6.897 6 6 6.896 6 8 6 9.103 6.897 10 8 10 9.103 10 10 9.103 10 8 10 6.896 9.103 6 8 6M8 12C5.794 12 4 10.206 4 8 4 5.794 5.794 4 8 4 10.206 4 12 5.794 12 8 12 10.206 10.206 12 8 12M2.1587 18L13.8417 18C13.2437 16.192 11.0077 15 7.9997 15 4.9917 15 2.7567 16.192 2.1587 18L2.1587 18zM15.9997 20L-.0003 20-.0003 19C-.0003 15.467 3.2897 13 7.9997 13 12.7107 13 15.9997 15.467 15.9997 19L15.9997 20z' />
							<polygon points='22 3 19 3 19 0 17 0 17 3 14 3 14 5 17 5 17 8 19 8 19 5 22 5' />
						</g>
					</>
				);
			}
			case 'Password': {
				return (
					<>
						<rect width='34' height='28' x='13' y='16' fill='#FFF' stroke={brandSecondary} strokeWidth='2' rx='5' />
						<path
							fill={brandSecondary}
							d='M34.998,35 L33,35 L33,37 L34.998,37 L34.997,38 L25,38 L25,30 L35,30 L34.999,32 L33,32 L33,34 L34.998,34 L34.998,35 Z M30,22 C32.206,22 34,23.794 34,26 L34,28 L26,28 L26,26 C26,23.794 27.794,22 30,22 L30,22 Z M38,34 L38,32 L37,32 L37,30 C37,29.263 36.595,28.625 36,28.278 L36,26 C36,22.691 33.309,20 30,20 C26.691,20 24,22.691 24,26 L24,28.278 C23.405,28.625 23,29.263 23,30 L23,38 C23,39.103 23.897,40 25,40 L35,40 C36.103,40 37,39.103 37,38 L37,37 L38,37 L38,35 L37,35 L37,34 L38,34 Z'
						/>
					</>
				);
			}
			case 'UserProfile': {
				return (
					<>
						<g stroke={brandSecondary} strokeWidth='2' transform='translate(12 13)'>
							<circle cx='18' cy='18' r='18' fill='#FFF' />
							<g strokeLinecap='round' strokeLinejoin='round' transform='translate(11 9)'>
								<path d='M14,14.9333333 L14,13.3777778 C14,11.6595586 12.4329966,10.2666667 10.5,10.2666667 L3.5,10.2666667 C1.56700338,10.2666667 1.94289029e-16,11.6595586 0,13.3777778 L0,14.9333333' />
								<circle cx='7' cy='3.267' r='3.267' />
							</g>
						</g>
					</>
				);
			}
			case 'Notifications': {
				return (
					<>
						<g fill='none' fillRule='evenodd'>
							<ellipse cx='30' cy='29.4' fill={mention} rx='30' ry='29.4' />
							<circle cx='30' cy='29' r='18' fill='#FFF' stroke={brandSecondary} strokeWidth='2' />
							<path
								fill={brandSecondary}
								fillRule='nonzero'
								d='M29.974359,38 C30.9333333,38 31.7179487,37.2153846 31.7179487,36.2564103 L28.2307692,36.2564103 C28.2307692,37.2153846 29.0153846,38 29.974359,38 Z M35.2051282,32.7692308 L35.2051282,28.4102564 C35.2051282,25.7338462 33.7841026,23.4933333 31.2820513,22.9005128 L31.2820513,22.3076923 C31.2820513,21.5841026 30.6979487,21 29.974359,21 C29.2507692,21 28.6666667,21.5841026 28.6666667,22.3076923 L28.6666667,22.9005128 C26.1733333,23.4933333 24.7435897,25.7251282 24.7435897,28.4102564 L24.7435897,32.7692308 L23,34.5128205 L23,35.3846154 L36.9487179,35.3846154 L36.9487179,34.5128205 L35.2051282,32.7692308 Z M33.4615385,33.6410256 L26.4871795,33.6410256 L26.4871795,28.4102564 C26.4871795,26.2482051 27.8035897,24.4871795 29.974359,24.4871795 C32.1451282,24.4871795 33.4615385,26.2482051 33.4615385,28.4102564 L33.4615385,33.6410256 Z'
							/>
						</g>
					</>
				);
			}
			case 'EmailSignature': {
				return (
					<>
						<g stroke={brandSecondary}>
							<rect x='13' y='16' width='34' height='28' rx='4' fill='#fff' stroke={brandSecondary} strokeWidth='2' />
							<path d='M20 27.5h20' stroke={brandSecondary} strokeLinecap='square' />
							<path
								d='M16.691 36.517c27.904-21.256-8.098 11.007 10.001-.091 4.767-2.923.188-3.073.253-.55.072 2.766 10.042 3.596 12.836.898'
								stroke={brandSecondary}
							/>
							<path d='M20 23.5h20' stroke={brandSecondary} strokeLinecap='square' />
						</g>
					</>
				);
			}
			case 'ScheduleMeeting': {
				return (
					<>
						<rect x='13' y='13' width='34' height='34' rx='16' fill='white' stroke={brandSecondary} strokeWidth='2' />
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M36.3977 35.4H23.6001V26.6H36.3993L36.3977 35.4ZM36.4001 23.4H35.6001V21H34.0001V25H33.2001V23.4H27.6001V21H26.0001V25H25.2001V23.4H23.6001C22.7177 23.4 22.0001 24.1184 22.0001 25V35.4C22.0001 36.2824 22.7177 37 23.6001 37H36.4001C37.2825 37 38.0001 36.2824 38.0001 35.4V25C38.0001 24.1184 37.2825 23.4 36.4001 23.4V23.4Z'
							fill={brandSecondary}
						/>
					</>
				);
			}
			case 'Texting': {
				return (
					<>
						<circle cx='30' cy='30' r='30' fill={mention} />
						<rect x='13' y='13' width='34' height='34' rx='16' fill='white' stroke={brandSecondary} strokeWidth='2' />
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M22.2932 36H30.0002C34.4112 36 38.0002 32.86 38.0002 29C38.0002 25.14 34.4112 22 30.0002 22C25.5892 22 22.0002 25.14 22.0002 29C22.0002 30.537 22.5682 32 23.6422 33.232C23.9782 33.618 23.9692 34.195 23.6212 34.57L22.2932 36ZM30.0002 38H20.0002C19.6022 38 19.2422 37.764 19.0832 37.4C18.9242 37.035 18.9972 36.611 19.2672 36.32L21.5732 33.836C20.5412 32.392 20.0002 30.735 20.0002 29C20.0002 24.038 24.4862 20 30.0002 20C35.5142 20 40.0002 24.038 40.0002 29C40.0002 33.962 35.5142 38 30.0002 38Z'
							fill={brandSecondary}
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M31.0002 29C31.0002 29.552 30.5522 30 30.0002 30C29.4482 30 29.0002 29.552 29.0002 29C29.0002 28.448 29.4482 28 30.0002 28C30.5522 28 31.0002 28.448 31.0002 29Z'
							fill={brandSecondary}
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M27.0002 29C27.0002 29.552 26.5522 30 26.0002 30C25.4482 30 25.0002 29.552 25.0002 29C25.0002 28.448 25.4482 28 26.0002 28C26.5522 28 27.0002 28.448 27.0002 29Z'
							fill={brandSecondary}
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M35.0002 29C35.0002 29.552 34.5522 30 34.0002 30C33.4482 30 33.0002 29.552 33.0002 29C33.0002 28.448 33.4482 28 34.0002 28C34.5522 28 35.0002 28.448 35.0002 29Z'
							fill={brandSecondary}
						/>
					</>
				);
			}
			default: {
				return null;
			}
		}
	};
	return (
		<SvgIcon className={`settings-group-icon ${className || ''}`} height={60} width={60}>
			<g fill='none' fillRule='evenodd'>
				<circle cx='30' cy='30' r='30' fill={mention} />
				{renderContent()}
			</g>
		</SvgIcon>
	);
};
