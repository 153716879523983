import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { alternateTitleColor, mention } from '../../../../styles/colors';
import { SvgIcon } from '../../icons/SvgIcon';

interface Props extends React.SVGProps<SVGSVGElement> {
	strokeColor?: string;
	styles?: StyleDeclarationValue[];
	bgCircleFill?: string;
}

export const CalendarInCircleGraphic = ({
	className,
	styles,
	width = 180,
	height = 180,
	strokeColor = alternateTitleColor,
	bgCircleFill = mention,
}: Props) => {
	return (
		<SvgIcon
			className={`calendar-in-circle-graphic ${className || ''} ${css(...(styles || []))}`}
			height={height}
			width={width}
			viewBox='0 0 180 180'
		>
			<circle cx='90' cy='90' r='90' fill={bgCircleFill} />
			<path fill='#CAE0EC' stroke={strokeColor} strokeWidth='2' d='M53 53h74v86H53z' />
			<path fill='#E8F3F9' stroke={strokeColor} strokeWidth='2' d='M48 48h84v86H48z' />
			<path fill='#fff' stroke={strokeColor} strokeWidth='2' d='M43 43h94v86H43z' />
			<path fill='#E8F3F9' d='M46 46h88v20H46z' />
			<rect x='64.5' y='40.5' width='1' height='5' rx='.5' stroke={strokeColor} />
			<rect x='74.5' y='40.5' width='1' height='5' rx='.5' stroke={strokeColor} />
			<rect x='84.5' y='40.5' width='1' height='5' rx='.5' stroke={strokeColor} />
			<rect x='94.5' y='40.5' width='1' height='5' rx='.5' stroke={strokeColor} />
			<rect x='104.5' y='40.5' width='1' height='5' rx='.5' stroke={strokeColor} />
			<rect x='114.5' y='40.5' width='1' height='5' rx='.5' stroke={strokeColor} />
			<rect x='42.5' y='68.5' width='95' height='1' rx='.5' fill='#D8D8D8' stroke={strokeColor} />
			<rect x='53.5' y='61.5' width='7' height='1' rx='.5' fill='#D8D8D8' stroke={strokeColor} />
			<rect x='64.5' y='61.5' width='7' height='1' rx='.5' fill='#D8D8D8' stroke={strokeColor} />
			<rect x='75.5' y='61.5' width='7' height='1' rx='.5' fill='#D8D8D8' stroke={strokeColor} />
			<rect x='86.5' y='61.5' width='7' height='1' rx='.5' fill='#D8D8D8' stroke={strokeColor} />
			<rect x='97.5' y='61.5' width='7' height='1' rx='.5' fill='#D8D8D8' stroke={strokeColor} />
			<rect x='108.5' y='61.5' width='7' height='1' rx='.5' fill='#D8D8D8' stroke={strokeColor} />
			<rect x='119.5' y='61.5' width='7' height='1' rx='.5' fill='#D8D8D8' stroke={strokeColor} />
			<path
				d='M79.73 114h-4.963V90.026l-7.322 2.502v-4.204l11.649-4.286h.636V114zm30.454 0H90.106v-3.425l9.947-10.848c1.435-1.6 2.454-2.933 3.055-4 .616-1.08.923-2.16.923-3.24 0-1.422-.403-2.57-1.21-3.445-.793-.875-1.866-1.313-3.22-1.313-1.613 0-2.864.493-3.752 1.477-.889.984-1.333 2.331-1.333 4.04h-4.984c0-1.818.41-3.452 1.23-4.901.835-1.463 2.017-2.598 3.549-3.405 1.544-.806 3.322-1.21 5.332-1.21 2.898 0 5.181.732 6.849 2.195 1.682 1.449 2.523 3.459 2.523 6.03 0 1.49-.424 3.055-1.272 4.695-.834 1.627-2.208 3.48-4.122 5.558l-7.3 7.813h13.863V114z'
				fill='#00AAE8'
			/>
		</SvgIcon>
	);
};
