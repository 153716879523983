import * as Api from '@ViewModels';
import { yupResolver } from '@hookform/resolvers/yup';
import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { RouteComponentProps, useLocation, useParams } from 'react-router';
import * as yup from 'yup';
import { IEventRegistrationSurvey } from '../../../extViewmodels';
import { IImpersonationContextComponentProps, ILocationState, ImpersonationContextKey } from '../../../models';
import { IEnvironmentComponentProps } from '../../../models/AppState';
import { useEventLogging } from '../../../models/Logging';
import { copyToClipboard, getDefaultDateStringValue } from '../../../models/UiUtils';
import { useErrorMessages, useFullscreenModal, useToaster } from '../../../models/hooks/appStateHooks';
import {
	invalidateInfiniteSurveys,
	useEventRegistrationMutation,
	useImageAttachmentMutation,
	useUpdateEventSurveyMutation,
} from '../../../queries';
import { IFileAttachment } from '../../../view/models/Api';
import CalendarIconUrl from '../../assets/icon_calendar.svg';
import { Checkbox } from '../../components/Checkbox';
import { CustomDateRange } from '../../components/CustomDateRange';
import { DayPicker } from '../../components/DayPicker';
import { FabContext } from '../../components/FabContext';
import { ImageFileChooserModal } from '../../components/ImageFileChooserModal';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { MediaChooserModal } from '../../components/MediaChooser';
import { IModalProps, Modal } from '../../components/Modal';
import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { Popover, PopoverType } from '../../components/Popover';
import { ISelectOption, Select } from '../../components/Select';
import { TextArea } from '../../components/TextArea';
import { TextInput } from '../../components/TextInput';
import { Toggle } from '../../components/Toggle';
import { CameraIcon } from '../../components/svgs/icons/CameraIcon';
import { EyeIcon } from '../../components/svgs/icons/EyeIcon';
import { SquareLinkIcon } from '../../components/svgs/icons/SquareLinkIcon';
import { alternateTitleColor, grayIconFill, success } from '../../styles/colors';
import { baseStyleSheet } from '../../styles/styles';
import { ImagePreviewSmall } from './presentation';
import { styleSheet } from './styles';
import { eventTimeOptions } from './utils';

interface IProps extends RouteComponentProps<any>, IEnvironmentComponentProps, IImpersonationContextComponentProps {
	className?: string;
}

interface IState {
	enableRequestAttendeesToRSVP: boolean;
	fileInputModalOpen: boolean;
	now: Date;
	selectedtImage: IFileAttachment | null;
	showEventDatePopover: boolean;
	showEventDeadlineDatePopover: boolean;
	startDateTime: Date | null;
	startTime: string;
	endDateTime: Date | null;
	endTime: string;
}

const validationSchema = yup.object({
	details: yup.string().required('Event details are required'),
	eventDateRange: yup.object({
		end: yup.date(),
		start: yup.date().required('Event date & time are required'),
	}),
	eventName: yup.string().required('Event name is required'),
	guestLimit: yup.number(),
	location: yup.string().required('Event location is required'),
	maximumCapacity: yup
		.number()
		.min(1)
		.typeError('Maximum capacity must be a number')
		.when('requireMaximumCapacity', {
			is: true,
			then: schema => {
				return schema.required('Maximum capacity is required');
			},
		}),
	registrationDeadline: yup
		.date()
		.nullable()
		.typeError('Deadline date is required')
		.when('requireDeadline', {
			is: true,
			then: schema => {
				return schema.required('Deadline date is required');
			},
		}),
	requireDeadline: yup.boolean(),
	requireMaximumCapacity: yup.boolean(),
	requirePhoneNumber: yup.boolean(),
});

type CreateEventRegistrationSurveyRequest = yup.InferType<typeof validationSchema>;

const EventRegistrationSurveyBase = ({ className }: IProps) => {
	const [state, setState] = React.useState<IState>({
		enableRequestAttendeesToRSVP: false,
		endDateTime: null,
		endTime: '',
		fileInputModalOpen: false,
		now: new Date(),
		selectedtImage: null,
		showEventDatePopover: false,
		showEventDeadlineDatePopover: false,
		startDateTime: null,
		startTime: '',
	});
	const fullscreenModal = useFullscreenModal();
	const toaster = useToaster();
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging('EventRegistrationSurvey');
	const loc = useLocation();
	const locationState: ILocationState<Api.EventSurveyViewModel, any> = loc.state || {};
	// @ts-ignore
	const eventViewModel = React.useRef<Api.EventSurveyViewModel>(locationState.viewModel);
	const eventData = eventViewModel?.current?.toJs();
	const params = useParams<{ id?: string }>();
	const EVENT_ID = params?.id;

	const IS_EDIT = Boolean(EVENT_ID);
	const PAST_EVENT = React.useMemo(() => {
		return moment(eventData?.eventInformation?.startTime).isBefore(state.now.setHours(0, 0, 0, 0)) || false;
	}, [eventData?.eventInformation?.startTime, state.now]);

	const { handleSubmit, control, formState, getValues, watch, setValue } = useForm({
		defaultValues: {
			details: '',
			eventDateRange: {
				end: null,
				start: null,
			},
			eventName: '',
			guestLimit: 0,
			location: '',
			maximumCapacity: 1,
			registrationDeadline: null,
			requireDeadline: false,
			requireMaximumCapacity: false,
			requirePhoneNumber: false,
		},
		resolver: yupResolver(validationSchema),
	});
	const formValues = watch();
	const hasImages = Boolean(state.selectedtImage);

	React.useEffect(() => {
		if (eventData) {
			// @ts-ignore
			let endDateTime: Date = null;
			let endTime = '';
			// @ts-ignore
			const image: Api.IFileAttachment = eventData.eventInformation?.image || null;
			const rsvpEnabled = eventData.attendeeOptions?.enabled;
			const startTime = moment(eventData.eventInformation?.startTime).format('hh:mm a');
			const strDate = moment(eventData.eventInformation?.startTime).format('YYYY/MM/DD');
			const startDateTime = new Date(`${strDate}` + ' ' + `${startTime}`);
			/**
			 * @IF the event has an end time, we will use it
			 * we can check for form value because it was set in the default values or its null
			 */
			if (eventData?.eventInformation?.endTime) {
				const endDate = moment(eventData?.eventInformation?.endTime).format('YYYY/MM/DD');
				endTime = moment(eventData?.eventInformation?.endTime).format('hh:mm a');
				endDateTime = new Date(`${endDate}` + ' ' + `${endTime}`);
			}

			setValue('guestLimit', eventData?.attendeeOptions?.guestLimit || 0);
			setValue('details', eventData?.eventInformation?.details || '');
			setValue('eventDateRange', {
				// @ts-ignore
				end: eventData?.eventInformation?.endTime
					? new Date(eventData?.eventInformation?.endTime)
					: eventData?.eventInformation?.startTime
						? new Date(eventData?.eventInformation?.startTime)
						: null,
				// @ts-ignore
				start: eventData?.eventInformation?.startTime ? new Date(eventData.eventInformation?.startTime) : null,
			});
			setValue('eventName', eventData.name || '');
			setValue('location', eventData?.eventInformation?.location || '');
			setValue('maximumCapacity', eventData?.attendeeOptions?.maximumCapacity || 1);
			setValue(
				'registrationDeadline',
				// @ts-ignore
				eventData?.attendeeOptions?.registrationDeadline
					? new Date(eventData.attendeeOptions?.registrationDeadline)
					: null
			);
			setValue('requireDeadline', Boolean(eventData?.attendeeOptions?.registrationDeadline));
			// @ts-ignore
			setValue('requireMaximumCapacity', Boolean(eventData?.attendeeOptions?.maximumCapacity > 1));
			setValue('requirePhoneNumber', eventData?.attendeeOptions?.requirePhoneNumber || false);

			// @ts-ignore
			setState(prevState => ({
				...prevState,
				enableRequestAttendeesToRSVP: rsvpEnabled,
				endDateTime,
				endTime,
				selectedtImage: image,
				startDateTime,
				startTime,
			}));
		}
	}, [eventData, setValue]);

	const eventRegistrationMutation = useEventRegistrationMutation({
		onError: error => {
			// @ts-ignore
			errorMessages.pushApiError(error);
			// @ts-ignore
			toaster.push({
				message: 'Failed to create event registration survey',
				type: 'errorMessage',
			});
			logApiError('EventRegistrationSurvey-Error', error);
		},
		onSuccess: () => {
			// @ts-ignore
			toaster.push({
				message: 'Event registration survey created',
				type: 'successMessage',
			});
			// @ts-ignore
			fullscreenModal.dismissModal();
			invalidateInfiniteSurveys();
		},
	});

	const updateSurveyEventMutation = useUpdateEventSurveyMutation({
		onError: error => {
			// @ts-ignore
			errorMessages.pushApiError(error);
			// @ts-ignore
			toaster.push({
				message: 'Failed to update the event',
				type: 'errorMessage',
			});
			logApiError('EventRegistrationSurvey-Error', error);
		},
		onSuccess: () => {
			// @ts-ignore
			toaster.push({
				message: 'Updates event success',
				type: 'successMessage',
			});
			// @ts-ignore
			fullscreenModal.dismissModal();
			invalidateInfiniteSurveys();
		},
	});

	const imageAttachmentMutation = useImageAttachmentMutation({
		onError: error => {
			// @ts-ignore
			errorMessages.pushApiError(error);
			// @ts-ignore
			toaster.push({
				message: 'Failed to upload image',
				type: 'errorMessage',
			});
			logApiError('UploadImageEventRegistrationSurvey-Error', error);
		},
		onSuccess: result => {
			setState(prevState => ({
				...prevState,
				selectedtImage: result[0],
			}));
		},
	});
	/**
	 * Pixabay selection setup
	 */
	const onFreeImageChooserRequestCloseRef = React.useRef<(img?: IFileAttachment, cancel?: boolean) => void>(null);
	const [freeImageModalProps, setFreeImageModalProps] = React.useState<IModalProps>({
		isOpen: false,
		onRequestClose: (result, cancel) => onFreeImageChooserRequestCloseRef.current?.(result, cancel),
	});

	const onFreeImageChooserRequestClose = React.useCallback((imageFile?: IFileAttachment, cancel?: boolean) => {
		setFreeImageModalProps(value => {
			return {
				...value,
				isOpen: false,
			};
		});
		if (cancel) {
			return;
		}
		// @ts-ignore
		setState(prevState => ({
			...prevState,
			selectedtImage: imageFile,
		}));
	}, []);
	// @ts-ignore
	onFreeImageChooserRequestCloseRef.current = onFreeImageChooserRequestClose;
	/**
	 * End Pixabay selection setup
	 */

	const onChangeClosingDateClicked = () => {
		setState(prevState => ({
			...prevState,
			showEventDatePopover: true,
		}));
	};

	/**
	 * @NOTE this is the callback for the date picker modal
	 * @IF the user has not selected a start date, and start time we will clear the date range when the modal is closed
	 */
	const onClosingDateDayPickerRequestClose = () => {
		if (!state?.startDateTime && !state?.startTime) {
			setValue('eventDateRange', {
				end: null,
				start: null,
			});
		}
		setState(prevState => ({
			...prevState,
			showEventDatePopover: false,
		}));
	};
	const onChangeDeadlineDateClicked = () => {
		setState(prevState => ({
			...prevState,
			showEventDeadlineDatePopover: true,
		}));
	};

	const onDeadlineDateDayPickerRequestClose = () => {
		setState(prevState => ({
			...prevState,
			showEventDeadlineDatePopover: false,
		}));
	};

	const handleSave = () => {
		if (PAST_EVENT) {
			// @ts-ignore
			toaster.push({
				message: 'Cannot edit a past event',
				type: 'errorMessage',
			});
			return;
		}

		// @ts-ignore
		const formData: CreateEventRegistrationSurveyRequest = getValues();
		const eventRegistration: IEventRegistrationSurvey = {
			_type: 'EventRegistrationSurvey',
			attendeeOptions: {
				enabled: state.enableRequestAttendeesToRSVP,
				guestLimit: state.enableRequestAttendeesToRSVP ? formData.guestLimit : 0,
				// @ts-ignore
				maximumCapacity:
					// @ts-ignore
					state.enableRequestAttendeesToRSVP && formData.maximumCapacity > 1 ? formData.maximumCapacity : null,
				// @ts-ignore
				registrationDeadline: state.enableRequestAttendeesToRSVP
					? formData.registrationDeadline
						? new Date(formData.registrationDeadline)
						: null
					: null,
				requirePhoneNumber: state.enableRequestAttendeesToRSVP ? formData.requirePhoneNumber : false,
			},
			eventInformation: {
				details: formData.details,
				// @ts-ignore
				endTime: state?.endDateTime
					? new Date(state.endDateTime)
					: moment(state?.startDateTime).isSame(state?.endDateTime, 'day') && state?.endTime
						? new Date(`${getDefaultDateStringValue(state?.startDateTime)}, ${state?.endTime}`)
						: null,
				location: formData.location,
				// @ts-ignore
				startTime: new Date(state.startDateTime),
				timeZone: eventData?.eventInformation?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
			},
			name: formData.eventName,
		};
		if (state.selectedtImage) {
			// @ts-ignore
			eventRegistration.eventInformation.image = state.selectedtImage;
		}
		if (IS_EDIT) {
			const payload = { ...eventViewModel.current.toJs(), ...eventRegistration };
			// @ts-ignore
			updateSurveyEventMutation.mutate({ eventRegistration: payload, id: EVENT_ID });
			return;
		}
		eventRegistrationMutation.mutate({
			eventRegistration,
		});
	};

	const onToggleCheckChanged = () => {
		setState(prevState => ({
			...prevState,
			enableRequestAttendeesToRSVP: !prevState.enableRequestAttendeesToRSVP,
		}));
	};

	const onClickMedia = () => {
		setState(prevState => ({
			...prevState,
			fileInputModalOpen: true,
		}));
	};

	const onRequestfileInputClose = (val?: File | null, cancel?: boolean) => {
		if (cancel) {
			setState(prevState => ({
				...prevState,
				fileInputModalOpen: false,
			}));
			return;
		}
		// @ts-ignore
		imageAttachmentMutation.mutate({ files: [val] });
		setState(prevState => ({
			...prevState,
			fileInputModalOpen: false,
		}));
	};

	const onClickFreeImage = () => {
		setFreeImageModalProps(value => {
			return {
				...value,
				isOpen: true,
			};
		});
	};
	const onEventTimeChange = (val: ISelectOption<string>) => {
		const strDate = moment(formValues.eventDateRange.start).format('YYYY/MM/DD');
		const startDateTime = new Date(`${strDate}` + ' ' + `${val.dataContext}`);
		setState(prevState => ({
			...prevState,
			startDateTime,
			startTime: val.dataContext,
		}));
	};

	const onEventTimeEndChange = (val: ISelectOption<string>) => {
		const strDate = moment(formValues.eventDateRange.end).format('YYYY/MM/DD');
		const endDateTime = new Date(`${strDate}` + ' ' + `${val.dataContext}`);
		setState(prevState => ({
			...prevState,
			endDateTime,
			endTime: val.dataContext,
		}));
	};

	const onCopyLinkClicked = React.useCallback(() => {
		// @ts-ignore
		if (copyToClipboard(eventData?.anonymousLink)) {
			// @ts-ignore
			toaster.push({
				message: 'Link copied to clipboard',
				type: 'successMessage',
			});
			return;
		}

		// @ts-ignore
		toaster.push({
			message: 'Error copying link to clipboard',
			type: 'errorMessage',
		});
	}, [eventData?.anonymousLink, toaster]);

	const previewClicked = React.useCallback(() => {
		const url = `${eventData?.anonymousLink}`;
		window.open(url, '_blank');
	}, [eventData?.anonymousLink]);

	const selectStartTimeText = eventTimeOptions().find(x => x.dataContext === state.startTime);
	const selectedEndTimeText =
		state?.endTime !== state.startTime ? eventTimeOptions().find(x => x.dataContext === state.endTime) : null;

	const formattedStartDate = state?.startDateTime ? moment(state?.startDateTime).format('MMM Do, YYYY hh:mm a') : '';
	const formattedEndDate =
		state?.startDateTime && !state?.endDateTime
			? ``
			: state?.endDateTime && moment(state?.endDateTime).isSame(state?.startDateTime, 'day')
				? `- ${moment(state?.endDateTime).format('hh:mm a')}`
				: `- ${moment(state?.endDateTime).format('MMM Do, YYYY hh:mm a')}`;

	return (
		<div className={`${className ?? ''}`}>
			<MultiContainerHeader fullscreenHeader={!IS_EDIT ? 'New Event' : `Edit Event: ${eventData.name}`} />
			<FabContext appearance={{ hidden: true }} />
			{PAST_EVENT ? (
				<h2 className={css(styleSheet.notEditableNotice)}>This event has passed and its no longer editable</h2>
			) : null}
			<form className='form-container' onSubmit={handleSubmit(handleSave)}>
				<fieldset disabled={PAST_EVENT} className={css(styleSheet.fieldset, styleSheet.formStyles)}>
					<div className={css(styleSheet.mainContainer)}>
						<section className={css(styleSheet.section)}>
							<h3 className={css(styleSheet.sectionTitle)}>Event Information</h3>
							<div className={css(styleSheet.formControlWrap)}>
								<label htmlFor='event-name-input' className={css(styleSheet.eventlabel)}>
									Event name
								</label>
								<Controller
									name='eventName'
									control={control}
									render={({ field: { ref, ...fieldProps } }) => (
										<TextInput id='eventName' inputId='event-name-input' type='text' inputRef={ref} {...fieldProps} />
									)}
								/>
								{formState.isSubmitted && formState.errors.eventName && (
									<p className={css(styleSheet.errorMessage, styleSheet.noMargin)}>
										{formState.errors.eventName.message}
									</p>
								)}
							</div>
							<div className={css(styleSheet.formControlWrap)}>
								<label htmlFor='event-location-input' className={css(styleSheet.eventlabel)}>
									Event location
								</label>
								<Controller
									name='location'
									control={control}
									render={({ field: { ref, ...fieldProps } }) => (
										<TextArea id='eventLocation' inputId='event-location-input' inputRef={ref} {...fieldProps} />
									)}
								/>
								{formState.isSubmitted && formState.errors.location && (
									<p className={css(styleSheet.errorMessage, styleSheet.noMargin)}>
										{formState.errors.location.message}
									</p>
								)}
							</div>
							<div className={css(styleSheet.formControlWrap)}>
								<div style={{ width: '94%' }}>
									<label htmlFor='event-date-input' className={css(styleSheet.eventlabel)}>
										Event Date & Time
									</label>
									<Controller
										name='eventDateRange'
										control={control}
										render={({ field: { ref, ...fieldProps } }) => {
											return (
												<div className={css(styleSheet.eventDateInput)}>
													<button
														type='button'
														className={`endDate-date-field-button ${css(styleSheet.eventDateButton)}`}
														onClick={onChangeClosingDateClicked}
													>
														<span className={css(styleSheet.selectedDate)}>
															{state?.startDateTime && !state.showEventDatePopover && (
																<>
																	{formattedStartDate} {formattedEndDate}
																</>
															)}
														</span>
													</button>
													<Modal
														isOpen={state.showEventDatePopover}
														onRequestClose={() => onClosingDateDayPickerRequestClose()}
														shouldCloseOnOverlayClick={true}
													>
														<div>
															<CustomDateRange
																minDate={state.now}
																from={formValues.eventDateRange.start || undefined}
																to={formValues.eventDateRange.end || undefined}
																resetDates={() => {
																	fieldProps.onChange({
																		end: undefined,
																		start: undefined,
																	});
																	setState(prevState => ({
																		...prevState,
																		endDateTime: null,
																		endTime: '',
																		startDateTime: null,
																		startTime: '',
																	}));
																}}
																onChange={(from, to) => {
																	fieldProps.onChange({
																		end: to || from,
																		start: from,
																	});

																	setState(prevState => ({
																		...prevState,
																		endDateTime: null,
																		endTime: '',
																		startDateTime: null,
																		startTime: '',
																	}));
																}}
																styles={[styleSheet.dateRangePicker]}
															/>
															<footer>
																<div className={css(styleSheet.selectTimeWrap)}>
																	<div>
																		<label className={css(styleSheet.selectDateLabel)}>Start Time</label>
																		<Select
																			triggerStyles={[styleSheet.selectDate]}
																			onOptionClick={onEventTimeChange}
																			options={eventTimeOptions()}
																			selectedOption={selectStartTimeText}
																			selectedOptionTitle={selectStartTimeText?.text || 'Select time'}
																			disabled={!fieldProps.value.start}
																		/>
																	</div>
																	<div>
																		<label className={css(styleSheet.selectDateLabel)}>End Time </label>
																		<Select
																			triggerStyles={[styleSheet.selectDate]}
																			onOptionClick={onEventTimeEndChange}
																			options={eventTimeOptions()}
																			// @ts-ignore
																			selectedOption={selectedEndTimeText}
																			selectedOptionTitle={
																				selectedEndTimeText?.text ||
																				(getDefaultDateStringValue(formValues.eventDateRange.end) !==
																					getDefaultDateStringValue(formValues.eventDateRange.start) &&
																					!state?.endTime &&
																					'Select Time') ||
																				'Optional'
																			}
																			disabled={!fieldProps.value.end}
																		/>
																	</div>
																</div>
																<button
																	type='button'
																	disabled={
																		(getDefaultDateStringValue(formValues.eventDateRange.end) !==
																			getDefaultDateStringValue(formValues.eventDateRange.start) &&
																			!state.endTime) ||
																		(getDefaultDateStringValue(formValues.eventDateRange.end) ===
																			getDefaultDateStringValue(formValues.eventDateRange.start) &&
																			!state?.startTime)
																	}
																	className={css(baseStyleSheet.ctaButtonSmall, styleSheet.saveButton)}
																	onClick={() => {
																		setState(prevState => ({
																			...prevState,
																			showEventDatePopover: false,
																		}));
																	}}
																>
																	Save Date & Time
																</button>
															</footer>
														</div>
													</Modal>
												</div>
											);
										}}
									/>
									{formState.isSubmitted && formState?.errors?.eventDateRange?.start && (
										<p className={css(styleSheet.errorMessage, styleSheet.noMargin)}>
											{formState.errors.eventDateRange.start.message}
										</p>
									)}
								</div>
							</div>
							<div className={css(styleSheet.formControlWrap)}>
								<label htmlFor='event-details-input' className={css(styleSheet.eventlabel)}>
									Event Details
								</label>
								<Controller
									name='details'
									control={control}
									render={({ field: { ref, ...fieldProps } }) => (
										<TextArea id='details' inputId='event-details-input' inputRef={ref} {...fieldProps} />
									)}
								/>
								{formState.isSubmitted && formState.errors.details && (
									<p className={css(styleSheet.errorMessage, styleSheet.noMargin)}>
										{formState.errors.details.message}
									</p>
								)}
							</div>
							<div className={css(styleSheet.noMargin)}>
								<label htmlFor='event-image-input' className={css(styleSheet.eventlabel)}>
									Upload an event image (optional)
								</label>
								{!hasImages ? (
									<div className={css(styleSheet.addMediaContainer, styleSheet.contentContainers)}>
										<div>
											<button
												type='button'
												className={css(styleSheet.iconButtons)}
												disabled={hasImages}
												onClick={onClickFreeImage}
												title={hasImages ? 'Only one image is supported at this time' : undefined}
											>
												<CameraIcon />
												<div className={css(styleSheet.freeImage)}> Free Images</div>
											</button>
											<div className={css(styleSheet.sourceMention)}>Royalty free image library</div>
										</div>
										<div>
											<button
												type='button'
												className={css(styleSheet.ownImage)}
												disabled={hasImages}
												onClick={onClickMedia}
												title={hasImages ? 'Only one image is supported at this time' : undefined}
											>
												+ Upload Media
											</button>
											<div className={css(styleSheet.sourceMention)}>Choose file from your computer</div>
										</div>
									</div>
								) : (
									<div>
										{state?.selectedtImage ? (
											<ImagePreviewSmall
												name={state?.selectedtImage?.fileName}
												onRemoveClick={() => {
													setState(prevState => ({
														...prevState,
														selectedtImage: null,
													}));
												}}
												// @ts-ignore
												url={state?.selectedtImage?.url}
											/>
										) : null}
									</div>
								)}
							</div>
						</section>
						<section className={css(styleSheet.section)}>
							<h3 className={css(styleSheet.sectionTitle)}>Request Attendees to RSVP</h3>
							<div className={css(styleSheet.formControlWrap)}>
								<Toggle
									checkedColor={success}
									id='request-attendees-rsvp-toggle'
									isOn={state.enableRequestAttendeesToRSVP}
									onToggleCheckChanged={onToggleCheckChanged}
									text={state.enableRequestAttendeesToRSVP ? 'Enabled' : 'Disabled'}
									textStyles={[styleSheet.toggleText]}
									uncheckedColor={grayIconFill}
								/>
							</div>
							<fieldset disabled={!state.enableRequestAttendeesToRSVP} className={css(styleSheet.fieldset)}>
								<div className={css(styleSheet.formControlWrap)}>
									<Controller
										name='requirePhoneNumber'
										control={control}
										render={({ field: { ref, ...fieldProps } }) => (
											<Checkbox
												id='require-attendees-phone-number-checkbox'
												checked={fieldProps.value}
												name='requirePhoneNumber'
												onChange={() => {
													if (!state.enableRequestAttendeesToRSVP) {
														return;
													}
													fieldProps.onChange(!fieldProps.value);
												}}
											>
												<p className={css(styleSheet.noMargin)}>Require attendees to provide phone number</p>
											</Checkbox>
										)}
									/>
								</div>
								<div className={css(styleSheet.formControlWrap)}>
									<Controller
										name='guestLimit'
										control={control}
										render={({ field: { ref, ...fieldProps } }) => (
											<Checkbox
												id='allow-plus-one-checkbox'
												checked={Boolean(fieldProps.value)}
												name='guestLimit'
												onChange={() => {
													if (!state.enableRequestAttendeesToRSVP) {
														return;
													}
													fieldProps.onChange(!fieldProps.value ? 1 : 0);
												}}
											>
												<p className={css(styleSheet.noMargin)}>Allow attendees to bring a plus-one</p>
											</Checkbox>
										)}
									/>
								</div>
								<div className={css(styleSheet.formControlWrap)}>
									<Controller
										name='requireMaximumCapacity'
										control={control}
										render={({ field: { ref, ...fieldProps } }) => (
											<>
												<Checkbox
													id='set-maximum-capacity-checkbox'
													checked={fieldProps.value}
													name='requireMaximumCapacity'
													onChange={() => {
														if (!state.enableRequestAttendeesToRSVP) {
															return;
														}
														fieldProps.onChange(!fieldProps.value);
													}}
												>
													<p className={css(styleSheet.noMargin)}>Set maximum capacity</p>
												</Checkbox>
											</>
										)}
									/>
									{formValues.requireMaximumCapacity ? (
										<div className={css(styleSheet.maximumCapasityInput)}>
											<Controller
												name='maximumCapacity'
												control={control}
												render={({ field: { ref, ...fieldProps } }) => (
													<TextInput
														id='maximumCapacity'
														inputId='maximum-capacity-input'
														inputRef={ref}
														min={1}
														type='number'
														onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
															fieldProps.onChange(e.target.value);
														}}
														value={fieldProps.value}
													/>
												)}
											/>
											{formState.isSubmitted && formState.errors.maximumCapacity && (
												<p className={css(styleSheet.errorMessage, styleSheet.noMargin)}>
													{formState.errors.maximumCapacity.message}
												</p>
											)}
										</div>
									) : null}
								</div>
								<div className={css(styleSheet.formControlWrap, styleSheet.formControlWrapLastChild)}>
									<Controller
										name='requireDeadline'
										control={control}
										render={({ field: { ref, ...fieldProps } }) => (
											<>
												<Checkbox
													id='rsvp-by-deadline-checkbox'
													checked={fieldProps.value}
													name='requireDeadline'
													onChange={() => {
														if (!state.enableRequestAttendeesToRSVP) {
															return;
														}
														if (!fieldProps.value) {
															setValue('registrationDeadline', null);
														}
														fieldProps.onChange(!fieldProps.value);
													}}
												>
													<p className={css(styleSheet.noMargin)}>Please RSVP by… (deadline)</p>
												</Checkbox>
											</>
										)}
									/>
									{formValues.requireDeadline ? (
										<div className={css(styleSheet.deadlineDateInput)}>
											<Controller
												name='registrationDeadline'
												control={control}
												render={({ field: { ref, ...fieldProps } }) => {
													return (
														<>
															<div className={css(styleSheet.eventDateInput)}>
																<button
																	type='button'
																	className='deadline-date-field-button'
																	onClick={onChangeDeadlineDateClicked}
																>
																	<Popover
																		anchor={<img src={CalendarIconUrl} className={css(styleSheet.calendarIcon)} />}
																		dismissOnClickOutside={true}
																		isOpen={state.showEventDeadlineDatePopover}
																		onRequestClose={onDeadlineDateDayPickerRequestClose}
																		preferredPlacement='below'
																		type={PopoverType.white}
																	>
																		<div style={{ padding: '20px 10px' }}>
																			<DayPicker
																				allowPastDates={false}
																				minDate={new Date()}
																				// @ts-ignore
																				maxDate={new Date(formValues.eventDateRange.end)}
																				onDayClick={val => {
																					fieldProps.onChange(val);
																					setState(prevState => ({
																						...prevState,
																						showEventDeadlineDatePopover: false,
																					}));
																				}}
																				selectedDays={
																					fieldProps.value
																						? new Date(fieldProps.value)
																						: formValues.eventDateRange.start
																							? new Date(formValues.eventDateRange.start)
																							: new Date()
																				}
																			/>
																		</div>
																	</Popover>
																	<span className={css(styleSheet.selectedDate)}>
																		{fieldProps.value && getDefaultDateStringValue(fieldProps.value)}
																	</span>
																</button>
																{fieldProps.value && (
																	<a
																		href='#'
																		className={css(styleSheet.clearDeadline)}
																		onClick={(e: React.MouseEvent) => {
																			e.preventDefault();
																			fieldProps.onChange(null);
																		}}
																	>
																		Clear
																	</a>
																)}
															</div>
														</>
													);
												}}
											/>
											{formState.isSubmitted && formState.errors.registrationDeadline && (
												<p className={css(styleSheet.errorMessage, styleSheet.noMargin)}>
													{formState.errors.registrationDeadline.message}
												</p>
											)}
										</div>
									) : null}
								</div>
							</fieldset>
							<div>
								<p className={css(styleSheet.noMargin, styleSheet.infoText)}>
									Once attendees have RSVP&apos;d, you would be able to send emails to those who answered “attending” vs
									“not attending”.
								</p>
								<p className={css(styleSheet.noMargin, styleSheet.infoText)}>
									If the invite is sent through Levitate, we would also be able to track of those who did not respond.
								</p>
							</div>
						</section>
					</div>
					<footer className={css(styleSheet.footer)}>
						{IS_EDIT && (
							<button type='button' className={css(styleSheet.previewEvent)} onClick={previewClicked}>
								<EyeIcon /> Preview event
							</button>
						)}
						<button type='submit' className={css(baseStyleSheet.ctaButtonSmall, styleSheet.submitButtom)}>
							{eventRegistrationMutation?.isLoading || updateSurveyEventMutation?.isLoading ? (
								<LoadingSpinner type='tiny' />
							) : (
								'Save'
							)}
						</button>
						{IS_EDIT && (
							<button type='button' className={css(baseStyleSheet.ctaButtonReverseSmall)} onClick={onCopyLinkClicked}>
								<SquareLinkIcon fillColor={alternateTitleColor} className={css(styleSheet.linkIcon)} /> Copy invite link
							</button>
						)}
					</footer>
				</fieldset>
			</form>
			<ImageFileChooserModal
				modalProps={{
					isOpen: state.fileInputModalOpen,
					onRequestClose: onRequestfileInputClose,
				}}
			/>
			<MediaChooserModal
				modalProps={freeImageModalProps}
				useBasicSearch={true}
				optionalImageName='External Image'
				imageOnly={true}
				styles={[styleSheet.mediaModalStyle]}
				onSave={onFreeImageChooserRequestClose}
			/>
		</div>
	);
};

export const EventRegistrationSurvey = inject(ImpersonationContextKey)(EventRegistrationSurveyBase);
