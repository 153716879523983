import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import {
	IImpersonationContextComponentProps,
	ILocationState,
	IModalContext,
	ImpersonationContextKey,
	ModalChildComponentContextKey,
} from '../../../models';
import { Topics } from '../../../models/LocalNotificationTopics';
import { useLocalNotificationService } from '../../../models/LocalNotifications';
import { useEventLogging } from '../../../models/Logging';
import { copyToClipboard } from '../../../models/UiUtils';
import { useErrorMessages, useToaster, useUserSession } from '../../../models/hooks/appStateHooks';
import { invalidateInfiniteSurveys } from '../../../queries';
import { FabContext } from '../../components/FabContext';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { SquareLinkIcon } from '../../components/svgs/icons/SquareLinkIcon';
import { baseStyleSheet } from '../../styles/styles';
import { EditSurveyContext, IEditSurveyContext } from './context';
import { BasicSurveyInfo, ReviewRequestOptions } from './presentation';
import { styleSheet } from './styles';

interface IProps
	extends RouteComponentProps<any>,
		IModalContext<Api.SatisfactionSurveyViewModel>,
		IImpersonationContextComponentProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

interface IEditSurveyComponent {
	BasicSurveyInfo: React.FC;
	ReviewRequestOptions: React.FC;
}

const getDefaultSatisfactionSurvey = (userSession: Api.UserSessionContext): Api.ISatisfactionSurvey => {
	return {
		_type: 'SatisfactionSurvey',
		companyDisplayName: userSession.account.companyName || '',
		intro: 'How would you rate your overall experience with us?',
		reviewRequestSettings: {
			isEnabled: false,
			ratings: [4, 5],
			reviewRequest:
				'Thank you so much for your kind words and rating. Would you be so generous as to leave us a review? It would really mean the world to us.',
		},
		startDate: moment().toISOString(),
	};
};

const SatisfactionSurveyBase: React.FC<IProps> & Partial<IEditSurveyComponent> = observer(props => {
	const { className, styles = [], impersonationContext, parentModal } = props;
	const userSession = useUserSession();
	const toaster = useToaster();
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging('EditSurvey');
	const loc = useLocation();
	const { postNotification } = useLocalNotificationService();

	const locationState: ILocationState<Api.SatisfactionSurveyViewModel, any> = loc.state || {};
	// @ts-ignore
	const surveyVmRef = React.useRef<Api.SatisfactionSurveyViewModel>(locationState.viewModel);
	const isArchived = !!surveyVmRef.current?.archiveMoment;

	const editSurveyContextRef =
		React.useRef<
			[IEditSurveyContext<Api.ISatisfactionSurvey>, (ctx: IEditSurveyContext<Api.ISatisfactionSurvey>) => void]
		>(null);
	const updateSurveyContext = React.useRef(
		(contextPropsToUpdate: Partial<IEditSurveyContext<Api.ISatisfactionSurvey>>) => {
			// @ts-ignore
			const [ctx, setCtx] = editSurveyContextRef.current;
			setCtx({
				...ctx,
				...(contextPropsToUpdate || {}),
			});
		}
	).current;
	const [editSurveyContext, setEditSurveyContext] = React.useState<IEditSurveyContext<Api.ISatisfactionSurvey>>({
		askForReview: true,
		setAskForReview: (askForReview: boolean) => updateSurveyContext({ askForReview }),
		setSurvey: (s: Api.ISatisfactionSurvey) => updateSurveyContext({ survey: s }),
		survey: (locationState.viewModel?.toJs() as Api.ISatisfactionSurvey) || getDefaultSatisfactionSurvey(userSession),
	});
	// @ts-ignore
	editSurveyContextRef.current = [editSurveyContext, setEditSurveyContext];

	const [saving, setSaving] = React.useState<boolean>(false);
	const save = React.useCallback(async () => {
		// check for missing required info
		const { survey } = editSurveyContext;
		if (!survey.name) {
			// @ts-ignore
			errorMessages.push({
				messages: ['Please provide a name for this survey.'],
			});
			return;
		}

		if (!survey.intro) {
			// @ts-ignore
			errorMessages.push({
				messages: ['Please provide an intro for this survey.'],
			});
			return;
		}

		if (!survey.startDate) {
			// @ts-ignore
			errorMessages.push({
				messages: ['Please select a date range option for this survey.'],
			});
			return;
		}

		const satSurvey: Api.ISatisfactionSurvey = survey as Api.ISatisfactionSurvey;
		if (satSurvey.reviewRequestSettings?.isEnabled) {
			if (satSurvey.reviewRequestSettings.ratings?.length === 0) {
				// @ts-ignore
				errorMessages.push({
					messages: ['Please provide at least one review rating.'],
				});
				return;
			}

			if (!satSurvey.reviewRequestSettings.reviewRequest || !satSurvey.reviewRequestSettings.reviewUrl) {
				// @ts-ignore
				errorMessages.push({
					messages: ['Please provide a review request message and a link to your review site.'],
				});
				return;
			}
		}

		try {
			setSaving(true);
			if (surveyVmRef.current?.id) {
				await surveyVmRef.current.update(survey);
				postNotification({
					info: surveyVmRef.current.toJs(),
					topic: Topics.EDIT_SURVEY,
				});
			} else {
				// @ts-ignore
				const surveyVm = await Api.SurveyViewModel.create<Api.SatisfactionSurveyViewModel>(
					userSession,
					satSurvey,
					impersonationContext
				);
				surveyVmRef.current = surveyVm;
				postNotification({
					info: surveyVm.toJs(),
					topic: Topics.CREATE_SURVEY,
				});
			}
			invalidateInfiniteSurveys();
			updateSurveyContext({ survey: surveyVmRef.current.toJs() });
			setSaving(false);
			// @ts-ignore
			toaster.push({
				message: 'Survey saved.',
				type: 'successMessage',
			});
			parentModal?.onRequestClose(surveyVmRef.current);
		} catch (error) {
			// @ts-ignore
			logApiError('SurveySave-Error', error);
			// @ts-ignore
			// @ts-ignore
			errorMessages.pushApiError(error);
			setSaving(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editSurveyContext]);

	const onCopyLinkClicked = () => {
		// @ts-ignore
		if (copyToClipboard(surveyVmRef.current.anonymousLink)) {
			// @ts-ignore
			toaster.push({
				message: 'Link copied to clipboard',
				type: 'successMessage',
			});
		} else {
			// @ts-ignore
			toaster.push({
				message: 'Error copying link to clipboard',
				type: 'errorMessage',
			});
		}
	};

	return (
		<EditSurveyContext.Provider value={editSurveyContext}>
			<div className={`${css(styleSheet.container, ...styles)} edit-survey ${className || ''}`}>
				<MultiContainerHeader
					fullscreenHeader={`${editSurveyContext?.survey?.id ? (isArchived ? 'View' : 'Edit') : 'New'} Survey`}
				/>
				<FabContext appearance={{ hidden: true }} />
				<div className={css(styleSheet.header)}>
					<div className={css(styleSheet.headerActions)}>
						{isArchived && <span className={css(styleSheet.archivedLabel)}>ARCHIVED</span>}
						<button
							className={css(baseStyleSheet.ctaButtonSmall)}
							disabled={surveyVmRef.current?.isBusy || isArchived}
							onClick={save}
						>
							{saving ? <LoadingSpinner type='tiny' /> : <span>Save</span>}
						</button>
						<button
							className={css(baseStyleSheet.ctaButtonSmall, styleSheet.copyLinkButton)}
							disabled={!editSurveyContext?.survey?.id}
							onClick={onCopyLinkClicked}
							title={!editSurveyContext?.survey?.id ? 'Please save in order to generate this link' : undefined}
						>
							<SquareLinkIcon fillColor='#fff' />
							<span>Copy Survey Link</span>
						</button>
					</div>
				</div>
				<div className={css(styleSheet.body)}>
					{/* @ts-ignore */}
					<SatisfactionSurvey.BasicSurveyInfo />
					{/* @ts-ignore */}
					<SatisfactionSurvey.ReviewRequestOptions />
				</div>
			</div>
		</EditSurveyContext.Provider>
	);
});

export const SatisfactionSurvey = inject(
	ImpersonationContextKey,
	ModalChildComponentContextKey
)(SatisfactionSurveyBase);

SatisfactionSurvey.BasicSurveyInfo = BasicSurveyInfo;
SatisfactionSurvey.ReviewRequestOptions = ReviewRequestOptions;
