import { css } from 'aphrodite';
import * as React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { ISatisfactionSurveyCardData } from '../../../../extViewmodels';
import { PersistentStorageSuppressEmptySatisfactionCardKey } from '../../../../models';
import { PersistentStorageManager } from '../../../../models/Storage';
import { grayIconFill, surveysTint } from '../../../styles/colors';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { GhostHeading } from '../../GhostHeading';
import { GhostImage } from '../../GhostImage';
import { GhostCard } from '../../GhostsCard';
import { RightArrow } from '../../svgs/icons/RightArrow';
import { StarIcon } from '../../svgs/icons/StarIcon';
import { DashboardCard, DashboardCardHeaderLabel, DashboardCardStat } from '../DashboardCard';
import { DashboardCarousel } from '../DashboardCarousel';
import { SatisfactionBgGraphic, SatisfactionClientFeedback } from './presentation';
import { styleSheet } from './styles';

export function DashboardSatisfactionCard({
	data,
	status,
}: {
	data?: ISatisfactionSurveyCardData;
	status: 'error' | 'success' | 'loading';
}) {
	const history = useHistory();
	const [isEmptySuppressed, setIsEmptySuppressed] = React.useState(() => {
		return (
			PersistentStorageManager.local.get(PersistentStorageSuppressEmptySatisfactionCardKey)?.toLowerCase() === 'true'
		);
	});
	if (!data && status !== 'success') {
		return (
			<GhostCard stylesDeclarations={[styleSheet.root]} style={{ padding: '1rem 1.25rem' }}>
				<>
					<div style={{ alignItems: 'flex-start', display: 'flex', marginBottom: 14 }}>
						<GhostImage style={{ marginRight: '3rem' }} />{' '}
						<div style={{ marginRight: '3.5rem' }}>
							<GhostHeading style={{ height: 10, marginBottom: '0.5rem', width: 100 }} />
							<div style={{ alignItems: 'center', display: 'flex' }}>
								<GhostHeading style={{ height: 47, marginRight: '0.7rem', width: 58 }} />
								<GhostImage style={{ height: 19, width: 20 }} />
							</div>
						</div>
						<div style={{ width: '100%' }}>
							<GhostHeading style={{ height: 10, marginBottom: '1.5rem', width: 100 }} />
							<GhostHeading style={{ height: 14, marginBottom: '0.5rem', width: '100%' }} />
							<GhostHeading style={{ height: 14, marginBottom: '0.5rem', width: '100%' }} />
							<GhostHeading style={{ height: 14, marginBottom: '0.5rem', width: '100%' }} />
							<GhostHeading style={{ height: 14, marginBottom: '0.5rem', width: '60%' }} />
						</div>
					</div>
				</>
			</GhostCard>
		);
	}
	const isCardEmpty = !data?.averageScore && (data?.recentResponses?.length ?? 0) === 0;
	const handleCloseClick = () => {
		PersistentStorageManager.local.set(PersistentStorageSuppressEmptySatisfactionCardKey, String(true));
		setIsEmptySuppressed(true);
	};

	if (isCardEmpty && isEmptySuppressed) {
		return null;
	}

	return (
		<DashboardCard styleDeclaration={styleSheet.root}>
			{isCardEmpty ? (
				<DeprecatedCloseButton
					fillColor={grayIconFill}
					onClick={handleCloseClick}
					className={css(styleSheet.closeButton)}
				/>
			) : null}
			<button className={css(styleSheet.starButton)} onClick={() => history.push('/reporting/surveys')}>
				<StarIcon fillColor='white' />
			</button>
			<div className={css(styleSheet.rightContainerColumn)}>
				<section className={css(styleSheet.column1)}>
					<DashboardCardHeaderLabel>Satisfaction Score</DashboardCardHeaderLabel>
					<div className={css(styleSheet.satisfactionRatingContainer)}>
						{data?.averageScore ? (
							<>
								<DashboardCardStat styleDeclaration={styleSheet.satisfactionRating}>
									{data.averageScore}
								</DashboardCardStat>
								<StarIcon fillColor={surveysTint} filled={true} />
							</>
						) : (
							<SatisfactionBgGraphic />
						)}
					</div>
				</section>
				<section className={css(styleSheet.column2)}>
					{(data?.recentResponses?.length ?? 0) > 0 ? (
						<>
							<DashboardCardHeaderLabel styleDeclaration={styleSheet.clientFeedbackHeadline}>
								Client Feedback
							</DashboardCardHeaderLabel>
							{/* @ts-ignore */}
							<DashboardCarousel
								sliderLeftProps={{ fillColor: surveysTint }}
								sliderRightProps={{ fillColor: surveysTint }}
							>
								{data?.recentResponses?.map((review, i) => (
									<SatisfactionClientFeedback key={i} review={review} />
								))}
							</DashboardCarousel>
						</>
					) : (
						<>
							<p>
								You haven&apos;t run a customer satisfaction survey yet. You can learn valuable insights while gaining
								Google reviews through sending a survey!
							</p>
							<NavLink className={css(styleSheet.surveyLink)} to='/surveys'>
								Send your first survey now <RightArrow styles={{ marginLeft: 4 }} />
							</NavLink>
						</>
					)}
				</section>
			</div>
		</DashboardCard>
	);
}
