import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const InputFieldEnterIcon: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon className={`input-field-enter-icon ${className || ''}`} height={22} width={22}>
			<g fill='none' fillRule='evenodd'>
				<circle cx='11' cy='11' r='11' fill='#D3DBDE' />
				<polygon
					fill='#FFF'
					points='11.897 13.798 13.099 15 17.099 11 13.099 7 11.897 8.202 13.846 10.15 4 10.15 4 11.85 13.846 11.85'
				/>
			</g>
		</SvgIcon>
	);
};
