import * as React from 'react';

export interface IFocusedViewContext {
	setContactIds(contactIds: string[]): void;
	contactIds: string[];
	selectedContactId: string;
	setSelectedContactId(contactId: string): void;
}

export const FocusedViewContext = React.createContext<IFocusedViewContext>(null);

export const useFocusedViewContext = () => {
	const context = React.useContext(FocusedViewContext);
	if (!context) {
		throw new Error('This component requires the use of "FocusedViewContext".');
	}

	return context;
};
