import { brandPrimary } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

const fabSize = 55;

export const styleSheet = StyleSheet.create({
	addNoteloatingActionButton: {
		bottom: 30,
		color: '#fff',
		fontWeight: 600,
		margin: '1em',
		pointerEvents: 'none',
		position: 'fixed',
		right: 30,
		zIndex: 1,
	},
	addNoteloatingActionButtonOpen: {
		pointerEvents: 'all',
	},
	menu: {
		listStyle: 'none',
		margin: '0',
		padding: '0',
		pointerEvents: 'none',
		textAlign: 'center',
	},
	menuItem: {
		':nth-last-of-type(1)': {
			transitionDelay: '20ms',
		},
		':nth-last-of-type(2)': {
			transitionDelay: '40ms',
		},
		':nth-last-of-type(3)': {
			transitionDelay: '60ms',
		},
		':nth-last-of-type(4)': {
			transitionDelay: '80ms',
		},
		':nth-last-of-type(5)': {
			transitionDelay: '100ms',
		},
		alignItems: 'center',
		background: brandPrimary,
		borderRadius: '50%',
		boxShadow: '0 2px 4px 0 rgba(0,0,0,0.50)',
		cursor: 'pointer',
		display: 'flex',
		height: fabSize - 10,
		justifyContent: 'center',
		marginBottom: 20,
		marginLeft: 'auto',
		marginRight: 'auto',
		opacity: 0,
		position: 'relative',
		transform: 'scale(0.9) translateY(20%)',
		transition: '0.2s',
		width: fabSize - 10,
	},
	menuOpen: {
		pointerEvents: 'all',
	},
	modal: {
		height: 0,
		left: 0,
		outline: 'none',
		pointerEvents: 'none',
		position: 'fixed',
		top: 0,
		width: 0,
	},
	modalPortal: {
		zIndex: 5,
	},
	rotate: {
		transform: 'rotate(0deg)',
	},
	showMenuItem: {
		opacity: 1,
		transform: 'scale(1), translateY(0%)',
	},
	showTooltip: {
		opacity: 1,
		visibility: 'visible',
	},
	tooltip: {
		backgroundColor: '#9B9B9B',
		borderRadius: 2,
		bottom: '50%',
		color: '#fff',
		fontSize: 14,
		opacity: 0,
		padding: '4px 10px',
		pointerEvents: 'none',
		position: 'absolute',
		right: fabSize + 10,
		transform: 'translateY(50%)',
		transition: '0.3s',
		visibility: 'hidden',
		whiteSpace: 'nowrap',
	},
	trigger: {
		alignItems: 'center',
		backgroundColor: brandPrimary,
		border: 'none',
		borderRadius: '50%',
		boxShadow: '0 2px 4px 0 rgba(0,0,0,0.50);',
		color: '#fff',
		display: 'flex',
		height: fabSize,
		justifyContent: 'center',
		pointerEvents: 'all',
		transition: '0.3s',
		verticalAlign: 'middle',
		width: fabSize,
	},
	triggerImage: {
		alignItems: 'center',
		display: 'flex',
		height: 22,
		justifyContent: 'center',
		transform: 'rotate(90deg)',
		transition: '0.3s',
		width: 22,
	},
	triggerWithTooltip: {
		alignItems: 'center',
		display: 'flex',
		position: 'relative',
	},
});
