import { ContactViewModel } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { UserSessionViewModelKey } from '../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { getContactTitleAndCompany } from '../../../../models/UiUtils';
import { baseStyleSheet } from '../../../styles/styles';
import { Avatar } from '../../Avatar';
import { Tag } from '../../tags/Tag';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps {
	className?: string;
	contact: ContactViewModel;
	disabled?: boolean;
	isSelected?: boolean;
	onClick?(): void;
	tooltipText?: string;
}

interface IState {
	contact?: ContactViewModel;
	isSelected?: boolean;
}

class _ContactCard extends React.Component<IProps, IState> {
	public state: IState = {};

	public UNSAFE_componentWillMount() {
		const { contact, isSelected } = this.props;
		this.setState({
			contact,
			isSelected,
		});
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		const nextState: IState = {};
		if (this.state.isSelected !== nextProps.isSelected) {
			nextState.isSelected = nextProps.isSelected;
		}

		if (this.state.contact !== nextProps.contact) {
			nextState.contact = nextProps.contact;
		}

		if (Object.keys(nextState).length > 0) {
			this.setState(nextState);
		}
	}

	public render() {
		const { className, onClick, disabled, tooltipText } = this.props;
		const { isSelected, contact } = this.state;
		const titleAndCompany = this.contactTitleAndCompany;
		const visibleTags = this.visibleTags || [];
		const sortedTags = this.sortedTags || [];
		const overflowCount = sortedTags.length - visibleTags.length;
		const containerStyles: StyleDeclarationValue[] = [
			styleSheet.container,
			// @ts-ignore
			isSelected ? styleSheet.containerSelected : null,
			// @ts-ignore
			disabled ? baseStyleSheet.disabled : null,
		];
		return (
			<div
				className={`${css(containerStyles)} contact-card ${className || ''}`}
				onClick={!disabled ? onClick : undefined}
				title={tooltipText}
			>
				<div className={css(styleSheet.avatarContainer)}>
					{/* @ts-ignore */}
					<Avatar className={css(styleSheet.avatar)} entityVm={contact} />
				</div>
				{/* @ts-ignore */}
				<div className={`contact-card-text ${css(styleSheet.name)}`}>{contact.name}</div>
				{!!titleAndCompany && (
					<div className={`contact-card-text ${css(styleSheet.titleAndCompany)}`}>{titleAndCompany}</div>
				)}
				{!!this.lastInteractionTimeStringValue && (
					<div className={`contact-card-text ${css(styleSheet.interaction)}`}>
						<span>Last interaction:</span>
						&nbsp;
						<span className={css(styleSheet.interactionTime)}>{this.lastInteractionTimeStringValue}</span>
					</div>
				)}
				{visibleTags.length > 0 && (
					<div className={css(styleSheet.tags)}>
						<div className={css(styleSheet.tagsContent)}>
							{visibleTags.map(x => {
								return (
									<Tag
										className={css(styleSheet.tag)}
										key={`contact-${this.props.contact.id}-tag-${x}`}
										// @ts-ignore
										// @ts-ignore
										tagAlert={contact.getTagAlertForTagValue(x)}
										tagValue={x}
									/>
								);
							})}
							{overflowCount > 0 && (
								<span className={`contact-card-text ${css(styleSheet.tag, styleSheet.tagsOverflow)}`}>
									{`+${overflowCount} more`}
								</span>
							)}
						</div>
					</div>
				)}
			</div>
		);
	}

	@computed
	private get sortedTags() {
		const { contact } = this.state;
		if (!!contact && !!contact.tags) {
			const sortedTags = contact.tags.sort((a, b) => {
				if (contact.getTagAlertForTagValue(a)) {
					return -1;
				} else if (contact.getTagAlertForTagValue(b)) {
					return 1;
				}

				return 0;
			});
			return sortedTags;
		}
		return null;
	}

	@computed
	private get visibleTags() {
		const sortedTags = this.sortedTags;
		if (sortedTags) {
			return sortedTags.slice(0, 2);
		}

		return null;
	}

	@computed
	private get lastInteractionTimeStringValue() {
		const { contact } = this.state;
		// @ts-ignore
		// @ts-ignore
		if (!!contact.relationshipHealth && !!contact.relationshipHealth.userLastInteractionDate) {
			const daysSinceLastInteraction = moment().diff(
				// @ts-ignore
				moment(contact.relationshipHealth.userLastInteractionDate),
				'days'
			);
			if (daysSinceLastInteraction > 0) {
				return `${daysSinceLastInteraction} day${daysSinceLastInteraction > 1 ? 's' : ''} ago`;
			}

			if (daysSinceLastInteraction === 0) {
				return 'today';
			}
		}

		return '';
	}

	/** Helper for composing contact title and company string */
	@computed
	private get contactTitleAndCompany() {
		const { contact } = this.state;
		// @ts-ignore
		return getContactTitleAndCompany(contact);
	}
}

const ContactCardAsObserver = observer(_ContactCard);
const ContactCardWithContext = inject(UserSessionViewModelKey)(ContactCardAsObserver);
export const ContactCard = withEventLogging(ContactCardWithContext, 'ContactCard');
