import { IMeetingConfig, IOperationResultNoValue, MeetingConfigViewModel, MeetingDateRange } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ILocationState } from '../../../../../models';
import { useEventLogging } from '../../../../../models/Logging';
import { getDisplayName } from '../../../../../models/UiUtils';
import { useErrorMessages, useUserSession } from '../../../../../models/hooks/appStateHooks';
import { invalidateSchedulerConfigsQuery, useSchedulerConfigDeleteMutation } from '../../../../../queries';
import { Button } from '../../../Button';
import { LoadingSpinner } from '../../../LoadingSpinner';
import { INotificationServiceComponentProps } from '../../../LocalNotificationObserver/WithNotificationService';
import { MultiContainerHeader } from '../../../MultiContainerHeader';
import { AutomaticReminders } from './components/AutomaticReminders';
import { Availability } from './components/Availability';
import { BasicInfo } from './components/BasicInfo';
import { SchedulingLimits } from './components/SchedulingLimits';
import { styleSheet } from './styles';

interface IProps extends RouteComponentProps<any>, INotificationServiceComponentProps<any> {
	meeting: MeetingConfigViewModel;
	onClose(): void;
	styles?: StyleDeclarationValue[];
}

export type IMeetingConfigModalLocationState = ILocationState<MeetingConfigViewModel, IMeetingConfig>;

const MeetingConfigSFC: React.FC<IProps> = ({ meeting, onClose }) => {
	const logger = useEventLogging();
	const errorMessages = useErrorMessages();
	const userSession = useUserSession();

	const [ccEmailError, setCcEmailError] = useState('');

	const meetingRange = meeting?.meetingDateRadio;
	const handleError = (err: IOperationResultNoValue) => {
		logger.logApiError('ScheduleMeetingDelete-Error', err);
		// @ts-ignore
		errorMessages.pushApiError(err);
	};
	const handleOnClose = () => {
		// @ts-ignore
		// @ts-ignore
		invalidateSchedulerConfigsQuery({ userId: meeting.forUser?.id ?? userSession.user.id });
		onClose();
	};
	const deleteMeetingMutation = useSchedulerConfigDeleteMutation({
		onError: handleError,
		onSuccess: handleOnClose,
	});

	useEffect(() => {
		meeting.setAsEditing()?.catch((err: IOperationResultNoValue) => {
			logger.logApiError('MeetingConfigSetAsEditing-Error', err);
			// do not need to show user that this failed...
		});
		meeting.getAutomationChoices();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onCancelMeetingConfig = useCallback(() => {
		if (meeting.isPublished) {
			meeting.discardChanges()?.then(handleOnClose)?.catch(handleError);
		} else {
			// @ts-ignore
			// @ts-ignore
			// @ts-ignore
			deleteMeetingMutation.mutate({ meetingId: meeting.id, userId: meeting.forUser?.id ?? userSession.user.id });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [meeting, onClose]);

	const onSaveMeetingConfig = useCallback(() => {
		if (meetingRange === MeetingDateRange.DateRange) {
			// @ts-ignore
			const from = moment(meeting.dateRange.start);
			// @ts-ignore
			const to = moment(meeting.dateRange.end);
			if (to > moment(maxDate)) {
				// @ts-ignore
				errorMessages.push({
					messages: ['To date cannot be more than 180 days in the future'],
				});
				return;
			}
			if (from >= to) {
				// @ts-ignore
				errorMessages.push({
					messages: ['From date cannot be greater than to date'],
				});
				return;
			}
		}
		meeting
			.commitChanges()
			?.then(() => {
				// @ts-ignore
				// @ts-ignore
				invalidateSchedulerConfigsQuery({ userId: meeting.forUser?.id ?? userSession.user.id });
				onClose();
			})
			?.catch((err: IOperationResultNoValue) => {
				logger.logApiError('MeetingConfigCommitingChanges-Error', err);
				// @ts-ignore
				errorMessages.pushApiError(err);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [meeting, onClose, meetingRange]);

	const maxDate = new Date();
	maxDate.setDate(maxDate.getDate() + 180);

	return (
		<div className={css(styleSheet.container)}>
			<MultiContainerHeader
				fullscreenHeader={`${meeting?.isPublished ? 'Edit' : 'New'} Meeting${
					meeting?.forUser ? ` for ${getDisplayName(meeting?.forUser)}` : ''
				}`}
				onFullscreenRequestBack={onCancelMeetingConfig}
				onFullscreenRequestClose={onCancelMeetingConfig}
			/>

			<div className={css(styleSheet.wrapper)}>
				<BasicInfo meeting={meeting} onEmailError={setCcEmailError} />
				<Availability meeting={meeting} />
			</div>
			<div className={css(styleSheet.cardBottomContainer)}>
				<div className={css(styleSheet.card)}>
					<SchedulingLimits meeting={meeting} />
				</div>
			</div>
			<AutomaticReminders meeting={meeting} />
			<div className={css(styleSheet.footer)}>
				<Button
					disabled={meeting?.isBusy || meeting?.isSaving || ccEmailError.length > 0}
					label='Save'
					onClick={onSaveMeetingConfig}
				/>
				<Button
					disabled={meeting?.isBusy || meeting?.isSaving}
					kind='reverse'
					label='Cancel'
					onClick={onCancelMeetingConfig}
				/>
				{meeting?.isSaving && (
					<div className={css(styleSheet.savingChanges)}>
						<LoadingSpinner type='tiny' />
						Saving changes...
					</div>
				)}
			</div>
		</div>
	);
};

export const MeetingConfigModal = observer(MeetingConfigSFC);
