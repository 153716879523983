import { withEventLogging } from '../../../../models/Logging';
import { useFullscreenModal } from '../../../../models/hooks/appStateHooks';
import { SvgIcon } from '../../svgs/icons/SvgIcon';
import { DashboardCard } from '../DashboardCard';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';

const Icon = () => (
	<SvgIcon width='24' height='24'>
		<path
			fill='#fff'
			fillRule='evenodd'
			d='M8.999 23h2v1h2v-1h2v-2h-6v2ZM13.665 16.709a1 1 0 0 0-.667.942V18h-2v-.349a1 1 0 0 0-.666-.942A5.008 5.008 0 0 1 6.998 12c0-2.757 2.244-5 5-5 2.757 0 5 2.243 5 5a5.007 5.007 0 0 1-3.333 4.709M11.998 5c-3.859 0-7 3.14-7 7a7.013 7.013 0 0 0 4 6.319V19a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-.681a7.011 7.011 0 0 0 4-6.319c0-3.86-3.14-7-7-7M12.999 0h-2v4h2V0ZM-.001 13h4v-2h-4v2ZM19.999 13h4v-2h-4v2ZM21.706 3.707l-1.414-1.414-3 3 1.414 1.414 3-3ZM6.706 5.293l-3-3-1.414 1.414 3 3 1.414-1.414Z'
			clipRule='evenodd'
		/>
	</SvgIcon>
);

export const _DashboardSuggestedForYouCard = () => {
	const fullscreenModal = useFullscreenModal();
	const focus = () => {
		fullscreenModal.history?.push({
			pathname: '/people/focused/',
			state: {
				mode: 'suggestions',
			},
		});
	};

	return (
		<DashboardCard className={css(styleSheet.card)}>
			<header>
				<figure className={css(styleSheet.iconWrap)}>
					<Icon />
				</figure>
			</header>
			<article className={css(styleSheet.content)}>
				<h3 className={css(styleSheet.heading)}>Suggested for you</h3>
				<p className={css(styleSheet.description)}>Contacts from the past</p>
				<button className={css(styleSheet.button)} onClick={focus}>
					<div tabIndex={-1}>View Suggestions</div>
				</button>
			</article>
		</DashboardCard>
	);
};

const ObservableDashboardSuggestedForYou = observer(_DashboardSuggestedForYouCard);
const DashboardSuggestedForYouWithContext = inject()(ObservableDashboardSuggestedForYou);

export const DashboardSuggestedForYouCard = withEventLogging(
	DashboardSuggestedForYouWithContext,
	'DashboardSuggestedForYouCard'
);
