import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { getDisplayName, getPhoneNumber } from '../../../../extViewmodels/Utils';
import { getPrincipalInitials } from '../../../../models/UiUtils';
import { Avatar2 } from '../../Avatar2';
import { UserIconAvatar } from '../../UserIconAvatar';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	onClick?: (recipient: Api.TextRecipientViewModel | Api.IPhoneNumber) => void;
	recipient?: Api.TextRecipientViewModel | Api.IPhoneNumber;
	toNumbers?: Api.ITextRecipient[];
}

export const RecipientListItem: React.FC<IProps> = ({ className = '', onClick, recipient, toNumbers }) => {
	const contact = recipient instanceof Api.TextRecipientViewModel ? recipient : null;
	const onItemClick = () => {
		onClick?.(recipient);
	};
	if (!contact?.toJs()?.firstName && !contact?.toJs().primaryEmail?.value) {
		return null;
	}

	return (
		<div className={`${css(styleSheet.container)} ${className}`} onClick={onItemClick}>
			<figure className={css(styleSheet.avatarContainer)}>
				{contact ? (
					<Avatar2
						fallbackText={getPrincipalInitials(contact)}
						imgSrc={contact?.profilePic}
						styleDeclaration={styleSheet.avatar}
					/>
				) : (
					<UserIconAvatar styleDeclaration={styleSheet.avatar} />
				)}
			</figure>
			<div className={css(styleSheet.contentContainer)}>
				<p>
					{recipient instanceof Api.TextRecipientViewModel
						? getDisplayName(recipient)
						: recipient.metadata?.standard ?? recipient.value}
				</p>
				<p className={css(styleSheet.phoneNumber)}>
					{recipient instanceof Api.TextRecipientViewModel
						? getPhoneNumber(recipient as Api.TextRecipientViewModel, toNumbers)
						: null}
				</p>
			</div>
		</div>
	);
};
