import * as React from 'react';
import { SvgIcon } from '../../icons/SvgIcon';

interface IProps {
	className?: string;
}

export const TaggingGameEmptyGraphic: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon className={`tagging-game-empty-graphic ${className || ''}`} height={300} width={300}>
			<g fill='none' fillRule='nonzero'>
				<path
					fill='#C6CCD7'
					d='M303,300.494526 C303,300.771849 302.693905,300.994526 302.312733,300.994526 L0.687231922,300.994526 C0.306095408,300.994526 0,300.771849 0,300.494526 C0,300.217202 0.306095408,299.994526 0.687231922,299.994526 L302.312733,299.994526 C302.693905,299.994526 303,300.217228 303,300.494526 Z'
				/>
				<polygon fill='#FFB6B6' points='209.455 267.995 213 282.161 202.364 284.995 200 269.695' />
				<polygon fill='#FFB6B6' points='226.196 219.995 215.043 229.386 208 240.538 217.978 246.995 235 227.625' />
				<path
					fill='#E072BE'
					d='M222.492967,124.994526 L231.738259,156.213276 C231.738259,156.213276 272.764242,192.057026 268.719426,199.572651 C264.674611,207.088276 237.516566,227.900776 237.516566,227.900776 C237.516566,227.900776 239.53215,234.556041 233.612794,231.806536 C227.693444,229.057026 221.337306,221.541401 221.337306,221.541401 C221.337306,221.541401 221.337306,215.760151 224.226459,216.916401 C227.115613,218.072651 240.40572,202.463276 240.40572,202.463276 L211.514183,175.869526 L211.514183,264.7938 C211.514183,264.7938 218.448152,270.859562 211.514183,271.348916 C204.580214,271.838276 198.224076,272.994526 198.224076,272.994526 C198.224076,272.994526 190.134446,270.103901 193.023599,267.213276 L184.356138,219.807026 L178,161.994526 L182.622646,131.932026 L222.492967,124.994526 Z'
				/>
				<path
					fill='#8B3DC6'
					d='M203.443 280.279C203.443 280.279 204.034 277.995 206.399 277.995L213.623 277.995C213.623 277.995 215.86 287.702 215.269 287.702 214.678 287.702 224.73 300.264 214.086 300.835 203.443 301.406 200.486 300.264 200.486 300.264L199.303 295.756C199.303 295.756 197.529 292.841 198.121 290.557 198.712 288.273 203.443 280.279 203.443 280.279L203.443 280.279ZM218.66 242.93C218.66 242.93 225.351 242.825 223.753 244.622 222.156 246.42 219.243 249.327 219.243 249.327 219.243 249.327 212.313 263.285 209.078 260.668 205.843 258.051 200.6 228.973 202.348 225.484 204.096 221.995 206.426 221.995 206.426 221.995L217.174 228.447C217.174 228.447 210.504 244.675 218.66 242.93Z'
				/>
				<polygon fill='#FFB6B6' points='202.677 76.837 205 62.363 189.323 55.995 187 77.995' />
				<polygon fill='#000' points='202.677 76.837 205 62.363 189.323 55.995 187 77.995' opacity='.1' />
				<path
					fill='#FFF'
					d='M185.805246,74.5940791 L204.167031,69.9945258 L223.676426,76.3189115 C223.676426,76.3189115 232.283513,100.466566 229.414484,109.090728 C226.545455,117.71489 226.545455,134.5603 226.545455,134.5603 C226.545455,134.5603 226.258552,127.201469 209.618185,132.375966 C192.977818,137.550464 180.927897,136.975519 180.927897,136.975519 L180.354092,116.27753 L173.145766,97.8926616 C170.064733,90.0344687 173.385611,81.1125084 180.849745,77.1949654 L185.805246,74.5940791 L185.805246,74.5940791 Z'
				/>
				<g transform='translate(219 76.995)'>
					<path
						fill='#FFB6B6'
						d='M51.6486049,65.6549144 C54.27177,68.7572479 54.776543,72.6080718 52.7762734,74.2557426 C50.7760038,75.9034076 47.0287254,74.7239225 44.405049,71.6201323 C43.3397676,70.3944869 42.5873108,68.9348233 42.210635,67.3632547 L31.2643356,54.0799678 L36.5964534,49.8201883 L47.7973556,62.7611648 C49.2967968,63.4082793 50.6173956,64.4005487 51.6486107,65.6549201 L51.6486049,65.6549144 Z'
					/>
					<polygon
						fill='#FFF'
						points='3.81 0 0 14.897 18.646 44.362 37.682 63.248 43.851 58.195 24.654 34.615 10.103 4.233'
					/>
				</g>
				<g transform='translate(238.993)'>
					<ellipse
						cx='26.007'
						cy='31.922'
						fill='#06F'
						rx='23.842'
						ry='30.247'
						transform='rotate(-4.226 26.007 31.922)'
					/>
					<rect width='1.149' height='114.906' x='26.581' y='62.088' fill='#2F2E41' />
					<polygon
						fill='#06F'
						points='28.016 59.662 28.154 63.733 29.989 67.503 22.334 67.111 25.552 63.865 25.188 60.166'
					/>
				</g>
				<g transform='translate(174 16.995)'>
					<ellipse cx='23.956' cy='33.478' fill='#FFB6B6' rx='15.732' ry='15.892' />
					<path
						fill='#2F2E41'
						d='M22.7008022,12.1133311 C23.1560148,12.3817549 23.7657645,11.975636 23.9117255,11.4640106 C24.0576865,10.9523852 23.8876596,10.4091714 23.7190497,9.90470287 L22.8702406,7.36511458 C22.26825,5.56398474 21.6293321,3.69990888 20.3255619,2.32901787 C18.3577336,0.25987399 15.2303936,-0.266509602 12.4151561,0.114837277 C8.79984141,0.604559115 5.23277279,2.58209479 3.55175286,5.85221123 C1.87073865,9.12232766 2.58640813,13.6853134 5.62389528,15.7255354 C1.29481373,20.7377777 -0.214236632,26.3237488 0.0240398911,32.9711677 C0.2623107,39.6185865 7.43353527,45.7363839 12.1101327,50.4192303 C13.154503,49.7797216 14.1039523,46.7826926 13.5296444,45.6939389 C12.9553364,44.6051853 13.7781883,43.3438293 13.0669298,42.3409699 C12.3556713,41.3381105 11.7606113,42.934963 12.4796804,41.9377945 C12.9334246,41.3085652 11.1624774,39.8609858 11.8369343,39.4843775 C15.0991909,37.6627986 16.184191,33.5552393 18.233096,30.4097682 C20.7044571,26.6157442 24.9341897,24.0463913 29.4103692,23.620135 C31.8761424,23.3853254 34.4805346,23.8106122 36.5016369,25.2566621 C38.5227336,26.702712 39.8311832,29.2867769 39.3625093,31.7434376 C40.5762952,30.4984848 41.1804913,28.6740027 40.9526022,26.9418734 C40.7247131,25.209744 39.6697439,23.6080144 38.176189,22.726522 C39.0843344,19.692837 38.3063518,16.2032175 36.1985104,13.8556475 C34.0906691,11.5080774 25.5400998,11.9079168 22.4628057,12.5257527'
					/>
					<path
						fill='#2F2E41'
						d='M22.2229122,23.712228 C18.1471341,24.1566767 15.204305,27.7230711 12.7193282,31.0166264 C11.2870465,32.9149572 9.78674377,35.0113492 9.82270551,37.397907 C9.85906149,39.8107837 11.4560907,41.8803431 12.2193378,44.1670161 C13.4668856,47.9046507 12.2510028,52.3515737 9.28127392,54.9125749 C12.2156982,55.4750719 15.3878904,53.2524935 15.8944687,50.2790822 C16.4841864,46.8176632 13.8866449,43.4768609 14.1942795,39.97833 C14.4653067,36.896082 16.8698295,34.5239245 18.9140264,32.2193362 C20.9582291,29.9147479 22.8780912,26.856614 21.9376523,23.9116628'
					/>
				</g>
				<path
					fill='#FFF'
					d='M181.692165,136.994526 L63.3078345,136.994526 C49.8829867,136.994526 39,147.916779 39,161.390057 L39,276.599053 C39,290.072302 49.8829637,300.994526 63.3077827,300.994526 L181.692165,300.994526 C195.117013,300.994526 206,290.072279 206,276.599001 L206,161.390051 C206,147.916773 195.117013,136.994526 181.692165,136.994526 L181.692165,136.994526 Z'
				/>
				<path
					fill='#06F'
					d='M181.99095,300.994526 L63.0090504,300.994526 C49.2191184,300.994526 38,289.802276 38,276.045022 L38,160.944313 C38,147.187058 49.2191184,135.994526 63.0090504,135.994526 L181.99095,135.994526 C195.780882,135.994526 207,147.187058 207,160.944313 L207,276.045022 C207,289.802276 195.780882,300.994526 181.99095,300.994526 Z M63.0090504,137.148808 C49.8569449,137.148808 39.1570235,147.823379 39.1570235,160.944313 L39.1570235,276.045022 C39.1570235,289.165961 49.8569507,299.840244 63.0090504,299.840244 L181.99095,299.840244 C195.143055,299.840244 205.842977,289.165955 205.842977,276.045022 L205.842977,160.944313 C205.842977,147.823379 195.143049,137.148808 181.99095,137.148808 L63.0090504,137.148808 Z'
				/>
				<path
					fill='#E9EBEF'
					d='M58.5477635,259.994526 C57.6942793,259.994526 57,260.667374 57,261.494526 C57,261.897846 57.1607199,262.270892 57.4524362,262.546282 C57.7466698,262.838274 58.1321015,262.994526 58.5477577,262.994526 L182.452236,262.994526 C183.305721,262.994526 184,262.321677 184,261.494526 C184,261.091205 183.83928,260.718159 183.547564,260.44277 C183.25333,260.150777 182.867899,259.994526 182.452242,259.994526 L58.5477635,259.994526 Z'
				/>
				<path
					fill='#06F'
					d='M125,259.994526 L125,263.994526 L59.0541308,263.994526 C58.489257,263.994526 57.9757229,263.774493 57.605996,263.4045 C57.2259254,263.044517 57,262.544516 57,261.994526 C57,260.894492 57.9243832,259.994526 59.0541308,259.994526 L125,259.994526 L125,259.994526 Z'
				/>
				<path
					fill='#E9EBEF'
					d='M180.857142,251.994526 L160.142858,251.994526 C157.858522,251.994526 156,250.200583 156,247.994526 C156,245.788469 157.858522,243.994526 160.142858,243.994526 L180.857142,243.994526 C183.141478,243.994526 185,245.788469 185,247.994526 C185,250.200583 183.141478,251.994526 180.857142,251.994526 Z'
				/>
				<g transform='translate(45.048 81.987)'>
					<polyline fill='#2F2E41' points='57.514 172.437 43.029 123.955 41.92 124.284 56.354 172.595' />
					<ellipse
						cx='55.777'
						cy='31.935'
						fill='#06F'
						rx='24.002'
						ry='30.249'
						transform='rotate(-4.226 55.777 31.935)'
					/>
					<rect width='1.157' height='114.906' x='56.355' y='62.101' fill='#2F2E41' />
					<polygon
						fill='#06F'
						points='43.68 122.55 44.978 126.414 47.828 129.502 40.329 131.316 42.505 127.284 41.095 123.842'
					/>
					<polygon
						fill='#06F'
						points='57.8 59.675 57.939 63.746 59.786 67.515 52.079 67.124 55.319 63.878 54.953 60.178'
					/>
					<ellipse
						cx='33.22'
						cy='95.312'
						fill='#06F'
						rx='23.991'
						ry='30.263'
						transform='rotate(-20.934 33.22 95.312)'
					/>
				</g>
				<path
					fill='#FFB6B6'
					d='M194.204413,130.245637 C198.07832,131.457987 200.614969,134.512634 199.87024,137.068053 C199.12551,139.623473 195.381861,140.711612 191.506731,139.498291 C189.952362,139.035626 188.527486,138.193838 187.351313,137.043343 L171,131.691707 L173.058109,124.994526 L189.431222,129.905933 C191.02563,129.622401 192.662368,129.738879 194.204413,130.245637 L194.204413,130.245637 Z'
				/>
				<path
					fill='#FFF'
					d='M165.285714,87.72627 L182.428571,74.9945258 L181.857143,99.3005828 L165.857143,120.134346 L183,127.078934 L182.428571,136.994526 C182.428571,136.994526 160.714286,131.708659 157.285714,131.129943 C153.857143,130.551227 151,122.449208 151,122.449208 L165.285714,87.72627 Z'
				/>
			</g>
		</SvgIcon>
	);
};
