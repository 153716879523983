import { IEntity, IOperationResultNoValue, getTypeForEntity } from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { CompanyViewModel, ContactViewModel, EntityViewModel } from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import { CompanyContextTabView } from '../../email/CompanyContextTabView';
import { ContactContextTabView } from '../../email/ContactContextTabView';
import { BackArrowIcon } from '../../svgs/icons/BackArrowIcon';
import { ReferencedContactsIcon } from '../../svgs/icons/ReferencedContactsIcon';
import { MentionList } from '../MentionList';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps {
	className?: string;
	contextItemName?: 'note' | 'action item' | 'phone call';
	mentionedEntities?: EntityViewModel<IEntity>[];
	onMentionedEntitiesChanged?(mentionedEntities: EntityViewModel<IEntity>[]): void;
}

interface IState {
	mentionedEntities?: EntityViewModel<IEntity>[];
	selectedMentionedEntity?: EntityViewModel<IEntity>;
	showingReferencedContactsPlaceholder?: boolean;
}

export class _MentionEntitiesContext extends React.Component<IProps, IState> {
	public readonly state: IState = {
		showingReferencedContactsPlaceholder: true,
	};

	public UNSAFE_componentWillMount() {
		const nextState = this.getNextStateWithProps(this.props);
		if (nextState) {
			this.setState(nextState);
		}
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		const nextState = this.getNextStateWithProps(nextProps);
		if (nextState) {
			this.setState(nextState);
		}
	}

	public render() {
		const { className, contextItemName } = this.props;
		const { selectedMentionedEntity, showingReferencedContactsPlaceholder, mentionedEntities } = this.state;

		if (showingReferencedContactsPlaceholder) {
			return this.onRenderReferencedContactsPlaceholder();
		}

		if (selectedMentionedEntity) {
			const entityType = getTypeForEntity(selectedMentionedEntity.toJs());
			return (
				<div className={`${css(styleSheet.container)} mention-entities-context ${className || ''}`}>
					<div className={css(styleSheet.entityNavigatorHeader)}>
						<button className={css(styleSheet.navBackButton)} onClick={this.onNavigateBackToMentionedEntitiesList}>
							<BackArrowIcon />
							<span>See All Mentioned Contacts</span>
						</button>
					</div>
					<div className={css(styleSheet.entityNavigatorBody)}>
						{entityType === 'principal' && (
							<ContactContextTabView
								className={css(styleSheet.entityContextTabView)}
								contact={selectedMentionedEntity as ContactViewModel}
							/>
						)}
						{entityType === 'company' && (
							<CompanyContextTabView
								className={css(styleSheet.entityContextTabView)}
								company={selectedMentionedEntity as CompanyViewModel}
							/>
						)}
					</div>
				</div>
			);
		}

		return (
			<MentionList
				className={css(styleSheet.mentionList)}
				contextItemName={contextItemName}
				mentionedEntities={mentionedEntities}
				onEntityClicked={this.onEntityClicked}
				onEntitySelected={this.onEntitySelected}
				onRemoveEntity={this.onRemoveEntity}
			/>
		);
	}

	private onRenderReferencedContactsPlaceholder() {
		const { contextItemName } = this.props;
		return (
			<div className={css(styleSheet.placeholder)}>
				<div className={css(styleSheet.placeholderContent)}>
					<ReferencedContactsIcon className={css(styleSheet.placeholderIcon)} />
					<div className={css(styleSheet.placeholderTitle)}>
						{contextItemName === 'phone call'
							? 'Who was on this phone call?'
							: `Who is this ${contextItemName || 'item'} about?`}
					</div>
					<div className={css(styleSheet.placeholderDescription)}>
						<div>{`Reference contacts so you can find ${contextItemName || 'item'}s related`}</div>
						<div>to that contact easily. This will not send the</div>
						<div>contacts any notifications.</div>
					</div>
					<button
						className={`${css(baseStyleSheet.ctaButtonReverse)} ${css(styleSheet.placeholderAddButton)}`}
						onClick={this.onShowMentionList}
					>
						<span>Reference Contacts</span>
					</button>
				</div>
			</div>
		);
	}

	private getNextStateWithProps = (nextProps: IProps) => {
		const nextState: IState = {};

		if (nextProps.mentionedEntities !== this.state.mentionedEntities) {
			nextState.mentionedEntities = nextProps.mentionedEntities;
			if (!!nextState.mentionedEntities && nextState.mentionedEntities.length > 0) {
				nextState.showingReferencedContactsPlaceholder = false;
			}
		}

		if (Object.keys(nextState).length > 0) {
			return nextState;
		}
		return null;
	};

	private onEntityClicked = (entity: EntityViewModel<IEntity>) => {
		const { logApiError } = this.props;
		const promise = entity.load();
		if (promise) {
			if (getTypeForEntity(entity.toJs()) === 'principal') {
				promise.catch((error: IOperationResultNoValue) => {
					// @ts-ignore
					logApiError('ContactLoad-Error', error);
				});
			} else if (getTypeForEntity(entity.toJs()) === 'company') {
				promise.catch((error: IOperationResultNoValue) => {
					// @ts-ignore
					logApiError('CompanyLoad-Error', error);
				});
			}
		}
		this.setState({
			selectedMentionedEntity: entity,
		});
	};

	private onRemoveEntity = (entity: EntityViewModel<IEntity>) => {
		const { onMentionedEntitiesChanged } = this.props;
		let mentionedEntities = this.state.mentionedEntities || [];
		const index = mentionedEntities.findIndex(x => x.id === entity.id);
		if (index >= 0) {
			mentionedEntities = [...mentionedEntities];
			mentionedEntities.splice(index, 1);

			if (onMentionedEntitiesChanged) {
				onMentionedEntitiesChanged(mentionedEntities);
			} else {
				this.setState({
					mentionedEntities,
				});
			}
		}
	};

	private onEntitySelected = (entity: EntityViewModel<IEntity>) => {
		let mentionedEntities = this.state.mentionedEntities || [];
		const { onMentionedEntitiesChanged } = this.props;
		if (mentionedEntities.findIndex(x => x.id === entity.id) < 0) {
			mentionedEntities = [...mentionedEntities, entity];

			if (onMentionedEntitiesChanged) {
				onMentionedEntitiesChanged(mentionedEntities);
			} else {
				this.setState({
					mentionedEntities,
				});
			}
		}
	};

	private onShowMentionList = () => {
		this.setState({
			showingReferencedContactsPlaceholder: false,
		});
	};

	private onNavigateBackToMentionedEntitiesList = () => {
		this.setState({
			// @ts-ignore
			selectedMentionedEntity: null,
		});
	};
}

const MentionEntitiesContextAsObserver = observer(_MentionEntitiesContext);
export const MentionEntitiesContext = withEventLogging(MentionEntitiesContextAsObserver);
