import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { useEventLogging } from '../../../../models/Logging';
import { getLogoUrlForIntegrationProvider } from '../../../../models/UiUtils';
import { useToaster } from '../../../../models/hooks/appStateHooks';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { InProgressIcon } from '../../../components/svgs/icons/InProgressIcon';
import { baseStyleSheet } from '../../../styles/styles';
import { useMergeIntegration } from '../hooks/useMergeIntegration';
import { styleSheet as integrationsStyleSheet } from '../styles';
import { styleSheet } from './styles';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject } from 'mobx-react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

interface IProps extends IImpersonationContextComponentProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const MergeCrmIntegration = inject(ImpersonationContextKey)((props: IProps) => {
	const { className, styles, impersonationContext } = props;
	const toaster = useToaster();
	const h = useHistory();
	const { logApiError } = useEventLogging('MergeCrmIntegration');

	const onError = React.useCallback(
		(action: string) => (error: Api.IOperationResultNoValue) => {
			logApiError(action, error);
			// @ts-ignore
			toaster.push({
				message: error.systemMessage,
				type: 'errorMessage',
			});
		},
		[toaster, logApiError]
	);

	const {
		disableIntegrationAsync,
		isDisablingIntegration,
		isLoadingStatus,
		isMergeReady,
		openMergeAuthFlow,
		providerDisplayName,
		providerName,
		status,
	} = useMergeIntegration({
		impersonationContext,
		onAuthError: onError('Auth-Error'),
		onStatusError: onError('GetStatus-Error'),
		provider: Api.IntegrationSources.MergeCrm,
	});

	const onDisableClick = async (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		try {
			await disableIntegrationAsync();
			// @ts-ignore
			toaster.push({
				message: 'Integration disabled successfully',
				type: 'successMessage',
			});
			h.replace('/integrations');
		} catch (error) {
			// @ts-ignore
			onError(error);
		}
	};

	let content: React.ReactNode = null;

	if (status?.isConnected) {
		content = status.lastLoadDate ? (
			<>
				<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
					{`We are now syncing your ${providerDisplayName} contacts. This may take a while. Feel free to navigate away from this
					screen.`}
				</p>
				<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
					If you wish to disable this integration for your user,{' '}
					<button className={css(baseStyleSheet.brandLink)} disabled={isDisablingIntegration} onClick={onDisableClick}>
						<span>click here to disable.</span>
						{isDisablingIntegration ? <LoadingSpinner type='extra-tiny' /> : null}
					</button>
				</p>
			</>
		) : (
			<>
				<div className={css(styleSheet.inProgressContainer)}>
					<div className={css(styleSheet.inProgressIconContainer)}>
						<InProgressIcon className={css(baseStyleSheet.absoluteCenter)} />
					</div>
					<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
						Sync in progress... this could take a while.
					</p>
				</div>
			</>
		);
	} else {
		content = (
			<>
				<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
					{`To get started, please sign into your ${providerDisplayName} account.`}
				</p>
				<div className={css(styleSheet.flex)}>
					<button
						className={css(baseStyleSheet.ctaButton, styleSheet.button)}
						disabled={!isMergeReady && !isDisablingIntegration}
						onClick={openMergeAuthFlow}
					>
						{!isMergeReady ? <LoadingSpinner type='extra-small' /> : <span>Connect</span>}
					</button>
				</div>
			</>
		);
	}

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
			<div className={css(integrationsStyleSheet.logo, integrationsStyleSheet.headerLogo)}>
				{/* @ts-ignore */}
				<img src={getLogoUrlForIntegrationProvider(providerName)} alt={`${providerDisplayName} Logo`} />
			</div>
			<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
				{`Levitate can sync data from ${providerDisplayName}, including client names, email addresses, physical addresses, matters,
				and more.`}
			</p>
			{isLoadingStatus ? <LoadingSpinner type='large' className={css(baseStyleSheet.absoluteCenter)} /> : content}
		</div>
	);
});
