import { StyleDeclaration, css } from 'aphrodite';
import { PhoneIcon } from '../../components/svgs/icons/PhoneIcon';
import { bs } from '../../styles/styles';

export function PhoneMissingPill({ styleDeclarations = [] }: { styleDeclarations?: StyleDeclaration[] }) {
	return (
		<div
			className={css(
				bs.flex,
				bs.itemsCenter,
				bs.rounded,
				bs.boxBorder,
				bs.textXs,
				bs.p1,
				bs.gap1,
				...styleDeclarations
			)}
			style={{ backgroundColor: 'rgba(0, 170, 232, 0.05)' }}
		>
			<span className={css(bs.textVividCerulian)}>Missing</span>
			<span
				className={css(
					bs.boxBorder,
					bs.flex,
					bs.justifyCenter,
					bs.itemsCenter,
					bs.roundedFull,
					bs.h4,
					bs.p1,
					bs.w4,
					bs.bgVividCerulian
				)}
			>
				<PhoneIcon width={12} height={12} fill='white' />
			</span>
		</div>
	);
}
