import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useDebounceValue } from '../../../hooks/useDebounceValue';
import {
	ICreateCampaignRequest,
	ICreateSocialMediaPostRequest,
	ILocationState,
	TemplateOrTemplateFilter,
} from '../../../models';
import { useEventLogging } from '../../../models/Logging';
import { hideDefaultMessageTemplate } from '../../../models/MessageTemplates';
import { useErrorMessages, useFullscreenModal, useToaster, useUserSession } from '../../../models/hooks/appStateHooks';
import { useModal } from '../../../models/hooks/useModal';
import { invalidateMyTemplates, useTemplateScopeMutation } from '../../../queries';
import { invalidateEmailAllCategoryTemplates, useEmailDeleteTemplate } from '../../../queries/EmailCampaign';
import { useEmailMyTemplatesFilter } from '../../../queries/Templates/useEmailMyTemplatesFilter';
import { ITemplateCard, KnownCategories } from '../../../viewmodels/AppViewModels';
import { IConfirmationDialogOption } from '../../components/ConfirmationDialog';
import { DeleteConfirmation } from '../../components/DeleteConfirmation';
import { ISelectOption } from '../../components/Select';
import { EmailReportCard } from '../../components/campaigns/EmailReportCard';
import { TemplateSharingConfirmation } from '../../components/templates/TemplateSharingConfirmation';
import { baseStyleSheet } from '../../styles/styles';
import { CampaignBrowser, useCampaignParams, useEnforceIndustryAndCategory } from '../CampaignBrowser';
import { BrowseAllInCategoryButton } from '../CampaignBrowser/presentation';
import { useEmailCampaignBrowserData } from './hooks';
import {
	HTMLNewsletter,
	SearchKeywords,
	SectionCards,
	SortBySelector,
	TabValues,
	emailCampaignSortByOptions,
	renderLoading,
	renderNotFound,
} from './presentation';
import { styleSheet } from './styles';

const invalidateTemplateQueries = () => {
	invalidateEmailAllCategoryTemplates();
	invalidateMyTemplates({ templateType: Api.TemplateType.Email });
};

export const EmailCampaignCards = observer(() => {
	const { category, industry, search, setCampaignBrowserParam, sortBy } = useCampaignParams();
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const toaster = useToaster();
	const fullscreenModal = useFullscreenModal();
	const { logApiError } = useEventLogging('EmailCampaignCards');
	const { allCategoryTemplatesQuery, categoriesQuery, categoryTemplatesQuery, myTemplatesQuery, searchTemplatesQuery } =
		useEmailCampaignBrowserData();
	const shareEmailMutation = useTemplateScopeMutation();
	const deleteEmailMutation = useEmailDeleteTemplate();
	const [templateToBeManaged, setTemplateToBeManaged] = React.useState<ITemplateCard | undefined | null>();

	const selectedSortByCampaignParam = emailCampaignSortByOptions.find(i => i.dataContext === sortBy) || null;

	const [keywordsSearchValue, setKeywordsSearchValue] = React.useState<string>('');
	const debouncedKeywordsSearchValue = useDebounceValue(keywordsSearchValue, 500);

	const myTemplatesFilterQuery = useEmailMyTemplatesFilter({
		enabled: !!debouncedKeywordsSearchValue,
		industry,
		onError: error => {
			logApiError('LoadMyEmailTemplates-Error', error);
		},
		term: debouncedKeywordsSearchValue,
	});

	// #region Delete
	const canDelete = React.useCallback(
		(cardOwnerId: string) => {
			return cardOwnerId === userSession.user?.id || userSession.isAdmin;
		},
		[userSession.user?.id, userSession.isAdmin]
	);

	useEnforceIndustryAndCategory({ setCampaignBrowserParam, tabUrlParam: TabValues.Email });

	const deleteConfirmationModalProps = useModal(
		false,
		async (shouldDelete: boolean | undefined) => {
			if (shouldDelete) {
				try {
					if (templateToBeManaged?.creator?.id && canDelete(templateToBeManaged?.creator?.id)) {
						await deleteEmailMutation.mutateAsync({ template: templateToBeManaged });
					} else if (templateToBeManaged?.id) {
						hideDefaultMessageTemplate(templateToBeManaged?.id);
					}
					toaster?.push({
						message: 'Template deleted successfully',
						type: 'successMessage',
					});
					invalidateTemplateQueries();
				} catch (error: any) {
					errorMessages.pushApiError(error);
					logApiError('TemplateShare-Error', error);
					toaster.push({
						message: 'Error deleting template',
						type: 'errorMessage',
					});
				}
			}

			setTemplateToBeManaged(null);
		},
		[templateToBeManaged, deleteEmailMutation]
	);

	const onDeleteClick = (templateCard: ITemplateCard) => {
		setTemplateToBeManaged(templateCard);
		deleteConfirmationModalProps.setIsOpen(true)();
	};
	// #endregion

	// #region Share
	const [shareTemplate, setShareTemplate] = React.useState<boolean | null>(false);

	const shareConfirmationModalProps = useModal(
		false,
		async (result?: IConfirmationDialogOption<boolean>, canceled?: boolean) => {
			if (templateToBeManaged && result?.representedObject && !canceled) {
				try {
					await shareEmailMutation.mutateAsync({
						scope: shareTemplate ? Api.TemplateScope.Account : Api.TemplateScope.User,
						id: templateToBeManaged.id,
					});
					invalidateTemplateQueries();
					toaster.push({
						message: `Template ${shareTemplate ? 'shared' : 'unshared'} successfully`,
						type: 'successMessage',
					});
				} catch (error: any) {
					errorMessages.pushApiError(error);
					logApiError('TemplateShare-Error', error);
					toaster.push({
						message: `Error ${shareTemplate ? 'sharing' : 'unsharing'} template`,
						type: 'errorMessage',
					});
				}
			}
			setShareTemplate(null);
			setTemplateToBeManaged(null);
		},
		[shareTemplate, templateToBeManaged, shareEmailMutation]
	);

	const onShareClick = (templateCard: ITemplateCard) => (isShared: boolean) => {
		setShareTemplate(isShared);
		setTemplateToBeManaged(templateCard);
		shareConfirmationModalProps.setIsOpen(true)();
	};
	// #endregion

	const onViewCampaign = async (templateId: string, socialIndicator?: boolean) => {
		try {
			const templates = new Api.TemplatesViewModel(userSession);
			const template = await templates.getByIdExpandedByLastUsedBy(templateId);
			if (socialIndicator) {
				const associatedTemplateId = template?.associatedTemplates?.[0]?.id ?? '';
				const locationState: ILocationState<any, ICreateSocialMediaPostRequest<string | Api.ITemplate>> = {
					model: {
						context: associatedTemplateId,
						type: 'Template',
					},
				};
				fullscreenModal?.history.push({
					pathname: '/social-media/post/create/from-template',
					state: locationState,
				});
			} else {
				const locationState: ILocationState<any, ICreateCampaignRequest<TemplateOrTemplateFilter>> = {
					model: {
						context: { ...template },
						type: 'Template',
					},
				};

				fullscreenModal?.history.push({
					pathname: '/email/campaigns/create/from-template',
					state: locationState,
				});
			}
		} catch (error: any) {
			errorMessages.pushApiError(error);
			logApiError('LoadCategories-Error', error);
		}
	};

	const renderMainBrowser = () => {
		if (category === KnownCategories.All && !search) {
			if (allCategoryTemplatesQuery.status !== 'success') {
				return renderLoading();
			}
			return (
				<div className={css(styleSheet.cards)}>
					<>
						<EmailReportCard />
						{(allCategoryTemplatesQuery?.data?.Featured ?? []).length > 0 ? (
							<>
								<SectionCards
									title='Featured Campaigns'
									templates={allCategoryTemplatesQuery?.data?.Featured}
									industry={industry}
									button={
										<BrowseAllInCategoryButton
											styles={[styleSheet.browseAllCampaigns, baseStyleSheet.brandLink]}
											onClick={() => setCampaignBrowserParam({ category: KnownCategories.Featured })}
										>
											Browse all Featured Campaigns
										</BrowseAllInCategoryButton>
									}
									onViewCampaign={onViewCampaign}
									onDeleteClick={onDeleteClick}
									onShareClick={onShareClick}
									areAccountTemplates={false}
								/>
							</>
						) : null}
					</>

					{(allCategoryTemplatesQuery?.data?.['FINRA Reviewed'] ?? []).length > 0 ? (
						<>
							<SectionCards
								title='FINRA Reviewed'
								templates={allCategoryTemplatesQuery?.data?.['FINRA Reviewed']}
								industry={industry}
								button={
									<BrowseAllInCategoryButton
										onClick={() => setCampaignBrowserParam({ category: KnownCategories.FinraReviewed })}
										styles={[styleSheet.browseAllCampaigns, baseStyleSheet.brandLink]}
									>
										Browse all of FINRA Reviewed{' '}
									</BrowseAllInCategoryButton>
								}
								onViewCampaign={onViewCampaign}
								onDeleteClick={onDeleteClick}
								onShareClick={onShareClick}
								areAccountTemplates={false}
							/>
						</>
					) : null}

					{(allCategoryTemplatesQuery?.data?.Me ?? []).length > 0 ? (
						<>
							<SectionCards
								title='My Templates'
								templates={allCategoryTemplatesQuery?.data?.Me}
								industry={industry}
								button={
									<BrowseAllInCategoryButton
										onClick={() => setCampaignBrowserParam({ category: KnownCategories.MyTemplates })}
										styles={[styleSheet.browseAllCampaigns, baseStyleSheet.brandLink]}
									>
										Browse all of My Templates{' '}
									</BrowseAllInCategoryButton>
								}
								onViewCampaign={onViewCampaign}
								onDeleteClick={onDeleteClick}
								onShareClick={onShareClick}
								areAccountTemplates={true}
							/>
						</>
					) : null}
					{(categoriesQuery?.data ?? [])?.map(c => {
						if (
							c === KnownCategories.Featured ||
							c === KnownCategories.All ||
							c === KnownCategories.MyTemplates ||
							c === KnownCategories.HtmlNewsletters ||
							c === KnownCategories.FinraReviewed ||
							allCategoryTemplatesQuery?.data?.[c].length === 0
						) {
							return c === KnownCategories.All && allCategoryTemplatesQuery?.data?.[c]?.length === 0
								? renderNotFound(c)
								: null;
						}
						return (
							<React.Fragment key={c}>
								<SectionCards
									key={c}
									title={`${c.split('-').join(' ')} Campaigns`}
									templates={allCategoryTemplatesQuery?.data?.[c]}
									industry={industry}
									button={
										<BrowseAllInCategoryButton
											onClick={() => setCampaignBrowserParam({ category: c as KnownCategories })}
											styles={[styleSheet.browseAllCampaigns, baseStyleSheet.brandLink]}
										>
											<span>{`Browse all ${c.split('-').join(' ')} Campaigns`}</span>
										</BrowseAllInCategoryButton>
									}
									onViewCampaign={onViewCampaign}
									onDeleteClick={onDeleteClick}
									onShareClick={onShareClick}
									areAccountTemplates={c === KnownCategories.MyTemplates}
								/>
							</React.Fragment>
						);
					})}
				</div>
			);
		}
		return null;
	};

	const handleSortBy = (option: ISelectOption<string>) => {
		setCampaignBrowserParam({ sortBy: option.dataContext });
	};

	const handleSearchKeywordsInputChange = (searchValue: string) => {
		setKeywordsSearchValue(searchValue);
	};

	const handleBrowseAllCampaignsClick = () => {
		setKeywordsSearchValue('');
		setCampaignBrowserParam({ category: KnownCategories.All });
	};

	const renderTemplates = (templatesToDisplay: ITemplateCard[], isLoading?: boolean) => {
		return (
			<>
				<EmailReportCard />
				<div className={css(styleSheet.cardSection)}>
					{!search ? (
						<div className={css(styleSheet.flexDiv)}>
							<h2 className={css(styleSheet.categoryTitle)}>
								{category === KnownCategories.MyTemplates
									? KnownCategories.MyTemplates
									: `${String(category).split('-').join(' ')} Campaigns`}
							</h2>
							{category === KnownCategories.MyTemplates ? (
								<>
									<SortBySelector
										onChange={handleSortBy}
										selectedOption={selectedSortByCampaignParam || emailCampaignSortByOptions[0]}
									/>
									<SearchKeywords onChange={handleSearchKeywordsInputChange} value={keywordsSearchValue} />
								</>
							) : null}
							<BrowseAllInCategoryButton onClick={handleBrowseAllCampaignsClick}>
								Browse All Campaigns
							</BrowseAllInCategoryButton>
						</div>
					) : null}
					{templatesToDisplay?.length > 0 ? (
						<>
							<SectionCards
								category={category as KnownCategories}
								title=''
								templates={templatesToDisplay}
								industry={industry}
								button={<></>}
								onViewCampaign={onViewCampaign}
								onDeleteClick={onDeleteClick}
								onShareClick={onShareClick}
								areAccountTemplates={category === KnownCategories.MyTemplates && !search}
							/>
						</>
					) : isLoading ? (
						renderLoading()
					) : (
						renderNotFound()
					)}
				</div>
			</>
		);
	};
	const renderSearchTemplates = () => {
		if (search) {
			if (searchTemplatesQuery.status !== 'success') {
				return renderLoading();
			}
			return renderTemplates(searchTemplatesQuery.data);
		}
		return null;
	};
	const renderMyTemplates = () => {
		if (search || category !== KnownCategories.MyTemplates) {
			return null;
		}
		if (myTemplatesQuery.status !== 'success') {
			return renderLoading();
		}
		if (!keywordsSearchValue) {
			return renderTemplates(myTemplatesQuery.data, myTemplatesQuery.isLoading);
		}
		// @ts-ignore
		return renderTemplates(myTemplatesFilterQuery.data, myTemplatesFilterQuery.isLoading);
	};
	const renderCategoryTemplates = () => {
		if (
			search ||
			category === KnownCategories.All ||
			category === KnownCategories.MyTemplates ||
			category === KnownCategories.HtmlNewsletters
		) {
			return null;
		}
		if (categoryTemplatesQuery.status !== 'success') {
			return renderLoading();
		}
		return renderTemplates(categoryTemplatesQuery.data);
	};

	const handleSearchChange = React.useCallback(
		(value: string) => {
			setCampaignBrowserParam({ search: value });
		},
		[setCampaignBrowserParam]
	);
	return (
		<CampaignBrowser
			category={category}
			categories={categoriesQuery?.data ?? []}
			industry={industry}
			search={search}
			onSearchChange={handleSearchChange}
			onCategoryChange={value => setCampaignBrowserParam({ category: value })}
			onIndustryChange={value => setCampaignBrowserParam({ industry: value, category: KnownCategories.All })}
		>
			{renderMainBrowser()}
			{renderSearchTemplates()}
			{renderMyTemplates()}
			{renderCategoryTemplates()}
			<DeleteConfirmation
				bodyText='Are you sure you want to delete this template?'
				destructiveButtonText='Yes, Delete'
				isOpen={deleteConfirmationModalProps.isOpen}
				onFinish={deleteConfirmationModalProps.onRequestClose}
			/>
			<TemplateSharingConfirmation
				onRequestClose={shareConfirmationModalProps.onRequestClose}
				show={shareConfirmationModalProps.isOpen}
				template={templateToBeManaged ?? undefined}
			/>
			<HTMLNewsletter search={search} category={category} industry={industry} />
		</CampaignBrowser>
	);
});
