import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ILocationState } from '../../../models';
import { BlogPostRequest } from '../../../models/Blogs';
import { useErrorMessages, useFullscreenModal, useUserSession } from '../../../models/hooks/appStateHooks';
import {
	invalidateBlogPostDrafts,
	useBlogCardsAllCategoryTemplates,
	useBlogCardsCategories,
	useBlogCardsCategoryTemplates,
	useBlogPostDraftsInfiniteQuery,
	useBlogsCardSearchTemplates,
	useDeleteBlogPostMutation,
} from '../../../queries';
import { IBlogTemplateCard, KnownCategories } from '../../../viewmodels/AppViewModels';
import { ConfirmationDialog, DefaultDeleteConfirmationOptions } from '../../components/ConfirmationDialog';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { ISelectOption } from '../../components/Select';
import { BlogEmptyGraphic } from '../../components/svgs/graphics/BlogEmptyGraphic';
import { WarningIcon } from '../../components/svgs/icons/WarningIcon';
import { BlogPostTemplateCard } from '../../components/templateCards/BlogPostTemplateCard';
import { baseStyleSheet, bs } from '../../styles/styles';
import { CampaignBrowser, useCampaignParams, useEnforceIndustryAndCategory } from '../CampaignBrowser';
import { BrowseAllInCategoryButton } from '../CampaignBrowser/presentation';
import { TabValues } from './presentation';
import { styleSheet } from './styles';

export const BlogsCampaignCards = observer(() => {
	const fullscreenModal = useFullscreenModal();
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const { category, industry, search, setCampaignBrowserParam } = useCampaignParams();
	const isAllCategory = category === KnownCategories.All;
	const [showConfirmationModal, setShowConfirmationModal] = React.useState(false);
	const [blogToDelete, setBlogToDelete] = React.useState<Api.IBlogPost | null>(null);
	const isMyBlogDaftsCategory = category === KnownCategories.BlogPostDrafts;

	useEnforceIndustryAndCategory({ setCampaignBrowserParam, tabUrlParam: TabValues.Blogs });

	const isSearching = Boolean(search);

	const searchTemplatesQuery = useBlogsCardSearchTemplates({
		enabled: isSearching,
		industry,
		search,
	});

	const myDraftsQuery = useBlogPostDraftsInfiniteQuery({
		enabled: category === KnownCategories.BlogPostDrafts,
		userId: userSession.user!.id,
	});

	const deleteBlogPostMutation = useDeleteBlogPostMutation({
		onSuccess: () => {
			setBlogToDelete(null);
			invalidateBlogPostDrafts({ userId: userSession.user.id });
		},
		onError: error => {
			errorMessages.pushApiError(error);
		},
	});

	const handleTemplateClick = (templateCard: IBlogTemplateCard) => {
		const locationState: ILocationState<any, BlogPostRequest> = {
			model: {
				templateCard,
			},
		};
		fullscreenModal.history.push({
			pathname: `/blog/post/create-from-template/${templateCard.id}`,
			state: locationState,
		});
	};

	const categoryQuery = useBlogCardsCategories({
		enabled: Boolean(industry),
		industry,
		withAll: true,
		withMyTemplates: false,
	});

	const categoryTemplatesQuery = useBlogCardsCategoryTemplates({
		category,
		enabled: !isAllCategory && !isMyBlogDaftsCategory,
		industry,
	});

	const allCategoryTemplatesQuery = useBlogCardsAllCategoryTemplates({
		enabled: isAllCategory,
		industry,
	});

	const hasData = React.useMemo(() => {
		return (
			(isSearching && searchTemplatesQuery.data && searchTemplatesQuery.data.length > 0) ||
			(!isSearching &&
				((isAllCategory &&
					allCategoryTemplatesQuery.data &&
					Object.values(allCategoryTemplatesQuery.data).flat().length > 0) ||
					(!isAllCategory &&
						!isMyBlogDaftsCategory &&
						categoryTemplatesQuery.data &&
						categoryTemplatesQuery.data.length > 0) ||
					(isMyBlogDaftsCategory && myDraftsQuery.data && myDraftsQuery.data.pages.length > 0)))
		);
	}, [
		isSearching,
		searchTemplatesQuery.data,
		isAllCategory,
		allCategoryTemplatesQuery.data,
		isMyBlogDaftsCategory,
		categoryTemplatesQuery.data,
		myDraftsQuery.data,
	]);

	const isLoading =
		(isSearching && searchTemplatesQuery.isLoading) ||
		(!isSearching &&
			((isAllCategory && allCategoryTemplatesQuery.isLoading) ||
				(!isAllCategory && !isMyBlogDaftsCategory && categoryTemplatesQuery.isLoading) ||
				(isMyBlogDaftsCategory && myDraftsQuery.isLoading)));

	const handleChangeIndustry = (value: Api.Industry) => {
		setCampaignBrowserParam({ industry: value, category: KnownCategories.All });
	};
	const handleChangeCategory = (value: string) => {
		setCampaignBrowserParam({ category: value });
	};
	const handleSearchChange = React.useCallback(
		(value: string) => {
			setCampaignBrowserParam({ search: value });
		},
		[setCampaignBrowserParam]
	);
	const onBlogPostClicked = React.useCallback(
		async (blog: Api.IBlogPost) => {
			if (blog.id) {
				const locationState: ILocationState<any, BlogPostRequest> = {
					model: {
						post: blog,
					},
				};
				fullscreenModal?.history?.push({
					pathname: `/blog/post/edit/${blog.id}`,
					state: locationState,
				});
			}
		},
		[fullscreenModal?.history]
	);

	const onOptionClick = React.useCallback(
		(option: ISelectOption<Api.IBlogPost>) => {
			if (option.id === 'edit') {
				onBlogPostClicked(option.dataContext);
			}
			if (option.id === 'delete') {
				setShowConfirmationModal(true);
				setBlogToDelete(option.dataContext);
			}
		},
		[onBlogPostClicked]
	);

	const onCreateNewBlogPostClicked = () => {
		fullscreenModal.history.push({
			pathname: '/blog/post/create',
		});
	};

	const onDeleteConfirmationRequestClose = (_: any, cancel: boolean) => {
		if (cancel) {
			setShowConfirmationModal(false);
			return;
		}

		deleteBlogPostMutation.mutate({ id: blogToDelete?.id });
		setShowConfirmationModal(false);
	};

	return (
		<>
			<CampaignBrowser
				categories={categoryQuery.data ?? []}
				category={category}
				industry={industry}
				onCategoryChange={handleChangeCategory}
				onIndustryChange={handleChangeIndustry}
				onSearchChange={handleSearchChange}
				renderRightActions={
					<button className={css(baseStyleSheet.ctaButton)} onClick={onCreateNewBlogPostClicked}>
						<span>Create New Blog Post</span>
					</button>
				}
				search={search}
				searchPlaceholder='Search blog templates or keywords'
			>
				<div>
					{isSearching && searchTemplatesQuery.data && searchTemplatesQuery.data.length > 0 ? (
						<div className={css(bs.gap5, bs.grid, bs.p6, bs.boxBorder, bs.overflowAuto, styleSheet.cardList)}>
							{searchTemplatesQuery.data.map(template => (
								<BlogPostTemplateCard
									key={template.id}
									title={template.name}
									description={template.summary}
									onTemplateCardClick={() => handleTemplateClick(template)}
								/>
							))}
						</div>
					) : null}
					{!isSearching && !isAllCategory && !isMyBlogDaftsCategory && categoryTemplatesQuery.data ? (
						<div className={css(styleSheet.cards)}>
							<section className={css(styleSheet.cardSection)}>
								<h2 className={css(styleSheet.categoryTitle)}>{category}</h2>
								<div className={css(bs.gap5, bs.grid, bs.p6, bs.boxBorder, bs.overflowAuto, styleSheet.cardList)}>
									{categoryTemplatesQuery.data.map(template => (
										<BlogPostTemplateCard
											key={template.id}
											title={template.name}
											description={template.summary}
											onTemplateCardClick={() => handleTemplateClick(template)}
										/>
									))}
								</div>
							</section>
						</div>
					) : null}

					{!isSearching && isMyBlogDaftsCategory && myDraftsQuery.data ? (
						<div className={css(styleSheet.cards)}>
							<section className={css(styleSheet.cardSection)}>
								<h2 className={css(styleSheet.categoryTitle)}>{category}</h2>
								<div className={css(bs.gap5, bs.grid, bs.p6, bs.boxBorder, bs.overflowAuto, styleSheet.cardList)}>
									{myDraftsQuery.data.pages.map(group =>
										group.values.map(blog => {
											if (group.totalCount === 0) {
												return null;
											}

											const options: ISelectOption<Api.IBlogPost>[] = [
												{
													id: 'edit',
													text: 'Edit',
													label: 'Edit',
													dataContext: blog,
												},
												{
													id: 'delete',
													text: 'Delete',
													label: 'Delete',
													dataContext: blog,
												},
											];

											return (
												<BlogPostTemplateCard
													key={blog.id}
													title={blog.title}
													options={options}
													onOptionClick={onOptionClick}
													description={blog.content.document.replace(/<[^>]*>?/gm, '')}
													onTemplateCardClick={() => onBlogPostClicked(blog)}
												/>
											);
										})
									)}
								</div>
							</section>
						</div>
					) : null}
					{!isSearching &&
					isAllCategory &&
					allCategoryTemplatesQuery.data &&
					Object.keys(allCategoryTemplatesQuery.data).filter(x => x !== 'Featured').length > 0 ? (
						<div className={css(styleSheet.cards)}>
							{Object.keys(allCategoryTemplatesQuery.data).map((categoryName: string) => {
								const templates = allCategoryTemplatesQuery.data[categoryName] ?? [];
								return (
									<section key={categoryName} className={css(styleSheet.cardSection)}>
										{templates?.length > 0 ? (
											<h2 className={css(styleSheet.categoryTitle)}>
												{categoryName === KnownCategories.Me ? KnownCategories.MyTemplates : categoryName}
											</h2>
										) : null}
										<div className={css(bs.gap5, bs.grid, bs.p6, bs.boxBorder, bs.overflowAuto, styleSheet.cardList)}>
											{templates.map(template => (
												<BlogPostTemplateCard
													key={template.id}
													title={template.name}
													description={template.summary}
													onTemplateCardClick={() => handleTemplateClick(template)}
												/>
											))}
										</div>
										{templates?.length > 0 ? (
											<BrowseAllInCategoryButton
												styles={[styleSheet.browseAllCampaigns, baseStyleSheet.brandLink]}
												onClick={() => handleChangeCategory(categoryName)}
											>
												Browse all {categoryName === KnownCategories.Me ? KnownCategories.MyTemplates : categoryName}
											</BrowseAllInCategoryButton>
										) : null}
									</section>
								);
							})}
						</div>
					) : null}
					{isLoading && <LoadingSpinner className={css(styleSheet.pageLoading)} />}
					{!isLoading && !hasData ? (
						<div className={css(bs.gap5, bs.grid, bs.p6, bs.boxBorder, bs.overflowAuto, styleSheet.cardListEmpty)}>
							<figure>
								<BlogEmptyGraphic />
							</figure>
							<p>We did not find any blog templates</p>
						</div>
					) : null}

					<ConfirmationDialog
						icon={<WarningIcon />}
						modalProps={{
							isOpen: showConfirmationModal,
							onRequestClose: onDeleteConfirmationRequestClose,
						}}
						options={DefaultDeleteConfirmationOptions}
						title='Are you sure you want to delete this blog post?'
					/>
				</div>
			</CampaignBrowser>
		</>
	);
});
