import { EmailAddress, IOperationResult, IUser, ToastMessageType } from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import moment from 'moment-timezone';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IToasterComponentProps,
	IUserSessionComponentProps,
	ToasterViewModelKey,
	UserSessionViewModelKey,
} from '../../../../../models/AppState';
import { EventLogger, IEventLoggingComponentProps } from '../../../../../models/Logging';
import { baseStyleSheet } from '../../../../styles/styles';
import { TextInput } from '../../../TextInput';
import { ITimezoneData, TimezonePicker } from '../../../TimezonePicker';
import { SettingsGroup } from '../../SettingsGroup';
import { SettingsGroupIcon } from '../../SettingsGroupIcon';
import { PersonalSettingsProfilePictureSection } from './presentation';
import { styleSheet } from './styles';

interface IProps
	extends RouteComponentProps<any>,
		IToasterComponentProps,
		IEventLoggingComponentProps,
		IErrorMessageComponentProps,
		IUserSessionComponentProps {
	className?: string;
}

interface IState {
	changeTimezone?: boolean;
	firstName?: string;
	isLoading?: boolean;
	lastName?: string;
	phoneNumber?: string;
	primaryEmail?: EmailAddress;
	selectedTimeZoneOption?: string;
	showingTimeZoneOptions?: boolean;
}

class _ProfileSettings extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		const nextState = this.getResetStateWithProps(this.props);
		this.state = {
			isLoading: false,
			...(nextState || {}),
		};
	}

	public render() {
		const { firstName, isLoading, lastName, phoneNumber, primaryEmail, showingTimeZoneOptions } = this.state;
		const { userSession } = this.props;

		if (!firstName && !lastName) {
			return null;
		}

		const footer = (
			<div className={css(styleSheet.buttonContainer)}>
				<button className={css(baseStyleSheet.ctaButton)} onClick={this.saveClick}>
					Save
				</button>
				<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={this.onReset}>
					Cancel
				</button>
			</div>
		);

		return (
			<SettingsGroup
				className={showingTimeZoneOptions ? css(styleSheet.showingTzOptions) : undefined}
				footer={footer}
				icon={<SettingsGroupIcon type='UserProfile' />}
				name='Profile'
				description='Change your name, contact info, and time zone.'
			>
				<div className={isLoading ? 'lev-loading-spinner' : 'hide'} />
				<PersonalSettingsProfilePictureSection />
				<section className={isLoading ? 'hide' : 'settings-formContainer'}>
					<div className={css(baseStyleSheet.mb2)}>
						<label className={css(styleSheet.formFieldLabel)}>First Name</label>
						<TextInput
							className={css(styleSheet.inputField)}
							inputId='profileFirstName'
							onChange={this.updateFormFieldValue('firstName')}
							placeholder='First Name (required)'
							type='text'
							value={firstName || ''}
						/>
					</div>
					<div className={css(baseStyleSheet.mb2)}>
						<label className={css(styleSheet.formFieldLabel)}>Last Name</label>
						<TextInput
							className={css(styleSheet.inputField)}
							inputId='profileLastName'
							onChange={this.updateFormFieldValue('lastName')}
							placeholder='Last Name (required)'
							type='text'
							value={lastName || ''}
						/>
					</div>
					<div className={css(baseStyleSheet.mb2)}>
						<label className={css(styleSheet.formFieldLabel)}>Phone Number</label>
						<TextInput
							className={css(styleSheet.inputField)}
							inputId='profilePhoneNumber'
							onChange={this.updateFormFieldValue('phoneNumber')}
							placeholder='(optional)'
							type='text'
							value={phoneNumber || ''}
						/>
					</div>
					<div className={css(baseStyleSheet.mb2)}>
						<span className={css(styleSheet.formFieldLabel)}>Levitate Login</span>
						<div className={css(styleSheet.formValueNonEditable, styleSheet.emailField)}>
							{/* @ts-ignore */}
							<span>{primaryEmail.value}</span>
							<a
								className={css(baseStyleSheet.brandLink, styleSheet.connectEmailLink)}
								href='mailto:support@levitateapp.com'
							>
								Contact support to change
							</a>
						</div>
					</div>
					<div className={css(baseStyleSheet.mb2)}>
						<span className={css(styleSheet.formFieldLabel)}>&quot;Sending As&quot; Email</span>
						<div className={css(styleSheet.formValueNonEditable, styleSheet.emailField)}>
							<span>
								{userSession?.user?.lastTestEmail?.emailAddress || (
									<span className={css(styleSheet.noTestEmail)}>Not available</span>
								)}
							</span>
							<a
								className={css(baseStyleSheet.brandLink, styleSheet.connectEmailLink)}
								href={window.location.origin + '/#/user/connect-email'}
							>
								Connect to a different email
							</a>
						</div>
					</div>
					{this.renderTimezone()}
				</section>
			</SettingsGroup>
		);
	}

	private renderTimezone() {
		const { userSession } = this.props;
		const { changeTimezone, selectedTimeZoneOption } = this.state;

		const content = changeTimezone ? (
			<span>
				<TimezonePicker className={css(styleSheet.inputField)} onTimezoneSelected={this.onTimeZoneChanged} />
				<button
					className={css(baseStyleSheet.brandLink, styleSheet.changeTimezoneBtn)}
					onClick={this.onUserSystemDefaultClick}
				>
					Use system default
				</button>
				<button
					className={css(baseStyleSheet.brandLink, styleSheet.changeTimezoneBtn)}
					onClick={this.onCancelTimezoneClick}
				>
					Cancel
				</button>
			</span>
		) : (
			<span className={css(styleSheet.formValueNonEditable, styleSheet.emailField)}>
				{selectedTimeZoneOption || userSession?.user?.userPreferences?.timeZone || moment.tz.guess()}
				<button
					className={css(baseStyleSheet.brandLink, styleSheet.changeTimezoneBtn)}
					onClick={this.onChangeTimezoneClick}
				>
					Change
				</button>
			</span>
		);

		return (
			<div className={css(baseStyleSheet.mb2)}>
				<span className={css(styleSheet.formFieldLabel)}>Time Zone</span>
				{content}
			</div>
		);
	}

	private onUserSystemDefaultClick = () => {
		this.setState({
			changeTimezone: false,
			selectedTimeZoneOption: moment.tz.guess(),
		});
	};

	private onCancelTimezoneClick = () => {
		this.setState({
			changeTimezone: false,
			// @ts-ignore
			selectedTimeZoneOption: null,
		});
	};

	private onChangeTimezoneClick = () => {
		this.setState({ changeTimezone: true });
	};

	private onTimeZoneChanged = (tz: ITimezoneData) => {
		this.setState({
			changeTimezone: false,
			selectedTimeZoneOption: tz.name,
		});
	};

	private updateFormFieldValue = (propName: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
		const nextState: IState & Record<string, string> = {};
		nextState[propName] = e.target.value;
		this.setState(nextState);
	};

	private showToasterMessage = (message: string, type: ToastMessageType) => {
		if (this.props.toaster) {
			this.props.toaster.push({
				message,
				type,
			});
		}
	};

	private displayErrorMessages = (errorMessages: string[]) => {
		if (this.props.errorMessages) {
			this.props.errorMessages.push({
				messages: errorMessages,
			});
		}
	};

	private saveClick = () => {
		const { userSession } = this.props;
		// Validate required fields
		let isValid = true;
		const errorMessages: string[] = [];
		const firstName = (this.state.firstName || '').trim();
		const lastName = (this.state.lastName || '').trim();
		const phoneNumber = (this.state.phoneNumber || '').trim();
		if (!firstName) {
			errorMessages.push('First name is required');
			isValid = false;
		}
		if (!lastName) {
			errorMessages.push('Last name is required');
			isValid = false;
		}

		if (!isValid) {
			this.displayErrorMessages(errorMessages);
			return;
		}

		// Call a web service to update the current user
		const user: IUser = {
			// @ts-ignore
			...userSession.user,
			firstName,
			lastName,
			mobilePhone: phoneNumber ? phoneNumber : '',
			userPreferences: {
				// @ts-ignore
				// @ts-ignore
				...(userSession.user.userPreferences || {}),
				timeZone: this.state.selectedTimeZoneOption,
			},
		};

		// @ts-ignore
		userSession.webServiceHelper.callWebServiceWithOperationResults<IUser>(
			// @ts-ignore
			// @ts-ignore
			`user/${userSession.user.id}`, // URL
			'PUT', // Method
			user,
			this.saveSuccess,
			// @ts-ignore
			result => this.displayErrorMessages([result.systemMessage])
		);

		this.setState({ isLoading: true });
	};

	private saveSuccess = (operationResult: IOperationResult<IUser>) => {
		// @ts-ignore
		// @ts-ignore
		// @ts-ignore
		const { firstName, lastName, mobilePhone } = operationResult.value;
		// @ts-ignore
		this.props.userSession.updateUser({
			// @ts-ignore
			...this.props.userSession.user,
			firstName,
			lastName,
			mobilePhone,
		});
		this.setState({
			firstName,
			isLoading: false,
			lastName,
			phoneNumber: mobilePhone,
		});

		this.showToasterMessage('Changes were saved successfully!', 'successMessage');

		EventLogger.logEvent({
			action: 'ProfileInfo-Changed',
			category: 'Settings-Personal',
		});
	};

	private getResetStateWithProps = (props: IProps) => {
		// @ts-ignore
		const user = props.userSession.user;
		if (user) {
			// @ts-ignore
			let selectedTimeZoneOption: string = null;
			// @ts-ignore
			if (user.userPreferences.timeZone) {
				// @ts-ignore
				const timeZone = user.userPreferences.timeZone;
				selectedTimeZoneOption = timeZone;
			}
			const nextState: IState = {
				firstName: user.firstName,
				lastName: user.lastName,
				phoneNumber: user.mobilePhone,
				// @ts-ignore
				primaryEmail: user?.primaryEmail ? { ...user.primaryEmail } : null,
				selectedTimeZoneOption,
			};
			return nextState;
		}
		return null;
	};

	private onReset = () => {
		const nextState = this.getResetStateWithProps(this.props);
		if (nextState) {
			this.setState(nextState);
		}
	};
}

const ProfileSettingsAsObserver = observer(_ProfileSettings);
const ProfileSettingsWithRouter = withRouter(ProfileSettingsAsObserver);
export const ProfileSettings = inject(
	ToasterViewModelKey,
	ErrorMessagesViewModelKey,
	UserSessionViewModelKey
)(ProfileSettingsWithRouter);
