import { css } from 'aphrodite';
import * as React from 'react';
import { baseStyleSheet } from '../../../../styles/styles';
import { Dropdown } from '../../../Dropdown';
import { RadioButton } from '../../../RadioButton';
import { TextInput } from '../../../TextInput';
import { AmountFilterOption } from '../../../dataBoards/donations/models';
import { DisclosureIcon } from '../../../svgs/icons/DisclosureIcon';
import { styleSheet } from './styles';
import { styleSheet as dropdownStyles } from '../../DropdownComponent/styles';

interface IAmountFilterResult {
	minimumDonation: string;
	maximumDonation: string;
	amountFilterOption: AmountFilterOption;
}

export const AmountFilterDropdown = ({
	initialAmountFilterOption,
	initialMinimumDonation,
	initialMaximumDonation,
	onChange,
	label = 'Amount',
}: {
	initialAmountFilterOption: AmountFilterOption;
	initialMinimumDonation: string;
	initialMaximumDonation: string;
	onChange: (value: IAmountFilterResult) => void;
	label?: string;
}) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const [minimumDonation, setMinimumDonation] = React.useState(initialMinimumDonation);
	const [maximumDonation, setMaximumDonation] = React.useState(initialMaximumDonation);
	const [amountFilterOption, setAmountFilterOption] = React.useState(initialAmountFilterOption);
	const filterValid = !!minimumDonation || !!maximumDonation;
	const viewResults = () => {
		setIsOpen(false);
		onChange({
			minimumDonation,
			maximumDonation,
			amountFilterOption,
		});
	};

	return (
		<Dropdown
			anchor={
				<div className={css(dropdownStyles.dropdownAnchor)}>
					<span>{label}</span>
					<DisclosureIcon className={css(dropdownStyles.dropdownIcon)} />
				</div>
			}
			disabled={false}
			className={css(dropdownStyles.dropdownInline, isOpen ? dropdownStyles.dropdownInlineOpen : null)}
			contentClassName={css(dropdownStyles.dropdownMenu)}
			contentPositionY='bottom'
			contentInnerClassName={css(styleSheet.dropdownContentInnerOverrides)}
			isOpen={isOpen}
			onOpenChanged={setIsOpen}
			openOnClick={true}
		>
			<div className={css(styleSheet.amountDropdown)}>
				<div className={css(styleSheet.radios)}>
					<RadioButton
						checked={amountFilterOption === AmountFilterOption.Lifetime}
						id={AmountFilterOption.Lifetime}
						onChange={() => setAmountFilterOption(AmountFilterOption.Lifetime)}
					>
						<span>Lifetime</span>
					</RadioButton>

					<RadioButton
						checked={amountFilterOption === AmountFilterOption.OneTime}
						id={AmountFilterOption.OneTime}
						onChange={() => setAmountFilterOption(AmountFilterOption.OneTime)}
					>
						<span>One-Time</span>
					</RadioButton>
				</div>

				<div className={css(styleSheet.amountInputs)}>
					<label className={css(styleSheet.textInputContainer)}>
						<span>Minimum</span>
						<TextInput
							inputId='minimum-donation-amount-input'
							onChange={e => setMinimumDonation(e.target.value)}
							type='number'
							value={minimumDonation}
							className={css(styleSheet.textInputWrapper)}
							inputClassName={css(styleSheet.textInput)}
							placeholder='$'
						/>
					</label>

					<div className={css(styleSheet.separator)}>
						<span>&nbsp;</span>
						<span>-</span>
					</div>

					<label className={css(styleSheet.textInputContainer)}>
						<span>Maximum</span>
						<TextInput
							inputId='maximum-donation-amount-input'
							onChange={e => setMaximumDonation(e.target.value)}
							type='number'
							value={maximumDonation}
							className={css(styleSheet.textInputWrapper)}
							inputClassName={css(styleSheet.textInput)}
							placeholder='$'
						/>
					</label>
				</div>

				<button className={css(baseStyleSheet.ctaButton)} onClick={viewResults} disabled={!filterValid}>
					View Results
				</button>
			</div>
		</Dropdown>
	);
};
