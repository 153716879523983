import { brandPrimary, header } from '../../../../styles/colors';
import { StyleSheet } from 'aphrodite';

const height = 3;
export const styleSheet = StyleSheet.create({
	clear: {
		marginTop: '10',
	},
	container: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		maxWidth: 640,
		opacity: 1,
		transition: 'opacity 0.3s',
	},
	tab: {
		alignItems: 'center',
		borderBottom: `${height}px solid transparent`,
		cursor: 'pointer',
		display: 'flex',
		padding: '10px 10px 8px',
	},
	tabContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	tabIcon: {
		height: 15,
		width: 15,
	},
	tabSelected: {
		borderBottom: `${height}px solid ${brandPrimary}`,
	},
	tabText: {
		color: header,
		fontSize: 14,
		marginLeft: 6,
	},
	tabTextSelected: {
		color: brandPrimary,
	},
});
