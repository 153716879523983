import * as React from 'react';
import { GoogleBusinessReplyModal } from '.';
import { IGoogleBusinessReview } from '../../../../extViewmodels';
import {
	invalidateGetGoogleBusinessReviewsWithoutReplies,
	useGetGoogleBusinessReviewsWithoutReplies,
} from '../../../../queries';
interface IProps {
	isOpen: boolean;
	activeReview?: IGoogleBusinessReview;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GoogleBusinessReplyModalControlWrapper = ({ isOpen, setIsOpen, activeReview }: IProps) => {
	const [reviews, setReviews] = React.useState<IGoogleBusinessReview[]>([]);
	const [carouselIndex, setCarouselIndex] = React.useState(0);
	const {
		data: reviewData,
		status,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
	} = useGetGoogleBusinessReviewsWithoutReplies({
		enabled: isOpen,
		refetchOnWindowFocus: false,
	});
	const totalCount = reviewData?.pages?.[0]?.totalCount;
	const disableNext = !reviews?.length || carouselIndex + 1 === totalCount;
	const disablePrevious = !carouselIndex;
	const closeModal = () => {
		invalidateGetGoogleBusinessReviewsWithoutReplies();
		setIsOpen(false);
		setCarouselIndex(0);
	};
	const onSuccess = (reviewId: string) => {
		invalidateGetGoogleBusinessReviewsWithoutReplies();
		setReviews(reviews.filter(review => review.id !== reviewId));
		setCarouselIndex(0);
	};
	const carouselProgressText = `${carouselIndex + 1}/${totalCount}`;
	const goToNext = () => {
		if (disableNext) {
			return;
		}
		const shouldFetchNextPage = hasNextPage && !isFetchingNextPage && carouselIndex + 5 === reviews.length;
		if (shouldFetchNextPage) {
			fetchNextPage();
		}
		setCarouselIndex(carouselIndex + 1);
	};

	const goBack = () => {
		if (!disablePrevious) {
			setCarouselIndex(carouselIndex - 1);
		}
	};

	React.useEffect(() => {
		let recentReviews = reviewData?.pages
			? reviewData.pages
					.map(page => {
						return page.values;
					})
					.flat()
			: null;
		if (recentReviews) {
			// workaround to not forcing the "activeReview" in front if its been responded to
			if (activeReview && !activeReview.reply) {
				recentReviews = [activeReview, ...recentReviews.filter(review => review.id !== activeReview.id)];
			}
			setReviews(recentReviews);
		}
	}, [reviewData, activeReview]);
	return (
		<GoogleBusinessReplyModal
			activeReview={reviews[carouselIndex]}
			isLoadingReviews={status === 'loading'}
			isOpen={isOpen}
			goToNext={goToNext}
			goBack={goBack}
			disableNext={disableNext}
			disablePrevious={disablePrevious}
			closeModal={closeModal}
			onSuccess={onSuccess}
			remainingReviewsCount={totalCount}
			carouselProgressText={carouselProgressText}
		/>
	);
};
