import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useEventLogging } from '../../../../../models/Logging';
import {
	useErrorMessages,
	useTextMessaging,
	useToaster,
	useUserSession,
} from '../../../../../models/hooks/appStateHooks';
import { error } from '../../../../styles/colors';
import { QuickAddEntity } from '../../../QuickAddEntity';
import { AutoCompleteSearchField } from '../../../autocomplete/AutoCompleteSearchField';
import { EditableContact } from '../../../contacts/EditableContact';
import { RoundAddIcon } from '../../../svgs/icons/RoundAddIcon';
import { SearchIcon } from '../../../svgs/icons/SearchIcon';
import { RecipientList } from '../../RecipientList';
import { useTextMessageConversationsContext } from '../context';
import { styleSheet } from '../styles';
import { ContactSectionHeader } from './ContactSectionHeader';
import { getContactFields, verifyIsNotAlreadyAdded } from './utils';

interface IProps {
	setRedirectTo: (value: string) => void;
}

// @ts-ignore
export const ContactSection: React.FC<IProps> = observer(({ setRedirectTo }) => {
	const userSession = useUserSession();
	const quickAddEntityViewModelRef = React.useRef<Api.QuickAddEntityViewModel>(
		new Api.QuickAddEntityViewModel(userSession)
	);
	const textMessagingVM = useTextMessaging();

	const {
		recipients,
		selectedConversation,
		selectedRecipient,
		selectedRecipientIndex,
		setRecipients,
		setSelectedConversation,
		setSelectedRecipient,
		setSelectedRecipientIndex,
		setShowNewConversationSearch,
	} = useTextMessageConversationsContext();

	const errorMessages = useErrorMessages();
	const logger = useEventLogging();
	const toaster = useToaster();
	const history = useHistory();

	const alreadyAddedToasterError = () => {
		// @ts-ignore
		toaster.push({
			message: 'Recipient has already been added to the conversation.',
			type: 'errorMessage',
		});
	};

	let selectedRecipientPhoneValue: string | null | undefined = null;
	if (selectedRecipient) {
		selectedRecipientPhoneValue = selectedRecipient?.value ?? selectedRecipient?.metadata?.standard;
	} else if (!selectedConversation || selectedRecipientIndex !== null) {
		if (recipients?.length > 0 && selectedRecipientIndex !== null) {
			selectedRecipientPhoneValue =
				recipients[selectedRecipientIndex]?.value ?? recipients[selectedRecipientIndex]?.metadata?.standard;
		}
		if (!selectedRecipientPhoneValue && recipients?.length === 1) {
			selectedRecipientPhoneValue = recipients[0]?.value ?? recipients[0]?.metadata?.standard;
		}
	}
	const [prevSelectedRecipientPhoneValue, setPrevSelectedRecipientPhoneValue] = React.useState<
		string | null | undefined
	>(selectedRecipientPhoneValue);
	if (selectedRecipientPhoneValue !== prevSelectedRecipientPhoneValue) {
		setPrevSelectedRecipientPhoneValue(selectedRecipientPhoneValue);
		if (selectedRecipientPhoneValue && recipients?.length) {
			quickAddEntityViewModelRef.current.phoneNumbers = [
				{
					label: 'Mobile',
					value: selectedRecipientPhoneValue,
				},
			];
		}
	}
	const createConversation = async (phoneNumbers: string[]) => {
		try {
			setShowNewConversationSearch(false);
			// @ts-ignore
			const newConvo = await textMessagingVM.createConversation(phoneNumbers);
			// @ts-ignore
			setSelectedConversation(newConvo);

			const recipientsAsContacts = recipients.filter(r => r instanceof Api.TextRecipientViewModel);
			setRecipients(recipientsAsContacts);

			if (recipientsAsContacts.length) {
				for (const recipient of recipientsAsContacts) {
					if (recipient instanceof Api.TextRecipientViewModel) {
						// @ts-ignore
						await newConvo.updateRecipients(recipient.toJs());
					}
				}
			}

			return newConvo;
		} catch (err) {
			// @ts-ignore
			logger.logApiError('CreateTextConversation-Error', err);
			// @ts-ignore
			errorMessages.pushApiError(Api.asApiError(err));
		}
	};

	const onContactToMapToSelected = (contact: string | Api.IEntity | Api.IUser) => {
		const contactVm = new Api.TextRecipientViewModel(userSession, contact as Api.IContact);
		const phoneNumbers = contactVm.phoneNumbers || [];

		if ((selectedConversation && selectedConversation?.toNumbers?.length >= 1) || !selectedConversation) {
			const phoneNumberExists = selectedConversation
				? phoneNumbers.find(
						// @ts-ignore
						p => p.metadata.standard === selectedRecipientPhoneValue || p.value === selectedRecipientPhoneValue
					)
				: phoneNumbers.find(p => p.value === selectedRecipientPhoneValue);

			if (!phoneNumberExists) {
				phoneNumbers.push({
					label: 'Mobile',
					// @ts-ignore
					value: selectedRecipientPhoneValue,
				});
			}
		}

		if (selectedConversation) {
			// @ts-ignore
			contactVm
				.load()
				.then(() => {
					// @ts-ignore
					const contactModel: Api.ITextContact = {
						...contactVm.toJs(),
						phoneNumbers,
					};

					// @ts-ignore
					const promises: Promise<any>[] = [selectedConversation.updateRecipients(contactModel)];

					// @ts-ignore
					if (phoneNumbers.length !== contactVm.phoneNumbers.length) {
						// only need to update contact if a new phone number was found
						// @ts-ignore
						promises.push(contactVm.update(contactModel));
					}

					return Promise.all(promises);
				})
				.catch(err => {
					logger.logApiError('TextMessageUpdateRecipients-Error', err);
					// @ts-ignore
					errorMessages.pushApiError(err);
				});
		} else {
			// @ts-ignore
			contactVm
				.load()
				.then(() => {
					// @ts-ignore
					const contactModel: Api.ITextContact = {
						...contactVm.toJs(),
						phoneNumbers,
					};
					// @ts-ignore
					contactVm
						.update(contactModel)
						.then(() => {
							setSelectedRecipient(contactVm);
							const updatedRecipients = recipients.filter(r => r.value !== selectedRecipientPhoneValue);
							updatedRecipients.push(contactVm);
							setRecipients(updatedRecipients);
							setSelectedRecipientIndex(updatedRecipients.length - 1); // This is needed to adjust for index position
						})
						.catch(err => {
							logger.logApiError('TextMessageUpdateRecipients-Error', err);
							// @ts-ignore
							errorMessages.pushApiError(err);
						});
				})
				.catch(err => {
					logger.logApiError('TextMessageLoadTextContact-Error', err);
					// @ts-ignore
					errorMessages.pushApiError(err);
				});
		}
	};

	const onNewContactSaved = (entity: Api.IContact | Api.ICompany) => {
		if (selectedConversation) {
			selectedConversation.updateRecipients(entity as Api.ITextContact).catch(err => {
				logger.logApiError('TextMessageUpdateRecipients-Error', err);
				// @ts-ignore
				errorMessages.pushApiError(Api.asApiError(err));
			});
		} else {
			onContactToMapToSelected(entity); // This update is for when a new contact is saved and there is not yet a selected coversation -- updates selectedrecipients
		}
	};

	const onPhoneNumberAddedToRecipient = async () => {
		if (selectedRecipient.needsResolving) {
			const alreadyAdded = verifyIsNotAlreadyAdded(
				selectedRecipient,
				recipients as Api.TextRecipientViewModel[],
				alreadyAddedToasterError
			);

			if (!alreadyAdded) {
				const newRecipients = recipients.map(r => {
					if (r instanceof Api.TextRecipientViewModel) {
						return r.id === selectedRecipient.id ? selectedRecipient : r;
					}
					return r;
				});
				setRecipients(newRecipients);
			}
		}
	};

	const onRecipientItemClick = (recipient: Api.TextRecipientViewModel | Api.IPhoneNumber) => {
		setSelectedRecipientIndex(recipients.indexOf(recipient));
	};

	const onPhoneNumberEdited = async (originalPhoneNumber: Api.IPhoneNumber, newPhoneNumber: Api.IPhoneNumber) => {
		if (selectedConversation) {
			// check if edited number is used in the current conversation
			const toNumber = selectedConversation.toNumbers.find(
				// @ts-ignore
				toNum => toNum.number.standard === originalPhoneNumber.metadata.standard
			);

			if (toNumber) {
				// edited phone number is included in this conversation
				// need to create new conversation with all recipients, but now
				// use the new phone number.
				const newPhoneNumbers = selectedConversation.toNumbers.map(toNum => {
					// @ts-ignore
					return toNum.number.standard === originalPhoneNumber.metadata.standard
						? newPhoneNumber.value // must use value here...metadata.standard has not been updated during edit
						: toNum.number.standard;
				});

				// @ts-ignore
				const newConvo = await createConversation(newPhoneNumbers);
				if (newConvo) {
					// @ts-ignore
					history.push(`/texting/${textMessagingVM.id}/${newConvo.id}`);
				}
			}
		}
	};

	const onShowRelationshipAnalysisButtonClicked = () => {
		setRedirectTo(`/people/${selectedRecipient.id}?tabIndex=1`);
	};

	const renderContactError = (recipient: Api.TextRecipientViewModel) => {
		if (recipient?.phoneNumbers?.length === 0) {
			return (
				<div className={css(styleSheet.addPhoneNotificationContent)}>
					<RoundAddIcon fillColor={error} />
					Add a mobile number to start texting.
				</div>
			);
		}
		return null;
	};
	const showEditableContact = !!recipients?.length && !!selectedRecipient;
	if (showEditableContact && recipients.length) {
		return (
			<>
				<ContactSectionHeader setRedirectTo={setRedirectTo} />
				<EditableContact
					addPhoneNotification={{
						// @ts-ignore
						content: renderContactError(selectedRecipient),
						type: selectedRecipient?.needsResolving ? 'important' : 'standard',
					}}
					compactLayout={true}
					contact={selectedRecipient}
					disableCompanyLink={true}
					hideEditButton={true}
					showSendMessage={false}
					showHwcActionButton={false}
					linkToProfile={true}
					onPhoneNumberAdded={onPhoneNumberAddedToRecipient}
					onPhoneNumberEdited={onPhoneNumberEdited}
					onShowRelationshipAnalysisButtonClicked={onShowRelationshipAnalysisButtonClicked}
				/>
			</>
		);
	}
	if (recipients.length && selectedRecipientPhoneValue) {
		const contactFields = getContactFields(quickAddEntityViewModelRef.current);
		return (
			<>
				<ContactSectionHeader setRedirectTo={setRedirectTo} />
				<p className={css(styleSheet.updateRecipientsHeader)}>Number belongs to an existing contact?</p>
				<AutoCompleteSearchField
					dropdownClassName={css(styleSheet.noPhoneContactSearchAnchor)}
					clearSearchFieldAfterSelectingItem={false}
					contentPositionY='bottom'
					inputId='email-recipients-list-search-input'
					inputProps={{ placeholder: 'Search contacts' }}
					leftAccessory={<SearchIcon className={css(styleSheet.searchIcon)} />}
					onItemSelected={onContactToMapToSelected}
					resultsLimit={5}
					type={Api.ResourceAutoCompleteViewModelType.Contact}
				/>
				<QuickAddEntity
					className={css(styleSheet.quickAddEntity)}
					contactFields={contactFields}
					fieldsStyles={[styleSheet.quickAddEntityFields]}
					onSave={onNewContactSaved}
					quickAddEntityVm={quickAddEntityViewModelRef.current}
					title='Create a new contact'
				/>
			</>
		);
	}

	if (recipients.length > 1) {
		if (!selectedRecipient && !selectedRecipientPhoneValue) {
			return (
				<RecipientList
					onRecipientItemClick={onRecipientItemClick}
					recipients={recipients}
					toNumbers={selectedConversation?.toNumbers}
				/>
			);
		}
	}

	return null;
});
