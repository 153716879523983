import { IFileAttachment, IKeepInTouchReference, IOperationResultNoValue } from '@ViewModels';
import { css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IModalContext } from '../../../../models';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { Topics } from '../../../../models/LocalNotificationTopics';
import { EventLogger } from '../../../../models/Logging';
import { ActionItemViewModel, EmailMessageViewModel } from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import {
	INotificationServiceComponentProps,
	withNotificationService,
} from '../../LocalNotificationObserver/WithNotificationService';
import { SendMessageComposerDialog } from '../SendMessageComposerDialog';
import './styles.less';

interface IProps
	extends IUserSessionComponentProps,
		IModalContext<boolean>,
		IErrorMessageComponentProps,
		INotificationServiceComponentProps<IKeepInTouchReference> {
	actionItem: ActionItemViewModel;
	emailMessage?: EmailMessageViewModel<File>;
	onDismissedActionItem?(actionItem: ActionItemViewModel): void;
	toastOnSendSuccess?: boolean;
	onRequestClose?(): void;
}

interface IState {
	emailMessage?: EmailMessageViewModel<File>;
}

class _KitEmailComposer extends React.Component<IProps, IState> {
	public state: IState = {};

	public UNSAFE_componentWillMount() {
		const { actionItem, userSession, emailMessage: initialEmailMessage } = this.props;
		// @ts-ignore
		let emailMessage: EmailMessageViewModel<File> = null;
		if (initialEmailMessage) {
			emailMessage = initialEmailMessage;
		}
		if (!emailMessage && !!actionItem) {
			const suggested = actionItem.isSuggestedKeepInTouchActionItem;
			// @ts-ignore
			emailMessage = new EmailMessageViewModel<File>(userSession, suggested);
			emailMessage.contactsToAdd.add(actionItem.keepInTouchReference.contact);
			emailMessage.setContext({
				ref: {
					id: actionItem.id,
					type: 'ActionItem',
				},
			});
		}
		this.setState({
			emailMessage,
		});
	}

	public render() {
		const { toastOnSendSuccess, onRequestClose } = this.props;
		const { emailMessage } = this.state;

		if (!this.contact) {
			return null;
		}
		return (
			<SendMessageComposerDialog
				// @ts-ignore
				emailMessage={emailMessage}
				onRenderAdditionalFooterItems={this.onRenderAdditionalFooterItems}
				onRequestRemoveSavedEmailAttachment={this.onRequestRemoveSavedEmailAttachment}
				onSend={this.onSend}
				toastOnSendSuccess={toastOnSendSuccess}
				// @ts-ignore
				onRequestClose={onRequestClose}
			/>
		);
	}

	private onRequestRemoveSavedEmailAttachment = (savedAttachment: IFileAttachment) => {
		return new Promise(resolve => {
			const { emailMessage } = this.state;
			const currentAttachments = emailMessage?.savedAttachments;
			const filteredAttachments = currentAttachments?.filter(x => x.id !== savedAttachment.id);
			// @ts-ignore
			emailMessage?.setSavedAttachments(filteredAttachments);
			resolve(undefined);
		});
	};

	@computed
	private get contact() {
		const { actionItem } = this.props;
		if (actionItem) {
			return !!actionItem && !!actionItem.keepInTouchReference ? actionItem.keepInTouchReference.contact : null;
		}

		return null;
	}

	private onSend = (sendPromise: Promise<IOperationResultNoValue>) => {
		if (sendPromise) {
			sendPromise.then(() => {
				const keepInTouchReferenceModel = this.props.actionItem.keepInTouchReference.toJs();

				EventLogger.logInput('KitEmailComposer', 'Send', 'Click', {
					kitRef: {
						...keepInTouchReferenceModel,
						// @ts-ignore
						contact: { id: keepInTouchReferenceModel.contact.id },
					},
				});

				// right now we're just handling one case, keep in touch emails
				// send this before onRequestClose
				if (!!this.props.actionItem && !!this.props.actionItem.keepInTouchReference) {
					// @ts-ignore
					this.props.postNotification({
						info: keepInTouchReferenceModel,
						topic: Topics.SEND_KIT_EMAIL,
					});
				}

				// @ts-ignore
				this.props.onRequestClose();
			});
		}
	};

	private onRenderAdditionalFooterItems = () => {
		if (this.props.actionItem) {
			return (
				<React.Fragment>
					<button
						className={`${css(baseStyleSheet.ctaButtonReverse)} kit-email-composer-footer-cancel-button`}
						// @ts-ignore
						disabled={this.state.emailMessage.isSending}
						onClick={this.dismissActionitem}
					>
						Cancel
					</button>
				</React.Fragment>
			);
		}

		return null;
	};

	private dismissActionitem = () => {
		const { actionItem, onDismissedActionItem, onRequestClose } = this.props;
		if (actionItem) {
			EventLogger.logInput('KitEmailComposer', 'Dismiss', 'Click');
			if (onDismissedActionItem) {
				onDismissedActionItem(actionItem);
			}

			// @ts-ignore
			onRequestClose();
		}
	};
}

const KitEmailComposerAsObserver = observer(_KitEmailComposer);
const KitEmailComposereWithNotificationService = withNotificationService(KitEmailComposerAsObserver);
export const KitEmailComposer = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey
)(KitEmailComposereWithNotificationService);
