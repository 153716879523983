import { ISocialProfile } from '@ViewModels';
import { styleSheet } from './styles';
import { css } from 'aphrodite';

export const SocialProfiles = ({
	socialProfiles,
}: {
	socialProfiles?: ISocialProfile[];
}) => {
	if (!socialProfiles || socialProfiles.length === 0) {
		return null;
	}
	return (
		<div className={css(styleSheet.container)}>
			<span className={css(styleSheet.label)}>{`Social Profile${socialProfiles.length > 1 ? 's' : ''}:`}</span>

			{socialProfiles.map(socialProfile => {
				const key = `${socialProfile.typeId}Link` as keyof typeof styleSheet;
				const styles = css(styleSheet.link, styleSheet[key]);
				return (
					<a
						className={styles}
						href={socialProfile.url}
						key={`${socialProfile.typeId}-social-profile`}
						target='_blank'
						rel='noreferrer'
					/>
				);
			})}
		</div>
	);
};
