import { brandPrimary } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	birthdayCounterWrap: {
		display: 'flex',
		flexDirection: 'column-reverse',
	},
	button: {
		color: brandPrimary,
		display: 'flex',
		fontSize: 14,
		fontWeight: 400,
		lineHeight: '19px',
		marginBottom: 16,
		/* identical to box height */
	},
	card: {
		borderRadius: 12,
		boxSizing: 'border-box',
		flexDirection: 'column',
		maxWidth: 274,
		...CssSelectors.allChildren(
			{
				marginTop: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	counter: {
		color: '#C75DA6',
		fontSize: 26,
		letterSpacing: '1.18182px',
		lineHeight: '30px',
		margin: 0,
	},
	header: {
		alignItems: 'center',
		borderBottom: '1px solid #E8F3F9',
		display: 'flex',
		marginBottom: 15,
		paddingBottom: 15,
	},
	headerText: {
		color: '#858585',
		fontSize: 11,
		fontWeight: 400,
		lineHeight: '15px',
		margin: 0,
	},
	iconWrap: {
		alignItems: 'center',
		backgroundColor: '#C75DA6',
		borderRadius: 12,
		display: 'inline-flex',
		justifyContent: 'center',
		marginBottom: 7,
		marginRight: 17,
		minHeight: 40,
		minWidth: 40,
	},
});
