import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {},
	content: {
		boxSizing: 'border-box',
		paddingLeft: 20,
	},
	contentSocial: {
		boxSizing: 'border-box',
		paddingLeft: 0,
	},
	title: {
		color: '#858585',
		fontSize: 14,
		lineHeight: '22px',
		marginBottom: 24,
	},
});
