import { SvgIcon } from '../../icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const PeopleInWindowCircleGradiantGraphic: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon className={`people-in-window-graphic ${className || ''}`} height={120} width={120}>
			<circle cx='60' cy='60' r='60' fill='url(#Group3__a)' />
			<path
				d='M102.946 29.835v61.207c0 .46-.314.835-.701.835H18.7a.648.648 0 0 1-.495-.245.921.921 0 0 1-.205-.59V29.835c0-.221.073-.434.205-.59A.648.648 0 0 1 18.7 29h83.542c.186 0 .365.087.497.243.132.157.207.37.207.592Z'
				stroke='#fff'
			/>
			<path d='M101.587 43.994v46.257h-82s-.249-60 0-60h81.549c.249 0 .451 6.153.451 13.743Z' fill='#E8F3F9' />
			<path
				fill='#fff'
				stroke='#00AAE8'
				d='M26.068 49.987h19.924V73.36H26.068zm24.395 0h19.924V73.36H50.463zm44.32 23.373H74.859V49.987h19.924z'
			/>
			<path
				d='M35.139 61.866c-.06.351-.407.572-.734.71-.328.137-.694.251-.904.538-.283.39-.164.948.097 1.352.297.464.746.805 1.185 1.137.19.161.407.288.642.374.178.046.362.066.546.059a9.94 9.94 0 0 0 1.766-.159c.747-.136 1.546-.409 1.944-1.06.322-.532.302-1.197.27-1.82-.351-.03-.666-.223-.965-.409-.354-.22-.736-.476-.85-.876-.121-.42.071-.938-.216-1.266-.194-.222-.523-.248-.817-.256-.398-.01-.797-.016-1.194-.017-.274 0-.887-.115-1.112.068-.415.333.417 1.179.342 1.625Z'
				fill='#A0616A'
			/>
			<path
				opacity='.1'
				d='M35.139 61.866c-.06.351-.407.572-.734.71-.328.137-.694.251-.904.538-.283.39-.164.948.097 1.352.297.464.746.805 1.185 1.137.19.161.407.288.642.374.178.046.362.066.546.059a9.94 9.94 0 0 0 1.766-.159c.747-.136 1.546-.409 1.944-1.06.322-.532.302-1.197.27-1.82-.351-.03-.666-.223-.965-.409-.354-.22-.736-.476-.85-.876-.121-.42.071-.938-.216-1.266-.194-.222-.523-.248-.817-.256-.398-.01-.797-.016-1.194-.017-.274 0-.887-.115-1.112.068-.415.333.417 1.179.342 1.625Z'
				fill='#000'
			/>
			<ellipse cx='36.497' cy='58.809' rx='2.851' ry='2.856' fill='#A0616A' />
			<path
				d='M42.453 65.74a1.233 1.233 0 0 1-.16.488.832.832 0 0 1-.087.113c-.16.176-.39.292-.596.416-.583.355-1.237.818-1.346 1.494-.036.211-.085.42-.147.624-.136.463-.314.919-.418 1.382a3.151 3.151 0 0 0-.087.822c.004.086.012.172.024.258.092.656.428 1.245.824 1.805l.13.18.027.038h-9.305l.046-.177a7.133 7.133 0 0 0 .323-2.156c0-.292.064-.616.308-.78l.047-.178a5.576 5.576 0 0 0-.013-2.691l-.044-.164-.021-.073a7.995 7.995 0 0 1-.206-.79c-.058-.318-.07-.657-.24-.932-.169-.274-.463-.435-.724-.62a1.666 1.666 0 0 1-.344-.314.768.768 0 0 1-.184-.448.657.657 0 0 1 .01-.146c.049-.2.151-.384.297-.53.59-.652 1.494-.925 2.381-.937.137 0 .273 0 .408.011.158.003.314.038.458.104.089.045.167.11.228.189.087.11.153.234.195.368.035.107.06.217.078.329.054.348.066.718.248 1.018.332.543 1.087.642 1.711.525a3.859 3.859 0 0 0 2.646-1.948c.059-.122.123-.24.195-.354a.885.885 0 0 1 .132-.156 5.42 5.42 0 0 1 2.369 1.07c.216.165.406.362.561.585.292.462.358 1.038.276 1.576Z'
				fill='#F2F2F2'
			/>
			<path
				d='M42.745 71.622a2.74 2.74 0 0 1-.357.765 1.84 1.84 0 0 1-.412.528c-.345.272-.822.266-1.263.24h-.035l-.219-.012-1.255-.075a7.063 7.063 0 0 1-.991-.106 4.006 4.006 0 0 1-.26-.062c-.486-.13-.962-.326-1.464-.354-.146-.009-.292 0-.44 0h-.041c-.098 0-.195-.006-.292-.02-.16-.023-.316-.073-.478-.081-.416-.022-.783.229-1.138.457l-.097.06a4.37 4.37 0 0 1-.805.401h-1.685a.557.557 0 0 1-.155-.177l-.014-.026a.89.89 0 0 1 0-.751c.063-.135.146-.26.246-.37.11-.12.23-.232.356-.335.655-.548 1.408-1.01 2.246-1.169.059-.01.118-.02.176-.028a7.85 7.85 0 0 1 1.264-.042l.395.008 1.657.034h.046c.666.012 1.366.017 1.967-.249.077-.034.151-.072.224-.114a.422.422 0 0 0 .154-.135.466.466 0 0 0 .046-.253l-.006-.877-.012-1.69c0-.414-.002-.828-.007-1.243a.855.855 0 0 1 .238-.708c.242-.305.832-1.38 1.25-1.398a.336.336 0 0 1 .209.058c.355.238.357 1.139.366 1.443a34.21 34.21 0 0 0 .53 5.096c.072.388.15.795.056 1.185Z'
				fill='#A0616A'
			/>
			<path
				opacity='.1'
				d='M42.745 71.622a2.74 2.74 0 0 1-.357.765 1.84 1.84 0 0 1-.412.528c-.345.272-.822.266-1.263.24h-.035l-.219-.012-1.255-.075a7.063 7.063 0 0 1-.991-.106 4.006 4.006 0 0 1-.26-.062c-.486-.13-.962-.326-1.464-.354-.146-.009-.292 0-.44 0h-.041c-.098 0-.195-.006-.292-.02-.16-.023-.316-.073-.478-.081-.416-.022-.783.229-1.138.457l-.097.06a4.37 4.37 0 0 1-.805.401h-1.685a.557.557 0 0 1-.155-.177l-.014-.026a.89.89 0 0 1 0-.751c.063-.135.146-.26.246-.37.11-.12.23-.232.356-.335.655-.548 1.408-1.01 2.246-1.169.059-.01.118-.02.176-.028a7.85 7.85 0 0 1 1.264-.042l.395.008 1.657.034h.046c.666.012 1.366.017 1.967-.249.077-.034.151-.072.224-.114a.422.422 0 0 0 .154-.135.466.466 0 0 0 .046-.253l-.006-.877-.012-1.69c0-.414-.002-.828-.007-1.243a.855.855 0 0 1 .238-.708c.242-.305.832-1.38 1.25-1.398a.336.336 0 0 1 .209.058c.355.238.357 1.139.366 1.443a34.21 34.21 0 0 0 .53 5.096c.072.388.15.795.056 1.185Z'
				fill='#000'
			/>
			<path
				d='M34.619 54.28c-.247.036-.52.103-.654.313-.105.164-.098.371-.133.562-.059.306-.23.58-.479.766-.204.151-.45.236-.662.377-.21.142-.397.372-.369.625.024.221.2.39.283.598.153.38-.027.804-.194 1.178-.168.374-.325.814-.133 1.175.203.381.727.525.874.93.184.486-.291 1.005-.23 1.524.052.427.472.732.897.794.203.03.428.009.583-.127.155-.135.203-.356.21-.561.026-.793-.424-1.52-.555-2.304-.043-.258-.049-.534.071-.767.076-.147.194-.268.26-.42.132-.32-.017-.704.124-1.018a2.66 2.66 0 0 1 .275-.39c.09-.131.15-.306.08-.449.25.16.451.389.578.658.184-.037.179-.432.359-.376.05.017.08.07.112.112a.508.508 0 0 0 .539.143 1.06 1.06 0 0 0 .474-.342l.307.382a.35.35 0 0 1 .337-.215.81.81 0 0 1 .399.138c.583.35 1.02 1.065.797 1.708-.097.275-.292.5-.422.76-.13.261-.163.612.045.815.043.042.097.075.134.122.152.18.056.455.102.682.08.418.572.584.986.682.292.066.639.116.843-.098.224-.233.124-.616-.011-.91-.136-.294-.292-.634-.147-.922.145-.289.57-.39.722-.691.194-.383-.149-.802-.286-1.21-.188-.557.022-1.182-.132-1.752a2.447 2.447 0 0 0-.43-.791c-.454-.612-.998-1.174-1.672-1.528-.638-.335-1.28-.39-1.983-.305-.63.08-1.267.037-1.9.131Z'
				fill='#3C354C'
			/>
			<path
				d='M40.159 67.223a.235.235 0 0 1-.112-.155 43.59 43.59 0 0 1-.606-1.836.715.715 0 0 1-.046-.341.664.664 0 0 1 .26-.356c.32-.264.679-.475 1.064-.625.28-.11.694-.306.972-.128.346.221-.023.78-.15 1.072-.17.4-.365.79-.582 1.168-.067.117-.712 1.236-.8 1.201Z'
				fill='#F2F2F2'
			/>
			<path
				d='M40.714 73.156a.583.583 0 0 1-.17.203h-1.69a4.376 4.376 0 0 1-.805-.4l-.097-.061c-.355-.228-.723-.478-1.139-.457-.162.008-.318.058-.478.082-.096.013-.194.02-.291.02h-.041c-.147 0-.292-.008-.44 0-.503.027-.977.222-1.464.353a4.073 4.073 0 0 1-.26.062 7.064 7.064 0 0 1-.992.106l-1.473.088h-.035c-.44.025-.918.031-1.264-.24a1.842 1.842 0 0 1-.412-.529 2.749 2.749 0 0 1-.357-.765c-.097-.39-.015-.797.056-1.191.302-1.684.479-3.387.528-5.097.008-.305.01-1.209.368-1.443a.31.31 0 0 1 .135-.053.347.347 0 0 1 .072 0c.418.017 1.008 1.094 1.25 1.398a.855.855 0 0 1 .238.708c0 .4-.003.8-.008 1.202v.055l-.016 2.558a.465.465 0 0 0 .045.253.28.28 0 0 0 .05.062.642.642 0 0 0 .105.073c.646.381 1.44.377 2.19.363h.046l1.657-.034.396-.008c.421-.02.844-.006 1.263.042.059.008.118.017.176.028a4.58 4.58 0 0 1 1.779.804c.162.115.32.237.472.365.24.194.475.42.602.705a.89.89 0 0 1 .004.748Z'
				fill='#A0616A'
			/>
			<path
				d='M32 67.223a.239.239 0 0 0 .111-.155c.216-.608.418-1.22.607-1.836a.728.728 0 0 0 .044-.341.664.664 0 0 0-.26-.356 3.86 3.86 0 0 0-1.063-.625c-.28-.11-.692-.306-.972-.128-.346.221.022.78.15 1.072.17.4.365.79.583 1.168.066.117.711 1.236.8 1.201Z'
				fill='#F2F2F2'
			/>
			<path
				d='M84.585 56.169c.578-.141 1.027.818 1.61.704.32-.063.566-.442.89-.414.368.032.526.536.714.906.328.639.99 1.025 1.627.948.2-.265.163-.672.06-1.003-.102-.331-.257-.652-.26-1.005-.006-.466.25-.983.019-1.363-.178-.292-.542-.316-.81-.496-.208-.14-.355-.375-.53-.567a1.753 1.753 0 0 0-1.082-.57c-.344-.038-.69.035-1.03.11l-1.59.344a.443.443 0 0 0-.248.114.86.86 0 0 0-.128.326c-.15.495-.623.727-1.009 1.002-.386.274-.752.818-.54 1.28.121.262.405.424.435.718.027.263-.163.478-.318.673-.656.824.815 1.252 1.167.693.391-.615.196-2.198 1.023-2.4Z'
				fill='#3C354C'
			/>
			<path
				d='M83.744 61.35c-.138.18-.317.33-.428.53-.14.284-.162.611-.064.912.1.297.239.578.415.837.201.354.446.682.729.974.284.296.662.484 1.069.532.432.032.85-.147 1.242-.335a1.36 1.36 0 0 0 .448-.292c.094-.12.167-.253.218-.396l.337-.81a2.22 2.22 0 0 0 .194-.621c.035-.336-.081-.668-.194-.987l-.617-1.7a.575.575 0 0 0-.148-.259.557.557 0 0 0-.227-.097 6.594 6.594 0 0 0-1.193-.195c-.3-.02-.979-.127-1.24.043-.24.157-.14.556-.168.814a2.064 2.064 0 0 1-.373 1.05Z'
				fill='#A0616A'
			/>
			<path
				opacity='.1'
				d='M83.744 61.35c-.138.18-.317.33-.428.53-.14.284-.162.611-.064.912.1.297.239.578.415.837.201.354.446.682.729.974.284.296.662.484 1.069.532.432.032.85-.147 1.242-.335a1.36 1.36 0 0 0 .448-.292c.094-.12.167-.253.218-.396l.337-.81a2.22 2.22 0 0 0 .194-.621c.035-.336-.081-.668-.194-.987l-.617-1.7a.575.575 0 0 0-.148-.259.557.557 0 0 0-.227-.097 6.594 6.594 0 0 0-1.193-.195c-.3-.02-.979-.127-1.24.043-.24.157-.14.556-.168.814a2.064 2.064 0 0 1-.373 1.05Z'
				fill='#000'
			/>
			<ellipse cx='85.764' cy='58.253' rx='2.692' ry='2.698' fill='#A0616A' />
			<path
				d='M91.73 64.989c.005.744-.28 1.46-.574 2.142-.294.682-.668 1.355-.956 2.055a46.86 46.86 0 0 0-1.382 4.076l-.028.097h-8.35c.382-1.46.708-2.921-.205-3.93a2.075 2.075 0 0 1-.084-.097c-.597-.721-.866-1.706-1.576-2.32-.163-.14-.353-.265-.454-.457a.833.833 0 0 1-.086-.38c0-.116.007-.232.022-.347.07-.633.16-1.297.541-1.807.174-.223.382-.417.617-.575a4.92 4.92 0 0 1 1.721-.77c.696-.16 1.464-.183 2.032-.618.12-.11.25-.209.389-.292l.038-.017a.404.404 0 0 1 .42.045.578.578 0 0 1 .14.292c.102.378.206.756.31 1.133.034.187.115.362.234.51.275.287.8.253 1.014.585.129.202.466-.033.613-.22a8.37 8.37 0 0 0 .918-1.455c.086-.204.2-.396.34-.568.1-.11.23-.19.375-.228a.504.504 0 0 1 .216-.005c.21.042.364.216.507.376a11.375 11.375 0 0 0 2.987 2.38c.09.038.165.101.219.182a.375.375 0 0 1 .037.157c.004.02.005.038.005.056Zm-6.854-8.958c.45-.097.799.55 1.251.474.25-.041.442-.293.693-.278.286.022.409.36.556.608.266.435.755.682 1.263.638.156-.178.126-.451.047-.674-.08-.223-.202-.44-.205-.675 0-.315.195-.661.016-.918-.138-.194-.42-.212-.629-.333-.162-.097-.276-.252-.413-.38a1.436 1.436 0 0 0-.84-.383 2.854 2.854 0 0 0-.801.073l-1.237.232a.365.365 0 0 0-.194.076.536.536 0 0 0-.097.219c-.118.333-.486.487-.785.673-.298.186-.583.55-.42.86.098.177.314.285.338.488.021.176-.126.321-.247.451-.51.554.634.842.909.466.304-.417.151-1.48.795-1.617Z'
				fill='#3C354C'
			/>
			<path
				d='M92.993 73.36H90.72c.037-.934-.342-1.881-.389-2.833-.017-.357.013-.72-.06-1.071a2.329 2.329 0 0 0-.072-.27c-.057-.18-.127-.357-.182-.538-.18-.605-.174-1.248-.165-1.879.01-.697.021-1.393.033-2.088 0-.18-.053-.448.11-.524.371-.171.756.169 1.096.39.204.136.438.243.636.39.118.08.22.183.302.301.096.163.164.34.201.525.276 1.054.515 2.116.72 3.188.097.465.162.937.194 1.412.06.997-.148 2.003-.15 2.996Zm-14.12-1.086c-.097.238-.202.487-.187.746.008.117.035.23.077.34h-2.06a7.624 7.624 0 0 1-.232-1.072c-.098-.705-.044-1.423.047-2.13.061-.549.181-1.09.357-1.614.239-.67.617-1.278.939-1.91.076-.146.145-.312.225-.458.173-.32.397-.556.842-.34 1.107.534 1.582 2.407 1.274 3.497v.01a5.4 5.4 0 0 1-.328.804 51.118 51.118 0 0 0-.954 2.127Z'
				fill='#3C354C'
			/>
			<path
				d='M60.832 54.805c.22.08.405.238.52.443.089.184.086.399.146.595.128.411.51.681.749 1.041.322.487.36 1.108.338 1.692-.023.584-.097 1.18.048 1.748.07.277.187.54.235.821.057.341.01.697.097 1.032.077.305.256.572.433.831l.756 1.107a8.722 8.722 0 0 1-.998-.3c.1.317.288.6.54.817l-.546-.663a1.569 1.569 0 0 0-.408-.39c-.2-.113-.437-.13-.663-.177-.432-.088-.866-.31-1.078-.7-.282-.52-.097-1.174-.259-1.745-.116-.417-.401-.76-.598-1.144-.446-.87-.419-1.895-.559-2.863-.06-.411-.151-.82-.153-1.235 0-.307.03-.89.35-1.046.279-.131.792.026 1.05.136Z'
				fill='#3F3D56'
			/>
			<path
				d='M61.166 61.771c.046.509.151 1.109.614 1.322.261.12.566.076.85.123.535.086.971.5 1.224.983.253.484.334 1.033.389 1.575.073.677.097 1.412-.275 1.983-.3.462-.82.744-1.351.887a3.763 3.763 0 0 1-3.146-.585c-.44-.32-.804-.733-1.158-1.146-.77-.899-1.546-1.884-1.705-3.059-.018-.133-.016-.292.098-.374a.407.407 0 0 1 .247-.05l.74.014c.187 0 .399 0 .526-.14.127-.142.12-.32.115-.488-.02-.714-.038-1.43-.057-2.147a.31.31 0 0 1 .049-.217.286.286 0 0 1 .17-.073c.407-.07.833 0 1.231-.111.38-.105.972-.54 1.253-.093.235.375.15 1.164.186 1.596Z'
				fill='#FFC1C7'
			/>
			<path
				opacity='.1'
				d='M61.166 61.771c.046.509.151 1.109.614 1.322.261.12.566.076.85.123.535.086.971.5 1.224.983.253.484.334 1.033.389 1.575.073.677.097 1.412-.275 1.983-.3.462-.82.744-1.351.887a3.763 3.763 0 0 1-3.146-.585c-.44-.32-.804-.733-1.158-1.146-.77-.899-1.546-1.884-1.705-3.059-.018-.133-.016-.292.098-.374a.407.407 0 0 1 .247-.05l.74.014c.187 0 .399 0 .526-.14.127-.142.12-.32.115-.488-.02-.714-.038-1.43-.057-2.147a.31.31 0 0 1 .049-.217.286.286 0 0 1 .17-.073c.407-.07.833 0 1.231-.111.38-.105.972-.54 1.253-.093.235.375.15 1.164.186 1.596Z'
				fill='#000'
			/>
			<ellipse cx='59.306' cy='58.803' rx='2.823' ry='2.829' fill='#FFC1C7' />
			<path
				d='M65.59 70.918c-.02.115-.046.23-.08.342a8.23 8.23 0 0 1-.312.768 3.334 3.334 0 0 0-.196.57 4.506 4.506 0 0 0-.064.305 6.523 6.523 0 0 0-.061.456h-7.368c.031-.373.017-.75-.042-1.12a7.383 7.383 0 0 0-.455-1.485c-.47-1.205-.995-2.434-1.283-3.692v-.005a7.351 7.351 0 0 1-.179-2.496l.02-.149a3.81 3.81 0 0 1 .07-.376c.072-.352.233-.68.47-.952.33-.356.89-.533 1.318-.305.258.138.419.381.547.65.082.172.15.353.222.526a5.358 5.358 0 0 0 1.4 1.94c.098.085.184.157.28.231.312.241.688.546 1.095.556.237.007.478 0 .721 0 .214 0 .43-.008.643 0 .456.01.972-.12 1.193-.52.15-.274.125-.608.097-.92l-.068-.731v-.038l-.049-.527-.024-.255-.018-.19a7.44 7.44 0 0 1 .245-.023c.369-.029.745-.022 1.061.158.302.17.487.464.605.796.079.237.13.482.152.73.109 1.106-.067 2.22-.007 3.329.039.802.208 1.635.067 2.427Z'
				fill='#00528C'
			/>
			<path
				d='M67.272 73.36h-2.228a19.86 19.86 0 0 1-.042-.761 24.49 24.49 0 0 1-.005-1.94c.439.247.93.388 1.434.409.204.228.368.49.486.772a3.74 3.74 0 0 1 .166.485c.091.339.155.685.19 1.034Z'
				fill='#FFC1C7'
			/>
			<path
				d='M67.077 71.837c-.843.008-1.682.12-2.498.331-.108-.533-.055-1.085 0-1.626l.36-3.721c.027-.276-.024-2.806.51-2.359.193.165.442 1.644.504 1.882.092.359.165.722.23 1.086.133.748.223 1.502.353 2.251.037.216.349 2.158.541 2.156Z'
				fill='#046CB6'
			/>
			<path
				d='M60.381 55.206a.487.487 0 0 0-.058-.255.421.421 0 0 0-.302-.16c-.407-.063-.855.098-1.223-.085-.194-.097-.341-.292-.535-.4-.46-.26-1.038 0-1.445.337a4.225 4.225 0 0 0-1.353 2.066c-.181.625-.254 1.357-.771 1.753-.214.164-.486.254-.647.472-.12.168-.148.383-.17.585-.054.475-.098.962.026 1.424.097.343.277.655.388.993a2.64 2.64 0 0 1-.717 2.744.852.852 0 0 0 .632.11.543.543 0 0 1-.092.28c.661-.022 1.236-.666 1.882-.525.494.107.769.617 1.137.964.475.447 1.211.634 1.807.37.596-.266.957-1.02.705-1.621-.292-.682-1.175-.956-1.483-1.633-.239-.525-.062-1.136-.113-1.71-.044-.492-.256-.951-.389-1.427-.133-.477-.175-1.021.097-1.436.286-.44.833-.611 1.33-.779 1.121-.362 1.337-.932 1.294-2.067Z'
				fill='#3F3D56'
			/>
			<path
				d='M59.688 65.376c.08-.389.16-.815.018-1.192-.264-.703-1.128-.922-1.726-1.374a2.338 2.338 0 0 1-.768-1.026c-.109-.285-.123-.682.144-.826a.647.647 0 0 1 .333-.058c.931.021 1.785.529 2.499 1.128.415.35.81.75 1.017 1.252.146.355.194.743.236 1.125l.146 1.266c.096.725.152 1.456.168 2.188 0 .42-.026.839-.079 1.255-.037.292-.097.6-.265.843-.323.45-.943.552-1.497.567-.21.013-.42 0-.625-.04-.92-.2-1.618-1.491-.922-2.29.291-.338.74-.347.908-.811.226-.616.28-1.36.413-2.007Z'
				fill='#FFC1C7'
			/>
			<path
				d='m58.94 65.599.726 1.189a.545.545 0 0 1 .089.205c.02.155-.098.292-.187.418-.626.857-.467 2.035-.278 3.08a5.588 5.588 0 0 1-3.835-4.525c-.092-.622-.24-1.647.312-2.052 1.327-.976 2.55.666 3.173 1.685Z'
				fill='#046CB6'
			/>
			<path
				d='M62.63 63.216c-.284-.047-.584 0-.85-.123-.463-.213-.569-.813-.614-1.322 0-.046-.007-.097-.009-.153a4.23 4.23 0 0 1 .538-.468 2.64 2.64 0 0 1 .549-.242l.513-.182c.047.137.084.277.11.42.058.34.01.696.098 1.031.077.305.256.572.433.831l.756 1.107a8.307 8.307 0 0 1-.408-.108c-.261-.397-.653-.717-1.117-.791Z'
				fill='#3F3D56'
			/>
			<ellipse cx='20.555' cy='31.974' rx='.486' ry='.487' fill='#00AAE8' />
			<ellipse cx='21.916' cy='31.974' rx='.486' ry='.487' fill='#00AAE8' />
			<ellipse cx='23.276' cy='31.974' rx='.486' ry='.487' fill='#00AAE8' />
			<path fill='#00AAE8' d='M47.547 36.158H73.4v1.363H47.547z' />
			<defs>
				<linearGradient id='Group3__a' x1='120' y1='120' x2='120' y2='0' gradientUnits='userSpaceOnUse'>
					<stop stopColor='#C30DAF' />
					<stop offset='1' stopColor='#FF7892' />
				</linearGradient>
			</defs>
		</SvgIcon>
	);
};
