import { navigation } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';
import twitterImageUrl from '../../../assets/twitter@2x.png';
import facebookImageUrl from '../../../assets/facebook@2x.png';
import linkedinImageUrl from '../../../assets/linkedin@2x.png';

export const styleSheet = StyleSheet.create({
	container: {
		display: 'flex',
		alignItems: 'center',
	},
	label: {
		marginRight: 8,
		fontSize: 14,
		color: navigation,
	},
	link: {
		backgroundPosition: 'left center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 42,
		borderRadius: '50%',
		display: 'inline-block',
		height: 42,
		width: 42,
	},
	twitterLink: {
		backgroundImage: `url(${twitterImageUrl})`,
	},
	facebookLink: {
		backgroundImage: `url(${facebookImageUrl})`,
	},
	linkedinLink: {
		backgroundImage: `url(${linkedinImageUrl})`,
	},
});
