import { css } from 'aphrodite';
import { TextArea } from '../../../components/TextArea';
import { bs } from '../../../styles/styles';

interface Props {
	inputProps: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
}

export function HwcSettingsSignatureInputField({ inputProps }: Props) {
	return (
		<div className={css(bs.flex, bs.itemsStart)}>
			<label className={css(bs.inlineBlock, bs.textHeader, bs.textSm, bs.w48)}>Signature</label>
			<div>
				<label className={css(bs.textNavigation, bs.inlineBlock, bs.textXs, bs.mb2)} htmlFor='hwc-signature'>
					<span className={css(bs.uppercase)}>Add Signature</span>
					&nbsp;(as you would like it displayed)
				</label>
				<TextArea className={css(bs.w80)} inputId='hwc-signature' {...inputProps} />
			</div>
		</div>
	);
}
