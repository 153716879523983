import { CssSelectors } from '../../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	arrowRight: {
		display: 'inline-block',
		left: 'calc(100% + 8px)',
		top: '50%',
		transform: 'translateY(-50%)',
		transition: 'left 300ms ease-in-out',
		width: 8,
	},
	arrowRightHover: {
		left: 'calc(100% + 18px)',
	},
	button: {
		':focus': {
			borderBottom: '2px dashed #28538c',
		},
		borderBottom: '2px dashed transparent',
		color: 'rgba(13,15,17,1)',
		display: 'flex',
		flexDirection: 'row',
		fontSize: 16,
		fontStyle: 'normal',
		fontWeight: 600,
		letterSpacing: 0,
		marginBottom: 4,
		textAlign: 'left',
	},
	centeredButton: {
		alignItems: 'center',
	},
	childrenWrap: {
		alignItems: 'flex-end',
		bottom: 3,
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		height: 'auto',
		justifyContent: 'flex-start',
		left: 60,
		maxWidth: '150px',
		top: 'auto',
		width: '100%',
		zIndex: 2,
	},
	childrenWrapAdjusted: {
		bottom: -26,
	},
	counter: {
		backgroundColor: '#FF6175',
		borderRadius: 12,
		color: '#fff',
		fontSize: 14,
		fontWeight: 700,
		lineHeight: '19px',
		padding: '0 6px',
		position: 'absolute',
		right: -6,
		top: -10,
	},
	figure: {
		alignItems: 'center',
		borderRadius: 8,
		display: 'inline-flex',
		height: 49,
		justifyContent: 'center',
		marginRight: 12,
		position: 'relative',
		width: 49,
	},
	heading: {
		margin: '0 0 2px',
		fontWeight: 600,
		width: 162,
	},
	headingWrap: {
		display: 'flex',
		flexDirection: 'row',
	},
	list: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		...CssSelectors.allChildren(
			{
				display: 'block',
			},
			'li'
		),
	},
	listItem: {
		transform: 'translateY(13px)',
	},
	listWrap: {
		position: 'relative',
	},
	mainWrapper: {
		height: 49,
		marginBottom: 20,
	},
	mainWrapperWChildren: {
		height: 49,
		marginBottom: 26,
	},
	mainWrapperWLotsOfChildren: {
		height: 80,
		marginBottom: 30,
		marginTop: -13,
	},
	positionAbsolute: {
		height: '100%',
		left: 0,
		position: 'absolute',
		top: 0,
		width: '100%',
	},
	positionRelative: {
		position: 'relative',
	},
	smallButton: {
		':focus': {
			border: '1px dashed #28538c',
		},
		backgroundColor: '#fff',
		border: '1px dashed transparent',
		borderRadius: '3px',
		color: '#D3DBDE',
		margin: '0 4px 4px 0',
		padding: '2px 5px 2px 7px',
	},
	smallButtonSelected: {
		color: '#0D0F11',
	},
	subHeading: {
		color: '#858585',
		fontSize: '12px',
		fontWeight: 600,
		lineHeight: '16px',
		margin: 0,
	},
});
