import { render as DOMRender } from 'react-dom';
import { AppHashHistory } from '../models';
import { BugsnagEventLogger, EventLogger } from '../models/Logging';
import { polyfillAsync } from '../models/UiUtils';
import { App } from './containers/App';
import AppRouter from './containers/AppRouter';
import './styles/main.less';

const envName = process.env.BUGSNAG_ENV as BUGSNAG_ENV;
const bugsnagEventLogger = new BugsnagEventLogger(envName);
const ErrorBoundary = bugsnagEventLogger.getErrorBoundary();
EventLogger.addLogger(bugsnagEventLogger);

document.addEventListener('DOMContentLoaded', async () => {
	await polyfillAsync();
	if (process.env.MOCKS === 'true') {
		const { worker } = await import('../mocks');
		await worker.start();
	}
	const rootElement = document.getElementById('react-root');
	DOMRender(
		<ErrorBoundary>
			<App>
				<AppRouter history={AppHashHistory} />
			</App>
		</ErrorBoundary>,
		rootElement
	);
});
