import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	background: {
		borderRadius: 12,
		boxShadow: '0px 1px 2px rgba(167, 171, 173, 0.21)',
		boxSizing: 'border-box',
	},
	card: {
		color: 'transparent',
		fontSize: 0,
		position: 'relative',
	},
});
