import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { brandPrimaryHover } from '../../../../styles/colors';
import { baseStyleSheet } from '../../../../styles/styles';
import { FabContext } from '../../../FabContext';
import { DisclosureIcon } from '../../../svgs/icons/DisclosureIcon';
import { useTextMessageConversationsContext } from '../context';
import { styleSheet } from '../styles';

interface IProps {
	setRedirectTo: (value: string) => void;
}

export const ContactSectionHeader: React.FC<IProps> = observer(({ setRedirectTo }) => {
	const {
		recipients,
		selectedConversation,
		selectedRecipient,
		setRecipients,
		setSelectedRecipient,
		setSelectedRecipientIndex,
	} = useTextMessageConversationsContext();

	const cancelLinkingConvoToRecipientWithNoNumber = () => {
		// @ts-ignore
		setSelectedRecipientIndex(null);
		setRecipients([]);
	};

	const goToProfile = () => setRedirectTo(`/people/${selectedRecipient?.id}`);

	const onBackToRecipientListClick = () => {
		// @ts-ignore
		setSelectedRecipientIndex(null);
		// @ts-ignore
		setSelectedRecipient(null);
	};

	const showEditableContact = !!recipients?.length && !!selectedRecipient;

	return (
		<div className={css(styleSheet.contactColHeaderLinks)}>
			<div>
				{(!selectedRecipient?.needsResolving || selectedConversation) && recipients.length > 1 ? (
					<button className={css(baseStyleSheet.brandLink, styleSheet.button)} onClick={onBackToRecipientListClick}>
						<DisclosureIcon type='chevron' className={css(styleSheet.backChevron)} fillColor={brandPrimaryHover} />
						Back
					</button>
				) : !selectedConversation?.toNumbers?.length && recipients.length > 1 ? (
					<button
						className={css(baseStyleSheet.brandLink, styleSheet.button)}
						onClick={cancelLinkingConvoToRecipientWithNoNumber}
					>
						Cancel
					</button>
				) : null}
			</div>
			{showEditableContact && (
				<button className={css(baseStyleSheet.brandLink, styleSheet.button)} onClick={goToProfile}>
					Go to profile
				</button>
			)}
			<FabContext appearance={{ hidden: true }} />
		</div>
	);
});
