import { BreakPoints } from '../../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	button: {
		bottom: 20,
		boxSizing: 'border-box',
		left: '50%',
		position: 'absolute',
		transform: 'translate(-50%, -50%)',
		width: 260,
		zIndex: 1,
		[BreakPoints.tabletCompactWidth]: {
			bottom: 10,
			width: 200,
		},
	},
	container: {},
	graphic: {
		height: 'auto',
		width: '100%',
	},
	wrapper: {
		maxWidth: 620,
		position: 'relative',
	},
});
