import React from 'react';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { Link } from 'react-router-dom';
import { useToaster, useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	useAccountIntegrationDonorPerfectDeleteApiKey,
	useAccountIntegrationDonorPerfectApiKey,
} from '../../../../queries';
import donorPerfectLogo from '../../../assets/logo_donorperfect.png';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { TextInput } from '../../../components/TextInput';
import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet as integrationsStyleSheet } from '../styles';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const DonorPerfect = (props: IProps) => {
	const { className, styles } = props;
	const [apiKey, setApiKey] = React.useState<string>('');
	const toaster = useToaster();
	const userSession = useUserSession();

	const donorPerfectIntegration = userSession.account.integrations?.donorPerfect ?? {};

	const saveApiKeyMutation = useAccountIntegrationDonorPerfectApiKey({
		onError: () => {
			toaster.push({
				message: `An error occurred while saving the API key.  If the problem persists, please contact support.`,
				type: 'errorMessage',
			});
		},
		onSuccess: () => {
			donorPerfectIntegration.isConnected = true;
			userSession.account.setIntegration(Api.ConfigurableIntegrationType.DonorPerfect, donorPerfectIntegration);
			setApiKey('');
		},
	});

	const deleteApiKeyMutation = useAccountIntegrationDonorPerfectDeleteApiKey({
		onError: () => {
			toaster.push({
				message: `An error occurred while deleting the API key.  If the problem persists, please contact support.`,
				type: 'errorMessage',
			});
		},
		onSuccess: () => {
			donorPerfectIntegration.isConnected = false;
			userSession.account.setIntegration(Api.ConfigurableIntegrationType.DonorPerfect, donorPerfectIntegration);
		},
	});

	const onSaveClicked = () => {
		const value = apiKey?.trim();
		if (!value) {
			return;
		}

		saveApiKeyMutation.mutate({
			apiKey: { apiKey },
		});
	};

	const isConnected = donorPerfectIntegration.isConnected ?? false;
	const onApiKeyValueChanged = (ev: React.ChangeEvent<HTMLInputElement>) => {
		setApiKey(ev.target.value);
	};

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} component-class ${className || ''}`}>
			<div className={css(integrationsStyleSheet.logo, integrationsStyleSheet.headerLogo)}>
				<img src={donorPerfectLogo} alt='DonorPerfect Logo' />
			</div>
			<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
				Levitate can sync with DonorPerfect to pull donor information, including names, email and physical mailing
				addresses, phone numbers, gifting history, and more.
			</p>
			{!isConnected ? (
				<>
					<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
						To sync Levitate with DonorPerfect, please enter the API Key provided by DonorPerfect Support or their API
						Help Desk.
					</p>
					<div className={css(integrationsStyleSheet.signInContainer, styleSheet.signInContainer)}>
						<div>
							<label htmlFor='apiKey'>API Key</label>
							<TextInput
								id='apiKey'
								onChange={onApiKeyValueChanged}
								placeholder='Enter API Key here'
								type='text'
								value={apiKey}
								inputId='apiKey'
							/>
						</div>
						<div className={css(styleSheet.center)}>
							<button
								className={css(baseStyleSheet.ctaButton, styleSheet.button)}
								onClick={onSaveClicked}
								disabled={saveApiKeyMutation.isLoading}
							>
								Save
							</button>
						</div>

						{saveApiKeyMutation.isLoading && (
							<LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='large' />
						)}
					</div>
				</>
			) : (
				<>
					<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
						You are successfully connected.
					</p>
					<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
						If you wish to disconnect this integration, click{' '}
						<Link
							to='#'
							className={css(styleSheet.link)}
							onClick={ev => {
								ev.preventDefault();

								deleteApiKeyMutation.mutate();
							}}
						>
							here
						</Link>
						.
					</p>

					{deleteApiKeyMutation.isLoading && (
						<LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='large' />
					)}
				</>
			)}
		</div>
	);
};
