import { CompanyViewModel, IOperationResultNoValue } from '@ViewModels';
import { css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { CompanyInfo } from '../../companies/CompanyInfo';
import { EntityRichContent } from '../../entities/EntityRichContent';
import { ITabViewChild, ITabbarItem, TabView } from '../../TabView';
import { styleSheet } from './styles';

interface IProps extends IUserSessionComponentProps, IEventLoggingComponentProps {
	className?: string;
	company: CompanyViewModel;
}

interface IState {
	selectedTabIndex?: number;
}

class _CompanyContextTabView extends React.Component<IProps, IState> {
	public readonly state: IState = {
		selectedTabIndex: 0,
	};

	public componentDidMount() {
		const { company } = this.props;
		if (!!company && !company.isLoading) {
			this.loadCompany(company);
		}
	}

	public render() {
		const { className } = this.props;
		const { selectedTabIndex } = this.state;
		return (
			<TabView
				className={` ${className || ''}`}
				hasFixedHeaders={true}
				onSelectedTabIndexChanged={this.onSelectedTabIndexChanged}
				selectedTabIndex={selectedTabIndex}
			>
				{this.getCompanyTabViewContent}
			</TabView>
		);
	}

	private loadCompany = (company: CompanyViewModel) => {
		const { logEvent } = this.props;
		const promise = company.load();
		if (promise) {
			// @ts-ignore
			logEvent('CompanyLoad');
			promise.catch((error: IOperationResultNoValue) => {
				// @ts-ignore
				logEvent('CompanyLoad-Error', { ...error });
			});
		}
	};

	private onSelectedTabIndexChanged = (selectedTabIndex: number) => {
		this.setState({
			selectedTabIndex,
		});
	};

	private getCompanyTabViewContent = (): ITabViewChild[] => {
		const { company } = this.props;
		// @ts-ignore
		const tabs = this.tabbarItems.map<ITabViewChild>(tab => {
			switch (tab.dataContext) {
				case 'Profile': {
					return {
						content: (
							<CompanyInfo className={`${css(styleSheet.companyInfo)}`} compactLayout={true} company={company} />
						),
						tabbarItem: tab,
					};
				}
				case 'RichContent': {
					return {
						content: (
							<EntityRichContent entity={company} readonly={true} sendMessageDisabled={true} sizeConstraint='compact' />
						),
						tabbarItem: tab,
					};
				}
				default: {
					break;
				}
			}
		});
		return tabs;
	};

	@computed
	private get tabbarItems() {
		const tabbarItems: ITabbarItem<string>[] = [
			{
				content: 'Profile',
				dataContext: 'Profile',
			},
			{
				content: 'Notes',
				dataContext: 'RichContent',
			},
		];

		return tabbarItems;
	}
}

const CompanyContextTabViewAsObserver = observer(_CompanyContextTabView);
const CompanyContextTabViewWithContext = inject(UserSessionViewModelKey)(CompanyContextTabViewAsObserver);
export const CompanyContextTabView = withEventLogging(CompanyContextTabViewWithContext, 'CompanyContextTabView');
