import { StyleSheet } from 'aphrodite';
import { brandPrimary, brandSecondary, destructive, header } from '../../styles/colors';
import { CssSelectors, baseStyleSheet } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	actionLink: {
		borderBottom: '2px solid transparent',
		cursor: 'pointer',
		fontSize: 14,
	},
	actionLinkActivate: {
		':focus': {
			borderBottom: `2px solid ${brandPrimary}`,
		},
		':hover': {
			borderBottom: `2px solid ${brandPrimary}`,
		},
		color: brandPrimary,
	},
	actionLinkDeactivate: {
		':focus': {
			borderBottom: `2px solid ${destructive}`,
		},
		':hover': {
			borderBottom: `2px solid ${destructive}`,
		},
		color: destructive,
	},
	avatar: {
		height: 45,
		width: 45,
	},
	container: {
		paddingLeft: 24,
		paddingRight: 24,
	},
	modalButtons: {
		marginTop: 16,
		display: 'flex',
		justifyContent: 'space-between',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		width: 400,
		padding: '24px 24px 8px 24px',
	},
	title: {
		margin: 0,
		color: brandPrimary,
	},
	label: {
		display: 'block',
		fontSize: 14,
		color: header,
		marginTop: 12,
		marginBottom: 4,
	},
	inputs: {
		borderRadius: 6,
	},
	employeeListItemActionItems: {
		marginLeft: 10,
		width: 200,
	},
	employeeListItemEmail: {
		color: '#4a4a4a',
		fontSize: 14,
		fontWeight: 'normal',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	employeeListItemName: {
		color: '#0d0f11',
		flexGrow: 1,
		fontSize: 18,
		fontWeight: 600,
		marginLeft: 20,
		minWidth: 250,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	employeeListItemSignature: {
		fontSize: 12,
		marginLeft: 10,
		width: 150,
		...CssSelectors.allChildren(
			{
				'@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
					paddingLeft: 38,
				},
				paddingLeft: 20,
				...CssSelectors.allDescendants(
					{
						paddingLeft: 5,
					},
					'div'
				),
				...CssSelectors.allDescendants(
					{
						paddingLeft: 5,
					},
					'figure'
				),
			},
			'div'
		),
	},
	employeeListItemSignatureAddButton: {
		':focus': {
			borderBottom: '2px solid #1272b9',
		},
		borderBottom: '2px solid transparent',
		color: '#1272b9',
	},
	employeeListItemStatus: {
		color: '#4a4a4a',
		fontSize: 12,
		marginRight: 10,
		width: 150,
	},
	employeeListItemType: {
		color: '#4a4a4a',
		fontSize: 14,
		marginLeft: 20,
		width: 150,
	},
	headerActions: {
		marginLeft: 10,
		width: 200,
	},
	headerAvatar: {
		width: 50,
	},
	headerLabel: {
		color: '#767676',
		fontSize: 11,
		textTransform: 'uppercase',
	},
	headerName: {
		flexGrow: 1,
		marginLeft: 20,
		minWidth: 250,
	},
	headerSignature: {
		marginLeft: 10,
		width: 150,
	},
	headerStatus: {
		marginLeft: 10,
		width: 150,
	},
	headerType: {
		alignItems: 'center',
		display: 'flex',
		marginLeft: 20,
		width: 150,
	},
	headerTypeTooltipContent: {
		whiteSpace: 'normal',
		width: 350,
	},
	helpTooltip: {
		marginLeft: 10,
	},
	licenseCount: {
		color: '#858585',
		fontSize: 14,
		margin: '0 0 20px',
	},
	licenseCountStrong: {
		color: '#0d0f11 !important',
		...baseStyleSheet.fontBold,
		fontWeight: 'bold',
	},
	listItem: {
		alignItems: 'center',
		borderTop: '1px solid #f0eded',
		display: 'flex',
		minHeight: 50,
		padding: '15px 0 15px',
		textAlign: 'left',
	},
	listItemLoading: {
		opacity: 0.5,
	},
	managedUsersActionBarContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginBottom: 30,
	},
	managedUsersEmployeeList: {
		fontSize: 14,
	},
	managedUsersEmployeeListHeader: {
		display: 'flex',
		paddingBottom: 18,
	},
	manageUsersContainerListView: {
		height: '100%',
		...CssSelectors.allDescendants(
			{
				background: '#fff',
				paddingTop: 20,
			},
			'.scroll-view-header-wrap'
		),
	},
	manageUsersModalContainer: {
		height: 640,
		minWidth: 858,
		overflowY: 'auto',
	},
	manageUsersModalContainerSignature: {
		...CssSelectors.allDescendants(
			{
				paddingLeft: '30px',
			},
			'.settings-group'
		),
		...CssSelectors.allDescendants(
			{
				padding: '30px 20px 30px 0px',
			},
			'.settings-group-body-content'
		),
	},
	manageUsersModalHeader: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	manageUsersModalTitle: {
		color: brandSecondary,
		fontSize: 18,
		lineHeight: '22px',
	},
	moreMenuWrap: {
		boxSize: 'border-box',
	},
	noneFound: {
		margin: 0,
	},
	overlayContainer: {
		left: 'calc(50% - 30px)',
		margin: 'auto',
		position: 'absolute',
		textAlign: 'center',
		top: 'calc(50% - 13px)',
		transform: 'translate(-50%, -50%)',
		zIndex: 1,
	},
	removeBorder: {
		border: 'none',
		boxShadow: 'none',
	},
	selectBoxTrigger: {
		width: 'auto',
	},
	settingsContainerTabview: {
		...CssSelectors.allChildren(
			{
				minHeight: '100%',
			},
			'.tab-view-content'
		),
	},
	errorMessage: {
		color: destructive,
		fontSize: 12,
	},
});
