import { StyleSheet } from 'aphrodite';
import { background, white } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	card: {
		background: white,
		position: 'relative',
	},
	cardList: {
		gridTemplateColumns: 'repeat(auto-fill, minmax(min(320px, 100%), 1fr))',
		height: 'calc(100% - 66px)',
	},
	grayBackground: {
		background,
	},
	moreMenu: {
		position: 'absolute',
		right: 8,
		top: 8,
		width: 30,
	},
});
