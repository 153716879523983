import { IContact, IUser } from '@ViewModels';

/**
 * Useful when api returns a contact that isn't in the system. The contactID will be '<resolveByEmail>'.
 * (case-insensitive)
 */
export const ResolveByEmailRegExp = new RegExp('resolveByEmail-', 'i');

/** Good for checking if a string begins with 'http' (case-insensitive) */
export const HttpRegExp = new RegExp('^http', 'i');

export const parseContactDisplayName = (name: string): IContact => {
	let displayName = extractDisplayName(name, "'");
	displayName = extractDisplayName(displayName, '"');

	const splitResults = displayName.split(' ');
	if (splitResults.length > 1) {
		return {
			firstName: splitResults[0],
			lastName: splitResults.slice(1, splitResults.length + 1).join(' '),
		};
	}
	return {};
};

const extractDisplayName = (name: string, separator: string): string => {
	const firstOccurrance = name.indexOf(separator);
	if (firstOccurrance < 0) {
		return name;
	}

	const lastOccurrance = name.lastIndexOf(separator);
	if (lastOccurrance < firstOccurrance) {
		return name;
	}

	return name.substring(firstOccurrance, lastOccurrance + 1);
};

export const isAdminUser = (user: IUser): boolean => {
	return user.groups ? user.groups.indexOf('admin') > -1 : false;
};

export const calculateDaysBetween = (date1: Date, date2: Date): number => {
	const oneDay: number = 1000 * 60 * 60 * 24; // Convert both dates to milliseconds
	const date1Milliseconds = new Date(Date.parse(date1.toString())).getTime();
	const date2Milliseconds = new Date(Date.parse(date2.toString())).getTime(); // Calculate the difference in milliseconds
	const differenceMilliseconds = date2Milliseconds - date1Milliseconds; // Convert back to days and return
	return Math.round(differenceMilliseconds / oneDay);
};
