import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useLocation, useParams } from 'react-router';
import { useFullscreenModal, useUserSession } from '../../../../models/hooks/appStateHooks';
import { useAutomationTemplateIdQuery } from '../../../../queries';
import { ComposerSendFromDropdown } from '../../../components/ComposerSendFromDropdown';
import { FabContext } from '../../../components/FabContext';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import {
	CampaignRecipientsSearch,
	CampaignRecipientsSearchResult,
} from '../../../components/campaigns/CampaignRecipientsSearch';
import { bs } from '../../../styles/styles';
import { CampaignComposerBaseLayout } from '../../BulkHwcPages/presentation';
import { TextCampaignTemplateBody } from './TextCampaignTemplateBody';

export function TextCampaignTemplateSearchPage() {
	const userSession = useUserSession();
	const fullscreenModal = useFullscreenModal();
	const location = useLocation<{
		initialSendFrom?: Api.ISendEmailFrom;
		initialSendFromUserId?: string;
	}>();
	const { automationTemplateId } = useParams<{ automationTemplateId: string }>();
	const automationTemplateIdQuery = useAutomationTemplateIdQuery({
		id: automationTemplateId,
	});
	const [sendFrom, setSendFrom] = React.useState<Api.ISendEmailFrom>(
		location.state?.initialSendFrom ?? Api.SendEmailFrom.CurrentUser
	);
	const [sendFromUserId, setSendFromUserId] = React.useState<string | null>(
		location.state?.initialSendFromUserId ?? null
	);
	const handleNextClick = (value: CampaignRecipientsSearchResult) => {
		const initialContactIdsToInclude = value.selectedContacts.map(c => c.id);
		if (
			initialContactIdsToInclude.length > 0 &&
			!value.selectedContactSearch?.filter?.criteria &&
			value.selectedTags.length === 0
		) {
			fullscreenModal.history.push({
				pathname: `/texting-campaign/template-contacts/${automationTemplateId}`,
				state: {
					contactIds: initialContactIdsToInclude,
					initialSendFrom: sendFrom,
					initialSendFromUserId: sendFromUserId,
				},
			});
			return;
		}
		let initialContactFilterCriteriaList: Api.IContactFilterCriteria[];
		if (value.selectedContactSearch && value.selectedContactSearch?.filter?.criteria) {
			// assumes the top level op of all contact searches is 'And'
			initialContactFilterCriteriaList = value.selectedContactSearch.filter.criteria;
		} else if (value.selectedTags.length > 0) {
			initialContactFilterCriteriaList = [
				{
					op: 'Or',
					criteria: value.selectedTags.map(tag => ({
						property: Api.ContactFilterCriteriaProperty.Tag,
						value: tag.tag,
					})),
				},
			];
		}
		fullscreenModal.history.push({
			pathname: `/texting-campaign/template-filter/${automationTemplateId}`,
			state: {
				initialContactIdsToInclude,
				initialFilterCriteriaList: initialContactFilterCriteriaList,
				initialSendFrom: sendFrom,
				initialSendFromUserId: sendFromUserId,
			},
		});
	};
	return (
		<>
			<MultiContainerHeader
				fullscreenHeader='New Texting Campaign'
				onFullscreenRequestBack={ev => {
					ev.preventDefault();
					fullscreenModal.dismissModal();
				}}
				onFullscreenRequestClose={() => fullscreenModal.dismissModal()}
			/>
			<CampaignComposerBaseLayout
				mainHeader={
					<div className={css(bs.px3, bs.py5, bs.flex, bs.gap2, bs.flexCol)}>
						<div className={css(bs.mb2)}>
							{automationTemplateIdQuery.status === 'success' ? (
								<h2 className={css(bs.m0)}>{automationTemplateIdQuery.data?.name}</h2>
							) : (
								<LoadingSpinner type='small' />
							)}
						</div>
						<ComposerSendFromDropdown
							disabled={!userSession.canSendOnBehalf}
							sendFrom={sendFrom}
							sendFromUserId={sendFromUserId}
							onChange={params => {
								setSendFrom(params.sendFrom);
								setSendFromUserId(params.sendFromUserId);
							}}
						/>
					</div>
				}
				sideBar={<CampaignRecipientsSearch onSubmit={handleNextClick} />}
			>
				<>
					<FabContext appearance={{ hidden: true }} />
					<TextCampaignTemplateBody
						automationTemplateId={automationTemplateId}
						onDuplicateClick={({ title, contentEditorState, attachments }) => {
							fullscreenModal.history.push({
								pathname: `/texting-campaign/search`,
								state: {
									initialSendFrom: sendFrom,
									initialSendFromUserId: sendFromUserId,
									initialCampaignTitle: title,
									initialContentEditorState: contentEditorState,
									initialStoredAttachments: attachments,
								},
							});
						}}
					/>
				</>
			</CampaignComposerBaseLayout>
		</>
	);
}
