import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import clsx from 'clsx';
import { UnregisterCallback } from 'history';
import { observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, NavLink as RouterLink, withRouter } from 'react-router-dom';
import { AppHashHistory } from '../../../../models';
import { useEventLogging } from '../../../../models/Logging';
import { canViewContentCalendarAndCampaigns } from '../../../../models/UiUtils';
import { useTextMessaging, useUserSession } from '../../../../models/hooks/appStateHooks';
import { AutomationsOnHoldViewModel, IOperationResultNoValue } from '../../../../viewmodels/AppViewModels';
import { TrialExpiringSoon } from '../../Billing/TrialExpiringSoon';
import { CompactLogoIcon2023 } from '../../svgs/icons/CompactLogoIcon2023';
import { FlagIcon } from '../../svgs/icons/FlagIcon';
import { LogoIcon2023 } from '../../svgs/icons/LogoIcon2023';
import { NavBarLink } from '../NavBarLink';
import { styleSheet } from './styles';

interface IProps extends RouteComponentProps<any> {
	className?: string;
	compactLayout?: boolean;
	header?: React.ReactNode;
	onRenderLogo?(compactLayout?: boolean): React.ReactNode;
}

function _NavBar({ className, compactLayout, header, onRenderLogo }: IProps) {
	const [automationsPending, setAutomationsPending] = React.useState<boolean>(false);

	const userSession = useUserSession();
	const textMessaging = useTextMessaging();
	const { logApiError } = useEventLogging('Navbar');

	const [automationsOnHold] = React.useState<AutomationsOnHoldViewModel>(new AutomationsOnHoldViewModel(userSession));

	React.useEffect(() => {
		let unregisterHandle: UnregisterCallback = null;
		const checkForAutomations = () => {
			if (userSession?.account?.features?.automation?.enabled) {
				automationsOnHold
					.loadAutomationsOnHold(userSession.canSendOnBehalf)
					?.then(() => {
						setAutomationsPending(
							userSession.canSendOnBehalf
								? automationsOnHold?.allAutomationsPending?.length > 0
								: automationsOnHold?.myAutomationsPending?.length > 0
						);
					})
					?.catch((err: IOperationResultNoValue) => {
						logApiError('LoadPendingAutomationsNavBar', err);
					});
			}
		};
		unregisterHandle = AppHashHistory.listen(() => checkForAutomations());
		checkForAutomations();
		return () => {
			unregisterHandle();
		};
	}, [automationsOnHold, logApiError, userSession]);

	const isLimitedUser = userSession.userRole === 'limitedUser';
	const canViewAutomations = !!userSession.account.features.automation?.enabled && !isLimitedUser;
	const canViewTexting = Api.VmUtils.canViewTexting(userSession);
	const canManageTags = userSession.account.features.tags || userSession.account.features.tags.canManageTags;
	const canViewCalendarAndCampaigns = canViewContentCalendarAndCampaigns(userSession);

	const boardsFeature = userSession.account.features.boards;
	const canViewDataboards =
		boardsFeature?.opportunitiesEnabled || boardsFeature?.donationsEnabled || boardsFeature?.policiesEnabled || false;

	return (
		<nav className={`nav-bar ${clsx(css(styleSheet.nav, !compactLayout && styleSheet.navExpanded), className)}`}>
			{header || (
				<div className={css(styleSheet.header)}>
					{onRenderLogo?.(compactLayout) || (
						<RouterLink to='/dashboard'>
							{compactLayout ? <CompactLogoIcon2023 /> : <LogoIcon2023 className={css(styleSheet.mainLogo)} />}
						</RouterLink>
					)}
				</div>
			)}
			<div className={css(styleSheet.links)}>
				<NavBarLink linkName='Dashboard' path='/dashboard' compactLayout={compactLayout} />
				{canViewCalendarAndCampaigns ? (
					<NavBarLink compactLayout={compactLayout} linkName='Campaigns' path='/campaigns' />
				) : null}
				{canViewCalendarAndCampaigns ? (
					<NavBarLink compactLayout={compactLayout} linkName='Content Calendar' path='/content-calendar' />
				) : null}
				{isLimitedUser ? (
					<NavBarLink compactLayout={compactLayout} linkName='Contacts' path='/people' />
				) : (
					<NavBarLink compactLayout={compactLayout} linkName='Contacts' path={['/people', '/companies']} />
				)}
				{canViewTexting ? (
					<NavBarLink
						accessory={
							textMessaging?.totalUnreadMessages ? (
								<div
									className={css(
										compactLayout ? styleSheet.compactUnreadTextMessageCount : styleSheet.unreadTextMessageCount
									)}
								>
									{textMessaging.totalUnreadMessages}
								</div>
							) : null
						}
						compactLayout={compactLayout}
						linkName='Texting'
						path={['/texting', '/texting/registration']}
					/>
				) : null}
				<NavBarLink compactLayout={compactLayout} linkName='Notes & Actions' path='/notes-action-items' />
				{canViewAutomations ? (
					<NavBarLink
						accessory={automationsPending ? <FlagIcon /> : null}
						compactLayout={compactLayout}
						linkName='Automations'
						path='/automations'
					/>
				) : null}
				{canViewDataboards ? (
					<NavBarLink compactLayout={compactLayout} linkName='Data Boards' path='/dataBoards' />
				) : null}
				<NavBarLink
					compactLayout={compactLayout}
					linkName={canManageTags ? 'Tags & Reports' : 'Reporting'}
					path='/reporting'
				/>
			</div>
			<TrialExpiringSoon compactLayout={compactLayout} userSession={userSession} />
		</nav>
	);
}

const NavBarAsObserver = observer(_NavBar);
export const NavBar = withRouter(NavBarAsObserver);
