import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { AppImportContactsViewModel } from '../../../../viewmodels/AppViewModels';
import { brandSecondary } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { Checkbox } from '../../Checkbox';
import { MultiContainerHeader } from '../../MultiContainerHeader';
import {
	ISimpleAutoCompleteSearchFieldEvent,
	ISimpleAutoCompleteSearchFieldItemSelectionEvent,
	SimpleAutoCompleteSearchField,
} from '../../autocomplete/SimpleAutoCompleteSearchField';
import { styleSheet } from './styles';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { IOperationResultNoValue, ResourceAutoCompleteViewModelType } from '@ViewModels';
import { FilesDropTarget } from '@WebComponents/FilesDropTarget';
import { LoadingSpinner } from '@WebComponents/LoadingSpinner';
import { RadioButton } from '@WebComponents/RadioButton';
import { SpreadsheetIcon } from '@WebComponents/svgs/icons/SpreadsheetIcon';
import { SvgIcon } from '@WebComponents/svgs/icons/SvgIcon';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';

interface IProps
	extends IEventLoggingComponentProps,
		IUserSessionComponentProps,
		IUserSessionComponentProps,
		IErrorMessageComponentProps {
	className?: string;
	importContacts: AppImportContactsViewModel;
	onCancelButtonClicked?(): void;
	onNextButtonClicked?(): void;
}

interface IState {
	dataRefresh?: boolean;
	file?: File;
}

const AcceptedFileExtensions = '.csv,.xlsx,.xls';

class _ContactsSelfImportUpload extends React.Component<IProps, IState> {
	private mFilesInputRef: React.RefObject<HTMLInputElement>;
	// @ts-ignore
	private mMounted: boolean;

	constructor(props: IProps) {
		super(props);
		this.mFilesInputRef = React.createRef<HTMLInputElement>();
		this.state = { dataRefresh: false };
	}

	public componentDidMount() {
		this.mMounted = true;
		const { logPageView } = this.props;
		// @ts-ignore
		logPageView('ContactsSelfImport/Upload');
	}

	public componentWillUnmount() {
		this.mMounted = false;
	}

	public render() {
		const { className, onCancelButtonClicked, importContacts, userSession } = this.props;
		const { dataRefresh, file } = this.state;
		const uploadHint = `Supported file types: ${AcceptedFileExtensions.split(',').reduce((x, y) => {
			return `${x}${x ? ', ' : ''}${y}`;
		}, '')}`;

		return (
			<div className={`${css(styleSheet.container)} contacts-self-import-upload ${className || ''}`}>
				<MultiContainerHeader fullscreenHeader='Import Contacts from Spreadsheet' />
				<div className={css(styleSheet.options)}>
					<div className={css(styleSheet.importOptions)}>
						<div className={css(styleSheet.optionsTitle)}>How would you like the new contacts imported?</div>
						<div>
							<RadioButton
								checked={importContacts.options.visibility === 'all'}
								className={css(styleSheet.importOptionsRadio)}
								id='ContactsSelfImportUpload-Import-Visibility-All'
								name='ContactsSelfImportUpload-Import-Visibility'
								onChange={this.onImportPrivacyOptionChanged('all')}
							>
								<span>Import contacts with visibility set to all employees</span>
							</RadioButton>
							<RadioButton
								checked={importContacts.options.visibility === 'admin'}
								className={css(styleSheet.importOptionsRadio)}
								id='ContactsSelfImportUpload-Import-Visibility-Admin'
								name='ContactsSelfImportUpload-Import-Visibility'
								onChange={this.onImportPrivacyOptionChanged('admin')}
							>
								<span>Import contacts with visibility set to private so only I can see them</span>
							</RadioButton>
						</div>
					</div>
					<div className={css(styleSheet.tagOptions)}>
						<div className={css(styleSheet.optionsTitle)}>Preferred Tag:</div>
						<div className={css(styleSheet.optionsSubtitle)}>You can add a tag to everyone you import.</div>
						<SimpleAutoCompleteSearchField
							onChange={this.onTagInputChanged}
							// @ts-ignore
							onItemSelected={this.onTagSelected}
							onKeyDown={this.onSearchFieldInputKeyDown}
							onClear={this.clearSelectedTag}
							pageSize={10}
							placeholder='Search existing or enter new tag'
							resultsLimit={10}
							style={styleSheet.searchField}
							type={ResourceAutoCompleteViewModelType.Tag}
						/>
					</div>
					{/* @ts-ignore */}
					{/* @ts-ignore */}
					{userSession.account.preferences.deleteRenewalKeyFacts && (
						<div className={css(styleSheet.importOptionsRadio)}>
							<Checkbox id='data-refresh' onChange={this.onDataRefreshChanged} checked={dataRefresh}>
								<div>This is a data refresh (clear renewal keyfacts and policy tags)</div>
							</Checkbox>
						</div>
					)}
				</div>
				<div className={css(styleSheet.uploadField)}>
					{importContacts.isBusy ? (
						<LoadingSpinner className='absolute-center' type='large' />
					) : (
						<FilesDropTarget
							className={css(styleSheet.uploadTarget)}
							onFilesDropped={this.onFileDropped}
							supportedFileExtensions={AcceptedFileExtensions.split(',').map(x => x.slice(1))}
							supportMultiple={true}
							title={uploadHint}
						>
							<div className={css(styleSheet.uploadTargetOverlay)}>
								<SpreadsheetIcon className={css(styleSheet.uploadSpreadsheetIcon)} />
								{file ? (
									<div className={css(styleSheet.uploadTargetOverlayChosenFile)}>{file.name}</div>
								) : (
									<SvgIcon className={css(styleSheet.uploadTargetOverlayArrow)} height={27} width={20}>
										<polygon
											fill={brandSecondary}
											fillRule='evenodd'
											points='20 10.048 10 0 0 10.048 3.004 13.066 7.876 8.172 7.876 26.5 12.124 26.5 12.124 8.172 16.996 13.066'
										/>
									</SvgIcon>
								)}
								<input
									accept={AcceptedFileExtensions}
									id='contacts-self-import-upload-files-input'
									multiple={false}
									onChange={this.onFilesInputChanged}
									ref={this.mFilesInputRef}
									style={{
										height: 0,
										opacity: 0,
										pointerEvents: 'none',
										position: 'fixed',
										visibility: 'hidden',
										width: 0,
										zIndex: -1,
									}}
									type='file'
								/>
								<div className={css(styleSheet.uploadTargetOverlayMessage)}>
									<span>Drop any Excel, CSV, or</span>
									&nbsp;
									<button
										className={css(baseStyleSheet.brandSecondaryLink, styleSheet.uploadTargetOverlayButton)}
										onClick={this.onChooseFileButtonClicked}
									>
										<span>browse your files</span>
									</button>
								</div>
							</div>
						</FilesDropTarget>
					)}
				</div>
				<div className={css(styleSheet.footer)}>
					<button className={css(baseStyleSheet.ctaButton)} onClick={this.onNextButtonClicked}>
						<span>Next</span>
					</button>
					<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={onCancelButtonClicked}>
						<span>Cancel</span>
					</button>
				</div>
			</div>
		);
	}

	private clearSelectedTag = () => {
		const { logInput, importContacts } = this.props;
		importContacts.options.setTags([]);
		// @ts-ignore
		logInput('ClearSearchField', 'Click');
	};

	private onSearchFieldInputKeyDown = (
		e: ISimpleAutoCompleteSearchFieldEvent<React.KeyboardEvent<HTMLInputElement>>
	) => {
		if (!!e.sourceEvent && e.sourceEvent.keyCode === 13) {
			(e.sourceEvent.target as HTMLInputElement).blur();
		}
	};

	private onTagSelected = (e: ISimpleAutoCompleteSearchFieldItemSelectionEvent<string>) => {
		const { logEvent, importContacts } = this.props;
		if (e.target) {
			e.target.setSearchQuery(e.selection);
		}
		importContacts.options.setTags(e.selection ? [e.selection] : []);
		// @ts-ignore
		logEvent('TagSelected', { tag: e.selection || '' });
	};

	private onTagInputChanged = (e: ISimpleAutoCompleteSearchFieldEvent<React.ChangeEvent<HTMLInputElement>>) => {
		if (e.sourceEvent) {
			const tag = (e.sourceEvent.target as HTMLInputElement).value;
			this.props.importContacts.options.setTags(tag ? [tag] : []);
		}
	};

	private onChooseFileButtonClicked = () => {
		if (this.mFilesInputRef.current) {
			const { logInput } = this.props;
			// @ts-ignore
			logInput('ChooseFile', 'Click');
			this.mFilesInputRef.current.click();
		}
	};

	private onFilesInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { logEvent } = this.props;
		const files = e.target.files;
		if (!!files && files.length > 0) {
			const filteredFiles = Array.from(files).filter(x => {
				const comps = x.name ? x.name.split('.').filter(y => !!y) : [];
				const ext = comps.length > 1 ? comps[comps.length - 1] : null;
				return ext ? AcceptedFileExtensions.indexOf(ext.toLocaleLowerCase()) >= 0 : false;
			});
			// @ts-ignore
			e.target.value = null;

			if (filteredFiles.length > 0) {
				const file = filteredFiles[0];
				const fileExt = ((file ? file.name : '')
					.split('.')
					.filter(x => !!x)
					.reverse() || [])[0];
				// @ts-ignore
				logEvent('FilesAdded', { fileExt });
				this.setState({
					file,
				});
			}
		}
	};

	private onFileDropped = (files: File[]) => {
		const { logInput } = this.props;
		if (files.length > 0) {
			const file = files[0];
			const fileExt = ((file ? file.name : '')
				.split('.')
				.filter(x => !!x)
				.reverse() || [])[0];
			// @ts-ignore
			logInput('FileDropped', 'Direct', {
				fileExt,
			});
			this.setState({ file });
		}
	};

	private onImportPrivacyOptionChanged = (option: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
		const { importContacts, logEvent } = this.props;
		const visibility = option === 'admin' && !!e.target.checked ? 'admin' : 'all';
		importContacts.options.setVisibility(visibility);
		// @ts-ignore
		logEvent('ImportVisibilityChanged', { visibility });
	};

	private onNextButtonClicked = () => {
		const { onNextButtonClicked, importContacts, logApiError, logInput, errorMessages } = this.props;
		const { file, dataRefresh } = this.state;
		if (file) {
			// @ts-ignore
			const promise = importContacts.importFromFile(file, null, dataRefresh);
			if (promise) {
				// @ts-ignore
				logInput('CreatePreview', 'Click');
				promise
					.then(() => {
						if (!!onNextButtonClicked && !!this.mMounted) {
							onNextButtonClicked();
						}
					})
					.catch((error: IOperationResultNoValue) => {
						// @ts-ignore
						logApiError('CreatePreview-Error', error);
						// @ts-ignore
						errorMessages.pushApiError(error);
					});
			}
		}
	};
	private onDataRefreshChanged = () => {
		const { dataRefresh } = this.state;
		return this.setState({ dataRefresh: !dataRefresh });
	};
}

const ContactsSelfImportUploadAsObserver = observer(_ContactsSelfImportUpload);
const ContactsSelfImportUploadWithContext = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey
)(ContactsSelfImportUploadAsObserver);
export const ContactsSelfImportUpload = withEventLogging(
	ContactsSelfImportUploadWithContext,
	'ContactsSelfImportUpload'
);
