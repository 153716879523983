import { ICompany, IContact, IEntity, ResourceAutoCompleteViewModelType, getTypeForEntity } from '@ViewModels';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import { CompanyViewModel, ContactViewModel, EntityViewModel } from '../../../../viewmodels/AppViewModels';
import { Avatar } from '../../Avatar';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { AutoCompleteSearchField } from '../../autocomplete/AutoCompleteSearchField';
import { ReferencedContactsIcon } from '../../svgs/icons/ReferencedContactsIcon';
import { SearchIcon } from '../../svgs/icons/SearchIcon';
import './styles.less';

interface IProps extends IUserSessionComponentProps {
	className?: string;
	contextItemName?: 'note' | 'action item' | 'phone call';
	mentionedEntities?: EntityViewModel<IEntity>[];
	onEntityClicked?(entity: EntityViewModel<IEntity>): void;
	onEntitySelected?(entity: EntityViewModel<IEntity>): void;
	onRemoveEntity?(entity: EntityViewModel<IEntity>): void;
}

interface IState {
	test?: string;
	mentionedEntities?: EntityViewModel<IEntity>[];
}

class _MentionList extends React.Component<IProps, IState> {
	public state: IState = {};

	public UNSAFE_componentWillMount() {
		const nextState = this.getNextStateWithProps(this.props);
		if (nextState) {
			this.setState(nextState);
		}
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		const nextState = this.getNextStateWithProps(nextProps);
		if (nextState) {
			this.setState(nextState);
		}
	}

	public render() {
		const { className, contextItemName } = this.props;
		const { mentionedEntities } = this.state;
		return (
			<div className={`mention-list ${className || ''}`}>
				<div className='mention-list-header'>
					<div className='mention-list-header-title'>
						<ReferencedContactsIcon className='mention-list-header-title-icon' />
						<span className='mention-list-header-title-text'>
							{contextItemName === 'phone call'
								? 'Who was on this phone call?'
								: `Who is this ${contextItemName || 'item'} about?`}
						</span>
					</div>
					<AutoCompleteSearchField
						anchorClassName='mention-list-autocomplete-search-field-anchor'
						className='mention-list-autocomplete-search-field'
						clearSearchFieldAfterSelectingItem={true}
						dropdownContentClassName='mention-list-autocomplete-search-field-dropdown-content'
						inputId='mention-list-search-input'
						inputProps={{
							placeholder: 'Type the name of a person or company',
						}}
						leftAccessory={<SearchIcon className='mention-list-autocomplete-search-field-icon' />}
						onItemSelected={this.onEntitySelected}
						resultsLimit={6}
						retainFocusAfterSelectingItem={true}
						type={ResourceAutoCompleteViewModelType.Entity}
					/>
				</div>
				<div className='mention-list-body'>
					{!!mentionedEntities &&
						mentionedEntities.map(x => {
							return (
								<div className='mention-list-item' key={x.id} onClick={this.onEntityClicked(x)}>
									<div className='mention-list-item-content'>
										<DeprecatedCloseButton
											className='mention-list-item-remove-button'
											onClick={this.onRemoveEntity(x)}
										/>
										<Avatar className='mention-list-item-avatar' entityVm={x} />
										<div className='mention-list-item-info'>
											<div className='mention-list-item-info-name truncate-text'>{x.name}</div>
											{getTypeForEntity(x.toJs()) !== 'company' && (
												<div className='mention-list-item-info-message truncate-text'>
													Add key facts, tags, and set Keep in Touch
												</div>
											)}
										</div>
									</div>
								</div>
							);
						})}
				</div>
			</div>
		);
	}

	private onEntityClicked = (entity: EntityViewModel<IEntity>) => () => {
		const { onEntityClicked } = this.props;
		if (onEntityClicked) {
			onEntityClicked(entity);
		}
	};

	private getNextStateWithProps = (nextProps: IProps) => {
		const nextState: IState = {};

		if (nextProps.mentionedEntities !== this.state.mentionedEntities) {
			nextState.mentionedEntities = nextProps.mentionedEntities;
		}

		if (Object.keys(nextState).length > 0) {
			return nextState;
		}
		return null;
	};

	private onRemoveEntity = (entity: EntityViewModel<IEntity>) => (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		e.stopPropagation();
		const { onRemoveEntity } = this.props;
		if (onRemoveEntity) {
			onRemoveEntity(entity);
		} else {
			let mentionedEntities = this.state.mentionedEntities || [];
			const index = mentionedEntities.findIndex(x => x.id === entity.id);
			if (index >= 0) {
				mentionedEntities = [...mentionedEntities];
				mentionedEntities.splice(index, 1);
				this.setState({
					mentionedEntities,
				});
			}
		}
	};

	private onEntitySelected = (entityModel: IEntity) => {
		const { onEntitySelected } = this.props;
		const entity =
			getTypeForEntity(entityModel) === 'company'
				? new CompanyViewModel(this.props.userSession, entityModel as ICompany)
				: new ContactViewModel(this.props.userSession, entityModel as IContact);
		if (onEntitySelected) {
			onEntitySelected(entity);
		} else {
			let mentionedEntities = this.state.mentionedEntities || [];
			if (mentionedEntities.findIndex(x => x.id === entityModel.id) < 0) {
				mentionedEntities = [...mentionedEntities, entity];
				this.setState({
					mentionedEntities,
				});
			}
		}
	};
}

const MentionListAsObserver = observer(_MentionList);
export const MentionList = inject(UserSessionViewModelKey)(MentionListAsObserver);
