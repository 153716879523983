import { css } from 'aphrodite';
import * as React from 'react';
import { baseStyleSheet } from '../../../styles/styles';
import { HandwrittenFoldedCardThumbnail } from '../../HandwrittenCards/HandwrittenfoldedCardThumbnail';
import '../../richContent/RichContentDocumentEditor/placeholders.tinymce.css';
import { styleSheet } from './styles';

interface IProps {
	name: string;
	onTemplateClicked: () => void;
	publicUrl: string;
}

export const HandwrittenCardsTemplateCard: React.FC<IProps> = ({ name, onTemplateClicked, publicUrl }) => {
	return (
		<div className={css(styleSheet.cardListItem)}>
			<HandwrittenFoldedCardThumbnail src={publicUrl} alt={name} styleDeclaration={styleSheet.cardThumbnail} />
			{/* For flat card */}
			{/* <img src={template.thumbnail.publicUrl} alt={name} className={css(styleSheet.cardThumbnail)} /> */}
			<h3 className={css(styleSheet.cardListItemTitle)}>{name}</h3>
			<footer className={css(styleSheet.cardListItemCta)}>
				<button className={css(baseStyleSheet.ctaButtonReverseSmall)} onClick={onTemplateClicked}>
					View Card
				</button>
			</footer>
		</div>
	);
};
