import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { DefaultBulkContactsRequest } from '../../../../extViewmodels/Utils';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { useEventLogging } from '../../../../models/Logging';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { ComposeEmailViewModel } from '../../../../viewmodels/AppViewModels';
import { brandPrimary, destructive } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { LoadingSpinner } from '../../LoadingSpinner';
import { ISelectOption } from '../../Select';
import { PopoverType, TinyPopover } from '../../TinyPopover';
import { UserSelectBox } from '../../UserSelectBox';
import { TagEmailTagList } from '../../email/TagEmailTagList';
import { DangerMessage } from '../../errorMessages/DangerMessage';
import { InfoIcon } from '../../svgs/icons/InfoIcon';
import { styleSheet as campaignDetailsSummaryStyleSheet } from '../CampaignDetailsSummary/styles';
import { useEmailWorkloadEstimateErrors } from './hooks';
import { styleSheet } from './styles';

interface IProps extends IImpersonationContextComponentProps {
	className?: string;
	emailComposer?: ComposeEmailViewModel;
	onApproveClicked?(): void;
	onApproveWithCompliance?(): void;
	onCancelClicked?(): void;
	onChangeScheduleClicked?(): void;
	onEditRecipientsClicked?(): void;
	onRejectClicked?(): void;
	onRenderSendFromOptions?(): React.ReactNode;
	sendOnBehalfSelectEmployee?(): void;
	readOnly?: boolean;
	styles?: StyleDeclarationValue[];
	sendFromDropdownIsOpen?: boolean;
}

export const SuggestionUserSelectDefaultOption: ISelectOption<string | Api.UserViewModel> = {
	dataContext: null,
	id: 'contactOwners',
	text: 'Contact owner of the recipient',
};

const SuggestedCampaignOptionsSummaryBase: React.FC<IProps> = observer(props => {
	const {
		className,
		emailComposer,
		impersonationContext,
		onApproveClicked,
		onApproveWithCompliance,
		onCancelClicked,
		onChangeScheduleClicked,
		onEditRecipientsClicked,
		onRejectClicked,
		readOnly,
		styles,
	} = props;
	if (!emailComposer) {
		return null;
	}

	const { logApiError } = useEventLogging();
	const userSession = useUserSession();
	const complianceSettings = impersonationContext?.isValid
		? impersonationContext?.account?.preferences?.complianceSettings
		: userSession.account.preferences?.complianceSettings;
	const emailOptions = emailComposer?.emailMessage.options;

	const updateRecipientsApproximation = React.useCallback(() => {
		emailComposer
			?.updateApproximationForFilter(
				!emailComposer.emailMessage.hasUserSelectedContactFilterSearchCriteria ? DefaultBulkContactsRequest : undefined
			)
			?.catch(error => {
				logApiError('ApproximationLoad-Error', error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emailComposer]);

	React.useEffect(() => {
		updateRecipientsApproximation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emailComposer]);

	// #region Send From
	const [selectedUser, setSelectedUser] = React.useState<Api.UserViewModel>(
		emailComposer.emailMessage.isSendFromOption(Api.SendEmailFrom.CurrentUser)
			? new Api.UserViewModel(userSession, userSession.user)
			: emailComposer.emailMessage.isSendFromOption(Api.SendEmailFrom.SelectedUser)
				? new Api.UserViewModel(
						userSession,
						emailOptions.sendEmailFromUser || {
							id: emailOptions.sendEmailFromUserId,
						}
					).impersonate(impersonationContext)
				: null
	);

	const onOptionSelected = React.useCallback(
		async (option: ISelectOption<string | Api.UserViewModel>, user?: Api.UserViewModel) => {
			if (emailOptions) {
				const userVm = option.dataContext instanceof Api.UserViewModel ? option.dataContext : user;
				const userId = userVm ? userVm.id : typeof option.dataContext === 'string' ? option.dataContext : null;

				let filterRequest: Api.IContactsFilterRequest = {
					...DefaultBulkContactsRequest.filter,
					...(emailComposer?.emailMessage?.contactsFilterRequest?.contactFilterRequest || {}),
				};
				const sorted = Api.VmUtils.sortContactFilterCriteria(filterRequest?.criteria);
				const ownedByFilter = sorted.filters.find(x => x.property === Api.ContactFilterCriteriaProperty.OwnedBy);
				if (ownedByFilter) {
					// remove the OwnedBy filter
					filterRequest = {
						criteria: [
							...(sorted.filters || []).filter(x => x.property !== Api.ContactFilterCriteriaProperty.OwnedBy),
							...sorted.searches,
							...sorted.compound,
						],
					};
				}

				if (userId) {
					const fromCurrentUser = userSession.user.id === userId;
					emailOptions.sendEmailFrom = fromCurrentUser ? Api.SendEmailFrom.CurrentUser : Api.SendEmailFrom.SelectedUser;
					emailOptions.sendEmailFromUser = fromCurrentUser ? undefined : userVm ? userVm.toJs() : { id: userId };
					emailOptions.sendEmailFromUserId = fromCurrentUser ? undefined : userId;
					setSelectedUser(
						// FOLLOWUP: Resolve
						// @ts-ignore
						userVm ?? new Api.UserViewModel(userSession, { id: userId }).impersonate(impersonationContext)
					);
					filterRequest.criteria.push({
						property: Api.ContactFilterCriteriaProperty.OwnedBy,
						value: userId,
					});
				} else {
					emailOptions.sendEmailFrom = Api.SendEmailFrom.ContactOwner;
					emailOptions.sendEmailFromUser = undefined;
					emailOptions.sendEmailFromUserId = undefined;
					setSelectedUser(null);
				}

				if (filterRequest) {
					emailComposer.emailMessage.contactsFilterRequest.contactFilterRequest = filterRequest;
				}
				updateRecipientsApproximation();
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[emailOptions, impersonationContext, updateRecipientsApproximation]
	);

	const onFilterOptions = React.useCallback((options: ISelectOption<string | Api.UserViewModel>[]) => {
		const index = options.findIndex(x => (x.dataContext as Api.UserViewModel)?.id === userSession.user.id);
		if (index >= 0) {
			const opts = options.slice();
			const option = opts[index];
			opts.splice(index, 1, {
				...option,
				text: Api.VmUtils.getDisplayName(userSession.user),
			});
			return opts;
		}
		return options;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderSendOnBehalf = React.useCallback(() => {
		if (emailComposer) {
			return (
				<div className={css(campaignDetailsSummaryStyleSheet.card, baseStyleSheet.verticalStack)}>
					<div className={css(campaignDetailsSummaryStyleSheet.cardTitle)}>Send From</div>
					<UserSelectBox
						defaultOption={SuggestionUserSelectDefaultOption}
						initialSelectedOption={selectedUser ? { dataContext: selectedUser, id: selectedUser.id } : null}
						onFilterOptions={onFilterOptions}
						onOptionSelected={onOptionSelected}
					/>
					{emailComposer.emailMessage.isSendFromOption(Api.SendEmailFrom.ContactOwner) && (
						<DangerMessage iconBackgroundColor='#F3CE0D'>
							Please confirm you want all the contact owners of the recipients to send this email.
						</DangerMessage>
					)}
				</div>
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emailComposer, selectedUser]);
	// #endregion

	const renderContinueWarning = React.useCallback(() => {
		if (emailComposer?.editModeIsActive) {
			return (
				<TinyPopover
					anchor={
						<figure className={css(baseStyleSheet.flex, baseStyleSheet.itemsCenter)}>
							<InfoIcon className={css(styleSheet.infoIcon)} fillColor={brandPrimary} />
						</figure>
					}
					className={css(styleSheet.infoPopover)}
					dismissOnOutsideAction={true}
					toggleOnHover={true}
					autoCloseOtherPopoversOnHover={true}
					type={PopoverType.blue}
					placement={['right', 'top', 'bottom']}
				>
					<p className={css(styleSheet.popoverContent)}>Please save content before proceeding.</p>
				</TinyPopover>
			);
		}
	}, [emailComposer?.editModeIsActive]);

	// #region Send Time
	const [estimatedSchduleSendOverlapsWithExistingWorkload, datePassed] = useEmailWorkloadEstimateErrors(emailComposer);
	const renderSendTimeErrors = React.useCallback(() => {
		if (!estimatedSchduleSendOverlapsWithExistingWorkload && !datePassed) {
			return null;
		}
		return (
			<div className={css(baseStyleSheet.verticalStack, styleSheet.warningContainer)}>
				{estimatedSchduleSendOverlapsWithExistingWorkload && (
					<DangerMessage iconBackgroundColor='#F3CE0D'>
						It looks like you have other emails going out over the selected day(s). We recommend starting sooner.
					</DangerMessage>
				)}
				{datePassed && (
					<DangerMessage iconBackgroundColor={destructive} styles={[styleSheet.errorMessage]}>
						<span>
							The selected date has already passed.
							<br />
							Please select a new date in the future.
						</span>
					</DangerMessage>
				)}
			</div>
		);
	}, [estimatedSchduleSendOverlapsWithExistingWorkload, datePassed]);
	// #endregion

	// #region Actions
	const renderActions = React.useCallback(() => {
		return (
			<div className={css(styleSheet.actions)}>
				{!(impersonationContext?.isValid || complianceSettings?.enabled) && (
					<div className={css(baseStyleSheet.flex)}>
						<button
							className={css(baseStyleSheet.ctaButtonSmall)}
							disabled={datePassed || emailComposer?.editModeIsActive}
							onClick={onApproveClicked}
						>
							<span>Approve & add campaign to calendar</span>
						</button>
						{renderContinueWarning()}
					</div>
				)}
				<div />
				<button
					className={css(baseStyleSheet.linkDestructive, campaignDetailsSummaryStyleSheet.cancelButton)}
					onClick={props.impersonationContext?.isValid ? onCancelClicked : onRejectClicked}
				>
					<span>Delete this suggestion</span>
				</button>
			</div>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [complianceSettings, impersonationContext, datePassed]);

	const renderComplianceActions = React.useCallback(() => {
		{
			return (
				complianceSettings?.enabled && (
					<div className={css(campaignDetailsSummaryStyleSheet.card, campaignDetailsSummaryStyleSheet.recipientsCard)}>
						<div className={css(campaignDetailsSummaryStyleSheet.cardTitle)}>Do you need Compliance Approval?</div>
						<div className={css(campaignDetailsSummaryStyleSheet.buttonActions)}>
							<div className={css(baseStyleSheet.verticalStack)}>
								<div className={css(baseStyleSheet.flex)}>
									<button
										className={css(baseStyleSheet.ctaButton)}
										disabled={datePassed || emailComposer?.editModeIsActive}
										onClick={onApproveWithCompliance}
									>
										<span>Add this to my calendar as &quot;Pending&quot;</span>
									</button>
									{renderContinueWarning()}
								</div>
								<span className={css(styleSheet.complianceDetails)}>
									Or if you don&apos;t need compliance approval,
								</span>
								<button
									className={css(baseStyleSheet.ctaButtonReverse)}
									disabled={datePassed || emailComposer?.editModeIsActive}
									onClick={onApproveClicked}
								>
									<span>Approve & add campaign to calendar</span>
								</button>
							</div>
						</div>
					</div>
				)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [complianceSettings, datePassed]);
	// #endregion

	return (
		<div
			className={`${css(
				campaignDetailsSummaryStyleSheet.container,
				...(styles || [])
			)} suggested-campaign-options-summary ${className || ''}`}
		>
			{renderSendOnBehalf()}
			<div className={css(campaignDetailsSummaryStyleSheet.card, campaignDetailsSummaryStyleSheet.recipientsCard)}>
				<div className={css(campaignDetailsSummaryStyleSheet.cardTitle)}>Recipients</div>
				<div className={css(campaignDetailsSummaryStyleSheet.cardActions)}>
					<div className={css(baseStyleSheet.truncateText)}>
						{`Approximately ${emailComposer.emailMessage?.contactEmailApproximation?.hasEmail ?? 0}`}
					</div>
					{!readOnly && (
						<button className={css(baseStyleSheet.brandLink)} onClick={onEditRecipientsClicked}>
							<span>{`Preview${!impersonationContext?.isValid ? '/Edit' : ''}`}</span>
						</button>
					)}
				</div>
				<div className={css(baseStyleSheet.verticalStack)}>
					<TagEmailTagList
						emailComposer={emailComposer}
						readOnly={true}
						styles={[campaignDetailsSummaryStyleSheet.tagList]}
					/>
					{!emailComposer.emailMessage.isSendFromOption(Api.SendEmailFrom.ContactOwner) && (
						<DangerMessage iconBackgroundColor='#F3CE0D'>
							{emailComposer?.emailMessage?.isApproximating ? (
								<LoadingSpinner type='tiny' />
							) : (
								<span>
									{`By default, we will only send to contacts owned by the selected user ( ${
										emailComposer.emailMessage?.contactEmailApproximation?.hasEmail ?? 0
									} recipients). Admins can edit this to be all contact including the ones they don't own.`}
								</span>
							)}
						</DangerMessage>
					)}
				</div>
			</div>
			{!!emailOptions?.scheduledSend && (
				<div className={css(campaignDetailsSummaryStyleSheet.card)}>
					<div className={css(campaignDetailsSummaryStyleSheet.cardTitle, styleSheet.cardTitleInfo)}>
						Send Time
						{renderContinueWarning()}
					</div>
					<div className={css(campaignDetailsSummaryStyleSheet.cardActions)}>
						<span>{moment(emailOptions?.scheduledSend.startDate).format('dddd, MMMM Do, h:mm A')}</span>
						<button
							className={css(baseStyleSheet.brandLink)}
							disabled={emailComposer?.editModeIsActive}
							onClick={onChangeScheduleClicked}
						>
							<span>Change</span>
						</button>
					</div>
					<div className={css(styleSheet.sendTimeDetails)}>
						{!emailOptions?.minimumDurationInDays
							? 'Send as quickly as possible'
							: `Spread out over ${emailOptions?.minimumDurationInDays} days`}
					</div>
					{renderSendTimeErrors()}
				</div>
			)}
			{renderComplianceActions()}
			{renderActions()}
		</div>
	);
});

export const SuggestedCampaignOptionsSummary = inject(ImpersonationContextKey)(SuggestedCampaignOptionsSummaryBase);
