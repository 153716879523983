import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		display: 'flex',
		justifyContent: 'center',
		margin: '30px auto',
		maxWidth: 800,
		...CssSelectors.allChildren({
			width: '100%',
		}),
	},
});
