import { emailActivityTintColor } from '../../../styles/colors';
import { EmailIcon } from '../../svgs/icons/EmailIcon';
import { styleSheet } from './styles';
import { IPushNotificationMessage } from '@AppModels/.';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	pushMessage: IPushNotificationMessage;
}

export const EmailReadOrOpenedToast: React.FC<IProps> = props => {
	const { className, styles, pushMessage } = props;
	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} email-read-or-opened-toast ${className || ''}`}>
			<EmailIcon className={css(styleSheet.icon)} fillColor={emailActivityTintColor} />
			<div className={css(styleSheet.body)}>
				{!!pushMessage.title && <div className={css(styleSheet.title)}>{pushMessage.title}</div>}
				<div className={css(styleSheet.message)}>{pushMessage.message}</div>
			</div>
		</div>
	);
};
