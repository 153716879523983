import {
	AutoCompleteSearchField,
	IAutoCompleteSearchFieldComponent,
} from '../../../autocomplete/AutoCompleteSearchField';
import { ClearFieldIcon } from '../../../svgs/icons/ClearFieldIcon';
import { SearchIcon } from '../../../svgs/icons/SearchIcon';
import { styleSheet } from './styles';
import './styles.less';
import { AutoCompleteResultFilter, ResourceAutoCompleteViewModelType } from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';

interface IProps<TItem = any> {
	className?: string;
	disabled?: boolean;
	filter?: AutoCompleteResultFilter<TItem>;
	inputId: string;
	/**
	 * Set this to null to not render a left accessory, even if showSearchLeftAccessory === true. Setting this to
	 * undefined and showSearchLeftAccessory === true will render the default leftAccessory (search icon)
	 */
	leftAccessory?: React.ReactNode;
	onClearButtonClicked?(e: React.MouseEvent<HTMLElement>): void;
	onFocus?(): void;
	onInputRef?(ref: HTMLInputElement): void;
	onItemSelected?(item: TItem): void;
	placeholder?: string;
	resultsLimit?: number;
	showClearButton?: boolean;
	showSearchLeftAccessory?: boolean;
	hideResultsFooter?: boolean;
	type?: ResourceAutoCompleteViewModelType;
	value?: string;
}

interface IState {
	leftAccessory?: React.ReactNode;
	showClearButton?: boolean;
	type?: ResourceAutoCompleteViewModelType;
	value?: string;
}

class _EddOpportunitySearchField extends React.Component<IProps, IState> {
	// @ts-ignore
	private innerSearchFieldRef: IAutoCompleteSearchFieldComponent;
	public static defaultProps: Partial<IProps> = {
		resultsLimit: 5,
	};
	public state: IState = {};

	public UNSAFE_componentWillMount() {
		const nextState = this.getNextStateWithProps(this.props);
		if (nextState) {
			this.setState(nextState);
		}
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		const nextState = this.getNextStateWithProps(nextProps);
		if (nextState) {
			this.setState(nextState);
		}
	}

	public UNSAFE_componentWillUpdate() {
		if (this.innerSearchFieldRef) {
			setTimeout(() => {
				if (!!this.innerSearchFieldRef && !this.state.value) {
					this.innerSearchFieldRef.clearInput();
				}
			});
		}
	}

	public render() {
		const {
			className,
			onItemSelected,
			inputId,
			resultsLimit,
			showSearchLeftAccessory,
			placeholder,
			onFocus,
			disabled,
			filter,
			value: propsValue,
			hideResultsFooter,
		} = this.props;
		const { value, type, showClearButton, leftAccessory } = this.state;
		let computedLeftAccessory: React.ReactNode = null;
		if (showSearchLeftAccessory) {
			if (leftAccessory === undefined) {
				computedLeftAccessory = <SearchIcon className={css(styleSheet.leftAccessory)} />;
			} else if (leftAccessory !== null) {
				computedLeftAccessory = <div className={css(styleSheet.leftAccessory)}>{leftAccessory}</div>;
			}
		}

		// disable input when a value is set
		const disableInput = !!disabled || !!propsValue;
		// @ts-ignore
		const inputStyle: React.CSSProperties = propsValue ? { pointerEvents: 'none' } : undefined;

		return (
			<AutoCompleteSearchField
				autoCompleteFilter={filter}
				dropdownClassName={css(styleSheet.dropdownContent)}
				anchorClassName={className}
				disableAutocomplete={disableInput}
				hideResultsFooter={hideResultsFooter}
				inputId={inputId}
				inputProps={{
					disabled,
					onFocus,
					style: inputStyle,
					value: value || '',
				}}
				leftAccessory={computedLeftAccessory}
				onInnerRef={this.onInnerSearchFieldRef}
				onInputRef={this.onInputRef}
				onItemSelected={onItemSelected}
				placeholder={placeholder}
				resultsLimit={resultsLimit}
				rightAccessory={!!showClearButton && !disabled ? this.onRenderFieldClearButton() : null}
				type={type}
			/>
		);
	}

	private getNextStateWithProps = (nextProps: IProps) => {
		const nextState: IState = {};
		if (nextProps.value !== this.state.value) {
			nextState.value = nextProps.value;
		}

		if (nextProps.type !== this.state.type) {
			nextState.type = nextProps.type;
		}

		if (nextProps.showClearButton !== this.state.showClearButton) {
			nextState.showClearButton = nextProps.showClearButton;
		}

		if (nextProps.leftAccessory !== this.state.leftAccessory) {
			nextState.leftAccessory = nextProps.leftAccessory;
		}

		if (Object.keys(nextState).length > 0) {
			return nextState;
		}

		return null;
	};

	private onInnerSearchFieldRef = (ref: IAutoCompleteSearchFieldComponent) => {
		this.innerSearchFieldRef = ref;
	};

	private onRenderFieldClearButton() {
		return (
			<button className='edit-opportunity-search-field-clear-button' onClick={this.onClearButtonClicked}>
				<ClearFieldIcon />
			</button>
		);
	}

	private onClearButtonClicked = (e: React.MouseEvent<HTMLElement>) => {
		if (this.props.onClearButtonClicked) {
			this.props.onClearButtonClicked(e);
		} else {
			this.setState({
				// @ts-ignore
				value: null,
			});
		}

		if (this.innerSearchFieldRef) {
			this.innerSearchFieldRef.clearInput();
		}
	};

	private onInputRef = (ref: HTMLInputElement) => {
		const { onInputRef } = this.props;
		if (onInputRef) {
			onInputRef(ref);
		}
	};
}

export const EditOpportunitySearchField = observer(_EddOpportunitySearchField);
