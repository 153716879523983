import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	clearButton: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
	},
	dropdownContent: {
		height: 40,
		maxWidth: '100%',
		width: '100%',
	},
	leftAccessory: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		marginRight: 10,
	},
	searchIcon: {
		height: 16,
		width: 16,
	},
});
