import { Countries } from '../../ValueSelectors/CountrySelector';
import { EntityInfoField } from '../EntityInfoField';
import { styleSheet } from './styles';
import { IAddress } from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	address?: IAddress;
	className?: string;
}

export class EntityAddress extends React.PureComponent<IProps> {
	public render() {
		const { className, address } = this.props;
		if (!address) {
			return null;
		}

		const countryName = (
			Countries.find(x => x.value === address.country) || {
				name: address.country,
			}
		).name;
		return (
			<div className={`${css(styleSheet.container)} entity-address ${className || ''}`}>
				{(!!address.address1 || !!address.address2) &&
					this.renderLabeledField(
						'Address',
						<div className='truncate-text'>
							{!!address.address1 && <span>{address.address1}</span>}
							{!!address.address1 && !!address.address2 && <span>, &nbsp;</span>}
							{!!address.address2 && <span>{address.address2}</span>}
						</div>
					)}
				{this.renderLabeledField('City', address.city)}
				{this.renderLabeledField('State/Province', address.stateProvince)}
				{this.renderLabeledField('Zip/Postal Code', address.postalCode)}
				{this.renderLabeledField('Country', countryName)}
			</div>
		);
	}

	private renderLabeledField(label: string, value: React.ReactNode, truncateText = true) {
		return <EntityInfoField label={label} truncateText={truncateText} value={value} />;
	}
}
