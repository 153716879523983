import { brandPrimaryHover, titles } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	button: {
		border: `1px solid ${brandPrimaryHover}`,
		borderRadius: 3,
		boxShadow: 'none',
		color: brandPrimaryHover,
	},
	circle: {
		alignItems: 'center',
		background: 'white',
		borderRadius: 30,
		display: 'flex',
		height: 60,
		justifyContent: 'center',
		width: 60,
	},
	container: {
		background: '#dbf2c3',
		borderLeft: '3px solid #78DBFF',
		borderRadius: 3,
		boxShadow: '0px 0px 6px rgba(139, 139, 139, 0.100034)',
		color: '#FFF',
		padding: 10,
		paddingLeft: 30,
	},
	title: {
		color: titles,

		fontSize: 18,
		marginLeft: 20,
	},
	titleContainer: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'flex-start',
		marginBottom: 10,
		marginTop: 10,
	},
	titleMessage: {
		color: titles,
		display: 'flex',
		flexWrap: 'wrap',
		fontSize: 14,
		justifyContent: 'flex-start',
		marginLeft: 20,
		marginTop: 5,
	},
});
