import * as React from 'react';

export function FileImagePreview({
	file,
	loadingEl,
	...props
}: { file: File; loadingEl?: JSX.Element } & React.DetailedHTMLProps<
	React.ImgHTMLAttributes<HTMLImageElement>,
	HTMLImageElement
>) {
	const [previewUrl, setPreviewUrl] = React.useState<string | null>(null);
	React.useEffect(() => {
		if (!file || !file.type.includes('image/')) {
			setPreviewUrl(null);
			return;
		}
		const reader = new FileReader();
		reader.onload = () => {
			setPreviewUrl(reader.result as string);
		};
		reader.readAsDataURL(file);
	}, [file]);
	if (!previewUrl) {
		return loadingEl ?? null;
	}
	return <img src={previewUrl} alt={file.name} {...props} />;
}
