import { titles } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	avatars: {},
	body: {
		color: '#8E8E8E',
		fontSize: 14,
		marginTop: 16,
	},
	container: {},
	creatorHeader: {
		alignItems: 'center',
		display: 'flex',
		marginBottom: 10,
	},
	creatorName: {
		color: titles,
	},
	emailHeader: {
		marginBottom: 10,
	},
	header: {
		color: titles,
		fontSize: 18,
		maxWidth: '100%',
		minWidth: 0,
	},
	privacyIcon: {
		marginRight: 10,
	},
	title: {
		alignItems: 'center',
		display: 'flex',
		flexGrow: 1,
	},
});
