import { MeetingConfigViewModel } from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { useCallback } from 'react';
import { ErrorMessagesViewModelKey, IErrorMessageComponentProps } from '../../../../../models/AppState';
import { convertDurationToString, copyToClipboard } from '../../../../../models/UiUtils';
import { useToaster } from '../../../../../models/hooks/appStateHooks';
import { getDurationText } from '../../../../../scheduleMeeting/UiUtils';
import { brandPrimary, grayIconFill } from '../../../../styles/colors';
import { baseStyleSheet } from '../../../../styles/styles';
import { LoadingSpinner } from '../../../LoadingSpinner';
import { MoreMenu, MoreMenuItem } from '../../../MoreMenu';
import { ClipboardIcon } from '../../../svgs/icons/ClipboardIcon';
import { ClockIcon } from '../../../svgs/icons/ClockIcon';
import { DeleteConfirmation } from '../../../DeleteConfirmation';
import { styleSheet } from './styles';

interface IProps extends IErrorMessageComponentProps {
	meeting: MeetingConfigViewModel;
	onDelete(meeting: MeetingConfigViewModel): void;
	onEdit(meeting: MeetingConfigViewModel): void;
}

const MeetingConfigCardBase: React.FC<IProps> = ({ meeting, onDelete, onEdit }) => {
	const toaster = useToaster();
	const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = React.useState(false);

	const onDeleteConfirm = (confirmedDelete: boolean) => {
		if (confirmedDelete) {
			onDelete(meeting);
		}
		setIsDeleteConfirmationOpen(false);
	};

	const onCopyLinkClick = useCallback(() => {
		const done = copyToClipboard(meeting.link);
		toaster.push({
			message: done ? `Copied meeting link to clipboard!` : 'Unable to copy to clipboard.',
			type: done ? 'successMessage' : 'errorMessage',
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [meeting]);

	return (
		<div className={css(styleSheet.container)}>
			<div className={css(styleSheet.moreMenu)}>
				<MoreMenu>
					<MoreMenuItem onClick={() => onEdit(meeting)}>Edit</MoreMenuItem>
					<MoreMenuItem onClick={() => setIsDeleteConfirmationOpen(true)}>Delete</MoreMenuItem>
				</MoreMenu>
			</div>

			<h3 className={css(styleSheet.header)}>{meeting.name}</h3>
			<div className={css(styleSheet.clockContainer)}>
				<ClockIcon className={css(styleSheet.clockIcon)} fillColor={grayIconFill} />
				<span className={css(styleSheet.clockText)}>{getDurationText(convertDurationToString(meeting.duration))}</span>
			</div>
			<div className={css(styleSheet.link)}>
				<a
					className={css(baseStyleSheet.brandLink, !meeting.isPublished && styleSheet.linkDisabled)}
					href={meeting.isPublished ? meeting.link : null}
					target='_blank'
					rel='noreferrer'
				>
					{meeting.isPublished ? 'View meeting page' : 'Unpublished'}
				</a>
			</div>
			<button className={css(baseStyleSheet.ctaButtonReverseSmall)} onClick={onCopyLinkClick}>
				<span>
					<ClipboardIcon className={css(styleSheet.clipboardIcon)} fillColor={brandPrimary} />
					Copy Meeting Link
				</span>
			</button>
			{meeting.isBusy && <LoadingSpinner type='small' className={css(baseStyleSheet.absoluteCenter)} />}

			<DeleteConfirmation
				bodyText={`Are you sure you want to delete the "${meeting.name}" meeting?`}
				isOpen={isDeleteConfirmationOpen}
				onFinish={onDeleteConfirm}
			/>
		</div>
	);
};

const MeetingConfigCardAsObserver = observer(MeetingConfigCardBase);
export const MeetingConfigCard = inject(ErrorMessagesViewModelKey)(MeetingConfigCardAsObserver);
