import { IUserSessionComponentProps } from '../../../../../models/AppState';
import { numberToFormattedCount } from '../../../../../models/UiUtils';
import { Tag } from '../../../../components/tags/Tag';
import { baseStyleSheet } from '../../../../styles/styles';
import { useCompaniesSearch } from '../CompaniesContext';
import { styleSheet } from './styles';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';

interface IProps extends IUserSessionComponentProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const CompaniesSearchDescription: React.FC<IProps> = observer(props => {
	const { className, styles } = props;
	const { filter, companies } = useCompaniesSearch();

	if (!companies.companies) {
		return null;
	}

	const search =
		filter.criteria?.find(x => x.property === Api.CompanySearchRequestProperty.Name)?.value ??
		filter.criteria?.find(x => x.property === Api.CompanySearchRequestProperty.All)?.value ??
		'';

	const tag = filter.criteria?.find(x => x.property === Api.CompanySearchRequestProperty.Tag)?.value;

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
			<span className={css(baseStyleSheet.fontBold)}>{numberToFormattedCount(companies.totalNumberOfResults)}</span>
			&nbsp;
			{companies.totalNumberOfResults === 1 ? 'company' : 'companies'}
			&nbsp;
			{!!search && (
				<span>
					{' for "'}
					<span className={css(baseStyleSheet.fontBold)}>{search + '"'}</span>
					&nbsp;
				</span>
			)}
			{!!tag && (
				<span className={css(styleSheet.tag)}>
					{' with the tag '}
					&nbsp;
					<Tag tagValue={tag} />
					&nbsp;
				</span>
			)}
		</div>
	);
});
