import { error, errorLight } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		background: errorLight,
		border: `1px solid ${error}`,
		borderRadius: 4,
		display: 'flex',
		maxWidth: 500,
		padding: 10,
		...CssSelectors.allChildren(
			{
				paddingRight: 10,
			},
			'div:first-child'
		),
	},
	headerContainer: {
		alignItems: 'center',
		display: 'flex',
		fontSize: '14px',
	},
});
