import { brandPrimaryHover } from '../../../../styles/colors';
import { CssSelectors, truncateTextStyle } from '../../../../styles/styles';
import { StyleSheet } from 'aphrodite';

const modalPadding = 20;

export const styleSheet = StyleSheet.create({
	backButton: {
		alignItems: 'center',
		color: brandPrimaryHover,
		display: 'flex',
		fontSize: 14,
		marginBottom: 20,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	body: {
		height: 'calc(100% - 20px)',
	},
	container: {
		maxWidth: 860,
		minWidth: 840,
	},
	guide: {
		'@media only screen and (max-height: 768px)': {
			height: 580,
		},
		height: 650,
	},
	guidedComposerContactContext: {
		height: '100%',
	},
	guidedComposerContainer: {
		display: 'flex',
		height: '100%',
	},
	guidedComposerContainerColumn: {
		':not(:first-child)': {
			marginLeft: 30,
		},
		width: 'calc(50% - 15px)',
	},
	header: {
		alignItems: 'center',
		display: 'flex',
		height: 20,
		justifyContent: 'flex-end',
		textAlign: 'right',
	},
	sendComplete: {
		...CssSelectors.allChildren(
			{
				height: `calc(50% + ${modalPadding}px)`,
				margin: `0 -${modalPadding}px -${modalPadding}px -${modalPadding}px`,
			},
			'.single-email-send-complete-footer'
		),
	},
	sendMessageComposer: {
		height: '100%',
	},
	sendMessageComposerHeader: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
	},
	sendMessageComposerHeaderName: {
		maxWidth: 150,
		...truncateTextStyle,
	},
	sendMessageComposerHeaderNotSureButton: {
		alignItems: 'center',
		color: brandPrimaryHover,
		display: 'flex',
		fontSize: 14,
	},
	sendMessageComposerHeaderTitle: {
		alignItems: 'center',
		display: 'flex',
	},
	sendMessageComposerModal: {
		...CssSelectors.allDescendants(
			{
				maxHeight: 'inherit',
				maxWidth: 'inherit',
			},
			'.modal-overlay-content'
		),
	},
	warningModalMessage: {
		textAlign: 'center',
	},
});
