import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { DeprecatedMoreMenu } from '../../DeprecatedMoreMenu';
import { IMoreMenuItem } from '../../MoreMenu';
import { ColorCodedCard } from '../ColorCodedCard';
import { styleSheet } from './styles';

export interface IFeedCardCallbackProps {
	onRequestRemove?(): void;
	onShowDeleteConfirmation?(performDelete: () => void): void;
	onShowWasReminderHelpfulModal?(): void;
	onSuppressClicked?(): void;
}

export interface IFeedCardMoreMenuProps<T = any> {
	moreMenuItems?: IMoreMenuItem<T>[];
	onMoreMenuItemClicked?(menuItem: IMoreMenuItem<T>, e: React.MouseEvent<HTMLElement>): void;
}

interface IProps<T = any> extends IFeedCardMoreMenuProps<T> {
	className?: string;
	footer?: React.ReactNode;
	hideHeader?: boolean;
	indicatorColor?: string;
	onClick?(e: React.MouseEvent<HTMLElement>): void;
	onSuppressClicked?(): void;
	showSuppressOption?: boolean;
	styles?: StyleDeclarationValue[];
}

export class FeedCard<T = any> extends React.PureComponent<IProps<T>> {
	public render() {
		const {
			className,
			children,
			indicatorColor,
			footer,
			onClick,
			hideHeader,
			onSuppressClicked,
			showSuppressOption,
			moreMenuItems,
			onMoreMenuItemClicked,
			styles,
			...restProps
		} = this.props;
		return (
			<ColorCodedCard
				className={`feed-card ${className || ''}`}
				footer={footer}
				header={this.renderHeader()}
				hideHeader={!!hideHeader}
				indicatorColor={indicatorColor}
				onClick={onClick}
				styles={[styleSheet.container, ...(styles || [])]}
				{...restProps}
			>
				{children}
			</ColorCodedCard>
		);
	}

	private renderHeader() {
		const { moreMenuItems, onMoreMenuItemClicked, showSuppressOption, onSuppressClicked } = this.props;
		// @ts-ignore
		const showMoreMenu = !!onMoreMenuItemClicked && moreMenuItems?.length > 0 && moreMenuItems?.some(x => !!x);

		if (!showSuppressOption && !showMoreMenu) {
			return null;
		}

		return (
			<div className={css(styleSheet.header)}>
				{!!showMoreMenu && <DeprecatedMoreMenu menuItems={moreMenuItems} onMenuItemClicked={onMoreMenuItemClicked} />}
				{!showMoreMenu && !!showSuppressOption && onSuppressClicked && (
					<DeprecatedCloseButton onClick={onSuppressClicked} />
				)}
			</div>
		);
	}
}
