import { StyleSheet } from 'aphrodite';
import { titles } from '../../../../styles/colors';
import { CssSelectors } from '../../../../styles/styles';

export const styleSheet = StyleSheet.create({
	container: {
		position: 'relative',
	},
	exchangeLogo: {
		backgroundImage: "url('../../../assets/exchange_logo.png')",
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 171,
		display: 'block',
		height: 53,
		margin: '20px 0 20px 0',
		width: 171,
	},
	footer: {
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	genericEmailLogo: {
		backgroundImage: "url('../../../assets/email_graphic.png')",
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 97,
		display: 'block',
		height: 97,
		margin: '20px 0 20px 0',
		width: 97,
	},
	googleLogo: {
		backgroundImage: "url('../../../assets/google_logo_large.png')",
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 140,
		display: 'block',
		height: 47,
		margin: '20px 0 20px 0',
		width: 140,
	},
	importNow: {},
	importNowButton: {
		marginTop: 20,
	},
	importNowRadioButtons: {
		marginBottom: 20,
	},
	o365Logo: {
		backgroundImage: "url('../../../assets/office_365_logo.png')",
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 140,
		display: 'block',
		height: 47,
		margin: '20px 0 20px 0',
		width: 140,
	},
	secondaryMessage: {
		color: '#858585',
		fontSize: 14,
		lineHeight: '22px',
	},
	sections: {
		...CssSelectors.allChildren(
			{
				marginTop: 28,
			},
			':not(:first-child)'
		),
	},
	sectionSubHeader: {
		color: titles,
		fontSize: '0.875rem',
		marginTop: '0.5rem 0 2rem',
	},
});
