import { brandPrimaryText } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';

type Props = {
	fillColor?: string;
	height?: number;
	width?: number;
};

export const HatIcon = ({ fillColor = brandPrimaryText, height = 20, width = 20 }: Props) => {
	return (
		<SvgIcon height={height} width={width} viewBox='0 0 20 20'>
			<g fill={fillColor} fillRule='evenodd'>
				<path d='M9.99870002,6 C4.74870002,6 1.99870002,4.514 1.99870002,4 C1.99870002,3.486 4.74870002,2 9.99870002,2 C15.2487,2 17.9987,3.486 17.9987,4 C17.9987,4.514 15.2487,6 9.99870002,6 L9.99870002,6 Z M14.9987,9.543 C12.6407,11.476 7.35670002,11.476 4.99870002,9.543 L4.99870002,7.516 C6.54870002,7.84 8.29470002,8 9.99870002,8 C11.7027,8 13.4487,7.84 14.9987,7.516 L14.9987,9.543 Z M4.99870002,16.557 L4.99870002,11.904 C6.43870002,12.636 8.21470002,13.011 9.99870002,13.011 C11.7827,13.011 13.5587,12.636 14.9987,11.904 L14.9987,16.557 C12.6397,18.48 7.35770002,18.48 4.99870002,16.557 L4.99870002,16.557 Z M19.9987,4 C19.9987,1.252 14.8147,0 9.99870002,0 C5.18270002,0 -0.00129997616,1.252 -0.00129997616,4 C-0.00129997616,5.325 1.20970002,6.299 2.99870002,6.956 L2.99870002,17 C2.99870002,17.266 3.10470002,17.52 3.29170002,17.707 C4.82770002,19.243 7.41370002,20.011 9.99870002,20.011 C12.5837,20.011 15.1697,19.243 16.7057,17.707 C16.8937,17.52 16.9987,17.266 16.9987,17 L16.9987,6.956 C18.7877,6.299 19.9987,5.325 19.9987,4 L19.9987,4 Z' />
				<path d='M12.9987,3 L6.99870002,3 C6.44670002,3 5.99870002,3.448 5.99870002,4 C5.99870002,4.552 6.44670002,5 6.99870002,5 L12.9987,5 C13.5507,5 13.9987,4.552 13.9987,4 C13.9987,3.448 13.5507,3 12.9987,3' />
			</g>
		</SvgIcon>
	);
};
