import { StyleSheet } from 'aphrodite';
import { brandPrimary, brandPrimaryText, navigation } from '../../../styles/colors';
import { BrowserMediaQueries, CssSelectors } from '../../../styles/styles';

const MinBodyInputsWidth = 450;
const MaxBodyInputsWidth = 600;
export const styleSheet = StyleSheet.create({
	body: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		marginTop: 16,
	},
	bodyContextTabView: {
		':nth-child(1n).tab-view': {
			flexGrow: 1,
			width: '100%',
		},
	},
	bodyEditor: {
		flexBasis: 0,
		flexGrow: 1,
		[BrowserMediaQueries.IE11]: {
			// IE11 BS... no position: sticky
			...CssSelectors.allDescendants(
				{
					maxWidth: `${MaxBodyInputsWidth - 2}px`,
					minWidth: MinBodyInputsWidth,
				},
				'.tox.tox-tinymce .tox-editor-container .tox-editor-header'
			),
		},
	},
	bodyInputs: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		flexShrink: 0,
		maxHeight: '100%',
		minWidth: MinBodyInputsWidth,
		position: 'relative',
		...CssSelectors.allDescendants(
			{
				fontSize: 14,
			},
			'input'
		),
		...CssSelectors.allChildren(
			{
				marginTop: 10,
			},
			':not(:first-child)'
		),
	},
	composerContainer: {
		display: 'flex',
		height: '100%',
		width: '100%',
	},
	composerHidden: {
		display: 'none',
	},
	emailAutomationGraphic: {
		display: 'block',
		marginBottom: '1.5rem',
	},
	footer: {
		marginTop: 20,
	},
	header: {
		color: brandPrimaryText,
		fontSize: 24,
		margin: 0,
		padding: 0,
	},
	leftContainer: {
		border: '1px solid rgb(202, 224, 236)',
		borderRadius: 3,
		boxShadow: 'rgb(232, 243, 249) 0px 0px 12px 0px',
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		padding: '1rem',
	},
	modalBody: {
		boxSizing: 'border-box',
		display: 'flex',
		height: 'calc(100% - 50px)',
		padding: '1rem',
	},
	modalContent: {
		backgroundColor: 'white',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: '100%',
	},
	modalHeader: {
		alignItems: 'center',
		backgroundColor: brandPrimary,
		display: 'flex',
		flex: '0 0 50px',
		padding: '0 1rem',
	},
	modalHeaderTitle: {
		boxSizing: 'border-box',
		color: 'white',
		flex: 1,
		fontSize: 20,
		margin: 0,
		padding: 0,
		textAlign: 'center',
	},
	modalOverlay: {
		height: '100%',
		width: '100%',
	},
	modalRoot: {
		bottom: 0,
		left: 0,
		position: 'fixed',
		right: 0,
		top: 0,
		zIndex: 5,
	},
	rightContainer: {
		boxSizing: 'border-box',
		display: 'flex',
		flex: '0 1 400px',
		justifyContent: 'center',
		overflow: 'hidden',
		padding: '1rem',
	},
	rightContainerPlaceholder: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		paddingTop: '7rem',
	},
	rightInfoText: {
		color: navigation,
		fontSize: '0.75rem',
		textAlign: 'center',
		width: 167,
	},
	scheduleSendContainer: {
		height: '100%',
		width: '100%',
	},
	sendButton: {
		height: 40,
	},
});
