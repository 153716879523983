import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { Dropdown } from '../../../Dropdown';
import { DisclosureIcon } from '../../../svgs/icons/DisclosureIcon';
import { styleSheet } from './styles';
import { styleSheet as dropdownStyles } from '../../DropdownComponent/styles';
import { CustomDateRange } from '../../../CustomDateRange';

export const RenewalDateFilterDropdown = ({
	selectedMinimumDate,
	setSelectedMinimumDate,
	selectedMaximumDate,
	setSelectedMaximumDate,
}: {
	selectedMinimumDate: Date;
	setSelectedMinimumDate: React.Dispatch<React.SetStateAction<Date>>;
	selectedMaximumDate: Date;
	setSelectedMaximumDate: React.Dispatch<React.SetStateAction<Date>>;
}) => {
	const [isOpen, setIsOpen] = React.useState(false);

	const dropdownMessage = React.useMemo(() => {
		if (!selectedMinimumDate || !selectedMaximumDate) {
			return 'Renewal Date';
		}
		const selectedMinimumDateString = moment(selectedMinimumDate).format('MM/DD');
		const selectedMaximumDateString = moment(selectedMaximumDate).format('MM/DD');
		return `Renews ${selectedMinimumDateString} - ${selectedMaximumDateString}`;
	}, [selectedMinimumDate, selectedMaximumDate]);

	const resetFilter = () => {
		setSelectedMinimumDate(null);
		setSelectedMaximumDate(null);
	};

	return (
		<Dropdown
			anchor={
				<div className={css(dropdownStyles.dropdownAnchor)}>
					<span>{dropdownMessage}</span>
					<DisclosureIcon className={css(dropdownStyles.dropdownIcon)} />
				</div>
			}
			disabled={false}
			className={css(dropdownStyles.dropdownInline, isOpen ? dropdownStyles.dropdownInlineOpen : null)}
			contentClassName={css(dropdownStyles.dropdownMenu, styleSheet.dropdownMenuOverrides)}
			contentPositionY='bottom'
			contentInnerClassName={css(styleSheet.dropdownContentInnerOverrides)}
			isOpen={isOpen}
			onOpenChanged={() => setIsOpen(!isOpen)}
			openOnClick={true}
		>
			<div className={css(dropdownStyles.dropdown)}>
				<div className={css(dropdownStyles.dropdownSeparator)} />

				<div>
					<CustomDateRange
						endDateRequired
						from={selectedMinimumDate || null}
						to={selectedMaximumDate || null}
						onChange={(from, to) => {
							setSelectedMinimumDate(from);
							if (to !== from) {
								setSelectedMaximumDate(to);
							}
						}}
					/>
				</div>

				<button onClick={resetFilter}>Reset Filter</button>
			</div>
		</Dropdown>
	);
};
