import { css } from 'aphrodite';
import { DeprecatedCloseButton } from '../../components/DeprecatedCloseButton';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { baseStyleSheet } from '../../styles/styles';
import { styleSheet } from './styles';

export const ImagePreviewSmall = ({
	onRemoveClick,
	isLoading,
	name,
	url,
}: {
	onRemoveClick: () => void;
	isLoading?: boolean;
	name?: string;
	url: string;
}) => {
	return (
		<>
			{!isLoading ? (
				<div className={css(styleSheet.logoWrap)}>
					<img className={css(styleSheet.logoImage)} src={url} alt={name} />{' '}
					<p className={css(baseStyleSheet.truncateText, styleSheet.noMargin, styleSheet.logoName)}>{name}</p>
					<DeprecatedCloseButton type='button' onClick={onRemoveClick} className={css(styleSheet.logoRemove)} />
				</div>
			) : (
				<>
					<LoadingSpinner type='tiny' />
				</>
			)}
		</>
	);
};
