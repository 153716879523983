import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEventLogging } from '../../../../models/Logging';
import { useErrorMessages } from '../../../../models/hooks/appStateHooks';
import { TagsListWithTagsEditor } from '../../../components/entities/tags/TagsEditor';
import { TagIcon } from '../../../components/svgs/icons/TagIcon';
import { bs } from '../../../styles/styles';

interface Props {
	companyVm: Api.CompanyViewModel;
}

function _CompanyInfoTags({ companyVm }: Props) {
	const [tags, setTags] = React.useState<Api.IAccountTag[] | null>(null);
	const errorMessages = useErrorMessages();
	const { logEvent } = useEventLogging('ContactInfoKeyFactsAndTags');
	const updateTags = (newTags: Api.IAccountTag[], eventName: string) => {
		const promise = companyVm.updateTags(newTags.map(x => x.tag));
		if (promise) {
			logEvent(eventName, { count: newTags.length });
			// set override
			setTags(newTags);
			promise
				.then(() => {
					setTags(null);
				})
				.catch((error: Api.IOperationResultNoValue) => {
					setTags(null);
					logEvent(`${eventName}-Error`, { ...error });
					errorMessages.pushApiError(error);
				});
		}
	};
	const onRequestAddTag = (accountTag: Api.IAccountTag) => {
		if (
			companyVm.tags?.length &&
			accountTag?.tag &&
			companyVm.tags.find(x => x.toLocaleLowerCase() === accountTag.tag.toLocaleLowerCase())
		) {
			return;
		}
		const newTags: Api.IAccountTag[] = [...(companyVm.tags.map(tag => ({ tag })) || [])];
		newTags.push(accountTag);
		updateTags(newTags, 'AddTag');
	};
	const onRequestRemoveTag = (accountTag: Api.IAccountTag) => {
		const newTags = [...(companyVm.tags.map(tag => ({ tag })) || [])];
		const index = newTags.findIndex(x => x.tag === accountTag.tag);
		if (index >= 0) {
			newTags.splice(index, 1);
			updateTags(newTags, 'RemoveTag');
		}
	};
	return (
		<div className={css(bs.flex, bs.justifyStart)}>
			<TagIcon className={css(bs.flexShrink0, bs.mr4, bs.mt3, bs.w4)} />
			<TagsListWithTagsEditor
				tagType={Api.ResourceAutoCompleteViewModelType.AccountTag}
				className={css(bs.flex1)}
				disabled={!!companyVm.isBusy}
				entity={companyVm}
				onRequestAddTag={onRequestAddTag}
				onRequestRemoveTag={onRequestRemoveTag}
				tags={tags || companyVm?.tags?.map((tag: string) => ({ tag })) || []}
			/>
		</div>
	);
}

export const CompanyInfoTags = observer(_CompanyInfoTags);
