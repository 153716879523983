import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { DragDropContext, DropResult, Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { IDictionary, IOperationResultNoValue } from '../../../../extViewmodels/sdk/models';
import {
	IBoardShowArchivedStorage,
	IModalContext,
	ModalChildComponentContextKey,
	PersistentStorageBoardShowArchivedKey,
} from '../../../../models';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { EventLogger } from '../../../../models/Logging';
import { PersistentStorageManager } from '../../../../models/Storage';
import {
	BoardStageDefinitionViewModel,
	BoardStageViewModel,
	BoardViewModel,
	EditBoardViewModel,
} from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import { AddButton } from '../../AddButton';
import { Checkbox } from '../../Checkbox';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { LoadingSpinner } from '../../LoadingSpinner';
import { asModalComponent } from '../../Modal';
import { DeleteStageConfirmationModal } from '../DeleteStageConfirmation';
import { EditBoardColumnDefinition } from '../EditBoardColumnDefinition';
import { TextInput } from '../../TextInput';
import { styleSheet } from './styles';

interface IProps extends IModalContext<BoardViewModel>, IUserSessionComponentProps, IErrorMessageComponentProps {
	board?: BoardViewModel;
	className?: string;
	initialColumns?: BoardStageDefinitionViewModel[];
	onCreateBoard?(name: string, columns: BoardStageDefinitionViewModel[]): Promise<BoardViewModel>;
	onEditBoard?(board: BoardViewModel, name: string, columns: BoardStageDefinitionViewModel[]): Promise<BoardViewModel>;
}

interface IState {
	showArchived?: boolean;
	editBoardVm?: EditBoardViewModel;
	queuedItemMoves?: IDictionary<BoardStageViewModel>;
	saving?: boolean;
	stageToDelete?: BoardStageViewModel;
}

interface IEditBoardColumnsContext {
	createBoardVm?: EditBoardViewModel;
	provided?: DroppableProvided;
	snapshot?: DroppableStateSnapshot;
}

interface IEditBoardColumnsProps extends IEditBoardColumnsContext {
	className?: string;
	onRequestRemoveColumnDefinition?(columnDefinition: BoardStageDefinitionViewModel): void;
}

class EditBoardColumns extends React.Component<IEditBoardColumnsProps, IEditBoardColumnsContext> {
	// @ts-ignore
	private bottomDivRef: HTMLDivElement;
	public state: IEditBoardColumnsContext = {
		createBoardVm: this.props.createBoardVm,
		provided: this.props.provided,
		snapshot: this.props.snapshot,
	};

	public UNSAFE_componentWillReceiveProps(nextProps: IEditBoardColumnsProps) {
		if (
			this.state.createBoardVm !== nextProps.createBoardVm ||
			this.state.provided !== nextProps.provided ||
			this.state.snapshot !== nextProps.snapshot
		) {
			this.setState({
				createBoardVm: nextProps.createBoardVm,
				provided: nextProps.provided,
				snapshot: nextProps.snapshot,
			});
		}
	}

	public render() {
		const { provided, createBoardVm } = this.props;
		return (
			<div className={css(styleSheet.columns)} ref={provided.innerRef} {...provided.droppableProps}>
				<div>
					<label htmlFor='create-board-name' className={css(styleSheet.nameLabel)}>
						Board Name
					</label>

					<TextInput
						className={css(styleSheet.nameInput)}
						inputId='create-board-name'
						onChange={this.onNameChanged}
						type='text'
						value={createBoardVm.name}
					/>
				</div>

				{createBoardVm.columns.map((x, i) => {
					return (
						<EditBoardColumnDefinition
							stageDefinition={x}
							index={i}
							key={x.uuid}
							onRequestRemove={this.onRequestRemove(i)}
						/>
					);
				})}
				<div className={css(styleSheet.columnsFooter)}>
					<AddButton title='Add Column' onClick={this.onRequestAdd} />
				</div>
				{/* @ts-ignore */}
				{provided.placeholder}
				<div ref={this.onBottomDivRef} />
			</div>
		);
	}

	private onBottomDivRef = (ref: HTMLDivElement) => {
		this.bottomDivRef = ref;
	};

	private scrollToBottom = () => {
		// requestAnimationFrame delays the scroll until after a possible render pass (e.g. inserting a column)
		requestAnimationFrame(() => {
			if (this.bottomDivRef) {
				this.bottomDivRef.scrollIntoView();
			}
		});
	};

	private onRequestAdd = () => {
		EventLogger.logInput('EditBoard', 'AddStage', 'Click');
		// @ts-ignore
		// @ts-ignore
		this.props.createBoardVm.insertColumn(new BoardStageDefinitionViewModel(), this.props.createBoardVm.columns.length);
		this.scrollToBottom();
	};

	private onRequestRemove = (index: number) => () => {
		// @ts-ignore
		const columnDefinition = this.props.createBoardVm.columns[index];
		EventLogger.logInput('EditBoard', 'RemoveStage', 'Click');
		const { onRequestRemoveColumnDefinition } = this.props;
		if (onRequestRemoveColumnDefinition) {
			// show delete confirmation
			onRequestRemoveColumnDefinition(columnDefinition);
		} else {
			// @ts-ignore
			this.props.createBoardVm.removeColumnAtIndex(index);
		}
	};

	private onNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.props.createBoardVm.setName(e.target.value);
	};
}

// Needed to pull this out as an observer in order for the vm-level reorder logic to work properly
const EditKanbanBoardColumnsAsObserver = observer(EditBoardColumns);

class _EditKanbanBoard extends React.Component<IProps, IState> {
	public readonly state: IState = {
		showArchived: false,
	};

	public UNSAFE_componentWillMount() {
		const { userSession, board, initialColumns } = this.props;
		const editBoardVm = new EditBoardViewModel(
			userSession,
			board ? board.name : 'Opportunities',
			board ? board.stages.map(x => x.toColumnDefinition()) : initialColumns
		);
		this.setState({
			editBoardVm,
		});

		PersistentStorageManager.local
			.getObject<IBoardShowArchivedStorage>(PersistentStorageBoardShowArchivedKey)
			.then(storedValue => {
				this.setState({
					showArchived: storedValue || false,
				});
			});
	}

	public render() {
		const { className = '', board } = this.props;
		const { editBoardVm, stageToDelete, saving, showArchived } = this.state;
		// @ts-ignore
		const validMoveTargets = editBoardVm.columns
			.map(x => {
				if (!!x.stage && !!x.stage.id) {
					if (!stageToDelete || (!!stageToDelete && stageToDelete.id !== x.stage.id)) {
						return x.stage;
					}
				}
				return null;
			})
			.filter(x => !!x);
		return (
			<DragDropContext onDragEnd={this.onDragEnd}>
				<div className={`${css(styleSheet.board)} ${className}`}>
					<div className={css(styleSheet.boardHeader)}>
						<DeprecatedCloseButton onClick={this.onCancel} />
						<div className={css(styleSheet.boardHeaderTitles)}>
							<div className={css(styleSheet.boardHeaderTitle)}>{`${board ? 'Edit' : 'Create'} Board`}</div>
							<div className={css(styleSheet.boardHeaderSubheader)}>
								{!board && 'We’ve generated a few columns as an example to keep track of your opportunities.\n\r'}
								You may rename, reorder, add, and delete columns.
							</div>
						</div>
					</div>
					<div className={css(styleSheet.body)}>
						<Droppable direction='vertical' droppableId={editBoardVm.id}>
							{(droppableProvided: DroppableProvided, droppableSnapshot: DroppableStateSnapshot) => {
								return (
									<EditKanbanBoardColumnsAsObserver
										createBoardVm={editBoardVm}
										onRequestRemoveColumnDefinition={this.onRequestRemoveColumnDefinition}
										provided={droppableProvided}
										snapshot={droppableSnapshot}
									/>
								);
							}}
						</Droppable>
						<div className={css(styleSheet.checkboxSection, styleSheet.archiveCheckbox)}>
							<Checkbox id='show-archived-checkbox' onChange={this.onToggleShowArchivedColumn} checked={showArchived}>
								<span>Show archived opportunities</span>
							</Checkbox>
						</div>
					</div>
					<div className={css(styleSheet.footer)}>
						<button className={css(baseStyleSheet.ctaButton)} onClick={this.onSaveClicked}>
							Save
						</button>
						<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={this.onCancel}>
							Cancel
						</button>
					</div>
					{!!saving && <LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='large' />}
					<DeleteStageConfirmationModal
						// @ts-ignore
						options={validMoveTargets}
						// @ts-ignore
						stage={stageToDelete}
						modalProps={{
							isOpen: !!stageToDelete,
							onRequestClose: this.onColumnDeleteConfirmationRequestClose,
							shouldCloseOnOverlayClick: false,
						}}
					/>
				</div>
			</DragDropContext>
		);
	}

	private onToggleShowArchivedColumn = (event: React.ChangeEvent<HTMLInputElement>) => {
		const showArchived = event.target.checked;
		this.setState({ showArchived });
	};

	private onColumnDeleteConfirmationRequestClose = (toStage?: BoardStageViewModel, canceled?: boolean) => {
		const { stageToDelete, editBoardVm } = this.state;
		// @ts-ignore
		const nextState: IState = { stageToDelete: null };
		if (!!toStage && !canceled) {
			// @ts-ignore
			// @ts-ignore
			editBoardVm.removeColumnAtIndex(editBoardVm.columns.findIndex(x => x.stage === stageToDelete));

			// add the move request to a collection of things to do during save
			const queuedItemMoves = this.state.queuedItemMoves || {};
			// @ts-ignore
			// @ts-ignore
			queuedItemMoves[stageToDelete.id] = toStage;

			// if there are existing queued moves that target stageToDelete, redirect them to toStage
			Object.keys(queuedItemMoves).forEach(key => {
				// @ts-ignore
				if (key !== stageToDelete.id && queuedItemMoves[key] === stageToDelete) {
					queuedItemMoves[key] = toStage;
				}
			});

			nextState.queuedItemMoves = queuedItemMoves;
		}
		this.setState(nextState);
	};

	private onRequestRemoveColumnDefinition = (columnDefinition: BoardStageDefinitionViewModel) => {
		const { editBoardVm } = this.state;
		if (!!columnDefinition.stage && !!columnDefinition.stage.items && columnDefinition.stage.items.length > 0) {
			// show delete confirmation
			this.setState({
				stageToDelete: columnDefinition.stage,
			});
		} else {
			// @ts-ignore
			// @ts-ignore
			editBoardVm.removeColumnAtIndex(editBoardVm.columns.indexOf(columnDefinition));
		}
	};

	private onSaveClicked = () => {
		PersistentStorageManager.local.setObject<IBoardShowArchivedStorage>(
			PersistentStorageBoardShowArchivedKey,
			// @ts-ignore
			this.state.showArchived
		);
		this.moveItems();
	};

	private moveItems = () => {
		const { board } = this.props;
		const { queuedItemMoves, editBoardVm } = this.state;
		if (!!queuedItemMoves && !!board) {
			const idsOfDeletedStages = Object.keys(queuedItemMoves);
			if (idsOfDeletedStages.length > 0) {
				this.setState({
					saving: true,
				});

				const stagesToResetAndReload = new Set<BoardStageViewModel>();
				const stagesToReset = new Set<BoardStageViewModel>();
				const promises = idsOfDeletedStages.map(id => {
					// search the board.stages collection becase we removed the fromStage from the edit board vm column collection
					const fromStage = board.stages.find(x => x.id === id);
					const toStage = queuedItemMoves[id];
					EventLogger.logEvent(
						{
							action: 'MoveItems',
							category: 'EditBoard',
						},
						// @ts-ignore
						{ fromStageId: fromStage.id, toStageId: toStage.id }
					);
					stagesToResetAndReload.add(toStage);
					// @ts-ignore
					stagesToReset.add(fromStage);
					// @ts-ignore
					// @ts-ignore
					return editBoardVm.moveStageItems(fromStage, toStage);
				});

				const promise = Promise.all(promises).then(() => {
					const savePromise = this.save();
					if (savePromise) {
						savePromise.then(() => {
							// reset and reload stages
							Array.from(stagesToResetAndReload).forEach(x => {
								x.reset();
								x.getItems();
							});

							Array.from(stagesToReset).forEach(x => {
								x.reset();
							});
						});
					}
				});
				promise.catch((error: IOperationResultNoValue) => {
					// @ts-ignore
					this.props.errorMessages.push({
						// @ts-ignore
						messages: [error.systemMessage],
					});

					EventLogger.logEvent(
						{
							action: 'MoveItems-Error',
							category: 'EditBoard',
						},
						{ ...error }
					);
				});
				return promise;
			}
		}

		return this.save();
	};

	private save = () => {
		const { board, onEditBoard, onCreateBoard } = this.props;
		// edit case
		let isEdit = true;
		// @ts-ignore
		let promise =
			!!board && !!onEditBoard ? onEditBoard(board, this.state.editBoardVm.name, this.state.editBoardVm.columns) : null;
		if (!promise) {
			isEdit = false;
			// create case
			// @ts-ignore
			promise =
				!board && !!onCreateBoard ? onCreateBoard(this.state.editBoardVm.name, this.state.editBoardVm.columns) : null;
		}

		if (promise) {
			this.setState({
				saving: true,
			});
			EventLogger.logEvent({
				action: `${isEdit ? 'Edit' : 'Create'}`,
				category: 'EditBoard',
			});
			promise
				.then(editedBoard => {
					this.setState({
						saving: false,
					});
					this.onRequestClose(editedBoard, false);
				})
				.catch((error: IOperationResultNoValue) => {
					this.setState({
						saving: false,
					});
					// @ts-ignore
					this.props.errorMessages.push({
						// @ts-ignore
						messages: [error.systemMessage],
					});

					EventLogger.logEvent(
						{
							action: `${isEdit ? 'Edit' : 'Create'}-Error`,
							category: 'EditBoard',
						},
						{ ...error }
					);
				});
		}

		return promise;
	};

	private onRequestClose = (board?: BoardViewModel, canceled?: boolean) => {
		if (this.props.parentModal) {
			this.props.parentModal.onRequestClose(board, canceled);
		}
	};

	private onDragEnd = (result: DropResult) => {
		if (!!result.destination && result.source.index !== result.destination.index) {
			// @ts-ignore
			this.state.editBoardVm.moveColumn(result.source.index, result.destination.index);
		}
	};

	private onCancel = () => {
		// @ts-ignore
		this.onRequestClose(null, true);
	};
}

const EditBoardAsObserver = observer(_EditKanbanBoard);
export const EditBoard = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey,
	ModalChildComponentContextKey
)(EditBoardAsObserver);
export const EditBoardModal = asModalComponent(EditBoard, {
	className: 'edit-kanban-board-modal',
	isOpen: false,
});
