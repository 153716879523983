import { StyleSheet } from 'aphrodite';
import { inputBorderColor, mention, navigation } from '../../styles/colors';
import { CssSelectors } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	dropdownInline: {
		borderColor: inputBorderColor,
		borderRadius: 3,
		borderStyle: 'solid',
		borderWidth: 1,
		boxSizing: 'border-box',
		maxWidth: 600,
		overflowY: 'visible',
		padding: 10,
		width: '100%',
	},
	dropdownMenu: {
		background: '#fff',
		border: '1px solid #E2E2E2',
		boxShadow: '0 0 6px 0 rgba(184,184,184,0.30)',
		marginTop: 21,
		maxHeight: 300,
		overflowY: 'auto',
		width: '100%',
	},
	dropdownItem: {
		':hover': {
			background: mention,
		},
		...CssSelectors.allChildren({
			color: navigation,
			fontSize: 12,
		}),
	},
});
