import { SvgIcon } from '../../icons/SvgIcon';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const EmailInCircleGradiantGraphic: React.FunctionComponent<IProps> = props => {
	const { className, styles } = props;
	return (
		<SvgIcon
			className={`email-in-circle-graphic ${className || ''} ${css(...(styles || []))}`}
			height={120}
			width={120}
		>
			<circle cx='60' cy='60' r='60' fill='url(#Group49__a)' />
			<path
				d='m27.071 53.363 31.5-26.439a3 3 0 0 1 3.858 0l31.5 26.439A3 3 0 0 1 95 55.66V93a3 3 0 0 1-3 3H29a3 3 0 0 1-3-3V55.66a3 3 0 0 1 1.071-2.297Z'
				fill='#F89143'
				stroke='#00AAE8'
				strokeWidth='2'
			/>
			<path fill='#fff' stroke='#00AAE8' strokeWidth='2' d='M34 33h52v46H34z' />
			<path fill='#046CB6' d='M44 43h33v4H44zm0 7h33v4H44zm0 7h13v4H44z' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M26 56v36a4 4 0 0 0 4 4h48L26 56Z'
				fill='#E8F3F9'
				stroke='#00AAE8'
				strokeWidth='2'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M95 56v36a4 4 0 0 1-4 4H43l52-40Z'
				fill='#E8F3F9'
				stroke='#00AAE8'
				strokeWidth='2'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M47.867 74 31 95l22-17.25L47.867 74Zm25.266 0L90 95 68 77.75 73.133 74Z'
				fill='#CAE0EC'
			/>
			<path
				d='M62.975 72.057 90.34 96H31.66l27.364-23.943a3 3 0 0 1 3.95 0Z'
				fill='#fff'
				stroke='#00AAE8'
				strokeWidth='2'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M58.62 76.043a4 4 0 0 1 4.76 0L89 95H33l25.62-18.957Z'
				fill='#E8F3F9'
			/>
			<defs>
				<linearGradient id='Group49__a' x1='0' y1='0' x2='0' y2='120' gradientUnits='userSpaceOnUse'>
					<stop stopColor='#CCEAFF' />
					<stop offset='1' stopColor='#83D0EB' />
				</linearGradient>
			</defs>
		</SvgIcon>
	);
};
