import { brandPrimaryHover, darkGrayFontColor, titles } from '../../../../web/styles/colors';
import { BreakPoints, CssSelectors } from '../../../../web/styles/styles';
import * as tableStyles from '../IntegrationsManageUsersTable/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	addUserButton: {
		alignItems: 'center',
		color: brandPrimaryHover,
		display: 'flex',
		fontSize: 14,
		justifyContent: 'center',
		width: '100%',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	addUserDropdownFooter: {
		borderTop: '1px #D8D8D8 solid',
		boxSizing: 'border-box',
		padding: '8px 20px',
	},
	autoCompleteDropdownContent: {
		maxHeight: 'auto',
		overflow: 'inherit',
	},
	autoCompleteDropdownItems: {
		maxHeight: 300,
		overflow: 'auto',
	},
	cell: {
		borderBottom: tableStyles.ManageUsersTableCellBorder,
		padding: '20px 0',
	},
	container: {},
	emailColumnCell: {
		width: tableStyles.ManageUsersTableEmailColumnWidth,
	},
	emailColumnInputs: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	emailInput: {
		color: '#4A4A4A',
		fontSize: 12,
		width: 200,
		[BreakPoints.forMinWidth(900)]: {
			width: 300,
		},
		[BreakPoints.forMinWidth(850)]: {
			[BreakPoints.forMaxWidth(900)]: {
				width: 250,
			},
		},
	},
	initialsColumnCell: {
		minWidth: tableStyles.ManageUsersTableInitialsColumnWidth,
		width: tableStyles.ManageUsersTableInitialsColumnWidth,
	},
	status: {
		color: darkGrayFontColor,
		fontSize: 12,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	statusColumnCell: {
		minWidth: tableStyles.ManageUsersTableStatusColumnWidth,
		width: tableStyles.ManageUsersTableStatusColumnWidth,
	},
	statusConnect: {
		color: titles,
	},
	statusIndicatorColumnCell: {
		minWidth: tableStyles.ManageUsersTableStatusIndicatorColumnWidth,
		width: tableStyles.ManageUsersTableStatusIndicatorColumnWidth,
	},
	statusIndicatorInactive: {
		opacity: 0.5,
	},
});
