import * as Api from '@ViewModels';
import { useReducer } from 'react';
import { createContentStateWithHtmlStringValue, firstNameHtml } from '../../../../models/UiUtils';

interface ITextingCampaignPageState {
	campaignTitle: string;
	contentEditorState: Api.IRichContentEditorState;
	attachmentFiles: File[];
	storedAttachments: Api.IFileAttachment[];
}

type Actions =
	| { type: 'setCampaignTitle'; value: string }
	| { type: 'setTextingContentEditorState'; value: Api.IRichContentEditorState }
	| { type: 'addAttachmentFiles'; value: File[] }
	| { type: 'removeAttachmentFile'; value: File }
	| { type: 'removeStoredAttachment'; value: string };

const reducer = (state: ITextingCampaignPageState, action: Actions): ITextingCampaignPageState => {
	switch (action.type) {
		case 'setCampaignTitle': {
			return {
				...state,
				campaignTitle: action.value,
			};
		}
		case 'setTextingContentEditorState': {
			return {
				...state,
				contentEditorState: action.value,
			};
		}
		case 'addAttachmentFiles': {
			return {
				...state,
				attachmentFiles: state.attachmentFiles.concat(action.value),
			};
		}
		case 'removeAttachmentFile': {
			return {
				...state,
				attachmentFiles: state.attachmentFiles.filter(x => x !== action.value),
			};
		}
		case 'removeStoredAttachment': {
			return {
				...state,
				storedAttachments: state.storedAttachments.filter(a => a.id !== action.value),
			};
		}
		default:
			throw new Error('Invalid action type');
	}
};

export function useTextingCampaignEditor({
	initialCampaignTitle,
	initialContentEditorState,
	initialAttachmentFiles,
	initialStoredAttachments,
}: {
	initialCampaignTitle?: string;
	initialContentEditorState?: Api.IRichContentEditorState;
	initialAttachmentFiles?: File[];
	initialStoredAttachments?: Api.IFileAttachment[];
}) {
	const [state, dispatch] = useReducer(reducer, {
		campaignTitle: initialCampaignTitle ?? '',
		contentEditorState: initialContentEditorState ?? createContentStateWithHtmlStringValue(`Hi ${firstNameHtml}, `),
		attachmentFiles: initialAttachmentFiles ?? [],
		storedAttachments: initialStoredAttachments ?? [],
	});
	return {
		state,
		dispatch,
	};
}
