import { StyleDeclaration, css } from 'aphrodite';
import { TextArea } from '../../components/TextArea';
import { baseStyleSheet } from '../../styles/styles';
import { styleSheet } from './styles';

export function CustomRecipientAddressField({
	address,
	id,
	onBtnClick,
	onEditBtnClick,
	required = false,
	styleDeclaration,
}: {
	address?: string;
	id: string;
	onBtnClick: () => void;
	onEditBtnClick: () => void;
	required?: boolean;
	styleDeclaration?: StyleDeclaration;
}) {
	return (
		<div className={css(styleSheet.configDrawerSection, styleDeclaration)}>
			<div className={css(styleSheet.configDrawerAddressHeader)}>
				<strong className={css(styleSheet.configDrawerThirdHeader, styleSheet.configDrawerAddressHeaderTitle)}>
					Recipient Address <span className={css(styleSheet.requiredIndicator)}>*</span>
				</strong>
				{address ? (
					<button className={css(baseStyleSheet.brandLink)} onClick={onEditBtnClick}>
						Edit
					</button>
				) : null}
			</div>
			{address ? (
				<TextArea
					inputClassName={css(styleSheet.configDrawerAddressInput)}
					value={address}
					inputId={`postcard-textarea-${id}`}
					disabled={true}
				/>
			) : (
				<button className={css(styleSheet.addAddressButton)} onClick={onBtnClick}>
					+ Add Address
				</button>
			)}
			{required && !address ? <span className={css(styleSheet.requiredLabel)}>* Address Required</span> : null}
		</div>
	);
}
