import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	buttonContainer: {
		marginTop: 16,
	},
	container: {},
	input: {
		maxWidth: 300,
	},
	inputContainer: {
		padding: '20px 0',
	},
	logo: {
		fontSize: 18,
		padding: '30px 0 20px',
	},
	text: {
		fontSize: 14,
		maxWidth: '70%',
	},
});
