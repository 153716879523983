import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		...CssSelectors.allChildren(
			{
				marginTop: 30,
			},
			':not(:first-child)'
		),
	},
	emailToggleImage: {
		maxHeight: 46,
		width: 'auto',
	},
	integrationTagName: {
		fontWeight: 600,
	},
});
