import { SvgIcon } from '../../icons/SvgIcon';

export const TrialExpiredGraphic = () => {
	return (
		<SvgIcon viewBox='0 0 104 92' height={92} width={104}>
			<g fill='none' fillRule='evenodd'>
				<g stroke='#00aae8'>
					<path d='m.5 90.5h103v1h-103z' />
					<circle cx='22' cy='4' fill='#e8f3f9' r='2' />
					<circle cx='13.5' cy='27.5' fill='#e8f3f9' r='1' />
					<circle cx='7' cy='14' fill='#e8f3f9' r='1' />
				</g>
				<circle cx='56' cy='4' fill='#00aae8' r='1' />
				<circle cx='83.5' cy='33.5' r='1.5' stroke='#00aae8' />
				<g fill='#00aae8' transform='translate(69)'>
					<rect height='1' rx='.5' width='3' y='1' />
					<rect height='1' rx='.5' transform='matrix(0 1 -1 0 3 0)' width='3' y='1' />
				</g>
				<g fill='#00aae8' transform='translate(92 13)'>
					<rect height='1' rx='.5' width='3' y='1' />
					<rect height='1' rx='.5' transform='matrix(0 1 -1 0 3 0)' width='3' y='1' />
				</g>
				<g transform='translate(20 14)'>
					<path
						d='m39.6666667 39 13.0311829 10.1983171c1.4531168 1.1372218 2.3021504 2.879814 2.3021504 4.72503v24.0766529h-46v-24.0766529c0-1.845216.84903361-3.5878082 2.3021504-4.72503l13.0311829-10.1983171-13.0311829-10.1983171c-1.45311679-1.1372218-2.3021504-2.879814-2.3021504-4.72503v-24.0766529h46v24.0766529c0 1.845216-.8490336 3.5878082-2.3021504 4.72503z'
						fill='#e8f3f9'
					/>
					<path
						d='m54 1h-44v23.0766529c0 1.53768.707528 2.9898402 1.9184587 3.937525l14.0374393 10.9858221-14.0374393 10.9858221c-1.2109307.9476848-1.9184587 2.399845-1.9184587 3.937525v23.0766529h44v-23.0766529c0-1.53768-.707528-2.9898402-1.9184587-3.937525l-14.0374393-10.9858221 14.0374393-10.9858221c1.2109307-.9476848 1.9184587-2.399845 1.9184587-3.937525z'
						stroke='#00aae8'
						strokeWidth='2'
					/>
					<path
						d='m14 4.83327874h36v17.08682596c0 1.8394405-.8437526 3.5772412-2.289207 4.7148717l-15.710793 12.3650236-15.710793-12.3650236c-1.4454544-1.1376305-2.289207-2.8754312-2.289207-4.7148717z'
						fill='#fff'
					/>
					<path
						d='m14 39h36v17.3125653c0 1.872427-.8741315 3.6375067-2.3634503 4.772369l-15.6365497 11.9150657-15.6365497-11.9150657c-1.4893188-1.1348623-2.3634503-2.899942-2.3634503-4.772369z'
						fill='#fff'
						transform='matrix(1 0 0 -1 0 112)'
					/>
					<path
						d='m15 57.3260144v14.6739856h34l-.0000074-14.673997c0-1.3767129-.937041-2.5767275-2.2726825-2.9104941-6.4441327-1.6103393-11.3532361-2.4155089-14.7273101-2.4155089-3.374077 0-8.2831861.8051711-14.7273273 2.4155133l.0000036.0000146c-1.3356379.3337658-2.2726763 1.5337773-2.2726763 2.9104865z'
						fill='#00aae8'
					/>
					<rect fill='#e8f3f9' height='10' rx='5' width='64' />
					<rect height='8' rx='4' stroke='#00aae8' strokeWidth='2' width='62' x='1' y='1' />
					<rect fill='#e8f3f9' height='10' rx='5' width='64' y='68' />
					<rect height='8' rx='4' stroke='#00aae8' strokeWidth='2' width='62' x='1' y='69' />
				</g>
			</g>
		</SvgIcon>
	);
};
