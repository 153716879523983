import * as Api from '@ViewModels';
import * as React from 'react';
import { IAutomationInfo } from '../../../../../models';
import { ILocalNotification } from '../../../../../models/LocalNotifications';
import { useEventLogging } from '../../../../../models/Logging';
import { useActionItemComposer, useErrorMessages, useNoteComposer } from '../../../../../models/hooks/appStateHooks';
import {
	removeItemFromCompanyTimelineQueryCache,
	removeItemFromContactTimelineQueryCache,
} from '../../../../../queries';
import { IConfirmationDialogOption } from '../../../ConfirmationDialog';
import { IMoreMenuItem } from '../../../MoreMenu';
import { TimelinePaginationData, useCompanyTimelineData, useContactTimelineData } from './useTimelineData';

function useTimeline({
	eventLoggingCategory,
	id,
	onDeleteRichContent,
	onMoreMenuOptionClicked,
	removeRichContentFromTimeline,
	timelineData,
	setShouldStartPollingForRelationshipUpdate,
}: {
	emailAddresses?: Api.EmailAddress[];
	eventLoggingCategory: string;
	id: string;
	onDeleteRichContent: (richContent: Api.RichContentViewModel) => Promise<Api.IRichContent>;
	onMoreMenuOptionClicked?(
		richContent: Api.RichContentViewModel,
		option: 'edit' | 'delete',
		e: React.MouseEvent<HTMLElement>
	): void;
	removeRichContentFromTimeline: (richContentId: string) => void;
	timelineData: TimelinePaginationData;
	setShouldStartPollingForRelationshipUpdate?(poll: boolean): void;
}) {
	const errorMessages = useErrorMessages();
	const { logApiError, logInput } = useEventLogging(eventLoggingCategory);
	const [showDeletingRichContentModal, setShowDeletingRichContentModal] =
		// @ts-ignore
		React.useState<Api.RichContentViewModel>(null);
	const noteComposer = useNoteComposer();
	const actionItemComposer = useActionItemComposer();
	const onMoreMenuItemClicked = (
		richContent: Api.RichContentViewModel,
		menuItem: IMoreMenuItem,
		e: React.MouseEvent<HTMLElement>
	) => {
		if (onMoreMenuOptionClicked) {
			onMoreMenuOptionClicked(richContent, menuItem.representedObject, e);
		}
		if (e.defaultPrevented) {
			e.stopPropagation();
			return;
		}
		e.preventDefault();
		e.stopPropagation();

		const getAction = (name: string) =>
			`MoreMenu${name}${richContent instanceof Api.NoteViewModel ? 'Note' : 'ActionItem'}`;
		if (menuItem.representedObject === 'delete') {
			setShowDeletingRichContentModal(richContent);
		} else if (menuItem.representedObject === 'edit') {
			logInput(getAction('Edit'), 'Click', { id: richContent.id });
			if (richContent instanceof Api.NoteViewModel) {
				noteComposer.show(richContent);
			} else if (richContent instanceof Api.ActionItemViewModel) {
				// @ts-ignore
				actionItemComposer.show(
					richContent,
					(editedActionItem?: Api.ActionItemViewModel, result?: Api.RichContentComposerResult) => {
						if (!!editedActionItem && result === Api.RichContentComposerResult.Delete) {
							// @ts-ignore
							removeRichContentFromTimeline(editedActionItem.id);
						}
					}
				);
			}
		}
	};

	const onCreateOrUpdateNotificationReceived = (notification: ILocalNotification<Api.IActionItem>) => {
		const actionItemModel = notification.info;
		const entityIds = [
			// @ts-ignore
			// @ts-ignore
			// @ts-ignore
			...actionItemModel.referencedEntities.contacts,
			// @ts-ignore
			// @ts-ignore
			// @ts-ignore
			...actionItemModel.referencedEntities.companies,
		].map(x => x.entity.id);
		if (entityIds.indexOf(id) >= 0) {
			timelineData.invalidateTimeline();
		}
	};
	const onTimelineRefreshNotificationReceived = () => {
		timelineData.invalidateTimeline();
	};

	const onNoteCreateOrUpdateNotificationReceived = (notification: ILocalNotification<Api.INote>) => {
		if (!timelineData.isLoading) {
			const noteModel = notification.info;
			// @ts-ignore
			if (noteModel.referencedEntities) {
				// @ts-ignore
				// @ts-ignore
				// @ts-ignore
				// @ts-ignore
				const entityIds = [...noteModel.referencedEntities.contacts, ...noteModel.referencedEntities.companies].map(
					x => x.entity.id
				);
				if (setShouldStartPollingForRelationshipUpdate) {
					setShouldStartPollingForRelationshipUpdate(true);
				}
				if (entityIds.indexOf(id) >= 0) {
					timelineData.invalidateTimeline();
				}
			}
		}
	};

	const onAutomationEdited = (notification?: ILocalNotification<IAutomationInfo>) => {
		if (
			!!id &&
			notification?.info?.contact?.id === id &&
			notification?.info?.automation?.steps?.some(x => x.step?._type === 'ActionItemAutomationStep')
		) {
			timelineData.invalidateTimeline();
		}
	};

	const onDeleteContentConfirmationDialogRequestClose = async (
		result?: IConfirmationDialogOption<boolean>,
		canceled?: boolean
	) => {
		if (!!result && !canceled && !!showDeletingRichContentModal) {
			logInput('MoreMenuDeleteNote', 'Click', {
				id: showDeletingRichContentModal.id,
			});
			try {
				await onDeleteRichContent(showDeletingRichContentModal);
				// @ts-ignore
				removeRichContentFromTimeline(showDeletingRichContentModal.id);
			} catch (error) {
				// @ts-ignore
				logApiError('NoteDelete-Error', error);
				// @ts-ignore
				// @ts-ignore
				errorMessages.pushApiError(error);
			}
		}
		// @ts-ignore
		setShowDeletingRichContentModal(null);
	};

	return {
		onAutomationEdited,
		onCreateOrUpdateNotificationReceived,
		onDeleteContentConfirmationDialogRequestClose,
		onMoreMenuItemClicked,
		onNoteCreateOrUpdateNotificationReceived,
		onTimelineRefreshNotificationReceived,
		showDeletingRichContentModal,
		timelineData,
	};
}

export const useContactTimeline = ({
	emailAddresses,
	eventTypes,
	contactId,
	onDeleteRichContent,
	onMoreMenuOptionClicked,
	setShouldStartPollingForRelationshipUpdate,
}: {
	emailAddresses?: Api.EmailAddress[];
	eventTypes: Api.TimelineEventTypes[];
	contactId: string;
	onDeleteRichContent: (richContent: Api.RichContentViewModel) => Promise<Api.IRichContent>;
	setShouldStartPollingForRelationshipUpdate?(poll: boolean): void;
	onMoreMenuOptionClicked?(
		richContent: Api.RichContentViewModel,
		option: 'edit' | 'delete',
		e: React.MouseEvent<HTMLElement>
	): void;
}) => {
	const timelineData = useContactTimelineData({ contactId, eventTypes });
	const removeRichContentFromTimeline = (richContentId: string) => {
		removeItemFromContactTimelineQueryCache({
			id: richContentId,
			queryParams: { contactId, eventTypes },
		});
	};
	const onEmailsSent = (notification: ILocalNotification<Api.EmailMessageViewModel>) => {
		// @ts-ignore
		// @ts-ignore
		if (!!notification.info.options && !!notification.info.options.saveAsNote) {
			let scheduleReload = false;
			if (notification.info instanceof Api.EmailMessageViewModel) {
				const entityEmailAddresses = (emailAddresses || []).map(x => x.value).filter(x => !!x);
				const emailMessage = notification.info;
				if (emailMessage.contactsToAdd.length > 0) {
					const recipientIds = emailMessage.contactsToAdd
						.filter(x => !emailMessage.contactsToOmit.has(x))
						.map(x => x.id);
					scheduleReload = recipientIds.indexOf(contactId) >= 0;
				} else if (entityEmailAddresses.length > 0) {
					const addresses = (emailMessage.contactsToAdd.toArray() || [])
						.map(y => {
							const emailAddress = emailMessage.getPreferredEmailAddressForContact(y);
							return !!emailAddress && !!emailAddress.value ? emailAddress.value : null;
						})
						.filter(z => !!z);
					const matchingEmail = entityEmailAddresses.find(x => {
						return !!addresses.find(y => y === x);
					});
					scheduleReload = !!matchingEmail;
				}
			}
			if (scheduleReload) {
				timelineData.invalidateTimeline();
				if (setShouldStartPollingForRelationshipUpdate) {
					setShouldStartPollingForRelationshipUpdate(true);
				}
			}
		}
	};
	const timelineProps = useTimeline({
		eventLoggingCategory: 'ContactTimeline',
		id: contactId,
		onDeleteRichContent,
		onMoreMenuOptionClicked,
		removeRichContentFromTimeline,
		timelineData,
		setShouldStartPollingForRelationshipUpdate,
	});
	return {
		...timelineProps,
		onEmailsSent,
	};
};

export const useCompanyTimeline = ({
	eventTypes,
	companyId,
	onDeleteRichContent,
	onMoreMenuOptionClicked,
}: {
	eventTypes: Api.TimelineEventTypes[];
	companyId: string;
	onDeleteRichContent: (richContent: Api.RichContentViewModel) => Promise<Api.IRichContent>;
	onMoreMenuOptionClicked?(
		richContent: Api.RichContentViewModel,
		option: 'edit' | 'delete',
		e: React.MouseEvent<HTMLElement>
	): void;
}) => {
	const timelineData = useCompanyTimelineData({ companyId, eventTypes });
	const removeRichContentFromTimeline = (richContentId: string) => {
		removeItemFromCompanyTimelineQueryCache({
			id: richContentId,
			queryParams: { companyId, eventTypes },
		});
	};
	return useTimeline({
		eventLoggingCategory: 'ComapnyTimeline',
		id: companyId,
		onDeleteRichContent,
		onMoreMenuOptionClicked,
		removeRichContentFromTimeline,
		timelineData,
	});
};
