import { brandPrimary } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	fauxRadioButtonContainer: {
		border: `1px solid ${brandPrimary}`,
		borderRadius: '50%',
		cursor: 'pointer',
		display: 'inline-flex',
		height: '12px',
		marginTop: '0px',
		width: '12px',
	},
	fauxRadioButtonContainerSelected: {
		backgroundImage: 'url(../../../assets/bluecirlce.svg)',
		backgroundPosition: '1px 1px',
		backgroundRepeat: 'no-repeat',
		backgroundSize: '10px',
		border: `1px solid ${brandPrimary}`,
		borderRadius: '50%',
		cursor: 'pointer',
		display: 'inline-flex',
		height: '12px',
		marginTop: '0px',
		width: '12px',
	},
	mergeContactEntryContainer: {
		alignItems: 'center',
		borderBottom: '1px solid #f0eded',
		display: 'flex',
		fontSize: '14px',
		padding: '10px 5px 10px 5px',
		width: '100%',
		...CssSelectors.allChildren(
			{
				cursor: 'pointer',
				display: 'inline-flex',
				marginBottom: '2px',
				marginLeft: '5px',
			},
			'.emailAddress'
		),
	},
	mergeContactList: {
		maxHeight: '225px',
		overflow: 'auto',
		padding: '0 20px',
	},
	mergeContactsButtonContainer: {
		bottom: 0,
		marginBottom: '20px',
		marginLeft: '20px',
		position: 'absolute',
	},
	mergeContactsCloseButton: {
		backgroundImage: 'url(/assets/close-x.svg)',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: '14px',
		color: 'transparent',
		cursor: 'pointer',
		display: 'inline-block',
		fontSize: 0,
		height: '14px',
		width: '14px',
	},
	mergeContactsCloseButtonContainer: {
		marginRight: '15px',
		marginTop: '15px',
		textAlign: 'right',
	},
	mergeContactsHeadline: {
		color: '#00AAEB',
		fontSize: '18px',
		margin: '0 0 10px',
		textAlign: 'center',
	},
	mergeContactsSubHeadline: {
		fontSize: '14px',
		margin: '0 0 20px',
		textAlign: 'center',
		...CssSelectors.allChildren(
			{
				fontWeight: 700,
			},
			'strong'
		),
	},
	mergeContactsWizardOuterContainer: {
		backgroundColor: 'white',
		border: '1px solid #BABABA',
		boxShadow: '0px 2px 6px 0px #cccccc',
		left: '50%',
		minHeight: '400px',
		position: 'fixed',
		top: '50%',
		transform: 'translate(-50%, -50%)',
		width: '600px',
		zIndex: 2,
	},
	modalWindow: {
		backgroundColor: 'white',
		height: '100%',
		left: 0,
		margin: 0,
		opacity: 0.6,
		padding: 0,
		position: 'fixed',
		top: 0,
		width: '100%',
		zIndex: 1,
	},
});
