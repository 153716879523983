import { inputBorderColor } from '../../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	dropdownContentInnerOverrides: {
		borderColor: inputBorderColor,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
		borderTopRightRadius: 0,
		maxHeight: 500,
	},
});
