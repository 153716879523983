import { StyleSheet } from 'aphrodite';

export const compactStagePillHeight = 10;
export const stagePillHeight = 20;

export const styleSheet = StyleSheet.create({
	compact: {
		':not(:first-of-type)': {
			marginLeft: 4,
		},
		borderRadius: '5px',
		height: compactStagePillHeight,
		width: 16,
	},
	indicator: {
		display: 'inline-flex',
	},
	pill: {
		':not(:first-of-type)': {
			marginLeft: 10,
		},
		borderRadius: '10px',
		boxSizing: 'border-box',
		height: stagePillHeight,
		width: 32,
	},
});
