import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { INavigationItemProps } from '../../../containers/MainContainer';
import { Settings as LevitateSettings } from '../../../containers/Settings';
import { SettingsContextProvider } from '../SettingsContextProvider';
import { styleSheet } from './styles';

interface IProps extends INavigationItemProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const CoffeeSettings: React.FC<IProps> = props => {
	const { className, styles = [], routeContainerClassName = '' } = props;
	return (
		<SettingsContextProvider>
			<div
				className={`${routeContainerClassName} ${css(styleSheet.container, ...styles)} coffee-settings ${
					className || ''
				}`}
			>
				<LevitateSettings />
			</div>
		</SettingsContextProvider>
	);
};
