import * as Api from '@ViewModels';
import clioLogo from '../../../../assets/logo_clio_sm.svg';
import { baseStyleSheet } from '../../../../styles/styles';
import { ClioIntegrationGraphic } from '../../../svgs/graphics/ClioIntegrationGraphic';
import { IntegrationCard, useIntegrationCard } from '../IntegrationCard';
import { styleSheet } from './styles';

export const DashboardClioIntegrationCard = () => {
	const { getLogoImgProps, getCloseButtonProps, getCtaButtonProps, getIntegrationTitleProps, showCard } =
		useIntegrationCard({
			closeIntegrationEventName: 'ClioIntegrationClosed',
			enableUrlPath: '/integrations/clio',
			integrationEnableEventName: 'ClioIntegrationEnableClick',
			pendingAction: Api.PendingActions.ConnectClio,
		});

	if (!showCard) {
		return null;
	}

	return (
		<IntegrationCard
			closeButtonProps={getCloseButtonProps()}
			IntegrationGraphic={ClioIntegrationGraphic}
			headerLabel='Clio Integration'
			logoBgColor='#1370E0'
			logoImgProps={getLogoImgProps({ alt: 'Clio Logo', src: clioLogo })}
			ctaButtonProps={getCtaButtonProps({
				children: 'Connect with Clio',
				styleDeclaration: [baseStyleSheet.ctaButtonReverse, styleSheet.ctaButton],
			})}
			integrationTitleProps={getIntegrationTitleProps({
				styleDeclaration: styleSheet.integrationTitle,
			})}
		>
			<p>
				We are excited to announce that you can integrate your Clio account with Levitate. With this integration, we
				will have the ability to pull client information, including names, email, phone number, birthdays,
				anniversaries, and more &mdash; making staying in touch even easier. We can also push our notes into Clio.
			</p>
		</IntegrationCard>
	);
};
