import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import {
	ErrorMessagesViewModelKey,
	FullScreenModalViewModelKey,
	IErrorMessageComponentProps,
	IFullscreenModalComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { baseStyleSheet } from '../../../styles/styles';
import { EmailReportIcon } from '../../svgs/icons/EmailReportIcon';
import { styleSheet } from './styles';

interface IProps
	extends IEventLoggingComponentProps,
		IUserSessionComponentProps,
		IErrorMessageComponentProps,
		IFullscreenModalComponentProps {
	className?: string;
	isSocial?: boolean;
	shouldHide?: boolean;
	styles?: StyleDeclarationValue[];
}

class _EmailReportCard extends React.Component<IProps> {
	constructor(props: IProps) {
		super(props);
	}

	public render() {
		const { className, styles, isSocial } = this.props;
		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} email-pending-card ${className || ''}`}>
				<div className={css(styleSheet.titleContainer)}>
					<figure className={css(styleSheet.circle)}>
						<EmailReportIcon />
					</figure>
					<div>
						<div className={css(styleSheet.title)}> Looking for all your campaigns?</div>
						<div className={css(styleSheet.titleMessage)}>
							See what you have scheduled to go out vs how past campaigns have performed in the &nbsp;
							<NavLink
								className={css(baseStyleSheet.brandLink)}
								to={`${isSocial ? '/reporting/social-media-posts' : '/reporting/group-email'}`}
							>
								{`${isSocial ? 'Social Media' : 'Email'} Reports`}
							</NavLink>
							&nbsp; section.
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const EmailReportCardAsObserver = observer(_EmailReportCard);
const EmailReportCardWithContext = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey,
	FullScreenModalViewModelKey
)(EmailReportCardAsObserver);
export const EmailReportCard = withEventLogging(EmailReportCardWithContext, 'EmailReportCard');
