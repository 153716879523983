import { brandSecondary } from '../../../../styles/colors';
import { CssSelectors } from '../../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	addContainer: {
		':not(:last-child)': {
			marginBottom: 10,
		},
		color: brandSecondary,
		display: 'flex',
		...CssSelectors.allChildren(
			{
				margin: '3px 8px 0 0',
			},
			'svg'
		),
	},
	addContainerFirst: {
		marginTop: 15,
	},
	containerParent: {
		':not(:last-child)': {
			marginBottom: 12,
		},
	},
});
