import { css } from 'aphrodite';
import * as React from 'react';
import { useHistory } from 'react-router';
import { useTelephonyConfigurationQuery } from '../../../../queries';
import { Checkbox } from '../../../components/Checkbox';
import { baseStyleSheet as bs } from '../../../styles/styles';
import { TextingRegistrationFormLayout } from '../presentation';
import { styleSheet } from './styles';

export function TextingRegistrationFormConsent({
	defaultValues,
	onContinue,
}: {
	defaultValues: { consent: boolean };
	onContinue: () => void;
}) {
	const [consentChecked, setConsentChecked] = React.useState(false);
	const configurationQuery = useTelephonyConfigurationQuery({ refetchOnWindowFocus: false, retry: false });
	const history = useHistory();
	React.useEffect(() => {
		// Block user from initial confirmation if consent has been previously given
		if (defaultValues.consent || configurationQuery.data?.clientProfile?.registrationRequirementsCertified) {
			history.replace('/texting/registration/form/brand-registration');
			return;
		}
	}, [configurationQuery.data, defaultValues.consent, history]);
	if (defaultValues.consent || configurationQuery.data?.clientProfile?.registrationRequirementsCertified) {
		return null;
	}
	return (
		<TextingRegistrationFormLayout>
			<div className={css(bs.w96, bs.mxAuto)}>
				<h2 className={css(bs.mb8, bs.textLg, bs.textCenter)}>Texting Campaign Registration</h2>
				<p className={css(bs.mb3)}>
					Before getting started on your application please make sure you have the following information ready:
				</p>
				<ul className={css(styleSheet.infoList, bs.textXs, bs.px3, bs.mb6, bs.textDarkGrayFontColor)}>
					<li>Tax EIN (If you&apos;re a sole proprietor please contact support)</li>
					<li>Your website must have a privacy policy</li>
					<li>If you collect phone numbers on your website you must include opt-in language for texting.</li>
				</ul>
				<div
					className={css(
						styleSheet.consentBox,
						bs.p7,
						bs.roundedLg,
						bs.mb6,
						bs.maxH40,
						bs.boxBorder,
						bs.overflowYAuto,
						bs['-mx10']
					)}
					style={{ backgroundColor: 'rgba(208, 2, 27, 0.05' }}
				>
					<h3 className={css(bs.textDestructive, bs.fontBold, bs.textSm, bs.textCenter, bs.mb4)}>
						Non-Compliance Carrier Fees
					</h3>
					<p className={css(bs.textXs, bs.textTitles, bs.mb6)}>
						The following content is prohibited by the telephone carriers in text campaigns. You are responsible for
						carrier fees, which may be up to $2,000 for each violation, if you use texting campaigns for the following:
					</p>
					<strong className={css(bs.fontBold, bs.textSm)}>High-Risk Financial Service</strong>
					<ul className={css(styleSheet.consentList, bs.textXs, bs.px1, bs.mb4)}>
						<li>Payday loans</li>
						<li>Non-Direct lenders</li>
						<li>Debt collection</li>
					</ul>
					<strong className={css(bs.fontBold, bs.textSm)}>Debt Forgiveness</strong>
					<ul className={css(styleSheet.consentList, bs.textXs, bs.px1, bs.mb4)}>
						<li>Debt Consolidation</li>
						<li>Debt Reduction</li>
						<li>Credit Repair Programs</li>
					</ul>
					<strong className={css(bs.fontBold, bs.textSm)}>Illegal Substances</strong>
					<ul className={css(styleSheet.consentList, bs.textXs, bs.px1, bs.mb4)}>
						<li>Cannabis</li>
						<li>Illegal Prescriptions</li>
					</ul>
					<strong className={css(bs.fontBold, bs.textSm)}>Work & Investment Opportunities</strong>
					<ul className={css(styleSheet.consentList, bs.textXs, bs.px1, bs.mb4)}>
						<li>Work from Home Programs</li>
						<li>Job Alerts from 3rd Party Recruiting Firms</li>
						<li>Risk Investment Opportunities</li>
					</ul>
					<strong className={css(bs.fontBold, bs.textSm)}>Other</strong>
					<ul className={css(styleSheet.consentList, bs.textXs, bs.px1, bs.mb8)}>
						<li>Gambling</li>
						<li>Any other illegal content</li>
						<li>Lead generation indicates the sharing of collected information with third parties</li>
						<li>
							Campaign types are not in compliance with the recommendations of or prohibited by the CTIA Short Code
							Monitoring Handbook, Version 1.7, or later.
						</li>
						<li>
							Campaign types are not in compliance with the recommendations of or prohibited by the CTIA Messaging
							Principles and Best Practices - 2019 version
						</li>
					</ul>
				</div>
				<div className={css(bs.flex, bs.gap2, bs['-ml6'])}>
					<Checkbox
						id='checkbox-consent-tcr'
						onChange={() => setConsentChecked(!consentChecked)}
						checked={consentChecked}
					/>
					<label htmlFor='checkbox-consent-tcr' className={css(styleSheet.consentCheckbox, bs.textXs)}>
						I confirm that I am ready to file my campaign registration and have reviewed the above checklist.
					</label>
				</div>
				<p className={css(bs.textDestructive, bs.textXs, bs.mb8)}>
					If you fill out the texting application without validating the above steps and your application is denied by
					the carriers, you will be charged an additional fee to apply again.
				</p>
				<button disabled={!consentChecked} className={css(bs.ctaButton, bs.wFull)} onClick={onContinue}>
					Continue to campaign registration form
				</button>
			</div>
		</TextingRegistrationFormLayout>
	);
}
