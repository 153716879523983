import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { calculateDaysBetween, isAdminUser } from '../../../api/Utils';
import { BillingType } from '../../../extViewmodels';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../models/AppState';
import { InviteEmployeesModal } from '../../components/settings/InviteEmployees';
import { TrialExpired } from '../Billing/TrialExpired';
import { TrialExpiringSoonGraphic } from '../../components/svgs/graphics/TrialExpiringSoonGraphic';
import './styles.less';

interface IProps extends IUserSessionComponentProps {
	compactLayout?: boolean;
}

interface IState {
	showInviteEmployees?: boolean;
}

class _TrialExpiringSoon extends React.Component<IProps, IState> {
	public state: IState = {
		showInviteEmployees: false,
	};

	public render() {
		const { userSession, compactLayout } = this.props;
		const account = userSession ? userSession.account : null;
		if (!account) {
			return null;
		}

		if (account.planDetails?.billingType === BillingType.Trial) {
			let daysRemaining: React.ReactNode = null;
			if (account.planDetails.trialExpirationDate) {
				const expirationDays = calculateDaysBetween(new Date(), account.planDetails.trialExpirationDate);
				if (expirationDays < 1) {
					return <TrialExpired />;
				}

				daysRemaining = this.renderDaysRemaining(expirationDays);
			}

			const { showInviteEmployees } = this.state;

			if (compactLayout) {
				return null;
			}

			return (
				<div className='trialExpiringSoon-outerContainer'>
					<div className='trialExpiringSoon-inviteContainer'>
						<div className='trialExpiringSoon-inviteContainerText'>Enjoying Levitate?</div>
						<div>
							<span className='trialExpiringSoon-inviteContainerLink' onClick={this.toggleInviteTeammates(true)}>
								Invite teammates
							</span>
						</div>
					</div>
					<div className='trialExpiringSoon-contentContainer'>
						<figure>
							<TrialExpiringSoonGraphic />
						</figure>

						<div className='trialExpiringSoon-daysRemainingContainer'>
							{daysRemaining}
							<div className={isAdminUser(this.props.userSession.user) ? 'trialExpiringSoon-buttonContainer' : 'hide'}>
								<a
									className='trial-contact-support-link purchaseButton'
									href={`mailto:support@levitateapp.com?subject=${encodeURIComponent('Purchase Levitate')}`}
								>
									<span>Purchase</span>
								</a>
							</div>
						</div>
					</div>
					<InviteEmployeesModal
						account={account.toJs()}
						modalProps={{
							isOpen: !!showInviteEmployees,
							onRequestClose: this.toggleInviteTeammates(false),
						}}
					/>
				</div>
			);
		}

		return null;
	}

	private renderDaysRemaining(expirationDays: number) {
		let expirationText = '';
		if (expirationDays === 1) {
			expirationText = '1 day';
		} else if (expirationDays > 1) {
			expirationText = expirationDays.toString() + ' days';
		} else {
			return (
				<div className='trialExpiringSoon-text'>
					<span className='trialExpiringSoon-textHighlight'>Trial is expired</span>
				</div>
			);
		}
		return (
			<div className='trialExpiringSoon-text'>
				Trial ends in <span className='trialExpiringSoon-textHighlight'>{expirationText}</span>
			</div>
		);
	}

	private toggleInviteTeammates = (showInviteEmployees: boolean) => () => {
		this.setState({ showInviteEmployees });
	};
}

const TrialExpiringSoonAsObserver = observer(_TrialExpiringSoon);
export const TrialExpiringSoon = inject(UserSessionViewModelKey)(TrialExpiringSoonAsObserver);
