import { StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../../../styles/styles';

export const styleSheet = StyleSheet.create({
	contactActivity: {
		flexGrow: 1,
	},
	contactActivityTabview: {
		height: '100%',
	},
	contactActivityTabviewContent: {
		...CssSelectors.allChildren({
			flexGrow: 1,
		}),
	},
	contactActivityTabviewScrollerContent: {
		minHeight: 400,
	},
	contactActivityTabviewTabbarItemIcon: {
		'@media only screen and (max-width: 960px)': {
			display: 'inline-block',
		},
		display: 'none',
	},
	contactActivityTabviewTabbarItemTitle: {
		'@media only screen and (max-width: 960px)': {
			display: 'none',
		},
		display: 'inline-block',
		textAlign: 'center',
	},
	dropdown: {
		width: 300,
	},
	richContentFilterOptionsContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		margin: '0 30 0 auto',
		padding: '15px 0',
		zIndex: 1,
		...CssSelectors.allChildren({
			borderRadius: 30,
		}),
	},
	timelineFilterOptionsContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		paddingTop: 15,
		...CssSelectors.allChildren({
			borderRadius: 30,
		}),
	},
});
