import { navigation, titles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { contentHorizontalPadding, contentVerticalPadding } from '../../PrincipalListItem/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	addConnectionItem: {
		alignItems: 'center',
		cursor: 'pointer',
		display: 'flex',
		fontSize: 18,
		padding: `${contentVerticalPadding}px ${contentHorizontalPadding}px`,
		...CssSelectors.allChildren(
			{
				marginLeft: 14,
			},
			':not(:first-child)'
		),
	},
	connectionNotes: {
		alignItems: 'center',
		display: 'flex',
	},
	contactOwner: {
		alignItems: 'center',
		color: navigation,
		display: 'flex',
		fontSize: 12,
	},
	container: {
		position: 'relative',
	},
	editNotesButton: {
		cursor: 'pointer',
		fontSize: 12,
	},
	header: {
		color: '#858585',
		fontSize: 14,
		letterSpacing: '0px',
		marginTop: 24,
		padding: '0 30px',
	},
	headerName: {
		color: titles,
	},
	list: {
		flexGrow: 1,
		marginTop: 10,
	},
	listItem: {
		':nth-child(1n) > .avatar': {
			alignSelf: 'flex-start',
		},
	},
	moreMenu: {
		marginRight: -5,
		padding: 5,
	},
	tooltipPopover: {
		width: 180,
	},
	tooltipPopoverAnchor: {
		marginLeft: 4,
	},
	tooltipPopoverContent: {
		boxSizing: 'border-box',
		fontSize: 12,
		margin: 0,
		padding: 5,
	},
});
