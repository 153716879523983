import GIFT_SET_CARD from '../../../assets/gift_sets.png';
import HAPPY_BIRTHDAY_CARD from '../../../assets/happy_birthday_banner.png';
import { RightArrow } from '../../svgs/icons/RightArrow';
import { SvgIcon } from '../../svgs/icons/SvgIcon';
import { DashboardCard, DashboardCardStat } from '../DashboardCard';
import { styleSheet } from './styles';
import { css } from 'aphrodite';

const counter = 2000; // mock data

export const PhysicalGiftsCard = () => {
	return (
		<DashboardCard styleDeclaration={styleSheet.card}>
			<header className={css(styleSheet.header)}>
				<figure className={css(styleSheet.iconWrap)}>
					<SvgIcon width='20' height='20' viewBox='0 0 20 20' fill='none'>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M14.1667 4.65534L13.0892 3.57867C12.9267 3.41534 12.7133 3.33367 12.5 3.33367C12.2867 3.33367 12.0733 3.41534 11.9108 3.57867L10.8333 4.65534V1.667H14.1667V4.65534ZM18.3333 0.000335693H6.66667C5.7475 0.000335693 5 0.747836 5 1.667V9.167H6.66667V1.667H9.16667V6.667C9.16667 7.00367 9.37 7.30784 9.68083 7.437C9.99167 7.56617 10.35 7.4945 10.5892 7.257L12.5 5.34617L14.4108 7.257C14.57 7.41617 14.7833 7.50034 15 7.50034C15.1075 7.50034 15.2158 7.4795 15.3192 7.437C15.63 7.30784 15.8333 7.00367 15.8333 6.667V1.667H18.3333L18.3308 11.667H12.5V13.3337H18.3333C19.2525 13.3337 20 12.5862 20 11.667V1.667C20 0.747836 19.2525 0.000335693 18.3333 0.000335693Z'
							fill='white'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M13.75 17.5004H5V13.9346L8.745 12.6863L8.98583 13.1696L7.74417 14.4113C7.585 14.5704 7.50833 14.7846 7.5075 15.0004H7.5V15.8338H15L13.75 17.5004ZM1.66667 13.3338H3.33333V18.3338H1.66667V13.3338ZM16.6667 14.1671H10.345L10.5892 13.9238C10.8425 13.6696 10.9058 13.2821 10.745 12.9613L9.91167 11.2946C9.72667 10.9238 9.29833 10.7454 8.90333 10.8763L4.93917 12.1979C4.8175 11.8879 4.51917 11.6671 4.16667 11.6671H0.833333C0.373333 11.6671 0 12.0404 0 12.5004V19.1671C0 19.6271 0.373333 20.0004 0.833333 20.0004H4.16667C4.62667 20.0004 5 19.6271 5 19.1671H14.1667C14.4292 19.1671 14.6758 19.0438 14.8333 18.8338L17.3333 15.5004C17.5225 15.2479 17.5533 14.9104 17.4117 14.6279C17.2708 14.3454 16.9825 14.1671 16.6667 14.1671Z'
							fill='white'
						/>
					</SvgIcon>
				</figure>
				{counter > 0 ? (
					<article className={css(styleSheet.birthdayCounterWrap)}>
						<DashboardCardStat styleDeclaration={styleSheet.counter}>{counter.toLocaleString()}</DashboardCardStat>
						<p className={css(styleSheet.headerText)}>Birthdays Celebrated</p>
					</article>
				) : (
					<p className={css(styleSheet.headerText)}>Celebrate Birthdays, New Clients, and Holidays</p>
				)}
			</header>
			<figure>
				<img src={HAPPY_BIRTHDAY_CARD} alt='physical cards' />
			</figure>
			<button type='button' className={css(styleSheet.button)}>
				Browse cards for all occasions <RightArrow styles={{ marginLeft: 4 }} />
			</button>
			<figure>
				<img src={GIFT_SET_CARD} alt='gift sets' />
			</figure>
			<button type='button' className={css(styleSheet.button)} style={{ marginBottom: 0 }}>
				Browse all gift sets
				<RightArrow styles={{ marginLeft: 4 }} />
			</button>
		</DashboardCard>
	);
};
