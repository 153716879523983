import { borderColor, grayIconFill } from '../../../../styles/colors';
import { truncateTextStyle } from '../../../../styles/styles';
import { contactTable } from '../CompaniesTable/styles';
import { StyleSheet } from 'aphrodite';

const clickable = {
	':active': {
		opacity: 0.7,
	},
	transition: 'opacity 0.1s',
	width: 50,
};

export const styleSheet = StyleSheet.create({
	container: {
		alignItems: 'center',
		borderBottom: `1px solid ${borderColor}`,
		display: 'flex',
		fontSize: 12,
		// height less than min-height is an IE11 hack to make alignItems work
		height: 60,
		margin: '0 28px',
		minHeight: 80,
	},
	containerSelected: {
		background: '#f3f6f8',
	},
	domain: {
		...contactTable.domain,
		color: grayIconFill,
		fontSize: 12,
		...truncateTextStyle,
	},
	extraTags: {
		background: 'white',
		border: `1px solid ${borderColor}`,
		borderRadius: 3,
	},
	extraTagsItem: {
		':not(:last-of-type)': {
			borderBottom: `1px solid ${borderColor}`,
		},
		alignItems: 'center',
		display: 'flex',
		fontSize: 12,
		padding: '3px 10px',
	},
	name: {
		...clickable,
		...contactTable.name,
		fontSize: 18,
	},
	outerContainer: {
		background: '#fff',
		cursor: 'pointer',
		transition: 'background 0.1s',
	},
	selection: {
		alignItems: 'center',
		display: 'flex',
		height: 50,
		justifyContent: 'center',
		paddingLeft: 12,
		width: 50,
	},
	tag: {
		display: 'inline-flex',
		margin: 4,
		// maxWidth: '80%',
	},
	tagMore: {
		fontSize: 12,
		margin: 4,
	},
	tags: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		...contactTable.tags,
		height: 50,
		minHeight: 80,
		overflow: 'hidden',
		paddingBottom: 8,
		paddingTop: 8,
	},
});
