import { StyleSheet } from 'aphrodite';
import { grayIconFill } from '../../styles/colors';
import { BreakPoints, BrowserMediaQueries, CssSelectors } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	anchor: {
		[BrowserMediaQueries.IE11]: {
			transform: 'translate3d(-1px,2px,0)',
		},
	},
	button: {
		opacity: 1,
		transform: 'translate3d(0,0,0)',
		transition: 'opacity 0.2s, transform 0.2s',
		...CssSelectors.allChildren(
			{
				alignItems: 'center',
				background: 'none',
				borderRadius: 20,
				boxShadow: 'none',
				display: 'flex',
				marginRight: 12,
				padding: '8px 10px',
				transform: 'translate3d(0,0,0)',
				transition: 'background 0.2s, box-shadow 0.2s, transform 0.1s',
				[BreakPoints.forMaxWidth(1080)]: {
					alignItems: 'center',
					flexDirection: 'column',
					justifyContent: 'center',
					marginRight: 2,
				},
				':hover': {
					boxShadow: `0 1px 2px 1px  var(--lev-people-action-bar-button-hover-shadow)`,
				},
			},
			'span'
		),
		[BreakPoints.forMaxWidth(1080)]: {
			display: 'block',
			textAlign: 'center',
		},
		':active': {
			...CssSelectors.allChildren(
				{
					boxShadow: `0 0 2px 1px rgba(0,0,0, 0.1) !important`,
					transform: 'translate3d(0,0,0) !important',
				},
				'span'
			),
		},
		':hover': {
			...CssSelectors.allChildren(
				{
					background: 'white',
					transform: 'translate3d(0,-1px,0)',
				},
				'span'
			),
		},
	},
	buttonText: {
		marginLeft: 6,
		[BreakPoints.forMaxWidth(1080)]: {
			display: 'none',
		},
	},
	container: {
		alignItems: 'center',
		display: 'flex',
		height: 62,
	},
	disabled: {
		opacity: 0.4,
		pointerEvents: 'none',
		transform: 'translate3d(-1px,1px,0)',
	},
	export: {
		'--lev-people-action-bar-button-hover-shadow': 'rgba(137,201,71, 0.4)',
	},
	noMargin: {
		margin: 0,
	},
	restoreContactText: {
		color: grayIconFill,
		textAlign: 'center',
	},
	sendCard: {
		'--lev-people-action-bar-button-hover-shadow': 'rgba(0, 170, 232, 0.4)',
	},
	sendEmail: {
		'--lev-people-action-bar-button-hover-shadow': 'rgba(234,129,96, 0.4)',
	},
	sendText: {
		'--lev-people-action-bar-button-hover-shadow': 'rgba(110, 39, 163, 0.4)',
	},
	tag: {
		'--lev-people-action-bar-button-hover-shadow': 'rgba(4,108,182, 0.4)',
	},
});
