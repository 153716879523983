import { brandPrimaryHover, darkGrayFontColor } from '../../../styles/colors';
import { CssSelectors, baseStyles } from '../../../styles/styles';
import { CSSProperties, StyleSheet } from 'aphrodite';

const ContactCardAvatarSize = 70;

export const ContactCardContainerStyle: CSSProperties = {
	background: '#E8F3F9',
	borderRadius: 4,
	boxShadow: '0 0 6px 0 rgba(139,139,139,0.10)',
	boxSizing: 'border-box',
	height: 230,
	padding: 20,
	textAlign: 'center',
};

export const styleSheet = StyleSheet.create({
	avatar: {
		height: ContactCardAvatarSize,
		width: ContactCardAvatarSize,
	},
	avatarContainer: {},
	container: {
		...ContactCardContainerStyle,
	},
	containerSelected: {
		background: brandPrimaryHover,
		transition: 'background 0.1s, color 0.1s',
		...CssSelectors.allDescendants(
			{
				color: '#F9F9F9',
			},
			'.contact-card-text'
		),
	},
	interaction: {
		color: '#4A4A4A',
		fontSize: 12,
		marginTop: 10,
	},
	interactionTime: {
		fontWeight: 600,
	},
	loadingCard: {},
	name: {
		fontSize: 16,
		fontWeight: 500,
		marginTop: 12,
		...(baseStyles.truncateText as CSSProperties),
	},
	tag: {
		display: 'inline-flex',
		marginLeft: 4,
		marginTop: 4,
		maxWidth: 80,
		minWidth: 50,
	},
	tags: {
		marginTop: 16,
		overflow: 'hidden',
	},
	tagsContent: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		marginLeft: -4,
		marginTop: -4,
	},
	tagsOverflow: {
		color: darkGrayFontColor,
		flexShrink: 0,
		fontSize: 12,
	},
	titleAndCompany: {
		color: '#4A4A4A',
		fontSize: 12,
		marginTop: 2,
		...(baseStyles.truncateText as CSSProperties),
	},
});
