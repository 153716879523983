import { StyleSheet } from 'aphrodite';
import { header, label } from '../../../../styles/colors';
import { CssSelectors } from '../../../../styles/styles';

export const styleSheet = StyleSheet.create({
	buttonContainer: {
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	editUserPasswordInstructionsContainer: {
		color: label,
		fontSize: '0.875rem',
	},
	fieldContainerTwoColumn: {
		display: 'flex',
		marginBottom: '1.125rem',
	},
	formFieldLabel: {
		color: header,
		display: 'inline-block',
		fontSize: '0.875rem',
		width: 200,
	},
	passwordInstructionsLabel: {
		color: header,
		display: 'inline-block',
		fontSize: '0.875rem',
		width: 170,
	},
});
