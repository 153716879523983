import { StyleSheet } from 'aphrodite';
import { brandPrimaryText, error, header } from '../../../../styles/colors';

export const styleSheet = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: 16,
		padding: 12,
	},
	header: {
		color: brandPrimaryText,
		fontSize: 24,
		margin: '0 0 23px',
	},
	modalBody: {
		paddingBottom: 24,
		color: error,
	},
	footer: {
		marginBottom: 24,
		display: 'flex',
		gap: 12,
	},
	label: {
		color: header,
		fontSize: 12,
	},
	sectionHeader: {
		margin: 0,
	},
	section: {
		marginTop: 16,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	lifetimeSection: {
		marginTop: 16,
		display: 'flex',
		gap: 8,
	},
	donationRow: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: 8,
		alignItems: 'flex-start',
	},
	stackedText: {
		display: 'flex',
		flexDirection: 'column',
		gap: 4,
	},
	tableOverrides: {
		marginTop: 16,
		padding: 8,
	},
});
