import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import * as TelephonyApiModels from '../../extViewmodels/sdk/models/Telephony';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useTelephonyUpdateConfiguration({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async ({
			body,
			registrationId,
		}: {
			body: TelephonyApiModels.ClientProfile;
			registrationId: string;
		}) => {
			const response =
				await userSession.webServiceHelper.callAsync<TelephonyApiModels.TelephonyServiceProviderConfiguration>(
					`telephony/configuration/${registrationId}/clientProfile`,
					'PUT',
					body
				);
			return response;
		},
		onError,
		onSuccess,
	});
}
