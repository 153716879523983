import { StyleSheet } from 'aphrodite';
import { background, brandPrimary, charmGray, prestoPurple, shuffleGray, white } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	container: {
		width: '100%',
		height: '100%',
		background: 'rgb(0, 82, 140)',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	ghostContainer: {
		zIndex: 1,
		borderRadius: '12px 12px 0px 0px',
		background: 'linear-gradient(225deg, rgba(50,197,255,.2) 0%, rgba(182,32,224,.2) 51%, rgba(247,181,0,.8) 100%)',
		position: 'absolute',
	},
	unclassifiedContactsContainer: {
		zIndex: 3,
		height: '90%',
		width: '75%',
		borderRadius: '12px 12px 0px 0px',
		display: 'flex',
		flexDirection: 'column',
		border: '3px solid rgba(182,32,224,.1)',
		background:
			'linear-gradient(225deg, rgba(50,197,255,.1) 0%, rgba(182,32,224,.1) 51%, rgba(247,181,0,.1) 100%), white',
	},
	recentEmailSubjectsContainer: {
		borderBottom: `1px solid ${shuffleGray}`,
		flex: '0 0 50px',
	},
	backButtonContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	backToTaggingGameButton: {
		gap: 4,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 24,
		textDecoration: 'none',
		marginRight: 24,
		color: brandPrimary,
	},
	headerSection: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		marginBottom: 24,
	},
	title: {
		color: prestoPurple,
		fontWeight: 600,
		marginBottom: 20,
	},
	emptyStateTitle: {
		fontSize: 20,
		color: prestoPurple,
		fontWeight: 700,
	},
	contactCardOuter: {
		width: '95%',
	},
	tagsAndSubjects: {
		border: `1px solid ${shuffleGray}`,
		borderTopRightRadius: 8,
		borderBottomRightRadius: 8,
		borderLeft: 0,
	},
	avatarOverride: {
		width: 36,
		height: 36,
		...CssSelectors.allChildren(
			{
				backgroundColor: background,
				borderColor: shuffleGray,
				color: prestoPurple,
				fontWeight: 600,
			},
			'span'
		),
	},
	cardNameContainer: {
		width: '20%',
		borderRight: `1px solid ${shuffleGray}`,
		backgroundColor: white,
		borderTopLeftRadius: 8,
		borderBottomLeftRadius: 8,
	},
	emailAddress: {
		color: charmGray,
	},
	suggestedTags: {
		flex: '1 0 50px',
	},
	ctaButton: {
		width: '90%',
		alignSelf: 'center',
	},
});
