import { SvgIcon } from '../../icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const GoogleBusinessProfileDisconnectedIntegrationGraphic: React.FC<IProps> = props => {
	const { className } = props;

	return (
		<SvgIcon className={`integration-icon ${className ?? ''}`} fill='none' width='211' height='125'>
			<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
				<g transform='translate(-323, -333)'>
					<g transform='translate(290, 219)'>
						<g transform='translate(33, 114)'>
							<g fill='#FFB302' fillOpacity='0.144445886'>
								<circle cx='52.5' cy='52.5' r='52.5' />
								<circle cx='163' cy='70' r='48' />
							</g>
							<g transform='translate(2.0157, 66.1034)' fillRule='nonzero'>
								<path
									d='M19.2670157,10.937931 L2.51308901,10.937931 L6.45585095,4.19696501 C6.59966715,3.95105671 6.86547162,3.7995678 7.15312946,3.7995678 C7.4407873,3.7995678 7.70659178,3.95105671 7.85040798,4.19696501 L9.53470264,7.07658474 L12.3985917,2.18029308 C12.5786542,1.87241936 12.9114416,1.68275862 13.2715884,1.68275862 C13.6317353,1.68275862 13.9645227,1.87241936 14.1445852,2.18029308 L19.2670157,10.937931 Z'
									fill='#FFB100'
								/>
								<path
									d='M18.5259792,10.937931 L0.741036482,10.937931 C0.331972459,10.9374705 0.00048033278,10.6196472 0,10.2274506 L0,0.710480429 C0.00048033278,0.318283838 0.331972459,0.000460526638 0.741036482,0 L18.5259792,0 C18.9350432,0.000460526638 19.2665354,0.318283838 19.2670157,0.710480429 L19.2670157,10.2274506 C19.2665354,10.6196472 18.9350432,10.9374705 18.5259792,10.937931 Z M0.741036482,0.284193138 C0.495589769,0.284451228 0.296682358,0.475156129 0.296413921,0.710482039 L0.296413921,10.2274522 C0.296681433,10.4627788 0.495589114,10.6534846 0.741036482,10.6537411 L18.5259792,10.6537411 C18.7714266,10.6534846 18.9703343,10.4627788 18.9706018,10.2274522 L18.9706018,0.710480429 C18.9703333,0.475154518 18.7714259,0.284449617 18.5259792,0.284193138 L0.741036482,0.284193138 Z'
									stroke='#2984FC'
									strokeWidth='0.5'
									fill='#00528C'
								/>
								<ellipse fill='#ED332B' cx='45.6544503' cy='2.94482759' rx='2.09424084' ry='2.10344828' />
								<path
									d='M18.7524441,5.4788942 C20.7693528,4.53211239 23.0212017,5.35754457 24.9222445,6.23018299 C25.9013307,6.67961381 26.8699241,7.15932501 27.8831581,7.51420535 C28.7355596,7.81196942 29.6085138,8.03583103 30.4937485,8.18366887 C32.2701914,8.4781025 34.0768594,8.49006601 35.8564991,8.21918027 C37.6451648,7.9492777 39.3883396,7.40077982 41.0318627,6.59072859 C42.6661959,5.78916399 44.1768845,4.72036873 45.5109516,3.42182358 C45.6806823,3.25593688 45.8471064,3.08623715 46.010224,2.9127244 C46.2043528,2.70690914 45.9061483,2.38439754 45.7121045,2.59012447 C44.4274553,3.9452197 42.952717,5.07177132 41.342614,5.92797544 C39.7390742,6.78124774 38.0287111,7.37582698 36.2659565,7.6927909 C34.4974262,8.01546938 32.6941623,8.04915521 30.9163937,7.7927233 C30.0295557,7.66296223 29.153686,7.45691839 28.2971043,7.17654896 C27.3132448,6.83027869 26.348079,6.42465285 25.4061408,5.96157695 C23.4873431,5.06985621 21.3554357,4.19666581 19.2470338,4.811646 C19.0053926,4.88395051 18.768967,4.97530008 18.5396524,5.08496236 C18.2913901,5.20150232 18.5058158,5.59467241 18.7524441,5.47889972 L18.7524441,5.4788942 Z'
									stroke='#00528C'
									strokeWidth='0.5'
									fill='#FFFFFF'
								/>
							</g>
							<g transform='translate(18.5079, 26.1448)' fillRule='nonzero'>
								<path
									d='M40.3141375,10.937931 L0.732982899,10.937931 C0.328364583,10.9374705 0.000475112525,10.6196472 0,10.2274506 L0,0.710480429 C0.000475112525,0.318283838 0.328364583,0.000460526638 0.732982899,0 L40.3141375,0 C40.7187558,0.000460526638 41.0466453,0.318283838 41.0471204,0.710480429 L41.0471204,10.2274506 C41.0466453,10.6196472 40.7187558,10.9374705 40.3141375,10.937931 L40.3141375,10.937931 Z M0.732982899,0.284188307 C0.490203702,0.284445675 0.293194156,0.475151297 0.293194156,0.710477208 L0.293194156,10.2274474 C0.293194156,10.4627727 0.490204349,10.6534773 0.732982899,10.6537347 L40.3141375,10.6537347 C40.5569161,10.6534773 40.7539263,10.4627727 40.7539263,10.2274474 L40.7539263,0.710480429 C40.7539263,0.475154517 40.5569167,0.284448896 40.3141375,0.284188307 L0.732982899,0.284188307 Z'
									stroke='#2984FC'
									strokeWidth='0.5'
								/>
								<ellipse fill='#ED332C' cx='7.12041885' cy='5.46896552' rx='2.93193717' ry='2.94482759' />
								<path
									d='M7.87056474,3.96182823 L6.9921466,4.84031669 L6.11372846,3.96182823 C5.97153405,3.81962048 5.74098681,3.81962048 5.5987924,3.96182823 C5.456598,4.10403598 5.456598,4.33459776 5.5987924,4.47680551 L6.47721054,5.35529397 L5.5987924,6.23378244 C5.45673013,6.37612169 5.45694535,6.60668543 5.59927582,6.74875972 C5.74141397,6.89064364 5.97159031,6.89064364 6.11372846,6.74875776 L6.9921466,5.87026929 L7.87056474,6.74875776 C8.01289455,6.8908314 8.24343787,6.89061616 8.38550079,6.74827691 C8.52737532,6.60612542 8.52737532,6.37593458 8.38550079,6.23378309 L7.50708265,5.35529463 L8.38550079,4.47680616 C8.5276952,4.33459841 8.5276952,4.10403663 8.38550079,3.96182888 C8.24330639,3.81962113 8.01275914,3.81962113 7.87056474,3.96182888 L7.87056474,3.96182888 L7.87056474,3.96182823 Z'
									fill='#FFFFFF'
								/>
								<path
									d='M13.914526,2.52413793 C13.6320961,2.52413793 13.4031414,2.71248711 13.4031414,2.94482759 C13.4031414,3.17716807 13.6320961,3.36551724 13.914526,3.36551724 L38.0226467,3.36551724 C38.3050767,3.36551724 38.5340314,3.17716807 38.5340314,2.94482759 C38.5340314,2.71248711 38.3050767,2.52413793 38.0226467,2.52413793 L13.914526,2.52413793 Z'
									fill='#606468'
								/>
								<path
									d='M13.891757,5.88965517 C13.621902,5.88965517 13.4031414,6.07800474 13.4031414,6.31034483 C13.4031414,6.54268492 13.621902,6.73103448 13.891757,6.73103448 L23.8036845,6.73103448 C23.9784627,6.73130872 24.1401012,6.65118605 24.2275827,6.52091151 C24.3150641,6.39063696 24.3150641,6.23005269 24.2275827,6.09977815 C24.1401012,5.96950361 23.9784627,5.88938093 23.8036845,5.88965517 L13.891757,5.88965517 Z'
									fill='#606468'
								/>
							</g>
							<g transform='translate(160.5079, 53.1448)' fillRule='nonzero'>
								<path
									d='M40.3141375,10.937931 L0.732982899,10.937931 C0.328364583,10.9374705 0.000475112525,10.6196472 0,10.2274506 L0,0.710480429 C0.000475112525,0.318283838 0.328364583,0.000460526638 0.732982899,0 L40.3141375,0 C40.7187558,0.000460526638 41.0466453,0.318283838 41.0471204,0.710480429 L41.0471204,10.2274506 C41.0466453,10.6196472 40.7187558,10.9374705 40.3141375,10.937931 L40.3141375,10.937931 Z M0.732982899,0.284188307 C0.490203702,0.284445675 0.293194156,0.475151297 0.293194156,0.710477208 L0.293194156,10.2274474 C0.293194156,10.4627727 0.490204349,10.6534773 0.732982899,10.6537347 L40.3141375,10.6537347 C40.5569161,10.6534773 40.7539263,10.4627727 40.7539263,10.2274474 L40.7539263,0.710480429 C40.7539263,0.475154517 40.5569167,0.284448896 40.3141375,0.284188307 L0.732982899,0.284188307 Z'
									stroke='#2984FC'
									strokeWidth='0.5'
								/>
								<ellipse fill='#FFB100' cx='7.12041885' cy='5.46896552' rx='2.93193717' ry='2.94482759' />
								<path
									d='M13.914526,2.52413793 C13.6320961,2.52413793 13.4031414,2.71248711 13.4031414,2.94482759 C13.4031414,3.17716807 13.6320961,3.36551724 13.914526,3.36551724 L38.0226467,3.36551724 C38.3050767,3.36551724 38.5340314,3.17716807 38.5340314,2.94482759 C38.5340314,2.71248711 38.3050767,2.52413793 38.0226467,2.52413793 L13.914526,2.52413793 Z'
									fill='#606468'
								/>
								<path
									d='M13.891757,5.88965517 C13.621902,5.88965517 13.4031414,6.07800474 13.4031414,6.31034483 C13.4031414,6.54268492 13.621902,6.73103448 13.891757,6.73103448 L23.8036845,6.73103448 C23.9784627,6.73130872 24.1401012,6.65118605 24.2275827,6.52091151 C24.3150641,6.39063696 24.3150641,6.23005269 24.2275827,6.09977815 C24.1401012,5.96950361 23.9784627,5.88938093 23.8036845,5.88965517 L13.891757,5.88965517 Z'
									fill='#606468'
								/>
							</g>
							<path
								d='M171.348366,76 L189.133309,76 C189.542373,76.0004647 189.873865,76.3211418 189.874346,76.7168601 L189.874346,94.6348641 C189.873865,95.0305823 189.542373,95.3512595 189.133309,95.3517241 L171.348366,95.3517241 C170.939302,95.3512595 170.60781,95.0305823 170.60733,94.6348641 L170.60733,76.7168601 C170.60781,76.3211418 170.939302,76.0004647 171.348366,76 Z M189.133309,95.0649808 C189.378756,95.0647211 189.577663,94.872303 189.57793,94.6348641 L189.57793,76.7168601 C189.577663,76.4794211 189.378756,76.2870031 189.133309,76.2867434 L171.348366,76.2867434 C171.102919,76.2870031 170.904013,76.4794211 170.903745,76.7168601 L170.903745,94.6348641 C170.904013,94.872303 171.102919,95.0647211 171.348366,95.0649808 L189.133309,95.0649808 Z'
								stroke='#ED332B'
								strokeWidth='0.5'
								fillRule='nonzero'
							/>
							<path
								d='M185.197207,92.8275862 C185.467085,92.8275862 185.685864,92.639237 185.685864,92.4068966 C185.685864,92.1745561 185.467085,91.9862069 185.197207,91.9862069 L175.284468,91.9862069 C175.01459,91.9862069 174.795812,92.1745561 174.795812,92.4068966 C174.795812,92.639237 175.01459,92.8275862 175.284468,92.8275862 L185.197207,92.8275862 Z'
								fill='#606468'
								fillRule='nonzero'
							/>
							<path
								d='M185.197207,88.6206897 C185.467085,88.6206897 185.685864,88.4323405 185.685864,88.2 C185.685864,87.9676595 185.467085,87.7793103 185.197207,87.7793103 L175.284468,87.7793103 C175.01459,87.7793103 174.795812,87.9676595 174.795812,88.2 C174.795812,88.4323405 175.01459,88.6206897 175.284468,88.6206897 L185.197207,88.6206897 Z'
								fill='#606468'
								fillRule='nonzero'
							/>
							<path
								d='M180.659635,79.3655172 C182.014882,79.3655172 183.236683,80.1854862 183.755313,81.4430756 C184.273944,82.700665 183.987269,84.1482132 183.028965,85.1107306 C182.070661,86.0732479 180.629449,86.361183 179.377365,85.840272 C178.12528,85.319361 177.308901,84.0921886 177.308901,82.7309835 C177.310992,80.8731588 178.809943,79.3676183 180.659635,79.3655172 Z'
								fill='#00A150'
								fillRule='nonzero'
							/>
							<g transform='translate(93, 26)' fillRule='nonzero'>
								<g transform='translate(36, 0)'>
									<circle fill='#2F2E41' cx='9.5' cy='9.5' r='7.5' />
									<circle fill='#E7B388' cx='8' cy='11' r='6' />
									<circle fill='#2F2E41' cx='15.5' cy='4.5' r='2.5' />
									<path
										d='M6.6031746,1 L6.6031746,1 C2.95634792,1 0,3.83148755 0,7.32430973 L0,10 L3.4360127,10 L5.07936508,7.32432432 L5.57242159,10 L16,10 L13.2063492,7.32430973 C13.2063408,3.83148755 10.2500013,1 6.6031746,1 L6.6031746,1 Z'
										fill='#2F2E41'
									/>
									<path
										d='M17,2.62856702 C17.5576096,0.880438405 19.2871627,-0.213392553 21.1008312,0.0350554081 C22.657478,0.295978806 23.8882342,1.55054718 24.6215602,2.95176864 C25.3548861,4.35299011 25.6935115,5.92123488 26.1865052,7.4244671 C26.679499,8.92769931 27.3786837,10.4391145 28.6232596,11.4121897 C29.8678356,12.3852649 31.7776615,12.649222 33,11.6482173 C32.713636,13.7875979 31.0950825,15.5037985 28.9806776,15.910025 C26.8662726,16.3162515 24.7296262,15.3215123 23.674785,13.4398085 C23.0298167,12.2892667 22.8633157,10.9407857 22.6720516,9.63495625 C22.4807876,8.32912678 22.232279,6.97802129 21.462239,5.90758571 C20.692199,4.83715013 19.2507555,4.13802631 18.0275287,4.62559925 L17,2.62856702 Z'
										fill='#2F2E41'
									/>
								</g>
								<polygon fill='#E7B388' points='31.999171 96 28.6117712 96 27 84 32 84' />
								<path
									d='M25.863633,95 L32,95 L32,99 L22,99 L22,99 C22,97.9391343 22.4070592,96.9217186 23.131631,96.171573 C23.8562028,95.4214274 24.8389334,95 25.863633,95 Z'
									fill='#ED332C'
								/>
								<polygon fill='#E7B388' points='55.999171 96 52.6117712 96 51 84 56 84' />
								<path
									d='M49.863633,95 L56,95 L56,99 L46,99 L46,99 C46,97.9391343 46.4070592,96.9217186 47.131631,96.171573 C47.8562028,95.4214274 48.8389334,95 49.863633,95 Z'
									fill='#ED332C'
								/>
								<path
									d='M47.5827994,37.8240438 L50.4089517,31.4029123 C51.8156709,28.3158379 51.0992913,24.6869883 48.6217464,22.3497527 C48.3734492,22.1070254 48.1097224,21.8802345 47.8321776,21.6707657 C46.4377175,20.5919952 44.7191978,20.0040416 42.9487249,20 C41.7583242,20.0045696 40.5829326,20.2630572 39.5027722,20.7578207 C39.4101037,20.798509 39.3201868,20.8417404 39.2275286,20.8849718 C39.0501018,20.9688915 38.875311,21.0604403 38.7055426,21.1545322 C36.4603427,22.429343 34.9615889,24.6835935 34.6680415,27.2272696 L33.4207773,37.5621125 C33.1071085,38.3733366 21.9153886,67.6689542 25.8294594,90.0348359 C25.9149173,90.5250725 26.3130025,90.90329 26.8117741,90.9681251 L30.7798387,91.5021598 C31.3809218,91.5834581 31.9433433,91.1915699 32.0681614,90.6044729 L38.7261362,59.2210339 C38.811505,58.8197544 39.1628445,58.5279306 39.5772831,58.5140683 C39.9917216,58.5002061 40.3621989,58.7678864 40.4747927,59.1625444 L49.8818173,92.1658887 C50.0195874,92.6587494 50.4731081,93 50.9902033,93 C51.0652406,93 51.1401004,92.9927279 51.2139656,92.9796558 L56.0613796,92.0565387 C56.6454432,91.9486031 57.0501378,91.4183735 56.9949749,90.8333449 C56.2826295,82.9779489 52.4971425,43.6297689 47.5827994,37.8240438 Z'
									fill='#2984FC'
								/>
								<circle
									fill='#ED332C'
									transform='translate(12.5502, 19.5502) rotate(-85.2688) translate(-12.5502, -19.5502)'
									cx='12.5502363'
									cy='19.5502363'
									r='11.63057'
								/>
								<path
									d='M15.0383383,14.7090217 L12.1102779,17.6373166 L9.18221741,14.7090217 C8.70823606,14.2349959 7.93974524,14.2349959 7.46576389,14.7090217 C6.99178254,15.1830476 6.99178254,15.9515868 7.46576389,16.4256127 L10.3938244,19.3539075 L7.46576389,22.2822024 C6.992223,22.7566666 6.99294039,23.5252124 7.46737529,23.9987934 C7.94116912,24.4717397 8.70842359,24.4717397 9.18221741,23.9987868 L12.1102779,21.0704919 L15.0383383,23.9987868 C15.5127711,24.4723656 16.2812488,24.4716482 16.7547919,23.997184 C17.2277069,23.5233457 17.2277069,22.7560429 16.7547919,22.2822046 L13.8267314,19.3539097 L16.7547919,16.4256148 C17.2287732,15.951589 17.2287732,15.1830497 16.7547919,14.7090239 C16.2808105,14.2349981 15.5123197,14.2349981 15.0383383,14.7090239 L15.0383383,14.7090239 L15.0383383,14.7090217 Z'
									fill='#FFFFFF'
								/>
								<path
									d='M20.0175687,16.2000473 C19.9282684,16.9223662 20.1823937,17.6449343 20.7115641,18.1733215 C21.2407346,18.7017086 21.9905916,18.9816367 22.7593621,18.9377821 L26.3123834,27 L29,23.2381533 L25.2139492,16.1588642 C25.0466596,14.9170179 23.9221234,13.9895821 22.5963863,14 C21.2706491,14.0105947 20.1627573,14.9557221 20.0175661,16.2000448 L20.0175687,16.2000473 Z'
									fill='#E7B388'
								/>
								<path
									d='M43.8039485,45.9802197 C44.5322321,45.8931095 45.1845537,45.4876522 45.5853862,44.8729487 C45.9862186,44.2582451 46.0943872,43.4974395 45.8807858,42.7952499 L53,37.6062132 L48.7138901,36 L42.6104703,41.1738458 C41.4295597,41.6209508 40.7694461,42.8812938 41.0739338,44.1075272 C41.3784216,45.3337606 42.5512671,46.1382917 43.8039485,45.9802222 L43.8039485,45.9802197 Z'
									fill='#E7B388'
								/>
								<path
									d='M31.9003848,33 C31.6227501,32.9997391 31.3458013,32.9731863 31.0734597,32.9207173 C29.7357944,32.6731347 28.6127126,31.794097 28.0781058,30.5762497 L23.2558646,24.8582898 C23.0496199,24.6137116 22.9619351,24.2944829 23.015297,23.9824709 C23.0686589,23.6704589 23.2578769,23.3960147 23.5344253,23.2295202 L26.965214,21.1643321 C27.2210528,21.0102889 27.5295708,20.9620152 27.8221863,21.0302421 C28.1148017,21.0984691 28.3672654,21.2775426 28.5234561,21.5276557 L31.922174,26.9727676 L38.4309504,22.2950084 C39.5021516,21.7488385 40.823188,22.0492372 41.5342695,23.0006914 C42.2453509,23.9521455 42.1313311,25.2667814 41.2664194,26.0889743 L34.7700706,31.8516942 C34.0066957,32.5875517 32.975251,33.0002846 31.9003848,33 Z'
									fill='#2984FC'
								/>
								<path
									d='M49.3772418,42 C49.36801,42 49.3587125,42 49.3494781,41.9997408 C49.014308,41.991693 48.6983103,41.8437754 48.4799318,41.5927088 L45.2845836,37.9137207 C45.0644393,37.6602849 44.9648745,37.3259037 45.0110916,36.9952178 C45.0573088,36.6645318 45.2448778,36.369238 45.5263202,36.1840828 L51.5096538,32.2474088 L46.0891764,25.8158878 C45.4373966,24.7435296 45.6811158,23.3616531 46.6616992,22.5697101 C47.6422826,21.7777671 49.0630146,21.8153923 49.9991526,22.6580959 L56.5870864,29.0119046 C57.6395829,29.9608064 58.1528092,31.359304 57.9601167,32.7532946 C57.7674242,34.1472851 56.8933855,35.3590035 55.6218077,35.9949955 L50.2373646,41.633715 C50.0138824,41.8673326 49.7027839,42 49.3772418,42 L49.3772418,42 Z'
									fill='#2984FC'
								/>
								<path
									d='M42.4928689,29 C42.442673,29 42.3925281,28.9967574 42.3427343,28.9903337 C41.8979627,28.9355546 41.5254073,28.6234087 41.3888524,28.1911228 L39.038541,20.8915428 C38.9167651,20.5099115 39.0917172,20.0957884 39.4483263,19.9215543 L39.5245839,19.8850581 C39.5937384,19.8517417 39.6622244,19.8188081 39.7321424,19.7872704 C40.8281231,19.2723822 42.0211024,19.0038143 43.2291996,19 C44.7975253,19.0029329 46.3308814,19.4696823 47.6410444,20.3429536 C48.0064845,20.5893177 48.1095843,21.0869203 47.8727659,21.4613385 L43.4638235,28.4584197 C43.2536978,28.7961378 42.8869413,29.000707 42.4928689,29 Z'
									fill='#F9F9F9'
								/>
							</g>
							<g transform='translate(55, 29)' fillRule='nonzero'>
								<polygon fill='#A27062' points='14.000829 93 17.3882288 92.9997462 19 81 14 81.0002538' />
								<path
									d='M13.4322882,92.1571495 L23.2063709,92.1571495 L23.2063709,95.9335018 L17.2086304,95.9335018 C15.123013,95.9335018 13.4322882,94.2427694 13.4322882,92.1571495 L13.4322882,92.1571495 L13.4322882,92.1571495 Z'
									fill='#2F2E41'
									transform='translate(18.3193, 94.0453) rotate(179.9974) translate(-18.3193, -94.0453)'
								/>
								<polygon fill='#A27062' points='3.00082896 93 6.38822879 92.9997462 8 81 3 81.0002538' />
								<path
									d='M2.27080821,92.157149 L12.0448909,92.157149 L12.0448909,95.9335013 L6.04715038,95.9335013 C3.96153296,95.9335013 2.27080821,94.2427689 2.27080821,92.157149 L2.27080821,92.157149 L2.27080821,92.157149 Z'
									fill='#2F2E41'
									transform='translate(7.1578, 94.0453) rotate(179.9974) translate(-7.1578, -94.0453)'
								/>
								<path
									d='M6.16787447,89.9981758 L3.12514882,89.8369338 C2.80823676,89.8201487 2.51117861,89.6762642 2.30025233,89.437383 C2.08932605,89.1985018 1.9820829,88.8845013 2.00245039,88.5654395 L6.16787447,46.5 L22.3864712,52.5529121 L24,52.1094352 L18.9542055,88.0207039 C18.8656572,88.5771925 18.4052682,88.9970052 17.8468414,89.0304706 L14.5668212,89.1844399 C14.2352348,89.2029887 13.9110862,89.0808195 13.6729999,88.8475653 C13.4349136,88.6143112 13.3046408,88.2912816 13.3137798,87.9568297 L13.9080959,66.403527 C13.9138564,66.1969347 13.7620267,66.0201292 13.5582705,65.9961559 C13.3545144,65.9721825 13.1663319,66.1089831 13.1246862,66.3113532 L7.39319978,89.0463086 C7.28163272,89.6025551 6.79550763,90.0018852 6.23223034,90.0001304 C6.21091982,90.0001304 6.1894594,89.9994736 6.16787447,89.9981758 Z'
									fill='#2984FC'
								/>
								<circle fill='#A27062' cx='16' cy='9' r='6' />
								<path
									d='M5.01072294,26.8766129 L5.68236555,41.6654189 L5.92516375,47.0457923 C5.94495883,47.524914 6.24732184,47.9439911 6.68976231,48.1055308 L22.4417382,53.9287754 C22.5689826,53.9770501 22.7038042,54.0011737 22.8395517,54 C23.1530434,54.0014981 23.4536795,53.8729135 23.6724179,53.6437322 C23.8911563,53.4145509 24.0091998,53.1044674 23.9994394,52.7846936 L23.3536497,26.1938593 C23.2661493,21.6933957 20.0561825,17.8918768 15.7075397,17.1386779 C15.5551756,17.1149595 15.4001603,17.0912284 15.2451475,17.0701343 C12.5990542,16.7349297 9.94329361,17.6122891 7.99166799,19.4664001 C5.97069199,21.3633333 4.87876972,24.0776898 5.01071548,26.8766053 L5.01072294,26.8766129 Z'
									fill='#00A150'
								/>
								<path
									d='M30.7090226,50.8745562 C29.949942,50.6254587 29.3468831,50.011566 29.0825944,49.2189097 C28.8183056,48.4262534 28.9238946,47.5481314 29.367364,46.8506463 L24,39.3031346 L28.6317168,39 L33.0185685,46.2137219 C33.9879419,47.0062797 34.2798818,48.4201032 33.7111576,49.5678331 C33.1424334,50.715563 31.8769768,51.2663723 30.7090276,50.8745562 L30.7090226,50.8745562 Z'
									fill='#A27062'
								/>
								<path
									d='M26.8607747,45.9100391 C26.5768918,45.7901321 26.3508942,45.5570273 26.233147,45.2626736 L18.240836,25.445872 C17.814191,24.3902987 17.9613859,23.1779995 18.6269236,22.2660499 C19.2924613,21.3541003 20.3751213,20.8811971 21.4667062,21.0256398 C22.5582911,21.1700826 23.4927837,21.9099033 23.9178505,22.966166 L31.9101467,42.7832246 C32.1561009,43.394995 31.8798202,44.0985145 31.292542,44.3558894 L27.7443628,45.9056205 C27.4622294,46.0299049 27.1440337,46.0315007 26.8607624,45.9100519 L26.8607747,45.9100391 Z'
									fill='#00A150'
								/>
								<path
									d='M13.1414829,45.0646058 L21.0980528,29.6764827 C21.4175319,29.0602972 22.1705335,28.8216014 22.7812976,29.1429068 L34.3302596,35.2352101 C34.9403046,35.5579053 35.1766209,36.3184849 34.8585166,36.9353951 L26.901939,52.3235182 C26.5824589,52.9397025 25.8294584,53.178398 25.2186942,52.8570941 L13.6697424,46.7647908 C13.0596965,46.4420965 12.8233789,45.6815167 13.1414829,45.0646058 L13.1414829,45.0646058 Z'
									fill='#FFE2A0'
								/>
								<path
									d='M18.8247777,38.3810933 C18.532632,37.5966978 17.8907723,36.9980236 17.0937021,36.7664877 C16.2966319,36.5349517 15.4381669,36.697806 14.7784487,37.2057006 L7.01319744,32 L7,36.8935729 L14.3760271,41.0867686 C15.2164511,42.0605434 16.6271002,42.2846119 17.7226056,41.6183416 C18.818111,40.9520713 19.2826984,39.5875114 18.8247777,38.3811038 L18.8247777,38.3810933 Z'
									fill='#A27062'
								/>
								<path
									d='M9.3455726,38.7481318 L3.03424361,33.9337272 C1.64830616,33.4855766 0.579269804,32.4105753 0.174258516,31.0577841 C-0.230752771,29.7049929 0.0799089692,28.2469228 1.00536958,27.15702 L6.73589107,19.9247069 C7.55903848,18.9560329 8.9954938,18.715532 10.1098415,19.3598177 C11.2241892,20.0041034 11.6790256,21.338098 11.1781626,22.4931081 L6.64402493,29.6356376 L13.3073285,32.6791693 C13.6207533,32.8223126 13.8554677,33.0879207 13.9520592,33.4087611 C14.0486507,33.7296015 13.9978607,34.0749208 13.8125456,34.3573092 L11.122808,38.4565367 C10.9389296,38.7363277 10.6404894,38.9279234 10.3015754,38.9837603 C10.2949235,38.9848791 10.2882223,38.9858731 10.2815289,38.9868321 C9.948694,39.035263 9.60988847,38.9488562 9.3455726,38.7481318 Z'
									fill='#00A150'
								/>
								<path
									d='M11.0848495,12.5335773 C11.0302878,12.3801661 11.0526494,12.2111214 11.1454163,12.0757153 C11.2381832,11.9403091 11.3909149,11.8537812 11.559094,11.841352 C11.9025062,11.859306 12.2233464,12.0114492 12.4471338,12.2624626 C12.6742132,12.5141232 12.938572,12.7323085 13.231721,12.910012 C13.5352502,13.0570711 13.9469174,13.0361132 14.1199524,12.755746 C14.2824442,12.492462 14.1711052,12.1398999 14.0727364,11.8340713 C13.8224196,11.0560041 13.6823608,10.2490555 13.656343,9.43502152 C13.6280063,8.52639798 13.7613796,7.57815205 14.2847281,6.86946978 C14.960158,5.95484739 16.1679059,5.60761623 17.3108844,5.63279275 C18.453863,5.65796927 19.5783021,5.99709964 20.710459,6.21147451 C21.1013079,6.28548163 21.5607301,6.32332376 21.822145,6.03215965 C22.1001093,5.7225619 21.9980209,5.22149199 21.8797867,4.8034729 C21.5729824,3.71876106 21.2474981,2.59727771 20.5346725,1.72123655 C19.7639266,0.810947957 18.6629609,0.215175283 17.4534955,0.0538993748 C16.2923397,-0.084351529 15.1136254,0.0442728344 14.0142644,0.429197202 C12.2231282,0.971823059 10.6470547,2.02715749 9.49682402,3.45406262 C8.31110937,4.97151634 7.79875335,6.87850646 8.07178852,8.75804748 C8.33853025,10.3950523 9.24101258,11.8739933 10.5973128,12.8967448 L11.0848495,12.5335773 Z'
									fill='#2F2E41'
								/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
