import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	toaster: {
		left: '50%',
		position: 'fixed',
		top: 10,
		transform: 'translate(-50%, 0)',
	},
});
