import { StyleSheet } from 'aphrodite';
import { header, titles, white } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	card: {
		boxSizing: 'border-box',
		borderRadius: 8,
		backgroundColor: white,
		zIndex: 1,
		...CssSelectors.allChildren(
			{
				margin: '0 0 8px',
				fontSize: 16,
				color: titles,
			},
			'h3'
		),
		...CssSelectors.allChildren(
			{
				fontSize: 14,
				marginBottom: '0 0 16px',
				color: header,
			},
			'p'
		),
	},
	button: {},
	moreMenu: {
		position: 'absolute',
		top: 16,
		right: 16,
		width: 32,
		zIndex: 3,
	},
});
