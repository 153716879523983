import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	heading: {
		borderRadius: 8,
		height: 16,
		position: 'relative',
		width: 150,
	},
});
