/* eslint-disable react/no-unescaped-entities */
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEventLogging } from '../../../../models/Logging';
import { getSocialMediaPlatformLogoUrl } from '../../../../models/UiUtils';
import { useErrorMessages, useUserSession } from '../../../../models/hooks/appStateHooks';
import FacebookLogoImage from '../../../../web/assets/facebook_logo.png';
import InstagramLogoImage from '../../../../web/assets/instagram_logo.png';
import LinkedInLogoImage from '../../../../web/assets/linkedIn_logo.png';
import LoginWithFacebookImageUrl from '../../../../web/assets/loginwithfacebook.png';
import { DashboardCardTag } from '../DashboardCard';
import { styleSheet } from './styles';

export interface ISocialMediaSettingsserviceConnectionStatusProps {
	viewModel: Api.SocialMediaViewModel;
}

export function ConnectAccountsSection() {
	const userSession = useUserSession();

	// create a dictionary of vms for the supported services
	const vms = React.useRef<Record<string, Api.SocialMediaViewModel>>(
		Api.SupportedSocialMediaTypes.reduce<Record<string, Api.SocialMediaViewModel>>((result, x) => {
			switch (x) {
				case Api.SocialMediaType.Facebook: {
					result[x] = new Api.FacebookViewModel(userSession);
					break;
				}
				case Api.SocialMediaType.Instagram: {
					result[x] = new Api.InstagramViewModel(userSession);
					break;
				}
				case Api.SocialMediaType.LinkedIn: {
					result[x] = new Api.LinkedInViewModel(userSession);
					break;
				}
				default: {
					break;
				}
			}
			return result;
		}, {})
	).current;

	return (
		<>
			<div className={css(styleSheet.socialMediaColumn)}>
				<DashboardCardTag styleDeclaration={styleSheet.recentPostsTag}>Connect Social Media Accounts</DashboardCardTag>
				<div className={css(styleSheet.serviceConnections)}>
					{Api.SupportedSocialMediaTypes.map(x => {
						const vm = vms[x];
						return vm && <SocialMediaSettingsServiceConnectionStatus key={x} viewModel={vm} />;
					}).filter(x => !!x)}
				</div>
			</div>
		</>
	);
}

export function RecentPostsSection({ recentPosts }: { recentPosts: Api.ISocialMediaPost[] }) {
	const post = recentPosts?.[0];
	// @ts-ignore
	const displayTargets = post?.designatedTargets?.length > 0 ? post.designatedTargets : post?.targets;

	return (
		<div className={css(styleSheet.socialMediaColumn)}>
			<DashboardCardTag styleDeclaration={styleSheet.recentPostsTag}>Most Recent Post</DashboardCardTag>
			{post ? (
				<article className={css(styleSheet.socialMediaPost)}>
					<h3 className={css(styleSheet.socialMediaPostTitle)}>{post.name}</h3>
					<div className={css(styleSheet.socialMediaPostTargets)}>
						{displayTargets?.map((target, index) => {
							const displayName = target.state?.lastConnectionState?.postTargetDisplayName;
							return (
								<figure key={index} className={css(styleSheet.socialMediaPostTarget)}>
									<img
										src={getSocialMediaPlatformLogoUrl(target.provider)}
										className={css(styleSheet.socialMediaPostLogo)}
									/>
									<figcaption className={css(styleSheet.postSubmitter)}>{displayName}</figcaption>
								</figure>
							);
						})}
					</div>
				</article>
			) : (
				<span className={css(styleSheet.postSubmitter)}>You don't have any post to show.</span>
			)}
		</div>
	);
}

export const SocialMediaSettingsServiceConnectionStatus: React.FC<ISocialMediaSettingsserviceConnectionStatusProps> =
	observer(({ viewModel }) => {
		const errorMessages = useErrorMessages();
		const { logApiError } = useEventLogging('SocialMediaCardConnectionStatus');

		const name = viewModel.getConnectionType();
		const connections = viewModel.connections;

		const onCtaClicked = async () => {
			try {
				const opResult = await viewModel.getAuthUrl();
				// @ts-ignore
				const authUrl = opResult.value;
				// @ts-ignore
				window.location.href = authUrl;
			} catch (err) {
				// @ts-ignore
				logApiError('GetAuthUrl-Error', err);
				// @ts-ignore
				// @ts-ignore
				errorMessages.pushApiError(err);
			}
		};

		const getMediaLogo = () => {
			switch (viewModel.getConnectionType()) {
				case Api.SocialMediaType.Facebook: {
					return FacebookLogoImage;
				}
				case Api.SocialMediaType.Instagram: {
					return InstagramLogoImage;
				}
				case Api.SocialMediaType.LinkedIn: {
					return LinkedInLogoImage;
				}
				default: {
					return;
				}
			}
		};
		const renderConnectBtn = () => {
			return (
				<button
					className={css(
						viewModel.getConnectionType() === Api.SocialMediaType.LinkedIn
							? styleSheet.linkedInConnect
							: styleSheet.loginButton
					)}
					disabled={viewModel.isBusy}
					onClick={onCtaClicked}
				>
					{viewModel.getConnectionType() !== Api.SocialMediaType.LinkedIn ? (
						<div>
							<img src={LoginWithFacebookImageUrl} width={160} />
						</div>
					) : (
						<span>Sign in with {name}</span>
					)}
				</button>
			);
		};
		if (!viewModel.getConnectionType()) {
			return null;
		}
		return (
			<div className={css(styleSheet.serviceConnection)}>
				<div className={css(styleSheet.socialButtonContainer)}>
					<img src={getMediaLogo()} width={20} height={20} />
					{connections == null || connections.length === 0 ? renderConnectBtn() : null}
					<div className={css(styleSheet.stackMultipleAccounts)}>
						{connections != null &&
							connections.length > 0 &&
							connections.map(conn => {
								return conn.state === Api.SocialMediaConnectionState.Connected ? (
									<div className={css(styleSheet.serviceConnectedText)} key={conn.userName}>
										<span>{`@${conn.userName}`}</span>
									</div>
								) : (
									renderConnectBtn()
								);
							})}
					</div>
				</div>
			</div>
		);
	});
