import * as Api from '@ViewModels';
import { useHistory } from 'react-router';
import { useToaster } from '../../../../models/hooks/appStateHooks';
import {
	invalidateIntegrationStatusQuery,
	useCallbackAuthorizeUrlMutation,
	useDisableIntegrationMutation,
	useIntegrationStatusQuery,
} from '../../../../queries';
import { IntegrationOAuthStep } from '../../../../viewmodels/AppViewModels';

export const getOAuthStepFromIntegrationStatus = (status: Api.IIntegrationStatus) => {
	if (!status.isConnected) {
		return IntegrationOAuthStep.SignIn;
	}
	return status.lastLoadDate ? IntegrationOAuthStep.Connected : IntegrationOAuthStep.InProgress;
};

export function useManageIntegrationConnection({ provider }: { provider: Api.ConfigurableIntegrationType }) {
	const toaster = useToaster();
	const history = useHistory();
	const disableIntegrationMutation = useDisableIntegrationMutation({
		onError: error => {
			toaster.push({
				message: error.systemMessage,
				type: 'errorMessage',
			});
		},
		onSuccess: () => {
			toaster.push({
				message: 'Integration disabled successfully',
				type: 'successMessage',
			});
			invalidateIntegrationStatusQuery(provider);
			history.push('/integrations');
		},
	});
	const callbackAuthorizeUrlMutation = useCallbackAuthorizeUrlMutation({
		onError: error => {
			toaster.push({
				message: error.systemMessage,
				type: 'errorMessage',
			});
		},
		onSuccess: url => {
			window.location.replace(url);
		},
	});
	const integrationStatusQuery = useIntegrationStatusQuery({ provider });
	const oauthStep: IntegrationOAuthStep | null = integrationStatusQuery.data
		? getOAuthStepFromIntegrationStatus(integrationStatusQuery.data)
		: null;
	const onConnect = () => {
		callbackAuthorizeUrlMutation.mutate({ provider });
	};
	const onDisable = () => {
		disableIntegrationMutation.mutate(provider);
	};
	return {
		oauthStep,
		onConnect,
		onDisable,
		isLoadingCallbackAuthUrl: callbackAuthorizeUrlMutation.isLoading,
		hasIntegrationStatusLoaded: integrationStatusQuery.isSuccess,
	};
}
