import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { Editor } from 'tinymce';
import { IRichContentEditorState } from '../../../extViewmodels';
import { useErrorMessages } from '../../../models/hooks/appStateHooks';
import { AttachInputButton } from '../../components/AttachInputButton';
import { DeprecatedCloseButton } from '../../components/DeprecatedCloseButton';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { TextCampaignEditor } from '../../components/TextCampaignEditor';
import { EmojiPickerButton } from '../../components/texting/EmojiPickerButton';
import { OutboundTextMessageBubble } from '../../components/texting/MessageBubble/OutboundTextMessageBubble';
import { oldSilver } from '../../styles/colors';
import { bs } from '../../styles/styles';
import { FileImagePreview } from './FileImagePreview';
import { StoredAttachmentPreview } from './StoredAttachmentPreview';

export function TextCampaignPageBody({
	contentEditorState,
	attachmentFiles,
	storedAttachments,
	onAttachFiles,
	onRemoveAttachmentFile,
	onContentStateChanged,
	onRemoveStoredAttachment,
}: {
	attachmentFiles: File[];
	storedAttachments: Api.IFileAttachment[];
	onAttachFiles: (files: File[]) => void;
	onRemoveAttachmentFile: (file: File) => void;
	onRemoveStoredAttachment: (attachmentId: string) => void;
	contentEditorState: IRichContentEditorState;
	onContentStateChanged: (content: IRichContentEditorState) => void;
}) {
	const contentEditorRef = React.useRef<Editor>();
	const errorMessages = useErrorMessages();
	const hasAttachments = attachmentFiles.length > 0 || storedAttachments.length > 0;
	return (
		<>
			<div className={css(bs.mb2, bs.flex, bs.gap2)}>
				<EmojiPickerButton
					onEmojiClick={(_, data) => {
						contentEditorRef.current?.insertContent(data.emoji);
					}}
				/>
				<AttachInputButton
					multiple={true}
					onChange={ev => {
						if (!ev.target.files) {
							return;
						}
						const files = Array.from(ev.target.files).filter(file => attachmentFiles.every(f => f.name !== file.name));
						let totalFileSize = 0;
						for (const attachedFile of attachmentFiles) {
							totalFileSize += attachedFile.size;
						}
						for (const file of files) {
							totalFileSize += file.size;
						}
						ev.target.value = null;
						if (totalFileSize > Api.TextMessagingMaxFileByteSize) {
							errorMessages.push({
								messages: ['Total attached file size must not exceed 4.5MB.'],
							});
							return;
						}
						onAttachFiles(files);
					}}
				/>
			</div>
			{hasAttachments ? (
				<div
					className={css(
						bs.flex,
						bs.itemsEnd,
						bs.bgWhite,
						bs.borderGray300,
						bs.border,
						bs.borderSolid,
						bs.roundedTLg,
						bs.roundedBNone,
						bs.flexWrap,
						bs.gap2,
						bs.p2
					)}
				>
					{storedAttachments.map(attachment => {
						return (
							<StoredAttachmentPreview
								attachment={attachment}
								key={attachment.url}
								onClose={() => onRemoveStoredAttachment(attachment.id)}
							/>
						);
					})}
					{attachmentFiles.map((file, i) => {
						return file.type.includes('image/') ? (
							<div className={css(bs.relative)} key={i}>
								<FileImagePreview
									className={css(bs.roundedLg, bs.maxWFull, bs.objectCover)}
									file={file}
									style={{ maxWidth: 240, maxHeight: 240 }}
									loadingEl={<LoadingSpinner type='extra-small' />}
								/>
								<DeprecatedCloseButton
									className={css(bs.absolute, bs.right1)}
									fillColor={oldSilver}
									onClick={() => {
										onRemoveAttachmentFile(file);
									}}
									opacity={0.9}
									outlineColor='#FFF'
									outlineWidth={1.5}
									widthAndHeight={16}
									xMark={true}
								/>
							</div>
						) : (
							<div
								className={css(bs.flex, bs.py1, bs.itemsCenter, bs.px2, bs.gap2, bs.bgGray200, bs.boxBorder)}
								key={i}
							>
								<span className={css(bs.boxBorder, bs.textBrandPrimaryHover, bs.textSm)}>{file.name}</span>
								<DeprecatedCloseButton
									fillColor={oldSilver}
									onClick={() => onRemoveAttachmentFile(file)}
									opacity={0.9}
									outlineColor='#FFF'
									outlineWidth={1.5}
									widthAndHeight={16}
									xMark={false}
								/>
							</div>
						);
					})}
				</div>
			) : null}
			<div className={css(bs.mb3)}>
				<TextCampaignEditor
					onLoad={editor => {
						contentEditorRef.current = editor;
					}}
					onContentStateChanged={onContentStateChanged}
					contentState={contentEditorState}
					styles={[bs.roundedLg, bs.bgWhite, hasAttachments ? bs.roundedTNone : undefined]}
					config={{
						minHeight: 160,
					}}
				/>
			</div>
			<OutboundTextMessageBubble>
				If you&apos;d prefer not to hear from me by text message, please reply back with “Stop”.
			</OutboundTextMessageBubble>
			<div className={css(bs.textRight, bs.textXs, bs.textHeader, bs.italic)}>*Required by CAN-SPAM</div>
		</>
	);
}
