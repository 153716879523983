import { StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	contentContainer: {
		':hover': {
			cursor: 'pointer',
		},
		...CssSelectors.allChildren(
			{
				fontSize: '14px',
			},
			':first-child:not(.tag):not(.saved-search)'
		),
		...CssSelectors.allChildren(
			{
				alignItems: 'center',
				display: 'flex',
				marginLeft: 8,
				textTransform: 'capitalize',
			},
			':last-child'
		),
		...CssSelectors.allChildren(
			{
				marginLeft: 0,
			},
			'.saved-search'
		),
	},
	itemsContainer: {
		':last-child': {
			marginBottom: 0,
		},
		marginBottom: 8,
	},
	pill: {
		borderRadius: 30,
		fontSize: '12px',
		padding: '3px 10px',
		...CssSelectors.allChildren(
			{
				height: 13,
				marginRight: 8,
				width: 13,
			},
			'svg'
		),
	},
});
