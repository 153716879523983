import { SvgIcon } from '../../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

const maskTypeStyle: React.CSSProperties = { maskType: 'alpha' };
export const CoffeeCupGraphic: React.FC<React.SVGProps<SVGSVGElement>> = ({ className = '', ...restProps }) => {
	return (
		<SvgIcon className={`coffee-cup-graphic ${className}`} height={412} width={602} {...restProps}>
			<g filter='url(#coffee-cup-graphic-filter)'>
				<rect x='6' y='6' width='590' height='400' rx='3' fill='#E8F3F9' />
			</g>
			<mask
				id='coffee-cup-graphic-mask'
				style={maskTypeStyle}
				maskUnits='userSpaceOnUse'
				x='6'
				y='6'
				width='590'
				height='400'
			>
				<rect x='6' y='6' width='590' height='400' rx='3' fill='#fff' />
			</mask>
			<g mask='url(#coffee-cup-graphic-mask)'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M212.017 470.722c-30.54-9.908-69.356-29.514-125.69-99.835v-.019c-24.884-31.052-46.503-63.47-56.905-81.824C15.111 263.763 7.386 236.394 6 208.986v-18.088c1.974-38.093 16.267-75.446 42.346-106.535 19.114-22.757 43.352-43.806 72.032-62.596l.797-.513a132.206 132.206 0 0 1 4.878-3.018l24.618-14.405c1.613-.93 3.227-1.842 4.84-2.677l.721-.38c58.252-30.5 123.773-47.981 179.785-47.981 26.706 0 52.121 3.74 75.563 11.122 32.172 10.136 60.89 29.059 83.021 54.758l.873 1.025 1.177.664c20.67 11.502 39.537 27.009 54.589 44.888 38.436 45.647 54.55 106.118 44.206 165.905-9.396 54.378-37.677 100.196-81.75 132.481l-.949.703-.684.949c-.265.351-.526.702-.787 1.053s-.522.702-.788 1.053c-27.408 36.271-66.091 66.184-111.872 86.531-39.138 17.404-81.693 26.97-119.825 26.97-23.498 0-45.953-3.416-66.774-10.173Zm-83.307-436.2c-27.446 17.974-50.564 38.035-68.729 59.654-44.813 53.334-51.608 126.901-17.33 187.371 10.08 17.823 31.167 49.405 55.538 79.831 53.811 67.189 90.121 85.657 118.516 94.881 19.303 6.264 40.201 9.433 62.086 9.433 36.044 0 76.416-9.11 113.656-25.661 43.447-19.284 80.061-47.583 105.912-81.804a181.331 181.331 0 0 0 3.474-4.783c42.536-30.046 69.716-73.206 78.656-124.889 9.566-55.365-5.334-111.318-40.866-153.53-14.805-17.595-33.425-32.551-53.943-43.332-20.651-25.167-47.831-43.578-78.675-53.277-21.96-6.909-45.857-10.42-71.007-10.42-53.62 0-116.579 16.854-172.724 46.255l-.759.398a87.758 87.758 0 0 0-4.176 2.316L133.721 31.39a113.72 113.72 0 0 0-4.214 2.619l-.797.512Z'
					fill='#5BBF7D'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M221.393 441.815c-26.269-8.522-60.036-25.87-111.36-89.927l.019-.038c-23.84-29.78-44.396-60.528-54.19-77.819-31.128-54.909-24.94-121.7 15.754-170.137 17.197-20.48 39.214-39.574 65.408-56.732l.778-.512a89.559 89.559 0 0 1 3.587-2.221l24.618-14.425a78.375 78.375 0 0 1 3.55-1.955l.778-.398C224.373-.63 284.751-16.858 336.017-16.858c23.612 0 45.972 3.265 66.452 9.718 28.49 8.978 53.525 26.117 72.43 49.576l1.006 1.253 1.424.74c19.303 9.945 36.841 23.896 50.697 40.352 32.647 38.776 46.313 90.231 37.506 141.174-8.313 48.057-33.729 88.105-73.493 115.816l-1.139.797-.778 1.139a162.62 162.62 0 0 1-3.872 5.371c-24.296 32.172-58.859 58.82-99.953 77.079-35.342 15.696-73.512 24.351-107.487 24.351-20.31 0-39.613-2.923-57.417-8.693ZM145.337 59.898C120.378 76.24 99.48 94.347 83.233 113.687h.018c-36.575 43.541-42.137 103.556-14.178 152.904 9.49 16.759 29.515 46.71 52.823 75.807 48.819 60.926 80.061 77.154 104.185 84.973 16.286 5.277 34.014 7.953 52.729 7.953 59.39 0 145.828-29.856 195.33-95.394a130.171 130.171 0 0 0 5.77-8.256c38.398-25.472 62.807-62.881 70.665-108.301 8.048-46.52-4.403-93.458-34.165-128.799-13.685-16.266-31.071-29.647-50.375-38.776-17.386-23.137-40.903-39.858-68.14-48.437-19.019-5.979-39.841-9.016-61.897-9.016-48.875 0-106.709 15.583-158.64 42.781l-.797.418a59.547 59.547 0 0 0-2.885 1.594l-24.618 14.425a65.784 65.784 0 0 0-2.942 1.822l-.779.513Z'
					fill='#89C947'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M230.75 412.947c-21.979-7.137-50.697-22.226-97.01-80.04l-.019.019c-22.777-28.451-42.25-57.566-51.457-73.794-24.807-43.787-19.853-97.026 12.585-135.651 15.298-18.221 35.076-35.322 58.802-50.867l.778-.512c.778-.513 1.538-.968 2.297-1.424l24.618-14.424c.74-.437 1.48-.855 2.258-1.253l.798-.418C234.224 28.486 289.477 13.53 335.998 13.53c20.519 0 39.803 2.79 57.341 8.314 24.751 7.782 46.047 23.174 61.612 44.489l1.176 1.613 1.803.835c17.975 8.219 34.185 20.518 46.864 35.569 26.858 31.906 38.075 74.345 30.806 116.443-7.213 41.68-29.819 75.939-65.37 99.057l-1.405.911-.892 1.424a127.798 127.798 0 0 1-5.941 8.617c-46.35 61.4-127.455 89.358-183.201 89.358-17.121 0-33.274-2.429-48.041-7.212ZM162.002 85.312c-22.492 14.729-41.15 30.843-55.499 47.925-28.338 33.727-32.647 80.191-11.009 118.398 8.617 15.203 27.978 44.147 50.109 71.782 43.808 54.701 69.982 68.651 89.836 75.086 13.267 4.289 27.844 6.472 43.371 6.472 53.753 0 129.79-28.622 171.092-83.323 2.79-3.701 5.39-7.668 7.744-11.805 34.45-20.841 56.221-52.48 62.997-91.636 6.51-37.676-3.493-75.598-27.465-104.068-12.604-14.975-28.832-26.648-47.073-33.899-13.95-21.333-33.709-36.48-57.302-43.9-16.058-5.05-33.805-7.612-52.767-7.612-44.13 0-96.839 14.33-144.576 39.327l-.797.418c-.55.284-1.082.588-1.613.892l-24.637 14.425-.302.182c-.436.263-.88.53-1.311.824l-.798.512Z'
					fill='#AEB646'
				/>
				<path
					d='m535.182-27.468-.873-.531-.702-.74c-26.953-28.698-60.644-50.468-97.694-63.223h-77.897c23.422 1.594 45.971 5.75 67.249 12.45 38.037 11.977 72.373 33.88 99.364 63.413 23.156 13.742 44.377 31.716 61.478 52.006a243.962 243.962 0 0 1 16.874 22.567v-25.87a286.543 286.543 0 0 0-5.258-6.472c-17.424-20.707-39.062-39.232-62.541-53.6Zm-407.8-25.186a193.896 193.896 0 0 0-7.422 4.1L95.361-34.15a186.275 186.275 0 0 0-7.46 4.613l-.796.53C55.464-8.278 28.206 15.087 6 40.5v24.048c2.41-3.189 4.878-6.34 7.478-9.433 21.98-26.155 49.54-50.184 81.94-71.403l.816-.532a192.064 192.064 0 0 1 6.795-4.213l24.618-14.406c2.259-1.31 4.518-2.582 6.757-3.74l.684-.36C192.277-69.489 256.261-88.109 314-91.981h-87.33c-33.539 9.3-67.097 22.472-98.605 38.966l-.664.342-.019.019Z'
					fill='#00AAE8'
				/>
				<path
					d='m533.834 386.868.627-.816.816-.627c29.021-22.055 51.817-49.405 67.704-80.817v-40.2c-14.065 44.585-41.093 82.45-79.358 110.768-.342.456-.665.911-1.006 1.348-28.946 38.321-69.678 69.884-117.813 91.256-41.037 18.221-85.793 28.261-125.994 28.261-25.074 0-49.122-3.663-71.463-10.913-32.684-10.61-74.024-31.336-132.865-104.789-25.415-31.716-47.565-64.969-58.27-83.835A200.963 200.963 0 0 1 6 275.815v33.348c11.806 19.91 32.837 51 56.638 80.704 61.327 76.565 105.21 98.43 140.04 109.743 6.416 2.068 12.964 3.853 19.607 5.39h115.877c24.523-4.992 49.274-12.85 72.81-23.308 49.957-22.187 92.455-54.985 122.881-94.824h-.019Z'
					fill='#2EB4B2'
				/>
				<path
					d='M50.776 399.357C33.73 378.08 18.034 356.044 6 337.709v27.047c9.851 14.102 21.088 29.286 32.931 44.091 29.838 37.258 58.1 65.177 86.363 85.334a265.7 265.7 0 0 0 16.399 10.8h32.533c-32.78-14.615-72.563-42.117-123.45-105.624Z'
					fill='#00AAE8'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M240.127 384.059c-17.709-5.751-41.378-18.563-82.68-70.132v-.019c-21.619-26.989-40.429-55.08-48.742-69.77-18.488-32.627-14.786-72.334 9.414-101.145 13.4-15.944 30.958-31.071 52.197-44.983l.778-.513c.323-.209.645-.417.987-.607l24.637-14.425c.323-.19.626-.36.968-.531l.797-.418c45.63-23.896 95.777-37.6 137.534-37.6 17.406 0 33.634 2.316 48.211 6.91 20.879 6.566 38.303 20.27 50.375 39.61l1.367 2.202 2.429.911c16.76 6.245 31.679 16.741 43.162 30.369 21.088 25.034 29.876 58.458 24.125 91.711-6.112 35.284-26.004 63.717-57.531 82.184l-1.822 1.063-1.006 1.86a94.203 94.203 0 0 1-7.554 11.768c-38.303 50.734-108.968 77.287-158.963 77.287-13.932 0-26.953-1.936-38.683-5.732Zm-61.498-273.333c-19.986 13.097-36.442 27.237-48.875 42.041l.038-.019c-20.404 24.276-23.403 56.428-7.839 83.892 8.295 14.634 27.218 42.611 47.376 67.778 39.024 48.722 60.852 60.433 75.505 65.178 10.212 3.303 21.657 4.992 33.995 4.992 45.117 0 111.91-24.978 146.853-71.252 3.512-4.65 6.568-9.85 9.111-15.487 30.939-16.114 50.242-41.927 55.936-74.82 4.973-28.812-2.6-57.737-20.784-79.337-11.711-13.912-26.99-23.649-44.358-28.337-9.983-19.682-26.174-33.842-45.914-40.048-13.097-4.119-27.788-6.206-43.656-6.206-39.403 0-86.969 13.077-130.511 35.872l-25.757 15.032-1.12.721Z'
					fill='#D3A444'
				/>
				<path
					d='m515.936-3.61-1.006-.588-.779-.873c-25.301-27.977-57.625-48.703-93.461-59.977-26.402-8.314-54.892-12.527-84.692-12.527-60.757 0-131.422 18.752-193.887 51.455l-.684.36a140.288 140.288 0 0 0-6.13 3.398L110.698-7.956a144.011 144.011 0 0 0-6.15 3.815l-.797.531C72.584 16.794 46.125 39.836 25.114 64.87A217.5 217.5 0 0 0 6 91.425v33.158c7.535-17.69 17.785-34.544 30.73-49.956C56.792 50.75 82.132 28.694 112.064 9.088l.797-.531a141.372 141.372 0 0 1 5.505-3.417l24.618-14.425a121.412 121.412 0 0 1 5.466-3.018l.721-.38c60.34-31.601 128.443-49.727 186.827-49.727 28.263 0 55.215 3.986 80.137 11.825 34.45 10.837 65.218 30.994 89.076 58.306 21.809 12.3 41.72 28.793 57.664 47.716a215.618 215.618 0 0 1 40.106 72.22v-39.29a230.69 230.69 0 0 0-28.49-42.723C558.243 26.36 538.01 9.316 515.936-3.648v.038Z'
					fill='#2EB4B2'
				/>
				<path
					d='M181.135 294.928c36.5 45.571 55.689 56.124 68.33 60.224 8.713 2.828 18.583 4.252 29.326 4.252 41.435 0 102.742-22.852 134.744-65.216 3.283-4.346 6.092-9.319 8.351-14.766l1.101-2.639 2.562-1.271c28.282-13.97 45.174-35.873 50.223-65.064 4.214-24.409-2.125-48.817-17.424-66.981-10.363-12.318-24.105-20.688-39.708-24.2l-3.663-.835-1.575-3.416c-7.991-17.462-21.6-29.97-38.284-35.227-11.616-3.663-24.77-5.523-39.101-5.523-37.012 0-82.015 12.45-123.469 34.145l-.645.341-24.315 14.236-.645.417c-18.753 12.28-34.089 25.452-45.573 39.118-16.209 19.303-18.601 44.831-6.244 66.639 7.991 14.102 26.364 41.225 46.028 65.766h-.019Z'
					fill='#F89143'
				/>
				<path
					d='M89.515 504.981h24.808c-28.699-20.859-57.265-49.215-87.254-86.644A844.897 844.897 0 0 1 6 390.835v25.262a894.437 894.437 0 0 0 9.225 11.749c25.358 31.659 49.824 57.073 74.29 77.154v-.019ZM553.631-51.762l-.626-.664a273.533 273.533 0 0 0-48.23-39.555h-30.293c25.832 13.267 49.406 30.862 69.507 52.1C565.741-26.46 585.955-9.645 603 9.126V-12.72c-14.71-14.444-31.166-27.56-48.572-38.549l-.778-.493h-.019Zm-482.375-3.17-.779.513C46.62-38.78 25.057-21.698 6-3.401v21.467c20.803-21.657 45.193-41.7 72.791-59.769l.816-.531a196.705 196.705 0 0 1 8.105-5.01l24.599-14.407a179.346 179.346 0 0 1 8.048-4.46l.664-.342c18.43-9.642 37.544-18.183 56.961-25.528h-39.366A527.864 527.864 0 0 0 114-79.91l-.645.342a213.074 213.074 0 0 0-8.712 4.82l-24.6 14.407a221.901 221.901 0 0 0-8.768 5.428l-.02-.019Zm505.93 484.942.532-.646.645-.512c8.674-6.966 16.893-14.311 24.618-22.036v-22.093c-10.895 12.147-22.929 23.46-36.082 33.842-26.934 34.126-61.555 63.526-101.433 86.435h28.907c31.85-21.125 59.923-46.444 82.813-74.971v-.019Z'
					fill='#2C8DD6'
				/>
				<path
					d='m555.529 408.448.57-.721.721-.569c17.5-13.704 32.912-29.154 46.161-46.103v-26.098c-15.317 23.422-34.64 44.262-57.701 61.932-31.926 41.491-76.208 75.598-128.158 98.659-7.687 3.416-15.526 6.548-23.422 9.433h39.214c49.103-23.478 91.354-56.694 122.615-96.533Z'
					fill='#00AAE8'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M279.649 344c-9.06-.001-17.552-1.16-25.115-3.609-37.657-12.233-98.409-106.166-105.728-119.12-9.139-16.166-7.357-35.104 4.664-49.427 10.524-12.537 24.726-24.732 42.227-36.205l.645-.399 23.645-13.866c65.985-34.61 121.163-36.966 150.799-27.62 16.231 5.11 28.12 19.053 32.993 38.466 14.373.247 27.835 5.604 38.378 15.557 15.662 14.798 22.678 37.098 18.79 59.665-5.84 33.831-29.636 49.236-50.133 57.67-2.105 7.693-5.177 14.266-9.178 19.565-26.013 34.512-79.951 59.319-121.987 59.323Zm-67.645-182.736c-14.676 9.669-26.868 20.078-35.306 30.127-2.711 3.229-5.157 8.415-1.498 14.892 17.881 31.627 69.114 98.833 88.682 105.197 32.31 10.485 92.323-16.963 113.541-45.114 2.427-3.211 4.285-8.415 5.366-15.007l1.422-8.643-.398-16.697c-.342-14.607-1.498-30.867-3.3-47.013l-1.687-15.083-2.181-12.632a304.594 304.594 0 0 0-1.858-9.896c-.967-4.673-4.171-15.861-13.083-18.654-29.409-9.27-80.548.931-127.307 25.397l-22.374 13.126h-.019Zm189.083 1.349c7.413 1.044 14.315 3.495 20.136 7.142-4.057-3.78-10.599-7.693-20.136-7.142Z'
					fill='#000'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M279.63 344c-9.06-.001-17.552-1.16-25.115-3.609-37.657-12.233-98.409-106.166-105.728-119.12-9.139-16.166-7.357-35.104 4.664-49.427 10.524-12.537 24.726-24.713 42.227-36.205l.645-.399 23.645-13.866c65.985-34.61 121.182-36.966 150.799-27.62 16.231 5.11 28.12 19.053 32.993 38.466 14.373.247 27.854 5.604 38.378 15.557 15.662 14.798 22.678 37.098 18.791 59.665-5.841 33.831-29.637 49.236-50.134 57.67-2.105 7.693-5.177 14.266-9.178 19.565-26.013 34.512-79.951 59.319-121.987 59.323Zm-67.645-182.736c-14.676 9.669-26.868 20.078-35.306 30.127-2.711 3.229-5.157 8.415-1.498 14.892 17.881 31.627 69.114 98.833 88.682 105.197 32.31 10.485 92.323-16.963 113.541-45.114 2.427-3.211 4.285-8.415 5.366-15.007l1.422-8.605-.398-16.716c-.342-14.607-1.498-30.867-3.299-47.013l-1.688-15.121-2.181-12.594a286.41 286.41 0 0 0-1.858-9.896c-.967-4.673-4.19-15.862-13.083-18.673-29.409-9.27-80.529.931-127.307 25.397l-22.374 13.126h-.019Zm189.083 1.349c7.413 1.044 14.315 3.495 20.136 7.142-4.057-3.799-10.618-7.693-20.136-7.142Z'
					fill='#000'
				/>
				<path
					d='M399.077 236.41c-.038 10.447-1.195 27.999-9.557 39.111-29.143 38.656-96.418 61.432-130.321 50.414-28.025-9.098-83.43-87.816-97.196-112.149-7.016-12.423-3.489-24.333 3.072-32.159 9.803-11.682 23.303-23.022 38.927-33.261l23.019-13.506c46.304-24.295 102.733-38.086 139.215-26.593 14.467 4.559 21.028 18.748 23.379 30.069 5.556 26.879 9.594 67.415 9.462 98.074Z'
					fill='#fff'
				/>
				<path
					d='M382.485 258.559c9.31 1.272 12.268 3.438 12.856 5.318 1.858-5.698 2.807-11.758 3.281-17.286l-16.156 11.968h.019Zm14.752-64.015a582.25 582.25 0 0 0-2.958-27.79c-3.735.493-9.348 3.21-15.851 12.841 4.968 2.146 12.874 1.918 18.809 14.968v-.019Z'
					fill='#000'
				/>
				<path
					d='M385.671 150.418c38.283-13.43 66.365 19.47 60.316 54.421-5.309 30.773-28.404 44.184-63.502 53.72-4.854 1.31-8.324-24.087 8.742-29.975 15.339-5.3 24.801-10.999 33.865-21.978 1.061-28.094-29.978-32.976-44.844-27.239-10.05 3.894-10.467-22.054 5.404-28.949h.019Z'
					fill='#fff'
				/>
				<path
					d='M180.377 185.255c-11.036 12.024-4.324 22.529 6.029 24.732 49.698 13.772 150.345-25.872 178.957-69.447 6.314-11.131-5.138-21.921-24.479-21.75-53.433.494-121.807 24.314-160.489 66.465h-.018Z'
					fill='url(#coffee-cup-graphic-linear-gradient)'
				/>
				<path
					d='M334.779 169.983c-2.427-.304-16.08-1.767-33.05 1.519-4.266-3.419-7.964-6.933-14.676-6.382-8.04.646-11.168 11.074-16.288 7.484-8.76-6.154-5.385-14.531-11.794-23.535-5.366-7.541-18.373-5.661-21.843 5.736-1.972 6.421-6.371 10.239-13.614 2.356-13.027-14.152-8.514-36.87 7.641-61.526 14.297-21.788-4.911-37.991-19.853-16.564-6.124 8.776-10.22 8.624-15.112 9.06-6.143.551-11.642 6.212-14.96 19.889-4.665 19.223-15.15 67.13 14.353 103.81 39.023 5.451 99.832-14.323 139.196-41.866v.019Z'
					fill='#000'
				/>
				<path
					d='M279.165 137.235c.683-25.568 37.847-26.955 24.783 1.12-11.738 25.245-25.295 17.742-24.783-1.12Z'
					fill='#000'
				/>
				<path
					d='M228.273 73.866c-6.466-.418-13.614 8.89-16.478 13.962-2.844 5.071-11.282 2.26-17.956 6.325-6.674 4.065-16.079 38.01-13.406 62.875.436 4.141 3.679 2.697 3.679-3.666 0-11.663.588-19.717 4.854-32.102 4.608-13.392 7.357-15.33 12.344-16.128 6.617-1.063 9.689-7.446 13.007-13.163 2.788-4.806 5.423-9.67 10.277-11.246 7.3-2.393 7.812-6.572 3.679-6.838v-.02Zm22.128 74.88c-6.58.361-8.817 4.787-10.675 12.423-1.593 6.572 1.251 19.185-6.428 25.074-4.418 3.4.872 4.445 3.963 1.804 9.006-7.693 6.446-25.415 14.05-26.973 9.025-1.843 4.607-12.632-.929-12.328h.019Zm43.744-28.094c-6.371 1.329-13.349 7.598-12.667 17.817.304 4.787 4.741 3.496 5.385-2.222.569-4.977 2.484-7.009 6.997-9.612 5.006-2.887 6.769-7.351.304-6.002l-.019.019Zm-5.822 47.944c-4.19.456-7.944 2.868-11.073 7.56-3.811 5.737-9.955 6.364-11.984 7.2-2.028.835-2.977 4.198-.777 3.913 2.446-.323 6.504-.969 11.889-7.086 2.256-2.564 5.878-5.546 8.741-6.211 3.697-.874 6.883-5.756 3.204-5.357v-.019Z'
					fill='#666'
				/>
			</g>
			<defs>
				<linearGradient
					id='coffee-cup-graphic-linear-gradient'
					x1='190.64'
					y1='151.672'
					x2='197.717'
					y2='223.597'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#D1D3D4' />
					<stop offset='1' stopColor='#999' />
				</linearGradient>
				<filter
					id='coffee-cup-graphic-filter'
					x='0'
					y='0'
					width='602'
					height='412'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
					<feOffset />
					<feGaussianBlur stdDeviation='3' />
					<feColorMatrix values='0 0 0 0 0.544244 0 0 0 0 0.544244 0 0 0 0 0.544244 0 0 0 0.100034 0' />
					<feBlend in2='BackgroundImageFix' result='effect1_dropShadow_2_2' />
					<feBlend in='SourceGraphic' in2='effect1_dropShadow_2_2' result='shape' />
				</filter>
			</defs>
		</SvgIcon>
	);
};
