import { SvgIcon } from '../SvgIcon';
import * as React from 'react';
import { white } from '../../../../styles/colors';

export const PolicyIcon = ({
	width = 24,
	height = 20,
	fillColor = white,
	...props
}: React.SVGProps<SVGSVGElement> & { fillColor?: string }) => {
	return (
		<SvgIcon height={height} width={width} viewBox='0 0 24 20' {...props}>
			<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
				<g transform='translate(-155.000000, -539.000000)' fill={fillColor}>
					<g transform='translate(130.000000, 516.000000)'>
						<g transform='translate(25.000000, 23.000000)'>
							<path d='M2,2 L2,18 L22.002,18 L22,2 L2,2 Z M22,20 L2,20 C0.897,20 0,19.103 0,18 L0,2 C0,0.898 0.897,0 2,0 L22,0 C23.103,0 24,0.898 24,2 L24,18 C24,19.103 23.103,20 22,20 L22,20 Z' />
							<polygon points='7.4209 14.7832 3.3069 12.0402 4.6939 9.9602 6.5789 11.2172 9.9149 5.3802 12.0849 6.6202' />
							<polygon points='20 9 14 9 14 7 20 7' />
							<polygon points='20 13 14 13 14 11 20 11' />
						</g>
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
