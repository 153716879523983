import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import produce from 'immer';
import { observer } from 'mobx-react';
import * as React from 'react';
import { IPeopleSearchAddedTagsState, PersistentStoragePeopleSearchAddedTagsStateKey } from '../../../../../models';
import { useEventLogging } from '../../../../../models/Logging';
import { PersistentStorageManager } from '../../../../../models/Storage';
import { isTagSearchContactFilterCriteria } from '../../../../../models/UiUtils';
import { useContactSuggestedTagsQuery } from '../../../../../queries';
import { AutoCompleteSearchField } from '../../../../components/autocomplete/AutoCompleteSearchField';
import { ContactsGutterTag } from '../../../../components/contacts/ContactsGutterTag';
import { useTagsStorage } from '../../../../components/entities/tags/TagsEditor/hooks';
import { TagsList } from '../../../../components/entities/tags/TagsEditor/presentation';
import { HistoryIcon } from '../../../../components/svgs/icons/HistoryIcon';
import { SearchIcon } from '../../../../components/svgs/icons/SearchIcon';
import { green4, navigation } from '../../../../styles/colors';
import { AdvancedSearch } from '../AdvancedSearch';
import { PeopleCollapsibleSection } from '../PeopleCollapsibleSection';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	filter: Api.IBulkContactsRequest;
	onChangeFilter: (filter: Api.IBulkContactsRequest) => void;
}

interface IGutterTag {
	isActive: boolean;
	onRemoveButtonClicked(): void;
	showRemoveButton: boolean;
	tag: string;
	updateSelectedTagList(): void;
}

export const PopularTags: React.FC<IProps> = observer(({ className, styles, filter, onChangeFilter }) => {
	const { logEvent } = useEventLogging();
	const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
	const [userAddedTags, setUserAddedTags] = React.useState<string[]>([]);
	const [isOpen, setIsOpen] = React.useState(false);
	const { recentSearches, updateTagsRecentSearches } = useTagsStorage();
	const [inputValue, setInputValue] = React.useState('');

	const tagsQuery = useContactSuggestedTagsQuery({
		addKeepInTouchTag: false,
		pageSize: 25,
	});
	const suggestedTags = React.useMemo(() => {
		return (tagsQuery.data as Api.IAccountTag[]) || [];
	}, [tagsQuery.data]);

	React.useEffect(() => {
		loadStoredTags();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		updateSelectedTagList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	React.useEffect(() => {
		updateStoredTags();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userAddedTags]);

	const isTagSelected = (tagToCheck: string) => {
		return selectedTags.includes(tagToCheck);
	};

	const userAdded = () => {
		return Array.from(
			new Set([...userAddedTags, ...selectedTags].filter(x => !suggestedTags.map(y => y.tag).includes(x)))
		);
	};

	const toggleTagOnSelectedTagList = (tag: string) => () => {
		updateTagsRecentSearches(tag);
		const nextFilter = produce(filter, draftFilter => {
			const alreadySelected = isTagSelected(tag);

			const criteriaWithNoTags = draftFilter.filter.criteria.filter(x => !isTagSearchContactFilterCriteria(x));

			const tagCriteria = draftFilter.filter.criteria.find(x => x.op === Api.FilterOperator.Or);

			if (alreadySelected) {
				// remove tag from the list
				draftFilter.filter.criteria = [
					...criteriaWithNoTags,
					{
						...tagCriteria,
						criteria: tagCriteria?.criteria?.filter(x => x.value !== tag),
					},
				];
			} else {
				// add tag to the list, or create an empty list if not present
				const tagCriterionSafe = {
					criteria: [...(tagCriteria?.criteria || []), { property: Api.ContactFilterCriteriaProperty.Tag, value: tag }],
					op: Api.FilterOperator.Or,
				} as Api.IContactFilterCriteria;

				draftFilter.filter.criteria = [...criteriaWithNoTags, tagCriterionSafe];
			}

			const cleanCriteria = draftFilter.filter.criteria!.filter(
				x => !(x.op === Api.FilterOperator.Or && (!x.criteria || x.criteria?.length === 0))
			);

			draftFilter.filter.criteria = cleanCriteria;
		});

		onChangeFilter(nextFilter);
		setInputValue('');
	};

	const onAutocompleteTagSelected = (tag: string) => {
		if ((suggestedTags.includes(tag) || userAddedTags.includes(tag)) && !selectedTags.includes(tag)) {
			toggleTagOnSelectedTagList(tag)();
		} else if (!userAddedTags.includes(tag) && !suggestedTags.includes(tag)) {
			setUserAddedTags([...userAddedTags, tag]);
			toggleTagOnSelectedTagList(tag)();
		}
		logEvent('AddTagToGutter', { tag: tag.length });
	};
	const updateSelectedTagList = () => {
		const firstLevelCriteriaOr = filter?.filter?.criteria?.find(x => x.op === Api.FilterOperator.Or);

		const tagCriteria = firstLevelCriteriaOr?.criteria
			? firstLevelCriteriaOr.criteria.filter(y => y.property === Api.ContactFilterCriteriaProperty.Tag)
			: filter?.filter?.criteria?.filter(y => y.property === Api.ContactFilterCriteriaProperty.Tag) || [];

		const selected = tagCriteria.map(x => x.value);
		const newUserAddedTags: string[] = [];
		tagCriteria.forEach(tagCriterion => {
			if (!suggestedTags.includes(tagCriterion.value)) {
				newUserAddedTags.push(tagCriterion.value);
			}
		});
		const updatedUserAddedTags = Array.from(new Set([...userAddedTags, ...newUserAddedTags]));
		setSelectedTags(selected);
		setUserAddedTags(updatedUserAddedTags);
	};

	const removeTagFromUserAddedTagList = (tag: string) => () => {
		setUserAddedTags(userAddedTags.filter(x => x !== tag));

		if (selectedTags.includes(tag)) {
			toggleTagOnSelectedTagList(tag)();
		}
	};

	const updateStoredTags = () => {
		PersistentStorageManager.local.setObject<IPeopleSearchAddedTagsState>(
			PersistentStoragePeopleSearchAddedTagsStateKey,
			{
				tags: Array.from(new Set([...userAddedTags])),
			}
		);
	};

	const loadStoredTags = () => {
		PersistentStorageManager.local
			.getObject<IPeopleSearchAddedTagsState>(PersistentStoragePeopleSearchAddedTagsStateKey)
			.then(storedPeopleTagsState => {
				const userStoredTags = storedPeopleTagsState?.tags ?? [];
				if (userStoredTags.length === 0) {
					PersistentStorageManager.local.remove(PersistentStoragePeopleSearchAddedTagsStateKey);
				}
				const userAddedTagsLocal = Array.from(new Set([...userAddedTags, ...userStoredTags]));
				setUserAddedTags(userAddedTagsLocal);
			});
	};
	const createGutterTag = (tag: string, isUserAdded: boolean) => {
		const isActive: boolean = isTagSelected(tag);
		return {
			isActive,
			onRemoveButtonClicked: removeTagFromUserAddedTagList(tag),
			showRemoveButton: isUserAdded,
			tag,
			updateSelectedTagList: toggleTagOnSelectedTagList(tag),
		};
	};

	const renderTags: IGutterTag[] = [
		...(suggestedTags?.map((tag: Api.IAccountTag) => {
			return createGutterTag(tag.tag, false);
		}) || []),
		...(userAdded().map(tag => createGutterTag(tag, true)) || []),
	];
	const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
	};

	const emptyResults =
		recentSearches?.length > 0 ? (
			<>
				{!inputValue ? (
					<TagsList
						title='Recent Searches'
						icon={<HistoryIcon fillColor={green4} className={css(styleSheet.sectionIcon)} />}
						altKey='searches'
						list={recentSearches.slice(0, 6)}
						addTag={tag => onAutocompleteTagSelected(tag?.tag)}
					/>
				) : null}
				{inputValue ? (
					<p className={css(styleSheet.noMargin)} key={`${inputValue}-pending`} title={inputValue}>
						<span className={css(styleSheet.createTag)}>{`No Results found for "${inputValue}"`}</span>
					</p>
				) : null}
			</>
		) : undefined;

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
			<PeopleCollapsibleSection
				showLoader={tagsQuery.isLoading}
				items={renderTags.map(tag => {
					return (
						<ContactsGutterTag
							className={css(styleSheet.tag)}
							key={tag.tag}
							isActive={tag.isActive}
							onRemoveButtonClicked={removeTagFromUserAddedTagList(tag.tag)}
							showRemoveButton={tag.showRemoveButton}
							tag={tag.tag}
							updateSelectedTagList={toggleTagOnSelectedTagList(tag.tag)}
						/>
					);
				})}
				itemHeightPx={34}
			/>
			<div>
				<AutoCompleteSearchField
					sectionTitleElement={
						<>
							<div className={css(styleSheet.sectionWrap)}>
								<p className={css(styleSheet.sectionTitle)}>Top Results</p>
							</div>
						</>
					}
					emptyResults={emptyResults}
					anchorClassName={css(styleSheet.searchBox)}
					clearSearchFieldAfterSelectingItem={true}
					dropdownContentClassName={css(styleSheet.autocompleteBox)}
					inputId='contactsGutterTagSearchInput'
					inputProps={{
						placeholder: 'Search tags',
						onChange: handleValueChange,
						onFocus: () => setIsOpen(true),
						onBlur: () => setIsOpen(false),
					}}
					leftAccessory={
						<span className={css(styleSheet.searchIcon)}>
							<SearchIcon fillColor={navigation} />
						</span>
					}
					onItemSelected={onAutocompleteTagSelected}
					resultsLimit={25}
					pageSize={25}
					type={Api.ResourceAutoCompleteViewModelType.Tag}
					openOnFocus={Boolean(emptyResults)}
					isOpen={Boolean(emptyResults) || isOpen}
				/>
			</div>
			<div className={css(styleSheet.advancedTagSearch)}>
				<AdvancedSearch filter={filter} onChangeFilter={onChangeFilter} />
			</div>
		</div>
	);
});
