import { CSSProperties, StyleSheet } from 'aphrodite';
import { brandPrimaryText, header, nameCircles, navigation, titles } from '../../../styles/colors';
import { BrowserMediaQueries, CssSelectors, truncateTextStyle } from '../../../styles/styles';

const lightGray = '#f9f9f9';
const updateRecipientsHeader: CSSProperties = {
	color: '#000',
	fontSize: '18px',
	marginTop: 8,
	textTransform: 'none',
};

export const styleSheet = StyleSheet.create({
	alert: {
		position: 'absolute',
		top: '100%',
	},
	addPhoneNotificationContent: {
		alignItems: 'center',
		color: titles,
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginRight: 10,
			},
			'svg'
		),
	},
	anchor: {
		marginLeft: 10,
		marginRight: 10,
	},
	backChevron: {
		marginRight: 5,
		transform: 'rotateZ(90deg)',
	},
	button: {
		alignItems: 'center',
		color: brandPrimaryText,
		display: 'flex',
	},
	column: {
		padding: '20px 15px',
		width: 320,
	},
	contactColHeaderLinks: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingRight: 5,
	},
	contactInfoContainer: {
		position: 'relative',
		...CssSelectors.allChildren(
			{
				'::-webkit-scrollbar': {
					background: '#f9f9f9',
					width: 8,
				},
				'::-webkit-scrollbar-thumb': {
					backgroundColor: '#D8D8D8',
					borderRadius: 10,
					padding: '0 2px',
				},
				boxSizing: 'border-box',
				maxHeight: '100%',
				maxWidth: '100%',
				minHeight: '100%',
				overflowX: 'hidden',
				overflowY: 'auto',
				padding: '40px 8px 16px 4px',
				...CssSelectors.allChildren(
					{
						maxHeight: '100%',
						maxWidth: '100%',
						minHeight: 200,
						overflow: 'unset',
					},
					'.editable-contact-inner'
				),
			},
			'.editable-contact'
		),
	},
	container: {
		alignItems: 'stretch',
		display: 'flex',
		height: '100%',
	},
	conversationContainer: {
		background: lightGray,
		borderLeft: `1px solid ${nameCircles}`,
		borderRight: `1px solid ${nameCircles}`,
		display: 'flex',
		flex: '1 0 33%',
		flexDirection: 'column',
		minWidth: 310,
		...CssSelectors.allChildren(
			{
				display: 'flex',
				flexDirection: 'column-reverse',
				flexGrow: 1,
				padding: '10px 20px',
				[BrowserMediaQueries.IE11]: {
					'-ms-overflow-style': 'none',
					flexDirection: 'column',
					transform: 'rotate(180deg)',
				},
			},
			'.conversation'
		),
		...CssSelectors.allChildren(
			{
				padding: '8px 20px 20px',
			},
			'.text-message-input'
		),
	},
	conversationHeader: {
		alignItems: 'center',
		background: '#fff',
		borderBottom: '1px solid rgb(211, 219, 222)',
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		minHeight: 80,
		minWidth: '100%',
		padding: '17px 20px',
		position: 'relative',
	},
	conversationHeaderContentContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		marginBottom: 10,
		width: '100%',
		...CssSelectors.allChildren({
			margin: 0,
			textAlign: 'center',
		}),
	},
	conversationHeaderLeft: {
		justifyContent: 'flex-start',
		paddingTop: 30,
		...CssSelectors.allDescendants(
			{
				marginRight: 10,
				marginTop: 3,
			},
			'svg.svg-icon'
		),
	},
	conversationHeaderNames: {
		...truncateTextStyle,
		fontSize: '18px',
		maxWidth: '95%',
	},
	conversationHeaderNumbers: {
		...truncateTextStyle,
		color: header,
		maxWidth: '95%',
	},
	conversationItem: {
		':not(:first-child)': {
			marginTop: 2,
		},
		'@media (max-width: 1200px)': {
			justifyContent: 'center',
		},
	},
	conversationsCol: {
		'@media (max-width: 1200px)': {
			maxWidth: '160px',
			minWidth: '160px',
		},
		padding: 0,
	},
	conversationsContainer: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		maxHeight: 'calc(100% - 125px)',
		minHeight: 200,
		overflowX: 'hidden',
		overflowY: 'auto',
		padding: '0 10px 0 5px',
	},
	conversationsHeader: {
		'@media (max-width: 1200px)': {
			flexDirection: 'column',
		},
		borderBottom: '1px solid rgb(211, 219, 222)',
		display: 'flex',
		justifyContent: 'space-between',
		padding: '17px 20px',
		...CssSelectors.allDescendants(
			{
				minWidth: 40,
			},
			'.new-conversation-cta'
		),
	},
	conversationsLoading: {
		marginTop: 75,
	},
	conversationsSearchContainer: {
		padding: '20px 0',
	},
	forwardChevron: {
		marginLeft: 5,
		transform: 'rotateZ(-90deg)',
	},
	inputContainer: {
		background: lightGray,
		...CssSelectors.allChildren(
			{
				fontSize: '16px !important',
			},
			'input'
		),
	},
	noConversations: {
		color: navigation,
		marginTop: 40,
		textAlign: 'center',
	},
	noPhoneContactSearchAnchor: {
		width: '100%',
		...CssSelectors.allDescendants(
			{
				width: '100%',
			},
			'.autocomplete-search-field-input'
		),
	},
	quickAddEntity: {
		borderTop: `1px solid ${nameCircles}`,
		marginTop: 25,
		...CssSelectors.allDescendants(
			{
				...updateRecipientsHeader,
			},
			'.quick-add-entity-title'
		),
	},
	quickAddEntityFields: {
		marginRight: 0,
	},
	recipientSearch: {
		maxWidth: 800,
	},
	searchIcon: {
		marginRight: 8,
	},
	textContainer: {
		color: header,
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginRight: 2,
				marginTop: 2,
			},
			'svg'
		),
	},
	textContainerLeftAlign: {
		justifyContent: 'flex-start',
	},
	unreadOnlyCheckbox: {
		marginBottom: 10,
		marginLeft: 20,
		marginTop: 20,
	},
	updateRecipientsHeader: {
		...updateRecipientsHeader,
	},
	yourNumber: {
		'@media (max-width: 1200px)': {
			marginBottom: '24px',
		},
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		...CssSelectors.allChildren(
			{
				color: '#aaa',
				fontSize: '12px',
				textTransform: 'uppercase',
			},
			':first-child'
		),
		...CssSelectors.allChildren(
			{
				color: header,
				fontSize: '16px',
			},
			':last-child'
		),
	},
});
