import { brandPrimary } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { CSSProperties, StyleSheet } from 'aphrodite';

const tagItemBase = {
	alignItems: 'center',
	cursor: 'pointer',
	display: 'flex',
	margin: '0 auto',
	padding: '6px 20px',
};

export const styleSheet = StyleSheet.create({
	container: {},
	disableTouch: {
		pointerEvents: 'none',
	},
	tag: {
		display: 'flex',
		margin: '0 0 0 6px',
		maxWidth: '85%',
		transition: 'background 0.3s, color 0.3s',
		...CssSelectors.allChildren(
			{
				...CssSelectors.allChildren(
					{
						marginLeft: 6,
					},
					':not(:first-child)'
				),
			},
			'div'
		),
	},
	tagItem: {
		...(tagItemBase as CSSProperties),
		':hover': {
			background: 'rgba(232, 243, 249, 0.5)',
		},
	},
	tagItemSelected: {
		...(tagItemBase as CSSProperties),
		background: '#E8F3F9',
	},
	tagSelected: {
		background: brandPrimary,
		color: '#fff',
		...CssSelectors.allChildren({
			color: '#fff',
		}),
	},
});
