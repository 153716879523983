import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { AppBar as RetroAppBar } from '../AppBar';
import { AppBarContextProvider } from '../AppBar/context';
import { CustomerImpersonationWarning } from '../CustomerImpersonationWarning';
import { AppBar } from '../Happiness/AppBar';
import { NavBar } from '../Happiness/NavBar';
import { MainContainerBackground } from '../MainContainerBackground';
import { NavBar as RetroNavBar } from '../NavBar';
import { INavBarLink } from '../NavBarLink';
import { CompactLogoIcon2023 } from '../svgs/icons/CompactLogoIcon2023';
import { LogoIcon2023 } from '../svgs/icons/LogoIcon2023';
import { useCoffeeNavLinks } from './hooks/useCoffeeNavLinks';
import { useIsCoffeeNavCompact, useIsMainNavCompact } from './hooks/useIsCompactNav';
import { styleSheet } from './styles';

function TopLevelAppShell({ children }: { children: React.ReactNode }) {
	const showingCompactNav = useIsMainNavCompact();
	return (
		<AppBarContextProvider>
			<MainContainerBackground className={`main-container ${showingCompactNav ? `main-container-compact-nav` : ``}`}>
				{children}
			</MainContainerBackground>
		</AppBarContextProvider>
	);
}

export function LevitateAppShell({ children }: { children: React.ReactNode }) {
	const userSession = useUserSession();
	const showingCompactNav = useIsMainNavCompact();
	return (
		<TopLevelAppShell>
			<NavBar compactLayout={showingCompactNav} />
			<div className={`${css(styleSheet.rightContainer, showingCompactNav && styleSheet.rightContainerCompact)}`}>
				<CustomerImpersonationWarning userSession={userSession} />
				<RetroAppBar className='main-container-header' />
				<div className='main-container-right-container-content'>{children}</div>
			</div>
		</TopLevelAppShell>
	);
}

export function HappinessAppShell({ children }: { children: React.ReactNode }) {
	const showingCompactNav = useIsMainNavCompact();
	const userSession = useUserSession();
	return (
		<TopLevelAppShell>
			<NavBar compactLayout={showingCompactNav} />
			<div
				className={`${css(
					styleSheet.rightContainer,
					styleSheet.rightContainerTopGradient,
					showingCompactNav && styleSheet.rightContainerCompact
				)}`}
			>
				<CustomerImpersonationWarning userSession={userSession} />
				<AppBar className='main-container-header' />
				<div className='main-container-right-container-content'>{children}</div>
			</div>
		</TopLevelAppShell>
	);
}

const CoffeeAppBarUserLinks: INavBarLink[] = [
	{
		linkName: 'Schedule a Meeting',
		path: '/schedule-meeting',
	},
	{
		linkName: 'Settings',
		path: '/settings',
	},
	{
		linkName: 'Logout',
		path: '/logout',
	},
];

const CoffeeAppBarAdminLinks: INavBarLink[] = [
	{
		linkName: 'Invite Teammates',
		path: '/settings/manage-users',
	},
	...CoffeeAppBarUserLinks,
];

export function CoffeeAppShell({
	children,
	className,
	styles = [],
}: {
	className?: string;
	children: React.ReactNode;
	styles?: StyleDeclarationValue[];
}) {
	const userSession = useUserSession();
	const showingCompactNav = useIsCoffeeNavCompact();
	const links = useCoffeeNavLinks();
	const onRenderNavBarLogo = React.useCallback(compact => {
		const coffee = (
			<div className={css(styleSheet.coffeeLogoContainer)}>
				<NavLink to='/dashboard'>
					<LogoIcon2023 className={css(styleSheet.mainLogo)} />
				</NavLink>
			</div>
		);
		const compactLogo = (
			<NavLink to='/dashboard'>
				<CompactLogoIcon2023 />
			</NavLink>
		);
		return compact ? compactLogo : coffee;
	}, []);

	return (
		<MainContainerBackground
			className={`coffee-main-container main-container ${className} ${css(styleSheet.coffeeContainer, ...styles)}`}
		>
			<RetroNavBar
				className={`coffee-main-container-nav ${css(
					styleSheet.coffeeNavbar,
					showingCompactNav ? styleSheet.coffeeNavBarCompact : null
				)}`}
				compactLayout={showingCompactNav}
				links={links}
				onRenderLogo={onRenderNavBarLogo}
			/>
			<div
				className={`coffee-main-container-right-container ${css(
					styleSheet.coffeeRightContainer,
					showingCompactNav ? styleSheet.coffeeRightContainerExpanded : null
				)}`}
			>
				<CustomerImpersonationWarning userSession={userSession} />
				<RetroAppBar
					className={`coffee-main-container-header ${css(
						styleSheet.coffeeAppBar,
						showingCompactNav ? styleSheet.coffeeAppBarExpanded : null
					)}`}
					style={{ background: 'transparent' }}
					coffeeLinks={userSession.account.isAdmin ? CoffeeAppBarAdminLinks : CoffeeAppBarUserLinks}
				/>
				<div className={`coffee-main-container-right-container-content ${css(styleSheet.coffeeContentContainer)}`}>
					{children}
				</div>
			</div>
		</MainContainerBackground>
	);
}

export { MainContainerPageShell } from './MainContainerPageShell';
