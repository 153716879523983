import { StyleSheet } from 'aphrodite';
import { background, brandPrimaryText, inputBorderColor } from '../../../../styles/colors';
import { CssSelectors } from '../../../../styles/styles';

export const styleSheet = StyleSheet.create({
	callForwardingInput: {
		backgroundColor: background,
		borderColor: inputBorderColor,
		borderStyle: 'solid',
		borderWidth: '1px',
		height: '40px',
		width: '270px',
	},
	contactSupport: {
		color: brandPrimaryText,
		paddingLeft: 40,
		textDecoration: 'none',
	},
	container: {},
	enableForwardingContainer: {
		alignItems: 'center',
		display: 'flex',
	},
	fieldErrorContainer: {
		marginLeft: 20,
	},
	marginBottom: {
		marginBottom: 10,
	},
	sections: {
		fontSize: 14,
		...CssSelectors.allChildren(
			{
				marginTop: 28,
			},
			':not(:first-child)'
		),
	},
});
