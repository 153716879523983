import * as React from 'react';
import { brandPrimaryHover, mention } from '../../../../styles/colors';
import { SvgIcon } from '../../icons/SvgIcon';

export const NoConversationTextingGraphic = ({
	className,
	width = 205,
	height = 205,
	...props
}: React.SVGProps<SVGSVGElement>) => {
	return (
		<SvgIcon className={className} height={height} width={width} viewBox='0 0 205 205' {...props}>
			<g id='Group 2'>
				<circle id='Oval' cx='102.5' cy='102.5' r='102.5' fill={mention} />
				<g id='Group 38'>
					<path
						id='Path'
						d='M49.1065 139.525C49.0583 139.7 49.0243 139.878 49.0048 140.059L34.322 147.622L30.9127 145.516L27 150.147L32.666 154.451C33.6063 155.165 34.9142 155.184 35.8763 154.499L51.1414 143.622C52.489 144.172 54.0429 143.846 55.044 142.805C56.0451 141.763 56.2846 140.222 55.6454 138.935C55.0063 137.648 53.6219 136.884 52.1685 137.015C50.715 137.145 49.4959 138.145 49.1065 139.525V139.525Z'
						fill='#9E616A'
					/>
					<path id='Path_2' d='M66.8394 192L70 190.127L63.6644 178L59 180.765L66.8394 192V192Z' fill='#9E616A' />
					<path
						id='Path_3'
						d='M64 190.297L70.3093 186.678L70.3095 186.678C72.5302 185.404 75.4879 185.977 76.916 187.958L77 188.075L66.6699 194L64 190.297Z'
						fill='#2F2E41'
					/>
					<path
						id='Path_4'
						d='M24.8768 170.154L47.3885 156.008C47.3885 156.008 51.5247 155.616 54.0071 160.418L69 181.555L62.6034 185.804L45.9665 168.848L34.5503 179.93C34.5503 179.93 51.3702 177.223 50.8014 188.109C50.35 196.749 27.3294 193.785 17.8459 192.194C14.1992 191.61 11.006 189.464 9.13169 186.339C8.11458 184.607 7.56784 182.641 8.42214 180.783C10.7002 175.827 24.8768 170.154 24.8768 170.154Z'
						fill='#2F2E41'
					/>
					<path
						id='Path_5'
						d='M12.4711 182L6 180.692L7.84405 148.978L15.2899 139.871L19.7261 139.087C22.468 138.59 25.1336 140.274 25.8581 142.961L27.8134 150.094C31.1311 155.703 26.4834 162.207 26.0548 162.785L26.2963 172.622L12.4711 182Z'
						fill={brandPrimaryHover}
					/>
					<path
						id='Path_6'
						d='M19 141.135L19.422 139.641C19.422 139.641 24.0018 136.263 31 145.018L27.6467 151L20.3569 150.708L19 141.135Z'
						fill={brandPrimaryHover}
					/>
					<path
						id='Path_7'
						d='M28.804 130.268C30.0571 135.53 25.1175 135.921 19.258 137.385C13.3985 138.849 8.83797 140.832 7.58485 135.57C6.33174 130.308 6.43078 120.4 14.72 118.329C23.2949 116.187 27.5509 125.006 28.804 130.268Z'
						fill='#2F2E41'
					/>
					<circle id='Oval_2' cx='18' cy='130' r='7' fill='#9E616A' />
					<path
						id='Path_8'
						d='M9.2244 132V132L12.0834 131.241L12.4832 127.061L13.5969 130.839L14.9634 130.476L15.1966 128.038L15.8463 130.241L26 127.545V127.545C24.9691 122.944 20.7075 120.124 16.4814 121.246L15.0098 121.637C12.9804 122.176 11.2307 123.571 10.1457 125.514C9.06076 127.457 8.72935 129.791 9.2244 132V132Z'
						fill='#2F2E41'
					/>
					<path
						id='Path_9'
						d='M30.5863 167.001C30.4056 167.049 30.229 167.11 30.0579 167.185L15.9559 158.146L16.132 154.07L10.0371 153L9.02662 160.184C8.85894 161.376 9.49567 162.536 10.5894 163.031L27.9433 170.887C28.1262 172.353 29.1942 173.553 30.6258 173.9C32.0573 174.247 33.5535 173.67 34.3837 172.449C35.2138 171.229 35.2046 169.62 34.3606 168.409C33.5165 167.198 32.0137 166.638 30.5863 167.001L30.5863 167.001Z'
						fill='#9E616A'
					/>
					<path
						id='Path_10'
						d='M16.9041 140.503L15.8161 139.145C15.8161 139.145 11.4475 137.752 9.08822 143.592C6.72892 149.431 8.94077 155.124 8.94077 155.124L19.2627 156L20 146.511L16.9041 140.503Z'
						fill={brandPrimaryHover}
					/>
					<path id='Path_11' d='M127 57H205V83.1586H202.046V90L195.349 83.1586H127V57V57Z' fill='#CAE0EC' />
					<rect id='Rectangle' x='129' y='59' width='74' height='22' fill='white' />
					<rect id='Rectangle_2' x='135' y='67' width='36' height='1' fill={brandPrimaryHover} />
					<rect id='Rectangle_3' x='135' y='71' width='62' height='1' fill='#E6E6E6' />
					<rect id='Rectangle_4' x='135' y='74' width='62' height='1' fill='#E6E6E6' />
					<path id='Path_12' d='M158 19H80V45.1584H82.9546V52L89.6514 45.1584H158V19V19Z' fill='#CAE0EC' />
					<rect id='Rectangle_5' x='82' y='21' width='74' height='22' fill='white' />
					<rect id='Rectangle_6' x='89' y='28' width='36' height='1' fill={brandPrimaryHover} />
					<rect id='Rectangle_7' x='89' y='32' width='62' height='1' fill='#E6E6E6' />
					<rect id='Rectangle_8' x='89' y='35' width='62' height='1' fill='#E6E6E6' />
					<path
						id='Path_13'
						d='M62 134.703H61.8567V131.091C61.8568 130.536 61.6175 130.004 61.1915 129.612C60.7656 129.22 60.1879 129 59.5855 129H51.2713C50.6689 129 50.0912 129.22 49.6652 129.612C49.2393 130.004 49 130.536 49 131.091V150.909C49 151.464 49.2393 151.996 49.6652 152.388C50.0912 152.78 50.6689 153 51.2713 153H59.5855C60.1878 153 60.7655 152.78 61.1915 152.388C61.6174 151.996 61.8567 151.464 61.8567 150.909V137.275H62V134.703Z'
						fill='#3F3D56'
					/>
					<path
						id='Path_14'
						d='M59.4116 130H58.3953C58.4902 130.22 58.4631 130.469 58.3229 130.666C58.1827 130.863 57.9479 130.981 57.6966 130.981H53.2363C52.985 130.981 52.7502 130.863 52.61 130.666C52.4698 130.469 52.4427 130.22 52.5376 130H51.5884C50.7111 130 50 130.671 50 131.499V150.501C50 150.898 50.1673 151.28 50.4652 151.561C50.7631 151.842 51.1671 152 51.5884 152H59.4116C59.8329 152 60.2369 151.842 60.5348 151.561C60.8327 151.28 61 150.898 61 150.501V150.501V131.499C61 131.102 60.8327 130.72 60.5348 130.439C60.2369 130.158 59.8329 130 59.4116 130Z'
						fill='white'
					/>
					<path id='Path_15' d='M133 101H55V127.158H57.9546V134L64.6514 127.158H133V101V101Z' fill='#CAE0EC' />
					<rect id='Rectangle_9' x='57' y='103' width='74' height='22' fill='white' />
					<rect id='Rectangle_10' x='64' y='110' width='36' height='1' fill={brandPrimaryHover} />
					<rect id='Rectangle_11' x='64' y='114' width='62' height='1' fill='#E6E6E6' />
					<rect id='Rectangle_12' x='64' y='117' width='62' height='1' fill='#E6E6E6' />
				</g>
			</g>
		</SvgIcon>
	);
};
