import { ISatisfactionSurveyResponse } from '../../../../extViewmodels';
import { truncateTextToLength } from '../../../../models/UiUtils';
import { useFullscreenModal, useUserSession } from '../../../../models/hooks/appStateHooks';
import { surveysTint } from '../../../styles/colors';
import { RightArrow } from '../../svgs/icons/RightArrow';
import { StarIcon } from '../../svgs/icons/StarIcon';
import { styleSheet } from './styles';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';

interface IProps {
	date: string;
	rating: number;
}

const maxFeedbackLength = 400;

export function SatisfactionClientFeedback({ review }: { review: ISatisfactionSurveyResponse }) {
	const fullscreenModal = useFullscreenModal();
	const userSession = useUserSession();
	let feedback = review.feedback;
	// @ts-ignore
	if (feedback.length > maxFeedbackLength) {
		// @ts-ignore
		feedback = `${truncateTextToLength(review.feedback, maxFeedbackLength)}...`;
	}

	return (
		<>
			<p className={css(styleSheet.feedback)}>{feedback}</p>
			<div className={css(styleSheet.userRatingWrapper)}>
				<div className={css(styleSheet.userRatingLeft)}>
					<span className={css(styleSheet.userRaterName)}>{review.contact?.handle ?? 'Anonymous'}</span>
					<span className={css(styleSheet.userRaterNumber)}>{review.rating}</span>
					<StarIcon fillColor={surveysTint} filled={true} width={10} height={10} />
				</div>
				{review?.contact ? (
					<button
						className={css(styleSheet.viewContactLink)}
						onClick={() =>
							// @ts-ignore
							fullscreenModal.history.push({
								// @ts-ignore
								pathname: `/people/${review.contact.id}`,
								state: {
									// @ts-ignore
									viewModel: new Api.ContactViewModel(userSession, review.contact),
								},
							})
						}
					>
						View Contact <RightArrow styles={{ marginLeft: 4 }} />
					</button>
				) : null}
			</div>
		</>
	);
}

export function RatingBar({ date, rating }: IProps) {
	const ratingBarWidth = 20 * rating;
	return (
		<div className={css(styleSheet.ratingBarContainer)}>
			<div className={css(styleSheet.ratingBarWrapper)}>
				<time
					dateTime={date}
					className={css(styleSheet.ratingBar)}
					style={{ backgroundColor: `rgb(243, 206, 13, ${ratingBarWidth / 100})`, width: `${ratingBarWidth}%` }}
				>
					{date}
				</time>
			</div>
			<div className={css(styleSheet.ratingBarLabelContainer)}>
				<span className={css(styleSheet.ratingBarLabel)}>{rating}</span>
				<StarIcon fillColor={surveysTint} filled={true} width={12} height={12} />
			</div>
		</div>
	);
}

export function SatisfactionBgGraphic() {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' fill='none'>
			<path
				fill='#F3CE0D'
				fillRule='evenodd'
				d='M66.413 92.275A4.306 4.306 0 0 0 68.8 93c.89 0 1.78-.276 2.533-.827a4.31 4.31 0 0 0 1.6-4.668l-7.904-27.728L84.74 40.02a4.31 4.31 0 0 0 .933-4.693 4.304 4.304 0 0 0-3.973-2.664H58.557L46.848 9.188c-1.457-2.917-6.239-2.917-7.696 0l-11.71 23.475H4.302a4.304 4.304 0 0 0-3.973 2.664 4.31 4.31 0 0 0 .933 4.693l19.71 19.756-7.903 27.728a4.31 4.31 0 0 0 1.6 4.668 4.288 4.288 0 0 0 4.919.103L43 76.631l23.413 15.644Z'
				clipRule='evenodd'
				opacity='.509'
			/>
			<path
				fill='#F3CE0D'
				fillRule='evenodd'
				d='M92.107 73.486a3.055 3.055 0 0 0 1.693.513 3.057 3.057 0 0 0 2.93-3.897l-5.605-19.668 13.981-14.013a3.059 3.059 0 0 0-2.157-5.218H86.535l-8.305-16.65c-1.034-2.07-4.426-2.07-5.46 0l-8.305 16.65H48.05a3.057 3.057 0 0 0-2.156 5.218l13.981 14.013-5.606 19.668a3.057 3.057 0 0 0 1.135 3.31 3.042 3.042 0 0 0 3.49.074L75.5 62.389l16.607 11.097Z'
				clipRule='evenodd'
				opacity='.248'
			/>
			<path
				fill='#F3CE0D'
				fillRule='evenodd'
				d='M124.167 74.247a1.504 1.504 0 0 0 1.716-.036 1.503 1.503 0 0 0 .558-1.628l-2.757-9.673 6.876-6.891a1.504 1.504 0 0 0-1.06-2.567h-8.073l-4.085-8.189c-.508-1.017-2.176-1.017-2.684 0l-4.085 8.19H102.5a1.502 1.502 0 0 0-1.06 2.566l6.876 6.891-2.757 9.673a1.503 1.503 0 0 0 .558 1.628 1.496 1.496 0 0 0 1.716.036L116 68.79l8.167 5.457Z'
				clipRule='evenodd'
				opacity='.248'
			/>
			<path
				fill='#F3CE0D'
				fillRule='evenodd'
				d='m137.849 30.766 1.304.871-.075-.264-1.229-.607Zm3.496 6.914a1.903 1.903 0 0 0 2.174-.046c.644-.47.927-1.295.707-2.062l-3.492-12.253 8.709-8.729a1.904 1.904 0 0 0-1.343-3.25h-10.226L132.7.967c-.644-1.29-2.756-1.29-3.4 0l-5.174 10.373H113.9a1.904 1.904 0 0 0-1.343 3.25l8.709 8.73-3.492 12.252c-.22.767.063 1.592.707 2.062a1.895 1.895 0 0 0 2.174.046L131 30.767l10.345 6.913Z'
				clipRule='evenodd'
				opacity='.541'
			/>
			<path
				fill='#F3CE0D'
				fillRule='evenodd'
				d='m179.062 38.498.229.153-.013-.046-.216-.107Zm1.383 3.333a1.001 1.001 0 0 0 1.516-1.11l-1.838-6.448 4.584-4.594a1.002 1.002 0 0 0-.707-1.71h-5.382l-2.723-5.46c-.339-.679-1.451-.679-1.79 0l-2.723 5.46H166a1 1 0 0 0-.707 1.71l4.584 4.594-1.838 6.449c-.116.404.033.838.372 1.085a.997.997 0 0 0 1.144.024L175 38.193l5.445 3.638Z'
				clipRule='evenodd'
				opacity='.248'
			/>
		</svg>
	);
}
