import { DefaultContactsSortDescriptor, IContactsSelfImportEvent, IContactsSelfImportState } from '@AppModels/.';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '@AppModels/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { getLastPathComponent, searchRequestToQueryStringParams } from '@AppModels/UiUtils';
import { IContactsFilterRequest, ISortDecriptor } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import { stringify as toQueryString } from 'query-string';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppImportContactsViewModel } from '../../../../viewmodels/AppViewModels';
import { PrivateRoute } from '../../PrivateRoute';
import { ContactsSelfImportMapping } from '../ContactsSelfImportMapping';
import { ContactsSelfImportProcessing } from '../ContactsSelfImportProcessing';
import { ContactsSelfImportUpload } from '../ContactsSelfImportUpload';
import { styleSheet } from './styles';

interface IProps
	extends IEventLoggingComponentProps,
		RouteComponentProps<any, any, IContactsSelfImportState>,
		IUserSessionComponentProps {
	className?: string;
	onCancel?(): void;
	styles?: StyleDeclarationValue[];
}

class _ContactsSelfImport extends React.Component<IProps> {
	private mImportContacts: AppImportContactsViewModel;

	constructor(props: IProps) {
		super(props);
		this.mImportContacts = new AppImportContactsViewModel(props.userSession);
	}

	public componentDidMount() {
		const { logPageView } = this.props;
		logPageView('ContactsSelfImport');
	}

	public render() {
		const { className, styles, match, userSession, location } = this.props;
		const lastPathComp = getLastPathComponent(location ? location.pathname : null);
		return (
			<div
				className={`${css(
					...(styles || []),
					styleSheet.container,
					lastPathComp === 'upload' ? styleSheet.containerVariableHeight : null
				)} contacts-self-import ${className || ''}`}
			>
				<PrivateRoute
					location={location}
					path={`${match.path}/upload`}
					render={this.onRenderUpload}
					userSession={userSession}
				/>
				<PrivateRoute
					location={location}
					path={`${match.path}/map`}
					render={this.onRenderMap}
					userSession={userSession}
				/>
				<PrivateRoute
					location={location}
					path={`${match.path}/processing`}
					render={this.onRenderProcessing}
					userSession={userSession}
				/>
			</div>
		);
	}

	private onRenderUpload = (props: RouteComponentProps<any>) => {
		const { onCancel } = this.props;
		return (
			<ContactsSelfImportUpload
				{...props}
				className={css(styleSheet.content, styleSheet.uploadContent)}
				importContacts={this.mImportContacts}
				onCancelButtonClicked={onCancel}
				onNextButtonClicked={this.onNavigateToSubRoute('/map')}
			/>
		);
	};

	private onRenderMap = (props: RouteComponentProps<any>) => {
		const { onCancel } = this.props;
		return (
			<ContactsSelfImportMapping
				{...props}
				className={css(styleSheet.content, styleSheet.mapContent)}
				importContacts={this.mImportContacts}
				onCancelButtonClicked={onCancel}
				onImportClicked={this.onNavigateToSubRoute('/processing')}
				showMergeByNameCheckbox={true}
			/>
		);
	};

	private onRenderProcessing = (props: RouteComponentProps<any>) => {
		return (
			<ContactsSelfImportProcessing
				{...props}
				className={css(styleSheet.content, styleSheet.importContent)}
				importContacts={this.mImportContacts}
				onRequestClose={this.onProcessingRequestClose}
			/>
		);
	};

	private onNavigateToSubRoute = (path: string) => () => {
		const { history, match, location } = this.props;
		history.push({
			pathname: `${match.path}${path}`,
			state: location.state, // pass the shared state to the next route
		});
	};

	private onProcessingRequestClose = (contactsSearchRequestFilter?: IContactsFilterRequest) => {
		const { history, onCancel, location } = this.props;

		if (location.state?.model?.onFinish) {
			let defaultPrevented = false;
			const finishEvent: IContactsSelfImportEvent = {
				searchResult: contactsSearchRequestFilter,
				preventDefault: () => (defaultPrevented = true),
			};
			location.state.model.onFinish(finishEvent);

			if (defaultPrevented) {
				onCancel?.();
				return;
			}
		}

		if (contactsSearchRequestFilter) {
			setTimeout(() => {
				const sortDescriptor: ISortDecriptor = {
					...DefaultContactsSortDescriptor,
				};
				history.push({
					pathname: `/people`,
					search: `${toQueryString(searchRequestToQueryStringParams(contactsSearchRequestFilter, sortDescriptor))}`,
				});
			});
		} else {
			if (onCancel) {
				onCancel();
			}
		}
	};
}

const ContactsSelfImportAsObserver = observer(_ContactsSelfImport);
const ContactsSelfImportWithContext = inject(UserSessionViewModelKey)(ContactsSelfImportAsObserver);
export const ContactsSelfImport = withEventLogging(withRouter(ContactsSelfImportWithContext), 'ContactsSelfImport');
