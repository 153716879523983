import { css } from 'aphrodite';
import * as React from 'react';
import { baseStyleSheet } from '../../../../styles/styles';
import { Dropdown } from '../../../Dropdown';
import { TextInput } from '../../../TextInput';
import { DisclosureIcon } from '../../../svgs/icons/DisclosureIcon';
import { styleSheet } from './styles';
import { styleSheet as dropdownStyles } from '../../DropdownComponent/styles';

interface IPremiumFilterResult {
	minimumPremium: string;
	maximumPremium: string;
}

export const PremiumFilterDropdown = ({
	initialMinimumPremium,
	initialMaximumPremium,
	onChange,
}: {
	initialMinimumPremium: string;
	initialMaximumPremium: string;
	onChange: (value: IPremiumFilterResult) => void;
}) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const [minimumPremium, setMinimumPremium] = React.useState(initialMinimumPremium);
	const [maximumPremium, setMaximumPremium] = React.useState(initialMaximumPremium);
	const filterValid = !!minimumPremium || !!maximumPremium;

	const dropdownMessage = React.useMemo(() => {
		if (!initialMinimumPremium && !initialMaximumPremium) {
			return 'Premium';
		}
		return `$${initialMinimumPremium || '0'}${initialMaximumPremium ? '-' + initialMaximumPremium : '+'}`;
	}, [initialMinimumPremium, initialMaximumPremium]);

	const viewResults = () => {
		setIsOpen(false);
		onChange({
			minimumPremium,
			maximumPremium,
		});
	};

	return (
		<Dropdown
			anchor={
				<div className={css(dropdownStyles.dropdownAnchor)}>
					<span>{dropdownMessage}</span>
					<DisclosureIcon className={css(dropdownStyles.dropdownIcon)} />
				</div>
			}
			className={css(dropdownStyles.dropdownInline, isOpen ? dropdownStyles.dropdownInlineOpen : null)}
			contentClassName={css(dropdownStyles.dropdownMenu)}
			contentPositionY='bottom'
			contentInnerClassName={css(styleSheet.dropdownContentInnerOverrides)}
			isOpen={isOpen}
			onOpenChanged={setIsOpen}
			openOnClick
		>
			<div className={css(styleSheet.amountDropdown)}>
				<div className={css(styleSheet.amountInputs)}>
					<label className={css(styleSheet.textInputContainer)}>
						<span>Minimum</span>
						<TextInput
							inputId='minimum-premium-amount-input'
							onChange={e => setMinimumPremium(e.target.value)}
							type='number'
							value={minimumPremium}
							className={css(styleSheet.textInputWrapper)}
							inputClassName={css(styleSheet.textInput)}
							placeholder='$'
						/>
					</label>

					<div className={css(styleSheet.separator)}>
						<span>&nbsp;</span>
						<span>-</span>
					</div>

					<label className={css(styleSheet.textInputContainer)}>
						<span>Maximum</span>
						<TextInput
							inputId='maximum-premium-amount-input'
							onChange={e => setMaximumPremium(e.target.value)}
							type='number'
							value={maximumPremium}
							className={css(styleSheet.textInputWrapper)}
							inputClassName={css(styleSheet.textInput)}
							placeholder='$'
						/>
					</label>
				</div>

				<button className={css(baseStyleSheet.ctaButton)} onClick={viewResults} disabled={!filterValid}>
					View Results
				</button>
			</div>
		</Dropdown>
	);
};
