import { background, brandPrimaryHover, header, inputBorderColor, mention, titles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	callForwardButton: {
		height: '40px',
		width: '300px',
	},
	callForwardInput: {
		backgroundColor: background,
		borderColor: inputBorderColor,
		borderStyle: 'solid',
		borderWidth: '1px',
		height: '40px',
		width: '300px',
	},
	container: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		fontSize: 14,
		width: 385,
		...CssSelectors.allChildren({
			alignItems: 'center',
			textAlign: 'center',
		}),
		...CssSelectors.allChildren(
			{
				marginTop: 20,
			},
			':not(:first-child)'
		),
	},
	footerText: {
		color: header,
		fontSize: '11px',
	},
	infoDetailText: {
		color: header,
	},
	phoneNumber: {
		background: mention,
		border: 'none',
		margin: '10px 0',
		padding: '20px 0',
	},
	phoneNumberInput: {
		color: `${brandPrimaryHover} !important`,
		fontSize: '36px !important',
		maxWidth: 350,
		opacity: 1,
		textAlign: 'center',
	},
	skipButton: {
		color: brandPrimaryHover,
		fontSize: '14px',
	},
	titleText: {
		color: titles,
		fontSize: '18px',
	},
});
