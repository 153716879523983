import { StyleSheet } from 'aphrodite';
import { destructive, titles } from '../../../styles/colors';
import { Layouts } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	actions: {
		...Layouts.verticalStack(16),
	},
	cardTitleInfo: {
		alignItems: 'center',
		display: 'flex',
	},
	complianceDetails: {
		display: 'block',
	},
	errorMessage: {
		borderColor: destructive,
	},
	infoIcon: {
		padding: 4,
	},
	infoPopover: {
		marginLeft: 2,
	},
	popoverContent: {
		borderRadius: 3,
		color: titles,
		fontSize: 12,
		margin: 4,
		padding: 6,
		width: 200,
	},
	sendTimeDetails: {
		fontStyle: 'italic',
	},
	warningContainer: {
		marginTop: 10,
	},
});
