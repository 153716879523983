import { StyleSheet } from 'aphrodite';

// nameCircles with 50% opacity
const backgroundColor = 'rgba(211, 219, 222, .5)';

export const styleSheet = StyleSheet.create({
	container: {
		textAlign: 'center',
	},
	navIcon: {
		alignItems: 'center',
		backgroundColor,
		borderRadius: '100%',
		display: 'inline-flex',
		height: 60,
		justifyContent: 'center',
		overflow: 'hidden',
		userSelect: 'none',
		verticalAlign: 'middle',
		width: 60,
	},
	nextIcon: {
		transform: 'rotate(-90deg)',
	},
	backIcon: {
		transform: 'rotate(90deg)',
	},
	rightNav: {
		display: 'flex',
		alignItems: 'flex-start',
		gap: 36,
	},
});
