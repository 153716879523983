import {
	IOperationResultNoValue,
	IRichContentEditorState,
	MeetingConfigViewModel,
	MeetingLocation,
	MeetingType,
} from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useEventLogging } from '../../../../../../models/Logging';
import { DefaultContactPlaceholders, MeetingRescheduleLinkPlaceholder } from '../../../../../../models/Token';
import {
	convertRawRichTextContentStateToRichContentEditorState,
	createRichContentEditorStateWithText,
	getFormattedAddress,
	isValidEmail,
} from '../../../../../../models/UiUtils';
import { useErrorMessages, useUserSession } from '../../../../../../models/hooks/appStateHooks';
import { useLambda } from '../../../../../../models/hooks/useLambda';
import { Checkbox } from '../../../../Checkbox';
import { RadioButton } from '../../../../RadioButton';
import { TextInput } from '../../../../TextInput';
import { TinyPopover } from '../../../../TinyPopover';
import { RichContentDocumentEditor } from '../../../../richContent/RichContentDocumentEditor';
import { InfoIcon } from '../../../../svgs/icons/InfoIcon';
import { styleSheet } from '../styles';

interface IProps {
	meeting: MeetingConfigViewModel;
	onEmailError: (message: string) => void;
}

const RawRichTextContentBlockStyleAttribute = 'margin:0;padding:0;font-size:14px;font-family:arial,sans-serif;';

const defaultEditorConfig = {
	defaultBlockStyleAttributeStringValue: RawRichTextContentBlockStyleAttribute,
	imageOptions: { base64EmbedOnInsert: true },
	placeholders: [...DefaultContactPlaceholders, MeetingRescheduleLinkPlaceholder],
	plugins: 'placeholders',
	toolbar: '',
};

export const BasicInfo: React.FC<IProps> = observer(({ meeting, onEmailError }) => {
	const logger = useEventLogging();
	const errorMessages = useErrorMessages();
	const userSession = useUserSession();
	const [meetingName, setMeetingName] = useState(meeting?.name ?? '');
	const [meetingNameError, setMeetingNameError] = useState('');
	const [meetingLocation, setMeetingLocation] = useState<MeetingType>(
		meeting?.locationConfig?._type ?? MeetingType.Phone
	);

	const [meetingNote, setMeetingNote] = useState<IRichContentEditorState>(
		meeting?.confirmationNote
			? convertRawRichTextContentStateToRichContentEditorState(meeting.confirmationNote)
			: createRichContentEditorStateWithText('', RawRichTextContentBlockStyleAttribute)
	);
	const [phonePopoverIsOpen, , setPhonePopoverIsOpen] = useLambda(false);
	const [ccEmail, setCcEmail] = useState(meeting?.ccEmails?.join(', ') ?? '');
	const [ccEmailError, setCcEmailError] = useState('');
	const [showOnHomepage, setShowOnHomepage] = useState(meeting?.showOnHomepage);
	const [addMeetingNameToCalendarEvent, setAddMeetingNameToCalendarEvent] = useState(
		meeting?.addMeetingNameToCalendarEvent
	);
	const [address, setAddress] = useState(
		meeting?.getDefaultAddress() ||
			(userSession?.account?.planDetails?.address ? getFormattedAddress(userSession.account.planDetails.address) : '')
	);
	const [virtualLink, setVirtualLink] = useState(meeting?.getDefaultVirtualLink());

	useEffect(() => {
		meeting.setAsEditing()?.catch((err: IOperationResultNoValue) => {
			logger.logApiError('MeetingConfigSetAsEditing-Error', err);
			// do not need to show user that this failed...
		});
		meeting.getAutomationChoices();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onShowOnHomepageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.checked;
		setShowOnHomepage(value);
		meeting.showOnHomepage = value;
	};

	const onAddMeetingNameToCalendarEventChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.checked;
		setAddMeetingNameToCalendarEvent(value);
		meeting.addMeetingNameToCalendarEvent = value;
	};

	const onLocationChange = useCallback(
		(location: MeetingType) => () => {
			setMeetingLocation(location);

			switch (location) {
				case MeetingType.Phone:
					meeting.locationConfig = {
						_type: location,
						willCall: true,
					};
					break;
				case MeetingType.InPerson:
					meeting.locationConfig = {
						_type: location,
						address,
					};
					break;
				case MeetingType.Virtual:
					meeting.locationConfig = {
						_type: location,
						link: virtualLink,
					};
					break;
				case MeetingType.AllowInvitee:
					meeting.locationConfig = {
						_type: location,
						locations: [],
					};
					break;
				default:
					break;
			}
		},
		[address, meeting, virtualLink]
	);

	const onAllowInviteeConfigUpdated = (location: MeetingLocation) => (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			updateAllowInviteeLocation(location)();
			return;
		}
		meeting.deleteAllowInviteeLocation(location);
	};

	const updateAllowInviteeLocation = (location: MeetingLocation) => async () => {
		try {
			switch (location) {
				case MeetingLocation.Phone:
					await meeting.updateAllowInviteeLocation({
						_type: location,
						phoneNumber: '',
					});
					break;
				case MeetingLocation.InPerson:
					await meeting.updateAllowInviteeLocation({
						_type: location,
						address,
					});
					break;
				case MeetingLocation.Virtual:
					await meeting.updateAllowInviteeLocation({
						_type: location,
						link: virtualLink,
					});
					break;
				default:
					break;
			}
		} catch (err) {
			// @ts-ignore
			logger.logApiError('MeetingConfigUpdateAllowInvitee-Error', err);
			// @ts-ignore
			// @ts-ignore
			errorMessages.pushApiError(err);
		}
	};

	const onMeetingNoteBlur = useCallback(() => {
		const richTextContent = meetingNote.getRawRichTextContent();
		if (richTextContent.document !== meeting?.confirmationNote?.document) {
			meeting.confirmationNote = richTextContent;
		}
	}, [meeting, meetingNote]);

	const onMeetingNoteStateChange = useCallback((contentState: IRichContentEditorState) => {
		setMeetingNote(contentState);
	}, []);

	const onNameBlur = useCallback(() => {
		if (!meetingName) {
			setMeetingNameError('a meeting name is required.');
		} else if (meeting && meetingName !== meeting.name) {
			meeting.name = meetingName;
		}
	}, [meeting, meetingName]);

	const onNameChanged = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setMeetingName(e.target.value);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[meeting]
	);

	const onCcEmailBlur = useCallback(() => {
		const ccEmailsArray = ccEmail
			.split(',')
			.map(cc => cc.trim())
			.filter(cc => !!cc);
		const omit = ccEmailsArray?.filter(x => !isValidEmail(x));
		const valid = ccEmailsArray?.filter(x => isValidEmail(x));

		if (omit?.length > 0) {
			onEmailError('A valid email is required');
			setCcEmailError('A valid email is required');
		} else if (meeting) {
			onEmailError('');
			setCcEmailError('');
			meeting.ccEmails = valid;
		}
	}, [meeting, ccEmail, onEmailError]);

	const onCcEmailChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setCcEmail(e.target.value);
	}, []);

	const onPhonePopoverHover = (isOpen: boolean) => () => {
		setPhonePopoverIsOpen(isOpen)();
	};

	const onChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => setAddress(e.target.value);
	const onChangeVirtualLink = (e: React.ChangeEvent<HTMLInputElement>) => setVirtualLink(e.target.value);

	const isMeetingAllowInvitee = meetingLocation === MeetingType.AllowInvitee;
	const isMeetingInPerson = meetingLocation === MeetingType.InPerson;
	const isMeetingLocationPhone = meetingLocation === MeetingType.Phone;
	const isVirtualMeeting = meetingLocation === MeetingType.Virtual;

	return (
		<div className={css(styleSheet.col)}>
			<div className={css(styleSheet.card)}>
				<div className={css(styleSheet.sectionTitle)}>Basic Info</div>
				<div className={css(styleSheet.sectionWrapper)}>
					<p className={css(styleSheet.label)}>
						Meeting Name
						{meetingNameError && <span className={css(styleSheet.error)}>{meetingNameError}</span>}
					</p>
					<TextInput
						inputId='meeting-config-name-input'
						onBlur={onNameBlur}
						onChange={onNameChanged}
						type='text'
						value={meetingName}
					/>
				</div>
				<div className={css(styleSheet.sectionWrapper)}>
					<div className={css(styleSheet.checkboxContainer)}>
						<Checkbox checked={showOnHomepage} id='meeting-config-show-on-homepage' onChange={onShowOnHomepageChange}>
							<span className={css(styleSheet.checkboxText)}>Add to meetings homepage</span>
						</Checkbox>
					</div>
					<div className={css(styleSheet.checkboxContainer)}>
						<Checkbox
							checked={addMeetingNameToCalendarEvent}
							id='meeting-config-meeting-name-in-calendar-invite'
							onChange={onAddMeetingNameToCalendarEventChange}
						>
							<span className={css(styleSheet.checkboxText)}>Include meeting name in calendar invitation</span>
						</Checkbox>
					</div>
				</div>
				<div className={css(styleSheet.sectionWrapper)}>
					<p className={css(styleSheet.label)}>Meeting Location</p>
					<RadioButton
						checked={isMeetingLocationPhone}
						className={css(styleSheet.radio)}
						disabled={meeting?.isBusy || meeting?.isSaving}
						id='meeting-config-location-phone'
						name='meeting-config-location'
						onChange={onLocationChange(MeetingType.Phone)}
					>
						<span className={css(styleSheet.phoneLabel)}>
							Phone call
							<TinyPopover
								anchor={
									<span
										className={css(styleSheet.phoneLabelIcon)}
										onMouseEnter={onPhonePopoverHover(true)}
										onMouseLeave={onPhonePopoverHover(false)}
									>
										<InfoIcon />
									</span>
								}
								isOpen={phonePopoverIsOpen}
								align='center'
								placement={['right']}
							>
								<div className={css(styleSheet.label, styleSheet.phoneInfo)}>
									We&apos;ll ask the client to leave a number and let them know you will call them.
								</div>
							</TinyPopover>
						</span>
					</RadioButton>
					<RadioButton
						checked={isMeetingInPerson}
						className={css(styleSheet.radio)}
						disabled={meeting?.isBusy || meeting?.isSaving}
						id='meeting-config-location-in-person'
						name='meeting-config-location'
						onChange={onLocationChange(MeetingType.InPerson)}
					>
						<span>In Person</span>
					</RadioButton>
					{isMeetingInPerson && (
						<div className={css(styleSheet.locationInfoContainer)}>
							<p className={css(styleSheet.label)}>Please enter the physical meeting address below:</p>
							<TextInput
								className={css(styleSheet.locationInfoInput)}
								type='text'
								inputId='meeting-config-location-in-person-text'
								value={address}
								onChange={onChangeAddress}
								onBlur={onLocationChange(MeetingType.InPerson)}
							/>
						</div>
					)}
					<RadioButton
						checked={isVirtualMeeting}
						className={css(styleSheet.radio)}
						disabled={meeting?.isBusy || meeting?.isSaving}
						id='meeting-config-location-virtual'
						name='meeting-config-location'
						onChange={onLocationChange(MeetingType.Virtual)}
					>
						<span>Virtual</span>
					</RadioButton>
					{isVirtualMeeting && (
						<div className={css(styleSheet.locationInfoContainer)}>
							<p className={css(styleSheet.label)}>
								Please enter a virtual meeting link (Zoom, Google Hangout, etc) below:
							</p>
							<TextInput
								className={css(styleSheet.locationInfoInput)}
								type='text'
								inputId='meeting-config-location-virtual-text'
								value={virtualLink}
								onChange={onChangeVirtualLink}
								onBlur={onLocationChange(MeetingType.Virtual)}
							/>
						</div>
					)}
					<RadioButton
						checked={isMeetingAllowInvitee}
						className={css(styleSheet.radio)}
						disabled={meeting?.isBusy || meeting?.isSaving}
						id='meeting-config-location-allow-invitee'
						name='meeting-config-location'
						onChange={onLocationChange(MeetingType.AllowInvitee)}
					>
						<span>Allow invitee to decide</span>
					</RadioButton>
					{isMeetingAllowInvitee && (
						<div className={css(styleSheet.allowInviteeCheckboxes)}>
							<Checkbox
								checked={!!meeting?.allowInviteePhone}
								id='allow-invitee-phone'
								onChange={onAllowInviteeConfigUpdated(MeetingLocation.Phone)}
								className={css(styleSheet.radio)}
							>
								<span className={css(styleSheet.checkboxText)}>Phone Call</span>
							</Checkbox>
							<Checkbox
								checked={!!meeting?.allowInviteeInPerson}
								id='allow-invitee-inperson'
								onChange={onAllowInviteeConfigUpdated(MeetingLocation.InPerson)}
								className={css(styleSheet.radio)}
							>
								<span className={css(styleSheet.checkboxText)}>In Person</span>
							</Checkbox>
							{!!meeting?.allowInviteeInPerson && (
								<div className={css(styleSheet.locationInfoContainer)}>
									<p className={css(styleSheet.label)}>Please enter the physical meeting address below:</p>
									<TextInput
										className={css(styleSheet.locationInfoInput)}
										type='text'
										inputId='meeting-config-location-in-person-text'
										value={address}
										onChange={onChangeAddress}
										onBlur={updateAllowInviteeLocation(MeetingLocation.InPerson)}
									/>
								</div>
							)}
							<Checkbox
								checked={!!meeting?.allowInviteeVirtual}
								id='allow-invitee-virtual'
								onChange={onAllowInviteeConfigUpdated(MeetingLocation.Virtual)}
								className={css(styleSheet.radio)}
							>
								<span className={css(styleSheet.checkboxText)}>Virtual (please provide meeting link)</span>
							</Checkbox>
							{!!meeting?.allowInviteeVirtual && (
								<div className={css(styleSheet.locationInfoContainer)}>
									<p className={css(styleSheet.label)}>
										Please enter a virtual meeting link (Zoom, Google Hangout, etc) below:
									</p>
									<TextInput
										className={css(styleSheet.locationInfoInput)}
										type='text'
										inputId='meeting-config-location-virtual-text'
										value={virtualLink}
										onChange={onChangeVirtualLink}
										onBlur={updateAllowInviteeLocation(MeetingLocation.Virtual)}
									/>
								</div>
							)}
						</div>
					)}
				</div>
				<div className={css(styleSheet.sectionWrapper)}>
					<p className={css(styleSheet.label)}>Meeting Note</p>
					<RichContentDocumentEditor
						className={css(styleSheet.richContentEditor, styleSheet.noteEditor)}
						config={defaultEditorConfig}
						contentState={meetingNote}
						onBlur={onMeetingNoteBlur}
						onContentStateChanged={onMeetingNoteStateChange}
						readOnlyUseFullEditor={false}
					/>
				</div>
				<div className={css(styleSheet.sectionWrapper)}>
					<p className={css(styleSheet.label)}>Send a copy of meeting notifications to this email</p>
					{ccEmailError && <span className={css(styleSheet.error)}>{ccEmailError}</span>}
					<TextInput
						inputId='meeting-config-cc'
						onBlur={onCcEmailBlur}
						onChange={onCcEmailChanged}
						type='text'
						value={ccEmail}
					/>
				</div>
			</div>
		</div>
	);
});
