import { brandPrimary, brandSecondary } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fill?: string;
}

export const EmailReportIcon: React.FC<IProps> = ({ className }) => {
	return (
		<SvgIcon className={className} height={33} width={36}>
			<path
				d='M0 32.946V20.82c0-.155.316-.282.705-.282H3.85c.187 0 .366.03.498.083.133.053.207.124.207.2v12.156L0 32.947zM10.389 33v-7.691c0-.124.315-.225.704-.225h3.146c.389 0 .704.1.704.225v7.674L10.39 33zm14.943-.106c-1.518.021-3.037.041-4.555.06V12.539c0-.19.316-.344.705-.344h3.145c.39 0 .705.154.705.344v20.355zm10.388-17.12V32.85c-1.518.038-3.036.065-4.555.089V15.773c0-.2.316-.364.705-.364h3.146c.389 0 .704.163.704.364z'
				fill={brandPrimary}
			/>
			<path
				d='M12.602 19.972l10.416-18.14 10.642 8.854c.12.1.137.277.039.398a.278.278 0 01-.381.049l-.013-.01-10.134-8.431-10.362 18.046L2.18 14.705a.284.284 0 01-.106-.385.278.278 0 01.366-.116l.014.008 10.148 5.76z'
				fill='#3F3D56'
			/>
			<ellipse cx='2.277' cy='14.458' rx='2.238' ry='2.262' fill={brandSecondary} />
			<ellipse cx='12.666' cy='20.355' rx='2.238' ry='2.262' fill={brandSecondary} />
			<ellipse cx='23.054' cy='2.262' rx='2.238' ry='2.262' fill={brandSecondary} />
			<ellipse cx='33.763' cy='11.308' rx='2.238' ry='2.262' fill={brandSecondary} />
		</SvgIcon>
	);
};
