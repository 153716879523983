import { StyleSheet } from 'aphrodite';
import { brandPrimaryText, titles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

const avatarSize = 35;

export const styleSheet = StyleSheet.create({
	avatar: {
		fontSize: 14,
		height: avatarSize,
		width: avatarSize,
	},
	body: {
		alignItems: 'center',
		color: '#8E8E8E',
		display: 'flex',
		fontSize: 12,
		marginTop: 16,
	},
	contactName: {
		':active': {
			textDecoration: 'none',
		},
		':focus': {
			textDecoration: 'none',
		},
		':hover': {
			textDecoration: 'underline',
		},
		color: brandPrimaryText,
		textDecoration: 'none',
	},
	contactNameContainer: {
		maxWidth: 300,
	},
	container: {},
	footer: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
	},
	header: {
		alignItems: 'center',
		color: titles,
		display: 'flex',
		fontSize: 16,
	},
	headerDescription: {
		alignItems: 'center',
		display: 'flex',
		marginLeft: 12,
		width: `calc(100% - ${avatarSize + 12}px)`,
	},
	sendButton: {
		flexShrink: 0,
	},
	viewHeader: {
		alignItems: 'center',
		color: '#8E8E8E',
		display: 'flex',
		fontSize: 14,
		marginTop: 14,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
});
