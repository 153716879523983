import { Button } from '../../../Button';
import { NewTextMessageIcon } from '../../../svgs/icons/NewTextMessageIcon';
import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';

interface IProps {
	onCreateConversationClick: () => void;
	phoneNumber: string;
}

export const YourNumber: React.FC<IProps> = observer(({ onCreateConversationClick, phoneNumber }) => {
	return (
		<div className={css(styleSheet.conversationsHeader)}>
			<div className={css(styleSheet.yourNumber)}>
				<div>Your Number</div>
				<div>{phoneNumber}</div>
			</div>
			<Button
				className='new-conversation-cta'
				label={<NewTextMessageIcon fillColor='#fff' />}
				onClick={onCreateConversationClick}
			/>
		</div>
	);
});
