import { StyleSheet } from 'aphrodite';
import { brandPrimary } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

const searchHeaderHeight = 90;

export const styleSheet = StyleSheet.create({
	clear: {
		color: brandPrimary,
		marginLeft: 10,
	},
	dateSelect: {
		width: 300,
		borderRadius: 6,
		marginLeft: 10,
	},
	filterSelect: {
		width: 250,
		height: 40,
		marginLeft: 10,
		borderRadius: 6,
	},
	filterSelectOption: {
		width: 248,
	},
	note: {
		':not(:last-child)': {
			marginBottom: 15,
		},
		boxSizing: 'border-box',
		width: 'calc(100% - 5px)',
	},
	notesList: {
		boxSizing: 'border-box',
		height: `calc(100% - ${searchHeaderHeight}px)`,
		maxWidth: '100%',
		overflowY: 'auto',
		padding: '0 5px 20px 0',
		width: '100%',
	},
	searchContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
	searchField: {
		borderRadius: 6,
		width: 360,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			'input[type="text"]'
		),
	},
	searchHeader: {
		alignItems: 'center',
		background: '#fff',
		display: 'flex',
		height: searchHeaderHeight,
		justifyContent: 'space-between',
	},
	searchHeaderWrap: {
		display: 'flex',
	},
	searchIcon: {
		height: 16,
		width: 16,
	},
	sortSelect: {
		width: 500,
		borderRadius: 6,
	},
});
