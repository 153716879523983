import { inputBorderColor, titles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	checkbox: {
		color: '#767676',
		fontSize: '14px',
		letterSpacing: 0,
		marginLeft: 20,
	},
	checkboxLabel: {
		marginLeft: 10,
		whiteSpace: 'nowrap',
	},
	definition: {
		display: 'flex',
	},
	definitionField: {
		maxWidth: 325,
		minWidth: 325,
	},
	definitionInput: {
		alignItems: 'center',
		background: '#F9F9F9',
		border: `1px solid ${inputBorderColor}`,
		borderRadius: '3px',
		boxSizing: 'border-box',
		color: titles,
		display: 'flex',
		fontSize: '14px',
		padding: 10,
	},
	left: {
		alignItems: 'center',
		color: '#858585',
		display: 'flex',
		fontSize: '12px',
		lineHeight: '22px',
		...CssSelectors.allChildren({
			':last-child': {
				marginRight: 10,
			},
			':not(:first-child)': {
				marginLeft: 10,
			},
		}),
	},
	leftSpan: {
		whiteSpace: 'nowrap',
	},
	right: {
		padding: '0 0 0 10px',
	},
});
