import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { TransparentButton } from '../../../../aida/components/TransparentButton';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { invalidateMyTemplates } from '../../../../queries';
import {
	invalidateEmailMyTemplatesKeywords,
	useEmailMyTemplatesKeywords,
} from '../../../../queries/Templates/useEmailMyTemplatesKeywords';
import { useEmailMyTemplatesUpdateKeywords } from '../../../../queries/Templates/useEmailMyTemplatesUpdateKeywords';
import { ITemplateCard } from '../../../../viewmodels/AppViewModels';
import { hotPink } from '../../../styles/colors';
import { KeywordsSelector } from '../../KeywordsSelector';
import { KeywordIcon } from '../../svgs/icons/KeywordIcon';
import { RemoveTagIcon } from '../../svgs/icons/RemoveTagIcon';
import { styleSheet } from './styles';

export interface IProps {
	card: ITemplateCard;
	industry: Api.Industry;
}

export const TemplateKeywords: React.FC<IProps> = ({ card, industry }) => {
	const userSession = useUserSession();
	const [toggleAddKeyword, setToggleAddKeyword] = React.useState(false);

	const [inputValue, setInputValue] = React.useState('');

	const keywordsQuery = useEmailMyTemplatesKeywords({
		industry,
	});

	const keywordsMutation = useEmailMyTemplatesUpdateKeywords({
		onSuccess: () => {
			invalidateMyTemplates({ templateType: Api.TemplateType.Email });
			invalidateEmailMyTemplatesKeywords({ industry });
			setInputValue('');
			setToggleAddKeyword(false);
		},
	});

	const cardKeywords = React.useMemo(() => card?.keywords || [], [card?.keywords]);

	const filteredKeywords = React.useMemo(() => {
		if (keywordsQuery.data) {
			const filteredCardKeywords = keywordsQuery.data?.filter((kq: string) => !cardKeywords.find(k => k === kq));
			if (!inputValue) {
				return filteredCardKeywords;
			}
			return filteredCardKeywords.filter((keyword: string) => {
				return !inputValue || keyword.toLowerCase().includes(inputValue.toLowerCase());
			});
		}
		return [];
	}, [cardKeywords, inputValue, keywordsQuery.data]);

	const handleRemove = (selected: string) => () => {
		const updated = cardKeywords.filter(k => k !== selected);
		keywordsMutation.mutate({
			keywords: updated,
			templateId: card.id,
		});
	};

	const handleClickEnter = (value: string) => {
		if (value) {
			handleSelectItem(value);
		}
	};

	const handleInputChange = (value: string) => {
		setInputValue(value);
	};

	const handleSelectItem = (selected: string) => {
		if (selected && !cardKeywords.find(k => k === selected)) {
			const updated = [...cardKeywords.filter(k => !!k), selected];
			keywordsMutation.mutate({
				keywords: updated,
				templateId: card.id,
			});
		}
	};

	const handleToggle = () => {
		setToggleAddKeyword(!toggleAddKeyword);
	};

	const closeToggle = (value?: string) => {
		if (!value) {
			setToggleAddKeyword(false);
		}
	};

	const isMyOwnTemplate = userSession?.user?.id === card?.creator?.id;

	return (
		<div className={css(styleSheet.keywordsContainer)}>
			{cardKeywords.length > 0 || isMyOwnTemplate ? (
				<KeywordIcon fillColor={hotPink} className={css(styleSheet.keywordsIcon)} />
			) : null}
			{cardKeywords.map((k, index) => {
				return (
					<span key={`${index}-${k}`} className={css(styleSheet.keywords)}>
						{isMyOwnTemplate ? (
							<TransparentButton onClick={handleRemove(k)} className={css(styleSheet.keywordsRemoveButton)}>
								<RemoveTagIcon fillColor={hotPink} />
							</TransparentButton>
						) : null}
						&nbsp;{k || 'novalue'}
						{index !== cardKeywords.length - 1 ? ',' : null}&nbsp;
					</span>
				);
			})}
			{toggleAddKeyword ? (
				<KeywordsSelector
					autoFocus
					disabled={keywordsMutation.isLoading}
					inputStyleDeclaration={styleSheet.keywordsSelectorInputField}
					items={filteredKeywords}
					onClickEnter={handleClickEnter}
					onInputBlur={closeToggle}
					onInputValueChange={handleInputChange}
					onSelectItem={handleSelectItem}
					saveButtonStyleDeclaration={styleSheet.keywordsSelectorSaveButton}
					inputValue={inputValue}
				/>
			) : isMyOwnTemplate ? (
				<TransparentButton onClick={handleToggle} className={css(styleSheet.keywordsAddCTA)}>
					+ Add Keyword
				</TransparentButton>
			) : null}
		</div>
	);
};
