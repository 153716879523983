export const EyeIcon = () => {
	return (
		<svg width='22' height='14' viewBox='0 0 22 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11 12C6.835 12 3.451 8.424 2.269 6.999C3.448 5.573 6.82 2 11 2C15.165 2 18.549 5.576 19.731 7.001C18.552 8.427 15.18 12 11 12M21.817 6.424C21.633 6.162 17.222 0 11 0C4.778 0 0.367 6.162 0.183 6.424C-0.061 6.77 -0.061 7.23 0.183 7.576C0.367 7.838 4.778 14 11 14C17.222 14 21.633 7.838 21.817 7.576C22.061 7.23 22.061 6.77 21.817 6.424'
				fill='#00AAE8'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11 9C9.897 9 9 8.103 9 7C9 5.898 9.897 5 11 5C12.103 5 13 5.898 13 7C13 8.103 12.103 9 11 9M11 3C8.794 3 7 4.794 7 7C7 9.206 8.794 11 11 11C13.206 11 15 9.206 15 7C15 4.794 13.206 3 11 3'
				fill='#00AAE8'
			/>
		</svg>
	);
};
