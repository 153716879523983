import { INotesActionsCriteria, RichContentSupportedTypes } from '../../../../models';
import {
	CompanyViewModel,
	ExtendedSupportedTimelineTypes,
	RichContentViewModel,
	TimelineEventTypes,
	UserSessionContext,
} from '../../../../viewmodels/AppViewModels';
import { ITimelineCriteria } from '../../../containers/People/Contact/Activity';
import { LoadingSpinner } from '../../LoadingSpinner';
import { ISelectOption, MultiSelect } from '../../Select';
import { ITabViewChild, TabView } from '../../TabView';
import { EntityRichContent } from '../../entities/EntityRichContent';
import { CompanyTimeline } from '../../entities/EntityTimeline/CompanyTimeline';
import { CompanyContactsList } from '../CompanyContactsList';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';

const LazyOpportunities = React.lazy(
	() => import(/* webpackPrefetch: true */ '../../dataBoards/opportunities/EntityOpportunities')
);

interface IProps {
	company: CompanyViewModel;
	scrollToBottomWaypointPortalId?: string;
	userSession?: UserSessionContext;
}

interface IState {
	company?: CompanyViewModel;
	richContentSupportedTypes?: RichContentSupportedTypes[];
	timelineEventTypes?: TimelineEventTypes[];
}

const richContentStorageKey = 'notesActionsFilterOptions';
const timelineStorageKey = 'timelineFilterOptions';
class _CompanyActivity extends React.Component<IProps, IState> {
	public readonly state: IState = {
		company: this.props.company,
	};

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		if (
			this.props.company !== nextProps.company &&
			!!this.props.company &&
			!!nextProps.company &&
			this.props.company.id !== nextProps.company.id
		) {
			this.setState({
				company: nextProps.company,
			});
			nextProps.company.getRichContent();
		}
	}

	public componentDidMount() {
		this.getRichContentSupportedTypes();
		this.getTimelineSupportedTypes();
	}

	public render() {
		return (
			<div>
				<TabView className={css(styleSheet.tabView)} contentClassName={css(styleSheet.content)}>
					{this.getTabViewContent}
				</TabView>
			</div>
		);
	}

	private getTabViewContent = (): ITabViewChild[] => {
		const { company, scrollToBottomWaypointPortalId, userSession } = this.props;
		const showTimeline = !!userSession.account.features?.timeline?.enabled;
		const { richContentSupportedTypes, timelineEventTypes } = this.state;

		const tabs = [
			showTimeline
				? {
						content: (
							<>
								<div className={css(styleSheet.timelineFilterOptionsContainer)}>
									<MultiSelect
										onClose={this.onTimelineFilterClose}
										options={this.getTimelineFilterOptions()}
										dropdownStyles={[styleSheet.dropdown]}
										selectedOptions={this.getDefaultTimelineFilterOptions()}
										selectAllIfNoneSelected={false}
										selectedOptionsTitle={this.getFilterOptionsTitle}
									/>
								</div>
								<CompanyTimeline
									companyId={company.id}
									onDeleteRichContent={(richContent: RichContentViewModel) => company.deleteRichContent(richContent)}
									eventTypes={timelineEventTypes}
									scrollToBottomWaypointPortalId={scrollToBottomWaypointPortalId}
								/>
							</>
						),
						tabbarItem: {
							content: 'History',
						},
					}
				: {
						content: (
							<>
								<div className={css(styleSheet.richContentFilterOptionsContainer)}>
									<MultiSelect
										onClose={this.onRichContentFilterClose}
										options={this.getRichContentFilterOptions()}
										dropdownStyles={[styleSheet.dropdown]}
										selectedOptions={this.getDefaultRichContentFilterOptions()}
										selectAllIfNoneSelected={true}
										selectedOptionsTitle={this.getFilterOptionsTitle}
									/>
								</div>
								<EntityRichContent
									entity={company}
									scrollToBottomWaypointPortalId={scrollToBottomWaypointPortalId}
									supportedTypes={richContentSupportedTypes}
								/>
							</>
						),
						tabbarItem: {
							content: 'Notes / Actions',
						},
					},
			{
				content: (
					<CompanyContactsList company={company} scrollToBottomWaypointPortalId={scrollToBottomWaypointPortalId} />
				),
				tabbarItem: {
					content: 'Contacts',
				},
			},
		];

		if (
			!!userSession &&
			!!userSession.account.features.boards &&
			!!userSession.account.features.boards.opportunitiesEnabled
		) {
			tabs.push({
				content: (
					<React.Suspense fallback={this.onRenderCompanyOpportunitiesLoading()}>
						<LazyOpportunities entity={company} scrollToBottomWaypointPortalId={scrollToBottomWaypointPortalId} />
					</React.Suspense>
				),
				tabbarItem: {
					content: 'Opportunities',
				},
			});
		}

		return tabs;
	};

	private getDefaultRichContentFilterOptions = (): ISelectOption<INotesActionsCriteria>[] => {
		const notesActionsFilterOptions = window.localStorage.getItem(richContentStorageKey)?.split('|');
		return this.getRichContentFilterOptions().filter(option => {
			if (!notesActionsFilterOptions || notesActionsFilterOptions.length === 0) {
				return option;
			}

			return notesActionsFilterOptions.includes(option.dataContext.type);
		});
	};

	private getDefaultTimelineFilterOptions = (): ISelectOption<ITimelineCriteria>[] => {
		const timelineFilterOptions = window.localStorage.getItem(timelineStorageKey)?.split('|');

		const filteredOptions = this.getTimelineFilterOptions().filter(option => {
			if (!timelineFilterOptions || timelineFilterOptions.length === 0) {
				return option;
			}

			let typeToCheck = option.dataContext.type as string;

			if (Array.isArray(option.dataContext.type)) {
				typeToCheck = option.dataContext.type.join(',');
			}

			return timelineFilterOptions.includes(typeToCheck);
		});

		return filteredOptions;
	};

	private getRichContentFilterOptions = (): ISelectOption<INotesActionsCriteria>[] => {
		return [
			{
				dataContext: {
					name: 'all',
					type: 'All',
				},
				forceSelectAll: true,
				id: 'rich-content-filter-option-show-all',
				text: 'Show all',
				type: 'checkbox',
			},
			{
				dataContext: {
					name: 'notes',
					type: 'Note',
				},
				id: 'rich-content-filter-option-show-notes',
				text: 'Show notes',
				type: 'checkbox',
			},
			{
				dataContext: {
					name: 'action items',
					type: 'ActionItem',
				},
				id: 'rich-content-filter-option-show-action-items',
				text: 'Show action items',
				type: 'checkbox',
			},
			{
				dataContext: {
					name: 'emails',
					type: 'EmailNote',
				},
				id: 'rich-content-filter-option-show-emails',
				text: 'Show emails',
				type: 'checkbox',
			},
		];
	};

	private getTimelineFilterOptions = (): ISelectOption<ITimelineCriteria>[] => {
		const { userSession } = this.props;

		const isCoffeeUser = userSession.account.features.aida.enabled;

		const filterOptions: ISelectOption<ITimelineCriteria>[] = [
			{
				dataContext: {
					name: 'all',
					type: 'All',
				},
				forceSelectAll: true,
				id: 'rich-content-filter-option-show-all',
				text: 'Show all',
				type: 'checkbox',
			},
			{
				dataContext: {
					name: 'notes',
					type: 'NoteEvent',
				},
				id: 'rich-content-filter-option-show-notes',
				text: 'Show notes',
				type: 'checkbox',
			},
			{
				dataContext: {
					name: 'action items',
					type: 'ActionItemEvent',
				},
				id: 'rich-content-filter-option-show-action-items',
				text: 'Show action items',
				type: 'checkbox',
			},
			{
				dataContext: {
					name: 'deals',
					type: ['DealCreatedEvent', 'DealUpdatedEvent'],
				},
				id: 'rich-content-filter-option-show-deals',
				text: 'Show deals',
				type: 'checkbox',
			},
			{
				dataContext: {
					name: 'emails',
					type: 'SentEmailEvent',
				},
				id: 'rich-content-filter-option-show-emails',
				text: 'Show emails',
				type: 'checkbox',
			},
			{
				dataContext: {
					name: 'conversations',
					type: 'ConversationThreadEvent',
				},
				id: 'rich-content-filter-option-show-texts',
				text: 'Show conversations',
				type: 'checkbox',
			},
			{
				dataContext: {
					name: 'meetings',
					type: 'MeetingEvent',
				},
				id: 'rich-content-filter-option-show-meetings',
				text: 'Show meetings',
				type: 'checkbox',
			},
		];

		const phoneCallEvent: ISelectOption<ITimelineCriteria> = {
			dataContext: {
				name: 'phone calls',
				type: 'PhoneCallEvent',
			},
			id: 'rich-content-filter-option-show-phone-calls',
			text: 'Show phone calls',
			type: 'checkbox',
		};

		if (isCoffeeUser) {
			phoneCallEvent.dataContext.type = ['PhoneCallEvent', 'PhoneCallCompletedEvent', 'UntrackedPhoneCallEvent'];
		}

		filterOptions.push(phoneCallEvent);
		return filterOptions;
	};

	private getFilterOptionsTitle(selectedOptions: ISelectOption<INotesActionsCriteria | ITimelineCriteria>[]) {
		if (selectedOptions.find(option => option.dataContext.type === 'All')) {
			return <div>Show all</div>;
		}

		const maxCharCount = 25;
		const text = `Show ${selectedOptions.map(option => option.dataContext.name).join(' and ') || 'none'}`;
		return <div>{text.length > maxCharCount ? `${text.slice(0, maxCharCount)}...` : text}</div>;
	}

	private getRichContentSupportedTypes = () => {
		const notesActionsFilterOptions = window.localStorage.getItem(richContentStorageKey)?.split('|');
		const richContentSupportedTypes =
			!notesActionsFilterOptions || notesActionsFilterOptions.length === 0 || notesActionsFilterOptions.includes('All')
				? ['ActionItem', 'Note', 'EmailNote']
				: notesActionsFilterOptions.filter(option => option !== 'All');

		this.setState({
			richContentSupportedTypes: richContentSupportedTypes as RichContentSupportedTypes[],
		});
	};

	private getTimelineSupportedTypes = () => {
		const timelineFilterOptions: string[] = window.localStorage.getItem(timelineStorageKey)?.split('|');
		const timelineEventTypes =
			!timelineFilterOptions || timelineFilterOptions.length === 0 || timelineFilterOptions.includes('All')
				? ExtendedSupportedTimelineTypes
				: timelineFilterOptions?.map(item => item?.split(',') || item)?.filter(option => option !== 'All');

		this.setState({
			timelineEventTypes: timelineEventTypes.flat() as TimelineEventTypes[],
		});
	};

	private onRichContentFilterClose = (selectedOptions: ISelectOption<INotesActionsCriteria>[]) => {
		const selected = selectedOptions.map(option => option.dataContext.type);
		window.localStorage.setItem(richContentStorageKey, selected.join('|'));
		this.getRichContentSupportedTypes();
	};

	private onTimelineFilterClose = (selectedOptions: ISelectOption<ITimelineCriteria>[]) => {
		const selected = selectedOptions.map(option => option.dataContext.type);

		window.localStorage.setItem(timelineStorageKey, selected.join('|'));
		this.getTimelineSupportedTypes();
	};

	private onRenderCompanyOpportunitiesLoading = () => {
		return <LoadingSpinner className={css(styleSheet.oppsLoadingSpinner)} type='small' />;
	};
}

export const CompanyActivity = observer(_CompanyActivity);
