import {
	ICustomFieldMetaData,
	IFormField,
	IMeetingDuration,
	IOperationResultNoValue,
	MeetingConfigViewModel,
	MeetingDateRange,
} from '@ViewModels';
import { css } from 'aphrodite';
import equal from 'fast-deep-equal';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useEventLogging } from '../../../../../../models/Logging';
import { useToaster, useUserSession } from '../../../../../../models/hooks/appStateHooks';
import { useLambda } from '../../../../../../models/hooks/useLambda';
import { useContactCustomFields } from '../../../../../../queries/Contact/useContactCustomFields';
import { baseStyleSheet } from '../../../../../styles/styles';
import { Checkbox } from '../../../../Checkbox';
import { DayPicker } from '../../../../DayPicker';
import { Popover, PopoverType } from '../../../../Popover';
import { RadioButton } from '../../../../RadioButton';
import { ISelectOption, Select } from '../../../../Select';
import { TextInput } from '../../../../TextInput';
import { Toggle } from '../../../../Toggle';
import { styleSheet } from '../styles';

interface IProps {
	meeting: MeetingConfigViewModel;
}

const getStringId = (interval: number, hours?: boolean): string => {
	return `meeting-duration-option-${interval}-${hours ? 'hour' : 'min'}${hours && interval > 1 ? 's' : ''}`;
};

const meetingDurationOptions: ISelectOption<IMeetingDuration>[] = [
	{
		dataContext: { days: 0, hours: 0, minutes: 15, seconds: 0 },
		id: getStringId(15),
		text: '15 minutes',
	},
	{
		dataContext: { days: 0, hours: 0, minutes: 30, seconds: 0 },
		id: getStringId(30),
		text: '30 minutes',
	},
	{
		dataContext: { days: 0, hours: 0, minutes: 45, seconds: 0 },
		id: getStringId(45),
		text: '45 minutes',
	},
	{
		dataContext: { days: 0, hours: 1, minutes: 0, seconds: 0 },
		id: getStringId(1, true),
		text: '1 hour',
	},
	{
		dataContext: { days: 0, hours: 1, minutes: 30, seconds: 0 },
		id: getStringId(90),
		text: '90 minutes',
	},
	{
		dataContext: { days: 0, hours: 2, minutes: 0, seconds: 0 },
		id: getStringId(2, true),
		text: '2 hours',
	},
	{
		dataContext: { days: 0, hours: 3, minutes: 0, seconds: 0 },
		id: getStringId(3, true),
		text: '3 hours',
	},
	{
		dataContext: { days: 0, hours: 4, minutes: 0, seconds: 0 },
		id: getStringId(4, true),
		text: '4 hours',
	},
	{
		dataContext: { days: 0, hours: 8, minutes: 0, seconds: 0 },
		id: getStringId(8, true),
		text: '8 hours',
	},
];

interface IMeetingAvailabilitySlots {
	selected: boolean;
	value: number;
}
const meetingAvailabilitySlotStartInMinutes: IMeetingAvailabilitySlots[] = [
	{ selected: true, value: 0 },
	{ selected: false, value: 15 },
	{ selected: false, value: 20 },
	{ selected: true, value: 30 },
	{ selected: false, value: 40 },
	{ selected: false, value: 45 },
];

const getMeetingBufferTimeOptions = () => {
	const options: ISelectOption<string>[] = [];
	[10, 15, 20, 30, 40, 45, 60].forEach(minutes => {
		const padded = String(minutes).padStart(2, '0');
		options.push({
			dataContext: minutes === 60 ? `01:00:00` : `00:${padded}:00`,
			id: `meeting-buffer-time-option-${minutes}`,
			text: padded,
		});
	});
	return options;
};

const CustomFieldLayout = (props: {
	meeting: MeetingConfigViewModel;
	field: IFormField<string>;
	metadata?: ICustomFieldMetaData;
}) => {
	const [isIncluded, setIsIncluded] = useState(!!props.metadata);
	const [isRequired, setIsRequired] = useState(props.metadata?.isRequired ?? false);
	const onIncludeCustomFieldChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.checked;
		setIsIncluded(value);
		if (!value) {
			setIsRequired(false);
		}
		props.meeting.updateCustomFieldMetaData(props.field.name, value, isRequired);
	};

	const onRequireChanged = (checked: boolean) => {
		setIsRequired(checked);
		if (checked) {
			setIsIncluded(true);
		}
		props.meeting.updateCustomFieldMetaData(props.field.name, checked || isIncluded, checked);
	};

	return (
		<div className={css(styleSheet.advancedSettingsRow)}>
			<div className={css(styleSheet.checkboxContainer)}>
				<Checkbox
					checked={isIncluded}
					id={`meeting-config-include-${props.field.name}`}
					onChange={onIncludeCustomFieldChanged}
				>
					<span className={css(styleSheet.checkboxText)}>{props.field.label}</span>
				</Checkbox>
			</div>
			<Toggle
				id={`meeting-config-require-${props.field.name}`}
				className={css(styleSheet.customFieldToggle)}
				isOn={isRequired}
				onToggleCheckChanged={onRequireChanged}
				uncheckedColor='#ccc'
			/>
		</div>
	);
};

export const Availability: React.FC<IProps> = observer(({ meeting }) => {
	const logger = useEventLogging();
	const toaster = useToaster();
	const userSession = useUserSession();
	const [meetingDuration, setMeetingDuration] = useState(
		meetingDurationOptions.find(m => equal(m.dataContext, meeting?.duration)) || meetingDurationOptions[0]
	);
	const [requireCompanyOnMainParticipant, setRequireCompanyOnMainParticipant] = useState(
		meeting?.requireCompanyOnMainParticipant
	);
	const [commentsRequired, setCommentsRequired] = useState(meeting?.commentsRequired);
	const [commentsLabel, setCommentsLabel] = useState(meeting?.commentsLabel);
	const [meetingRange, setMeetingRange] = useState<MeetingDateRange>(meeting?.meetingDateRadio);
	const [rangeFutureDays, setRangeFutureDays] = useState(
		meeting?.atMostDays <= 180 ? meeting?.atMostDays : MeetingConfigViewModel.FUTURE_DAYS_TO_LOOK_AHEAD
	);
	const [datePickerFromOpen, setDatePickerFromOpen] = useLambda(false);
	const [datePickerToOpen, setDatePickerToOpen] = useLambda(false);
	const [datePickerRange, setDatePickerRange] = useState<{
		from: Date;
		to: Date;
	}>(meeting?.defaultDateRange);
	const [datePickerToText, setDatePickerToText] = useLambda(moment(meeting?.defaultDateRange?.to).format('YYYY-MM-DD'));
	const [datePickerFromText, setDatePickerFromText] = useLambda(
		moment(meeting?.defaultDateRange?.from).format('YYYY-MM-DD')
	);
	const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
	const [meetingAvailabilitySlotStartInMinutesIntervals, setMeetingAvailabilitySlotStartInMinutesIntervals] = useState<
		IMeetingAvailabilitySlots[]
	>([...meetingAvailabilitySlotStartInMinutes]);

	const meetingBufferTimeOptions = getMeetingBufferTimeOptions();

	const bufferBeforeIntervalOption = meetingBufferTimeOptions.find(
		o => o.dataContext === meeting?.bufferBeforeInterval
	);
	const bufferAfterIntervalOption = meetingBufferTimeOptions.find(o => o.dataContext === meeting?.bufferAfterInterval);

	const [selectedBeforeMeetingBufferTime, setBeforeMeetingBufferTime] =
		// @ts-ignore
		useState<ISelectOption<string>>(bufferBeforeIntervalOption);
	const [selectedAfterMeetingBufferTime, setAfterMeetingBufferTime] =
		// @ts-ignore
		useState<ISelectOption<string>>(bufferAfterIntervalOption);

	const [beforeMeetingBuffer, setBeforeMeetingBuffer] = useState(!!bufferBeforeIntervalOption);
	const [afterMeetingBuffer, setAfterMeetingBuffer] = useState(!!bufferAfterIntervalOption);

	const hasCustomContactFields = userSession.account?.additionalInfo?.hasCustomContactFields;
	const customFieldsQuery = useContactCustomFields({
		refetchOnWindowFocus: false,
		enabled: hasCustomContactFields,
	});

	useEffect(() => {
		const nextMeetingAvailabilitySlotIntervals = [...meetingAvailabilitySlotStartInMinutes];
		if (meeting?.slotStartInMinutes == null || meeting.slotStartInMinutes.length === 0) {
			return;
		}
		meetingAvailabilitySlotStartInMinutes.forEach((slot, index) => {
			nextMeetingAvailabilitySlotIntervals[index].selected =
				meeting.slotStartInMinutes.find(s => s === slot.value) != null;
		});
		setShowAdvancedSettings(meeting?.slotStartInMinutes?.length > 2);
		setMeetingAvailabilitySlotStartInMinutesIntervals(nextMeetingAvailabilitySlotIntervals);
	}, [meeting]);
	useEffect(() => {
		meeting.setAsEditing()?.catch((err: IOperationResultNoValue) => {
			logger.logApiError('MeetingConfigSetAsEditing-Error', err);
			// do not need to show user that this failed...
		});
		meeting.getAutomationChoices();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onDurationChange = useCallback(
		(option: ISelectOption<IMeetingDuration>) => {
			setMeetingDuration(option);
			meeting.duration = option.dataContext;
		},
		[meeting]
	);

	const onRequireCompanyOnMainParticipantChange = (value: boolean) => {
		setRequireCompanyOnMainParticipant(value);
		meeting.requireCompanyOnMainParticipant = value;
	};

	const onCommentsRequiredChange = (value: boolean) => {
		setCommentsRequired(value);
		meeting.commentsRequired = value;
	};

	const onCommentsLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setCommentsLabel(value);
	};

	const onCommentsLabelBlur = () => {
		meeting.commentsLabel = commentsLabel;
	};

	const onRangeChange = (range: MeetingDateRange) => () => {
		setMeetingRange(range);

		switch (range) {
			case MeetingDateRange.FutureDays:
				meeting.setAtMostInterval(rangeFutureDays);
				break;
			case MeetingDateRange.DateRange:
				meeting.setDateRange(datePickerRange.from, datePickerRange.to);
				break;
			case MeetingDateRange.Indefinitely:
				meeting.setAtMostInterval(MeetingConfigViewModel.INDEFINITELY_IN_DAYS);
				break;
			default:
				break;
		}
	};

	const onRangeFutureDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const num = parseInt(e.target.value, 10);
		setRangeFutureDays(num);
	};

	const onOpenDatePickerFrom = () => setDatePickerFromOpen(true);

	const onOpenDatePickerTo = () => setDatePickerToOpen(true);

	const onCloseDatePickerFrom = () => {
		setDatePickerFromOpen(false);
		const to = datePickerRange.to;
		let from = datePickerRange.from;
		const today = moment();
		if (moment(from) < today) {
			from = today.toDate();
			setDatePickerRange({ from, to });
			setDatePickerFromText(today.format('YYYY-MM-DD'));
		}
		meeting.setDateRange(from, to);
	};

	const onCloseDatePickerTo = () => {
		setDatePickerToOpen(false);
		meeting.setDateRange(datePickerRange.from, datePickerRange.to);
	};

	const onDayPickerFromClick = (from: Date) => {
		const to = from < datePickerRange.to ? datePickerRange.to : from;
		setDatePickerRange({ from, to });
		setDatePickerFromText(moment(from).format('YYYY-MM-DD'));
	};

	const onDatePickerFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { to } = datePickerRange;
		setDatePickerFromText(e.target.value);
		const parsed = moment(e.target.value);
		if (parsed.isValid()) {
			setDatePickerRange({ from: parsed.toDate(), to });
		}
	};

	const onDayPickerToClick = (to: Date) => {
		const { from } = datePickerRange;
		setDatePickerRange({ from, to });
		setDatePickerToText(moment(to).format('YYYY-MM-DD'));
	};

	const onDatePickerToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { from } = datePickerRange;
		setDatePickerToText(e.target.value);
		const parsed = moment(e.target.value);
		if (parsed.isValid()) {
			setDatePickerRange({ from, to: parsed.toDate() });
		}
	};

	const maxDate = new Date();
	maxDate.setDate(maxDate.getDate() + MeetingConfigViewModel.FUTURE_DAYS_TO_LOOK_AHEAD);

	const toggleAdvancedSettings = () => setShowAdvancedSettings(!showAdvancedSettings);
	const handleMeetingAvailabilitySlotChange = (slot: number) => {
		const nextState = [...meetingAvailabilitySlotStartInMinutesIntervals];
		let slots: number[] = [];
		nextState[slot].selected = !nextState[slot].selected;
		slots = getSlots(nextState);
		if (slots.length === 0) {
			// @ts-ignore
			toaster.push({
				message: 'Error start time slots cannot be empty.',
				type: 'errorMessage',
			});
			nextState[slot].selected = true;
			slots = getSlots(nextState);
		}
		meeting.slotStartInMinutes = slots;
		setMeetingAvailabilitySlotStartInMinutesIntervals(nextState);
	};

	const getSlots = (state: IMeetingAvailabilitySlots[]) => {
		const slots: number[] = [];
		state.forEach(x => {
			if (x.selected) {
				slots.push(x.value);
			}
		});
		return slots;
	};

	const defaultFromDate = new Date(new Date().setHours(0, 0, 0));
	const defaultToDate = new Date(new Date().setHours(23, 59, 59));

	const handleBufferBeforeIntervalCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
		const checked = event.target.checked;
		setBeforeMeetingBuffer(checked);
		if (!checked) {
			// @ts-ignore
			setBeforeMeetingBufferTime(null);
			meeting.setBufferBeforeInterval('');
			return;
		}
		setBeforeMeetingBufferTime(meetingBufferTimeOptions[1]);
		meeting.setBufferBeforeInterval(meetingBufferTimeOptions[1].dataContext);
	};

	const handleBufferBeforeIntervalSelection = (option: ISelectOption<string>) => {
		setBeforeMeetingBufferTime(option);
		meeting.setBufferBeforeInterval(option.dataContext);
	};

	const handleBufferAfterIntervalCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
		const checked = event.target.checked;
		setAfterMeetingBuffer(checked);
		if (!checked) {
			// @ts-ignore
			setAfterMeetingBufferTime(null);
			meeting.setBufferAfterInterval('');
			return;
		}
		setAfterMeetingBufferTime(meetingBufferTimeOptions[1]);
		meeting.setBufferAfterInterval(meetingBufferTimeOptions[1].dataContext);
	};

	const handleBufferAfterIntervalSelection = (option: ISelectOption<string>) => {
		setAfterMeetingBufferTime(option);
		meeting.setBufferAfterInterval(option.dataContext);
	};

	return (
		<div className={css(styleSheet.col)}>
			<div className={css(styleSheet.card)}>
				<h3 className={css(styleSheet.sectionTitle)}>
					Availability
					<button
						className={css(baseStyleSheet.ctaButtonSmall, styleSheet.advancedSettingsButton)}
						onClick={toggleAdvancedSettings}
					>
						Advanced Settings
					</button>
				</h3>

				<div className={css(styleSheet.sectionWrapper)}>
					<p className={css(styleSheet.label)}>Meeting Duration</p>
					<Select
						styles={[styleSheet.duration]}
						options={meetingDurationOptions}
						onOptionClick={onDurationChange}
						selectedOption={meetingDuration}
					/>
				</div>
				<div className={css(styleSheet.spacer)} />
				<div className={css(styleSheet.sectionWrapper)}>
					<p className={css(styleSheet.label)}>Meeting Date Range</p>
					<div className={css(styleSheet.subLabel)}>Invitees can schedule up to...</div>
					<RadioButton
						checked={meetingRange === MeetingDateRange.FutureDays}
						className={css(styleSheet.radioBig)}
						disabled={meeting?.isBusy || meeting?.isSaving}
						id='meeting-config-range-future-days'
						name='meeting-config-range'
						onChange={onRangeChange(MeetingDateRange.FutureDays)}
					>
						<div className={css(styleSheet.futureDays)}>
							<TextInput
								className={css(styleSheet.futureDaysText)}
								inputId='range-future-days-input'
								min={14}
								max={MeetingConfigViewModel.FUTURE_DAYS_TO_LOOK_AHEAD}
								onChange={onRangeFutureDaysChange}
								onBlur={onRangeChange(MeetingDateRange.FutureDays)}
								step={1}
								type='number'
								value={rangeFutureDays}
							/>
							<span>days into the future</span>
						</div>
					</RadioButton>
					<RadioButton
						checked={meetingRange === MeetingDateRange.DateRange}
						className={css(styleSheet.radioBig)}
						disabled={meeting?.isBusy || meeting?.isSaving}
						id='meeting-config-range-date-range'
						name='meeting-config-range'
						onChange={onRangeChange(MeetingDateRange.DateRange)}
					>
						<div className={css(styleSheet.futureDays)}>
							<span className={css(styleSheet.futureDaysText)}>Within specific date range</span>
							{meetingRange === MeetingDateRange.DateRange && (
								<div className={css(styleSheet.dateRangeContainer)}>
									<Popover
										anchor={
											<TextInput
												className={css(styleSheet.dateRangeText)}
												inputId='range-date-range-from'
												onChange={onDatePickerFromChange}
												onClick={onOpenDatePickerFrom}
												type='date'
												value={datePickerFromText}
											/>
										}
										dismissOnClickOutside={true}
										isOpen={datePickerFromOpen}
										onRequestClose={onCloseDatePickerFrom}
										type={PopoverType.white}
										preferredPlacement='below'
									>
										<DayPicker
											defaultSelectedDay={defaultFromDate}
											disabledDays={[{ after: maxDate }]}
											month={datePickerRange.from}
											onDayClick={onDayPickerFromClick}
											selectedDays={datePickerRange.from}
										/>
									</Popover>
									<span className={css(styleSheet.futureDaysText)}>-</span>
									<Popover
										anchor={
											<TextInput
												className={css(styleSheet.dateRangeText)}
												inputId='range-date-range-to'
												onChange={onDatePickerToChange}
												onClick={onOpenDatePickerTo}
												type='date'
												value={datePickerToText}
											/>
										}
										dismissOnClickOutside={true}
										isOpen={datePickerToOpen}
										onRequestClose={onCloseDatePickerTo}
										type={PopoverType.white}
										preferredPlacement='below'
									>
										<DayPicker
											defaultSelectedDay={defaultToDate}
											disabledDays={[{ after: maxDate }]}
											month={datePickerRange.to}
											onDayClick={onDayPickerToClick}
											selectedDays={datePickerRange.to}
										/>
									</Popover>
								</div>
							)}
						</div>
					</RadioButton>
					<RadioButton
						checked={meetingRange === MeetingDateRange.Indefinitely}
						className={css(styleSheet.radioBig)}
						disabled={meeting?.isBusy || meeting?.isSaving}
						id='meeting-config-range-indefinitely'
						name='meeting-config-range'
						onChange={onRangeChange(MeetingDateRange.Indefinitely)}
					>
						<span>Indefinitely into the future</span>
					</RadioButton>
				</div>
				<div className={css(styleSheet.sectionWrapper)}>
					<p className={css(styleSheet.label)}>Meeting Buffer</p>
					<div className={css(styleSheet.subLabel)}>Setup buffer time before or after your meeting</div>
					<Checkbox
						className={css(styleSheet.checkbox)}
						checked={beforeMeetingBuffer}
						id='before-meeting-buffer'
						onChange={handleBufferBeforeIntervalCheckbox}
					>
						<Select
							disabled={!beforeMeetingBuffer}
							onOptionClick={handleBufferBeforeIntervalSelection}
							options={meetingBufferTimeOptions}
							selectedOption={selectedBeforeMeetingBufferTime}
							// @ts-ignore
							styles={[styleSheet.timeDropdown, !beforeMeetingBuffer ? styleSheet.timeDropdownDisabled : null]}
						/>
						<span
							className={css(styleSheet.checkboxText, !beforeMeetingBuffer ? styleSheet.checkboxTextDisabled : null)}
						>
							minutes&nbsp;
							<u>
								<em>before</em>
							</u>
							&nbsp;meeting
						</span>
					</Checkbox>
					<Checkbox
						className={css(styleSheet.checkbox)}
						checked={afterMeetingBuffer}
						id='after-meeting-buffer'
						onChange={handleBufferAfterIntervalCheckbox}
					>
						<Select
							disabled={!afterMeetingBuffer}
							onOptionClick={handleBufferAfterIntervalSelection}
							options={meetingBufferTimeOptions}
							selectedOption={selectedAfterMeetingBufferTime}
							// @ts-ignore
							styles={[styleSheet.timeDropdown, !afterMeetingBuffer ? styleSheet.timeDropdownDisabled : null]}
						/>
						<span
							className={css(styleSheet.checkboxText, !afterMeetingBuffer ? styleSheet.checkboxTextDisabled : null)}
						>
							minutes&nbsp;
							<u>
								<em>after</em>
							</u>
							&nbsp;meeting
						</span>
					</Checkbox>
				</div>
				<div
					className={css(
						styleSheet.sectionWrapper,
						styleSheet.advancedSettingsWrapperHidden,
						showAdvancedSettings ? styleSheet.advancedSettingsWrapper : undefined
					)}
				>
					{showAdvancedSettings && (
						<div className={css(showAdvancedSettings ? styleSheet.animateIn : styleSheet.animateOut)}>
							<h3 className={css(styleSheet.advancedSettingsTitle)}>Advanced Settings</h3>
							<p className={css(styleSheet.label)}>Only schedule meetings in the following time slots:</p>
							<div className={css(styleSheet.advancedSettingsIntervals)}>
								{meetingAvailabilitySlotStartInMinutesIntervals.map((x, idx) => {
									return (
										<>
											<Toggle
												id='max-same-day-meetings-toggle'
												isOn={x.selected}
												onToggleCheckChanged={() => handleMeetingAvailabilitySlotChange(idx)}
												uncheckedColor='#ccc'
												className={css(styleSheet.toggle, styleSheet.toggleAdjustedMargin)}
											/>
											<span className={css(styleSheet.checkboxText)}>
												:{x.value === 0 && x.value}
												{x.value}
											</span>
										</>
									);
								})}
							</div>
							<div className={css(styleSheet.spacer)} />
							<div className={css(styleSheet.advancedSettingsRow)}>
								<p className={css(styleSheet.label)}>Additional Fields</p>
								<span className={css(styleSheet.advancedSettingsRequiredLabel)}>Required Field</span>
							</div>
							<div>
								{/* @ts-ignore */}
								{/* @ts-ignore */}
								{userSession.account.features.meetingScheduler.allowCompanyNameRequired ? (
									<>
										<div className={css(styleSheet.advancedSettingsRow)}>
											<p className={css(styleSheet.advancedSettingsLabel)}>Company name for invitee</p>
											<Toggle
												id='meeting-config-require-company-on-main-participant'
												isOn={requireCompanyOnMainParticipant}
												onToggleCheckChanged={onRequireCompanyOnMainParticipantChange}
												uncheckedColor='#ccc'
											/>
										</div>
									</>
								) : null}
								<div className={css(styleSheet.advancedSettingsRow)}>
									<p className={css(styleSheet.advancedSettingsLabel)}>Comments</p>
									<Toggle
										id='meeting-config-require-comments'
										isOn={commentsRequired}
										onToggleCheckChanged={onCommentsRequiredChange}
										uncheckedColor='#ccc'
									/>
								</div>
								<p className={css(styleSheet.commentsInputLabel)}>Comment Instructions</p>
								<TextInput
									className={css(styleSheet.commentsInput)}
									inputId='meeting-config-name-input'
									onChange={onCommentsLabelChange}
									onBlur={onCommentsLabelBlur}
									type='text'
									placeholder='Add instructions'
									value={commentsLabel}
								/>
							</div>
							{customFieldsQuery.data?.fields?.length > 0 ? (
								<>
									<div className={css(styleSheet.spacer)} />
									<div className={css(styleSheet.advancedSettingsRow)}>
										<p className={css(styleSheet.customFieldslabel)}>Custom Fields</p>
										<span className={css(styleSheet.advancedSettingsRequiredLabel)}>Required Field</span>
									</div>
								</>
							) : null}
							{customFieldsQuery.data?.fields?.map(field => (
								<CustomFieldLayout
									key={field.name}
									meeting={meeting}
									field={field}
									metadata={meeting?.contactFormFields?.find(i => i.fieldName === field.name)}
								/>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
});
