import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {},
	newCompany: {
		height: 40,
	},
	right: {
		flexgrow: 1,
		marginLeft: 20,
		width: 'calc(100vw - 115px)',
	},
	rightCard: {
		background: 'white',
		borderRadius: 3,
		boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.1)',
		fontSize: 14,
		height: 'calc(100vh - 197px)',
	},
});
