import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	body: {
		position: 'relative',
		textAlign: 'left',
		zIndex: 0, // important. establish a new stacking context such that the placeholder doesn't appear above the filter dropdown
		...CssSelectors.allDescendants(
			{
				zIndex: 1,
			},
			'.loading-spinner'
		),
	},
	item: {
		':last-child': {
			marginBottom: 35,
		},
		':not(:first-child)': {
			marginTop: 15,
		},
		...CssSelectors.allDescendants(
			{
				marginRight: 10,
				width: 'calc(100% - 80px)',
			},
			'.action-item-content'
		),
	},
	itemContent: {
		marginRight: 10,
		width: 'calc(100% - 80px)',
	},
});
