import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

export interface IQuickActionCardProps
	extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	graphic?: React.ReactNode;
	description?: React.ReactNode;
}

export const QuickAction: React.FC<IQuickActionCardProps> = ({ graphic, description, className, ...restProps }) => {
	return (
		<button {...restProps} className={`${className || ''} ${css(styleSheet.quickAction)}`}>
			{!!graphic && <div>{graphic}</div>}
			{!!description && <div>{description}</div>}
		</button>
	);
};
