import { useEventLogging } from '../../../../../models/Logging';
import { useFullscreenModal, useUserSession } from '../../../../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import * as React from 'react';

export function useLaunchIntegrationOwnership({
	queryCommand,
	eventLoggingCategory,
}: {
	queryCommand: Api.IQueryStringCommand;
	eventLoggingCategory: string;
}) {
	const isCommandOwnership = queryCommand?.command === 'ownership';
	const [showIntegrationOwnershipModal, setShowIntegrationOwnershipModal] = React.useState(false);
	const userSession = useUserSession();
	const fullscreenModal = useFullscreenModal();
	const { logEvent } = useEventLogging(eventLoggingCategory);
	React.useEffect(() => {
		if (queryCommand?.command === 'ownership' && userSession) {
			const integrations = userSession.account.toJs().integrations;
			if (integrations?.ams360?.invalidOwnerships?.length || integrations?.hawkSoft?.invalidOwnerships?.length) {
				// @ts-ignore
				fullscreenModal.history.push('/integrations/ownership');
			} else {
				setShowIntegrationOwnershipModal(true);
			}
		}
	}, [queryCommand, logEvent, fullscreenModal, userSession]);
	return {
		onCloseIntegrationOwnershipModal: () => setShowIntegrationOwnershipModal(false),
		showIntegrationOwnershipModal: isCommandOwnership ? showIntegrationOwnershipModal : false,
	};
}
