import { StyleSheet } from 'aphrodite';
import { header } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	container: {
		padding: '0 16px',
	},
	row: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 12,
		fontSize: 12,
	},
	box: {
		padding: 8,
		width: 100,
		display: 'flex',
		flexDirection: 'column',
	},
	label: {
		color: header,
		fontSize: 12,
		marginBottom: 4,
	},
});
