import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useEventLogging } from '../../../../models/Logging';
import { useErrorMessages, useToaster, useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	invalidateIntegrationStatusQuery,
	useDisableIntegrationMutation,
	useIntegrationStatusQuery,
} from '../../../../queries';
import { IntegrationOAuthStep, RedtailSignInViewModel } from '../../../../viewmodels/AppViewModels';
import redtailLogo from '../../../assets/logo_redtail.png';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { Popover, PopoverType } from '../../../components/Popover';
import { DangerIcon } from '../../../components/svgs/icons/DangerIcon';
import { InProgressIcon } from '../../../components/svgs/icons/InProgressIcon';
import { bs } from '../../../styles/styles';
import { getOAuthStepFromIntegrationStatus } from '../hooks/useManageIntegrationConnection';
import { styleSheet as integrationsStyleSheet } from '../styles';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

function _Redtail({ className, styles }: IProps) {
	const userSession = useUserSession();
	const { logEvent } = useEventLogging('RedtailIntegrations');
	const errorMessages = useErrorMessages();
	const [signInVM] = React.useState(new RedtailSignInViewModel(userSession));
	const toaster = useToaster();
	const history = useHistory();
	const disableIntegrationMutation = useDisableIntegrationMutation({
		onError: error => {
			toaster.push({
				message: error.systemMessage,
				type: 'errorMessage',
			});
		},
		onSuccess: () => {
			toaster.push({
				message: 'Integration disabled successfully',
				type: 'successMessage',
			});
			invalidateIntegrationStatusQuery(Api.ConfigurableIntegrationType.Redtail);
			history.push('/integrations');
		},
	});
	const [integrationStatusQueryEnabled, setIntegrationStatusQueryEnabled] = React.useState(
		!userSession.pendingActions.includes(Api.PendingActions.ConnectRedtail)
	);
	const integrationStatusQuery = useIntegrationStatusQuery({
		enabled: integrationStatusQueryEnabled,
		provider: Api.ConfigurableIntegrationType.Redtail,
	});
	const oauthStep: IntegrationOAuthStep | null = integrationStatusQuery.data
		? getOAuthStepFromIntegrationStatus(integrationStatusQuery.data)
		: null;
	const onDisable = () => {
		disableIntegrationMutation.mutate(Api.ConfigurableIntegrationType.Redtail);
	};
	const [focusedField, setFocusedField] = React.useState<'username' | 'password' | null>(null);
	const [signingIn, setSigningIn] = React.useState(false);
	const usernameInput = React.useRef<HTMLInputElement>(null);
	const passwordInput = React.useRef<HTMLInputElement>(null);
	const connect = async () => {
		setSigningIn(true);
		try {
			await signInVM.submit();
			logEvent('RedtailSignIn', {});
			setIntegrationStatusQueryEnabled(true);
		} catch (error) {
			errorMessages.pushApiError(error);
			logEvent('RedtailSignInError', {
				validationErrorMessage: signInVM.validationErrorMessage,
			});
		} finally {
			setSigningIn(false);
		}
	};
	const getPasswordField = () => {
		const editingPassword = focusedField === 'password' || signInVM.password;
		const hasError = signInVM.hasErrorMessage('password');
		return (
			<div className={css(styleSheet.inputWrapper)}>
				<span className={css(styleSheet.signInFormFieldLabel)}>Password</span>
				<div
					className={css(styleSheet.signInFormField, editingPassword && styleSheet.editingSignInFormField)}
					onClick={() => passwordInput.current?.focus()}
				>
					<input
						autoComplete='password'
						id='login-password'
						onBlur={() => setFocusedField(null)}
						onChange={ev => (signInVM.password = ev.target.value)}
						onFocus={() => setFocusedField('password')}
						onKeyDown={ev => {
							if (ev.keyCode === 13) {
								connect();
							}
						}}
						ref={passwordInput}
						type='password'
						value={signInVM.password || ''}
					/>
					{hasError && <DangerIcon backgroundColor='#fff' />}
				</div>
			</div>
		);
	};
	const getUsernameField = () => {
		const editingUsername = focusedField === 'username' || signInVM.username;
		const hasError = signInVM.hasErrorMessage('username');

		return (
			<div className={css(styleSheet.inputWrapper)}>
				<span className={css(styleSheet.signInFormFieldLabel)}>Username</span>
				<div
					className={css(styleSheet.signInFormField, editingUsername && styleSheet.editingSignInFormField)}
					onClick={() => usernameInput.current?.focus()}
				>
					<input
						autoComplete='off'
						autoFocus={true}
						id='login-username'
						onBlur={() => setFocusedField(null)}
						onChange={ev => (signInVM.username = ev.target.value)}
						onFocus={() => setFocusedField('username')}
						ref={usernameInput}
						type='text'
						value={signInVM.username || ''}
					/>
					{hasError && <DangerIcon backgroundColor='#fff' />}
				</div>
			</div>
		);
	};
	const renderSignIn = () => {
		const errorMessage = signInVM.getErrorMessage('global');

		return (
			<div>
				<p className={css(integrationsStyleSheet.bodyText)}>To get started, please sign into your Redtail account.</p>
				<div className={css(integrationsStyleSheet.signInContainer)}>
					<figure className={css(integrationsStyleSheet.logo, styleSheet.logoCenter)}>
						<img src={redtailLogo} alt='Redtail Logo' />
					</figure>
					{signInVM.isBusy || signInVM.isLoading ? (
						<LoadingSpinner type='large' className={css(bs.absoluteCenter)} />
					) : (
						<>
							{errorMessage && <div className={css(styleSheet.signInFormError)}>{errorMessage}</div>}
							<div>
								<Popover
									className='login-form-field-popover'
									anchor={getUsernameField()}
									isOpen={signInVM.hasErrorMessage('username')}
									type={PopoverType.error}
									contentClassName='login-error-popover'
									preferredPlacement='right'
								>
									<span>{signInVM.getErrorMessage('username')}</span>
								</Popover>
								<Popover
									className='login-form-field-popover'
									anchor={getPasswordField()}
									isOpen={signInVM.hasErrorMessage('password')}
									type={PopoverType.error}
									contentClassName='login-error-popover'
									preferredPlacement='right'
								>
									<span>{signInVM.getErrorMessage('password')}</span>
								</Popover>
							</div>
							<div>
								<button className={css(bs.ctaButton, styleSheet.connectCta)} onClick={connect}>
									Connect
								</button>
							</div>
						</>
					)}
				</div>
			</div>
		);
	};
	const renderInProgress = () => {
		return (
			<div className={css(styleSheet.inProgressContainer)}>
				<div className={css(styleSheet.inProgressIconContainer)}>
					<InProgressIcon />
				</div>
				<p className={css(styleSheet.inProgressText)}>
					Sync in progress... this could take a while. Feel free to navigate away from this page.
				</p>
				<div>
					<Link to='/people' className={css(bs.ctaButton, styleSheet.ctaButton)}>
						Go to Contacts
					</Link>
				</div>
			</div>
		);
	};
	const renderConnected = () => {
		return (
			<div className={css(styleSheet.synced)}>
				<p>
					We&apos;ve synced your data. Your Redtail data will now show up in the{' '}
					<Link to='/people' className={css(styleSheet.link)}>
						Contacts
					</Link>{' '}
					section.
				</p>
				<p>
					If you wish to disable this integration for you user,{' '}
					<span onClick={onDisable} className={css(styleSheet.link)}>
						click here to disable.
					</span>
				</p>
			</div>
		);
	};
	const renderContent = () => {
		if (
			oauthStep === IntegrationOAuthStep.SignIn ||
			userSession.pendingActions.includes(Api.PendingActions.ConnectRedtail)
		) {
			return renderSignIn();
		}
		switch (oauthStep) {
			case IntegrationOAuthStep.InProgress:
				return renderInProgress();
			case IntegrationOAuthStep.Connected:
				return renderConnected();
			default:
				return null;
		}
	};
	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} integrations-container ${className || ''}`}>
			<div className={css(integrationsStyleSheet.logo, integrationsStyleSheet.headerLogo)}>
				<img src={redtailLogo} alt='Redtail Logo' />
			</div>
			<p className={css(integrationsStyleSheet.bodyText, integrationsStyleSheet.description)}>
				Levitate can sync with Redtail, and pull client information, including names, email, phone number, birthdays,
				anniversaries, and more. We can also push our notes into Redtail.
			</p>
			{(integrationStatusQueryEnabled && integrationStatusQuery.status !== 'success') || signingIn ? (
				<LoadingSpinner type='large' className={css(bs.absoluteCenter)} />
			) : (
				renderContent()
			)}
			{userSession.isBusy ? <LoadingSpinner type='large' className={css(bs.absoluteCenter)} /> : null}
		</div>
	);
}

const Redtail = observer(_Redtail);
export { Redtail };
