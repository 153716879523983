import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import { brandPrimary } from '../../../styles/colors';
import { INavBarLink } from '../../NavBarLink';
import { UserProfileNav } from '../../UserProfileNav';
import { HeartIcon } from '../../svgs/icons/HeartIcon';
import { SvgIcon } from '../../svgs/icons/SvgIcon';
import { AppBarContext } from './context';
import { styleSheet } from './styles';

export const AppBarNavigationItemPortalDestinationId = 'AppBarNavigationItemPortalDestinationId';

interface IProps extends IUserSessionComponentProps {
	className?: string;
	style?: React.CSSProperties;
	coffeeLinks?: INavBarLink[];
}

class _AppBar extends React.Component<IProps> {
	public render() {
		const { style, coffeeLinks } = this.props;
		return (
			<AppBarContext.Consumer>
				{context => {
					return (
						<div className={`${css(styleSheet.appBar, ...(context?.appBarStyles || []))}`} style={style}>
							<div className={`${css(styleSheet.appBarContent, ...(context?.appBarContentStyles || []))}`}>
								<div className={`${css(styleSheet.leftContainer)}`}>
									<HeartIcon className={css(styleSheet.heartIcon)} filled width={30} height={30} />
									<h1 className={css(styleSheet.welcomeMessage)}>Welcome to the happiness platform</h1>
									<a
										className={css(styleSheet.helpLink)}
										href='https://help.levitate.ai'
										target='_blank'
										rel='noreferrer'
									>
										<SvgIcon height={32} width={64}>
											<rect fill={brandPrimary} fillRule='nonzero' x='0' y='0' width='64' height='32' rx='16' />
											<text transform='translate(17 21)' className={css(styleSheet.helpText)}>
												Help
											</text>
										</SvgIcon>
									</a>
								</div>
								<div className={css(styleSheet.rightAccessoryContainer)}>
									<UserProfileNav className={`${css(styleSheet.userProfileNav)}`} coffeeLinks={coffeeLinks} />
								</div>
							</div>
						</div>
					);
				}}
			</AppBarContext.Consumer>
		);
	}
}

const AppBarAsObserver = observer(_AppBar);
export const AppBar = inject(UserSessionViewModelKey)(AppBarAsObserver);
