import { IModalContext, ModalChildComponentContextKey } from '@AppModels/.';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '@AppModels/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { baseStyleSheet } from '../../../styles/styles';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { LoadingSpinner } from '../../LoadingSpinner';
import { asModalComponent } from '../../Modal';
import {
	ISimpleAutoCompleteSearchFieldEvent,
	ISimpleAutoCompleteSearchFieldItemSelectionEvent,
	SimpleAutoCompleteSearchField,
} from '../../autocomplete/SimpleAutoCompleteSearchField';
import { AutomationConfirmation, AutomationStartedNotification } from './presentation';
import { styleSheet } from './styles';

enum TagModalStep {
	ShowingTagSearch,
	ShowingAutomationOption,
	ShowingAutomationConfirmation,
}

interface IProps
	extends IUserSessionComponentProps,
		IModalContext<string>,
		IEventLoggingComponentProps,
		IErrorMessageComponentProps {
	className?: string;
	contacts?: Api.ContactsViewModel;
	onAddTagButtonClicked?(tagValue: string, e: React.MouseEvent<HTMLElement>): void;
}

interface IState {
	tagModalStep?: TagModalStep;
}

class _ContactsAddTag extends React.Component<IProps, IState> {
	// @ts-ignore
	@observable private mSelectedTag: string;
	// @ts-ignore
	@observable.ref private mSystemJob: Api.SystemJobViewModel;
	@observable.ref private automationTemplate?: Api.AutomationTemplateViewModel;
	// @ts-ignore
	private mMounted: boolean;
	private mProgressTimeoutHandle: any;

	constructor(props: IProps) {
		super(props);
		this.state = {
			tagModalStep: TagModalStep.ShowingTagSearch,
		};
	}

	public componentDidMount() {
		this.mMounted = true;
	}

	public componentWillUnmount() {
		this.mMounted = false;
		if (this.mProgressTimeoutHandle) {
			clearTimeout(this.mProgressTimeoutHandle);
			this.mProgressTimeoutHandle = null;
		}
	}

	public render() {
		const { className, contacts } = this.props;
		const { tagModalStep } = this.state;
		return (
			<div className={`${css(styleSheet.container)} contacts-add-tag ${className || ''}`}>
				<div className={css(styleSheet.header)}>
					<DeprecatedCloseButton disabled={!!this.mSystemJob || contacts?.isBusy} onClick={this.onCloseClicked} />
				</div>
				{tagModalStep !== TagModalStep.ShowingAutomationOption &&
					tagModalStep !== TagModalStep.ShowingAutomationConfirmation && (
						<div className={css(styleSheet.title)}>Add Tag</div>
					)}
				{this.mSystemJob ? (
					<>
						<div className={css(styleSheet.taggingMessage)}>
							<div>Tagging contacts...</div>
							<LoadingSpinner type='small' />
						</div>
					</>
				) : (
					<>
						{tagModalStep === TagModalStep.ShowingTagSearch && (
							<div>
								<SimpleAutoCompleteSearchField
									disabled={!!this.mSystemJob}
									onChange={this.onSearchFieldChanged}
									onClear={this.onClearButtonClicked}
									// @ts-ignore
									onItemSelected={this.onTagSelected}
									onKeyDown={this.onSearchFieldKeyDown}
									pageSize={10}
									placeholder='Search existing or enter new tag'
									renderNoResultsItem={false}
									resultsLimit={10}
									style={styleSheet.searchField}
									type={Api.ResourceAutoCompleteViewModelType.AccountTag}
								/>
								<button
									className={css(baseStyleSheet.ctaButton, styleSheet.submitButton)}
									disabled={!!this.mSystemJob || contacts?.isBusy}
									onClick={this.onSubmitClicked}
								>
									<span>Add</span>
								</button>
							</div>
						)}
						{tagModalStep === TagModalStep.ShowingAutomationOption && (
							<AutomationConfirmation
								contacts={contacts}
								// @ts-ignore
								automationTemplate={this.automationTemplate}
								onAutomationConfirmed={this.onAutomationConfirmationRequest}
							/>
						)}
						{tagModalStep === TagModalStep.ShowingAutomationConfirmation && (
							<AutomationStartedNotification onAutomationConfirmClosed={this.onConfirmationClose} />
						)}
					</>
				)}
			</div>
		);
	}

	private onConfirmationClose = () => {
		const {
			// @ts-ignore
			parentModal: { onRequestClose },
		} = this.props;
		this.setState({ tagModalStep: TagModalStep.ShowingTagSearch });
		if (onRequestClose) {
			onRequestClose(this.mSelectedTag, false);
		}
	};

	private onAutomationConfirmationRequest = (result?: Api.ICreateAutomationRequest, cancel?: boolean) => {
		const {
			// @ts-ignore
			parentModal: { onRequestClose },
		} = this.props;
		if (!result && cancel) {
			this.setState({ tagModalStep: TagModalStep.ShowingTagSearch });
			if (onRequestClose) {
				onRequestClose(this.mSelectedTag, false);
			}
		} else {
			const { errorMessages, logApiError } = this.props;
			// @ts-ignore
			// @ts-ignore
			const promise = this.automationTemplate.startForContacts(result);
			if (promise) {
				promise
					.then(startContactsResult => {
						// @ts-ignore
						if (startContactsResult.success) {
							this.setState({ tagModalStep: TagModalStep.ShowingAutomationConfirmation });
						} else {
							// @ts-ignore
							logApiError('StartAutomationBulkTag', startContactsResult);
							// @ts-ignore
							// @ts-ignore
							errorMessages.pushApiError(startContactsResult);
						}
					})
					.catch((error: Api.IOperationResultNoValue) => {
						// @ts-ignore
						logApiError('StartAutomationBulkTag', error);
						// @ts-ignore
						errorMessages.pushApiError(error);
					});
			}
		}
	};

	private onCloseClicked = () => {
		const { parentModal } = this.props;
		if (parentModal) {
			// @ts-ignore
			parentModal.onRequestClose(null, true);
		}
	};

	private onSearchFieldChanged = (e: ISimpleAutoCompleteSearchFieldEvent<React.ChangeEvent<HTMLInputElement>>) => {
		if (e.sourceEvent) {
			const inputElement = e.sourceEvent.target as HTMLInputElement;
			this.mSelectedTag = inputElement.value || '';
		}
	};

	private onSearchFieldKeyDown = (e: ISimpleAutoCompleteSearchFieldEvent<React.KeyboardEvent<HTMLInputElement>>) => {
		if (e.sourceEvent && e.sourceEvent.keyCode === 13) {
			const inputElement = e.sourceEvent.target as HTMLInputElement;
			this.mSelectedTag = inputElement.value || '';
			inputElement.blur();
		}
	};

	private onClearButtonClicked = () => {
		// @ts-ignore
		this.mSelectedTag = null;
	};

	private onTagSelected = (e: ISimpleAutoCompleteSearchFieldItemSelectionEvent<Api.IAccountTag>) => {
		if (e.target) {
			e.target.setSearchQuery(e.selection?.tag);
		}
		// @ts-ignore
		this.mSelectedTag = e.selection?.tag;
	};

	private onSubmitClicked = async (e: React.MouseEvent<HTMLElement>) => {
		const { contacts, onAddTagButtonClicked } = this.props;
		const selectedTag = (this.mSelectedTag || '').trim();
		if (!selectedTag) {
			return;
		}
		if (onAddTagButtonClicked) {
			onAddTagButtonClicked(selectedTag, e);
		}
		if (e.defaultPrevented || !!this.mSystemJob || contacts?.isBusy) {
			return;
		}
		await this.tagSelectedContacts(selectedTag);
		this.mSelectedTag = selectedTag;
	};

	private tagSelectedContacts = (tag: string) => {
		const { logInput, contacts, logApiError, errorMessages, userSession } = this.props;
		const selectedTag = (this.mSelectedTag || '').trim();

		// @ts-ignore
		logInput('Add', 'Click', { tagLength: selectedTag.length });
		const promise = contacts?.addTags([selectedTag]);
		if (promise) {
			promise
				.then(systemJob => {
					if (this.mMounted) {
						// @ts-ignore
						this.mSystemJob = new Api.SystemJobViewModel(userSession, systemJob);
						this.getProgress(tag);
					}
				})
				.catch((error: Api.IOperationResultNoValue) => {
					// @ts-ignore
					logApiError('AddTags-Error', error);
					// @ts-ignore
					errorMessages.pushApiError(error);
				});
		}
		return promise;
	};

	private getProgress = (selectedTag: string) => {
		if (this.mMounted && this.mSystemJob) {
			const { logApiError, errorMessages, userSession } = this.props;
			const promise = this.mSystemJob.get();
			if (promise) {
				promise
					.then(() => {
						if (this.mMounted) {
							// @ts-ignore
							if (this.mSystemJob.percentComplete >= 100) {
								// @ts-ignore
								const tagVM = new Api.TagViewModel(userSession, { tag: selectedTag });
								const cleanup = () => {
									// @ts-ignore
									this.mSystemJob = null;
									this.mProgressTimeoutHandle = null;
								};
								const tagLoadPromise = tagVM
									.load()
									?.then(result => {
										if (result?.automationTemplateId && userSession?.account?.features?.automation?.enabled) {
											this.automationTemplate = new Api.AutomationTemplateViewModel(userSession, {
												id: result?.automationTemplateId,
												templateType: Api.TemplateType.Automation,
											});
											this.automationTemplate
												.load()
												?.then(() => {
													cleanup();
													if (this.automationTemplate) {
														this.setState({ tagModalStep: TagModalStep.ShowingAutomationOption });
													}
												})
												?.catch((err: Api.IOperationResultNoValue) => {
													cleanup();
													// @ts-ignore
													errorMessages.pushApiError(err);
													// @ts-ignore
													logApiError('BulkTagLoadAutomation', err);
													this.onConfirmationClose();
												});
										} else {
											cleanup();
											this.onConfirmationClose();
										}
									})
									?.catch(() => {
										cleanup();
										// if we cannot load the tag, usually it's because it does not yet exist.
										// we should allow tagging to continue
										this.onConfirmationClose();
									});
								if (!tagLoadPromise) {
									cleanup();
								}
							} else {
								this.mProgressTimeoutHandle = setTimeout(() => {
									this.getProgress(selectedTag);
								}, 2000);
							}
						}
					})
					.catch((error: Api.IOperationResultNoValue) => {
						// @ts-ignore
						logApiError('GetSystemJobUpdate-Error', error);
						// @ts-ignore
						errorMessages.pushApiError(error);
					});
				return promise;
			}
		}
	};
}

const ContactsAddTagAsObserver = observer(_ContactsAddTag);
const ContactsAddTagContext = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey,
	ModalChildComponentContextKey
)(ContactsAddTagAsObserver);
export const ContactsAddTag = withEventLogging(ContactsAddTagContext, 'ContactsAddTag');

export const ContactsAddTagModal = asModalComponent(ContactsAddTag, {
	className: 'contacts-add-tag-modal',
	shouldCloseOnOverlayClick: false,
});
