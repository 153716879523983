import { navigation } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

export const DonationIcon = ({
	width = 42,
	height = 42,
	fillColor = navigation,
	...props
}: React.SVGProps<SVGSVGElement> & { fillColor?: string }) => {
	return (
		<SvgIcon height={height} width={width} viewBox='0 0 42 42' {...props}>
			<g fill={fillColor} fillRule='evenodd' transform='translate(1 1)'>
				<circle cx='20' cy='20' r='20' fill='#E8F3F9' stroke='#0CF' strokeWidth='2' />
				<circle cx='19.889' cy='19.889' r='16' fill='#FFF' />
				<g fill='#00AAE8' transform='translate(12 10)'>
					<path d='M14,17.382 L11.447,16.105 C11.125,15.945 10.743,15.968 10.445,16.168 L8,17.798 L5.555,16.168 C5.388,16.057 5.194,16 5,16 C4.847,16 4.694,16.035 4.553,16.105 L2,17.382 L2,2.618 L4.553,3.894 C4.873,4.054 5.256,4.03 5.555,3.832 L8,2.201 L10.445,3.832 C10.743,4.03 11.126,4.054 11.447,3.894 L14,2.618 L14,17.382 Z M15.526,0.15 C15.231,-0.033 14.863,-0.049 14.553,0.105 L11.072,1.846 L8.555,0.168 C8.219,-0.056 7.781,-0.056 7.445,0.168 L4.928,1.846 L1.447,0.105 C1.138,-0.049 0.769,-0.033 0.474,0.15 C0.18,0.331 0,0.653 0,1 L0,19 C0,19.347 0.18,19.668 0.474,19.851 C0.635,19.949 0.817,20 1,20 C1.153,20 1.306,19.965 1.447,19.894 L4.928,18.153 L7.445,19.832 C7.781,20.055 8.219,20.055 8.555,19.832 L11.071,18.153 L14.553,19.894 C14.863,20.049 15.231,20.032 15.526,19.851 C15.821,19.668 16,19.347 16,19 L16,1 C16,0.653 15.82,0.331 15.526,0.15 L15.526,0.15 Z' />
					<path d='M9,4.9997 L7,4.9997 L7,6.0497 C5.86,6.2827 5,7.2917 5,8.4997 C5,9.8777 6.122,10.9997 7.5,10.9997 L8.5,10.9997 C8.776,10.9997 9,11.2237 9,11.4997 C9,11.7757 8.776,11.9997 8.5,11.9997 L5,11.9997 L5,13.9997 L7,13.9997 L7,14.9997 L9,14.9997 L9,13.9497 C10.14,13.7167 11,12.7077 11,11.4997 C11,10.1217 9.878,8.9997 8.5,8.9997 L7.5,8.9997 C7.224,8.9997 7,8.7757 7,8.4997 C7,8.2237 7.224,7.9997 7.5,7.9997 L11,7.9997 L11,5.9997 L9,5.9997 L9,4.9997 Z' />
				</g>
			</g>
		</SvgIcon>
	);
};
