import * as Api from '@ViewModels';

export const useActionBarStatus = ({ contactsVM }: { contactsVM: Api.ContactsViewModel }) => {
	const isActive = contactsVM.selectionState !== 'none';
	const canKeepInTouch = contactsVM.selectedContacts.length;
	const canMerge = contactsVM.selectedContacts.length > 1;
	const canFocus = isActive;
	const canRestore = isActive || contactsVM.selectedContacts.length >= 1;
	const isSelectingAll =
		contactsVM.selectionState === 'all' ||
		(contactsVM.selectionState === 'some' && contactsVM.selectedContacts.length === 0);
	return {
		canKeepInTouch,
		canMerge,
		canRestore,
		canFocus,
		isActive,
		isSelectingAll,
	} as const;
};
