import { StyleSheet } from 'aphrodite';
import { borderColor, grayIconFill, titles } from '../../../../styles/colors';
import { BreakPoints, BrowserMediaQueries, truncateTextStyle } from '../../../../styles/styles';
import { contactTable } from '../PeopleTable/styles';

const clickable = {
	':active': {
		opacity: 0.7,
	},
	transition: 'opacity 0.1s',
	width: 50,
};

export const styleSheet = StyleSheet.create({
	avatar: {
		cursor: 'pointer',
		flexShrink: 0,
		height: 50,
		opacity: 1,
		...clickable,
	},
	connections: {
		...contactTable.connection,
		color: grayIconFill,
	},
	connectionsItem: {
		display: 'inline-flex',
	},
	connectionsItemOwner: {
		color: titles,
	},
	container: {
		alignItems: 'center',
		borderBottom: `1px solid ${borderColor}`,
		display: 'flex',
		fontSize: 12,
		// height less than min-height is an IE11 hack to make alignItems work
		[BrowserMediaQueries.IE11]: {
			height: 60,
		},
		margin: '0 28px',
		minHeight: 80,
		[BreakPoints.forMaxWidth(1000)]: {
			margin: '0 0px',
		},
	},
	containerSelected: {
		background: '#f3f6f8',
	},
	extraTags: {
		background: 'white',
		border: `1px solid ${borderColor}`,
		borderRadius: 3,
		maxHeight: 300,
		overflowY: 'auto',
		overflowX: 'hidden',
	},
	extraTagsItem: {
		':not(:last-of-type)': {
			borderBottom: `1px solid ${borderColor}`,
		},
		alignItems: 'center',
		display: 'flex',
		fontSize: 12,
		padding: '3px 10px',
	},
	info: {
		...contactTable.info,
	},
	infoTitle: {
		color: titles,
	},
	name: {
		...clickable,
		...contactTable.name,
		fontSize: 18,
	},
	outerContainer: {
		background: '#fff',
		cursor: 'pointer',
		transition: 'background 0.1s',
	},
	selection: {
		alignItems: 'center',
		display: 'flex',
		height: 50,
		justifyContent: 'center',
		paddingLeft: 12,
		width: 50,
	},
	tag: {
		display: 'inline-flex',
		margin: 4,
		// maxWidth: '80%',
	},
	tagMore: {
		fontSize: 12,
		margin: 4,
	},
	tags: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		...contactTable.tags,
		[BrowserMediaQueries.IE11]: {
			height: 60,
		},
		minHeight: 80,
		overflow: 'hidden',
		paddingBottom: 8,
		paddingTop: 8,
	},
	title: {
		color: grayIconFill,
		fontSize: 12,
		...truncateTextStyle,
	},
	visibility: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		marginLeft: -2,
		marginRight: 13,
		width: 15,
	},
});
