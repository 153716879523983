import { brandPrimaryHover } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const ArchiveIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`${className || ''}`} height={17} width={16}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5.5 9c0-1.841.966-3 2.5-3s2.5 1.159 2.5 3h-5zm4-5.5a1.5 1.5 0 11-3.001-.001A1.5 1.5 0 019.5 3.5'
				fill={fillColor || brandPrimaryHover}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M14.5 9h-1.625V1.636h-9.75V9H1.5V0h13v9zM1.6 15.25h12.8v-3.5h-2.869l-1.365 1.494a.765.765 0 01-.566.256H6.4a.765.765 0 01-.566-.256L4.47 11.75H1.6v3.5zM15.2 17H.8c-.442 0-.8-.392-.8-.875v-5.25C0 10.392.358 10 .8 10h4c.212 0 .416.092.566.256L6.73 11.75h2.54l1.365-1.494A.765.765 0 0111.2 10h4c.442 0 .8.392.8.875v5.25c0 .483-.358.875-.8.875z'
				fill={fillColor || brandPrimaryHover}
			/>
		</SvgIcon>
	);
};
