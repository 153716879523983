import { titles } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const avatarSize = 50;
export const contentItemSpacing = 20;
export const contentHorizontalPadding = 28;
export const contentVerticalPadding = 15;

export const styleSheet = StyleSheet.create({
	avatar: {
		flexShrink: 0,
		height: avatarSize,
		marginRight: contentItemSpacing,
		width: avatarSize,
	},
	container: {
		alignItems: 'center',
		boxSizing: 'border-box',
		display: 'flex',
		padding: `${contentVerticalPadding}px ${contentHorizontalPadding}px`,
	},
	content: {
		':nth-child(1n) > *:not(:first-child)': {
			color: '#858585',
			fontSize: 12,
			marginLeft: 1,
			marginTop: 2,
		},
		color: titles,
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		fontSize: 18,
		marginBottom: 4,
		textAlign: 'left',
		width: `calc(100% - ${avatarSize + contentItemSpacing}px)`,
	},
	title: {
		alignItems: 'center',
		boxSizing: 'border-box',
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
	},
	userName: {
		flexGrow: 1,
	},
});
