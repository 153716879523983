import { StyleSheet } from 'aphrodite';
import { header, titles } from '../../../../styles/colors';
import { CssSelectors } from '../../../../styles/styles';

const inputFieldWidth = 300;

export const styleSheet = StyleSheet.create({
	buttonContainer: {
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	changeTimezoneBtn: {
		marginLeft: 20,
	},
	connectEmailLink: {
		marginLeft: 20,
	},
	container: {},
	deleteProfilePicture: {
		color: '#046CB6',
		fontSize: 14,
	},
	emailField: {
		display: 'inline-block',
		margin: '11px 0',
	},
	formFieldLabel: {
		color: header,
		display: 'inline-block',
		fontSize: '0.875rem',
		width: 200,
	},
	formValueNonEditable: {
		color: titles,
		fontSize: '0.875rem',
	},
	inputField: {
		display: 'inline-block',
		width: inputFieldWidth,
		...CssSelectors.allChildren(
			{
				width: '100%',
			},
			'input'
		),
	},
	noTestEmail: {
		color: header,
		fontStyle: 'italic',
	},
	profilePlaceHolder: {
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		borderRadius: '50%',
		display: 'inline-flex',
		height: 60,
		marginRight: 14,
		overflow: 'hidden',
		position: 'relative',
		width: 60,
		...CssSelectors.allChildren(
			{
				height: '100%',
				left: '50%',
				objectFit: 'cover',
				position: 'absolute',
				top: '50%',
				transform: 'translate(-50%, -50%)',
				width: '100%',
			},
			'img'
		),
	},
	profilePlaceHolderBlurb: {
		color: '#858585',
		fontSize: 14,
		margin: '-2px 0px 7px 2px',
	},
	profilePlaceHolderContainer: {
		alignItems: 'center',
		display: 'flex',
	},
	profileUploadCta: {
		cursor: 'pointer',
		width: 60,
	},
	showingTzOptions: {
		overflow: 'visible',
	},
});
