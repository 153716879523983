import { ICreateCampaignRequest, ILocationState, TemplateOrTemplateFilter } from '../../../../../models';
import { useEventLogging } from '../../../../../models/Logging';
import { useFullscreenModal } from '../../../../../models/hooks/appStateHooks';
import * as Api from '@ViewModels';
import { TemplateType } from '@ViewModels';
import * as React from 'react';

export function useLaunchCampaignFromTemplate({
	queryCommand,
	eventLoggingCategory,
}: {
	queryCommand: Api.IQueryStringCommand;
	eventLoggingCategory: string;
}) {
	const fullscreenModal = useFullscreenModal();
	const { logEvent } = useEventLogging(eventLoggingCategory);
	React.useEffect(() => {
		if (queryCommand?.command === 'templates') {
			const templateCommand = queryCommand as Api.ITemplateCommand;
			// @ts-ignore
			const isSocial = templateCommand.template.templateType === TemplateType.SocialMediaPost;
			if (templateCommand) {
				const locationState: ILocationState<any, ICreateCampaignRequest<TemplateOrTemplateFilter>> = {
					model: {
						context: templateCommand.template,
						schedule: {
							criteria:
								templateCommand.sendOption === 'now'
									? Api.ScheduleCriteria.Immediately
									: Api.ScheduleCriteria.StartAfter,
							startDate: templateCommand.date,
						},
						tags: templateCommand.tags,
						type: 'Template',
					},
				};
				fullscreenModal?.history.push({
					pathname: isSocial ? `/social-media/post/create/from-template` : '/email/campaigns/create/from-template',
					state: locationState,
				});
				logEvent('AppLink-Template', {
					id: templateCommand.id,
					op: templateCommand.op,
				});
			}
		}
	}, [queryCommand, logEvent, fullscreenModal]);
}
