import { css } from 'aphrodite';
import * as React from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { getFormattedPhoneNumber } from '../../../../models/UiUtils';
import { useLambda } from '../../../../models/hooks/useLambda';
import { Button } from '../../Button';
import { InputFieldError } from '../../InputFieldError';
import { TextInput } from '../../TextInput';
import { TextingCallForwardingGraphic } from '../../svgs/graphics/TextingCallForwardingGraphic';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	onStepComplete?(phoneNumber?: string): void;
}

export const CallForwardingSetup: React.FC<IProps> = ({ className = '', onStepComplete }) => {
	const [forwardingPhoneNumber, setForwardingPhoneNumber] = React.useState('');
	const [hasFocus, setHasFocus, toggleHasFocus] = useLambda(false);
	const [hasValue, setHasValue] = React.useState(false);
	// @ts-ignore
	const [errorMessage, setErrorMessage] = React.useState<string>(null);

	const onComplete = React.useCallback(
		(useNumber = true) => {
			return () => {
				onStepComplete?.(useNumber ? forwardingPhoneNumber : '');
			};
		},
		[onStepComplete, forwardingPhoneNumber]
	);

	// #region input callbacks
	const onInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		let value = (e.target.value || '').trim();
		if (value.length >= 10) {
			try {
				value = getFormattedPhoneNumber(value);
			} catch (error) {
				setErrorMessage('Please enter a valid phone number.');
				setHasValue(false);
			}
			setHasFocus(false);
		}
		unstable_batchedUpdates(() => {
			setForwardingPhoneNumber(value);
			// @ts-ignore
			setErrorMessage(null);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onInputBlur = React.useCallback((e: React.FocusEvent<HTMLInputElement>) => {
		let value = (e.target.value || '').trim();
		unstable_batchedUpdates(() => {
			try {
				value = getFormattedPhoneNumber(value);
				setForwardingPhoneNumber(value);
			} catch (error) {
				setErrorMessage('Please enter a valid phone number.');
				setHasValue(false);
			}
			setHasFocus(false);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getDecoratedNumber = () => {
		let value = (forwardingPhoneNumber || '').trim();
		if (!hasFocus) {
			try {
				value = getFormattedPhoneNumber(value);
				if (!hasValue) {
					setHasValue(true);
				}
			} catch (_) {
				// continue regardless of error
			}
		}
		return value;
	};
	// #endregion

	return (
		<div className={`${css(styleSheet.container)} ${className}`}>
			<TextingCallForwardingGraphic width={176.25} height={129} />
			<div className={css(styleSheet.titleText)}>Set Up Call Forwarding</div>
			<div className={css(styleSheet.infoDetailText)}>
				When someone calls your Levitate texting number, which number would you like for the call to be forwarded to?
			</div>
			<InputFieldError errorMessage={errorMessage}>
				<TextInput
					className={css(styleSheet.callForwardInput)}
					inputId='call-forwarding-phone-number'
					onBlur={onInputBlur}
					onChange={onInputChange}
					onFocus={toggleHasFocus(true)}
					placeholder={getFormattedPhoneNumber('9195555555')}
					type='text'
					value={getDecoratedNumber()}
				/>
			</InputFieldError>
			<Button
				className={`set-up-call-forwarding-cta ${css(styleSheet.callForwardButton)} `}
				label='Set Up Call Forwarding & Start Texting'
				onClick={onComplete(true)}
				disabled={!hasValue}
			/>
			<Button
				className={`skip-call-forwarding-cta ${css(styleSheet.skipButton)} `}
				label='Skip Call Forwarding'
				kind='custom'
				onClick={onComplete()}
			/>
			<div className={css(styleSheet.footerText)}>
				If you choose to skip this step, when someone calls the Levitate number, they will simply get the message that
				it&apos;s not a working number.
			</div>
		</div>
	);
};
