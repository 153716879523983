import { brandPrimary, brandPrimaryActive, brandPrimaryHover } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	connectCta: {
		marginTop: 20,
		width: '100%',
	},
	container: {},
	ctaButton: {
		margin: '20px auto 0',
		maxWidth: 180,
		textDecoration: 'none',
	},
	editingSignInFormField: {
		background: '#fff',
		border: `#aaa 1px solid`,
	},
	inProgressContainer: {
		...CssSelectors.allChildren({
			fontSize: 14,
		}),
	},
	inProgressIconContainer: {
		...CssSelectors.allChildren({
			marginTop: 35,
			opacity: 0.148717,
		}),
		background: '#f9f9f9',
		borderRadius: '50%',
		height: 180,
		margin: '30px auto 40px',
		textAlign: 'center',
		width: 180,
	},
	inProgressText: {
		margin: '0 auto',
		textAlign: 'center',
		width: 350,
	},
	inputWrapper: {
		marginTop: 14,
	},
	link: {
		':active': {
			color: brandPrimaryActive,
		},
		':hover': {
			color: brandPrimary,
			cursor: 'pointer',
		},
		color: brandPrimaryHover,
		textDecoration: 'none',
	},
	logoCenter: {
		...CssSelectors.allChildren({
			margin: '0 auto',
		}),
	},
	signInFormError: {
		color: '#B34755',
		fontSize: 14,
		marginTop: 20,
		minHeight: 50,
		textAlign: 'center',
	},
	signInFormField: {
		':not(:first-of-type)': {
			marginTop: 4,
		},
		background: '#f9f9f9',
		border: `#ccc 1px solid`,
		borderRadius: 3,
		boxSizing: 'border-box',
		display: 'flex',
		margin: 0,
		padding: `15px 20px`,
		position: 'relative',
		...CssSelectors.allChildren(
			{
				background: 'transparent',
				border: 'none',
				fontSize: 14,
				height: '100%',
				outline: 'none',
				width: '100%',
			},
			':not(.danger-icon)'
		),
	},
	signInFormFieldLabel: {
		':not(:first-of-type)': {
			marginTop: 16,
		},
		color: '#A7ABAD',
		fontSize: 18,
		letterSpacing: 0,
		marginBottom: 8,
	},
	synced: {
		...CssSelectors.allChildren({
			fontSize: 14,
			marginBottom: 30,
		}),
	},
});
