import { IFabMenuItem, ILocationState } from '@AppModels/.';
import * as AppState from '@AppModels/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import {
	IContact,
	ICreateEntityRichContentCommand,
	IEntity,
	IOperationResultNoValue,
	QueryCommands,
} from '@ViewModels';
import { NotFound } from '@WebComponents/NotFound';
import { PortalDestination } from '@WebComponents/Portal';
import { StyleDeclarationValue, css } from 'aphrodite';
import Bluebird from 'bluebird';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { parse as queryStringToObject } from 'query-string';
import * as React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { v4 as uuidgen } from 'uuid';
import { Topics } from '../../../models/LocalNotificationTopics';
import { ContactViewModel, EntityViewModel, RichContentComposerViewModel } from '../../../viewmodels/AppViewModels';
import {
	INotificationServiceComponentProps,
	withNotificationService,
} from '../../components/LocalNotificationObserver/WithNotificationService';
import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { NavigationBreadcrumbsBar } from '../../components/NavigationBreadcrumbsBar';
import { AutomationSelectorModal } from '../../components/automation/AutomationSelector';
import { EditableContact } from '../../components/contacts/EditableContact';
import { AutomationsIcon } from '../../components/svgs/icons/AutomationsIcon';
import { INavigationItemProps } from '../MainContainer';
import { ContactActivity, IContactActivityComponent } from '../People/Contact/Activity';
import { styleSheet } from './styles';

export interface IContactProps
	extends AppState.IUserSessionComponentProps,
		RouteComponentProps<any>,
		INavigationItemProps,
		IEventLoggingComponentProps,
		AppState.IErrorMessageComponentProps,
		AppState.IToasterComponentProps,
		AppState.IActionItemComposerComponentProps,
		AppState.IFabComponentProps,
		AppState.INoteComposerComponentProps,
		INotificationServiceComponentProps<ContactViewModel>,
		AppState.IFullscreenModalComponentProps {
	className?: string;
	contact?: ContactViewModel;
	contactId?: string;
	disableCompanyLink?: boolean;
	hideDeleteButton?: boolean;
	hideEditButton?: boolean;
	styles?: StyleDeclarationValue[];
}

interface IState {
	contact?: ContactViewModel;
	createRichContentQueryCommand?: ICreateEntityRichContentCommand;
	loadError?: IOperationResultNoValue;
	redirectPath?: string;
	scrollToBottomWaypointPortalId?: string;
	showInitAutomationsModal?: boolean;
	showNotFound?: boolean;
	shouldPollForRelationshipHealth?: boolean;
}

class _Contact extends React.Component<IContactProps, IState> {
	@observable private mMounted: boolean;
	private contactActivityComponentRef: IContactActivityComponent;
	private mFabContextDisposer: () => void;
	private mInProgressEntityResolvePromise: Bluebird<EntityViewModel<IEntity>[]>;

	public static getDerivedStateFromProps(props: IContactProps, state: IState) {
		const nextState: IState = {};
		if (!!props.contact && props.contact !== state.contact) {
			nextState.contact = props.contact;
		} else {
			const currentContactId = state.contact ? state.contact.id : null;
			const locationState: ILocationState<ContactViewModel, IContact> =
				(props.history.location ? props.history.location.state : null) || {};
			const nextContactId = props.match.params?.id || props.contactId;
			if (!!locationState && !!locationState.viewModel) {
				if (state.contact !== locationState.viewModel) {
					nextState.contact = locationState.viewModel;
				}
			} else if (nextContactId && currentContactId !== nextContactId) {
				nextState.contact = new ContactViewModel(props.userSession, {
					id: nextContactId,
				});
			}
		}

		if (nextState.contact) {
			// clear the error
			nextState.showNotFound = false;
			nextState.loadError = null;
		}

		return Object.keys(nextState).length > 0 ? nextState : null;
	}

	constructor(props: IContactProps) {
		super(props);
		const initialState: IState = {
			scrollToBottomWaypointPortalId: uuidgen(),
		};
		this.state = {
			...initialState,
			...(_Contact.getDerivedStateFromProps(props, initialState) || {}),
		};
	}

	@action
	public componentDidMount() {
		this.mMounted = true;
		const { fab, history, userSession } = this.props;
		const { contact } = this.state;
		const menuItems: IFabMenuItem<string>[] = [];
		if (userSession?.account?.features?.automation?.enabled) {
			menuItems.push({
				icon: <AutomationsIcon fillColor='#fff' />,
				representedObject: 'startAutomation',
				tooltip: 'Start Automation',
			});
		}
		const disposer = fab.registerContext({
			menuItems,
			onMenuItemSelected: this.onFabMenuItemSelected,
		});

		this.mFabContextDisposer = () => {
			disposer();
			this.mFabContextDisposer = null;
		};

		const promise = this.loadContact();
		// handle query commands
		const searchParams = queryStringToObject(history?.location?.search || '');
		const cmd = searchParams?.cmd;
		switch (cmd) {
			case 'start-automation': {
				promise?.then(() => {
					history.replace({
						pathname: history.location.pathname,
						state: history.location.state,
					});
					if (userSession.account.features?.automation?.enabled) {
						this.showInitAutomationsModal();
					}
				});
				break;
			}
			case 'manage-automation': {
				promise?.then(() => {
					history.replace({
						pathname: history.location.pathname,
						state: history.location.state,
					});
					const automationId = searchParams?.automationId;
					if (
						!!automationId &&
						userSession.account.features?.automation?.enabled &&
						!!contact?.inProgressAutomations?.find(x => x.automationId === automationId)
					) {
						this.contactActivityComponentRef?.showRichContentTab({
							manageAutomationId: automationId,
						});
					}
				});
				break;
			}
			default: {
				break;
			}
		}
	}

	public componentWillUnmount() {
		this.mMounted = false;
		if (this.mInProgressEntityResolvePromise) {
			this.mInProgressEntityResolvePromise.cancel();
			this.mInProgressEntityResolvePromise = null;
		}

		if (this.mFabContextDisposer) {
			this.mFabContextDisposer();
		}
	}

	public componentDidUpdate() {
		const { contact, showNotFound, loadError } = this.state;
		if (!this.isLoading && !contact.isLoaded && !showNotFound && !loadError) {
			this.loadContact();
		}
	}

	private setShouldStartPollingForRelationshipUpdate = (shouldPoll: boolean) => {
		this.setState({ shouldPollForRelationshipHealth: shouldPoll });
	};

	public render() {
		const { className, routeContainerClassName, styles } = this.props;
		const { contact, redirectPath, showNotFound, showInitAutomationsModal } = this.state;
		return (
			<div
				className={`${css(styleSheet.container, ...(styles || []))} contact-container ${
					routeContainerClassName || ''
				} ${className || ''}`}
			>
				<MultiContainerHeader
					appBarHeader={!!contact && !!contact.name && <NavigationBreadcrumbsBar currentLocationName={contact.name} />}
					fullscreenHeader='Contact Profile'
				/>
				{showNotFound ? (
					<NotFound
						className={css(styleSheet.notFound)}
						message='The contact you are looking for magically vanished.'
					/>
				) : (
					<>
						<div className={css(styleSheet.splitMaster)}>{this.onRenderSplitViewMaster()}</div>
						<div className={css(styleSheet.splitDetail)}>{this.onRenderSplitViewDetail()}</div>
					</>
				)}
				{!!redirectPath && <Redirect push={true} to={redirectPath} />}
				{showInitAutomationsModal ? (
					<AutomationSelectorModal
						contact={contact}
						createAutomationForContact={true}
						closeModal={() => this.onAutomationSelectorModalRequestClose()}
					/>
				) : null}
			</div>
		);
	}

	private onRenderSplitViewMaster() {
		const { disableCompanyLink, hideDeleteButton, hideEditButton } = this.props;
		const { contact, shouldPollForRelationshipHealth } = this.state;
		return (
			<EditableContact
				contact={contact}
				disableCompanyLink={disableCompanyLink}
				hideEditButton={hideEditButton}
				isLoading={this.isLoading}
				onDeleteConfirmation={!hideDeleteButton && this.onDeleteConfirmationFinished}
				onShowRelationshipAnalysisButtonClicked={this.onShowRelationshipAnalysisButtonClicked}
				shouldPollForRelationshipHealth={shouldPollForRelationshipHealth}
			/>
		);
	}

	private onAutomationSelectorModalRequestClose = () => {
		this.setState({
			showInitAutomationsModal: false,
		});
	};

	@computed
	private get isLoading() {
		const { contact } = this.state;
		return !contact || (!!contact && !!contact.isLoading) || !this.mMounted;
	}

	private onFabMenuItemSelected = (menuItem: IFabMenuItem<string>) => {
		if (menuItem.representedObject === 'startAutomation') {
			this.showInitAutomationsModal();
		}
	};

	private showInitAutomationsModal = () => {
		this.setState({
			showInitAutomationsModal: true,
		});
	};

	private onDeleteConfirmationFinished = (confirmDelete: boolean) => {
		if (confirmDelete) {
			const { logApiError, logEvent, errorMessages, toaster, postNotification, fullscreenModal } = this.props;
			const { contact } = this.state;

			const promise = contact.delete();
			if (promise) {
				logEvent('DeleteContact', { id: contact.id });
				promise
					.then(() => {
						toaster.push({
							message: 'Contact was deleted successfully',
							type: 'successMessage',
						});

						if (fullscreenModal.history?.historyStack?.length) {
							fullscreenModal.dismissModal();
						} else {
							this.setState({ redirectPath: '/people' });
						}
						postNotification?.({ info: contact, topic: Topics.DELETE_CONTACT });
					})
					.catch((error: IOperationResultNoValue) => {
						errorMessages.push({
							messages: [error.systemMessage],
						});
						logApiError('DeleteContact-Error', error);
					});
			}
		}
	};

	private loadContact = (contact: ContactViewModel = this.state.contact) => {
		if (contact) {
			const { history, logEvent, noteComposer, logApiError, actionItemComposer } = this.props;
			const promise = contact.load(true);
			if (promise) {
				logEvent('ContactLoad');
				if (history) {
					history.location.state = null;
				}

				// parse query commands for this contact
				const nextState = this.getNextStateWithQueryCommand();
				if (nextState) {
					this.setState(nextState);
				}

				promise
					.then(() => {
						if (this.mMounted) {
							const { createRichContentQueryCommand } = this.state;

							// handle pending query commands
							if (createRichContentQueryCommand) {
								// remove the command
								history.replace(history.location.pathname);
								this.setState({
									createRichContentQueryCommand: null,
								});

								if (this.mInProgressEntityResolvePromise) {
									this.mInProgressEntityResolvePromise.cancel();
								}

								// FOLLOWUP: Resolve
								// @ts-ignore
								const composer: RichContentComposerViewModel =
									createRichContentQueryCommand.type === 'note' ? noteComposer : actionItemComposer;
								const loadPromise = composer.showWithReferencedEntities(
									[contact],
									createRichContentQueryCommand.refs.contacts,
									createRichContentQueryCommand.refs.companies
								);
								if (loadPromise) {
									logEvent('RichContentQueryCommandReferences-Load');
									this.mInProgressEntityResolvePromise = loadPromise;
									const onFinish = () => {
										if (this.mInProgressEntityResolvePromise === loadPromise) {
											this.mInProgressEntityResolvePromise = null;
										}
									};
									this.mInProgressEntityResolvePromise.then(onFinish).catch((error: IOperationResultNoValue) => {
										logApiError('RichContentQueryCommandReferences-Error', error);
										onFinish();
									});
								}
							}

							if (history) {
								// save it back to location state
								const locationState: ILocationState<ContactViewModel, IContact> = {
									viewModel: contact,
								};
								history.location.state = locationState;
							}
						}
					})
					.catch((error: IOperationResultNoValue) => {
						logApiError('ContactLoad-Error', error);
						if (this.mMounted) {
							const stateWithError: IState = { loadError: error };
							if (error.systemCode === 404) {
								stateWithError.showNotFound = true;
							}
							this.setState(stateWithError);
						}
					});
			}
			return promise;
		}
	};

	private onRenderSplitViewDetail() {
		const { contact, scrollToBottomWaypointPortalId } = this.state;
		return (
			<>
				<ContactActivity
					{...this.props}
					className={[styleSheet.activity]}
					contact={contact}
					innerRef={this.onContactActivityRef}
					scrollToBottomWaypointPortalId={scrollToBottomWaypointPortalId}
					userSession={this.props.userSession}
					setShouldStartPollingForRelationshipUpdate={this.setShouldStartPollingForRelationshipUpdate}
				/>
				{!!scrollToBottomWaypointPortalId && <PortalDestination id={scrollToBottomWaypointPortalId} />}
			</>
		);
	}

	private getNextStateWithQueryCommand = () => {
		const nextState: IState = {};
		const { location } = this.props;
		if (location) {
			const queryCommand = QueryCommands.Contact.parse(location.search);
			if (queryCommand) {
				switch (queryCommand.command) {
					case 'crc': {
						nextState.createRichContentQueryCommand = queryCommand as ICreateEntityRichContentCommand;
						break;
					}
					default: {
						break;
					}
				}
			}
		}
		return Object.keys(nextState).length > 0 ? nextState : null;
	};

	private onShowRelationshipAnalysisButtonClicked = () => {
		if (this.contactActivityComponentRef) {
			this.contactActivityComponentRef.showAnalysisTab();
		}
	};

	private onContactActivityRef = (ref?: IContactActivityComponent) => {
		this.contactActivityComponentRef = ref;
	};
}

// FOLLOWUP: Resolve
// @ts-ignore
const ContactAsObserver = observer(_Contact);
const ContactWithContext = inject(
	AppState.ActionItemComposerViewModelKey,
	AppState.ErrorMessagesViewModelKey,
	AppState.FabViewModelKey,
	AppState.NoteComposerViewModelKey,
	AppState.ToasterViewModelKey,
	AppState.UserSessionViewModelKey,
	AppState.FullScreenModalViewModelKey
)(ContactAsObserver);
const ContactWithNotifications = withEventLogging(ContactWithContext, 'Contact');
export const Contact = withRouter(withNotificationService(ContactWithNotifications));
