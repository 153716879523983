import {
	ManageUsersTableCellBorder,
	ManageUsersTableEmailColumnWidth,
	ManageUsersTableInitialsColumnWidth,
	ManageUsersTableStatusColumnWidth,
	ManageUsersTableStatusIndicatorColumnWidth,
} from '../../../../admin/components/integrations/IntegrationsManageUsersTable/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	buttonContainer: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 30,
	},
	container: {
		padding: '0 40px',
	},
	icon: {
		margin: '30px 0px 20px',
		maxWidth: 300,
	},
	instructionsText: {
		color: '#4A4A4A',
		marginBottom: 32,
	},
	loader: {
		marginTop: 30,
	},
	tableContainer: {
		border: 0,
		borderCollapse: 'collapse',
		borderSpacing: 0,
		width: '100%',
	},
	tableHeader: {
		background: '#fff',
	},
	tableHeaderCell: {
		borderBottom: ManageUsersTableCellBorder,
		color: '#AAAAAA',
		fontSize: 11,
		letterSpacing: '1.1px',
		paddingBottom: 20,
		textTransform: 'uppercase',
	},
	tableHeaderEmailHeader: {
		width: ManageUsersTableEmailColumnWidth,
	},
	tableHeaderInitialsHeader: {
		minWidth: ManageUsersTableInitialsColumnWidth,
		whiteSpace: 'nowrap',
		width: ManageUsersTableInitialsColumnWidth,
	},
	tableHeaderRow: {},
	tableHeaderStatusHeader: {
		minWidth: ManageUsersTableStatusColumnWidth,
		whiteSpace: 'nowrap',
		width: ManageUsersTableStatusColumnWidth,
	},
	tableHeaderStatusIndicatorHeader: {
		minWidth: ManageUsersTableStatusIndicatorColumnWidth,
		width: ManageUsersTableStatusIndicatorColumnWidth,
	},
});
