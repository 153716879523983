import { StyleSheet } from 'aphrodite';
import { darkGrayFontColor } from '../../../../styles/colors';

export const styleSheet = StyleSheet.create({
	container: {},
	emptyText: {
		color: darkGrayFontColor,
		fontSize: 14,
		fontStyle: 'italic',
		paddingLeft: 0,
	},
	expander: {
		height: '100%',
		overflow: 'hidden',
		position: 'relative',
		width: '215px',
	},
	search: {
		':nth-child(1n)': {
			padding: '2px 6px 2px 0',
		},
	},
	searchIcon: {
		height: 14,
		margin: '8px 6px 0 0',
		minWidth: 14,
		width: 14,
	},
});
