import { SvgIcon } from '../../icons/SvgIcon';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const ContactInCircleGraphic: React.FunctionComponent<IProps> = props => {
	const { className, styles } = props;
	return (
		<SvgIcon
			className={`contact-in-circle-graphic ${className || ''} ${css(...(styles || []))}`}
			height={180}
			width={180}
		>
			<circle cx='90' cy='90' r='90' fill='#E8F3F9' />
			<path fill='#CAE0EC' stroke='#00AAE8' strokeWidth='2' d='M53 50h74v86H53z' />
			<path fill='#E8F3F9' stroke='#00AAE8' strokeWidth='2' d='M48 45h84v86H48z' />
			<rect x='74.5' y='109.5' width='32' height='1' rx='.5' fill='#D8D8D8' stroke='#00AAE8' />
			<rect x='74.5' y='103.5' width='32' height='1' rx='.5' fill='#D8D8D8' stroke='#00AAE8' />
			<rect x='74.5' y='115.5' width='17' height='1' rx='.5' fill='#D8D8D8' stroke='#00AAE8' />
			<circle cx='90.5' cy='76.5' r='18.5' fill='#fff' stroke='#00AAE8' strokeWidth='2' />
			<path
				d='M97.546 84.333v-1.777c0-1.964-1.629-3.556-3.637-3.556h-7.273C84.628 79 83 80.592 83 82.556v1.777'
				stroke='#00AAE8'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
				fill='none'
			/>
			<ellipse
				cx='90.636'
				cy='71.556'
				rx='3.636'
				ry='3.556'
				stroke='#00AAE8'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
				fill='none'
			/>
		</SvgIcon>
	);
};
