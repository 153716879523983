import { SizeConstraint } from '../../../../models';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { noteTintColor } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { VisibilityIndicatorIcon } from '../../VisibilityIndicatorIcon';
import { EmailMessageHeader } from '../../notes/EmailMessageHeader';
import { FeedCard, IFeedCardCallbackProps, IFeedCardMoreMenuProps } from '../FeedCard';
import { FeedCardMentionedEntitiesHeader } from '../FeedCardMentionedEntitiesHeader';
import { FeedCardNoteContent } from '../FeedCardNoteContent';
import { styleSheet } from './styles';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';

interface IProps extends IFeedCardCallbackProps, IFeedCardMoreMenuProps<string> {
	className?: string;
	note: Api.NoteViewModel;
	showSuppressOption?: boolean;
	sizeConstraint?: SizeConstraint;
}

function _NoteCard({
	className,
	moreMenuItems,
	onMoreMenuItemClicked,
	onSuppressClicked,
	showSuppressOption,
	sizeConstraint = 'normal',
	note,
}: IProps) {
	const userSession = useUserSession();
	const wasCreatedByEmail = !!note.context && note.context.source === Api.RichContentContextSource.EmailMessageSend;
	// @ts-ignore
	const userIsCreator = note.creatorId === userSession.user.id;
	const elementDataProps = {
		...(!!note && {
			'data-id': note.id,
		}),
	};
	const mentionedEntities = note.mentionedEntities.reduce<Api.EntityViewModel[]>((result, mention) => {
		if (mention.method === Api.RichContentReferenceMethod.Explicit) {
			result.push(mention.entity);
		}
		return result;
	}, []);
	const renderEmailMessageHeader = () => {
		const recipients = (note.mentionedEntities || [])
			.filter(x => Api.getTypeForEntity(x.entity.toJs()) !== 'company')
			.map(x => x.entity as Api.ContactViewModel);
		const emailMessageContext = note.context as Api.IEmailMessageSendContext;
		return (
			<EmailMessageHeader
				className={css(styleSheet.emailHeader)}
				recipients={recipients}
				seenOnDate={emailMessageContext.openDate ? new Date(emailMessageContext.openDate) : undefined}
			/>
		);
	};
	return (
		<FeedCard
			{...elementDataProps}
			className={`${css(styleSheet.container)} note-card ${className || ''}`}
			indicatorColor={noteTintColor}
			moreMenuItems={moreMenuItems}
			onMoreMenuItemClicked={onMoreMenuItemClicked}
			onSuppressClicked={onSuppressClicked}
			showSuppressOption={showSuppressOption}
		>
			<FeedCardMentionedEntitiesHeader className={css(styleSheet.header)} mentionedEntities={mentionedEntities}>
				{({ entityList, overflow }) => {
					return (
						<div className={css(styleSheet.title, baseStyleSheet.truncateText)}>
							New note
							{!!entityList && (
								<>
									<div>&nbsp;about&nbsp;</div>
									{entityList}
									{!!overflow && (
										<>
											&nbsp;
											{overflow}
										</>
									)}
								</>
							)}
						</div>
					);
				}}
			</FeedCardMentionedEntitiesHeader>
			<div className={css(styleSheet.body)}>
				{!!note.creatorName && (
					<div className={css(styleSheet.creatorHeader)}>
						{!!userIsCreator && note.visibility !== 'all' && (
							<VisibilityIndicatorIcon className={css(styleSheet.privacyIcon)} visibility={note.visibility} />
						)}
						<div className={css(styleSheet.creatorName, baseStyleSheet.truncateText)}>
							{userIsCreator
								? 'You'
								: sizeConstraint === 'compact'
									? note.creatorNameShort || note.creatorName
									: note.creatorName}
						</div>
						&nbsp;
						<div>{`added note on ${note.lastModifiedDateText}`}</div>
					</div>
				)}
				{!!wasCreatedByEmail && renderEmailMessageHeader()}
				<FeedCardNoteContent note={note} />
			</div>
		</FeedCard>
	);
}

export const NoteCard = observer(_NoteCard);
