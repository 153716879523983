import { styleSheet } from './styles';
import { StyleDeclaration, css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	styleDeclarations?: StyleDeclaration[];
	detail?: React.ReactNode;
	master?: React.ReactNode;
}

/** Note: for the divider to work properly, the parent of SplitView should have display: block; */
export const SplitView = ({ className, master, detail, styleDeclarations = [] }: IProps) => {
	return (
		<div className={`${css(styleSheet.splitView, ...styleDeclarations)} ${className}`}>
			<div className={css(styleSheet.splitViewMaster)}>{master}</div>
			<div className={css(styleSheet.splitViewDivider)} />
			<div className={css(styleSheet.splitViewDetail)}>{detail}</div>
		</div>
	);
};
