import { useEventLogging } from '../../../../../models/Logging';
import * as Api from '@ViewModels';
import * as React from 'react';

export function useLaunchSingleEmail({
	queryCommand,
	eventLoggingCategory,
	showSingleEmailModal,
}: {
	queryCommand: Api.IQueryStringCommand;
	eventLoggingCategory: string;
	showSingleEmailModal: () => void;
}) {
	const isCommandSendIndividual = queryCommand?.command === 'send-individual';
	const { logEvent } = useEventLogging(eventLoggingCategory);
	React.useEffect(() => {
		if (isCommandSendIndividual) {
			showSingleEmailModal();
			logEvent('AppLink-Send-Individual');
		}
	}, [isCommandSendIndividual, showSingleEmailModal, logEvent]);
}
