import { Login } from '..';
import { OAuthLoginViewModel, UserSessionContext } from '../../../../viewmodels/AppViewModels';
import { observer } from 'mobx-react';
import * as React from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

interface IProps extends RouteComponentProps<any> {
	className?: string;
	userSession?: UserSessionContext;
}

interface IState {
	loginVM?: OAuthLoginViewModel;
}

export class _OAuthLogin extends React.Component<IProps, IState> {
	public state: IState = {};

	public UNSAFE_componentWillMount() {
		// @ts-ignore
		const loginVM = new OAuthLoginViewModel(this.props.userSession, this.usernamePasswordValidCallback);
		loginVM.setQueryString(this.props.location.search);

		this.setState({
			loginVM,
		});
	}

	public render() {
		return (
			<Login
				{...this.props}
				autoRedirectIfAuthenticated={false}
				loginMode='standard'
				showRememberMe={false}
				showSignupButton={false}
				// @ts-ignore
				loginVM={this.state.loginVM}
			/>
		);
	}

	private usernamePasswordValidCallback = (redirectUrl: string) => {
		window.location.href = redirectUrl;
	};
}

const OAuthLoginObserver = observer(_OAuthLogin);

// Return the HOC as a top-level object in order to preserve HMR
export const OAuthLogin = withRouter(OAuthLoginObserver);
