import { styleSheet } from './styles';
import { StyleDeclarationValue, css } from 'aphrodite';
import { clsx } from 'clsx';
import * as React from 'react';

interface IProps {
	className?: string;
	styleDeclaration?: StyleDeclarationValue[] | StyleDeclarationValue;
	children: React.ReactNode;
}

export const DashboardCardStat = ({ className, styleDeclaration, children }: IProps) => {
	const styleDeclarations = Array.isArray(styleDeclaration) ? styleDeclaration : [styleDeclaration];
	return <span className={clsx(css(styleSheet.stat, ...styleDeclarations), className)}>{children}</span>;
};
