import { inputBorderColor, titles } from '../../../styles/colors';
import { cardStyle } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	body: {},
	container: {
		...cardStyle,
		alignItems: 'center',
		border: `1px solid ${inputBorderColor}`,
		display: 'flex',
		minWidth: 200,
		padding: 10,
	},
	icon: {
		height: 22,
		marginRight: 16,
		width: 'auto',
	},
	message: {
		color: '#858585',
		fontSize: 14,
	},
	title: {
		color: titles,
		fontSize: 16,
	},
});
