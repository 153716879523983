import { StyleSheet } from 'aphrodite';

export const CompactWidth = 1200;
const contentPadding = 40;
const contentPaddingCompact = 20;

export const styleSheet = StyleSheet.create({
	contentContainerChild: {
		paddingLeft: contentPadding,
		paddingRight: contentPadding,
	},
	contentContainerChildCompact: {
		paddingLeft: contentPaddingCompact,
		paddingRight: contentPaddingCompact,
	},
});
