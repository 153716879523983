import {
	DayOfWeek,
	IDailyIntervals,
	IInterval,
	IOperationResultNoValue,
	MeetingConfigViewModel,
	asApiError,
} from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useEventLogging } from '../../../../../../models/Logging';
import { useErrorMessages, useToaster } from '../../../../../../models/hooks/appStateHooks';
import { ISelectOption, Select } from '../../../../Select';
import { TextInput } from '../../../../TextInput';
import { Toggle } from '../../../../Toggle';
import { AvailabilityTimeProperty, MeetingConfigAvailability } from '../../MeetingConfigAvailability';
import { styleSheet } from '../styles';

interface IProps {
	meeting: MeetingConfigViewModel;
}

const defaultIncrement = 30;

const yesNoOptions: ISelectOption<boolean>[] = [
	{
		dataContext: false,
		id: 'yesno-option-no',
		text: 'No',
	},
	{
		dataContext: true,
		id: 'yesno-option-yes',
		text: 'Yes',
	},
];

const hoursOrDaysOptions: ISelectOption<string>[] = [
	{
		dataContext: 'days',
		id: 'hoursOrDays-option-days',
		text: 'days',
	},
	{
		dataContext: 'hours',
		id: 'hoursOrDays-option-hours',
		text: 'hours',
	},
];

const timeInAdvanceOptions: ISelectOption<number>[] = [
	1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
].map(n => ({
	dataContext: n,
	id: `time-in-advance-option-${n}`,
	text: `${n}`,
}));

const defaultInterval = {
	endMinutes: 1020,
	startMinutes: 540,
};

const defaultAvailability: IDailyIntervals[] = [
	// @ts-ignore
	null,
	{ intervals: [defaultInterval] },
	{ intervals: [defaultInterval] },
	{ intervals: [defaultInterval] },
	{ intervals: [defaultInterval, defaultInterval] },
	{ intervals: [defaultInterval] },
	// @ts-ignore
	null,
];

const lookForHoursOrDaysRegex = new RegExp(/([0-9]|[1-9][0-9])\./);

export const SchedulingLimits: React.FC<IProps> = observer(({ meeting }) => {
	const logger = useEventLogging();
	const errorMessages = useErrorMessages();
	const toaster = useToaster();
	const [meetingAvailability, setMeetingAvailability] = useState(meeting?.availability ?? defaultAvailability);
	const [allowSameDayMeetings, setAllowSameDayMeetings] = useState(
		meeting?.allowSameDay ? yesNoOptions[1] : yesNoOptions[0]
	);

	// @ts-ignore
	const matchIfHaveDays = meeting?.atLeastInterval.match(lookForHoursOrDaysRegex);

	const [hoursOrDays, setHoursOrDays] = useState<ISelectOption<string>>(
		hoursOrDaysOptions.find(i => {
			if (matchIfHaveDays) {
				return i.dataContext === 'days';
			}
			return i.dataContext === 'hours';
		}) ?? hoursOrDaysOptions[0]
	);

	const [meetingAtLeastInterval, setMeetingAtLeastInterval] = useState<ISelectOption<number>>(
		timeInAdvanceOptions.find(i => {
			if (matchIfHaveDays) {
				return i.dataContext === Number(matchIfHaveDays[0].replace('.', ''));
			}
			// @ts-ignore
			return i.dataContext === parseInt(meeting?.atLeastInterval.split(':')[0], 10);
		}) ?? timeInAdvanceOptions[0]
	);

	const [maxPerDay, setMaxPerDay] = useState(meeting?.maxPerDay || 0);
	const [maxPerMonth, setMaxPerMonth] = useState(meeting?.maxPerMonth || 0);

	useEffect(() => {
		meeting.setAsEditing()?.catch((err: IOperationResultNoValue) => {
			logger.logApiError('MeetingConfigSetAsEditing-Error', err);
			// do not need to show user that this failed...
		});
		meeting.getAutomationChoices();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onAllowSameDayMeetingsChange = (option: ISelectOption<boolean>) => {
		setAllowSameDayMeetings(option);
		meeting.allowSameDay = option.dataContext;
	};

	const onChangeHoursOrDays = (option: ISelectOption<string>) => {
		setHoursOrDays(option);
		setMeetingAtLeastInterval(timeInAdvanceOptions[0]);
		const hours = option.dataContext === 'hours' ? 1 : 0;
		const days = option.dataContext === 'days' ? 1 : 0;
		meeting.setAtLeastInterval(hours, days);
	};

	const onChangeMeetingAtLeastInterval = (option: ISelectOption<number>) => {
		setMeetingAtLeastInterval(option);
		const hours = hoursOrDays.dataContext === 'hours' ? option.dataContext : 0;
		const days = hoursOrDays.dataContext === 'days' ? option.dataContext : 0;
		meeting.setAtLeastInterval(hours, days);
	};

	const onMaxPerDayChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const num = parseInt(e.target.value, 10);
		setMaxPerDay(num);
		meeting.maxPerDay = num;
	};

	const onMaxPerDayToggled = () => {
		if (maxPerDay > 0) {
			setMaxPerDay(0);
			meeting.maxPerDay = 0;
			return;
		}
		setMaxPerDay(3);
		meeting.maxPerDay = 3;
	};

	const onMaxPerMonthChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const num = parseInt(e.target.value, 10);
		setMaxPerMonth(num);
		meeting.maxPerMonth = num;
	};

	const onMaxPerMonthToggled = () => {
		if (maxPerMonth > 0) {
			setMaxPerMonth(0);
			meeting.maxPerMonth = 0;
			return;
		}
		setMaxPerMonth(30);
		meeting.maxPerMonth = 30;
	};

	const onTimeChange = (day: DayOfWeek, block: number, property: AvailabilityTimeProperty, value: number) => {
		const updated: any = { ...meetingAvailability[day] };
		if (updated?.intervals?.length) {
			updated.intervals[block][property] = value;

			// ensure that endMinutes are never earlier than startminutes
			if (property === 'startMinutes' && updated.intervals[block].endMinutes <= updated.intervals[block].startMinutes) {
				updated.intervals[block].endMinutes = updated.intervals[block].startMinutes + defaultIncrement;
			}

			meeting
				.setAvailability(day, updated)
				?.then(() => {
					// @ts-ignore
					setMeetingAvailability(meeting.availability);
				})
				?.catch((err: IOperationResultNoValue) => {
					logger.logApiError('ScheduleMeetingAvailabilityTimeChange-Error', err);
					// @ts-ignore
					errorMessages.pushApiError(err);
				});
			return;
		}
		// @ts-ignore
		toaster.push({
			message: 'Error getting availabilities.',
			type: 'errorMessage',
		});
		logger.logApiError(
			'MeetingConfigModal-onTimeChange-Error',
			asApiError(new Error('updated.intervals does not exist'))
		);
	};

	const addDay = (day: DayOfWeek) => {
		const update = { intervals: [defaultInterval] };
		updateAvailability(day, update);
	};

	const addSubblock = (day: DayOfWeek) => {
		const intervals = meetingAvailability[day].intervals;
		// @ts-ignore
		// @ts-ignore
		const lastInterval = intervals[intervals?.length - 1];
		const newInterval: IInterval = {
			// @ts-ignore
			endMinutes: lastInterval?.endMinutes + defaultIncrement * 2,
			// @ts-ignore
			startMinutes: lastInterval?.endMinutes + defaultIncrement,
		};
		const update = {
			// @ts-ignore
			intervals: [...intervals, newInterval],
		};
		updateAvailability(day, update);
	};

	const removeSubblock = (day: DayOfWeek) => {
		const newIntervals = meetingAvailability[day].intervals;
		// @ts-ignore
		newIntervals.pop();
		const update = {
			intervals: newIntervals,
		};
		updateAvailability(day, update);
	};

	const updateAvailability = (day: DayOfWeek, update: IDailyIntervals) => {
		meeting
			.setAvailability(day, update)
			?.then(() => {
				setMeetingAvailability(
					meetingAvailability.map((a, i) => {
						if (i === day) {
							a = update;
						}

						return a;
					})
				);
			})
			?.catch((err: IOperationResultNoValue) => {
				logger.logApiError('ScheduleMeetingAvailabilityAddDay-Error', err);
				// @ts-ignore
				errorMessages.pushApiError(err);
			});
	};

	const toggleAvailableDay = (day: number) => {
		const updated = meetingAvailability.map((a, idx) => {
			return meetingAvailability[day]
				? idx === day
					? null
					: a
				: idx === day
					? {
							intervals: [
								{
									endMinutes: 1020,
									startMinutes: 540,
								},
							],
						}
					: a;
		});

		meeting
			// @ts-ignore
			.setAvailability(day, updated[day])
			?.then(() => {
				// @ts-ignore
				setMeetingAvailability(updated);
			})
			?.catch((err: IOperationResultNoValue) => {
				logger.logApiError('ScheduleMeetingAvailability-Error', err);
				// @ts-ignore
				errorMessages.pushApiError(err);
			});
	};

	const isHoursInAdvance = hoursOrDays.dataContext === 'hours';

	return (
		<div className={css(styleSheet.wrapper)}>
			<div className={css(styleSheet.col)}>
				<h3 className={css(styleSheet.sectionTitle)}>Scheduling Limits</h3>
				<p className={css(styleSheet.label)}>Scheduling Limits</p>
				<div className={css(styleSheet.subLabel)}>
					<b>Allow invitees to schedule immediately...</b>
				</div>
				<Select
					styles={[styleSheet.smallInput]}
					options={yesNoOptions}
					onOptionClick={onAllowSameDayMeetingsChange}
					selectedOption={allowSameDayMeetings}
				/>
				{!allowSameDayMeetings.dataContext && (
					<div className={css(styleSheet.numberInputContainer)}>
						<span className={css(styleSheet.text)}>Invitees must schedule meetings&nbsp;&nbsp;</span>
						<Select
							styles={[styleSheet.smallInput, styleSheet.numberInput, styleSheet.timeInAdvanceSelect]}
							options={[...timeInAdvanceOptions].slice(0, isHoursInAdvance ? 23 : 15)}
							onOptionClick={onChangeMeetingAtLeastInterval}
							selectedOption={meetingAtLeastInterval}
						/>
						&nbsp;
						<Select
							styles={[styleSheet.hoursOrDaysSelect]}
							options={hoursOrDaysOptions}
							onOptionClick={onChangeHoursOrDays}
							selectedOption={hoursOrDays}
						/>
						&nbsp;
						<span className={css(styleSheet.text)}>in advance</span>
					</div>
				)}
				<div className={css(styleSheet.spacer)} />
				<p className={css(styleSheet.label)}>Max Meeting Limits</p>
				<div className={css(styleSheet.subLabel)}>
					<b>Setup the max amount of meetings of this type allowed</b>
				</div>
				<div className={css(styleSheet.numberInputContainer)}>
					<Toggle
						id='max-same-day-meetings-toggle'
						isOn={maxPerDay > 0}
						onToggleCheckChanged={onMaxPerDayToggled}
						uncheckedColor='#ccc'
						className={css(styleSheet.toggle)}
					/>
					<TextInput
						className={css(styleSheet.smallInput, styleSheet.numberInput)}
						inputId='max-same-day-meetings-input'
						min={1}
						max={10}
						onChange={onMaxPerDayChanged}
						step={1}
						type='number'
						value={maxPerDay}
						disabled={maxPerDay === 0}
					/>
					<span className={css(styleSheet.text, maxPerDay === 0 && styleSheet.textDisabled)}>
						Maximum meetings per <u>day</u>
					</span>
				</div>
				<div className={css(styleSheet.numberInputContainer)}>
					<Toggle
						id='max-same-month-meetings-toggle'
						isOn={maxPerMonth > 0}
						onToggleCheckChanged={onMaxPerMonthToggled}
						uncheckedColor='#ccc'
						className={css(styleSheet.toggle)}
					/>
					<TextInput
						className={css(styleSheet.smallInput, styleSheet.numberInput)}
						inputId='max-same-month-meetings-input'
						min={1}
						max={50}
						onChange={onMaxPerMonthChanged}
						step={1}
						type='number'
						value={maxPerMonth}
						disabled={maxPerMonth === 0}
					/>
					<span className={css(styleSheet.text, maxPerMonth === 0 && styleSheet.textDisabled)}>
						Maximum meetings per <u>month</u>
					</span>
				</div>
			</div>
			<div className={css(styleSheet.col)}>
				<div className={css(styleSheet.availabilitiesContainer)}>
					<MeetingConfigAvailability
						meetingAvailability={meetingAvailability}
						onAddBlock={addSubblock}
						onAddDay={addDay}
						onChangeTime={onTimeChange}
						onRemoveBlock={removeSubblock}
						onToggleAvailable={toggleAvailableDay}
						increment={defaultIncrement}
					/>
				</div>
			</div>
		</div>
	);
});
