import { borderColor, titles } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	frequencyContainer: {
		backgroundColor: 'white',
		border: `1px solid ${borderColor}`,
		borderRadius: 3,
		boxShadow: `0px 2px 4px 0px ${borderColor}`,
		boxSizing: 'border-box',
		color: titles,
		fontSize: '14px',
		marginTop: 5,
		width: 200,
		zIndex: 2,
	},
	optionContainer: {
		':hover': {
			backgroundColor: borderColor,
		},
		':not(:last-child)': {
			borderBottom: '1px solid #f0eded',
		},
		color: titles,
		cursor: 'pointer',
		padding: '8px 10px',
	},
});
