import { TinyColor } from '@ctrl/tinycolor';
import { StyleSheet, css } from 'aphrodite';
import * as React from 'react';
import { DashboardCardTag } from '../../../components/Happiness/DashboardCard';
import { styleSheet } from './styles';

const BRIGHTNESS_THRESHOLD = 200;

const lighten = (tinyColor: TinyColor) => {
	let newColor = tinyColor;
	let newLuminance = 0;

	do {
		newColor = newColor.lighten(2);
		newLuminance = newColor.getLuminance();
	} while (newLuminance < 0.85);

	return newColor;
};

export const Badge = ({ text, foregroundColor }: { text: string; foregroundColor: string }) => {
	const dynamicStyleSheet = React.useMemo(() => {
		const baseColor = new TinyColor(foregroundColor);
		const brightness = baseColor.getBrightness();
		const shouldLighten = brightness < BRIGHTNESS_THRESHOLD;
		const backgroundColor = (shouldLighten ? lighten(baseColor) : baseColor.darken(50)).toHexString();

		const color = baseColor.toHexString();
		return StyleSheet.create({
			color: {
				color,
				backgroundColor,
			},
		});
	}, [foregroundColor]);

	return (
		<div className={css(styleSheet.badge)}>
			<DashboardCardTag className={css(dynamicStyleSheet.color, styleSheet.campaignTag)}>{text}</DashboardCardTag>
		</div>
	);
};
