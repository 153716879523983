import * as React from 'react';
import { SvgIcon } from '../SvgIcon';

type Props = React.SVGProps<SVGSVGElement>;

export const ImageMissingIcon = (props: Props) => {
	return (
		<SvgIcon {...props} viewBox='0 0 180 180' height={props.height ?? 180} width={props.width ?? 180}>
			<rect x='1' y='1' width='178' height='178' rx='7' fillOpacity='0' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M38 122L74.2673 59.0471L102.723 108.861L117.693 88.4007L141.456 122H38Z'
				fill='#D3DBDE'
			/>
			<circle cx='53.8069' cy='67.5738' r='9.57377' fill='#D3DBDE' />
		</SvgIcon>
	);
};
