import { brandPrimary } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
	disabled?: boolean;
}

export const KeywordIcon: React.FC<IProps> = props => {
	const { className, fillColor, disabled } = props;

	return (
		<SvgIcon
			className={`keyword-icon ${className || ''}`}
			height={24}
			width={24}
			style={{ opacity: disabled ? 0.5 : 1 }}
		>
			<g fill={fillColor || brandPrimary} fillRule='evenodd'>
				<path d='M0 0h24v24H0z' fill='none' />
				<path d='M10.758 11.828l7.849-7.849 1.414 1.414-1.414 1.415 2.474 2.474-1.414 1.415-2.475-2.475-1.414 1.414 2.121 2.121-1.414 1.415-2.121-2.122-2.192 2.192a5.002 5.002 0 0 1-7.708 6.294 5 5 0 0 1 6.294-7.708zm-.637 6.293A3 3 0 1 0 5.88 13.88a3 3 0 0 0 4.242 4.242z' />
			</g>
		</SvgIcon>
	);
};
