import { css } from 'aphrodite';
import * as Api from '@ViewModels';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	useContact,
	useContactFilterQuery,
	useDashboardFeed,
	useContactSuggestionsInfiniteQuery,
	getFeedItemViewModel,
} from '../../../../queries';
import * as React from 'react';
import { v4 as uuidgen } from 'uuid';
import { ContactViewModel } from '../../../../viewmodels/AppViewModels';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { EditableContact } from '../../../components/contacts/EditableContact';
import { FabContext } from '../../../components/FabContext';
import { ContactActivity } from '../../People/Contact/Activity';
import { styleSheet } from './styles';
import { FullscreenModalNavHeader } from '../../../components/fullscreen/FullscreenModalNavHeader';
import { useFocusedViewContext } from './FocusedViewProvider/context';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { baseStyleSheet } from '../../../styles/styles';
import { remindersToKeepInTouchWithContacts } from '../../../../models/UiUtils';

export const KIT_FEED_TYPE = 'Reminder';

export const FocusedContact = ({
	initialContactIdsToOmit = [],
	initialFilterCriteriaList,
	initialContactIds,
	mode,
}: {
	initialContactIdsToOmit?: string[];
	initialFilterCriteriaList?: Api.IContactFilterCriteria[];
	initialContactIds?: string[];
	mode: 'contact' | 'kit' | 'suggestions';
}) => {
	const [totalCount, setTotalCount] = React.useState<number>(initialContactIds?.length ?? 0);
	const { setContactIds, contactIds, selectedContactId, setSelectedContactId } = useFocusedViewContext();
	const [scrollToBottomWaypointPortalId] = React.useState(uuidgen());

	React.useEffect(() => {
		if (initialContactIds?.length > 0) {
			setContactIds(initialContactIds);
			setSelectedContactId(initialContactIds[0]);
		}
		return () => {
			setContactIds([]);
			setSelectedContactId(null);
			setTotalCount(0);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const contactQuery = useContact({
		contactId: selectedContactId,
		enabled: !!selectedContactId,
	});

	const isLoading = contactQuery.isLoading;

	const contact = contactQuery.data || null;

	const userSession = useUserSession();

	// If selected contact ids were not passed in then enable a paged filtering mode for contacts
	const contactFilterQuery = useContactFilterQuery({
		enabled: mode === 'contact' && initialContactIds?.length === 0,
		filterRequest: {
			filter: {
				criteria: initialFilterCriteriaList,
			},
		},
	});

	// If the kit button directs here it does not support paged fetching so fetch 100
	const dashboardFeedQuery = useDashboardFeed({
		enabled: mode === 'kit',
		pageSize: 100,
		typeOf: KIT_FEED_TYPE,
	});

	const contactSuggestionsQuery = useContactSuggestionsInfiniteQuery({
		enabled: mode === 'suggestions',
		pageSize: 25,
	});

	React.useEffect(() => {
		if (mode !== 'contact') {
			return;
		}
		const loadedContactIds = contactFilterQuery.data?.pages
			?.map(page => {
				return page.values;
			})
			.flat()
			.map(item => item.id)
			.filter(item => initialContactIdsToOmit.indexOf(item) === -1);

		if (loadedContactIds?.length > 0) {
			setContactIds(loadedContactIds);
			if (!selectedContactId) {
				setSelectedContactId(loadedContactIds[0]);
			}
			setTotalCount(contactFilterQuery.data?.pages?.[0]?.totalCount ?? 0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contactFilterQuery.data]);

	React.useEffect(() => {
		if (mode !== 'kit') {
			return;
		}
		const values = dashboardFeedQuery.data?.values ?? [];
		const actionItemVms = values
			.map((item: Api.IDashboardFeedItem) => getFeedItemViewModel({ feedItem: item, userSession }))
			.filter((itemVm: any) => itemVm instanceof Api.ActionItemViewModel) as Api.ActionItemViewModel[];
		const { contacts } = remindersToKeepInTouchWithContacts(actionItemVms);
		const loadedContactIds = contacts
			.map(loadedContact => loadedContact.id)
			.filter(loadedContact => initialContactIdsToOmit.indexOf(loadedContact) === -1);

		if (loadedContactIds?.length > 0) {
			setContactIds(loadedContactIds);
			if (!selectedContactId) {
				setSelectedContactId(loadedContactIds[0]);
			}
			setTotalCount(dashboardFeedQuery.data?.totalCount ?? 0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dashboardFeedQuery.data]);

	React.useEffect(() => {
		if (mode !== 'suggestions') {
			return;
		}
		const loadedContactIds = contactSuggestionsQuery.data?.pages
			?.map(page => {
				return page.values;
			})
			.flat()
			.map(item => item.id)
			.filter(item => initialContactIdsToOmit.indexOf(item) === -1);

		if (loadedContactIds?.length > 0) {
			setContactIds(loadedContactIds);
			if (!selectedContactId) {
				setSelectedContactId(loadedContactIds[0]);
			}
			setTotalCount(contactSuggestionsQuery.data?.pages?.[0]?.totalCount ?? 0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contactSuggestionsQuery.data]);

	const next = async () => {
		const currentIndex = contactIds.indexOf(selectedContactId);

		const isLastItem = currentIndex + 1 === contactIds.length;
		if (isLastItem) {
			if (totalCount === contactIds.length) {
				return;
			}
			if (mode === 'contact') {
				await contactFilterQuery.fetchNextPage();
			} else if (mode === 'kit') {
				await contactFilterQuery.fetchNextPage();
			}
		}

		const nextContactId = contactIds[currentIndex + 1];
		setSelectedContactId(nextContactId);
	};

	const back = () => {
		const currentIndex = contactIds.indexOf(selectedContactId);
		if (currentIndex === 0) {
			return;
		}
		const nextContactId = contactIds[currentIndex - 1];
		setSelectedContactId(nextContactId);
	};

	if (!contactIds?.length) {
		return null;
	}
	const contactVm = contact ? new ContactViewModel(userSession, contact) : null;

	const currentContactPosition = contactIds.indexOf(selectedContactId) + 1;
	const countHeader = `${currentContactPosition}/${totalCount}`;

	return (
		<div className={`${css(styleSheet.container)}`}>
			<MultiContainerHeader
				navHeader={
					<FullscreenModalNavHeader next={next} back={back}>
						{countHeader}
					</FullscreenModalNavHeader>
				}
			/>

			{!contactVm ? <LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} /> : null}

			{contactVm ? (
				<>
					<div className={css(styleSheet.splitMaster)}>
						<EditableContact contact={contactVm} hideEditButton={false} isLoading={isLoading} />
					</div>
					<div className={css(styleSheet.splitDetail)}>
						<ContactActivity
							className={[styleSheet.activity]}
							contact={contactVm}
							scrollToBottomWaypointPortalId={scrollToBottomWaypointPortalId}
							userSession={userSession}
						/>
					</div>
				</>
			) : null}

			<FabContext appearance={{ hidden: true }} />
		</div>
	);
};
