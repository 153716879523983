import { StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../../styles/styles';
import { brandPrimaryText, inputBorderColor, white } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	dropdown: {
		display: 'flex',
		flexDirection: 'column',
		gap: 12,
		padding: '12px 12px',
		height: 'auto',
		opacity: 1,
		border: `1px solid ${inputBorderColor}`,
		position: 'fixed',
		marginTop: 38,
		borderTopRightRadius: 8,
		borderTopLeftRadius: 0,
		borderBottomRightRadius: 8,
		borderBottomLeftRadius: 8,
		overflow: 'hidden',
		background: white,
		width: 431,
		zIndex: 1,
	},
	addNewButton: {
		color: brandPrimaryText,
	},
	searchInputWrap: {
		display: 'flex',
		width: 300,
		...CssSelectors.allChildren(
			{
				borderRadius: 8,
				width: '100%',
			},
			'.text-input'
		),
	},
	popoverOpen: {
		borderBottomLeftRadius: '0 !important',
		borderBottomRightRadius: '0 !important',
	},
	searchIcon: {
		marginRight: 6,
		flexShrink: 0,
	},
});
