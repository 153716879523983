import { useEventLogging } from '../../../../../models/Logging';
import * as Api from '@ViewModels';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

export function useLaunchFeedFilter({
	queryCommand,
	eventLoggingCategory,
}: {
	queryCommand: Api.IQueryStringCommand;
	eventLoggingCategory: string;
}) {
	const history = useHistory();
	const { logEvent } = useEventLogging(eventLoggingCategory);
	React.useEffect(() => {
		if (queryCommand?.command === 'feedfilter' && history) {
			const feedFilterCommand = queryCommand as Api.IDashboardFeedFilterCommand;
			logEvent('AppLink-FeedFilter', {
				activeFilters: feedFilterCommand.activeFilters,
			});

			if (feedFilterCommand?.activeFilters?.includes('ActionItem')) {
				history.push('/notes-action-items?cmd=action-item');
			} else if (feedFilterCommand?.activeFilters?.includes('ClassifyContact')) {
				window.open('/#/people/tagging-game');
			} else if (feedFilterCommand?.activeFilters?.includes('EmailActivity')) {
				history.push('/dashboard/recently-viewed-emails');
			}
		}
	}, [queryCommand, logEvent, history]);
}
