import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { useUserSession } from '../../../../../models/hooks/appStateHooks';
import donorPerfectLogo from '../../../../assets/logo_donorperfect_sm.png';
import { white } from '../../../../styles/colors';
import { baseStyleSheet } from '../../../../styles/styles';
import { DonorPerfectIntegrationGraphic } from '../../../svgs/graphics/DonorPerfectIntegrationGraphic';
import { IntegrationCard, useIntegrationCard } from '../IntegrationCard';
import { styleSheet } from './styles';

export const DashboardDonorPerfectIntegrationCard = () => {
	const userSession = useUserSession();
	const { getLogoImgProps, getCloseButtonProps, getCtaButtonProps, getIntegrationTitleProps, showCard } =
		useIntegrationCard({
			closeIntegrationEventName: 'DonorPerfectIntegrationClosed',
			enableUrlPath: '/integrations/donorperfect',
			integrationEnableEventName: 'DonorPerfectIntegrationEnableClick',
			pendingAction: Api.PendingActions.ConnectDonorPerfect,
		});

	const isConnected = userSession.account.integrations?.donorPerfect?.isConnected ?? false;

	if (!showCard || isConnected) {
		return null;
	}

	return (
		<IntegrationCard
			closeButtonProps={getCloseButtonProps()}
			IntegrationGraphic={DonorPerfectIntegrationGraphic}
			headerLabel='DonorPerfect Integration'
			logoBgColor={white}
			logoImgProps={getLogoImgProps({
				alt: 'DonorPerfect Logo',
				className: css(styleSheet.logo),
				src: donorPerfectLogo,
			})}
			ctaButtonProps={getCtaButtonProps({
				children: 'Connect with DonorPerfect',
				styleDeclaration: [baseStyleSheet.ctaButtonReverse, styleSheet.ctaButton],
			})}
			integrationTitleProps={getIntegrationTitleProps({
				styleDeclaration: styleSheet.integrationTitle,
			})}
		>
			<p>
				We are excited to announce that you can integrate your DonorPerfect account with Levitate. With this
				integration, we will have the ability to pull client information, including names, email, phone number,
				birthdays, anniversaries, and more &mdash; making staying in touch even easier.
			</p>
		</IntegrationCard>
	);
};
