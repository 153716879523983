import { styleSheet } from './styles';
import { TinyColor } from '@ctrl/tinycolor';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	currentIndex: number;
	hideFutureStages?: boolean;
	total: number;
	type?: 'default' | 'compact';
}

// color to start with... first control color
const start = { h: 89, s: 19, v: 95 }; // #DBF2C3

// second control color
const end = { h: 196, s: 100, v: 91 }; // #00AAE8

const getColorRange = (count: number, secondControlPointOffset?: number) => {
	const secondCtrlPointOffset = Math.max(4, secondControlPointOffset || 4);
	const max = Math.max(0, count || 0);

	const result: string[] = [];
	for (let i = 0; i < max; i++) {
		const hsv = {
			h: start.h + ((end.h - start.h) / secondCtrlPointOffset) * i,
			s: start.s + ((end.s - start.s) / secondCtrlPointOffset) * i,
			v: start.v + ((end.v - start.v) / secondCtrlPointOffset) * i,
		};
		result.push(new TinyColor(hsv).toHexString());
	}
	return result;
};

export const StageIndicator: React.FC<IProps> = props => {
	const { className, total, currentIndex, hideFutureStages, type } = props;

	const range = getColorRange(total);
	let pills = range.map((x, i) => {
		const style: React.CSSProperties = {};
		if (i > currentIndex) {
			style.border = `1px solid #D3DBDE`;
		} else {
			style.background = x;
		}

		return (
			<div className={css(styleSheet.pill, type === 'compact' && styleSheet.compact)} key={`${i}`} style={style} />
		);
	});

	if (hideFutureStages) {
		pills = pills.filter((_, i) => i <= currentIndex);
	}

	return <div className={`${css(styleSheet.indicator)} ${className || ''}`}>{pills}</div>;
};
