import * as Api from '@ViewModels';
import {
	ICreateCampaignRequest,
	ICreateSocialMediaPostRequest,
	ILocationState,
	TemplateOrTemplateFilter,
} from '../../../../models';
import { useEventLogging } from '../../../../models/Logging';
import {
	useErrorMessages,
	useFullscreenModal,
	useToaster,
	useUserSession,
} from '../../../../models/hooks/appStateHooks';
import { invalidateSocialDrafts, useDeleteSocialPostMutation } from '../../../../queries';

export const useSocialMediaCampaignBehavior = (loggingCategory?: string) => {
	const userSession = useUserSession();
	const fullscreenModal = useFullscreenModal();
	const errorMessages = useErrorMessages();
	const toaster = useToaster();
	const { logApiError } = useEventLogging(loggingCategory);
	const onViewSocialCampaign = async (templateId: string) => {
		try {
			const templates = new Api.TemplatesViewModel(userSession);
			const template = await templates.getByIdExpandedByLastUsedBy(templateId);

			const locationState: ILocationState<any, ICreateSocialMediaPostRequest<Api.ITemplate>> = {
				model: {
					context: { ...template },
					type: 'Template',
				},
			};

			fullscreenModal.history.push({
				pathname: `/social-media/post/create/from-template`,
				state: locationState,
			});
		} catch (error) {
			errorMessages.pushApiError(error);
			logApiError('LoadCategories-Error', error);
		}
	};

	const onViewAssociatedCampaign = async (templateId: string) => {
		try {
			const templates = new Api.TemplatesViewModel(userSession);
			const template = await templates.getByIdExpandedByLastUsedBy(templateId);

			const locationState: ILocationState<any, ICreateCampaignRequest<TemplateOrTemplateFilter>> = {
				model: {
					context: { ...template },
					type: 'Template',
				},
			};

			fullscreenModal.history.push({
				pathname: '/email/campaigns/create/from-template',
				state: locationState,
			});
		} catch (error: any) {
			errorMessages.pushApiError(error);
			logApiError('LoadCategories-Error', error);
		}
	};

	const deleteSocialPostMutation = useDeleteSocialPostMutation({
		onSuccess: () => {
			invalidateSocialDrafts();
			toaster.push({
				message: 'Social media post deleted',
				type: 'successMessage',
			});
		},
		onError: error => {
			errorMessages.pushApiError(error);
			logApiError('DeleteSocialPost-Error', error);
		},
	});

	const onSocialMediaDraftClicked = async (postCard: Api.ISocialMediaPost) => {
		try {
			const postVm = new Api.SocialMediaPostViewModel(userSession, postCard);
			await postVm.load();

			const locationState: ILocationState<Api.SocialMediaPostViewModel, any> = {
				viewModel: postVm,
			};
			fullscreenModal.history.push({
				pathname: `/social-media/post/edit-post-draft/${postVm.id}`,
				state: locationState,
			});
		} catch (error) {
			errorMessages.pushApiError(error);
			logApiError('LoadPost-Error', error);
		}
	};

	return { onViewSocialCampaign, onViewAssociatedCampaign, deleteSocialPostMutation, onSocialMediaDraftClicked };
};
