import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useLocation, useParams } from 'react-router';
import { getDisplayNameForBulkEmail, getPrincipalInitials } from '../../../../models/UiUtils';
import { useFullscreenModal, useUserSession } from '../../../../models/hooks/appStateHooks';
import { useAutomationTemplateIdQuery } from '../../../../queries';
import { Avatar2 } from '../../../components/Avatar2';
import { Checkbox } from '../../../components/Checkbox';
import { ComposerSendFromDropdown } from '../../../components/ComposerSendFromDropdown';
import { DeprecatedCloseButton } from '../../../components/DeprecatedCloseButton';
import { FabContext } from '../../../components/FabContext';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { AutoCompleteSearchField } from '../../../components/autocomplete/AutoCompleteSearchField';
import { SearchIcon } from '../../../components/svgs/icons/SearchIcon';
import { baseStyleSheet as bs } from '../../../styles/styles';
import { CampaignComposerBaseLayout } from '../../BulkHwcPages/presentation';
import { PhoneMissingPill } from '../PhoneMissingPill';
import { useTextingCampaignContacts } from '../hooks/useTextingCampaignContacts';
import { styleSheet } from '../styles';
import { TextCampaignTemplateBody } from './TextCampaignTemplateBody';

export function TextCampaignTemplateContactsPage() {
	const userSession = useUserSession();
	const location = useLocation<{
		contactIds?: string[];
		initialSendFrom?: Api.ISendEmailFrom;
		initialSendFromUserId?: string;
		initialCampaignTitle?: string;
		initialContentEditorState?: Api.IRichContentEditorState;
		initialAttachmentFiles?: File[];
	}>();
	const fullscreenModal = useFullscreenModal();
	const { automationTemplateId } = useParams<{ automationTemplateId: string }>();
	const automationTemplateIdQuery = useAutomationTemplateIdQuery({
		id: automationTemplateId,
	});
	const [sendFrom, setSendFrom] = React.useState<Api.ISendEmailFrom>(
		location.state?.initialSendFrom ?? Api.SendEmailFrom.CurrentUser
	);
	const [sendFromUserId, setSendFromUserId] = React.useState<string | null>(location.state?.initialSendFromUserId);
	const { state, dispatch, contactQueries, numberOfRecipients } = useTextingCampaignContacts({
		initialContactIds: location.state?.contactIds,
	});
	const onNextButtonClick = () => {
		fullscreenModal.history.push(`/texting-campaign/schedule-send/${automationTemplateId}`, {
			request: {
				includeContactIds: state.contactIds,
				sendFromOptions: {
					mode: sendFrom,
					selectedUser: sendFromUserId ?? undefined,
				},
				autoStart: false,
				putOnHold: false,
				skipIfInProgress: true,
				skipIfInPrevious: false,
				groupByHousehold: false,
			},
		});
	};
	const handleRemoveRecipient = (contact: Api.IContact) => {
		if (state.contactIds.length === 1) {
			fullscreenModal.history.push({
				pathname: `/texting-campaign/template-search/${automationTemplateId}`,
				state: {
					initialSendFrom: sendFrom,
					initialSendFromUserId: sendFromUserId,
				},
			});
		}
		dispatch({ type: 'removeRecipient', value: contact.id! });
	};
	const renderRecipientItem = (contact: Api.IContact) => {
		let companyLine = '';
		if (contact.companyName != null) {
			companyLine = contact.jobTitle != null ? `${contact.jobTitle} at ${contact.companyName}` : contact.companyName;
		}
		const nameLabel = getDisplayNameForBulkEmail(contact);
		return (
			<div
				className={css(
					bs.flex,
					bs.itemsCenter,
					bs.border0,
					bs.borderB,
					bs.borderSolid,
					bs.borderGray200,
					bs.cursorPointer,
					bs.py5,
					bs.px0,
					bs.relative
				)}
				key={contact.id}
			>
				<DeprecatedCloseButton
					onClick={ev => {
						ev.stopPropagation();
						handleRemoveRecipient(contact);
					}}
				/>
				<Avatar2
					styleDeclaration={styleSheet.recipientsListItemAvatar}
					imgSrc={contact.profilePic}
					fallbackText={getPrincipalInitials(contact)}
				/>

				<div className={css(bs.flex1, bs.textSm, bs.ml4, bs.overflowHidden)}>
					<div className={css(styleSheet.recipientsListItemDetailsName)}>{nameLabel}</div>
					<div className={css(styleSheet.recipientsListItemDetailsCompanyName, bs.truncateText)}>{companyLine}</div>
				</div>
				{!contact.canReceiveText ? <PhoneMissingPill styleDeclarations={[bs.absolute, bs.bottom2, bs.right2]} /> : null}
			</div>
		);
	};
	return (
		<>
			<MultiContainerHeader
				fullscreenHeader='New Texting Campaign'
				onFullscreenRequestBack={ev => {
					ev.preventDefault();
					fullscreenModal.dismissModal();
				}}
				onFullscreenRequestClose={() => fullscreenModal.dismissModal()}
			/>
			<CampaignComposerBaseLayout
				mainHeader={
					<div className={css(bs.px3, bs.py5, bs.flex, bs.gap2, bs.flexCol)}>
						<div className={css(bs.mb2)}>
							{automationTemplateIdQuery.status === 'success' ? (
								<h2 className={css(bs.m0)}>{automationTemplateIdQuery.data?.name}</h2>
							) : (
								<LoadingSpinner type='small' />
							)}
						</div>
						<ComposerSendFromDropdown
							disabled={!userSession.canSendOnBehalf}
							sendFrom={sendFrom}
							sendFromUserId={sendFromUserId}
							onChange={params => {
								setSendFrom(params.sendFrom);
								setSendFromUserId(params.sendFromUserId);
							}}
						/>
					</div>
				}
				sideBar={
					<>
						<div className={css(styleSheet.recipientsHeader)}>
							<div className={css(styleSheet.recipientsHeaderTitle)}>
								<div>Recipients</div>
							</div>
							<div className={css(bs.flex, bs.itemsCenter, bs.flexWrap, bs.textSm, bs.justifyStart)}>
								<div className={css(bs.flex, bs.mr2)}>
									{numberOfRecipients != null
										? `${numberOfRecipients} out of ${state.contactIds.length} contact${
												state.contactIds.length !== 1 ? 's' : ''
											} with phone numbers`
										: null}
								</div>
							</div>
							<AutoCompleteSearchField
								anchorClassName='email-recipients-list-autocomplete-search-field-anchor'
								className='email-recipients-list-autocomplete-search-field'
								clearSearchFieldAfterSelectingItem={true}
								dropdownContentClassName={css(bs.wFull)}
								inputId='email-recipients-list-search-input'
								inputProps={{
									placeholder: 'Add additional contacts',
								}}
								leftAccessory={<SearchIcon className='email-recipients-list-autocomplete-search-field-icon' />}
								onItemSelected={(contact: Api.IContact) => {
									if (contact.id) {
										dispatch({ type: 'addRecipient', value: contact.id });
									}
								}}
								resultsLimit={6}
								type={Api.ResourceAutoCompleteViewModelType.Contact}
							/>
						</div>
						<div className={css(bs.flex, bs.flex1, bs.flexCol, bs.overflowXHidden, bs.overflowYAuto)}>
							<div>
								{contactQueries.map((contactQuery, i) => {
									if (!contactQuery.data) {
										return <LoadingSpinner key={i} type='small' />;
									}
									return renderRecipientItem(contactQuery.data);
								})}
							</div>
						</div>
						<div className={css(bs.py2, bs.px4, bs.border0, bs.borderT, bs.borderSolid, bs.borderGray200)}>
							<Checkbox
								id='textingCampaignAgreement-checkbox'
								className={css(bs.mb6)}
								checked={state.consentChecked}
								onChange={() => dispatch({ type: 'toggleConsentChecked' })}
							>
								<span className={css(bs.ml2)}>
									I agree that the recipients have opted in to receive texts and this is not a marketing campaign.
								</span>
							</Checkbox>
							<button
								className={css(bs.ctaButton, bs.wFull)}
								onClick={onNextButtonClick}
								disabled={!numberOfRecipients || !state.consentChecked}
							>
								<span>Next: Send Options</span>
							</button>
						</div>
					</>
				}
			>
				<>
					<FabContext appearance={{ hidden: true }} />
					<TextCampaignTemplateBody
						automationTemplateId={automationTemplateId}
						onDuplicateClick={({ title, contentEditorState, attachments }) => {
							fullscreenModal.history.push({
								pathname: `/texting-campaign/contacts`,
								state: {
									initialSendFrom: sendFrom,
									initialSendFromUserId: sendFromUserId,
									contactIds: state.contactIds,
									initialCampaignTitle: title,
									initialContentEditorState: contentEditorState,
									initialStoredAttachments: attachments,
								},
							});
						}}
					/>
				</>
			</CampaignComposerBaseLayout>
		</>
	);
}
