import * as Api from '@ViewModels';
import { observer } from 'mobx-react';
import * as React from 'react';
import { SizeConstraint } from '../../../../models';
import { Timeline } from './Timeline';
import { useContactTimeline } from './hooks/useTimeline';

interface IProps {
	className?: string;
	contactId: string;
	emailAddresses?: Api.EmailAddress[];
	onDeleteRichContent: (richContent: Api.RichContentViewModel) => Promise<Api.IRichContent>;
	onMoreMenuOptionClicked?(
		richContent: Api.RichContentViewModel,
		option: 'edit' | 'delete',
		e: React.MouseEvent<HTMLElement>
	): void;
	readonly?: boolean;
	renderAction?(evt: Api.TimelineEventViewModel): JSX.Element;
	renderActionFor?: Api.TimelineEventTypes[];
	renderContent?(evt: Api.TimelineEventViewModel): JSX.Element;
	renderContentFor?: Api.TimelineEventTypes[];
	placeholderText?: string;
	scrollToBottomWaypointPortalId?: string;
	sizeConstraint?: SizeConstraint;
	eventTypes: Api.TimelineEventTypes[];
	setShouldStartPollingForRelationshipUpdate?(poll: boolean): void;
}

function _ContactTimeline({
	contactId,
	emailAddresses,
	eventTypes,
	className,
	sizeConstraint,
	placeholderText,
	renderAction,
	renderActionFor,
	readonly,
	renderContentFor,
	renderContent,
	onDeleteRichContent,
	onMoreMenuOptionClicked,
	scrollToBottomWaypointPortalId,
	setShouldStartPollingForRelationshipUpdate,
}: IProps) {
	const timelineProps = useContactTimeline({
		contactId,
		emailAddresses,
		eventTypes,
		onDeleteRichContent,
		onMoreMenuOptionClicked,
		setShouldStartPollingForRelationshipUpdate,
	});
	return (
		<Timeline
			className={className}
			sizeConstraint={sizeConstraint}
			placeholderText={placeholderText}
			renderAction={renderAction}
			renderActionFor={renderActionFor}
			readonly={readonly}
			renderContentFor={renderContentFor}
			renderContent={renderContent}
			scrollToBottomWaypointPortalId={scrollToBottomWaypointPortalId}
			{...timelineProps}
		/>
	);
}

export const ContactTimeline = observer(_ContactTimeline);
