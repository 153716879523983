import { StyleSheet } from 'aphrodite';
import { brandSecondary } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	body: {
		marginBottom: 30,
	},
	container: {
		minWidth: 460,
	},
	footer: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	header: {
		color: brandSecondary,
		fontSize: 18,
		marginBottom: 16,
	},
	searchFieldDropDownContent: {
		width: 464,
	},
});
