import { styleSheet } from './styles';
import { brandSecondary } from '../../../../../../styles/colors';
import { baseStyleSheet } from '../../../../../../styles/styles';
import * as React from 'react';
import { css } from 'aphrodite';
import { FilesDropTarget } from '../../../../../FilesDropTarget';
import { LoadingSpinner } from '../../../../../LoadingSpinner';
import { SpreadsheetIcon } from '../../../../../svgs/icons/SpreadsheetIcon';
import { SvgIcon } from '../../../../../svgs/icons/SvgIcon';

const AcceptedFileExtensions = '.csv,.xlsx,.xls';

export const UploadStep = ({
	isLoading,
	file,
	setFile,
}: {
	isLoading: boolean;
	file: File;
	setFile: React.Dispatch<React.SetStateAction<File>>;
}) => {
	const hiddenFilesInputRef = React.useRef(null);
	const uploadHint = `Supported file types: ${AcceptedFileExtensions.split(',').reduce((x, y) => {
		return `${x}${x ? ', ' : ''}${y}`;
	}, '')}`;

	const onFilesInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;

		if (!!files && files.length > 0) {
			const filteredFiles = Array.from(files).filter(x => {
				const comps = x.name ? x.name.split('.').filter(y => !!y) : [];
				const ext = comps.length > 1 ? comps[comps.length - 1] : null;
				return ext ? AcceptedFileExtensions.indexOf(ext.toLocaleLowerCase()) >= 0 : false;
			});
			e.target.value = null;

			if (filteredFiles.length > 0) {
				setFile(filteredFiles[0]);
			}
		}
	};

	const onFileDropped = (files: File[]) => {
		if (files.length > 0) {
			setFile(files[0]);
		}
	};

	const onChooseFileButtonClicked = () => {
		if (hiddenFilesInputRef.current) {
			hiddenFilesInputRef.current.click();
		}
	};
	return (
		<div className={css(styleSheet.container)}>
			<div className={css(styleSheet.uploadField)}>
				{isLoading ? <LoadingSpinner className='absolute-center' type='large' /> : null}

				{!isLoading ? (
					<FilesDropTarget
						className={css(styleSheet.uploadTarget)}
						onFilesDropped={onFileDropped}
						supportedFileExtensions={AcceptedFileExtensions.split(',').map(x => x.slice(1))}
						supportMultiple={true}
						title={uploadHint}
					>
						<div className={css(styleSheet.uploadTargetOverlay)}>
							<SpreadsheetIcon className={css(styleSheet.uploadSpreadsheetIcon)} />
							{file ? (
								<div className={css(styleSheet.uploadTargetOverlayChosenFile)}>{file.name}</div>
							) : (
								<SvgIcon className={css(styleSheet.uploadTargetOverlayArrow)} height={27} width={20}>
									<polygon
										fill={brandSecondary}
										fillRule='evenodd'
										points='20 10.048 10 0 0 10.048 3.004 13.066 7.876 8.172 7.876 26.5 12.124 26.5 12.124 8.172 16.996 13.066'
									/>
								</SvgIcon>
							)}
							<input
								accept={AcceptedFileExtensions}
								multiple={false}
								onChange={onFilesInputChanged}
								ref={hiddenFilesInputRef}
								style={{
									height: 0,
									opacity: 0,
									pointerEvents: 'none',
									position: 'fixed',
									visibility: 'hidden',
									width: 0,
									zIndex: -1,
								}}
								type='file'
							/>
							<div className={css(styleSheet.uploadTargetOverlayMessage)}>
								<span>Drop any Excel, CSV, or</span>
								&nbsp;
								<button
									className={css(baseStyleSheet.brandSecondaryLink, styleSheet.uploadTargetOverlayButton)}
									onClick={onChooseFileButtonClicked}
								>
									<span>browse your files</span>
								</button>
							</div>
						</div>
					</FilesDropTarget>
				) : null}
			</div>
		</div>
	);
};
