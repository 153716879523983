import { css } from 'aphrodite';
import { bs } from '../../../styles/styles';
import { styleSheet } from './styles';

type Props = {
	recentEmailSubjects: string[] | undefined;
};

export const RecentEmailSubjects = ({ recentEmailSubjects }: Props) => {
	if (!recentEmailSubjects?.length) {
		return null;
	}
	const firstFourSubjectLines = recentEmailSubjects.slice(0, 4);
	const evenMaxWidthSizeSpacing = 100 / recentEmailSubjects.length;

	return (
		<div className={css(bs.pl2, bs.pr2, bs.flex, bs.itemsCenter, bs.gap3, styleSheet.recentEmailSubjectsContainer)}>
			{firstFourSubjectLines.map(subjectLine => (
				<div
					style={{ flex: `1 1 ${evenMaxWidthSizeSpacing}%` }}
					className={css(bs.emailSubjectBadge, bs.flex, bs.minW0, bs.maxWFit, bs.cursorDefault)}
					key={subjectLine}
				>
					<div title={subjectLine} className={css(bs.truncateText)}>
						{subjectLine}
					</div>
				</div>
			))}
		</div>
	);
};
