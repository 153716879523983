import { brandPrimaryHover, darkGrayFontColor, titles } from '../../../../styles/colors';
import { CtaButtons, truncateTextStyle } from '../../../../styles/styles';
import { CSSProperties, StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	avatar: {
		height: 80,
		width: 80,
	},
	body: {
		paddingTop: 44,
		textAlign: 'center',
	},
	container: {
		height: '100%',
	},
	footerContent: {
		alignItems: 'center',
		color: '#fff',
		display: 'flex',
		flexDirection: 'column',
		fontSize: 18,
		justifyContent: 'center',
	},
	header: {
		textAlign: 'center',
	},
	headerSubtitle: {
		color: '#4A4A4A',
		fontSize: 14,
		marginTop: 10,
	},
	headerTitle: {
		alignItems: 'center',
		color: titles,
		display: 'flex',
		fontSize: 18,
		justifyContent: 'center',
	},
	name: {
		maxWidth: 300,
		...truncateTextStyle,
	},
	section: {
		':not(:first-child)': {
			alignItems: 'center',
			background: brandPrimaryHover,
			display: 'flex',
			justifyContent: 'center',
		},
		height: '50%',
	},
	startOverButton: {
		alignItems: 'center',
		color: '#ff',
		display: 'flex',
		marginTop: 22,
		...(CtaButtons.ctaButton as CSSProperties),
	},
	titleAndCompany: {
		color: darkGrayFontColor,
		fontSize: 14,
		marginTop: 8,
	},
});
