import { StyleSheet } from 'aphrodite';
import { brandPrimary, brandSecondary, destructive, header } from '../../../styles/colors';
import { CssSelectors, DefaultBorder, truncateTextStyle } from '../../../styles/styles';

const avatarSize = 35;
const rightContainerSize = 95;

export const styleSheet = StyleSheet.create({
	avatar: {
		height: avatarSize,
		width: avatarSize,
	},
	avatarContainer: {
		alignItems: 'center',
		display: 'flex',
		marginRight: 10,
	},
	container: {
		':hover': {
			background: 'rgba(211, 219, 222, 0.4)',
			cursor: 'pointer',
		},
		alignItems: 'stretch',
		borderRadius: '4px',
		display: 'flex',
		padding: '10px 15px 10px 5px',
		position: 'relative',
		...CssSelectors.allDescendants(
			{
				margin: 0,
			},
			'p'
		),
	},
	contentWrapper: {
		'@media (max-width: 1200px)': {
			display: 'none',
		},
		alignItems: 'flex-start',
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		justifyContent: 'center',
		maxWidth: `calc(100% - ${avatarSize}px - 10px - ${rightContainerSize}px)`,
		...CssSelectors.allChildren(
			{
				maxWidth: 145, // IE11 Fallback for ellipsis overflow
			},
			'p'
		),
	},
	conversationNames: {
		...truncateTextStyle,
		fontSize: '14px',
	},
	errorContainer: {
		alignItems: 'center',
		display: 'flex',
		minWidth: '20px',
	},
	gray: {
		color: header,
		fontSize: '14px',
	},
	hiddenButton: {
		visibility: 'hidden',
	},
	lastMessage: {
		...truncateTextStyle,
	},
	menu: {
		background: brandPrimary,
		border: DefaultBorder,
		boxShadow: '0 2px 4px 0 rgba(0, 0, 0, .1)',
		minWidth: 200,
	},
	menuItem: {
		color: '#fff',
		padding: 8,
	},
	moreConversation: {
		marginLeft: 8,
		padding: '3px 3px 0',
		border: '1px solid transparent',
		':focus': {
			borderColor: brandSecondary,
			outline: 'none',
		},
	},
	rightContainer: {
		'@media (max-width: 1200px)': {
			display: 'none',
		},
		alignItems: 'flex-start',
		display: 'flex',
		justifyContent: 'flex-end',
		paddingTop: 2,
		position: 'relative',
		width: rightContainerSize,
	},
	rightContainerCenter: {
		alignItems: 'center',
		padding: 0,
	},
	selected: {
		background: 'rgba(211, 219, 222, 0.4)',
	},
	trashConversation: {
		padding: '3px 3px 0',
		border: '1px solid transparent',
		':focus': {
			borderColor: brandSecondary,
			outline: 'none',
		},
	},
	unread: {
		fontWeight: 700,
	},
	unreadDot: {
		background: brandSecondary,
		borderRadius: '50%',
		height: 8,
		left: 8,
		width: 8,
	},
	unsubscribed: {
		color: destructive,
		fontSize: '14px',
	},
});
